<template>
  <div>
    <div :data-sid="section.sid">
      <highliter
        v-model="section.name"
        class="highlight-section es-navbar"
        hide-copy
        hide-move
        :class="[`--${section.sid}--es-navbar-bg`, 'navbar-' + resolution, {'position-absolute w-100': bringToFront}]"
        label="Navigation"
        uid="#navigation"
        :dublicate="{group: 'navbar'}"
        @delete="$emit('delete')"
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @click.stop.native="sectionSettings('menuItemsV3Mod', section)"
      >
        <template #right-panel>
          <i
            v-if="userRole.includes('support') || userRole.includes('superadministrator')"
            class="fa fa-upload hover:!text-white hover:opacity-60 ml-3"
            @click.stop="addToBlocks"
          />
        </template>

        <div>
          <b-collapse
            v-if="isCollapsed && section.options.content.subject.specialCols.navigation.placement === 'top'"
            id="header-collapse"
            v-model="collapse"
          >
            <flex-wrapper-col-v2
              :col="section.options.content.subject.specialCols.navigation"
              :index="0"
              :section="section.options.content.subject"
              :custom-back-options="{openedItem: 'menuItemsV2Mod', section}"
              mod="navigation-flex-col-mod-v2"
              @flow="handleFlow"
            />
          </b-collapse>

          <div class="flex w-100 items-start justify-center">
            <el-flex-wrapper-box-v2
              :section="section.options.content.subject"
              :custom-back-options="{openedItem: 'menuItemsV2Mod', section}"
              :highliter-options="{hideAdd: true, hideMove: true, hideRight: true}"
              @flow="handleFlow"
            >
              <b-link
                v-show="collapsedBreakpoint"
                class="absolute top-1/2 -translate-y-1/2 right-[10px] z-[1000000000]"
                :class="`--${section.options.content.subject.sid}--icon`"
                @click.stop.native="collapse = !collapse"
              >
                <i :class="hamburgerIcon" />
              </b-link>

              <template v-slot:navigation="{index, column}">
                <flex-wrapper-col-v2
                  v-if="!isCollapsed"
                  :col="column"
                  :index="index"
                  :section="section.options.content.subject"
                  :custom-back-options="{openedItem: 'menuItemsV2Mod', section}"
                  mod="navigation-flex-col-mod-v2"
                  @flow="handleFlow"
                />
              </template>
            </el-flex-wrapper-box-v2>
          </div>

          <b-collapse
            v-if="isCollapsed && section.options.content.subject.specialCols.navigation.placement === 'bottom'"
            id="header-collapse"
            v-model="collapse"
          >
            <flex-wrapper-col-v2
              :col="section.options.content.subject.specialCols.navigation"
              :index="0"
              :section="section.options.content.subject"
              :custom-back-options="{openedItem: 'menuItemsV2Mod', section}"
              mod="navigation-flex-col-mod-v2"
              @flow="handleFlow"
            />
          </b-collapse>
        </div>

        <add-to-blocks-modal
          :block="publicBlock"
        />
      </highliter>
    </div>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import ElFlexWrapperBoxV2 from '@/components/builder/sections/utils/el-inline/ElFlexWrapperBoxV2'
import _ from 'lodash'
import BackgroundMixin from '@/components/mixins/BackgroundMixin'
import FlexWrapperColV2 from '@/components/builder/sections/utils/el-inline/FlexWrapperColV2'
import {mapGetters} from 'vuex'
import AddToBlocksModal from '@/components/builder/components/modals/AddToBlockModal.vue'
import {clonePart} from '@/utils/useStylesheet'

export default {
  name: 'Navbar1',
  components: {AddToBlocksModal, FlexWrapperColV2, ElFlexWrapperBoxV2},
  mixins: [SectionMixin, BackgroundMixin],
  data () {
    return {
      collapse: false,
      collapsed: false,
      publicBlock: {}
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),
    bringToFront () {
      return _.get(this.section.options.content.menuItems, ['bringToFront', this.resolutioner], _.get(this.section.options.content.menuItems, ['bringToFront', 'lg'], false))
    },
    collapsedBreakpoint () {
      const breakpoints = ['lg', 'md', 'sm']
      const currentBreakpoint = breakpoints.findIndex(b => b === this.resolutioner)
      const collapsableBreakpoint = breakpoints.findIndex(b => b === this.section.options.content.subject.specialCols.navigation.showOn)

      return collapsableBreakpoint < currentBreakpoint
    },
    isCollapsed () {
      return this.collapsed || this.collapsedBreakpoint
    },
    iconOpen () {
      return _.get(this.section.options.content.subject.specialCols.navigation, 'hamburgerIcon_open', 'fa fa-bars')
    },
    iconClose () {
      return _.get(this.section.options.content.subject.specialCols.navigation, 'hamburgerIcon_close', 'fa fa-times')
    },
    iconColor () {
      return _.get(this.section.options.content.subject.specialCols.navigation, 'hamburgerIcon_color', '#656565')
    },
    closeIconColor () {
      return _.get(this.section.options.content.subject.specialCols.navigation, 'hamburgerIcon_close_color', this.iconColor)
    },
    hamburgerIcon () {
      if (!this.collapse) {
        return [this.iconOpen, `--${this.section.options.content.subject.sid}--open-icon`]
      }

      return [this.iconClose, `--${this.section.options.content.subject.sid}--close-icon`]
    },
    hamburgerIconColor () {
      if (!this.collapse) {
        return this.iconColor
      }

      return this.closeIconColor
    }
  },
  methods: {
    addToBlocks () {
      this.publicBlock = {
        section: JSON.parse(clonePart(this.section, this.$el)),
        original: this.section
      }
    }
  }
}
</script>
