<template>
  <div>
    <h6 class="font-weight-bold mb-4">
      Select Default Blog Author Profile
    </h6>
    <b-row>
      <b-col
        v-for="(author, index) in authors"
        :key="author.id"
        md="4"
      >
        <div
          class="group d-flex align-items-center border rounded p-3 author mb-4"
          style="height: 74px;"
          @click="authorAction(author)"
        >
          <b-img
            v-if="author.avatar.src"
            height="40"
            width="40"
            rounded="circle"
            class="avatar mr-3"
            :src="author.avatar.src"
            alt="Author Avatar"
          />
          <h6
            class="author-name mb-0"
            v-text="author.name"
          />
          <b-link
            class="ml-auto group-hover:opacity-100 opacity-0 text-danger"
            @click.stop="$store.dispatch('authors/remove', {author, index})"
          >
            <i class="fa fa-trash" />
          </b-link>
        </div>
      </b-col>
      <b-col md="4">
        <div
          class="d-flex align-items-center border rounded p-3 author"
          @click="authorAction(null)"
        >
          <svg
            class="mr-3"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="20"
              cy="20"
              r="20"
              fill="#E2E5EC"
            />
            <path
              opacity="0.3"
              d="M26.4238 21.6514V20.3525H20.7158V14.2344H19.041V20.3525H13.333V21.6514H19.041V27.7695H20.7158V21.6514H26.4238Z"
              fill="#6E747A"
            />
          </svg>

          <h6 class="author-name text-muted mb-0">
            Add New Author Profile
          </h6>
        </div>
      </b-col>
    </b-row>

    <blog-add-autor-modal :author="selectedAuthor" />
  </div>
</template>

<script>
import BlogAddAutorModal from './modals/BlogAddAutorModal'
import {mapState} from 'vuex'

export default {
  name: 'BlogAuthorSettings',
  components: {BlogAddAutorModal},
  data () {
    return {
      selectedAuthor: {}
      // authors: [
      //   {
      //     id: 1,
      //     name: 'Your Profile Name',
      //     avatar: {
      //       src: require('@builder/assets/images/testerBester.png')
      //     }
      //   }
      // ]
    }
  },
  computed: {
    ...mapState('authors', {
      authors: state => state.authors
    })
  },
  methods: {
    authorAction (author = null) {
      this.selectedAuthor = author || {
        name: '',
        avatar: {
          src: null
        },
        email: ''
      }

      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'author-modal-settings')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@builder/assets/scss/_variables.scss";

.author {
  cursor: pointer;
  transition: .5s;

  &:hover {
    box-shadow: 0 0 1px 2px $primary;
  }
}

.author-name {
  font-weight: 600;
  font-size: 15px;
  color: #333333;
}
</style>
