import { render, staticRenderFns } from "./BorderCustomsV3.vue?vue&type=template&id=57fdad0f&"
import script from "./BorderCustomsV3.vue?vue&type=script&lang=js&"
export * from "./BorderCustomsV3.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports