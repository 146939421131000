<template>
  <div>
    <b-btn
      variant="primary"
      @click="dialog = true"
    >
      <div class="d-flex align-items-center font-weight-bold">
        <div class="mr-2">
          Set Restriction
        </div>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.6 9.3C17.6 13.884 13.884 17.6 9.3 17.6C4.71604 17.6 1 13.884 1 9.3C1 4.71604 4.71604 1 9.3 1C13.884 1 17.6 4.71604 17.6 9.3ZM9.3 16.3C13.166 16.3 16.3 13.166 16.3 9.3C16.3 5.43401 13.166 2.3 9.3 2.3C5.43401 2.3 2.3 5.43401 2.3 9.3C2.3 13.166 5.43401 16.3 9.3 16.3Z"
            fill="white"
          />
          <path
            d="M12.9 9.53198V8.26798H10.036V5.14798H8.64398V8.26798H5.76398V9.53198H8.64398V12.78H10.036V9.53198H12.9Z"
            fill="white"
          />
        </svg>
      </div>
    </b-btn>

    <b-modal
      v-model="dialog"
      size="lg"
      hide-footer
      hide-header
    >
      <icon
        class="cursor-pinter ml-3 mt-3"
        icon="close.svg"
        @click.native="dialog = !dialog"
      />

      <h4 class="h2 text-center my-4">
        Set Restriction
      </h4>


      <b-form
        autocomplete="off"
        @submit.prevent="create"
      >
        <b-row class="justify-content-center">
          <b-col md="8">
            <b-form-group label="Name restriction">
              <b-input
                v-model="password.title"
                v-validate="'required'"
                name="title"
                autocomplete="false"
                :state="errors.has('title') ? false : null"
              />
            </b-form-group>

            <b-form-group label="Password">
              <div class="position-relative">
                <b-input
                  v-model="password.password"
                  v-validate="'required'"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  autocomplete="false"
                  :state="errors.has('password') ? false : null"
                />
                <div class="position-absolute top-1/2 transform -translate-y-1/2 right-8">
                  <b-link @click="showPassword = !showPassword">
                    <i
                      v-if="showPassword"
                      class="fa fa-eye"
                    />
                    <i
                      v-else
                      class="fa fa-eye-slash"
                    />
                  </b-link>
                </div>
              </div>
            </b-form-group>

            <div class="d-flex align-items-center justify-content-center mb-4">
              <b-btn
                variant="primary"
                type="submit"
                :disabled="loading"
                class="d-flex align-items-center btn-wide"
              >
                Save

                <b-spinner
                  v-if="loading"
                  class="ml-2"
                  variant="light"
                  small
                />
              </b-btn>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'EditPasswordModal',
  data () {
    return {
      password: {
        title: '',
        password: ''
      },
      dialog: false,
      loading: false,
      showPassword: false
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  methods: {
    create () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true

          axios.post(`api/projects/${this.project.id}/passwords`, this.password)
            .finally(() => {
              this.$emit('submited')

              this.loading = false
              this.password = {
                title: '',
                password: ''
              }
              this.dialog = false

              this.$swal({
                icon: 'success',
                title: 'Restriction added successfully!',
                showConfirmButton: false,
                timer: 1500
              })
            })
        }
      })
    }
  }
}
</script>
