<template>
  <div class="pt-[20px]">
    <b-form-group label="Columns">
      <b-row class="align-items-center pl-2">
        <b-col
          class="mr-2"
          cols="8"
        >
          <vue-slider
            v-model="getSubject[resolutioner].cols"
            :max="12"
            :min="1"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="getSubject[resolutioner].cols"
            :max="12"
            :min="1"
            :step="1"
            readonly
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <background-customs :config="backgroundColor" />

    <SidbearSettingsGroup
      class="border-top"
      title="Background Image"
    >
      <background-image-customs :config="backgroundImage" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Shape Divider"
    >
      <shape-divider-customs :config="getSubject" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <shadow-customs :config="getSubject" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Border & Radius"
    >
      <border-customs
        :config="getSubject"
        :default-border="[0,0,0,0]"
        :default-radius="[0,0,0,0]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <height-customs
        :config="padding"
      />
      <margin-full-customs
        :config="margin"
      />
    </SidbearSettingsGroup>

    <Panel
      v-if="getSubject.hasOwnProperty('display')"
      :config="getSubject"
      class="bg-white"
    />
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'

export default {
  name: 'ColumnMod',
  components: {SidbearSettingsGroup},
  mixins: [ModMixin],
  computed: {

    getSubject () {
      return this.config.options.content.columns[this.openedItemOptions.subjectIndex]
    },
    padding () {
      if (!this.getSubject.padding) {
        this.$set(this.getSubject, 'padding', {})
      }

      return this.getSubject.padding
    },
    margin () {
      if (!this.getSubject.margin) {
        this.$set(this.getSubject, 'margin', {})
      }

      return this.getSubject.margin
    },
    backgroundColor () {
      if (!this.getSubject.background) {
        this.$set(this.getSubject, 'backgroundType', null)
        this.$set(this.getSubject, 'background', 'rgba(255, 255, 255 ,0)')
      }

      return this.getSubject
    },
    backgroundImage () {
      if (!this.getSubject.backgroundImage) {
        this.$set(this.getSubject, 'backgroundImage', {})
      }

      return this.getSubject.backgroundImage
    }
  }
}
</script>