import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Tabs'
export const group = 'Advanced'

export const defaultOptions = {
  'content': {
    'gridCollapse': {
      'type': 'grid-collapse-mod-v3',
      'display': true,
      opened: 0,
      'items': [{'title': 'Tab 1', tabType: null, 'elements': []}, {'title': 'Tab 2', tabType: null, 'elements': []}]
    }
  }
}

export const icon = `<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="5" height="4" rx="1" fill="#0D0D0D"/>
<rect x="14" width="5" height="4" rx="1" fill="#0D0D0D"/>
<rect x="6" width="7" height="4" rx="1" fill="#0D0D0D"/>
<rect y="5" width="19" height="11" rx="1" fill="#0D0D0D"/>
</svg>
`

export default class ElGridCollapse extends SectionProvider {
  constructor (options = {}) {
    super('ElGridCollapseV3', options)

    this.name = name
    this.group = group
    this.css = [['.--cea967j--D0z_dh87ja--wrapper {padding: 20px;}', '.--cea967j--D0z_dh87ja--tab { border-width: 1px 1px 0px; padding: 15px 40px; border-radius: 5px 5px 0px 0px; margin-top: -1px; margin-right: 0px; font-size: 16px; background-color: rgb(255, 255, 255); }', '.--cea967j--D0z_dh87ja--flex { column-gap: 4px; }', '.--cea967j--D0z_dh87ja--content { border-width: 1px; margin-right: 0px; margin-bottom: 20px; margin-top: -1px; }', '.--cea967j--D0z_dh87ja--tab:hover { background-color: rgb(117, 147, 255); color: rgb(255, 255, 255); }', '.--cea967j--D0z_dh87ja--tab.active { background-color: rgb(117, 147, 255); color: rgb(255, 255, 255); }'], [], []]
    this.sid = 'cea967j--D0z_dh87ja'
    this.sids = ['cea967j--D0z_dh87ja']
    this.defaultOptions = defaultOptions
  }
}
