<script>
export default {
  name: 'ETG'
}
</script>

<template>
  <div class="text-center text-muted">
    ESTAGE Global Tracking is ON!
  </div>
</template>