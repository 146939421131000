<template>
  <label class="position-relative w-100 mb-4">
    <div class="account-image-uploader rounded border-solid border border-primary text-center cursor-pointer px-4 py-5">
      <p class="text-base mb-0">Profile Image</p>

      <div class="my-4">
        <b-avatar
          class="avatar"
          :src="imageMutated"
        />
      </div>

      <b-link
        v-if="image && imageMutated"
        class="text-base text-primary"
        @click="removeImage"
      >Remove Image</b-link>
      <span
        v-else
        class="text-base text-primary"
      >Select Image</span>
    </div>

    <div class="d-none">
      <b-form-file
        v-model="file"
        accept=".jpg, .png"
        @change="onChange"
      />
    </div>

    <div class="mt-2">The maximum file size is 5 MB.</div>
  </label>
</template>

<script>
export default {
  name: 'AccountImageUploader',
  props: {
    value: {
      required: true
    },
    image: {
      required: true
    }
  },
  data () {
    return {
      file: [],
      imageMutated: this.image,
      deleted: false
    }
  },
  methods: {
    async onChange (event) {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      this.imageMutated = URL.createObjectURL(event.target.files[0])
      this.$emit('input', await toBase64(event.target.files[0]))
    },
    removeImage () {
      axios.delete('api/user/profile/remove-image')
        .then(() => {
          this.imageMutated = null
        })
    }
  }
}
</script>

<style lang="scss">
.account-image-uploader {
  background: #FBFCFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);

  .avatar {
    width: 86px;
    height: 86px;
  }
}
</style>