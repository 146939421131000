<template>
  <div class="pt-[9px] min-h-[500px]">
    <draggable
      v-model="config.items"
      handle=".move"
    >
      <div
        v-for="(item, index) in config.items"
        :key="index"
        class="border group rounded-[2px] h-[38px] flex items-center pl-[13px] hover:bg-[#F1F5FD] cursor-pinter mb-[12px]"
      >
        <div class="mr-[11px] move cursor-move">
          <svg
            fill="none"
            height="11"
            viewBox="0 0 11 11"
            width="11"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.57745 0.894104H0.568359V3.8941H3.57745V0.894104Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 0.894104H7.58936V3.8941H10.5984V0.894104Z"
              fill="#3D82EA"
            />
            <path
              d="M3.57745 7.8941H0.568359V10.8941H3.57745V7.8941Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 7.8941H7.58936V10.8941H10.5984V7.8941Z"
              fill="#3D82EA"
            />
          </svg>
        </div>

        <span
          class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]"
          v-text="item.title"
        />

        <button
          class="w-[38px] h-[36px] text-center border-left bg-[#fff] p-0 ml-auto"
          @click="config.items.splice(index, 1)"
        >
          <svg
            fill="none"
            height="10"
            viewBox="0 0 10 10"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
              fill="#6E747A"
            />
          </svg>
        </button>
      </div>
    </draggable>

    <button
      class="flex items-center border rounded-[3px] font-[600] text-[#44474A] text-[14px] h-[38px] px-[17px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[17px]"
      @click="addCollapse"
    >
      <span class="text-[#3D82EA] text-[22px] mr-[9px]">+</span>
      Add Menu Item
    </button>

    <b-form-group
      label="Header size"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="fontSize"
            :interval="1"
            :max="90"
            :min="10"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="fontSize"
            :max="90"
            :min="10"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <font-family-and-weight-control
      text-type="paragraph"
      :family="fontFamily"
      :weight="fontWeight"
      @family="family => fontFamily = family"
      @weight="weight => fontWeight = weight"
    />

    <b-form-group label="Open Icon">
      <div class="flex items-center gap-[10px] w-[170px]">
        <icon-select v-model="openIcon" />

        <color-picker
          :color="openIconColor"
          @input="event => openIconColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </div>
    </b-form-group>

    <b-form-group label="Close Icon">
      <div class="flex items-center gap-[10px] w-[170px]">
        <icon-select v-model="closeIcon" />

        <color-picker
          :color="closeIconColor"
          @input="event => closeIconColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </div>
    </b-form-group>

    <background-customs
      :config="section.options.customize.style.background"
      type-label="Header Background Type"
    >
      <color-customs :config="section.options.customize.style.color" />
    </background-customs>

    <background-customs
      :config="section.options.customize.style.backgroundBody"
      type-label="Body Background Type"
    />

    <SidbearSettingsGroup
      class="border-top"
      title="Drop Shadow"
    >
      <shadow-customs :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="section.options.customize.style.padding" />
      <margin-full-customs :config="margin" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import _ from 'lodash'
import shortid from 'shortid'
import ModMixin from '@builder/components/mixins/ModMixin'
import draggable from 'vuedraggable'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import IconSelect from '@/components/editor/components/IconSelect'
import FontFamilyAndWeightControl from '@/components/editor/controls/FontFamilyAndWeightControl'

export default {
  name: 'CollapseMod',
  components: {FontFamilyAndWeightControl, IconSelect, SidbearSettingsGroup, draggable},
  mixins: [ModMixin],
  computed: {
    margin () {
      if (!this.config.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.margin
    },
    fontSize: {
      get () {
        return parseInt(this.config.fontSize[this.resolutioner])
      },
      set (val) {
        this.$set(this.config.fontSize, this.resolutioner, val)
      }
    },
    fontFamily: {
      get () {
        return _.get(this.config, ['fontFamily', this.resolutioner], _.get(this.config, ['fontFamily', 'lg'], ''))
      },
      set (val) {
        this.$set(this.config, 'fontFamily', {..._.set(this.config['fontFamily'] || {}, [this.resolutioner], val)})
      }
    },
    fontWeight: {
      get () {
        return _.get(this.config, ['fontWeight', this.resolutioner], _.get(this.config, ['fontWeight', 'lg'], ''))
      },
      set (val) {
        this.$set(this.config, 'fontWeight', {..._.set(this.config['fontWeight'] || {}, [this.resolutioner], val)})
      }
    },
    openIcon: {
      get () {
        return _.get(this.config, 'icon.open.iocn', 'fa fa-plus')
      },
      set (val) {
        this.$set(this.config, 'icon', {..._.set(this.config.icon || {}, ['open', 'iocn'], val)})
      }
    },
    closeIcon: {
      get () {
        return _.get(this.config, 'icon.close.iocn', 'fa fa-minus')
      },
      set (val) {
        this.$set(this.config, 'icon', {..._.set(this.config.icon || {}, ['close', 'iocn'], val)})
      }
    },
    openIconColor: {
      get () {
        return _.get(this.config, 'icon.open.color', this.section.options.customize.style.color.color)
      },
      set (val) {
        this.$set(this.config, 'icon', {..._.set(this.config.icon || {}, ['open', 'color'], val)})
      }
    },
    closeIconColor: {
      get () {
        return _.get(this.config, 'icon.close.color', this.section.options.customize.style.color.color)
      },
      set (val) {
        this.$set(this.config, 'icon', {..._.set(this.config.icon || {}, ['close', 'color'], val)})
      }
    }
  },
  methods: {
    addCollapse () {
      this.config.items.push({
        uid: shortid.generate(),
        title: 'Title text',
        elements: []
      })
    }
  }
}
</script>
