<template>
  <div>
    <b-card
      v-for="(group, index) in sections"
      :key="index"
      :class="{'active': index === active}"
      class="border-0"
      no-body
      @click="openBlocksMenu(group, index, renames[index] || startCase(index))"
    >
      <section-item-hover class="border-0">
        <icon
          :icon="'menu-sections/' + index + '.svg'"
          class="chevron mr-3"
          size="20px"
        />
        <div block>
          {{ renames[index] || startCase(index) }}
        </div>
      </section-item-hover>
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'
import TextMixins from '../../mixins/TextMixins'
import Icon from '@builder/components/editor/utils/Icon'
import SectionItemHover from '../utils/SectionItemHover'
import sections from '@builder/sections'

export default {
  name: 'BlocksDragItems',
  components: {SectionItemHover, Icon},
  mixins: [TextMixins],
  props: {
    filteredBlocks: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      globalBlocks: {},
      order: [
        'empty-blocks',
        'alert-bar',
        'navbar',
        'hero',
        'featured',
        'testimonials',
        'blog',
        'textAndImage',
        'resource',
        'actions',
        'call-to-actions',
        'opt-in-forms',
        'cards',
        'logos',
        'faq',
        'gallery',
        'showcase',
        'newsLetter',
        'contact-forms',
        'About Us',
        'Pricing',
        'videos',
        'Carousel',
        'quotes',
        'Footer',
        'others'
      ],
      active: 0,
      renames: {
        'actions': 'Call To Actions',
        'opt-in-forms': 'Opt-In Forms',
        'navbar': 'Header',
        'blog': 'Blog Feed',
        'faq': 'FAQ'
      }
    }
  },
  computed: {
    sections () {
      let sortedSections = {}

      const blocks = _.reduce(sections, (acc, group, key) => {
        return {
          ...acc,
          [key]: this.globalBlocks[key] ? [...this.globalBlocks[key], ...group] : group
        }
      }, this.globalBlocks)

      this.order.forEach(key => {
        if (this.filteredBlocks.length) {
          if (!this.filteredBlocks.includes(key)) return
        }

        if (blocks[key] && blocks[key].length) {
          sortedSections[key] = blocks[key]
        }
      })

      return sortedSections
    }
  },
  mounted () {
    this.fetchBlocks()
  },
  methods: {
    openBlocksMenu (group, index = 0, name) {
      this.active = index
      this.$store.commit('editor/SET_PAGE', 'blocks-menu')
      setTimeout(() => {
        VEvent.fire('blocks-menu', {group, name})
      }, 400)
    },
    fetchBlocks () {
      axios.get('api/projects/block-management?perPage=200')
        .then(({data}) => {
          this.globalBlocks = _.groupBy(_.map(data.data.data, (group) => {
            return {
              ...group,
              section: JSON.parse(group.block)
            }
          }), 'group')
        })
    }
  }
}
</script>
