<template>
  <div class="mb-4">
    <div class="flex items-center gap-[10px]">
      <vue-slider
        v-model="color.position"
        class="editor-slider-1 !w-[160px]"
        :interval="1"
        :dot-size="17"
        tooltip="none"
        :min="0"
        :max="100"
        @change="() => $emit('change')"
      />

      <LinkedControlItem
        v-model="color.position"
        :min="0"
        :max="100"
        class="d-block !w-[70px]"
        @input="() => $emit('change')"
      />

      <color-picker
        :color="color.color"
        @input="updateColor"
      />

      <i
        class="fa fa-trash cursor-pointer text-[#6E747A] hover:opacity-80"
        @click="() => $emit('delete')"
      />
    </div>
  </div>
</template>

<script>
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
export default {
  name: 'GradientPickerItem',
  components: {LinkedControlItem},
  props: {
    color: {
      type: Object,
      required: true
    }
  },
  methods: {
    updateColor (event) {
      this.color.color = event.hex8
      this.$emit('change')
    }
  }
}
</script>