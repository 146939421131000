<template>
  <div class="pt-[12px]">
    <sticky-control :config="config" />

    <background-customs :config="section.options.customize.style.background" />

    <SidbearSettingsGroup
      class="border-top"
      title="Background Image"
    >
      <background-image-customs :config="section.options.customize.style.backgroundImage" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Float Image"
    >
      <float-image-customs :config="section.options.customize.style.floatImage" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Animations"
    >
      <b-form-group label="Onload effect">
        <animations :animations="animations" />
      </b-form-group>

      <hover-animations v-model="hoverAnimation" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Border & Radius"
    >
      <border-customs :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <StateTabs
        :states-text="['Normal State', 'Hover State']"
        class="pb-[12px]"
      >
        <shadow-customs :config="config" />

        <template slot="action-state">
          <shadow-customs :config="hoverConfig" />
        </template>
      </StateTabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="section.options.customize.style.padding" />
      <margin-full-customs :config="section.options.customize.style.marginY" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Shape Divider"
    >
      <shape-divider-customs :config="section.options.customize.style" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ThemeMixin from '@builder/components/mixins/ThemeMixin'
import _ from 'lodash'
import StickyControl from '../controls/StickyControl'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import ShapeDividerCustoms from '@/components/editor/controls/ShapeDividerCustoms'
import Animations from '@/components/editor/controls/Animations'
import HoverAnimations from '@/components/editor/controls/HoverAnimations'
import StateTabs from '@/components/editor/components/StateTabs'

export default {
  name: 'ButtonMod',
  components: {StateTabs, HoverAnimations, Animations, ShapeDividerCustoms, SidbearSettingsGroup, StickyControl},
  mixins: [ModMixin, ThemeMixin],
  computed: {
    customHeight: {
      get () {
        return _.get(this.config, 'custom_height', false)
      },
      set (val) {
        return this.$set(this.config, 'custom_height', val)
      }
    },
    height: {
      get () {
        return _.get(this.config, `height_${this.resolution}`, 500)
      },
      set (val) {
        return this.$set(this.config, `height_${this.resolution}`, val)
      }
    },
    type: {
      get () {
        return _.get(this.config, 'height_type', 'px')
      },
      set (val) {
        return this.$set(this.config, 'height_type', val)
      }
    },
    hoverAnimation: {
      get () {
        return this.config.hoverAnimation || null
      },
      set (val) {
        this.$set(this.config, 'hoverAnimation', val)
      }
    },
    animations: {
      get () {
        if (!this.config.hasOwnProperty('animations')) {
          this.$set(this.config, 'animations', {
            animation: null,
            once: true,
            offset: 200,
            delay: 50
          })
        }

        return this.config.animations
      },
      set (val) {
        this.config.animations = val
      }
    }
  }
}
</script>
