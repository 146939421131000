<template>
  <div class="min-vh-50">
    <h2 class="page-title mb-5">
      Your Current Plan
    </h2>
    <p class="text-[16px]">
      You are on the <b
        class="font-italic"
        v-text="plan"
      />
    </p>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import _ from 'lodash'

export default {
  name: 'Main',
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    plan () {
      const code = _.get(this.user, 'user_subscription.plan.code')
      switch (code) {
      case 'ESTAGE_SUB':
        return 'ESTAGE - Business $197/month'
      case 'BUSINESS_Y':
        return 'ESTAGE - Business $1970/year'
      case 'BUSINESS_PLUS_M':
        return 'ESTAGE - Business Plus $297/month'
      case 'BUSINESS_PLUS_Y':
        return 'ESTAGE - Business Plus $2970/year'
      default:
        return _.get(this.user, 'user_subscription.plan.description', 'special custom Four Percent insider\'s plan')
      }
    }
  }
}
</script>