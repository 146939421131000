<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('tableOfContents')"
  >
    <div
      :data-sid="section.sid"
      :class="[{'element-hidden': !visability('tableOfContents')}]"
    >
      <div
        class="toc-card"
        :class="[`--${section.sid}--card`,!opened ? 'closed':'']"
      >
        <div
          class="toc-card--header"
        >
          <span class="toc-title">{{ section.options.content.tableOfContents.title }}</span>
          <span
            v-if="section.options.content.tableOfContents.toggler"
            class="toc-card--header-toggler"
            @click="handleToggle" 
          >
            <i :class="`fa fa-chevron-${opened ? 'up':'down'}`" />
          </span>
        </div>
        <div class="toc-card--body">
          <el-table-of-contents-item
            v-if="toc.length"
            :marker="section.options.content.tableOfContents.marker"
            :icon-class="section.options.content.tableOfContents.iconClass"
            :items="toc"
            class="pl-0"
          />
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ElTableOfContentsItem from './ElTableOfContentsItem.vue'
import { findSections } from '../../../../utils/helpers'
export default {
  name: 'ElTableOfContents',

  components: { Highliter,ElTableOfContentsItem },

  mixins: [SectionMixin],

  data () {
    return {
      opened:true
    }
  },
  computed: {
    toc () {
      let cd = this.page.content_draft
      if(typeof cd === 'string'){
        try {
          cd = JSON.parse(cd)
        } catch (error) {
          console.error('Error on parsing content draft', error)
        }
      }
      const components = ['ElHeadlineV2','ElSubheadlineV2']
      const sections = findSections(cd, components)   
   
      const anchors = this.section.options.content.tableOfContents.anchors || []
      const excludes = this.section.options.content.tableOfContents.excludes || []

      if (!anchors.length) return []

      const headings = sections.reduce((acc, section) => {
        if (components.includes(section.component) && section.options?.content?.description?.tag) {
          const tag = section.options.content.description.tag
          const id = section.options.content.description.id
          
          if ((excludes.length && id && excludes.includes(id)) || !anchors.includes(tag)) {
            return acc
          }

          const level = parseInt(tag.charAt(1), 10)
          const textContent = section.options.content.description.text.replace(/<\/?[^>]+(>|$)/g, '')

          acc.push({
            uuid: section.uid,
            text: textContent,
            level,
            numbering: ''
          })
        }
        return acc
      }, [])

      return this.nestHeadings(headings)
    }
  },
  methods: {
    nestHeadings (headings) {
      const nested = []
      const stack = []

      headings.forEach((heading) => {
        const item = {
          uuid: heading.uuid,
          text: heading.text,
          level: heading.level,
          children: [],
          numbering: ''
        }

        // Adjust the numbering for the current heading level
        while (stack.length && stack[stack.length - 1].level >= heading.level) {
          stack.pop()
        }

        // Compute the numbering based on current stack state
        if (stack.length > 0) {
          const parentItem = stack[stack.length - 1]
          const count = parentItem.children.length + 1
          item.numbering = `${parentItem.numbering}.${count}`
          parentItem.children.push(item)
        } else {
          item.numbering = (nested.length + 1).toString()
          nested.push(item)
        }

        stack.push(item)
      })

      return nested
    },
    handleToggle (){
      this.opened = !this.opened
    }
  }

}
</script>

<style lang="scss">
.toc-card{
    background: #FFFFFF;
    border: 1px;
    border-style:solid;
    border-color: #E2E5EC;
    border-radius: 4px;
    font-family: "Roboto";
    overflow: hidden;
    &--header{
      padding: 15px 15px;
      display: flex;
      justify-content: space-between;
      font-size: 22px;
      border-bottom: 1px solid #0000001a;

      .toc-title{
        font-weight: bold;
        flex: 1 1 100%;
      }

      .toc-card--header-toggler{
        cursor: pointer;
        margin-left: 8px;
      }
    }
    &--body{
      padding: 15px;
      // transition: max-height 0.3s ease; 
    }
  &.closed {
    .toc-card--body{
      // display: none;
      visibility: hidden; 
      height: 0; 
      overflow: hidden; 
      padding: 0!important;
    }
  }
}
</style>