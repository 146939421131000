<template>
  <div>
    <button-mod
      v-if="globalStyleButton"
      :config="globalStyleButton.options.content.button"
      :global-style-presset="true"
      :custom-button-pressets="buttonPressets"
      :hide="['text', 'subtext', 'actions', 'overlay']"
      :local-section="globalStyleButton"
      :show-active-group="false"
      :show-others="false"
      component-name="Button"
      group-prefix="Button"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import GlobalStyleButton from '@/components/mixins/GlobalStyleButton'
import buttonVariants from '@/composition/buttons'

export default {
  name: 'ButtonGlobalStyle',
  mixins: [ResolutionMixin, GlobalStyleButton],
  data () {
    return {
      buttonPressets: [
        {
          id: 0,
          text: 'Custom',
          onSelect: (pressetID) => {
            const button = _.cloneDeep(this.globalStyleButton.options.content.button)
            button.pressetID = pressetID

            this.$set(this.globalStyleButton.options.content, 'button', button)
          }
        },
        {
          id: 1,
          preview: require('@/assets/images/element-presset/button-mod/button-1.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[1])


            button.pressetID = pressetID

            this.$set(this.globalStyleButton.options.content, 'button', button)
          }
        },
        {
          id: 2,
          preview: require('@/assets/images/element-presset/button-mod/button-2.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[2])


            button.pressetID = pressetID

            this.$set(this.globalStyleButton.options.content, 'button', button)
          }
        },
        {
          id: 3,
          preview: require('@/assets/images/element-presset/button-mod/button-3.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[3])


            button.pressetID = pressetID

            this.$set(this.globalStyleButton.options.content, 'button', button)
          }
        },
        {
          id: 4,
          preview: require('@/assets/images/element-presset/button-mod/button-4.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[4])


            button.pressetID = pressetID

            this.$set(this.globalStyleButton.options.content, 'button', button)
          }
        },
        {
          id: 5,
          preview: require('@/assets/images/element-presset/button-mod/button-5.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[5])


            button.pressetID = pressetID

            this.$set(this.globalStyleButton.options.content, 'button', button)
          }
        },
        {
          id: 6,
          preview: require('@/assets/images/element-presset/button-mod/button-6.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[6])


            button.pressetID = pressetID

            this.$set(this.globalStyleButton.options.content, 'button', button)
          }
        },
        {
          id: 7,
          preview: require('@/assets/images/element-presset/button-mod/button-7.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[7])


            button.pressetID = pressetID

            this.$set(this.globalStyleButton.options.content, 'button', button)
          }
        },
        {
          id: 8,
          preview: require('@/assets/images/element-presset/button-mod/button-8.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[8])


            button.pressetID = pressetID

            this.$set(this.globalStyleButton.options.content, 'button', button)
          }
        },
        {
          id: 9,
          preview: require('@/assets/images/element-presset/button-mod/button-9.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[9])


            button.pressetID = pressetID

            this.$set(this.globalStyleButton.options.content, 'button', button)
          }
        }
      ]
    }
  }
}
</script>