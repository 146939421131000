import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Product Title'
export const group = ''

export default class ElProductTitle extends SectionProvider {
  constructor (options = {}) {
    super('ElProductTitle', options)

    this.name = name
    this.group = group
  }
}