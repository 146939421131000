<template>
  <div>
    <motion-triggers
      :motion="subjectMotions"
    />

    <motion-duration :subject-motions="subjectMotions" />

    <motion-delay :subject-motions="subjectMotions" />

    <motion-ease :subject-motions="subjectMotions" />

    <b-form-group>
      <b-checkbox v-model="infinite">
        Infinite
      </b-checkbox>
    </b-form-group>

    <motion-progress
      v-model="progress"
      label="Progress"
    />

    <motion-effects-list
      v-model="subjectMotions.viewport[resolutioner]"
      :options="viewportEffectOptions"
      :progress="progress"
    />
  </div>
</template>

<script>
import MotionTriggers from '@/components/editor/components/motions/MotionTriggers'
import MotionProgress from '@/components/editor/components/motions/MotionProgress'
import MotionEffectsList from '@/components/editor/components/motions/MotionEffectsList'
import {viewportEffectOptions} from '@/components/editor/components/motions/motions-effects/motions-effects-list'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import MotionDuration from '@/components/editor/components/motions/MotionDuration'
import MotionEase from '@/components/editor/components/motions/MotionEase'
import MotionDelay from '@/components/editor/components/motions/MotionDelay'
export default {
  name: 'ViewportMotion',
  components: {MotionDelay, MotionEase, MotionDuration, MotionEffectsList, MotionProgress, MotionTriggers},
  mixins: [ResolutionMixin],
  props: {
    subjectMotions: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      progress: 0,
      viewportEffectOptions
    }
  },
  computed: {
    infinite: {
      get () {
        return this.subjectMotions.infinite || false
      },
      set (val) {
        this.$set(this.subjectMotions, 'infinite', val)
      }
    }
  }
}
</script>

<style scoped>

</style>