<template>
  <settings-page-card title="Share Entire Project (all pages)">
    <div class="px-4">
      <div class="max-w-[600px]">
        <b-form-group>
          <b-input-group>
            <b-input
              :value="targetFull"
              readonly
            />
            <b-input-group-append>
              <b-btn
                v-b-tooltip.top="tooltipText"
                v-clipboard:copy="targetFull"
                v-clipboard:success="onCopy"
                variant="primary"
              >
                <i class="fa fa-clipboard" />
              </b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <div class="d-flex align-items-center mb-4">
          <b-checkbox
            v-model="globalSafeMode"
            class="mr-2"
          />
          Include active links
        </div>

        <p>Copy and share the link above to export the project</p>
      </div>

      <hr class="my-5">

      <div>
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h5 class="title font-weight-sami-bold">
            Share Individual Pages
          </h5>

          <div
            class="opacity-0"
            style="height: 43px;"
          />

          <b-btn
            v-if="selectedPages.length"
            v-clipboard:copy="selectedPagesMudated.join(',')"
            v-clipboard:success="onExport"
            class="animate__animated animate__fadeInLeft"
            v-text="btnExportText"
          />
        </div>
        <share-page-item
          v-for="page in pages.data"
          :key="page.id"
          v-model="selectedPages"
          :page="page"
          :safe-mode="safeMode"
          :modals-mode="modalsMode"
          @safeModeChange="val => safeMode = val"
          @modalsModeChange="val => modalsMode = val"
        />

        <div
          v-if="pageIsLoading"
          class="d-flex align-items-center justify-content-center mt-3"
        >
          Loading
          <b-spinner
            class="ml-2"
            variant="primary"
            small
          />
        </div>
      </div>
    </div>
  </settings-page-card>
</template>

<script>
import {mapState} from 'vuex'
import SettingsPageCard from '../../layouts/dashboard/SettingsPageCard'
import TextMixins from '../../components/mixins/TextMixins'
import SharePageItem from './SharePageItem'

export default {
  name: 'Share',

  components: {SharePageItem, SettingsPageCard},

  mixins: [TextMixins],

  data () {
    return {
      tooltipText: 'Copy',
      target: null,
      selectedPages: [],
      safeMode: [],
      modalsMode: [],
      globalSafeMode: false,
      btnExportText: 'Copy the hash of the selected pages',
      currentPage: 1,
      pageIsLoading: false
    }
  },

  computed: {
    selectedPagesMudated () {
      return this.selectedPages.map(hash => {
        const params = new URLSearchParams('')

        if (this.safeMode.includes(hash)) {
          params.append('saveMode', 1)
        }

        if (this.modalsMode.includes(hash)) {
          params.append('include_modal', 1)
        }

        return `${hash}${params.toString() ? '?' : ''}${params.toString()}`
      })
    },
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('pages', {
      pages: state => state.pages
    }),
    targetFull () {
      return `${this.target}${this.globalSafeMode ? '?saveMode=1' : ''}`
    }
  },

  watch: {
    async currentPage (val) {
      this.pageIsLoading = true
      let pages = await this.$store.dispatch('pages/loadPagesScrolling', {page:val})
      pages = pages.data
      this.pageIsLoading = false

      this.pages.data = [...this.pages.data, ...pages]
    }
  },

  mounted () {
    this.target = `https://app.estage.com/project-import/${this.project.hash}`
    window.addEventListener('scroll', this.onScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onScroll () {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && (this.pages.data.length + 1) < this.pages.total) {
        this.currentPage++
      }
    },
    onExport () {
      this.btnExportText = 'Copied!'

      setTimeout(() => {
        this.btnExportText = 'Export Selected Pages'
        this.selectedPages = []
        this.safeMode = []
        this.modalsMode = []
      }, 2000)
    },
    onCopy () {
      this.tooltipText = 'Copied!'

      setTimeout(() => {
        this.tooltipText = 'Copy'
      }, 800)
    }
  }
}
</script>

<style lang="scss">
.pages-list-group-item {
  & .list-item-part {
    height: 60px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }

  & > div {
    border: 1px solid #eee;
    border-bottom: none;
  }

  &:last-child > div {
    border-bottom: 1px solid #eee;
  }
}
</style>
