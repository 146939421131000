<template>
  <div class="mb-4">
    <div class="mb-[18px]">
      <label class="block !mb-[10px]">Margin</label>

      <div class="flex items-center justify-between">
        <b-form-radio-group
          v-model="position"
          :options="[{text: 'TOP', key: 'top'}, {text: 'BOTTOM', key: 'bottom'}]"
          buttons
          buttons-variant="light"
          class="resolution-style-tabs"
          size="sm"
          text-field="text"
          value-field="key"
        />

        <button
          class="h-[38px] w-[38px] flex items-center justify-center p-0 !border-[#E2E5EC] rounded"
          style="border:1px solid;"
          @click="linked = !linked"
        >
          <svg
            fill="none"
            height="17"
            viewBox="0 0 18 17"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :fill="linked ? '#7CACFA' : '#6E747A'"
              d="M16.6721 1.25439C15.1096 -0.210115 12.5765 -0.210115 11.014 1.25435L7.67013 4.38854C6.05395 5.90345 6.20358 8.3172 7.67013 9.69186C7.91572 9.92205 8.18744 10.1097 8.47437 10.2639L9.08467 9.69186C9.48496 9.31661 9.34406 8.87795 9.33686 8.56142C9.249 8.50305 9.16355 8.43995 9.08467 8.36602C8.33207 7.66067 8.29823 6.45148 9.08467 5.71436C9.20146 5.60489 12.3576 2.64667 12.4285 2.58023C13.2086 1.84901 14.4774 1.84901 15.2575 2.58023C16.0376 3.31145 16.0376 4.50067 15.2575 5.23189L13.0473 7.30355C13.1112 7.63502 13.4948 8.4202 13.2974 9.71545C13.3071 9.70658 13.3186 9.70086 13.3282 9.69189L16.6721 6.5577C18.2345 5.0932 18.2345 2.71889 16.6721 1.25439Z"
            />
            <path
              :fill="linked ? '#7CACFA' : '#6E747A'"
              d="M11.2064 6.37727C10.9608 6.14708 10.6891 5.95939 10.4022 5.80524L9.79192 6.37727C9.39156 6.75246 9.53246 7.19111 9.53966 7.50764C9.62758 7.56602 9.71303 7.62911 9.79192 7.70305C10.5445 8.40846 10.5784 9.61758 9.79192 10.3547C9.67486 10.4644 6.25732 13.6677 6.18894 13.7318C5.40881 14.463 4.14003 14.463 3.3599 13.7318C2.57976 13.0006 2.57976 11.8113 3.3599 11.0801L5.82933 8.76552C5.76542 8.43405 5.3818 7.64886 5.57914 6.35361C5.56951 6.36249 5.55791 6.36827 5.5483 6.37724L1.94536 9.75433C0.382959 11.2188 0.382959 13.5931 1.94536 15.0576C3.50783 16.5221 6.04094 16.5221 7.60341 15.0576L11.2064 11.6806C12.7926 10.1938 12.7059 7.78274 11.2064 6.37727Z"
            />
          </svg>
        </button>
      </div>
    </div>

    <b-row
      class="align-items-center pl-2"
      no-gutters
    >
      <b-col
        class="mr-2"
        cols="8"
      >
        <vue-slider
          v-model="margin"
          :dot-size="17"
          :interval="0.1"
          :max="100"
          :min="-100"
          class="editor-slider-1 pr-2"
          tooltip="none"
        />
      </b-col>
      <b-col cols="3">
        <b-input
          v-model="margin"
          :min="-100"
          size="sm"
          step="1"
          style="width:71px;"
          type="number"
        />
      </b-col>
    </b-row>

    <div class="mt-[3px]">
      <span
        class="text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
        @click="syncPadding"
      >
        {{ syncReady ? 'Done!' : 'Sync margin for all devices' }}
      </span>
    </div>

    <span
      class="text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80"
      @click="removeMargin"
    >Remove margins for all devices</span>
  </div>
</template>

<script>
import _ from 'lodash'
import ControlMixin from '../../mixins/ControlMixin'
import SyncSettingsMixin from '@/components/mixins/SyncSettingsMixin'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  name: 'MarginYCustoms',
  mixins: [ControlMixin, SyncSettingsMixin, ResolutionMixin],

  data () {
    return {
      position: 'top',
      syncReady: false,
      linked: false
    }
  },

  computed: {
    margin: {
      get () {
        return _.get(this.config, [this.resolutioner, this.position], 0)
      },
      set (val) {
        this.$set(this.config, this.resolutioner, {..._.set(this.config[this.resolutioner] || {}, [this.position], val)})

        if (this.linked) {
          this.$set(this.config, this.resolutioner, {
            top: val,
            bottom: val
          })
        }
      }
    }
  },

  methods: {
    removeMargin () {
      this.$set(this.config, 'lg', {
        top: 0,
        bottom: 0
      })

      this.$set(this.config, 'md', {
        top: 0,
        bottom: 0
      })

      this.$set(this.config, 'sm', {
        top: 0,
        bottom: 0
      })
    },
    syncPadding () {
      if (this.syncReady) return

      this.$set(this.config, 'lg', {
        top: this.config[this.resolutioner].top,
        bottom: this.config[this.resolutioner].bottom
      })

      this.$set(this.config, 'md', {
        top: this.config[this.resolutioner].top,
        bottom: this.config[this.resolutioner].bottom
      })

      this.$set(this.config, 'sm', {
        top: this.config[this.resolutioner].top,
        bottom: this.config[this.resolutioner].bottom
      })

      this.syncReady = true
      setTimeout(() => {
        this.syncReady = false
      }, 500)
    }
  }
}
</script>
