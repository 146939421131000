<template>
  <div
    class="position-relative"
    :style="{...padding}"
  >
    <div
      class="image-background"
      :style="{...backgroundImage}"
    />
    <div :class="container">
      <b-row
        no-gutters
        class="featured-items-5"
      >
        <b-col
          :offset="aligment('left') ? 0 : 6"
          :cols="cols(6, 12)"
        >
          <div
            class="featured-text py-5 d-flex align-items-center px-5"
            :style="{...backgroundColor, ...shadow}"
          >
            <template>
              <slot name="elements" />
            </template>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'FeaturedItems5Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.resolution-sm {
  .featured-items-5 {
    .btn {
      display: block !important;
      width: 100% !important;
    }
  }
}

.featured-items-5 {
  .featured-text {
    margin-bottom: 10%;
    margin-top: 10%;
  }
}
</style>
