<template>
  <component
    :is="draggable ? 'draggable' : 'div'"
    v-model="content"
    chosen-class="block-chosen"
    :class="[{'empty-row-drag group': !content.length}, !content.length ? dropAreaClass : '']"
    class="w-100"
    :group="group"
    handle=".move"
    @click.native="addBlock"
  >
    <div
      v-if="!content.length && emptyMessage"
      class="group-hover:block hidden absolute top-1/2 w-full text-center left-0 -translate-y-1/2 bg-white text-[18px] text-gray-500"
      v-text="emptyMessage"
    />
    
    <component
      :is="section.component"
      v-for="(section, index) in content"
      :key="`${contentKey}${section.uid}`"
      :section="section"
      :highliter-options="highliterOptions"
      @delete="() => deleteSection(index)"
      @dublicate="() => dublicateElement(index)"
      @move-to="to => moveTo(index, to)"
      @add-subject="() => addBlock(index)"
      @flow="() => $emit('flow')"
      @favorite="node => favoriteBlock = {section: JSON.parse(JSON.stringify(section)), node}"
    >
      <slot />
    </component>
    <add-to-favorite-modal
      :favorite="favoriteBlock"
      @submit="favorite"
    />
  </component>
</template>

<script>
import draggable from 'vuedraggable'
import {insert} from '@/sections/utils/helpers'
import shortid from 'shortid'
import AddToFavoriteModal from '@/components/builder/components/modals/AddToFavoriteModal.vue'

export default {
  name: 'ContentDropArea',
  components: {AddToFavoriteModal, draggable},
  props: {
    value: {
      type: Array,
      required: true
    },
    emptyMessage: {
      type: String
    },
    contentKey: {
      type: [String, Number],
      default: ''
    },
    draggable: {
      type: Boolean,
      default: true
    },
    group: {
      type: String,
      default: 'rows'
    },
    highliterOptions: {
      type: Object,
      default: () => {}
    },
    dropAreaClass: {
      type: String
    }
  },
  data () {
    return {
      favoriteBlock: {}
    }
  },
  computed: {
    content: {
      get () {
        return this.value || []
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    addBlock (index) {
      this.$store.commit('editor/SET_PAGE', {
        name: 'add-row',
        event: 'click',
        subject: {
          subject: {
            children: this.content
          },
          options: {
            index
          }
        }
      })
    },
    favorite (payload) {
      this.$store.dispatch('favorites/addFavorite', {
        favorite: {
          section: payload.favorite,
          group: 'rows'
        },
        origSection: payload.origSection,
        inGlobal: payload.globally,
        inSharable: payload.sharable,
        node: payload.node
      })
        .finally(() => {
          this.favoriteBlock = {}
        })
    },
    moveTo (index, to) {
      const copySection = JSON.parse(JSON.stringify(this.content[index]))

      if (to === 'up') {
        if (index === 0) return
        this.content.splice(index, 1)
        this.content = insert(this.content, index - 1, copySection)
      } else if (to === 'down') {
        if (index + 1 === this.content.length) return
        this.content.splice(index, 1)
        this.content = insert(this.content, index + 1, copySection)
      }
    },
    dublicateElement (section) {
      this.content = insert(this.content, section, JSON.parse(JSON.stringify({
        ...this.content[section],
        uid: shortid.generate()
      })))
    },
    deleteSection (section) {
      this.content.splice(section, 1)
    }
  }
}
</script>