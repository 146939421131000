export default {
  methods: {
    addhttp (url) {
      if (!/^(?:f|ht)tps?:\/\//.test(url)) {
        url = 'http://' + url
      }
      return url
    },
    goPage (object) {
      const item_type = object.page.id !== 'custom-link' ? 'page' : 'custom_url'
      this.$store.dispatch('pages/goToPage', {
        item_type,
        item_object_id: item_type === 'page' ? object.page.id : null,
        item_url: object.customLink ? this.addhttp(object.customLink) : null,
        open_new_tab: object.open_new_tab
      })
    }
  }
}
