<template>
  <div>
    <div class="hover:-translate-y-5 flex items-center justify-center cursor-pointer transition translate-y-[-10px] h-[310px] bg-white rounded-[4px] shadow-[0_5px_6px_rgba(0,0,0,0.13)] mb-[18px]">
      <div class="uppercase">
        blank
      </div>
    </div>

    <h6 class="text-[#44474A] text-[16px] font-[400] mt-[-6px]">
      Start with a Blank Canvas
    </h6>
  </div>
</template>
<script>
export default {
  name: 'BlankTemplateCard',
  props: {
    template: {
      type: Object,
      required: false
    }
  }
}
</script>