<template>
  <div
    class="pages-list-group-item mx-3"
    @click="onClick"
  >
    <b-row class="align-items-center">
      <b-col class="list-item-part hover:bg-gray-50 transition cursor-pinter">
        <div class="d-flex align-items-center">
          <b-checkbox
            ref="checkbox"
            v-model="valueMutated"
            :value="page.hash"
            class="mr-2"
          />
          {{ page.title }}
        </div>
      </b-col>

      <b-col class="list-item-part">
        <b-input-group>
          <b-input
            :value="targetMutated"
            readonly
          />
          <b-input-group-append>
            <b-btn
              v-b-tooltip.top="tooltipText"
              v-clipboard:copy="targetMutated"
              v-clipboard:success="onCopy"
              variant="primary"
            >
              <i class="fa fa-clipboard" />
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col
        class="list-item-part"
        md="2"
      >
        <b-input-group>
          <div class="d-flex align-items-center">
            <b-checkbox
              v-model="safeModeMutated"
              :value="page.hash"
              class="mr-2"
            />
            Include active links
          </div>
        </b-input-group>
      </b-col>

      <b-col
        class="list-item-part"
        md="3"
      >
        <b-input-group>
          <div class="d-flex align-items-center">
            <b-checkbox
              v-model="modalsModeMutated"
              :value="page.hash"
              class="mr-2"
            />
            Include modals
          </div>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'SharePageItem',
  props: {
    value: {
      type: Array,
      required: true
    },
    safeMode: {
      type: Array,
      required: true
    },
    modalsMode: {
      type: Array,
      required: true
    },
    page: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tooltipText: 'Copy',
      target: this.page.hash
    }
  },

  computed: {
    targetMutated () {
      const params = new URLSearchParams('')

      if (this.safeModeMutated.includes(this.page.hash)) {
        params.append('saveMode', 1)
      }

      if (this.modalsModeMutated.includes(this.page.hash)) {
        params.append('include_modal', 1)
      }

      return `${this.target}${params.toString() ? '?' : ''}${params.toString()}`
    },
    valueMutated: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    safeModeMutated: {
      get () {
        return this.safeMode
      },
      set (val) {
        this.$emit('safeModeChange', val)
      }
    },
    modalsModeMutated: {
      get () {
        return this.modalsMode
      },
      set (val) {
        this.$emit('modalsModeChange', val)
      }
    }
  },

  methods: {
    onClick (e) {
      if (e.target.closest('input') || e.target.closest('button') || e.target.closest('label')) return
      this.$refs.checkbox.$el.querySelector('input').click()
    },
    onCopy () {
      this.tooltipText = 'Copied!'

      setTimeout(() => {
        this.tooltipText = 'Copy'
      }, 800)
    }
  }
}
</script>
