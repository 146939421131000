<template>
  <div>
    <draggable
      :list="step.content"
      handle=".move"
    >
      <div
        v-for="(control, index) in fieldsList"
        :key="index"
      >
        <form-controls-list-item-v2
          :control="control"
          :index="index"
          :active="active"
          :config="step"
          :section="section"
          @open-settings="active === index ? active = null : active = index"
          @deleted="deleteControl(control)"
        />
      </div>
    </draggable>
    
    <add-new-control 
      :form-id="1"
      :items-count="fieldsList.length"
      @added-item="item => step.content.push(item)"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'
import FormControlsListItemV2 from '@/components/editor/components/form/FormControlsListItemV2.vue'
import AddNewControl from '@/components/editor/components/form/AddNewControl.vue'
export default {
  name: 'FormFieldV3',
  components: {
    AddNewControl,
    FormControlsListItemV2,
    draggable
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      active: null
    }
  },
  computed: {
    step () {
      return this.section.options.content.orderForm.steps[this.currentStep]
    },
    fieldsList () {
      const content = _.chain(this.step.content)
        .flatten()
        .filter({fieldSettings: true})
        .value()
      const flex = _.chain(this.step.content)
        .filter({component: 'ElFlexWrapperV2'})
        .map('options.content.cols')
        .flatten()
        .map('elements')
        .flatten()
        .filter({fieldSettings: true})
        .value()
      
      return [
        ...content,
        ...flex
      ]
    }
  },
  methods: {
    deleteControl (val) {
      let control = _.findIndex(this.step.content, ['uid', val.uid])

      if (control === -1) {
        const flexList = _.chain(this.step.content).filter({component: 'ElFlexWrapperV2'})
          .filter({component: 'ElFlexWrapperV2'})
          .flatten()
          .value()

        for (let i = 0; i < flexList.length; i++) {
          const flex = flexList[i]
          const cols = flex.options.content.cols

          for (let j = 0; j < cols.length; j++) {
            const col = cols[j]

            control = _.chain(col.elements)
              .findIndex({uid: val.uid})
              .value()

            if (control === -1) continue
            else return col.elements.splice(control, 1)
          }
        }
      }
      else this.step.content.splice(control, 1)
    }
  }
}
</script>