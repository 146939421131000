<template>
  <b-form-group label="Effect">
    <v-select
      v-model="selectedEffectMutation"
      :options="filteredOptions"
      :searchable="false"
      placeholder="Add motion effect"
    />
  </b-form-group>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'MotionsEffectsSelect',
  props: {
    value: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedEffect: null
    }
  },
  computed: {
    selectedEffectMutation: {
      get () {
        return this.selectedEffect
      },
      set (val) {
        const clonedVal = _.cloneDeep(val)
        this.selectedEffect = null
        this.$emit('input', {
          ...this.value,
          [clonedVal.effect]: {
            values: clonedVal.data,
            effect: clonedVal.effect
          }
        })
      }
    },
    filteredOptions () {
      return this.options.filter(effect => !this.value[effect.effect])
    }
  }
}
</script>