import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Bullet List'
export const group = 'Typography'

export const defaultOptions = {
  'selectedSubjectIndex': 0,
  'content': {
    'list': {
      'type': 'list-mod-v2',
      'display': true,
      'icon': 'fa fa-check-circle',
      'lottie': {
        'loop': true,
        'autoplay': true
      },
      'items': [
        {
          'label': 'Item 1',
          'subject': {
            'uid': 'Aix0I-8NC',
            'display': true,
            'name': 'Paragraph',
            'css': [
              [
                '.--cea967j--KEEWE4kGXZ-text div { text-align: left; font-size: 16px; }'
              ],
              [],
              []
            ],
            'sid': 'cea967j--KEEWE4kGXZ',
            'sids': [
              'cea967j--KEEWE4kGXZ'
            ],
            'hideFromSections': false,
            'component': 'ElParagraphV2',
            'group': 'Text',
            'layout': null,
            'options': {
              'content': {
                'description': {
                  'type': 'tiptap-mod-v2',
                  'display': true,
                  'resolutionStyle': {
                    'sm': {},
                    'md': {},
                    'lg': {}
                  },
                  'text': '<p>Cras faucibus purus tincidunt tincidunt mollis.</p>',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              },
              'customize': null
            },
            'editor': 'project',
            'temp_id': 'Wolqva4u8v'
          }
        },
        {
          'label': 'Item 2',
          'subject': {
            'uid': 'Aix0I-8NC',
            'display': true,
            'name': 'Paragraph',
            'css': [
              [
                '.--cea967j--KEEWE4kGXZ-text div { text-align: left; font-size: 16px; }'
              ],
              [],
              []
            ],
            'sid': 'cea967j--KEEWE4kGXZ',
            'sids': [
              'cea967j--KEEWE4kGXZ'
            ],
            'hideFromSections': false,
            'component': 'ElParagraphV2',
            'group': 'Text',
            'layout': null,
            'options': {
              'content': {
                'description': {
                  'type': 'tiptap-mod-v2',
                  'display': true,
                  'resolutionStyle': {
                    'sm': {},
                    'md': {},
                    'lg': {}
                  },
                  'text': '<p>Cras faucibus purus tincidunt tincidunt mollis.</p>',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              },
              'customize': null
            },
            'editor': 'project',
            'temp_id': 'Wolqva4u8v'
          }
        },
        {
          'label': 'Item 3',
          'subject': {
            'uid': 'Aix0I-8NC',
            'display': true,
            'name': 'Paragraph',
            'css': [
              [
                '.--cea967j--KEEWE4kGXZ-text div { text-align: left; font-size: 16px; }'
              ],
              [],
              []
            ],
            'sid': 'cea967j--KEEWE4kGXZ',
            'sids': [
              'cea967j--KEEWE4kGXZ'
            ],
            'hideFromSections': false,
            'component': 'ElParagraphV2',
            'group': 'Text',
            'layout': null,
            'options': {
              'content': {
                'description': {
                  'type': 'tiptap-mod-v2',
                  'display': true,
                  'resolutionStyle': {
                    'sm': {},
                    'md': {},
                    'lg': {}
                  },
                  'text': '<p>Cras faucibus purus tincidunt tincidunt mollis.</p>',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              },
              'customize': null
            },
            'editor': 'project',
            'temp_id': 'Wolqva4u8v'
          }
        },
        {
          'label': 'Item 4',
          'subject': {
            'uid': 'Aix0I-8NC',
            'display': true,
            'name': 'Paragraph',
            'css': [
              [
                '.--cea967j--KEEWE4kGXZ-text div { text-align: left; font-size: 16px; }'
              ],
              [],
              []
            ],
            'sid': 'cea967j--KEEWE4kGXZ',
            'sids': [
              'cea967j--KEEWE4kGXZ'
            ],
            'hideFromSections': false,
            'component': 'ElParagraphV2',
            'group': 'Text',
            'layout': null,
            'options': {
              'content': {
                'description': {
                  'type': 'tiptap-mod-v2',
                  'display': true,
                  'resolutionStyle': {
                    'sm': {},
                    'md': {},
                    'lg': {}
                  },
                  'text': '<p>Cras faucibus purus tincidunt tincidunt mollis.</p>',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              },
              'customize': null
            },
            'editor': 'project',
            'temp_id': 'Wolqva4u8v'
          }
        }
      ]
    }
  }
}

export const icon = `
<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.9777 14.625H6.27266C5.70781 14.625 5.25 14.2333 5.25 13.75C5.25 13.2667 5.70781 12.875 6.27266 12.875H19.9773C20.5422 12.875 21 13.2667 21 13.75C21 14.2333 20.5425 14.625 19.9777 14.625Z" fill="black"/>
<path d="M19.9777 8.5H6.27266C5.70781 8.5 5.25 8.10829 5.25 7.625C5.25 7.14171 5.70781 6.75 6.27266 6.75H19.9773C20.5422 6.75 21 7.14171 21 7.625C21.0003 8.10829 20.5425 8.5 19.9777 8.5Z" fill="black"/>
<path d="M19.9777 2.375H6.27266C5.70781 2.375 5.25 1.98329 5.25 1.5C5.25 1.01671 5.70781 0.625 6.27266 0.625H19.9773C20.5422 0.625 21 1.01671 21 1.5C21 1.98329 20.5425 2.375 19.9777 2.375Z" fill="black"/>
<circle cx="1.3125" cy="1.0625" r="1.3125" fill="black"/>
<circle cx="1.3125" cy="8.0625" r="1.3125" fill="black"/>
<circle cx="1.3125" cy="14.1875" r="1.3125" fill="black"/>
</svg>
`
export default class ElList extends SectionProvider {
  constructor (options = {}) {
    super('ElListV2', options)

    this.name = name
    this.group = group
    this.css = [
      [
        '.--lh4l0deb--cPaVgtr-oT--list { padding: 20px; }',
        '.--lh4l0deb--cPaVgtr-oT--list .list { row-gap: 8px; }'
      ],
      [],
      []
    ]
    this.sids = ['lh4l0deb--cPaVgtr-oT', 'cea967j--KEEWE4kGXZ']
    this.sid = 'lh4l0deb--cPaVgtr-oT'
    this.defaultOptions = defaultOptions
  }
}

