<template>
  <div class="form-mod">
    <!--    <template v-else>-->
    <!--      <h6 class="mb-0">Connected Autoresponder</h6>-->
    <!--      <small class="d-block text-muted mb-3">Change your autoresponder account-->
    <!--        <b-link @click="connectDialog = true">here</b-link>-->
    <!--      </small>-->

    <!--      <div class="d-flex align-items-center shadow-sm border rounded p-2 mb-4">-->
    <!--        <span class="bg-success rounded-circle mr-3" style="width: 8px;height: 8px;"></span>-->
    <!--        Conntected-->
    <!--      </div>-->
    <!--          <b-form-group v-if="hasAPI.name !== 'Ontraport'" label="list">-->
    <!--            <v-select left label="name" v-model="config.selectedList"-->
    <!--                      :reduce="option => option.id || option.campaignId || option.list_id"-->
    <!--                      class="custom-select" :options="list" :searchable="false"></v-select>-->
    <!--          </b-form-group>-->

    <!--      <b-form-group v-else label="Tags">-->
    <!--            <v-select left-->
    <!--                      label="tag_name"-->
    <!--                      v-model="selectedTags"-->
    <!--                      taggable-->
    <!--                      multiple-->
    <!--                      placeholder="Select or create tags"-->
    <!--                      class="custom-select v-multiple"-->
    <!--                      :loading="tagsLoadig"-->
    <!--                      :options="filteredTags">-->
    <!--              <b-spinner slot="spinner" class="mr-3" v-show="tagsLoadig" variant="primary" small/>-->
    <!--            </v-select>-->
    <!--      </b-form-group>-->

    <!--      <div v-if="config.selectedList || hasAPI.name == 'Ontraport'">-->
    <!--        <div class="form-mod-inner mb-3">-->
    <!--          <div class="form-mod-inner-index">-->
    <!--            <div class="d-flex align-items-center justify-content-between pt-3 mb-2">-->
    <!--              <label>Form Fields</label>-->

    <!--              <b-link @click="formSettings = true">-->
    <!--                <icon size="16px" icon="form-settings.svg"></icon>-->
    <!--              </b-link>-->
    <!--            </div>-->

    <!--            <draggable v-model="config.fields" handle=".move" class="list-group mb-3">-->
    <!--              <b-list-group-item v-for="(field, index) in config.fields" :key="field.name"-->
    <!--                                 class="d-flex align-items-center text-capitalize">-->
    <!--                <icon class="move mr-3" icon="move-gray.svg"/>-->
    <!--                {{ field.label || field.name }}-->

    <!--                <icon class="cursor-pinter ml-auto mr-3" icon="edit.svg" @click.native="newFormFieldModal = index"/>-->
    <!--                <icon v-if="field.display" class="cursor-pinter" icon="eye-3.svg"-->
    <!--                      @click.native="field.display = false"/>-->
    <!--                <icon v-else class="cursor-pinte" icon="eye-disable.svg" @click.native="field.display = true"/>-->
    <!--                <icon class="cursor-pinter ml-2" v-if="field.name !== 'email'" icon="trash.svg"-->
    <!--                      @click.native="removeFormField(index)"/>-->
    <!--              </b-list-group-item>-->
    <!--            </draggable>-->

    <!--            <div-->
    <!--                class="d-flex align-items-center justify-content-end cursor-pinter font-weight-sami-bold text-primary w-100"-->
    <!--                @click="newFormFieldModal = true">-->
    <!--              <icon class="mt-1 mr-2" icon="plus.svg"></icon>-->
    <!--              Add Form Field-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->

    <!--        <b-form-group label="Button position">-->
    <!--          <v-select left label="name" v-model="config.btnPosition" :reduce="option => option.position"-->
    <!--                    class="custom-select" :options="btnPositions" :searchable="false"></v-select>-->
    <!--        </b-form-group>-->
    <!--      </div>-->
    <!--    </template>-->

    <!--    <b-modal v-model="getNewFormFieldModal" size="lg" hide-footer hide-header>-->
    <!--      <icon class="cursor-pinter ml-3" icon="close.svg" @click.native="newFormFieldModal = false"></icon>-->

    <!--      <h4 class="h2 font-weight-bold text-center my-4">Add A Form Field</h4>-->

    <!--      <b-form @submit.prevent="addFormField">-->
    <!--        <div class="p-5">-->
    <!--          <b-row class="mb-4">-->
    <!--            <b-col cols="6">-->
    <!--              <b-form-group v-if="typeof newFormFieldModal !== 'number'" label="Form Type">-->
    <!--                <v-select left v-model="newForm.type" :reduce="option => option.key" class="custom-select"-->
    <!--                          :options="typeOptions" :searchable="false"></v-select>-->
    <!--              </b-form-group>-->
    <!--              <b-form-group v-else label="Form Type">-->
    <!--                <v-select left v-model="config.fields[newFormFieldModal].type" :reduce="option => option.key"-->
    <!--                          class="custom-select" :options="typeOptions" :searchable="false"></v-select>-->
    <!--              </b-form-group>-->
    <!--            </b-col>-->

    <!--            <b-col cols="6">-->
    <!--              <b-form-group label="Name">-->
    <!--                <b-input v-if="typeof newFormFieldModal !== 'number'" v-model="newForm.name" required></b-input>-->
    <!--                <b-input v-else readonly :value="config.fields[newFormFieldModal].name" required></b-input>-->
    <!--              </b-form-group>-->

    <!--              <small v-if="hasAPI.name === 'GetResponse'" class="d-block text-danger mb-4">Due to technical-->
    <!--                peculiarities of GetResponse, the field names cannot contain: name, email, twitter, facebook, buzz,-->
    <!--                myspace, linkedin, digg, googleplus, pinterest, responder, campaign, change.</small>-->
    <!--            </b-col>-->

    <!--            <b-col cols="6">-->
    <!--              <b-form-group label="Form Input Placeholder">-->
    <!--                <b-input v-if="typeof newFormFieldModal !== 'number'" v-model="newForm.placeholder"></b-input>-->
    <!--                <b-input v-else v-model="config.fields[newFormFieldModal].placeholder"></b-input>-->
    <!--              </b-form-group>-->
    <!--            </b-col>-->

    <!--            <b-col cols="6">-->
    <!--              <b-form-group label="Form Input Label">-->
    <!--                <b-input v-if="typeof newFormFieldModal !== 'number'" v-model="newForm.label"></b-input>-->
    <!--                <b-input v-else v-model="config.fields[newFormFieldModal].label"></b-input>-->
    <!--              </b-form-group>-->
    <!--            </b-col>-->

    <!--            <b-col cols="6">-->
    <!--              <b-form-group class="pt-4">-->
    <!--                <b-checkbox v-if="typeof newFormFieldModal !== 'number'" v-model="newForm.required">Required Field-->
    <!--                </b-checkbox>-->
    <!--                <b-checkbox v-else v-model="config.fields[newFormFieldModal].required">Required Field</b-checkbox>-->
    <!--              </b-form-group>-->
    <!--            </b-col>-->

    <!--            <b-col cols="6">-->
    <!--              <b-form-group v-if="typeof newFormFieldModal !== 'number'" label="Control Type">-->
    <!--                <v-select left v-model="newForm.fieldType" :reduce="option => option.key" class="custom-select"-->
    <!--                          :options="controlTypeOptions" :searchable="false"></v-select>-->
    <!--              </b-form-group>-->
    <!--              <b-form-group v-else label="Form Type">-->
    <!--                <v-select left v-model="fieldType" :reduce="option => option.key"-->
    <!--                          class="custom-select" :options="controlTypeOptions" :searchable="false"></v-select>-->
    <!--              </b-form-group>-->
    <!--            </b-col>-->

    <!--            <b-col cols="6">-->
    <!--              <b-row>-->
    <!--                <b-col>-->
    <!--                  <b-form-group label="Field on large screen">-->
    <!--                    <grid-control v-if="typeof newFormFieldModal !== 'number'" target="form-grid-lg"-->
    <!--                                  v-model="newForm.cols.lg"></grid-control>-->
    <!--                    <grid-control v-else v-model="config.fields[newFormFieldModal].cols.lg"-->
    <!--                                  target="form-grid-lg"></grid-control>-->
    <!--                  </b-form-group>-->
    <!--                </b-col>-->

    <!--                <b-col>-->
    <!--                  <b-form-group label="Field on small screen">-->
    <!--                    <grid-control v-if="typeof newFormFieldModal !== 'number'" target="form-grid-sm"-->
    <!--                                  v-model="newForm.cols.sm"></grid-control>-->
    <!--                    <grid-control v-else v-model="config.fields[newFormFieldModal].cols.sm"-->
    <!--                                  target="form-grid-sm"></grid-control>-->
    <!--                  </b-form-group>-->
    <!--                </b-col>-->
    <!--              </b-row>-->
    <!--            </b-col>-->
    <!--          </b-row>-->

    <!--          <div v-if="typeof newFormFieldModal !== 'number'" class="d-flex justify-content-center">-->
    <!--            <b-btn variant="primary" type="submit">Add Form Field</b-btn>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </b-form>-->
    <!--    </b-modal>-->

    <!--    <b-modal v-model="formSettings" size="lg" hide-footer hide-header>-->
    <!--      <icon class="cursor-pinter ml-3" icon="close.svg" @click.native="formSettings = false"></icon>-->

    <!--      <h4 class="h2 font-weight-bold text-center my-4">Form Settings</h4>-->

    <!--      <b-form @submit.prevent="saveFormSettings">-->
    <!--        <div class="p-5">-->
    <!--          <h5 class="text-muted mb-4">Confirmation Page</h5>-->

    <!--          <b-row>-->
    <!--            <b-col md="4">-->
    <!--              <b-form-group>-->
    <!--                <b-form-radio class="mb-2" value="inner_link" v-model="config.successMessageType" name="message_type">-->
    <!--                  Custom-->
    <!--                  Page from Site-->
    <!--                </b-form-radio>-->

    <!--                <v-select left label="name" v-model="config.successMessageInnerLink" :reduce="option => option.key"-->
    <!--                          class="custom-select" :options="pages" :searchable="false"></v-select>-->
    <!--              </b-form-group>-->
    <!--            </b-col>-->

    <!--            <b-col md="8">-->
    <!--              <b-form-group>-->
    <!--                <b-form-radio class="mb-2" value="link" v-model="config.successMessageType" name="message_type">Custom-->
    <!--                  URL-->
    <!--                </b-form-radio>-->

    <!--                <b-input v-model="config.successMessageLink"></b-input>-->
    <!--              </b-form-group>-->
    <!--            </b-col>-->

    <!--            <b-col cols="12">-->
    <!--              <b-form-group>-->
    <!--                <b-form-radio class="mb-2" value="text" v-model="config.successMessageType" name="message_type">-->
    <!--                  (Default) Success Message-->
    <!--                </b-form-radio>-->

    <!--                <form-settings-editor :config="config.successMessage" v-model="config.successMessage.text"/>-->
    <!--                <label class="text-muted">This wil display inside the section block once form has been-->
    <!--                  submitted </label>-->
    <!--              </b-form-group>-->
    <!--            </b-col>-->
    <!--          </b-row>-->

    <!--          <div class="d-flex justify-content-center">-->
    <!--            <b-btn variant="primary" type="submit">Save Form Settings</b-btn>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </b-form>-->
    <!--    </b-modal>-->

    <div
      v-if="!hasAPI"
      class="mb-4"
    >
      <div
        class="d-flex align-items-center shadow-sm border rounded p-2"
        style="font-size: 14px;"
      >
        <div class="mr-2">
          No Account Connected
        </div>
        <b-link @click="connectDialog = true">
          Connect Here
        </b-link>
      </div>
    </div>

    <b-list-group
      v-if="relatedControls.length"
      handle=".move"
      class="mb-3"
    >
      <b-list-group-item
        v-for="(field, index) in relatedControls"
        :key="index"
        class="d-flex align-items-center text-capitalize"
      >
        {{ field.section.name }}
        <b-link class="ml-auto">
          <i class="fa fa-cog" />
        </b-link>
      </b-list-group-item>
    </b-list-group>

    <b-modal
      v-model="connectDialog"
      size="lg"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <icon
        class="cursor-pinter ml-3"
        icon="close.svg"
        @click.native="connectDialog = false"
      />

      <h4 class="h2 font-weight-bold text-center my-4">
        Connect Autoresponder
      </h4>

      <b-form>
        <div class="p-5">
          <integrations @integrated="integrateMailService" />
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import {mapGetters, mapState} from 'vuex'
import Integrations from '../../../views/dashboard/Integrations'
import _ from 'lodash'
import TextMixins from '../../mixins/TextMixins'
import FormCustomFieldsBuilder from '@builder/components/builder/utils/auto-responders/mixins/FormCustomFieldsBuilder'

const newForm = {
  display: true,
  type: 'text',
  name: null,
  placeholder: null,
  required: false,
  label: null,
  fieldType: null,
  cols: {
    lg: 12,
    sm: 12
  }
}

export default {
  name: 'AutoresponderMod',

  components: {Integrations},

  mixins: [ModMixin, TextMixins, FormCustomFieldsBuilder],

  data () {
    return {
      tagsLoadig: true,
      tags: [],
      connectDialog: false,
      list: [],
      newFormFieldModal: false,
      newForm: newForm,
      formSettings: false,
      typeOptions: [
        {
          key: 'text',
          label: 'Text'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'tel',
          label: 'Tel'
        },
        {
          key: 'color',
          label: 'Color'
        },
        {
          key: 'date',
          label: 'Date'
        },
        {
          key: 'number',
          label: 'Number'
        }
      ],
      controlTypeOptions: [
        {
          key: null,
          label: 'Text field'
        },
        {
          key: 'textarea',
          label: 'Textarea field'
        }
      ]
    }
  },

  computed: {
    filteredTags () {
      let arr = []
      this.tags.forEach(tag => {
        if (!this.selectedTags.filter(selectedTag => selectedTag === tag).length) {
          arr.push(tag)
        }
      })
      return arr
    },

    fieldType: {
      get () {
        return this.config.fields[this.newFormFieldModal].fieldType || null
      },
      set (val) {
        this.$set(this.config.fields[this.newFormFieldModal], 'fieldType', val)
      }
    },

    btnPositions () {
      const positions = []

      this.config.fields.forEach(field => {
        positions.push({
          name: `Prepend ${_.kebabCase(field.name)}`,
          position: `prepend-${field.name}`
        })

        positions.push({
          name: `Append ${_.kebabCase(field.name)}`,
          position: `append-${field.name}`
        })
      })

      positions.unshift(
        {
          name: 'Top',
          position: 'top'
        },
        {
          name: 'Submit',
          position: 'submit'
        }
      )

      return positions
    },
    ...mapGetters('apiIntegration', {
      hasAPI: 'hasMailAPIService'
    }),
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    ...mapState('forms', {
      allFormControls: state => state.allFormControls
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    getNewFormFieldModal: {
      get () {
        if (this.newFormFieldModal === 0) {
          return true
        }

        return !!this.newFormFieldModal
      },
      set (val) {
        this.newFormFieldModal = val
      }
    },
    selectedTags: {
      get () {
        return this.config.selectedTags || []
      },
      set (val) {
        const containNew = val.filter(tag => !tag.hasOwnProperty('tag_id'))
        if (containNew.length) {
          VEvent.fire('loader', true)
          axios.post(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/tags`, {
            label: containNew[0].tag_name
          })
            .then(({data}) => {
              if (data.data) {
                this.config.selectedTags.push(data.data)
                this.tags.push(data.data)
              }
            })
            .finally(() => {
              VEvent.fire('loader', false)
            })
        } else {
          this.config.selectedTags = val
        }
      }
    },
    relatedControls () {
      return this.allFormControls.filter(control => control.section.options.content.input.relatedUID === this.config.buttonUID)
    }
  },

  mounted () {
    this.config.succesMessage = {}

    if (this.hasAPI) {
      this.config.action = this.hasAPI.name
    }
  },

  created () {
    this.fetchList()
  },

  methods: {
    integrateMailService (integrationMailServiceData) {
      this.$set(this.config, 'autoresponder', {
        id: integrationMailServiceData.id,
        name: integrationMailServiceData.name
      })
    },
    saveFormSettings () {
      VEvent.fire('loader', true)

      this.$store.dispatch('projects/save')
        .then(() => {
          this.formSettings = false
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    },
    async removeFormField (index) {
      const method = 'Delete' + String(this.config.action)
      await this[method](index)
    },
    async addFormField () {
      const createFormField = {
        ...this.newForm,
        list_id: this.config.selectedList
      }

      await this[this.config.action](createFormField)
      // .then(() => {
      // axios.post(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/custom-fields`,
      //     createFormField)
      //     .then(({data}) => {
      //       if (data.data) {
      //         this.config.fields.push({...createFormField, name: Object.keys(data.data)[0]})
      //       } else {
      //         this.config.fields.push(createFormField)
      //       }
      //
      //
      //       this.newForm = newForm
      //
      //       this.newFormFieldModal = false
      //     })
      // })
    },
    fetchList () {
      if (this.hasAPI && this.hasAPI.name !== 'Ontraport') {
        this.$set(this.config, 'action', this.hasAPI.name)

        axios.get(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/lists`)
          .then(({data}) => {
            this.list = data.data
          })
      } else if (this.hasAPI && this.hasAPI.name === 'Ontraport') {
        this.tagsLoadig = true
        if (!this.config.selectedTags) this.$set(this.config, 'selectedTags', [])
        axios.get(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/tags`)
          .then(({data}) => {
            this.tags = data.data
          })
          .finally(() => this.tagsLoadig = false)
      }
    }
  }
}
</script>
