<template>
  <div class="bg-white mt-5 p-4 rounded">
    <most-popular class="mb-4" />
    <side-search class="mb-4" />
    <side-categoreis />
    <hr class="my-4 -mx-6">
    <latest-posts />
    <sidebar-ad />
  </div>
</template>

<script>
import MostPopular from './MostPopular'
import SideSearch from './SideSearch'
import SideCategoreis from './SideCategoreis'
import LatestPosts from './LatestPosts'
import SidebarAd from './SidebarAd'
export default {
  name: 'SidebarWrapper',
  components: {SidebarAd, LatestPosts, SideCategoreis, SideSearch, MostPopular}
}
</script>

<style scoped>

</style>
