<template>
  <b-form-group :label="label">
    <v-select
      v-model="valueMutation"
      :options="fontSizes()"
      class="custom-select"
    >
      <template #selected-option="{label}">
        {{ label }}px
      </template>

      <template #option="{label}">
        {{ label }}px
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import ResolutionMixin from '../../mixins/ResolutionMixin'

export default {
  name: 'FontSizeControlV2',

  mixins: [ResolutionMixin],

  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      default: 'Font size'
    }
  },

  computed: {
    valueMutation: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  methods: {
    fontSizes () {
      const arr = Array(100)
        .fill({})
        .map((_, i) => i+1)
      return arr.slice(10)
    }
  }
}
</script>
