import {mapState} from 'vuex'
import resolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  mixins: [resolutionMixin],
  props: {
    config: {
      type: Object,
      required: true
    },
    hide: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('editor', {
      resolution: state => state.resolution,
      globalStyles: state => state.globalStyles
    })
  }
}
