import SectionProvider from '@builder/sections/SectionProvider'
import Navbar from './Navbar'

export const name = 'Hero_005'
export const group = 'hero'
export const defaultOptions = {
  'content': {
    'items': {
      'type': null,
      'children': [
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'ZnsZDXlcK',
                      'display': true,
                      'name': 'Headline',
                      'sid': 'lrg8hk4l--Teo3oWG8nU',
                      'hideFromSections': false,
                      'component': 'ElHeadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h3',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>Boost Your Marketing Business with This Course</p>',
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            }
                          }
                        }
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': 'kd-nMkQ8z',
                      'display': true,
                      'name': 'Headline',
                      'sid': 'lrg8hk4l--MAd_HwmgJL',
                      'hideFromSections': false,
                      'component': 'ElHeadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h3',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>Ut laoreet nec augue a placerat. Etiam vel sodales neque. Donec ullamcorper iaculis ligula, eget condimentum turpis.</p>',
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            }
                          }
                        }
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': 'ESGKlX2ya',
                      'display': true,
                      'name': 'Image',
                      'sid': 'lrg8hk4l--tTGXTPDJVk',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': '0XIV5cOJ9',
                      'display': true,
                      'name': 'Image',
                      'sid': 'rg8hk4l--JMihHOTTo',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project',
                      '_uid': 'rg8hk4l--JMihHOTTo'
                    },
                    {
                      'uid': 'wlrJUg_wq',
                      'display': true,
                      'name': 'Image',
                      'sid': 'rg8hk4l--97g1wcMfZ',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project',
                      '_uid': 'rg8hk4l--97g1wcMfZ'
                    },
                    {
                      'uid': '7dJn6NQf0',
                      'display': true,
                      'name': 'Paragraph',
                      'sid': 'lrg8hk4l--WdwpFZrHf',
                      'hideFromSections': false,
                      'component': 'ElParagraphV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>Join over 1000+ students now!</p>',
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            }
                          }
                        },
                        'customize': null
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': '12T4J44vue',
                  'sid': 'lrg8hk4l--KRoKoQ6LLi',
                  'highlight': false,
                  '_uid': '2xejl2mjON',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                },
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'display': true,
                      'name': 'Flex Wrapper',
                      'hideFromSections': false,
                      'component': 'ElFlexWrapperV2',
                      'group': '',
                      'layout': null,
                      'options': {
                        'content': {
                          'display': true,
                          'cols': [
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'uid': '3i5yl_Xb1',
                                  'display': true,
                                  'name': 'Headline',
                                  'sid': 'rg8hk4l--rZm6ei8qC',
                                  'hideFromSections': false,
                                  'component': 'ElHeadlineV2',
                                  'group': 'Text',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'description': {
                                        'type': 'tiptap-mod-v2',
                                        'display': true,
                                        'tag': 'h2',
                                        'resolutionStyle': {
                                          'sm': {},
                                          'md': {},
                                          'lg': {}
                                        },
                                        'text': '<p>Register Now!</p>',
                                        'animations': {
                                          'animation': null,
                                          'once': true,
                                          'offset': 200,
                                          'delay': 50
                                        }
                                      }
                                    }
                                  },
                                  'editor': 'project',
                                  '_uid': 'rg8hk4l--rZm6ei8qC'
                                },
                                {
                                  'uid': 'hwLa-rWQZ',
                                  'display': true,
                                  'name': 'Form',
                                  'sid': 'lrg8hk4l--2B0NbKZzMa',
                                  'hideFromSections': false,
                                  'component': 'ElFormV2',
                                  'group': 'Form',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'customize': null,
                                    'content': {
                                      'form': {
                                        'display': true,
                                        'type': 'form-mod-v2',
                                        'controls': [
                                          {
                                            'label': null,
                                            'placeholder': 'First name',
                                            'field_name': 'First Name',
                                            'field_type': 'text',
                                            'values': null,
                                            'validators': '[]',
                                            'is_required': false,
                                            'columns': {
                                              'lg': 12,
                                              'md': 12,
                                              'sm': 12
                                            },
                                            'order': 0,
                                            'styles': 'el-2-3yHVC3Ll',
                                            'form_nane': 'New Form',
                                            'mapping': []
                                          },
                                          {
                                            'label': null,
                                            'placeholder': 'Last name',
                                            'field_name': 'Last Name',
                                            'field_type': 'text',
                                            'values': null,
                                            'validators': '[]',
                                            'is_required': false,
                                            'columns': {
                                              'lg': 12,
                                              'md': 12,
                                              'sm': 12
                                            },
                                            'order': 1,
                                            'styles': 'el-jlYjYt-Xy',
                                            'form_nane': 'New Form',
                                            'mapping': []
                                          },
                                          {
                                            'label': null,
                                            'placeholder': 'Email address',
                                            'field_name': 'Email',
                                            'field_type': 'email',
                                            'values': null,
                                            'validators': '[]',
                                            'is_required': true,
                                            'columns': {
                                              'lg': 12,
                                              'md': 12,
                                              'sm': 12
                                            },
                                            'order': 2,
                                            'styles': 'el-yfnQCqqCP',
                                            'form_nane': 'New Form',
                                            'mapping': []
                                          }
                                        ],
                                        'subject': {
                                          'uid': 'RSfMebaiz8',
                                          'display': true,
                                          'name': 'Button',
                                          'css': null,
                                          'sid': 'lrg8hk4l--LYP9oznp4m',
                                          'sids': null,
                                          'hideFromSections': false,
                                          'component': 'ElButtonV2',
                                          'group': 'Media',
                                          'layout': null,
                                          'options': {
                                            'content': {
                                              'button': {
                                                'pressetID': 0,
                                                'display': true,
                                                'type': 'button-mod-v2',
                                                'text': 'Sign Up Now',
                                                'buttonType': 'button',
                                                'icon': {
                                                  'lottie': {
                                                    'jsonPath': '',
                                                    'loop': true,
                                                    'autoplay': true
                                                  }
                                                },
                                                'hoverAnimation': 'hover-float',
                                                'animations': {
                                                  'animation': null,
                                                  'once': true,
                                                  'offset': 200,
                                                  'delay': 50
                                                }
                                              }
                                            },
                                            'customize': null
                                          },
                                          'temp_id': 'GJ3MzsHvO9'
                                        },
                                        'gdpr': {
                                          'enabled': false,
                                          'text': 'I consent to the processing of data.'
                                        },
                                        'form': {
                                          'name': 'New Form',
                                          'actions': [],
                                          'api_list_or_tag': {},
                                          'is_active': true,
                                          'agree_subscribe': false,
                                          'project_id': 36402,
                                          'created_at': '2023-07-27T03:52:39.000000Z',
                                          'updated_at': '2023-08-31T03:56:39.000000Z',
                                          'fields': [
                                            {
                                              'label': null,
                                              'placeholder': 'First name',
                                              'field_name': 'First Name',
                                              'field_type': 'text',
                                              'values': null,
                                              'validators': '[]',
                                              'is_required': false,
                                              'columns': '{"lg":12,"md":12,"sm":12}',
                                              'order': 0,
                                              'styles': 'el-2-3yHVC3Ll'
                                            },
                                            {
                                              'label': null,
                                              'placeholder': 'Last name',
                                              'field_name': 'Last Name',
                                              'field_type': 'text',
                                              'values': null,
                                              'validators': '[]',
                                              'is_required': false,
                                              'columns': '{"lg":12,"md":12,"sm":12}',
                                              'order': 1,
                                              'styles': 'el-jlYjYt-Xy'
                                            },
                                            {
                                              'label': null,
                                              'placeholder': 'Email address',
                                              'field_name': 'Email',
                                              'field_type': 'email',
                                              'values': null,
                                              'validators': '[]',
                                              'is_required': true,
                                              'columns': '{"lg":12,"md":12,"sm":12}',
                                              'order': 2,
                                              'styles': 'el-yfnQCqqCP'
                                            }
                                          ],
                                          'mappedFields': {}
                                        }
                                      }
                                    }
                                  },
                                  'editor': 'project'
                                },
                                {
                                  'uid': 'JjvmH1saB',
                                  'display': true,
                                  'name': 'Paragraph',
                                  'sid': 'lrg8hk4l--kwbsNHgjc0',
                                  'hideFromSections': false,
                                  'component': 'ElParagraphV2',
                                  'group': 'Text',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'description': {
                                        'type': 'tiptap-mod-v2',
                                        'display': true,
                                        'resolutionStyle': {
                                          'sm': {},
                                          'md': {},
                                          'lg': {}
                                        },
                                        'text': "<p>We'll never share your details or spam you.</p>",
                                        'animations': {
                                          'animation': null,
                                          'once': true,
                                          'offset': 200,
                                          'delay': 50
                                        }
                                      }
                                    },
                                    'customize': null
                                  },
                                  'editor': 'project'
                                }
                              ],
                              'sortIndex': {
                                'lg': 0,
                                'md': 0,
                                'sm': 0
                              },
                              'uid': 'RHy0AzdIIE',
                              'sid': 'lrg8hk4l--bVOAwkaxy_',
                              '_uid': 'aDfWLsLaJ',
                              'highlight': false,
                              'animations': {
                                'animation': null,
                                'once': true,
                                'offset': 200,
                                'delay': 50
                              }
                            }
                          ],
                          'animations': {
                            'animation': null,
                            'once': true,
                            'offset': 200,
                            'delay': 50
                          }
                        }
                      },
                      'uid': 'zVJb1eQaE',
                      'sid': 'lrg8hk4l--qoMXRmwr_s',
                      'temp_id': 'j0pbRmZok'
                    }
                  ],
                  'sortIndex': {
                    'lg': 1,
                    'md': 1,
                    'sm': 1
                  },
                  'uid': '80lGtgoxtO',
                  'sid': 'lrg8hk4l--2Tg7vFI_QH',
                  'highlight': false,
                  '_uid': 'oRoPnjXwUI',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': '1w2ai39rQ',
          'sid': 'lrg8hk4l--0ErzzSBh76',
          'temp_id': 'SpR-HP7X_'
        }
      ]
    },
    'lottie': {}
  },
  'backgroundImage': {
    'src': ''
  },
  'backgroundVideo': {
    'enabled': false,
    'url': '',
    'loop': true,
    'ratio': 1
  }
}

export const iframeURL = 'https://my-web-1690414928752.estage.site'

export const beforeBlock = new Navbar().json()

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('BlankV2', options)

    this.name = name
    this.group = group
    this.sid = 'lrg8hk4l---g_PxyYIO8'
    this.sids = [
      'lrg8hk4l---g_PxyYIO8',
      'lrg8hk4l--0ErzzSBh76',
      'lrg8hk4l--KRoKoQ6LLi',
      'lrg8hk4l--Teo3oWG8nU',
      'lrg8hk4l--MAd_HwmgJL',
      'lrg8hk4l--tTGXTPDJVk',
      'rg8hk4l--JMihHOTTo',
      'rg8hk4l--97g1wcMfZ',
      'lrg8hk4l--WdwpFZrHf',
      'lrg8hk4l--2Tg7vFI_QH',
      'lrg8hk4l--qoMXRmwr_s',
      'lrg8hk4l--bVOAwkaxy_',
      'rg8hk4l--rZm6ei8qC',
      'lrg8hk4l--2B0NbKZzMa',
      'lrg8hk4l--LYP9oznp4m',
      'lrg8hk4l--kwbsNHgjc0'
    ]
    this.css = [
      [
        '.--lrg8hk4l---g_PxyYIO8--container { max-width: 100vw; padding-top: 150px; padding-bottom: 90px; }',
        '.--lrg8hk4l--KRoKoQ6LLi--flex { max-width: 80vw; --width: 80vw; }',
        '.--lrg8hk4l--KRoKoQ6LLi--flex-col-el { width: 50%; --width: 50%; }',
        '.--lrg8hk4l--2Tg7vFI_QH--flex-col-el { width: 50%; --width: 50%; }',
        '.--lrg8hk4l--0ErzzSBh76--flex { max-width: 1200px; --width: 1200px; gap: 0px 52px; }',
        '.--lrg8hk4l---g_PxyYIO8--bg { background-color: rgba(0, 0, 0, 0); background-size: cover; --background-size: cover; background-attachment: unset; background-image: url("https://res.cloudinary.com/estage/image/upload/v1690416777/users/user-572/aaf05e6085356bd376562e194b79d04f.png"); --bg-id: 167304; }',
        '.--lrg8hk4l--Teo3oWG8nU-text { padding: 20px; }',
        '.--lrg8hk4l--Teo3oWG8nU-text div { text-align: left; color: rgb(255, 255, 255); font-family: "Noto Sans KR"; font-size: 51px; line-height: 1.2; --line-height: 1.2; }',
        '.--lrg8hk4l--MAd_HwmgJL-text { padding: 0px 20px 20px; }',
        '.--lrg8hk4l--MAd_HwmgJL-text div { color: rgb(255, 255, 255); text-align: left; font-family: "Noto Sans KR"; font-weight: 300; font-size: 17px; line-height: 1.5; --line-height: 1.5; }',
        '.--lrg8hk4l--KRoKoQ6LLi--flex-col-padding { padding: 0px; }',
        '.--lrg8hk4l--qoMXRmwr_s--flex { max-width: 100vw; --width: 100vw; }',
        '.--lrg8hk4l--bVOAwkaxy_--flex-col-el { width: 425px; --width: 425px; background-color: rgb(255, 255, 255); border-radius: 5px; --border-bottom-left-radius: 5px; --border-top-left-radius: 5px; --border-top-right-radius: 5px; --border-bottom-right-radius: 5px; }',
        '.--lrg8hk4l--qoMXRmwr_s--flex.flex-wrapper { padding: 0px; }',
        '.--rg8hk4l--rZm6ei8qC-text { padding: 20px; }',
        '.--rg8hk4l--rZm6ei8qC-text div { text-align: center; color: rgb(30, 40, 101); font-family: "Noto Sans KR"; font-size: 21px; line-height: 1.2; --line-height: 1.2; }',
        '.--lrg8hk4l--2B0NbKZzMa--form { gap: 9px 22px; align-items: end; flex-direction: column; padding: 20px; }',
        '.--lrg8hk4l--LYP9oznp4m--1 { margin-bottom: 0px; height: 51px; width: 100%; font-family: "Noto Sans KR"; font-size: 15px; font-weight: 400; background-color: rgb(250, 128, 82); }',
        '.--lrg8hk4l--2B0NbKZzMa--form .pU9T6Auqb { width: 100%; }',
        '.--lrg8hk4l--2B0NbKZzMa--form .faQ0DoIri4 { width: 100%; }',
        '.--lrg8hk4l--2B0NbKZzMa--form .input.form-control { background-color: rgb(239, 240, 255); border-width: 0px; border-style: none; font-family: "Noto Sans KR"; border-color: rgb(44, 41, 176); color: rgb(4, 5, 113); }',
        '.--lrg8hk4l--2B0NbKZzMa--form .form-control.input { font-size: 14px; }',
        '.--lrg8hk4l--kwbsNHgjc0-text { padding: 0px 0px 25px; }',
        '.--lrg8hk4l--kwbsNHgjc0-text div { font-size: 12px; color: rgb(60, 85, 172); }',
        '.--lrg8hk4l--tTGXTPDJVk--container { justify-content: center; }',
        '.--lrg8hk4l--tTGXTPDJVk--wrapper { padding: 0px 0px 0px 20px; display: inline-flex; }',
        '.el-image.--lrg8hk4l--tTGXTPDJVk--image { width: 45px; --width: 45px; }',
        '.--lrg8hk4l--tTGXTPDJVk--image { border-radius: 250px; content: url("https://res.cloudinary.com/estage/image/upload/v1690416224/users/user-572/4d8a1ac79edca1f09fc33ac348b882fa.jpg"); --bg-id: 167298; border-width: 2px; }',
        '.--rg8hk4l--JMihHOTTo--container { justify-content: center; }',
        '.--rg8hk4l--JMihHOTTo--wrapper { padding: 0px 0px 0px 20px; display: inline-flex; margin-left: -27px; }',
        '.el-image.--rg8hk4l--JMihHOTTo--image { width: 45px; --width: 45px; }',
        '.--rg8hk4l--JMihHOTTo--image { border-radius: 250px; content: url("https://res.cloudinary.com/estage/image/upload/v1690416297/users/user-572/fbd855c30c2bed847d3cab215ac3f3f4.jpg"); --bg-id: 167299; border-width: 2px; }',
        '.--rg8hk4l--97g1wcMfZ--container { justify-content: center; }',
        '.--rg8hk4l--97g1wcMfZ--wrapper { padding: 0px 0px 0px 20px; display: inline-flex; margin-left: -27px; }',
        '.el-image.--rg8hk4l--97g1wcMfZ--image { width: 45px; --width: 45px; }',
        '.--rg8hk4l--97g1wcMfZ--image { border-radius: 250px; content: url("https://res.cloudinary.com/estage/image/upload/v1690416331/users/user-572/f6941388caa08220d0d7d44b9046b636.jpg"); --bg-id: 167300; border-width: 2px; }',
        '.--lrg8hk4l--WdwpFZrHf-text { padding: 0px 20px 20px; }',
        '.--lrg8hk4l--WdwpFZrHf-text div { text-align: left; font-family: "Noto Sans KR"; color: rgb(255, 255, 255); font-size: 11px; }',
        '.--lrg8hk4l--LYP9oznp4m--container { width: 100%; --align: stretch; }'
      ],
      [
        '.--lrg8hk4l--KRoKoQ6LLi--flex { max-width: 100%; --width: 100%; }',
        '.--lrg8hk4l--0ErzzSBh76--flex { max-width: 100%; --width: 100%; flex-wrap: nowrap; column-gap: 0px; }',
        '.--lrg8hk4l--qoMXRmwr_s--flex { max-width: 100%; --width: 100%; }',
        '.--lrg8hk4l--Teo3oWG8nU-text { padding-left: 0px; }',
        '.--lrg8hk4l--Teo3oWG8nU-text div { font-size: 46px; }',
        '.--lrg8hk4l--MAd_HwmgJL-text { padding-left: 0px; }',
        '.--lrg8hk4l--MAd_HwmgJL-text div { font-size: 16px; }',
        '.--lrg8hk4l--tTGXTPDJVk--wrapper { padding-left: 0px; }',
        '.--lrg8hk4l--WdwpFZrHf-text { padding-left: 0px; }',
        '.--lrg8hk4l--2Tg7vFI_QH--flex-col-padding { padding-right: 0px; }',
        '.--lrg8hk4l---g_PxyYIO8--container { padding-top: 90px; padding-bottom: 50px; }'
      ],
      [
        '.--lrg8hk4l--KRoKoQ6LLi--flex { max-width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lrg8hk4l--0ErzzSBh76--flex { max-width: 100%; --width: 100%; flex-flow: column wrap; }',
        '.--lrg8hk4l--qoMXRmwr_s--flex { max-width: 100%; --width: 100%; }',
        '.--lrg8hk4l--KRoKoQ6LLi--flex-col-el { width: 100%; --width: 100%; }',
        '.--lrg8hk4l--Teo3oWG8nU-text div { text-align: center; font-size: 34px; }',
        '.--lrg8hk4l--0ErzzSBh76--flex.flex-wrapper { padding-top: 0px; }',
        '.--lrg8hk4l--MAd_HwmgJL-text div { text-align: center; font-size: 15px; }',
        '.--lrg8hk4l--KRoKoQ6LLi--flex-col { text-align: center; justify-content: center; align-items: center; }',
        '.--lrg8hk4l--WdwpFZrHf-text div { text-align: center; }',
        '.--lrg8hk4l--WdwpFZrHf-text { padding-right: 0px; }',
        '.--lrg8hk4l--MAd_HwmgJL-text { padding-right: 0px; }',
        '.--lrg8hk4l--Teo3oWG8nU-text { padding-right: 0px; }',
        '.--lrg8hk4l--bVOAwkaxy_--flex-col-el { width: 100%; --width: 100%; }',
        '.--lrg8hk4l--2Tg7vFI_QH--flex-col-el { width: 100%; --width: 100%; }',
        '.--lrg8hk4l--2Tg7vFI_QH--flex-col-padding { padding-left: 0px; }',
        '.--lrg8hk4l--2B0NbKZzMa--form { padding-left: 0px; padding-right: 0px; padding-bottom: 20px; }',
        '.--rg8hk4l--rZm6ei8qC-text { padding-bottom: 10px; padding-top: 10px; }',
        '.--lrg8hk4l--bVOAwkaxy_--flex-col-padding { padding-bottom: 10px; }',
        '.--lrg8hk4l---g_PxyYIO8--container { padding-bottom: 15px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
