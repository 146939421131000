<template>
  <draggable
    class="dragArea pl-0"
    ghost-class="link-ghost"
    tag="ul"
    :group="{ name: 'menu-links', pull: pull, put: true }"
    :list="children"
    handle=".move"
    :force-fallback="true"
    :fallback-on-body="true"
    v-bind="dragOptions"
    fallback-class="link-dragged-item"
    @start="drag = true"
    @end="drag = false"
  >
    <li
      v-for="(el, index) in children"
      :key="el.name"
      :data-children="el.children.length"
    >
      <slot
        name="item"
        :page="el"
        :index="index"
      />
    </li>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'NestedDraggable',
  components: {
    draggable
  },
  props: {
    children: {
      required: true,
      type: Array
    },
    menu: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      drag: false
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        disabled: false
      }
    }
  },
  methods: {
    pull (to, from, item) {
      const children = parseInt(item.getAttribute('data-children'))
      if (!children) return true
      return false
    }
  }
}
</script>

<style lang="scss">
.nav-items-els {
  & > .dragArea {
    padding-left: 0;
  }

  .dragArea {
    list-style: none;
  }
}

.sub-drag-area {
  padding-bottom: 30px;
  background-color: #000;
}

.dragArea.drop-placeholder {
  min-height: 50px;
  border: 1px dashed #eee;
  position: relative;

  &:before {
    content: 'Drop & Drop Link Here';
    font-size: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.link-dragged-item {
  list-style: none;

  .dragArea {
    display: none;
  }
}

.link-ghost {
  .dragArea {
    display: none;
  }

  //.dragArea {
  //  display: none !important;
  //}
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}
</style>
