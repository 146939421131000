<template>
  <b-modal
    v-model="modal"
    class="position-relative"
    lazy
    centered
    body-class="py-5"
    content-class="overflow-visible new-section-modal"
    size="lg"
    hide-footer
    hide-header
  >
    <h3 class="text-center font-weight-bold px-0">
      Create Template
    </h3>
    <p class="text-center mb-12">
      Create your own template and use it in your new projects
    </p>

    <b-row>
      <b-col
        offset="2"
        cols="8"
      >
        <b-form @submit.prevent="onsubmit">
          <b-form-group
            class="estage-form-group"
            label="Enter Template Name"
          >
            <b-input
              v-model="form.title"
              v-validate="'required'"
              name="title"
              :state="errors.has('title') ? false : null"
            />
          </b-form-group>

          <b-form-group
            class="estage-form-group"
            label="Select Home Page"
          >
            <v-select
              v-model="form.homePageId"
              v-validate="'required'"
              name="homePageId"
              :class="{'is-invalid': errors.has('homePageId')}"
              label="title"
              :reduce="option => option.id"
              :options="selectedPages"
              :searchable="false"
            />
          </b-form-group>

          <!--          <b-form-group class="estage-form-group" label="Template Description">-->
          <!--            <b-input v-model="form.description"-->
          <!--                     name="description"/>-->
          <!--          </b-form-group>-->

          <div class="text-center mt-5">
            <b-btn
              type="submit"
              variant="primary"
              class="font-weight-bold font-size-14 px-5"
              :disabled="loading"
            >
              <b-spinner
                v-if="loading"
                class="mr-2"
                variant="light"
                small
              />
              Create
            </b-btn>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'
import _ from 'lodash'

const form = {
  title: '',
  description: '',
  homePageId: null
}

export default {
  name: 'CreateOwnTemplateModal',
  props: {
    value: {
      type: [Boolean, Number],
      required: true
    },
    selectedPages: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      form: JSON.parse(JSON.stringify(form)),
      apiErrors: []
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    modal: {
      get () {
        if (this.value) {
          const homePage = _.find(this.selectedPages, ['is_home', 1])
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.title = ''
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.homePageId = homePage ? homePage.id : this.selectedPages[0].id
        }

        return !!this.value
      },
      set (val) {
        if (!val) {
          return this.$emit('input', 0)
        }
      }
    }
  },
  watch: {
    modal (val) {
      if (val) {
        this.form = JSON.parse(JSON.stringify(form))
      }
    }
  },
  methods: {
    onsubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true

          axios.post('api/templates/my-templates', {
            ...this.form,
            pages: this.selectedPages.map(page => page.id)
          })
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Done!',
                text: 'The template has been successfully created and added to the list of your own templates.',
                showConfirmButton: false,
                timer: 3500
              })
            })
            .catch(() => {
              this.$swal({
                icon: 'error',
                title: 'Server error',
                text: 'Please try again later or let us know about your problem.',
                showConfirmButton: false,
                timer: 3500
              })
            })
            .finally(() => {
              this.loading = false
              this.modal = false
            })
        }
      })
    }
  }
}
</script>