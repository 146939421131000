import Cookie from 'js-cookie'
import axios from 'axios'

const instance  = axios.create({
  baseURL: 'https://video.estage.com',
  headers: {
    'Authorization': `Bearer ${Cookie.get('cross_estage_token')}`
  }
})

const state = () => ({
  breadcrumbs: [],
  folders: [],
  files: [],
  filters: {
    search: ''
  },
  total: 0,
  activeFolder: null,
  activeFile: null
})

const getters = {
  breadcrumbs: (state) => {
    let data = [
      {
        'id': '0',
        'href': '/',
        'name': 'Estage Videos Home'
      }
    ]
    if (state.activeFolder) {
      data = [...data, ...state.breadcrumbs.slice(1)]
    }
    return data
  }
}

const actions = {
  async getAll ({commit}, params = {}) {
    const state = this.state.videosLibrary
    const id = state.activeFolder ? state.activeFolder.id : '0'
    commit('SET_TOTAL', null)
    commit('SET_BREADCRUMBS', [])
    commit('SET_FOLDERS', [])
    commit('SET_FILES', [])
    await instance.get(`api/folder/${id}`, {params})
      .then((res) => {
        commit('SET_TOTAL', res.data.files.size)
        commit('SET_BREADCRUMBS', res.data.breadcrumbs)
        commit('SET_FOLDERS', res.data.folders.data)
        commit('SET_FILES', res.data.files.data)
      })
      .catch((err) => {
        console.error(err)
      })
  },
  async getTotal ({commit}, params = {}) {
    const state = this.state.videosLibrary
    const id = state.activeFolder ? state.activeFolder.id : '0'
    commit('SET_TOTAL', null)
    await instance.get(`api/folder/${id}`, {params})
      .then((res) => {
        commit('SET_TOTAL', res.data.files.size)
      })
      .catch((err) => {
        console.error(err)
      })
  },
  async getBreadcrumbs ({commit}, params = {}) {
    const state = this.state.videosLibrary
    const id = state.activeFolder ? state.activeFolder.id : '0'
    commit('SET_BREADCRUMBS', [])
    await instance.get(`api/folder/${id}`, {params})
      .then((res) => {
        commit('SET_BREADCRUMBS', res.data.breadcrumbs)
      })
      .catch((err) => {
        console.error(err)
      })
  },
  async getFolders ({commit}, params = {}) {
    const state = this.state.videosLibrary
    const id = state.activeFolder ? state.activeFolder.id : '0'
    commit('SET_FOLDERS', [])
    await instance.get(`api/folder/${id}`, {params})
      .then((res) => {
        commit('SET_FOLDERS', res.data.folders.data)
      })
      .catch((err) => {
        console.error(err)
      })
  },
  async getFiles ({commit}, params = {}) {
    const state = this.state.videosLibrary
    const id = state.activeFolder ? state.activeFolder.id : '0'
    commit('SET_FILES', [])
    await instance.get(`api/folder/${id}`, {params})
      .then((res) => {
        commit('SET_FILES', res.data.files.data)
      })
      .catch((err) => {
        console.error(err)
      })
  },
  async getSearch ({commit}, params = {}) {
    const state = this.state.videosLibrary
    const search = state.filters.search
    params.search = search.toLowerCase()
    commit('SET_FOLDERS', [])
    commit('SET_FILES', [])
    await instance.get('api/search', {params})
      .then((res) => {
        commit('SET_FOLDERS', res.data.folders)
        commit('SET_FILES', res.data.files)
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

const mutations = {
  SET_TOTAL (state, payload) {
    state.total = payload
  },
  SET_BREADCRUMBS (state, paylaod) {
    state.breadcrumbs = paylaod
  },
  SET_FOLDERS (state, paylaod) {
    state.folders = paylaod
  },
  SET_FILES (state, paylaod) {
    state.files = paylaod
  },
  SET_ACTIVE_FOLDER (state, payload) {
    state.activeFolder = payload
  },
  SET_ACTIVE_FILE (state, payload) {
    state.activeFile = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}