<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings('testimonial-mod')"
    @add-subject="$emit('add-subject')"
  >
    <Template :section="section">
      <template slot="image">
        <ElAvatar :section="section.options.content.avatar" />
      </template>

      <template slot="elements">
        <elements
          v-model="section.options.content.elements"
          @flow="handleFlow"
        />
      </template>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from '@builder/components/builder/elements/16.testimonials/testimonial-4/Template'
import Elements from '../../../utils/Elements/Elements'

export default {
  name: 'FeaturedItems1',

  components: {Elements, Template, Highliter},

  mixins: [SectionMixin]
}
</script>
