<template>
  <b-media
    :class="[{'px-4': padding.paddingLeft + padding.paddingRight === 0 || padding.paddingLeft + padding.paddingRight === 'undefinedpxundefinedpx'}, {'py-4 pb-4': padding.paddingBottom + padding.paddingTop === '0px0px'}]"
    :style="{...backgroundColor, ...border, ...padding, ...shadow, ...marginTop, ...marginBottom, ...radius}"
    class="el-media-wrapper testimonial-item-4 flex-wrap"
  >
    <template slot="aside">
      <slot name="image" />
    </template>

    <b-media-body class="mt-2">
      <slot name="elements" />
    </b-media-body>
  </b-media>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'

export default {
  name: 'TestimonialItem4Template',
  mixins: [TemplateMixin],
  computed: {
    shadow () {
      const shadow = this.section.shadowOptions
      const color = this.section.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    border () {
      const border = this.section.border || [1, 1, 1, 1]
      return {
        borderColor: this.section.borderColor,
        borderStyle: 'solid',
        borderTopWidth: `${border[0]}px`,
        borderRightWidth: `${border[1]}px`,
        borderBottomWidth: `${border[2]}px`,
        borderLeftWidth: `${border[3]}px`
      }
    },
    radius () {
      const radius = this.section.radius || [10, 10, 10, 10]

      return {
        borderTopLeftRadius: `${radius[0]}px`,
        borderTopRightRadius: `${radius[1]}px`,
        borderBottomLeftRadius: `${radius[2]}px`,
        borderBottomRightRadius: `${radius[3]}px`
      }
    }
  }
}
</script>

<style lang="scss">
.testimonial-item-3 {
  p {
    margin-bottom: 0;
  }
}
</style>
