<template>
  <div>
    <b-form
      class="mb-4"
      @submit.prevent="submit"
    >
      <b-form-group>
        <div slot="label">
          <h6 class="text-dark font-weight-bold">
            Custom Domain
          </h6>
          <span class="text-muted">Add your domain below. See setup instructions below for further guidance.</span>
        </div>
        <div class="d-flex position-relative">
          <i
            class="fa fa-globe position-absolute"
            style="left: 10px; top: 50%; transform: translateY(-50%)"
          />

          <b-input
            v-model="domain"
            v-validate="'required|domain'"
            name="domain"
            placeholder="www.domain.com"
            :state="errors.has('domain') ? false : null"
            autocomplete="off"
            style="padding-left: 30px;"
          />

          <div
            v-if="project.domain && !verify"
            class="ml-2"
          >
            <div class="btn btn-white flex-nowrap text-nowrap d-flex align-items-center h-100 border text-danger">
              <i class="fa fa-wifi mr-2" />
              Not Connected
            </div>
          </div>

          <div
            v-if="verify"
            class="ml-2"
          >
            <div class="btn btn-white flex-nowrap text-nowrap d-flex align-items-center h-100 border text-success">
              <i class="fa fa-wifi mr-2" />
              Domain Connected
            </div>
          </div>

          <div
            v-if="project.domain && !loading"
            class="ml-2"
          >
            <b-btn
              :disabled="loading"
              variant="white"
              class="d-flex align-items-center h-100 border"
              @click="deleteDomain"
            >
              <i class="fa fa-trash-o" />
            </b-btn>
          </div>
        </div>
        <span
          v-show="errors.has('domain')"
          class="d-block text-danger mt-1"
        >{{ errors.first('domain') }}</span>
      </b-form-group>

      <b-btn
        :disabled="loading || project.domain === domain"
        type="submit"
        variant="success"
        class="d-flex align-items-center"
      >
        Add Custom Domain
        <b-spinner
          v-if="loading"
          class="ml-2"
          variant="white"
          small
        />
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import {VueMaskDirective} from 'v-mask'
import {mapState} from 'vuex'
import RouterMixin from '../../components/mixins/RouterMixin'

export default {
  name: 'DoaminConnectForm',
  directives: {
    mask: VueMaskDirective
  },

  mixins: [RouterMixin],

  data () {
    return {
      domain: '',
      loading: false,
      verify: false
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },

  created () {
    if (this.project.domain) {
      this.domain = this.project.domain
      this.checkDomain()
    }
  },

  methods: {
    deleteDomain () {
      this.domain = ''
      this.project.domain = ''
      this.loading = true
      this.verify = false
      this.$validator.reset()

      this.$store.dispatch('projects/save',true)
        .then(() => {
          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Domain has been deleted.',
            showConfirmButton: false,
            timer: 3000
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    submit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true
          this.project.domain = this.removeWWW(this.domain)

          this.$store.dispatch('projects/save', true)
            .then(() => {
              this.checkDomain()
            })
            .catch(() => {
              this.project.domain = ''
              this.$swal({
                icon: 'error',
                iconColor: '#e34f5b',
                toast: true,
                position: 'top-right',
                title: 'Sorry, that domain name is already in use - please check your other projects.',
                showConfirmButton: false,
                timer: 5000
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    checkDomain () {
      if (!this.project.domain) return false

      axios.get(`api/projects/${this.project.id}/check-domain`)
        .then(({data}) => {
          this.verify = data.data

          if (data.data) {
            this.$swal({
              icon: 'success',
              iconColor: '#4F83E3',
              toast: true,
              position: 'top-right',
              title: 'Domain connected.',
              showConfirmButton: false,
              timer: 3000
            })
          }
        })
    }
  }
}
</script>
