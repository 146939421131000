<template>
  <Template :section="section">
    <component
      :is="section.options.component"
      :section="section.options"
      @delete="$emit('delete')"
      @dublicate="$emit('dublicate')"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    />
  </Template>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditable from '@builder/components/builder/utils/ContentEditable'
import Template from './Template'

export default {
  name: 'FeaturedItems1',

  components: {Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
