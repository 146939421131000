const state = () => ({
  categories: []
})

const getters = {}

const actions = {
  async laodCategories ({state, rootState}) {
    return await axios.get(`api/projects/${rootState.projects.project.id}/blog/categories`)
      .then(({data}) => {
        state.categories = data.data
      })
  },
  store ({state, rootState}, payload) {
    VEvent.fire('loader', true)
    return axios.post(`api/projects/${rootState.projects.project.id}/blog/categories`, {
      ...payload
    })
      .then(({data}) => {
        state.categories.push({
          ...data.data
        })
      })
      .finally(() => {
        VEvent.fire('loader', false)
      })
  },
  update ({rootState}, payload) {
    VEvent.fire('loader', true)
    return axios.put(`api/projects/${rootState.projects.project.id}/blog/categories/${payload.id}`, {
      ...payload,
      avatar: JSON.stringify(payload.avatar)
    })
      .finally(() => {
        VEvent.fire('loader', false)
      })
  },
  remove ({rootState}, payload) {
    VEvent.fire('loader', true)
    return axios.delete(`api/projects/${rootState.projects.project.id}/blog/categories/${payload}`)
      .finally(() => {
        VEvent.fire('loader', false)
      })
  }
}

const mutations = {}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
