<template>
  <b-modal
    v-model="modal"
    hide-footer
    hide-header
    :centered="isAllChildComments"
    size="lg"
  >
    <div class="p-4">
      <div v-if="isAllChildComments">
        <h3 class="text-center mb-4">
          All selected comments are responses to another comment <br> and cannot be replied to.
        </h3>

        <div class="flex justify-center w-full">
          <b-btn @click="modal = false">
            Cancel
          </b-btn>
        </div>
      </div>
      <b-form
        v-else
        @submit.prevent="submit"
      >
        <b-form-group>
          <b-textarea
            v-model="reply"
            required
            :maxlength="500"
            rows="7"
            placeholder="Your reply here..."
          />
          <div class="text-right text-muted mt-2">
            {{ reply.length }} / 500
          </div>
        </b-form-group>

        <div class="flex w-100 justify-end">
          <b-btn
            variant="primary"
            type="submit"
            :disabled="loading"
          >
            <b-spinner
              v-if="loading"
              variant="white"
              small
              class="mr-2"
            />
            Reply
          </b-btn>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'MultipleReply',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    selectedIds: {
      type: Array,
      required: true
    },
    isAllChildComments: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      reply: ''
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('editor', {
      commentsManagementAPI: state => state.commentsManagementAPI
    }),
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    submit () {
      this.loading = true

      axios.put(`api/projects/${this.project.id}/${this.commentsManagementAPI}/mass-actions`, {
        ids: this.selectedIds,
        action: 'reply',
        comment: this.reply
      })
        .then(() => {
          this.modal = false

          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Submitted.',
            showConfirmButton: false,
            timer: 3000
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>