<template>
  <div class="sid-select">
    <SidbearSettingsGroup
      title="Main Text"
      active
      content-class="border-0"
    >
      <rule-tabs>
        <template slot="Normal">
          <b-form-group
            label="Text"
          >
            <b-input v-model="btnText" />
          </b-form-group>

          <sid-font
            :sid="className(' .post-button')"
          />

          <sid-range-slider
            label="Text Size"
            :sid="className(' .post-button')"
            rule-name="font-size"
          />

          <sid-range-slider
            label="Letter-Spacing"
            :sid="className(' .post-button')"
            rule-name="letter-spacing"
          />

          <sid-range-slider
            label="Line-Height"
            :sid="className(' .post-button')"
            rule-name="line-height"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="className(' .post-button')"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <sid-align
            :sid="className(' .post-button')"
            rule-name="justify-content"
            label="Text Alignment"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="p-0">Text Decoration</label>

              <sid-font-style
                :sid="className(' .post-button')"
                rule-name="font"
              />
            </div>
          </b-form-group>
        </template>

        <template slot="Hover">
          <sid-checkbox
            value=".3s"
            unchecked-value=""
            rule-name="transition"
            var="--transition"
            :sid="className(' .post-button')"
          >
            Transition <span
              class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
            >BETA</span>
          </sid-checkbox>

          <sid-font
            :sid="className()"
            presudo=" .post-grid-card:hover .post-button"
          />

          <sid-range-slider
            label="Text Size"
            :sid="className()"
            rule-name="font-size"
            presudo=" .post-grid-card:hover .post-button"
          />

          <sid-range-slider
            label="Letter-Spacing"
            :sid="className()"
            rule-name="letter-spacing"
            presudo=" .post-grid-card:hover .post-button"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="className()"
                presudo=" .post-grid-card:hover .post-button"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-range-slider
              label="Line-Height"
              :sid="className()"
              presudo=" .post-grid-card:hover .post-button"
              rule-name="line-height"
            />
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="p-0">Text Decoration</label>

              <sid-font-style
                :sid="className()"
                rule-name="font"
                presudo=" .post-grid-card:hover .post-button"
                :pseudo-index="1"
              />
            </div>
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Layout & Style">
      <rule-tabs>
        <template #Normal>
          <sid-align
            :sid="className(' .post-button-container')"
            rule-name="justify-content"
            label="Button Alignment"
          />

          <sid-background :sid="className(' .post-button')" />

          <sid-range-slider
            label="Width"
            :sid="className(' .post-button')"
            rule-name="width"
            var="--width"
            default-dimension="%"
            :dimensions="['px', '%']"
            measurement="width"
          />

          <sid-range-slider
            label="Height"
            :sid="className(' .post-button')"
            rule-name="height"
            var="--height"
            default-dimension="px"
            :dimensions="['px', '%']"
          />
        </template>

        <template #Hover>
          <sid-checkbox
            value=".3s"
            unchecked-value=""
            rule-name="transition"
            var="--transition"
            :sid="className(' .post-button')"
          >
            Transition <span
              class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
            >BETA</span>
          </sid-checkbox>

          <sid-background
            :sid="className()"
            presudo=" .post-grid-card:hover .post-button"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <rule-tabs>
        <template slot="Normal">
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="className(' .post-button')"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="className(' .post-button')"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="className(' .post-button')"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="className(' .post-button')"
            rule-name="border"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>

        <template slot="Hover">
          <sid-checkbox
            value=".3s"
            unchecked-value=""
            rule-name="transition"
            var="--transition"
            :sid="className(' .post-button')"
          >
            Transition <span
              class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
            >BETA</span>
          </sid-checkbox>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="className()"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=" .post-grid-card:hover .post-button"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="className()"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=" .post-grid-card:hover .post-button"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="className(' .post-button')"
                presudo=":hover"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="className()"
            rule-name="border"
            presudo=" .post-grid-card:hover .post-button"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Drop Shadow">
      <rule-tabs>
        <template #Normal>
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="className(' .post-button')"
              rule-name="box-shadow"
            />
          </b-form-group>
        </template>

        <template #Hover>
          <sid-checkbox
            value=".3s"
            unchecked-value=""
            rule-name="transition"
            var="--transition"
            :sid="className(' .post-button')"
          >
            Transition <span
              class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
            >BETA</span>
          </sid-checkbox>

          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="className()"
              presudo=" .post-grid-card:hover .post-button"
              rule-name="box-shadow"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Transform">
      <rule-tabs>
        <template #Normal>
          <sid-transform
            :sid="className(' .post-button')"
            rule-name="transform"
            :section="section"
          />
        </template>

        <template #Hover>
          <sid-checkbox
            value=".3s"
            unchecked-value=""
            rule-name="transition"
            var="--transition"
            :sid="className(' .post-button')"
          >
            Transition <span
              class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
            >BETA</span>
          </sid-checkbox>

          <sid-transform
            :sid="className()"
            rule-name="transform"
            presudo=" .post-grid-card:hover .post-button"
            :section="section"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Click Action">
      <b-form-group class="mb-0">
        <b-checkbox v-model="section.openPostInNewTab">
          Open in new tab
        </b-checkbox>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Padding & Margins">
      <sid-linked-group
        label="Padding"
        :sid="className(' .post-button-container .post-button')"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="className(' .post-button-container')"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
/* eslint-disable */

import ModMixin from '@builder/components/mixins/ModMixin'
import ButtonAction from '@/components/editor/controls/button-mod/ButtonAction'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidLinkedGroupSlider from '@/components/editor/components/sid-controls/SidLinkedGroupSlider'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import RuleTabs from "@/components/editor/components/RuleTabs";
import SidbearSettingsGroup from "@/components/editor/components/SidbearSettingsGroup";
import SidStyles from "@/components/mixins/SidStyles";
import SidTransform from "@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTransform";
import SidInput from "@/components/editor/components/sid-controls/SidInput";
import Animations from "@/components/editor/controls/Animations";
import HoverAnimations from "@/components/editor/controls/HoverAnimations";
import SidFontStyle from "@/components/editor/components/sid-controls/SidFontStyle";
import SidFont from "@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont";
import TooltipMod from "@/components/editor/mods/TooltipMod";
import SidCheckbox from "@/components/editor/components/sid-controls/SidCheckbox";

export default {
  name: 'ButtonMod',
  components: {
    SidCheckbox,
    TooltipMod,
    SidFont,
    SidFontStyle,
    HoverAnimations,
    Animations,
    SidInput,
    SidTransform,
    SidbearSettingsGroup,
    RuleTabs,
    SidBoxShadow,
    SidBackground,
    SidSelect,
    SidLinkedGroupSlider, SidLinkedGroup, SidAlign, SidColor, SidRangeSlider, ButtonAction
  },
  mixins: [ModMixin, SidStyles],
  computed: {
    iconPosition: {
      get() {
        return _.get(this.section.options.content.button, ['iconPosition', this.resolutioner], _.get(this.section.options.content.button, ['iconPosition', 'lg'], 'right'))
      },
      set(val) {
        this.$set(this.section.options.content.button, 'iconPosition', {..._.set(this.section.options.content.button['iconPosition'] || {}, [this.resolutioner], val)})
      }
    },
    hoverAnimation: {
      get() {
        return this.config.hoverAnimation || null
      },
      set(val) {
        this.$set(this.config, 'hoverAnimation', val)
      }
    },
    btnText: {
      get () {
        return this.section.options.content.post.settings.btnText || 'Read More...'
      },
      set (val) {
        this.$set(this.section.options.content.post.settings, 'btnText', val)
      }
    }
  },
  methods: {
    className(name = '') {
      return `.post-grid-${this.section.sid}${name}`
    }
  }
}
</script>
