<template>
  <b-modal
    v-model="modal"
    :dialog-class="[!success ? '!max-w-[1164px]' : '!max-w-[600px]', 'rounded-[.375rem]']"
    content-class="w-full overflow-hidden"
    body-class="p-0"
    hide-header
    hide-footer
    centered
    lazy
  >
    <i
      class="fa fa-close z-[50] absolute top-[15px] right-[17px] text-[16px] text-[#6E747A] transition cursor-pinter hover:opacity-60"
      @click="modal = false"
    />

    <template v-if="!success">
      <div class="relative w-full !min-h-[640px] flex flex-row">
        <purchase-plan-overview :plan="currentPlanType" />
        <purchase-plan-upgrade 
          :plan="currentPlanType"
          :month="month"
          :year="year"
          @success="success = true"
        />
      </div>
    </template>
    <template v-else-if="success && element">
      <div class="relative w-[600px] !min-h-[640px] flex flex-col items-center justify-center mx-auto px-[64px] py-[48px]">
        <h4 class="text-center text-[1.875rem] text-[#363636] leading-[2.25rem] font-[700] font-[ProximaNova] mb-[0]">
          🎉 Success!
        </h4>

        <p class="text-[.875rem] leading-[1.25rem] text-[#979797] mt-[1.25rem] mb-[0]">
          Your Payment was Successful & {{ element.name }} has been Activated!
        </p>

        <b-btn
          variant="primary"
          class="w-full h-[3.75rem] mt-[1.25rem] !font-[600]"
          @click="$emit('add-element')"
        >
          Start Using {{ element.name }}
        </b-btn>
      </div>
    </template>
    <template v-else-if="success && !element">
      <div class="relative w-[600px] flex flex-col items-center justify-center mx-auto px-[64px] py-[48px]">
        <h4 class="text-center text-[1.875rem] text-[#363636] leading-[2.25rem] font-[700] font-[ProximaNova] mb-[0]">
          🎉 Success!
        </h4>

        <p class="text-[.875rem] leading-[1.25rem] text-[#979797] mt-[1.25rem] mb-[0]">
          Your Payment was Successful
        </p>
      </div>
    </template>
  </b-modal>
</template>

<script>
import BusinessBanner from '@/assets/images/business-plan-banner.png'
import BusinessPlusBanner from '@/assets/images/business-plus-plan-banner.png'
import PurchasePlanOverview from '@/components/editor/modals/purchase-plan/PurchasePlanOverview.vue'
import PurchasePlanUpgrade from '@/components/editor/modals/purchase-plan/PurchasePlanUpgrade.vue'
export default {
  name: 'PurchasePlanModal',
  components: {
    PurchasePlanUpgrade,
    PurchasePlanOverview
  },
  props: {
    businessPlus: {
      type: Boolean,
      default: true
    },
    month: {
      type: Boolean,
      require: false
    },
    year: {
      type: Boolean,
      require: false
    },
    element: {
      type: Object,
      required: false
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      success: false,
      businessPlans: [
        {
          name: 'Yearly',
          interval: 'year',
          value: 'BUSINESS_Y',
          save: 20,
          price: 1970
        },
        {
          name: 'Monthly',
          interval: 'month',
          value: 'ESTAGE_SUB',
          price: 197
        }
      ],
      businessPlusPlans: [
        {
          name: 'Yearly',
          interval: 'year',
          value: 'BUSINESS_PLUS_Y',
          save: 20,
          price: 2970
        },
        {
          name: 'Monthly',
          interval: 'month',
          value: 'BUSINESS_PLUS_M',
          price: 297
        }
      ]
    }
  },
  computed: {
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    currentPlanType () {
      if (this.businessPlus) {
        return {
          name: 'Business Plus',
          image: BusinessPlusBanner,
          duration: this.businessPlusPlans
        }
      } else {
        return {
          name: 'Business',
          image: BusinessBanner,
          duration: this.businessPlans
        }
      }
    }
  },
  watch: {
    modal (val) {
      if (!val && this.success) {
        window.location = '/account/billing'
      }
    }
  }
}
</script>