<template>
  <b-modal
    :visible="modal"
    size="813"
    header-class="!border-b-0"
    body-class="p-0"
    hide-footer
    @change="handleModalChange"
  >
    <template #modal-header>
      <icon
        class="ml-auto cursor-pinter"
        icon="close.svg"
        @click.native="handleModalChange(false)"
      />
    </template>
    <div class="max-w-[470px] mx-auto">
      <div class="mx-auto">
        <div class="capitalize text-center font-semibold text-[27px] leading-[27px] text-[#1D1D1D] mb-[48px] mt-[12px]">
          {{ action || 'Save' }} Asset
        </div>

        <div class="w-100">
          <image-editor
            v-if="!action && isImg"
            ref="imageEditor"
            class="mb-[12px]"
            hide-submit-btn
          />
          <b-form-group
            v-if="action ? action === 'edit' : true"
            label="Image Name"
            label-class="!text-[13px] !leading-[16px] !text-[#6E747A] !font-normal !pb-[4px]"
            class="mb-[12px]"
          >
            <b-input
              v-model="form.original_name"
              required
              class="!rounded-[3px] !h-[38px] !text-[#6E747A]"
              :class="[$v.form.original_name.$error ? '!border-red-500' : '!border-[#E2E5EC] focus:!border-[#80bdff]']"
            />
          </b-form-group>

          <b-form-group
            v-if="action ? action === 'edit' : true"
            label="Image Alt"
            label-class="!text-[13px] !leading-[16px] !text-[#6E747A] !font-normal !pb-[4px]"
            class="mb-[12px]"
          >
            <b-input
              v-model="form.alt"
              required
              class="!rounded-[3px] !h-[38px] !text-[#6E747A]"
              :class="[$v.form.alt.$error ? '!border-red-500' : '!border-[#E2E5EC] focus:!border-[#80bdff]']"
            />
          </b-form-group>

          <b-form-group
            v-if="action ? action === 'edit' : true"
            label-class="!text-[13px] !leading-[16px] !text-[#6E747A] !font-normal !pb-[4px]"
            class="mb-[12px]"
          >
            <template #label>
              <legend
                tabindex="-1"
                class="bv-no-focus-ring col-form-label pt-0 !text-[13px] !leading-[16px] !text-[#6E747A] !font-normal !pb-[4px]"
              >
                Alt Text Type

                <icon
                  id="popover-info-no-index"
                  tabindex="0"
                  icon="info-green.svg"
                >
                  Button
                </icon>
              </legend>

              <b-popover
                target="popover-info-no-index"
                triggers="focus"
              >
                <div>Descriptive is used by screen readers and helps with SEO</div>
                <div>Decorative is ignored by screen readers and not used for SEO</div>
              </b-popover>
            </template>

            <b-form-radio-group v-model="form.no_index">
              <b-form-radio
                value="0"
                required
                class="!rounded-[3px] !h-[38px] !text-[#6E747A]"
                :class="[$v.form.no_index.$error ? '!border-red-500' : '!border-[#E2E5EC] focus:!border-[#80bdff]']"
                title="Decorative"
              >
                Descriptive
              </b-form-radio>

              <b-form-radio
                value="1"
                required
                class="!rounded-[3px] !h-[38px] !text-[#6E747A]"
                :class="[$v.form.no_index.$error ? '!border-red-500' : '!border-[#E2E5EC] focus:!border-[#80bdff]']"
                title="Descriptive"
              >
                Decorative
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-row>
            <b-col v-if="action ? action === 'move': true">
              <b-form-group
                label="Select Folder"
                label-class="!text-[13px] !leading-[16px] !text-[#6E747A] !font-normal !pb-[4px] !text-[#6E747A]"
                class="pm-form-group mb-[12px]"
              >
                <div class="d-flex position-relative page-folder-tree">
                  <treeselect
                    v-model="form.folder_id"
                    :options="folders"
                    :searchable="false"
                    :normalizer="normalizer"
                    open-direction="below"
                    no-options-text="No folders created"
                    placeholder="Select folder"
                    required
                  >
                    <label
                      slot="value-label"
                      slot-scope="{ node}"
                    >
                      {{ node.raw.name }}
                    </label>

                    <label
                      slot="option-label"
                      slot-scope="{ node, shouldShowCount, count, labelClassName }"
                      :class="labelClassName"
                    >
                      {{ node.raw.name }}
                    </label>
                  </treeselect>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              v-if="!action"
              class="flex items-center"
            >
              <span class="text-[#6E747A] text-[15px] text-[18px]">or</span>
            </b-col>
          </b-row>

          <b-form-group
            v-if="!action"
            label="Create New Folder"
            label-class="!text-[13px] !leading-[16px] !text-[#6E747A] !font-normal !pb-[4px]"
            class="!mb-[0]"
          >
            <b-input
              v-model="form.new_folder_name"
              class="!rounded-[3px] !h-[38px] !text-[#6E747A] placeholder:!text-[#939393] placeholder:italic"
              :class="[$v.form.new_folder_name.$error ? '!border-red-500' : '!border-[#E2E5EC] focus:!border-[#80bdff]']"
              placeholder="enter folder name..."
            />
          </b-form-group>


          <p
            v-if="action === 'delete'"
            class="text-center"
          >
            Are you sure you want to delete this asset?
          </p>


          <div class="d-flex align-items-center justify-content-center">
            <div class="d-flex justify-content-center mt-[46px] mb-[75px]">
              <b-btn
                v-if="action === 'delete'"
                class="btn-wide mr-2"
                @click="handleModalChange(false)"
              >
                No
              </b-btn>

              <b-btn
                class="capitalize !inline-flex !text-[15px] btn-wide align-items-center"
                type="button"
                variant="primary"
                :disabled="loading"
                @click="submit"
              >
                {{ action || 'Save' }}
                <b-spinner
                  v-if="loading"
                  class="ml-2 !w-4 !h-4"
                  variant="white"
                />
              </b-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import { requiredIf } from 'vuelidate/lib/validators'
import ImageEditor from '../../editor/components/ImageEditor.vue'
export default {
  components: {
    Treeselect,
    ImageEditor
  },

  props: {
    modal: {
      type: Boolean,
      default: false
    },

    item: {
      type: Object,
      default:()=> new Object()
    },

    action: {
      type: String,
      default:()=> ''
    }
  },

  validations: {
    form: {
      original_name: {
        required: requiredIf(function () {
          return this.action !== 'move'
        })
      },

      alt: {
        required: requiredIf(function () {
          return this.action !== 'move'
        })
      },

      no_index: {
        required: requiredIf(function () {
          return this.action !== 'move'
        })
      },

      new_folder_name: {
        required: requiredIf (function () {
          return this.action ? false : !this.form.folder_id
        })
      }
    }
  },

  data () {
    return {
      form: {},
      loading:false
    }
  },

  computed: {
    ...mapState('assetsLibrary', {
      allFolders: state => state.folders,
      activeFolder: state => state.activeFolder
    }),

    isImg (){
      if(this.item.file){
        return this.item.file.type.startsWith('image')
      }

      if(this.activeFolder){
        return this.activeFolder.type === 'image'
      }

      return false
    },

    isCropableImg () {
      const type = this.get(this.form,'file.type')
      const valid = type !== 'image/gif' && type !== 'image/svg+xml'
      return valid && this.isImg
    },

    folders () {
      let data = _.cloneDeep(this.allFolders)
      data.forEach(d => {
        if(d.items && d.items.length){
          d.items = d.items.filter(i => !i.is_my_saved_images)
        }
      })
      // if(this.item.file || this.activeFolder){
      //   data = data.filter(d=> (this.isImg ? d.type == 'image' : d.type == 'pdf'))
      // }
      return data
    }
  },

  watch: {
    modal () {
      this.initForm(this.item)
    }
  },

  methods: {
    ...mapActions({
      updateFile:'assetsLibrary/updateFile',
      createFile:'assetsLibrary/createFile',
      deleteFile:'assetsLibrary/deleteFile',
      moveFileToFolder:'assetsLibrary/moveFileToFolder'
    }),

    handleModalChange (val) {
      this.$emit('update:modal',val)
    },

    initForm (params = {}) {
      let fileName = this.get(params, 'file.name')

      this.form = {
        id: params.id || '',
        original_name: params.original_name || fileName || '',
        folder_id: params.folder_id || this.folders[0].id,
        file: params.file,
        new_folder_name: '',
        alt: params.alt || (fileName || '').split('.').slice(0, -1).join('.') || '',
        no_index: params.no_index ? '1' : '0'
      }

      setTimeout(() => {
        if (this.form.file && this.modal && this.isCropableImg && this.$refs.imageEditor) {
          this.$refs.imageEditor.setImageFromFile(this.form.file)
        }
      }, 200)

      this.$v.$reset()
    },

    normalizer (node) {
      const params = {}
      params.id = node.id
      params.label = node.name
      params.children = node.items && node.items.length !== 0 ? node.items : undefined

      return params
    },

    async submit () {
      let params  = this.form
      params.new_folder_parent = this.folders[0].id
      let action = this.updateFile

      if(this.action === 'delete') {
        action = this.deleteFile
        this.handleModalChange(false)
      } else if (this.action === 'move') {
        action = this.moveFileToFolder
        this.handleModalChange(false)
      } else {
        if(this.$v.$invalid) {
          return this.$v.$touch()
        }

        if(!this.form.id) {
          action = this.createFile

          if (this.isCropableImg) {
            params.file = await this.$refs.imageEditor.getCropedImage()
            console.log('params.file :>> ', params.file)
          }

          params = this.jsonToFormData(params)
        }

        if (this.action) {
          params.action = this.action
        }
      }

      this.loading = true

      action(params).then(({data}) => {
        if (data) {
          this.$store.commit('assetsLibrary/INSERT_FILE', data.data)
        }

        this.loading = false
        this.$emit('submited')
        this.$emit('uploaded', data.data)
      }).catch((err) => {
        console.error(err)
        this.loading = false
      }).finally(() => {
        this.handleModalChange(false)
      })
    }
  }
}
</script>

<style>
  .vue-treeselect .vue-treeselect__control .vue-treeselect__control-arrow-container {
    width: 24px;
  }
</style>