<template>
  <component
    :is="tag"
    :class="[{'element-hidden': !visability}]"
    class="mb-0"
    @click="initer"
  >
    <div
      v-if="show"
      class="position-relative"
    >
      <div v-html="linksStyle" />
      <div
        v-if="textShadow.blur"
        :class="[align === 'justify' ? 'text-justify' : '', type, customClass]"
        :style="{...style}"
        class="text-ghost editor__content"
        style="overflow-wrap: anywhere;"
      >
        <div v-html="value" />
      </div>
      <editor-content
        ref="content"
        :class="[align === 'justify' ? 'text-justify' : '', type, customClass, {'text-gradient': backgroundGradient}]"
        :editor="editor"
        :style="{...style, textShadow:'none'}"
        class="editor__content"
        style="overflow-wrap: anywhere;"
      />
    </div>
  </component>
</template>

<script>
import {Editor, EditorContent} from 'tiptap'
import {Plugin, PluginKey} from 'prosemirror-state'

import {
  Blockquote,
  Bold,
  Code,
  CodeBlock,
  HardBreak,
  Heading,
  History,
  Italic,
  Strike,
  Underline
} from 'tiptap-extensions'

import TextColor from '@builder/plugins/TiptapTextColor'
import ComponentProvider from '@builder/components/mixins/ComponentProvider'
import {mapState} from 'vuex'
import FontSize from '@builder/plugins/TiptapFontSize'
import BackgroundColor from '@builder/plugins/TiptapBackgroundColor'
import FontWeight from '@builder/plugins/TiptapFontWeight'
import FontWeight2 from '@builder/plugins/TiptapFontWeight2'
import FontWeight3 from '@builder/plugins/TiptapFontWeight3'
import CustomLink from '@builder/plugins/TiptapCustomLink'
import Selection from '@builder/plugins/TiptapSelection'
import _ from 'lodash'
import ResolutionMixin from '../../mixins/ResolutionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'
import TextTransform from '@/plugins/TiptapTextTransform'
import TextGradientColor from '@/plugins/TiptapTextGradientColor'
import BackgroundMixin from '@/components/mixins/BackgroundMixin'

export default {
  name: 'ContentEditable',

  components: {
    EditorContent
  },

  mixins: [ComponentProvider, ResolutionMixin, BorderMixin, BackgroundMixin],

  props: {
    value: {
      required: true
    },
    customs: {},
    uid: String,
    type: {
      type: String,
      default: 'paragraph'
    },
    customClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      show: true,
      keepInBounds: true,
      editor: new Editor({
        extensions: [
          new Selection(),
          new Blockquote(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({levels: [1, 2, 3]}),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new TextColor(),
          new TextGradientColor(),
          new Underline(),
          new History(),
          new FontSize(),
          new BackgroundColor(),
          new FontWeight(),
          new FontWeight2(),
          new FontWeight3(),
          new CustomLink(),
          new TextTransform()
          // new TextBackground()
        ],
        onUpdate: ({getHTML}) => {
          const content = getHTML()
          this.$emit('input', content)
        },

        parseOptions: {
          preserveWhitespace: 'full'
        },
        content: this.value
      })
    }
  },

  computed: {
    transform () {
      return `rotate(${parseInt(_.get(this.customs, ['rotate', this.resolutioner], _.get(this.customs, ['rotate', 'lg'], 0)))}deg)`
    },
    linksStyle () {
      const styles = []
      const $virtualEl = document.createElement('div')
      $virtualEl.innerHTML = this.editor.getHTML()

      $virtualEl.querySelectorAll('[styleeffect]').forEach(item => {
        const _style = JSON.parse(item.getAttribute('styleeffect'))
        styles.push(`
          #${item.getAttribute('id')} {
            color:${_style.normal.color};
            font-weight:${_style.normal.bold ? 'bold' : 'inherit'};
            text-decoration:${_style.normal.underline ? 'underline' : 'inherit'};
            font-style:${_style.normal.italic ? 'italic' : 'inherit'};
          }
          #${item.getAttribute('id')}:hover {
            color:${_style.hover.color} !important;
            font-weight:${_style.hover.bold ? 'bold' : 'inherit'};
            text-decoration:${_style.hover.underline ? 'underline' : 'inherit'};
            font-style:${_style.hover.italic ? 'italic' : 'inherit'};
          }
        `)
      })

      return `
        <style type="text/css">${styles.join('')}</style>
      `
    },
    tag () {
      if (!this.customs.tag) {
        return this.type === 'headline' ? 'h3' : 'div'
      }

      return this.customs.tag
    },
    fontFamaly () {
      return _.get(this.customs, ['resolutionStyle', 'lg', 'fontFamily'], null)
    },
    color () {
      return _.get(this.customs, ['resolutionStyle', 'lg', 'color'], null)
    },
    fontSize () {
      const fontSize = parseInt(_.get(this.customs.resolutionStyle, [this.resolutioner, 'fontSize'], null) || this.customs.resolutionStyle.lg.fontSize)
      const fontSizeMetric = _.get(this.customs, ['fontSizeMetric', this.resolutioner], _.get(this.customs, ['fontSizeMetric', 'lg'], 'px'))
      return `${fontSize}${fontSizeMetric}`
    },
    backgroundGradient () {
      const _backgroundGradient = _.get(this.customs.resolutionStyle, [this.resolutioner, 'backgroundGradient'], null) || this.customs.resolutionStyle.lg.backgroundGradient
      if (_backgroundGradient) {
        const backgroundColorSubject = (attrs) => {
          if (!attrs) return null

          const gradient = () => {
            const values = attrs.map(gradient => {
              return `${gradient.color} ${gradient.position}%`
            })
            return `linear-gradient(90deg,${values})`
          }

          return gradient()
        }

        return {
          backgroundImage: backgroundColorSubject(_backgroundGradient)
        }
      }

      return null
    },
    fontWeight () {
      return _.get(this.customs, ['resolutionStyle', this.resolutioner, 'fontWeight'], null) || this.customs.resolutionStyle.lg.fontWeight
    },
    align () {
      return _.get(this.customs.resolutionStyle, [this.resolutioner, 'align'], null)
    },
    letterSpacing () {
      const _letterSpacing = _.get(this.customs, ['resolutionStyle', this.resolutioner, 'letterSpacing'], null)
      return _letterSpacing ? `${_letterSpacing}px` : null
    },
    lineHeight () {
      return _.get(this.customs.resolutionStyle, [this.resolutioner, 'lineHeight'], null) || this.customs.resolutionStyle.lg.lineHeight
    },
    textShadow () {
      return _.get(this.customs.resolutionStyle[this.resolutioner], 'textShadow', {
        enabled: false,
        x: 0,
        y: 0,
        blur: 0,
        color: '#dddddd'
      })
    },
    style () {
      return {
        fontWeight: this.fontWeight,
        color: this.color,
        textAlign: this.align,
        fontFamily: this.fontFamaly,
        fontSize: this.fontSize,
        textTransform: this.textTransform,
        textJustify: 'inter-word',
        ...this.fullPaddingSubject(this.customs, 'padding', [0, 10, 0, 0]),
        transform: this.transform,
        lineHeight: this.lineHeight,
        letterSpacing: this.letterSpacing,
        textShadow: parseInt(this.textShadow.blur) > 0 ? `${this.textShadow.x}px ${this.textShadow.y}px ${this.textShadow.blur}px ${this.textShadow.color}` : null,
        ...this.backgroundGradient
      }
    },
    ...mapState('historyChanges', {
      historyDisabled: state => state.disable
    }),
    textTransform () {
      return _.get(this.customs, ['textTransform', this.resolutioner], null) || _.get(this.customs, ['textTransform', 'lg'], null)
    }
  },

  watch: {
    historyDisabled () {
      setTimeout(() => {
        this.editor.setContent(this.value)
      })
    }
  },

  mounted () {
    VEvent.listen(this.uid, () => {
      this.initer()
    })

    this.editor.registerPlugin(new Plugin({
      key: new PluginKey('codeClearOnPaste'),
      props: {
        transformPastedHTML: function (text) {
          function stripHtml (html) {
            let tmp = document.createElement('DIV')
            tmp.innerHTML = html
            return tmp.textContent || tmp.innerText || ''
          }

          return stripHtml(text)
        }
      }
    }))
  },

  beforeDestroy () {
    this.editor.destroy()
  },

  methods: {
    initer () {
      this.$store.commit('editor/SET_TIPTAP', this.editor)
    }
  }
}
</script>

<style lang="scss">
.menububble {
  position: absolute;
  display: -webkit-box;
  display: flex;
  z-index: 20;
  background: #000;
  border-radius: 5px;
  padding: .3rem;
  margin-bottom: .5rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .2s, visibility .2s;
  transition: opacity .2s, visibility .2s
}

.menububble.is-active {
  opacity: 1;
  visibility: visible
}

.menububble__button {
  display: -webkit-inline-box;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #fff;
  padding: .2rem .5rem;
  margin-right: .2rem;
  border-radius: 3px;
  cursor: pointer
}

.menububble__button:last-child {
  margin-right: 0
}

.menububble__button:hover {
  background-color: hsla(0, 0%, 100%, .1)
}

.menububble__button.is-active {
  background-color: hsla(0, 0%, 100%, .2)
}

.menububble__form {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center
}

.menububble__input {
  font: inherit;
  border: none;
  background: transparent;
  color: #fff
}

.editor__content {
  overflow-wrap: anywhere;
}

.menububble::before {
  position: absolute;
  content: '';
  top: 100%;
  left: 0;
  background: transparent;
  height: 10px;
  width: 100%;
}
</style>
