<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element hide-copy-favorite"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('liveStream')"
  >
    <live-stream-video
      :class="{'element-hidden': !visability('liveStream')}"
      :section="section"
      :content="section.options.content.liveStream"
    />
  </highliter>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
import LiveStreamVideo from '@/components/builder/utils/LiveStreamVideo.vue'
export default {
  name: 'ElLiveStream',
  components: {
    LiveStreamVideo,
    Highliter
  },
  mixins: [SectionMixin],
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),
    isPlus () {
      return this.$store.state.editor.communityPlusSubscription || this.$store.state.editor.businessPlusSubscription || this.userRole.includes('support') || this.userRole.includes('superadministrator') || this.user.id === 4
    }
  },
  created () {
    if (!this.isPlus) {
      this.$emit('delete')
    }
  }
}
</script>