<template>
  <div :style="{...padding}">
    <div
      v-if="collapsable"
      class="px-4"
    >
      <b-button
        class="btn-icon rounded-circle mb-4"
        :style="{backgroundColor: toggleBackgroundColor}"
        @click="visible = !visible"
      >
        <span
          v-if="!visible"
          class="navbar-toggler-default"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :fill="toggleIconColor"
              d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z"
            />
          </svg>
        </span>
        <span
          v-else
          class="navbar-toggler-toggled"
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 18 18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :fill="toggleIconColor"
              d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
            />
          </svg>
        </span>
      </b-button>

      <b-collapse
        v-model="visible"
        class="mt-3 mb-3"
      >
        <div class="px-2">
          <slot />
        </div>
      </b-collapse>
    </div>

    <slot v-else />
  </div>
</template>

<script>
import _ from 'lodash'
import TemplateMixin from '@builder/components/mixins/TemplateMixin'

export default {
  name: 'Template',
  mixins: [TemplateMixin],
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      visible: false
    }
  },
  computed: {
    collapsable () {
      return _.get(this.config, 'collapsable', false)
    },
    toggleBackgroundColor () {
      return this.config.toggleBackgroundColor || '#F8FAFD'
    },
    toggleIconColor () {
      return this.config.toggleIconColor || '#1b386b'
    }
  }
}
</script>
