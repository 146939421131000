import SectionProvider from '@builder/sections/SectionProvider'
import Navbar from './Navbar'

export const name = 'Hero_004'
export const group = 'hero'
export const defaultOptions = {
  'content': {
    'items': {
      'type': null,
      'children': [
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'SbOLp44mC',
                      'display': true,
                      'name': 'Image',
                      'sid': 'ld2ki2j2--j5SuQXilB7',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': 'fade-in',
                              'once': true,
                              'offset': 200,
                              'delay': 50,
                              'duration': '900'
                            },
                            'url': '',
                            'hoverAnimation': 'hover-grow'
                          }
                        }
                      },
                      'editor': 'project'
                    },
                    {
                      'display': true,
                      'name': 'Flex Wrapper',
                      'hideFromSections': false,
                      'component': 'ElFlexWrapperV2',
                      'group': '',
                      'layout': null,
                      'options': {
                        'content': {
                          'display': true,
                          'cols': [
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'uid': 'VZSYaIxia',
                                  'display': true,
                                  'name': 'Subheadline',
                                  'sid': 'ld2ki2j2--bo8JtfMmOb',
                                  'hideFromSections': false,
                                  'component': 'ElSubheadlineV2',
                                  'group': 'Text',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'description': {
                                        'type': 'tiptap-mod-v2',
                                        'display': true,
                                        'tag': 'h4',
                                        'resolutionStyle': {
                                          'sm': {},
                                          'md': {},
                                          'lg': {}
                                        },
                                        'text': '<p><span style="color:rgba(255, 255, 255, 1)"><spane style="font-weight:400">Tony Has Appeared In:</spane></span></p>',
                                        'animations': {
                                          'animation': null,
                                          'once': true,
                                          'offset': 200,
                                          'delay': 50
                                        }
                                      }
                                    },
                                    'customize': null
                                  },
                                  'editor': 'project'
                                }
                              ],
                              'sortIndex': {
                                'lg': 0,
                                'md': 0,
                                'sm': 0
                              },
                              'uid': 'Sp6f-7UgIK',
                              'sid': 'ld2ki2j2--3GdR_7BHc4',
                              '_uid': 'Bs27DFQnf',
                              'highlight': false,
                              'animations': {
                                'animation': null,
                                'once': true,
                                'offset': 200,
                                'delay': 50
                              }
                            }
                          ],
                          'animations': {
                            'animation': null,
                            'once': true,
                            'offset': 200,
                            'delay': 50
                          }
                        }
                      },
                      'uid': 'uaYlkYd91',
                      'sid': 'ld2ki2j2--zmZ2EJwkH_',
                      'temp_id': 'j0pbRmZok'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': 'xO80y1ueWF',
                  'sid': 'ld2ki2j2--MBR89yJI6p',
                  '_uid': 'VkuzmRuehW',
                  'highlight': false,
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': 'xr2yg2ebs',
          'sid': 'ld2ki2j2--H6srJHNgre',
          'temp_id': 'j0pbRmZok'
        },
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'display': true,
                      'name': 'Flex Wrapper',
                      'hideFromSections': false,
                      'component': 'ElFlexWrapperV2',
                      'group': '',
                      'layout': null,
                      'options': {
                        'content': {
                          'display': true,
                          'cols': [
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'uid': 'V0xI-m9Hn',
                                  'display': true,
                                  'name': 'Image',
                                  'sid': 'ld2ki2j2--F4i247FUVA',
                                  'hideFromSections': false,
                                  'component': 'ElImageV3',
                                  'group': 'Media',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'image': {
                                        'display': true,
                                        'type': 'ImageModV3',
                                        'src': null,
                                        'alt': 'Image',
                                        'modal': {
                                          'data': {
                                            'modal_id': null
                                          }
                                        },
                                        'animations': {
                                          'animation': null,
                                          'once': true,
                                          'offset': 200,
                                          'delay': 50
                                        },
                                        'url': ''
                                      }
                                    }
                                  },
                                  'editor': 'project'
                                }
                              ],
                              'sortIndex': {
                                'lg': 0,
                                'md': 0,
                                'sm': 0
                              },
                              'uid': 'rjK3mk0vRh',
                              'sid': 'ld2ki2j2--dYxr5ST_fo',
                              'highlight': false,
                              '_uid': 'uTi46iVE6',
                              'animations': {
                                'animation': null,
                                'once': true,
                                'offset': 200,
                                'delay': 50
                              }
                            },
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'uid': 'Ig5degfQH',
                                  'display': true,
                                  'name': 'Image',
                                  'sid': 'd2ki2j2--jiFm9H-cG',
                                  'hideFromSections': false,
                                  'component': 'ElImageV3',
                                  'group': 'Media',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'image': {
                                        'display': true,
                                        'type': 'ImageModV3',
                                        'src': null,
                                        'alt': 'Image',
                                        'modal': {
                                          'data': {
                                            'modal_id': null
                                          }
                                        },
                                        'animations': {
                                          'animation': null,
                                          'once': true,
                                          'offset': 200,
                                          'delay': 50
                                        },
                                        'url': ''
                                      }
                                    }
                                  },
                                  'editor': 'project',
                                  '_uid': 'd2ki2j2--jiFm9H-cG'
                                }
                              ],
                              'sortIndex': {
                                'lg': 1,
                                'md': 1,
                                'sm': 1
                              },
                              'uid': 'tyGO6re1Td',
                              'sid': 'ld2ki2j2--duePjxccUB',
                              'highlight': false,
                              '_uid': 'ohPuC2a4Zm',
                              'animations': {
                                'animation': null,
                                'once': true,
                                'offset': 200,
                                'delay': 50
                              }
                            },
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'uid': 'onAoXNpk7',
                                  'display': true,
                                  'name': 'Image',
                                  'sid': 'd2ki2j2--N2bbXe0OB',
                                  'hideFromSections': false,
                                  'component': 'ElImageV3',
                                  'group': 'Media',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'image': {
                                        'display': true,
                                        'type': 'ImageModV3',
                                        'src': null,
                                        'alt': 'Image',
                                        'modal': {
                                          'data': {
                                            'modal_id': null
                                          }
                                        },
                                        'animations': {
                                          'animation': null,
                                          'once': true,
                                          'offset': 200,
                                          'delay': 50
                                        },
                                        'url': ''
                                      }
                                    }
                                  },
                                  'editor': 'project',
                                  '_uid': 'd2ki2j2--N2bbXe0OB'
                                }
                              ],
                              'sortIndex': {
                                'lg': 2,
                                'md': 2,
                                'sm': 2
                              },
                              'uid': 'hZgW51AqSR',
                              'sid': 'ld2ki2j2--nqn5o5GEZD',
                              'highlight': false,
                              '_uid': 'KClbcGuiXi',
                              'animations': {
                                'animation': null,
                                'once': true,
                                'offset': 200,
                                'delay': 50
                              }
                            },
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'uid': 'sTtgGL9ui',
                                  'display': true,
                                  'name': 'Image',
                                  'sid': 'd2ki2j2--2tUI_5OCG',
                                  'hideFromSections': false,
                                  'component': 'ElImageV3',
                                  'group': 'Media',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'image': {
                                        'display': true,
                                        'type': 'ImageModV3',
                                        'src': null,
                                        'alt': 'Image',
                                        'modal': {
                                          'data': {
                                            'modal_id': null
                                          }
                                        },
                                        'animations': {
                                          'animation': null,
                                          'once': true,
                                          'offset': 200,
                                          'delay': 50
                                        },
                                        'url': ''
                                      }
                                    }
                                  },
                                  'editor': 'project',
                                  '_uid': 'd2ki2j2--2tUI_5OCG'
                                }
                              ],
                              'sortIndex': {
                                'lg': 3,
                                'md': 3,
                                'sm': 3
                              },
                              'uid': 'dbAuOiCdVK',
                              'sid': 'ld2ki2j2--9iYvAjonAw',
                              'highlight': false,
                              '_uid': 'cEXxFTTWwY',
                              'animations': {
                                'animation': null,
                                'once': true,
                                'offset': 200,
                                'delay': 50
                              }
                            }
                          ],
                          'animations': {
                            'animation': null,
                            'once': true,
                            'offset': 200,
                            'delay': 50
                          }
                        }
                      },
                      'uid': 'Zynr4cfjh',
                      'sid': 'ld2ki2j2--vz3rpUkknY',
                      'temp_id': 'YQ479Gjit'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': 'gIEStydjlr',
                  'sid': 'ld2ki2j2--RRHjcmuCKQ',
                  '_uid': '9HO4_d8Xbc',
                  'highlight': false,
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            },
            'shapeDivider': {
              'bottom': {
                'type': {
                  'lg': 7,
                  'md': 7,
                  'sm': 7
                },
                'flip': {
                  'lg': false
                },
                'invert': {
                  'lg': '1',
                  'md': '1',
                  'sm': '#fff'
                },
                'color': '#94A3B0FF',
                'height': {
                  'lg': 165,
                  'md': 86,
                  'sm': 78
                }
              },
              'top': {
                'height': {
                  'lg': 90,
                  'md': 90,
                  'sm': 90
                }
              }
            }
          },
          'uid': 'ym88ZTma_',
          'sid': 'ld2ki2j2--ckR0CIz6DT',
          'temp_id': 'j0pbRmZok'
        },
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': 'wb-sOsW3T',
          'sid': 'ld2ki2j2--C0rOkEEfV1',
          'temp_id': 'j0pbRmZok'
        }
      ]
    },
    'lottie': {}
  },
  'backgroundImage': {
    'src': ''
  },
  'backgroundVideo': {
    'enabled': false,
    'url': '',
    'loop': true,
    'ratio': 1
  }
}

export const iframeURL = 'https://my-web-1690330974714.estage.site'

export const beforeBlock = new Navbar().json()

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('BlankV2', options)

    this.name = name
    this.group = group
    this.sid = 'ld2ki2j2--iB4GEDIfoy'
    this.sids = [
      'ld2ki2j2--iB4GEDIfoy',
      'ld2ki2j2--H6srJHNgre',
      'ld2ki2j2--MBR89yJI6p',
      'ld2ki2j2--j5SuQXilB7',
      'ld2ki2j2--zmZ2EJwkH_',
      'ld2ki2j2--3GdR_7BHc4',
      'ld2ki2j2--bo8JtfMmOb',
      'ld2ki2j2--ckR0CIz6DT',
      'ld2ki2j2--RRHjcmuCKQ',
      'ld2ki2j2--vz3rpUkknY',
      'ld2ki2j2--dYxr5ST_fo',
      'ld2ki2j2--F4i247FUVA',
      'ld2ki2j2--duePjxccUB',
      'd2ki2j2--jiFm9H-cG',
      'ld2ki2j2--nqn5o5GEZD',
      'd2ki2j2--N2bbXe0OB',
      'ld2ki2j2--9iYvAjonAw',
      'd2ki2j2--2tUI_5OCG',
      'ld2ki2j2--C0rOkEEfV1'
    ]
    this.css = [
      [
        '.--ld2ki2j2--iB4GEDIfoy--container { max-width: 100vw; padding: 0px; }',
        '.--ld2ki2j2--H6srJHNgre--flex { max-width: 100vw; --width: 100vw; }',
        '.--ld2ki2j2--MBR89yJI6p--flex-col-el { width: 100%; --width: 100%; background-color: rgba(0, 0, 0, 0); background-image: linear-gradient(rgb(124, 139, 145) 6%, rgb(148, 163, 176) 21%); }',
        '.--ld2ki2j2--j5SuQXilB7--container { justify-content: center; }',
        '.--ld2ki2j2--j5SuQXilB7--wrapper { padding: 20px 20px 0px; }',
        '.el-image.--ld2ki2j2--j5SuQXilB7--image { width: 1150px; --width: 1150px; }',
        '.--ld2ki2j2--j5SuQXilB7--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690332540/users/user-572/43efe7e74bc48dce626f059631108f83.png"); --bg-id: 167149; }',
        '.--ld2ki2j2--H6srJHNgre--flex.flex-wrapper { padding: 0px; }',
        '.--ld2ki2j2--ckR0CIz6DT--flex { max-width: 100vw; --width: 100vw; }',
        '.--ld2ki2j2--RRHjcmuCKQ--flex-col-el { width: 825px; --width: 825px; }',
        '.--ld2ki2j2--ckR0CIz6DT--bg { background-color: rgb(148, 163, 176); }',
        '.--ld2ki2j2--ckR0CIz6DT--flex.flex-wrapper { padding-bottom: 0px; padding-top: 0px; }',
        '.--ld2ki2j2--C0rOkEEfV1--flex { max-width: 80vw; --width: 80vw; height: 225px; --height: 225px; }',
        '.--ld2ki2j2--RRHjcmuCKQ--flex-col-padding { padding: 0px; }',
        '.--ld2ki2j2--MBR89yJI6p--flex-col-padding { padding-bottom: 0px; padding-left: 0px; padding-right: 0px; }',
        '.--ld2ki2j2--dYxr5ST_fo--flex-col-el { width: 100%; --width: 100%; }',
        '.--ld2ki2j2--duePjxccUB--flex-col-el { width: 100%; --width: 100%; }',
        '.--ld2ki2j2--nqn5o5GEZD--flex-col-el { width: 100%; --width: 100%; }',
        '.--ld2ki2j2--9iYvAjonAw--flex-col-el { width: 100%; --width: 100%; }',
        '.--ld2ki2j2--vz3rpUkknY--flex { max-width: 100%; --width: 100%; }',
        '.--ld2ki2j2--vz3rpUkknY--flex.flex-wrapper { padding: 0px; justify-content: center; }',
        '.--ld2ki2j2--F4i247FUVA--container { justify-content: center; }',
        '.--ld2ki2j2--F4i247FUVA--wrapper { padding: 20px; }',
        '.el-image.--ld2ki2j2--F4i247FUVA--image { width: 125px; --width: 125px; }',
        '.--ld2ki2j2--F4i247FUVA--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690333238/users/user-572/aee4c0ff2fb825eec60fc8b8320e7dda.png"); --bg-id: 167151; }',
        '.--d2ki2j2--jiFm9H-cG--container { justify-content: center; }',
        '.--d2ki2j2--jiFm9H-cG--wrapper { padding: 20px; }',
        '.el-image.--d2ki2j2--jiFm9H-cG--image { width: 193px; --width: 193px; }',
        '.--d2ki2j2--jiFm9H-cG--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690333270/users/user-572/e16b0ea0c9ff86858288f70ea2223644.png"); --bg-id: 167152; }',
        '.--d2ki2j2--2tUI_5OCG--container { justify-content: center; }',
        '.--d2ki2j2--2tUI_5OCG--wrapper { padding: 0px 20px; }',
        '.el-image.--d2ki2j2--2tUI_5OCG--image { width: 150px; --width: 150px; }',
        '.--d2ki2j2--2tUI_5OCG--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690333294/users/user-572/6eb0c5cd6b77eb41d9a05561cce85aec.png"); --bg-id: 167153; }',
        '.--d2ki2j2--N2bbXe0OB--container { justify-content: center; }',
        '.--d2ki2j2--N2bbXe0OB--wrapper { padding: 20px; }',
        '.el-image.--d2ki2j2--N2bbXe0OB--image { width: 75px; --width: 75px; }',
        '.--d2ki2j2--N2bbXe0OB--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690333319/users/user-572/f719fd8dd000ed05a3f29bc48a3faa13.png"); --bg-id: 167154; }',
        '.--ld2ki2j2--RRHjcmuCKQ--flex-col { text-align: center; justify-content: center; align-items: center; }',
        '.--ld2ki2j2--ckR0CIz6DT--container { border-top-width: 0px; border-color: rgba(184, 196, 214, 0.96); }',
        '.--ld2ki2j2--dYxr5ST_fo--flex-col-padding { padding-bottom: 0px; padding-top: 0px; }',
        '.--ld2ki2j2--duePjxccUB--flex-col-padding { padding: 0px; }',
        '.--ld2ki2j2--nqn5o5GEZD--flex-col-padding { padding-bottom: 0px; padding-top: 0px; }',
        '.--ld2ki2j2--9iYvAjonAw--flex-col-padding { padding: 0px; }',
        '.--ld2ki2j2--vz3rpUkknY--container { border-bottom-width: 1px; border-top-width: 1px; border-color: rgba(229, 231, 235, 0.41); }',
        '.--ld2ki2j2--zmZ2EJwkH_--flex { max-width: 100vw; --width: 100vw; }',
        '.--ld2ki2j2--3GdR_7BHc4--flex-col-el { width: 100%; --width: 100%; }',
        '.--ld2ki2j2--zmZ2EJwkH_--flex.flex-wrapper { padding: 0px; }',
        '.--ld2ki2j2--3GdR_7BHc4--flex-col-padding { padding: 0px; }',
        '.--ld2ki2j2--bo8JtfMmOb-text { padding: 20px; }',
        '.--ld2ki2j2--bo8JtfMmOb-text div { font-size: 15px; letter-spacing: 1px; color: rgb(87, 92, 106); }',
        '.--ld2ki2j2--C0rOkEEfV1--flex.flex-wrapper { padding-bottom: 190px; }'
      ],
      [
        '.--ld2ki2j2--H6srJHNgre--flex { max-width: 100%; --width: 100%; }',
        '.--ld2ki2j2--ckR0CIz6DT--flex { max-width: 100%; --width: 100%; }',
        '.--ld2ki2j2--C0rOkEEfV1--flex { max-width: 100%; --width: 100%; }',
        '.--ld2ki2j2--vz3rpUkknY--flex { max-width: 100%; --width: 100%; }',
        '.--ld2ki2j2--zmZ2EJwkH_--flex { max-width: 100%; --width: 100%; }'
      ],
      [
        '.--ld2ki2j2--H6srJHNgre--flex { max-width: 100%; --width: 100%; }',
        '.--ld2ki2j2--ckR0CIz6DT--flex { max-width: 100%; --width: 100%; }',
        '.--ld2ki2j2--C0rOkEEfV1--flex { max-width: 100%; --width: 100%; }',
        '.--ld2ki2j2--vz3rpUkknY--flex { max-width: 100%; --width: 100%; }',
        '.--ld2ki2j2--zmZ2EJwkH_--flex { max-width: 100%; --width: 100%; }',
        '.--ld2ki2j2--dYxr5ST_fo--flex-col-padding { padding: 0px; }',
        '.--ld2ki2j2--nqn5o5GEZD--flex-col-padding { padding: 0px; }',
        '.el-image.--d2ki2j2--N2bbXe0OB--image { width: 25px; --width: 25px; }',
        '.--ld2ki2j2--bo8JtfMmOb-text { padding-top: 0px; }',
        '.--ld2ki2j2--bo8JtfMmOb-text div { font-size: 12px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
