<template>
  <div class="menubar my-4">
    <b-form-group>
      <div class="flex items-center">
        <label class="mb-0 pb-0">Row Align</label>

        <div class="flex items-center gap-[6px] ml-auto">
          <button
            class="h-[38px] w-[38px] flex items-center justify-center hover:bg-gray-100 border rounded-[2px] p-0"
            @click="config.align = 'start'"
          >
            <svg
              fill="none"
              height="12"
              viewBox="0 0 14 12"
              width="14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :fill="config.align === 'start' ? '#3D82EA' : '#333333'"
                d="M0.583313 0.74999C0.583313 0.427824 0.84448 0.166656 1.16665 0.166656H12.8333C13.1555 0.166656 13.4166 0.427824 13.4166 0.74999C13.4166 1.07216 13.1555 1.33332 12.8333 1.33332H1.16665C0.84448 1.33332 0.583313 1.07216 0.583313 0.74999Z"
              />
              <path
                :fill="config.align === 'start' ? '#3D82EA' : '#333333'"
                clip-rule="evenodd"
                d="M1.74998 3.08332C1.74998 2.76116 2.01115 2.49999 2.33331 2.49999H5.83331C6.15548 2.49999 6.41665 2.76116 6.41665 3.08332V11.25C6.41665 11.5722 6.15548 11.8333 5.83331 11.8333H2.33331C2.01115 11.8333 1.74998 11.5722 1.74998 11.25V3.08332ZM2.91665 3.66666V10.6667H5.24998V3.66666H2.91665Z"
                fill-rule="evenodd"
              />
              <path
                :fill="config.align === 'start' ? '#3D82EA' : '#333333'"
                clip-rule="evenodd"
                d="M8.16665 2.49999C7.84448 2.49999 7.58331 2.76116 7.58331 3.08332V7.74999C7.58331 8.07216 7.84448 8.33332 8.16665 8.33332H11.6666C11.9888 8.33332 12.25 8.07216 12.25 7.74999V3.08332C12.25 2.76116 11.9888 2.49999 11.6666 2.49999H8.16665ZM8.74998 7.16666V3.66666H11.0833V7.16666H8.74998Z"
                fill-rule="evenodd"
              />
            </svg>
          </button>
          <button
            class="h-[38px] w-[38px] flex items-center justify-center hover:bg-gray-100 border rounded-[2px] p-0"
            @click="config.align = 'center'"
          >
            <svg
              fill="none"
              height="14"
              viewBox="0 0 12 14"
              width="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :fill="config.align === 'center' ? '#3D82EA' : '#333333'"
                clip-rule="evenodd"
                d="M6.58335 1.16668C6.58335 0.844511 6.32219 0.583344 6.00002 0.583344C5.67785 0.583344 5.41669 0.844511 5.41669 1.16668V1.75001H3.66669C3.34452 1.75001 3.08335 2.01118 3.08335 2.33334V5.83334C3.08335 6.15551 3.34452 6.41668 3.66669 6.41668H5.41669V7.58334H0.75002C0.427854 7.58334 0.166687 7.84451 0.166687 8.16668V11.6667C0.166687 11.9888 0.427854 12.25 0.75002 12.25H5.41669V12.8333C5.41669 13.1555 5.67785 13.4167 6.00002 13.4167C6.32219 13.4167 6.58335 13.1555 6.58335 12.8333V12.25H11.25C11.5722 12.25 11.8334 11.9888 11.8334 11.6667V8.16668C11.8334 7.84451 11.5722 7.58334 11.25 7.58334H6.58335V6.41668H8.33335C8.65552 6.41668 8.91669 6.15551 8.91669 5.83334V2.33334C8.91669 2.01118 8.65552 1.75001 8.33335 1.75001H6.58335V1.16668ZM5.41669 2.91668H4.25002V5.25001H5.41669V2.91668ZM6.58335 5.25001V2.91668H7.75002V5.25001H6.58335ZM5.41669 8.75001H1.33335V11.0833H5.41669V8.75001ZM6.58335 11.0833V8.75001H10.6667V11.0833H6.58335Z"
                fill-rule="evenodd"
              />
            </svg>
          </button>
          <button
            class="h-[38px] w-[38px] flex items-center justify-center hover:bg-gray-100 border rounded-[2px] p-0"
            @click="config.align = 'end'"
          >
            <svg
              fill="none"
              height="12"
              viewBox="0 0 14 12"
              width="14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :fill="config.align === 'end' ? '#3D82EA' : '#333333'"
                clip-rule="evenodd"
                d="M1.74998 0.74999C1.74998 0.427824 2.01115 0.166656 2.33331 0.166656H5.83331C6.15548 0.166656 6.41665 0.427824 6.41665 0.74999V8.91666C6.41665 9.23882 6.15548 9.49999 5.83331 9.49999H2.33331C2.01115 9.49999 1.74998 9.23882 1.74998 8.91666V0.74999ZM2.91665 1.33332V8.33332H5.24998V1.33332H2.91665Z"
                fill-rule="evenodd"
              />
              <path
                :fill="config.align === 'end' ? '#3D82EA' : '#333333'"
                d="M0.583313 11.25C0.583313 10.9278 0.84448 10.6667 1.16665 10.6667H12.8333C13.1555 10.6667 13.4166 10.9278 13.4166 11.25C13.4166 11.5722 13.1555 11.8333 12.8333 11.8333H1.16665C0.84448 11.8333 0.583313 11.5722 0.583313 11.25Z"
              />
              <path
                :fill="config.align === 'end' ? '#3D82EA' : '#333333'"
                clip-rule="evenodd"
                d="M8.16665 3.08332C7.84448 3.08332 7.58331 3.34449 7.58331 3.66666V8.91666C7.58331 9.23882 7.84448 9.49999 8.16665 9.49999H11.6666C11.9888 9.49999 12.25 9.23882 12.25 8.91666V3.66666C12.25 3.34449 11.9888 3.08332 11.6666 3.08332H8.16665ZM8.74998 8.33332V4.24999H11.0833V8.33332H8.74998Z"
                fill-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="noGutters">
        No Gutters
      </b-checkbox>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="hasContainer">
        Remove Container Spacing
      </b-checkbox>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="removeContainer">
        Remove Container
      </b-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'
import ControlMixin from '../../mixins/ControlMixin'

export default {
  name: 'AlignRow',

  mixins: [ControlMixin],

  computed: {
    removeContainer: {
      get () {
        return _.get(this.section.options.customize, 'removeContainer', false)
      },
      set (val) {
        this.$set(this.section.options.customize, 'removeContainer', val)
      }
    },
    noGutters: {
      get () {
        return _.get(this.section.options.customize, 'noGutters', false)
      },
      set (val) {
        this.$set(this.section.options.customize, 'noGutters', val)
      }
    },
    hasContainer: {
      get () {
        return _.get(this.section.options.customize, 'hasContainer', false)
      },
      set (val) {
        this.$set(this.section.options.customize, 'hasContainer', val)
      }
    }
  }
}
</script>

