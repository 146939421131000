<template>
  <b-form-group label="Modal">
    <v-select
      v-model="service.data.modal_id"
      :options="modals"
      :reduce="option => option.id"
      class="custom-select"
      label="name"
      left
      placeholder="Select Modal"
    >
      <template v-slot:selected-option="option">
        {{ !option.id ? 'Deleted Modal' : option.name }}
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ActionModal',
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('modal', {
      modals: state => state.modals
    })
  },
  created () {
    this.$store.dispatch('modal/fetch')
  }
}
</script>

<style scoped>
.padding-x {
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
