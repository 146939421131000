<template>
  <div class="panel-settings my-[-1px]">
    <b-btn-group class="h-[39px] w-full">
      <b-btn
        v-b-tooltip.hover
        class="w-full !hover:bg-white hover:opacity-80 flex items-start"
        :class="{'active': config.display === true}"
        squared
        title="Visible On All"
        variant="light"
        @click="() => $set(config, 'display', true)"
      >
        <span>ALL</span>
      </b-btn>
      <b-btn
        v-b-tooltip.hover
        class="w-full flex justify-content-center !hover:bg-white hover:opacity-80"
        squared
        title="Visible On Desktop"
        variant="light"
        @click="() => setDisplay('lg')"
      >
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.25 12.25H4.75"
            :stroke="displayIsActive('lg') ? '#7CACFA' : '#717171'"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.25 1H1.75C1.33579 1 1 1.33579 1 1.75V9.25C1 9.66421 1.33579 10 1.75 10H12.25C12.6642 10 13 9.66421 13 9.25V1.75C13 1.33579 12.6642 1 12.25 1Z"
            :stroke="displayIsActive('lg') ? '#7CACFA' : '#717171'"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </b-btn>
      <b-btn
        v-if="hideTablet"
        v-b-tooltip.hover
        class="w-full flex justify-content-center !hover:bg-white hover:opacity-80"
        squared
        title="Visible On Tablet"
        variant="light"
        @click="() => setDisplay('md')"
      >
        <svg
          width="14"
          height="16"
          viewBox="0 0 14 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.25 1H1.75C1.33579 1 1 1.33579 1 1.75V13.75C1 14.1642 1.33579 14.5 1.75 14.5H12.25C12.6642 14.5 13 14.1642 13 13.75V1.75C13 1.33579 12.6642 1 12.25 1Z"
            :stroke="displayIsActive('md') ? '#7CACFA' : '#717171'"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 3.25H7.002V3.252H7V3.25Z"
            :stroke="displayIsActive('md') ? '#7CACFA' : '#717171'"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </b-btn>
      <b-btn
        v-b-tooltip.hover
        class="w-full flex justify-content-center !hover:bg-white hover:opacity-80"
        squared
        title="Visible On Mobile"
        variant="light"
        @click="() => setDisplay('sm')"
      >
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.75 1H1.75C1.33579 1 1 1.33579 1 1.75V13.75C1 14.1642 1.33579 14.5 1.75 14.5H7.75C8.16421 14.5 8.5 14.1642 8.5 13.75V1.75C8.5 1.33579 8.16421 1 7.75 1Z"
            :stroke="displayIsActive('sm') ? '#7CACFA' : '#717171'"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.75 12.25H4.752V12.252H4.75V12.25Z"
            :stroke="displayIsActive('sm') ? '#7CACFA' : '#717171'"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </b-btn>
      <b-btn
        v-if="hideAll"
        v-b-tooltip.hover
        class="w-full border-right-0 flex justify-content-center !hover:bg-white hover:opacity-80"
        squared
        title="Hide On All"
        variant="light"
        @click="config.display = false"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_7663_57532)">
            <path
              d="M15.7251 7.65239C15.6928 7.61148 14.9176 6.63896 13.5885 5.66253C11.8125 4.35768 9.87997 3.66797 7.99995 3.66797C6.12 3.66797 4.18751 4.35768 2.41141 5.66253C1.08236 6.63893 0.307114 7.61148 0.274784 7.65239L0 7.99992L0.274819 8.34749C0.307149 8.38841 1.08239 9.36096 2.41144 10.3374C4.18754 11.6422 6.12003 12.3319 7.99998 12.3319C9.87997 12.3319 11.8125 11.6422 13.5885 10.3374C14.9176 9.36096 15.6928 8.38841 15.7251 8.34749L16 7.99992L15.7251 7.65239ZM7.99995 11.0347C6.32658 11.0347 4.96517 9.67332 4.96517 7.99992C4.96517 7.73775 4.99858 7.48325 5.06139 7.24041L4.36852 7.1238C4.30064 7.40489 4.26463 7.69828 4.26463 7.99992C4.26463 9.08085 4.72636 10.0558 5.46269 10.7386C4.51483 10.3884 3.70883 9.89711 3.10052 9.45276C2.34689 8.90232 1.78209 8.3456 1.46009 7.99992C1.78223 7.65414 2.34696 7.0975 3.10052 6.54709C3.70883 6.10274 4.51487 5.61142 5.46269 5.26126L5.92749 5.78519C6.47033 5.27688 7.19938 4.96515 7.99995 4.96515C9.67331 4.96515 11.0347 6.32653 11.0347 7.99992C11.0347 9.67332 9.67331 11.0347 7.99995 11.0347ZM12.8994 9.45276C12.291 9.89711 11.485 10.3884 10.5372 10.7386C11.2735 10.0559 11.7352 9.08088 11.7352 7.99989C11.7352 6.9189 11.2735 5.94393 10.5372 5.26123C11.4851 5.61139 12.291 6.10271 12.8994 6.54705C13.653 7.0975 14.2178 7.65421 14.5398 7.99985C14.2177 8.34571 13.6529 8.90239 12.8994 9.45276Z"
              :fill="config.display === false ? '#7CACFA' : '#717171'"
            />
            <path
              d="M6.06877 7.41001C6.01182 7.59667 5.98096 7.79467 5.98096 7.99996C5.98096 9.115 6.88489 10.019 7.99996 10.019C9.115 10.019 10.0189 9.11504 10.0189 7.99996C10.0189 6.88489 9.115 5.98096 7.99996 5.98096C7.45684 5.98096 6.96419 6.19581 6.60128 6.54468L7.59716 7.66721L6.06877 7.41001Z"
              :fill="config.display === false ? '#7CACFA' : '#717171'"
            />
            <line
              x1="12.4358"
              y1="-0.754869"
              x2="3.43579"
              y2="15.2451"
              :stroke="config.display === false ? '#7CACFA' : '#717171'"
            />
          </g>
          <defs>
            <clipPath id="clip0_7663_57532">
              <rect
                width="16"
                height="16"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </b-btn>
    </b-btn-group>
  </div>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'

export default {
  name: 'Panel',

  mixins: [ControlMixin],

  props: {
    hideAll: {
      type: Boolean,
      default: true
    },
    hideTablet: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      position: 'top'
    }
  },

  computed: {
    display () {
      return this.config.display
    }
  },

  methods: {
    setDisplay (resolution) {
      if (!this.config.display) {
        this.$set(this.config, 'display', true)
      }

      if (typeof this.config.display !== 'object') {
        this.$set(this.config, 'display', [])
      }

      if (!this.config.display.includes(resolution)) {
        this.config.display.push(resolution)
      } else {
        if (this.config.display.length === 1) return
        this.config.display.splice(this.config.display.findIndex(r => r === resolution), 1)
      }
    },
    displayIsActive (resolution) {
      if (typeof this.config.display === 'object') {
        return this.config.display.includes(resolution)
      }

      return false
    }
  }
}
</script>

<style lang="scss">
@import "@builder/assets/scss/_variables.scss";

.panel-settings {
  position: relative;
  width: 100%;

  .btn {
    background: #fff;
    color: #AEB1B4;
    font-size: 10px;
    font-weight: bold;
    border-left: none;

    & {
      border-left: 1px solid #E2E5EC;
    }

    &.active {
      background: #FAFBFD !important;
      color: $primary !important;
    }

    transition: none !important;

    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }
}
</style>
