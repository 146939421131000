import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import {getStylesheetEl} from '@/utils/useStylesheet'

const badRules = ['width', 'height', 'max-width', 'line-height', 'background-size', 'transform', 'transition']

export default {
  mixins: [ResolutionMixin],
  props: {
    sid: {
      type: String,
      required: true
    },
    ruleName: {
      type: String,
      required: true
    },
    reference: null,
    inlineStyles: {
      type: Boolean,
      default: false
    },
    styleEl: {
      type: String,
      default: '#sty'
    },
    presudo: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: String,
      default: null
    },
    var: {
      type: String,
      default: ''
    },
    pseudoIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      forcedStyle: null,
      sidValue: '',
      customSid: null
    }
  },
  computed: {
    sidValueMutation: {
      get () {
        return this.sidValue || this.getSidStyle()
      },
      set (val) {
        return this.sidValue = val
      }
    },
    cssRules () {
      return this.styleSheet.cssRules
    },
    styleSheet () {
      const styleEl = getStylesheetEl()

      const keys = {
        lg: 0,
        md: 1,
        sm: 2
      }

      return styleEl.sheet.cssRules[keys[this.resolutioner]]
    },
    styleSheets () {
      const styleEl = getStylesheetEl()
      return [...styleEl.sheet.cssRules]
    }
  },
  methods: {
    parseInt (val) {
      if (isNaN(parseInt(val))) {
        return ''
      }

      return parseInt(val)
    },
    getSid (sid = this.sid) {
      const sidParts = sid.split(' ')

      if (this.presudo) {
        sidParts[this.pseudoIndex] = `${sidParts[this.pseudoIndex]}${this.presudo}`
      }

      return sidParts.join(' ')
    },
    getVarValue (sid = this.sid, varName, pseudo = null, defaultValue = this.defaultValue) {
      const $el = document.querySelector(sid) || document.querySelector(this.reference)
      if (!$el) return defaultValue
      return window.getComputedStyle($el, pseudo || this.presudo).getPropertyValue(varName) || defaultValue
    },
    getSidStyle (sid = this.sid, rule = this.ruleName) {
      if (badRules.includes(rule)) {
        if (this.getVarValue(this.getSid(sid), this.var, this.presudo, this.defaultValue)) {
          return this.getVarValue(this.getSid(sid), this.var, this.presudo, this.defaultValue)
        }
      }

      const rules = this.cssRules
      const $el = document.querySelector(sid) || document.querySelector(this.reference)
      if (!$el) return ''

      let style = ''
      let j = 0
      const len = rules.length

      while (j < len) {
        if (rules[j].selectorText === this.getSid(sid) && rules[j].style[rule]) {
          return style = rules[j].style[rule]
        }

        j++
      }

      if(this.ruleName === 'position' && !style)
        style = 'static'

      if (!style) {
        style = window.getComputedStyle($el, this.presudo).getPropertyValue(rule)
      }

      return style
    },
    refreshStyleValue () {
      this.sidValue = this.getSidStyle()
    },
    getSidStyles (sid = this.sid) {
      return [...this.styleSheet.cssRules].filter(_s => {
        return _s.selectorText === this.getSid(sid)
      })
    },
    addSidRules (rules, options = {}) {
      rules.forEach((rule) => {
        let j = 1,
          selector = rule[0],
          cleanSelector = selector.replace(/::after|::before|:hover/, ''),
          propStr = ''
        if (Array.isArray(rule[1][0])) {
          rule = rule[1]
          j = 0
        }
        let pl = rule.length

        while (j < pl) {
          const prop = rule[j]
          if (this.getSidStyles(cleanSelector).length) {
            const styleObject = this.getSidStyles(cleanSelector)[0].style

            if (prop[1] !== '') {
              styleObject.setProperty(prop[0], prop[1])

              if (this.var) {
                styleObject.setProperty(this.var, options.variable || prop[1])
              }
            } else {
              styleObject.removeProperty(prop[0])

              if (this.var) {
                styleObject.removeProperty(this.var)
              }
            }

            this.sidValue = this.getSidStyle(cleanSelector)
            return
          }

          this.getSidStyles(cleanSelector).forEach(_s => {
            propStr += _s.style.cssText
          })

          propStr += `${prop[0]}: ${prop[1]}${prop[2] ? ' !important' : ''};\n`

          j++
        }

        // this.clearSidRule()
        this.styleSheet.insertRule(`${selector}{${propStr}}`, this.styleSheet.cssRules.length)
        this.sidValue = this.getSidStyle(cleanSelector)
      })
    },
    clearSidRule () {
      const index = [...this.styleSheet.cssRules].findIndex(_s => _s.selectorText === this.getSid)

      if (index !== -1) {
        this.removeSidRule(index)
      }
    },
    removeSidRule (index) {
      this.styleSheet.deleteRule(index)
    },
    forceStyleSetup () {}
  },
  watch: {
    sid () {
      this.sidValue = ''
    },
    resolutioner () {
      setTimeout(() => {
        this.sidValue = this.getSidStyle()
      }, 400)

      if (this.d) {
        this.d = null
      }
    }
  }
}
