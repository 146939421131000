<template>
  <div class="flex gap-[6px] comments-action">
    <v-select
      v-model="value"
      :searchable="false"
      placeholder="Bulk Actions"
      class="!w-[220px]"
      :reduce="val => val.key"
      label="name"
      :options="actions"
    />

    <b-btn
      variant="primary"
      class="!px-[20px]"
      @click="() => $emit('apply', value)"
    >
      Apply
    </b-btn>
  </div>
</template>

<script>
export default {
  name: 'ApplyAction',
  data () {
    return {
      value: null,
      actions: [
        {
          name: 'Reply',
          key: 'reply'
        },
        {
          name: 'Hide',
          key: 'hide'
        },
        {
          name: 'Delete',
          key: 'delete'
        },
        {
          name: 'Approve',
          key: 'approve'
        },
        {
          name: 'Reject',
          key: 'reject'
        }
      ]
    }
  }
}
</script>

<style>
.comments-action .vs__selected {
    max-width: 100% !important;
}
</style>