<template>
  <div>
    <SidbearSettingsGroup
      title="Collapsable Menu"
      active
    >
      <b-form-group
        label="Expand Menu Display"
        class="mb-0"
      >
        <b-checkbox-group
          v-model="section.options.content.menu.visability"
          stacked
          :options="[{text: 'Desktop', value: 'lg'}, {text: 'Tablet', value: 'md'}, {text: 'Mobile', value: 'sm'}]"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Hamburger Icon">
      <b-form-group label="Open icon">
        <icon-select v-model="section.options.content.menu.openIcon" />
        <sid-color
          :sid="`.--${section.sid}--open-icon`"
          rule-name="color"
        />
      </b-form-group>

      <b-form-group label="Close icon">
        <icon-select v-model="section.options.content.menu.closeIcon" />

        <sid-color
          :sid="`.--${section.sid}--close-icon`"
          :reference="`.--${section.sid}--icon`"
          rule-name="color"
        />
      </b-form-group>

      <b-form-group
        label="Hamburger Icon Size"
        label-class="!pb-[3px]"
        class="mb-0"
      >
        <sid-range-slider
          :sid="`.--${section.sid}--icon`"
          rule-name="font-size"
        />
      </b-form-group>

      <b-form-group>
        <sid-buttons-group
          label="Icon Placement"
          class="mb-0"
          :options="[
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              tooltip: 'Start',
              value: 'left'
            },
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              tooltip: 'End',
              value: 'right'
            }
          ]"
          :sid="`.--${section.sid}--icon`"
          rule-name="text-align"
        />
      </b-form-group>

      <b-form-group>
        <sid-translate
          :sid="`.--${section.sid}--icon`"
          rule-name="translate"
          :min="-100"
          :max="100"
          :step="1"
        />
      </b-form-group>
    </SidbearSettingsGroup>
    
    <menu-links-mod-v4
      :config="section.options.content.menu.subject"
      :local-section="section.options.content.menu.subject"
      component-name="Menu Links"
      :show-active-group="false"
    />
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import MenuLinksModV4 from '@/components/editor/mods/MenuLinksModV4'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import IconSelect from '@/components/editor/components/IconSelect'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup'
import SidTranslate from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTranslate'

export default {
  name: 'CollapsableMenuLinksMod',
  components: {SidTranslate, SidButtonsGroup, SidRangeSlider, SidColor, IconSelect, SidbearSettingsGroup, MenuLinksModV4},
  mixins: [ModMixin]
}
</script>