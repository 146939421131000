<template>
  <b-modal
    v-model="modal"
    dialog-class="!max-w-[800px]"
    content-class="rounded-[10px]"
    body-class="!px-[64px] !py-[48px]"
    hide-header
    hide-footer
    centered
    lazy
  >
    <i
      class="fa fa-close z-[50] absolute top-[15px] right-[17px] text-[16px] text-[#6E747A] transition cursor-pinter hover:opacity-60"
      @click="modal = false"
    />

    <div class="w-full flex flex-col items-center gap-y-[32px] font-[ProximaNova]">
      <div class="max-w-[600px] w-full flex flex-col items-center gap-[16px]">
        <h3 class="text-[32px] leading-[36px] font-[700] text-[#000000] mb-0">
          {{ update ? 'Update Payment Method' : 'Add New Payment Method' }}
        </h3>
      </div>
      
      <b-form
        class="w-full flex flex-col"
        @submit.prevent="submit"
      >
        <b-form-group
          label="Card Number"
          label-class="text-[13px] leading-[16px] font-[500] text-[#363636]"
          class="w-full"
        >
          <div class="relative w-full">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="absolute top-[13px] left-[12px]"
            >
              <path
                d="M4.75 11.875C4.31277 11.875 3.95833 12.2294 3.95833 12.6667C3.95833 13.1039 4.31277 13.4583 4.75 13.4583H5.54167C5.97889 13.4583 6.33333 13.1039 6.33333 12.6667C6.33333 12.2294 5.97889 11.875 5.54167 11.875H4.75Z"
                fill="#AAAAAA"
              />
              <path
                d="M8.70833 11.875C8.27111 11.875 7.91667 12.2294 7.91667 12.6667C7.91667 13.1039 8.27111 13.4583 8.70833 13.4583H11.875C12.3122 13.4583 12.6667 13.1039 12.6667 12.6667C12.6667 12.2294 12.3122 11.875 11.875 11.875H8.70833Z"
                fill="#AAAAAA"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 4.75C0 3.43832 1.06332 2.375 2.375 2.375H16.625C17.9367 2.375 19 3.43832 19 4.75V14.25C19 15.5617 17.9367 16.625 16.625 16.625H2.375C1.06332 16.625 0 15.5617 0 14.25V4.75ZM2.375 3.95833C1.93777 3.95833 1.58333 4.31277 1.58333 4.75V6.33333H17.4167V4.75C17.4167 4.31277 17.0622 3.95833 16.625 3.95833H2.375ZM17.4167 7.91667H1.58333V14.25C1.58333 14.6872 1.93777 15.0417 2.375 15.0417H16.625C17.0622 15.0417 17.4167 14.6872 17.4167 14.25V7.91667Z"
                fill="#AAAAAA"
              />
            </svg>

            <b-input
              v-model="form.number"
              v-mask="'#### #### #### ####'"
              v-validate="'required|min:16|max:19'"
              placeholder="**** **** **** ****"
              name="number"
              class="!h-[46px] !pl-[48px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
              :class="{'is-invalid': errors.has('number') ? true : null}"
              autocomplete="cc-number"
              x-autocompletetype="cc-number"
            />
          </div>
          <div class="inline-flex flex-shrink-0 items-center gap-[4px] mt-[.5rem]">
            <img
              src="@/assets/icons/payment-systems/visa.svg"
              class="h-[24px]"
            >
            <img
              src="@/assets/icons/payment-systems/diners-club.svg"
              class="h-[24px]"
            >
            <img
              src="@/assets/icons/payment-systems/amex.svg"
              class="h-[24px]"
            >
            <img
              src="@/assets/icons/payment-systems/discover.svg"
              class="h-[24px]"
            >
            <img
              src="@/assets/icons/payment-systems/master-card.svg"
              class="h-[24px]"
            >
          </div>
        </b-form-group>

        <div class="w-full flex items-end">
          <b-form-group
            label="Expiry Date"
            label-class="text-[13px] text-nowrap leading-[16px] font-[500] text-[#363636]"
            class="w-[72px] mb-[0]"
          >
            <b-form-input
              v-model="form.exp_month"
              v-mask="'##'"
              v-validate="'required'"
              placeholder="MM"
              name="exp_month"
              class="!h-[46px] !rounded-[4px] justify-center border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
              :class="{'is-invalid': errors.has('exp_month') ? true : null}"
              autocomplete="cc-exp cc-exp-month"
              x-autocompletetype="cc-exp cc-exp-month"
            />
          </b-form-group>

          <b-form-group
            class="w-[96px] pt-[27px] ml-[8px] mb-[0]"
          >
            <b-form-input
              v-model="form.exp_year"
              v-mask="'####'"
              v-validate="'required'"
              placeholder="YYYY"
              name="exp_year"
              class="!h-[46px] !rounded-[4px] justify-center border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
              :class="{'is-invalid': errors.has('exp_year') ? true : null}"
              autocomplete="cc-exp cc-exp-year"
              x-autocompletetype="cc-exp cc-exp-year"
            />
          </b-form-group>

          <b-form-group
            label="CVC"
            label-class="text-[13px] leading-[16px] font-[500] text-[#363636]"
            class="w-[128px] ml-[20px] mb-[0]"
          >
            <div class="relative w-full">
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute top-[15px] left-[12px] text-[#AAAAAA]"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.31818 6.18182V4.63636C2.31818 2.08636 4.40455 0 6.95455 0C9.50455 0 11.5909 2.08636 11.5909 4.63636V6.18182C12.9045 6.18182 13.9091 7.18636 13.9091 8.5V14.6818C13.9091 15.9955 12.9045 17 11.5909 17H2.31818C1.00455 17 0 15.9955 0 14.6818V8.5C0 7.18636 1.00455 6.18182 2.31818 6.18182ZM3.86364 4.63636C3.86364 2.93636 5.25455 1.54545 6.95455 1.54545C8.65454 1.54545 10.0455 2.93636 10.0455 4.63636V6.18182H3.86364V4.63636ZM2.31818 7.72727C1.85455 7.72727 1.54545 8.03636 1.54545 8.5V14.6818C1.54545 15.1455 1.85455 15.4545 2.31818 15.4545H11.5909C12.0545 15.4545 12.3636 15.1455 12.3636 14.6818V8.5C12.3636 8.03636 12.0545 7.72727 11.5909 7.72727H2.31818Z"
                  fill="currentColor"
                />
              </svg>
              <b-input
                v-model="form.cvc"
                v-mask="'####'"
                v-validate="`required|min:3|max:4`"
                placeholder="***"
                name="cvc"
                :class="{'is-invalid': errors.has('cvc') ? true : null}"
                class="!h-[46px] !pl-[40px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
                autocomplete="cc-cvc"
                x-autocompletetype="cc-cvc"
              />
            </div>
          </b-form-group>
        </div>

        <b-form-group
          class="w-full"
          label="Cardholder Name"
          label-class="text-[13px] leading-[16px] font-[500] text-[#363636]"
        >
          <b-input
            v-model="form.name"
            v-validate="`required`"
            name="name"
            :class="{'is-invalid': errors.has('name') ? true : null}"
            class="!h-[46px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
            autocomplete="cc-name"
            x-autocompletetype="cc-name"
          />
        </b-form-group>

        <div class="w-full flex items-end">
          <b-form-group
            class="w-[264px]"
            label="Country"
            label-class="text-[13px] leading-[16px] font-[500] text-[#363636]"
          >
            <country-select 
              v-model="form.country"
              v-validate="`required`"
              name="country"
              :class="{'is-invalid': errors.has('country') ? true : null}"
              class="!h-[46px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
              autocomplete="billing country"
              x-autocompletetype="billing country"
            />
          </b-form-group>

          <b-form-group
            class="w-[128px] ml-[20px]"
            label="Zip Code"
            label-class="text-[13px] leading-[16px] font-[500] text-[#363636]"
          >
            <b-input
              v-model="form.zip_code"
              v-validate="`required`"
              name="zip_code"
              :class="{'is-invalid': errors.has('zip_code') ? true : null}"
              class="!h-[46px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
              autocomplete="billing postal-code"
              x-autocompletetype="billing postal-code"
            />
          </b-form-group>
        </div>

        <b-form-group
          v-if="!setPrimary"
          class="w-full"
        >
          <b-form-checkbox v-model="form.main">
            <div class="translate-y-[-.5rem]">
              Set as primary payment method
            </div>
          </b-form-checkbox>
        </b-form-group>

        <b-btn
          variant="primary"
          class="w-full h-[60px] !font-[700] mt-[12px]"
          type="submit"
          :disabled="loading || $validator.errors.any()"
        >
          {{ update ? 'Update Payment Method' : 'Add Payment Method' }}
          <b-spinner
            v-if="loading"
            class="ml-3"
            variant="light"
            small
          />
        </b-btn>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import {VueMaskDirective} from 'v-mask'
import CountrySelect from '@/layouts/dashboard/compontens/account/general/components/CountrySelect.vue'

const defaultState = {
  number: '',
  exp_month: '',
  exp_year: '',
  cvc: '',
  name: '',
  country: '',
  zip_code: '',
  main: false
}

export default {
  name: 'AddMethodModal',
  components: {CountrySelect},
  directives: {
    mask: VueMaskDirective
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    update: {
      type: Boolean,
      default: false
    },
    setPrimary: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      expMonthsOptions: [
        {
          value: 1,
          label: 'Jan'
        },
        {
          value: 2,
          label: 'Feb'
        },
        {
          value: 3,
          label: 'Mar'
        },
        {
          value: 4,
          label: 'Apr'
        },
        {
          value: 5,
          label: 'May'
        },
        {
          value: 6,
          label: 'Jun'
        },
        {
          value: 7,
          label: 'Jul'
        },
        {
          value: 8,
          label: 'Aug'
        },
        {
          value: 9,
          label: 'Sep'
        },
        {
          value: 10,
          label: 'Oct'
        },
        {
          value: 11,
          label: 'Nov'
        },
        {
          value: 12,
          label: 'Dec'
        }
      ]
    }
  },
  computed: {
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    modal (val) {
      if (val) this.onOpen()
    }
  },
  methods: {
    onOpen () {
      const template = JSON.parse(JSON.stringify(defaultState))
      Object.keys(this.form).forEach(key => this.form[key] = template[key])
    },
    expYearsOptions (endYear) {
      let startYear = new Date().getFullYear()
      const endDate = endYear || new Date().getFullYear()
      let years = []

      while (startYear <= endDate) {
        years.push(startYear)
        startYear++
      }
      return years
    },
    submit () {
      this.$validator.validateAll()
        .then((valid) => {
          if (valid) {
            this.loading = true
            // eslint-disable-next-line no-undef
            Stripe.setPublishableKey(process.env.VUE_APP_STRIPE_KEY)

            // eslint-disable-next-line no-undef
            Stripe.createToken({
              number: this.form.number,
              cvc: this.form.cvc,
              exp_month: this.form.exp_month,
              exp_year: this.form.exp_year,
              name: this.form.name,
              address_country: this.form.country,
              address_zip: this.form.zip_code
            }, (status, response) => {
              if (status !== 200) {
                this.$swal({
                  icon: 'error',
                  title: 'Payment Declined',
                  text: response.error.message,
                  showConfirmButton: false,
                  timer: 3500
                })

                return this.loading = false
              }

              axios.post('api/user/settings/payment-methods', {
                token: response.id,
                main: this.setPrimary ? true : this.form.main
              })
                .then(() => {
                  this.modal = false

                  this.$emit('added')

                  this.$swal({
                    icon: 'success',
                    iconColor: '#4F83E3',
                    toast: true,
                    position: 'top-right',
                    title: 'Payment method has been successfully added.',
                    showConfirmButton: false,
                    timer: 3000
                  })
                })
                .catch(error => {
                  this.$swal({
                    icon: 'warning',
                    iconColor: '#4F83E3',
                    toast: true,
                    position: 'top-right',
                    title: error.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                  })
                })
                .finally(() => {
                  this.loading = false
                })

              this.$emit('submit', response)
            })
          }
        })
    }
  }
}
</script>

<style lang="scss">
.add-method-trigger {
  height: 254px;
  background: #FBFCFF;
  border: 1px solid #C2D8FF;
  box-sizing: border-box;
  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  transition: .5s;

  &:hover {
    box-shadow: none;
  }
}

.new-method-form {
  legend {
    font-size: 16px;
    font-weight: normal !important;
    color: #000 !important;
  }

  input,
  .custom-select, .form-control {
    border-radius: 2px;
  }

  .expire-date {
    display: flex;
    align-items: center;
  }

  .date-divided-control {
    input:focus {
      border-color: #E2E5EC !important;
    }

    &.is-invalid {
      .form-control {
        border-color: #dc3545 !important;
      }
    }
  }
}
</style>