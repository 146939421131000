<template>
  <div>
    <SidbearSettingsGroup title="Header">
      <b-form-group class="mb-5">
        <b-checkbox v-model="bringToFront">
          bring to front / overlay page content
        </b-checkbox>
      </b-form-group>

      <b-form-group class="mb-0">
        <sid-background :sid="`.--${section.sid}--es-navbar-bg`" />
      </b-form-group>

      <sidbear-settings-group title="Border & Radius">
        <b-form-group>
          <div class="flex items-center justify-between">
            <label class="pb-0">Border Type</label>

            <sid-select
              :sid="`.--${section.sid}--es-navbar-bg`"
              rule-name="border-style"
              :searchable="false"
              class="w-[118px]"
              :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
            />
          </div>
        </b-form-group>

        <b-form-group>
          <sid-linked-group
            :sid="`.--${section.sid}--es-navbar-bg`"
            label="Border Width"
            rule-name="border"
            class="!mb-[7px]"
            :angles="[
              {
                text: '',
                value: 'top-width'
              },
              {
                text: '',
                value: 'right-width'
              },
              {
                text: '',
                value: 'bottom-width'
              },
              {
                text: '',
                value: 'left-width'
              }
            ]"
          />

          <div class="flex w-full justify-end">
            <sid-color
              :sid="`.--${section.sid}--es-navbar-bg`"
              rule-name="border-color"
            />
          </div>
        </b-form-group>

        <sid-linked-group
          label="Radius"
          :sid="`.--${section.sid}--es-navbar-bg`"
          rule-name="border"
          class="mb-0"
          :angles="[
            {
              text: '',
              value: 'top-left-radius'
            },
            {
              text: '',
              value: 'top-right-radius'
            },
            {
              text: '',
              value: 'bottom-left-radius'
            },
            {
              text: '',
              value: 'bottom-right-radius'
            }
          ]"
        />
      </sidbear-settings-group>

      <sidbear-settings-group title="Drop Shadow">
        <b-form-group
          label="Shadow Color"
          class="mb-0"
        >
          <sid-box-shadow
            :sid="`.--${section.sid}--es-navbar-bg`"
            rule-name="box-shadow"
          />
        </b-form-group>
      </sidbear-settings-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Z-Index</label>

          <sid-input
            :sid="`.--${section.sid}--es-navbar-bg`"
            rule-name="z-index"
            default-dimension=""
            class="!w-[59px]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Position</label>

          <sid-select
            :sid="`.--${section.sid}--es-navbar-bg`"
            :priority="true"
            rule-name="position"
            :searchable="false"
            class="w-[138px]"
            :options="[
              {
                label: 'Static',
                value: ''
              },
              {
                label: 'Relative',
                value: 'relative'
              },
              {
                label: 'Fixed',
                value: 'fixed',
              },
              {
                label: 'Absolute',
                value: 'absolute',
              }
            ]"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      v-if="!section.localNavbar"
      content-class="pb-0"
      title="Scroll Effect"
    >
      <navbar-scroller-customs :config="section.options.customize.style.navbarScroller" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Navbar Layout"
      content-class="pt-0 border-0 pb-0"
      active
    >
      <flex-mod-v2
        component-name="Header Layout"
        :config="section.options.content.subject"
        :hide="['visibility']"
        :local-section="section.options.content.subject"
        :custom-back-options="{openedItem: 'MenuItemsV2Mod', section}"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import PagesPaginationSelectMixin from '@/components/editor/mixins/PagesPaginationSelectMixin'
import EventsMixin from '@/components/editor/mixins/EventsMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import NavbarScrollerCustoms from '@/components/editor/controls/NavbarScrollerCustoms'
import _ from 'lodash'
import FlexModV2 from '@/components/editor/mods/FlexModV2'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidInput from '@/components/editor/components/sid-controls/SidInput'

export default {
  name: 'ButtonMod',
  components: {
    SidLinkedGroup,
    SidColor,
    SidSelect,
    SidBoxShadow, SidBackground, FlexModV2, NavbarScrollerCustoms, SidbearSettingsGroup,
    SidInput
  },
  mixins: [ModMixin, PagesPaginationSelectMixin, EventsMixin],
  computed: {
    bringToFront: {
      get () {
        return _.get(this.config.options.content.menuItems, ['bringToFront', this.resolutioner], _.get(this.config.options.content.menuItems, ['bringToFront', 'lg'], false))
      },
      set (val) {
        const _bringToFront = typeof this.config.options.content.menuItems.bringToFront === 'object' ? this.config.options.content.menuItems.bringToFront : {}
        this.$set(this.config.options.content.menuItems, 'bringToFront', {..._.set(_bringToFront, [this.resolutioner], val)})
      }
    }
  }
}
</script>

