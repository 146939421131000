<template>
  <div
    class="video-item-2"
    :style="{...backgroundColor, ...padding}"
  >
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'VideoItems2Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
  .video-item-2 {
    padding: 71px 207px;
  }
</style>
