<template>
  <div class="flex items-center multi-select">
    <linked-control-item
      v-model="valueMutated"
      class="d-block"
      :max="type.max"
      :min="type.min"
      :style="{width}"
    />
    <v-select
      v-model="type"
      left
      label="name"
      :reduce="option => option.value"
      class="custom-select !w-[60px]"
      :options="options"
      :searchable="false"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

export default {
  name: 'MultiSelect',
  components: {LinkedControlItem},
  mixins: [ResolutionMixin],
  props: {
    width: {
      type: String,
      default: '90px'
    },
    value: {
      type: Object,
      required: true
    },
    defaultType: {
      type: Number,
      default: 0
    },
    options: {
      type: Array,
      default: () => [
        {
          name: 'px',
          value: 'px',
          max: 4000,
          min: 0,
          defaultValue: 300
        },
        {
          name: '%',
          value: '%',
          max: 100,
          min: 0,
          defaultValue: 100
        }
      ]
    }
  },
  data () {
    return {
      disabled: false
    }
  },
  computed: {
    type: {
      get () {
        const type = _.get(this.value, [this.resolutioner, 'type'], this.options[this.defaultType].value)
        return this.options.find(opt => opt.value === type)
      },
      set (val) {
        this.$emit('input', {
          ..._.set(this.value, [this.resolutioner, 'type'], val)
        })

        setTimeout(() => {
          const type = this.options.find(opt => opt.value === val)
          this.valueMutated = type.defaultValue
        })
      }
    },
    valueMutated: {
      get () {
        return _.get(this.value, [this.resolutioner, 'value'], this.options[this.defaultType].defaultValue)
      },
      set (val) {
        if (this.disabled) return
        this.$emit('input', {
          ..._.set(this.value, [this.resolutioner], {
            value: val,
            type: this.type.value
          })
        })
      }
    }
  },
  watch: {
    resolutioner () {
      this.disabled = true
      setTimeout(() => this.disabled = false, 100)
    }
  }
}
</script>

<style>
.multi-select .v-select.custom-select .vs__selected {
  text-transform: lowercase !important;
}
</style>