<template>
  <div class="dashboard-space dashboard-background min-vh-100">
    <dashboard-header />

    <!-- Topbar -->
    <dashboard-topbar title="Select a Template for Your Project" />
    <!-- End Topbar -->

    <div class="dashboard-container flex gap-[60px] w-full pt-5">
      <!-- Category Filter -->
      <template-filters
        @selected="category => filter(category)"
      />
      <!-- End Category Filter -->

      <div class="pb-[100px] w-100">
        <div class="flex flex-wrap items-center justify-between w-100 mb-[49px]">
          <h4 class="text-[16px] font-[600]">
            <span class="text-[#44474A]">{{ getTemlates.length }} Template Packs</span> <span class="text-[#B5BBC5]"> ({{ templatesData.total }} Total Template Packs)</span>
          </h4>

          <templates-search />
        </div>

        <div class="grid 2md:grid-cols-2 2lg:grid-cols-3 3xl:grid-cols-4 gap-[55px]">
          <template v-if="loading">
            <div
              v-for="n in 8"
              :key="n"
            >
              <b-skeleton
                class="w-full template-skeleton h-[298px] rounded-[4px] shadow-[0_5px_6px_rgba(0,0,0,0.13)]"
                :class="{'template-loading': loading}"
              />
            </div>
          </template>
          
          <template v-else>
            <blank-template-card
              v-if="templatesData.current_page === 1"
              @click.native="() => selectedTemplateData= {id: 1, name: 'Blank'}"
            />
            
            <div
              v-for="template in getTemlates"
              :key="template.id"
              data-aos="zoom-in"
            >
              <template-card
                :name="template.name"
                :is-free="template.is_free"
                :page-count="template.pages_count"
                :preview="template.cover_url || template.cover"
                :price="template.amount"
                :class="{'template-loading': transitionClass}"
                @click.native="() => $router.push({name: 'template', params: {id: template.id}})"
              />
              <b-skeleton
                class="w-full template-skeleton absolute top-0 left-0 h-[298px] rounded-[4px] shadow-[0_5px_6px_rgba(0,0,0,0.13)]"
                :class="{'template-loading': loading}"
              />
            </div>
          </template>
        </div>

        <div 
          v-if="templatesData" 
          class="flex w-full justify-center pb-[25px] pt-[30px]"
        >
          <nav aria-label="Page navigation">
            <ul class="list-none flex mb-0 ">
              <li :class="{ disabled: templatesData.current_page === 1 }">
                <button
                  class="flex h-[33px] w-[33px] p-0 items-center justify-center rounded-l-md border bg-[#FFFFFF] border-[#dee2e6] text-[11px] font-bold text-[#9E9EAE] hover:bg-[#F9FAFB]"
                  :disabled="templatesData.current_page === 1"
                  @click="goToPage(templatesData.current_page - 1)"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9974 5.64298H1.28906M1.28906 5.64298L6.14323 10.4972M1.28906 5.64298L6.14323 0.788818"
                      stroke="#9E9EAE"
                      stroke-width="1.38968"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </li>
              <li
                v-for="page in pageRange"
                :key="page"
                :class="{
                  'bg-[#F9FAFB] text-[#182230]': page === templatesData.current_page,
                  disabled: page === '...',
                }"
                class="list-none h-[33px] w-[33px] bg-[#FFFFFF] text-[#9E9EAE]"
              >
                <button
                  class="ml-[-1px] h-[33px] w-[33px] p-0 border border-[#dee2e6] text-[13px] hover:bg-[#F9FAFB]"
                  :disabled="page === '...'"
                  @click="goToPage(page)"
                >
                  {{ page }}
                </button>
              </li>
              <li :class="{ disabled: templatesData.current_page === templatesData.last_page }">
                <button
                  class="ml-[-1px] flex h-[33px] w-[33px] p-0 items-center justify-center rounded-r-md border bg-[#FFFFFF] border-[#dee2e6] text-[11px] font-bold text-[#9E9EAE] hover:bg-[#F9FAFB]"
                  :disabled="templatesData.current_page === templatesData.last_page"
                  @click="goToPage(templatesData.current_page + 1)"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.00293 5.64298H10.7113M10.7113 5.64298L5.8571 0.788818M10.7113 5.64298L5.8571 10.4972"
                      stroke="#9E9EAE"
                      stroke-width="1.38968"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- End Templates -->

      <create-project-modal v-model="selectedTemplateData" />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import DashboardHeader from '../../layouts/dashboard/DashboardHeader'
import DashboardTopbar from '../../layouts/dashboard/DashboardTopbar'
import TemplateCard from '../../layouts/dashboard/compontens/templates/TemplateCard'
import TemplateFilters from '../../layouts/dashboard/compontens/templates/TemplateFilters'
import CreateProjectModal from '../../layouts/dashboard/compontens/templates/CreateProjectModal'
import BlankTemplateCard from '@/layouts/dashboard/compontens/templates/BlankTemplateCard'
import TemplatesSearch from '@/layouts/dashboard/compontens/templates/TemplatesSearch'

export default {
  name: 'Dashboard',
  components: {
    TemplatesSearch,
    BlankTemplateCard,
    CreateProjectModal,
    TemplateFilters,
    TemplateCard,
    DashboardTopbar,
    DashboardHeader
  },
  data () {
    return {
      templatesLoading: true,
      selectedTemplateData: {},
      transitionClass: false,
      ids: [22, 23, 24, 26, 27, 28, 29, 30, 31, 33, 34, 35, 36, 37]
    }
  },
  computed: {
    ...mapState('templates', {
      templates: state => state.templates,
      templatesData: state => state.templatesData,
      loading: state => state.loading
    }),
    pageRange () {
      return this.createPageRange(this.templatesData.current_page, this.templatesData.last_page)
    },
    getTemlates () {
      return this.templates.filter(template => template.id !== 1)
    }
  },
  watch: {
    loading (val) {
      if (!val) {
        return setTimeout(() => {
          this.transitionClass = false
        }, 500)
      }

      this.transitionClass = true
    }
  },
  created () {
    this.filter()
  },
  methods: {
    goToPage (page) {
      if (page === '...') return
      this.templatesLoading = true
      this.$store.dispatch('templates/getTemplates', page)
        .finally(() => {
          this.templatesLoading = false
        })
    },
    createPageRange (currentPage, totalPages) {
      if (totalPages <= 4) {
        return Array.from({ length: totalPages }, (_, i) => i + 1)
      }

      const range = []
      range.push(1)

      if (currentPage > 3) {
        range.push('...')
      }

      const startPage = Math.max(2, currentPage - 1)
      const endPage = Math.min(totalPages - 1, currentPage + 1)

      for (let i = startPage; i <= endPage; i++) {
        range.push(i)
      }

      if (currentPage < totalPages - 2) {
        range.push('...')
      }

      range.push(totalPages)

      return range
    },
    filter (category) {
      this.templatesLoading = true

      if (category === 'my-templates') {
        return this.$store.dispatch('templates/getMyTemplates')
          .finally(() => {
            this.templatesLoading = false
          })
      }

      this.$store.dispatch('templates/getTemplates')
        .finally(() => {
          this.templatesLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
.dashboard-background {
  background-color: #F7F8F9;
  padding-top: 0px;
}

.block-spacing-47 {
  padding-top: 47px;
  padding-bottom: 47px;
}

.template-skeleton {
  transition: .4s;
  transform: scale(0);
  opacity: 0;

  &.template-loading {
    transform: scale(1);
    opacity: 1;
  }
}

.card-template {
  transition: .4s;

  &.template-loading {
    transform: scale(0);
    opacity: 0;
  }
}
</style>
