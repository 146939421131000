import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Field Payment Method'
export const group = ''

export default class ElFieldPaymentMethod extends SectionProvider {
  constructor (options = {}) {
    super('ElFieldPaymentMethod', options)

    this.name = name
    this.group = group
  }
}