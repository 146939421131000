var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sid-select"},[_c('SidbearSettingsGroup',{attrs:{"title":"Content & Style","active":"","content-class":"border-0"}},[_c('rule-tabs',[_c('template',{slot:"Normal"},[_c('sid-align',{attrs:{"sid":".estage-btn","rule-name":"justify-content","label":"Button Alignment"}}),_c('sid-background',{attrs:{"sid":".estage-btn"}}),_c('sid-font',{attrs:{"sid":".estage-btn"}}),_c('sid-range-slider',{attrs:{"label":"Font Size","sid":".estage-btn","rule-name":"font-size"}}),_c('sid-range-slider',{attrs:{"label":"Letter-Spacing","sid":".estage-btn","rule-name":"letter-spacing"}}),_c('sid-range-slider',{attrs:{"label":"Line-Height","sid":".estage-btn","rule-name":"line-height"}}),_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Text Color")]),_c('sid-color',{attrs:{"sid":".estage-btn","rule-name":"color"}})],1)]),_c('sid-align',{attrs:{"sid":".estage-btn","rule-name":"justify-content","label":"Text Alignment"}}),_c('b-form-group',[_c('sid-font-style',{attrs:{"sid":".estage-btn","rule-name":"font"}})],1)],1),_c('template',{slot:"Hover"},[_c('sid-range-slider',{attrs:{"label":"Text Size","sid":".estage-btn","rule-name":"font-size","presudo":":hover"}}),_c('sid-range-slider',{attrs:{"label":"Letter-Spacing","sid":".estage-btn","rule-name":"letter-spacing","presudo":":hover"}}),_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Text Color")]),_c('sid-color',{attrs:{"sid":".estage-btn","presudo":":hover","rule-name":"color"}})],1)]),_c('sid-background',{attrs:{"sid":".estage-btn","presudo":":hover"}})],1)],2)],1),_c('SidbearSettingsGroup',{attrs:{"title":"Layout"}},[_c('sid-linked-group',{attrs:{"label":"Margin","sid":".estage-btn","rule-name":"margin","min":-2000,"max":2000,"angles":[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]}}),_c('sid-linked-group',{attrs:{"label":"Padding","sid":".estage-btn","rule-name":"padding","min":0,"angles":[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]}}),_c('sid-range-slider',{attrs:{"label":"Width","sid":".estage-btn","rule-name":"width"}}),_c('sid-range-slider',{attrs:{"label":"Height","sid":".estage-btn","rule-name":"height"}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Border & Radius"}},[_c('rule-tabs',[_c('template',{slot:"Normal"},[_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Border Type")]),_c('sid-select',{staticClass:"w-[118px]",attrs:{"sid":".estage-btn","rule-name":"border-style","searchable":false,"options":[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]}})],1)]),_c('b-form-group',[_c('sid-linked-group',{staticClass:"!mb-[7px]",attrs:{"sid":".estage-btn","label":"Border Width","rule-name":"border","angles":[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]}}),_c('div',{staticClass:"flex w-full justify-end"},[_c('sid-color',{attrs:{"sid":".estage-btn","rule-name":"border-color"}})],1)],1),_c('sid-linked-group',{staticClass:"mb-0",attrs:{"label":"Radius","sid":".estage-btn","rule-name":"border","angles":[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]}})],1),_c('template',{slot:"Hover"},[_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Border Type")]),_c('sid-select',{staticClass:"w-[118px]",attrs:{"sid":".estage-btn","rule-name":"border-style","searchable":false,"presudo":":hover","options":[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]}})],1)]),_c('b-form-group',[_c('sid-linked-group',{staticClass:"!mb-[7px]",attrs:{"sid":".estage-btn","label":"Border Width","rule-name":"border","presudo":":hover","angles":[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]}}),_c('div',{staticClass:"flex w-full justify-end"},[_c('sid-color',{attrs:{"sid":".estage-btn","presudo":":hover","rule-name":"border-color"}})],1)],1),_c('sid-linked-group',{staticClass:"mb-0",attrs:{"label":"Radius","sid":".estage-btn","rule-name":"border","presudo":":hover","angles":[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]}})],1)],2)],1),_c('SidbearSettingsGroup',{attrs:{"title":"Transform"}},[_c('rule-tabs',{scopedSlots:_vm._u([{key:"Normal",fn:function(){return [_c('sid-transform',{attrs:{"sid":".estage-btn","rule-name":"transform","section":_vm.section}})]},proxy:true},{key:"Hover",fn:function(){return [_c('sid-transform',{attrs:{"sid":".estage-btn","rule-name":"transform","presudo":":hover","section":_vm.section}})]},proxy:true}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }