<template>
  <b-row class="align-items-center">
    <b-col cols="2">
      <slot />
      <color-picker-placeholder
        :color="values.color"
        :disabled="!values.mark"
        @click.native="() => values.mark ? pickedColor = values.color : null"
      />
    </b-col>

    <b-col cols="10">
      <v-select
        ref="select"
        v-model="values.mark"
        :options="options"
        :reduce="val => val.mark"
        :searchable="false"
        placeholder="Select mark"
        @option:selected="onSelect"
      >
        <template #selected-option="{label, preview}">
          <img
            :src="preview"
            class="img-fluid"
          >
        </template>

        <template #option="{label, preview}">
          <img
            :src="preview"
            class="img-fluid"
          >
        </template>
      </v-select>
    </b-col>

    <b-col
      v-if="isActive"
      class="mt-2"
      cols="6"
    >
      <b-link @click="commands.background(false)">
        Remove mark
      </b-link>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import ColorPickerPlaceholder from '../utils/ColorPickerPlaceholder'

const defaultState = {
  markId: '',
  color: '#F2E038',
  mark: ''
}
export default {
  name: 'TiptapBackgroundColor',
  components: {ColorPickerPlaceholder},
  props: {
    config: {
      type: Object,
      required: true
    },
    isActive: {
      required: true
    },
    commands: {
      required: true
    },
    marks: {
      required: true,
      default: false
    }
  },
  data () {
    return {
      localActive: false,
      values: _.clone(defaultState),
      options: [
        {
          value: 'mark-1',
          label: 'Mark 1',
          mark: 1,
          preview: require('@builder/assets/images/mark-1.svg')
        },
        {
          value: 'mark-2',
          label: 'Mark 2',
          mark: 2,
          preview: require('@builder/assets/images/mark-2.svg')
        },
        {
          value: 'mark-3',
          label: 'Mark 3',
          mark: 3,
          preview: require('@builder/assets/images/mark-3.svg')
        },
        {
          value: 'mark-4',
          label: 'Mark 4',
          mark: 4,
          preview: require('@builder/assets/images/mark-4.svg')
        },
        {
          value: 'mark-5',
          label: 'Mark 5',
          mark: 5,
          preview: require('@builder/assets/images/mark-5.svg')
        },
        {
          value: 'mark-6',
          label: 'Mark 6',
          mark: 6,
          preview: require('@builder/assets/images/mark-6.svg')
        },
        {
          value: 'mark-7',
          label: 'Mark 7',
          mark: 7,
          preview: require('@builder/assets/images/mark-7.svg')
        },
        {
          value: 'mark-8',
          label: 'Mark 8',
          mark: 8,
          preview: require('@builder/assets/images/mark-8.svg')
        },
        {
          value: 'mark-9',
          label: 'Mark 9',
          mark: 9,
          preview: require('@builder/assets/images/mark-9.svg')
        }
      ],
      timer: null
    }
  },
  computed: {
    getColor () {
      return this.values.color
    },
    pickedColor: {
      get () {
        return this.config.color
      },
      set (val) {
        this.config.color = val
      }
    }
  },
  watch: {
    pickedColor (val) {
      if (!val) return
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        if (this.values.color !== val) {
          this.values.color = this.color(val)
        }
      }, 400)
    },
    getColor (val) {
      if (!document.querySelector('.color-picker-flat')) return
      this.commands.background({
        markid: this.values.mark,
        color: this.color(val)
      })
    },
    marks: {
      deep: true,
      handler (val) {
        const value = val()

        if (this.isActive) {
          this.values.mark = parseInt(value.markid)
          this.values.markid = parseInt(value.markid)
          this.values.color = `#${value.color}`
        } else if (value && !this.isActive && !_.isEqual(this.values, defaultState)) {
          this.values = _.clone(defaultState)
        }
      }
    }
  },
  methods: {
    color (hex) {
      let color = hex
      return _.replace(color, '#', '') || ''
    },
    onSelect (val) {
      if (val.mark) {
        setTimeout(() => {
          this.commands.background({
            markid: val.mark,
            color: this.color(this.values.color)
          })
        })
      }
    }
  }
}
</script>