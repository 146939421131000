<template>
  <div
    v-if="loader"
    class="text-center mb-3 d-flex align-items-center justify-content-center preloader-overlay"
  >
    <div>
      <b-spinner
        style="width: 6rem; height: 6rem;"
        variant="primary"
      />
      <div
        v-if="message"
        class="mt-3"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Preloader',
  data () {
    return {
      loader: false,
      message: null
    }
  },

  created () {
    VEvent.listen('loader', (state) => {
      this.message = null
      this.loader = state
    })
    VEvent.listen('loader.message', message => {
      this.message = message
      this.$emit('loaded', true)
    })
  }
}
</script>

<style lang="scss">
.preloader-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1000000000000;
}
</style>
