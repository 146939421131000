<template>
  <div>
    <div class="ml-[6px]">
      <h5 class="font-semibold text-[25px] leading-[30px] text-black mb-[10px]">
        Payment Processing
      </h5>
      <p class="text-[16px] leading-[26px] text-[#6E747A] mb-[65px]">
        Collect payments on the Offers you sell in Estage. <br>
        We offer a variety of ways your members will be able to pay for your products.
      </p>

      <integration-item
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :class="{'mb-[16px]':index + 1 != items.length }"
        @submit="val => handleSubmit(val,item)"
        @toggle-status="val => handleToggleStatus(val,item)"
        @remove="val => handleDelete(val,item)"
      />
    </div>
  </div>
</template>

<script>
import IntegrationItem from '@/views/dashboard/integration/components/IntegrationItem.vue'
import IntegrationsMixin from '@/mixins/IntegrationsMixin'

export default {
  components: {IntegrationItem},
  mixins: [IntegrationsMixin],
  data () {
    return {
      items: [
        {
          integration_id: 8,
          title: 'Stripe',
          active: false,
          image: require('@/assets/images/integrations/stripe-image.png'),
          icon: require('@/assets/images/integrations/stripe-icon.png'),
          alpha_code: 'Stripe',
          data: [
            {
              label: 'Public Key',
              value: '',
              type: 'text',
              key:'public_key',
              dirty:false,
              validation: (val) => {
                return {
                  valid: !val.length ? false : true,
                  message: 'Field is required.'
                }
              }
            },
            {
              label: 'Private Key',
              value: '',
              type: 'text',
              key:'private_key',
              dirty:false,
              validation: (val) => {
                return {
                  valid: !val.length ? false : true,
                  message: 'Field is required.'
                }
              }
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>