<template>
  <div>
    <b-link
      v-if="!exceptions.includes(element.block.section.component)"
      v-b-tooltip.hover
      title="Preview"
      class="link-muted flex items-center gap-[8px]"
      @click="modal = true"
    >
      <i class="fas fa-eye text-muted" /> Preview
    </b-link>

    <b-modal
      v-model="modal"
      class="position-relative"
      lazy
      centered
      size="xl"
      hide-footer
      hide-header
    >
      <div class="absolute top-0 left-0 bg-transparent w-full h-full z-20" />

      <div class="d-flex align-items-center justify-content-center min-vh-50">
        <component
          :is="element.block.section.component"
          :section="element.block.section"
          :preview="true"
          class="w-100"
        />
        <div class="absolute w-full h-full top-0 left-0 bg-transparent" />
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'PreviewElementModal',
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      modal: false,
      exceptions: ['ElPromo']
    }
  }
}
</script>