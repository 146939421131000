<template>
  <div
    :class="{'row-resize-overlay': pressed}"
    @mousemove="mousemove"
    @mouseup="mouseup"
    @mouseleave="mouseup"
  >
    <b-row ref="calcRows">
      <b-col
        v-for="index in 12"
        :key="index"
        class="calc-rows"
      >
        {{ index }} ({{ columnsToShow }})
      </b-col>
    </b-row>

    <div>
      <slot
        name="row"
        :handlers="{selectHandler}"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ElRowSizer',

  props: {
    columnsToShow: {
      type: Number
    },
    columns: {
      type: Array
    }
  },

  data () {
    return {
      pressed: false,
      resizerColumns: [],
      positionSizer: 0,
      column: 0,
      activeColumn: null,
      currentColumn: 0,
      currentColumnOnStart: 0
    }
  },

  computed: {
    ...mapState('editor', {
      resolutionMut: state => state.resolution,
      tablet: state => state.tablet
    }),
    resolution () {
      return this.tablet ? 'md' : this.resolutionMut
    }
  },

  watch: {
    currentColumn (column) {
      if (!column || column < 0) return

      if (column !== this.column) {
        if (this.currentColumnOnStart > column) {
          if (this.columns[this.activeColumn.columnIndex][this.resolution].cols - 1 === 0) return
          this.columns[this.activeColumn.columnIndex][this.resolution].cols--
          this.currentColumnOnStart = column
        } else if (this.currentColumnOnStart < column) {
          if (this.columns[this.activeColumn.columnIndex][this.resolution].cols + 1 > 12) return
          this.columns[this.activeColumn.columnIndex][this.resolution].cols++
          this.currentColumnOnStart = column
        }
      }
    },
    positionSizer (val) {
      this.currentColumn = 12 - this.resizerColumns.filter(col => col > val).length
    }
  },

  methods: {
    selectHandler (data) {
      this.activeColumn = data
      this.pressed = true
      this.getSizers()
      this.currentColumnOnStart = 12 - this.resizerColumns.filter(col => col > data.e.clientX).length
    },
    mouseup () {
      if (!this.pressed) return
      this.pressed = false
      this.positionSizer = 0
    },
    mousemove (e) {
      if (!this.pressed) return
      this.getSizers()
      this.positionSizer = e.clientX
    },
    getSizers () {
      this.resizerColumns = [...this.$refs.calcRows.querySelectorAll('.calc-rows')]
        .map(col => {
          return parseInt(col.getBoundingClientRect().left.toFixed())
        })
    },
    updateCol () {
      const col = this.columns[this.activeColumn.columnIndex]

      if (col[this.resolution].cols === this.column || this.column === 0) return false

      if (this.column > col[this.resolution].cols) {
        this.toRight(col)
      } else {
        this.toLeft(col)
      }
    },
    toLeft (col) {
      const copiedColumns = JSON.parse(JSON.stringify(this.columns))
      copiedColumns.splice(this.activeColumn.columnIndex, 1)
      if (this.column > 0) {
        col[this.resolution].cols = this.column
      }
    },
    toRight (col) {
      const copiedColumns = JSON.parse(JSON.stringify(this.columns))
      copiedColumns.splice(this.activeColumn.columnIndex, 1)

      if (this.column > 0) {
        col[this.resolution].cols = this.column
      }
    },
    getSum (total, col) {
      if ((12 - (total + col[this.resolution].cols)) < 0) return total
      return total + col[this.resolution].cols
    },
    debug () {
      if (this.columnsToShow !== 2) return
    }
  }
}
</script>

<style lang="scss">
.calc-rows {
  opacity: 0;
  height: 0;
}

.row-resize-overlay {
  [class*="col-"] {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      z-index: 50;
    }
  }
}
</style>


