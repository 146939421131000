<template>
  <div>
    <background-type
      :config="config"
      class="!mb-[12px]"
    />

    <div class="flex gap-[19px]">
      <b-form-group
        v-if="!config.backgroundType"
        label="Color"
        label-class="pb-0 !mb-[5px]"
      >
        <color-picker
          :color="backgroundColor"
          @input="event => config.resolutionStyle['lg'].backgroundColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </b-form-group>
 
      <div class="flex items-center gap-[19px]">
        <b-form-group
          label="Text color"
          label-class="pb-0 !mb-[5px]"
        >
          <text-color
            v-model="config.resolutionStyle['lg'].color"
            :color="textColor"
          />
        </b-form-group>

        <b-form-group
          label="Subtext color"
          label-class="pb-0 !mb-[5px]"
        >
          <text-color
            v-model="config.subTextColor"
            :color="config.subTextColor"
          />
        </b-form-group>
      </div>
    </div>

    <div v-if="config.backgroundType">
      <gradient-background :config="config" />
    </div>
  </div>
</template>

<script>
import ModControlMixin from '@/components/mixins/ModControlMixin'
import GradientBackground from '@/components/editor/controls/GradientBackground'
import BackgroundType from '@/components/editor/controls/BackgroundType'
import TextColor from '@/components/editor/controls/TextColor'

export default {
  name: 'ButtonBackground',
  components: {TextColor, BackgroundType, GradientBackground},
  mixins: [ModControlMixin],
  computed: {
    backgroundColor () {
      return this.config.resolutionStyle['lg'].backgroundColor !== 'colored' ? this.config.resolutionStyle['lg'].backgroundColor : this.globalStyles.colors.buttons
    },
    colorTextEditable () {
      return this.config.resolutionStyle['lg'].btnStyle === 'filled' || !this.globalStyles.buttons.outlined && this.config.resolutionStyle['lg'].btnStyle !== 'outlined'
    },
    textColor () {
      return this.config.resolutionStyle['lg'].color !== 'adaptive'
        ? this.config.resolutionStyle['lg'].color
        : this.theme === 'dark' ? '#fff' : '#000'
    }
  }
}
</script>