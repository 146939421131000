<template>
  <b-form-group>
    <div class="flex items-center justify-between">
      <div>
        <label>Height</label>
        <sid-input-with-dimension
          ref="height"
          :sid="sid"
          rule-name="height"
          :default-value="defaultValue"
          var="--height-fixed"
          @change="val => onChange(val, 'width')"
        />
      </div>

      <div>
        <label>Width</label>
        <sid-input-with-dimension
          ref="width"
          :sid="sid"
          :rule-name="widthRule"
          :default-value="defaultValue"
          var="--width-fixed"
          @change="val => onChange(val, 'height')"
        />
      </div>
    </div>
  </b-form-group>
</template>

<script>
import SidInputWithDimension from '@/components/editor/components/sid-controls/SidInputWithDimension'
export default {
  name: 'SidWidthAndHeight',
  components: {SidInputWithDimension},
  props: {
    sid: {
      type: String,
      required: true
    },
    defaultValue: {
      type: String,
      default: ''
    },
    widthRule: {
      type: String,
      default: 'width'
    }
  },
  data () {
    return {
      linked: false
    }
  },
  methods: {
    onChange (val, type) {
      if (this.linked) {
        this.$refs[type].val = val
      }
    }
  }
}
</script>