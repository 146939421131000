<template>
  <b-card
    class="settings-page-card py-3 pl-2 pr-2 w-100"
    :body-class="bodyClass"
  >
    <slot name="title">
      <div
        v-if="title"
        class="d-flex align-items-center justify-content-between px-4"
      >
        <h5 class="title font-weight-bold mb-4">
          {{ title }}
          <span class="font-weight-normal h6">{{ subtitle }}</span>
        </h5>
      </div>
    </slot>
    <slot />
  </b-card>
</template>

<script>
export default {
  name: 'SettingsPageCard',

  props: {
    title: String,
    subtitle: String,
    bodyClass: String
  }
}
</script>

<style lang="scss">
.settings-page-card {
  border: none !important;
  margin-top: -1px;

  .title {
    font-size: 20px;
  }

  .card-body {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>