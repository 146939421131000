import interact from 'interactjs'

export default {
  bind (el, binding, vnode) {
    interact(el).resizable({
      edges: { left: false, right: true, bottom: false, top: false },
      modifiers: [
        interact.modifiers.restrictSize({
          min: { width: 200 }
        })
      ],
      listeners: {
        move (event) {
          const { right: newWidth } = event.rect

          const sidebarRect = document.querySelector('.settings-sidebar').getBoundingClientRect()
          const containerRect = el.getBoundingClientRect()

          el.style.width = newWidth - (sidebarRect.width + el.offsetLeft)  + 'px'

          el.classList.add('on-resize')

          if (containerRect.width < 1100 && containerRect.width > 520) {
            vnode.context.$store.commit('editor/SET_TABLET', true)
            vnode.context.$store.commit('editor/SET_RESOLUTION', 'sm')
          } else if (containerRect.width < 520) {
            vnode.context.$store.commit('editor/SET_TABLET', false)
            vnode.context.$store.commit('editor/SET_RESOLUTION', 'sm')
          } else {
            vnode.context.$store.commit('editor/SET_TABLET', false)
            vnode.context.$store.commit('editor/SET_RESOLUTION', 'lg')
          }

          if (vnode.componentInstance) {
            vnode.componentInstance.$emit('resize', {
              width: newWidth
            })
          } else {
            vnode.elm.dispatchEvent(new CustomEvent('resize', {
              detail: { width: newWidth }
            }))
          }
        },
        end () {
          el.classList.remove('on-resize')
        }
      },
      inertia: true
    })
      .on('resizestart', (event) => {
        const handler = document.createElement('div')
        handler.className = 'resize-handler'
        event.target.appendChild(handler)
      })
      .on('resizeend', (event) => {
        const handler = event.target.querySelector('.resize-handler')
        handler.remove()
      })
  },
  unbind (el) {
    interact(el).unset()
  }
}



// export default {
//   bind(el, binding, vnode) {
//     const minWidth = binding.value?.minWidth || 100;
//     const minHeight = binding.value?.minHeight || 100;
//
//     interact(el).resizable({
//       edges: { left: true, right: true, bottom: true, top: true },
//       modifiers: [
//         interact.modifiers.aspectRatio({
//           ratio: 'preserve',
//         }),
//         interact.modifiers.restrictSize({
//           min: { width: minWidth, height: minHeight },
//         }),
//       ],
//       listeners: {
//         move(event) {
//           const { width: newWidth, height: newHeight } = event.rect;
//
//           el.style.width = newWidth + 'px';
//           el.style.height = newHeight + 'px';
//
//           if (vnode.componentInstance) {
//             vnode.componentInstance.$emit('resize', {
//               width: newWidth,
//               height: newHeight,
//             });
//           } else {
//             vnode.elm.dispatchEvent(new CustomEvent('resize', {
//               detail: { width: newWidth, height: newHeight },
//             }));
//           }
//         },
//       },
//     });
//   },
//   unbind(el) {
//     interact(el).unset();
//   },
// };
