<template>
  <div class="border-bottom mb-4">
    <b-form-group>
      <div class="d-flex align-items-center justify-content-between">
        <label class="mb-0">Shadow</label>

        <b-checkbox
          v-model="shadowEdge.enabled"
          switch
          size="lg"
        />
      </div>
    </b-form-group>

    <template v-if="shadowEdge.enabled">
      <b-form-group label="Color">
        <color-picker
          :color="shadowEdge.color"
          @input="event => shadowEdge.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </b-form-group>

      <b-form-group label="Offset y">
        <b-row
          class="align-items-center pl-2"
          no-gutters
        >
          <b-col
            cosl="9"
            class="pr-3"
          >
            <vue-slider
              v-model="shadowEdge.y"
              class="editor-slider-1 mt-2"
              :dot-size="17"
              tooltip="none"
              :min="0"
              :max="100"
            />
          </b-col>

          <b-col cols="3">
            <b-input
              v-model="shadowEdge.y"
              size="sm"
              type="number"
              :min="-100"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label="Offset x">
        <b-row
          class="align-items-center pl-2"
          no-gutters
        >
          <b-col
            cosl="9"
            class="pr-3"
          >
            <vue-slider
              v-model="shadowEdge.x"
              class="editor-slider-1 mt-2"
              :dot-size="17"
              tooltip="none"
              :min="0"
              :max="100"
            />
          </b-col>

          <b-col cols="3">
            <b-input
              v-model="shadowEdge.x"
              size="sm"
              type="number"
              :min="-100"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label="Blur">
        <b-row
          class="align-items-center pl-2"
          no-gutters
        >
          <b-col
            cosl="9"
            class="pr-3"
          >
            <vue-slider
              v-model="shadowEdge.blur"
              class="editor-slider-1 mt-2"
              :dot-size="17"
              tooltip="none"
              :min="0"
              :max="100"
            />
          </b-col>

          <b-col cols="3">
            <b-input
              v-model="shadowEdge.blur"
              size="sm"
              type="number"
              :min="-100"
            />
          </b-col>
        </b-row>
      </b-form-group>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ShadowEdges',
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  computed: {
    shadowEdge () {
      return this.config.shadowEdge
    }
  },
  created () {
    if (!this.config.hasOwnProperty('shadowEdge')) {
      this.$set(this.config, 'shadowEdge', {
        enabled: false,
        color: '#797979',
        x: 0,
        y: 0,
        blur: 20
      })
    }
  }
}
</script>
