<template>
  <div
    v-if="visability('image')"
    class="images-group-4 d-flex justify-content-center align-items-center"
  >
    <div
      class="position-relative"
      :style="{...dropShadow}"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'ImageItem2Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.images-group-4 {
  padding-top: 30px;
  padding-bottom: 60px;

  img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }
}
</style>
