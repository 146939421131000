<template>
  <div>
    <motion-triggers
      :motion="subjectMotions"
    />

    <motion-progress
      v-model="axisXProgress"
      label="Axis-X Progress"
    />

    <motion-effects-list
      v-model="subjectMotions.mouseMove[resolutioner].x"
      :options="mouseMoveEffectOptions"
      :progress="axisXProgress"
    />

    <hr>

    <motion-progress
      v-model="axisYProgress"
      label="Axis-Y Progress"
    />

    <motion-effects-list
      v-model="subjectMotions.mouseMove[resolutioner].y"
      :options="mouseMoveEffectOptions"
      :progress="axisYProgress"
    />

    <motion-smooth
      :subject-motions="subjectMotions"
    />
  </div>
</template>

<script>
import MotionTriggers from '@/components/editor/components/motions/MotionTriggers'
import MotionProgress from '@/components/editor/components/motions/MotionProgress'
import MotionEffectsList from '@/components/editor/components/motions/MotionEffectsList'
import {mouseMoveEffectOptions} from '@/components/editor/components/motions/motions-effects/motions-effects-list'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
export default {
  name: 'MouseMoveMotion',
  components: {MotionEffectsList, MotionProgress, MotionTriggers},
  mixins: [ResolutionMixin],
  props: {
    subjectMotions: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      axisXProgress: 0,
      axisYProgress: 0,
      mouseMoveEffectOptions
    }
  }
}
</script>