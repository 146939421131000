<template>
  <div class="w-[230px]">
    <SidbearSettingsGroup
      v-for="_type in types"
      :key="_type.value"
      :active="type === _type.value"
      :title="_type.label"
      button-class="d-flex"
      @opened="() => type = _type.value"
    >
      <div class="py-[14px]">
        <font-family-and-weight-control
          :global-style-option-enable="false"
          :family="fontFamily"
          :weight="fontWeight"
          @family="family => fontFamily = family"
          @weight="weight => fontWeight = weight"
        />

        <b-form-group
          v-if="_type.value !== 'link' && _type.value !== 'link-hover'"
          label="Font Size"
        >
          <div class="flex items-center gap-[6px]">
            <v-select
              v-model="fontSize"
              :options="fontSizes()"
              class="custom-select"
              left
            />

            <color-picker
              :color="color"
              @input="event => color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
            />
          </div>
        </b-form-group>

        <b-form-group
          v-else
          label="Color"
        >
          <color-picker
            :color="color"
            @input="event => color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />
        </b-form-group>

        <b-form-group
          v-if="_type.value !== 'link-hover'"
          label="Line Height"
          label-class="!pb-[3px]"
        >
          <b-row class="align-items-center pl-2">
            <b-col
              cols="8"
            >
              <vue-slider
                v-model="lineHeight"
                :interval=".1"
                :max="3.6"
                :min="1"
                class="editor-slider-1"
                tooltip="none"
              />
            </b-col>
            <b-col>
              <b-input
                v-model="lineHeight"
                :max="3.6"
                :min="1"
                :step=".1"
                size="sm"
                type="number"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group
          v-if="_type.value !== 'link-hover'"
          label="Letter Spacing"
          label-class="!pb-[3px]"
        >
          <b-row class="align-items-center pl-2">
            <b-col
              cols="8"
            >
              <vue-slider
                v-model="letterSpacing"
                :interval="1"
                :max="20"
                :min="0"
                class="editor-slider-1"
                tooltip="none"
              />
            </b-col>
            <b-col>
              <b-input
                v-model="letterSpacing"
                :max="20"
                :min="0"
                :step="1"
                size="sm"
                type="number"
              />
            </b-col>
          </b-row>
        </b-form-group>
      </div>
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapState} from 'vuex'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import FontFamilyAndWeightControl from '@/components/editor/controls/FontFamilyAndWeightControl'

export default {
  name: 'TextSettings',
  components: {FontFamilyAndWeightControl, SidbearSettingsGroup},
  mixins: [ResolutionMixin],

  data () {
    return {
      type: 'h3',
      types: [
        {
          label: 'H3 (Default Headline)',
          value: 'h3'
        },
        {
          label: 'H1 (Headline)',
          value: 'h1'
        },
        {
          label: 'H2 (Headline)',
          value: 'h2'
        },
        {
          label: 'H4 (Subheadline)',
          value: 'h4'
        },
        {
          label: 'Paragraph',
          value: 'paragraph'
        },
        {
          label: 'Link',
          value: 'link'
        },
        {
          label: 'Link Hover',
          value: 'link-hover'
        }
      ]
    }
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    ...mapState('font', {
      fonts: state => state.fonts
    }),
    fontWeight: {
      get () {
        return this.textStyles.fontWeight[this.resolutioner]
      },
      set (val) {
        this.setValue('fontWeight', val)
      }
    },
    align: {
      get () {
        return this.textStyles.align[this.resolutioner]
      },
      set (val) {
        this.setValue('align', val)
      }
    },
    textDecoration: {
      get () {
        return this.textStyles.textDecoration[this.resolutioner]
      },
      set (val) {
        this.setValue('textDecoration', val)
      }
    },
    textStyle: {
      get () {
        return this.textStyles.textStyle[this.resolutioner]
      },
      set (val) {
        this.setValue('textStyle', val)
      }
    },
    lineHeight: {
      get () {
        return this.textStyles.lineHeight[this.resolutioner] || 1.3
      },
      set (val) {
        this.setValue('lineHeight', val)
      }
    },
    letterSpacing: {
      get () {
        return this.textStyles.letterSpacing[this.resolutioner]
      },
      set (val) {
        this.setValue('letterSpacing', val)
      }
    },
    color: {
      get () {
        return this.textStyles.color.lg
      },
      set (val) {
        this.textStyles.color.lg = val
      }
    },
    fontSize: {
      get () {
        return this.textStyles.fontSize[this.resolutioner]
      },
      set (val) {
        this.setValue('fontSize', val)
      }
    },
    fontFamily: {
      get () {
        return this.textStyles.fontFamily[this.resolutioner]
      },
      set (val) {
        this.setValue('fontFamily', val)
      }
    },
    textStyles () {
      if (!this.globalStyles.textStyles) {
        this.$set(this.globalStyles, 'textStyles', {
          'h1': {
            fontFamily: {
              lg: _.get(this.globalStyles, ['headline', 'font', 'lg'], 'Roboto'),
              md: _.get(this.globalStyles, ['headline', 'font', 'md'], 'Roboto'),
              sm: _.get(this.globalStyles, ['headline', 'font', 'sm'], 'Roboto')
            },
            fontWeight: {
              lg: _.get(this.globalStyles, ['headline', 'fontWeight', 'lg'], 600),
              md: _.get(this.globalStyles, ['headline', 'fontWeight', 'md'], 600),
              sm: _.get(this.globalStyles, ['headline', 'fontWeight', 'sm'], 600)
            },
            fontSize: {
              lg: _.get(this.globalStyles, ['headline', 'fontSize', 'lg'], '48px'),
              md: _.get(this.globalStyles, ['headline', 'fontSize', 'md'], '48px'),
              sm: _.get(this.globalStyles, ['headline', 'fontSize', 'sm'], '34px')
            },
            color: {
              lg: _.get(this.globalStyles, ['headline', 'color', 'lg'], '#0C226C'),
              md: _.get(this.globalStyles, ['headline', 'color', 'md'], '#0C226C'),
              sm: _.get(this.globalStyles, ['headline', 'color', 'sm'], '#0C226C')
            },
            align: {
              lg: _.get(this.globalStyles, ['headline', 'align', 'lg'], 'center'),
              md: _.get(this.globalStyles, ['headline', 'align', 'md'], 'center'),
              sm: _.get(this.globalStyles, ['headline', 'align', 'sm'], 'center')
            },
            textDecoration: {
              lg: _.get(this.globalStyles, ['headline', 'underline', 'lg'], '') === true ? 'underline' : '',
              md: _.get(this.globalStyles, ['headline', 'underline', 'md'], '') === true ? 'underline' : '',
              sm: _.get(this.globalStyles, ['headline', 'underline', 'sm'], '') === true ? 'underline' : ''
            },
            textStyle: {
              lg: _.get(this.globalStyles, ['headline', 'italic', 'lg'], '') === true ? 'italic' : '',
              md: _.get(this.globalStyles, ['headline', 'italic', 'md'], '') === true ? 'italic' : '',
              sm: _.get(this.globalStyles, ['headline', 'italic', 'sm'], '') === true ? 'italic' : ''
            },
            lineHeight: {
              lg: 1.3,
              md: 1.3,
              sm: 1.3
            },
            letterSpacing: {
              lg: 0,
              md: 0,
              sm: 0
            }
          },
          'h2': {
            fontFamily: {
              lg: _.get(this.globalStyles, ['headline', 'font', 'lg'], 'Roboto'),
              md: _.get(this.globalStyles, ['headline', 'font', 'md'], 'Roboto'),
              sm: _.get(this.globalStyles, ['headline', 'font', 'sm'], 'Roboto')
            },
            fontWeight: {
              lg: _.get(this.globalStyles, ['headline', 'fontWeight', 'lg'], 600),
              md: _.get(this.globalStyles, ['headline', 'fontWeight', 'md'], 600),
              sm: _.get(this.globalStyles, ['headline', 'fontWeight', 'sm'], 600)
            },
            fontSize: {
              lg: _.get(this.globalStyles, ['headline', 'fontSize', 'lg'], '48px'),
              md: _.get(this.globalStyles, ['headline', 'fontSize', 'md'], '48px'),
              sm: _.get(this.globalStyles, ['headline', 'fontSize', 'sm'], '34px')
            },
            color: {
              lg: _.get(this.globalStyles, ['headline', 'color', 'lg'], '#0C226C'),
              md: _.get(this.globalStyles, ['headline', 'color', 'md'], '#0C226C'),
              sm: _.get(this.globalStyles, ['headline', 'color', 'sm'], '#0C226C')
            },
            align: {
              lg: _.get(this.globalStyles, ['headline', 'align', 'lg'], 'center'),
              md: _.get(this.globalStyles, ['headline', 'align', 'md'], 'center'),
              sm: _.get(this.globalStyles, ['headline', 'align', 'sm'], 'center')
            },
            textDecoration: {
              lg: _.get(this.globalStyles, ['headline', 'underline', 'lg'], '') === true ? 'underline' : '',
              md: _.get(this.globalStyles, ['headline', 'underline', 'md'], '') === true ? 'underline' : '',
              sm: _.get(this.globalStyles, ['headline', 'underline', 'sm'], '') === true ? 'underline' : ''
            },
            textStyle: {
              lg: _.get(this.globalStyles, ['headline', 'italic', 'lg'], '') === true ? 'italic' : '',
              md: _.get(this.globalStyles, ['headline', 'italic', 'md'], '') === true ? 'italic' : '',
              sm: _.get(this.globalStyles, ['headline', 'italic', 'sm'], '') === true ? 'italic' : ''
            },
            lineHeight: {
              lg: 1.3,
              md: 1.3,
              sm: 1.3
            },
            letterSpacing: {
              lg: 0,
              md: 0,
              sm: 0
            }
          },
          'h3': {
            fontFamily: {
              lg: _.get(this.globalStyles, ['headline', 'font', 'lg'], 'Roboto'),
              md: _.get(this.globalStyles, ['headline', 'font', 'md'], 'Roboto'),
              sm: _.get(this.globalStyles, ['headline', 'font', 'sm'], 'Roboto')
            },
            fontWeight: {
              lg: _.get(this.globalStyles, ['headline', 'fontWeight', 'lg'], 600),
              md: _.get(this.globalStyles, ['headline', 'fontWeight', 'md'], 600),
              sm: _.get(this.globalStyles, ['headline', 'fontWeight', 'sm'], 600)
            },
            fontSize: {
              lg: _.get(this.globalStyles, ['headline', 'fontSize', 'lg'], '48px'),
              md: _.get(this.globalStyles, ['headline', 'fontSize', 'md'], '48px'),
              sm: _.get(this.globalStyles, ['headline', 'fontSize', 'sm'], '34px')
            },
            color: {
              lg: _.get(this.globalStyles, ['headline', 'color', 'lg'], '#0C226C'),
              md: _.get(this.globalStyles, ['headline', 'color', 'md'], '#0C226C'),
              sm: _.get(this.globalStyles, ['headline', 'color', 'sm'], '#0C226C')
            },
            align: {
              lg: _.get(this.globalStyles, ['headline', 'align', 'lg'], 'center'),
              md: _.get(this.globalStyles, ['headline', 'align', 'md'], 'center'),
              sm: _.get(this.globalStyles, ['headline', 'align', 'sm'], 'center')
            },
            textDecoration: {
              lg: _.get(this.globalStyles, ['headline', 'underline', 'lg'], '') === true ? 'underline' : '',
              md: _.get(this.globalStyles, ['headline', 'underline', 'md'], '') === true ? 'underline' : '',
              sm: _.get(this.globalStyles, ['headline', 'underline', 'sm'], '') === true ? 'underline' : ''
            },
            textStyle: {
              lg: _.get(this.globalStyles, ['headline', 'italic', 'lg'], '') === true ? 'italic' : '',
              md: _.get(this.globalStyles, ['headline', 'italic', 'md'], '') === true ? 'italic' : '',
              sm: _.get(this.globalStyles, ['headline', 'italic', 'sm'], '') === true ? 'italic' : ''
            },
            lineHeight: {
              lg: 1.3,
              md: 1.3,
              sm: 1.3
            },
            letterSpacing: {
              lg: 0,
              md: 0,
              sm: 0
            }
          },
          'h4': {
            fontFamily: {
              lg: _.get(this.globalStyles, ['headline', 'font', 'lg'], 'Roboto'),
              md: _.get(this.globalStyles, ['headline', 'font', 'md'], 'Roboto'),
              sm: _.get(this.globalStyles, ['headline', 'font', 'sm'], 'Roboto')
            },
            fontWeight: {
              lg: _.get(this.globalStyles, ['headline', 'fontWeight', 'lg'], 600),
              md: _.get(this.globalStyles, ['headline', 'fontWeight', 'md'], 600),
              sm: _.get(this.globalStyles, ['headline', 'fontWeight', 'sm'], 600)
            },
            fontSize: {
              lg: _.get(this.globalStyles, ['headline', 'fontSize', 'lg'], '48px'),
              md: _.get(this.globalStyles, ['headline', 'fontSize', 'md'], '48px'),
              sm: _.get(this.globalStyles, ['headline', 'fontSize', 'sm'], '34px')
            },
            color: {
              lg: _.get(this.globalStyles, ['headline', 'color', 'lg'], '#0C226C'),
              md: _.get(this.globalStyles, ['headline', 'color', 'md'], '#0C226C'),
              sm: _.get(this.globalStyles, ['headline', 'color', 'sm'], '#0C226C')
            },
            align: {
              lg: _.get(this.globalStyles, ['headline', 'align', 'lg'], 'center'),
              md: _.get(this.globalStyles, ['headline', 'align', 'md'], 'center'),
              sm: _.get(this.globalStyles, ['headline', 'align', 'sm'], 'center')
            },
            textDecoration: {
              lg: _.get(this.globalStyles, ['headline', 'underline', 'lg'], '') === true ? 'underline' : '',
              md: _.get(this.globalStyles, ['headline', 'underline', 'md'], '') === true ? 'underline' : '',
              sm: _.get(this.globalStyles, ['headline', 'underline', 'sm'], '') === true ? 'underline' : ''
            },
            textStyle: {
              lg: _.get(this.globalStyles, ['headline', 'italic', 'lg'], '') === true ? 'italic' : '',
              md: _.get(this.globalStyles, ['headline', 'italic', 'md'], '') === true ? 'italic' : '',
              sm: _.get(this.globalStyles, ['headline', 'italic', 'sm'], '') === true ? 'italic' : ''
            },
            lineHeight: {
              lg: 1.3,
              md: 1.3,
              sm: 1.3
            },
            letterSpacing: {
              lg: 0,
              md: 0,
              sm: 0
            }
          },
          'paragraph': {
            fontFamily: {
              lg: _.get(this.globalStyles, ['paragpraph', 'font', 'lg'], 'Roboto'),
              md: _.get(this.globalStyles, ['paragpraph', 'font', 'md'], 'Roboto'),
              sm: _.get(this.globalStyles, ['paragpraph', 'font', 'sm'], 'Roboto')
            },
            fontWeight: {
              lg: _.get(this.globalStyles, ['paragpraph', 'fontWeight', 'lg'], 400),
              md: _.get(this.globalStyles, ['paragpraph', 'fontWeight', 'md'], 400),
              sm: _.get(this.globalStyles, ['paragpraph', 'fontWeight', 'sm'], 400)
            },
            fontSize: {
              lg: _.get(this.globalStyles, ['paragpraph', 'fontSize', 'lg'], '18px'),
              md: _.get(this.globalStyles, ['paragpraph', 'fontSize', 'md'], '18px'),
              sm: _.get(this.globalStyles, ['paragpraph', 'fontSize', 'sm'], '18px')
            },
            color: {
              lg: _.get(this.globalStyles, ['paragpraph', 'color', 'lg'], '#383838'),
              md: _.get(this.globalStyles, ['paragpraph', 'color', 'md'], '#383838'),
              sm: _.get(this.globalStyles, ['paragpraph', 'color', 'sm'], '#383838')
            },
            align: {
              lg: _.get(this.globalStyles, ['paragpraph', 'align', 'lg'], 'center'),
              md: _.get(this.globalStyles, ['paragpraph', 'align', 'md'], 'center'),
              sm: _.get(this.globalStyles, ['paragpraph', 'align', 'sm'], 'center')
            },
            textDecoration: {
              lg: _.get(this.globalStyles, ['paragpraph', 'underline', 'lg'], '') === true ? 'underline' : '',
              md: _.get(this.globalStyles, ['paragpraph', 'underline', 'md'], '') === true ? 'underline' : '',
              sm: _.get(this.globalStyles, ['paragpraph', 'underline', 'sm'], '') === true ? 'underline' : ''
            },
            textStyle: {
              lg: _.get(this.globalStyles, ['paragpraph', 'italic', 'lg'], '') === true ? 'italic' : '',
              md: _.get(this.globalStyles, ['paragpraph', 'italic', 'md'], '') === true ? 'italic' : '',
              sm: _.get(this.globalStyles, ['paragpraph', 'italic', 'sm'], '') === true ? 'italic' : ''
            },
            lineHeight: {
              lg: 1.3,
              md: 1.3,
              sm: 1.3
            },
            letterSpacing: {
              lg: 0,
              md: 0,
              sm: 0
            }
          },
          'link': {
            fontFamily: {
              lg: _.get(this.globalStyles, ['link', 'font', 'lg'], 'Roboto'),
              md: _.get(this.globalStyles, ['link', 'font', 'md'], 'Roboto'),
              sm: _.get(this.globalStyles, ['link', 'font', 'sm'], 'Roboto')
            },
            fontWeight: {
              lg: _.get(this.globalStyles, ['link', 'fontWeight', 'lg'], 400),
              md: _.get(this.globalStyles, ['link', 'fontWeight', 'md'], 400),
              sm: _.get(this.globalStyles, ['link', 'fontWeight', 'sm'], 400)
            },
            fontSize: {
              lg: _.get(this.globalStyles, ['link', 'fontSize', 'lg'], '18px'),
              md: _.get(this.globalStyles, ['link', 'fontSize', 'md'], '18px'),
              sm: _.get(this.globalStyles, ['link', 'fontSize', 'sm'], '18px')
            },
            color: {
              lg: _.get(this.globalStyles, ['link', 'color', 'lg'], '#7CACFA'),
              md: _.get(this.globalStyles, ['link', 'color', 'md'], '#7CACFA'),
              sm: _.get(this.globalStyles, ['link', 'color', 'sm'], '#7CACFA')
            },
            align: {
              lg: _.get(this.globalStyles, ['link', 'align', 'lg'], 'center'),
              md: _.get(this.globalStyles, ['link', 'align', 'md'], 'center'),
              sm: _.get(this.globalStyles, ['link', 'align', 'sm'], 'center')
            },
            textDecoration: {
              lg: _.get(this.globalStyles, ['link', 'underline', 'lg'], '') === true ? 'underline' : '',
              md: _.get(this.globalStyles, ['link', 'underline', 'md'], '') === true ? 'underline' : '',
              sm: _.get(this.globalStyles, ['link', 'underline', 'sm'], '') === true ? 'underline' : ''
            },
            textStyle: {
              lg: _.get(this.globalStyles, ['paragpraph', 'italic', 'lg'], '') === true ? 'italic' : '',
              md: _.get(this.globalStyles, ['paragpraph', 'italic', 'md'], '') === true ? 'italic' : '',
              sm: _.get(this.globalStyles, ['paragpraph', 'italic', 'sm'], '') === true ? 'italic' : ''
            },
            lineHeight: {
              lg: 1.3,
              md: 1.3,
              sm: 1.3
            },
            letterSpacing: {
              lg: 0,
              md: 0,
              sm: 0
            }
          },
          'link-hover': {
            fontFamily: {
              lg: _.get(this.globalStyles, ['link', 'font', 'lg'], 'Roboto'),
              md: _.get(this.globalStyles, ['link', 'font', 'md'], 'Roboto'),
              sm: _.get(this.globalStyles, ['link', 'font', 'sm'], 'Roboto')
            },
            fontWeight: {
              lg: _.get(this.globalStyles, ['link', 'fontWeight', 'lg'], 400),
              md: _.get(this.globalStyles, ['link', 'fontWeight', 'md'], 400),
              sm: _.get(this.globalStyles, ['link', 'fontWeight', 'sm'], 400)
            },
            fontSize: {
              lg: _.get(this.globalStyles, ['link', 'fontSize', 'lg'], '18px'),
              md: _.get(this.globalStyles, ['link', 'fontSize', 'md'], '18px'),
              sm: _.get(this.globalStyles, ['link', 'fontSize', 'sm'], '18px')
            },
            color: {
              lg: _.get(this.globalStyles, ['link', 'color', 'lg'], '#5f8edc'),
              md: _.get(this.globalStyles, ['link', 'color', 'md'], '#5f8edc'),
              sm: _.get(this.globalStyles, ['link', 'color', 'sm'], '#5f8edc')
            },
            align: {
              lg: _.get(this.globalStyles, ['link', 'align', 'lg'], 'center'),
              md: _.get(this.globalStyles, ['link', 'align', 'md'], 'center'),
              sm: _.get(this.globalStyles, ['link', 'align', 'sm'], 'center')
            },
            textDecoration: {
              lg: _.get(this.globalStyles, ['link', 'underline', 'lg'], '') === true ? 'underline' : '',
              md: _.get(this.globalStyles, ['link', 'underline', 'md'], '') === true ? 'underline' : '',
              sm: _.get(this.globalStyles, ['link', 'underline', 'sm'], '') === true ? 'underline' : ''
            },
            textStyle: {
              lg: _.get(this.globalStyles, ['paragpraph', 'italic', 'lg'], '') === true ? 'italic' : '',
              md: _.get(this.globalStyles, ['paragpraph', 'italic', 'md'], '') === true ? 'italic' : '',
              sm: _.get(this.globalStyles, ['paragpraph', 'italic', 'sm'], '') === true ? 'italic' : ''
            },
            lineHeight: {
              lg: 1.3,
              md: 1.3,
              sm: 1.3
            },
            letterSpacing: {
              lg: 0,
              md: 0,
              sm: 0
            }
          }
        })
      }

      if (!this.globalStyles.textStyles.h4) {
        this.$set(this.globalStyles.textStyles, 'h4', {
          fontFamily: {
            lg: _.get(this.globalStyles, ['headline', 'font', 'lg'], 'Roboto'),
            md: _.get(this.globalStyles, ['headline', 'font', 'md'], 'Roboto'),
            sm: _.get(this.globalStyles, ['headline', 'font', 'sm'], 'Roboto')
          },
          fontWeight: {
            lg: _.get(this.globalStyles, ['headline', 'fontWeight', 'lg'], 400),
            md: _.get(this.globalStyles, ['headline', 'fontWeight', 'md'], 400),
            sm: _.get(this.globalStyles, ['headline', 'fontWeight', 'sm'], 400)
          },
          fontSize: {
            lg: _.get(this.globalStyles, ['headline', 'fontSize', 'lg'], '48px'),
            md: _.get(this.globalStyles, ['headline', 'fontSize', 'md'], '48px'),
            sm: _.get(this.globalStyles, ['headline', 'fontSize', 'sm'], '34px')
          },
          color: {
            lg: _.get(this.globalStyles, ['headline', 'color', 'lg'], '#0C226C'),
            md: _.get(this.globalStyles, ['headline', 'color', 'md'], '#0C226C'),
            sm: _.get(this.globalStyles, ['headline', 'color', 'sm'], '#0C226C')
          },
          align: {
            lg: _.get(this.globalStyles, ['headline', 'align', 'lg'], 'center'),
            md: _.get(this.globalStyles, ['headline', 'align', 'md'], 'center'),
            sm: _.get(this.globalStyles, ['headline', 'align', 'sm'], 'center')
          },
          textDecoration: {
            lg: _.get(this.globalStyles, ['headline', 'underline', 'lg'], '') === true ? 'underline' : '',
            md: _.get(this.globalStyles, ['headline', 'underline', 'md'], '') === true ? 'underline' : '',
            sm: _.get(this.globalStyles, ['headline', 'underline', 'sm'], '') === true ? 'underline' : ''
          },
          textStyle: {
            lg: _.get(this.globalStyles, ['headline', 'italic', 'lg'], '') === true ? 'italic' : '',
            md: _.get(this.globalStyles, ['headline', 'italic', 'md'], '') === true ? 'italic' : '',
            sm: _.get(this.globalStyles, ['headline', 'italic', 'sm'], '') === true ? 'italic' : ''
          },
          lineHeight: {
            lg: 1.3,
            md: 1.3,
            sm: 1.3
          },
          letterSpacing: {
            lg: 0,
            md: 0,
            sm: 0
          }
        })
      }

      return this.globalStyles.textStyles[this.type]
    }
  },

  methods: {
    setValue (key, val) {
      if (this.resolutioner === 'lg') {
        const lg = this.textStyles[key].lg
        const md = this.textStyles[key].md
        const sm = this.textStyles[key].sm

        const obj = {
          lg: val,
          md: md === lg ? val : md,
          sm: sm === lg ? val : sm
        }

        return this.$set(this.textStyles, key, obj)
      }

      this.textStyles[key][this.resolutioner] = val
    },
    fontSizes () {
      const arr = Array(100)
        .fill({})
        .map((_, i) => `${i + 1}px`)
      return arr.slice(10)
    }
  }
}
</script>
