<template>
  <div>
    <b-form-group
      label="Rotate"
      label-class="!pb-[3px]"
    >
      <b-row
        class="align-items-center pl-2"
        no-gutters
      >
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="rotate"
            :interval="1"
            :max="360"
            :min="0"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col class="pl-3">
          <b-input
            v-model="rotate"
            :max="360"
            :min="0"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group label="Flip">
      <div class="flex gap-[6px]">
        <button
          class="btn h-[38px] w-[38px] !border-[#E2E5EC] p-0"
          style="border:1px solid;"
          @click="flipV = !flipV"
        >
          <svg
            fill="none"
            height="14"
            viewBox="0 0 13 14"
            width="13"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :fill="flipV ? '#3D82EA' : '#44474A'"
              d="M7.20833 1.13709C7.20833 0.745888 6.8912 0.428757 6.5 0.428757C6.1088 0.428757 5.79167 0.745888 5.79167 1.13709L5.79167 12.4704C5.79167 12.8616 6.1088 13.1788 6.5 13.1788C6.8912 13.1788 7.20833 12.8616 7.20833 12.4704L7.20833 1.13709Z"
            />
            <path
              :fill="flipV ? '#3D82EA' : '#44474A'"
              d="M4.375 6.80376C4.375 6.99162 4.30037 7.17179 4.16753 7.30462L1.3342 10.138C1.05758 10.4146 0.609087 10.4146 0.332466 10.138C0.0558446 9.86134 0.0558446 9.41284 0.332466 9.13622L2.66493 6.80376L0.332467 4.47129C0.0558452 4.19467 0.0558452 3.74618 0.332467 3.46956C0.609088 3.19293 1.05758 3.19293 1.3342 3.46956L4.16753 6.30289C4.30037 6.43573 4.375 6.61589 4.375 6.80376Z"
            />
            <path
              :fill="flipV ? '#3D82EA' : '#44474A'"
              d="M12.6675 3.46956C12.9442 3.74618 12.9442 4.19467 12.6675 4.47129L10.3351 6.80376L12.6675 9.13622C12.9442 9.41284 12.9442 9.86134 12.6675 10.138C12.3909 10.4146 11.9424 10.4146 11.6658 10.138L8.83247 7.30463C8.55584 7.028 8.55584 6.57951 8.83247 6.30289L11.6658 3.46956C11.9424 3.19293 12.3909 3.19293 12.6675 3.46956Z"
            />
          </svg>
        </button>

        <button
          class="btn h-[38px] w-[38px] !border-[#E2E5EC] p-0"
          style="border:1px solid;"
          @click="flipH = !flipH"
        >
          <svg
            fill="none"
            height="13"
            viewBox="0 0 12 13"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :fill="flipH ? '#3D82EA' : '#44474A'"
              d="M6 4.38004C5.82319 4.38004 5.65362 4.3098 5.5286 4.18477L2.86193 1.51811C2.60158 1.25776 2.60158 0.835647 2.86193 0.575298C3.12228 0.314948 3.54439 0.314948 3.80474 0.575298L6 2.77056L8.19526 0.575298C8.45561 0.314949 8.87772 0.314949 9.13807 0.575298C9.39842 0.835648 9.39842 1.25776 9.13807 1.51811L6.4714 4.18477C6.34638 4.3098 6.17681 4.38004 6 4.38004Z"
            />
            <path
              :fill="flipH ? '#3D82EA' : '#44474A'"
              d="M9.13807 12.1848C8.87772 12.4451 8.45561 12.4451 8.19526 12.1848L6 9.98951L3.80474 12.1848C3.54439 12.4451 3.12228 12.4451 2.86193 12.1848C2.60158 11.9244 2.60158 11.5023 2.86193 11.242L5.52859 8.5753C5.78894 8.31495 6.21105 8.31495 6.4714 8.5753L9.13807 11.242C9.39842 11.5023 9.39842 11.9244 9.13807 12.1848Z"
            />
            <path
              :fill="flipH ? '#3D82EA' : '#44474A'"
              d="M11.3333 7.0467C11.7015 7.0467 12 6.74823 12 6.38003C12 6.01185 11.7015 5.71337 11.3333 5.71337H0.666667C0.298476 5.71337 0 6.01185 0 6.38003C0 6.74823 0.298476 7.0467 0.666667 7.0467H11.3333Z"
            />
          </svg>
        </button>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'
import ModControlMixin from '@/components/mixins/ModControlMixin'

export default {
  name: 'ImageFlip',
  mixins: [ModControlMixin],
  data () {
    return {
      rotateValues: [0, 90, 180, 270]
    }
  },
  computed: {
    flip () {
      if (!this.config.flip) {
        this.$set(this.config, 'flip', {})
      }

      return this.config.flip
    },
    rotate: {
      get () {
        return parseInt(_.get(this.config, ['rotate', this.resolutioner], _.get(this.config, ['rotate', 'lg'], _.get(this.config, 'rotate', 0))))
      },
      set (val) {
        this.$set(this.config, 'rotate', {..._.set(this.config['rotate'] || {}, [this.resolutioner], val)})
      }
    },
    flipH: {
      get () {
        return _.get(this.config, 'flip.h', false)
      },
      set (val) {
        this.$set(this.config, 'flip', {..._.set(this.flip, ['h'], val)})
      }
    },
    flipV: {
      get () {
        return _.get(this.config, 'flip.v', false)
      },
      set (val) {
        this.$set(this.config, 'flip', {..._.set(this.flip, ['v'], val)})
      }
    }
  }
}
</script>