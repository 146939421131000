<template>
  <div :class="[{'d-flex': align}, align]">
    <div
      :class="[{'text-center': !align}]"
      :style="{...borderSubject(badge, [0,0,0,0]), ...fullPaddingSubject(section.options.customize.style), ...backgroundColor, ...fullMarginSubject(section.options.customize.style, 'marginY'), ...radiusSubject(badge, [0,0,0,0]), ...fontSize, ...shadowSubject(badge), ...color, ...fontWeight, ...fontFamaly}"
      class="el-badge"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import {mapState} from 'vuex'
import ResolutionMixin from '../../../mixins/ResolutionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'ImageItem1Template',
  mixins: [TemplateMixin, ResolutionMixin, BorderMixin],
  computed: {
    ...mapState({
      globalStyles: state => state.editor.globalStyles
    }),
    badge () {
      return this.section.options.content.badge
    },
    color () {
      return {color: this.badge.color}
    },
    fontWeight () {
      return {fontWeight: this.badge.fontWeight}
    },
    fontSize () {
      return {fontSize: this.badge.fontSize[this.resolutioner]}
    },
    fontFamaly () {
      return {fontFamily: (!this.badge.fontFamily || this.badge.fontFamily === 'Global Style') ? this.globalStyles.paragraph.font.lg : this.badge.fontFamily}
    },
    align: {
      get () {
        if (this.badge.align[this.resolutioner] === 'between') {
          return false
        }
        return `justify-content-${this.badge.align[this.resolutioner]}`
      },
      set (val) {
        if (this.badge) {
          this.badge.align[this.resolutioner] = val
        }
      }
    }
  }
}
</script>
