<template>
  <div
    class="es-tooltip"
  >
    {{ text }}
    <div
      class="es-tooltip-arrow"
      data-popper-arrow
    />
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'

export default {
  name: 'ElTooltip',
  props: {
    text: {
      type: String,
      default: 'My Tooltip'
    },
    placement: {
      type: String,
      default: 'top'
    },
    enable: {
      default: false
    }
  },
  data () {
    return {
      popperInstance: null
    }
  },
  watch: {
    placement () {
      this.popperInstance.destroy()
      this.init()
    },
    enable (val) {
      if (val) return this.init()
      this.popperInstance.destroy()
    }
  },
  mounted () {
    if (this.enable) this.init()
  },
  methods: {
    init () {
      this.popperInstance = createPopper(this.$el.parentElement, this.$el, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          }
        ]
      })

      const showEvents = ['mouseenter', 'focus']
      const hideEvents = ['mouseleave', 'blur']

      showEvents.forEach((event) => {
        this.$el.parentElement.addEventListener(event, this.show)
      })

      hideEvents.forEach((event) => {
        this.$el.parentElement.addEventListener(event, this.hide)
      })
    },
    show () {
      if (!this.enable) return
      this.$el.setAttribute('data-show', '')

      this.popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: true }
        ]
      }))

      this.popperInstance.update()
    },
    hide () {
      if (!this.enable) return
      this.$el.removeAttribute('data-show')

      this.popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: false }
        ]
      }))
    }
  }
}
</script>

<style lang="scss">
.es-tooltip {
  background: #333;
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  display: none;
}

.es-tooltip[data-show] {
  display: inline-block;
}


.es-tooltip-arrow,
.es-tooltip-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.es-tooltip-arrow {
  visibility: hidden;
}

.es-tooltip-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.es-tooltip[data-popper-placement^='top'] > .es-tooltip-arrow {
  bottom: -4px;
}

.es-tooltip[data-popper-placement^='bottom'] > .es-tooltip-arrow {
  top: -4px;
}

.es-tooltip[data-popper-placement^='left'] > .es-tooltip-arrow {
  right: 0;
}

.es-tooltip[data-popper-placement^='right'] > .es-tooltip-arrow {
  left: -8px;
}
</style>