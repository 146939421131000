<template>
  <div>
    <b-btn
      class="font-weight-normal"
      pill
      variant="outline-primary"
      @click="modal = true"
    >
      Edit Email Address
    </b-btn>

    <b-modal
      v-model="modal"
      size="lg"
      hide-header
      hide-footer
      body-class="account-settings p-5"
      centered
    >
      <div class="px-md-5">
        <b-link
          class="position-absolute top-5 right-10"
          @click="modal = false"
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
              fill="#6E747A"
            />
          </svg>
        </b-link>

        <h4 class="font-weight-bold mb-4">
          Edit Email Address
        </h4>

        <b-form @submit.prevent="submit">
          <b-row class="mb-4">
            <!--            <b-col md="8">-->
            <!--              <b-form-group label="Old email address">-->
            <!--                <b-input v-model="form.oldemail"-->
            <!--                         :type="showemail ? 'text' : 'email'"-->
            <!--                         name="old_email"-->
            <!--                         :state="errors.has('old_email') ? false : null"-->
            <!--                         v-validate="'required'"-->
            <!--                />-->
            <!--              </b-form-group>-->
            <!--            </b-col>-->

            <b-col md="8">
              <b-form-group label="New email address">
                <input
                  ref="new_email"
                  v-model="form.newEmail"
                  v-validate="'required|email'"
                  type="email"
                  name="new_email"
                  class="form-control"
                  :class="{'is-invalid': errors.has('new_email') ? true : null}"
                >
              </b-form-group>
            </b-col>

            <b-col md="8">
              <b-form-group label="Re-type new email address">
                <input
                  v-model="form.emailConfirmation"
                  v-validate="'required|email|confirmed:new_email'"
                  type="email"
                  class="form-control"
                  name="email_confiramtion"
                  :class="{'is-invalid': errors.has('email_confiramtion') ? true : null}"
                  data-vv-as="new_email"
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-alert
            v-for="(error, key) in responseErrors"
            :key="key"
            show
            class="small"
            variant="danger"
          >
            {{ error[0] }}
          </b-alert>

          <b-btn
            type="submit"
            variant="primary"
            size="lg"
            class="font-weight-bold font-size-14 px-20"
            :disabled="loading"
          >
            Update Email Address
            <b-spinner
              v-if="loading"
              class="ml-3"
              variant="light"
              small
            />
          </b-btn>

          <!--          <div>-->
          <!--            Forgotten email?-->
          <!--            <b-link class="text-primary">Click Here</b-link>-->
          <!--          </div>-->
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ChangeEmailModal',

  data () {
    return {
      responseErrors: [],
      modal: false,
      loading: false,
      showemail: false,

      form: {
        newEmail: '',
        emailConfirmation: ''
      }
    }
  },

  methods: {
    submit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.responseErrors = []
          this.loading = true

          axios.put('api/user/profile/login-info', {
            email: this.form.newEmail
          })
            .then((res) => {
              if (res.data) {
                this.$store.commit('auth/UPDATE_EMAIL', res.data.data.email)
                Object.keys(this.form).forEach(key => this.form[key] = '')
                this.modal = false
              }
            })
            .catch((res) => {
              if (res && res.response.data.errors) {
                this.responseErrors = res.response.data.errors
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
