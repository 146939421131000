<template>
  <b-form-group
    :label="`${prefix} Font Weight`"
  >
    <v-select
      v-model="weightMutated"
      :options="fontWeightOptions"
      :reduce="item => item.value"
      :searchable="false"
      class="custom-select"
      left
      placeholder="Global Style"
      @option:selected="onSelect"
    />
  </b-form-group>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'
import _ from 'lodash'
import {mapState} from 'vuex'

export default {
  name: 'SidFontWeight',
  mixins: [SidConcept],
  props: {
    familyMutated: {
      type: String,
      required: true
    },
    prefix: {
      type: String,
      default: ''
    },
    editor: {
      type: Boolean,
      default: false
    },
    selectedFontWeight: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      weight: ''
    }
  },
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    ...mapState('font', {
      allCustomFonts: state => state.allCustomFonts
    }),
    addedGoogleFonts () {
      return this.globalStyles.font.addedGoogleFonts
    },
    weightMutated: {
      get () {
        return this.selectedFontWeight || this.weight || this.sidValueMutation
      },
      set (val) {
        this.weight = val

        if (this.editor) return
        this.apply(val)
      }
    },
    fontWeightOptions () {
      let options = []
      const family = this.familyMutated

      let font = this.allCustomFonts.find(group => group.font_name.toLowerCase() === family.toLowerCase())

      if (!font) {
        font = this.addedGoogleFonts.find(font => font.family.toLowerCase() === family.toLowerCase())
      }

      if (!font) return options

      options.push(..._.sortBy((font.fonts || font.variants)
        .map(variant => {
          return {
            label: variant.font_weight || variant,
            value: variant.font_weight || variant
          }
        }), (e) => parseInt(e.value)))

      return options
    }
  },
  watch: {
    resolutioner () {
      this.weight = ''
    },
    sid () {
      this.weight = ''
    },
    selectedFontWeight (val) {
      if (!val) this.weight = ''
    }
  },
  methods: {
    apply (val) {
      this.addSidRules([[this.getSid(), [this.ruleName, val]]])
    },
    reset () {
      this.weight = this.getSidStyle(this.getSid(), 'font-weight')
    },
    onSelect (option) {
      this.$emit('onFontWeightChange', {
        val: option.value,
        apply: () => this.apply(option.value)
      })
    }
  }
}
</script>