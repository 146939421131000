<template>
  <div
    v-if="show"
    class="inline-flex items-center justify-between w-full h-[36px] p-[10px] bg-[#F9F9F9] border border-[#F9F9F9] rounded-[4px] text-[14px] text-[#525253] leading-none font-[400] transition-all !duration-[0ms] cursor-pointer hover:bg-[#FFFFFF] hover:font-[600] hover:text-[#303030] hover:shadow-[0_2px_5px_rgba(0,0,0,0.07)]"
    @click.prevent="onClick"
  >
    {{ item.title }}

    <div class="flex flex-row items-center justify-end gap-x-[8px] ml-[4px]">
      <span
        v-if="badge"
        class="flex items-center justify-center h-[16px] px-[10px] bg-[#3D82EA] gap-x-[4px] rounded-[4px] text-[10px] text-[#FFFFFF] leading-none font-[600] tracking-[1px] uppercase"
      >
        {{ badge }}
      </span>
      <i
        v-if="item.hasOwnProperty('children')"
        class="fa fa-chevron-right"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapState} from 'vuex'
export default {
  name: 'ModuleSelectMenuItem',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    show () {
      if (!this.item.hasOwnProperty('private')) {
        return true
      } else {
        return this.item.private.ids.length && _.includes(this.item.private.ids, this.user.id) || this.item.private.roles.length && _.includes(this.item.private.roles, this.userRole)
      }
    },
    badge () {
      if (this.item.private) {
        return 'Private'
      } else if (this.item.beta) {
        return 'Beta'
      } else if (this.item.new) {
        return 'New'
      } else return null
    }
  },
  methods: {
    async onClick () {
      if (this.item.hasOwnProperty('children')) {
        this.$emit('submenu', this.item.title)
        return
      }
      if (this.item.hasOwnProperty('route')) {
        if (this.$route.name !== this.item.route || JSON.stringify(this.$route.query) !== JSON.stringify(this.item.query)) {
          try {
            await this.$router.push({ name: this.item.route, query: this.item.query })
          } catch (error) {
            console.error('Navigation error:', error)
          }
        }
        return
      }
      if (this.item.hasOwnProperty('href')) {
        window.open(this.item.href.toString())
      }
    }
  }
}
</script>
