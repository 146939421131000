import _ from 'lodash'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  mixins: [ResolutionMixin],
  methods: {
    backgroundImageSubject (subject) {
      if (!_.get(subject, 'backgroundImage.enabled', false)) {
        return false
      }

      const size = _.get(subject, ['backgroundImage', 'backgroundSizeV2', this.resolutioner], _.get(subject, ['backgroundImage', 'backgroundSizeV2', 'lg'], _.get(subject, 'backgroundImage.backgroundSize', null)))
      const position = _.get(subject, ['backgroundImage', 'backgroundPositionV2', this.resolutioner], _.get(subject, ['backgroundImage', 'backgroundPositionV2', 'lg'], _.get(subject, 'backgroundImage.backgroundPosition', 'center')))

      const styles = {
        null: {},
        'fill-y': {
          backgroundSize: '100% auto',
          backgroundPosition: '',
          backgroundRepeat: 'no-repeat'
        },
        'parallax': {
          backgroundSize: 'cover',
          backgroundPosition: '',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed'
        },
        'fill': {
          backgroundSize: '100% 100%',
          backgroundPosition: position,
          backgroundRepeat: 'no-repeat'
        },
        'repeat': {
          backgroundSize: 'auto',
          backgroundPosition: position,
          backgroundRepeat: 'repeat'
        },
        'repeat-x': {
          backgroundSize: 'auto',
          backgroundPosition: position,
          backgroundRepeat: 'repeat-x'
        },
        'repeat-y': {
          backgroundSize: 'auto',
          backgroundPosition: position,
          backgroundRepeat: 'repeat-y'
        },
        'cover': {
          backgroundSize: 'cover',
          backgroundPosition: position,
          backgroundRepeat: 'no-repeat'
        },
        'contain': {
          backgroundSize: 'contain',
          backgroundPosition: position,
          backgroundRepeat: 'no-repeat'
        },
        'normal': {
          backgroundSize: 'auto',
          backgroundPosition: position,
          backgroundRepeat: 'no-repeat'
        }
      }

      if (!size) return {}

      const filters = _.get(subject, 'backgroundImage.filters', null)
      const flipX = _.get(subject, ['backgroundImage', 'flipX', this.resolutioner], _.get(subject, ['backgroundImage', 'flipX', 'lg'], false))
      const flipY = _.get(subject, ['backgroundImage', 'flipY', this.resolutioner], _.get(subject, ['backgroundImage', 'flipY', 'lg'], false))

      return {
        backgroundImage: `url(${_.get(subject, 'backgroundImage.src', null)})`,
        ...styles[size],
        opacity: _.get(subject, ['backgroundImage', 'opacity', this.resolutioner], 1),
        filter: !filters ? '' : `blur(${filters.blur || '0px'}) grayscale(${filters.grayscale || '0%'}) invert(${filters.invert || '0%'}) contrast(${filters.contrast || '100%'}) sepia(${filters.sepia || '0%'})`,
        transform: `${flipX ? 'scaleX(-1)' : ''}${flipY ? 'scaleY(-1)' : ''}`
      }
    },
    backgroundColorSubject (subject, key = 'background') {
      if (!subject || !subject[key]) return {}
      const gradient = () => {
        if (subject[key].backgroundType === 'gradient' && subject[key].hasOwnProperty('backgroundGradient')) {
          const values = subject[key].backgroundGradient.map(gradient => {
            return `${gradient.color} ${gradient.position}%`
          })
          return `linear-gradient(${subject[key].gradientDirection || 90}deg,${values})`
        }

        return null
      }
      const _backgroundColor = _.get(subject[key], ['background', this.resolutioner], _.get(subject[key], ['background', 'lg'], subject[key].background || ''))

      return {
        backgroundColor: _backgroundColor,
        backgroundImage: gradient()
      }
    },
    backgroundImageOverlaySubject (subject) {
      const enabled = _.get(subject, 'backgroundImage.overlay.enabled', false)
      if (!enabled) return false

      return {
        background: _.get(subject, 'backgroundImage.overlay.color', null),
        opacity: _.get(subject, 'backgroundImage.overlay.opacity', .5)
      }
    },
    backgroundSliderSubject (subject) {
      const enabled = _.get(subject, 'backgroundImage.bgType', 0) || 0
      if (!enabled) return null
      return _.get(subject, 'backgroundImage.backgroundSlider', null) || null
    }
  }
}