const state = () => ({
  courses: []
})

const getters = {

}

const actions = {
  async getCourses ({commit}, params = {}) {
    await axios.get('api/courses', params)
      .then(({data}) => {
        commit('SET_COURSES',data.data)
      })
  }  
}

const mutations = {
  SET_COURSES (state, paylaod) {
    state.courses = paylaod
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
