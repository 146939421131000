import SectionProvider from '@builder/sections/SectionProvider'
import Navbar from './Navbar'

export const name = 'Hero_002'
export const group = 'hero'
export const defaultOptions = {
  'content': {
    'items': {
      'type': null,
      'children': [
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'X7fpzqApC',
                      'display': true,
                      'name': 'Headline',
                      'sid': 'lczpn2wg--PLgvqW0CKO',
                      'hideFromSections': false,
                      'component': 'ElHeadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h3',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>Empower Your Clients</p><p>in Your <gradient-span style="background-image:linear-gradient(90deg,rgba(66, 223, 143, 1) 0%,rgba(96, 148, 227, 1) 100%)" dataraw="[{&quot;color&quot;:&quot;rgba(66, 223, 143, 1)&quot;,&quot;position&quot;:0},{&quot;color&quot;:&quot;rgba(96, 148, 227, 1)&quot;,&quot;position&quot;:100}]">Business.</gradient-span></p>',
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            }
                          }
                        }
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': 'QKQZvkH87g',
                  'sid': 'lczpn2wg--RoZB7-MHJX',
                  '_uid': 'TfoiE-8dWW',
                  'highlight': false,
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': 'sscci1-_O',
          'sid': 'lczpn2wg--f-uW8XO15J',
          'temp_id': 'j0pbRmZok'
        },
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'TLgJuWqR-',
                      'display': true,
                      'name': 'Subheadline',
                      'sid': 'lczpn2wg--l6BFIyxOcd',
                      'hideFromSections': false,
                      'component': 'ElSubheadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h4',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>Learn how to source, sell and engage with prospects.</p><p>All done through an exclusive email course.</p>',
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            }
                          }
                        },
                        'customize': null
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': 'raecmuMDC',
                      'display': true,
                      'name': 'Form',
                      'sid': 'lczpn2wg--R-syUfFvMl',
                      'hideFromSections': false,
                      'component': 'ElFormV2',
                      'group': 'Form',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'customize': null,
                        'content': {
                          'form': {
                            'display': true,
                            'type': 'form-mod-v2',
                            'controls': [
                              {
                                'label': null,
                                'placeholder': 'Enter email address',
                                'field_name': 'Email',
                                'field_type': 'email',
                                'values': null,
                                'validators': '[]',
                                'is_required': true,
                                'columns': {
                                  'lg': 12,
                                  'md': 12,
                                  'sm': 12
                                },
                                'order': 0,
                                'styles': 'el-dZ5JfXQoN',
                                'form_nane': 'New Form',
                                'mapping': []
                              }
                            ],
                            'subject': {
                              'uid': 'RSfMebaiz8',
                              'display': true,
                              'name': 'Button',
                              'css': null,
                              'sid': 'lczpn2wg--WZ8exmambe',
                              'sids': null,
                              'hideFromSections': false,
                              'component': 'ElButtonV2',
                              'group': 'Media',
                              'layout': null,
                              'options': {
                                'content': {
                                  'button': {
                                    'pressetID': 0,
                                    'display': true,
                                    'type': 'button-mod-v2',
                                    'text': 'SUBSCIBE FOR FREE TIPS',
                                    'buttonType': 'button',
                                    'icon': {
                                      'lottie': {
                                        'jsonPath': '',
                                        'loop': true,
                                        'autoplay': true
                                      }
                                    },
                                    'hoverAnimation': {},
                                    'animations': {
                                      'animation': null,
                                      'once': true,
                                      'offset': 200,
                                      'delay': 50
                                    }
                                  }
                                },
                                'customize': null
                              },
                              'temp_id': 'GJ3MzsHvO9'
                            },
                            'gdpr': {
                              'enabled': false,
                              'text': 'I consent to the processing of data.'
                            },
                            'form': {
                              'name': 'New Form',
                              'actions': [],
                              'api_list_or_tag': {},
                              'is_active': true,
                              'agree_subscribe': false,
                              'project_id': 36260,
                              'created_at': '2023-07-20T04:28:10.000000Z',
                              'updated_at': '2023-08-31T03:45:27.000000Z',
                              'fields': [
                                {
                                  'label': null,
                                  'placeholder': 'Enter email address',
                                  'field_name': 'Email',
                                  'field_type': 'email',
                                  'values': null,
                                  'validators': '[]',
                                  'is_required': true,
                                  'columns': '{"lg":12,"md":12,"sm":12}',
                                  'order': 0,
                                  'styles': 'el-dZ5JfXQoN'
                                }
                              ],
                              'mappedFields': {}
                            }
                          }
                        }
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': 'bJpVHwZ_JB',
                  'sid': 'lczpn2wg--otRdH25WTP',
                  '_uid': '040ev7RKDw',
                  'highlight': false,
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': 'm7kW94Nya',
          'sid': 'lczpn2wg--3__VPgcUN3',
          'temp_id': 'j0pbRmZok'
        },
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'KSzuMvqNV',
                      'display': true,
                      'name': 'Subheadline',
                      'sid': 'lczpn2wg--sSi5rZ6dL1',
                      'hideFromSections': false,
                      'component': 'ElSubheadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h4',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>TRUSTED BY THESE COMPANIES</p>',
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            }
                          }
                        },
                        'customize': null
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': 'QYzOpOG8vL',
                  'sid': 'lczpn2wg--6y7COdwhlN',
                  'highlight': false,
                  '_uid': 'X2VTFQorRp',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': '-m9M1AvLB',
          'sid': 'lczpn2wg--NuUbgOvimW',
          'temp_id': 'SpR-HP7X_'
        },
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'mLM7sDHDc',
                      'display': true,
                      'name': 'Image',
                      'sid': 'lczpn2wg--Fxnbg8jQ-d',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': 'OoGXX8jrhB',
                  'sid': 'czpn2wg--sYGC83yY1',
                  'highlight': false,
                  '_uid': 'czpn2wg--sYGC83yY1',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                },
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'l7vfx1xZQ',
                      'display': true,
                      'name': 'Image',
                      'sid': 'czpn2wg--62dirkbHd',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project',
                      '_uid': 'czpn2wg--62dirkbHd'
                    }
                  ],
                  'sortIndex': {
                    'lg': 1,
                    'md': 1,
                    'sm': 1
                  },
                  'uid': 'PplGuSUmu4',
                  'sid': 'lczpn2wg--bIIAamSeka',
                  'highlight': false,
                  '_uid': 'Q4M5o3lv56',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                },
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'CZnV7ueOp',
                      'display': true,
                      'name': 'Image',
                      'sid': 'czpn2wg--Mt_pwFFC4',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project',
                      '_uid': 'czpn2wg--Mt_pwFFC4'
                    }
                  ],
                  'sortIndex': {
                    'lg': 2,
                    'md': 2,
                    'sm': 2
                  },
                  'uid': 'HteYFcjwhb',
                  'sid': 'lczpn2wg--Muxe0yp6h5',
                  'highlight': false,
                  '_uid': '73Sx1Z7JVa',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                },
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': '5fLozzfeg',
                      'display': true,
                      'name': 'Image',
                      'sid': 'czpn2wg--HfDIo_h0N',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project',
                      '_uid': 'czpn2wg--HfDIo_h0N'
                    }
                  ],
                  'sortIndex': {
                    'lg': 3,
                    'md': 3,
                    'sm': 3
                  },
                  'uid': 'xjxrYFdLyk',
                  'sid': 'lczpn2wg--8Mq3K7-xNi',
                  'highlight': false,
                  '_uid': 'erOixl6mf_',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                },
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'sT8vVKVhr',
                      'display': true,
                      'name': 'Image',
                      'sid': 'czpn2wg--tlDjgBPog',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project',
                      '_uid': 'czpn2wg--tlDjgBPog'
                    }
                  ],
                  'sortIndex': {
                    'lg': 4,
                    'md': 4,
                    'sm': 4
                  },
                  'uid': 'tLhTKG2bV4',
                  'sid': 'lczpn2wg--M375USx66l',
                  'highlight': false,
                  '_uid': 'JJmYmlaKYF',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': '8g0-JWK_e',
          'sid': 'lczpn2wg--saqT0HQCVO',
          'temp_id': 'YQ479Gjit'
        }
      ]
    },
    'lottie': {}
  },
  'backgroundImage': {
    'src': ''
  },
  'backgroundVideo': {
    'enabled': false,
    'url': '',
    'loop': true,
    'ratio': 1
  }
}

export const iframeURL = 'https://my-web-1689812511572.estage.site'

export const beforeBlock = new Navbar().json()

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('BlankV2', options)

    this.name = name
    this.group = group
    this.sid = 'lczpn2wg--0G0LeYVm-A'
    this.sids = [
      'lczpn2wg--0G0LeYVm-A',
      'lczpn2wg--f-uW8XO15J',
      'lczpn2wg--RoZB7-MHJX',
      'lczpn2wg--PLgvqW0CKO',
      'lczpn2wg--3__VPgcUN3',
      'lczpn2wg--otRdH25WTP',
      'lczpn2wg--l6BFIyxOcd',
      'lczpn2wg--R-syUfFvMl',
      'lczpn2wg--WZ8exmambe',
      'lczpn2wg--NuUbgOvimW',
      'lczpn2wg--6y7COdwhlN',
      'lczpn2wg--sSi5rZ6dL1',
      'lczpn2wg--saqT0HQCVO',
      'czpn2wg--sYGC83yY1',
      'lczpn2wg--Fxnbg8jQ-d',
      'lczpn2wg--bIIAamSeka',
      'czpn2wg--62dirkbHd',
      'lczpn2wg--Muxe0yp6h5',
      'czpn2wg--Mt_pwFFC4',
      'lczpn2wg--8Mq3K7-xNi',
      'czpn2wg--HfDIo_h0N',
      'lczpn2wg--M375USx66l',
      'czpn2wg--tlDjgBPog'
    ]
    this.css = [
      [
        '.--lczpn2wg--0G0LeYVm-A--container { max-width: 100vw; padding-bottom: 50px; padding-top: 90px; }',
        '.--lczpn2wg--f-uW8XO15J--flex { max-width: 1200px; --width: 1200px; }',
        '.--lczpn2wg--RoZB7-MHJX--flex-col-el { width: 100%; --width: 100%; }',
        '.--lczpn2wg--0G0LeYVm-A--bg { background-color: rgba(0, 0, 0, 0); background-image: linear-gradient(87deg, rgb(6, 18, 32) 0px, rgb(17, 63, 88) 100%); }',
        '.--lczpn2wg--PLgvqW0CKO-text { padding: 20px 20px 0px; }',
        '.--lczpn2wg--PLgvqW0CKO-text div { color: rgb(255, 255, 255); font-size: 75px; line-height: 1.1; --line-height: 1.1; }',
        '.--lczpn2wg--RoZB7-MHJX--flex-col-padding { padding: 70px 0px 0px; }',
        '.--lczpn2wg--f-uW8XO15J--flex.flex-wrapper { padding: 0px; }',
        '.--lczpn2wg--l6BFIyxOcd-text { padding: 20px; }',
        '.--lczpn2wg--3__VPgcUN3--flex { max-width: 700px; --width: 700px; }',
        '.--lczpn2wg--otRdH25WTP--flex-col-el { width: 100%; --width: 100%; }',
        '.--lczpn2wg--otRdH25WTP--flex-col-padding { padding-top: 0px; padding-left: 0px; }',
        '.--lczpn2wg--3__VPgcUN3--flex.flex-wrapper { padding-top: 0px; }',
        '.--lczpn2wg--l6BFIyxOcd-text div { color: rgb(255, 255, 255); font-weight: 400; font-size: 21px; line-height: 1.5; --line-height: 1.5; }',
        '.--lczpn2wg--R-syUfFvMl--form { gap: 27px 22px; align-items: end; flex-direction: column; padding: 20px; }',
        '.--lczpn2wg--WZ8exmambe--1 { margin-bottom: 0px; height: 51px; font-family: Roboto; font-size: 14px; letter-spacing: 3px; background-color: rgb(71, 209, 123); }',
        '.--lczpn2wg--R-syUfFvMl--form .pU9T6Auqb { width: 100%; }',
        '.--lczpn2wg--R-syUfFvMl--form .faQ0DoIri4 { width: 100%; }',
        '.--lczpn2wg--R-syUfFvMl--form .input.form-control { background-color: rgba(0, 0, 0, 0); background-image: linear-gradient(29deg, rgb(17, 63, 88) 0px, rgb(7, 39, 56) 100%); border-width: 1px 0px 0px; border-color: rgb(44, 94, 134); color: rgb(143, 159, 170); border-radius: 5px; }',
        '.--lczpn2wg--R-syUfFvMl--form .form-control.input { font-size: 15px; }',
        '.--lczpn2wg--R-syUfFvMl--form .secondary-text { font-family: Roboto; }',
        '.--lczpn2wg--WZ8exmambe--container { display: inline-block; --width: auto; width: 100%; --align: stretch; }',
        '.--lczpn2wg--WZ8exmambe--1 .main-text { justify-content: center; }',
        '.--lczpn2wg--WZ8exmambe--2 { justify-content: center; }',
        '.--lczpn2wg--6y7COdwhlN--flex { max-width: 80vw; --width: 80vw; }',
        '.--lczpn2wg--6y7COdwhlN--flex-col-el { width: 100%; --width: 100%; }',
        '.--lczpn2wg--NuUbgOvimW--flex { max-width: 1000px; --width: 1000px; flex-direction: column; }',
        '.--lczpn2wg--sSi5rZ6dL1-text { padding: 20px; }',
        '.--lczpn2wg--sSi5rZ6dL1-text div { color: rgb(201, 222, 227); font-weight: 400; font-size: 15px; letter-spacing: 1px; }',
        '.--lczpn2wg--M375USx66l--flex-col-el { width: 100%; --width: 100%; }',
        '.--lczpn2wg--bIIAamSeka--flex-col-el { width: 100%; --width: 100%; }',
        '.--lczpn2wg--Muxe0yp6h5--flex-col-el { width: 100%; --width: 100%; }',
        '.--lczpn2wg--8Mq3K7-xNi--flex-col-el { width: 100%; --width: 100%; }',
        '.--lczpn2wg--saqT0HQCVO--flex { max-width: 1000px; --width: 1000px; flex-wrap: nowrap; }',
        '.--lczpn2wg--M375USx66l--flex-col-padding { padding: 0px; }',
        '.--lczpn2wg--bIIAamSeka--flex-col-padding { padding: 0px; }',
        '.--lczpn2wg--Muxe0yp6h5--flex-col-padding { padding: 0px; }',
        '.--lczpn2wg--8Mq3K7-xNi--flex-col-padding { padding: 0px; }',
        '.--lczpn2wg--6y7COdwhlN--flex-col-padding { padding-bottom: 0px; }',
        '.--lczpn2wg--NuUbgOvimW--flex.flex-wrapper { padding-bottom: 0px; }',
        '.--lczpn2wg--Fxnbg8jQ-d--container { justify-content: center; }',
        '.--lczpn2wg--Fxnbg8jQ-d--wrapper { padding: 20px; }',
        '.el-image.--lczpn2wg--Fxnbg8jQ-d--image { width: 40px; --width: 40px; }',
        '.--lczpn2wg--Fxnbg8jQ-d--image { content: url("https://res.cloudinary.com/estage/image/upload/v1689813934/users/user-572/e89b77cbba1b0aad7cdc8bbc1bc42355.png"); --bg-id: 166573; }',
        '.--czpn2wg--sYGC83yY1--flex-col-el { width: 100%; --width: 100%; }',
        '.--czpn2wg--sYGC83yY1--flex-col-padding { padding: 0px; }',
        '.--czpn2wg--62dirkbHd--container { justify-content: center; }',
        '.--czpn2wg--62dirkbHd--wrapper { padding: 20px; }',
        '.el-image.--czpn2wg--62dirkbHd--image { width: 40px; --width: 40px; }',
        '.--czpn2wg--62dirkbHd--image { content: url("https://res.cloudinary.com/estage/image/upload/v1689814233/users/user-572/5827d9539882e017a31dec481b79c444.png"); --bg-id: 166574; }',
        '.--czpn2wg--Mt_pwFFC4--container { justify-content: center; }',
        '.--czpn2wg--Mt_pwFFC4--wrapper { padding: 20px; }',
        '.el-image.--czpn2wg--Mt_pwFFC4--image { width: 39%; --width: 39%; }',
        '.--czpn2wg--Mt_pwFFC4--image { content: url("https://res.cloudinary.com/estage/image/upload/v1689814312/users/user-572/5c277a1e23af3be2a1f43b78b540b2ec.png"); --bg-id: 166575; }',
        '.--czpn2wg--HfDIo_h0N--container { justify-content: center; }',
        '.--czpn2wg--HfDIo_h0N--wrapper { padding: 20px; }',
        '.el-image.--czpn2wg--HfDIo_h0N--image { width: 45%; --width: 45%; }',
        '.--czpn2wg--HfDIo_h0N--image { content: url("https://res.cloudinary.com/estage/image/upload/v1689814386/users/user-572/c9b7bea23fb947b103fe1e7f42a68d0f.png"); --bg-id: 166576; }',
        '.--czpn2wg--tlDjgBPog--container { justify-content: center; }',
        '.--czpn2wg--tlDjgBPog--wrapper { padding: 20px; }',
        '.el-image.--czpn2wg--tlDjgBPog--image { width: 52%; --width: 52%; }',
        '.--czpn2wg--tlDjgBPog--image { content: url("https://res.cloudinary.com/estage/image/upload/v1689814493/users/user-572/92ab82981332420f5b5dfd3e8c55c62f.png"); --bg-id: 166577; }',
        '.--lczpn2wg--WZ8exmambe--1:hover { background-color: rgb(28, 173, 114); }'
      ],
      [
        '.--lczpn2wg--f-uW8XO15J--flex { max-width: 100%; --width: 100%; }',
        '.--lczpn2wg--3__VPgcUN3--flex { max-width: 100%; --width: 100%; }',
        '.--lczpn2wg--6y7COdwhlN--flex { max-width: 100%; --width: 100%; }',
        '.--lczpn2wg--NuUbgOvimW--flex { max-width: 100%; --width: 100%; flex-wrap: nowrap; }',
        '.--lczpn2wg--saqT0HQCVO--flex { max-width: 100%; --width: 100%; }',
        '.--lczpn2wg--RoZB7-MHJX--flex-col-padding { padding-top: 30px; }',
        '.--lczpn2wg--PLgvqW0CKO-text div { font-size: 61px; }',
        '.--lczpn2wg--l6BFIyxOcd-text div { font-size: 20px; }'
      ],
      [
        '.--lczpn2wg--f-uW8XO15J--flex { max-width: 100%; --width: 100%; }',
        '.--lczpn2wg--3__VPgcUN3--flex { max-width: 100%; --width: 100%; }',
        '.--lczpn2wg--6y7COdwhlN--flex { max-width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lczpn2wg--NuUbgOvimW--flex { max-width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lczpn2wg--saqT0HQCVO--flex { max-width: 100%; --width: 100%; }',
        '.el-image.--lczpn2wg--Fxnbg8jQ-d--image { width: 100%; --width: 100%; }',
        '.--lczpn2wg--Muxe0yp6h5--flex-col-el { width: 100%; --width: 100%; }',
        '.el-image.--czpn2wg--HfDIo_h0N--image { width: 100%; --width: 100%; }',
        '.el-image.--czpn2wg--tlDjgBPog--image { width: 100%; --width: 100%; }',
        '.el-image.--czpn2wg--Mt_pwFFC4--image { width: 100%; --width: 100%; }',
        '.el-image.--czpn2wg--62dirkbHd--image { width: 4000px; --width: 4000px; }',
        '.--lczpn2wg--M375USx66l--flex-col-padding { padding: 0px; }',
        '.--lczpn2wg--saqT0HQCVO--flex.flex-wrapper { padding: 0px; }',
        '.--lczpn2wg--sSi5rZ6dL1-text div { font-size: 12px; }',
        '.--lczpn2wg--NuUbgOvimW--flex.flex-wrapper { padding-top: 0px; }',
        '.--lczpn2wg--6y7COdwhlN--flex-col-padding { padding-top: 0px; }',
        '.--lczpn2wg--otRdH25WTP--flex-col-padding { padding-right: 0px; }',
        '.--lczpn2wg--3__VPgcUN3--flex.flex-wrapper { padding-left: 0px; padding-right: 0px; }',
        '.--lczpn2wg--R-syUfFvMl--form { row-gap: 13px; }',
        '.--lczpn2wg--WZ8exmambe--1 { font-size: 12px; padding-left: 20px; padding-right: 20px; width: 100%; --width: 100%; }',
        '.--lczpn2wg--l6BFIyxOcd-text div { font-size: 17px; font-weight: 300; }',
        '.--lczpn2wg--PLgvqW0CKO-text div { font-size: 53px; line-height: 1.1; --line-height: 1.1; }',
        '.--lczpn2wg--RoZB7-MHJX--flex-col-padding { padding-top: 10px; }',
        '.--lczpn2wg--WZ8exmambe--container { --align: stretch; width: 100%; display: block; --width: 100%; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
