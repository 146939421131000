<template>
  <div class="dashboard-projects-grid">
    <b-row>
      <b-col
        v-for="project in projects"
        :key="project.id"
        cols="12"
        md="6"
        lg="4"
        xl="3"
        class="mb-4"
      >
        <project-grid-card :project="project" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ProjectGridCard from './components/ProjectGridCard'
export default {
  name: 'DashboardProjectsGrid',
  components: {ProjectGridCard},
  props: {
    projects: {
      type: Array,
      required: true
    }
  }
}
</script>