<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element hide-copy-favorite"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('collapse')"
  >
    <div
      :data-sid="section.sid"
      :class="`--${section.sid}--wrapper`"
      class="d-flex flex-column w-full"
    >
      <div
        v-for="(collapse, index) in section.options.content.collapse.items"
        :key="collapse.uid"
        :class="`--${section.sid}--container`"
      >
        <div
          class="d-flex w-100 gap-3 cursor-pointer transition items-center"
          :class="[`--${section.sid}--tab-header-wrapper`, {'active': openIndex === index}]"
          @click="() => openIndex = index"
        >
          <span
            v-show="openIndex !== index"
            :class="[`--${section.sid}--tab-open-icon`, section.options.content.collapse.icons.openIcon.icon]"
          />
          <span
            v-show="openIndex === index"
            :class="[`--${section.sid}--tab-close-icon`, section.options.content.collapse.icons.closeIcon.icon]"
          />

          <editable
            v-model="collapse.title"
            :class="`--${section.sid}--tab-header`"
            class="mr-auto w-100"
          />
        </div>
        <div
          v-if="openIndex === index"
          :class="`--${section.sid}--tab-content`"
        >
          <elements
            v-model="collapse.elements"
            @flow="handleFlow"
          />
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Elements from '../../utils/Elements/Elements'
import Editable from '@/components/builder/utils/Editable'

export default {
  name: 'FeaturedItems1',

  components: {Editable, Elements, Highliter},

  mixins: [SectionMixin],

  data () {
    return {
      acitve: null
    }
  },
  
  computed: {
    openIndex: {
      get () {
        if (this.section.options.content.collapse.openIndex === null) return this.acitve
        if (this.acitve !== null) return this.acitve
        return this.section.options.content.collapse.openIndex || 0
      },
      set (val) {
        this.acitve = val
      }
    }
  }
}
</script>
