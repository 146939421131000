export default {
  data () {
    return {
      isSticky: false
    }
  },
  computed: {
    isStickyEnabled () {
      return this.col.sticky
    },
    offset () {
      return 50 + this.col.stickyOffsetTop
    }
  },
  methods: {
    onScroll () {
      if (this.resolutioner === 'sm') return
      const parentContainerRect = (this.$el.closest('.blog-aside') || this.$el.parentElement).getBoundingClientRect()

      if (parentContainerRect.top - this.offset < 0) {
        if (parentContainerRect.bottom - this.offset > this.$el.getBoundingClientRect().height) this._onSticky()
        return this.isSticky = true
      } else if (this.isSticky) {
        this._onUnSticky()
        return this.isSticky = false
      }
    },

    stickyInit (enable = true) {
      enable ? this._enableSticky() : this._disableSticky()
    },

    _onSticky () {
      const parentContainerRect = (this.$el.closest('.blog-aside') || this.$el.parentElement).getBoundingClientRect()
      this.$el.style.transition = '.1s ease-out'
      this.$el.style.transform = `translateY(${Math.abs(parentContainerRect.top - this.offset)}px)`
    },

    _onUnSticky () {
      this.$el.style.transition = null
    },

    _enableSticky () {
      const $scrollContainer = document.querySelector('.scrollbale-container')
      if (!$scrollContainer) return

      $scrollContainer.addEventListener('scroll', this.onScroll)
    },

    _disableSticky () {
      const $scrollContainer = document.querySelector('.scrollbale-container')
      if (!$scrollContainer) return

      $scrollContainer.removeEventListener('scroll', this.onScroll)
      this.$el.style.transition = null
      this.$el.style.transform = null
    }
  },
  mounted () {
    if (this.isStickyEnabled) this.stickyInit(true)
  },
  beforeDestroy () {
    if (this.isStickyEnabled) this.stickyInit(false)
  },
  watch: {
    isStickyEnabled (val) {
      this.stickyInit(val)
    },
    resolutioner (val) {
      if (val === 'sm') {
        this.$el.style.transition = null
        this.$el.style.transform = null
      }
    }
  }
}