<template>
  <highliter
    class="el-dynamic"
    value="Post Title"
    hide-add
    hide-right
  >
    <h5 class="post-title">
      {{ title }}
    </h5>
  </highliter>
</template>

<script>
import Highliter from '@/components/builder/utils/Highliter.vue'

export default {
  name: 'PostTitle',
  components: {Highliter},
  props: {
    settings: {
      type: Object
    }
  },
  computed: {
    title () {
      const text = 'Your Post Title Here'

      if (!this.settings.maxTitleWords) return text

      // Split the text into an array of words
      const words = text.split(' ')

      // Extract the first "limit" words
      const limitedWords = words.slice(0, this.settings.maxTitleWords)

      // Join the words back into a string
      const limitedText = limitedWords.join(' ')

      // Add ellipsis if the text was truncated
      if (words.length > this.settings.maxTitleWords) {
        return `${limitedText}...`
      }

      return limitedText
    }
  }
}
</script>

<style>
.post-title-container {
    width: 100%;
    --width: 100%;
}

.post-title {
    font-weight: 700;
    font-size: 23px;
    color: #000000;
    padding: 0 20px;
}
</style>