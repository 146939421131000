<template>
  <div :class="[section.options.content.image.customClass, {'element-hidden': !visability('image')}]">
    <highliter
      v-model="section.name"
      :disable-toolbar="section.options.content.image.disableToolbar"
      :dublicate="section"
      :dublicate-deep="parentUID"
      class="highlight-element"
      section-type="element"
      @delete="$emit('delete')"
      @dublicate="$emit('dublicate')"
      @favorite="$emit('favorite', $el)"
      @flow="handleFlow"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
      @click.stop.native="sectionSettings('image')"
    >
      <preview-el-image-v3
        v-if="section.options.content.image.subject"
        :section="section.options.content.image.subject"
      />
    </highliter>
  </div>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'

export default {
  name: 'FeaturedItems1',

  components: {Highliter},

  mixins: [SectionMixin]
}
</script>
