<template>
  <div
    :style="{...shadowSubject(section.options.content.menu), ...borderSubject(section.options.content.menu, [0, 0, 0, 0]), ...radiusSubject(section.options.content.menu), ...backgroundColor, ...fullPaddingSubject(section.options.customize.style), ...fullMarginSubject(section.options.customize.style)}"
  >
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin, BorderMixin]
}
</script>
