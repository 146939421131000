<template>
  <div class="mb-5 mt-5">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h5 class="title font-weight-bold font-size-20">
        Design & Position
      </h5>

      <slot name="switcher" />
    </div>

    <div class="col-md-12 border rounded-[4px]">
      <div class="row mt-2 mb-3">
        <div class="col-md-3">
          <div class="label mb-3">
            Banner Type
          </div>

          <b-form-radio-group
            v-model="form.bannerType"
            value-field="key"
            text-field="text"
            :options="[{text: 'Bar', key: 'bar'}, {text: 'Pop-up', key: 'pop-up'}]"
          />
        </div>

        <div
          v-show="form.bannerType === 'bar'"
          class="col-md-4"
        >
          <div class="label mb-2">
            Banner Position
          </div>

          <v-select
            v-model="form.bannerPosition"
            :searchable="false"
            :options="[{text: 'Top', key: 'top'}, {text: 'Bottom', key: 'bottom'}]"
            label="text"
            :reduce="i => i.key"
          />
        </div>

        <div
          v-show="form.bannerType === 'pop-up'"
          class="col-md-4"
        >
          <div class="label mb-2">
            Pop-up Position
          </div>

          <v-select
            v-model="form.popUpPosition"
            :searchable="false"
            :options="[{text: 'Top left', key: 'top-left'}, {text: 'Top center', key: 'top-center'}, {text: 'Top right', key: 'top-right'}, {text: 'Bottom left', key: 'bottom-left'}, {text: 'Bottom center', key: 'bottom-center'}, {text: 'Bottom right', key: 'bottom-right'}]"
            label="text"
            :reduce="i => i.key"
          />
        </div>
      </div>
    </div>

    <div class="col-md-12 border rounded-[4px] mt-3">
      <div class="row mt-2 mb-3">
        <div class="col-md-12">
          <div class="label mb-4">
            Banner Style
          </div>

          <b-form-radio-group
            v-model="form.bannerStyle"
            value-field="key"
            text-field="text"
            :options="[{text: 'Light theme', key: 'light'}, {text: 'Dark theme', key: 'dark'}, {text: 'Custom theme', key: 'custom'}]"
            @change="setTheme"
          />

          <div class="row mt-3">
            <div class="col-md-12 text-secondary">
              or Choose from Template
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <b-form-radio-group
                v-model="form.bannerStyle"
                value-field="key"
                text-field="text"
                class="radio-group-border"
                :options="[{text: 'Default', key: 'default'}, {text: 'Modern & Clean', key: 'modern-clean'}, {text: 'Basic B&W', key: 'basic-b-w'}]"
                @change="setTheme"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="form.bannerStyle === 'custom'"
      class="col-md-12 border rounded-[4px] mt-3"
    >
      <div class="row mt-2 mb-3">
        <div class="col-md-12">
          <div class="label mb-4">
            Custom Theme Style
          </div>

          <div v-if="form.bannerStyle === 'custom'">
            <div class="row mb-2">
              <div class="col-md-4 text-secondary">
                <color-picker
                  :color="form.colors.bannerBackground"
                  @input="event => form.colors.bannerBackground = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
                />

                Banner Background Color
              </div>

              <div class="col-md-4 text-secondary">
                <color-picker
                  :color="form.colors.bannerText"
                  @input="event => form.colors.bannerText = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
                />

                Banner Text Color
              </div>
            </div>

            <div class="row">
              <div class="col-md-4 text-secondary">
                <color-picker
                  :color="form.colors.ctaBackgroundColor"
                  @input="event => form.colors.ctaBackgroundColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
                />

                Banner Background Color
              </div>

              <div class="col-md-4 text-secondary">
                <color-picker
                  :color="form.colors.ctaButtonText"
                  @input="event => form.colors.ctaButtonText = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
                />

                CTA Text Button Color
              </div>

              <div class="col-md-4 text-secondary">
                <color-picker
                  :color="form.colors.ctaLinkText"
                  @input="event => form.colors.ctaLinkText = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
                />

                CTA Text Link Color
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 border rounded-[4px] mt-3">
      <div class="row mt-2 mb-3">
        <div class="col-md-12">
          <div class="label mb-4">
            CTA Styling
          </div>

          <div class="row">
            <div class="col-md-12 mb-3">
              <b-row>
                <b-col md="5">
                  <div class="label mb-2">
                    CTA Type for ‘Allow All’
                  </div>

                  <v-select
                    v-model="form.bannerCtaAllowAllType"
                    :searchable="false"
                    :options="optionButtons"
                    label="text"
                    :reduce="i => i.key"
                  />
                </b-col>

                <b-col
                  v-if="form.bannerCtaAllowAllType !== 'icon'"
                  md="5"
                >
                  <div class="label mb-2">
                    CTA Text for ‘Allow All’
                  </div>

                  <b-input v-model="form.bannerCtaAllowAllText" />
                </b-col>
              </b-row>
            </div>

            <div class="col-md-12 mb-3">
              <b-row>
                <b-col md="5">
                  <div class="label mb-2">
                    CTA Type for ‘Disable All’
                  </div>

                  <v-select
                    v-model="form.bannerCtaDisableAllType"
                    :searchable="false"
                    :options="optionButtons"
                    label="text"
                    :reduce="i => i.key"
                  />
                </b-col>

                <b-col
                  v-if="form.bannerCtaDisableAllType !== 'icon'"
                  md="5"
                >
                  <div class="label mb-2">
                    CTA Text for ‘Disable All’
                  </div>

                  <b-input v-model="form.bannerCtaDisableAllText" />
                </b-col>
              </b-row>
            </div>

            <div class="col-md-12 mb-3">
              <b-row>
                <b-col md="5">
                  <div class="label mb-2">
                    CTA Type for ‘Customize Settings’
                  </div>

                  <v-select
                    v-model="form.bannerCtaCustomizeSettingsType"
                    :searchable="false"
                    :options="optionButtons"
                    label="text"
                    :reduce="i => i.key"
                  />
                </b-col>

                <b-col
                  v-if="form.bannerCtaCustomizeSettingsType !== 'icon'"
                  md="5"
                >
                  <div class="label mb-2">
                    CTA Text for ‘Customize Settings’
                  </div>

                  <b-input v-model="form.bannerCtaCustomizeSettingsText" />
                </b-col>
              </b-row>
            </div>

            <div class="col-md-12 mb-3">
              <b-row>
                <b-col md="5">
                  <div class="label mb-2">
                    CTA Type for ‘Save Settings’
                  </div>

                  <v-select
                    v-model="form.bannerCtaSettingsSaveType"
                    :searchable="false"
                    :options="optionButtons"
                    label="text"
                    :reduce="i => i.key"
                  />
                </b-col>

                <b-col
                  v-if="form.bannerCtaSettingsSaveType !== 'icon'"
                  md="5"
                >
                  <div class="label mb-2">
                    CTA Text for ‘Save Settings’
                  </div>

                  <b-input v-model="form.bannerCtaSettingsSaveText" />
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesignAndPosition',

  props: {
    form: {
      required: true
    }
  },

  data () {
    return {
      optionButtons: [
        {text: 'Button', key: 'button'},
        {text: 'Button Border', key: 'buttonBorder'},
        {text: 'Text', key: 'text'}
      ]
    }
  },

  methods: {
    setTheme (val) {
      if (val === 'dark') {
        this.form.colors.bannerBackground = '#393939'
        this.form.colors.bannerText = '#DDDDDD'
        this.form.colors.ctaBackgroundColor = '#FFFFFF'
        this.form.colors.ctaButtonText = '#393939'
        this.form.colors.ctaLinkText = '#FFFFFF'
        this.form.colors.ctaLinkBackgroundColor = '#4B4B4B'
      }

      if (val === 'light') {
        this.form.colors.bannerBackground = '#F5F5F5'
        this.form.colors.bannerText = '#4B4B4B'
        this.form.colors.ctaBackgroundColor = '#848484'
        this.form.colors.ctaButtonText = '#FFFFFF'
        this.form.colors.ctaLinkText = '#4B4B4B'
        this.form.colors.ctaLinkBackgroundColor = '#FFFFFF'
      }
    }
  }
}
</script>
