<template>
  <div>
    <sidbear-settings-group
      title="Image"
      active
    >
      <sid-range-slider
        rule-name="width"
        :sid="className(' img')"
        default-dimension="%"
        :dimensions="['%', 'vw', 'px']"
        label="Image Size"
        var="--width"
        measurement="width"
      />

      <sid-align
        :sid="className(' .image-wrapper')"
        rule-name="justify-content"
        label="Alignment"
      />
    </sidbear-settings-group>

    <SidbearSettingsGroup title="Padding & Margins">
      <sid-linked-group
        label="Padding"
        :sid="className()"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="className()"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'

export default {
  name: 'PostImageMod',
  components: {SidLinkedGroup, SidAlign, SidbearSettingsGroup, SidRangeSlider},
  mixins: [ModMixin],
  methods: {
    className (name = '') {
      return `.--post-image-${this.section.sid}${name}`
    }
  }
}
</script>