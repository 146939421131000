const state = () => ({
  loading: false,
  userPaymentsInfo: {},
  userNotes: '',
  product: null,
  products: [],
  userProducts: [],
  userTags: [],
  allTags: [],
  errorMessage: '',
  invoices: [],
  cards: [],
  stripe: {
    payment_system: 'stripe'
  }
})

const actions = {
  getCards ({state, rootState}) {
    return axios.get(`api/user/payment-systems/cards/${rootState.projects.project.id}`)
      .then(({data}) => {
        state.cards = data.data
      })
  },
  async getProduct ({state}, id) {
    state.loading = true
    await axios.get(`api/user/payment-systems/products/${id}`)
      .then(({data}) => {
        state.product = data.data
      })
      .finally(() => {
        state.loading = false
      })
  },
  async getProductList ({state, rootState}) {
    state.loading = true
    await axios.get(`api/user/payment-systems/products?project_id=${rootState.projects.project.id}`)
      .then(({data}) => {
        state.products = data.data.data
      })
      .finally(() => {
        state.loading = false
      })
  }
}

export default {
  namespaced: true,
  state,
  actions
}