<template>
  <b-modal
    v-model="modal"
    hide-footer
    hide-header
    size="lg"
  >
    <div class="p-4">
      <div class="flex gap-[17px] items-center mb-4">
        <svg
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="16.5"
            cy="16.5"
            r="16.5"
            fill="#D9D9D9"
          />
        </svg>

        <div>
          <div
            class="text-[17px]"
            v-text="comment.guest_full_name"
          />
          <a
            class="blocktext-[16px]"
            :href="`mailto:${comment.guest_email}`"
          >{{ comment.guest_email }}</a>
        </div>
      </div>

      <p v-text="comment.text" />

      <hr>

      <b-form @submit.prevent="submit">
        <b-form-group>
          <b-textarea
            v-model="reply"
            required
            :maxlength="500"
            rows="7"
            placeholder="Your reply here..."
          />
          <div class="text-right text-muted mt-2">
            {{ reply.length }} / 500
          </div>
        </b-form-group>

        <div class="flex w-100 justify-end">
          <b-btn
            variant="primary"
            type="submit"
            :disabled="loading"
          >
            <b-spinner
              v-if="loading"
              variant="white"
              small
              class="mr-2"
            />
            Reply
          </b-btn>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'
import _ from 'lodash'
export default {
  name: 'Reply',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    comment: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      reply: ''
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('editor', {
      commentsManagementAPI: state => state.commentsManagementAPI
    }),
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    submit () {
      this.loading = true

      axios.post(`api/projects/${this.project.id}/${this.commentsManagementAPI}`, {
        comment: this.reply,
        parent_id: this.comment.id,
        comment_block_id: this.comment.comment_block_id,
        post_id: parseInt(this.comment.post_id) || _.get(this.comment,'blog.id')
      })
        .then(() => {
          this.modal = false
          this.$emit('refresh')
          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Submitted.',
            showConfirmButton: false,
            timer: 3000
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>