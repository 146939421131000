<template>
  <highliter
    v-model="section.name"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings(true)"
  >
    <FeaturedItems3Template :section="section">
      <template slot="image">
        <highliter
          v-model="section.options.content.image.name"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('image')"
        >
          <image-fit
            :customs="section.options.content.image"
            :src="section.options.content.image.src || section.options.content.image.placeholder"
            :size="cols(section.options.content.image.size, 'contain')"
            :height="cols('50vh', '200px')"
            :position="section.options.content.image.position"
          />
        </highliter>
      </template>

      <template slot="elements">
        <elements v-model="section.options.content.elements" />
      </template>
    </FeaturedItems3Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import FeaturedItems3Template from '@builder/templates/sections/featured/FeaturedItems3Template'
import ImageFit from '../../../utils/ImageFit'

export default {
  name: 'FeaturedItems3',

  components: {ImageFit, FeaturedItems3Template, Highliter},

  mixins: [SectionMixin]
}
</script>
