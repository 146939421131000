var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SidbearSettingsGroup',{attrs:{"title":"Cards Slider","active":""}},[_c('draggable',{staticClass:"mt-3",attrs:{"handle":".move"},model:{value:(_vm.section.options.content.carousel.cards),callback:function ($$v) {_vm.$set(_vm.section.options.content.carousel, "cards", $$v)},expression:"section.options.content.carousel.cards"}},[_c('transition-group',{attrs:{"name":"shufle"}},_vm._l((_vm.cards),function(card,index){return _c('flex-column-drag-item',{key:("key-" + index),attrs:{"item":card},on:{"open":function($event){_vm.sectionSettings('card-carousel-card-mod', {subjectIndex: card.sid, title: 'Card', back: function () {_vm.sectionSettings('card-carousel-mod')}})},"delete":function($event){return _vm.deleteColumn(index, card)},"copy":function($event){return _vm.copyColumn(card)}}})}),1)],1),_c('button',{staticClass:"flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]",on:{"click":_vm.addNewItem}},[_vm._v(" Add Item "),_c('span',{staticClass:"text-[#3D82EA] text-[20px]"},[_vm._v("+")])]),_c('b-form-group',{attrs:{"label":"Animation Type"}},[_c('v-select',{attrs:{"options":_vm.animations,"reduce":function (ref) {
      var value = ref.value;

      return value;
},"searchable":false},model:{value:(_vm.section.options.content.carousel.animationType),callback:function ($$v) {_vm.$set(_vm.section.options.content.carousel, "animationType", $$v)},expression:"section.options.content.carousel.animationType"}})],1)],1),_c('SidbearSettingsGroup',{attrs:{"title":"Scrollbar"}},[_c('sid-range-slider',{attrs:{"label":"Height","rule-name":"--carousel-scrollbar-height","default-dimension":"px","dimensions":[],"sid":(".--" + (_vm.section.sid) + "-- .carousel-card-container")}}),_c('b-form-group',{attrs:{"label":"Thumb Background"}},[_c('sid-background',{attrs:{"sid":(".--" + (_vm.section.sid) + "-- .carousel-card-container::-webkit-scrollbar-thumb")}})],1),_c('b-form-group',{attrs:{"label":"Track Background"}},[_c('sid-background',{attrs:{"sid":(".--" + (_vm.section.sid) + "-- .carousel-card-container::-webkit-scrollbar-track")}})],1)],1),_c('SidbearSettingsGroup',{attrs:{"title":"Card"}},[_c('sid-range-slider',{attrs:{"label":"Width","rule-name":"--width","var":"--carouse-card-width","sid":(".--" + (_vm.section.sid) + "--"),"measurement":"width","px-range":[244, 3000]}}),_c('sid-range-slider',{attrs:{"label":"Height","rule-name":"--height","var":"--carouse-card-height","sid":(".--" + (_vm.section.sid) + "--"),"default-dimension":"px","dimensions":['vh', 'px'],"measurement":"height"}}),_c('sid-buttons-group',{staticClass:"mb-0",attrs:{"label":"Vertical Alignment","options":[
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'start',
          tooltip: 'Start'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'center',
          tooltip: 'Center'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'end',
          tooltip: 'End'
        }
      ],"sid":(".--" + (_vm.section.sid) + "-- .carousel-card"),"rule-name":"justify-content"}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Padding & Margins"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":(".--" + (_vm.section.sid) + "--"),"rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":(".--" + (_vm.section.sid) + "--"),"rule-name":"margin","min":-2000,"max":2000,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }