<template>
  <div>
    <SidbearSettingsGroup
      active
      title="Button"
      content-class="pb-0"
    >
      <ElementPresset
        v-if="render"
        v-model="pressetID"
        :pressets="customButtonPressets || buttonPressets"
        class="mb-[27px]"
        title="Button Style"
      >
        <template
          slot="preview"
          slot-scope="{ content }"
        >
          <img
            v-if="content.preview"
            :src="content.preview"
            alt="element presset image"
          >
          <span
            v-else
            v-text="content.text"
          />
        </template>
      </ElementPresset>
      <div
        v-else
        class="h-[144px] mb-[27px]"
      />

      <StateTabs
        :states-text="['Normal State', 'Hover State']"
        class="pb-[12px]"
      >
        <b-form-group
          v-if="!hide.includes('text')"
          label="Text"
        >
          <b-input v-model="config.text" />
        </b-form-group>

        <b-form-group
          v-if="!hide.includes('subtext')"
          label="Subtext"
        >
          <b-input v-model="config.subtext" />
        </b-form-group>

        <div class="relative">
          <ButtonBackground :config="config" />
          
          <b-form-group
            v-if="!hide.includes('align')"
            label="Alignment"
          >
            <b-btn-group>
              <b-btn
                variant="white border"
                @click="align = 'start'"
              >
                <i
                  :class="{'text-primary': align === 'start'}"
                  class="fa fa-align-left text-[#000]"
                />
              </b-btn>
              <b-btn
                variant="white border"
                @click="align = 'center'"
              >
                <i
                  :class="{'text-primary': align === 'center'}"
                  class="fa fa-align-center text-[#000]"
                />
              </b-btn>
              <b-btn
                variant="white border"
                @click="align = 'end'"
              >
                <i
                  :class="{'text-primary': align === 'end'}"
                  class="fa fa-align-right text-[#000]"
                />
              </b-btn>
            </b-btn-group>
          </b-form-group>

          <ButtonSize :config="config" />

          <ButtonIcon :config="config" />

          <font-family-and-weight-control
            text-type="button"
            :family="fontFamily"
            :weight="fontWeight"
            :global-style-option-enable="!globalStylePresset"
            @family="family => fontFamily = family"
            @weight="weight => fontWeight = weight"
          />

          <div
            v-if="config.pressetID === 0 && !hide.includes('overlay')"
            class="absolute top-0 left-0 bg-white w-100 h-100 z-index-100 opacity-80"
          />
        </div>

        <slot
          v-if="!hide.includes('actions')"
          name="actions"
        >
          <ButtonAction
            :config="config"
          />
        </slot>

        <template slot="action-state">
          <ButtonHoverText
            v-if="!hide.includes('text')"
            :config="config"
          />
          
          <div class="relative">
            <ButtonHoverBackground :config="config" />
            <ButtonHoverShadow :config="config" />
            <hover-animations v-model="hoverAnimation" />

            <div
              v-if="config.pressetID === 0 && !hide.includes('overlay')"
              class="absolute top-0 left-0 bg-white w-100 h-100 z-index-100 opacity-80"
            />
          </div>
        </template>
      </StateTabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      :title="`${groupPrefix} Animations`"
    >
      <animations :animations="animations" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      :title="`${groupPrefix} Border & Radius`"
    >
      <ButtonBorder :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      :title="`${groupPrefix} Drop Shadow`"
    >
      <ButtonShadow :config="config" />
    </SidbearSettingsGroup>

    <template>
      <SidbearSettingsGroup
        :title="`${groupPrefix} Padding & Margins`"
        content-class="pb-0"
      >
        <ButtonMargin :config="config" />
      </SidbearSettingsGroup>
    </template>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ThemeMixin from '@builder/components/mixins/ThemeMixin'
import {mapGetters, mapState} from 'vuex'
import Animations from '../controls/Animations'
import popperMixin from '../mixins/popperMixin'
import PagesPaginationMixin from '../mixins/PagesPaginationMixin'
import _ from 'lodash'
import ElementPresset from '@/components/editor/components/ElementPresset'
import ButtonAction from '@/components/editor/controls/button-mod/ButtonAction'
import ButtonShadow from '@/components/editor/controls/button-mod/ButtonShadow'
import ButtonIcon from '@/components/editor/controls/button-mod/ButtonIcon'
import ButtonBackground from '@/components/editor/controls/button-mod/ButtonBackground'
import StateTabs from '@/components/editor/components/StateTabs'
import ButtonSize from '@/components/editor/controls/button-mod/ButtonSize'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import ButtonBorder from '@/components/editor/controls/button-mod/ButtonBorder'
import ButtonHoverBackground from '@/components/editor/controls/button-mod/hover/ButtonHoverBackground'
import ButtonMargin from '@/components/editor/controls/button-mod/ButtonMargin'
import buttonVariants from '@/composition/buttons'
import ButtonHoverText from '@/components/editor/controls/button-mod/hover/ButtonHoverText'
import GlobalStyleButton from '@/components/mixins/GlobalStyleButton'
import FontFamilyAndWeightControl from '@/components/editor/controls/FontFamilyAndWeightControl'
import HoverAnimations from '@/components/editor/controls/HoverAnimations'
import ButtonHoverShadow from '@/components/editor/controls/button-mod/hover/ButtonHoverShadow'

export default {
  name: 'ButtonMod',
  components: {
    ButtonHoverShadow,
    HoverAnimations,
    FontFamilyAndWeightControl,
    ButtonHoverText,
    ButtonMargin,
    ButtonHoverBackground,
    ButtonBorder,
    SidbearSettingsGroup,
    ButtonSize,
    StateTabs,
    ButtonBackground,
    ButtonIcon,
    ButtonShadow,
    ButtonAction,
    ElementPresset,
    Animations
  },
  mixins: [ModMixin, ThemeMixin, popperMixin, PagesPaginationMixin, GlobalStyleButton],

  props: {
    customButtonPressets: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      render: true,
      styleOptions: [
        {
          key: null,
          label: 'Global Style'
        },
        {
          key: 'filled',
          label: 'Filled'
        },
        {
          key: 'outlined',
          label: 'Outlined'
        }
      ],
      sizeOptions: [
        {
          key: 'sm',
          label: 'Small'
        },
        {
          key: 'md',
          label: 'Default'
        },
        {
          key: 'lg',
          label: 'Large'
        },
        {
          key: 'xxl',
          label: 'Extra Large'
        }
      ],
      buttonPressets: [
        {
          id: 0,
          text: 'Global Style',
          onSelect: () => {
            // this.$set(this.config, 'pressetID', 0)
          }
        },
        {
          id: 1,
          text: 'Custom',
          onSelect: (pressetID) => {
            const button = _.cloneDeep({
              ...this.globalStyleButton.options.content.button,
              'border': {
                lg: [0, 0, 0, 0],
                md: [0, 0, 0, 0],
                sm: [0, 0, 0, 0]
              },
              'radius': {
                lg: [4, 4, 4, 4],
                md: [4, 4, 4, 4],
                sm: [4, 4, 4, 4]
              }
            })
            button.text = this.config.text
            button.padding = this.config.padding
            button.margin = this.config.margin
            button.pressetID = pressetID
            button.action = this.config.action
            button.blank_page = this.config.blank_page
            button.page = this.config.page
            button.modal = this.config.modal
            button.randomLinks = this.config.randomLinks

            this.$set(this.section.options.content, 'button', button)
          }
        },
        {
          id: 2,
          preview: require('@/assets/images/element-presset/button-mod/button-1.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[1])

            button.text = this.config.text
            button.padding = this.config.padding
            button.margin = this.config.margin
            button.pressetID = pressetID
            button.action = this.config.action
            button.blank_page = this.config.blank_page
            button.page = this.config.page
            button.modal = this.config.modal
            button.randomLinks = this.config.randomLinks

            this.$set(this.section.options.content, 'button', button)
          }
        },
        {
          id: 3,
          preview: require('@/assets/images/element-presset/button-mod/button-2.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[2])

            button.text = this.config.text
            button.padding = this.config.padding
            button.margin = this.config.margin
            button.pressetID = pressetID
            button.action = this.config.action
            button.blank_page = this.config.blank_page
            button.page = this.config.page
            button.modal = this.config.modal
            button.randomLinks = this.config.randomLinks

            this.$set(this.section.options.content, 'button', button)
          }
        },
        {
          id: 4,
          preview: require('@/assets/images/element-presset/button-mod/button-3.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[3])

            button.text = this.config.text
            button.padding = this.config.padding
            button.margin = this.config.margin
            button.pressetID = pressetID
            button.action = this.config.action
            button.blank_page = this.config.blank_page
            button.page = this.config.page
            button.modal = this.config.modal
            button.randomLinks = this.config.randomLinks

            this.$set(this.section.options.content, 'button', button)
          }
        },
        {
          id: 5,
          preview: require('@/assets/images/element-presset/button-mod/button-4.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[4])

            button.text = this.config.text
            button.padding = this.config.padding
            button.margin = this.config.margin
            button.pressetID = pressetID
            button.action = this.config.action
            button.blank_page = this.config.blank_page
            button.page = this.config.page
            button.modal = this.config.modal
            button.randomLinks = this.config.randomLinks

            this.$set(this.section.options.content, 'button', button)
          }
        },
        {
          id: 6,
          preview: require('@/assets/images/element-presset/button-mod/button-5.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[5])

            button.text = this.config.text
            button.padding = this.config.padding
            button.margin = this.config.margin
            button.pressetID = pressetID
            button.action = this.config.action
            button.blank_page = this.config.blank_page
            button.page = this.config.page
            button.modal = this.config.modal
            button.randomLinks = this.config.randomLinks

            this.$set(this.section.options.content, 'button', button)
          }
        },
        {
          id: 7,
          preview: require('@/assets/images/element-presset/button-mod/button-6.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[6])

            button.text = this.config.text
            button.padding = this.config.padding
            button.margin = this.config.margin
            button.pressetID = pressetID
            button.action = this.config.action
            button.blank_page = this.config.blank_page
            button.page = this.config.page
            button.modal = this.config.modal
            button.randomLinks = this.config.randomLinks

            this.$set(this.section.options.content, 'button', button)
          }
        },
        {
          id: 8,
          preview: require('@/assets/images/element-presset/button-mod/button-7.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[7])

            button.text = this.config.text
            button.padding = this.config.padding
            button.margin = this.config.margin
            button.pressetID = pressetID
            button.action = this.config.action
            button.blank_page = this.config.blank_page
            button.page = this.config.page
            button.modal = this.config.modal
            button.randomLinks = this.config.randomLinks

            this.$set(this.section.options.content, 'button', button)
          }
        },
        {
          id: 9,
          preview: require('@/assets/images/element-presset/button-mod/button-8.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[8])

            button.text = this.config.text
            button.padding = this.config.padding
            button.margin = this.config.margin
            button.pressetID = pressetID
            button.action = this.config.action
            button.blank_page = this.config.blank_page
            button.page = this.config.page
            button.modal = this.config.modal
            button.randomLinks = this.config.randomLinks

            this.$set(this.section.options.content, 'button', button)
          }
        },
        {
          id: 10,
          preview: require('@/assets/images/element-presset/button-mod/button-9.svg'),
          onSelect: (pressetID) => {
            const button = _.cloneDeep(buttonVariants[9])

            button.text = this.config.text
            button.padding = this.config.padding
            button.margin = this.config.margin
            button.pressetID = pressetID
            button.action = this.config.action
            button.blank_page = this.config.blank_page
            button.page = this.config.page
            button.modal = this.config.modal
            button.randomLinks = this.config.randomLinks

            this.$set(this.section.options.content, 'button', button)
          }
        }
      ]
    }
  },

  computed: {
    pressetID: {
      get () {
        return this.config.pressetID || 0
      },
      set (val) {
        setTimeout(() => this.$set(this.config, 'pressetID', val))
      }
    },
    getButtonStyles () {
      return this.config.resolutionStyle
    },
    align: {
      get () {
        return _.get(this.config.resolutionStyle, [this.resolutioner, 'align'], 'left')
      },
      set (val) {
        this.$set(this.config.resolutionStyle, this.resolutioner, {
          ..._.get(this.config.resolutionStyle, [this.resolutioner], {}),
          align: val
        })
      }
    },
    buttonName: {
      get () {
        return this.config.buttonName
      },
      set (val) {
        this.$set(this.config, 'buttonName', val)
      }
    },
    buttonType: {
      get () {
        return this.config.buttonType || 'button'
      },
      set (val) {
        if (val === 'submit') {
          this.$store.dispatch('forms/addAvaliableButton', this.section)
        } else {
          this.$store.dispatch('forms/removeAvaliableButton', this.section)
        }

        this.$set(this.config, 'buttonType', val)
      }
    },
    hoverAnimation: {
      get () {
        return this.config.hoverAnimation || null
      },
      set (val) {
        this.$set(this.config, 'hoverAnimation', val)
      }
    },
    animations: {
      get () {
        if (!this.config.hasOwnProperty('animations')) {
          this.$set(this.config, 'animations', {
            animation: null,
            once: true,
            offset: 200,
            delay: 50
          })
        }

        return this.config.animations
      },
      set (val) {
        this.config.animations = val
      }
    },
    btnStyle: {
      get () {
        return this.config.resolutionStyle['lg'].btnStyle || null
      },
      set (val) {
        this.$set(this.config.resolutionStyle['lg'], 'btnStyle', val)
      }
    },
    sizeObj () {
      return _.isObject(this.config.size) ? this.config.size : {
        lg: this.config.resolutionStyle['lg'].size,
        md: this.config.resolutionStyle['lg'].size,
        sm: this.config.resolutionStyle['lg'].size
      }
    },
    size: {
      get () {
        return this.sizeObj[this.resolutioner]
      },
      set (val) {
        this.$set(this.config, 'size', {
          ...this.sizeObj,
          [this.resolutioner]: val
        })
      }
    },
    fontFamily: {
      get () {
        return this.config.fontFamily ? this.config.fontFamily : this.globalStylePresset ? 'Roboto' : null
      },
      set (val) {
        this.$set(this.config, 'fontFamily', val)
      }
    },
    fontWeight: {
      get () {
        return this.config.fontWeight
      },
      set (val) {
        this.$set(this.config, 'fontWeight', val)
      }
    },
    textColor () {
      return this.config.resolutionStyle['lg'].color !== 'adaptive'
        ? this.config.resolutionStyle['lg'].color
        : this.theme === 'dark' ? '#fff' : '#000'
    },
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    ...mapState('font', {
      fonts: state => state.fonts
    })
  },
  watch: {
    // pressetID () {
    //   this.render = false
    //   setTimeout(() => this.render = true)
    // },
    getButtonStyles: {
      deep: true,
      handler: function () {
        // this.pressetID = 0
      }
    }
  },

  methods: {
    async onOpen () {
      setTimeout(() => {
        document.querySelector('.vs__dropdown-menu').addEventListener('scroll', this.scrollLoadingPages)
      })
    },
    onClose () {
      this.searching = false
      document.querySelector('.vs__dropdown-menu').removeEventListener('scroll', this.scrollLoadingPages)
    },
    setDefaults () {
      this.config.resolutionStyle['lg'].backgroundColor = 'colored'
      this.config.resolutionStyle['lg'].color = 'adaptive'
      this.config.iconColor = ''
      this.$set(this.config, 'subTextColor', 'adaptive')
      this.config.subtextIconColor = ''
      this.config.resolutionStyle['lg'].fontSize = 'Global Style'
      this.config.subTextFontSize = 'Global Style'
      this.config.subTextFontFamily = 'Global Style'
    },
    fontSizes () {
      const arr = Array(70)
        .fill({})
        .map((_, i) => `${i + 1}px`)

      arr.unshift('Global Style')
      return arr
    }
  }
}
</script>
