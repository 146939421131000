<template>
  <div class="relative">
    <input
      v-model="search"
      type="text"
      placeholder="Search templates..."
      class="border h-[38px] px-[16px] w-[322px] placeholder-[#B5BBC5] placeholder-[400] rounded-[3px]"
    >

    <div class="absolute right-[13px] top-1/2 -translate-y-1/2">
      <b-spinner
        v-if="loading"
        variant="primary"
        small
      />
      <svg
        v-else
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.1781 9.43713C11.7089 7.2892 11.5108 4.28795 9.58373 2.36091C7.43584 0.213029 3.95344 0.213029 1.80555 2.36091C-0.34233 4.5088 -0.34233 7.9912 1.80555 10.1391C3.73259 12.0661 6.73384 12.2643 8.88177 10.7335L14.121 15.9727L15.4174 14.6764L10.1781 9.43713C10.1781 9.43713 11.7089 7.2892 10.1781 9.43713ZM8.28737 3.65727C9.71929 5.0892 9.71929 7.4108 8.28737 8.84272C6.85544 10.2746 4.53384 10.2746 3.10192 8.84272C1.66999 7.4108 1.66999 5.0892 3.10192 3.65727C4.53384 2.22535 6.85544 2.22535 8.28737 3.65727C8.28737 3.65727 9.71929 5.0892 8.28737 3.65727Z"
          fill="#6E747A"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'TemplatesSearch',
  data () {
    return {
      timer: null
    }
  },
  computed: {
    ...mapState('templates', {
      loading: state => state.loading
    }),
    search: {
      get () {
        return this.$store.state.templates.search
      },
      set (val) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$store.state.templates.search = val
          this.$store.dispatch('templates/getTemplates')
        }, 200)
      }
    }
  }
}
</script>