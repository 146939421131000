<template>
  <b-modal
    ref="modal"
    :visible="modal"
    dialog-class="assets-modal"
    size="xl"
    header-class="!border-b-0"
    body-class="p-0"
    hide-footer
    @change="handleModalChange"
  >
    <template #modal-header>
      <icon
        class="ml-auto cursor-pinter"
        icon="close.svg"
        @click.native="handleModalChange(false)"
      />
    </template>

    <div
      v-if="loading"
      class="w-full h-[250px] flex items-center justify-center "
    >
      <b-spinner
        class="!w-12 !h-12"
        variant="primary"
      />
    </div>

    <template v-else>
      <assets-lib-header
        v-if="modal"
        @uploaded="uploadFile"
      />

      <assets-lib-content
        v-if="modal"
        ref="al-content"
        :type="type"
        :modal-id="modalId"
        :uploaded="uploaded && uploadFile"
        @upload-from-saved-ones="addImageFromFavorites"
        @delete-from-saved-ones="deleteImageFromFavorite"
      />
    </template>
  </b-modal>
</template>

<script>
import AssetsLibHeader from '@/components/assets-library/AssetsLibHeader.vue'
import AssetsLibContent from '@/components/assets-library/AssetsLibContent.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import _ from 'lodash'

export default {
  components: {
    AssetsLibHeader,
    AssetsLibContent
  },

  props: {
    type: {
      type: String,
      default: null
    },

    modal: {
      type: Boolean,
      default: false
    },

    file: {
      type: Object,
      default: null
    },

    uploaded: Function
  },

  data () {
    return {
      loading: false,
      modalId:null
    }
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles,
      section: state => state.section
    }),

    ...mapState('assetsLibrary', {
      activeFile: state => state.activeFile,
      folders: state => state.folders
    })
  },

  async mounted () {
    this.loading = true
    await this.getTotals()
    await this.getFolders()
    this.loading = false
    this.modalId = this.$refs.modal ? this.$refs.modal.modalId : null

    this.$nextTick(() => {
      const self = this

      let
        isMouseDown = false,
        isMouseDownPos = null,
        draggingItem = null

      document.addEventListener('mousedown',function (e){
        const el  = e.target
        const draggable = el.closest('[data-draggable="file"]')

        if(draggable){
          const id =draggable.getAttribute('data-draggable-id')
          const type =draggable.getAttribute('data-draggable')
          const name =draggable.getAttribute('data-draggable-name')
          draggingItem = { id, type,name,el:draggable }
          isMouseDownPos = {x:e.clientX,y:e.clientY}
          isMouseDown = true
        }
      })

      const getDropZonesInPosition = (x,y)=> {
        const items = document.querySelectorAll('[data-draggable=folder]:not(.dragging-item-highlighted)')
        let item = null

        items.forEach(function (el) {
          const pos = el.getBoundingClientRect()
          const y1 = pos.top
          const y2 = y1 + pos.height
          const x1 = pos.left
          const x2 = x1 +  pos.width

          if (y >= y1 && y <= y2 && x >= x1 && x <= x2) {
            const id = el.getAttribute('data-draggable-id')
            const type = el.getAttribute('data-draggable')
            const name = el.getAttribute('data-draggable-name')
            item = { id, name, type, el}
          }
        })

        return item
      }

      document.addEventListener('mousemove',function async (e){
        if (isMouseDown && draggingItem) {
          const x = e.clientX
          const y = e.clientY
          const x2 = isMouseDownPos.x
          const y2 = isMouseDownPos.y
          const dist = Math.sqrt(Math.pow((x-x2), 2) + Math.pow((y-y2), 2))
          if(dist < 5) return

          if(!document.querySelector('.dragging-item')) {
            const clone = draggingItem.el.cloneNode(true)
            clone.classList.remove('border')
            clone.classList.remove('border-[#E2E5EC]')
            clone.classList.add('dragging-item')
            clone.classList.add('!border-2')
            clone.classList.add('!border-[#4F83E3]')
            clone.classList.add('!shadow-md')
            document.body.append(clone)
          }

          const dragging = document.querySelector('.dragging-item')
          dragging.style.left = `${x}px`
          dragging.style.top = `${y}px`

          const dropzone = getDropZonesInPosition(e.clientX,e.clientY)

          if (dropzone){
            const el = dropzone.el
            el.classList.add('dragging-dropzone-highlighted')
          } else {
            document.querySelectorAll('.dragging-dropzone-highlighted').forEach(el => {
              el.classList.remove('dragging-dropzone-highlighted')
            })
          }
        }
      })

      document.addEventListener('mouseup',function (){
        const highlightedDropzone = document.querySelector('.dragging-dropzone-highlighted')

        if(highlightedDropzone){
          const folderId = highlightedDropzone.getAttribute('data-draggable-id')
          self.moveFileToFolder({
            id:draggingItem.id,
            folder_id:folderId
          })
        }

        isMouseDown = false
        draggingItem = null
        document.querySelectorAll('.dragging-item').forEach(el => el.remove())
        document.querySelectorAll('.dragging-item-highlighted').forEach(el => el.classList.remove('dragging-item-highlighted'))
        document.querySelectorAll('.dragging-dropzone-highlighted').forEach(el => el.classList.remove('dragging-dropzone-highlighted'))
      })
    })
  },

  methods: {
    ...mapActions({
      getTotals:'assetsLibrary/getTotals',
      getFolders:'assetsLibrary/getFolders',
      moveFileToFolder:'assetsLibrary/moveFileToFolder'
    }),

    ...mapMutations({
      setActiveFile:'assetsLibrary/SET_ACTIVE_FILE'
    }),

    uploadFile (file) {
      if (!this.$store.state.assetsLibrary.activeFolder) {
        this.$refs['al-content']
          .openFolder(_.find(this.folders, { type: file.name.indexOf('.pdf') !== -1 ? 'pdf' : 'image' }, null))
      }

      if (!this.file) return
      this.$set(this.file, 'src', file.url)
      this.$set(this.file, 'id', file.id)

      if (this.uploaded) {
        this.uploaded({...file, src: file.url})
      }

      this.handleModalChange(false)
    },

    addImageFromFavorites (url) {
      if (!this.file) return

      if (this.section.component === 'ElImageV4') {
        this.setActiveFile(this.activeFile && this.activeFile.url === url
          ? null

          : {
            ...this.file,
            url: url,
            src: url
          }
        )
      } else {
        this.$set(this.file, 'src', url)

        if (this.uploaded) {
          this.uploaded({ url })
        }

        this.handleModalChange(false)
      }
    },

    deleteImageFromFavorite (index) {
      this.globalStyles.libraryImages.splice(index, 1)
      this.$emit('removed-favorite')
      this.$store.dispatch('projects/save', true)
    },

    handleModalChange (val) {
      this.$emit('update:modal',val)
    }
  },

  watch: {
    modal (nextState, prevState) {
      if (nextState !== prevState) {
        this.setActiveFile(null)
      }
    }
  }
}
</script>

<style lang="scss" >
.assets-modal .modal-content {
  min-height: 110vh;
}

@media (max-width: 1200px) {
  .assets-modal{
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 50px)!important;
  }
}
</style>
