<template>
  <fieldset class="relative flex rounded-full p-[.1875rem] border border-[#FFFFFF] bg-gradient-to-b from-[#FFFFFF] to-[#E6E6E6] shadow-[0_1px_3px_0_rgba(0,0,0,0.15)]">
    <div
      v-for="(plan, index) in plans"
      :key="index"
      class="relative flex items-center justify-center w-auto"
    >
      <label
        :class="value === index ? '!text-[#FFFFFF] bg-gradient-to-b from-[#A0DF64] to-[#63AE63]' : null"
        class="flex flex-wrap flex-shrink-0 w-full rounded-full h-[34px] m-[0] px-[1rem] text-[#637288] text-center items-center justify-center cursor-pointer"
      >
        <input
          v-model="selectedValue"
          :value="index"
          class="pointer-events-none absolute opacity-0 h-0 w-0"
          type="radio"
          @change="updateValue"
        >
        <span class="text-[.75rem] leading-none font-[700] font-[ProximaNova]">
          {{ plan.name }} {{ plan.save ? `(Save ${plan.save}%)` : null }}
        </span>
      </label>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: 'PlanSelect',
  props: {
    value: {
      type: Number,
      required: false
    },
    modelValue: {
      type: Number,
      required: false
    },
    plans: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedValue: this.value
    }
  },
  methods: {
    updateValue () {
      this.$emit('input', this.selectedValue)
    }
  },
  watch: {
    value (val) {
      this.selectedValue = val
    }
  }
}
</script>