<template>
  <div>
    <input
      ref="input"
      :value="value"
      :type="type"
      class="w-full h-full px-2"
      :placeholder="placeholder"
      v-bind="$attrs"
      @input="onInput"
    >
  </div>
</template>

<script>
export default {
  name: 'PageSelectMenuSearch',
  props: {
    value:{
      type:String,
      default:''
    },
    autoFocus: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'search'
    },
    placeholder: {
      type: String,
      default: 'Type to search pages'
    }
  },
  data () {
    return {
      timer: null
    }
  },
  mounted () {
    if (this.autoFocus) {
      setTimeout(() => {
        this.$refs.input.focus()
      }, 10)
    }
  },
  methods: {
    onInput (e) {
      const val = e.target.value
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.$emit('input', val)
        this.$emit('onSearch', val)
      }, 300)
    }
  }
}
</script>