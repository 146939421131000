import { Mark } from 'tiptap'
import { updateMark, removeMark } from 'tiptap-commands'

export default class TextColor extends Mark {

  get name () {
    return 'textcolor'
  }

  get defaultOptions () {
    return {
      color: ['red']
    }
  }

  get schema () {
    return {
      attrs: {
        color: {
          default: 'red'
        }
      },
      parseDOM: this.options.color.map(color => ({
        tag: 'span',
        attrs: { color },
        getAttrs (dom){
          return {
            color:dom.style.color
          }
        }
      })),
      toDOM:
        node => {
          return ['span', {
            style: `color:${node.attrs.color}`
          }, 0]
        }
    }
  }

  commands ({ type }) {
    return (attrs) => {
      if (!attrs) {
        return removeMark(type, attrs)
      }

      return updateMark(type, attrs)
    }
  }
}
