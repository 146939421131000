import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Field Card Number'
export const group = ''

export default class ElFieldCardNumber extends SectionProvider {
  constructor (options = {}) {
    super('ElFieldCardNumber', options)

    this.name = name
    this.group = group
  }
}