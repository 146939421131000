<template>
  <div>
    <b-modal
      v-model="confirmDialog"
      body-class="text-center py-5"
      size="lg"
      hide-footer
      hide-header
      centered
    >
      <h4
        class="h2 font-weight-bold mb-4"
        v-html="title"
      />

      <p
        class="my-4"
        v-html="description"
      />


      <div class="d-flex align-items-center justify-content-center">
        <b-btn
          v-if="!hideYesBtn"
          variant="danger"
          class="btn-wide mr-2"
          @click="confirm"
        >
          {{ yesBtnText }}
        </b-btn>
        <b-btn
          class="btn-wide"
          @click="confirmDialog = false"
        >
          {{ noBtnText }}
        </b-btn>
      </div>
    </b-modal>

    <slot
      name="activator"
      v-bind="{on: handlers}"
      @click="confirmDialog = true"
    />
  </div>
</template>

<script>
export default {
  name: 'DeleteConfirm',
  props: {
    title: String,
    description: String,
    yesBtnText:{
      type:String,
      default:'Yes'
    },
    noBtnText:{
      type:String,
      default:'No'
    },
    hideYesBtn: Boolean
  },
  data () {
    return {
      confirmDialog: false,
      handlers: {
        click: () => this.onClick()
      }
    }
  },
  methods: {
    onClick () {
      this.confirmDialog = true
    },
    confirm () {
      this.confirmDialog = false
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped>

</style>