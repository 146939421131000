<template>
  <div>
    <SidbearSettingsGroup
      title="Search Form"
      active
    >
      <b-form-group>
        <sid-flex-direction
          :sid="className()"
          rule-name="flex-direction"
        />

        <sid-buttons-group
          label="Alignment"
          :options="[
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              customProperties: [[className(), ['margin-left', '']], [className(), ['margin-right', '']], [className(), ['--align', 'start']]],
              value: 'start',
              tooltip: 'Start'
            },
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              customProperties: [[className(), ['margin-left', 'auto']], [className(), ['margin-right', 'auto']], [className(), ['--align', 'center']]],
              value: 'center',
              tooltip: 'Center'
            },
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              customProperties: [[className(), ['margin-left', 'auto']], [className(), ['margin-right', '']], [className(), ['--align', 'end']]],
              value: 'end',
              tooltip: 'End'
            }
          ]"
          :sid="className()"
          rule-name="--align"
        />
      </b-form-group>

      <b-form-group>
        <sid-range-slider
          label="Gap"
          :sid="className()"
          rule-name="gap"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Input Field"
    >
      <b-form-group>
        <div class="flex gap-[14px] items-center">
          <label class="pb-0">Text Color</label>
          <sid-color
            rule-name="color"
            :sid="className(' .input')"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex gap-[14px] items-center">
          <label class="pb-0">Placeholder Color</label>
          <sid-color
            rule-name="color"
            :sid="className(' .input::placeholder')"
          />
        </div>
      </b-form-group>

      <sid-background
        :sid="className(' .input')"
      />

      <sid-range-slider
        label="Height"
        :sid="className(' .input')"
        rule-name="height"
        default-dimension="px"
        :dimensions="[]"
        var="--height"
      />


      <sid-range-slider
        label="Width"
        :sid="className()"
        rule-name="width"
        default-dimension="%"
        :dimensions="[]"
        var="--width"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="className(' .input')"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="className(' .input')"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="className(' .input')"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="className(' .input')"
        rule-name="border"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />

      <sid-box-shadow
        :sid="className(' .input')"
        rule-name="box-shadow"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Button">
      <SidbearSettingsGroup
        title="Main Text"
        active
        content-class="border-0"
      >
        <rule-tabs>
          <template slot="Normal">
            <sid-font
              :sid="className(' button.search-button')"
            />

            <sid-range-slider
              label="Text Size"
              :sid="className(' button.search-button')"
              rule-name="font-size"
            />

            <sid-range-slider
              label="Letter-Spacing"
              :sid="className(' button.search-button')"
              rule-name="letter-spacing"
            />

            <sid-range-slider
              label="Line-Height"
              :sid="className(' button.search-button')"
              rule-name="line-height"
            />

            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Text Color</label>

                <sid-color
                  :sid="className(' button.search-button')"
                  rule-name="color"
                />
              </div>
            </b-form-group>

            <sid-align
              :sid="className(' button.search-button')"
              rule-name="justify-content"
              label="Text Alignment"
            />

            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="p-0">Text Decoration</label>

                <sid-font-style
                  :sid="className(' button.search-button')"
                  rule-name="font"
                />
              </div>
            </b-form-group>
          </template>

          <template slot="Hover">
            <sid-range-slider
              label="Text Size"
              :sid="className(' button.search-button')"
              rule-name="font-size"
              presudo=":hover"
            />

            <sid-range-slider
              label="Letter-Spacing"
              :sid="className(' button.search-button')"
              rule-name="letter-spacing"
              presudo=":hover"
            />


            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Text Color</label>

                <sid-color
                  :sid="className(' button.search-button')"
                  presudo=":hover"
                  rule-name="color"
                />
              </div>
            </b-form-group>
          </template>
        </rule-tabs>
      </SidbearSettingsGroup>

      <SidbearSettingsGroup title="Layout & Style">
        <rule-tabs>
          <template #Normal>
            <sid-align
              :sid="className(' .search-button')"
              rule-name="justify-content"
              label="Button Alignment"
            />

            <sid-background :sid="className(' button.search-button')" />

            <sid-range-slider
              label="Width"
              :sid="className(' button.search-button')"
              rule-name="width"
              var="--width"
              default-dimension="%"
              :dimensions="['px', '%']"
              measurement="width"
            />

            <sid-range-slider
              label="Height"
              :sid="className(' button.search-button')"
              rule-name="height"
              var="--height"
              default-dimension="px"
              :dimensions="['px', '%']"
            />
          </template>

          <template #Hover>
            <sid-background
              :sid="className(' button.search-button')"
              presudo=":hover"
            />
          </template>
        </rule-tabs>
      </SidbearSettingsGroup>

      <SidbearSettingsGroup title="Border & Radius">
        <rule-tabs>
          <template slot="Normal">
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Border Type</label>

                <sid-select
                  :sid="className(' button.search-button')"
                  rule-name="border-style"
                  :searchable="false"
                  class="w-[118px]"
                  :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
                />
              </div>
            </b-form-group>

            <b-form-group>
              <sid-linked-group
                :sid="className(' button.search-button')"
                label="Border Width"
                rule-name="border"
                class="!mb-[7px]"
                :angles="[
                  {
                    text: '',
                    value: 'top-width'
                  },
                  {
                    text: '',
                    value: 'right-width'
                  },
                  {
                    text: '',
                    value: 'bottom-width'
                  },
                  {
                    text: '',
                    value: 'left-width'
                  }
                ]"
              />

              <div class="flex w-full justify-end">
                <sid-color
                  :sid="className(' button.search-button')"
                  rule-name="border-color"
                />
              </div>
            </b-form-group>

            <sid-linked-group
              label="Radius"
              :sid="className(' button.search-button')"
              rule-name="border"
              class="mb-0"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius'
                }
              ]"
            />
          </template>

          <template slot="Hover">
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Border Type</label>

                <sid-select
                  :sid="className(' button.search-button')"
                  rule-name="border-style"
                  :searchable="false"
                  class="w-[118px]"
                  presudo=":hover"
                  :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
                />
              </div>
            </b-form-group>

            <b-form-group>
              <sid-linked-group
                :sid="className(' button.search-button')"
                label="Border Width"
                rule-name="border"
                class="!mb-[7px]"
                presudo=":hover"
                :angles="[
                  {
                    text: '',
                    value: 'top-width'
                  },
                  {
                    text: '',
                    value: 'right-width'
                  },
                  {
                    text: '',
                    value: 'bottom-width'
                  },
                  {
                    text: '',
                    value: 'left-width'
                  }
                ]"
              />

              <div class="flex w-full justify-end">
                <sid-color
                  :sid="className(' button.search-button')"
                  presudo=":hover"
                  rule-name="border-color"
                />
              </div>
            </b-form-group>

            <sid-linked-group
              label="Radius"
              :sid="className(' button.search-button')"
              rule-name="border"
              presudo=":hover"
              class="mb-0"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius'
                }
              ]"
            />
          </template>
        </rule-tabs>
      </SidbearSettingsGroup>

      <SidbearSettingsGroup title="Drop Shadow">
        <rule-tabs>
          <template #Normal>
            <b-form-group
              label="Shadow Color"
              class="mb-0"
            >
              <sid-box-shadow
                :sid="className(' button.search-button')"
                rule-name="box-shadow"
              />
            </b-form-group>
          </template>

          <template #Hover>
            <b-form-group
              label="Shadow Color"
              class="mb-0"
            >
              <sid-box-shadow
                :sid="className(' button.search-button')"
                presudo=":hover"
                rule-name="box-shadow"
              />
            </b-form-group>
          </template>
        </rule-tabs>
      </SidbearSettingsGroup>

      <SidbearSettingsGroup title="Transform">
        <rule-tabs>
          <template #Normal>
            <sid-transform
              :sid="className(' button.search-button')"
              rule-name="transform"
              :section="section"
            />
          </template>

          <template #Hover>
            <sid-transform
              :sid="className(' button.search-button')"
              rule-name="transform"
              presudo=":hover"
              :section="section"
            />
          </template>
        </rule-tabs>
      </SidbearSettingsGroup>

      <SidbearSettingsGroup title="Padding & Margins">
        <sid-linked-group
          label="Padding"
          :sid="className(' .search-button button.search-button')"
          rule-name="padding"
          :min="0"
          :angles="[
            {
              text: '',
              value: 'top'
            },
            {
              text: '',
              value: 'right'
            },
            {
              text: '',
              value: 'bottom'
            },
            {
              text: '',
              value: 'left'
            }
          ]"
        />

        <sid-linked-group
          label="Margin"
          :sid="className(' .search-button')"
          rule-name="margin"
          :min="-2000"
          :max="2000"
          class="mb-0"
          :angles="[
            {
              text: '',
              value: 'top'
            },
            {
              text: '',
              value: 'right'
            },
            {
              text: '',
              value: 'bottom'
            },
            {
              text: '',
              value: 'left'
            }
          ]"
        />
      </SidbearSettingsGroup>
    </SidbearSettingsGroup>

    <sidbear-settings-group title="Padding & Margins">
      <sid-linked-group
        label="Padding"
        :sid="className()"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="className()"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </sidbear-settings-group>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidFlexDirection
  from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import SidTransform from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTransform.vue'
import SidFontStyle from '@/components/editor/components/sid-controls/SidFontStyle.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup.vue'

export default {
  name: 'BlogSearchMod',
  components: {
    SidButtonsGroup,
    SidAlign, SidFont, RuleTabs, SidFontStyle, SidTransform,
    SidBoxShadow,
    SidLinkedGroup,
    SidSelect,
    SidColor, SidFlexDirection, SidRangeSlider, SidBackground, SidbearSettingsGroup},
  mixins: [ModMixin],
  methods: {
    className (name = '') {
      return `.--blog-search-${this.section.sid}${name}`
    }
  }
}
</script>