<template>
  <div>
    <SidbearSettingsGroup
      title="Layout"
      active
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Block Width:</label>

          <sid-select
            :sid="`.--${section.sid}--container`"
            rule-name="max-width"
            :searchable="false"
            var="--width"
            class="w-[138px]"
            :options="[
              {
                label: 'Full Width',
                value: '100vw',
              },
              {
                label: 'Wide',
                value: '70vw',
              },
              {
                label: 'Medium',
                value: '50vw'
              },
              {
                label: 'Small',
                value: '40vw'
              }
            ]"
          />
        </div>
      </b-form-group>

      <sid-background
        :sid="`.--${section.sid}--container`"
        :legacy-sid="`.--${section.sid}--bg`"
      />

      <sid-range-slider
        rule-name="min-height"
        :sid="`.--${section.sid}--container`"
        default-dimension="vh"
        :dimensions="['vh', 'px']"
        label="Min Height"
        var="--min-height"
        measurement="height"
      />

      <sid-range-slider
        rule-name="height"
        :sid="`.--${section.sid}--container`"
        default-dimension="%"
        :dimensions="['%', 'vh', 'px']"
        label="Height"
        var="--height"
        measurement="height"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Vertical Align</label>

          <sid-vertical-align
            :sid="`.--${section.sid}--container`"
            rule-name="align-items"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Horizontal Align</label>

          <sid-horizontal-align
            :sid="`.--${section.sid}--container .block-container`"
            rule-name="align-items"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Z-Index</label>

          <sid-input
            :sid="`.--${section.sid}--wrapper`"
            rule-name="z-index"
            default-dimension=""
            class="!w-[59px]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <b-checkbox v-model="section.snap">
          Snap Scroll
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <sid-checkbox
          value="auto"
          rule-name="overflow-y"
          :sid="`.--${section.sid}--wrapper`"
        >
          Scrollable
        </sid-checkbox>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Background Image"
    >
      <rule-tabs :tabs="['Static', 'Animation']">
        <template #Static>
          <sid-upload-image
            :sid="`.--${section.sid}--bg`"
            rule-name="background-image"
          />

          <image-upload
            v-if="false"
            :image="config.options.backgroundImage"
            class="w-100"
          />

          <b-form-group label="Background Image Size">
            <sid-select
              priority
              :searchable="false"
              :options="[
                {
                  label: 'Normal',
                  value: 'auto',
                  customProperties: [[`.--${section.sid}--bg`, ['background-attachment', 'unset']], [`.--${section.sid}--bg`, ['background-size', 'auto']]]
                },
                {
                  label: 'Full Center (Parallax)',
                  value: 'fixed',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'cover']], [`.--${section.sid}--bg`, ['background-attachment', 'fixed']]]
                },
                {
                  label: 'Fill 100 % Width',
                  value: '100% auto',
                  customProperties: [[`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Fill 100 % Width & Height',
                  value: '100% 100%',
                  customProperties: [[`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat',
                  value: 'repeat',
                  property: 'background-repeat',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'auto']], [`.--${section.sid}--bg`, ['background-repeat', 'repeat']], [`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat Horizontally',
                  value: 'repeat-x',
                  property: 'background-repeat',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'auto']], [`.--${section.sid}--bg`, ['background-repeat', 'repeat-x']], [`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat Vertically',
                  value: 'repeat-y',
                  property: 'background-repeat',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'auto']], [`.--${section.sid}--bg`, ['background-repeat', 'repeat-y']], [`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Cover',
                  value: 'cover',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'cover']], [`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Contain',
                  value: 'contain',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'contain']], [`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                }
              ]"
              :sid="`.--${section.sid}--bg`"
              var="--background-size"
              rule-name="background-size"
            />
          </b-form-group>

          <b-form-group label="Background Image Position">
            <sid-select
              :searchable="false"
              :options="[
                {label: 'Left Top', value: 'left top'},
                {label: 'Left Center', value: 'left center'},
                {label: 'Left Bottom', value: 'left bottom'},
                {label: 'Right Top', value: 'right top'},
                {label: 'Right Center', value: 'right center'},
                {label: 'Right Bottom', value: 'right bottom'},
                {label: 'Center Top', value: 'center top'},
                {label: 'Center Center', value: 'center center'},
                {label: 'Center Bottom', value: 'center bottom'},
              ]"
              :sid="`.--${section.sid}--bg`"
              rule-name="background-position"
            />
          </b-form-group>

          <sid-flip
            :sid="`.--${section.sid}--bg`"
            rule-name="scale"
          />

          <sid-range-slider
            :sid="`.--${section.sid}--bg`"
            label="Custom Size"
            rule-name="background-size"
            var="--custom-bg-size"
            unlim
            :max="200"
            :dimensions="['px', '%', 'vh', 'vw']"
          />

          <hr>

          <b-form-group label="Transform-Y">
            <sid-range-slider
              :min="-100"
              :sid="`.--${section.sid}--bg`"
              default-dimension="%"
              rule-name="background-position-y"
            />
          </b-form-group>

          <b-form-group label="Transform-X">
            <sid-range-slider
              :min="-100"
              :sid="`.--${section.sid}--bg`"
              default-dimension="%"
              rule-name="background-position-X"
            />
          </b-form-group>

          <hr>

          <sid-overlay
            :sid="`.--${section.sid}--wrapper > div > .el-overlay`"
            rule-name=""
          />

          <hr>

          <sid-background-filters
            :sid="`.--${section.sid}--bg`"
            rule-name="filter"
          />

          <b-form-group>
            <sid-range-slider
              label="Opacity"
              rule-name="opacity"
              :dimensions="[]"
              default-dimension=""
              :sid="`.--${section.sid}--bg`"
              :min="0"
              :max="1"
              :step="0.1"
            />
          </b-form-group>

          <sid-background-mask
            :sid="`.--${section.sid}--bg`"
          >
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="mb-0">Mask Fill Color</label>

                <sid-color
                  :sid="`.--${section.sid}--container > div .mask-overlay`"
                  rule-name="background-color"
                />
              </div>
            </b-form-group>
          </sid-background-mask>
        </template>
        <template #Animation>
          <backgroun-images-slider-customs :config="section.options" />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Lottie Background">
      <lottie-config
        :config="lottieBackground"
        :sid="section.sid"
      />

      <sid-translate
        :sid="`.--lottie-container-${section.sid} > div`"
        rule-name="translate"
        :min="-100"
        :max="100"
        dimension="%"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Float Image">
      <sid-checkbox
        :sid="`.--${section.sid}--wrapper > div > .float-image-wrapper`"
        rule-name="display"
        value="block"
        unchecked-value="none"
      >
        Float Image
      </sid-checkbox>

      <sid-upload-image
        :sid="`.--${section.sid}--wrapper > div > .float-image-wrapper`"
        rule-name="content"
        title=""
      />

      <sid-position-buttons
        :sid="`.--${section.sid}--wrapper > div > .float-image-wrapper`"
        rule-name="translate"
      />

      <sid-flip
        :sid="`.--${section.sid}--wrapper > div > .float-image-wrapper`"
        rule-name="scale"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Z-Index</label>

          <sid-input
            :sid="`.--${section.sid}--wrapper > div > .float-image-wrapper`"
            rule-name="z-index"
            default-dimension=""
            class="!w-[59px]"
          />
        </div>
      </b-form-group>

      <sid-transform
        :sid="`.--${section.sid}--wrapper > div > .float-image-wrapper`"
        rule-name="transform"
        translate-dimensional="%"
        :section="section"
        :controls="[
          {
            label: 'Skew',
            key: 'skew',
            min: -80,
            max: 80,
            step: 1,
            dimension: 'deg'
          },
          {
            label: 'Scale',
            key: 'scale',
            min: 0,
            max: 2,
            step: 0.1,
            dimension: ''
          },
          {
            label: 'Rotate',
            key: 'rotate',
            min: -360,
            max: 360,
            step: 1,
            dimension: 'deg'
          }
        ]"
      />

      <sid-position
        :sid="`.--${section.sid}--wrapper > div > .float-image-wrapper`"
        rule-name="translate"
      />

      <sid-range-slider
        label="Opacity"
        :sid="`.--${section.sid}--wrapper > div > .float-image-wrapper`"
        rule-name="opacity"
        :dimensions="[]"
        default-dimension=""
        :min="0"
        :max="1"
        :step="0.1"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Video Background"
    >
      <background-video-customs
        :sid="`.--${section.sid}--video .hs-video-bg-video`"
        :config="section.options.backgroundVideo"
      />

      <sid-overlay
        :sid="`.--${section.sid}--wrapper > div > .el-overlay`"
        rule-name=""
        class="mb-0"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Shape Divider"
    >
      <shape-divider-customs :config="section.options" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Drop Shadow">
      <b-form-group
        label="Shadow Color"
        class="mb-0"
      >
        <sid-box-shadow
          :sid="`.--${section.sid}--wrapper`"
          rule-name="box-shadow"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--wrapper`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--wrapper`"
          label="Border Width"
          rule-name="border"
          css-var="--border-size"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--wrapper`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="border"
        :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
        :angles="[
          {
            text: '',
            value: 'top-left-radius',
            cssVar: '--border-top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius',
            cssVar: '--border-top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius',
            cssVar: '--border-bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius',
            cssVar: '--border-bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Click Action"
    >
      <ButtonAction
        :config="config"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--container`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import ModMixin from '@/components/mixins/ModMixin'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidInput from '@/components/editor/components/sid-controls/SidInput'
import SidVerticalAlign from '@/components/editor/components/sid-controls/SidVerticalAlign'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidBackgroundFilters
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundFilters'
import SidOverlay from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidOverlay'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidTransform from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTransform'
import SidFlip from '@/components/editor/components/sid-controls/SidFlip'
import ImageUpload from '@/components/editor/utils/ImageUpload'
import SidUploadImage from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidUploadImage.vue'
import SidPosition from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidPosition'
import SidPositionButtons
  from '@/components/editor/components/sid-controls/sid-control-pressets/transform/buttons/SidPositionButtons'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import BackgroundVideoCustoms from '@/components/editor/controls/BackgroundVideoCustoms'
import ShapeDividerCustoms from '@/components/editor/controls/ShapeDividerCustoms'
import BackgrounImagesSliderCustoms from '@/components/editor/controls/BackgrounImagesSliderCustoms'
import SidHorizontalAlign from '@/components/editor/components/sid-controls/SidHorizontalAlign'
import SidBackgroundMask
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundMask'
import ButtonAction from '@/components/editor/controls/button-mod/ButtonAction'
import LottieConfig from '@/components/editor/components/LottieConfig'
import SidTranslate from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTranslate'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'

export default {
  name: 'BlockMod',
  components: {
    SidBackground,
    SidTranslate,
    LottieConfig,
    ButtonAction,
    SidBackgroundMask,
    SidHorizontalAlign,
    BackgrounImagesSliderCustoms,
    ShapeDividerCustoms,
    BackgroundVideoCustoms,
    SidBoxShadow,
    SidColor,
    SidCheckbox,
    SidPositionButtons,
    SidPosition,
    SidUploadImage,
    ImageUpload,
    SidFlip,
    SidTransform,
    SidRangeSlider,
    SidOverlay,
    SidBackgroundFilters,
    RuleTabs,
    SidVerticalAlign,
    SidInput,
    SidLinkedGroup,
    SidSelect,
    SidbearSettingsGroup
  },
  mixins: [ModMixin],
  computed: {
    lottieBackground () {
      if (!this.section.options.content.lottie) {
        this.$set(this.section.options.content, 'lottie', {})
      }

      return this.section.options.content.lottie
    }
  }
}
</script>

<style scoped>

</style>