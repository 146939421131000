<template>
  <settings-page-card title="Social Share">
    <template slot="title">
      <div class="px-4">
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h5 class="title font-weight-bold mb-0">
            Social Share
          </h5>
          <div class="flex items-center">
            <b-btn
              variant="primary"
              type="submit"
              :disabled="submiting"
              @click="saveSettings"
            >
              Save Changes
              <b-spinner
                v-if="submiting"
                class="ml-2 !w-4 !h-4"
                variant="white"
              />
            </b-btn>
          </div>
        </div>
      </div>
    </template>

    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center mt-3"
    >
      Loading
      <b-spinner
        class="ml-2"
        variant="primary"
        small
      />
    </div>
    <div
      v-else
      class="px-4"
    >
      <b-card
        class="overflow-hidden"
        no-body
      >
        <div class="d-flex gap-x-6">
          <div class="!w-[300px] min-w-[300px] bg-[#FAFAFA] border-r"> 
            <SidbearSettingsGroup
              ignore-scrolling-on-click
              class="!mx-0"
              title="Links"
            >
              <draggable
                v-model="config.links"
                handle=".move"
              >
                <div
                  v-for="(item, index) in config.links"
                  :key="index"
                  class="border group rounded-[2px] h-[29px] flex items-center pl-[13px] hover:bg-[#F1F5FD] cursor-pinter mb-[12px]"
                >
                  <div class="mr-[11px] move cursor-move">
                    <svg
                      fill="none"
                      height="11"
                      viewBox="0 0 11 11"
                      width="11"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.57745 0.894104H0.568359V3.8941H3.57745V0.894104Z"
                        fill="#3D82EA"
                      />
                      <path
                        d="M10.5984 0.894104H7.58936V3.8941H10.5984V0.894104Z"
                        fill="#3D82EA"
                      />
                      <path
                        d="M3.57745 7.8941H0.568359V10.8941H3.57745V7.8941Z"
                        fill="#3D82EA"
                      />
                      <path
                        d="M10.5984 7.8941H7.58936V10.8941H10.5984V7.8941Z"
                        fill="#3D82EA"
                      />
                    </svg>
                  </div>

                  <i
                    :class="item.icon"
                    class="text-[#3D82EA] ml-[2px]"
                  />

                  <button
                    class="w-[38px] h-[26px] text-center border-left bg-[#fff] p-0 ml-auto"
                    @click="openLinkModal(item,index)"
                  >
                    <svg
                      fill="none"
                      height="12"
                      viewBox="0 0 12 12"
                      width="12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M8.34918 0.18252C8.57698 -0.0452861 8.94633 -0.0452861 9.17414 0.18252L11.5075 2.51585C11.7353 2.74366 11.7353 3.11301 11.5075 3.34081L3.3408 11.5075C3.23141 11.6169 3.08303 11.6783 2.92832 11.6783H0.594991C0.272825 11.6783 0.0116577 11.4172 0.0116577 11.095V8.76167C0.0116577 8.60696 0.0731159 8.45858 0.182512 8.34919L8.34918 0.18252ZM7.83662 2.345L9.34499 3.85337L10.27 2.92833L8.76166 1.41996L7.83662 2.345ZM8.52003 4.67833L7.01166 3.16996L2.00328 8.17833L3.51166 9.68671L8.52003 4.67833ZM2.6867 10.5117L1.17832 9.00329V10.5117H2.6867Z"
                        fill="#6E747A"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </button>
                  <button
                    class="w-[38px] h-[26px] text-center border-left bg-[#fff] p-0"
                    @click="config.links.splice(index, 1)"
                  >
                    <svg
                      fill="none"
                      height="10"
                      viewBox="0 0 10 10"
                      width="10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
                        fill="#6E747A"
                      />
                    </svg>
                  </button>
                </div>
              </draggable>

              <button
                class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
                @click="openLinkModal()"
              >
                Add 
                <span class="text-[#3D82EA] text-[20px]">+</span>
              </button>

              <sid-flex-direction
                :sid="`.--${section.sid}--wrapper`"
                rule-name="flex-direction"
                @changed="updateTogglerStyles"
              />

              <sid-range-slider
                label="Gap"
                :sid="`.--${section.sid}--wrapper`"
                rule-name="gap"
                :dimensions="[]"
              />

              <sid-range-slider
                ref="sid-fs"
                :sid="`.--${section.sid}--link`"
                rule-name="font-size"
                label="Size"
                var="--social-size"
                class="mb-4"
              />

              <b-form-group>
                <div class="flex items-center justify-between">
                  <label class="mb-0">Horizontal Alignment</label>

                  <sid-horizontal-align
                    :sid="`.--${section.sid}--container`"
                    rule-name="justify-content"
                    @changed="updateTogglerStyles"
                  />
                </div>
              </b-form-group>
       
              <b-form-group>
                <div class="flex items-center justify-between">
                  <label class="mb-0">Vertical Alignment</label>

                  <sid-vertical-align
                    :sid="`.--${section.sid}--container`"
                    rule-name="align-items"
                    @changed="updateTogglerStyles"
                  />
                </div>
              </b-form-group>

              <sid-background
                :sid="`.--${section.sid}--wrapper`"
              />
      
              <b-modal
                v-model="linkFormModal"
                hide-footer
                hide-header
                no-close-on-backdrop
              >
                <icon
                  class="cursor-pinter ml-3"
                  icon="close.svg"
                  @click.native="linkFormModal = false"
                />

                <h4 class="text-[26px] font-weight-bold text-center mb-0 text-[#44474A]">
                  Icon Settings
                </h4>

                <div class="px-[2.5rem] py-4">
                  <b-form-group
                    label="Icon"
                  >
                    <v-select
                      v-model="linkForm.icon"
                      :options="socials"
                      :reduce="social => social.key"
                      class="w-100 position-relative custom-select custom-select-icon"
                      placeholder="Select Icon"
                    >
                      <template v-slot:selected-option="option">
                        <div class="d-flex align-items-center">
                          <i
                            :class="option.key"
                            class="text-primary mr-2"
                          />
                          <div>{{ option.label }}</div>
                        </div>
                      </template>

                      <template v-slot:option="option">
                        <div class="d-flex align-items-center">
                          <i
                            :class="option.key"
                            class="text-primary mr-3"
                          />
                          <div>{{ option.label }}</div>
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>


                  <b-row>
                    <b-col cols="5">
                      <b-form-group label="Background Color">
                        <color-picker
                          :color="linkForm.background"
                          classes="pollite-xl-squarer"
                          @input="event => linkForm.background = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group label="Color">
                        <color-picker
                          :color="linkForm.color"
                          classes="pollite-xl-squarer"
                          @input="event => linkForm.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-form-group label="Shape">
                    <v-select
                      v-model="linkForm.shape"
                      :options="shapes"
                      :reduce="sh => sh.key"
                      :searchable="false"
                      class="w-100 position-relative custom-select custom-select-icon"
                    />
                  </b-form-group>

                  <div
                    class="d-flex mt-[2rem]"
                  >
                    <b-btn
                      variant="primary"
                      class="px-4 py-2"
                      @click="submitLinkForm"
                    >
                      {{ linkForm.index != -1 ? 'Save' : 'Save' }}
                    </b-btn>
                  </div>
                </div>
              </b-modal>
            </SidbearSettingsGroup>

            <SidbearSettingsGroup
              :scroll-delta="currentHeaderHeight"
              title="Border & Radius"
              class="!mx-0"
            >
              <b-form-group>
                <div class="flex items-center justify-between">
                  <label class="pb-0">Border Type</label>

                  <sid-select
                    :sid="`.--${section.sid}--wrapper`"
                    rule-name="border-style"
                    :searchable="false"
                    class="w-[118px]"
                    :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
                  />
                </div>
              </b-form-group>

              <b-form-group>
                <sid-linked-group
                  :sid="`.--${section.sid}--wrapper`"
                  label="Border Width"
                  rule-name="border"
                  class="!mb-[7px]"
                  :angles="[
                    {
                      text: '',
                      value: 'top-width'
                    },
                    {
                      text: '',
                      value: 'right-width'
                    },
                    {
                      text: '',
                      value: 'bottom-width'
                    },
                    {
                      text: '',
                      value: 'left-width'
                    }
                  ]"
                  @changed="updateTogglerStyles"
                />

                <div class="flex w-full justify-end">
                  <sid-color
                    :sid="`.--${section.sid}--wrapper`"
                    rule-name="border-color"
                  />
                </div>
              </b-form-group>

              <sid-linked-group
                label="Radius"
                :sid="`.--${section.sid}--wrapper`"
                rule-name="border"
                :angles="[
                  {
                    text: '',
                    value: 'top-left-radius'
                  },
                  {
                    text: '',
                    value: 'top-right-radius'
                  },
                  {
                    text: '',
                    value: 'bottom-left-radius'
                  },
                  {
                    text: '',
                    value: 'bottom-right-radius'
                  }
                ]"
              />
            </SidbearSettingsGroup>

            <SidbearSettingsGroup
              :scroll-delta="currentHeaderHeight"
              title="Padding & Margins"
              class="!mx-0"
            >
              <sid-linked-group
                label="Padding"
                :sid="`.--${section.sid}--wrapper`"
                rule-name="padding"
                :min="0"
                :angles="[
                  {
                    text: '',
                    value: 'top'
                  },
                  {
                    text: '',
                    value: 'right'
                  },
                  {
                    text: '',
                    value: 'bottom'
                  },
                  {
                    text: '',
                    value: 'left'
                  }
                ]"
              />
              <sid-linked-group
                label="Margin"
                :sid="`.--${section.sid}--wrapper`"
                rule-name="margin"
                :min="-2000"
                :max="2000"
                :angles="[
                  {
                    text: '',
                    value: 'top'
                  },
                  {
                    text: '',
                    value: 'right'
                  },
                  {
                    text: '',
                    value: 'bottom'
                  },
                  {
                    text: '',
                    value: 'left'
                  }
                ]"
              />
            </SidbearSettingsGroup>

            <SidbearSettingsGroup
              :scroll-delta="currentHeaderHeight"
              title="Display Settings"
              class="!mx-0"
            >
              <b-form-group
                class="sid-range mb-3"
              >
                <div class="flex items-start gap-[17px] justify-between">
                  <div class="flex-1">
                    <label 
                      class="select-label pb-0 !mb-[15px]"
                    >
                      Sidebar reveal
                      <i
                        v-b-tooltip
                        class="fa fa-info-circle cursor-pointer hover:opacity-80 ml-1"
                        title="Set when the sidebar will appear based on how much of the page you've scrolled. Leave it empty to show the sidebar right away."
                      />
                    </label>
                    <vue-slider
                      v-model="config.reveal_val"
                      :step="1"
                      :min="0"
                      :max="config.reveal_unit == '%' ? 100: 1000"
                      class="editor-slider-1 w-100"
                      tooltip="none"
                    />
                  </div>
               
                  <div>
                    <div
                      class="flex gap-[7px]  text-[#A6A6A6] text-uppercase text-[11px] font-[600] mb-[11px]"
                    >
                      <span
                        class="cursor-pointer hover:text-[#44474A]"
                        :class="{'text-[#44474A]': config.reveal_unit == 'px'}"
                        @click="config.reveal_unit = 'px'"
                      >
                        PX
                      </span>
                      <span
                        class="cursor-pointer hover:text-[#44474A]"
                        :class="{'text-[#44474A]': config.reveal_unit == '%'}"
                        @click="config.reveal_unit = '%'"
                      >
                        %
                      </span>
                    </div>
                    <LinkedControlItem
                      v-model="config.reveal_val"
                      :step="1"
                      :min="1"
                      :max="config.reveal_unit == '%' ? 100: 1000"
                      class="d-block !w-[57px] !text-[#7E7E7E] !text-[13px] !h-[29px]"
                      placeholder="-"
                    />
                    <div class="flex justify-end pt-2">
                      <span 
                        class="text-[13px] text-[#44474A] hover:opacity-80 cursor-pointer underline" 
                        @click="config.reveal_val = null"
                      >
                        Reset
                      </span>
                    </div>
                  </div>
                </div>
              </b-form-group>

              <b-form-group>
                <div class="flex justify-between items-center select-wrapper">
                  <label 
                    class="select-label pb-0"
                  >
                    Entry effect
                  </label>
                  <v-select
                    v-model="config.animation"
                    :options="[
                      {id:null,name:'No Animation'},
                      {id:'slide',name:'Slide'},
                      {id:'fade',name:'Fade'},
                    ]"
                    :reduce="e => e.id"
                    class="!w-[138px]"
                    label="name"
                  />
                </div>
              </b-form-group>

              <b-checkbox
                v-model="config.toggler"
                :unchecked-value="0"
                :value="1"
              >
                Add toggle button to sidebar
              </b-checkbox>
              <div class="text-muted text-[13px] mt-2 mb-3">
                Enable this option to allow users to toggle the visibility of the sidebar
              </div>

              <template v-if="config.toggler">
                <sid-range-slider
                  ref="sid-fs"
                  :sid="`.--${section.sid}--wrapper .ssi-toggler`"
                  rule-name="font-size"
                  label="Toggle size"
                  class="mb-4"
                />
                <div class="flex w-full items-center justify-between mb-4">
                  <label 
                    class="select-label pb-0"
                  >
                    Toggle color
                  </label>
                  <sid-color
                    :sid="`.--${section.sid}--wrapper .ssi-toggler`"
                    rule-name="color"
                  />
                </div>
              </template>


              <b-checkbox
                v-model="config.all_pages"
                :unchecked-value="0"
                :value="1"
                class="mb-3"
              >
                Display on All Pages
              </b-checkbox>
              <b-form-group>
                <slot name="label">
                  <label class="">
                    Exclude pages
                  </label>
                </slot>
                <v-select
                  v-model="config.exclude_pages"
                  multiple
                  :options="pages.data || []"
                  :reduce="e => e.id"
                  label="title"
                  class="vselect-multiple"
                />
              </b-form-group>
              <b-checkbox
                v-model="config.all_posts"
                :unchecked-value="0"
                :value="1"
              
                class="mb-3"
              >
                Display on All Posts
              </b-checkbox>
              <b-form-group>
                <slot name="label">
                  <label class="">
                    Exclude posts
                  </label>
                </slot>
                <v-select
                  v-model="config.exclude_posts"
                  multiple
                  :options="posts.data || []"
                  :reduce="e => e.id"
                  label="title"
                  class="vselect-multiple"
                />
              </b-form-group>
            </SidbearSettingsGroup>

            <SidbearSettingsGroup
              :scroll-delta="currentHeaderHeight"
              title="Device Visibility"
              class="!mx-0 mb-0"
              content-class="p-0"
            >
              <Panel :config="config" />
            </SidbearSettingsGroup>
          </div>


          <div
            class="d-flex items-center justify-center flex-1 p-3"
          >
            <div class="w-full h-full min-h-[700px] border  shadow-sm">
              <div
                :class="[`--${section.sid}--container`]"
                class="d-flex h-full"
                :data-sid="section.sid"
              >
                <ul
                  class="list-unstyled flex flex-col list-inline mb-0 h-fit relative"
                  :class="`--${section.sid}--wrapper`"
                >
                  <li
                    v-for="(link, index) in config.links"
                    :key="index"
                    class="list-inline-item mx-0"
                  >
                    <b-link
                      :class="['social-link social-link-' + link.shape, `--${section.sid}--link`]"
                      :style="{color: link.color, backgroundColor: link.background}"
                    >
                      <i :class="link.icon" />
                    </b-link>
                  </li>
                  <span
                    v-if="config.toggler"
                    class="ssi-toggler"
                    :style="togglerstyles"
                  >
                    <i :class="togglerIcon" />
                  </span>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </settings-page-card>
</template>

<script>
import _ from 'lodash'
import {mapActions, mapState} from 'vuex'
import {pageStylesToStringAsync,insertSectionStyle,retriveStyles,deleteStylesBySid} from '@/utils/useStylesheet'
import SettingsPageCard from '@/layouts/dashboard/SettingsPageCard'
import draggable from 'vuedraggable'
import ColorPicker from '@/components/editor/utils/ColorPicker.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidBackground from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidHorizontalAlign from '@/components/editor/components/sid-controls/SidHorizontalAlign'
import SidVerticalAlign from '@/components/editor/components/sid-controls/SidVerticalAlign'
import SidFlexDirection from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection.vue'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
import Panel from '@/components/editor/controls/Panel.vue'

export default {
  name: 'SocialShare',
  components: { 
    SettingsPageCard,
    SidFlexDirection,
    Panel,
    SidHorizontalAlign,
    SidVerticalAlign,
    SidBackground,
    SidRangeSlider, 
    SidColor,
    SidSelect,
    SidLinkedGroup,
    SidbearSettingsGroup,
    ColorPicker,
    draggable,
    LinkedControlItem
  },
  data () {
    return {
      currentHeaderHeight:-95,
      submiting:false,
      loading:false,
      config: {},
  
      section:{
        /* Don't Change sid !!! */
        sid:'y2JEfMHr7xSocialShare'
      },

      linkForm: {},
      linkFormModal: false,
      shapes: [
        {
          key: null,
          label: 'Square'
        },
        {
          key: 'circle',
          label: 'Circle'
        }
      ],
      socials:[
        {
          key: 'fa fa-facebook',
          label: 'Facebook'
        },
        {
          key: 'fa-brands fa-x-twitter',
          label: 'X Twitter'
        },
        {
          key: 'fab fa-linkedin-in',
          label: 'LinkedIn'
        },
        {
          key: 'fab fa-pinterest',
          label: 'Pinterest'
        },
        {
          key: 'fa fa-rss',
          label: 'RSS'
        },
        {
          key: 'fab fa-whatsapp',
          label: 'Whatsapp'
        },
        {
          key: 'fa fa-link',
          label: 'Copy Link'
        }
      ],

      togglerstyles:{},
      togglerIcon: ''
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project,
      pages: state => state.pages,
      posts: state => state.posts
    })
  },
  mounted () {
    this.loading = true
    const theConfig = _.cloneDeep(JSON.parse(_.get(this.project, 'social_share', '{}'))||{})

    deleteStylesBySid(this.section.sid)  

    if(theConfig.css){
      const parsedStyles = retriveStyles(theConfig.css, this.section.sid)
      insertSectionStyle(parsedStyles)
    }
    this.initConfig(theConfig)

    this.loading = false
    if(this.pages.project_id != this.project.id)  this.getPages({project_id:this.project.id})
    if(this.posts.project_id != this.project.id)  this.getPosts({project_id:this.project.id})
    this.updateTogglerStyles()
  },
  methods: {
    ...mapActions({
      getPages:'projects/getPages',
      getPosts:'projects/getPosts'
    }),
    updateTogglerStyles (){
      setTimeout(() => {
        const parent = document.querySelector(`.--${this.section.sid}--container`)
        const child = document.querySelector(`.--${this.section.sid}--wrapper`)
      
        if (parent && child) {
          const childStyles = window.getComputedStyle(child)
          const parentStyles = window.getComputedStyle(parent)
          const direction = childStyles.getPropertyValue('flex-direction')
          const justifyContent = parentStyles.getPropertyValue('justify-content')
          const alignItems = parentStyles.getPropertyValue('align-items')

          const borderTopWidth = parseFloat(childStyles.getPropertyValue('border-top-width')) || 0
          const borderRightWidth = parseFloat(childStyles.getPropertyValue('border-right-width')) || 0
          const borderBottomWidth = parseFloat(childStyles.getPropertyValue('border-bottom-width')) || 0
          const borderLeftWidth = parseFloat(childStyles.getPropertyValue('border-left-width')) || 0
          console.log('Border Widths:', {
            borderTopWidth,
            borderRightWidth,
            borderBottomWidth,
            borderLeftWidth
          })

          this.togglerIcon = `fa fa-chevron-${justifyContent === 'normal' ? 'left' : 'right'}`
          const isRow = direction.includes('row')

          let styles = {}
        
          if(isRow){
            if(justifyContent == 'normal'){
              const delta = `${parseFloat(8 + borderRightWidth)}px`
              styles = {
                top: '50%',
                right: 0,
                transform: `translateX(calc(100% + ${delta})) translateY(calc(-50%))`
              }
            }else{
              const delta = `${parseFloat(8 + borderLeftWidth)}px`
              styles = {
                top: '50%',
                left: 0,
                transform: `translateX(calc(-100% - ${delta})) translateY(calc(-50%))`
              }
            }
          }else{
            const delta = `${parseFloat(8 + borderTopWidth)}px`
            if(alignItems == 'end'){
              styles = {
                top: '0',
                left: '50%',
                transform: `translateY(calc(-100% - ${delta})) translateX(calc(-50%))`
              }
            }else{
              const delta = `${parseFloat(8 + borderBottomWidth)}px`
              styles = {
                bottom: 0,
                left: '50%',
                transform: `translateY(calc(100% + ${delta})) translateX(calc(-50%))`
              }
            }
          }
        
          this.togglerstyles = styles
        }
      })
    },
    initConfig (data = {}){
      this.config =  {
        display: data.display != undefined ? data.display : true,
        sid:this.section.sid,
        animation:data.animation|| null,
        reveal_val: data.reveal_val || 0,
        reveal_unit: data.reveal_unit || 'px',
        toggler: data.toggler || 0,
        all_pages: data.all_pages || 0,
        exclude_pages: data.exclude_pages ||[],
        all_posts: data.all_posts || 0,
        exclude_posts: data.exclude_posts ||[],
        css: data.css || null,
        links: data.links || []
      }
    },

    initLinkForm (data,index = -1){
      this.linkForm = {
        index,
        icon: data.icon || '',
        shape: data.shape|| null,
        color: data.color|| '#ffffff',
        background: data.background|| '#1f3b9c'
      }
    },
    openLinkModal (data = {},index = -1) {
      this.initLinkForm(data,index)
      this.linkFormModal = true
    },
    submitLinkForm (){
      const { index, ...data } = this.linkForm 
      if (index !== -1) {
        this.config.links.splice(index, 1, data) 
      } else {
        this.config.links.push(data) 
      }
      const fs = this.$refs['sid-fs']      
      if(fs) setTimeout(() => fs.refreshStyleValue(), 200)
      this.linkFormModal = false
      this.$swal({
        icon: 'success',
        iconColor: '#4F83E3',
        toast: true,
        position: 'top-right',
        title: 'Success',
        showConfirmButton: false,
        timer: 1500
      })
    },
    saveSettings (){
      pageStylesToStringAsync('subject-styles')
        .then((css) => {
          this.config.css = css
          const social_share = JSON.stringify(this.config)
          this.submiting = true
          axios.put(`api/projects/${this.project.id}`, {
            name:this.project.name,
            social_share
          })
            .then(() => {
              this.project.social_share = social_share
              this.submiting = false
              this.$swal({
                icon: 'success',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: 'Success',
                showConfirmButton: false,
                timer: 1500
              })
            }).catch((err) => {
              console.error('err :>> ', err)
              this.submiting = false
            })
        }).catch((err) => {
          console.error('err :>> ', err)
        })
    }
  }
}
</script>

<style  lang="scss">
.vselect-multiple{
  min-height: unset;
  height: auto!important;
  padding: 4px 0!important;
  .vs__selected-options{
    row-gap: 2px;
  }
  .vs__dropdown-toggle{
    position: relative;
    padding-bottom: 0px!important;
  }
  .vs__search{
    margin-top: 0!important;
  }
  &.vs--open .vs__dropdown-toggle{
    padding-bottom: 0px!important;
  }
  &.vs--open .vs__selected{
    margin-top: 0!important;
  }
  .vs__selected{
    background-color: #007bff!important;
    color: #fff!important;
    margin-top: 0px!important;
    .vs__deselect{
      svg{
        transform: scale(.7);
      }
      path{
        fill: #fff;
      }
    }
  }
}
.ssi-toggler{
  position: absolute;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-select-icon{
    background: #fff url("data:image/svg+xml,%3Csvg width='20' height='10' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.600006 1.43243L4.92501 5.54054L9.25001 1.43243' stroke='%236E747A' stroke-width='1.5'/%3E%3C/svg%3E%0A") no-repeat right 0.8rem center/8px 20px!important;
}
</style>