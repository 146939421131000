<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings(true)"
    @add-subject="$emit('add-subject')"
  >
    <TestimonialItem3Template :section="section">
      <template slot="image">
        <highliter
          v-show="visability('image')"
          auto-width
          @delete="$emit('delete')"
          @dublicate="$emit('dublicate')"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @click.stop.native="sectionSettings('image')"
        >
          <image-fit
            :class="[{'mx-auto': section.options.content.image.align === 'center'}, {'ml-auto': section.options.content.image.align === 'right'}]"
            :customs="section.options.content.image"
            :position="section.options.content.image.position"
            :size="cols(section.options.content.image.size, 'contain')"
            :src="section.options.content.image.src || section.options.content.image.placeholder"
            height="86px"
            width="86px"
          />
        </highliter>
      </template>

      <template slot="name">
        <highliter
          v-show="visability('name')"
          @delete="$emit('delete')"
          @dublicate="$emit('dublicate')"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @click.stop.native="sectionSettings('name')"
        >
          <content-editable
            v-model="section.options.content.name.text"
            :customs="section.options.content.name"
            @init="(editor) => section.classes.tiptap.name = editor"
          />
        </highliter>
      </template>

      <template slot="subname">
        <highliter
          v-show="visability('subname')"
          @delete="$emit('delete')"
          @dublicate="$emit('dublicate')"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @click.stop.native="sectionSettings('subname')"
        >
          <content-editable
            v-model="section.options.content.subname.text"
            :customs="section.options.content.subname"
            @init="(editor) => section.classes.tiptap.subname = editor"
          />
        </highliter>
      </template>

      <template slot="text">
        <highliter
          v-show="visability('text')"
          @delete="$emit('delete')"
          @dublicate="$emit('dublicate')"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @click.stop.native="sectionSettings('text')"
        >
          <content-editable
            v-model="section.options.content.text.text"
            :customs="section.options.content.text"
            @init="(editor) => section.classes.tiptap.text = editor"
          />
        </highliter>
      </template>
    </TestimonialItem3Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditable from '@builder/components/builder/utils/ContentEditable'
import TestimonialItem3Template from '@builder/templates/sections/testimonials/TestimonialItem3Template'
import ImageFit from '@builder/components/utils/ImageFit'

export default {
  name: 'FeaturedItems1',

  components: {ImageFit, TestimonialItem3Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
