<template>
  <div class="bg-[#F4F7FC] overflow-hidden">
    <div class="relative flex items-center justify-center w-full min-h-screen p-[20px] md:p-[40px]">
      <div class="flex flex-col items-center max-w-[600px] w-full mx-auto">
        <img
          v-if="!success"
          class="h-[17px] w-[134.06px] cursor-pointer"
          src="@/assets/icons/main-logo.svg"
          alt="Logo"
        >

        <div class="flex flex-col">
          <div class="relative flex flex-col items-center justify-center w-[600px] mt-[36px] px-[40px] py-[32px] bg-gradient-to-t from-white to-[#FEFEFE] rounded-[10px] drop-shadow-[0,4px,34px,0,rgba(0,0,0,0.06)]">
            <template v-if="!success">
              <h4 class="text-center text-[30px] text-[#363636] leading-[36px] font-[700] font-[ProximaNova]">
                Start Your 14-Day Free Trial Now!
              </h4>

              <b-form
                class="welcome-step-form w-full mt-[24px]"
                @submit.prevent="submit"
              >
                <div class="flex flex-col gap-[10px]">
                  <div class="flex justify-center">
                    <plan-select
                      v-model="activePeriod"
                      :plans="plans[activePlan].duration"
                      @input="$emit('change-plan', plans[activePlan].duration[activePeriod].value)"
                    />
                  </div>

                  <b-form-group class="w-full !mb-[0]">
                    <div class="flex items-center justify-between gap-[10px]">
                      <span class="text-[15px] text-[#202020] leading-[18px] font-[400] font-[ProximaNova]">Plan</span>
                      <span class="text-[15px] text-[#202020] leading-[18px] font-[400] font-[ProximaNova]">Price</span>
                    </div>

                    <hr class="border-t-[1px] border-[#D0D5DD] mt-[8px] mb-[16px]">

                    <b-form-radio-group
                      v-model="activePlan"
                      class="flex flex-col gap-[12px] mb-[24px]"
                      @change="$emit('change-plan', plans[activePlan].duration[activePeriod].value)"
                    >
                      <template #default>
                        <div
                          v-for="(item, index) in plans"
                          :key="index"
                          class="inline-flex items-center justify-between gap-[10px]"
                        >
                          <b-form-radio
                            :value="index"
                            class="text-[15px] leading-[18px] font-[500] font-[ProximaNova]"
                            :class="[activePlan === index ? 'text-[#000000]' : 'text-[#464646]']"
                          >
                            <div class="inline-flex items-center gap-[4px]">
                              {{ item.name }}

                              <div
                                v-if="index === 1"
                                id="business-plus-information"
                              >
                                <svg
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_4684_8605)">
                                    <path
                                      d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                                      fill="#29292A"
                                    />
                                    <path
                                      d="M7.5 5V7.5M7.5 10H7.50625M13.75 7.5C13.75 10.9518 10.9518 13.75 7.5 13.75C4.04822 13.75 1.25 10.9518 1.25 7.5C1.25 4.04822 4.04822 1.25 7.5 1.25C10.9518 1.25 13.75 4.04822 13.75 7.5Z"
                                      stroke="#29292A"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M6.66927 9.16536L6.66927 3.33203L8.33594 3.33203L8.33594 9.16536L6.66927 9.16536Z"
                                    fill="white"
                                  />
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M6.66927 11.6657L6.66927 9.99902L8.33594 9.99902L8.33594 11.6657L6.66927 11.6657Z"
                                    fill="white"
                                  />
                                  <defs>
                                    <clipPath id="clip0_4684_8605">
                                      <rect
                                        width="15"
                                        height="15"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>

                                <b-popover
                                  target="business-plus-information"
                                  triggers="hover"
                                  placement="bottom"
                                  custom-class="business-plus-information"
                                >
                                  <div class="w-full flex flex-col items-center justify-center">
                                    <h4 class="text-center text-[24px] text-[#000000] leading-[32px] font-[700] font-[ProximaNova]">
                                      Here’s Everything You Are Getting In <br>
                                      Your Estage Business Plus Account:
                                    </h4>
                                    <ul class="w-full mt-[20px] columns-2 gap-x-[10px] list-none md:!max-h-[362px]">
                                      <li
                                        v-for="(feature, index) in businessPlusFeatures"
                                        :key="index"
                                        class="relative w-full inline-block gap-x-[8px] text-[14px] leading-[32px] text-[#333333] last:font-[700] last:text-[#333333]"
                                      >
                                        <svg
                                          width="12"
                                          height="8"
                                          viewBox="0 0 12 8"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9.72727 1L3.72727 7L1 4.27273"
                                            stroke="#3D82EA"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                        <span class="w-full">
                                          {{ feature }}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </b-popover>
                              </div>
                            </div>
                          </b-form-radio>
                          <span
                            class="text-[15px] leading-[18px] font-[500] font-[ProximaNova]"
                            :class="[activePlan === index ? 'text-[#000000]' : 'text-[#464646]']"
                          >
                            14-Days Free then ${{ item.duration[activePeriod].price }}/{{ item.duration[activePeriod].interval }}
                          </span>
                        </div>
                      </template>
                    </b-form-radio-group>
                  </b-form-group>

                  <template v-if="!user">
                    <div class="grid grid-cols-2 gap-[10px]">
                      <b-form-group class="w-full !mb-[0]">
                        <b-form-input
                          id="first-name"
                          v-model="form.first_name"
                          v-validate="'required|min:2|max:24'"
                          name="first-name"
                          :class="{'is-invalid': errors.has('first-name') ? true : null}"
                          placeholder="First Name"
                          autocomplete="first-name"
                          x-autocompletetype="first-name"
                        />
                      </b-form-group>

                      <b-form-group class="w-full !mb-[0]">
                        <b-form-input
                          id="last-name"
                          v-model="form.last_name"
                          v-validate="'required|min:2|max:24'"
                          name="last-name"
                          :class="{'is-invalid': errors.has('last-name') ? true : null}"
                          placeholder="Last Name"
                          autocomplete="last-name"
                          x-autocompletetype="last-name"
                        />
                      </b-form-group>
                    </div>

                    <b-form-group class="w-full !mb-[0]">
                      <b-form-input
                        id="email"
                        v-model="form.email"
                        v-validate="'required|email'"
                        name="email"
                        :class="{'is-invalid': errors.has('email') ? true : null}"
                        placeholder="Email Address"
                        autocomplete="email"
                        x-autocompletetype="email"
                      />
                    </b-form-group>

                    <div class="grid grid-cols-2 gap-[10px]">
                      <b-form-group class="w-full !mb-[0]">
                        <b-form-input
                          id="password"
                          ref="password"
                          v-model="form.password"
                          v-validate="'required|min:8|max:40'"
                          name="password"
                          type="password"
                          :class="{'is-invalid': errors.has('password') ? true : null}"
                          placeholder="Create Password"
                          autocomplete="password"
                          x-autocompletetype="password"
                        />
                      </b-form-group>

                      <b-form-group class="w-full !mb-[0]">
                        <b-form-input
                          id="password_confirmation"
                          v-model="form.password_confirmation"
                          v-validate="'required|confirmed:password'"
                          name="password_confirmation"
                          type="password"
                          :class="{'is-invalid': errors.has('password_confirmation') ? true : null}"
                          placeholder="Confirm Password"
                          autocomplete="password_confirmation"
                          x-autocompletetype="password_confirmation"
                        />
                      </b-form-group>
                    </div>
                  </template>

                  <div class="flex flex-col gap-[10px] bg-[#F2F5F9] border border-[#DBE1E9] rounded-[4px] px-[20px] py-[16px]">
                    <b-form-group
                      label="Payment Method"
                      label-class="text-[14px] text-[#000000] leading-[18px] font-[400] font-[ProximaNova]"
                      class="w-full !mb-[0]"
                    >
                      <v-select
                        v-model="paymentMethod"
                        :options="paymentMethods"
                        class="w-full !h-[46px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
                        :searchable="false"
                        label="name"
                        required
                      />
                    </b-form-group>

                    <template v-if="paymentMethod && paymentMethod.id === -1">
                      <b-form-group
                        label="Card Number"
                        label-class="text-[14px] text-[#000000] leading-[18px] font-[400] font-[ProximaNova]"
                        class="w-full !mb-[0]"
                      >
                        <b-form-input
                          id="cc-number"
                          v-model="form.cc_number"
                          v-mask="'#### #### #### ####'"
                          v-validate="'required|min:16|max:19'"
                          name="cc-number"
                          :class="{'is-invalid': errors.has('cc-number') ? true : null}"
                          placeholder="Card Number"
                          autocomplete="cc-number"
                          x-autocompletetype="cc-number"
                        />
                      </b-form-group>

                      <div class="flex flex-row items-end">
                        <b-form-group
                          class="!w-[72px] !mb-[0]"
                          label="Expiration"
                          label-class="text-[14px] text-nowrap text-[#000000] leading-[18px] font-[400] font-[ProximaNova]"
                        >
                          <b-form-input
                            v-model="form.cc_exp_month"
                            v-mask="'##'"
                            v-validate="'required'"
                            placeholder="MM"
                            name="cc_exp_month"
                            class="form-control !h-[40px]"
                            :class="{'is-invalid': errors.has('cc_exp_month') ? true : null}"
                            autocomplete="cc-exp cc-exp-month"
                            x-autocompletetype="cc-exp cc-exp-month"
                          />
                        </b-form-group>

                        <b-form-group class="!w-[96px] ml-[10px] !mb-[0]">
                          <b-form-input
                            v-model="form.cc_exp_year"
                            v-mask="'####'"
                            v-validate="'required'"
                            placeholder="YYYY"
                            name="cc_exp_year"
                            class="form-control !h-[40px]"
                            :class="{'is-invalid': errors.has('cc_exp_year') ? true : null}"
                            autocomplete="cc-exp cc-exp-year"
                            x-autocompletetype="cc-exp cc-exp-year"
                          />
                        </b-form-group>

                        <b-form-group
                          class="!w-[87px] ml-[24px] !mb-[0]"
                          label="CVC"
                          label-class="text-[14px] text-[#000000] leading-[18px] font-[400] font-[ProximaNova]"
                        >
                          <b-form-input
                            id="cc-cvc"
                            v-model="form.cc_cvc"
                            v-mask="'####'"
                            v-validate="'required|min:3|max:4'"
                            name="cc-cvc"
                            :class="{'is-invalid': errors.has('cc-cvc') ? true : null}"
                            placeholder="CVC"
                            autocomplete="cc-cvc"
                            x-autocompletetype="cc-cvc"
                          />
                        </b-form-group>

                        <div class="flex items-center justify-center !h-[40px] ml-[10px]">
                          <svg
                            width="31"
                            height="27"
                            viewBox="0 0 31 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M29.493 0.517578H1.50294C0.674223 0.517578 0 1.19003 0 2.01658V19.9983C0 20.8248 0.674223 21.4972 1.50294 21.4972H4.59037C4.84113 21.4972 5.04442 21.2945 5.04442 21.0444C5.04442 20.7942 4.84113 20.5915 4.59037 20.5915H1.50294C1.17494 20.5915 0.908086 20.3254 0.908086 19.9983V8.49485H30.0879V19.9983C30.0879 20.3254 29.8211 20.5915 29.4931 20.5915H29.1624C29.4065 19.8179 29.5384 18.9951 29.5384 18.1421C29.5384 16.9805 29.2987 15.8576 28.8261 14.8047C28.7237 14.5765 28.4549 14.4743 28.2261 14.5764C27.9972 14.6787 27.8948 14.9466 27.9972 15.1748C28.4173 16.1106 28.6303 17.1089 28.6303 18.1421C28.6303 19.7815 28.0832 21.2956 27.1619 22.513H15.5211C14.5998 21.2956 14.0527 19.7815 14.0527 18.1421C14.0527 14.1336 17.3225 10.8725 21.3415 10.8725C23.6134 10.8725 25.7153 11.9018 27.1083 13.6964C27.2619 13.8941 27.547 13.9302 27.7452 13.7773C27.9435 13.6242 27.9798 13.3398 27.8263 13.142C26.2601 11.1241 23.8965 9.96681 21.3415 9.96681C16.8217 9.96681 13.1446 13.6343 13.1446 18.1422C13.1446 18.9952 13.2765 19.818 13.5206 20.5916H6.2147C5.96394 20.5916 5.76065 20.7943 5.76065 21.0445C5.76065 21.2947 5.96394 21.4974 6.2147 21.4974H13.8671C15.1565 24.3436 18.0286 26.3176 21.3415 26.3176C24.6529 26.3176 27.5259 24.3448 28.8159 21.4974H29.493C30.3217 21.4974 30.9959 20.8249 30.9959 19.9984V2.01658C30.996 1.19003 30.3218 0.517578 29.493 0.517578ZM16.333 23.4187H26.35C25.0431 24.6533 23.2795 25.4118 21.3415 25.4118C19.4035 25.4118 17.64 24.6533 16.333 23.4187ZM0.908086 7.58915V5.73239H23.6789C23.9298 5.73239 24.133 5.52969 24.133 5.27954C24.133 5.02938 23.9298 4.82669 23.6789 4.82669H0.908086V2.01658C0.908086 1.68944 1.17494 1.42328 1.50294 1.42328H29.4931C29.8211 1.42328 30.0879 1.68944 30.0879 2.01658V4.82669H25.3639C25.113 4.82669 24.9098 5.02938 24.9098 5.27954C24.9098 5.52969 25.113 5.73239 25.3639 5.73239H30.0879V7.58915H0.908086Z"
                              fill="#747E8C"
                            />
                            <path
                              d="M10.999 17.9939C11.9752 17.5275 12.5127 16.868 12.5127 16.137C12.5127 15.406 11.9752 14.7465 10.999 14.2801C10.1292 13.8646 8.98277 13.6357 7.77102 13.6357C6.55927 13.6357 5.41284 13.8646 4.54301 14.2801C3.56682 14.7465 3.0293 15.406 3.0293 16.137C3.0293 16.868 3.56688 17.5275 4.54301 17.9939C5.41284 18.4094 6.55921 18.6383 7.77102 18.6383C8.98283 18.6383 10.1292 18.4094 10.999 17.9939ZM3.93738 16.137C3.93738 15.779 4.3011 15.3999 4.93531 15.0969C5.68514 14.7387 6.69221 14.5414 7.77102 14.5414C8.84982 14.5414 9.85689 14.7388 10.6067 15.0969C11.2409 15.4 11.6047 15.779 11.6047 16.137C11.6047 16.495 11.2409 16.874 10.6067 17.1771C9.85689 17.5353 8.84982 17.7326 7.77102 17.7326C6.69221 17.7326 5.68514 17.5352 4.93531 17.1771C4.3011 16.8741 3.93738 16.495 3.93738 16.137Z"
                              fill="#747E8C"
                            />
                            <path
                              d="M18.2698 16.6606C18.4461 16.6606 18.616 16.7123 18.7611 16.8101C18.9688 16.9501 19.2512 16.8956 19.3915 16.6884C19.5319 16.4812 19.4773 16.1996 19.2695 16.0597C18.9737 15.8602 18.628 15.7549 18.2698 15.7549C17.2842 15.7549 16.4824 16.5546 16.4824 17.5376C16.4824 18.5205 17.2842 19.3202 18.2698 19.3202C18.667 19.3202 19.0246 19.1893 19.3034 18.9416C19.358 18.8932 19.4099 18.8398 19.4577 18.7829C19.6188 18.5913 19.5937 18.3057 19.4016 18.145C19.2095 17.9842 18.9231 18.0093 18.762 18.201C18.7424 18.2242 18.7215 18.2458 18.6997 18.2651C18.588 18.3643 18.4434 18.4145 18.2698 18.4145C17.7849 18.4145 17.3905 18.0211 17.3905 17.5376C17.3905 17.054 17.785 16.6606 18.2698 16.6606Z"
                              fill="#747E8C"
                            />
                            <path
                              d="M21.9623 16.0701L21.4573 17.5862L20.926 16.0595C20.8439 15.8232 20.585 15.6978 20.3482 15.7802C20.1114 15.8621 19.986 16.1202 20.0682 16.3565L20.9809 18.9793C21.0538 19.1838 21.2482 19.3205 21.4655 19.3205C21.4666 19.3205 21.4678 19.3205 21.469 19.3204C21.6877 19.319 21.8819 19.1792 21.9534 18.9696L22.8241 16.3556C22.9032 16.1182 22.7743 15.8619 22.5364 15.7831C22.2986 15.7042 22.0413 15.8327 21.9623 16.0701Z"
                              fill="#747E8C"
                            />
                            <path
                              d="M25.3159 16.0701L24.8109 17.5862L24.2795 16.0595C24.1974 15.8232 23.9385 15.6978 23.7018 15.7802C23.4649 15.8622 23.3395 16.1202 23.4217 16.3565L24.3345 18.9793C24.4073 19.1838 24.6017 19.3205 24.819 19.3205C24.8202 19.3205 24.8214 19.3205 24.8225 19.3204C25.0412 19.319 25.2355 19.1792 25.307 18.9696L26.1777 16.3556C26.2568 16.1182 26.128 15.8619 25.8899 15.7831C25.6522 15.7042 25.395 15.8327 25.3159 16.0701Z"
                              fill="#747E8C"
                            />
                          </svg>
                        </div>
                      </div>

                      <div class="flex items-end">
                        <b-form-group
                          class="w-full !mb-[0]"
                          label="Country"
                          label-class="text-[14px] text-[#000000] leading-[18px] font-[400] font-[ProximaNova]"
                        >
                          <country-select
                            v-model="form.country"
                            v-validate="`required`"
                            name="country"
                            :class="{'is-invalid': errors.has('country') ? true : null}"
                            class="form-control !h-[40px]"
                            autocomplete="billing country"
                            x-autocompletetype="billing country"
                          />
                        </b-form-group>
                        <b-form-group
                          class="w-[128px] ml-[24px] !mb-[0]"
                          label="Zip Code"
                          label-class="text-[14px] text-[#000000] leading-[18px] font-[400] font-[ProximaNova]"
                        >
                          <b-input
                            v-model="form.zip_code"
                            v-validate="`required`"
                            name="zip_code"
                            :class="{'is-invalid': errors.has('zip_code') ? true : null}"
                            autocomplete="billing postal-code"
                            x-autocompletetype="billing postal-code"
                          />
                        </b-form-group>
                      </div>
                    </template>

                    <p class="text-[14px] text-[#717B8B] leading-[18px] font-[400] font-[ProximaNova] mt-[4px] mb-[0]">
                      Why? We ask for a payment method so that your subscription and business can continue without interruption after your trial ends.
                    </p>
                  </div>
                </div>

                <b-btn
                  class="!inline-flex items-center justify-center text-center gap-[8px] w-full h-[80px] !text-[28px] !leading-[32px] !font-[700] text-white mt-[20px] rounded-[4px] hover:opacity-80 !bg-[#4989EC]"
                  variant="primary"
                  type="submit"
                  :disabled="loading || $validator.errors.any()"
                >
                  Activate My Account Now!
                  <svg
                    class="w-6 h-6"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 12H19M19 12L12 5M19 12L12 19"
                      stroke="white"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <b-spinner
                    v-if="loading"
                    variant="white"
                    small
                  />
                </b-btn>

                <div class="flex flex-col mt-[14px] gap-[10px] px-[20px]">
                  <span class="text-center text-[14px] text-[#464646] leading-[18px] font-[400] font-[ProximaNova] mb-[0]">
                    No charges will be processed today
                  </span>
                  <p class="w-[436px] mx-auto text-center text-[14px] text-[#8A8A8A] leading-[18px] font-[400] font-[ProximaNova] mb-[0]">
                    14 days free from today, then just {{ planAmount }} (plus applicable taxes). Cancel through your dashboard or by e-mail (<a
                      class="text-primary underline"
                      href="mailto:support@estage.com"
                    >
                      support@estage.com
                    </a>).
                    <!--            I agree to the-->
                    <!--            <a-->
                    <!--              class="text-[#3D82EA] hover:underline"-->
                    <!--              href="https://estage.com"-->
                    <!--              target="_blank"-->
                    <!--            >-->
                    <!--              Terms of Service,-->
                    <!--            </a>-->
                    <!--            <a-->
                    <!--              class="text-[#3D82EA] hover:underline"-->
                    <!--              href="https://estage.com"-->
                    <!--              target="_blank"-->
                    <!--            >-->
                    <!--              Privacy Policy-->
                    <!--            </a>-->
                  </p>
                </div>
              </b-form>
            </template>
            <template v-else>
              <div ref="confetti" />
              <div class="absolute -top-[48px]">
                <div class="flex items-center justify-center p-[16px] bg-white rounded-full shadow-[0_4px_12px_rgba(0,0,0,0.02)]">
                  <div class="flex items-center justify-center p-[10px] rounded-full bg-[#F4F7FC] shadow-[inset_0_4px_4px_rgba(0,0,0,0.08)]">
                    <div class="flex justify-center p-[26px] rounded-full items-center border-2 border-[#DDE5F2] bg-[#F4F7FC]">
                      <svg
                        width="58"
                        height="63"
                        viewBox="0 0 58 63"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_di_3520_8581)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 62.2348H58V58.3806V51.4338H10.5694V36.188H58V32.3346V25.3877H0V62.2348Z"
                            fill="#1F2121"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0 11.7409H58V7.84559V0.823242H0V11.7409Z"
                            fill="#4F83E3"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_di_3520_8581"
                            x="0"
                            y="0.823242"
                            width="58"
                            height="65.4111"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset />
                            <feComposite
                              in2="hardAlpha"
                              operator="out"
                            />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_3520_8581"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_3520_8581"
                              result="shape"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="4" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite
                              in2="hardAlpha"
                              operator="arithmetic"
                              k2="-1"
                              k3="1"
                            />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="shape"
                              result="effect2_innerShadow_3520_8581"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div class="relative z-50 mt-[128px]">
                <h4 class="text-center text-[38px] text-[#363636] leading-[40px] font-[700] font-[ProximaNova]">
                  Welcome to ESTAGE!
                </h4>

                <div class="w-[465px] text-center text-[16px] text-[#5B6474] leading-[24px] font-[400] font-[ProximaNova] mt-[24px] mb-[32px]">
                  <b class="text-[#2B2B2B]">Congratulations and welcome to ESTAGE family!</b> <br>
                  <div class="mt-[8px]">
                    Your account was created successfully, and you can now access the most powerful HUB building software on the planet! <br>
                  </div>
                  <div class="mt-[8px]">
                    Simply click the button below to go to your main <b class="text-[#2B2B2B]">ESTAGE HUB</b> where you can now access the software.
                  </div>
                </div>

                <a
                  class="!inline-flex items-center justify-center text-center gap-[8px] w-full h-[80px] !text-[28px] !leading-[32px] !font-[700] text-white mt-[20px] rounded-[4px] hover:opacity-80 !bg-[#4989EC]"
                  href="https://hub.estage.com"
                  type="submit"
                >
                  Take Me To The Main Hub
                  <svg
                    class="w-6 h-6"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 12H19M19 12L12 5M19 12L12 19"
                      stroke="white"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>

                <div class="flex flex-col mt-[14px] gap-[10px] px-[20px]">
                  <p class="w-[436px] text-center text-[14px] text-[#8A8A8A] leading-[18px] font-[400] font-[ProximaNova] mb-[0]">
                    If you need any help at anytime, we're here for you <br>
                    at:
                    <a
                      class="text-primary underline"
                      href="mailto:support@estage.com"
                    >
                      support@estage.com
                    </a> anytime!
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
        
        <footer class="grid grid-flow-row mt-12">
          <div class="flex items-center justify-center gap-x-[12px]">
            <!--            <a-->
            <!--              href="https://estage.com/"-->
            <!--              target="_blank"-->
            <!--              class="text-center text-[14px] text-[#ADADAD] leading-[32px] font-[400] font-[ProximaNova] hover:text-[#ADADAD] hover:underline"-->
            <!--            >-->
            <!--              Terms-->
            <!--            </a>-->
            <!--            <a-->
            <!--              href="https://estage.com/"-->
            <!--              target="_blank"-->
            <!--              class="text-center text-[14px] text-[#ADADAD] leading-[32px] font-[400] font-[ProximaNova] hover:text-[#ADADAD] hover:underline"-->
            <!--            >-->
            <!--              Privacy-->
            <!--            </a>-->
            <a
              href="https://success.estage.com"
              target="_blank"
              class="text-center text-[14px] text-[#ADADAD] leading-[32px] font-[400] font-[ProximaNova] hover:text-[#ADADAD] hover:underline"
            >
              Support
            </a>
          </div>
          <div class="flex items-center justify-center gap-x-[12px]">
            <span class="text-center text-[14px] text-[#ADADAD] leading-[32px] font-[400] font-[ProximaNova]">
              © {{ new Date().getFullYear() }} -
              ESTAGE -
              All Rights Reserved.
            </span>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapState} from 'vuex'
import {VueMaskDirective} from 'v-mask'
import {confetti} from 'dom-confetti'
import PlanSelect from '@/components/editor/modals/purchase-element/business-plus/controls/PlanSelect.vue'
import CountrySelect from '@/layouts/dashboard/compontens/account/general/components/CountrySelect.vue'
export default {
  name: 'Signup',
  components: {
    CountrySelect,
    PlanSelect
  },
  directives: {
    mask: VueMaskDirective
  },
  data () {
    return {
      loading: false,
      success: false,
      activePlan: 0,
      activePeriod: 0,
      plans: [
        {
          name: 'Estage Business',
          duration: [
            {
              name: 'Yearly',
              interval: 'year',
              value: 'BUSINESS_Y',
              save: 20,
              price: 1970
            },
            {
              name: 'Monthly',
              interval: 'month',
              value: 'ESTAGE_SUB',
              price: 197
            }
          ]
        },
        {
          name: 'Estage Business Plus',
          duration: [
            {
              name: 'Yearly',
              interval: 'year',
              value: 'BUSINESS_PLUS_Y',
              save: 20,
              price: 2970
            },
            {
              name: 'Monthly',
              interval: 'month',
              value: 'BUSINESS_PLUS_M',
              price: 297
            }
          ]
        }
      ],
      businessPlusFeatures: [
        'Unlimited Video Hosting',
        'Real-time CTA',
        'ESTAGE Public Live Streaming',
        'ESTAGE Private Live Streaming',
        'Live Streaming Instant Replays',
        'Community Live Video Posts',
        'ESTAGE Native Live Chat',
        'Community Private Events',
        'Courses - Daily Challenge Mode',
        'Courses - A/R Integration for Lessons',
        'Community - Opportunities',
        'Community - MSI Ecosystem',
        'Community - IPS (Integrated Product Suite) Licensing and Management',
        'Community - Featured Ad Widget (in feed)',
        'Community - Upcoming Events Widget (in feed)',
        'And much, much more!'
      ],
      paymentMethod: null,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
        cc_number: '',
        cc_exp_month: '',
        cc_exp_year: '',
        cc_cvc: '',
        name: '',
        country: '',
        zip_code: ''
      },
      methods:[]
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    selectedPlan () {
      return this.plans[this.activePlan].duration[this.activePeriod].value
    },
    // eslint-disable-next-line vue/return-in-computed-property
    planAmount () {
      switch (this.selectedPlan) {
      case 'BUSINESS_PLUS_Y':
        return '$2970/year'
      case 'BUSINESS_PLUS_M':
        return '$297/month'
      case 'BUSINESS_Y':
        return '$1970/year'
      case 'ESTAGE_SUB':
        return '$197/month'
      }
    },
    paymentMethods () {
      const data  = _.cloneDeep(this.methods)
      data.push({
        id: -1,
        name: 'Add New Card'
      })
      return data
    }
  },
  methods: {
    async successEffect () {
      window.scrollTo({ top: 0, behavior: 'smooth' })

      setTimeout(() => {
        confetti(this.$refs.confetti, {
          angle: '180',
          spread: '360',
          startVelocity: 40,
          elementCount: '256',
          dragFriction: 0.12,
          duration: 5000,
          stagger: 3,
          width: '12px',
          height: '10px',
          perspective: '1080px'
        })
      }, 400)
    },
    async fetchPaymentMethods () {
      const {data} = await axios.get('api/user/settings/payment-methods')
      const cards = data.data.cards
      this.methods = (cards || []).map(c => {
        c.card.name = `Card ending in .. ${_.get(c,'card.last4')}`
        c.card.brand = _.get(c,'card.brand')
        return { ...c, ...c.card }
      })
    },
    submitExistUser () {
      if (this.paymentMethod.id === -1) {
        let valid = false
        this.$validator.validateAll()
          .then((v) => {
            valid = v
            if (valid) {
              this.loading = true
              // eslint-disable-next-line no-undef
              Stripe.setPublishableKey(process.env.VUE_APP_STRIPE_KEY)

              // eslint-disable-next-line no-undef
              Stripe.createToken({
                number: this.form.cc_number,
                cvc: this.form.cc_cvc,
                exp_month: this.form.cc_exp_month,
                exp_year: this.form.cc_exp_year,
                name: this.form.first_name + ' ' + this.form.last_name,
                address_country: this.form.country,
                address_zip: this.form.zip_code
              }, (status, response) => {
                if (status !== 200) {
                  this.$swal({
                    icon: 'error',
                    title: 'Payment Declined',
                    text: response.error.message,
                    showConfirmButton: false,
                    timer: 3500
                  })

                  return this.loading = false
                }

                axios.post('api/user/settings/payment-methods', {
                  token: response.id,
                  main: this.form.main
                })
                  .then(async (data) => {
                    const pm_id = _.get(data,'data.data.id')

                    await this.fetchPaymentMethods()

                    const pm = this.methods.find(m => m.id === pm_id)

                    if (pm) {
                      this.paymentMethod = pm
                    }

                    this.$swal({
                      icon: 'success',
                      iconColor: '#4F83E3',
                      toast: true,
                      position: 'top-right',
                      title: 'Payment method has been successfully added.',
                      showConfirmButton: false,
                      timer: 3000
                    })

                    await axios.post('api/user/payment-systems/new-subscription', {
                      'first_name': this.form.first_name,
                      'last_name': this.form.last_name,
                      plan: this.plans[this.activePlan].duration[this.activePeriod].value,
                      source: this.paymentMethod.id
                    })
                      .then(() => {
                        this.success = true
                        setTimeout(async () => {
                          await this.successEffect()
                        }, 300)
                      })
                      .catch(() => {
                        this.$swal({
                          icon: 'error',
                          iconColor: '#4F83E3',
                          toast: true,
                          position: 'top-right',
                          title: 'Payment Declined.',
                          text: 'The card information was declined',
                          showConfirmButton: false,
                          timer: 5000
                        })
                      })
                      .finally(() => {
                        this.loading = false
                      })
                  })
                  .catch(error => {
                    this.$swal({
                      icon: 'warning',
                      iconColor: '#4F83E3',
                      toast: true,
                      position: 'top-right',
                      title: error.response.data.message,
                      showConfirmButton: false,
                      timer: 3000
                    })
                    this.loading = false
                  })
              })
            }
          })
          .catch(() => {
            this.$swal({
              icon: 'error',
              title: 'Server Error',
              showConfirmButton: false,
              timer: 1500
            })

            this.loading = false
          })
      } else {
        axios.post('api/user/payment-systems/new-subscription', {
          'first_name': this.form.first_name,
          'last_name': this.form.last_name,
          plan: this.plans[this.activePlan].duration[this.activePeriod].value,
          source: this.paymentMethod.id
        })
          .then(() => {
            this.success = true
            setTimeout(async () => {
              await this.successEffect()
            }, 300)
          })
          .catch(() => {
            this.$swal({
              icon: 'error',
              iconColor: '#4F83E3',
              toast: true,
              position: 'top-right',
              title: 'Payment Declined.',
              text: 'The card information was declined',
              showConfirmButton: false,
              timer: 5000
            })
          })
      }
    },
    submitNewUser () {
      let valid = false
      this.$validator.validateAll()
        .then((v) => {
          valid = v
          if (valid) {
            this.loading = true
            // eslint-disable-next-line no-undef
            Stripe.setPublishableKey(process.env.VUE_APP_STRIPE_KEY)

            // eslint-disable-next-line no-undef
            Stripe.createToken({
              number: this.form.cc_number,
              cvc: this.form.cc_cvc,
              exp_month: this.form.cc_exp_month,
              exp_year: this.form.cc_exp_year,
              name: this.form.first_name + ' ' + this.form.last_name,
              address_country: this.form.country,
              address_zip: this.form.zip_code
            }, async (status, response) => {
              if (status !== 200) {
                this.$swal({
                  icon: 'error',
                  title: 'Payment Declined',
                  text: response.error.message,
                  showConfirmButton: false,
                  timer: 3500
                })

                return this.loading = false
              }

              await axios.post('/api/auth/signup', {
                email: this.form.email,
                password: this.form.password,
                'first_name': this.form.first_name,
                'last_name': this.form.last_name,
                plan: this.plans[this.activePlan].duration[this.activePeriod].value,
                stripe_card_token: response.id
              })
                .then(() => {
                  this.success = true
                  setTimeout(async () => {
                    await this.successEffect()
                  }, 300)
                })
                .catch(() => {
                  this.$swal({
                    icon: 'error',
                    iconColor: '#4F83E3',
                    toast: true,
                    position: 'top-right',
                    title: 'Payment Declined.',
                    text: 'The card information was declined',
                    showConfirmButton: false,
                    timer: 5000
                  })
                })
                .finally(() => {
                  this.loading = false
                })
            })
          }
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Server Error',
            showConfirmButton: false,
            timer: 1500
          })

          this.loading = false
        })
    },
    submit () {
      if (this.user) {
        this.submitExistUser()
      } else {
        this.submitNewUser()
      }
    },
    setUserData () {
      const userName = this.user.name.split(' ')

      if (userName.length === 2) {
        this.form.first_name = userName[0]
        this.form.last_name = userName[1]
      } else if (userName.length === 3) {
        this.form.first_name  = userName[0] + ' ' + userName[1]
        this.form.last_name = userName[2]
      } else if (userName.length === 4) {
        this.form.first_name  = userName[0] + ' ' + userName[1]
        this.form.last_name = userName[2] + ' ' + userName[3]
      } else {
        this.form.first_name = userName[0]
        this.form.last_name = userName[1]
      }

      this.form.email = this.user.email
      this.form.name = this.form.first_name + ' ' + this.form.last_name
    }
  },
  async mounted () {
    if (this.user) {
      this.setUserData()
      await this.fetchPaymentMethods()
        .catch(() => {
          this.paymentMethod = this.paymentMethods[0]
        })
      if (this.paymentMethod) {
        const main = this.methods.find(m => m.main)
        this.paymentMethod = main || this.paymentMethods[0]
      } else {
        this.paymentMethod = this.paymentMethods[0]
      }
    } else {
      this.paymentMethod = this.paymentMethods[0]
    }
  }
}
</script>