<template>
  <div>
    <sid-range-slider
      label="Font Size"
      :sid="`.--${config.sid}--form .phone-code-select`"
      rule-name="font-size"
    />

    <sid-font
      prefix="Input"
      :sid="`.--${config.sid}--form .phone-code-select`"
    />

    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0">Font Color</label>

        <sid-color
          :sid="`.--${config.sid}--form .phone-code-select`"
          rule-name="color"
        />
      </div>
    </b-form-group>

    <hr>

    <sid-background
      :sid="`.--${config.sid}--form .phone-code-select`"
    />

    <sid-range-slider
      label="Input Height"
      :sid="`.--${config.sid}--form .phone-code-select`"
      rule-name="height"
    />

    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0">Border Type</label>

        <sid-select
          :sid="`.--${config.sid}--form .phone-code-select`"
          rule-name="border-style"
          :searchable="false"
          class="w-[118px]"
          :options="[{label: 'None', value: 'none'}, {label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <sid-linked-group
        :sid="`.--${config.sid}--form .phone-code-select`"
        label="Border Width"
        rule-name="border"
        class="!mb-[7px]"
        :angles="[
          {
            text: '',
            value: 'top-width'
          },
          {
            text: '',
            value: 'right-width'
          },
          {
            text: '',
            value: 'bottom-width'
          },
          {
            text: '',
            value: 'left-width'
          }
        ]"
      />

      <div class="flex w-full justify-end">
        <sid-color
          :sid="`.--${config.sid}--form .phone-code-select`"
          rule-name="border-color"
        />
      </div>
    </b-form-group>

    <sid-linked-group
      label="Radius"
      :sid="`.--${config.sid}--form .phone-code-select`"
      rule-name="border"
      :angles="[
        {
          text: 'Top Left',
          value: 'top-left-radius'
        },
        {
          text: 'Top Right',
          value: 'top-right-radius'
        },
        {
          text: 'Bottom Left',
          value: 'bottom-left-radius'
        },
        {
          text: 'Bottom Right',
          value: 'bottom-right-radius'
        }
      ]"
    />

    <hr>

    <sid-linked-group
      label="Padding"
      :sid="`.--${config.sid}--form .phone-code-select`"
      rule-name="padding"
      :min="0"
      :angles="[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]"
    />

    <sid-linked-group
      label="Margin"
      :sid="`.--${config.sid}--form .phone-code-select`"
      rule-name="margin"
      :min="-2000"
      :max="2000"
      :angles="[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]"
    />
  </div>
</template>

<script>
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont'

export default {
  name: 'FormPhoneStyle',
  components: {
    SidFont,
    SidColor,
    SidSelect, SidLinkedGroup, SidBackground, SidRangeSlider},
  props: {
    config: {
      type: Object,
      required: true
    }
  }
}
</script>
