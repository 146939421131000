<template>
  <div :style="{height: sticky ? `${videoHeight}px` : null}">
    <div
      :class="stickyClasses"
      :style="{...radius}"
    >
      <i
        v-if="sticky && closable"
        class="fa fa-close"
        @click="stickyIsClosed = true"
      />
      <div
        v-if="!isShowVideo"
        class="embed-responsive overflow-visible embed-responsive-16by9"
      >
        <div
          :class="customs.shadow"
          class="embed-responsive-item"
        >
          <div
            :style="{backgroundColor: customs.playIcon.backgroundColor}"
            class="play-btn"
            @click="isShowVideo = true"
          >
            <i
              :class="customs.playIcon.icon"
              :style="{color: customs.playIcon.color}"
            />
          </div>
          <b-img
            :src="customs.placeholder || placeholder"
            :style="{...border, ...radius, ...shadow}"
            class="absolute top-0 left-0 w-full h-full"
          />
        </div>
      </div>
      <div v-else>
        <div
          :style="{...border, ...radius, ...shadow}"
          class="embed-responsive embed-responsive-16by9"
        >
          <div
            ref="video"
            class="embed-responsive-item"
          >
            <!--          <iframe src="https://fast.wistia.net/embed/iframe/4kco01ezaq?videoFoam=true&autoplay=true" frameborder="0"></iframe>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ComponentProvider from '@builder/components/mixins/ComponentProvider'
import VimeoPlayer from '@vimeo/player'
import getEmbedUrl from 'better-video-embeds'

const YouTubeIframeLoader = require('youtube-iframe')

export default {
  name: 'VideoPlay',

  mixins: [ComponentProvider],

  props: {
    autoplay: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isShowVideo: false,
      placeholder: require('@builder/assets/images/placeholder.png'),
      videoHeight: 0,
      stuck: false,
      stickyIsClosed: false
    }
  },

  computed: {
    sticky () {
      return this.stuck && !this.stickyIsClosed
    },
    videoId () {
      return getEmbedUrl(this.customs.src).split('/').slice(-1)[0]
    },
    platform () {
      if (this.customs.src.split('/').includes('vimeo.com')) {
        return 'vimeo'
      }

      if (this.customs.src.search('wistia') !== -1) {
        return 'wistia'
      }

      return 'youtube'
    },
    shadow () {
      const shadow = this.customs.shadowOptions
      const color = this.customs.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    stickyClasses () {
      if (this.sticky) {
        const position = this.customs.sticky.position
        return `stuck video-sticky-position-${position === undefined || position === null ? 0 : position} video-sticky-size-${this.customs.sticky.size || 0} video-sticky-style-${this.customs.sticky.style || 0}`
      }

      return null
    },
    border () {
      const border = this.customs.border || [0, 0, 0, 0]
      return {
        borderColor: this.customs.borderColor,
        borderStyle: 'solid',
        borderTopWidth: `${border[0]}px`,
        borderRightWidth: `${border[1]}px`,
        borderBottomWidth: `${border[2]}px`,
        borderLeftWidth: `${border[3]}px`
      }
    },
    radius () {
      const radius = this.customs.radius || [5, 5, 5, 5]

      return {
        borderTopLeftRadius: `${radius[0]}px`,
        borderTopRightRadius: `${radius[1]}px`,
        borderBottomLeftRadius: `${radius[2]}px`,
        borderBottomRightRadius: `${radius[3]}px`
      }
    },
    closable () {
      return _.get(this.customs, 'sticky.closable', false)
    },
    disablePreview () {
      return this.customs.disablePreview
    }
  },

  watch: {
    stuck (val) {
      if (val) {
        return this.$el.closest('[data-id]').classList.add('custom-z-index')
      }

      this.$el.closest('[data-id]').classList.remove('custom-z-index')
    },
    isShowVideo (val) {
      if (val) {
        setTimeout(() => {
          this.playVideo(!this.customs.disablePreview)
        })
      }
    },
    disablePreview (val) {
      if (!val) {
        this.isShowVideo = false
      } else {
        this.isShowVideo = false

        setTimeout(() => {
          this.isShowVideo = true
        })
      }
    }
  },

  created () {
    if (!this.customs.playIcon) {
      this.$set(this.customs, 'playIcon', {
        icon: 'fa fa-play',
        color: '#000',
        backgroundColor: '#fff'
      })
    }
  },

  mounted () {
    if (this.customs.disablePreview) {
      this.isShowVideo = true
    }

    this.videoHeight = this.$el.firstElementChild.clientHeight
    document.addEventListener('scroll', this.onScroll)
  },

  destroyed () {
    document.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onScroll () {
      if (!this.customs.sticky || !this.customs.sticky.enabled || !this.isShowVideo) return
      const videoBottom = this.videoHeight + this.$el.getBoundingClientRect().top
      this.stuck = videoBottom < 0
    },
    playVideo (autoplay = false) {
      if (this.platform === 'vimeo') {
        return new VimeoPlayer(this.$refs.video, {
          id: this.videoId,
          title: false,
          portrait: false,
          byline: false,
          autoplay: this.autoplay || autoplay
        })
      }

      if (this.platform === 'wistia') {
        const iframe = document.createElement('iframe')
        iframe.src = `https://fast.wistia.net/embed/iframe/${_.head(this.customs.src.split('/').slice(-1))}?videoFoam=true&autoplay=${this.autoplay || autoplay}`
        this.$refs.video.appendChild(iframe)
        return
      }

      return YouTubeIframeLoader.load(YT => {
        new YT.Player(this.$refs.video, {
          videoId: this.videoId,
          playerVars: {
            autoplay: this.autoplay || autoplay,
            aspect: '16by9',
            enablejsapi: 1,
            modestbranding: 1,
            iv_load_policy: 3,
            loop: true,
            origin: window.location.origin
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
.play-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 80px;
  font-size: 19px;
  height: 80px;
  transition: .2s;

  &:hover {
    cursor: pointer;
    width: 90px;
    height: 90px;
  }
}


@keyframes sticky-fade-in-up {
  0% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.stuck {
  position: fixed;
  transform: translateY(100%);
  animation: sticky-fade-in-up .25s ease forwards;
  z-index: 100000;

  .fa-close {
    position: absolute;
    z-index: 1000000;
    top: 5px;
    right: 5px;
    font-size: 18px;
    text-shadow: 0 0 3px #2d2d2d;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }
}

.video-sticky-position- {
  &0 {
    top: 20px;
    left: 20px;
  }

  &1 {
    top: 20px;
    right: 20px;
  }

  &2 {
    bottom: 20px;
    left: 20px;
  }

  &3 {
    bottom: 20px;
    right: 20px;
  }
}

.video-sticky-size- {
  &0 {
    width: 260px;
    height: 145px;
  }

  &1 {
    width: 402px;
    height: 218px;
  }

  &2 {
    width: 496px;
    height: 274px;
  }
}

.video-sticky-style- {
  &0 {
    box-shadow: 0 0 12px 4px #727272;
  }

  &1 {
    box-shadow: 0 0 12px 4px #e3e3e3;
  }
}
</style>
