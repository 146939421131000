<template>
  <div class="d-flex align-items-center p-[2px]">
    <img
      v-if="customs.image.src"
      :src="customs.image.src"
      :style="{height: imageSize}"
    >
    <div
      v-else-if="customs.text"
      :style="styles"
    >
      {{ customs.text }}
    </div>
    <div
      v-else
      class="logo-placeholder"
    />
  </div>
</template>

<script>
import ComponentProvider from '@builder/components/mixins/ComponentProvider'
import {mapGetters} from 'vuex'
import _ from 'lodash'
import ResolutionMixin from '../../mixins/ResolutionMixin'

export default {
  name: 'PubLogo',

  mixins: [ComponentProvider, ResolutionMixin],

  computed: {
    fontSize () {
      return _.get(this.customs, ['textLogoSize', this.resolutioner], _.get(this.customs, 'fontSize', '30px'))
    },
    imageSize () {
      return _.get(this.customs, ['logoSize', this.resolutioner], _.get(this.customs, 'image.logoSize', '30px'))
    },
    styles () {
      return {
        fontWeight: this.customs.fontWeight,
        fontSize: this.fontSize,
        fontFamily: this.customs.fontFamily === 'Global Style' ? this.globalStyles.font.style : this.customs.fontFamily,
        color: this.customs.color
      }
    },
    ...mapGetters('pages', {
      pages: 'getPages'
    })
  },

  created () {
    if (!this.customs.hasOwnProperty('image')) {
      this.$set(this.customs, 'image', {})
    }
  },

  methods: {
    goHomePage () {
      this.$store.dispatch('pages/goToPage', {
        item_type: 'page',
        item_object_id: _.get(_.head(_.filter(this.pages.data, ['is_home', 1])), 'id'),
        item_url: null
      })
    }
  }
}
</script>
