// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  data () {
    return {
      $parentRelativeFlexWrapper: null,
      isDrag: false,
      holdTimeout: null
    }
  },
  methods: {
    onMousedown (e) {
      if (e.target.closest('.move-col')) this.isDrag = true
    },
    onMouseUp (e) {
      if (!this.isDrag) return

      const {top: wrapperTop, left: wrapperLeft, width: wrapperWidth, height: wrapperHeight} = this.$parentRelativeFlexWrapper.firstElementChild.getBoundingClientRect()

      const top = Math.round((100 * (e.clientY - wrapperTop)) / wrapperHeight)
      let left = Math.round((100 * (e.clientX - wrapperLeft)) / wrapperWidth)

      this.$set(this.col, 'transformY', {..._.set(this.col['transformY'] || {}, [this.resolutioner], top)})
      this.$set(this.col, 'transformX', {..._.set(this.col['transformX'] || {}, [this.resolutioner], left)})

      this.isDrag = false
      window.isDrag = false
    },
    onMouseMove (e) {
      if (this.isDrag) {
        window.isDrag = true
        const {top: wrapperTop, left: wrapperLeft, width: wrapperWidth, height: wrapperHeight} = this.$parentRelativeFlexWrapper.querySelector('.flex-wrapper').getBoundingClientRect()
        const top = Math.round((100 * (e.clientY - wrapperTop)) / wrapperHeight)
        let left = Math.round((100 * (e.clientX - wrapperLeft)) / wrapperWidth)
        this.$set(this.col, 'transformY', {..._.set(this.col['transformY'] || {}, [this.resolutioner], top)})
        this.$set(this.col, 'transformX', {..._.set(this.col['transformX'] || {}, [this.resolutioner], left)})
      }
    },
    setInitialPosition () {
      const {top: wrapperTop, left: wrapperLeft, width: wrapperWidth, height: wrapperHeight} = this.$parentRelativeFlexWrapper.querySelector('.flex-wrapper').getBoundingClientRect()
      const {top: colTop, left: colLeft} = this.$el.getBoundingClientRect()

      const top = Math.round((100 * (colTop - wrapperTop)) / wrapperHeight)
      let left = Math.round((100 * (colLeft - wrapperLeft)) / wrapperWidth)
      this.$set(this.col, 'transformY', {..._.set(this.col['transformY'] || {}, [this.resolutioner], top)})
      this.$set(this.col, 'transformX', {..._.set(this.col['transformX'] || {}, [this.resolutioner], left)})
    }
  },
  mounted () {
    this.$parentRelativeFlexWrapper = this.$el.closest('.el-flex-wrapper')
    this.$el.addEventListener('mousedown', this.onMousedown)
    window.addEventListener('mouseup', this.onMouseUp)
    window.addEventListener('mousemove', this.onMouseMove)
  },
  beforeDestroy () {
    this.$el.removeEventListener('mousedown', this.onMousedown)
    window.removeEventListener('mouseup', this.onMouseUp)
    window.removeEventListener('mousemove', this.onMouseMove)
  }
}