<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="el-dynamic"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('data')"
  >
    <div :data-sid="section.sid">
      <div
        class="post-featured position-relative"
        :class="className()"
      >
        <div class="img-wrapper flex">
          <img
            src="https://res.cloudinary.com/estage/image/upload/v1692191348/users/user-3/fe0cec0aebff9e6f4e6a104ff985d1ce.png"
            class="img object-cover"
          >
        </div>

        <div class="post-info flex align-items-center w-100 flex-wrap">
          <div class="d-flex align-items-center flex-wrap">
            <div class="post-author align-items-center">
              <b-img
                v-if="l.get(customPostData,'author.avatar')"
                height="33"
                width="33"
                rounded="circle"
                class="avatar post-author-img mr-2"
                :src="l.get(customPostData,'author.avatar')"
                alt="Author Avatar"
              />
              <div
                v-else
                class="rounded-circle post-author-img mr-2"
              />
              <div class="post-author-name">
                {{ l.get(customPostData,'author.name','John Smith') }}
              </div>
            </div>

            <div class="post-date">
              March 28, 2023
            </div>
          </div>

          <div class="post-category">
            Social Media
          </div>
        </div>

        <div
          class="post-title"
          v-text="title"
        />

        <div
          class="post-text"
          v-text="text"
        />

        <div class="post-button-container">
          <div class="post-button flex align-items-center cursor-pointer">
            {{ section.options.content.data.btnText || 'Read More...' }}
          </div>
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
import _ from 'lodash'

export default {
  name: 'ElBlogCategories',
  components: {Highliter},
  mixins: [SectionMixin],
  inject: {
    getCustomPostEditorData: {
      default:() => () => null
    }
  },
  data () {
    return {
      l: _
    }
  },
  computed: {
    customPostData () {
      return this.getCustomPostEditorData() 
    },
    title () {
      const text = 'This is An Example Headline - Your Featured Post Headline Will Appear Here In The Live View!'
      let max = this.section.options.content.data.maxTitleWords

      if (!max) return text

      // Split the text into an array of words
      const words = text.split(' ')

      // Extract the first "limit" words
      const limitedWords = words.slice(0, max)

      // Join the words back into a string
      const limitedText = limitedWords.join(' ')

      // Add ellipsis if the text was truncated
      if (words.length > max) {
        return `${limitedText}...`
      }

      return limitedText
    },
    text () {
      const text = 'Curabitur convallis urna non sem faucibus, vitae egestas odio facilisis. Proin egestas vestibulum eros id posuere. Maecenas eu libero facilisis, egestas purus ac, ullamcorper elit. Fusce tristique ante mollis lacinia ullamcorper. Fusce bibendum cursus mauris eu gravida. Morbi non elementum enim, sit amet pellentesque magna. Duis eu risus tincidunt diam aliquam pulvinar vel in ex. Duis eleifend ipsum vel nulla dapibus blandit.'
      let max = this.section.options.content.data.maxExcerptWords

      if (!max) return text

      // Split the text into an array of words
      const words = text.split(' ')

      // Extract the first "limit" words
      const limitedWords = words.slice(0, max)

      // Join the words back into a string
      const limitedText = limitedWords.join(' ')

      // Add ellipsis if the text was truncated
      if (words.length > max) {
        return `${limitedText}...`
      }

      return limitedText
    }
  },
  methods: {
    className (name = '') {
      return `--post-featured-${this.section.sid}${name}`
    }
  }
}
</script>

<style lang="scss">
.post-featured {

  .img-wrapper {
    margin-bottom: 35px;
  }

  .img {
    height: 400px;
    width: 100%;
    background: #dddddd;
  }

  .post-title {
    font-weight: 700;
    font-size: 50px;
    line-height: 56px;
    color: #000000;
    margin-bottom: 20px;
  }

  .post-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: #667085;
    margin-bottom: 20px;
  }

  .post-button {
    font-size: 16px;
    color: #3D82EA;
    background: transparent;
    justify-content: left;
  }

  .post-info {
    justify-content: space-between;
    & > div {
      gap: 10px;
    }
  }

  .post-author {
    display: var(--post-author-display, flex);
  }

  .post-author-img {
    width: 33px;
    height: 33px;
    min-width: 33px;
    min-height: 33px;
    max-width: 33px;
    max-height: 33px;
    object-fit: cover;    
    background: #dddddd;
  }

  .post-author-name {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    color: #3D82EA;
  }

  .post-date {
    color: #667085;
    font-weight: 600;
    font-size: 13px;
  }

  .post-category {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    color: #3D82EA;
  }
}
</style>