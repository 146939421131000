<template>
  <div>
    <SidbearSettingsGroup
      title="Counter"
      active
    >
      <b-form-group label="Starting Number">
        <LinkedControlItem
          v-model="section.options.content.counter.from"
          :step="100"
          :min="0"
          :max="10000000000000"
          :delay="true"
          class="d-block w-100"
        />
      </b-form-group>

      <b-form-group label="Ending Number">
        <LinkedControlItem
          v-model="section.options.content.counter.to"
          :step="100"
          :min="0"
          :max="10000000000000"
          :delay="true"
          class="d-block w-100"
        />
      </b-form-group>

      <b-form-group>
        <label class="mb-0">Animation Duration (<span class="lowercase">sec</span>)</label>

        <LinkedControlItem
          v-model="section.options.content.counter.speed"
          :step="0.1"
          :min="0"
          :max="20"
          class="d-block w-100"
        />
      </b-form-group>

      <font-family-and-weight-control
        text-type="paragraph"
        :family="section.options.content.counter.fontFamily"
        :weight="section.options.content.counter.fontWeight"
        @family="family => section.options.content.counter.fontFamily = family"
        @weight="weight => section.options.content.counter.fontWeight = weight"
      />

      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <color-picker
            :color="section.options.content.counter.color"
            @input="event => section.options.content.counter.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />

          <label class="mb-0 pb-0">Text Color</label>
        </div>
      </b-form-group>

      <alignment-items-control :config="config" />

      <b-form-group
        label="Size"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="size"
              :interval="1"
              :max="200"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col class="p-0">
            <LinkedControlItem
              v-model="size"
              :max="200"
              :min="0"
              class="d-block !w-[60px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        label="Gap"
        label-class="!pb-[3px]"
        class="mb-4"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="gap"
              :interval="1"
              :max="40"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col class="p-0">
            <LinkedControlItem
              v-model="gap"
              :max="40"
              :min="0"
              class="d-block !w-[60px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="mb-0 pb-0">Background</label>

          <b-checkbox
            v-model="shapeEnabled"
            size="lg"
            switch
            class="mt-[-6px]"
          />
        </div>
      </b-form-group>

      <div class="relative">
        <background-customs
          type-label=""
          :config="section.options.customize.style.background"
        />

        <b-form-group label="Border & Radius">
          <border-customs :config="config" />
        </b-form-group>

        <b-form-group>
          <shadow-customs :config="config" />
        </b-form-group>

        <div
          v-if="!shapeEnabled"
          class="absolute top-0 left-[-3px] bg-white w-[103%] h-100 z-index-100 opacity-80"
        />
      </div>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Decimal"
    >
      <b-form-group>
        <b-checkbox v-model="section.options.content.counter.decimal.enabled.lg">
          Decimal
        </b-checkbox>
      </b-form-group>

      <b-form-group label="Char">
        <b-input
          v-model="section.options.content.counter.decimal.char"
          :maxlength="2"
        />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <color-picker
            :color="section.options.content.counter.decimal.color || section.options.content.counter.color"
            @input="event => section.options.content.counter.decimal.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />

          <label class="mb-0 pb-0">Color</label>
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Counter Prefix"
    >
      <b-form-group label="Prefix">
        <b-input
          v-model="section.options.content.counter.prefix.text.lg"
        />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <color-picker
            :color="section.options.content.counter.prefix.color || section.options.content.counter.color"
            @input="event => section.options.content.counter.prefix.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />

          <label class="mb-0 pb-0">Text Color</label>
        </div>
      </b-form-group>

      <font-family-and-weight-control
        text-type="paragraph"
        :family="section.options.content.counter.prefix.fontFamily || section.options.content.counter.fontFamily"
        :weight="section.options.content.counter.prefix.fontWeight || section.options.content.counter.fontWeight"
        @family="family => section.options.content.counter.prefix.fontFamily = family"
        @weight="weight => section.options.content.counter.prefix.fontWeight = weight"
      />

      <b-form-group
        label="Size"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="prefixSize"
              :interval="1"
              :max="200"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col class="p-0">
            <LinkedControlItem
              v-model="prefixSize"
              :max="200"
              :min="0"
              class="d-block !w-[60px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <margin-full-customs
        :config="section.options.content.counter.prefix.margin"
        :min="-100"
        :max="100"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Counter Postfix"
    >
      <b-form-group label="Postfix">
        <b-input
          v-model="section.options.content.counter.postfix.text.lg"
        />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <color-picker
            :color="section.options.content.counter.postfix.color || section.options.content.counter.color"
            @input="event => section.options.content.counter.postfix.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />

          <label class="mb-0 pb-0">Text Color</label>
        </div>
      </b-form-group>

      <font-family-and-weight-control
        text-type="paragraph"
        :family="section.options.content.counter.postfix.fontFamily || section.options.content.counter.fontFamily"
        :weight="section.options.content.counter.postfix.fontWeight || section.options.content.counter.fontWeight"
        @family="family => section.options.content.counter.postfix.fontFamily = family"
        @weight="weight => section.options.content.counter.postfix.fontWeight = weight"
      />

      <b-form-group
        label="Size"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="postfixSize"
              :interval="1"
              :max="200"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col class="p-0">
            <LinkedControlItem
              v-model="postfixSize"
              :max="200"
              :min="0"
              class="d-block !w-[60px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <margin-full-customs
        :config="section.options.content.counter.postfix.margin"
        :min="-100"
        :max="100"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Animations"
    >
      <animations :animations="config.animations" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="section.options.customize.style.padding" />
      <margin-full-customs
        :config="section.options.customize.style.margin"
        :min="-500"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
import AlignmentItemsControl from '@/components/editor/controls/AlignmentItemsControl'
import FontFamilyAndWeightControl from '@/components/editor/controls/FontFamilyAndWeightControl'
import Animations from '@/components/editor/controls/Animations'

export default {
  name: 'PromoMod',
  components: {
    Animations, FontFamilyAndWeightControl, AlignmentItemsControl, LinkedControlItem, SidbearSettingsGroup},
  mixins: [ModMixin],
  computed: {
    postfixSize: {
      get () {
        const size = this.section.options.content.counter.postfix.fontSize
        if (size[this.resolutioner] === null) return size.lg || this.section.options.content.counter.size[this.resolutioner]

        return size[this.resolutioner]
      },
      set (val) {
        this.section.options.content.counter.postfix.fontSize[this.resolutioner] = val
      }
    },
    prefixSize: {
      get () {
        const size = this.section.options.content.counter.prefix.fontSize
        if (size[this.resolutioner] === null) return size.lg || this.section.options.content.counter.size[this.resolutioner]

        return size[this.resolutioner]
      },
      set (val) {
        this.section.options.content.counter.prefix.fontSize[this.resolutioner] = val
      }
    },
    shapeEnabled: {
      get () {
        let _subject = this.section.options.content.counter.shapeEnabled[this.resolutioner]
        if (_subject === null) {
          _subject = this.section.options.content.counter.shapeEnabled.lg
        }

        return _subject
      },
      set (val) {
        this.section.options.content.counter.shapeEnabled[this.resolutioner] = val
      }
    },
    gap: {
      get () {
        let _gap = this.section.options.content.counter.gap[this.resolutioner]
        if (_gap === null) {
          _gap = this.section.options.content.counter.gap.lg
        }

        return _gap
      },
      set (val) {
        this.section.options.content.counter.gap[this.resolutioner] = val
      }
    },
    size: {
      get () {
        let _size = this.section.options.content.counter.size[this.resolutioner]
        if (_size === null) {
          _size = this.section.options.content.counter.size.lg
        }

        return _size
      },
      set (val) {
        this.section.options.content.counter.size[this.resolutioner] = val
      }
    }
  }
}
</script>