<template>
  <div>
    <b-form-group label="Focus Keyword/Keyphrase">
      <b-textarea
        v-model="post.seo_keywords"
        placeholder="animals, music"
      />
    </b-form-group>

    <b-form-group label="SEO Title">
      <b-input v-model="post.seo_title" />
    </b-form-group>

    <b-form-group label="SEO Description">
      <b-textarea v-model="post.seo_description" />
    </b-form-group>

    <b-form-group label="URL Slug">
      <b-input
        v-model="post.slug"
        placeholder="postitle"
        class="mb-[12px]"
      />

      <div class="text-[#4F4F4F]">
        <span class="block font-[600] text-[14px] mb-1">Warning note:</span>
        <i class="text-[12px]">Changes to the URL Slug will change the page link. Any links to this page will need to be updated to reflect this change.</i>
      </div>
    </b-form-group>

    <!--    <b-form-group label="Code Injection">-->
    <!--      <b-textarea v-model="post.code_injection" />-->
    <!--    </b-form-group>-->
  </div>
</template>

<script>
export default {
  name: 'PostSeo',
  props: {
    post: {
      type: Object
    }
  }
}
</script>
