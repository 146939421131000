import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Field Authorization'
export const group = ''

export default class ElFieldAuth extends SectionProvider {
  constructor (options = {}) {
    super('ElFieldAuth', options)

    this.name = name
    this.group = group
  }
}