<template>
  <div>
    <div>
      <div
        class="flex items-center justify-between h-[43px] bg-gradient-to-b from-white to-[#F1F3F4] px-[18px] border-b border-[#E2E5EC]"
      >
        <span class="text-[#44474A] font-[700] text-[15px] mb-0">
          Global Style
        </span>
      </div>
      <b-link
        class="flex items-center !text-[#949697] hover:no-underline hover:bg-gray-50 text-[14px] h-[53px] px-[20px] border-b border-[#E2E5EC]"
        @click="$store.commit('editor/SET_PAGE', 'sections')"
      >
        <icon
          class="mr-[11px]"
          icon="left-arrow.svg"
        />
        Go Back
      </b-link>
      <div class="flex flex-column relative max-h-full h-[calc(100vh-232px)] simple-scrollbar">
        <div class="overflow-auto pb-[1px]">
          <b-card
            class="border-0"
            no-body
          >
            <div class="section-settings">
              <b-card
                class="border-0 noselect"
                no-body
              >
                <b-card-body class="border-top-0 pr-3 pt-0 pb-0">
                  <SidbearSettingsGroup
                    :active="activeTab === 0"
                    content-class="py-0 pr-[15px]"
                    title="Text Styling"
                    @opened="() => activeTab = 0"
                  >
                    <template slot="title">
                      <div class="flex items-center">
                        <h4 class="font-[700] text-[#44474A] text-[14px] mb-0">
                          Text Style
                        </h4>
                      </div>
                    </template>

                    <text-settings />
                  </SidbearSettingsGroup>

                  <SidbearSettingsGroup
                    title="Blocks"
                    content-class="border-bottom pb-0"
                  >
                    <sid-linked-group
                      label="Padding"
                      :sid="`.el-block`"
                      rule-name="padding"
                      :min="0"
                      :angles="[
                        {
                          text: '',
                          value: 'top'
                        },
                        {
                          text: '',
                          value: 'right'
                        },
                        {
                          text: '',
                          value: 'bottom'
                        },
                        {
                          text: '',
                          value: 'left'
                        }
                      ]"
                    />
                    <sid-linked-group
                      label="Margin"
                      :sid="`.el-container`"
                      rule-name="margin"
                      :min="-2000"
                      :max="2000"
                      :angles="[
                        {
                          text: '',
                          value: 'top'
                        },
                        {
                          text: '',
                          value: 'right'
                        },
                        {
                          text: '',
                          value: 'bottom'
                        },
                        {
                          text: '',
                          value: 'left'
                        }
                      ]"
                    />
                  </SidbearSettingsGroup>

                  <SidbearSettingsGroup
                    title="Flex Wrapper"
                    content-class="border-bottom pb-0"
                  >
                    <sid-linked-group
                      label="Padding"
                      :sid="`.flex-wrapper`"
                      rule-name="padding"
                      :min="0"
                      :angles="[
                        {
                          text: '',
                          value: 'top'
                        },
                        {
                          text: '',
                          value: 'right'
                        },
                        {
                          text: '',
                          value: 'bottom'
                        },
                        {
                          text: '',
                          value: 'left'
                        }
                      ]"
                    />
                    <sid-linked-group
                      label="Margin"
                      :sid="`.el-flex-wrapper`"
                      rule-name="margin"
                      :min="-2000"
                      :max="2000"
                      :angles="[
                        {
                          text: '',
                          value: 'top'
                        },
                        {
                          text: '',
                          value: 'right'
                        },
                        {
                          text: '',
                          value: 'bottom'
                        },
                        {
                          text: '',
                          value: 'left'
                        }
                      ]"
                    />
                  </SidbearSettingsGroup>

                  <SidbearSettingsGroup
                    title="Columns"
                    content-class="border-bottom pb-0"
                  >
                    <sid-linked-group
                      label="Padding"
                      :sid="`.flex-col-inner`"
                      rule-name="padding"
                      :min="0"
                      :angles="[
                        {
                          text: '',
                          value: 'top'
                        },
                        {
                          text: '',
                          value: 'right'
                        },
                        {
                          text: '',
                          value: 'bottom'
                        },
                        {
                          text: '',
                          value: 'left'
                        }
                      ]"
                    />
                    <sid-linked-group
                      label="Margin"
                      :sid="`.col-hover-v2`"
                      rule-name="margin"
                      :min="-2000"
                      :max="2000"
                      :angles="[
                        {
                          text: '',
                          value: 'top'
                        },
                        {
                          text: '',
                          value: 'right'
                        },
                        {
                          text: '',
                          value: 'bottom'
                        },
                        {
                          text: '',
                          value: 'left'
                        }
                      ]"
                    />
                  </SidbearSettingsGroup>

                  <SidbearSettingsGroup
                    :active="activeTab === 2"
                    @opened="() => activeTab = 2"
                  >
                    <template slot="title">
                      <h4 class="font-[700] text-[#44474A] text-[14px] mb-0">
                        Menu Links
                      </h4>
                    </template>

                    <menu-links-global-style />
                  </SidbearSettingsGroup>

                  <SidbearSettingsGroup
                    :active="activeTab === 3"
                    @opened="() => activeTab = 3"
                  >
                    <template slot="title">
                      <div class="flex items-center">
                        <h4 class="font-[700] text-[#44474A] text-[14px] mb-0">
                          Color Palette
                        </h4>
                      </div>
                    </template>

                    <color-pollite
                      v-model="globalStyles.colors.primary"
                      :palette="getPalette(globalStyles.colors, 'palette')"
                    />
                  </SidbearSettingsGroup>

                  <SidbearSettingsGroup
                    :active="activeTab === 4"
                    content-class="pb-0"
                    @opened="() => activeTab = 4"
                  >
                    <template slot="title">
                      <div class="flex items-center">
                        <h4 class="font-[700] text-[#44474A] text-[14px] mb-0">
                          Buttons
                        </h4>
                      </div>
                    </template>

                    <button-global-style-v2 />
                  </SidbearSettingsGroup>

                  <SidbearSettingsGroup
                    :active="activeTab === 5"
                    @opened="() => activeTab = 5"
                  >
                    <template slot="title">
                      <div class="flex items-center">
                        <h4 class="font-[700] text-[#44474A] text-[14px] mb-0">
                          Background Settings
                        </h4>
                      </div>
                    </template>

                    <background-customs :config="siteBackgroundColor" />

                    <background-image-customs :config="siteBackgroundImage" />
                  </SidbearSettingsGroup>

                  <SidbearSettingsGroup
                    :active="activeTab === 6"
                    @opened="() => activeTab = 6"
                  >
                    <template slot="title">
                      <div class="flex items-center">
                        <h4 class="font-[700] text-[#44474A] text-[14px] mb-0">
                          Loading Indicator
                        </h4>
                      </div>
                    </template>
                    
                    <loading-indicator />
                  </SidbearSettingsGroup>
                </b-card-body>
              </b-card>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import TextSettings from '../utils/TextSettings'
import ColorPollite from '../utils/ColorPollite'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import ButtonGlobalStyleV2 from '@/components/editor/utils/ButtonGlobalStyleV2'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import MenuLinksGlobalStyle from '@/components/editor/utils/MenuLinksGlobalStyle'
import LoadingIndicator from '@/components/editor/utils/LoadingIndicator.vue'

const colors = {
  hex: '#194d33e6'
}

export default {
  name: 'GlobalStyles',

  components: {
    LoadingIndicator,
    MenuLinksGlobalStyle,
    SidLinkedGroup,
    ButtonGlobalStyleV2,
    SidbearSettingsGroup, ColorPollite, TextSettings},

  data () {
    return {
      colors: colors,
      activeTab: 0
    }
  },

  computed: {
    siteBackgroundColor () {
      if (!this.globalStyles.background) {
        this.$set(this.globalStyles, 'background', {
          background: '',
          gradient: {
            position: '',
            color: ''
          }
        })
      }

      return this.globalStyles.background
    },
    siteBackgroundImage () {
      if (!this.globalStyles.backgroundImage) {
        this.$set(this.globalStyles, 'backgroundImage', {})
      }

      return this.globalStyles.backgroundImage
    },
    linksStyle () {
      if (!this.globalStyles.hasOwnProperty('linksStyle')) {
        this.$set(this.globalStyles, 'linksStyle', {
          color: '',
          hoverColor: '',
          bold: false,
          italic: false,
          underline: false
        })
      }

      return this.globalStyles.linksStyle
    },
    ...mapState({
      globalStyles: state => state.editor.globalStyles
    }),
    paragraph () {
      if (!this.globalStyles.hasOwnProperty('paragraph')) {
        this.$set(this.globalStyles, 'paragraph', {
          font: {
            sm: 'Roboto',
            lg: 'Roboto'
          },
          fontSize: {
            sm: '16px',
            lg: '18px'
          },
          align: {
            sm: 'center',
            lg: 'center'
          },
          bold: {
            sm: false,
            lg: false
          },
          italic: {
            sm: false,
            lg: false
          },
          underline: {
            sm: false,
            lg: false
          },
          color: 'rgb(68, 71, 74)'
        })
      }

      return this.globalStyles.paragraph
    },
    headline () {
      if (!this.globalStyles.hasOwnProperty('headline')) {
        this.$set(this.globalStyles, 'headline', {
          font: {
            sm: 'Roboto',
            lg: 'Roboto'
          },
          fontSize: {
            sm: '36px',
            lg: '48px'
          },
          align: {
            sm: 'center',
            lg: 'center'
          },
          bold: {
            sm: false,
            lg: false
          },
          italic: {
            sm: false,
            lg: false
          },
          underline: {
            sm: false,
            lg: false
          },
          color: '#0C226C'
        })
      }

      return this.globalStyles.headline
    }
  },

  methods: {
    getPalette (obj, pollite) {
      if (!obj.hasOwnProperty(pollite)) {
        this.$set(obj, pollite, [])
      }
      return obj[pollite]
    },
    setShape (shape) {
      this.globalStyles.buttons.shape = shape
      VEvent.fire('editor-btn-shape', shape)
    }
  }
}
</script>

<style lang="scss">
.global-styles {
  .select-box {
    border-radius: 4px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
    text-align: center;
    font-size: 34px;
    width: 70px;
    height: 70px;
    display: flex !important;
    border: 1px solid #e2e5ec;
    align-items: center;
    justify-content: center;

    &.active {
      box-shadow: 0 0 0 2px #7CACFA !important;
    }
  }

  .card-header {
    font-size: 18px;
    font-weight: 600;
  }

  label {
    font-size: 15px;
  }

  .color-picker {
    padding: 0 !important;
    border: none !important;
  }

  .colors-palette {
    .vc-compact-colors {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
    }

    .vc-compact-color-item {
      width: 36px;
      height: 36px;
      border-radius: 2px;
      margin: 2px;

      &:hover {
        cursor: pointer;
      }
    }

    .vc-compact {
      box-shadow: none;
    }

    .vc-compact-dot {
      background: transparent;
      font: normal normal normal 14px/1 FontAwesome;
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &:before {
        content: "\f00c";
      }
    }
  }
}
</style>
