<template>
  <div>
    <highliter
      v-if="docked"
      v-model="section.name"
      :section="section"
      class="highlight-element"
      :class="{'element-hidden': !visability('promo')}"
      hide-copy
      hide-move
      hide-add
      @delete="$emit('delete')"
      @dublicate="$emit('dublicate')"
      @favorite="$emit('favorite', $el)"
      @flow="handleFlow"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
      @click.stop.native="sectionSettings('promo')"
    >
      <Template :section="section">
        <el-flex-wrapper-box
          :section="section.options.content.promo.subject"
          :custom-back-options="{openedItem: 'promo-mod', section}"
          @flow="handleFlow"
        >
          <a
            v-if="section.options.content.promo.closeIcon.enabled.lg"
            href="javascript:;"
            class="promo-close-icon absolute top-[12px] left-[14px] hover:opacity-80"
          >
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.853553 0.146447C0.658291 -0.0488156 0.341709 -0.0488156 0.146447 0.146447C-0.0488155 0.341709 -0.0488155 0.658291 0.146447 0.853554L3.29293 4.00004L0.146447 7.14652C-0.0488153 7.34178 -0.0488155 7.65837 0.146447 7.85363C0.341709 8.04889 0.658291 8.04889 0.853553 7.85363L4.00004 4.70714L7.14645 7.85355C7.34171 8.04882 7.65829 8.04882 7.85355 7.85355C8.04882 7.65829 8.04882 7.34171 7.85355 7.14645L4.70714 4.00004L7.85355 0.853629C8.04882 0.658367 8.04882 0.341784 7.85355 0.146522C7.65829 -0.04874 7.34171 -0.04874 7.14645 0.146522L4.00004 3.29293L0.853553 0.146447Z"
                :fill="section.options.content.promo.closeIcon.color.lg"
              />
            </svg>
          </a>
        </el-flex-wrapper-box>
      </Template>
    </highliter>
  </div>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import ElFlexWrapperBox from '@/components/builder/sections/utils/el-inline/ElFlexWrapperBox'

export default {
  name: 'FeaturedItems1',

  components: {ElFlexWrapperBox, Template, Highliter},

  mixins: [SectionMixin],

  props: {
    docked: {
      type: Boolean,
      defaut: false
    }
  },

  mounted () {
    if (!this.docked) {
      this.$store.commit('promoBar/ADD_PROMO_BAR', {
        section: this.section,
        onDelete: this.onDelete
      })
    }
  },

  methods: {
    onDelete () {
      this.$emit('delete')
    }
  }
}
</script>