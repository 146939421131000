<template>
  <div>
    <div class="flex items-center h-[160px] px-[55px]">
      <div
        class="media align-items-center"
      >
        <div
          class="media-aside align-self-start"
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_11430_11828)">
              <mask
                id="mask0_11430_11828"
                style="mask-type:luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="44"
                height="44"
              >
                <path
                  d="M0 3.8147e-06H44V44H0V3.8147e-06Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_11430_11828)">
                <path
                  d="M36.6517 3.24616C38.0764 3.24616 39.2305 4.40081 39.2305 5.82617C39.2305 7.24921 38.0764 8.40619 36.6517 8.40619C35.227 8.40619 34.0728 7.24921 34.0728 5.82617C34.0728 4.40081 35.227 3.24616 36.6517 3.24616Z"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32.7104 11.4294C32.7104 9.83633 33.6682 8.45652 35.0354 7.83605M38.268 7.83605C39.6351 8.45652 40.593 9.83633 40.593 11.4294"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M36.6517 12.8716C40.0158 12.8716 42.7622 10.1239 42.7622 6.75805C42.7622 3.3899 40.0158 0.64454 36.6517 0.64454C33.2851 0.64454 30.5411 3.3899 30.5411 6.75805C30.5411 10.1239 33.2851 12.8716 36.6517 12.8716Z"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M37.03 33.73C38.4547 33.73 39.6088 34.887 39.6088 36.31C39.6088 37.7354 38.4547 38.89 37.03 38.89C35.6077 38.89 34.4511 37.7354 34.4511 36.31C34.4511 34.887 35.6077 33.73 37.03 33.73Z"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M33.0887 41.9157C33.0887 40.3226 34.0466 38.9404 35.4138 38.3199M38.6486 38.3199C40.0158 38.9404 40.9713 40.3226 40.9713 41.9157"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M37.03 43.3555C40.3966 43.3555 43.1406 40.6101 43.1406 37.242C43.1406 33.8761 40.3966 31.1284 37.03 31.1284C33.6658 31.1284 30.9194 33.8761 30.9194 37.242C30.9194 40.6101 33.6658 43.3555 37.03 43.3555Z"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M31.9992 16.6375C31.9537 16.556 31.9082 16.4722 31.8603 16.3931C29.8753 13.013 26.2023 10.7419 22 10.7419C15.6882 10.7419 10.5737 15.8613 10.5737 22.1736C10.5737 28.4884 15.6882 33.6078 22 33.6078C28.3118 33.6078 33.4263 28.4884 33.4263 22.1736C33.4263 21.2322 33.3138 20.317 33.0983 19.4403"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M28.7859 31.3727V30.563C28.7859 27.9016 26.6525 27.4967 24.8422 27.0008C24.2029 26.8284 24.0353 26.0042 23.9035 25.7383M20.0965 25.7383C19.9647 26.0042 19.7971 26.8284 19.1578 27.0008C17.3475 27.4967 15.2141 27.9016 15.2141 30.563V31.3727"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M26.8583 19.8931V21.3783C26.8583 23.1223 25.2205 25.3119 23.2164 26.0545C22.3377 26.3827 21.6624 26.3827 20.7836 26.0545C18.7794 25.3119 17.1417 23.1223 17.1417 21.3783V19.8931"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.3221 27.2284L21.3416 28.9987C21.854 29.3317 22.146 29.3317 22.6585 28.9987L25.6779 27.2284"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M19.9935 14.8767C20.6256 14.5773 21.3128 14.4239 22.0001 14.412C24.4304 14.4527 26.8584 16.2829 26.8584 19.8931C24.8925 19.7541 25.1846 18.2521 23.8269 18.6019C22.7782 18.8726 22.3904 19.0091 22.0001 19.0091C21.6097 19.0091 21.2218 18.8726 20.1731 18.6019C18.8154 18.2521 19.1075 19.7541 17.1417 19.8931C17.1417 18.5875 17.4577 17.5142 17.9797 16.6758"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.2896 11.4582L13.9211 14.091"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30.0789 14.0886L32.7104 11.4295"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.2896 32.8892L13.9211 30.2588"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M30.0789 30.2611L32.7104 32.9202"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.61176 22.1736H10.5737"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M36.3882 22.1736H33.4263"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 36.5712V33.6079"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 7.77859V10.7419"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.61177 22.1736C7.61177 20.3507 6.13192 18.8725 4.30979 18.8725C2.48757 18.8725 1.00781 20.3507 1.00781 22.1736C1.00781 23.999 2.48757 25.4772 4.30979 25.4772C6.13192 25.4772 7.61177 23.999 7.61177 22.1736Z"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M36.3882 22.1736C36.3882 20.3507 37.8681 18.8725 39.6902 18.8725C41.5124 18.8725 42.9922 20.3507 42.9922 22.1736C42.9922 23.999 41.5124 25.4772 39.6902 25.4772C37.8681 25.4772 36.3882 23.999 36.3882 22.1736Z"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 36.5711C23.8221 36.5711 25.302 38.0493 25.302 39.8723C25.302 41.6977 23.8221 43.1758 22 43.1758C20.1779 43.1758 18.698 41.6977 18.698 39.8723C18.698 38.0493 20.1779 36.5711 22 36.5711Z"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 7.77863C23.8221 7.77863 25.302 6.3005 25.302 4.4751C25.302 2.65202 23.8221 1.17399 22 1.17399C20.1779 1.17399 18.698 2.65202 18.698 4.4751C18.698 6.3005 20.1779 7.77863 22 7.77863Z"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.96999 33.7013C8.39234 33.7013 9.54888 34.856 9.54888 36.2813C9.54888 37.7067 8.39234 38.8613 6.96999 38.8613C5.54531 38.8613 4.39117 37.7067 4.39117 36.2813C4.39117 34.856 5.54531 33.7013 6.96999 33.7013Z"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.02872 41.8846C3.02872 40.2915 3.98417 38.9117 5.35135 38.2912M8.58629 38.2912C9.95347 38.9117 10.9113 40.2915 10.9113 41.8846"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.96999 43.3267C10.3342 43.3267 13.0806 40.579 13.0806 37.2131C13.0806 33.845 10.3342 31.0996 6.96999 31.0996C3.60339 31.0996 0.859406 33.845 0.859406 37.2131C0.859406 40.579 3.60339 43.3267 6.96999 43.3267Z"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.34831 3.24616C8.77298 3.24616 9.92721 4.40081 9.92721 5.82617C9.92721 7.24921 8.77298 8.40619 7.34831 8.40619C5.92364 8.40619 4.7695 7.24921 4.7695 5.82617C4.7695 4.40081 5.92364 3.24616 7.34831 3.24616Z"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.40704 11.4294C3.40704 9.83633 4.3649 8.45652 5.73208 7.83605M8.96462 7.83605C10.3318 8.45652 11.2897 9.83633 11.2897 11.4294"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.34835 12.8716C10.715 12.8716 13.4589 10.1239 13.4589 6.75805C13.4589 3.3899 10.715 0.64454 7.34835 0.64454C3.98424 0.64454 1.23776 3.3899 1.23776 6.75805C1.23776 10.1239 3.98424 12.8716 7.34835 12.8716Z"
                  stroke="#3D82EA"
                  stroke-width="1.37695"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_11430_11828">
                <rect
                  width="44"
                  height="44"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          class="media-body"
        >
          <h4
            class="font-weight-bold mb-1"
          >
            Community Module
          </h4>
        </div>
      </div>
    </div>

    <div ref="confetti" />

    <div v-if="!loading">
      <div
        v-if="!activated"
        class="bg-white px-[55px] py-[50px]"
      >
        <h2 class="text-center text-[34px] font-bold mb-[28px]">
          Imagine having your very own full-fledged <br> social network for your HUB!
        </h2>

        <p class="text-center text-[20px] mb-5">
          That's exactly what the ESTAGE Community module gives you, <br> and that's just the beginning.
        </p>

        <p class="text-center text-[17px] mb-[28px]">
          Activate ESTAGE Community module and take your HUB to a whole new level:
        </p>

        <div class="flex justify-center w-full mb-[28px]">
          <div class="bg-[#F9FBFF] border w-full max-w-[700px] rounded-[5px] px-[33px] py-[27px]">
            <div
              v-for="(feature, index) in featuresList"
              :key="index"
              class="flex items-center gap-3 text-[17px]"
              :class="[index + 1 !== featuresList.length && 'border-b pb-[17px]', index != 0 && 'mt-[17px]']"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_11430_11713)">
                  <path
                    d="M5.36163 12.2307C5.22734 12.365 5.04604 12.4388 4.85468 12.4388C4.66331 12.4388 4.48201 12.365 4.34772 12.2307L0.315587 8.19184C-0.104077 7.77217 -0.104077 7.094 0.315587 6.67433L0.822542 6.16738C1.24221 5.74771 1.92038 5.74771 2.34005 6.16738L4.86139 8.68872L11.6633 1.88344C12.083 1.46378 12.7612 1.46378 13.1808 1.88344L13.6878 2.3904C14.1074 2.81006 14.1074 3.48824 13.6878 3.9079L5.36163 12.2307Z"
                    fill="#62AD62"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_11430_11713">
                    <rect
                      width="14"
                      height="14"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>

              {{ feature }}
            </div>
          </div>
        </div>

        <p class="text-center text-[17px] mb-[28px]">
          The best part is activating your HUB's social network couldn't be any easier- click one button, <br> and the system will do the rest automagically!
        </p>

        <div class="flex w-full justify-center">
          <button
            class="h-[63px] text-[17px] px-[34px] bg-[#3D82EA] hover:opacity-80 flex items-center gap-2 text-white rounded"
            :disabled="activating"
            @click="setup"
          >
            <b-spinner
              v-if="activating"
              small
              variant="light"
              class="ml-1"
            />

            ACTIVATE MY COMMUNITY & SOCIAL NETWORK
          </button>
        </div>
      </div>
      <div
        v-else
        class="bg-white px-[55px] py-[50px] max-w-[850px]"
      >
        <h4 class="text-[20px] font-bold mb-[21px]">
          Your Community and Social Network Is Active!
        </h4>

        <button
          class="h-[38px] text-[15px] px-[18px] bg-[#3D82EA] hover:opacity-80 flex items-center gap-2 text-white rounded mb-[34px]"
          :disabled="activating"
          @click="getAccess"
        >
          Access My Community

          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.6 9.3C17.6 13.884 13.884 17.6 9.3 17.6C4.71604 17.6 1 13.884 1 9.3C1 4.71604 4.71604 1 9.3 1C13.884 1 17.6 4.71604 17.6 9.3ZM9.3 16.3C13.166 16.3 16.3 13.166 16.3 9.3C16.3 5.43401 13.166 2.3 9.3 2.3C5.43401 2.3 2.3 5.43401 2.3 9.3C2.3 13.166 5.43401 16.3 9.3 16.3Z"
              fill="white"
            />
            <path
              d="M12.8999 9.53195V8.26795H10.0359V5.14795H8.64392V8.26795H5.76392V9.53195H8.64392V12.7799H10.0359V9.53195H12.8999Z"
              fill="white"
            />
          </svg>
        </button>

        <p class="text-[17px] mb-[21px]">
          If you haven't yet, please set up a custom sub-domain for your community by following the easy instructions below:
        </p>

        <h4 class="text-[20px] font-bold mb-[21px]">
          Your Custom Sub-Domain Setup
        </h4>

        <p class="text-[17px] mb-[25px]">
          We have developed a proprietary technology in collaboration with Cloudflare to take your HUB or project security and performance to the next level. Please follow the steps outlined below to create and connect your private token. This is VERY IMPORTANT.
        </p>

        <p class="text-[17px] mb-[25px]">
          <strong>Please note:</strong> you must have your project's custom domain set up first.
        </p>

        <p class="text-[17px] mb-[30px]">
          Once your project / HUB is set up on a custom domain, please follow the easy steps below to set up a custom sub-domain for your community / social network.
        </p>

        <hr class="mb-[40px]">
        
        <div class="shadow-sm h-[62px] bg-[#F9F9FB] px-[26px] py-[19px] border rounded-xl text-[20px] flex items-center leading-[0px] font-bold mb-[32px]">
          <span class="text-primary mr-2">STEP 1:</span> Login to your Cloudfare account.
        </div>

        <div class="shadow-sm border rounded-xl mb-[32px] overflow-hidden">
          <div class="h-[62px] bg-[#F9F9FB] px-[26px] py-[19px] text-[20px] flex items-center leading-[0px] font-bold">
            <span class="text-primary mr-2">STEP 2:</span> Click on the domain associated with your project.
          </div>

          <div class="px-[37px] py-[12px]">
            <img
              class="img-fluid"
              src="@builder/assets/images/community-1.png"
            >
          </div>
        </div>

        <div class="shadow-sm border rounded-xl mb-[32px] overflow-hidden">
          <div class="h-[62px] bg-[#F9F9FB] px-[26px] py-[19px] text-[20px] flex items-center leading-[0px] font-bold">
            <span class="text-primary mr-2">STEP 3:</span> Click on “DNS Records”
          </div>

          <div class="px-[37px] py-[12px]">
            <img
              class="img-fluid"
              src="@builder/assets/images/community-3.png"
            >
          </div>
        </div>

        <div class="shadow-sm border rounded-xl mb-[70px] overflow-hidden">
          <div class="min-h-[62px] bg-[#F9F9FB] px-[26px] py-[19px] text-[20px] flex items-start font-bold">
            <span class="text-primary whitespace-nowrap mr-2">STEP 4:</span> Click on “Add record” button and add a new “A” record with the following
            parameters: Type: A
          </div>

          <div class="px-[37px] py-[20px]">
            <p class="text-[17px] mb-[25px]">
              <strong>Name:</strong> This is whatever you want your sub-domain to be. (For example, if you put “community”, your sub-domain will be “community.YourDomain.com”). It is up to you what you want this to be.
            </p>

            <p class="text-[17px] mb-[25px]">
              <strong>IPv4 Address:</strong> <span class="text-primary">170.249.222.115</span>
            </p>

            <p class="text-[17px] mb-[25px]">
              Then, click on the blue “Save” button to save the record.
            </p>

            <img
              class="img-fluid"
              src="@builder/assets/images/community-2.png"
            >
          </div>
        </div>

        <p class="text-[17px]">
          That’s all. Congrats your community custom sub-domain for your HUB is now set up!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {confetti} from 'dom-confetti'

export default {
  name: 'Community',
  data () {
    return {
      activated: false,
      loading: true,
      activating: false,
      featuresList: [
        'Have your very own full-fledged social network for your HUB',
        'Turn your HUB into a real destination',
        'Create and easily manage multiple groups',
        'Easily create, manage, and deliver courses',
        'Create and manage your own events calendar',
        'Full admin management over everything'
      ]
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    })

    this.checkActivation()
  },
  methods: {
    checkActivation () {
      axios.get(`https://estagesocial.com/api/${this.project.id}/admin/check-activation`)
        .then(({data}) => {
          if (data.created) {
            this.activated = data.created
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getAccess () {
      window.open(`https://${this.project.subdomain}.estagesocial.com`)
    },
    setup () {
      this.activating = true
      axios.get(`https://estagesocial.com/api/${this.project.id}/admin/init`)
        .then(() => {
          this.activated = true
          window.scrollTo({top: 0, behavior: 'smooth'})

          setTimeout(() => {
            confetti(this.$refs.confetti, {
              angle: '151',
              spread: '344',
              startVelocity: 40,
              elementCount: '185',
              dragFriction: 0.12,
              duration: 3000,
              stagger: 3,
              width: '13px',
              height: '11px',
              perspective: '949px'
            })
          }, 400)
          // if (data.created) {
          //   window.open(`https://${this.project.subdomain}.estagesocial.com/login`)
          // } else {
          //   window.open(`https://${this.project.subdomain}.estagesocial.com`)
          // }
        })
        .finally(() => {
          this.activating = false
        })
    }
  }
}
</script>