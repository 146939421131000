import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Navbar'
export const group = 'navbar'
export const defaultOptions = {
  'customize': {
    'style': {
      'navbarScroller': {
        'type': 'navbar-scroller-customs',
        'scrollType': null
      }
    },
    'display': true
  },
  'content': {
    'logo': {
      'type': 'logo-mod',
      'display': true,
      'fontFamily': null,
      'fontWeight': 'bold',
      'fontSize': '24px',
      'color': '#0C226C',
      'text': 'AppStarter'
    },
    'menuItems': {
      'type': 'menu-items-mod-v2',
      'display': true,
      'bringToFront': {
        'lg': true
      }
    },
    'subject': {
      'uid': 'WKtPEOYr2',
      'sid': 'lldx0uuu--QGFdhwqt9X',
      'name': 'Flex Wrapper',
      'hideFromSections': false,
      'component': 'ElFlexWrapperV2',
      'group': 'navbar',
      'layout': null,
      'specialCols': {
        'navigation': {
          'special': true,
          'sid': 'lldx0uuu--jl7GzKkE_t',
          'placement': 'bottom',
          'hamburgerIcon_open': 'fa fa-bars',
          'hamburgerIcon_close': 'fa fa-times',
          'hamburgerIcon_color': '#656565',
          'showOn': 'lg',
          'name': 'Nav Column',
          'elements': [
            {
              'display': true,
              'name': 'Flex Wrapper',
              'hideFromSections': false,
              'component': 'ElFlexWrapperV2',
              'group': '',
              'layout': null,
              'options': {
                'content': {
                  'display': true,
                  'cols': [
                    {
                      'display': true,
                      'name': 'Flex Column',
                      'elements': [
                        {
                          'uid': 'BlbxyqYc0',
                          'display': true,
                          'name': 'Menu Links',
                          'sid': 'lldx0uuu--itkazl6qzh',
                          'hideFromSections': false,
                          'component': 'ElMenuLinksV4',
                          'group': 'Navigation',
                          'layout': null,
                          'options': {
                            'content': {
                              'menu': {
                                'display': true,
                                'type': 'menu-links-mod-v4',
                                'links': [
                                  {
                                    'id': '2lgcfYKlUS',
                                    'href': '#',
                                    'text': 'About',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'kE_0CEF0hZ',
                                    'href': '#',
                                    'text': 'Features',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'wJGQ-hWnL1',
                                    'href': '#',
                                    'text': 'Buy ',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'QlHAT-G7Lw',
                                    'href': '#',
                                    'text': 'Contact',
                                    'customLink': false,
                                    'blank': false,
                                    'children': []
                                  }
                                ]
                              }
                            }
                          },
                          'editor': 'project',
                          'temp_id': 'KL8FXUhHuN'
                        }
                      ],
                      'sortIndex': {
                        'lg': 0,
                        'md': 0,
                        'sm': 0
                      },
                      'uid': 'e_JseD2BbP',
                      'sid': 'lldx0uuu--aCiKUzL_ZP',
                      'highlight': false,
                      'animations': {
                        'animation': null,
                        'once': true,
                        'offset': 200,
                        'delay': 50
                      },
                      '_uid': 'i-aiKR4jaJ'
                    },
                    {
                      'display': true,
                      'name': 'Flex Column',
                      'elements': [
                        {
                          'uid': 'oCKQ7xxGP',
                          'display': true,
                          'name': 'Button',
                          'sid': 'lldx0uuu--onYPyiJAr4',
                          'hideFromSections': false,
                          'component': 'ElButtonV2',
                          'group': 'Media',
                          'layout': null,
                          'options': {
                            'content': {
                              'button': {
                                'pressetID': 0,
                                'display': true,
                                'type': 'button-mod-v2',
                                'text': 'BUY NOW',
                                'buttonType': 'button',
                                'icon': {
                                  'lottie': {
                                    'jsonPath': '',
                                    'loop': true,
                                    'autoplay': true
                                  }
                                },
                                'hoverAnimation': {},
                                'animations': {
                                  'animation': null,
                                  'once': true,
                                  'offset': 200,
                                  'delay': 50
                                }
                              }
                            },
                            'customize': null
                          },
                          'editor': 'project',
                          'temp_id': '-BOgEagQ0x'
                        }
                      ],
                      'sortIndex': {
                        'lg': 1,
                        'md': 1,
                        'sm': 1
                      },
                      'uid': 'qrV8fi9Oi7',
                      'sid': 'lldx0uuu--EvbfwESx06',
                      'highlight': false,
                      'animations': {
                        'animation': null,
                        'once': true,
                        'offset': 200,
                        'delay': 50
                      },
                      '_uid': 'KAwaBaHgAp'
                    }
                  ],
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              },
              'uid': '68Twket09',
              'sid': 'lldx0uuu--3ZwepSDM_E',
              'temp_id': 'kb5eyU6NYu'
            }
          ],
          'highlight': false,
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          },
          'sortIndex': {
            'lg': 1,
            'md': 1,
            'sm': 1
          },
          'uid': 'RfILePtQN',
          'navbarBackground': {
            'background': '',
            'gradient': {
              'position': '',
              'color': ''
            }
          },
          '_uid': 'Id-dGT2i_w'
        }
      },
      'options': {
        'content': {
          'display': true,
          'cols': [
            {
              'display': true,
              'name': 'Flex Column',
              'elements': [
                {
                  'uid': 'BrEOqO8cO',
                  'display': true,
                  'name': 'Subheadline',
                  'sid': 'global--Sf3jnz8IT',
                  'hideFromSections': false,
                  'component': 'ElSubheadlineV2',
                  'group': 'Text',
                  'ignoreGroup': false,
                  'layout': null,
                  'options': {
                    'content': {
                      'description': {
                        'type': 'tiptap-mod-v2',
                        'display': true,
                        'tag': 'h4',
                        'resolutionStyle': {
                          'sm': {},
                          'md': {},
                          'lg': {}
                        },
                        'text': '<p>Subtlement</p>',
                        'animations': {
                          'animation': null,
                          'once': true,
                          'offset': 200,
                          'delay': 50
                        }
                      }
                    },
                    'customize': null
                  },
                  'editor': 'project'
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              },
              'sortIndex': {
                'lg': 0,
                'md': 0,
                'sm': 0
              },
              'sid': 'lldx0uuu--BGqjfvObVq',
              'uid': 'UT79KqyE9j',
              'highlight': false,
              '_uid': 'u318BEWKXV'
            },
            {
              'special': true,
              'key': 'navigation',
              'mod': 'navigation-flex-col-mod-v2',
              'name': 'Nav Column',
              'sid': 'Uz96GLEcGy',
              'sortIndex': {
                'lg': 1,
                'md': 1,
                'sm': 1
              },
              'highlight': false
            }
          ],
          'animations': {
            'animation': 'fade-up',
            'once': true,
            'offset': 200,
            'delay': '90',
            'duration': '900'
          }
        }
      },
      'temp_id': '23MJlRTY6G'
    }
  }
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('NavbarV3', options)

    this.name = name
    this.group = group
    this.sid = ['global']
    this.sids = [
      'global',
      'lldx0uuu--QGFdhwqt9X',
      'lldx0uuu--BGqjfvObVq',
      'global--Sf3jnz8IT',
      'lldx0uuu--jl7GzKkE_t',
      'lldx0uuu--3ZwepSDM_E',
      'lldx0uuu--aCiKUzL_ZP',
      'lldx0uuu--itkazl6qzh',
      'lldx0uuu--EvbfwESx06',
      'lldx0uuu--onYPyiJAr4'
    ]

    this.css = [
      [
        '.--lldx0uuu--QGFdhwqt9X--container { padding-right: 0px; margin: 20px; border-radius: 3px; --border-bottom-right-radius: 3px; --border-top-left-radius: 3px; --border-top-right-radius: 3px; --border-bottom-left-radius: 3px; box-shadow: rgba(0, 0, 0, 0.05) -1px 0px 22px 5px; }',
        '.--lldx0uuu--BGqjfvObVq--flex-col-padding { padding: 0px; }',
        '.--lldx0uuu--jl7GzKkE_t--flex-col-padding { padding: 0px; }',
        '.--lldx0uuu--BGqjfvObVq--flex-col-el { width: 20%; background-color: rgba(255, 255, 255, 0); }',
        '.--lldx0uuu--jl7GzKkE_t--flex-col-el { width: 100%; --width: 100%; }',
        '.--lldx0uuu--jl7GzKkE_t--flex-col > div { align-items: center; justify-content: center; }',
        '.--lldx0uuu--aCiKUzL_ZP--flex { width: 100%; --width: 100%; }',
        '.--lldx0uuu--aCiKUzL_ZP--flex-col-el { width: 100%; --width: 100%; }',
        '.--lldx0uuu--EvbfwESx06--flex-col-el { width: 20%; --width: 20%; }',
        '.--lldx0uuu--3ZwepSDM_E--flex { width: 100%; --width: 100%; align-items: center; padding: 0px; }',
        '.--lldx0uuu--QGFdhwqt9X--flex { align-items: center; max-width: 1200px; --width: 1200px; }',
        '.--lldx0uuu--itkazl6qzh--wrapper { padding: 10px; justify-content: end; gap: 56px; font-family: Barlow; align-items: end; font-weight: 300; }',
        '.--lldx0uuu--onYPyiJAr4--1 { margin: 20px; background-color: rgba(0, 0, 0, 0); font-family: Barlow; font-size: 14px; color: rgb(0, 0, 0); font-weight: 400; border-width: 1px; border-color: rgb(0, 0, 0); border-radius: 75px; padding-bottom: 7px; padding-top: 7px; }',
        '.--lldx0uuu--itkazl6qzh--link { font-size: 14px; color: rgb(0, 0, 0); text-transform: uppercase; letter-spacing: 1px; }',
        '.--lldx0uuu--itkazl6qzh--link:hover { color: rgb(202, 182, 161); font-weight: 400; }',
        '.--lldx0uuu--aCiKUzL_ZP--flex-col-padding { padding: 0px; }',
        '.--lldx0uuu--EvbfwESx06--flex-col-padding { padding: 0px; }',
        '.--lldx0uuu--QGFdhwqt9X--flex.flex-wrapper { padding-bottom: 0px; padding-top: 0px; }',
        '.--lldx0uuu--QGFdhwqt9X--bg { background-color: rgba(255, 255, 255, 0.87); }',
        '.--global--Sf3jnz8IT-text { padding: 0px 0px 0px 20px; }',
        '.--global--Sf3jnz8IT-text { padding: 0px 0px 0px 20px; }',
        '.--global--Sf3jnz8IT-text div { color: rgb(0, 0, 0); text-align: left; font-size: 22px; font-family: "Playfair Display"; }',
        '.--global--Sf3jnz8IT-text div { color: rgb(0, 0, 0); text-align: left; font-size: 22px; font-family: "Playfair Display"; }',
        '.--lldx0uuu--onYPyiJAr4--1 .main-text { justify-content: center; --text-wrap: nowrap; }',
        '.--lldx0uuu--onYPyiJAr4--2 { justify-content: end; }',
        '.--lldx0uuu--onYPyiJAr4--1:hover { background-color: rgb(0, 0, 0); color: rgb(255, 255, 255); }'
      ],
      [
        '.--lldx0uuu--aCiKUzL_ZP--flex { width: 100%; --width: 100%; }',
        '.--lldx0uuu--3ZwepSDM_E--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lldx0uuu--EvbfwESx06--flex-col-el { width: 100%; --width: 100%; }',
        '.--lldx0uuu--itkazl6qzh--wrapper { flex-direction: column; justify-content: start; align-items: start; gap: 22px; padding-left: 20px; }',
        '.--lldx0uuu--jl7GzKkE_t--flex-col-el { background-color: rgb(250, 250, 250); margin-right: 20px; margin-left: 20px; border-radius: 4px; --border-bottom-right-radius: 4px; --border-top-left-radius: 4px; --border-top-right-radius: 4px; --border-bottom-left-radius: 4px; width: 3600px; --width: 3600px; }',
        '.--lldx0uuu--jl7GzKkE_t--flex-col-padding { padding-top: 20px; padding-bottom: 20px; }',
        '.--lldx0uuu--QGFdhwqt9X--close-icon { color: rgb(0, 0, 0); }',
        '.--lldx0uuu--QGFdhwqt9X--icon { font-size: 20px; translate: -13px; }',
        '.--lldx0uuu--QGFdhwqt9X--open-icon { color: rgb(0, 0, 0); }',
        '.--lldx0uuu--QGFdhwqt9X--flex.flex-wrapper { padding-bottom: 25px; padding-top: 25px; justify-content: start; }',
        '.--global--Sf3jnz8IT-text div { font-size: 20px; }',
        '.--global--Sf3jnz8IT-text div { font-size: 20px; }',
        '.--lldx0uuu--jl7GzKkE_t--flex-col { text-align: end; justify-content: end; }',
        '.--lldx0uuu--jl7GzKkE_t--flex-col > div { display: contents; --display: contents; }',
        '.--global--Sf3jnz8IT-text { padding-left: 10px; }',
        '.--global--Sf3jnz8IT-text { padding-left: 10px; }',
        '.--lldx0uuu--onYPyiJAr4--1 .main-text { justify-content: center; }',
        '.--lldx0uuu--onYPyiJAr4--2 { justify-content: start; }'
      ],
      [
        '.--lldx0uuu--aCiKUzL_ZP--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lldx0uuu--3ZwepSDM_E--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lldx0uuu--BGqjfvObVq--flex-col-el { width: 50%; --width: 50%; }',
        '.--lldx0uuu--EvbfwESx06--flex-col-el { width: 100%; --width: 100%; }',
        '.--lldx0uuu--itkazl6qzh--wrapper { gap: 49px; flex-direction: column; justify-content: center; align-items: center; }',
        '.--lldx0uuu--jl7GzKkE_t--flex-col-padding { padding-top: 20px; padding-bottom: 20px; }',
        '.--lldx0uuu--onYPyiJAr4--1 { width: 100%; }',
        '.--lldx0uuu--jl7GzKkE_t--flex-col-el { width: 100%; --width: 100%; }',
        '.--lldx0uuu--BGqjfvObVq--flex-col-padding { padding-left: 0px; }',
        '.--global--Sf3jnz8IT-text div { font-size: 18px; }',
        '.--global--Sf3jnz8IT-text div { font-size: 18px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
