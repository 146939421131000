<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('badge')"
  >
    <Template
      :class="{'element-hidden': !visability('badge')}"
      :section="section"
    >
      <editable
        v-model="badge.text"
        class="d-block w-100"
      />
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'

export default {
  name: 'ElBadge',

  components: { Template, Highliter },

  mixins: [SectionMixin],

  computed: {
    badge () {
      return this.section.options.content.badge
    }
  }
}
</script>
