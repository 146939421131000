export default {
  data () {
    return {
      _events: {}
    }
  },
  methods: {
    fire (name, params = {}) {
      window.dispatchEvent(new CustomEvent(name, {detail: params}))
    },
    listen (name, callback) {
      if (this._events[name]) return

      this._events[name] = callback
      window.addEventListener(name, callback)
    }
  },
  beforeDestroy () {
    for (const [key, value] of Object.entries(this._events)) {
      window.removeEventListener(key, value)
    }

    this._events = {}
  }
}