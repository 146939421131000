<template>
  <div>
    <b-form-group label="Font family">
      <v-select
        v-model="familyMutated"
        :options="fonts"
        class="custom-select mb-[10px]"
        left
        placeholder="Global Style"
        @option:selected="onSelect"
      >
        <template #selected-option="{label}">
          <div
            :style="{fontFamily: label === '' ? 'Proxima Nova' : label}"
            v-text="label"
          />
        </template>

        <template #option="{label}">
          <div
            :style="{fontFamily: label}"
            v-text="label || 'Global Style'"
          />
        </template>
      </v-select>

      <div class="w-[77px]">
        <b-link
          :to="{name:'settings.fonts'}"
          class="text-decoration-none w-[77px] mb-[12px]"
        >
          <span
            class="flex w-[77px] relative text-[#333333] text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
          >
            Font Settings
            <span class="absolute w-full h-[1px] bg-[#333333] left-0 bottom-0 translate-y-[-1px]" />
          </span>
        </b-link>
      </div>
    </b-form-group>

    <b-form-group
      v-if="weightEnable"
      label="Font Weight"
    >
      <v-select
        v-model="weightMutated"
        :options="fontWeightOptions"
        :reduce="item => item.value"
        :searchable="false"
        class="custom-select"
        left
        placeholder="Global Style"
      />
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapState} from 'vuex'
import GlobalStyleTextMixin from '@/components/mixins/GlobalStyleTextMixin'

export default {
  name: 'FontFamilyAndWeightControl',
  mixins: [GlobalStyleTextMixin],
  props: {
    family: {
      required: true
    },
    weight: {
      required: false
    },
    globalStyleOptionEnable: {
      type: Boolean,
      default: true
    },
    globalFamily: {
      default: null
    },
    weightEnable: {
      type: Boolean,
      default: true
    },
    textType: {
      type: String,
      default: 'paragraph'
    }
  },
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    ...mapState('font', {
      allCustomFonts: state => state.allCustomFonts
    }),
    addedGoogleFonts () {
      return this.globalStyles.font.addedGoogleFonts
    },
    fonts () {
      const fonts = []

      if (this.globalStyleOptionEnable) {
        fonts.push('')
      }

      fonts.push(...this.allCustomFonts.map(font => font.font_name))
      fonts.push(...this.addedGoogleFonts.map(font => font.family))

      return _.uniq(fonts).sort()
    },
    familyMutated: {
      get () {
        return this.family
      },
      set (val) {
        this.$emit('family', val)
      }
    },
    weightMutated: {
      get () {
        return this.weight
      },
      set (val) {
        this.$emit('weight', val)
      }
    },
    fontWeightOptions () {
      let options = []
      const family = this.familyMutated || this.getGlobalTextStyles[this.textType]

      let font = this.allCustomFonts.find(group => group.font_name === family)

      if (!font) {
        font = this.addedGoogleFonts.find(font => font.family === family)
      }

      if (this.globalStyleOptionEnable) {
        options.push({label: 'Global Style', value: null})
      }

      if (!font) return options

      options.push(..._.sortBy((font.fonts || font.variants)
        .map(variant => {
          return {
            label: variant.font_weight || variant,
            value: variant.font_weight || variant
          }
        }), (e) => parseInt(e.value)))

      return options
    }
  },
  methods: {
    onSelect () {
      if (!this.weightEnable) return
      this.weightMutated = 400
    }
  }
}
</script>