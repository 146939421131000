import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Featured Post'
export const group = 'Dynamic Blog Home'
export const beta = false
import { defaultOptions } from './ElFeaturedPost'

export const icon = ''

export default class ElPostCategories extends SectionProvider {
  constructor (options = {}) {
    super('ElFeaturedPost', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
