<template>
  <div class="mb-4">
    <background-type
      :config="hoverConfig"
      class="!mb-[12px]"
    />

    <b-form-group v-if="!hoverConfig.backgroundType">
      <div class="flex items-center gap-[14px]">
        <color-picker
          :color="backgroundColor"
          @input="event => backgroundColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />

        <label class="mb-0 pb-0">Color</label>
      </div>
    </b-form-group>

    <div v-if="hoverConfig.backgroundType">
      <gradient-background :config="hoverConfig" />
    </div>

    <b-form-group>
      <div class="flex items-center gap-[14px]">
        <text-color
          v-model="textColor"
          :color="textColor"
        />

        <label class="mb-0 pb-0">Text color</label>
      </div>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center gap-[14px]">
        <text-color
          v-model="subTextColor"
          :color="subTextColor"
        />

        <label class="mb-0 pb-0">Subtext color</label>
      </div>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center gap-[14px]">
        <color-picker
          :color="iconColor"
          @input="event => iconColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />

        <label class="mb-0 pb-0">Icon Color</label>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'
import ModControlMixin from '@/components/mixins/ModControlMixin'
import BackgroundType from '@/components/editor/controls/BackgroundType'
import TextColor from '@/components/editor/controls/TextColor'
import GradientBackground from '@/components/editor/controls/GradientBackground'
import ColorPicker from '@/components/editor/utils/ColorPicker'
import ModControlHoverMixin from '@/components/mixins/ModControlHoverMixin'

export default {
  name: 'ButtonHoverBackground',
  components: {ColorPicker, GradientBackground, TextColor, BackgroundType},
  mixins: [ModControlMixin, ModControlHoverMixin],
  computed: {
    backgroundColor: {
      get () {
        const color = _.get(this.hoverConfig, 'resolutionStyle.lg.backgroundColor', this.config.resolutionStyle['lg'].backgroundColor)
        return color !== 'colored' ? color : this.globalStyles.colors.buttons
      },
      set (val) {
        this.$set(this.config, 'hover', {..._.set(this.hoverConfig, ['resolutionStyle', 'lg', 'backgroundColor'], val)})
      }
    },
    iconColor: {
      get () {
        return _.get(this.hoverConfig, 'iconColor', this.config.iconColor)
      },
      set (val) {
        this.$set(this.config, 'hover', {..._.set(this.hoverConfig, ['iconColor'], val)})
      }
    },
    textColor: {
      get () {
        return _.get(this.hoverConfig, 'color', this.config.resolutionStyle['lg'].color)
      },
      set (val) {
        this.$set(this.config, 'hover', {..._.set(this.hoverConfig, ['color'], val)})
      }
    },
    subTextColor: {
      get () {
        return _.get(this.hoverConfig, 'subtextColor', this.config.resolutionStyle['lg'].subtextColor)
      },
      set (val) {
        this.$set(this.config, 'hover', {..._.set(this.hoverConfig, ['subtextColor'], val)})
      }
    }
  }
}
</script>