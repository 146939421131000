<template>
  <b-row no-gutters>
    <b-col
      md="3"
      class="py-5 border-right px-5"
    >
      <global-styles-render v-if="project" />

      <b-form-group>
        <b-checkbox v-model="settings.hero_banner.backgroundImageEnabled">
          Background-Image
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <vue-select
          v-model="settings.hero_banner.backgroundSize"
          class="custom-select"
          :options="sizeOptions"
        >
          <template v-slot:selected-option="option">
            <div
              class="text-capitalize"
              v-text="option.label"
            />
          </template>

          <template v-slot:option="option">
            <div
              class="text-capitalize"
              v-text="option.label"
            />
          </template>
        </vue-select>
      </b-form-group>

      <b-form-group>
        <b-row>
          <b-col cols="10">
            <image-upload
              class="position-relative"
              :image="settings.hero_banner.image"
            >
              <div class="absolute-center-x">
                <div class="d-flex align-items-center justify-content-center flex-nowrap">
                  <div class="text-nowrap mr-2">
                    Upload Image
                  </div>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.10627 0.375L11.1365 4.40813C11.2475 4.53033 11.3071 4.69054 11.303 4.85552C11.299 5.0205 11.2316 5.17759 11.1148 5.29422C10.9981 5.41084 10.8409 5.47803 10.6759 5.48187C10.5109 5.48571 10.3508 5.42589 10.2287 5.31483L7.74625 2.8301V10.4188C7.74625 10.5888 7.67871 10.7519 7.55847 10.8721C7.43824 10.9924 7.27516 11.0599 7.10513 11.0599C6.93509 11.0599 6.77201 10.9924 6.65178 10.8721C6.53154 10.7519 6.464 10.5888 6.464 10.4188V2.83124L3.98155 5.31483C3.92197 5.37436 3.85126 5.42158 3.77344 5.45379C3.69562 5.48599 3.61222 5.50255 3.52799 5.50253C3.44377 5.5025 3.36038 5.48589 3.28258 5.45363C3.20478 5.42138 3.1341 5.37411 3.07456 5.31454C3.01503 5.25497 2.96781 5.18426 2.9356 5.10644C2.9034 5.02861 2.88683 4.94521 2.88686 4.86099C2.88689 4.77677 2.9035 4.69338 2.93576 4.61558C2.96801 4.53778 3.01527 4.46709 3.07485 4.40756L7.10627 0.375ZM13.3386 12.3427C13.7039 12.3427 13.9997 12.63 13.9997 12.9839C13.9997 13.3378 13.7039 13.625 13.3386 13.625H0.985051C0.61861 13.625 0.322266 13.3378 0.322266 12.9839C0.322266 12.63 0.61861 12.3427 0.985051 12.3427H13.3386Z"
                      fill="#6E747A"
                    />
                  </svg>
                </div>
              </div>
            </image-upload>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <color-picker
          :color="settings.hero_banner.backgroundColor"
          @input="event => settings.hero_banner.backgroundColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </b-form-group>
    </b-col>

    <b-col class="d-flex justify-content-center py-5">
      <div class="w-100 px-5">
        <div
          class="mb-4 px-3 overflow-hidden position-relative"
          :style="heroStyles"
        >
          <div
            class="overlay-inner"
            :style="{backgroundColor: settings.hero_banner.backgroundColor}"
          >
            <div class="d-flex align-items-center w-100 h-100 px-3">
              <component
                :is="textStyle.bold ? 'b' : 'div'"
                class="blog-title w-100"
                :style="textStyle"
                v-text="settings.hero_banner.text"
              />
            </div>
          </div>
        </div>

        <b-row
          class="align-items-center"
          no-gutters
        >
          <b-col md="4">
            <div class="d-flex align-items mb-2">
              <b-form-radio-group
                v-model="resolution"
                class="resolution-style-tabs"
                buttons-variant="primary"
                value-field="key"
                text-field="text"
                buttons
                :options="[{text: 'DESKTOP', key: 'lg'}, {text: 'TABLET', key: 'md'}, {text: 'MOBILE', key: 'sm'}]"
              />
            </div>
            <font-family-and-weight-control
              :weight-enable="false"
              :family="font"
              :global-style-option-enable="false"
              @family="family => font = family"
            />
          </b-col>
          <b-col
            md="2"
            class="pt-[10px] px-2"
          >
            <b-form-group>
              <v-select
                v-model="fontSize"
                class="custom-select"
                :options="fontSizes()"
              />
            </b-form-group>
          </b-col>

          <b-col class="pt-[10px] d-flex">
            <b-form-group class="mr-2">
              <color-picker
                :color="color"
                @input="event => color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
              />
            </b-form-group>

            <b-form-group class="mr-2">
              <b-btn
                variant="menubar-button"
                class="px-3 py-2"
                :class="{ 'is-active': bold }"
                @click="bold = !bold"
              >
                <i class="fa fa-bold" />
              </b-btn>
            </b-form-group>

            <b-form-group>
              <b-btn-group>
                <b-btn
                  variant="menubar-button"
                  class="py-2"
                  :class="{ 'is-active': textAlign === 'left' }"
                  @click="textAlign = 'left'"
                >
                  <i class="fa fa-align-left" />
                </b-btn>
                <b-btn
                  variant="menubar-button"
                  class="py-2"
                  :class="{ 'is-active': textAlign === 'center' }"
                  @click="textAlign = 'center'"
                >
                  <i class="fa fa-align-center" />
                </b-btn>
                <b-btn
                  variant="menubar-button"
                  class="py-2"
                  :class="{ 'is-active': textAlign === 'right' }"
                  @click="textAlign = 'right'"
                >
                  <i class="fa fa-align-right" />
                </b-btn>
              </b-btn-group>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <b-input v-model="settings.hero_banner.text" />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import ImageUpload from '../../../editor/utils/ImageUpload'
import {mapState} from 'vuex'
import _ from 'lodash'
import FontFamilyAndWeightControl from '@/components/editor/controls/FontFamilyAndWeightControl'
import GlobalStylesRender from '@/components/builder/utils/GlobalStylesRender'

export default {
  name: 'BlogHeroSettings',
  components: {GlobalStylesRender, FontFamilyAndWeightControl, ImageUpload},

  props: {
    settings: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      sizeOptions: [
        'cover',
        'contain'
      ],
      resolution: 'lg'
    }
  },

  computed: {
    ...mapState({
      globalStyles: state => state.editor.globalStyles,
      fonts: state => state.font.fonts,
      project: state => state.projects.project
    }),
    textStyle () {
      return {
        fontSize: _.get(this.settings.hero_banner.styles, [this.resolution, 'fontSize'], '20px'),
        color: _.get(this.settings.hero_banner.styles, [this.resolution, 'color'], '#ffffff'),
        fontFamily: _.get(this.settings.hero_banner.styles, [this.resolution, 'fontFamily'], 'Roboto'),
        textAlign: _.get(this.settings.hero_banner.styles, [this.resolution, 'align'], 'center'),
        align: _.get(this.settings.hero_banner.styles, [this.resolution, 'align'], 'center'),
        bold: _.get(this.settings.hero_banner.styles, [this.resolution, 'bold'], false),
        font: _.get(this.settings.hero_banner.styles, [this.resolution, 'font'], 'Roboto')
      }
    },
    textAlign: {
      get () {
        return _.get(this.settings.hero_banner.styles, [this.resolution, 'align'], 'center')
      },
      set (align) {
        this.$set(this.settings.hero_banner.styles, this.resolution, {
          ...this.textStyle,
          align
        })
      }
    },
    bold: {
      get () {
        return _.get(this.settings.hero_banner.styles, [this.resolution, 'bold'], false)
      },
      set (bold) {
        this.$set(this.settings.hero_banner.styles, this.resolution, {
          ...this.textStyle,
          bold
        })
      }
    },
    font: {
      get () {
        return _.get(this.settings.hero_banner.styles, [this.resolution, 'fontFamily'], 'Roboto')
      },
      set (fontFamily) {
        this.$set(this.settings.hero_banner.styles, this.resolution, {
          ...this.textStyle,
          fontFamily
        })
      }
    },
    fontSize: {
      get () {
        return _.get(this.settings.hero_banner.styles, [this.resolution, 'fontSize'], '20px')
      },
      set (fontSize) {
        this.$set(this.settings.hero_banner.styles, this.resolution, {
          ...this.textStyle,
          fontSize
        })
      }
    },
    color: {
      get () {
        return _.get(this.settings.hero_banner.styles, [this.resolution, 'color'], '#ffffff')
      },
      set (color) {
        this.$set(this.settings.hero_banner.styles, this.resolution, {
          ...this.textStyle,
          color
        })
      }
    },
    heroStyles () {
      return {
        height: '115px',
        width: '100%',
        backgroundImage: `url(${this.settings.hero_banner.image.src || this.settings.hero_banner.image.placeholder})`,
        backgroundSize: this.settings.hero_banner.backgroundSize,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }
    }
  },
  methods: {
    fontSizes () {
      return Array(70)
        .fill({})
        .map((_, i) => `${i + 1}px`)
    }
  }
}
</script>
