export default {
  computed: {
    gradient () {
      if (this.button.backgroundType === 'gradient' && this.button.hasOwnProperty('backgroundGradient')) {
        const values = this.button.backgroundGradient.map(gradient => {
          return`${gradient.color} ${gradient.position}%`
        })
        return `linear-gradient(${this.button.gradientDirection || 90}deg,${values})`
      }

      return null
    }
  }
}