<template>
  <div class="form-mod">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">
        Form Fields
      </p>
    </div>

    <b-form-group label="Recipient email">
      <b-input
        v-model="config.recipientEmail"
        type="email"
      />
    </b-form-group>

    <div>
      <div class="form-mod-inner mb-3">
        <div class="form-mod-inner-index">
          <div class="d-flex align-items-center justify-content-between pt-3 mb-2">
            <label>Form Fields</label>

            <b-link @click="formSettings = true">
              <icon
                size="16px"
                icon="form-settings.svg"
              />
            </b-link>
          </div>

          <draggable
            v-model="config.fields"
            handle=".move"
            class="list-group mb-3"
          >
            <b-list-group-item
              v-for="(field, index) in config.fields"
              :key="field.name"
              class="d-flex align-items-center text-capitalize"
            >
              <icon
                class="move mr-3"
                icon="move-gray.svg"
              />
              {{ field.label || field.name }}

              <icon
                class="cursor-pinter ml-auto mr-3"
                icon="edit.svg"
                @click.native="newFormFieldModal = index"
              />
              <icon
                v-if="field.display"
                class="cursor-pinter"
                icon="eye-3.svg"
                @click.native="field.display = false"
              />
              <icon
                v-else
                class="cursor-pinte"
                icon="eye-disable.svg"
                @click.native="field.display = true"
              />
              <icon
                v-if="field.name !== 'email'"
                class="cursor-pinter ml-2"
                icon="trash.svg"
                @click.native="removeFormField(index)"
              />
            </b-list-group-item>
          </draggable>

          <div
            class="d-flex align-items-center justify-content-end cursor-pinter font-weight-sami-bold text-primary w-100"
            @click="newFormFieldModal = true"
          >
            <icon
              class="mt-1 mr-2"
              icon="plus.svg"
            />
            Add Form Field
          </div>
        </div>
      </div>

      <b-form-group label="Button position">
        <v-select
          v-model="config.btnPosition"
          left
          label="name"
          :reduce="option => option.position"
          class="custom-select"
          :options="btnPositions"
          :searchable="false"
        />
      </b-form-group>
    </div>

    <b-modal
      v-model="getNewFormFieldModal"
      size="lg"
      hide-footer
      hide-header
    >
      <icon
        class="cursor-pinter ml-3"
        icon="close.svg"
        @click.native="newFormFieldModal = false"
      />

      <h4 class="h2 font-weight-bold text-center my-4">
        Add A Form Field
      </h4>

      <b-form @submit.prevent="addFormField">
        <div class="p-5">
          <b-row class="mb-4">
            <b-col cols="6">
              <b-form-group
                v-if="typeof newFormFieldModal !== 'number'"
                label="Form Type"
              >
                <v-select
                  v-model="newForm.type"
                  left
                  :reduce="option => option.key"
                  class="custom-select"
                  :options="typeOptions"
                  :searchable="false"
                />
              </b-form-group>
              <b-form-group
                v-else
                label="Form Type"
              >
                <v-select
                  v-model="config.fields[newFormFieldModal].type"
                  left
                  :reduce="option => option.key"
                  class="custom-select"
                  :options="typeOptions"
                  :searchable="false"
                />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Name">
                <b-input
                  v-if="typeof newFormFieldModal !== 'number'"
                  v-model="newForm.name"
                  required
                />
                <b-input
                  v-else
                  readonly
                  :value="config.fields[newFormFieldModal].name"
                  required
                />
              </b-form-group>

              <small
                v-if="hasAPI.name === 'GetResponse'"
                class="d-block text-danger mb-4"
              >Due to technical peculiarities of GetResponse, the field names cannot contain: name, email, twitter, facebook, buzz, myspace, linkedin, digg, googleplus, pinterest, responder, campaign, change.</small>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Form Input Placeholder">
                <b-input
                  v-if="typeof newFormFieldModal !== 'number'"
                  v-model="newForm.placeholder"
                />
                <b-input
                  v-else
                  v-model="config.fields[newFormFieldModal].placeholder"
                />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Form Input Label">
                <b-input
                  v-if="typeof newFormFieldModal !== 'number'"
                  v-model="newForm.label"
                />
                <b-input
                  v-else
                  v-model="config.fields[newFormFieldModal].label"
                />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group class="pt-4">
                <b-checkbox
                  v-if="typeof newFormFieldModal !== 'number'"
                  v-model="newForm.required"
                >
                  Required Field
                </b-checkbox>
                <b-checkbox
                  v-else
                  v-model="config.fields[newFormFieldModal].required"
                >
                  Required Field
                </b-checkbox>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group
                v-if="typeof newFormFieldModal !== 'number'"
                label="Control Type"
              >
                <v-select
                  v-model="newForm.fieldType"
                  left
                  :reduce="option => option.key"
                  class="custom-select"
                  :options="controlTypeOptions"
                  :searchable="false"
                />
              </b-form-group>
              <b-form-group
                v-else
                label="Form Type"
              >
                <v-select
                  v-model="fieldType"
                  left
                  :reduce="option => option.key"
                  class="custom-select"
                  :options="controlTypeOptions"
                  :searchable="false"
                />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-row>
                <b-col>
                  <b-form-group label="Field on large screen">
                    <grid-control
                      v-if="typeof newFormFieldModal !== 'number'"
                      v-model="newForm.cols.lg"
                      target="form-grid-lg"
                    />
                    <grid-control
                      v-else
                      v-model="config.fields[newFormFieldModal].cols.lg"
                      target="form-grid-lg"
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group label="Field on small screen">
                    <grid-control
                      v-if="typeof newFormFieldModal !== 'number'"
                      v-model="newForm.cols.sm"
                      target="form-grid-sm"
                    />
                    <grid-control
                      v-else
                      v-model="config.fields[newFormFieldModal].cols.sm"
                      target="form-grid-sm"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <div
            v-if="typeof newFormFieldModal !== 'number'"
            class="d-flex justify-content-center"
          >
            <b-btn
              variant="primary"
              type="submit"
            >
              Add Form Field
            </b-btn>
          </div>
        </div>
      </b-form>
    </b-modal>

    <b-modal
      v-model="formSettings"
      size="lg"
      hide-footer
      hide-header
    >
      <icon
        class="cursor-pinter ml-3"
        icon="close.svg"
        @click.native="formSettings = false"
      />

      <h4 class="h2 font-weight-bold text-center my-4">
        Form Settings
      </h4>

      <b-form @submit.prevent="saveFormSettings">
        <div class="p-5">
          <h5 class="text-muted mb-4">
            Confirmation Page
          </h5>

          <b-row>
            <b-col md="4">
              <b-form-group>
                <b-form-radio
                  v-model="config.successMessageType"
                  class="mb-2"
                  value="inner_link"
                  name="message_type"
                >
                  Custom
                  Page from Site
                </b-form-radio>

                <v-select
                  v-model="config.successMessageInnerLink"
                  left
                  label="name"
                  :reduce="option => option.key"
                  class="custom-select"
                  :options="pages"
                  :searchable="false"
                />
              </b-form-group>
            </b-col>

            <b-col md="8">
              <b-form-group>
                <b-form-radio
                  v-model="config.successMessageType"
                  class="mb-2"
                  value="link"
                  name="message_type"
                >
                  Custom
                  URL
                </b-form-radio>

                <b-input v-model="config.successMessageLink" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <b-form-radio
                  v-model="config.successMessageType"
                  class="mb-2"
                  value="text"
                  name="message_type"
                >
                  (Default) Success Message
                </b-form-radio>

                <form-settings-editor
                  v-model="config.successMessage.text"
                  :config="config.successMessage"
                />
                <label class="text-muted">This wil display inside the section block once form has been
                  submitted </label>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="d-flex justify-content-center">
            <b-btn
              variant="primary"
              type="submit"
            >
              Save Form Settings
            </b-btn>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import draggable from 'vuedraggable'
import {mapGetters, mapState} from 'vuex'
import FormSettingsEditor from '../utils/FormSettingsEditor'
import _ from 'lodash'
import TextMixins from '../../mixins/TextMixins'
import FormCustomFieldsBuilder from '@builder/components/builder/utils/auto-responders/mixins/FormCustomFieldsBuilder'
import GridControl from '../utils/GridControl'

const newForm = {
  display: true,
  type: 'text',
  name: null,
  placeholder: null,
  required: false,
  label: null,
  fieldType: null,
  value: '',
  cols: {
    lg: 12,
    sm: 12
  }
}

export default {
  name: 'EmailFormMod',

  components: {GridControl, FormSettingsEditor, draggable},

  mixins: [ModMixin, TextMixins, FormCustomFieldsBuilder],

  data () {
    return {
      connectDialog: false,
      list: [],
      newFormFieldModal: false,
      newForm: newForm,
      formSettings: false,
      typeOptions: [
        {
          key: 'text',
          label: 'Text'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'tel',
          label: 'Tel'
        },
        {
          key: 'color',
          label: 'Color'
        },
        {
          key: 'date',
          label: 'Date'
        },
        {
          key: 'number',
          label: 'Number'
        }
      ],
      controlTypeOptions: [
        {
          key: null,
          label: 'Text field'
        },
        {
          key: 'textarea',
          label: 'Textarea field'
        }
      ]
    }
  },

  computed: {
    fieldType: {
      get () {
        return this.config.fields[this.newFormFieldModal].fieldType || null
      },
      set (val) {
        this.$set(this.config.fields[this.newFormFieldModal], 'fieldType', val)
      }
    },

    btnPositions () {
      const positions = []

      this.config.fields.forEach(field => {
        positions.push({
          name: `Prepend ${_.kebabCase(field.name)}`,
          position: `prepend-${field.name}`
        })

        positions.push({
          name: `Append ${_.kebabCase(field.name)}`,
          position: `append-${field.name}`
        })
      })

      positions.unshift(
        {
          name: 'Top',
          position: 'top'
        },
        {
          name: 'Submit',
          position: 'submit'
        }
      )

      return positions
    },
    ...mapGetters('apiIntegration', {
      hasAPI: 'hasMailAPIService'
    }),
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    getNewFormFieldModal: {
      get () {
        if (this.newFormFieldModal === 0) {
          return true
        }

        return !!this.newFormFieldModal
      },
      set (val) {
        this.newFormFieldModal = val
      }
    }
  },

  mounted () {
    this.config.succesMessage = {}

    this.config.action = 'ContactForm'
  },

  methods: {
    async removeFormField (index) {
      this.config.fields.splice(index, 1)
    },
    async addFormField () {
      this.config.fields.push(this.newForm)
      this.newForm = newForm
    }
  }
}
</script>
