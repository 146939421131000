<template>
  <div>
    <b-modal
      v-model="confirmDialog"
      body-class="text-center py-5"
      size="lg"
      hide-footer
      hide-header
      centered
    >
      <h4
        class="h3 font-weight-bold my-4"
        v-html="title"
      />

      <p v-html="description" />


      <div class="d-flex align-items-center justify-content-center">
        <b-btn
          variant="danger"
          class="btn-wide mr-2"
          @click="confirm"
        >
          Yes
        </b-btn>
        <b-btn
          class="btn-wide"
          @click="confirmDialog = false"
        >
          No
        </b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    title: String,
    description: String
  },
  data () {
    return {
      confirmDialog: false,
      data: null
    }
  },
  methods: {
    open (data) {
      this.confirmDialog = true
      this.data = data
    },
    confirm () {
      this.confirmDialog = false
      this.$emit('confirm', this.data)
    }
  }
}
</script>
