<template>
  <div>
    <h2 class="page-title mb-14">
      Basic Info
    </h2>

    <b-row class="mb-2">
      <b-col md="2">
        <account-image-uploader
          v-model="form.file"
          :image="form.image"
        />
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col>
            <b-form-group
              label="First Name"
              class="mb-4"
            >
              <b-input
                v-model="name"
                v-validate="'required'"
                name="first_name"
                :state="errors.has('first_name') ? false : null"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Last Name"
              class="mb-4"
            >
              <b-input
                v-model="lastName"
                v-validate="'required'"
                name="last_name"
                :state="errors.has('last_name') ? false : null"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group
          label="Account Name"
          class="mb-4"
        >
          <div class="relative">
            <b-input
              readonly
              class="bg-white cursor-default"
              :value="user.email"
            />

            <svg
              class="absolute right-[15px] top-1/2 -translate-y-1/2"
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 11.0833C6.9665 11.0833 7.75 10.2998 7.75 9.33331C7.75 8.36682 6.9665 7.58331 6 7.58331C5.0335 7.58331 4.25 8.36682 4.25 9.33331C4.25 10.2998 5.0335 11.0833 6 11.0833ZM6 9.91665C6.32217 9.91665 6.58333 9.65548 6.58333 9.33331C6.58333 9.01115 6.32217 8.74998 6 8.74998C5.67783 8.74998 5.41667 9.01115 5.41667 9.33331C5.41667 9.65548 5.67783 9.91665 6 9.91665Z"
                fill="#4E84D3"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.5 5.24998V4.08331C2.5 2.15032 4.067 0.583313 6 0.583313C7.933 0.583313 9.5 2.15032 9.5 4.08331V5.24998C10.4665 5.24998 11.25 6.03348 11.25 6.99998V11.6666C11.25 12.6331 10.4665 13.4166 9.5 13.4166H2.5C1.5335 13.4166 0.75 12.6331 0.75 11.6666V6.99998C0.75 6.03348 1.5335 5.24998 2.5 5.24998ZM3.66667 4.08331C3.66667 2.79465 4.71134 1.74998 6 1.74998C7.28866 1.74998 8.33333 2.79465 8.33333 4.08331V5.24998H3.66667V4.08331ZM2.5 6.41665C2.17783 6.41665 1.91667 6.67781 1.91667 6.99998V11.6666C1.91667 11.9888 2.17783 12.25 2.5 12.25H9.5C9.82217 12.25 10.0833 11.9888 10.0833 11.6666V6.99998C10.0833 6.67781 9.82217 6.41665 9.5 6.41665H2.5Z"
                fill="#4E84D3"
              />
            </svg>
          </div>
        </b-form-group>

        <b-form-group label="Website">
          <b-input v-model="form.website" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="8">
        <b-form-group label="Bio">
          <b-textarea
            v-model="form.bio"
            rows="4"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AccountImageUploader from './AccountImageUploader'
import {mapState} from 'vuex'
import _ from 'lodash'
export default {
  name: 'AccountBasicInfo',
  components: {AccountImageUploader},
  inject: ['$validator'],
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    name: {
      get () {
        return _.startCase(this.form.first_name)
      },
      set (val) {
        this.form.first_name = _.snakeCase(val)
      }
    },
    lastName: {
      get () {
        return _.startCase(this.form.last_name)
      },
      set (val) {
        this.form.last_name = _.snakeCase(val)
      }
    }
  }
}
</script>