<template>
  <div class="d-flex justify-content-center">
    <button
      v-bind="$attrs"
      :disabled="loading"
      class="estage-modal-form-btn d-flex align-items-center gap-[14px]"
      type="submit"
      @click="() => $emit('click')"
    >
      <slot />

      <b-spinner
        v-if="loading"
        small
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'ModalButton',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>