<template>
  <div
    v-if="loader"
    class="position-fixed progressLoaderWrapper"
  >
    <div class="progressLoader" />
  </div>
</template>

<script>
export default {
  name: 'ProgressLoader',
  data () {
    return {
      loader: false
    }
  },

  mounted () {
    VEvent.listen('progressLoader', (state) => {
      this.loader = state
    })
  }
}
</script>

<style lang="scss">
@import "@builder/assets/scss/_variables.scss";

@keyframes loader-before{
  0%{
    left:0;
    width:0;
  }
  50%{
    left:25%;
    width:75%
  }
  75%{
    left:100%;
    width:0
  }
}
@keyframes loader-after{
  0%,
  50%{
    left:0;
    width:0
  }
  75%{
    left:0;
    width:25%
  }
  100%{
    left:100%;
    width:0
  }
}

.progressLoaderWrapper {
  top: 0;
  z-index: 10000;
  width: 100%;

  .progressLoader {
    display: block;
    position: relative;
    height: 4px;
    width: 100%;

    &:after, &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 0;
      background-color: $primary;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    &:before {
      left: 0;
      animation-name: loader-before;
    }

    &:after {
      right: 0;
      animation-name: loader-after;
    }
  }
}
</style>
