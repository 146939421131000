<template>
  <div :style="{...padding, ...backgroundColor}">
    <div :class="container">
      <b-row
        no-gutters
        class="featured-items-3 align-items-end"
      >
        <b-col :cols="cols(8, 12)">
          <div class="image">
            <template v-if="visability('image')">
              <slot name="image" />
            </template>
          </div>

          <div
            :class="{'featured-text': visability('image') && resolution === 'lg'}"
            class="p-5"
            :style="{...shadow, ...backgroundColorCustom('Card')}"
          >
            <slot name="elements" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'FeaturedItems3Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
  .featured-items-3 {
    .featured-text {
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 0;
      minHeight: 50%;
      right: -50%;
      width: 80%;
      background: #FBFBFB;

      p {
        margin-bottom: 0;
      }

      transform: translateY(-50%);
    }
  }
</style>
