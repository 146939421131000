<template>
  <div>
    <p class="font-[600] text-[16px] mb-[23px]">
      Added Google Fonts
    </p>
    <div class="rounded-[6px] bg-[#FAFAFB] py-[38px] pl-[46px] pr-[23px]">
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 flex flex-wrap gap-[18px] h-[642px] pr-4 overflow-auto normal-scroll">
        <font-card
          v-for="(font, index) in sortFonts"
          :key="index"
          :font="font"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import FontCard from '@/views/dashboard/fonts/FontCard'
import {mapState} from 'vuex'

export default {
  name: 'GoogleFonts',
  components: {FontCard},
  computed: {
    ...mapState({
      addedGoogleFonts: state => state.editor.globalStyles.font.addedGoogleFonts
    }),
    sortFonts () {
      return _.uniqBy(_.orderBy(this.addedGoogleFonts, 'family'), 'family')
    }
  }
}
</script>