<template>
  <div class="position-relative h-100 custom-menu overflow-hidden">
    <div
      class="flex items-center justify-between h-[43px] bg-gradient-to-b from-white to-[#F1F3F4] px-[18px] border-b border-[#E2E5EC]"
    >
      <span class="text-[#44474A] font-[700] text-[15px] mb-0">
        Blocks
      </span>
    </div>

    <div class="flex flex-column relative max-h-full h-[calc(100vh-175px)]">
      <div class="overflow-auto">
        <blocks-drag-items />
      </div>
    </div>
  </div>
</template>

<script>
import BlocksDragItems from '../components/BlocksDragItems'

export default {
  name: 'Sections',
  components: {BlocksDragItems}
}
</script>
