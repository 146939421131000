<template>
  <div>
    <b-form
      v-if="loaded"
      @submit.prevent="save"
    >
      <account-basic-info :form="form.basicInfo" />

      <hr class="my-5">

      <shipping-info :form="form.shippingInfo" />

      <hr class="my-5">

      <notifications
        :form="form.notifications"
        class="mb-5"
      />

      <b-btn
        type="submit"
        variant="primary"
        size="lg"
        class="font-weight-bold font-size-14 px-5"
        :disabled="loading"
      >
        Save Profile Settings

        <svg
          v-if="!loading"
          class="ml-3"
          width="21"
          height="18"
          viewBox="0 0 21 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.2232 2H4.815C3.7298 2 2.84692 2.78855 2.84692 3.75781V15.2422C2.84692 16.2114 3.7298 17 4.815 17H17.6731C18.7583 17 19.6412 16.2114 19.6412 15.2422V4.15965L17.2232 2ZM6.78307 3.17188H14.3274V6.62891H6.78307V3.17188ZM15.705 15.8281H6.78307V11.1992H15.705V15.8281ZM18.3291 15.2422C18.3291 15.5653 18.0348 15.8281 17.6731 15.8281H17.0171V10.0273H5.47102V15.8281H4.815C4.45327 15.8281 4.15897 15.5653 4.15897 15.2422V3.75781C4.15897 3.43473 4.45327 3.17188 4.815 3.17188H5.47102V7.80078H15.6394V3.17188H16.6797L18.3291 4.64504V15.2422Z"
            fill="white"
          />
          <path
            d="M13.1465 3.72852H11.8345V6.04297H13.1465V3.72852Z"
            fill="white"
          />
        </svg>
        <b-spinner
          v-else
          class="ml-3"
          variant="light"
          small
        />
      </b-btn>
    </b-form>
    <div
      v-else
      class="d-flex align-items-center justify-content-center min-vh-50"
    >
      <b-spinner
        variant="primary"
        size="lg"
      />
    </div>
  </div>
</template>

<script>
import AccountBasicInfo from './AccountBasicInfo'
import ShippingInfo from './ShippingInfo'
import Notifications from './Notifications'

export default {
  name: 'Account',

  components: {Notifications, ShippingInfo, AccountBasicInfo},

  data () {
    return {
      loaded: false,
      form: {
        basicInfo: {
          first_name: '',
          last_name: '',
          website: '',
          bio: '',
          image: ''
        },
        shippingInfo: {
          address: '',
          city: '',
          state_id: 0,
          country_id: 0,
          zip_code: '',
          t_shirt_size: ''
        },
        notifications: {
          phone: '',
          phone_code: '',
          sms_notification: ''
        }
      },
      loading: false
    }
  },

  created () {
    axios.get('/api/user/profile')
      .then(({data}) => {
        this.form.basicInfo = {
          file: null,
          first_name: data.data['first_name'],
          last_name: data.data['last_name'],
          website: data.data['website'],
          bio: data.data['bio'],
          image: data.data['image']
        }

        if (data.data.shipping_information) {
          this.form.shippingInfo = {
            address: data.data.shipping_information['address'],
            city: data.data.shipping_information['city'],
            state_id: parseInt(data.data.shipping_information['state_id']),
            country_id: parseInt(data.data.shipping_information['country_id']),
            zip_code: data.data.shipping_information['zip_code'],
            t_shirt_size: data.data.shipping_information['t_shirt_size']
          }
        }

        let phone = data.data['phone_code']

        try {
          phone = JSON.parse(data.data['phone_code'])
        } catch(e) {
          console.log(e)
        }

        this.form.notifications = {
          phone: data.data['phone'],
          phone_code: data.data['phone_code'] ? phone : {'name':'United States','dial_code':'+1','code':'US'},
          sms_notification: data.data['sms_notification']
        }

        this.loaded = true
      })
  },

  methods: {
    save () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true

          axios.put('api/user/profile', {
            ...this.form.basicInfo,
            ...this.form.notifications,
            file: this.form.basicInfo.file,
            phone_code: JSON.stringify(this.form.notifications.phone_code),
            shipping_info: this.form.shippingInfo
          })
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Profile updated successfully!',
                showConfirmButton: false,
                timer: 1500
              })
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          document.querySelector('.is-invalid').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          })
        }
      })
    }
  },

  provide () {
    return {
      $validator: this.$validator
    }
  }
}
</script>
