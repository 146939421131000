<template>
  <b-form-group label="Duration (MS)">
    <LinkedControlItem
      v-model="duration"
      :max="50000"
      :min="0"
      class="d-block w-100"
    />
  </b-form-group>
</template>

<script>
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
export default {
  name: 'MotionDuration',
  components: {LinkedControlItem},
  props: {
    subjectMotions: {
      type: Object,
      required: true
    }
  },
  computed: {
    duration: {
      get () {
        return this.subjectMotions.duration || 4000
      },
      set (val) {
        this.$set(this.subjectMotions, 'duration', val)
      }
    }
  }
}
</script>