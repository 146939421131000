<template>
  <div>
    <div class="d-flex justify-content-center align-items-start">
      <div
        v-for="(layout, index) in layouts"
        :key="layout.id"
        class="mx-2"
      >
        <div
          class="layout-preview"
          :class="{'active': settings.layout.id === layout.id}"
          @click="selectLayout(layout)"
        >
          <svg
            v-if="index === 0"
            width="302"
            height="205"
            viewBox="0 0 302 205"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d)">
              <rect
                x="7"
                y="5"
                width="288"
                height="191"
                rx="3"
                fill="white"
              />
              <rect
                class="stroken"
                x="7.5"
                y="5.5"
                width="287"
                height="190"
                rx="2.5"
                stroke="#E3E5EB"
              />
            </g>
            <rect
              x="78.5"
              y="25.5"
              width="145"
              height="150"
              rx="3.5"
              fill="white"
              stroke="#D7D8D8"
            />
            <rect
              x="78"
              y="25"
              width="146"
              height="29"
              fill="#D7D8D8"
            />
            <rect
              x="99"
              y="75"
              width="30.7271"
              height="32.9927"
              rx="1"
              fill="#D7D8D8"
            />
            <rect
              x="135.636"
              y="75"
              width="29.5453"
              height="32.9927"
              rx="1"
              fill="#D7D8D8"
            />
            <rect
              x="171.091"
              y="75"
              width="31.909"
              height="32.9927"
              rx="1"
              fill="#D7D8D8"
            />
            <rect
              x="99"
              y="113.492"
              width="30.7271"
              height="32.9927"
              rx="1"
              fill="#D7D8D8"
            />
            <rect
              x="135.636"
              y="113.492"
              width="29.5453"
              height="32.9927"
              rx="1"
              fill="#D7D8D8"
            />
            <rect
              x="171.091"
              y="113.492"
              width="31.909"
              height="32.9927"
              rx="1"
              fill="#D7D8D8"
            />
            <defs>
              <filter
                id="filter0_d"
                x="0"
                y="0"
                width="302"
                height="205"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood
                  flood-opacity="0"
                  result="BackgroundImageFix"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="3.5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
          <svg
            v-else-if="index === 1"
            width="302"
            height="205"
            viewBox="0 0 302 205"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d)">
              <rect
                x="7"
                y="5"
                width="288"
                height="191"
                rx="3"
                fill="white"
              />
              <rect
                class="stroken"
                x="7.5"
                y="5.5"
                width="287"
                height="190"
                rx="2.5"
                stroke="#E3E5EB"
              />
            </g>
            <rect
              x="81.5"
              y="25.5"
              width="145"
              height="150"
              rx="3.5"
              fill="white"
              stroke="#D7D8D8"
            />
            <rect
              x="81"
              y="25"
              width="146"
              height="29"
              fill="#D7D8D8"
            />
            <rect
              x="110"
              y="62"
              width="88"
              height="21.547"
              rx="1"
              fill="#D7D8D8"
            />
            <rect
              x="110"
              y="87.9668"
              width="88"
              height="21.547"
              rx="1"
              fill="#D7D8D8"
            />
            <rect
              x="110"
              y="113.934"
              width="88"
              height="21.547"
              rx="1"
              fill="#D7D8D8"
            />
            <rect
              x="110"
              y="140.453"
              width="88"
              height="21.547"
              rx="1"
              fill="#D7D8D8"
            />
            <defs>
              <filter
                id="filter0_d"
                x="0"
                y="0"
                width="302"
                height="205"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood
                  flood-opacity="0"
                  result="BackgroundImageFix"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="3.5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
          <svg
            v-else-if="index === 2"
            width="302"
            height="205"
            viewBox="0 0 302 205"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d)">
              <rect
                x="7"
                y="5"
                width="288"
                height="191"
                rx="3"
                fill="white"
              />
              <rect
                class="stroken"
                x="7.5"
                y="5.5"
                width="287"
                height="190"
                rx="2.5"
                stroke="#E3E5EB"
              />
            </g>
            <rect
              x="78.5"
              y="25.5"
              width="145"
              height="150"
              rx="3.5"
              fill="white"
              stroke="#D7D8D8"
            />
            <rect
              x="78"
              y="25"
              width="146"
              height="29"
              fill="#D7D8D8"
            />
            <rect
              x="107"
              y="66"
              width="43"
              height="43"
              rx="1"
              fill="#D7D8D8"
            />
            <rect
              x="157"
              y="66"
              width="43"
              height="43"
              rx="1"
              fill="#D7D8D8"
            />
            <rect
              x="107"
              y="115"
              width="43"
              height="43"
              rx="1"
              fill="#D7D8D8"
            />
            <rect
              x="157"
              y="115"
              width="43"
              height="43"
              rx="1"
              fill="#D7D8D8"
            />
            <defs>
              <filter
                id="filter0_d"
                x="0"
                y="0"
                width="302"
                height="205"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood
                  flood-opacity="0"
                  result="BackgroundImageFix"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="3.5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div class="ml-2 text-muted mb-2">
          <span class="font-weight-bold">{{ layout.name }}</span>
          <span v-if="index === 0"> - Default Blog</span>
        </div>
        <b-checkbox
          v-if="settings.layout.id === layout.id"
          v-model="layout.sidebar"
          :disabled="settings.layout.id !== layout.id"
          class="ml-2"
          :value="1"
          @change="updateSidebarState(layout)"
        >
          <span :class="{'text-disabled': settings.layout.id !== layout.id}">Include sidebar</span>
        </b-checkbox>
      </div>
    </div>

    <hr class="my-[40px]">

    <div class="px-[35px]">
      <h6 class="font-weight-bold mb-3">
        Colors
      </h6>

      <b-form-group>
        <div class="flex items-center gap-3">
          <label
            for="theme"
            class="m-0 p-0 cursor-pointer"
          >
            Dark Theme
          </label>
          <b-checkbox
            id="theme"
            v-model="theme"
            :unchecked-value="false"
            :value="true"
            switch
            size="lg"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex gap-3 items-center">
          <color-picker
            :color="color"
            @input="event => color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />
          <div>Primary Color</div>
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'BlogLayouts',

  props: {
    settings: {
      type: Object,
      required: true
    }
  },

  computed: {
    theme: {
      get () {
        return this.settings.layout.theme
      },
      set (val) {
        this.$set(this.settings.layout, 'theme', val)
      }
    },
    color: {
      get () {
        return this.settings.primaryColor || this.globalStyles.colors.primary
      },
      set (val) {
        this.$set(this.settings, 'primaryColor', val)
      }
    },
    ...mapState({
      globalStyles: state => state.editor.globalStyles,
      layouts: state => state.blogSettings.layouts
    })
  },
  created () {
    this.layouts[this.settings.layout.id - 1].sidebar = this.settings.layout.sidebar
  },
  methods: {
    selectLayout (layout) {
      if (this.settings.layout.id === layout.id) return

      this.settings.layout = {
        id: layout.id,
        name: layout.name,
        sidebar: layout.sidebar,
        component: layout.component
      }

      this.updateSidebarState(layout)
    },
    updateSidebarState (layout) {
      if (this.settings.layout.id !== layout.id) return
      this.settings.layout.sidebar = layout.sidebar

      if (!layout.ad) {
        this.settings.ad_banner.allowed = false
      } else {
        this.settings.ad_banner.allowed = true
        this.settings.ad_banner.image = {
          src: null,
          placeholder: layout.ad.banner.sidebar[!!layout.sidebar].placeholder,
          description: layout.ad.banner.sidebar[!!layout.sidebar].description
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@builder/assets/scss/_variables.scss";

.layout-preview {
  .stroken {
    transition: .4s;
  }

  &:hover,
  &.active {
    cursor: pointer;

    .stroken {
      stroke: $primary;
      stroke-width: 2px;
    }
  }
}
</style>
