const draftSettings = {
  primaryColor: '',
  'layout': {
    id: 1,
    name: '3 Column Grid',
    sidebar: 1,
    component: 'GridThreeWrapper',
    show_date: true
  },
  'hero_banner': {
    text: 'Blog',
    backgroundImageEnabled: true,
    backgroundSize: 'cover',
    backgroundColor: 'rgba(124,172,250,0.71)',
    styles: {
      lg: {
        font: 'Roboto',
        fontSize: '20px',
        color: '#ffffff',
        bold: true,
        align: 'center'
      },
      sm: {
        font: 'Roboto',
        fontSize: '20px',
        color: '#ffffff',
        bold: true,
        align: 'center'
      }
    },
    image: {
      src: null,
      placeholder: require('@builder/assets/images/placeholder-blog-banner.png')
    }
  },
  'widget_sidebar': {
    display_search: true,
    display_popular_posts: true,
    display_categories: true,
    display_latest_posts: true
  },
  'ad_banner': {
    allowed: true,
    enabled: false,
    href: '',
    image: {
      src: null,
      placeholder: require('@builder/assets/images/ad-banner-4.png'),
      description: '3-Column Grid with Sidebar - 1920 x 1080 pixels'
    }
  },
  comments_moderation: false,
  'allow_comments': true,
  'display_comments': true,
  'display_social_share': true,
  'display_like_button': true,
  'social_accounts': [
    {
      shape: 'None',
      color: '#ffffff',
      background: '#3B6AE3',
      icon: 'fa fa-facebook',
      link: ''
    },
    {
      shape: 'None',
      color: '#ffffff',
      background: '#65B8F4',
      icon: 'fa fa-twitter',
      link: ''
    },
    {
      shape: 'None',
      color: '#ffffff',
      background: '#326EA6',
      icon: 'fab fa-linkedin-in',
      link: ''
    }
  ]
}

const state = () => ({
  layouts: [
    {
      id: 1,
      name: '3 Column Grid',
      component: 'GridThreeWrapper',
      sidebar: 1,
      ad: {
        banner: {
          sidebar: {
            true: {
              placeholder: require('@builder/assets/images/ad-banner-4.png'),
              description: '3-Column Grid with Sidebar - 1920 x 1080 pixels'
            },
            false: {
              placeholder: require('@builder/assets/images/ad-banner-3.png'),
              description: '3-Column Grid - 1920 x 1080 pixels'
            }
          }
        }
      }
    },
    {
      id: 2,
      name: 'Horiztonal List',
      component: 'HoriztonalListWrapper',
      sidebar: 0,
      ad: {
        banner: {
          sidebar: {
            true: {
              placeholder: require('@builder/assets/images/ad-banner-2.png'),
              description: 'Horiztonal List with Sidebar - 1920 x 1080 pixels'
            },
            false: {
              placeholder: require('@builder/assets/images/ad-banner-1.png'),
              description: 'Horiztonal List - 1920 x 1080 pixels'
            }
          }
        }
      }
    },
    {
      id: 3,
      name: 'Large Featured Posts',
      component: 'LargeFeaturedPostsWrapper',
      sidebar: 0,
      ad: false
    }
  ],
  settings: null,
  draftSettings: null
})

const getters = {}

const actions = {
  async loadSettings ({state, rootState, dispatch}) {
    VEvent.fire('loader', true)
    return await axios.get(`api/projects/${rootState.projects.project.id}/blog/settings`)
      .then(({data}) => {
        if (!data.data) {
          state.draftSettings = {...draftSettings}
          state.settings = {...draftSettings}
        } else {
          state.draftSettings = {
            ...data.data,
            ad_banner: data.data.banner ? JSON.parse(data.data.banner.banner): draftSettings.ad_banner,
            layout: JSON.parse(data.data.layout),
            hero_banner: JSON.parse(data.data.hero_banner),
            widget_sidebar: JSON.parse(data.data.widget_sidebar),
            social_accounts: JSON.parse(data.data.social_accounts),
            primaryColor: data.data.primary_color
          }
          state.settings = {
            ...data.data,
            ad_banner: data.data.banner ? JSON.parse(data.data.banner.banner): draftSettings.ad_banner,
            layout: JSON.parse(data.data.layout),
            hero_banner: JSON.parse(data.data.hero_banner),
            widget_sidebar: JSON.parse(data.data.widget_sidebar),
            social_accounts: JSON.parse(data.data.social_accounts),
            primaryColor: data.data.primary_color
          }
        }
        return dispatch('authors/fetchAuthors', null, {root: true})
      })
      .finally(() => {
        VEvent.fire('loader', false)
      })
  },
  async store ({state, rootState}) {
    return await axios.put(`api/projects/${rootState.projects.project.id}/blog/settings`, {
      ...state.draftSettings,
      layout: JSON.stringify(state.draftSettings.layout),
      hero_banner: JSON.stringify(state.draftSettings.hero_banner),
      widget_sidebar: JSON.stringify(state.draftSettings.widget_sidebar),
      social_accounts: JSON.stringify(state.draftSettings.social_accounts),
      banner: JSON.stringify(state.draftSettings.ad_banner),
      primary_color: state.draftSettings.primaryColor
    })
  }
}

const mutations = {}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
