import SectionProvider from '@builder/sections/SectionProvider'
import Navbar from './Navbar'

export const name = 'Hero_007'
export const group = 'hero'
export const defaultOptions = {
  'content': {
    'items': {
      'type': null,
      'children': [
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'display': true,
                      'name': 'Flex Wrapper',
                      'hideFromSections': false,
                      'component': 'ElFlexWrapperV2',
                      'group': '',
                      'layout': null,
                      'options': {
                        'content': {
                          'display': true,
                          'cols': [
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'uid': 'Xd7RDKF59',
                                  'display': true,
                                  'name': 'Subheadline',
                                  'sid': 'ldx0uuu--vZVJ5SwpA',
                                  'hideFromSections': false,
                                  'component': 'ElSubheadlineV2',
                                  'group': 'Text',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'description': {
                                        'type': 'tiptap-mod-v2',
                                        'display': true,
                                        'tag': 'h4',
                                        'resolutionStyle': {
                                          'sm': {},
                                          'md': {},
                                          'lg': {}
                                        },
                                        'text': '<p>Take Care of Your Skin.</p>',
                                        'animations': {
                                          'animation': 'fade-up',
                                          'once': true,
                                          'offset': 200,
                                          'delay': '190',
                                          'duration': '1000'
                                        }
                                      }
                                    },
                                    'customize': null
                                  },
                                  'editor': 'project',
                                  '_uid': 'ldx0uuu--vZVJ5SwpA'
                                },
                                {
                                  'uid': 'FdIi15bEs',
                                  'display': true,
                                  'name': 'Headline',
                                  'sid': 'lldx0uuu--kT_qZi0uQW',
                                  'hideFromSections': false,
                                  'component': 'ElHeadlineV2',
                                  'group': 'Text',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'description': {
                                        'type': 'tiptap-mod-v2',
                                        'display': true,
                                        'tag': 'h1',
                                        'resolutionStyle': {
                                          'sm': {},
                                          'md': {},
                                          'lg': {}
                                        },
                                        'text': '<p>Over five months, <em>your skin changes.</em></p>',
                                        'animations': {
                                          'animation': 'fade-up',
                                          'once': true,
                                          'offset': 200,
                                          'delay': '120',
                                          'duration': '1200'
                                        }
                                      }
                                    }
                                  },
                                  'editor': 'project'
                                },
                                {
                                  'uid': 'QJTUf_fy6',
                                  'display': true,
                                  'name': 'Subheadline',
                                  'sid': 'lldx0uuu--4S-DN6de2R',
                                  'hideFromSections': false,
                                  'component': 'ElSubheadlineV2',
                                  'group': 'Text',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'description': {
                                        'type': 'tiptap-mod-v2',
                                        'display': true,
                                        'tag': 'h4',
                                        'resolutionStyle': {
                                          'sm': {},
                                          'md': {},
                                          'lg': {}
                                        },
                                        'text': '<p>Your moisturiser should, too.</p>',
                                        'animations': {
                                          'animation': 'fade-up',
                                          'once': true,
                                          'offset': 200,
                                          'delay': '190',
                                          'duration': '1400'
                                        }
                                      }
                                    },
                                    'customize': null
                                  },
                                  'editor': 'project'
                                },
                                {
                                  'uid': 'shfXzOZx5',
                                  'display': true,
                                  'name': 'Button',
                                  'sid': 'lldx0uuu--rfOg7tb7RS',
                                  'hideFromSections': false,
                                  'component': 'ElButtonV2',
                                  'group': 'Media',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'button': {
                                        'pressetID': 0,
                                        'display': true,
                                        'type': 'button-mod-v2',
                                        'text': 'FIND OUT MORE',
                                        'buttonType': 'button',
                                        'icon': {
                                          'lottie': {
                                            'jsonPath': '',
                                            'loop': true,
                                            'autoplay': true
                                          }
                                        },
                                        'hoverAnimation': {},
                                        'animations': {
                                          'animation': 'fade-up',
                                          'once': true,
                                          'offset': 200,
                                          'delay': '230',
                                          'duration': '900'
                                        }
                                      }
                                    },
                                    'customize': null
                                  },
                                  'editor': 'project'
                                }
                              ],
                              'sortIndex': {
                                'lg': 0,
                                'md': 0,
                                'sm': 0
                              },
                              'uid': '2ZO84XvckM',
                              'sid': 'lldx0uuu--7t5_5tzCZS',
                              '_uid': 'lqpDxSFn8',
                              'animations': {
                                'animation': null,
                                'once': true,
                                'offset': 200,
                                'delay': 50
                              },
                              'highlight': false
                            }
                          ],
                          'animations': {
                            'animation': null,
                            'once': true,
                            'offset': 200,
                            'delay': 50
                          }
                        }
                      },
                      'uid': '8tMbF5Qrs',
                      'sid': 'lldx0uuu--mU1ZNn3Qu3',
                      'temp_id': 'j0pbRmZok'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': '4kyKCgLiPJ',
                  'sid': 'lldx0uuu--j7ZNN8TenN',
                  'highlight': false,
                  '_uid': 'f-LCml_q_V',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                },
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [],
                  'sortIndex': {
                    'lg': 1,
                    'md': 1,
                    'sm': 1
                  },
                  'uid': 'Ht-LoOsTLG',
                  'sid': 'lldx0uuu--uNMBQoS3rb',
                  'highlight': false,
                  '_uid': '2JgBeiYMYs',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': '6D8vfB4Jk',
          'sid': 'lldx0uuu--QHDPqqG3Ob',
          'temp_id': 'SpR-HP7X_'
        },
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'u_2_b9DPB',
                      'display': true,
                      'name': 'Image',
                      'sid': 'lldx0uuu--e87hYTqSVt',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': 'D1Spzp19_',
                      'display': true,
                      'name': 'Image',
                      'sid': 'ldx0uuu--mBAzqEX-H',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project',
                      '_uid': 'ldx0uuu--mBAzqEX-H'
                    },
                    {
                      'uid': '-STB-tY3t',
                      'display': true,
                      'name': 'Image',
                      'sid': 'ldx0uuu--ObGU7PSAP',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project',
                      '_uid': 'ldx0uuu--ObGU7PSAP'
                    },
                    {
                      'uid': 'm3wDOrYgD',
                      'display': true,
                      'name': 'Image',
                      'sid': 'ldx0uuu--VZDRfxlwH',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project',
                      '_uid': 'ldx0uuu--VZDRfxlwH'
                    },
                    {
                      'uid': '-rRJ8lu4Q',
                      'display': true,
                      'name': 'Image',
                      'sid': 'ldx0uuu--S2VhIadyp',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project',
                      '_uid': 'ldx0uuu--S2VhIadyp'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': 'lezUpH_VbJ',
                  'sid': 'lldx0uuu--yJ0yjs_QN_',
                  '_uid': 'VYz2GOOlRh',
                  'animations': {
                    'animation': 'fade-in',
                    'once': true,
                    'offset': 200,
                    'delay': '730',
                    'duration': '2300'
                  },
                  'highlight': false
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': 'Vhnvyd6hg',
          'sid': 'lldx0uuu--nAiHi4veJ_',
          'temp_id': 'j0pbRmZok'
        }
      ]
    },
    'lottie': {}
  },
  'backgroundImage': {
    'src': ''
  },
  'backgroundVideo': {
    'enabled': false,
    'url': '',
    'loop': true,
    'ratio': 1
  }
}

export const iframeURL = 'https://my-web-1690845437555.estage.site'

export const beforeBlock = new Navbar().json()

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('BlankV2', options)

    this.name = name
    this.group = group
    this.sid = 'lldx0uuu--xAl67dug9'
    this.sids = [
      'lldx0uuu--xAl67dug9',
      'lldx0uuu--QHDPqqG3Ob',
      'lldx0uuu--j7ZNN8TenN',
      'lldx0uuu--mU1ZNn3Qu3',
      'lldx0uuu--7t5_5tzCZS',
      'ldx0uuu--vZVJ5SwpA',
      'lldx0uuu--kT_qZi0uQW',
      'lldx0uuu--4S-DN6de2R',
      'lldx0uuu--rfOg7tb7RS',
      'lldx0uuu--uNMBQoS3rb',
      'lldx0uuu--nAiHi4veJ_',
      'lldx0uuu--yJ0yjs_QN_',
      'lldx0uuu--e87hYTqSVt',
      'ldx0uuu--mBAzqEX-H',
      'ldx0uuu--ObGU7PSAP',
      'ldx0uuu--VZDRfxlwH',
      'ldx0uuu--S2VhIadyp'
    ]
    this.css = [
      [
        '.--lldx0uuu--xAl67dug9--container { max-width: 100vw; padding: 0px; align-items: center; }',
        '.--lldx0uuu--j7ZNN8TenN--flex { max-width: 80vw; --width: 80vw; }',
        '.--lldx0uuu--j7ZNN8TenN--flex-col-el { width: 50%; --width: 50%; }',
        '.--lldx0uuu--uNMBQoS3rb--flex-col-el { width: 50%; --width: 50%; }',
        '.--lldx0uuu--QHDPqqG3Ob--flex { max-width: 100%; --width: 100%; }',
        '.--lldx0uuu--j7ZNN8TenN--flex-col-padding { padding: 0px; }',
        '.--lldx0uuu--uNMBQoS3rb--flex-col-padding { padding: 0px; }',
        '.--lldx0uuu--mU1ZNn3Qu3--flex { max-width: 100%; --width: 100%; }',
        '.--lldx0uuu--7t5_5tzCZS--flex-col-el { width: 600px; --width: 600px; }',
        '.--lldx0uuu--mU1ZNn3Qu3--flex.flex-wrapper { justify-content: end; align-items: center; padding-top: 270px; padding-bottom: 150px; }',
        '.--lldx0uuu--QHDPqqG3Ob--flex.flex-wrapper { align-items: stretch; padding: 0px; }',
        '.--lldx0uuu--j7ZNN8TenN--flex-col { align-items: center; }',
        '.--lldx0uuu--j7ZNN8TenN--flex-col > div { display: inherit; --display: inherit; }',
        '.--lldx0uuu--xAl67dug9--container .block-container { align-items: center; }',
        '.--lldx0uuu--mU1ZNn3Qu3--bg { background-color: rgb(224, 214, 200); }',
        '.--lldx0uuu--kT_qZi0uQW-text { padding: 20px; }',
        '.--lldx0uuu--kT_qZi0uQW-text div { color: rgb(0, 0, 0); font-family: "Playfair Display"; font-weight: 300; line-height: 1; --line-height: 1; text-align: left; font-size: 57px; }',
        '.--lldx0uuu--4S-DN6de2R-text { padding: 7px 20px 20px; }',
        '.--lldx0uuu--4S-DN6de2R-text div { font-family: Barlow; font-weight: 300; color: rgb(0, 0, 0); letter-spacing: 1px; font-size: 23px; text-align: left; }',
        '.--lldx0uuu--rfOg7tb7RS--1 { margin: 20px; background-color: rgb(0, 0, 0); border-radius: 90px; font-size: 14px; font-family: Barlow; }',
        '.--lldx0uuu--rfOg7tb7RS--1 .main-text { justify-content: center; }',
        '.--lldx0uuu--rfOg7tb7RS--2 { justify-content: start; }',
        '.--lldx0uuu--uNMBQoS3rb--bg { background-image: url("https://res.cloudinary.com/estage/image/upload/v1690848402/users/user-572/4ed0cd4295c2832d0277a4dadd711b21.png"); --bg-id: 167772; background-size: cover; --background-size: cover; background-attachment: unset; background-position: left center; }',
        '.--ldx0uuu--vZVJ5SwpA-text { padding: 0px 20px 20px; }',
        '.--ldx0uuu--vZVJ5SwpA-text div { font-family: Barlow; font-weight: 300; color: rgb(0, 0, 0); letter-spacing: 3px; font-size: 14px; text-align: left; text-transform: capitalize; }',
        '.--lldx0uuu--7t5_5tzCZS--flex-col-padding { padding-top: 0px; }',
        '.--lldx0uuu--nAiHi4veJ_--flex { max-width: 100vw; --width: 100vw; }',
        '.--lldx0uuu--yJ0yjs_QN_--flex-col-el { width: 1200px; --width: 1200px; }',
        '.--lldx0uuu--nAiHi4veJ_--flex.flex-wrapper { padding: 0px; }',
        '.--lldx0uuu--nAiHi4veJ_--bg { background-color: rgb(0, 0, 0); }',
        '.--lldx0uuu--e87hYTqSVt--container { justify-content: center; }',
        '.--lldx0uuu--e87hYTqSVt--wrapper { padding: 20px 30px; display: inline-flex; }',
        '.el-image.--lldx0uuu--e87hYTqSVt--image { width: 50px; --width: 50px; }',
        '.--lldx0uuu--e87hYTqSVt--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690333319/users/user-572/f719fd8dd000ed05a3f29bc48a3faa13.png"); --bg-id: 167154; }',
        '.--ldx0uuu--mBAzqEX-H--container { justify-content: center; }',
        '.--ldx0uuu--mBAzqEX-H--wrapper { padding: 20px 30px; display: inline-flex; }',
        '.el-image.--ldx0uuu--mBAzqEX-H--image { width: 105px; --width: 105px; }',
        '.--ldx0uuu--mBAzqEX-H--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690333294/users/user-572/6eb0c5cd6b77eb41d9a05561cce85aec.png"); --bg-id: 167153; }',
        '.--ldx0uuu--ObGU7PSAP--container { justify-content: center; }',
        '.--ldx0uuu--ObGU7PSAP--wrapper { padding: 20px 30px; display: inline-flex; }',
        '.el-image.--ldx0uuu--ObGU7PSAP--image { width: 155px; --width: 155px; }',
        '.--ldx0uuu--ObGU7PSAP--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690333270/users/user-572/e16b0ea0c9ff86858288f70ea2223644.png"); --bg-id: 167152; }',
        '.--lldx0uuu--yJ0yjs_QN_--flex-col { text-align: center; justify-content: center; }',
        '.--ldx0uuu--VZDRfxlwH--container { justify-content: center; }',
        '.--ldx0uuu--VZDRfxlwH--wrapper { padding: 20px 30px; display: inline-flex; }',
        '.el-image.--ldx0uuu--VZDRfxlwH--image { width: 100px; --width: 100px; }',
        '.--ldx0uuu--VZDRfxlwH--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690333238/users/user-572/aee4c0ff2fb825eec60fc8b8320e7dda.png"); --bg-id: 167151; }',
        '.--ldx0uuu--S2VhIadyp--container { justify-content: center; }',
        '.--ldx0uuu--S2VhIadyp--wrapper { padding: 20px 30px; display: inline-flex; }',
        '.el-image.--ldx0uuu--S2VhIadyp--image { width: 100px; --width: 100px; }',
        '.--ldx0uuu--S2VhIadyp--image { content: url("https://res.cloudinary.com/estage/image/upload/v1689814493/users/user-572/92ab82981332420f5b5dfd3e8c55c62f.png"); --bg-id: 166577; }',
        '.--lldx0uuu--rfOg7tb7RS--1:hover { border-color: rgb(0, 0, 0); border-width: 1px; color: rgb(0, 0, 0); background-color: rgba(0, 0, 0, 0); }'
      ],
      [
        '.--lldx0uuu--j7ZNN8TenN--flex { max-width: 100%; --width: 100%; }',
        '.--lldx0uuu--QHDPqqG3Ob--flex { max-width: 100%; --width: 100%; flex-wrap: nowrap; }',
        '.--lldx0uuu--mU1ZNn3Qu3--flex { max-width: 100%; --width: 100%; }',
        '.--lldx0uuu--nAiHi4veJ_--flex { max-width: 100%; --width: 100%; }',
        '.--lldx0uuu--mU1ZNn3Qu3--flex.flex-wrapper { padding-bottom: 70px; padding-top: 200px; }',
        '.--lldx0uuu--uNMBQoS3rb--bg { background-position: 37% bottom; }',
        '.--lldx0uuu--kT_qZi0uQW-text div { font-size: 46px; }',
        '.--lldx0uuu--4S-DN6de2R-text div { font-size: 19px; }',
        '.el-image.--lldx0uuu--e87hYTqSVt--image { width: 40px; --width: 40px; }',
        '.el-image.--ldx0uuu--mBAzqEX-H--image { width: 90px; --width: 90px; }',
        '.el-image.--ldx0uuu--ObGU7PSAP--image { width: 120px; --width: 120px; }',
        '.el-image.--ldx0uuu--VZDRfxlwH--image { width: 70px; --width: 70px; }',
        '.el-image.--ldx0uuu--S2VhIadyp--image { width: 75px; --width: 75px; }'
      ],
      [
        '.--lldx0uuu--j7ZNN8TenN--flex { max-width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lldx0uuu--QHDPqqG3Ob--flex { max-width: 100%; --width: 100%; flex-flow: column wrap; }',
        '.--lldx0uuu--mU1ZNn3Qu3--flex { max-width: 100%; --width: 100%; }',
        '.--lldx0uuu--nAiHi4veJ_--flex { max-width: 100%; --width: 100%; }',
        '.--lldx0uuu--j7ZNN8TenN--flex-col-el { width: 100%; --width: 100%; }',
        '.--lldx0uuu--uNMBQoS3rb--flex-col-el { width: 100%; --width: 100%; height: 400px; --height: 400px; }',
        '.--lldx0uuu--uNMBQoS3rb--flex-col > div { display: contents; --display: contents; }',
        '.--lldx0uuu--mU1ZNn3Qu3--flex.flex-wrapper { padding-top: 150px; padding-bottom: 30px; }',
        '.--lldx0uuu--kT_qZi0uQW-text div { text-align: center; font-size: 36px; line-height: 1.1; --line-height: 1.1; }',
        '.--lldx0uuu--4S-DN6de2R-text div { text-align: center; font-size: 17px; }',
        '.--lldx0uuu--kT_qZi0uQW-text { padding-top: 0px; }',
        '.--ldx0uuu--vZVJ5SwpA-text div { text-align: center; }',
        '.--lldx0uuu--4S-DN6de2R-text { padding-top: 0px; }',
        '.--ldx0uuu--vZVJ5SwpA-text { padding-bottom: 15px; }',
        '.--lldx0uuu--rfOg7tb7RS--2 { justify-content: center; }',
        '.--ldx0uuu--mBAzqEX-H--wrapper { padding-left: 10px; padding-right: 10px; }',
        '.--lldx0uuu--e87hYTqSVt--wrapper { padding-left: 10px; padding-right: 10px; }',
        '.--ldx0uuu--ObGU7PSAP--wrapper { padding-left: 10px; padding-right: 10px; }',
        '.--ldx0uuu--S2VhIadyp--wrapper { padding-left: 10px; padding-right: 10px; padding-top: 0px; }',
        '.--ldx0uuu--VZDRfxlwH--wrapper { padding-left: 10px; padding-right: 10px; padding-top: 0px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
