<template>
  <div class="border-top">
    <draggable
      v-if="favorites"
      v-model="favorites"
      :clone="cloneBlock"
      :group="{ name: group, pull: 'clone', put: false }"
      :sort="false"
      handle=".move"
      :scroll="true"
      :force-fallback="true"
      :fallback-class="`${group}-sortable-fallback`"
      :force-autoscroll-fallback="true"
      :scroll-sensitivity="60"
      :bubble-scroll="true"
      :fallback-on-body="true"
      @choose="setGroup"
    >
      <b-card
        v-for="(favorite, index) in favorites"
        :key="index"
        :data-group="favorite.block.group"
        class="border-0 px-3 py-2 hover:bg-gray-200/80"
        no-body
      >
        <div class="d-flex align-items-center cursor-move border-0">
          <div class="move d-flex align-items-center w-100">
            <div class="mr-3">
              <svg
                v-if="favorite.block.group === 'blocks'"
                fill="none"
                height="20"
                viewBox="0 0 20 20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M19.5555 10.3333C19.5555 15.4266 15.4266 19.5556 10.3333 19.5556C5.24001 19.5556 1.11108 15.4266 1.11108 10.3333C1.11108 5.24004 5.24001 1.11111 10.3333 1.11111C15.4266 1.11111 19.5555 5.24004 19.5555 10.3333ZM10.3333 18.1111C14.6289 18.1111 18.1111 14.6289 18.1111 10.3333C18.1111 6.03779 14.6289 2.55556 10.3333 2.55556C6.03776 2.55556 2.55553 6.03779 2.55553 10.3333C2.55553 14.6289 6.03776 18.1111 10.3333 18.1111Z"
                  fill="#6E747A"
                  fill-rule="evenodd"
                />
                <path
                  d="M14.3333 10.532V9.26798H11.1511V6.14798H9.60442V9.26798H6.40442V10.532H9.60442V13.78H11.1511V10.532H14.3333Z"
                  fill="#6E747A"
                />
              </svg>
              <svg
                v-else-if="favorite.block.group === 'elements'"
                fill="none"
                height="20"
                viewBox="0 0 20 20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.2222 1.11111H2.77775C1.85726 1.11111 1.11108 1.8573 1.11108 2.77778V17.2222C1.11108 18.1427 1.85726 18.8889 2.77775 18.8889H17.2222C18.1427 18.8889 18.8889 18.1427 18.8889 17.2222V2.77778C18.8889 1.8573 18.1427 1.11111 17.2222 1.11111ZM17.7778 17.2222C17.7778 17.5291 17.529 17.7778 17.2222 17.7778H2.77775C2.47091 17.7778 2.2222 17.5291 2.2222 17.2222V2.77778C2.2222 2.47094 2.47091 2.22223 2.77775 2.22223H17.2222C17.529 2.22223 17.7778 2.47094 17.7778 2.77778V17.2222Z"
                  fill="#6E747A"
                  stroke="#6E747A"
                  stroke-width="0.4"
                />
                <path
                  d="M13.1722 10.7167C12.9555 10.5013 12.6056 10.5013 12.3889 10.7167L11.1111 11.9945L8.72775 9.61116C8.51105 9.39575 8.16112 9.39575 7.94442 9.61116L4.05553 13.5001C3.84074 13.7191 3.84418 14.0709 4.06327 14.2857C4.16518 14.3856 4.30168 14.4425 4.44442 14.4445H15.5555C15.8623 14.4463 16.1125 14.199 16.1143 13.8922C16.1152 13.7429 16.0559 13.5996 15.95 13.4945L13.1722 10.7167Z"
                  fill="#6E747A"
                />
                <path
                  d="M11.6667 8.88889C12.5871 8.88889 13.3333 8.1427 13.3333 7.22222C13.3333 6.30175 12.5871 5.55556 11.6667 5.55556C10.7462 5.55556 10 6.30175 10 7.22222C10 8.1427 10.7462 8.88889 11.6667 8.88889Z"
                  fill="#6E747A"
                />
              </svg>
            </div>

            <div
              v-b-tooltip
              class="pt-1 text-truncate w-[100px]"
              :title="startCase(favorite.block.section.name)"
            >
              {{ startCase(favorite.block.section.name) }}
            </div>
          </div>

          <div class="position-relative">
            <b-dropdown menu-class="bg-white p-0">
              <template #button-content>
                <div class="py-2">
                  <svg
                    width="24"
                    height="5"
                    viewBox="0 0 24 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="2.5"
                      cy="2.5"
                      r="2.5"
                      fill="#4F83E3"
                    />
                    <circle
                      cx="12.0713"
                      cy="2.5"
                      r="2.5"
                      fill="#4F83E3"
                    />
                    <circle
                      cx="21.3569"
                      cy="2.5"
                      r="2.5"
                      fill="#4F83E3"
                    />
                  </svg>
                </div>
              </template>

              <div class="flex flex-column w-[200px] p-3 gap-3 text-[15px]">
                <preview-element-modal :element="favorite" />

                <b-link
                  class="link-muted"
                  @click.prevent="() => globalFavorite(favorite)"
                >
                  <b-spinner
                    v-if="loading === favorite.id"
                    small
                  />
                  <div
                    v-else
                    class="flex items-center gap-[8px]"
                  >
                    <i
                      :class="{'text-primary': favorite.user_id}"
                      class="fa fa-globe-americas"
                    />
                    {{ favorite.user_id ? 'Remove from global' : 'Add globally' }}
                  </div>
                </b-link>

                <delete-confirm
                  title="Are you sure?"
                  @confirm="() => $store.dispatch('favorites/removeFavorite', {favorite, index})"
                >
                  <template v-slot:activator="{on}">
                    <b-link
                      class="link-muted flex items-center gap-[8px]"
                      v-on="on"
                    >
                      <svg
                        height="16"
                        viewBox="0 0 16 17"
                        width="15"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.6395 6.03584L9.37117 5.98779L9.09619 13.7428L10.3645 13.7907L10.6395 6.03584Z"
                          fill="#6E747A"
                        />
                        <path
                          d="M8.18616 6.01184H6.91699V13.7669H8.18616V6.01184Z"
                          fill="#6E747A"
                        />
                        <path
                          d="M6.00676 13.7427L5.73178 5.98761L4.46344 6.03569L4.73845 13.7907L6.00676 13.7427Z"
                          fill="#6E747A"
                        />
                        <path
                          d="M0 2.77875V4.08945H1.32249L2.37165 16.4021C2.40041 16.7405 2.67479 17 3.0037 17H12.0781C12.4071 17 12.6816 16.7403 12.7102 16.4019L13.7594 4.08945H15.1032V2.77875H0ZM11.4969 15.6893H3.5848L2.59653 4.08945H12.4853L11.4969 15.6893Z"
                          fill="#6E747A"
                        />
                        <path
                          d="M9.94428 0H5.84654C5.27014 0 4.80121 0.53181 4.80121 1.1855V3.438H6.0556V1.42261H9.73521V3.438H10.9896V1.1855C10.9896 0.53181 10.5207 0 9.94428 0Z"
                          fill="#6E747A"
                        />
                      </svg>
                      Remove
                    </b-link>
                  </template>
                </delete-confirm>
              </div>
            </b-dropdown>
          </div>
        </div>
      </b-card>
    </draggable>
    <div
      v-else
      class="d-flex align-items-center justify-content-center h-100 py-5 "
    >
      <b-spinner
        small
        variant="primary"
      />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import elements from '@builder/components/builder/elements'
import TextMixins from '../../mixins/TextMixins'
import {mapState} from 'vuex'
import shortid from 'shortid'
import PreviewElementModal from '@/layouts/dashboard/compontens/templates/PreviewElementModal'
import DeleteConfirm from '@/components/editor/modals/DeleteConfirm.vue'

export default {
  name: 'FavoritesDragItems',
  components: {DeleteConfirm, PreviewElementModal, draggable},

  mixins: [TextMixins],

  data () {
    return {
      group: null,
      loading: false
    }
  },

  computed: {
    elements () {
      return elements
    },
    ...mapState('favorites', {
      favorites: state => state.favorites
    }),
    ...mapState('auth', {
      user: state => state.user
    })
  },

  created () {
    this.$store.dispatch('favorites/fetchFavorites')
  },

  methods: {
    globalFavorite (favorite) {
      this.loading = favorite.id
      return this.$store.dispatch('favorites/addToGlobal', {favorite, inGlobal: favorite.user_id})
        .then(() => {
          if (!favorite.user_id) {
            return this.$set(favorite, 'user_id', this.user.id)
          }

          this.$set(favorite, 'user_id', 0)
        })
        .finally(() => {
          this.loading = false
        })
    },
    cloneBlock ({block}) {
      return {
        ...block.section,
        css: block.css,
        uid: shortid.generate()
      }
    },
    setGroup (evt) {
      this.group = evt.item.getAttribute('data-group')
    }
  }
}
</script>
