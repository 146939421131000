<template>
  <div>
    <StateTabs
      :states-text="['Top', 'Bottom']"
    >
      <b-form-group label="Divider Type">
        <v-select
          v-model="topType"
          :options="shapeOptions"
          :reduce="val => val.key"
          :searchable="false"
          placeholder="None"
        >
          <template v-slot:selected-option="option">
            <div class="h-[24px]">
              <img
                v-if="option.preview"
                :src="option.preview"
                class="h-[20px]"
              >
              <span
                v-else
                v-text="option.text"
              />
            </div>
          </template>

          <template v-slot:option="option">
            <img
              v-if="option.preview"
              :src="option.preview"
              class="h-[24px]"
            >
            <span
              v-else
              v-text="option.text"
            />
          </template>
        </v-select>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <color-picker
            v-model="topColor"
            :color="topColor"
          />

          <label class="mb-0 pb-0">Color</label>
        </div>
      </b-form-group>

      <b-form-group
        label="Height"
        label-class="!pb-[3px]"
      >
        <b-row
          class="align-items-center pl-2"
          no-gutters
        >
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="topHeight"
              :interval="1"
              :max="500"
              :min="0"
              class="editor-slider-1 pr-3"
              tooltip="none"
            />
          </b-col>
          <b-col>
            <LinkedControlItem
              v-model="topHeight"
              :max="500"
              :min="0"
              class="d-block !w-[70px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label
            class="mb-0 pb-0"
            for="required-mark2"
          >Flip</label>

          <b-checkbox
            id="required-mark2"
            v-model="topFlip"
            switch
            value="1"
          />
        </div>
      </b-form-group>

      <b-form-group class="mb-0">
        <div class="flex items-center justify-between">
          <label
            class="mb-0 pb-0"
            for="required-mark5"
          >Invert</label>

          <b-checkbox
            id="required-mark5"
            v-model="topInvert"
            switch
            value="1"
          />
        </div>
      </b-form-group>

      <template slot="action-state">
        <b-form-group label="Divider Type">
          <v-select
            v-model="bottomType"
            :options="shapeOptions"
            :reduce="val => val.key"
            :searchable="false"
            placeholder="None"
          >
            <template v-slot:selected-option="option">
              <div class="h-[24px]">
                <img
                  v-if="option.preview"
                  :src="option.preview"
                  class="h-[20px] transform rotate-180"
                >
                <span
                  v-else
                  v-text="option.text"
                />
              </div>
            </template>

            <template v-slot:option="option">
              <img
                v-if="option.preview"
                :src="option.preview"
                class="h-[24px] transform rotate-180"
              >
              <span
                v-else
                v-text="option.text"
              />
            </template>
          </v-select>
        </b-form-group>

        <b-form-group>
          <div class="flex items-center gap-[10px]">
            <color-picker
              v-model="bottomColor"
              :color="bottomColor"
            />

            <label class="mb-0 pb-0">Color</label>
          </div>
        </b-form-group>

        <b-form-group
          label="Height"
          label-class="!pb-[3px]"
        >
          <b-row
            class="align-items-center pl-2"
            no-gutters
          >
            <b-col
              cols="8"
            >
              <vue-slider
                v-model="bottomHeight"
                :interval="1"
                :max="500"
                :min="0"
                class="editor-slider-1 pr-3"
                tooltip="none"
              />
            </b-col>
            <b-col>
              <LinkedControlItem
                v-model="bottomHeight"
                :max="500"
                :min="0"
                class="d-block !w-[70px]"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <div class="flex items-center justify-between">
            <label
              class="mb-0 pb-0"
              for="required-mark3"
            >Flip</label>

            <b-checkbox
              id="required-mark3"
              v-model="bottomFlip"
              switch
              value="1"
            />
          </div>
        </b-form-group>

        <b-form-group class="mb-0">
          <div class="flex items-center justify-between">
            <label
              class="mb-0 pb-0"
              for="required-mark6"
            >Invert</label>

            <b-checkbox
              id="required-mark6"
              v-model="bottomInvert"
              switch
              value="1"
            />
          </div>
        </b-form-group>
      </template>
    </StateTabs>
  </div>
</template>

<script>
import _ from 'lodash'
import ModControlMixin from '@/components/mixins/ModControlMixin'
import StateTabs from '@/components/editor/components/StateTabs'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

export default {
  name: 'ShapeDividerCustoms',
  components: {LinkedControlItem, StateTabs},
  mixins: [ModControlMixin],
  data () {
    return {
      shapeOptions: [
        {
          key: null,
          text: 'None'
        },
        {
          key: 1,
          preview: require('@builder/assets/png/divider-shapes/1.png')
        },
        {
          key: 2,
          preview: require('@builder/assets/png/divider-shapes/2.png')
        },
        {
          key: 3,
          preview: require('@builder/assets/png/divider-shapes/3.png')
        },
        {
          key: 4,
          preview: require('@builder/assets/png/divider-shapes/4.png')
        },
        {
          key: 5,
          preview: require('@builder/assets/png/divider-shapes/5.png')
        },
        {
          key: 6,
          preview: require('@builder/assets/png/divider-shapes/6.png')
        },
        {
          key: 7,
          preview: require('@builder/assets/png/divider-shapes/7.png')
        },
        {
          key: 8,
          preview: require('@builder/assets/png/divider-shapes/8.png')
        },
        {
          key: 9,
          preview: require('@builder/assets/png/divider-shapes/9.png')
        },
        {
          key: 10,
          preview: require('@builder/assets/png/divider-shapes/10.png')
        }
      ]
    }
  },
  computed: {
    shapeDivider () {
      return _.get(this.config, 'shapeDivider', {})
    },
    topType: {
      get () {
        return _.get(this.shapeDivider, ['top', 'type', this.resolutioner], null)
      },
      set (val) {
        if (this.resolutioner === 'lg') {
          const lg = _.get(this.shapeDivider, ['top', 'type', 'md'], null)
          const md = _.get(this.shapeDivider, ['top', 'type', 'md'], null)
          const sm = _.get(this.shapeDivider, ['top', 'type', 'sm'], null)
          const obj = {
            lg: val,
            md: md === lg ? val : md,
            sm: sm === lg ? val : sm
          }

          return this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['top', 'type'], obj)})
        }

        this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['top', 'type', this.resolutioner], val)})
      }
    },
    bottomType: {
      get () {
        return _.get(this.shapeDivider, ['bottom', 'type', this.resolutioner], null)
      },
      set (val) {
        if (this.resolutioner === 'lg') {
          const lg = _.get(this.shapeDivider, ['bottom', 'type', 'md'], null)
          const md = _.get(this.shapeDivider, ['bottom', 'type', 'md'], null)
          const sm = _.get(this.shapeDivider, ['bottom', 'type', 'sm'], null)
          const obj = {
            lg: val,
            md: md === lg ? val : md,
            sm: sm === lg ? val : sm
          }

          return this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['bottom', 'type'], obj)})
        }

        this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['bottom', 'type', this.resolutioner], val)})
      }
    },
    topColor: {
      get () {
        return _.get(this.shapeDivider, 'top.color', '#d9d9d9')
      },
      set (val) {
        this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['top', 'color'], val.hex8)})
      }
    },
    bottomColor: {
      get () {
        return _.get(this.shapeDivider, 'bottom.color', '#d9d9d9')
      },
      set (val) {
        this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['bottom', 'color'], val.hex8)})
      }
    },
    topHeight: {
      get () {
        return parseInt(_.get(this.shapeDivider, ['top', 'height', this.resolutioner], 200))
      },
      set (val) {
        if (this.resolutioner === 'lg') {
          const lg = parseInt(_.get(this.shapeDivider, ['top', 'height', 'md'], 200))
          const md = parseInt(_.get(this.shapeDivider, ['top', 'height', 'md'], 200))
          const sm = parseInt(_.get(this.shapeDivider, ['top', 'height', 'sm'], 200))

          const obj = {
            lg: val,
            md: md === lg ? val : md,
            sm: sm === lg ? val : sm
          }

          return this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['top', 'height'], obj)})
        }

        this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['top', 'height', this.resolutioner], val)})
      }
    },
    bottomHeight: {
      get () {
        return _.get(this.shapeDivider, ['bottom', 'height', this.resolutioner], 200)
      },
      set (val) {
        if (this.resolutioner === 'lg') {
          const lg = _.get(this.shapeDivider, ['bottom', 'height', 'md'], 200)
          const md = _.get(this.shapeDivider, ['bottom', 'height', 'md'], 200)
          const sm = _.get(this.shapeDivider, ['bottom', 'height', 'sm'], 200)
          const obj = {
            lg: val,
            md: md === lg ? val : md,
            sm: sm === lg ? val : sm
          }

          return this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['bottom', 'height'], obj)})
        }

        this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['bottom', 'height', this.resolutioner], val)})
      }
    },
    topFlip: {
      get () {
        return _.get(this.shapeDivider, ['top', 'flip', this.resolutioner], '#fff')
      },
      set (val) {
        this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['top', 'flip', this.resolutioner], val)})
      }
    },
    bottomFlip: {
      get () {
        return _.get(this.shapeDivider, ['bottom', 'flip', this.resolutioner], '#fff')
      },
      set (val) {
        this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['bottom', 'flip', this.resolutioner], val)})
      }
    },
    topInvert: {
      get () {
        return _.get(this.shapeDivider, ['top', 'invert', this.resolutioner], '#fff')
      },
      set (val) {
        this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['top', 'invert', this.resolutioner], val)})
      }
    },
    bottomInvert: {
      get () {
        return _.get(this.shapeDivider, ['bottom', 'invert', this.resolutioner], '#fff')
      },
      set (val) {
        this.$set(this.config, 'shapeDivider', {..._.set(this.shapeDivider, ['bottom', 'invert', this.resolutioner], val)})
      }
    }
  }
}
</script>