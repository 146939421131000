<template>
  <div>
    <blog-add-category
      :editable-category="editableCategory"
      @close="() => editableCategory = null"
    />

    <ul
      v-if="categories.length"
      class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4 pl-0 list-unstyled"
    >
      <li class="col-span-1">
        <b-btn
          v-b-modal.blog-category-modal-settings
          block
          variant="white"
          class="py-3"
        >
          <i class="fa fa-plus-circle mr-2" /> Add New Category
        </b-btn>
      </li>
      <li
        v-for="(category, index) in categories"
        :key="category.id"
        class="border bg-white col-span-1 flex shadow-sm rounded-md"
      >
        <div
          class="d-flex justify-content-between align-items-center px-3 w-100"
          style="height: 55px;"
        >
          <div class="font-weight-bold">
            {{ category.name }}
          </div>
          <b-dropdown toggle-class="bg-white border-0">
            <template slot="button-content">
              <i
                class="fa fa-ellipsis-h dot-menu fa-lg link-muted cursor-pinter"
                icon="dot-menu.svg"
              />
            </template>

            <b-dropdown-item @click="edit(category)">
              Edit
            </b-dropdown-item>
            <b-dropdown-item @click="remove(index, category)">
              Remove
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </li>
    </ul>

    <div
      v-else
      class="text-center"
    >
      <p class="text-muted">
        No Categories Eat
      </p>
      <b-btn
        v-b-modal.blog-category-modal-settings
        variant="white"
      >
        Add New Category
      </b-btn>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import BlogAddCategory from './modals/BlogAddCategory'

export default {
  name: 'BlogCategoriesSettings',
  components: {BlogAddCategory},
  data () {
    return {
      name: '',
      editableCategory: null
    }
  },
  computed: {
    ...mapState('blogCategories', {
      categories: state => state.categories
    })
  },
  created () {
    this.$store.dispatch('blogCategories/laodCategories')
  },
  methods: {
    edit (category) {
      this.editableCategory = category
    },
    remove (index, category) {
      this.$store.dispatch('blogCategories/remove', category.id)
        .then(() => {
          this.categories.splice(index, 1)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@builder/assets/scss/_variables.scss";


</style>
