<template>
  <b-form
    v-if="!message"
    @submit.prevent="submit"
  >
    <h3 class="font-family-base-3 font-weight-medium mb-1">
      Reset Your Password
    </h3>
    <p class="font-family-base-2 font-size-lg mb-4">
      Please enter the email address associated with your ESTAGE account. We will send you an email with a link to reset your password.
    </p>

    <div class="form-group">
      <label class="font-family-base-2 font-size-lg">Your Email</label>
      <input
        v-model="$v.form.email.$model"
        type="email"
        class="form-control"
        :class="{'is-invalid': $v.form.email.$error}"
        placeholder="Email"
        autocomplete="off"
        style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAkCAYAAADo6zjiAAAAAXNSR0IArs4c6QAAAbNJREFUWAntV8FqwkAQnaymUkpChB7tKSfxWCie/Yb+gbdeCqGf0YsQ+hU95QNyDoWCF/HkqdeiIaEUqyZ1ArvodrOHxanQOiCzO28y781skKwFW3scPV1/febP69XqarNeNTB2KGs07U3Ttt/Ozp3bh/u7V7muheQf6ftLUWyYDB5yz1ijuPAub2QRDDunJsdGkAO55KYYjl0OUu1VXOzQZ64Tr+IiPXedGI79bQHdbheCIAD0dUY6gV6vB67rAvo6IxVgWVbFy71KBKkAFaEc2xPQarXA931ot9tyHphiPwpJgSbfe54Hw+EQHMfZ/msVEEURjMfjCjbFeG2dFxPo9/sVOSYzxmAwGIjnTDFRQLMQAjQ5pJAQkCQJ5HlekeERxHEsiE0xUUCzEO9AmqYQhiF0Oh2Yz+ewWCzEY6aYKKBZCAGYs1wuYTabKdNNMWWxnaA4gp3Yry5JBZRlWTXDvaozUgGTyQSyLAP0dbb3DtQlmcan0yngT2ekE9ARc+z4AvC7nauh9iouhpcGamJeX8XF8MaClwaeROWRA7nk+tUnyzGvZrKg0/40gdME/t8EvgG0/NOS6v9NHQAAAABJRU5ErkJggg==&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%; cursor: auto;"
      >
      <template v-if="$v.form.email.$error">
        <div
          v-if="!$v.form.email.required"
          class="text-danger"
        >
          Email address field is required.
        </div>
        <div
          v-if="!$v.form.email.email"
          class="text-danger"
        >
          Enter valid email address.
        </div>
      </template>
    </div>
    <b-btn
      type="submit"
      variant="primary"
      class="d-flex align-items-center justify-content-center btn btn-primary btn-fill btn-block"
      :disabled="loading"
    >
      <span class="mr-2">Send Email</span><b-spinner
        v-if="loading"
        small
      />
    </b-btn>

    <slot name="footer" />
  </b-form>
  <div
    v-else
    class="text-center"
  >
    <svg
      class="mb-5"
      width="226"
      height="226"
      viewBox="0 0 226 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.5"
        y="4.5"
        width="217"
        height="217"
        rx="108.5"
        stroke="#598AEB"
        stroke-width="9"
      />
      <path
        d="M161 131.408V82C161 76.4771 156.523 72 151 72H75C69.4772 72 65 76.4772 65 82V132.286C65 137.809 69.4772 142.286 75 142.286H148.424M148.424 142.286L133.332 130.153M148.424 142.286L133.332 154"
        stroke="#598AEB"
        stroke-width="9"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M68 75L112.419 106L156 75"
        stroke="#598AEB"
        stroke-opacity="0.78"
        stroke-width="9"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <h5
      class="mb-0"
      v-text="message"
    />
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'ResetPassword',
  data () {
    return {
      message: null,
      form: {
        email: ''
      },
      loading: false
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  },
  methods: {
    submit () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.loading = true

        this.$store.dispatch('auth/forget', this.form)
          .then(({data}) => {
            this.message = data.data
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
