<template>
  <div class="bg-[#F3F8FA] pt-[64px] pb-[82px] pl-[88px] pr-[73px] gap-[105px]">
    <div class="text-center flex flex-column items-center">
      <h2 class="text-[33px] leading-[41px] mb-[21px]">
        Congratulations! Your Cloudflare Enterprise <br> License is Ready For Activation!
      </h2>

      <p class="text-[#666666] text-[18px] leading-[30px] mb-[52px]">
        To activate your Cloudflare Enterprise license, just click the button below <br> and our system will do the rest.
      </p>

      <button
        class="flex items-center hover:opacity-80 justify-center rounded-[3px] !text-[17px] text-white h-[55px] bg-[#F5821F] px-[40px]"
        @click="submit"
      >
        Activate Cloudflare Enterprise For This Project <b-spinner
          v-if="loading"
          variant="white"
          small
          class="ml-2"
        />
      </button>

      <slot />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'CloudflareEnterprisePurchasedHero',
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  methods: {
    submit () {
      this.loading = true
      axios.get(`api/projects/${this.project.id}/cf-enterprise`)
        .then(() => {
          this.project.cf_enterprise_verified = 1
        })
        .catch(error => {
          this.$swal({
            icon: 'warning',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
          })
        })
        .finally(() => {
          window.scrollTo({top: 0, behavior: 'smooth'})
          this.loading = false
        })
    }
  }
}
</script>