<template>
  <div
    :style="{...fullPaddingSubject(section.options.customize.style), ...opacity, ...dropShadow, ...marginV2, ...transform}"
    class="text-center position-relative"
  >
    <slot />
  </div>
</template>

<script>
import _ from 'lodash'
import TemplateMixin from '../../../../mixins/TemplateMixin'
import ResolutionMixin from '../../../../mixins/ResolutionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'TemplateV2',
  mixins: [TemplateMixin, ResolutionMixin, BorderMixin],
  computed: {
    transform () {
      return {
        transform: `translate(${_.get(this.section.options.content.image, ['translateX', this.resolutioner], 0)}px, ${_.get(this.section.options.content.image, ['translateY', this.resolutioner], 0)}px)`
      }
    }
  }
}
</script>

<style>
.rounded-bubble {
  mask-image: url("data:image/svg+xml,%3Csvg width='422' height='401' viewBox='0 0 422 401' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M337.854 33.0486C367.393 48.074 390.866 74.698 401.153 105.276C411.439 135.59 408.01 169.332 411.702 205.445C415.658 241.559 426.472 279.782 419.878 316.423C413.285 353.064 389.284 387.859 356.052 397.613C322.82 407.102 280.621 391.813 244.488 381.269C208.355 370.725 178.288 364.926 143.21 356.49C108.132 347.791 68.043 336.193 40.3499 310.36C12.9204 284.263 -2.37679 243.932 1.57938 205.709C5.53555 167.487 28.7451 131.373 50.3721 94.9955C71.9992 58.6182 92.0438 21.9772 123.166 8.00624C154.288 -5.96476 196.487 2.99777 234.993 9.06066C273.5 15.1235 308.578 18.0232 337.854 33.0486Z' fill='%23EDEDED'/%3E%3C/svg%3E%0A");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
</style>
