<script>
import { realTimeDB } from '@/firebase.js'
import {ref, onValue, set, onDisconnect, remove, serverTimestamp, update} from 'firebase/database'
import {mapState} from 'vuex'
import shortid from 'shortid'

export default {
  name: 'SessionControlModal',
  data () {
    return {
      modal: false,
      userRef: null,
      userSessionRef: null,
      sessionID: null,
      sessions: null,
      userRefListener: null,
      connectionRefListener: null,
      init: false
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('projects', {
      project: state => state.project
    })
  },
  async mounted () {
    this.userRef = ref(realTimeDB, `/sessions/${this.user.id}`)
    this.sessionID = shortid.generate()
    this.userSessionRef = ref(realTimeDB, `/sessions/${this.user.id}/${this.sessionID}`)
    const connectedRef = ref(realTimeDB, '.info/connected')

    this.userRefListener = onValue(this.userRef, snapshot => {
      this.sessions = snapshot.val()
      const projectSessionCount = Object.values(this.sessions).filter(({project}) => project === this.project.id).length

      if (!snapshot.val()[this.sessionID] && this.init && this.$route.name === 'editor') {
        return window.location = '/'
      }

      this.modal = projectSessionCount >= 2
    })

    this.connectionRefListener = onValue(connectedRef, snapshot => {
      if (snapshot.val()) {
        this.init = true
        set(this.userSessionRef, {
          project: this.project.id,
          timestamp: serverTimestamp()
        }).then(() => {
          onDisconnect(this.userSessionRef).remove()
        })
      }
    })
  },
  beforeDestroy () {
    remove(this.userSessionRef)

    if (this.userRefListener) this.userRefListener()
    if (this.connectionRefListener) this.connectionRefListener()
  },
  methods: {
    closeOtherSessions () {
      const sessionsToRemove = Object.entries(this.sessions)
        .filter(entry => entry[1].project === this.project.id)
        .filter(entry => entry[0] !== this.sessionID)
        .reduce((acc, val) => {
          acc[val[0]] = null
          return acc
        }, {})

      update(this.userRef, sessionsToRemove)
    }
  }
}
</script>

<template>
  <b-modal
    v-model="modal"
    hide-header
    hide-footer
    body-class="!py-[47px]"
    content-class="!w-[949px]"
    dialog-class="!max-w-[949px]"
    centered
  >
    <svg
      class="hover:opacity-60 cursor-pointer absolute top-5 right-5"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="modal = false"
    >
      <path
        d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
        fill="#6E747A"
      />
    </svg>


    <div class="text-center flex items-center flex-col">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mb-[23px]"
      >
        <path
          d="M13.5 16.5C13.5 17.3284 12.8284 18 12 18C11.1716 18 10.5 17.3284 10.5 16.5C10.5 15.6716 11.1716 15 12 15C12.8284 15 13.5 15.6716 13.5 16.5Z"
          fill="#3D82EA"
        />
        <path
          d="M11.5868 8C11.0018 8 10.5417 8.50003 10.5903 9.08305L10.9618 13.5415C10.9834 13.8007 11.2 14 11.4601 14H12.5399C12.8 14 13.0166 13.8007 13.0382 13.5415L13.4097 9.08305C13.4583 8.50003 12.9982 8 12.4132 8H11.5868Z"
          fill="#3D82EA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.39527 2.54283C10.547 0.527389 13.453 0.52739 14.6047 2.54283L23.1584 17.5117C24.3012 19.5117 22.8571 22.0001 20.5536 22.0001H3.44636C1.14289 22.0001 -0.301211 19.5117 0.841629 17.5117L9.39527 2.54283ZM12.8682 3.53511C12.4843 2.8633 11.5156 2.8633 11.1318 3.53511L2.57811 18.504C2.19717 19.1706 2.67854 20.0001 3.44636 20.0001H20.5536C21.3215 20.0001 21.8028 19.1706 21.4219 18.504L12.8682 3.53511Z"
          fill="#3D82EA"
        />
      </svg>

      <h3 class="font-bold text-[32px]">
        This Project is Already Open in Another Window
      </h3>

      <p class="text-[16px] text-[#717477] mb-3">
        Please close this page and return to the active window or click below to take over.
      </p>

      <b-btn @click="closeOtherSessions">
        Close other sessions and take over
      </b-btn>
    </div>
  </b-modal>
</template>
