<template>
  <div
    v-if="!urlType"
    class="unselectable w-[160px] h-[146px] bg-white rounded-[7px] shadow-[0px_1px_6px_rgba(0,0,0,0.07)]"
    :class="[active ? '!border-2 !border-[#4F83E3]' : 'border border-[#E2E5EC]']"
  >
    <div
      class="flex items-center justify-center w-full h-[122px] border-b border-[#E2E2E2] rounded-t-[7px] overflow-hidden  cursor-pointer"
      @click="$emit('click',file)"
    >
      <div v-if="file.url.indexOf('json') > -1">
        <lottie-render :url="file.url" />
      </div>
      <img
        v-else-if="isImg"
        :src="file.url"
        class="unselectable w-full h-full object-cover"
        draggable="false"
      >
      <img
        v-else
        class="unselectable w-1/2 h-1/2"
        src="@/assets/icons/pdf-icon.svg"
        draggable="false"
      >
    </div>
    <div class="bg-white flex justify-between items-center pt-[3px] px-[11px]">
      <span class="text-[12px] leading-[15px] text-[#6E747A] truncate mr-[12px]">
        {{ file.original_name }}
      </span>
      <b-dropdown
        variant="al-file"
        menu-class="project-menu py-0"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <svg
            width="24"
            height="5"
            viewBox="0 0 24 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="2.5"
              cy="2.5"
              r="2.5"
              fill="#6E747A"
            />
            <circle
              cx="12.0714"
              cy="2.5"
              r="2.5"
              fill="#6E747A"
            />
            <circle
              cx="21.3572"
              cy="2.5"
              r="2.5"
              fill="#6E747A"
            />
          </svg>
        </template>
        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click="$emit('move',file)"
        >
          <svg
            class="mr-2 mb-1"
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2738_22579)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.583374 2.91669C0.583374 1.95019 1.36688 1.16669 2.33337 1.16669H5.83337C6.02841 1.16669 6.21055 1.26416 6.31874 1.42645L7.31223 2.91669H11.6667C12.6332 2.91669 13.4167 3.70019 13.4167 4.66669V11.0834C13.4167 12.0499 12.6332 12.8334 11.6667 12.8334H2.33337C1.36688 12.8334 0.583374 12.0499 0.583374 11.0834V2.91669ZM2.33337 2.33335C2.01121 2.33335 1.75004 2.59452 1.75004 2.91669V11.0834C1.75004 11.4055 2.01121 11.6667 2.33337 11.6667H11.6667C11.9889 11.6667 12.25 11.4055 12.25 11.0834V4.66669C12.25 4.34452 11.9889 4.08335 11.6667 4.08335H7.00004C6.805 4.08335 6.62287 3.98588 6.51468 3.8236L5.52118 2.33335H2.33337Z"
                fill="#44474A"
              />
              <rect
                x="9.625"
                y="4.11249"
                width="7.875"
                height="7.525"
                fill="white"
              />
            </g>
            <g clip-path="url(#clip1_2738_22579)">
              <path
                d="M13.5661 10.0662C13.3668 10.2655 13.3668 10.5887 13.5661 10.788C13.7655 10.9873 14.0886 10.9873 14.288 10.788L16.84 8.23592C17.0394 8.03659 17.0394 7.71341 16.84 7.51408L14.288 4.962C14.0886 4.76267 13.7655 4.76267 13.5661 4.962C13.3668 5.16133 13.3668 5.48451 13.5661 5.68384L15.2469 7.36458H6.27079C5.9889 7.36458 5.76038 7.59311 5.76038 7.875C5.76038 8.1569 5.9889 8.38542 6.27079 8.38542H15.2469L13.5661 10.0662Z"
                fill="#44474A"
              />
            </g>
            <defs>
              <clipPath id="clip0_2738_22579">
                <rect
                  width="14"
                  height="14"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip1_2738_22579">
                <rect
                  width="12.25"
                  height="12.25"
                  fill="white"
                  transform="translate(5.25 1.75)"
                />
              </clipPath>
            </defs>
          </svg>

          Move To Folder
        </b-dropdown-item>

        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click="$emit('edit', file)"
        >
          <svg
            class="mr-2"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.8048 0.528575C10.5444 0.268226 10.1223 0.268226 9.86197 0.528575L0.528636 9.86191C0.403612 9.98693 0.333374 10.1565 0.333374 10.3333V13C0.333374 13.3682 0.631851 13.6666 1.00004 13.6666H3.66671C3.84352 13.6666 4.01309 13.5964 4.13811 13.4714L13.4714 4.13805C13.7318 3.8777 13.7318 3.45559 13.4714 3.19524L10.8048 0.528575ZM11 4.72384L9.27619 2.99998L10.3334 1.94279L12.0572 3.66665L11 4.72384ZM8.33338 3.94279L10.0572 5.66665L4.33337 11.3905L2.60952 9.66665L8.33338 3.94279ZM1.66671 10.6095L3.39057 12.3333H1.66671V10.6095Z"
              fill="#44474A"
            />
            <path
              d="M7.66671 12.3333C7.29852 12.3333 7.00004 12.6318 7.00004 13C7.00004 13.3682 7.29852 13.6666 7.66671 13.6666H13C13.3682 13.6666 13.6667 13.3682 13.6667 13C13.6667 12.6318 13.3682 12.3333 13 12.3333H7.66671Z"
              fill="#44474A"
            />
          </svg>

          Edit Info
        </b-dropdown-item>

        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click="copyToClipboard(file.url)"
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.70834 7.62496C8.40918 7.62496 8.16667 7.86747 8.16667 8.16663V16.8333C8.16667 17.1324 8.40918 17.375 8.70834 17.375H16.2917C16.5908 17.375 16.8333 17.1324 16.8333 16.8333V11.4166H14.6667C13.7692 11.4166 13.0417 10.6891 13.0417 9.79163V7.62496H8.70834ZM14.125 8.39099L16.0673 10.3333H14.6667C14.3675 10.3333 14.125 10.0908 14.125 9.79163V8.39099ZM7.08334 8.16663C7.08334 7.26916 7.81087 6.54163 8.70834 6.54163H13.5833C13.727 6.54163 13.8648 6.59869 13.9664 6.70028L17.758 10.4919C17.8596 10.5935 17.9167 10.7313 17.9167 10.875V16.8333C17.9167 17.7308 17.1891 18.4583 16.2917 18.4583H8.70834C7.81087 18.4583 7.08334 17.7308 7.08334 16.8333V8.16663Z"
              fill="#44474A"
              stroke="#44474A"
              stroke-width="0.1"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.70834 1.62496C2.40918 1.62496 2.16667 1.86747 2.16667 2.16663V10.8333C2.16667 11.1324 2.40918 11.375 2.70834 11.375H10.2917C10.5908 11.375 10.8333 11.1324 10.8333 10.8333V5.41663H8.66667C7.76921 5.41663 7.04167 4.68909 7.04167 3.79163V1.62496H2.70834ZM8.125 2.39099L10.0673 4.33329H8.66667C8.36752 4.33329 8.125 4.09078 8.125 3.79163V2.39099ZM1.08334 2.16663C1.08334 1.26916 1.81087 0.541626 2.70834 0.541626H7.58334C7.727 0.541626 7.86477 0.598694 7.96635 0.700276L11.758 4.49194C11.8596 4.59353 11.9167 4.7313 11.9167 4.87496V10.8333C11.9167 11.7308 11.1891 12.4583 10.2917 12.4583H2.70834C1.81087 12.4583 1.08334 11.7308 1.08334 10.8333V2.16663Z"
              fill="#44474A"
              stroke="#44474A"
              stroke-width="0.1"
              stroke-linejoin="round"
            />
            <rect
              x="5.80005"
              y="5.40002"
              width="5"
              height="6"
              rx="0.7"
              fill="white"
            />
          </svg>

          Copy Asset Link
        </b-dropdown-item>

        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click="() => downloadFile(file.url, file.original_name)"
        >
          <svg
            class="mr-2"
            width="21"
            height="15"
            viewBox="0 0 21 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.04167 14.575C3.65139 14.575 2.46339 14.0937 1.47767 13.1312C0.491945 12.1687 -0.000610543 10.9924 5.67947e-07 9.60208C5.67947e-07 8.41042 0.359028 7.34861 1.07708 6.41667C1.79514 5.48472 2.73472 4.88889 3.89583 4.62917C4.15556 3.52917 4.80486 2.48264 5.84375 1.48958C6.88264 0.496528 7.99028 0 9.16667 0C9.67083 0 10.1026 0.179667 10.4619 0.539C10.8213 0.898334 11.0006 1.32978 11 1.83333V7.37917L12.4667 5.95833L13.75 7.24167L10.0833 10.9083L6.41667 7.24167L7.7 5.95833L9.16667 7.37917V1.83333C8.00556 2.04722 7.10417 2.60883 6.4625 3.51817C5.82083 4.4275 5.5 5.36311 5.5 6.325H5.04167C4.15556 6.325 3.39931 6.63819 2.77292 7.26458C2.14653 7.89097 1.83333 8.64722 1.83333 9.53333C1.83333 10.4194 2.14653 11.1757 2.77292 11.8021C3.39931 12.4285 4.15556 12.7417 5.04167 12.7417H16.0417C16.6833 12.7417 17.2257 12.5201 17.6687 12.0771C18.1118 11.634 18.3333 11.0917 18.3333 10.45C18.3333 9.80833 18.1118 9.26597 17.6687 8.82292C17.2257 8.37986 16.6833 8.15833 16.0417 8.15833H14.6667V6.325C14.6667 5.59167 14.4986 4.90783 14.1625 4.2735C13.8264 3.63917 13.3833 3.10078 12.8333 2.65833V0.527084C13.9639 1.06181 14.8576 1.85258 15.5146 2.89942C16.1715 3.94625 16.5 5.08811 16.5 6.325C17.5542 6.44722 18.429 6.90189 19.1244 7.689C19.8199 8.47611 20.1673 9.39644 20.1667 10.45C20.1667 11.5958 19.7655 12.5699 18.9631 13.3723C18.1607 14.1747 17.1869 14.5756 16.0417 14.575H5.04167Z"
              fill="#44474A"
            />
          </svg>

          Download File
        </b-dropdown-item>

        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click="$emit('delete',file)"
        >
          <svg
            class="mr-2"
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.5 0.75C5.08579 0.75 4.75 1.08579 4.75 1.5V3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H13C13.4142 4.5 13.75 4.16421 13.75 3.75C13.75 3.33579 13.4142 3 13 3H9.25V1.5C9.25 1.08579 8.91421 0.75 8.5 0.75H5.5ZM7.75 3H6.25V2.25H7.75V3Z"
              fill="#44474A"
            />
            <path
              d="M5.5 8.25C5.91421 8.25 6.25 8.58579 6.25 9V13.5C6.25 13.9142 5.91421 14.25 5.5 14.25C5.08579 14.25 4.75 13.9142 4.75 13.5V9C4.75 8.58579 5.08579 8.25 5.5 8.25Z"
              fill="#44474A"
            />
            <path
              d="M9.25 9C9.25 8.58579 8.91421 8.25 8.5 8.25C8.08579 8.25 7.75 8.58579 7.75 9V13.5C7.75 13.9142 8.08579 14.25 8.5 14.25C8.91421 14.25 9.25 13.9142 9.25 13.5V9Z"
              fill="#44474A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.00001 5.25C0.782586 5.25 0.575867 5.34435 0.433415 5.5086C0.290961 5.67285 0.226795 5.89083 0.257543 6.10607L1.57356 15.3182C1.73191 16.4267 2.68123 17.25 3.80095 17.25H10.1991C11.3188 17.25 12.2681 16.4267 12.4264 15.3182L13.7425 6.10607C13.7732 5.89083 13.709 5.67285 13.5666 5.5086C13.4241 5.34435 13.2174 5.25 13 5.25H1.00001ZM3.05849 15.1061L1.86476 6.75H12.1352L10.9415 15.1061C10.8887 15.4756 10.5723 15.75 10.1991 15.75H3.80095C3.42771 15.75 3.11127 15.4756 3.05849 15.1061Z"
              fill="#44474A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.5 0.75C5.08579 0.75 4.75 1.08579 4.75 1.5V3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H13C13.4142 4.5 13.75 4.16421 13.75 3.75C13.75 3.33579 13.4142 3 13 3H9.25V1.5C9.25 1.08579 8.91421 0.75 8.5 0.75H5.5ZM7.75 3H6.25V2.25H7.75V3Z"
              stroke="white"
              stroke-width="0.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.5 8.25C5.91421 8.25 6.25 8.58579 6.25 9V13.5C6.25 13.9142 5.91421 14.25 5.5 14.25C5.08579 14.25 4.75 13.9142 4.75 13.5V9C4.75 8.58579 5.08579 8.25 5.5 8.25Z"
              stroke="white"
              stroke-width="0.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.25 9C9.25 8.58579 8.91421 8.25 8.5 8.25C8.08579 8.25 7.75 8.58579 7.75 9V13.5C7.75 13.9142 8.08579 14.25 8.5 14.25C8.91421 14.25 9.25 13.9142 9.25 13.5V9Z"
              stroke="white"
              stroke-width="0.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.00001 5.25C0.782586 5.25 0.575867 5.34435 0.433415 5.5086C0.290961 5.67285 0.226795 5.89083 0.257543 6.10607L1.57356 15.3182C1.73191 16.4267 2.68123 17.25 3.80095 17.25H10.1991C11.3188 17.25 12.2681 16.4267 12.4264 15.3182L13.7425 6.10607C13.7732 5.89083 13.709 5.67285 13.5666 5.5086C13.4241 5.34435 13.2174 5.25 13 5.25H1.00001ZM3.05849 15.1061L1.86476 6.75H12.1352L10.9415 15.1061C10.8887 15.4756 10.5723 15.75 10.1991 15.75H3.80095C3.42771 15.75 3.11127 15.4756 3.05849 15.1061Z"
              stroke="white"
              stroke-width="0.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Delete File
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
  <div
    v-else
    class="w-[160px] h-[146px] bg-white rounded-[7px] shadow-[0px_1px_6px_rgba(0,0,0,0.07)]"
  >
    <div
      class="flex items-center justify-center w-full h-[122px] border-b border-[#E2E2E2] rounded-t-[7px] overflow-hidden  cursor-pointer"
      @click="$emit('click',file)"
    >
      <img
        :src="file"
        class="w-full h-full object-cover"
      >
    </div>
    <div class="flex justify-end py-[8px] items-center px-[11px]">
      <b-dropdown
        variant="al-file"
        menu-class="project-menu py-0"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <svg
            width="24"
            height="5"
            viewBox="0 0 24 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="2.5"
              cy="2.5"
              r="2.5"
              fill="#6E747A"
            />
            <circle
              cx="12.0714"
              cy="2.5"
              r="2.5"
              fill="#6E747A"
            />
            <circle
              cx="21.3572"
              cy="2.5"
              r="2.5"
              fill="#6E747A"
            />
          </svg>
        </template>
        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click="copyToClipboard(file)"
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.70834 7.62496C8.40918 7.62496 8.16667 7.86747 8.16667 8.16663V16.8333C8.16667 17.1324 8.40918 17.375 8.70834 17.375H16.2917C16.5908 17.375 16.8333 17.1324 16.8333 16.8333V11.4166H14.6667C13.7692 11.4166 13.0417 10.6891 13.0417 9.79163V7.62496H8.70834ZM14.125 8.39099L16.0673 10.3333H14.6667C14.3675 10.3333 14.125 10.0908 14.125 9.79163V8.39099ZM7.08334 8.16663C7.08334 7.26916 7.81087 6.54163 8.70834 6.54163H13.5833C13.727 6.54163 13.8648 6.59869 13.9664 6.70028L17.758 10.4919C17.8596 10.5935 17.9167 10.7313 17.9167 10.875V16.8333C17.9167 17.7308 17.1891 18.4583 16.2917 18.4583H8.70834C7.81087 18.4583 7.08334 17.7308 7.08334 16.8333V8.16663Z"
              fill="#44474A"
              stroke="#44474A"
              stroke-width="0.1"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.70834 1.62496C2.40918 1.62496 2.16667 1.86747 2.16667 2.16663V10.8333C2.16667 11.1324 2.40918 11.375 2.70834 11.375H10.2917C10.5908 11.375 10.8333 11.1324 10.8333 10.8333V5.41663H8.66667C7.76921 5.41663 7.04167 4.68909 7.04167 3.79163V1.62496H2.70834ZM8.125 2.39099L10.0673 4.33329H8.66667C8.36752 4.33329 8.125 4.09078 8.125 3.79163V2.39099ZM1.08334 2.16663C1.08334 1.26916 1.81087 0.541626 2.70834 0.541626H7.58334C7.727 0.541626 7.86477 0.598694 7.96635 0.700276L11.758 4.49194C11.8596 4.59353 11.9167 4.7313 11.9167 4.87496V10.8333C11.9167 11.7308 11.1891 12.4583 10.2917 12.4583H2.70834C1.81087 12.4583 1.08334 11.7308 1.08334 10.8333V2.16663Z"
              fill="#44474A"
              stroke="#44474A"
              stroke-width="0.1"
              stroke-linejoin="round"
            />
            <rect
              x="5.80005"
              y="5.40002"
              width="5"
              height="6"
              rx="0.7"
              fill="white"
            />
          </svg>

          Copy Asset Link
        </b-dropdown-item>

        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click="() => downloadFile(file, file.substring(file.indexOf('-') + 1).substring(file.indexOf('/') + 1))"
        >
          <svg
            class="mr-2"
            width="21"
            height="15"
            viewBox="0 0 21 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.04167 14.575C3.65139 14.575 2.46339 14.0937 1.47767 13.1312C0.491945 12.1687 -0.000610543 10.9924 5.67947e-07 9.60208C5.67947e-07 8.41042 0.359028 7.34861 1.07708 6.41667C1.79514 5.48472 2.73472 4.88889 3.89583 4.62917C4.15556 3.52917 4.80486 2.48264 5.84375 1.48958C6.88264 0.496528 7.99028 0 9.16667 0C9.67083 0 10.1026 0.179667 10.4619 0.539C10.8213 0.898334 11.0006 1.32978 11 1.83333V7.37917L12.4667 5.95833L13.75 7.24167L10.0833 10.9083L6.41667 7.24167L7.7 5.95833L9.16667 7.37917V1.83333C8.00556 2.04722 7.10417 2.60883 6.4625 3.51817C5.82083 4.4275 5.5 5.36311 5.5 6.325H5.04167C4.15556 6.325 3.39931 6.63819 2.77292 7.26458C2.14653 7.89097 1.83333 8.64722 1.83333 9.53333C1.83333 10.4194 2.14653 11.1757 2.77292 11.8021C3.39931 12.4285 4.15556 12.7417 5.04167 12.7417H16.0417C16.6833 12.7417 17.2257 12.5201 17.6687 12.0771C18.1118 11.634 18.3333 11.0917 18.3333 10.45C18.3333 9.80833 18.1118 9.26597 17.6687 8.82292C17.2257 8.37986 16.6833 8.15833 16.0417 8.15833H14.6667V6.325C14.6667 5.59167 14.4986 4.90783 14.1625 4.2735C13.8264 3.63917 13.3833 3.10078 12.8333 2.65833V0.527084C13.9639 1.06181 14.8576 1.85258 15.5146 2.89942C16.1715 3.94625 16.5 5.08811 16.5 6.325C17.5542 6.44722 18.429 6.90189 19.1244 7.689C19.8199 8.47611 20.1673 9.39644 20.1667 10.45C20.1667 11.5958 19.7655 12.5699 18.9631 13.3723C18.1607 14.1747 17.1869 14.5756 16.0417 14.575H5.04167Z"
              fill="#44474A"
            />
          </svg>

          Download File
        </b-dropdown-item>

        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click="$emit('delete',file)"
        >
          <svg
            class="mr-2"
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.5 0.75C5.08579 0.75 4.75 1.08579 4.75 1.5V3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H13C13.4142 4.5 13.75 4.16421 13.75 3.75C13.75 3.33579 13.4142 3 13 3H9.25V1.5C9.25 1.08579 8.91421 0.75 8.5 0.75H5.5ZM7.75 3H6.25V2.25H7.75V3Z"
              fill="#44474A"
            />
            <path
              d="M5.5 8.25C5.91421 8.25 6.25 8.58579 6.25 9V13.5C6.25 13.9142 5.91421 14.25 5.5 14.25C5.08579 14.25 4.75 13.9142 4.75 13.5V9C4.75 8.58579 5.08579 8.25 5.5 8.25Z"
              fill="#44474A"
            />
            <path
              d="M9.25 9C9.25 8.58579 8.91421 8.25 8.5 8.25C8.08579 8.25 7.75 8.58579 7.75 9V13.5C7.75 13.9142 8.08579 14.25 8.5 14.25C8.91421 14.25 9.25 13.9142 9.25 13.5V9Z"
              fill="#44474A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.00001 5.25C0.782586 5.25 0.575867 5.34435 0.433415 5.5086C0.290961 5.67285 0.226795 5.89083 0.257543 6.10607L1.57356 15.3182C1.73191 16.4267 2.68123 17.25 3.80095 17.25H10.1991C11.3188 17.25 12.2681 16.4267 12.4264 15.3182L13.7425 6.10607C13.7732 5.89083 13.709 5.67285 13.5666 5.5086C13.4241 5.34435 13.2174 5.25 13 5.25H1.00001ZM3.05849 15.1061L1.86476 6.75H12.1352L10.9415 15.1061C10.8887 15.4756 10.5723 15.75 10.1991 15.75H3.80095C3.42771 15.75 3.11127 15.4756 3.05849 15.1061Z"
              fill="#44474A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.5 0.75C5.08579 0.75 4.75 1.08579 4.75 1.5V3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H13C13.4142 4.5 13.75 4.16421 13.75 3.75C13.75 3.33579 13.4142 3 13 3H9.25V1.5C9.25 1.08579 8.91421 0.75 8.5 0.75H5.5ZM7.75 3H6.25V2.25H7.75V3Z"
              stroke="white"
              stroke-width="0.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.5 8.25C5.91421 8.25 6.25 8.58579 6.25 9V13.5C6.25 13.9142 5.91421 14.25 5.5 14.25C5.08579 14.25 4.75 13.9142 4.75 13.5V9C4.75 8.58579 5.08579 8.25 5.5 8.25Z"
              stroke="white"
              stroke-width="0.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.25 9C9.25 8.58579 8.91421 8.25 8.5 8.25C8.08579 8.25 7.75 8.58579 7.75 9V13.5C7.75 13.9142 8.08579 14.25 8.5 14.25C8.91421 14.25 9.25 13.9142 9.25 13.5V9Z"
              stroke="white"
              stroke-width="0.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.00001 5.25C0.782586 5.25 0.575867 5.34435 0.433415 5.5086C0.290961 5.67285 0.226795 5.89083 0.257543 6.10607L1.57356 15.3182C1.73191 16.4267 2.68123 17.25 3.80095 17.25H10.1991C11.3188 17.25 12.2681 16.4267 12.4264 15.3182L13.7425 6.10607C13.7732 5.89083 13.709 5.67285 13.5666 5.5086C13.4241 5.34435 13.2174 5.25 13 5.25H1.00001ZM3.05849 15.1061L1.86476 6.75H12.1352L10.9415 15.1061C10.8887 15.4756 10.5723 15.75 10.1991 15.75H3.80095C3.42771 15.75 3.11127 15.4756 3.05849 15.1061Z"
              stroke="white"
              stroke-width="0.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Delete File
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LottieRender from '@/components/editor/components/LottieRender'
export default {
  components: {LottieRender},
  props: {
    file: {
      type: [Object,String],
      default: new Object()
    },
    active:{
      type:Boolean,
      default:false
    },
    urlType:{
      type:Boolean,
      default:false
    }
  },
  computed: {
    ...mapState('assetsLibrary', {
      activeFolder: state => state.activeFolder
    }),

    isImg (){
      if(this.activeFolder){
        return this.activeFolder.type === 'image'
      }

      return false
    }
  },
  methods: {
    downloadFile (file, name) {
      axios({
        url: file,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const href = URL.createObjectURL(response.data)

        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      })
    },
    copyToClipboard (url) {
      navigator.clipboard.writeText(url)
      this.$swal({
        icon: 'success',
        iconColor: '#4F83E3',
        toast: true,
        position: 'top-right',
        title: 'Copied to clipboard.',
        showConfirmButton: false,
        timer: 3000
      })
    }
  }
}
</script>

<style lang="scss">
.btn-al-file{
  border: unset!important;
  outline: unset!important;
  padding: 1px !important;
    margin: 0!important;
  &:hover{
    svg *{
      fill: #4F83E3;
   }
  }
}

.dropdown.show .btn-al-file:not(.no-focus) {
    background-color: unset!important;
    border-color: unset!important;
    color: #fff;
}

</style>
