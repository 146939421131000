<template>
  <b-modal
    v-model="modal"
    class="position-relative"
    lazy
    centered
    body-class="py-5"
    content-class="overflow-visible new-section-modal"
    size="lg"
    hide-footer
    hide-header
  >
    <h3 class="text-center font-weight-bold mb-12 px-0">
      Name Your Project
    </h3>

    <b-row>
      <b-col
        offset="2"
        cols="8"
      >
        <b-form @submit.prevent="onsubmit">
          <b-form-group
            class="estage-form-group"
            label="Enter Project Name"
          >
            <b-input
              v-model="form.name"
              v-validate="`required|${excluded}`"
              name="name"
              :state="errors.has('name') ? false : null"
            />

            <span
              v-if="errors.first('name') && form.name.length"
              class="text-danger"
            >A project with the same name already exists.</span>
          </b-form-group>

          <b-form-group
            class="estage-form-group"
            label="Select Folder"
          >
            <div class="flex items-center justify-between w-[350px]">
              <folders-tree-select v-model="form.folder" />
              <i class="text-[#6E747A] text-[15px] ml-[18px] font-bold">or</i>
            </div>
          </b-form-group>

          <b-form-group
            v-if="form.folder == ' ' || form.folder == null"
            class="estage-form-group"
            label="Create New Folder"
          >
            <b-input
              v-model="form.folderName"
              class="placeholder:italic"
              placeholder="enter folder name..."
            />
          </b-form-group>

          <div class="text-center mt-5">
            <b-btn
              type="submit"
              variant="primary"
              class="font-weight-bold font-size-14 px-5"
              :disabled="loading"
            >
              <b-spinner
                v-if="loading"
                class="mr-2"
                variant="light"
                small
              />
              Get Started!
            </b-btn>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'
import FoldersTreeSelect from './FoldersTreeSelect'

const form = {
  name: '',
  folder: null,
  folderName: ''
}

export default {
  name: 'CreateProjectModal',
  components: {FoldersTreeSelect},
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      form: JSON.parse(JSON.stringify(form)),
      apiErrors: false
    }
  },
  computed: {
    name () {
      return this.form.name
    },
    excluded () {
      if (!this.apiErrors) return ''
      return `excluded:${this.form.name}`
    },
    modal: {
      get () {
        return !!Object.keys(this.value).length
      },
      set (val) {
        if (!val) {
          this.$emit('input', {})
        }
      }
    },
    ...mapState('dashboard', {
      folder: state => state.folder
    })
  },
  watch: {
    name () {
      this.apiErrors = false
    },
    modal (val) {
      if (val) {
        this.form = JSON.parse(JSON.stringify(form))

        if (this.folder && this.folder.id !== 0) {
          this.form.folder = this.folder.id
        }
      }
    }
  },
  methods: {
    onsubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.apiErrors = false
          this.loading = true
          this.$store.dispatch('projects/create', {
            name: this.form.name,
            subdomain: `my-web-${new Date().getTime()}`,
            category_id: this.form.folder,
            category_name: this.form.folderName,
            template_id: this.value.id,
            is_my_template: this.value.isMyTemplate
          })
            .then(({data}) => {
              this.$store.dispatch('projects/gettingReady', data)
            })
            .catch(error => {
              const errors = error.response.data.errors
              if (errors.hasOwnProperty('name')) {
                this.apiErrors = true
              }

              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.new-section-modal {
  font-family: Raleway;
}
</style>