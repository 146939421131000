<template>
  <b-form-group v-if="responder.id">
    <b-form-group
      v-if="hasAPI.name !== 'Ontraport'"
      label="list"
    >
      <v-select
        v-model="button.selectedList"
        left
        label="name"
        :reduce="option => option.id || option.campaignId || option.list_id"
        class="custom-select"
        :options="list"
        :searchable="false"
      />
    </b-form-group>
  </b-form-group>
</template>

<script>
import _ from 'lodash'
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'AutorespondersAttributes',

  props: {
    button: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      tags: [],
      list: [],
      loading: false
    }
  },

  computed: {
    ...mapGetters('apiIntegration', {
      hasAPI: 'hasMailAPIService'
    }),
    ...mapState('apiIntegration', {
      contactForm: state => state.contactForm
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    responder () {
      return this.button.autoresponder || this.contactForm
    }
  },

  watch: {
    responder () {
      this.preapre()
    }
  },

  created () {
    this.preapre()
  },

  methods: {
    fetchTags () {
      this.loading = true
      if (!this.button.selectedTags) this.$set(this.button, 'selectedTags', [])
      axios.get(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/tags`)
        .then(({data}) => {
          this.tags = data.data
        })
        .finally(() => this.loading = false)
    },
    fetchList () {
      axios.get(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.hasAPI.name)}/lists`)
        .then(({data}) => {
          this.list = data.data
        })
    },
    preapre () {
      if (!this.responder.id) return
      if (this.hasAPI && this.hasAPI.name !== 'Ontraport') return this.fetchList()
      return this.fetchTags()
    }
  }
}
</script>
