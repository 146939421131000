<template>
  <div>
    <b-form-group class="position-relative">
      <page-select-menu-search
        v-model="searchQuery"
        type="text"
        class="form-control search-fc"
        @onSearch="onSearch"
      />
      <div
        v-if="searchQueryLoading"
        class="search-fc-spinner"
      >
        <b-spinner
          small
          variant="primary"
        />
      </div>
      <div class="search-fc-icon" />
    </b-form-group>

    <div
      v-if="pageIsLoading && !pages.length"
      class="h-200px d-flex align-items-center justify-content-center text-center mt-5"
    >
      <b-spinner variant="primary" />
    </div>
    <template v-else>
      <b-list-group
        v-if="pages.length"
        ref="scroll"
        v-h-scroll-class="'pr-[10px]'"
        class="overflow-auto text-[15px] text-[#6E747A] pr-[10px]"
        flush
        style="max-height:300px"
        @scroll="scrollLoadingPages"
      >
        <b-list-group-item
          v-for="(item, index) in pages"
          :key="index"
          class="px-0 py-2"
          :class="{'sticky top-0 z-10':item.is_home}"
        >
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              {{ item.title }}
              <span class="text-muted small ml-2">(Will be deleted after {{ item.deletion_date | moment("from", true) }})</span>
            </div>

            <div class="d-flex">
              <b-btn
                v-b-tooltip.right
                v-loading="restoreLoadingIndex == index"
                class="p-0 w-[32px] h-[32px] d-flex justify-center items-center"
                size="sm"
                title="Restore page"
                variant="white"
                @click="restore(item, index)"
              >
                <i
                  v-if="restoreLoadingIndex != index"
                  class="undo-icon"
                />
              </b-btn>
              <delete-confirm
                v-if="!item.is_home"
                title="Are you sure?"
                :description="`The <b>${item.title}</b> page will be removed along with the content.`"
                @confirm="deletePage(item,index)"
              >
                <template v-slot:activator="{on}">
                  <b-btn
                    v-b-tooltip.right
                    v-loading="deleteLoadingIndex == index"
                    size="sm"
                    variant="white"
                    class="p-0 w-[32px] h-[32px] d-flex justify-center items-center ml-2"
                    title="Delete page"
                    v-on="on"
                  >
                    <i
                      v-if="deleteLoadingIndex != index"
                      class="delete-icon"
                    />
                  </b-btn>
                </template>
              </delete-confirm>
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item v-if="pageIsLoading && paginationCurrentPage > 1">
          <div
            class="bg-white d-flex justify-content-center align-items-center text-primary position-absolute bottom-2 left-0 w-100"
          >
            <b-spinner
              class="mr-2"
              small
              variant="primary"
            />
            Loading
          </div>
        </b-list-group-item>
      </b-list-group>
      <p
        v-else
        class="text-muted text-center mb-0 text-[15px]"
      >
        No pages in archive.
      </p>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import DeleteConfirm from '../../../../components/editor/modals/DeleteConfirm'
import PageSelectMenuSearch from '@/components/editor/components/pagesSelectMenu/PageSelectMenuSearch'

export default {
  name: 'ArchivedPages',
  components: {DeleteConfirm,PageSelectMenuSearch},
  data () {
    return {
      pages:[],
      pageIsLoading: false,
      searchQueryLoading: false,
      paginationCurrentPage: 1,
      paginationPerPage: 20,
      paginationTotal: 0,
      searchQuery:'',
      deleteLoadingIndex:-1,
      restoreLoadingIndex:-1
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },

  created () {
    this.getPages()
  },

  methods: {
    async onSearch () {
      this.paginationCurrentPage = 1
      if(this.$refs.scroll) this.$refs.scroll.scrollTop = 0
      this.searchQueryLoading = true
      await this.getPages()
      this.searchQueryLoading = false
    },
    scrollLoadingPages (event) {
      if(this.pageIsLoading) return
      const $el = event.target
      const scrollHeight = $el.scrollHeight - $el.clientHeight

      if (Math.round($el.scrollTop) >= scrollHeight && (this.paginationCurrentPage || 1) < (this.paginationTotal / this.paginationPerPage)) {
        document.body.style.overflow = 'hidden'
        if (!this.paginationCurrentPage) {
          this.paginationCurrentPage = 2
        } else {
          this.paginationCurrentPage++
        }
        this.getPages()
      }
    },
    async getPages (){
      const page = this.paginationCurrentPage
      this.pageIsLoading = true
      try {
        let result = await axios.get(`api/projects/${this.project.id}/pages/trash`,{
          params: {
            q:this.searchQuery,
            page
            // perPage:7
          }
        })
        result = result.data.data
        this.paginationPerPage = Number(result.per_page)
        this.paginationTotal = Number(result.total)
        this.pages = page == 1 ? result.data : [...this.pages,...result.data]
      } finally{
        this.pageIsLoading = false
      }
    },
    deletePage (page, index) {
      this.deleteLoadingIndex = index
      axios.delete(`api/projects/${this.project.id}/pages/${page.id}/permanently`)
        .then(() => {
          this.pages.splice(index, 1)

          this.$swal({
            icon: 'success',
            title: 'Done!',
            text: 'The page have been delete from your project',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          this.deleteLoadingIndex = -1
        })
    },
    restore (page, index) {
      this.restoreLoadingIndex = index
      axios.get(`api/projects/${this.project.id}/pages/${page.id}/restore`)
        .then((data) => {
          VEvent.fire('pm-modal-append-page', data.data.data)
          this.pages.splice(index, 1)
          this.$store.dispatch('pages/loadPages')

          this.$swal({
            icon: 'success',
            title: 'Done!',
            text: 'The page have been restored.',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          this.restoreLoadingIndex = -1
        })
    }
  }
}
</script>