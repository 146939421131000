<template>
  <div class="position-absolute color-picker-flat border bg-white shadow overflow-hidden">
    <chrome-picker
      ref="color-picker"
      :preset-colors="[]"
      :value="colors"
      @input="changeColor($event, 'input')"
    />

    <div
      class="vc-sketch-presets !pl-[18px] bg-white border-0 w-[220px]"
      role="group"
      aria-label="A color preset, pick one to set as current color"
    >
      <i
        class="fa-solid fa-eye-dropper cursor-pointer text-[14px] mr-2"
        @click="sampleColorFromScreen"
      />

      <div
        v-for="(c, index) in ['#1f3b9c', '#fcb045', '#1abc9c', '#2ecc71', '#9b59b6', '#3498db', '#34495e', '#e67e22', '#e74c3c', '#bdc3c7', '#ffffff', '#000000', ...getPalette(globalStyles.colors, 'palette')]"
        :key="index"
        :aria-label="'Color:' + c"
        class="vc-sketch-presets-color"
        :style="{background: c}"
        @click="handlePreset(c)"
      />

      <div
        class="vc-sketch-presets-color"
        @click="addNewColor"
      >
        <i class="fa fa-plus text-[10px] text-gray-500 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ColorPickerFlat',

  props: {
    color: {
      required: true
    },
    small: {
      default: true
    }
  },

  data () {
    return {
      colors: {
        hex: this.color
      }
    }
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    })
  },

  watch: {
    color () {
      this.colors =  {
        hex: this.color
      }
    }
  },

  methods: {
    async sampleColorFromScreen (abortController) {
      // eslint-disable-next-line no-undef
      const eyeDropper = new EyeDropper()
      try {
        const result = await eyeDropper.open({signal: abortController.signal})
        this.handlePreset(result.sRGBHex)
        return result.sRGBHex
      } catch (e) {
        return null
      }
    },
    addNewColor () {
      if (this.globalStyles.colors.palette && this.globalStyles.colors.palette.includes(this.color)) return
      this.globalStyles.colors.palette.push(this.color)
    },
    handlePreset (c) {
      this.$refs['color-picker'].handlePreset(c)
    },
    getPalette (obj, pollite) {
      if (!obj.hasOwnProperty(pollite)) {
        this.$set(obj, pollite, [])
      }
      return obj[pollite]
    },
    updateLocalColor () {
      this.colors.hex = this.color
    },
    changeColor (event, emit) {
      this.colors.hex = event.hex
      this.colors.rgba = event.rgba

      this.updateLocalColor()
      this.$emit(emit, event)

      if (emit === 'change') {
        setTimeout(() => {
          if (window.getSelection) {
            if (window.getSelection().empty) {  // Chrome
              window.getSelection().empty()
            } else if (window.getSelection().removeAllRanges) {  // Firefox
              window.getSelection().removeAllRanges()
            }
          } else if (document.selection) {  // IE?
            document.selection.empty()
          }
        }, 100)
      }
    }
  }
}
</script>

<style lang="scss">
  .pollite.pollite-small {
    width: 34px;
    height: 34px;
  }

  .color-picker-flat {
    z-index: 100;
    top: 80px;
    right: 0;
  }
</style>
