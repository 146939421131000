<template>
  <div>
    <sidbear-settings-group
      title="Grid"
      active
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>
      
      <b-form-group label="Author">
        <tags-select
          v-model="settings.author"
          label="name"
          :url="`https://blog-api.estage.com/api/projects/${project.id}/blog/manage/authors`"
        />
      </b-form-group>

      <b-form-group label="Categories">
        <tags-select
          v-model="settings.categories"
          class="w-full"
          multiple
          label="name"
          :url="`https://blog-api.estage.com/api/projects/${project.id}/blog/manage/categories`"
        />
      </b-form-group>

      <!--      <b-form-group label="Exclude">-->
      <!--        &lt;!&ndash;        <tags-select&ndash;&gt;-->
      <!--        &lt;!&ndash;          v-model="settings.categories"&ndash;&gt;-->
      <!--        &lt;!&ndash;        />&ndash;&gt;-->
      <!--      </b-form-group>-->

      <b-form-group>
        <div class="flex items-center justify-between">
          <label
            class="!font-[400] pb-0"
          >Posts Per Page</label>

          <linked-control-item
            v-model="settings.postsPerPage"
            :min="1"
            :max="20"
            :step="1"
            class="d-block !w-[60px]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label
            class="!font-[400] pb-0"
          >Max Title Words</label>

          <linked-control-item
            v-model="settings.maxTitleWords"
            :min="1"
            :max="10"
            :step="1"
            class="d-block !w-[60px]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label
            class="!font-[400] pb-0"
          >Max Excerpt Words</label>

          <linked-control-item
            v-model="settings.maxExcerptWords"
            :min="1"
            :max="200"
            :step="1"
            class="d-block !w-[60px]"
          />
        </div>
      </b-form-group>

      <b-form-group class="mb-2">
        <div class="flex justify-between items-center">
          <label
            class="!font-[400] pt-3 pb-0"
          >Search & Filter Display</label>
          <b-checkbox
            v-model="settings.applyFilters"
            switch
          />
        </div>
      </b-form-group>

      <b-form-group class="mb-2">
        <div class="flex justify-between items-center">
          <label
            for="Show Image"
            class="!font-[400] pt-3 pb-0"
          >Show Image</label>
          <b-checkbox
            id="Show Image"
            v-model="settings.PostThumb.display"
            switch
          />
        </div>
      </b-form-group>


      <b-form-group class="mb-2">
        <div class="flex justify-between items-center">
          <label
            for="Show Excerpt"
            class="!font-[400] pt-3 pb-0"
          >Show Excerpt</label>
          <b-checkbox
            id="Show Excerpt"
            v-model="settings.PostText.display"
            switch
          />
        </div>
      </b-form-group>

      <b-form-group class="mb-2">
        <div class="flex justify-between items-center">
          <label
            for="Show Categories"
            class="!font-[400] pt-3 pb-0"
          >Show Categories</label>
          <b-checkbox
            id="Show Categories"
            v-model="settings.PostCategories.display"
            switch
          />
        </div>
      </b-form-group>


      <b-form-group class="mb-2">
        <div class="flex justify-between items-center">
          <label
            for="Show Button"
            class="!font-[400] pt-3 pb-0"
          >Show Button</label>
          <b-checkbox
            id="Show Button"
            v-model="settings.PostButton.display"
            switch
          />
        </div>
      </b-form-group>

      <b-form-group class="mb-2">
        <div class="flex justify-between items-center">
          <label
            for="Show Info"
            class="!font-[400] pt-3 pb-0"
          >Show Info</label>
          <b-checkbox
            id="Show Info"
            v-model="settings.showStats"
            switch
          />
        </div>
      </b-form-group>

      <b-form-group
        v-if="settings.showStats"
        class="mb-2"
      >
        <div class="flex justify-between items-center">
          <label
            for="Show Date"
            class="!font-[400] pt-3 pb-0"
          >Show Date</label>
          <b-checkbox
            id="Show Date"
            v-model="settings.showDate"
            switch
          />
        </div>
      </b-form-group>

      <b-form-group
        v-if="settings.showStats"
        class="mb-2"
      >
        <div class="flex justify-between items-center">
          <label
            for="Show Author Avatar"
            class="!font-[400] pt-3 pb-0"
          >Show Author Avatar</label>
          <b-checkbox
            id="Show Author Avatar"
            v-model="settings.showAuthorAvatar"
            switch
          />
        </div>
      </b-form-group>

      <b-form-group v-if="settings.showStats">
        <div class="flex justify-between items-center">
          <label
            for="Show Author Name"
            class="!font-[400] pt-3 pb-0"
          >Show Author Name</label>
          <b-checkbox
            id="Show Author Name"
            v-model="settings.showAuthorName"
            switch
          />
        </div>
      </b-form-group>

      <!--      <b-form-group class="mb-2">-->
      <!--        <div class="flex justify-between items-center">-->
      <!--          <label-->
      <!--            for="Show Date"-->
      <!--            class="!font-[400] pt-3 pb-0"-->
      <!--          >Show Date</label>-->
      <!--          <b-checkbox-->
      <!--            id="Show Date"-->
      <!--            v-model="settings.showDate"-->
      <!--            switch-->
      <!--          />-->
      <!--        </div>-->
      <!--      </b-form-group>-->

      <!--      <b-form-group class="mb-4">-->
      <!--        <div class="flex justify-between items-center">-->
      <!--          <label-->
      <!--            for="Show Categories"-->
      <!--            class="!font-[400] pt-3 pb-0"-->
      <!--          >Show Categories</label>-->
      <!--          <b-checkbox-->
      <!--            id="Show Categories"-->
      <!--            switch-->
      <!--          />-->
      <!--        </div>-->
      <!--      </b-form-group>-->

      <b-form-group>
        <sid-range-slider
          class="mt-5"
          rule-name="width"
          :sid="className(' .post-grid-card')"
          default-dimension="%"
          :dimensions="['%', 'vw', 'px']"
          label="Width"
          var="--width"
          measurement="width"
        />
      </b-form-group>

      <b-form-group>
        <sid-range-slider
          rule-name="height"
          :sid="className(' .post-grid-card')"
          default-dimension="%"
          :dimensions="['%', 'vh', 'px']"
          label="Height"
          var="--height"
          measurement="height"
        />
      </b-form-group>

      <sid-range-slider
        label="Horizontal Gap"
        :sid="className()"
        rule-name="column-gap"
        :dimensions="[]"
      />

      <sid-range-slider
        label="Vertical Gap"
        :sid="className()"
        rule-name="row-gap"
        :dimensions="[]"
      />

      <!--      <sid-checkbox-->
      <!--        :sid="className()"-->
      <!--        rule-name="flex-wrap"-->
      <!--        value="nowrap"-->
      <!--      >-->
      <!--        Nowrap-->
      <!--      </sid-checkbox>-->


      <sid-buttons-group
        label="Horizontal Alignment"
        class="mb-0"
        :options="[
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'start',
            tooltip: 'Start'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'center',
            tooltip: 'Center'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'end',
            tooltip: 'End'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'space-between',
            tooltip: 'Between'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'space-around',
            tooltip: 'Around'
          }
        ]"
        :sid="className()"
        rule-name="justify-content"
      />
    </sidbear-settings-group>

    <SidbearSettingsGroup title="Card Style">
      <rule-tabs>
        <template #Normal>
          <sid-background
            :sid="className(' .post-grid-card')"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="className(' .post-grid-card')"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="className(' .post-grid-card')"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="className(' .post-grid-card')"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              label="Radius"
              :sid="className(' .post-grid-card')"
              rule-name="border"
              class="mb-0"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius'
                }
              ]"
            />
          </b-form-group>

          <sid-box-shadow
            :sid="className(' .post-grid-card')"
            rule-name="box-shadow"
          />
        </template>

        <template #Hover>
          <sid-background
            :sid="className()"
            presudo=" .post-grid-card:hover"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="className()"
                presudo=" .post-grid-card:hover"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="className()"
              label="Border Width"
              presudo=" .post-grid-card:hover"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="className()"
                presudo=" .post-grid-card:hover"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              label="Radius"
              :sid="className()"
              presudo=" .post-grid-card:hover"
              rule-name="border"
              class="mb-0"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius'
                }
              ]"
            />
          </b-form-group>

          <sid-box-shadow
            :sid="className(' .post-grid-card')"
            rule-name="--shadow-hover"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            sid=".post-grid"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          sid=".post-grid"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            sid=".post-grid"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        sid=".post-grid"
        rule-name="border"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        sid=".post-grid"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        sid=".post-grid"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem.vue'
import TagsSelect from '@/components/editor/components/TagsSelect.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'

export default {
  name: 'PostGridMod',
  components: {
    SidBoxShadow,
    SidBackground,
    SidSelect,
    RuleTabs,
    SidButtonsGroup,
    SidLinkedGroup, SidColor, TagsSelect, LinkedControlItem, SidbearSettingsGroup, SidRangeSlider},
  mixins: [ModMixin],

  data () {
    return {
      authors: []
    }
  },

  computed: {
    settings () {
      return this.section.options.content.post.settings
    }
  },

  mounted () {
    /* Insert PostCategories for existing users since this was not initially included in the JSON data */
    const subjects = this.section.options.content.post.subjects
    const found = subjects.find(sub => sub.component === 'PostCategories')
    if(!found){
      this.section.options.content.post.subjects.push({
        mod: 'post-category-mod',
        component: 'PostCategories'
      })
    }
  },

  methods: {
    className (name = '') {
      return `.post-grid-${this.section.sid}${name}`
    }
  }
}
</script>