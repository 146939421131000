<template>
  <div>
    <b-dropdown-item
      v-if="!item || item.moderation"
      class="text-muted font-size-14"
      @click="$emit('approve')"
    >
      <svg
        class="mr-2"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.51627 0V3.81253H0V17.66L4.58503 13.992H14.0683V11.0563H14.9351L19.5202 14.7243V0H2.51627ZM12.9245 12.8482H4.1838L1.14376 15.2803V4.95629H2.51627V11.0563H12.9245V12.8482ZM18.3764 12.3446L15.3364 9.91259H3.66003V1.14376H18.3764V12.3446Z"
          fill="#44474A"
        />
      </svg>

      Approve
    </b-dropdown-item>
    <!--  <b-dropdown-item v-if="item" @click="$emit('reply')" class="text-muted font-size-14">-->
    <!--    <svg class="mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8044 0.528514C10.5441 0.268165 10.122 0.268165 9.86161 0.528514L0.52827 9.86185C0.403246 9.98687 0.333008 10.1564 0.333008 10.3333V12.9999C0.333008 13.3681 0.631485 13.6666 0.999675 13.6666H3.66634C3.84315 13.6666 4.01272 13.5963 4.13775 13.4713L13.4711 4.13799C13.7314 3.87764 13.7314 3.45553 13.4711 3.19518L10.8044 0.528514ZM10.9997 4.72378L9.27582 2.99992L10.333 1.94273L12.0569 3.66659L10.9997 4.72378ZM8.33301 3.94273L10.0569 5.66659L4.33301 11.3904L2.60915 9.66659L8.33301 3.94273ZM1.66634 10.6094L3.3902 12.3333H1.66634V10.6094Z" fill="#44474A"/>-->
    <!--      <path d="M7.66634 12.3333C7.29815 12.3333 6.99968 12.6317 6.99968 12.9999C6.99968 13.3681 7.29815 13.6666 7.66634 13.6666H12.9997C13.3679 13.6666 13.6663 13.3681 13.6663 12.9999C13.6663 12.6317 13.3679 12.3333 12.9997 12.3333H7.66634Z" fill="#44474A"/>-->
    <!--    </svg>-->

    <!--    Reply-->
    <!--  </b-dropdown-item>-->
    <b-dropdown-item
      v-if="!item || item.is_hide"
      class="text-muted font-size-14"
      @click="$emit('show')"
    >
      <svg
        class="mr-2"
        width="15"
        height="13"
        viewBox="0 0 15 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.625 6.5C10.625 8.22589 9.22589 9.625 7.5 9.625C5.77411 9.625 4.375 8.22589 4.375 6.5C4.375 4.77411 5.77411 3.375 7.5 3.375C9.22589 3.375 10.625 4.77411 10.625 6.5ZM9.375 6.5C9.375 7.53553 8.53553 8.375 7.5 8.375C6.46447 8.375 5.625 7.53553 5.625 6.5C5.625 5.46447 6.46447 4.625 7.5 4.625C8.53553 4.625 9.375 5.46447 9.375 6.5Z"
          fill="#44474A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15 6.5C15 8.0625 11.6421 12.125 7.5 12.125C3.35786 12.125 0 8.375 0 6.5C0 4.625 3.35786 0.875 7.5 0.875C11.6421 0.875 15 4.9375 15 6.5ZM13.7497 6.49933L13.7498 6.5L13.7497 6.50067C13.7482 6.51303 13.7388 6.59057 13.6804 6.74288C13.6161 6.91066 13.5098 7.12358 13.3539 7.37034C13.0418 7.86431 12.5706 8.43089 11.9711 8.96572C10.7568 10.0491 9.1656 10.875 7.5 10.875C5.80448 10.875 4.20847 10.1012 3.01361 9.0695C2.42109 8.5579 1.9588 8.00897 1.65325 7.51365C1.33054 6.9905 1.25 6.63849 1.25 6.5C1.25 6.36151 1.33054 6.0095 1.65325 5.48635C1.9588 4.99103 2.42109 4.4421 3.01361 3.9305C4.20847 2.89883 5.80448 2.125 7.5 2.125C9.1656 2.125 10.7568 2.95089 11.9711 4.03428C12.5706 4.5691 13.0418 5.13569 13.3539 5.62966C13.5098 5.87642 13.6161 6.08934 13.6804 6.25712C13.7388 6.40943 13.7482 6.48697 13.7497 6.49933Z"
          fill="#44474A"
        />
      </svg>

      Make Public
    </b-dropdown-item>
    <b-dropdown-item
      v-if="!item || !item.is_hide"
      class="text-muted font-size-14"
      @click="$emit('hide')"
    >
      <svg
        class="mr-2"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.625 7.5C10.625 9.22589 9.22589 10.625 7.5 10.625C5.77411 10.625 4.375 9.22589 4.375 7.5C4.375 5.77411 5.77411 4.375 7.5 4.375C9.22589 4.375 10.625 5.77411 10.625 7.5ZM9.375 7.5C9.375 8.53553 8.53553 9.375 7.5 9.375C6.46447 9.375 5.625 8.53553 5.625 7.5C5.625 6.46447 6.46447 5.625 7.5 5.625C8.53553 5.625 9.375 6.46447 9.375 7.5Z"
          fill="#44474A"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15 7.5C15 9.0625 11.6421 13.125 7.5 13.125C3.35786 13.125 0 9.375 0 7.5C0 5.625 3.35786 1.875 7.5 1.875C11.6421 1.875 15 5.9375 15 7.5ZM13.7497 7.49933L13.7498 7.5L13.7497 7.50067C13.7482 7.51303 13.7388 7.59057 13.6804 7.74288C13.6161 7.91066 13.5098 8.12358 13.3539 8.37034C13.0418 8.86431 12.5706 9.43089 11.9711 9.96572C10.7568 11.0491 9.1656 11.875 7.5 11.875C5.80448 11.875 4.20847 11.1012 3.01361 10.0695C2.42109 9.5579 1.9588 9.00897 1.65325 8.51365C1.33054 7.9905 1.25 7.63849 1.25 7.5C1.25 7.36151 1.33054 7.0095 1.65325 6.48635C1.9588 5.99103 2.42109 5.4421 3.01361 4.9305C4.20847 3.89883 5.80448 3.125 7.5 3.125C9.1656 3.125 10.7568 3.95089 11.9711 5.03428C12.5706 5.5691 13.0418 6.13569 13.3539 6.62966C13.5098 6.87642 13.6161 7.08934 13.6804 7.25712C13.7388 7.40943 13.7482 7.48697 13.7497 7.49933Z"
          fill="#44474A"
        />
        <line
          x1="14.5303"
          y1="0.53033"
          x2="0.53033"
          y2="14.5303"
          stroke="#6E747A"
          stroke-width="1.5"
        />
      </svg>


      Hide
    </b-dropdown-item>
    <b-dropdown-item
      class="text-muted font-size-14"
      @click="$emit('delete')"
    >
      <svg
        class="mr-2 mb-1"
        width="13"
        height="17"
        viewBox="0 0 13 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.08333 0.708374C4.69213 0.708374 4.375 1.02551 4.375 1.41671V2.83337H0.833333C0.442132 2.83337 0.125 3.15051 0.125 3.54171C0.125 3.93291 0.442132 4.25004 0.833333 4.25004H12.1667C12.5579 4.25004 12.875 3.93291 12.875 3.54171C12.875 3.15051 12.5579 2.83337 12.1667 2.83337H8.625V1.41671C8.625 1.02551 8.30787 0.708374 7.91667 0.708374H5.08333ZM7.20833 2.83337H5.79167V2.12504H7.20833V2.83337Z"
          fill="#616161"
        />
        <path
          d="M5.08333 7.79171C5.47454 7.79171 5.79167 8.10884 5.79167 8.50004V12.75C5.79167 13.1412 5.47454 13.4584 5.08333 13.4584C4.69213 13.4584 4.375 13.1412 4.375 12.75V8.50004C4.375 8.10884 4.69213 7.79171 5.08333 7.79171Z"
          fill="#616161"
        />
        <path
          d="M8.625 8.50004C8.625 8.10884 8.30787 7.79171 7.91667 7.79171C7.52547 7.79171 7.20833 8.10884 7.20833 8.50004V12.75C7.20833 13.1412 7.52547 13.4584 7.91667 13.4584C8.30787 13.4584 8.625 13.1412 8.625 12.75V8.50004Z"
          fill="#616161"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.833338 4.95837C0.627998 4.95837 0.432764 5.04748 0.298225 5.20261C0.163686 5.35773 0.103084 5.5636 0.132124 5.76688L1.37503 14.4672C1.52458 15.5141 2.42117 16.2917 3.47867 16.2917H9.52134C10.5788 16.2917 11.4754 15.5141 11.625 14.4672L12.8679 5.76688C12.8969 5.5636 12.8363 5.35773 12.7018 5.20261C12.5672 5.04748 12.372 4.95837 12.1667 4.95837H0.833338ZM2.77746 14.2669L1.65005 6.37504H11.35L10.2226 14.2669C10.1727 14.6158 9.87384 14.875 9.52134 14.875H3.47867C3.12617 14.875 2.82731 14.6158 2.77746 14.2669Z"
          fill="#616161"
        />
      </svg>

      Delete
    </b-dropdown-item>
  </div>
</template>

<script>
export default {
  name: 'BlogManagerCommentsMenuItems',
  props: {
    item: {
      type: [Object, Boolean],
      default: false
    }
  }
}
</script>

<style scoped>

</style>