export const fontsWeight = [
  {
    label: 'Light',
    value: '300'
  },
  {
    label: 'Regular',
    value: '400'
  },
  {
    label: 'Medium',
    value: '500'
  },
  {
    label: 'Bold',
    value: '600'
  }
]

export const fonts = [
  {
    label: null,
    value: null
  },
  {
    label: 'Roboto',
    value: 'Roboto'
  },
  {
    label: 'Montserrat',
    value: 'Montserrat'
  },
  {
    label: 'Georgia',
    value: 'Georgia'
  },
  {
    label: 'Playfair Display',
    value: 'Playfair Display'
  },
  {
    label: 'Raleway',
    value: 'Raleway'
  },
  {
    label: 'Spectral',
    value: 'Spectral'
  },
  {
    label: 'Rubik',
    value: 'Rubik'
  }
]
