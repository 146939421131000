<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings('pagination')"
  >
    <div class="p-[2px]">
      <Template :section="section" />
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'

export default {
  name: 'ActionItem2',

  components: {Highliter, Template},

  mixins: [SectionMixin]
}
</script>
