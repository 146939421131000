<template>
  <div>
    <b-form-group
      v-for="control in controls"
      :key="control.key"
      :label="control.label"
    >
      <div class="flex items-center justify-between gap-[10px]">
        <vue-slider
          v-model="control.value"
          class="editor-slider-1 !w-[160px]"
          :interval="1"
          :dot-size="17"
          tooltip="none"
          :min="-100"
          :max="100"
          @change="onChange"
        />

        <LinkedControlItem
          v-model="control.value"
          :min="-100"
          :max="100"
          class="d-block !w-[60px]"
          @input="onChange"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

export default {
  name: 'SidPosition',
  components: {LinkedControlItem},
  mixins: [SidConcept],
  data () {
    return {
      controls: [
        {
          label: 'Translate-X',
          value: 0
        },
        {
          label: 'Translate-Y',
          value: 0
        }
      ]
    }
  },
  created () {
    this.controls[0].value = parseInt(this.getSidStyle(this.getSid(), 'left'))
    this.controls[1].value = parseInt(this.getSidStyle(this.getSid(), 'top'))
  },
  methods: {
    onChange () {
      this.addSidRules([[this.getSid(), ['top', `${this.controls[1].value}%`]]])
      this.addSidRules([[this.getSid(), ['left', `${this.controls[0].value}%`]]])

      const x = this.controls[0].value === 0 ? 0 : `-${this.controls[0].value}%`
      const y = this.controls[1].value === 0 ? 0 : `-${this.controls[1].value}%`

      this.addSidRules([[this.getSid(), [this.ruleName, `${x} ${y}`]]])
    }
  }
}
</script>