<template>
  <div class="sid-select">
    <draggable
      v-model="options.images"
      handle=".move"
    >
      <div
        v-for="(image, index) in options.images"
        :key="index"
        class="flex items-center h-[29px] rounded-[4px] border mt-2 pl-[4px] mb-2"
      >
        <div class="mr-[11px] move cursor-move ml-2">
          <svg
            fill="none"
            height="11"
            viewBox="0 0 11 11"
            width="11"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.57745 0.894104H0.568359V3.8941H3.57745V0.894104Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 0.894104H7.58936V3.8941H10.5984V0.894104Z"
              fill="#3D82EA"
            />
            <path
              d="M3.57745 7.8941H0.568359V10.8941H3.57745V7.8941Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 7.8941H7.58936V10.8941H10.5984V7.8941Z"
              fill="#3D82EA"
            />
          </svg>
        </div>

        <div
          class="w-[22px] h-[22px] border rounded-[3px] bg-contain bg-no-repeat"
          :style="{backgroundImage: `url(${image.src})`}"
        />

        <div
          class="ml-auto flex items-center justify-center h-full w-[34px] border-l cursor-pointer hover:bg-gray-100"
          @click="options.images.splice(index, 1)"
        >
          <i
            class="fa fa-trash text-[#6e747a] text-[13px]"
            aria-hidden="true"
          />
        </div>
      </div>
    </draggable>

    <b-form-group>
      <image-upload
        :disable-settigns="true"
        :image="image"
        class="w-100"
        @uploaded="onUploaded"
      />
    </b-form-group>

    <b-form-group label="Speed">
      <v-select
        v-model="options.duration"
        :searchable="false"
        :reduce="val => val.value"
        :options="[{label: 'Extra Slow', value: 10}, {label: 'Slow', value: 7}, {label: 'Normal', value: 5}, {label: 'Fast', value: 3}, {label: 'Extra Fast', value: 1}]"
      />
    </b-form-group>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ImageUpload from '@/components/editor/utils/ImageUpload'

export default {
  components: {ImageUpload, draggable},
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      image: {}
    }
  },
  computed: {
    options () {
      if (!this.config.backgroundSlider) {
        this.$set(this.config, 'backgroundSlider', {
          images: [],
          duration: 5,
          animationType: null,
          visilibiliy: {
            lg: true,
            md: null,
            sm: null
          },
          backgroundSize: {},
          events: {}
        })
      }

      return this.config.backgroundSlider
    }
  },
  methods: {
    onUploaded (val) {
      console.log(val)
      this.image = {}
      this.options.images.push({
        id: val.id || 1,
        url: val.url,
        src: val.src || val.url,
        ...val
      })
    }
  }
}
</script>