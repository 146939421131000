<template>
  <b-form @submit.prevent="importPage">
    <b-row
      no-gutters
      class="d-flex justify-content-center align-items-end editor-toolbar"
    >
      <b-col
        cols="10"
        class="mx-auto"
      >
        <b-form-group
          label="Add Share Link"
          label-class="pm-label"
        >
          <b-input
            v-model="hash"
            :disabled="loading"
            required
            class="pm-input"
          />
        </b-form-group>
      </b-col>

      <b-col
        class="d-flex"
        cols="12"
      >
        <b-btn
          :disabled="loading"
          class="!text-[15px] font-weight-bold py-2 px-3 d-flex import-page-select align-items-center mx-auto"
          variant="primary"
          type="submit"
        >
          Add Page Now
          <svg
            v-if="!loading"
            class="ml-2"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.6 8.3C16.6 12.884 12.884 16.6 8.3 16.6C3.71604 16.6 0 12.884 0 8.3C0 3.71604 3.71604 0 8.3 0C12.884 0 16.6 3.71604 16.6 8.3ZM8.3 15.3C12.166 15.3 15.3 12.166 15.3 8.3C15.3 4.43401 12.166 1.3 8.3 1.3C4.43401 1.3 1.3 4.43401 1.3 8.3C1.3 12.166 4.43401 15.3 8.3 15.3Z"
              fill="white"
            />
            <path
              d="M11.8999 8.53198V7.26798H9.03592V4.14798H7.64392V7.26798H4.76392V8.53198H7.64392V11.78H9.03592V8.53198H11.8999Z"
              fill="white"
            />
          </svg>

          <b-spinner
            v-else
            class="ml-2"
            small
          />
        </b-btn>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import _ from 'lodash'
import {mapState} from 'vuex'
import {insertSectionStyle, retriveStyles} from '@/utils/useStylesheet'

export default {
  name: 'ImportPageFromProject',

  data () {
    return {
      step: 0,
      template: null,
      page: null,
      pages: [],
      hash: null,
      loading: false
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    pageMutatuion: {
      get () {
        return this.page
      },
      set (val) {
        if (val.back) return this.step = 1
        this.page = val
      }
    },
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    templateMutation: {
      get () {
        return this.template
      },
      set (val) {
        this.template = {
          ...val,
          pages: JSON.parse(val.setup).pages
        }
        this.step++
      }
    },
    ...mapState('templates', {
      templates: state => state.templates
    })
  },

  created () {
    this.$store.dispatch('templates/getTemplates')
  },

  methods: {
    importPage () {
      this.loading = true
      axios.post('api/pages/by-hash', {
        hash: this.hash.split(',')
      })
        .then(({data}) => {
          console.log(data)
          if (!data.data.length) {
            this.$swal({
              toast: true,
              position: 'top-right',
              icon: 'error',
              title: 'Invalid Page Code. Please Check and Try Again',
              showConfirmButton: false,
              timer: 2500
            })

            return this.loading = false
          }
          let pages = [], original_ids = []
          const clearContent = (content, safeMode = false) => {
            let contentDraft = content
            if (!content) {
              return '[]'
            }

            contentDraft = contentDraft.replace(/"id":\d+,?/g, '')
              .replace(/"form_id":\d+,?/g, '')


            if (!safeMode) {
              contentDraft = contentDraft.replace(/"action":".*?",?/g, '')
                .replace(/"customLink":".*?",?/g, '')
            }

            contentDraft = contentDraft.replace(/"actions":\[.*?\](,?)/g, '"actions": []$1')
              .replace(/"action":\{.*?\}(,?)/g, '"action": null$1')

            return contentDraft.replace(/,\}/g, '}')
          }

          data.data.forEach(page => {
            if (!original_ids.includes(page.project_id)) {
              original_ids.push(page.project_id)

              const pageSettings = JSON.parse(page.project.global_settings).font.addedGoogleFonts

              this.globalStyles.font.addedGoogleFonts = _.map(this.globalStyles.font.addedGoogleFonts, item1 => {
                const match = _.find(pageSettings, { family: item1.family })
                if (match) {
                  return {
                    family: item1.family,
                    variants: _.uniq(_.concat(item1.variants, match.variants)).sort((a, b) => a - b).map(weight => weight.toString())
                  }
                }
                return item1
              })
            }

            const _page = {
              slug: page.slug,
              title: page.title,
              is_home: false,
              meta_title: page.meta_title,
              page_status: page.page_status,
              content_draft: clearContent(page.content_draft, page.save_mode).replaceAll(page.css_hash, page.new_hash),
              content: clearContent(page.content, page.save_mode).replaceAll(page.css_hash, page.new_hash),
              code_injection: page.code_injection,
              settings: page.settings,
              css_hash: page.new_hash
            }

            if (page.modals) {
              _page.modals = page.modals
            }

            pages.push(_page)

            const css = page.project.draft_css ? page.project.draft_css : page.project.css
            const styles = JSON.parse(JSON.stringify(retriveStyles(css, page.css_hash)).replaceAll(page.css_hash, page.new_hash))

            insertSectionStyle(styles)
          })

          VEvent.fire('loader', true)

          axios.post(`api/projects/${this.project.id}/pages/mass-creation`, {
            original_ids,
            pages
          })
            .then(({data}) => {
              this.$swal({
                icon: 'success',
                title: 'Done!',
                text: 'The page have been added.',
                showConfirmButton: false,
                timer: 1500
              })
              this.$emit('page-added')


              if (data.data.length) {
                setTimeout(() => {
                  this.$store.dispatch('projects/save', {})
                    .finally(() => {
                      this.$store.dispatch('pages/loadPage', data.data[0].id)
                        .finally(() => {
                          VEvent.fire('loader', false)
                        })
                    })
                }, 1500)
              }
            })
            .finally(() => {
              this.loading = false
              this.$store.dispatch('pages/loadPages')
            })
        })
    }
  }
}
</script>

<style lang="scss">
.import-page-select {
  height: 38px !important;
  border-radius: 5px !important;
}
</style>
