<template>
  <v-select
    v-model="valueMutated"
    class="custom-select"
    :reduce="val => val.uid"
    label="name"
    :options="sections"
  />
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'SelectBlockPage',
  props: {
    value: {
      required: true
    }
  },
  computed: {
    ...mapGetters('sections', {
      getSections: 'getSections'
    }),
    sections () {
      return this.getSections
    },
    valueMutated: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>