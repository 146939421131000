export const backgroundVideo = {
  type: 'background-video-customs',
  enabled: false,
  url: '',
  overlay: {
    enabled: true,
    color: 'rgba(0,0,0,1)',
    opacity: 0.5
  },
  loop: true,
  ratio: 1
}

export const border = {
  type: 'border-customs',
  style: 'none',
  width: 3,
  color: '#c0c0c0'
}

export const borderRadius = {
  type: 'border-radius-customs',
  radius: 0
}

export const blockWidth = {
  type: 'block-width-customs',
  width: 'full-width'
}
