<template>
  <div>
    <p>Provide detailed instructions for the content you want to generate.</p>

    <b-form-group>
      <b-textarea
        v-model="prompt"
        :rows="5"
        :disabled="loading"
        maxlength="250"
      />
      <div class="text-muted text-right mt-2">
        {{ prompt.length }} / 250
      </div>
    </b-form-group>

    <b-btn
      variant="primary"
      :disabled="loading"
      @click="generate"
    >
      Generate

      <b-spinner
        v-if="loading"
        variant="white"
        class="ml-2"
        small
      />
    </b-btn>
  </div>
</template>

<script>
export default {
  name: 'ChatGpt',
  data () {
    return {
      prompt: '',
      loading: false,
      previousAnswer: '',
      chatHistory: ''
    }
  },
  methods: {
    async generateResponse (prompt) {
      this.loading = true
      const response = await axios.post(
        'https://api.openai.com/v1/engines/gpt-3.5-turbo-instruct/completions',
        {
          prompt,
          max_tokens: 3000,
          temperature: 0.7,
          frequency_penalty: 0.5,
          presence_penalty: 0
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Custom-Token': 'Bearer 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',
            organization: 'org-yQYLrEId3diLPlh1yTc8ojXr'
          }
        }
      )

      const answer = response.data.choices[0].text
      this.previousAnswer = answer
      this.chatHistory += `User: ${prompt}\nChatbot: ${answer}\n`

      return answer
    },
    async generate () {
      const response = await this.generateResponse(this.prompt).finally(() => {
        this.loading = false
      })

      this.$emit('generated', response)
    }
  }
}
</script>

<style scoped>

</style>