<template>
  <div>
    <b-form-group>
      <form-controls-list :form="form" />
    </b-form-group>

    <b-form-group
      label="Input Height"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="controlsSize"
            :interval="1"
            :max="90"
            :min="32"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="controlsSize"
            :max="90"
            :min="1"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Input Font Size"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="controlsFontSize"
            :interval="1"
            :max="120"
            :min="10"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="controlsFontSize"
            :max="120"
            :min="10"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Label Font Size"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="labelsFontSize"
            :interval="1"
            :max="120"
            :min="10"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="labelsFontSize"
            :max="120"
            :min="10"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <font-family-and-weight-control
      text-type="paragraph"
      :family="fontFamily"
      :weight="fontWeight"
      @family="family => fontFamily = family"
      @weight="weight => fontWeight = weight"
    />

    <b-form-group
      label="Spacing"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="spacing"
            :interval="1"
            :max="40"
            :min="0"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="spacing"
            :max="40"
            :min="1"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group class="mb-0">
      <b-row class="align-items-center">
        <b-col>
          <label for="required-mark2">Required Mark</label>
        </b-col>
        <b-col class="text-right">
          <b-checkbox
            id="required-mark2"
            v-model="form.required_mark"
            class="mb-2"
            switch
            value="1"
          />
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import FormControlsList from './FormControlsList'
import _ from 'lodash'
import resolutionMixin from '@/components/mixins/ResolutionMixin'
import FontFamilyAndWeightControl from '@/components/editor/controls/FontFamilyAndWeightControl'

export default {
  name: 'FormFields',
  components: {FontFamilyAndWeightControl, FormControlsList},
  mixins: [resolutionMixin],
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      formName: 'New Form',
      inputSizeOptions: [
        {
          label: 'Small',
          value: 'sm'
        },
        {
          label: 'Default',
          value: null
        },
        {
          label: 'Large',
          value: 'lg'
        }
      ]
    }
  },
  computed: {
    controlsFontSize: {
      get () {
        return _.get(this.form, ['controlsFontSize', this.resolutioner], _.get(this.form, ['controlsFontSize', 'lg'], 16))
      },
      set (val) {
        this.$set(this.form, 'controlsFontSize', {..._.set(this.form['controlsFontSize'] || {}, [this.resolutioner], val)})
      }
    },
    labelsFontSize: {
      get () {
        return _.get(this.form, ['labelsFontSize', this.resolutioner], _.get(this.form, ['labelsFontSize', 'lg'], 16))
      },
      set (val) {
        this.$set(this.form, 'labelsFontSize', {..._.set(this.form['labelsFontSize'] || {}, [this.resolutioner], val)})
      }
    },
    fontFamily: {
      get () {
        return _.get(this.form, ['fontFamily', this.resolutioner], _.get(this.form, ['fontFamily', 'lg'], null))
      },
      set (val) {
        this.$set(this.form, 'fontFamily', {..._.set(this.form['fontFamily'] || {}, [this.resolutioner], val)})
      }
    },
    fontWeight: {
      get () {
        return _.get(this.form, ['fontWeight', this.resolutioner], _.get(this.form, ['fontWeight', 'lg'], null))
      },
      set (val) {
        this.$set(this.form, 'fontWeight', {..._.set(this.form['fontWeight'] || {}, [this.resolutioner], val)})
      }
    },
    controlsSize: {
      get () {
        return _.get(this.form, ['controlsSize', this.resolutioner], _.get(this.form, ['controlsSize', 'lg'], this.form.controlsSize || 40))
      },
      set (val) {
        const _size = typeof this.form.controlsSize === 'object' ? this.form.controlsSize : {}
        this.$set(this.form, 'controlsSize', {..._.set(_size, [this.resolutioner], val)})
      }
    },
    spacing: {
      get () {
        return _.get(this.form, ['spacing', this.resolutioner], _.get(this.form, ['spacing', 'lg'], this.form.spacing || 0))
      },
      set (val) {
        const _spacing = typeof this.form.spacing === 'object' ? this.form.spacing : {}
        this.$set(this.form, 'spacing', {..._.set(_spacing, [this.resolutioner], val)})
      }
    }
  }
}
</script>
