<template>
  <div :style="{...backgroundColor, ...padding, ...shadow}">
    <div
      class="video-item-4"
      :class="container"
    >
      <slot name="title" />
      <slot name="subtitle" />

      <div class="video">
        <slot name="video" />
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'VideoItems3Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.video-item-4 {
  .video {
    margin-top: 15px;
  }
}
</style>
