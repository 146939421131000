<template>
  <b-modal 
    v-model="modal"
    dialog-class="!max-w-[600px] rounded-[.375rem]"
    body-class="!px-[2.5rem] !py-[3rem]"
    hide-header
    hide-footer
    centered
    lazy
  >
    <i
      class="fa fa-close z-[50] absolute top-[15px] right-[17px] text-[16px] text-[#6E747A] transition cursor-pinter hover:opacity-60"
      @click="modal = false"
    />

    <template v-if="!success">
      <b-form
        class="w-full flex flex-col items-center"
        @submit.prevent="submit"
      >
        <h4 class="text-center text-[1.875rem] text-[#363636] leading-[2.25rem] font-[700] font-[ProximaNova]">
          Reactivate Estage Today!
        </h4>
  
        <plan-select
          v-model="activePeriod"
          :plans="plans[activePlan].duration"
          class="mt-[1.25rem]"
        />
  
        <b-form-group class="w-full !mb-[0]">
          <div class="flex items-center justify-between gap-[.625rem]">
            <span class="text-[.9375rem] text-[#202020] leading-[1.125rem] font-[400] font-[ProximaNova]">Plan</span>
            <span class="text-[.9375rem] text-[#202020] leading-[1.125rem] font-[400] font-[ProximaNova]">Price</span>
          </div>
  
          <hr class="border-t-[1px] border-[#D0D5DD] mt-[.5rem] mb-[1rem]">
  
          <b-form-radio-group
            v-model="activePlan"
            class="flex flex-col gap-[.75rem]"
          >
            <template #default>
              <div
                v-for="(item, index) in plans"
                :key="index"
                class="inline-flex items-center justify-between gap-[.625rem]"
              >
                <b-form-radio
                  :value="index"
                  class="text-[.9375rem] text-[#464646] leading-[1.125rem] font-[500] font-[ProximaNova]"
                  :class="[activePlan === index ? 'text-[#000000]' : 'text-[#464646]']"
                >
                  <div class="inline-flex items-center gap-[.25rem]">
                    {{ item.name }}
  
                    <div
                      v-if="index === 1"
                      id="business-plus-information"
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_4684_8605)">
                          <path
                            d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
                            fill="#29292A"
                          />
                          <path
                            d="M7.5 5V7.5M7.5 10H7.50625M13.75 7.5C13.75 10.9518 10.9518 13.75 7.5 13.75C4.04822 13.75 1.25 10.9518 1.25 7.5C1.25 4.04822 4.04822 1.25 7.5 1.25C10.9518 1.25 13.75 4.04822 13.75 7.5Z"
                            stroke="#29292A"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.66927 9.16536L6.66927 3.33203L8.33594 3.33203L8.33594 9.16536L6.66927 9.16536Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M6.66927 11.6657L6.66927 9.99902L8.33594 9.99902L8.33594 11.6657L6.66927 11.6657Z"
                          fill="white"
                        />
                        <defs>
                          <clipPath id="clip0_4684_8605">
                            <rect
                              width="15"
                              height="15"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
  
                      <b-popover
                        target="business-plus-information"
                        triggers="hover"
                        placement="right"
                        custom-class="business-plus-information"
                      >
                        <div class="w-full flex flex-col items-center justify-center">
                          <h4 class="text-center text-[1.5rem] text-[#000000] leading-[2rem] font-[700] font-[ProximaNova]">
                            Here’s Everything You Are Getting In <br>
                            Your Estage Business Plus Account:
                          </h4>
                          <ul class="w-full mt-[1.25rem] columns-2 gap-x-[.625rem] list-none md:!max-h-[362px]">
                            <li
                              v-for="(feature, index) in businessPlusFeatures"
                              :key="index"
                              class="relative w-full inline-block gap-x-[.5rem] text-[.875rem] leading-[2rem] text-[#333333] last:font-[700] last:text-[#333333]"
                            >
                              <svg
                                width="12"
                                height="8"
                                viewBox="0 0 12 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.72727 1L3.72727 7L1 4.27273"
                                  stroke="#3D82EA"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span class="w-full">
                                {{ feature }}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </b-popover>
                    </div>
                  </div>
                </b-form-radio>
                <span
                  class="text-[.9375rem] text-[#464646] leading-[1.125rem] font-[500] font-[ProximaNova]"
                  :class="[activePlan === index ? 'text-[#000000]' : 'text-[#464646]']"
                >
                  ${{ item.duration[activePeriod].price }}/{{ item.duration[activePeriod].interval }}
                </span>
              </div>
            </template>
          </b-form-radio-group>
        </b-form-group>
  
        <b-form-group
          label="Payment Method"
          label-class="text-[13px] leading-[15px] font-[500] text-[#363636] mb-[12px]"
          class="w-full mt-[1.25rem]"
        >
          <div class="inline-flex w-full items-center gap-[15px]">
            <v-select
              v-model="paymentMethod"
              :options="paymentMethods"
              class="w-full !h-[46px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
              :searchable="false"
              label="name"
              required
              @input="handlePMSelectInput"
            />
            <div class="inline-flex flex-shrink-0 items-center gap-[4px]">
              <img
                v-if="!paymentMethods.brand|| paymentMethods.brand === 'visa'"
                src="@/assets/icons/payment-systems/visa.svg"
              >
              <img
                v-if="!paymentMethods.brand || paymentMethods.brand === 'diners-club'"
                src="@/assets/icons/payment-systems/diners-club.svg"
              >
              <img
                v-if="!paymentMethods.brand || paymentMethods.brand === 'amex'"
                src="@/assets/icons/payment-systems/amex.svg"
              >
              <img
                v-if="!paymentMethods.brand || paymentMethods.brand === 'discover'"
                src="@/assets/icons/payment-systems/discover.svg"
              >
              <img
                v-if="!paymentMethods.brand || paymentMethods.brand === 'mastercard'"
                src="@/assets/icons/payment-systems/master-card.svg"
              >
            </div>
          </div>
        </b-form-group>
  
        <template v-if="paymentMethod && paymentMethod.id === -1">
          <b-form-group
            label="Card Number"
            label-class="text-[13px] leading-[16px] font-[500] text-[#363636]"
            class="w-full"
          >
            <div class="relative w-full">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="absolute top-[13px] left-[12px]"
              >
                <path
                  d="M4.75 11.875C4.31277 11.875 3.95833 12.2294 3.95833 12.6667C3.95833 13.1039 4.31277 13.4583 4.75 13.4583H5.54167C5.97889 13.4583 6.33333 13.1039 6.33333 12.6667C6.33333 12.2294 5.97889 11.875 5.54167 11.875H4.75Z"
                  fill="#AAAAAA"
                />
                <path
                  d="M8.70833 11.875C8.27111 11.875 7.91667 12.2294 7.91667 12.6667C7.91667 13.1039 8.27111 13.4583 8.70833 13.4583H11.875C12.3122 13.4583 12.6667 13.1039 12.6667 12.6667C12.6667 12.2294 12.3122 11.875 11.875 11.875H8.70833Z"
                  fill="#AAAAAA"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 4.75C0 3.43832 1.06332 2.375 2.375 2.375H16.625C17.9367 2.375 19 3.43832 19 4.75V14.25C19 15.5617 17.9367 16.625 16.625 16.625H2.375C1.06332 16.625 0 15.5617 0 14.25V4.75ZM2.375 3.95833C1.93777 3.95833 1.58333 4.31277 1.58333 4.75V6.33333H17.4167V4.75C17.4167 4.31277 17.0622 3.95833 16.625 3.95833H2.375ZM17.4167 7.91667H1.58333V14.25C1.58333 14.6872 1.93777 15.0417 2.375 15.0417H16.625C17.0622 15.0417 17.4167 14.6872 17.4167 14.25V7.91667Z"
                  fill="#AAAAAA"
                />
              </svg>

              <b-input
                v-model="form.number"
                v-mask="'#### #### #### ####'"
                v-validate="'required|min:16|max:19'"
                placeholder="**** **** **** ****"
                name="number"
                class="!h-[46px] !pl-[48px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
                :class="{'is-invalid': errors.has('number') ? true : null}"
                autocomplete="cc-number"
                x-autocompletetype="cc-number"
              />
            </div>
          </b-form-group>
  
          <div class="w-full flex items-end">
            <b-form-group
              label="Expiry Date"
              label-class="text-[13px] text-nowrap leading-[16px] font-[500] text-[#363636]"
              class="w-full max-w-[72px] mb-[0]"
            >
              <b-form-input
                v-model="form.exp_month"
                v-mask="'##'"
                v-validate="'required'"
                placeholder="MM"
                name="exp_month"
                class="!h-[46px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
                :class="{'is-invalid': errors.has('exp_month') ? true : null}"
                autocomplete="cc-exp cc-exp-month"
                x-autocompletetype="cc-exp cc-exp-month"
              />
            </b-form-group>

            <b-form-group
              class="w-full max-w-[96px] pt-[27px] ml-[8px] mb-[0]"
            >
              <b-form-input
                v-model="form.exp_year"
                v-mask="'####'"
                v-validate="'required'"
                placeholder="YYYY"
                name="exp_year"
                class="!h-[46px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
                :class="{'is-invalid': errors.has('exp_year') ? true : null}"
                autocomplete="cc-exp cc-exp-year"
                x-autocompletetype="cc-exp cc-exp-year"
              />
            </b-form-group>

            <b-form-group
              label="CVC"
              label-class="text-[13px] leading-[16px] font-[500] text-[#363636]"
              class="max-w-[98px] ml-[20px] mb-[0]"
            >
              <div class="relative w-full">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="absolute top-[15px] left-[12px]"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.31818 6.18182V4.63636C2.31818 2.08636 4.40455 0 6.95455 0C9.50455 0 11.5909 2.08636 11.5909 4.63636V6.18182C12.9045 6.18182 13.9091 7.18636 13.9091 8.5V14.6818C13.9091 15.9955 12.9045 17 11.5909 17H2.31818C1.00455 17 0 15.9955 0 14.6818V8.5C0 7.18636 1.00455 6.18182 2.31818 6.18182ZM3.86364 4.63636C3.86364 2.93636 5.25455 1.54545 6.95455 1.54545C8.65454 1.54545 10.0455 2.93636 10.0455 4.63636V6.18182H3.86364V4.63636ZM2.31818 7.72727C1.85455 7.72727 1.54545 8.03636 1.54545 8.5V14.6818C1.54545 15.1455 1.85455 15.4545 2.31818 15.4545H11.5909C12.0545 15.4545 12.3636 15.1455 12.3636 14.6818V8.5C12.3636 8.03636 12.0545 7.72727 11.5909 7.72727H2.31818Z"
                    fill="#AAAAAA"
                  />
                </svg>
                <b-input
                  v-model="form.cvc"
                  v-mask="'####'"
                  v-validate="'required|min:3|max:4'"
                  placeholder="***"
                  name="cvc"
                  :class="{'is-invalid': errors.has('cvc') ? true : null}"
                  class="!h-[46px] !pl-[40px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
                  autocomplete="cc-cvc"
                  x-autocompletetype="cc-cvc"
                />
              </div>
            </b-form-group>
          </div>

          <b-form-group
            class="w-full"
            label="Cardholder Name"
            label-class="text-[.875rem] leading-[1.25rem] font-[500] text-[#363636]"
          >
            <b-input
              v-model="form.name"
              v-validate="`required`"
              name="name"
              :class="{'is-invalid': errors.has('name') ? true : null}"
              class="!h-[46px] !rounded-[.25rem] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
              autocomplete="cc-name"
              x-autocompletetype="cc-name"
            />
          </b-form-group>

          <div class="w-full flex items-end">
            <b-form-group
              class="w-full"
              label="Country"
              label-class="text-[.875rem] leading-[1.25rem] font-[500] text-[#363636]"
            >
              <country-select
                v-model="form.country"
                v-validate="`required`"
                name="country"
                :class="{'is-invalid': errors.has('country') ? true : null}"
                class="!h-[46px] !rounded-[.25rem] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
                autocomplete="billing country"
                x-autocompletetype="billing country"
              />
            </b-form-group>
            <b-form-group
              class="w-[128px] ml-[1.25rem]"
              label="Zip Code"
              label-class="text-[.875rem] leading-[1.25rem] font-[500] text-[#363636]"
            >
              <b-input
                v-model="form.zip_code"
                v-validate="`required`"
                name="zip_code"
                :class="{'is-invalid': errors.has('zip_code') ? true : null}"
                class="!h-[46px] !rounded-[.25rem] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
                autocomplete="billing postal-code"
                x-autocompletetype="billing postal-code"
              />
            </b-form-group>
          </div>
        </template>
        
        <b-btn
          variant="primary"
          class="w-full h-[3.75rem] mt-[1.25rem] !font-[600]"
          type="submit"
          :disabled="loading || $validator.errors.any()"
        >
          Reactivate
          <b-spinner
            v-if="loading"
            class="ml-3"
            variant="light"
            small
          />
        </b-btn>
  
        <div class="w-full flex items-center justify-start gap-x-[.5rem] mt-[.75rem]">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="text-[#B0B0B0]"
          >
            <path
              d="M9.72222 5H8.88888V3.88888C8.88888 1.74453 7.14438 0 5 0C2.85563 0 1.11112 1.74453 1.11112 3.88888V5H0.277786C0.124245 5 0 5.12425 0 5.27779V12.2222C0 12.835 0.498307 13.3333 1.11112 13.3333H8.88891C9.50169 13.3333 10 12.835 10 12.2222V5.27779C10 5.12425 9.87576 5 9.72222 5ZM5.83172 10.8027C5.84039 10.8811 5.81518 10.9597 5.76255 11.0186C5.70992 11.0775 5.63451 11.1111 5.55557 11.1111H4.44445C4.36552 11.1111 4.2901 11.0775 4.23747 11.0186C4.18484 10.9598 4.15961 10.8811 4.16831 10.8027L4.34354 9.2269C4.05899 9.01992 3.88891 8.6925 3.88891 8.33333C3.88891 7.72055 4.38721 7.22222 5.00003 7.22222C5.61284 7.22222 6.11115 7.72052 6.11115 8.33333C6.11115 8.6925 5.94107 9.01992 5.65651 9.2269L5.83172 10.8027ZM7.22222 5H2.77779V3.88888C2.77779 2.66357 3.77469 1.66667 5 1.66667C6.22531 1.66667 7.22222 2.66357 7.22222 3.88888V5Z"
              fill="#B0B0B0"
            />
          </svg>
          <p class="text-[.875rem] leading-[1.25rem] text-[#979797] m-[0]">
            Your transaction is secured with SSL encryption
          </p>
        </div>
      </b-form>
    </template>
    <template v-else>
      <div class="relative w-full flex flex-col items-center justify-center">
        <h4 class="text-center text-[1.875rem] text-[#363636] leading-[2.25rem] font-[700] font-[ProximaNova] mb-[0]">
          🎉 Success!
        </h4>

        <p class="text-[.875rem] leading-[1.25rem] text-[#979797] mt-[1.25rem] mb-[0]">
          Your Payment was Successful & Plan has been Activated!
        </p>

        <!--        <b-btn-->
        <!--          variant="primary"-->
        <!--          class="w-full h-[3.75rem] mt-[1.25rem] !font-[600]"-->
        <!--          @click="redirect"-->
        <!--        >-->
        <!--          Go to Dashboard-->
        <!--        </b-btn>-->
      </div>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import {VueMaskDirective} from 'v-mask'
import PlanSelect from '@/components/editor/modals/purchase-element/business-plus/controls/PlanSelect.vue'
import {mapState} from 'vuex'
import CountrySelect from '@/layouts/dashboard/compontens/account/general/components/CountrySelect.vue'
export default {
  name: 'ReactivatePlanModal',
  components: {CountrySelect, PlanSelect},
  directives: {
    mask: VueMaskDirective
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    success: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      activePlan: 0,
      activePeriod: 0,
      plans: [
        {
          name: 'Estage Business',
          duration: [
            {
              name: 'Yearly',
              interval: 'year',
              value: 'BUSINESS_Y',
              save: 20,
              price: 1970
            },
            {
              name: 'Monthly',
              interval: 'month',
              value: 'ESTAGE_SUB',
              price: 197
            }
          ]
        },
        {
          name: 'Estage Business Plus',
          duration: [
            {
              name: 'Yearly',
              interval: 'year',
              value: 'BUSINESS_PLUS_Y',
              save: 20,
              price: 2970
            },
            {
              name: 'Monthly',
              interval: 'month',
              value: 'BUSINESS_PLUS_M',
              price: 297
            }
          ]
        }
      ],
      businessPlusFeatures: [
        'Unlimited Video Hosting',
        'Real-time CTA',
        'ESTAGE Public Live Streaming',
        'ESTAGE Private Live Streaming',
        'Live Streaming Instant Replays',
        'Community Live Video Posts',
        'ESTAGE Native Live Chat',
        'Community Private Events',
        'Courses - Daily Challenge Mode',
        'Courses - A/R Integration for Lessons',
        'Community - Opportunities',
        'Community - MSI Ecosystem',
        'Community - IPS (Integrated Product Suite) Licensing and Management',
        'Community - Featured Ad Widget (in feed)',
        'Community - Upcoming Events Widget (in feed)',
        'And much, much more!'
      ],
      paymentMethod: null,
      form: {
        number: '',
        exp_month: null,
        exp_year: null,
        cvc: '',
        name: '',
        country: '',
        zip_code: '',
        main: false
      },
      methods:[],
      expMonthsOptions: [
        {
          value: 1,
          label: 'Jan'
        },
        {
          value: 2,
          label: 'Feb'
        },
        {
          value: 3,
          label: 'Mar'
        },
        {
          value: 4,
          label: 'Apr'
        },
        {
          value: 5,
          label: 'May'
        },
        {
          value: 6,
          label: 'Jun'
        },
        {
          value: 7,
          label: 'Jul'
        },
        {
          value: 8,
          label: 'Aug'
        },
        {
          value: 9,
          label: 'Sep'
        },
        {
          value: 10,
          label: 'Oct'
        },
        {
          value: 11,
          label: 'Nov'
        },
        {
          value: 12,
          label: 'Dec'
        }
      ]
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    paymentMethods () {
      const data  = _.cloneDeep(this.methods)
      data.push({
        id: -1,
        name: 'Add New Card'
      })
      return data
    },
    selectedPlan () {
      return this.plans[this.activePlan].duration[this.activePeriod]
    }
  },
  methods: {
    redirect () {
      window.open('/dashboard', '_self')
    },
    expYearsOptions (endYear) {
      let startYear = new Date().getFullYear()
      const endDate = endYear || new Date().getFullYear()
      let years = []

      while (startYear <= endDate) {
        years.push(startYear)
        startYear++
      }
      return years
    },
    async fetchPaymentMethods () {
      const {data} = await axios.get('api/user/settings/payment-methods')
      const cards = data.data.cards
      this.methods = (cards || []).map(c => {
        c.card.name = `Card ending in .. ${_.get(c,'card.last4')}`
        c.card.brand = _.get(c,'card.brand')
        return { ...c, ...c.card }
      })
    },
    initForm (data = {}) {
      this.form = {
        number: data.number || '',
        exp_month: data.exp_month || null,
        exp_year: data.exp_year || null,
        cvc: data.cvc || '',
        name: this.user.name || '',
        country: data.country || '',
        zip_code: data.zip_code || '',
        main: false
      }
    },
    handlePMSelectInput () {
      let data = { }
      this.$validator.reset()
      if (this.paymentMethod.id !== -1) {
        data = {
          number: `**** **** **** ${_.get(this.paymentMethod,'card.last4')}`,
          exp_month: _.get(this.paymentMethod,'card.exp_month'),
          exp_year: _.get(this.paymentMethod,'card.exp_year')
        }
      }
      this.initForm(data)
    },
    setLoading (val) {
      this.loading = val
    },
    emitSubmit () {
      this.$emit('submit',{
        pm_id: this.paymentMethod.id,
        setLoading: this.setLoading,
        plan: this.selectedPlan.value
      })
    },
    async submit () {
      if (this.paymentMethod.id === -1) {
        let valid = false
        this.$validator.validateAll()
          .then((v) => {
            valid = v
            if (valid) {
              this.loading = true
              // eslint-disable-next-line no-undef
              Stripe.setPublishableKey(process.env.VUE_APP_STRIPE_KEY)

              // eslint-disable-next-line no-undef
              Stripe.createToken({
                number: this.form.number,
                cvc: this.form.cvc,
                exp_month: this.form.exp_month,
                exp_year: this.form.exp_year,
                name: this.form.name,
                address_country: this.form.country,
                address_zip: this.form.zip_code
              }, (status, response) => {
                if (status !== 200) {
                  this.$swal({
                    icon: 'error',
                    title: 'Payment Declined',
                    text: response.error.message,
                    showConfirmButton: false,
                    timer: 3500
                  })

                  return this.loading = false
                }

                axios.post('api/user/settings/payment-methods', {
                  token: response.id,
                  main: this.form.main
                })
                  .then(async (data) => {
                    const pm_id = _.get(data,'data.data.id')

                    await this.fetchPaymentMethods()

                    const pm = this.methods.find(m => m.id === pm_id)

                    if (pm) {
                      this.paymentMethod = pm
                      this.handlePMSelectInput()
                    }

                    this.$swal({
                      icon: 'success',
                      iconColor: '#4F83E3',
                      toast: true,
                      position: 'top-right',
                      title: 'Payment method has been successfully added.',
                      showConfirmButton: false,
                      timer: 3000
                    })

                    this.loading = false

                    this.emitSubmit()
                  })
                  .catch(error => {
                    this.$swal({
                      icon: 'warning',
                      iconColor: '#4F83E3',
                      toast: true,
                      position: 'top-right',
                      title: error.response.data.message,
                      showConfirmButton: false,
                      timer: 3000
                    })
                    this.loading = false
                  })
              })
            }
          })
      } else {
        this.emitSubmit()
      }
    }
  },
  async mounted () {
    await this.fetchPaymentMethods()
    if (!this.paymentMethod) {
      const main = this.methods.find(m => m.main)
      this.paymentMethod = main || this.paymentMethods[0]
    }
    this.handlePMSelectInput()
  }
}
</script>