<template>
  <div
    class="d-flex align-items-center text-truncate"
    style="max-width: 60%;"
  >
    <div
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
      class="dashboard-breadcrumb mr-2"
      :class="isLast(index) ? 'current' : null"
      @click="e => loadFolder(breadcrumb.id)"
    >
      <span v-text="breadcrumb.name" />
      <svg
        v-if="!isLast(index)"
        class="ml-2"
        width="7"
        height="12"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.83748 0.920854C1.06529 0.693049 1.43463 0.693049 1.66244 0.920854L6.3291 5.58752C6.55691 5.81533 6.55691 6.18467 6.3291 6.41248L1.66244 11.0791C1.43463 11.307 1.06529 11.307 0.83748 11.0791C0.609675 10.8513 0.609675 10.482 0.83748 10.2542L5.09167 6L0.83748 1.74581C0.609675 1.51801 0.609675 1.14866 0.83748 0.920854Z"
          fill="#1E1E1E"
          fill-opacity="0.24"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardBreadcrumb',
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  methods: {
    isLast (index) {
      return index + 1 === this.breadcrumbs.length
    },
    loadFolder (id) {
      this.$store.dispatch('dashboard/loadFolder', id)
    }
  }
}
</script>

<style lang="scss">
.dashboard-breadcrumb {
  font-size: 16px;
  color: #44474A;

  &:not(.current):hover {
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>