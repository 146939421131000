<template>
  <div class="flex items-center h-[130px] w-full max-w-[1064px] border rounded-[7px]">
    <div class="flex items-center justify-center border-right h-full w-[116px]">
      <svg
        width="49"
        height="49"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_5932_55705)">
          <path
            d="M43.1417 19.8263V21.1702C41.6817 19.9976 39.8297 19.2937 37.8156 19.2937C33.1167 19.2937 29.2939 23.1166 29.2939 27.8154C29.2939 32.5142 33.1168 36.3371 37.8156 36.3371C39.8297 36.3371 41.6817 35.6332 43.1417 34.4606V35.8045H46.3373V19.8263H43.1417ZM37.8155 33.1415C34.8787 33.1415 32.4895 30.7521 32.4895 27.8154C32.4895 24.8785 34.8788 22.4893 37.8155 22.4893C40.7523 22.4893 43.1416 24.8786 43.1416 27.8154C43.1417 30.7522 40.7524 33.1415 37.8155 33.1415Z"
            fill="black"
          />
          <path
            d="M49 39.5325H0V42.7281H49V39.5325Z"
            fill="black"
          />
          <path
            d="M15.9787 6.27185L2.98438 35.8046H6.47553L10.9282 25.685H21.029L25.4817 35.8046H28.9728L15.9787 6.27185ZM12.3343 22.4894L15.9786 14.2068L19.6228 22.4894H12.3343Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_5932_55705">
            <rect
              width="49"
              height="49"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div>
      <div class="flex items-end gap-[18px] px-[37px]">
        <b-form-group
          label="Font Name"
          class="mb-0"
        >
          <b-input
            v-model="file.font_name"
            class="!w-[238px] mb-0"
          />
        </b-form-group>

        <b-form-group
          label="Font Weight"
          class="!w-[238px] mb-0"
        >
          <v-select
            v-model="file.font_weight"
            :options="weightOptions"
            :reduce="val => val.value"
            :searchable="false"
          />
        </b-form-group>

        <button
          class="h-[37px] w-[100px] text-white rounded-[3px] bg-[#3D82EA] hover:opacity-80 p-0"
          :class="{'opacity-80': loading}"
          :disabled="loading"
          @click="upload"
        >
          <b-spinner
            v-if="loading"
            class="mr-1"
            small
          />
          Save
        </button>
        <button
          class="h-[37px] w-[100px] text-[#6E747A] rounded-[3px] bg-[#EBEBEB] hover:opacity-80 p-0"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'UploadedFont',
  props: {
    file: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      name: null,
      weight: null,
      weightOptions: [
        {
          label: '100',
          value: 100
        },
        {
          label: '200',
          value: 200
        },
        {
          label: '300',
          value: 300
        },
        {
          label: '400',
          value: 400
        },
        {
          label: '500',
          value: 500
        },
        {
          label: '600',
          value: 600
        },
        {
          label: '700',
          value: 700
        },
        {
          label: '800',
          value: 800
        },
        {
          label: '900',
          value: 900
        }
      ]
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    upload () {
      this.$emit('uploading', true)
      axios.post(`api/projects/${this.project.id}/fonts`, {
        font_name: this.file.font_name,
        font_original_name: this.file.font_name,
        font_weight: this.file.font_weight,
        tmp_file: this.file.tmp_file
      })
        .then(() => {
          this.$emit('uploading', false)
          this.$emit('uploaded')

          setTimeout(() => {
            this.cancel()
          }, 100)
        })
        .catch((error) => {
          if (error.response.data.errors.font_weight) {
            this.$swal({
              icon: 'error',
              toast: true,
              position: 'top-right',
              title: 'The font with this font weight was loaded earlier, <br> delete the old one or change the font weight',
              showConfirmButton: false,
              timer: 3000
            })
          }
        })
        .finally(() => {
          this.$emit('uploading', false)
        })
    }
  }
}
</script>