<template>
  <b-modal
    v-model="dialog"
    body-class="py-0 pl-0"
    centered
    hide-footer
    hide-header-close
    size="xl"
    title="Assets Library"
  >
    <b-row
      class="explorer-container"
      no-gutters
    >
      <b-col
        class="bg-light rounded-left"
        cols="3"
      >
        <div
          v-for="tab in tabs"
          v-if="tab.condition()"
          :key="tab.key"
          :class="{'active': tab.key === activeTab}"
          class="d-flex align-items-center explorer-tab-item"
          @click="tab.loader"
        >
          <i
            :class="tab.icon"
            class="mr-3"
          />
          <div>{{ tab.name }}</div>
        </div>
      </b-col>
      <b-col cols="9">
        <div class="p-5">
          <template v-if="activeTab === 'my-images'">
            <label class="position-relative w-100 mb-4">
              <div class="btn-group w-100">
                <slot name="prepend" />
                <div class="btn btn-es-image-upload btn-block">
                  <icon
                    class="mr-2"
                    icon="plus.svg"
                  />

                  Upload Image…
                </div>
              </div>
              <div class="d-none">
                <b-form-file
                  v-model="file"
                  accept=".jpg, .png, .svg, .ico, .gif, .json"
                  @change="setImage"
                />
              </div>
            </label>

            <image-editor
              ref="imageEditor"
              @startUpload="startUpload"
            />

            <b-row>
              <b-col
                v-for="(image, index) in mediaList"
                :key="image.id"
                :data-image-id="image.id"
                class="image-container-box position-relative h-100 mb-4 p-2"
                cols="4"
              >
                <vue-load-image
                  class="explorer-image bg-light rounded p-2"
                  @click.native.stop="addImageFromMyImages(image)"
                >
                  <b-img
                    slot="image"
                    :src="image.url"
                    fluid
                  />
                  <div
                    slot="preloader"
                    class="loader-container"
                  >
                    <b-spinner variant="primary" />
                  </div>
                </vue-load-image>

                <a
                  v-b-tooltip.hover
                  :href="image.url"
                  class="download-image-icon text-muted"
                  target="_blank"
                  title="Open image in new tab"
                >
                  <i class="fas fa-external-link-alt" />
                </a>

                <i
                  class="delete-image-icon fa fa-trash text-muted"
                  @click="deleteImageFromMyImages({...image, index})"
                />
              </b-col>
            </b-row>

            <b-pagination
              v-if="explorerTotalPages > 1"
              v-model="explorerQuery.page"
              :per-page="20"
              :total-rows="explorerTotalPages"
            />
          </template>

          <template v-else-if="activeTab === 'favorites'">
            <b-row>
              <b-col
                v-for="(image, index) in favoriteList"
                :key="index"
                class="image-container-box position-relative h-100 mb-4 p-2"
                cols="4"
              >
                <vue-load-image
                  class="explorer-image bg-light rounded p-2"
                  @click.native.stop="addImageFromFavorites(image)"
                >
                  <b-img
                    slot="image"
                    :src="image"
                    fluid
                  />
                  <div
                    slot="preloader"
                    class="loader-container"
                  >
                    <b-spinner variant="primary" />
                  </div>
                </vue-load-image>
                <a
                  v-b-tooltip.hover
                  :href="image"
                  class="download-image-icon text-muted"
                  target="_blank"
                  title="Open image in new tab"
                >
                  <i class="fas fa-external-link-alt" />
                </a>
                <i
                  class="delete-image-icon fa fa-trash text-muted"
                  @click="deleteImageFromFavorite(index)"
                />
              </b-col>
            </b-row>
          </template>

          <template v-else-if="activeTab === 'unsplash'">
            <b-form-group class="text-right">
              <b-btn
                variant="danger"
                @click="deleteIntegration"
              >
                Delete integration
              </b-btn>
            </b-form-group>
            <b-form-group>
              <b-input
                v-model="unsplashQuery.query"
                placeholder="Search images"
              />
            </b-form-group>
            <b-row>
              <b-col
                v-for="image in mediaList"
                :key="image.id"
                class="unsplash-image h-100 mb-4 p-2"
                cols="4"
              >
                <vue-load-image
                  class="explorer-image bg-light rounded p-2"
                  @click.native.stop="addImageFromUnsplash(image)"
                >
                  <b-img
                    slot="image"
                    :src="image.urls.small"
                    fluid
                  />
                  <div
                    slot="preloader"
                    class="loader-container"
                  >
                    <b-spinner variant="primary" />
                  </div>
                </vue-load-image>
                <div class="unsplash-badge">
                  Photo by <a
                    :href="image.user.links.html"
                    class="text-blue-200 font-weight-bold hover:text-blue-400"
                    target="_blank"
                  >{{ image.user.first_name }} {{ image.user.last_name }}</a>
                </div>
              </b-col>
            </b-row>

            <b-pagination
              v-if="unsplashTotalPages > 1"
              v-model="unsplashQuery.page"
              :per-page="unsplashQuery.perPage"
              :total-rows="unsplashTotalPages"
            />
          </template>

          <template v-else-if="activeTab === 'unsplash-integration'">
            <unsplash-integration @integrated="loadUnsplash" />
          </template>

          <div
            v-else
            class="d-flex align-items-center justify-content-center h-100"
          >
            <b-spinner variant="primary" />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import VueLoadImage from 'vue-load-image'
import {mapGetters, mapState} from 'vuex'
import UnsplashIntegration from '../../builder/utils/api-services/UnsplashIntegration'
import ImageEditor from '../components/ImageEditor'

let unsplashSearchTimeout = null

export default {
  name: 'ImagesExploererModal',

  components: {
    ImageEditor,
    UnsplashIntegration,
    VueLoadImage
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    image: {
      type: Object,
      default: null
    },
    highlight: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      unsplash_settings: null,
      file: [],
      editableImage: false,
      unsplashQuery: {
        query: '',
        page: 1,
        perPage: 9
      },
      explorerQuery: {
        page: 1,
        perPage: 9
      },
      unsplashPaginationTotalPages: null,
      activeTab: 'my-images'
    }
  },

  computed: {
    tabs () {
      return [
        {
          name: 'My Images',
          loader: this.loadMyImages,
          key: 'my-images',
          icon: 'fa fa-folder',
          condition: () => true
        },
        {
          name: 'Unsplash',
          loader: this.loadUnsplash,
          key: 'unsplash',
          icon: 'fab fa-unsplash',
          condition: () => this.unsplash_settings
        },
        {
          name: 'My saved images',
          loader: () => this.activeTab = 'favorites',
          key: 'favorites',
          icon: 'fa fa-save',
          condition: () => true
        },
        {
          name: 'Unsplash',
          loader: () => this.activeTab = 'unsplash-integration',
          key: 'unsplash-integration',
          icon: 'fab fa-unsplash',
          condition: () => !this.unsplash_settings
        }
      ]
    },
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    favoriteList () {
      return this.globalStyles.libraryImages || []
    },

    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),

    ...mapState('project', {
      userSettings: state => state.userSettings
    }),

    ...mapState('explorer', {
      mediaList: state => state.mediaList,
      unsplashTotalPages: state => state.unsplashTotalPages,
      explorerTotalPages: state => state.explorerTotalPages
    }),

    ...mapGetters('apiIntegration', [
      'hasLocalAPIService'
    ])
  },

  watch: {
    unsplashQuery: {
      deep: true,
      handler: function (val) {
        if (!val.query) {
          this.loadUnsplash()

          return false
        }

        window.clearTimeout(unsplashSearchTimeout)

        unsplashSearchTimeout = setTimeout(() => {
          this.$store.dispatch('explorer/unsplashSearch', val)
        }, 400)
      }
    },

    explorerQuery: {
      deep: true,
      handler: function () {
        this.loadMyImages()
      }
    },

    value (val) {
      if (typeof val === 'string') {
        this.activeTab = val
      }

      if (val && this.highlight) {
        this.highlightImage()
      }
    }
  },

  mounted () {
    this.checkUnsplashAPI()
    this.loadMyImages()
  },

  methods: {
    async deleteIntegration () {
      VEvent.fire('loader', true)

      await this.$store.dispatch('apiIntegration/unsplashIntegrate', false)
        .then(() => {
          this.activeTab = 'unsplash-integration'
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    },
    setImage (event) {
      if (event.target.files[0].type === 'image/gif') {
        const formData = new FormData()
        formData.append('file', event.target.files[0])
        return this.startUpload(formData)
      }

      if (event.target.files[0].type === 'image/svg+xml') {
        const formData = new FormData()
        formData.append('file', event.target.files[0])
        return this.startUpload(formData)
      }

      this.$refs.imageEditor.setImage(event)
    },

    async highlightImage () {
      this.activeTab = false
      const page = await axios.get(`api/media/show-media-page/${this.image.id}?perPage=20`)
      this.explorerQuery.page = page.data.data.page
      this.loadMyImages()
    },

    async checkUnsplashAPI () {
      const settings = await axios.get('api/user/settings?name=unsplash_api')
      this.unsplash_settings = settings.data.data
    },

    startUpload (formData) {
      VEvent.fire('loader', true)

      axios.post('api/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(({data}) => {
          if (this.image) {
            this.$set(this.image, 'src', data.data.url)
            this.$set(this.image, 'id', data.data.id)
          }
          this.uploaded()
          this.loadMyImages()
        })
        .finally(() => {
          VEvent.fire('loader', false)
          if (this.image) {
            this.dialog = false
          }
        })
    },

    uploaded () {
      this.$emit('uploaded', this.image)
    },

    deleteImageFromFavorite (index) {
      this.globalStyles.libraryImages.splice(index, 1)
      this.$emit('removed-favorite')
    },

    loadMyImages () {
      this.activeTab = null

      this.$store.dispatch('explorer/images', this.explorerQuery)
        .then(() => {
          this.activeTab = 'my-images'

          if (this.highlight) {
            setTimeout(() => {
              const imageContainer = document.querySelector(`[data-image-id="${this.image.id}"]`)
              imageContainer.scrollIntoView({
                behavior: 'smooth'
              })
              const image = imageContainer.firstElementChild
              image.style.boxShadow = '0 0 0 4px #007bff'

              setTimeout(() => {
                image.style.boxShadow = 'unset'
              }, 1500)
            })
          }
        })
    },

    loadUnsplash () {
      this.activeTab = null

      this.$store.dispatch('explorer/unsplash', {})
        .then(() => {
          this.activeTab = 'unsplash'
        })
      this.unsplashQuery.query = ''
    },

    addImageFromMyImages (image) {
      if (!this.image) return
      this.$set(this.image, 'src', image.url)
      this.$set(this.image, 'id', image.id)
      this.uploaded()

      this.dialog = false
    },

    addImageFromFavorites (image) {
      this.$set(this.image, 'src', image)
      this.uploaded()

      this.dialog = false
    },

    addImageFromUnsplash (image) {
      const path = image.urls.full
      if (!this.image) return
      this.$set(this.image, 'src', path)
      this.$set(this.image, 'id', null)
      this.$set(this.image, 'user', image.user)
      this.uploaded()

      this.dialog = false
    },

    // eslint-disable-next-line no-unused-vars
    deleteImageFromMyImages ({index, id, src}) {
      axios.delete(`api/media/${id}`)
        .then(() => {
          src = null
        })

      this.mediaList.splice(index, 1)
      this.$swal({
        icon: 'success',
        iconColor: '#4F83E3',
        toast: true,
        position: 'top-right',
        title: 'Image was deleted.',
        showConfirmButton: false,
        timer: 3000
      })
    }
  }
}
</script>
