<template>
  <div>
    <template v-for="(item, key) in items">
      <PageSelectMenuPageItem
        v-if="!item.name"
        :key="key"
        :page="item"
        @click.native="() => selectPage(item)"
      />
      <PageSelectMenuCategoryItem
        v-else
        :key="key"
        :category="item"
        @click.native="() => selectCategory(item)"
      />
    </template>
  </div>
</template>

<script>
import PageSelectMenuPageItem from '@/components/editor/components/pagesSelectMenu/PageSelectMenuPageItem'
import PageSelectMenuCategoryItem from '@/components/editor/components/pagesSelectMenu/PageSelectMenuCategoryItem'

export default {
  name: 'PageSelectMenuRenderItems',
  components: {PageSelectMenuPageItem, PageSelectMenuCategoryItem},
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    selectCategory (category) {
      this.$emit('onSelectCategory', category)
    },
    selectPage (page) {
      this.$emit('onSelectPage', page)
    }
  }
}
</script>