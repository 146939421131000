<template>
  <b-form-group label="Border">
    <template slot="label">
      <slot name="label" />
    </template>

    <b-row no-gutters>
      <b-col
        cols="10"
        class="pr-3"
      >
        <b-input-group class="text-group">
          <b-input
            v-model="config.width"
            type="number"
          />
          <template #append>
            <b-input-group-text>px</b-input-group-text>
          </template>
        </b-input-group>
      </b-col>
      <template>
        <b-col cols="2">
          <color-picker
            :color="config.color"
            @input="event => config.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />
        </b-col>
      </template>
    </b-row>
  </b-form-group>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'
import ColorPicker from '../utils/ColorPicker'
import popperMixin from '../mixins/popperMixin'

export default {
  name: 'MaskBorderCustoms',
  components: {ColorPicker},
  mixins: [ControlMixin, popperMixin],

  data () {
    return {
      styles: [
        {
          value: 'none',
          label: 'No Border'
        },
        {
          value: 'solid',
          label: 'Solid'
        },
        {
          value: 'dashed',
          label: 'Dashed'
        },
        {
          value: 'dotted',
          label: 'Dotted'
        },
        {
          value: 'double',
          label: 'Double'
        },
        {
          value: 'outset',
          label: 'Outset'
        }
      ]
    }
  }
}
</script>
