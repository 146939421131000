<template>
  <div>
    <SidbearSettingsGroup
      title="Counter"
      active
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <b-form-group label="Starting Number">
        <LinkedControlItem
          v-model="section.options.content.counter.from"
          :step="100"
          :min="0"
          :max="10000000000000"
          :delay="true"
          class="d-block w-100"
        />
      </b-form-group>

      <b-form-group label="Ending Number">
        <LinkedControlItem
          v-model="section.options.content.counter.to"
          :step="100"
          :min="0"
          :max="10000000000000"
          :delay="true"
          class="d-block w-100"
        />
      </b-form-group>

      <b-form-group>
        <label class="mb-0">Animation Duration (<span class="lowercase">sec</span>)</label>

        <LinkedControlItem
          v-model="section.options.content.counter.speed"
          :step="0.1"
          :min="0"
          :max="20"
          class="d-block w-100"
        />
      </b-form-group>

      <sid-font
        :sid="`.--${section.sid}--wrapper`"
      />

      <sid-range-slider
        :sid="`.--${section.sid}--wrapper`"
        rule-name="font-size"
        label="Font Size"
        var="--counter-size"
      />

      <sid-range-slider
        :sid="`.--${section.sid}--wrapper`"
        rule-name="gap"
        label="Gap"
        default-dimension="px"
        :dimensions="[]"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Font Color</label>

          <sid-color
            :sid="`.--${section.sid}--wrapper`"
            rule-name="color"
          />
        </div>
      </b-form-group>

      <sid-align
        :sid="`.--${section.sid}--wrapper`"
        rule-name="justify-content"
        label="Text Alignment"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Decoration</label>

          <sid-font-style
            :sid="`.--${section.sid}--wrapper`"
            rule-name="font"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="mb-0 pb-0">Background</label>

          <b-checkbox
            v-model="section.options.content.counter.shapeEnabled"
            size="lg"
            switch
            class="mt-[-6px]"
          />
        </div>
      </b-form-group>

      <template v-if="section.options.content.counter.shapeEnabled">
        <sid-background :sid="`.--${section.sid}--digit.bg`" />

        <sid-range-slider
          label="Height"
          :sid="`.--${section.sid}--digit.bg`"
          rule-name="--counter-height"
          var="--counter-height"
        />

        <sid-range-slider
          label="Width"
          :sid="`.--${section.sid}--digit.bg`"
          rule-name="--counter-width"
          var="--counter-width"
        />

        <SidbearSettingsGroup title="Border & Radius">
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--digit.bg`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--digit.bg`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--digit.bg`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--digit.bg`"
            rule-name="border"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup title="Drop Shadow">
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--digit.bg`"
              rule-name="box-shadow"
            />
          </b-form-group>
        </SidbearSettingsGroup>
      </template>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Decimal"
    >
      <b-form-group>
        <b-checkbox v-model="section.options.content.counter.decimal.enabled.lg">
          Decimal
        </b-checkbox>
      </b-form-group>

      <b-form-group label="Char">
        <b-input
          v-model="section.options.content.counter.decimal.char"
          :maxlength="2"
        />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Font Color</label>

          <sid-color
            :sid="`.--${section.sid}--decimal`"
            rule-name="color"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Counter Prefix"
    >
      <b-form-group label="Prefix">
        <b-input
          v-model="section.options.content.counter.prefix.text.lg"
        />
      </b-form-group>

      <sid-range-slider
        label="Gap"
        :sid="`.--${section.sid}--prefix`"
        rule-name="margin-right"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Font Color</label>

          <sid-color
            :sid="`.--${section.sid}--prefix`"
            rule-name="color"
          />
        </div>
      </b-form-group>

      <sid-font :sid="`.--${section.sid}--prefix`" />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--prefix`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Counter Postfix"
    >
      <b-form-group label="Postfix">
        <b-input
          v-model="section.options.content.counter.postfix.text.lg"
        />
      </b-form-group>

      <sid-range-slider
        label="Gap"
        :sid="`.--${section.sid}--postfix`"
        rule-name="margin-left"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Font Color</label>

          <sid-color
            :sid="`.--${section.sid}--postfix`"
            rule-name="color"
          />
        </div>
      </b-form-group>

      <sid-font :sid="`.--${section.sid}--postfix`" />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--postfix`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Animations"
    >
      <animations
        :sid="section.uid"
        :animations="config.animations"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
import Animations from '@/components/editor/controls/Animations'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidFontStyle from '@/components/editor/components/sid-controls/SidFontStyle'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import SidStyles from '@/components/mixins/SidStyles'

export default {
  name: 'PromoMod',
  components: {
    SidBoxShadow,
    SidBackground,
    SidLinkedGroup,
    SidColor,
    SidAlign,
    SidFontStyle,
    SidRangeSlider,
    SidFont,
    Animations, LinkedControlItem, SidbearSettingsGroup},
  mixins: [ModMixin, SidStyles],
  computed: {
    shapeEnabled: {
      get () {
        let _subject = this.section.options.content.counter.shapeEnabled[this.resolutioner]
        if (_subject === null) {
          _subject = this.section.options.content.counter.shapeEnabled.lg
        }

        return _subject
      },
      set (val) {
        this.section.options.content.counter.shapeEnabled[this.resolutioner] = val
      }
    }
  }
}
</script>