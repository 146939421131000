import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Blog Search'
export const group = 'Dynamic Blog Home'
export const beta = false

export const defaultOptions = {
  content: {
    data: {
      type: 'blog-search-mod',
      placeholder: 'Search blog...',
      btnText: 'Search'
    }
  }
}

export const icon = `
`

export default class ElPostCategories extends SectionProvider {
  constructor (options = {}) {
    super('ElBlogSearch', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
