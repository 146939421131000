<template>
  <b-form-group label="Trigger options">
    <v-select
      v-model="trigger.value"
      :options="percents"
      :reduce="val => val.value"
      :searchable="false"
    />
  </b-form-group>
</template>

<script>
import ModalTriggerMixin from './ModalTriggerMixin'

export default {
  name: 'ModalOnScrollTrigger',
  mixins: [ModalTriggerMixin],
  computed: {
    percents () {
      return Array(101)
        .fill({})
        .map((_, i) => {
          return {
            value: i,
            label: `${i}%`
          }
        })
    }
  }
}
</script>
