<template>
  <b-form-group label="Set restriction">
    <v-select
      v-model="password"
      :options="[{title: 'No restriction', id: -1}, ...options]"
      :reduce="val => val.id"
      :serachable="false"
      label="title"
    />
  </b-form-group>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'PagePasswordSelect',
  props: {
    page: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      options: []
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    password: {
      get () {
        return this.page.password_id || -1
      },
      set (val) {
        this.$set(this.page, 'password_id', val > 0 ? val : null)
      }
    }
  },

  created () {
    axios.get(`api/projects/${this.project.id}/passwords`)
      .then(({data}) => {
        this.options = data.data.data
      })
  }
}
</script>
