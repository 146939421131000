<template>
  <div>
    <SidbearSettingsGroup
      title="Cards Slider"
      active
    >
      <draggable
        v-model="section.options.content.carousel.cards"
        class="mt-3"
        handle=".move"
      >
        <transition-group name="shufle">
          <flex-column-drag-item
            v-for="(card, index) in cards"
            :key="`key-${index}`"
            :item="card"
            @open="sectionSettings('card-carousel-card-mod', {subjectIndex: card.sid, title: 'Card', back: () => {sectionSettings('card-carousel-mod')}})"
            @delete="deleteColumn(index, card)"
            @copy="copyColumn(card)"
          />
        </transition-group>
      </draggable>

      <button
        class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
        @click="addNewItem"
      >
        Add Item
        <span class="text-[#3D82EA] text-[20px]">+</span>
      </button>

      <b-form-group label="Animation Type">
        <v-select
          v-model="section.options.content.carousel.animationType"
          :options="animations"
          :reduce="({value}) => value"
          :searchable="false"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Scrollbar">
      <sid-range-slider
        label="Height"
        rule-name="--carousel-scrollbar-height"
        default-dimension="px"
        :dimensions="[]"
        :sid="`.--${section.sid}-- .carousel-card-container`"
      />

      <b-form-group label="Thumb Background">
        <sid-background
          :sid="`.--${section.sid}-- .carousel-card-container::-webkit-scrollbar-thumb`"
        />
      </b-form-group>

      <b-form-group label="Track Background">
        <sid-background
          :sid="`.--${section.sid}-- .carousel-card-container::-webkit-scrollbar-track`"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Card"
    >
      <sid-range-slider
        label="Width"
        rule-name="--width"
        var="--carouse-card-width"
        :sid="`.--${section.sid}--`"
        measurement="width"
        :px-range="[244, 3000]"
      />

      <sid-range-slider
        label="Height"
        rule-name="--height"
        var="--carouse-card-height"
        :sid="`.--${section.sid}--`"
        default-dimension="px"
        :dimensions="['vh', 'px']"
        measurement="height"
      />

      <sid-buttons-group
        label="Vertical Alignment"
        class="mb-0"
        :options="[
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'start',
            tooltip: 'Start'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'center',
            tooltip: 'Center'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'end',
            tooltip: 'End'
          }
        ]"
        :sid="`.--${section.sid}-- .carousel-card`"
        rule-name="justify-content"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import FlexColumnDragItem from '@/components/editor/components/FlexColumnDragItem.vue'
import draggable from 'vuedraggable'
import _ from 'lodash'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import shortid from 'shortid'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup.vue'

export default {
  name: 'CardCarouselMod',
  components: {
    SidButtonsGroup,
    SidBackground, SidLinkedGroup, FlexColumnDragItem, SidbearSettingsGroup, SidRangeSlider, draggable},
  mixins: [ModMixin],
  data () {
    return {
      animations: [
        {
          label: 'Animation 1',
          value: 1
        },
        {
          label: 'Animation 2',
          value: 2
        },
        {
          label: 'Animation 3',
          value: 3
        }
      ]
    }
  },
  computed: {
    cards () {
      return this.section.options.content.carousel.cards
    }
  },
  methods: {
    deleteColumn (index, card) {
      this.cards.splice(index, 1)
      this.clearStyles(document.querySelector(`[data-sid="${card.sid}"]`))
    },
    copyColumn (card) {
      this.cards.push(_.cloneDeep(card))
    },
    addNewItem () {
      this.cards.push({
        sid: `col-${shortid.generate()}`,
        name: 'Card Name',
        elements: []
      })
    }
  }
}
</script>