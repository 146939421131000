<template>
  <component
    :is="tag"
    :class="[{'element-hidden': !visability}]"
    class="mb-0"
    @click="initer"
  >
    <div
      v-if="show"
      class="position-relative text-container"
      :data-sid="section.sid"
      :class="[`--${section.sid}-margin`]"
    >
      <div
        :class="[type, customClass, `--${section.sid}-text`]"
        :style="{...style}"
        class="text-ghost editor__content m-0"
        style="overflow-wrap: anywhere;"
      >
        <div
          class="ProseMirror"
          v-html="value"
        />
      </div>
      <editor-content
        ref="content"
        :class="[type, customClass, {'text-gradient': backgroundGradient}, `--${section.sid}-text`]"
        :editor="editor"
        :style="{...style, textShadow:'none'}"
        class="editor__content m-0"
        style="overflow-wrap: anywhere;"
      />
    </div>
  </component>
</template>

<script>
import {Editor, EditorContent} from 'tiptap'
import {Plugin, PluginKey} from 'prosemirror-state'

import {
  Blockquote,
  Bold,
  Code,
  CodeBlock,
  HardBreak,
  Heading,
  History,
  Italic,
  Strike,
  Underline,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Table,
  TableHeader,
  TableCell,
  TableRow
} from 'tiptap-extensions'

import TextColor from '@builder/plugins/TiptapTextColor'
import TextColorFix from '@builder/plugins/TiptapTextColorFix'
import ComponentProvider from '@builder/components/mixins/ComponentProvider'
import {mapState} from 'vuex'
import FontSize from '@builder/plugins/TiptapFontSize'
import BackgroundColor from '@builder/plugins/TiptapBackgroundColor'
import FontWeight from '@builder/plugins/TiptapFontWeight'
import FontWeight2 from '@builder/plugins/TiptapFontWeight2'
import FontWeight3 from '@builder/plugins/TiptapFontWeight3'
import CustomLink from '@builder/plugins/TiptapCustomLink'
import Typed from '@/plugins/TiptapTyped'
import TiptapFontFamily from '@builder/plugins/TiptapFontFamily'
import Selection from '@builder/plugins/TiptapSelection'
import _ from 'lodash'
import ResolutionMixin from '../../mixins/ResolutionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'
import TextTransform from '@/plugins/TiptapTextTransform'
import TextGradientColor from '@/plugins/TiptapTextGradientColor'
import BackgroundMixin from '@/components/mixins/BackgroundMixin'

export default {
  name: 'ContentEditableV2',

  components: {
    EditorContent
  },

  mixins: [ComponentProvider, ResolutionMixin, BorderMixin, BackgroundMixin],

  props: {
    value: {
      required: true
    },
    customs: {},
    section: {},
    uid: String,
    type: {
      type: String,
      default: 'paragraph'
    },
    customClass: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      show: true,
      keepInBounds: true,
      editor: new Editor({
        extensions: [
          new Selection(),
          new Blockquote(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({levels: [1, 2, 3]}),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new TextColor(),
          new TextColorFix(),
          new TextGradientColor(),
          new Underline(),
          new History(),
          new FontSize(),
          new BackgroundColor(),
          new FontWeight(),
          new FontWeight2(),
          new FontWeight3(),
          new CustomLink(),
          new TextTransform(),
          new TiptapFontFamily(),
          new Typed(),
          new OrderedList(),
          new BulletList(),
          new ListItem(),
          new TodoItem(),
          new TodoList(),
          new Table({
            resizable: true
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow()
          // new TextBackground()
        ],
        onUpdate: ({getHTML}) => {
          const content = getHTML()
          this.$emit('input', content)
        },

        parseOptions: {
          preserveWhitespace: 'full'
        },
        content: this.value
      })
    }
  },

  computed: {
    tag () {
      if (!this.customs.tag) {
        return this.type === 'headline' ? 'h2' : 'div'
      }

      return this.customs.tag
    },
    textShadow () {
      return _.get(this.customs.resolutionStyle[this.resolutioner], 'textShadow', {
        enabled: false,
        x: 0,
        y: 0,
        blur: 0,
        color: '#dddddd'
      })
    },
    backgroundGradient () {
      const _backgroundGradient = _.get(this.customs.resolutionStyle, [this.resolutioner, 'backgroundGradient'], this.customs.resolutionStyle.lg.backgroundGradient)
      if (_backgroundGradient) {
        const backgroundColorSubject = (attrs) => {
          if (!attrs) return null

          const gradient = () => {
            const values = attrs.map(gradient => {
              return `${gradient.color} ${gradient.position}%`
            })
            return `linear-gradient(90deg,${values})`
          }

          return gradient()
        }

        return {
          backgroundImage: backgroundColorSubject(_backgroundGradient)
        }
      }

      return null
    },
    style () {
      return {
        textJustify: 'inter-word',
        textShadow: parseInt(this.textShadow.blur) > 0 ? `${this.textShadow.x}px ${this.textShadow.y}px ${this.textShadow.blur}px ${this.textShadow.color}` : null,
        ...this.backgroundGradient
      }
    },
    ...mapState('historyChanges', {
      historyDisabled: state => state.disable
    })
  },

  watch: {
    historyDisabled () {
      setTimeout(() => {
        this.editor.setContent(this.value)
      })
    }
  },

  mounted () {
    VEvent.listen(this.uid, () => {
      this.initer()
    })

    const handleDomEvents = (view) => {
      const colgroups = [...view.dom.querySelectorAll('colgroup')]

      if (colgroups.length) {
        let tmp = document.createElement('DIV')
        tmp.innerHTML = this.value

        const tables = [...tmp.querySelectorAll('table')]
        tables.forEach((table, index) => {
          const colgroup = table.querySelector('colgroup')
          if (colgroup) {
            colgroup.innerHTML = colgroups[index].innerHTML
          } else {
            table.appendChild(colgroups[index])
          }

          table.style.width = colgroups[index].closest('table').style.width
        })

        setTimeout(() => {
          this.$emit('input', tmp.innerHTML)
        })
      }
    }

    this.editor.registerPlugin(new Plugin({
      key: new PluginKey('codeClearOnPaste'),
      props: {
        handleDOMEvents: {
          textInput: handleDomEvents,
          mouseup: handleDomEvents
        },
        transformPastedHTML: function (text) {
          function stripHtml (html) {
            let tmp = document.createElement('DIV')
            tmp.innerHTML = html
            return tmp.textContent || tmp.innerText || ''
          }

          return stripHtml(text)
        }
      }
    }))
  },

  beforeDestroy () {
    this.editor.destroy()
  },

  methods: {
    initer () {
      this.$store.commit('editor/SET_TIPTAP', this.editor)
    }
  }
}
</script>

<style>
.editor__content:not(.text-ghost) bg-marker {
  background: none !important;
}
</style>
