import SectionProvider from '@builder/sections/SectionProvider'
import Navbar from './Navbar'

export const name = 'Hero_001'
export const group = 'hero'
export const defaultOptions = {
  'content': {
    'items': {
      'type': null,
      'children': [
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'A0NnfbKdG',
                      'display': true,
                      'name': 'Headline',
                      'sid': 'llt0nxbq--d25awJ3c5O',
                      'hideFromSections': false,
                      'component': 'ElHeadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h3',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>Build Your Referrals.</p><p>Build Your Business</p>',
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            }
                          }
                        }
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': '3jUifO22n',
                      'display': true,
                      'name': 'Subheadline',
                      'sid': 'llt0nxbq--1c7DxWVVpZ',
                      'hideFromSections': false,
                      'component': 'ElSubheadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h4',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>Praesent consectetur vel velit eu pretium. Donec dignissim a turpis sed consectetur. </p>',
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            }
                          }
                        },
                        'customize': null
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': 'fUNTvPL6U',
                      'display': true,
                      'name': 'Form',
                      'sid': 'llt0nxbq--XzTVjYFmmu',
                      'hideFromSections': false,
                      'component': 'ElFormV2',
                      'group': 'Form',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'customize': null,
                        'content': {
                          'form': {
                            'display': true,
                            'type': 'form-mod-v2',
                            'controls': [
                              {
                                'label': null,
                                'placeholder': 'Enter email address',
                                'field_name': 'Email',
                                'field_type': 'email',
                                'values': null,
                                'validators': '[]',
                                'is_required': true,
                                'columns': {
                                  'lg': 12,
                                  'md': 12,
                                  'sm': 12
                                },
                                'order': 0,
                                'styles': 'el--pDj3h4n5',
                                'form_nane': 'New Form',
                                'mapping': []
                              }
                            ],
                            'subject': {
                              'uid': 'RSfMebaiz8',
                              'display': true,
                              'name': 'Button',
                              'css': null,
                              'sid': 'llt0nxbq--A7XvvzVD1F',
                              'sids': null,
                              'hideFromSections': false,
                              'component': 'ElButtonV2',
                              'group': 'Media',
                              'layout': null,
                              'options': {
                                'content': {
                                  'button': {
                                    'pressetID': 0,
                                    'display': true,
                                    'type': 'button-mod-v2',
                                    'text': 'Take the 2 Min Quiz',
                                    'buttonType': 'button',
                                    'icon': {
                                      'lottie': {
                                        'jsonPath': '',
                                        'loop': true,
                                        'autoplay': true
                                      }
                                    },
                                    'hoverAnimation': {},
                                    'animations': {
                                      'animation': null,
                                      'once': true,
                                      'offset': 200,
                                      'delay': 50
                                    }
                                  }
                                },
                                'customize': null
                              },
                              'temp_id': 'GJ3MzsHvO9'
                            },
                            'gdpr': {
                              'enabled': false,
                              'text': 'I consent to the processing of data.'
                            },
                            'form': {
                              'name': 'New Form',
                              'actions': [],
                              'api_list_or_tag': {},
                              'is_active': true,
                              'agree_subscribe': false,
                              'project_id': 36211,
                              'created_at': '2023-07-19T03:11:13.000000Z',
                              'updated_at': '2023-08-31T04:24:46.000000Z',
                              'fields': [
                                {
                                  'label': null,
                                  'placeholder': 'Enter email address',
                                  'field_name': 'Email',
                                  'field_type': 'email',
                                  'values': null,
                                  'validators': '[]',
                                  'is_required': true,
                                  'columns': '{"lg":12,"md":12,"sm":12}',
                                  'order': 0,
                                  'styles': 'el--pDj3h4n5'
                                }
                              ],
                              'mappedFields': {}
                            }
                          }
                        }
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': 'i_dQBGMUU',
                      'display': true,
                      'name': 'Subheadline',
                      'sid': 'llt0nxbq--BUoqp4MaM',
                      'hideFromSections': false,
                      'component': 'ElSubheadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h4',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>Start for free, cancel anytime</p>',
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            }
                          }
                        },
                        'customize': null
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': '_mfXq-o0yL',
                  'sid': 'llt0nxbq--fLPE_fw-XG',
                  'highlight': false,
                  '_uid': 'C-2Wh86mw6',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                },
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'c6MOJXlaX',
                      'display': true,
                      'name': 'Image',
                      'sid': 'llt0nxbq--snoRbqHZ9D',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': null,
                              'once': true,
                              'offset': 200,
                              'delay': 50
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 1,
                    'md': 1,
                    'sm': 1
                  },
                  'uid': 'sYqD8OdV3T',
                  'sid': 'llt0nxbq--BJ2X5OynZ2',
                  'highlight': false,
                  '_uid': 'z_CDExSAkU',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': 'Cbed6OC2V',
          'sid': 'llt0nxbq--kObb-r6rUh',
          'temp_id': 'SpR-HP7X_'
        }
      ]
    },
    'lottie': {}
  },
  'backgroundImage': {
    'src': ''
  },
  'backgroundVideo': {
    'enabled': false,
    'url': '',
    'loop': true,
    'ratio': 1
  }
}

export const iframeURL = 'https://my-web-1689721501119.estage.site'

export const beforeBlock = new Navbar().json()

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('BlankV2', options)

    this.name = name
    this.group = group
    this.sid = 'llt0nxbq--6qkmWxly1t'
    this.sids = [
      'llt0nxbq--6qkmWxly1t',
      'llt0nxbq--kObb-r6rUh',
      'llt0nxbq--fLPE_fw-XG',
      'llt0nxbq--d25awJ3c5O',
      'llt0nxbq--1c7DxWVVpZ',
      'llt0nxbq--XzTVjYFmmu',
      'llt0nxbq--A7XvvzVD1F',
      'llt0nxbq--BUoqp4MaM',
      'llt0nxbq--BJ2X5OynZ2',
      'llt0nxbq--snoRbqHZ9D'
    ]
    this.css = [
      [
        '.--llt0nxbq--6qkmWxly1t--container { max-width: 100vw; padding-top: 100px; align-items: center; padding-bottom: 0px; }',
        '.--llt0nxbq--fLPE_fw-XG--flex { max-width: 80vw; --width: 80vw; }',
        '.--llt0nxbq--fLPE_fw-XG--flex-col-el { width: 50%; --width: 50%; }',
        '.--llt0nxbq--BJ2X5OynZ2--flex-col-el { width: 50%; --width: 50%; }',
        '.--llt0nxbq--kObb-r6rUh--flex { max-width: 1300px; --width: 1300px; gap: 0px 71px; }',
        '.--llt0nxbq--fLPE_fw-XG--flex-col-padding { padding: 0px; }',
        '.--llt0nxbq--BJ2X5OynZ2--flex-col-padding { padding: 0px; }',
        '.--llt0nxbq--d25awJ3c5O-text { padding: 20px 0px 20px 20px; }',
        '.--llt0nxbq--d25awJ3c5O-text div { text-align: left; color: rgb(0, 0, 0); font-family: Barlow; font-size: 63px; line-height: 1.1; --line-height: 1.1; letter-spacing: -1px; }',
        '.--llt0nxbq--XzTVjYFmmu--form { gap: 21px 0px; align-items: end; flex-direction: row; padding: 20px; }',
        '.--llt0nxbq--A7XvvzVD1F--1 { margin-bottom: 0px; height: 50px; width: 100%; border-radius: 0px 3px 3px 0px; border-width: 0px; padding-bottom: 9px; padding-top: 9px; --height: 50px; background-color: rgb(93, 192, 254); font-family: Inter; font-weight: 700; font-size: 15px; --width: 100%; }',
        '.--llt0nxbq--XzTVjYFmmu--form .pU9T6Auqb { width: 100%; }',
        '.--llt0nxbq--XzTVjYFmmu--form .faQ0DoIri4 { width: 100%; }',
        '.--llt0nxbq--XzTVjYFmmu--form .input.form-control { border-radius: 3px 0px 0px 3px; border-width: 1px 0px 1px 1px; font-family: Inter; font-weight: 300; }',
        '.--llt0nxbq--6qkmWxly1t--bg { background-color: rgb(251, 247, 233); background-image: url("https://res.cloudinary.com/estage/image/upload/v1689732421/users/user-572/09925ae4afc527d47adae47b5ef58b90.png"); --bg-id: 166388; background-size: 170px; --background-size: repeat; background-repeat: repeat; background-attachment: unset; --custom-bg-size: 170px; }',
        '.--llt0nxbq--XzTVjYFmmu--form .form-control.input { font-size: 15px; }',
        '.--llt0nxbq--1c7DxWVVpZ-text { padding: 0px 20px 10px; }',
        '.--llt0nxbq--1c7DxWVVpZ-text div { text-align: left; color: rgb(0, 0, 0); font-family: Inter; font-weight: 300; font-size: 21px; line-height: 1.5; --line-height: 1.5; }',
        '.--llt0nxbq--BUoqp4MaM-text { padding: 0px 20px 20px; }',
        '.--llt0nxbq--BUoqp4MaM-text div { text-align: left; color: rgb(0, 0, 0); font-family: Inter; font-weight: 400; font-size: 15px; }',
        '.--llt0nxbq--kObb-r6rUh--flex.flex-wrapper { align-items: center; padding-top: 0px; padding-bottom: 0px; }',
        '.--llt0nxbq--A7XvvzVD1F--1:hover { background-color: rgb(34, 156, 230); }',
        '.--llt0nxbq--snoRbqHZ9D--container { justify-content: center; }',
        '.--llt0nxbq--snoRbqHZ9D--wrapper { padding: 20px 20px 0px; }',
        '.el-image.--llt0nxbq--snoRbqHZ9D--image { width: 100%; }',
        '.--llt0nxbq--snoRbqHZ9D--image { content: url("https://res.cloudinary.com/estage/image/upload/v1689811021/users/user-572/78777fc1b19821736524ed1f80c0dc65.png"); --bg-id: 166571; }',
        '.--llt0nxbq--A7XvvzVD1F--1 .main-text { --text-wrap: nowrap; }'
      ],
      [
        '.--llt0nxbq--fLPE_fw-XG--flex { max-width: 100%; --width: 100%; }',
        '.--llt0nxbq--kObb-r6rUh--flex { max-width: 100%; --width: 100%; flex-wrap: nowrap; gap: 0px 10px; }',
        '.--llt0nxbq--d25awJ3c5O-text div { font-size: 38px; }',
        '.--llt0nxbq--d25awJ3c5O-text { padding-left: 0px; padding-bottom: 10px; padding-top: 0px; }',
        '.--llt0nxbq--1c7DxWVVpZ-text { padding-left: 0px; }',
        '.--llt0nxbq--XzTVjYFmmu--form { padding-left: 0px; flex-direction: column; row-gap: 5px; padding-top: 5px; }',
        '.--llt0nxbq--fLPE_fw-XG--flex-col-el { width: 50%; --width: 50%; }',
        '.--llt0nxbq--BJ2X5OynZ2--flex-col-el { width: 50%; --width: 50%; }',
        '.--llt0nxbq--BUoqp4MaM-text { padding-left: 0px; }',
        '.--llt0nxbq--6qkmWxly1t--container { padding-top: 90px; padding-bottom: 0px; }',
        '.--llt0nxbq--XzTVjYFmmu--form .input.form-control { border-top-right-radius: 3px; border-bottom-right-radius: 3px; border-width: 1px; }',
        '.--llt0nxbq--A7XvvzVD1F--1 { border-bottom-left-radius: 3px; border-top-left-radius: 3px; }',
        '.--llt0nxbq--kObb-r6rUh--flex.flex-wrapper { align-items: center; }',
        '.--llt0nxbq--snoRbqHZ9D--wrapper { translate: 0px; }',
        '.--llt0nxbq--snoRbqHZ9D--image { transform: skew(0deg) scale(1) rotateY(0deg) rotateX(0deg) rotateZ(0deg) translateY(0px) translateX(0px) translateZ(0px); --transform: skew(0deg) scale(1) rotateY(0deg) rotateX(0deg) rotateZ(0deg) translateY(0px) translateX(0px) translateZ(0px); }',
        '.--llt0nxbq--1c7DxWVVpZ-text div { font-size: 17px; }',
        '.--llt0nxbq--A7XvvzVD1F--container { width: 100%; --align: stretch; }'
      ],
      [
        '.--llt0nxbq--fLPE_fw-XG--flex { max-width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--llt0nxbq--kObb-r6rUh--flex { max-width: 100%; --width: 100%; flex-flow: column wrap; }',
        '.--llt0nxbq--kObb-r6rUh--flex.flex-wrapper { padding: 30px 0px 0px; }',
        '.--llt0nxbq--6qkmWxly1t--container { padding-top: 70px; padding-right: 10px; padding-left: 10px; }',
        '.--llt0nxbq--fLPE_fw-XG--flex-col-el { width: 90%; --width: 90%; }',
        '.--llt0nxbq--d25awJ3c5O-text div { text-align: center; font-size: 32px; }',
        '.--llt0nxbq--1c7DxWVVpZ-text div { font-size: 16px; text-align: center; }',
        '.--llt0nxbq--d25awJ3c5O-text { padding-bottom: 10px; }',
        '.--llt0nxbq--1c7DxWVVpZ-text { padding-right: 0px; padding-bottom: 25px; }',
        '.--llt0nxbq--XzTVjYFmmu--form { padding-right: 0px; justify-content: center; align-items: center; }',
        '.--llt0nxbq--BUoqp4MaM-text { padding-right: 0px; }',
        '.--llt0nxbq--BUoqp4MaM-text div { font-size: 13px; text-align: center; }',
        '.--llt0nxbq--A7XvvzVD1F--1 { font-size: 15px; }',
        '.--llt0nxbq--XzTVjYFmmu--form .form-control.input { font-size: 14px; }',
        '.--llt0nxbq--BJ2X5OynZ2--flex-col-el { width: 94%; --width: 94%; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
