<template>
  <b-modal
    v-model="modal"
    class="position-relative"
    lazy
    centered
    body-class="py-5"
    content-class="overflow-visible new-section-modal"
    hide-footer
    hide-header
    size="lg"
  >
    <h4 class="text-center font-weight-bold mb-12 px-0">
      Create New Folder
    </h4>

    <b-row>
      <b-col
        offset="2"
        cols="8"
      >
        <b-form @submit.prevent="onsubmit">
          <b-form-group
            class="estage-form-group"
            label="Folder name"
          >
            <b-input
              v-model="form.name"
              v-validate="'required'"
              name="name"
              :state="errors.has('name') ? false : null"
            />
          </b-form-group>

          <div class="text-center mt-5">
            <b-btn
              type="submit"
              variant="primary"
              class="font-weight-bold font-size-14 px-5"
              :disabled="loading"
            >
              <b-spinner
                v-if="loading"
                class="mr-2"
                variant="light"
                small
              />
              Create
            </b-btn>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'PageSelectMenuCreateFolder',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      modal: false,
      loading: false,
      form: {
        name: '',
        parent_id: 0
      }
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  watch: {
    modal (val) {
      this.$emit('input', val)
    },
    value (val) {
      if (val) {
        this.form.name = ''
        this.form.parent_id = 0
      }

      this.modal = val
    }
  },
  methods: {
    onsubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true
          axios.post(`api/projects/${this.project.id}/pages/categories`, this.form)
            .then(() => {
              this.modal = false

              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'The folder has been successfully created',
                showConfirmButton: false,
                timer: 1500
              })

              this.$emit('deleted')
            })
            .catch(() => {
              this.$swal({
                icon: 'error',
                title: 'Server error',
                text: 'Please try again later or let us know about your problem.',
                showConfirmButton: false,
                timer: 1500
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
