<template>
  <v-select
    v-model="location"
    :searchable="false"
    class="!w-[145px] rounded-0"
    :reduce="val => val.key"
    label="name"
    :options="locations"
    @option:selected="val => $emit('selected', val)"
  />
</template>

<script>
export default {
  name: 'Location',
  props: {
    value: {}
  },
  data () {
    return {
      locations: [
        {
          name: 'All Comments',
          key: null
        },
        {
          name: 'By Location',
          key: 'by-location'
        },
        {
          name: 'By Username',
          key: 'by-username'
        }
      ]
    }
  },
  computed: {
    location: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>