<template>
  <div>
    <div class="pt-[20px]">
      <draggable
        v-model="getCols"
        handle=".move"
        @change="onChange"
      >
        <transition-group name="shufle">
          <flex-column-drag-item
            v-for="(column, index) in getCols"
            :key="`key-${index}`"
            :item="column.special ? section.specialCols[column.key] : column"
            @open="column.mod ? sectionSettings(column.mod, {subject: section.specialCols[column.key], title: column.name, back: () => {sectionSettings('flex-mod', customBackOptions)}}) : sectionSettings('flex-column-mod', {subjectIndex: column.uid, title: column.name, back: () => {sectionSettings('flex-mod', customBackOptions)}})"
            @delete="() => deleteColumn(index)"
            @copy="item => copyColumn(item)"
          />
        </transition-group>
      </draggable>

      <background-customs :config="config.options.customize.style.background" />

      <b-form-group
        label="Width (%)"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="config.options.content.width[resolutioner]"
              :interval="1"
              :max="100"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col class="p-0">
            <LinkedControlItem
              v-model="config.options.content.width[resolutioner]"
              :max="100"
              :min="0"
              class="d-block !w-[60px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        label="Horizontal Gap"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="config.options.content.gap.x[resolutioner]"
              :interval="1"
              :max="200"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col class="p-0">
            <LinkedControlItem
              v-model="config.options.content.gap.x[resolutioner]"
              :max="200"
              :min="0"
              class="d-block !w-[60px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        label="Vertical Gap"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="config.options.content.gap.y[resolutioner]"
              :interval="1"
              :max="200"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col class="p-0">
            <LinkedControlItem
              v-model="config.options.content.gap.y[resolutioner]"
              :max="200"
              :min="0"
              class="d-block !w-[60px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center">
          <button
            :class="{'bg-[#F8F8F8] !text-[#44474A]': columnAlignment}"
            class="h-[45px] py-0 text-[15px] px-[15px] border rounded-l-[4px] text-[#7E7E7E] hover:bg-[#F8F8F8]"
            @click="columnAlignment = true"
          >
            Vertical
          </button>
          <button
            :class="{'bg-[#F8F8F8] !text-[#44474A]': !columnAlignment}"
            class="h-[45px] py-0 text-[15px] text-[#7E7E7E] px-[15px] border-top border-bottom hover:bg-[#F8F8F8]"
            @click="columnAlignment = false"
          >
            Horizontal
          </button>
          <button
            v-b-tooltip
            class="h-[45px] py-0 text-[15px] text-[#44474A] px-[15px] border rounded-r-[4px] hover:bg-[#F8F8F8]"
            title="Reverse"
            @click="reverse = !reverse"
          >
            <svg
              fill="none"
              height="13"
              viewBox="0 0 18 13"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :fill="reverse ? '#44474A' : '#7E7E7E'"
                d="M4.67577 7.744C4.365 8.06943 4.365 8.59707 4.67577 8.92251C4.98653 9.24794 5.49038 9.24794 5.80115 8.92251L8.98421 5.58917C9.13344 5.43289 9.21728 5.22093 9.21728 4.99992C9.21728 4.7789 9.13344 4.56694 8.98421 4.41066L5.80115 1.07733C5.49038 0.751892 4.98653 0.751893 4.67577 1.07733C4.365 1.40277 4.365 1.9304 4.67577 2.25584L6.50037 4.16659H1.25963C0.820143 4.16659 0.463867 4.53968 0.463867 4.99992C0.463867 5.46016 0.820143 5.83325 1.25963 5.83325H6.50037L4.67577 7.744Z"
              />
              <path
                :fill="reverse ? '#44474A' : '#7E7E7E'"
                d="M12.6334 12.2558C12.9442 12.5813 13.448 12.5813 13.7588 12.2558C14.0696 11.9304 14.0696 11.4028 13.7588 11.0773L11.9342 9.16659H17.1749C17.6144 9.16659 17.9707 8.79349 17.9707 8.33325C17.9707 7.87301 17.6144 7.49992 17.1749 7.49992H11.9342L13.7588 5.58918C14.0696 5.26374 14.0696 4.7361 13.7588 4.41066C13.448 4.08523 12.9442 4.08523 12.6334 4.41066L9.45036 7.744C9.30112 7.90028 9.21728 8.11224 9.21728 8.33325C9.21728 8.55427 9.30112 8.76623 9.45036 8.92251L12.6334 12.2558Z"
              />
            </svg>
          </button>
        </div>
      </b-form-group>

      <b-form-group>
        <b-checkbox v-model="config.options.content.wrap[resolutioner]">
          Wrap
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <b-checkbox v-model="maxWidth">
          Max Width
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <b-checkbox v-model="config.options.content.fixedWidthEnabled[resolutioner]">
            Fixed Width
          </b-checkbox>

          <LinkedControlItem
            v-model="config.options.content.fixedWidth[resolutioner]"
            :max="4000"
            :min="0"
            class="d-block !w-[80px]"
          />
        </div>
      </b-form-group>
      <b-form-group v-if="config.options.content.hasOwnProperty('fixedHeightEnabled')">
        <div class="flex items-center gap-[10px]">
          <b-checkbox v-model="config.options.content.fixedHeightEnabled[resolutioner]">
            Fixed Height
          </b-checkbox>

          <multi-select
            v-model="fixedHeightV2"
            :default-type="1"
            width="70px"
            :options="[{
                         name: 'px',
                         value: 'px',
                         max: 4000,
                         min: 0,
                         defaultValue: 300
                       },
                       {
                         name: 'Vh',
                         value: 'vh',
                         max: 100,
                         min: 0,
                         defaultValue: 100
                       }]"
          />
        </div>
      </b-form-group>

      <b-form-group label="Position">
        <div class="flex items-center">
          <button
            v-b-tooltip
            :class="{'bg-[#F8F8F8]': verticalAlign === 'start'}"
            class="w-[47px] h-[43px] py-0 px-[11px] border rounded-l-[4px] hover:bg-[#F8F8F8]"
            title="Start"
            @click="() => verticalAlign = 'start'"
          >
            <svg
              fill="none"
              height="15"
              viewBox="0 0 24 15"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_128_80)">
                <path
                  :fill="verticalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
                  d="M10.8591 3.03638H4.72412V14.4114H10.8591V3.03638Z"
                />
                <path
                  :fill="verticalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
                  d="M17.9377 3.03638H11.8027V11.0937H17.9377V3.03638Z"
                />
                <path
                  :fill="verticalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
                  d="M23.6006 2.08826H0.948242V0.666377H23.6006V2.08826Z"
                />
              </g>
              <defs>
                <clipPath id="clip0_128_80">
                  <rect
                    fill="white"
                    height="15"
                    width="24"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button
            v-b-tooltip
            :class="{'bg-[#F8F8F8]': verticalAlign === 'center'}"
            class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom border-right hover:bg-[#F8F8F8]"
            title="Center"
            @click="() => verticalAlign = 'center'"
          >
            <svg
              fill="none"
              height="14"
              viewBox="0 0 23 14"
              width="23"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                :fill="verticalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
                height="12.5494"
                width="6.01636"
                x="4.9118"
                y="0.666626"
              />
              <rect
                :fill="verticalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
                height="10.2254"
                width="6.01636"
                x="11.8538"
                y="1.59619"
              />
              <rect
                :fill="verticalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
                height="1.39437"
                transform="matrix(1 0 0 -1 0.746765 7.63843)"
                width="22.2142"
              />
            </svg>
          </button>
          <button
            v-b-tooltip
            :class="{'bg-[#F8F8F8]': verticalAlign === 'end'}"
            class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom border-right hover:bg-[#F8F8F8]"
            title="End"
            @click="() => verticalAlign = 'end'"
          >
            <svg
              fill="none"
              height="15"
              viewBox="0 0 23 15"
              width="23"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                :fill="verticalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
                height="11.14"
                transform="matrix(1 -8.77996e-08 -8.70476e-08 -1 4.70483 11.8066)"
                width="6.00827"
              />
              <rect
                :fill="verticalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
                height="7.89083"
                transform="matrix(1 -8.77996e-08 -8.70476e-08 -1 11.637 11.8066)"
                width="6.00827"
              />
              <rect
                :fill="verticalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
                height="1.3925"
                transform="matrix(1 0 0 -1 0.545044 14.1278)"
                width="22.1844"
              />
            </svg>
          </button>
          <button
            v-b-tooltip
            :class="{'bg-[#F8F8F8]': verticalAlign === 'Stretch'}"
            class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom border-right hover:bg-[#F8F8F8]"
            title="Stretch"
            @click="() => verticalAlign = 'stretch'"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.78345 2.63062H8.81281V16.6181H3.78345V2.63062Z"
                :fill="verticalAlign === 'stretch' ? '#4F5255' : '#7E7E7E'"
              />
              <path
                d="M10.7834 2.63062H15.8128V16.6181H10.7834V2.63062Z"
                :fill="verticalAlign === 'stretch' ? '#4F5255' : '#7E7E7E'"
              />
              <path
                d="M19.1821 1.35336H0.612061V0.187744H19.1821V1.35336Z"
                :fill="verticalAlign === 'stretch' ? '#4F5255' : '#7E7E7E'"
              />
              <path
                d="M19.1821 19.0601H0.612061V17.8945H19.1821V19.0601Z"
                :fill="verticalAlign === 'stretch' ? '#4F5255' : '#7E7E7E'"
              />
            </svg>
          </button>
        </div>
      </b-form-group>

      <b-form-group label="Alignment">
        <div class="flex items-center">
          <button
            v-b-tooltip
            :class="{'bg-[#F8F8F8]': horizontalAlign === 'start'}"
            class="w-[47px] h-[43px] py-0 px-[11px] border rounded-l-[4px] hover:bg-[#F8F8F8]"
            title="Start"
            @click="() => horizontalAlign = 'start'"
          >
            <svg
              fill="none"
              height="23"
              viewBox="0 0 17 23"
              width="17"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                :fill="horizontalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
                height="11.375"
                transform="rotate(180 16.0269 17.2097)"
                width="6.13501"
                x="16.0269"
                y="17.2097"
              />
              <rect
                :fill="horizontalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
                height="11.375"
                transform="rotate(180 9.02686 17.2097)"
                width="6.13501"
                x="9.02686"
                y="17.2097"
              />
              <rect
                :fill="horizontalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
                height="1.42188"
                transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 1.82373 22.8652)"
                width="22.6524"
              />
            </svg>
          </button>
          <button
            v-b-tooltip
            :class="{'bg-[#F8F8F8]': horizontalAlign === 'center'}"
            class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom border-right hover:bg-[#F8F8F8]"
            title="Center"
            @click="() => horizontalAlign = 'center'"
          >
            <svg
              fill="none"
              height="13"
              viewBox="0 0 18 13"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                :fill="horizontalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
                height="11.375"
                transform="rotate(180 7.02686 12.2097)"
                width="6.13501"
                x="7.02686"
                y="12.2097"
              />
              <rect
                :fill="horizontalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
                height="11.375"
                transform="rotate(180 17.0269 12.2097)"
                width="6.13501"
                x="17.0269"
                y="12.2097"
              />
            </svg>
          </button>
          <button
            v-b-tooltip
            :class="{'bg-[#F8F8F8]': horizontalAlign === 'end'}"
            class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom border-right hover:bg-[#F8F8F8]"
            title="End"
            @click="() => horizontalAlign = 'end'"
          >
            <svg
              fill="none"
              height="23"
              viewBox="0 0 17 23"
              width="17"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                :fill="horizontalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
                height="11.375"
                transform="matrix(1 8.74228e-08 8.74228e-08 -1 0.401855 17.2097)"
                width="6.13501"
              />
              <rect
                :fill="horizontalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
                height="11.375"
                transform="matrix(1 8.74228e-08 8.74228e-08 -1 7.40186 17.2097)"
                width="6.13501"
              />
              <rect
                :fill="horizontalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
                height="1.42188"
                transform="rotate(-90 14.605 22.8652)"
                width="22.6524"
                x="14.605"
                y="22.8652"
              />
            </svg>
          </button>
          <button
            v-b-tooltip
            :class="{'bg-[#F8F8F8]': horizontalAlign === 'between'}"
            class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom hover:bg-[#F8F8F8]"
            title="Between"
            @click="() => horizontalAlign = 'between'"
          >
            <svg
              fill="none"
              height="19"
              viewBox="0 0 23 19"
              width="23"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                :fill="horizontalAlign === 'between' ? '#4F5255' : '#7E7E7E'"
                height="13.9875"
                transform="matrix(-1 8.74228e-08 8.66707e-08 1 8.13904 2.70801)"
                width="5.02936"
              />
              <rect
                :fill="horizontalAlign === 'between' ? '#4F5255' : '#7E7E7E'"
                height="13.9875"
                transform="matrix(-1 8.74228e-08 8.66707e-08 1 20.139 2.70801)"
                width="5.02936"
              />
              <rect
                :fill="horizontalAlign === 'between' ? '#4F5255' : '#7E7E7E'"
                height="1.16562"
                transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 1.85339 18.8879)"
                width="18.57"
              />
              <rect
                :fill="horizontalAlign === 'between' ? '#4F5255' : '#7E7E7E'"
                height="1.16562"
                transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 22.5602 18.8879)"
                width="18.57"
              />
            </svg>
          </button>
          <button
            v-b-tooltip
            :class="{'bg-[#F8F8F8]': horizontalAlign === 'around'}"
            class="w-[47px] h-[43px] py-0 px-[11px] border rounded-r-[4px] hover:bg-[#F8F8F8]"
            title="Around"
            @click="() => horizontalAlign = 'around'"
          >
            <svg
              fill="none"
              height="19"
              viewBox="0 0 20 19"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                :fill="horizontalAlign === 'around' ? '#4F5255' : '#7E7E7E'"
                height="13.9875"
                transform="matrix(-1 8.74228e-08 8.66707e-08 1 9.13904 2.70801)"
                width="5.02936"
              />
              <rect
                :fill="horizontalAlign === 'around' ? '#4F5255' : '#7E7E7E'"
                height="13.9875"
                transform="matrix(-1 8.74228e-08 8.66707e-08 1 16.139 2.70801)"
                width="5.02936"
              />
              <rect
                :fill="horizontalAlign === 'around' ? '#4F5255' : '#7E7E7E'"
                height="1.16562"
                transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 1.85339 18.8879)"
                width="18.57"
              />
              <rect
                :fill="horizontalAlign === 'around' ? '#4F5255' : '#7E7E7E'"
                height="1.16562"
                transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 19.5602 18.8879)"
                width="18.57"
              />
            </svg>
          </button>
        </div>
      </b-form-group>
    </div>

    <SidbearSettingsGroup
      title="Horizontal Scroll"
      class="border-top"
    >
      <flex-scroll-customs :config="config.options.customize" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Background Image"
    >
      <background-image-customs :config="config.options.customize.style.backgroundImage" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Shape Divider"
    >
      <shape-divider-customs :config="config.options.customize.style" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <shadow-customs :config="config.options.customize.style" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Animations"
    >
      <b-form-group label="Onload effect">
        <animations :animations="animations" />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Border & Radius"
    >
      <border-customs-v3
        :config="borderStyle"
        :default-border="[0,0,0,0]"
        :default-radius="[0,0,0,0]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="config.options.customize.style.padding" />
      <margin-full-customs
        :config="config.options.customize.style.marginTop"
        :min="section.group === 'navbar' ? 0 : -500"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      v-if="!hide.includes('visibility')"
      title="Device Visibility"
      content-class="py-0"
    >
      <Panel
        v-if="config.options.content.hasOwnProperty('display')"
        :config="config.options.content"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import _ from 'lodash'
import ModMixin from '@builder/components/mixins/ModMixin'
import draggable from 'vuedraggable'
import {mapState} from 'vuex'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
import FlexColumnDragItem from '@/components/editor/components/FlexColumnDragItem'
import {col} from '@/components/builder/sections/utils/el-inline/Section'
import Panel from '@/components/editor/controls/Panel'

export default {
  name: 'FlexMod',
  components: {Panel, FlexColumnDragItem, LinkedControlItem, SidbearSettingsGroup, draggable},
  mixins: [ModMixin],

  props: {
    customBackOptions: {
      type: Object,
      default: () => {
        return {
          openedItem: 'flex-mod'
        }
      }
    }
  },

  data () {
    return {
      activeColumn: false,
      positionOptions: [
        {
          label: 'Start',
          value: 'start'
        },
        {
          label: 'Center',
          value: 'center'
        },
        {
          label: 'End',
          value: 'end'
        },
        {
          label: 'Between',
          value: 'between'
        },
        {
          label: 'Around',
          value: 'around'
        }
      ]
    }
  },

  computed: {
    borderStyle () {
      if (Array.isArray(this.config.options.customize.style.border)) {
        this.$set(this.config.options.customize.style, 'border', {
          lg: _.clone(this.config.options.customize.style.border || [0, 0, 0, 0]),
          md: _.clone(this.config.options.customize.style.border || [0, 0, 0, 0]),
          sm: _.clone(this.config.options.customize.style.border || [0, 0, 0, 0])
        })
      }

      if (Array.isArray(this.config.options.customize.style.radius)) {
        this.$set(this.config.options.customize.style, 'radius', {
          lg: _.clone(this.config.options.customize.style.radius || [0, 0, 0, 0]),
          md: _.clone(this.config.options.customize.style.radius || [0, 0, 0, 0]),
          sm: _.clone(this.config.options.customize.style.radius || [0, 0, 0, 0])
        })
      }

      return this.config.options.customize.style
    },
    getCols: {
      get () {
        return _.sortBy([...this.config.options.content.cols], [(o) => {
          return _.get(o, ['sortIndex', this.resolutioner], 0)
        }])
      },
      set (val) {
        this.config.options.content.cols = val
      }
    },
    animations () {
      if (!this.section.options.content.animations) {
        this.$set(this.section.options.content, 'animations', {
          animation: null,
          once: true,
          offset: 200,
          delay: 50
        })
      }

      return this.section.options.content.animations
    },
    fixedHeightV2: {
      get () {
        if (!this.section.options.content.fixedHeightV2) {
          return {
            lg: {
              value: this.section.options.content.fixedHeight[this.resolutioner],
              type: 'vh'
            },
            md: {
              value: this.section.options.content.fixedHeight[this.resolutioner],
              type: 'vh'
            },
            sm: {
              value: this.section.options.content.fixedHeight[this.resolutioner],
              type: 'vh'
            }
          }
        }

        return this.section.options.content.fixedHeightV2
      },
      set (val) {
        this.$set(this.section.options.content, 'fixedHeightV2', val)
      }
    },
    ...mapState('editor', {
      resolutionMut: state => state.resolution,
      tablet: state => state.tablet
    }),
    resolutioner () {
      return this.tablet ? 'md' : this.resolutionMut
    },
    horizontalAlign: {
      get () {
        return this.config.options.content.horizontalAlign[this.resolutioner]
      },
      set (val) {
        this.config.options.content.horizontalAlign[this.resolutioner] = val
      }
    },
    verticalAlign: {
      get () {
        return this.config.options.content.verticalAlign[this.resolutioner]
      },
      set (val) {
        this.config.options.content.verticalAlign[this.resolutioner] = val
      }
    },
    columnAlignment: {
      get () {
        return this.config.options.content.column[this.resolutioner]
      },
      set (val) {
        this.config.options.content.column[this.resolutioner] = val
      }
    },
    reverse: {
      get () {
        return this.config.options.content.reverse[this.resolutioner]
      },
      set (val) {
        this.config.options.content.reverse[this.resolutioner] = val
      }
    },
    maxWidth: {
      get () {
        return _.get(this.config.options.content, ['maxWidth', this.resolutioner], _.get(this.config.options.content, ['maxWidth', 'lg'], false))
      },
      set (val) {
        this.$set(this.config.options.content, 'maxWidth', {..._.set(this.config.options.content['maxWidth'] || {}, [this.resolutioner], val)})
      }
    },
    columns () {
      return new Array(this.config.customize.columnsToShow)
        .fill({})
        .map((val, key) => this.config.options.content.columns[this.orderColumns[key]])
    },
    overflow: {
      get () {
        return this.config.options.content.overflow
      },
      set (val) {
        this.$set(this.config.options.content, 'overflow', val)
      }
    },
    orderColumns () {
      return this.config.options.content.cols
    },
    columnsToShow: {
      get () {
        return this.config.options.customize.columnsToShow
      },
      set (val) {
        this.config.options.content.columns.forEach(col => {
          this.$set(col, 'cols', 12 / val)
        })
        this.config.options.customize.columnsToShow = val
      }
    }
  },

  methods: {
    updateIndex () {
      this.config.options.content.cols.forEach((col, index) => {
        col.sortIndex[this.resolutioner] = index
        col.highlight = false
      })
    },
    onChange () {
      this.updateIndex()
    },
    setSection (section) {
      this.$store.commit('editor/SET_SECTION', section)
      this.$store.commit('editor/SET_OPENED_MENU', null)
      this.$store.commit('editor/SET_PAGE', 'section-settings')
    },
    newSectionModal () {
      this.$store.commit('editor/SET_SECTION_MODAL', {
        uid: this.section.uid
      })
    },
    deleteColumn (index) {
      const _index = this.config.options.content.cols.findIndex(col => _.get(col, ['sortIndex', this.resolutioner], null) === index)

      if (_index !== -1) {
        this.config.options.content.cols.splice(_index, 1)
        this.updateIndex()
      }
    },
    copyColumn (item) {
      this.config.options.content.cols.push(_.cloneDeep({
        ...item,
        sortIndex: {
          lg: this.config.options.content.cols.length,
          md: this.config.options.content.cols.length,
          sm: this.config.options.content.cols.length
        }
      }))
    },
    addNewColumn () {
      this.config.options.content.cols.push(_.cloneDeep({
        ...col,
        sortIndex: {
          lg: this.config.options.content.cols.length,
          md: this.config.options.content.cols.length,
          sm: this.config.options.content.cols.length
        }
      }))
    }
  }
}
</script>

<style lang="scss">
.row-panel-settings {
  transform: translateX(-10px);

  .btn {
    background: #f8f9fa !important;
    min-width: 56.8px;

    &:last-child {
      border-right: none !important;
    }
  }
}

.panel-settings-width {
  .btn-group .btn {
    min-width: 48px !important;
  }
}
</style>
