<template>
  <div>
    <b-form-group label="Trigger">
      <v-select
        v-model="selectedMotionTriggerMutation"
        :options="filteredOptions"
        :searchable="false"
        :reduce="val => val.value"
        placeholder="Select trigger"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'MotionTriggers',
  props: {
    motion: {
      type: Object,
      required: true
    }
  },
  computed: {
    selectedMotionTriggerMutation: {
      get () {
        return this.motion.trigger || 'current-element'
      },
      set (val) {
        this.$set(this.motion, 'trigger', val)
      }
    },
    filteredOptions () {
      return [
        // {
        //   label: 'Window',
        //   value: 'window'
        // },
        {
          label: 'Parent Section',
          value: 'parent-section'
        },
        {
          label: 'Parent Flex Wrapper',
          value: 'parent-flex-wrapper'
        },
        {
          label: 'Current Element',
          value: 'current-element'
        }
        // {
        //   label: 'Pick Area (comming soon)',
        //   value: 'window'
        // }
      ]
    }
  }
}
</script>
