<template>
  <div>
    <b-modal
      v-model="modal"
      title="Code Injection"
      hide-footer
      size="lg"
    >
      <b-form @submit.prevent="submit">
        <b-form-group>
          <slot name="label">
            <h6>Embed Custom/Tracking Code Into The &lt;HEAD&gt;</h6>
          </slot>
          <b-textarea
            v-model="injection.head"
            rows="5"
            class="mb-2"
          />
        </b-form-group>

        <b-form-group>
          <slot name="label">
            <h6>Embed Custom/Tracking Code Into The &lt;BODY&gt;</h6>
          </slot>
          <b-textarea
            v-model="injection.body"
            rows="5"
            class="mb-2"
          />
        </b-form-group>

        <div class="d-flex w-100 justify-content-end">
          <b-btn
            variant="primary"
            type="submit"
          >
            Save Changes
          </b-btn>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import CodeInjectionPageMixin from '../../mixins/CodeInjectionPageMixin'

export default {
  name: 'InjectionPageModal',

  mixins: [CodeInjectionPageMixin],

  data () {
    return {
      cdn: [],
      modal: false,
      injection: {},
      reactiveInjection: false
    }
  },

  created () {
    VEvent.listen('injection-code', () => this.inj())
  },

  methods: {
    submit () {
      VEvent.fire('loader', true)

      this.$store.dispatch('pages/save')
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Done!',
            text: 'Changes were saved successfully.',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    },
    inj () {
      this.injection = this.getPageInjections
      this.modal = true
    }
  }
}
</script>
