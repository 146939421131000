export default {
  methods: {
    translateX (motion, delta) {
      if (motion.motion.type === 'repeat') {
        this.values[motion.name].value = this.values[motion.name].value - delta * .1

        if (parseFloat(this.values[motion.name].value) <= motion.motion.values.end) {
          this.values[motion.name].value = 0
        }
      } else if (motion.motion.type === 'infinite') {
        if (this.values[motion.name].value > motion.motion.values.end) {
          this.values[motion.name].increase = false
        }

        if (this.values[motion.name].value < motion.motion.values.start) {
          this.values[motion.name].increase = true
        }

        if (this.values[motion.name].increase) {
          this.values[motion.name].value += 0.5
        } else {
          this.values[motion.name].value -= 0.5
        }
      }

      this.$el.style.transform = `translateX(${this.values[motion.name].value}px)`
    },
    translateY (motion, delta) {
      if (motion.motion.type === 'repeat') {
        this.values[motion.name].value = this.values[motion.name].value - delta * .1

        if (parseFloat(this.values[motion.name].value) <= motion.motion.values.end) {
          this.values[motion.name].value = 0
        }
      } else if (motion.motion.type === 'infinite') {
        if (this.values[motion.name].value > motion.motion.values.end) {
          this.values[motion.name].increase = false
        }

        if (this.values[motion.name].value < motion.motion.values.start) {
          this.values[motion.name].increase = true
        }

        if (this.values[motion.name].increase) {
          this.values[motion.name].value += 0.2
        } else {
          this.values[motion.name].value -= 0.2
        }
      }

      this.$el.style.transform = `translateY(${this.values[motion.name].value}px)`
    }
    // translateX (motion) {
    //   if (motion.motion.type === 'infinite') {
    //     if (this.values[motion.name].value > motion.motion.values.end) {
    //       this.values[motion.name].increase = false
    //     }
    //
    //     if (this.values[motion.name].value < motion.motion.values.start) {
    //       this.values[motion.name].increase = true
    //     }
    //
    //     if (this.values[motion.name].increase) {
    //       this.values[motion.name].value += 0.5
    //     } else {
    //       this.values[motion.name].value -= 0.5
    //     }
    //
    //     console.log(this.values[motion.name])
    //   }
    // }
  }
}