<template>
  <div :style="{...fullMarginSubject(section.options.customize.style)}">
    <div
      :style="scale"
      :class="{'hide-label': !property(section.options.content.time, 'displayLabels', true)}"
    >
      <div
        :class="container"
        class="position-relative time-item-5"
        :style="{...shadow, ...color, ...fullPaddingSubject(section.options.customize.style)}"
      >
        <slot
          name="time"
          v-bind="{color: colorCustom('Subtitle'), scale, sizing}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../../mixins/TemplateMixin'
import ThemeMixin from '../../../../mixins/ThemeMixin'
import PropertiesManager from '../../../../mixins/PropertiesManager'
import _ from 'lodash'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'Carousel1Template',
  mixins: [TemplateMixin, ThemeMixin, PropertiesManager, BorderMixin],
  computed: {
    scale () {
      return _.get(this.section.options.content.time, [`size${this.tablet ? 'md' : this.resolution}`], this.section.options.content.time.size) || 0.7
    },
    sizing () {
      return this.section.options.content.time.sizing || {
        width: 7.3,
        counter: 3,
        divider: 1.5,
        subtitle: 1.2
      }
    }
  }
}
</script>

<style lang="scss">
.time-item-5 {
  .timer-block {
    .timer-item {
      .time {
        border-radius: 50%;
        box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.13);
        background: linear-gradient(180deg, #DADADA 0%, #FFFFFF 69.79%), #FFFFFF;
        position: relative;
        display: block;
        font-weight: 700;
        font-size: 1.4rem;
        text-align: center;
        line-height: 1;
        width: 5rem;
        margin-bottom: 14px;
      }

      .subtitle {
        text-align: center;
        font-weight: 700;
        display: block;
        font-size: .7rem;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }
  }
}

.resolution-sm {
  .time-item-3 {
    .timer-block {
      .timer-item {
        .time {
          padding: 10px 10px;
          font-size: 40px;
          width: auto;
        }

        .time-divider {
          font-size: 30px;
          margin-right: 10px;
          margin-left: 10px;
        }

        .subtitle {
          font-size: 10px;
        }
      }
    }
  }
}
</style>
