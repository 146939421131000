<template>
  <b-modal
    v-model="modal"
    dialog-class="!max-w-[800px]"
    content-class="rounded-[10px]"
    body-class="!px-[64px] !py-[48px]"
    hide-header
    hide-footer
    centered
    lazy
  >
    <i
      class="fa fa-close z-[50] absolute top-[15px] right-[17px] text-[16px] text-[#6E747A] transition cursor-pinter hover:opacity-60"
      @click="modal = false"
    />
    
    <div
      class="w-full flex flex-col items-center gap-y-[32px] font-[ProximaNova]"
    >
      <div class="w-full flex flex-col items-center gap-[16px]">
        <div class="max-w-[600px] w-full flex items-center justify-center gap-[12px]">
          <div>
            <div class="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#FFEAEA] text-[14px] text-[#DB4848]">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 5L5 15M5 5L15 15"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <h3 class="text-[32px] leading-[36px] font-[700] text-[#000000] mb-0">
            Cancel Plan
          </h3>
        </div>

        <p class="text-center text-[16px] leading-[24px] font-[400] text-[#9D9D9D] mb-0">
          You are about to close your ESTAGE account. All your projects and data will be removed.
        </p>
      </div>

      <!--      <div class="w-full aspect-video bg-[#000000] rounded-[5px] overflow-hidden" />-->

      <b-form class="w-full flex flex-col gap-[16px]">
        <!--        <b-form-group-->
        <!--          class="w-full"-->
        <!--          label="Tell us why you’re closing your account (optional):"-->
        <!--          label-class="text-[16px] leading-[24px] font-[500] text-[#000000]"-->
        <!--        >-->
        <!--          <v-select-->
        <!--            v-model="form.reason"-->
        <!--            class="!h-[46px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"-->
        <!--          />-->
        <!--        </b-form-group>-->

        <!--        <b-form-group-->
        <!--          class="w-full"-->
        <!--          label="Your feedback is important to us. Is there anything else you’d like to share? (optional)"-->
        <!--          label-class="text-[16px] leading-[24px] font-[500] text-[#000000]"-->
        <!--        >-->
        <!--          <b-textarea-->
        <!--            v-model="form.feedback"-->
        <!--            class="!rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"-->
        <!--            rows="3"-->
        <!--          />-->
        <!--        </b-form-group>-->

        <b-form-group
          class="w-full"
          label="To continue, enter your password"
          label-class="text-[16px] leading-[24px] font-[500] text-[#000000]"
        >
          <b-input
            v-model="form.confirm_password"
            v-validate="`required`"
            placeholder="Enter Password"
            type="password"
            class="!h-[46px] !rounded-[4px] border border-[#E3E3E3] shadow-[0_1px_3px_0_rgba(0,0,0,0.1)]"
          />
        </b-form-group>

        <b-form-group class="w-full">
          <b-form-checkbox
            v-model="form.agree"
            v-validate="'boolean'"
          >
            <div class="translate-y-[-.35rem] text-[16px] leading-[24px] font-[500] text-[#000000] mb-0">
              I understand that if I close my account, I will not be able to access my projects or data.
            </div>
          </b-form-checkbox>
        </b-form-group>

        <div class="w-full flex flex-row items-center justify-center gap-x-[12px]">
          <b-btn
            variant="danger"
            size="md"
            class="min-w-[160px] h-[40px] !font-[700]"
            :disabled="!valid"
            @click="confirmModal = true"
          >
            Close Account
          </b-btn>

          <b-btn
            variant="outline-dark"
            size="md"
            class="min-w-[160px] h-[40px] !font-[700]"
            @click="modal = false"
          >
            Keep Account
          </b-btn>
        </div>

        <b-modal
          v-model="confirmModal"
          dialog-class="!max-w-[640px]"
          content-class="rounded-[10px]"
          body-class="!px-[64px] !py-[48px]"
          hide-header
          hide-footer
          centered
          lazy
        >
          <div class="relative w-full flex flex-col items-center justify-center">
            <h4 class="text-center text-[1.875rem] text-[#363636] leading-[2.25rem] font-[700] font-[ProximaNova] mb-[0]">
              Are you sure?
            </h4>

            <p class="text-[.875rem] leading-[1.25rem] text-[#979797] text-center mt-[1.25rem] mb-[0]">
              The account will be closed and all your data will be deleted.
            </p>

            <div class="w-full flex flex-row items-center justify-center gap-x-[12px] mt-[1.25rem]">
              <b-btn
                variant="danger"
                size="md"
                class="h-[40px] !font-[700]"
                :disabled="loading || !valid"
                @click="submit"
              >
                Yes
              </b-btn>
              <b-btn
                variant="secondary"
                size="md"
                class="h-[40px] !font-[700]"
                @click="closeModal"
              >
                No
              </b-btn>
            </div>
          </div>
        </b-modal>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import {VueMaskDirective} from 'v-mask'
export default {
  name: 'CancelPlanModal',
  directives: {
    mask: VueMaskDirective
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      confirmModal: false,
      loading: false,
      form: {
        reason: '',
        feedback: '',
        confirm_password: '',
        agree: false
      }
    }
  },
  computed: {
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    valid () {
      return this.form.agree && this.form.confirm_password.length > 0
    }
  },
  created () {
    this.$validator.extend('boolean', value => value)
  },
  methods: {
    closeModal () {
      this.modal = false
      this.confirmModal = false
    },
    async submit () {
      this.loading = true
      let valid = false
      this.$validator.validateAll()
        .then(async (v) => {
          valid = v
          if (valid) {
            await axios.post('/api/user/payment-systems/cancel-account', {
              current_password: this.form.confirm_password
            })
              .then(() => {
                this.loading = false
                this.$swal({
                  icon: 'success',
                  iconColor: '#4F83E3',
                  toast: true,
                  position: 'top-right',
                  title: 'Account has been successfully closed.',
                  showConfirmButton: false,
                  timer: 3000
                })
                window.location = '/account/billing'
              })
              .catch((error) => {
                this.loading = false
                this.$swal({
                  icon: 'error',
                  iconColor: '#4F83E3',
                  toast: true,
                  position: 'top-right',
                  title: 'Something went wrong.',
                  text: error.response.data.errors.current_password[0] || error.response.data.message,
                  showConfirmButton: false,
                  timer: 5000
                })
              })
          }
        })
        .catch((e) => {
          this.loading = false
          console.error(e)
        })
    }
  }
}
</script>