<template>
  <div>
    <h6 class="font-weight-bold mb-3">
      Facebook Settings
    </h6>

    <p class="text-muted font-size-16">
      Add Open Graph Meta Data
    </p>
    <b-form-radio-group
      v-model="form.fb_open_graph_enabled"
      class="mb-3"
      :options="[{value: '1', text: 'Enabled'}, {value: 0, text: 'Disabled'}]"
      button-variant="secondary"
      name="radios-btn-default"
      buttons
    />

    <p class="text-muted mb-4">
      Enable this feature if you want Facebook to display a preview with images and text excerpt when a link to your site is shared.
    </p>


    <h6 class="font-weight-bold">
      Default Settings
    </h6>
    <p class="text-muted">
      This image is used if the post/page being shared does not contain any images
    </p>
    <b-row>
      <b-col md="5">
        <image-upload
          :image="form.fb_open_graph_image"
          class="w-100 mb-3"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'MetaDataFacebook',
  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>