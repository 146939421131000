import { Mark } from 'tiptap'
import { updateMark, removeMark } from 'tiptap-commands'

export default class Background extends Mark {

  get name () {
    return 'background'
  }

  get defaultOptions () {
    return {
      markid: [''],
      color: ['#F2E038'],
      background: ['']
    }
  }

  get schema () {
    return {
      attrs: {
        markid: {
          default: false
        },
        color: {
          default: '#F2E038'
        }
      },
      inclusive: false,
      parseDOM: this.options.background.map(() => ({
        tag: 'bg-marker',
        getAttrs (dom){
          return {
            markid: dom.getAttribute('datamark'),
            color: dom.getAttribute('datacolor')
          }
        }
      })),
      toDOM:
        node => {
          return ['bg-marker', {
            class: `mark-${node.attrs.markid}`,
            style: `background:url(https://estage.site/api/mark?color=${node.attrs.color}&shape=${node.attrs.markid})`,
            dataMark: node.attrs.markid,
            dataColor: node.attrs.color
          }, 0]
        }
    }
  }

  commands ({ type }) {
    return (attrs) => {
      if (!attrs) {
        return removeMark(type, attrs)
      }

      return updateMark(type, attrs)
    }
  }
}
