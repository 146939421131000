<template>
  <div class="border-bottom mb-4">
    <b-form-group
      v-for="(gradient, index) in config.backgroundGradient"
      :key="index"
    >
      <b-row
        no-gutters
        class="align-items-center border-top pt-3"
      >
        <b-col
          cols="8"
          class="mb-3"
        >
          <div
            class="label"
            v-text="`Color ${index + 1}`"
          />
        </b-col>

        <b-col
          cols="4"
          class="d-flex justify-content-end mb-3"
        >
          <color-picker
            :color="gradient.color"
            @input="updateGradientColor(index, $event)"
            @click.stop
          />
        </b-col>

        <b-col cols="7">
          <vue-slider
            v-model="gradient.position"
            class="editor-slider-1"
            tooltip="none"
            :min="0"
            :max="100"
            @change="scheduleEmitChange"
          />
        </b-col>

        <b-col class="pl-3">
          <b-input
            v-model="gradient.position"
            :min="0"
            :max="100"
            type="number"
            step="1"
            size="sm"
            @input="scheduleEmitChange"
          />
        </b-col>

        <b-col cols="1">
          <i
            v-b-tooltip.hover
            title="Position"
            class="fa fa-question-circle cursor-pointer hover:text-blue-500 ml-1"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      v-if="directionEnabled"
      label="Direction (deg)"
    >
      <b-row class="align-items-center">
        <b-col cols="8">
          <vue-slider
            v-model="direction"
            class="editor-slider-1 ml-1"
            tooltip="none"
            :min="0"
            :max="360"
          />
        </b-col>

        <b-col class="pl-2">
          <b-input
            v-model="direction"
            :min="0"
            :max="360"
            type="number"
            step="1"
            size="sm"
          />
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'GradientBackground',
  props: {
    config: {
      type: Object,
      required: true
    },
    directionEnabled: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      mouseOver: false
    }
  },
  computed: {
    direction: {
      get () {
        return this.config.gradientDirection || 90
      },
      set (val) {
        this.$set(this.config, 'gradientDirection', val)
      }
    }
  },
  methods: {
    updateGradientColor (index, event) {
      const rgbaColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`
      this.$set(this.config.backgroundGradient[index], 'color', rgbaColor)
      this.scheduleEmitChange()
    },
    scheduleEmitChange () {
      clearTimeout(this.changeTimeout)
      this.changeTimeout = setTimeout(() => {
        this.$emit('change', this.config)
      }, 500)
    }
  },
  created () {
    if (!this.config.hasOwnProperty('backgroundGradient')) {
      this.$set(this.config, 'backgroundGradient', [
        {
          color: '#090979',
          position: 0
        },
        {
          color: '#00d4ff',
          position: 100
        }
      ])
    }
  }
}
</script>