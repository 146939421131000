import _ from 'lodash'

const state = () => ({
  loading: false,
  products: [],
  selectedProduct: null,
  options: [],
  selected: [],
  limit: 500,
  search: ''
})

const actions = {
  async getProduct ({state}, id) {
    state.loading = true
    await axios.get(`api/user/payment-systems/products/${id}`)
      .then(({data}) => {
        state.loading = false
        return data.data
      })
  },
  async getProductList ({ state, rootState, rootGetters }) {
    try {
      state.loading = true

      const { data: communityProducts } = await axios.get(
        `api/user/payment-systems/products?project_id=${rootState.projects.project.id}&per_page=${state.limit}&q=${state.search}`
      )
      state.products = communityProducts.data.data
      const isSuperAdmin = rootGetters['auth/userRole'].includes('superadministrator') || rootState['auth/user'].id === 4

      if (isSuperAdmin) {
        const { data: masterProducts } = await axios.get(
          `api/admin/crm/subscriptions/plans?per_page=${state.limit}&q=${state.search}`
        )

        const activeMasterProducts = masterProducts.data[0]?.data.filter(item => item.is_active) || []
        state.products = [...activeMasterProducts, ...communityProducts.data.data]
      }

      state.selectedProduct = state.products.length > 0 ? state.products[0] : null
    } catch (error) {
      console.error('Error fetching product list:', error)
    } finally {
      state.loading = false
    }
  },
  differenceOptions ({state}) {
    state.options = _.filter(state.products, item => !_.includes(state.selected, item))
  }
}

const mutations = {
  SET_SELECTED_PRODUCT (state, payload) {
    state.selectedProduct = payload
  },
  SET_SELECTED (state, payload) {
    state.selected = payload
  },
  PUSH_SELECTED (state, payload) {
    state.selected.push(payload)
  },
  SPLICE_SELECTED (state, payload) {
    state.selected.splice(payload, 1)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}