<template>
  <SidbearSettingsGroup
    title="Type Animation"
    beta
  >
    <editor-menu-bar
      v-if="editor"
      v-slot="{ getMarkAttrs, isActive, commands }"
      :editor="editor"
    >
      <div>
        <div
          v-if="!hasSelected"
          class="mt-2"
        >
          Select text to animate
        </div>

        <div v-else>
          <b-btn
            v-if="!isActive.typed()"
            variant="primary"
            @click="() => setup(commands)"
          >
            Add Effect
          </b-btn>

          <typed-animation-controls
            v-else
            :commands="commands"
            :get-mark-attrs="getMarkAttrs('typed')"
            :is-active="isActive.typed()"
            :editor="editor"
          />
        </div>
      </div>
    </editor-menu-bar>
  </SidbearSettingsGroup>
</template>

<script>
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import {EditorMenuBar} from 'tiptap'
import TypedAnimationControls from '@/components/editor/components/TypedAnimationControls'
export default {
  name: 'TypeAnimationSettings',
  components: {TypedAnimationControls, SidbearSettingsGroup, EditorMenuBar},
  props: {
    editor: {
      type: Object,
      required: true
    },
    sid: {
      type: String,
      required: true
    }
  },
  computed: {
    hasSelected () {
      const { selection } = this.editor.state
      return !selection.empty
    }
  },
  methods: {
    setup (commands) {
      const { from, to } = this.editor.selection
      const selectedText = this.editor.state.doc.textBetween(from, to)
      commands.typed({words: `<span>${selectedText}</span>`, backspeed: 70, speed: 70, fade: false})
    }
  }
}
</script>