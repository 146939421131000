<template>
  <b-navbar-nav
    v-if="menu"
    class="align-items-center"
    :class="[{'text-uppercase': customs.uppercase}, cols('', 'py-3')]"
  >
    <div
      v-for="(page, index) in menu.items"
      :key="index"
    >
      <b-nav-item
        v-if="!page.children || !page.children.length"
        class="mr-0"
        :to="{name: 'published', params: {page: page.item_url}}"
      >
        <div
          :style="{color: (page.item_object_id || page.item_url) === $store.state.pages.page.id ? customs.color: globalStyles.colors.primary, fontFamily: globalStyles.font.style || ''}"
          class="text-nowrap"
        >
          {{ page.item_name }} {{ page }}
        </div>
      </b-nav-item>

      <Menu
        v-else
        :label="page.item_name"
        :items="page.children"
        :customs="customs"
      />
    </div>
  </b-navbar-nav>
</template>

<script>
import ComponentProvider from '@builder/components/mixins/ComponentProvider'
import {mapState} from 'vuex'
import Menu from '@builder/components/builder/utils/menu/PubMenu'

export default {
  name: 'PubEsMenuItems',
  components: {Menu},
  mixins: [ComponentProvider],

  computed: {
    ...mapState('sections', {
      sections: state => state.sections
    }),
    ...mapState('menu', {
      menu: state => state.navbarMenu
    })
  },

  created () {
    this.$store.dispatch('menu/loadNavbarMenu')
  }
}
</script>
