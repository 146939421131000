import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Icon Box'
export const group = 'Typography'

export const defaultOptions = {
  'selectedSubjectIndex': 0,
  'content': {
    'iconBox': {
      'type': 'icon-box-mod',
      'customIcon': 'icon',
      'display': true,
      'icon': 'fa fa-check-circle',
      'lottie': {
        'loop': true,
        'autoplay': true
      },
      'subject': {
        'uid': 'Aix0I-8NC',
        'display': true,
        'name': 'Paragraph',
        'css': [
          [
            '.--cea967j--N_L8QGziUq-text div { text-align: left; font-size: 16px; }'
          ],
          [],
          []
        ],
        'sid': 'cea967j--N_L8QGziUq',
        'sids': [
          'cea967j--N_L8QGziUq'
        ],
        'hideFromSections': false,
        'component': 'ElTypography',
        'group': 'Text',
        'layout': null,
        'options': {
          'content': {
            'description': {
              'type': 'tiptap-mod-v2',
              'display': true,
              'resolutionStyle': {
                'sm': {},
                'md': {},
                'lg': {}
              },
              'text': '<p>Cras faucibus purus tincidunt tincidunt mollis.</p>',
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'customize': null
        },
        'editor': 'project',
        'temp_id': 'Wolqva4u8v'
      }
    }
  }
}

export const icon = `
<svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="3" cy="3" r="3" fill="black"/>
<rect x="10" width="13" height="2" rx="1" fill="black"/>
<rect x="10" y="5" width="13" height="2" rx="1" fill="black"/>
<rect x="10" y="10" width="7" height="2" rx="1" fill="black"/>
</svg>
`
export default class ElIconBox extends SectionProvider {
  constructor (options = {}) {
    super('ElIconBox', options)

    this.name = name
    this.group = group
    this.css = [
      [],
      [],
      []
    ]
    this.sid = 'l4p37inr--WjVMgwu6sf'
    this.sids = ['l4p37inr--WjVMgwu6sf', 'cea967j--N_L8QGziUq']
    this.defaultOptions = defaultOptions
  }
}

