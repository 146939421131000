<template>
  <div
    class="badge-addon"
    :class="[{'badge-rounded': options.settings.rounded}, options.settings.position]"
    :style="style"
  >
    {{ options.settings.text }}
  </div>
</template>

<script>
import {mapState} from 'vuex'
const hexRgb = require('hex-rgb')

export default {
  name: 'BadgeAddon',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      globalStyles: state => state.editor.globalStyles
    }),
    style () {
      return {
        color: this.options.settings.color || this.theme(),
        backgroundColor: this.options.settings.backgroundColor || this.globalStyles.colors.primary
      }
    }
  },
  methods: {
    theme () {
      const rgb = this.toRGBArray(this.globalStyles.colors.primary)
      const o = Math.round(((parseInt(rgb[0]) * 265) + (parseInt(rgb[1]) * 287) + (parseInt(rgb[2]) * 114)) / 1000)

      if (o > 125) {
        return '#1d1d1d'
      } else {
        return '#fff'
      }
    },
    toRGBArray (color) {
      if (color === 'Global Style') return ''

      if (color.indexOf('rgb') !== -1) {
        return color.split('(')[1].split(')')[0].split(',')
      }

      return hexRgb(color, {format: 'array'})
    }
  }
}
</script>

<style lang="scss">
.badge-addon {
  position: absolute;
  padding: 8px;

  &.top-left {
    top: 0;
    left: 0;

    &.badge-rounded {
      border-bottom-right-radius: 5px;
    }
  }

  &.top-right {
    top: 0;
    right: 0;

    &.badge-rounded {
      border-bottom-left-radius: 5px;
    }
  }

  &.bottom-right {
    bottom: 0;
    right: 0;

    &.badge-rounded {
      border-top-left-radius: 5px;
    }
  }

  &.bottom-left {
    bottom: 0;
    left: 0;

    &.badge-rounded {
      border-top-right-radius: 5px;
    }
  }
}
</style>
