<template>
  <div
    v-if="visability('image')"
    class="preview-image-element position-relative z-100 p-[2px]"
  >
    <TemplateV2 :section="section">
      <div class="relative">
        <image-fit-v2
          :customs="section.options.content.image"
        />
        <slot />
      </div>
    </TemplateV2>
  </div>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import ImageFitV2 from '../../../../utils/ImageFitV2'
import TemplateV2 from './TemplateV2'

export default {
  name: 'ElImageV2',

  components: {TemplateV2, ImageFitV2},

  mixins: [SectionMixin],

  computed: {
    user () {
      return this.section.options.content.image.user || null
    }
  },

  created () {
    this.buildLink()
  },

  methods: {
    buildLink () {
      if (!this.section.options.content.image.page || this.section.options.content.image.action !== 'Link' && this.section.options.content.image.page.id !== 'custom-link') {
        return null
      }

      return {
        href: this.section.options.content.image.customLink,
        target: '_blank'
      }
    },
    onClick () {
      if (this.section.options.content.image.action === 'Modal' && this.section.options.content.image.modal) {
        return this.$store.dispatch('modal/load', this.section.options.content.image.modal.data.modal_id)
      }
    }
  }
}
</script>

<style lang="scss">
.preview-image-element {
  .unsplash-badge2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-25px);
    opacity: 0;
    width: auto;
    white-space: nowrap;
    padding: 2px 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: rgba(0, 0, 0, 0.67);
    color: #fff;
    transition: .4s;
  }


  &:hover {
    .unsplash-badge2 {
      opacity: 1;
    }
  }
}
</style>