import SectionProvider from '@builder/sections/SectionProvider'
import Navbar from './Navbar'

export const name = 'Hero_008'
export const group = 'hero'
export const defaultOptions = {
  'content': {
    'items': {
      'type': null,
      'children': [
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': '4BPuiQJew',
                      'display': true,
                      'name': 'Headline',
                      'sid': 'l68huqyj--LACKGLsBR_',
                      'hideFromSections': false,
                      'component': 'ElHeadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h1',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p><spancolo style="color:rgba(255, 255, 255, 1)">Turn your testimonials into your most powerful</spancolo> <gradient-span style="background-image:linear-gradient(90deg, rgb(123, 53, 186) 0%, rgb(213, 64, 210) 100%)" dataraw="[{&quot;color&quot;:&quot;rgba(123, 53, 186, 1)&quot;,&quot;position&quot;:0},{&quot;color&quot;:&quot;rgba(213, 64, 210, 1)&quot;,&quot;position&quot;:100}]">marketing weapon</gradient-span></p>',
                            'animations': {
                              'animation': 'fade-in',
                              'once': true,
                              'offset': '300',
                              'delay': '70',
                              'duration': '1500'
                            }
                          }
                        }
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': 'N0WXESDY1y',
                  'sid': 'l68huqyj--rGi8mA3DpW',
                  'highlight': false,
                  '_uid': 'Jw4K2H7w_0',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                },
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'lU8nGC6fl',
                      'display': true,
                      'name': 'Form',
                      'sid': 'l68huqyj--FOhNzC6fsJ',
                      'hideFromSections': false,
                      'component': 'ElFormV2',
                      'group': 'Form',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'customize': null,
                        'content': {
                          'form': {
                            'display': true,
                            'type': 'form-mod-v2',
                            'controls': [
                              {
                                'label': null,
                                'placeholder': 'Enter email address',
                                'field_name': 'Email',
                                'field_type': 'email',
                                'values': null,
                                'validators': '[]',
                                'is_required': true,
                                'columns': {
                                  'lg': 12,
                                  'md': 12,
                                  'sm': 12
                                },
                                'order': 0,
                                'styles': 'el-NU9XhUwwl',
                                'form_nane': 'New Form',
                                'mapping': []
                              }
                            ],
                            'subject': {
                              'uid': 'RSfMebaiz8',
                              'display': true,
                              'name': 'Button',
                              'css': null,
                              'sid': 'l68huqyj--5k4CSdW44L',
                              'sids': null,
                              'hideFromSections': false,
                              'component': 'ElButtonV2',
                              'group': 'Media',
                              'layout': null,
                              'options': {
                                'content': {
                                  'button': {
                                    'pressetID': 0,
                                    'display': true,
                                    'type': 'button-mod-v2',
                                    'text': 'SIGN UP FOR FREE',
                                    'buttonType': 'button',
                                    'icon': {
                                      'lottie': {
                                        'jsonPath': '',
                                        'loop': true,
                                        'autoplay': true
                                      }
                                    },
                                    'hoverAnimation': {},
                                    'animations': {
                                      'animation': null,
                                      'once': true,
                                      'offset': 200,
                                      'delay': 50
                                    }
                                  }
                                },
                                'customize': null
                              },
                              'temp_id': 'GJ3MzsHvO9'
                            },
                            'gdpr': {
                              'enabled': false,
                              'text': 'I consent to the processing of data.'
                            },
                            'form': {
                              'name': 'New Form',
                              'actions': [],
                              'api_list_or_tag': {},
                              'is_active': true,
                              'agree_subscribe': false,
                              'project_id': 36511,
                              'created_at': '2023-08-01T07:30:04.000000Z',
                              'updated_at': '2023-08-02T06:12:35.000000Z',
                              'fields': [
                                {
                                  'label': null,
                                  'placeholder': 'Enter email address',
                                  'field_name': 'Email',
                                  'field_type': 'email',
                                  'values': null,
                                  'validators': '[]',
                                  'is_required': true,
                                  'columns': '{"lg":12,"md":12,"sm":12}',
                                  'order': 0,
                                  'styles': 'el-NU9XhUwwl'
                                }
                              ],
                              'mappedFields': {}
                            }
                          }
                        }
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 1,
                    'md': 1,
                    'sm': 2
                  },
                  'uid': '9kdhLzxLGJ',
                  'sid': '68huqyj--EcHaCiI5K',
                  'highlight': false,
                  '_uid': '68huqyj--EcHaCiI5K',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                },
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'o5ur_09XB',
                      'display': true,
                      'name': 'Image',
                      'sid': 'l68huqyj--17qzC_N4ws',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': 'fade-up',
                              'once': true,
                              'offset': '-500',
                              'delay': '90',
                              'duration': '700'
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 2,
                    'md': 2,
                    'sm': 1
                  },
                  'uid': 'UvVTltSIG',
                  'sid': 'l68huqyj--RMnSRbyF_R',
                  'highlight': false,
                  '_uid': 'EHQwas3lMB',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': 'ggCqQAtXr',
          'sid': 'l68huqyj--bZ6iBKMR_N',
          'temp_id': 'SpR-HP7X_'
        }
      ]
    },
    'lottie': {}
  },
  'backgroundImage': {
    'src': ''
  },
  'backgroundVideo': {
    'enabled': false,
    'url': '',
    'loop': true,
    'ratio': 1
  }
}

export const iframeURL = 'https://my-web-1690856258650.estage.site'

export const beforeBlock = new Navbar().json()

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('BlankV2', options)

    this.name = name
    this.group = group
    this.sid = 'l68huqyj--p4HhMsmY_O'
    this.sids = [
      'l68huqyj--p4HhMsmY_O',
      'l68huqyj--bZ6iBKMR_N',
      'l68huqyj--rGi8mA3DpW',
      'l68huqyj--LACKGLsBR_',
      '68huqyj--EcHaCiI5K',
      'l68huqyj--FOhNzC6fsJ',
      'l68huqyj--5k4CSdW44L',
      'l68huqyj--RMnSRbyF_R',
      'l68huqyj--17qzC_N4ws'
    ]
    this.css = [
      [
        '.--l68huqyj--p4HhMsmY_O--container { max-width: 100vw; padding-bottom: 0px; }',
        '.--l68huqyj--p4HhMsmY_O--bg { background-color: rgb(31, 31, 44); }',
        '.--l68huqyj--rGi8mA3DpW--flex { max-width: 80vw; --width: 80vw; }',
        '.--l68huqyj--rGi8mA3DpW--flex-col-el { width: 875px; --width: 875px; }',
        '.--l68huqyj--RMnSRbyF_R--flex-col-el { width: 100%; --width: 100%; background-color: rgba(0, 0, 0, 0); background-image: linear-gradient(45deg, rgb(119, 171, 255) 0px, rgb(201, 119, 255) 100%); border-top-left-radius: 7px; --border-top-left-radius: 7px; border-top-right-radius: 7px; --border-top-right-radius: 7px; }',
        '.--l68huqyj--bZ6iBKMR_N--flex { max-width: 1200px; --width: 1200px; flex-direction: column; }',
        '.--l68huqyj--RMnSRbyF_R--flex-col-padding { padding-bottom: 0px; }',
        '.--l68huqyj--bZ6iBKMR_N--flex.flex-wrapper { padding-bottom: 0px; padding-top: 0px; }',
        '.--l68huqyj--17qzC_N4ws--container { justify-content: center; }',
        '.--l68huqyj--17qzC_N4ws--wrapper { padding: 3px 20px 0px; }',
        '.el-image.--l68huqyj--17qzC_N4ws--image { width: 950px; --width: 950px; }',
        '.--l68huqyj--17qzC_N4ws--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690859926/users/user-572/17425849526dad30992e0c89c4986709.png"); --bg-id: 167780; }',
        '.--68huqyj--EcHaCiI5K--flex { max-width: 80vw; --width: 80vw; }',
        '.--68huqyj--EcHaCiI5K--flex-col-el { width: 725px; --width: 725px; }',
        '.--l68huqyj--FOhNzC6fsJ--form { gap: 0px; align-items: end; flex-direction: row; padding: 20px; justify-content: space-around; }',
        '.--l68huqyj--5k4CSdW44L--1 { margin-bottom: 0px; height: 51px; width: 259px; --width: 259px; border-bottom-left-radius: 4px; border-top-left-radius: 4px; font-family: Nunito; font-weight: 700; background-color: rgba(0, 0, 0, 0); font-size: 15px; background-image: linear-gradient(45deg, rgb(91, 9, 153) 38%, rgb(178, 56, 212) 100%); letter-spacing: 1px; border-bottom-width: 1px; border-color: rgb(153, 75, 167); }',
        '.--l68huqyj--FOhNzC6fsJ--form .pU9T6Auqb { width: 100%; }',
        '.--l68huqyj--FOhNzC6fsJ--form .faQ0DoIri4 { width: 100%; }',
        '.--l68huqyj--FOhNzC6fsJ--form .el-NU9XhUwwl { width: 900px; --width: 900px; }',
        '.--l68huqyj--FOhNzC6fsJ--form .input.form-control { height: 51px; border-bottom-right-radius: 4px; border-top-right-radius: 4px; border-width: 0px 0px 1px; font-family: Nunito; background-color: rgba(1, 1, 1, 0.24); color: rgb(162, 173, 194); font-weight: 300; border-color: rgb(52, 63, 73); }',
        '.--l68huqyj--FOhNzC6fsJ--form legend { font-family: Nunito; }',
        '.--l68huqyj--FOhNzC6fsJ--form .secondary-text { font-family: Nunito; }',
        '.--l68huqyj--LACKGLsBR_-text { padding: 20px; }',
        '.--l68huqyj--LACKGLsBR_-text div { color: rgb(0, 0, 0); font-family: Nunito; font-weight: 700; line-height: 1.1; --line-height: 1.1; letter-spacing: -1px; }',
        '.--l68huqyj--rGi8mA3DpW--flex-col-padding { padding-bottom: 0px; padding-top: 50px; }',
        '.--68huqyj--EcHaCiI5K--flex-col-padding { padding-top: 0px; padding-bottom: 70px; }'
      ],
      [
        '.--l68huqyj--rGi8mA3DpW--flex { max-width: 100%; --width: 100%; }',
        '.--l68huqyj--bZ6iBKMR_N--flex { max-width: 100%; --width: 100%; flex-wrap: nowrap; }',
        '.--68huqyj--EcHaCiI5K--flex { max-width: 100%; --width: 100%; }',
        '.--l68huqyj--rGi8mA3DpW--flex-col-el { width: 675px; --width: 675px; }',
        '.--68huqyj--EcHaCiI5K--flex-col-padding { padding-bottom: 40px; }',
        '.--l68huqyj--rGi8mA3DpW--flex-col-padding { padding-top: 30px; }'
      ],
      [
        '.--l68huqyj--rGi8mA3DpW--flex { max-width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--l68huqyj--bZ6iBKMR_N--flex { max-width: 100%; --width: 100%; flex-flow: column wrap; }',
        '.--68huqyj--EcHaCiI5K--flex { max-width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--l68huqyj--rGi8mA3DpW--flex-col-el { width: 100%; --width: 100%; }',
        '.--l68huqyj--p4HhMsmY_O--container { max-width: 100vw; padding-bottom: 20px; }',
        '.--l68huqyj--rGi8mA3DpW--flex-col > div { display: contents; --display: contents; }',
        '.--l68huqyj--LACKGLsBR_-text div { font-size: 25px; }',
        '.--l68huqyj--rGi8mA3DpW--flex-col-padding { padding-top: 0px; padding-right: 10px; padding-left: 10px; }',
        '.--68huqyj--EcHaCiI5K--flex-col-el { width: 100%; --width: 100%; }',
        '.--68huqyj--EcHaCiI5K--flex-col > div { display: contents; --display: contents; }',
        '.--l68huqyj--FOhNzC6fsJ--form { flex-flow: wrap; align-items: center; justify-content: center; row-gap: 9px; }',
        '.--68huqyj--EcHaCiI5K--flex-col-padding { padding: 10px 0px; }',
        '.--l68huqyj--RMnSRbyF_R--flex-col-el { border-bottom-left-radius: 7px; --border-bottom-left-radius: 7px; border-bottom-right-radius: 7px; --border-bottom-right-radius: 7px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
