<template>
  <div class="sid-select">
    <b-form-group class="mb-2">
      <div 
        class="flex justify-between items-center select-wrapper"
        :class="{'gap-[20px]': small}"
      >
        <label 
          class="select-label pb-0"
          :class="{'w-full': small}"
        >
          {{ label }}
        </label>
        <v-select
          v-model="backgroundType"
          :options="[{value: null, label: 'Fill', icon: 'bucket'}, {value: 'gradient', label: 'Gradient', icon: `<svg width=&quot;20&quot; height=&quot;15&quot; viewBox=&quot;0 0 20 15&quot; fill=&quot;none&quot; xmlns=&quot;http://www.w3.org/2000/svg&quot;><rect x=&quot;1.03394&quot; y=&quot;0.5&quot; width=&quot;17.9612&quot; height=&quot;14&quot; fill=&quot;url(#paint0_linear_37:1340)&quot; stroke=&quot;black&quot;/><defs><linearGradient id=&quot;paint0_linear_37:1340&quot; x1=&quot;15.7845&quot; y1=&quot;1.46771&quot; x2=&quot;0.533936&quot; y2=&quot;1.46771&quot; gradientUnits=&quot;userSpaceOnUse&quot;><stop stop-color=&quot;white&quot;/><stop offset=&quot;1&quot;/></linearGradient></defs></svg>`}]"
          :reduce="item => item.value"
          :searchable="false"
          class="position-relative custom-select"
          :class="{'!w-[138px]': !small}"
          placeholder="Fill"
          @option:selected="selected"
        >
          <template v-slot:selected-option="option">
            <div class="text-[14px]">
              {{ option.label }}
            </div>
          </template>

          <template v-slot:option="option">
            <div class="text-[14px]">
              {{ option.label }}
            </div>
          </template>
        </v-select>
      </div>
    </b-form-group>

    <b-form-group>
      <div
        v-show="!backgroundType"
        class="flex w-full gap-[10px] justify-end"
      >
        <span
          class="mt-2 block text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
          @click="resetColor"
        >
          Reset Color
        </span>

        <sid-color
          ref="color"
          v-bind="$attrs"
          :default-color="defaultColor"
          :legacy-sid="legacySid"
          rule-name="background-color"
          @change="removeGradient"
        />
      </div>

      <div class="mb-4 text-right">
        <span
          v-if="backgroundType"
          class="text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
          @click="resetGradient"
        >
          Reset Gradient
        </span>
      </div>

      <sid-gradient
        v-show="backgroundType"
        ref="gradient"
        v-bind="$attrs"
        :legacy-sid="legacySid"
        rule-name="background-image"
      />


      <div v-show="backgroundType">
        <b-form-group label="Background Size">
          <sid-select
            v-bind="$attrs"
            rule-name="background-size"
            :options="[{label: '100%', value: '100%'}, {label: '200%', value: '200%'}, {label: '300%', value: '300%'}, {label: '400%', value: '400%'}]"
          />
        </b-form-group>

        <b-form-group
          label="Background Position"
        >
          <sid-select
            v-bind="$attrs"
            rule-name="background-position"
            :options="[{label: '100%', value: '100%'}, {label: '0', value: '0'}]"
            var="--background-position"
          />
        </b-form-group>

        <div class="flex justify-between items-center">
          <sid-checkbox
            :sid="$attrs.sid"
            rule-name="animation"
            value="15s ease 0s infinite normal none running gradient"
            :unchecked-value="''"
          >
            Animate
          </sid-checkbox>

          <i
            v-b-tooltip
            class="fa fa-info-circle cursor-pointer hover:opacity-80 mb-4"
            title="To animate, the background size must be more than 100%"
          />
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import SidGradient
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/gradient/SidGradient'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox'
export default {
  name: 'SidBackground',
  components: {SidCheckbox, SidSelect, SidColor, SidGradient},
  props: {
    label: {
      type: String,
      default: 'Background'
    },
    defaultColor: {
      type: String,
      default: ''
    },
    legacySid: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localColor: null,
      backgroundType: null
    }
  },
  computed: {
    sid () {
      return this.$attrs.sid
    }
  },
  watch: {
    sid () {
      this.setType()
    }
  },
  mounted () {
    this.backgroundType = null
    this.setType()
  },
  methods: {
    setType () {
      const $el = document.querySelector(this.$attrs.sid)

      if ($el) {
        this.backgroundType = this.$refs['gradient'].getSidStyle().includes('linear-gradient') ? 'Gradient' : null
      }
    },
    selected (val) {
      this.$emit('updated', val)

      if (!val.value) {
        this.$refs['color'].setValue(this.localColor)
        this.removeGradient()
      } else {
        this.resetColor()
        this.$refs['gradient'].onChange()
      }
    },
    resetColor () {
      this.localColor = `${this.defaultColor || this.$refs.color.val}`
      this.$refs.color.reset()
    },
    resetGradient () {
      this.$refs['gradient'].reset()
    },
    removeGradient () {
      const $el = document.querySelector(this.$attrs.sid)

      if ($el) {
        this.$refs['gradient'].removeGradient()
      }
    }
  }
}
</script>
