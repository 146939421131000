<template>
  <div class="d-flex align-items-center dashboard-header-controls">
    <dashboard-breadcrumb
      :class="{'opacity-0': search}"
      :breadcrumbs="breadcrumbs"
    />

    <div class="ml-auto" />

    <dashboard-folder-options-menu
      v-if="folder && !search"
      :folder="folder"
    />
    <dashboard-search />
    <dashboard-view-control />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import DashboardBreadcrumb from './DashboardBreadcrumb'
import DashboardSearch from './DashboardSearch'
import DashboardViewControl from './DashboardViewControl'
import DashboardFolderOptionsMenu from './DashboardFolderOptionsMenu'
export default {
  name: 'DashboardHeaderControls',
  components: {DashboardFolderOptionsMenu, DashboardViewControl, DashboardSearch, DashboardBreadcrumb},
  computed: {
    ...mapGetters('dashboard', {
      breadcrumbs: 'breadcrumbs'
    }),
    ...mapState('dashboard', {
      folder: state => state.folder,
      search: state => state.search
    })
  }
}
</script>

<style lang="scss">
.dashboard-header-controls {
  padding-top: 60px;
  padding-bottom: 60px;
}
</style>