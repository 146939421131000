<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    style="min-height: 100px;"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('grid-collapse-mod-v3')"
  >
    <div :data-sid="section.sid">
      <div :class="`--${section.sid}--wrapper`">
        <div
          class="flex items-end"
          :class="`--${section.sid}--flex`"
        >
          <div
            v-for="(tab, index) in section.options.content.gridCollapse.items"
            :key="`${index}-${tab.title}`"
            class="transition es-tab cursor-pointer"
            :class="[`--${section.sid}--tab`, {'active': section.options.content.gridCollapse.opened === index}]"
            @click="() => section.options.content.gridCollapse.opened = index"
          >
            <el-icon
              v-if="tab.tabType === 'icon' && tab.subject"
              :section="tab.subject"
              hide-bar
              hide-add
            />
            <el-image-v3
              v-else-if="tab.tabType === 'image' && tab.subject"
              :section="tab.subject"
              hide-bar
              hide-add
            />
            <editable
              v-else
              v-model="tab.title"
              class="!cursor-text"
            />
          </div>
        </div>

        <div
          class="h-min-[300px]"
          :class="`--${section.sid}--content`"
        >
          <transition name="fade">
            <div v-if="!reload">
              <div
                v-for="(tabContent, index) in section.options.content.gridCollapse.items"
                v-show="index === section.options.content.gridCollapse.opened"
                :key="index"
              >
                <elements
                  v-model="tabContent.elements"
                  drag-height="300px"
                  @flow="handleFlow"
                />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import Elements from '@/components/builder/utils/Elements/Elements'
import Editable from '@/components/builder/utils/Editable'

export default {
  name: 'FeaturedItems1',

  components: {Editable, Elements, Highliter },

  mixins: [SectionMixin],

  data () {
    return {
      reload: false,
      tabs: [
        {
          title: 'Tab 1'
        },
        {
          title: 'Tab 2'
        }
      ]
    }
  },
  computed: {
    openedIndex () {
      return this.section.options.content.gridCollapse.opened
    }
  },
  watch: {
    openedIndex () {
      this.reload = true
      setTimeout(() => this.reload = false)
    }
  }
}
</script>