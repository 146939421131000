<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :style="{...fullMarginSubject(section.options.content.description)}"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('description')"
    @highlight-click="contentClick"
  >
    <template slot="section-type-icon">
      <svg
        fill="none"
        height="16"
        viewBox="0 0 18 16"
        width="18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.6042 0.9H-0.604154C-0.822766 0.9 -1 0.586585 -1 0.2C-1 -0.186585 -0.822766 -0.5 -0.604154 -0.5H17.6042C17.8228 -0.5 18 -0.186585 18 0.2C18 0.586585 17.8228 0.9 17.6042 0.9Z"
          fill="#6E747A"
        />
        <path
          d="M17.6041 3.5H-0.604155C-0.822766 3.5 -1 3.27613 -1 3C-1 2.72387 -0.822766 2.5 -0.604155 2.5H17.6042C17.8228 2.5 18 2.72387 18 3C18 3.27613 17.8227 3.5 17.6041 3.5Z"
          fill="#6E747A"
        />
        <path
          d="M17.6041 6.5H-0.604155C-0.822766 6.5 -1 6.27613 -1 6C-1 5.72387 -0.822766 5.5 -0.604155 5.5H17.6042C17.8228 5.5 18 5.72387 18 6C18 6.27613 17.8227 6.5 17.6041 6.5Z"
          fill="#6E747A"
        />
        <path
          d="M17.6041 12.5H-0.604155C-0.822766 12.5 -1 12.2761 -1 12C-1 11.7239 -0.822766 11.5 -0.604155 11.5H17.6042C17.8228 11.5 18 11.7239 18 12C18 12.2761 17.8227 12.5 17.6041 12.5Z"
          fill="#6E747A"
        />
        <path
          d="M17.6042 0.5H-0.604154C-0.822766 0.5 -1 0.276132 -1 0C-1 -0.276132 -0.822766 -0.5 -0.604154 -0.5H17.6042C17.8228 -0.5 18 -0.276132 18 0C18 0.276132 17.8228 0.5 17.6042 0.5Z"
          fill="#6E747A"
        />
        <path
          d="M17.6041 3.5H-0.604155C-0.822766 3.5 -1 3.27613 -1 3C-1 2.72387 -0.822766 2.5 -0.604155 2.5H17.6042C17.8228 2.5 18 2.72387 18 3C18 3.27613 17.8227 3.5 17.6041 3.5Z"
          fill="#6E747A"
        />
        <path
          d="M17.6041 9.5H-0.604155C-0.822766 9.5 -1 9.27613 -1 9C-1 8.72387 -0.822766 8.5 -0.604155 8.5H17.6042C17.8228 8.5 18 8.72387 18 9C18 9.27613 17.8227 9.5 17.6041 9.5Z"
          fill="#6E747A"
        />
        <path
          d="M11.9771 15.5H-0.606742C-0.823924 15.5 -1 15.2761 -1 15C-1 14.7239 -0.823924 14.5 -0.606742 14.5H11.9771C12.1943 14.5 12.3704 14.7239 12.3704 15C12.3704 15.2761 12.1943 15.5 11.9771 15.5Z"
          fill="#6E747A"
        />
      </svg>
    </template>

    <Template :section="section">
      <content-editable
        ref="content"
        v-model="section.options.content.description.text"
        :data-uid="section.uid"
        :customs="section.options.content.description"
        type="paragraph"
      />
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditable from '@builder/components/builder/utils/ContentEditable'
import Template from './Template'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'FeaturedItems1',

  components: {Template, ContentEditable, Highliter},

  mixins: [SectionMixin, BorderMixin],

  created () {
    if (typeof this.section.options.customize === 'object') {
      this.$set(this.section.options, 'customize', null)
    }
  },

  methods: {
    contentClick () {
      this.$refs.content.initer()
    }
  }
}
</script>