<template>
  <div class="sid-select">
    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0">Name</label>

        <div class="w-[118px]">
          <b-input v-model="control.field_name" />
        </div>
      </div>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0">Type</label>

        <div class="w-[118px]">
          <v-select
            v-model="control.field_type"
            :options="typeOptions"
            :reduce="option => option.value"
            :searchable="false"
            class="custom-select"
          />
        </div>
      </div>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0">Label</label>

        <div class="w-[118px]">
          <b-input v-model="control.label" />
        </div>
      </div>
    </b-form-group>

    <b-form-group v-if="isSupportedPlaceholder">
      <div class="flex items-center justify-between">
        <label class="pb-0">Placeholder</label>

        <div class="w-[118px]">
          <b-input v-model="control.placeholder" />
        </div>
      </div>
    </b-form-group>

    <b-form-group
      v-else
      label="Text"
    >
      <b-textarea v-model="control.placeholder" />
    </b-form-group>

    <b-row class="align-items-center">
      <b-col>
        <label for="required-mark">Required</label>
      </b-col>
      <b-col class="text-right">
        <b-checkbox
          id="required-mark"
          v-model="isRequired"
          class="mb-2"
          switch
          :value="true"
          :unchecked-value="false"
        />
      </b-col>
    </b-row>

    <sid-range-slider
      label="Width"
      :sid="`.--${config.sid}--form .${control.styles}`"
      :dimensions="['%', 'px']"
      default-dimension="%"
      rule-name="width"
      var="--width"
      measurement="width"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import ResolutionMixin from '../../../mixins/ResolutionMixin'
import {toJSON} from '../../../../utils/helpers'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'

export default {
  name: 'FormControlFieldSettings',
  components: {SidRangeSlider},
  mixins: [ResolutionMixin],
  props: {
    control: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      type: 'text',
      columns: 12,
      typeOptions: [
        {
          label: 'Text',
          value: 'text'
        },
        {
          label: 'Text Area',
          value: 'textarea'
        },
        {
          label: 'Email',
          value: 'email'
        },
        {
          label: 'Number',
          value: 'number'
        },
        {
          label: 'Phone Number',
          value: 'tel'
        },
        {
          label: 'Checkbox',
          value: 'checkbox'
        }
      ]
    }
  },
  computed: {
    isRequired: {
      get () {
        return !!this.control.is_required
      },
      set (val) {
        this.control.is_required = val
      }
    },
    isSupportedPlaceholder () {
      return !['checkbox'].includes(this.control.field_type)
    },
    columnsModel: {
      get () {
        return _.get(this.control.columns, this.resolutioner, this.control.columns)
      },
      set (val) {
        const column = Object.keys(toJSON(this.control.columns)).length
          ? this.control.columns
          : {
            lg: this.control.columns,
            md: this.control.columns,
            sm: this.control.columns
          }

        this.$set(this.control, 'columns', {
          ...column,
          [this.resolutioner]: val
        })
      }
    }
  },
  created () {
    if (!this.control.validators) {
      this.$set(this.control, 'validators', {})
    }

    if (typeof this.control.validators === 'string') {
      this.$set(this.control, 'validators', JSON.parse(this.control.validators))
    }
  }
}
</script>
