<template>
  <div class="custom-menu">
    <div class="d-flex align-items-center mb-4">
      <span class="indicator mr-2" />
      <p class="text-capitalize mb-0">
        {{ labelCase(componentName) }}
      </p>
    </div>

    <image-upload
      :image="config"
      class="w-100 mb-3"
    />

    <b-form-radio-group
      v-model="position"
      class="resolution-style-tabs"
      buttons-variant="light"
      value-field="key"
      text-field="text"
      size="sm"
      buttons
      :options="[{text: 'TOP', key: 'top'}, {text: 'LEFT', key: 'left'}]"
    />

    <b-row
      no-gutters
      class="align-items-center"
    >
      <b-col
        cols="9"
        class="mr-2"
      >
        <vue-slider
          v-model="config[position]"
          class="editor-slider-1"
          :dot-size="17"
          tooltip="none"
          :min="-100"
          :max="200"
        />
      </b-col>
      <b-col>
        <b-input
          v-model="config[position]"
          min="0"
          type="number"
          step="1"
          size="sm"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ImageUpload from '../utils/ImageUpload'

export default {
  name: 'ImageMod',
  components: {ImageUpload},
  mixins: [ModMixin],

  data () {
    return {
      position: 'top'
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-es-image-upload {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #E2E5EC;
    font-size: 14px;
    font-weight: 600;
    color: #6E747A;
    height: 38px;
    box-shadow: 0 1px 2px 0 rgba(226,226,226,0.5)
  }
</style>
