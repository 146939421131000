const state = () => ({
})
  
const getters = {}
  
const actions = {
  async create (_, data) {
    return await axios.post(`api/projects/${data.project_id}/auto-responders`, data)
  },
  async update (_, data) {
    return await axios.put(`api/projects/${data.project_id}/auto-responders/${data.auto_responder_id}`, data)
  },
  async delete (_, data) {
    return await axios.delete(`api/projects/${data.project_id}/auto-responders/${data.auto_responder_id}`)
  }
}
  
const mutations = {}
  
export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
  