<template>
  <div :class="[section.options.content.image.customClass, {'element-hidden': !visability('image')}]">
    <highliter
      v-model="section.name"
      :disable-toolbar="section.options.content.image.disableToolbar"
      :dublicate="section"
      :dublicate-deep="parentUID"
      class="highlight-element"
      section-type="element"
      @delete="$emit('delete')"
      @dublicate="$emit('dublicate')"
      @favorite="$emit('favorite', $el)"
      @flow="handleFlow"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
      @click.stop.native="sectionSettings('image')"
    >
      <Template :section="section">
        <preview-el-image-v2
          v-if="section.options.content.image.subject"
          :section="section.options.content.image.subject"
        />
      </Template>
    </highliter>
  </div>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'

export default {
  name: 'FeaturedItems1',

  components: {Template, Highliter},

  mixins: [SectionMixin],

  computed: {
    ratio () {
      if (!this.section.options.content.image.image.hasOwnProperty('ratio')) {
        this.$set(this.section.options.content.image.image, 'ratio', {
          type: 'vh',
          sm: {
            h: 60,
            w: 50
          },
          lg: {
            h: 60,
            w: 50
          }
        })
      }

      return {
        height: `${this.cols(this.section.options.content.image.image.ratio.lg.h, this.section.options.content.image.image.ratio.sm.h)}${this.section.options.content.image.image.ratio.type}`,
        width: `${this.cols(this.section.options.content.image.image.ratio.lg.w, this.section.options.content.image.image.ratio.sm.w)}${this.section.options.content.image.image.ratio.type}`
      }
    }
  }
}
</script>
