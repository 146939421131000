<template>
  <div :class="[section.options.content.image.customClass, {'element-hidden': !visability('image')}]">
    <highliter
      v-model="section.name"
      :disable-toolbar="section.options.content.image.disableToolbar"
      :dublicate="section"
      :dublicate-deep="parentUID"
      class="highlight-element"
      section-type="element"
      @delete="$emit('delete')"
      @dublicate="$emit('dublicate')"
      @favorite="$emit('favorite', $el)"
      @flow="handleFlow"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
      @click.stop.native="sectionSettings('image')"
    >
      <Template :section="section">
        <div
          class="position-relative overflow-hidden cursor-pinter rounded"
          :class="{'video-popup': overlayEnabled}"
          @mouseenter="showOverlay"
          @mouseleave="overlay = false"
        >
          <preview-el-image-v2
            v-if="section.options.content.image.subject"
            :section="section.options.content.image.subject"
          >
            <transition name="fade-fast">
              <div
                v-if="overlay && overlayEnabled"
                :style="{backgroundColor: section.options.content.image.video.overlay.color}"
                class="video-popup-overlay d-flex align-items-center justify-content-center rouneded"
              >
                <div
                  class="play-btn"
                  style="background-color: rgb(255, 255, 255);"
                >
                  <i
                    aria-hidden="true"
                    class="fa fa-play"
                    style="color: rgb(0, 0, 0);"
                  />
                </div>
              </div>
            </transition>
          </preview-el-image-v2>
        </div>
      </Template>
    </highliter>
  </div>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import _ from 'lodash'

export default {
  name: 'FeaturedItems1',

  components: {Template, Highliter},

  mixins: [SectionMixin],

  data () {
    return {
      overlay: false
    }
  },

  computed: {
    overlayEnabled () {
      return _.get(this.section.options.content.image.video.overlay, ['enable', this.resolutioner], _.get(this.section.options.content.image.video.overlay, ['enable', 'lg'], true))
    },
    ratio () {
      if (!this.section.options.content.image.image.hasOwnProperty('ratio')) {
        this.$set(this.section.options.content.image.image, 'ratio', {
          type: 'vh',
          sm: {
            h: 60,
            w: 50
          },
          lg: {
            h: 60,
            w: 50
          }
        })
      }

      return {
        height: `${this.cols(this.section.options.content.image.image.ratio.lg.h, this.section.options.content.image.image.ratio.sm.h)}${this.section.options.content.image.image.ratio.type}`,
        width: `${this.cols(this.section.options.content.image.image.ratio.lg.w, this.section.options.content.image.image.ratio.sm.w)}${this.section.options.content.image.image.ratio.type}`
      }
    }
  },

  methods: {
    showOverlay () {
      this.overlay = true

      this.overlaySizing = {
        width: '100%',
        height: '100%'
      }

      if (this.cols(this.section.options.content.image.image.size, 'contain') !== 'contain') return

      function getRenderedSize (contains, cWidth, cHeight, width, height) {
        var oRatio = width / height,
          cRatio = cWidth / cHeight
        return function () {
          if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
            this.width = `${cWidth}px`
            this.height = `${cWidth / oRatio}px`
          } else {
            this.width = `${cHeight * oRatio}px`
            this.height = `${cHeight}px`
          }
          return this
        }.call({})
      }

      const position = {
        center: {
          left: '50%',
          transform: 'translateX(-50%)'
        },
        top: {
          left: '50%',
          transform: 'translateX(-50%)'
        },
        bottom: {
          left: '50%',
          transform: 'translateX(-50%)'
        },
        right: {
          right: 0
        }
      }

      const img = this.$refs.image.$el.querySelector('img')
      this.overlaySizing = {
        ...getRenderedSize(true, img.width, img.height, img.naturalWidth, img.naturalHeight),
        ...position[this.section.options.content.image.image.position] || {}
      }
    }
  }
}
</script>

<style lang='scss'>
.video-popup {
  img {
    object-fit: cover !important;
    width: 100% !important;
    height: 100% !important;
  }
}
</style>