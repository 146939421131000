<template>
  <div>
    <div class="mb-3">
      <label class="block">Margin</label>
      <b-form-radio-group
        v-model="position"
        :options="[{text: 'TOP', key: 'top'}, {text: 'BOTTOM', key: 'bottom'}, {text: 'LEFT / RIGHT', key: 'x'}]"
        buttons
        buttons-variant="light"
        class="resolution-style-tabs"
        size="sm"
        text-field="text"
        value-field="key"
      />
    </div>

    <b-row
      class="align-items-center"
      no-gutters
    >
      <b-col
        class="pr-2 !pl-[8px]"
        cols="9"
      >
        <vue-slider
          v-model="padding"
          :dot-size="17"
          :interval="1"
          :max="200"
          :min="0"
          class="editor-slider-1"
          tooltip="none"
        />
      </b-col>
      <b-col>
        <b-input
          v-model="padding"
          max="100"
          min="0"
          size="sm"
          step="1"
          type="number"
        />
      </b-col>
    </b-row>

    <div class="mt-2 mb-2">
      <b-checkbox v-model="enableSync">
        Sync margin settings
      </b-checkbox>
      <Panel
        v-if="enableSync"
        :config="display"
        :hide-all="false"
        :hide-tablet="false"
        class="bg-white mt-1"
      />
    </div>

    <div class="mb-0">
      <span
        class="relative text-[#333333] text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
        @click="removePadding"
      >
        Remove margin for all devices
        <span class="absolute w-full h-[1px] bg-[#333333] left-0 bottom-0 translate-y-[-1px]" />
      </span>
    </div>
  </div>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'
import Panel from '@/components/editor/controls/Panel'
import SyncSettingsMixin from '@/components/mixins/SyncSettingsMixin'

export default {
  name: 'Padding',
  components: {Panel},
  mixins: [ControlMixin, SyncSettingsMixin],

  data () {
    return {
      position: 'top'
    }
  },

  computed: {
    padding: {
      get () {
        if (!this.section.options.customize.style.marginTop) {
          this.$set(this.section.options.customize.style, 'marginTop', {
            lg: 0,
            sm: 0,
            type: 'margin-top-customs'
          })
        }

        return this.config.resolutionStyle[this.display.display !== true ? this.getDisplays[0] : this.resolution].padding[this.position]
      },
      set (val) {
        this.getDisplays.forEach(display => {
          this.config.resolutionStyle[display].padding[this.position] = val
        })
      }
    }
  },

  methods: {
    removePadding () {
      this.$set(this.config.resolutionStyle.lg, 'padding', {
        top: 0,
        bottom: 0,
        x: 0
      })
      this.$set(this.config.resolutionStyle.sm, 'padding', {
        top: 0,
        bottom: 0,
        x: 0
      })
    }
  }
}
</script>
