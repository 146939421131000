import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Product Description'
export const group = ''

export default class ElProductDescription extends SectionProvider {
  constructor (options = {}) {
    super('ElProductDescription', options)

    this.name = name
    this.group = group
  }
}