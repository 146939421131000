<template>
  <div class="w-[160px] normal-checkbox-label">
    <div class="flex items-center gap-[10px] mb-[20px]">
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.1002 11.7937L5.32156 12.4578L5.32156 12.4578L5.1002 11.7937ZM8.23012 10.7504L8.00876 10.0864L8.00876 10.0864L8.23012 10.7504ZM11.7202 3.35785L12.2152 3.85283L11.7202 3.35785ZM8.8649 6.21319L9.35988 6.70816L8.8649 6.21319ZM4.14919 6.21415L3.65422 6.70913L4.14919 6.21415ZM2 1.7H11.0131V0.3H2V1.7ZM1.7 2.65075V2H0.3V2.65075H1.7ZM4.64417 5.71918L1.78787 2.86288L0.797918 3.85283L3.65422 6.70913L4.64417 5.71918ZM3.74208 6.92126V11.3194H5.14208V6.92126H3.74208ZM3.74208 11.3194C3.74208 12.1385 4.54452 12.7168 5.32156 12.4578L4.87884 11.1297C5.00834 11.0865 5.14208 11.1829 5.14208 11.3194H3.74208ZM5.32156 12.4578L8.45148 11.4145L8.00876 10.0864L4.87884 11.1297L5.32156 12.4578ZM8.45148 11.4145C8.94149 11.2512 9.27201 10.7926 9.27201 10.2761H7.87201C7.87201 10.19 7.92709 10.1136 8.00876 10.0864L8.45148 11.4145ZM9.27201 10.2761V6.9203H7.87201V10.2761H9.27201ZM11.2253 2.86288L8.36993 5.71821L9.35988 6.70816L12.2152 3.85283L11.2253 2.86288ZM11.3131 2V2.65075H12.7131V2H11.3131ZM12.2152 3.85283C12.534 3.53402 12.7131 3.10162 12.7131 2.65075H11.3131C11.3131 2.73031 11.2815 2.80662 11.2253 2.86288L12.2152 3.85283ZM9.27201 6.9203C9.27201 6.84073 9.30362 6.76442 9.35988 6.70816L8.36993 5.71821C8.05111 6.03703 7.87201 6.46943 7.87201 6.9203H9.27201ZM3.65422 6.70913C3.71048 6.76539 3.74208 6.84169 3.74208 6.92126H5.14208C5.14208 6.47039 4.96298 6.03799 4.64417 5.71918L3.65422 6.70913ZM0.3 2.65075C0.3 3.10162 0.479107 3.53402 0.797918 3.85283L1.78787 2.86288C1.73161 2.80662 1.7 2.73031 1.7 2.65075H0.3ZM11.0131 1.7C11.1788 1.7 11.3131 1.83431 11.3131 2H12.7131C12.7131 1.06112 11.952 0.3 11.0131 0.3V1.7ZM2 0.3C1.06112 0.3 0.3 1.06112 0.3 2H1.7C1.7 1.83431 1.83431 1.7 2 1.7V0.3Z"
          fill="black"
        />
      </svg>

      Filter
    </div>

    <div class="w-[153px] h-[1px] bg-[#E8EDF1] mb-[21px]" />

    <h5 class="text-[15px] text-[#202020] font-[600] !mb-[17px]">
      Categories
    </h5>

    <b-form-checkbox-group
      v-model="filteredCategories"
      :options="categories"
      value-field="id"
      text-field="name"
      class="mb-[16px]"
    />

    <div class="w-[153px] h-[1px] bg-[#E8EDF1] mt-[26px] mb-[21px]" />

    <h5 class="text-[15px] text-[#202020] font-[600] !mb-[17px]">
      Themes
    </h5>

    <b-form-checkbox-group
      v-model="filteredThemes"
      :options="themes"
      value-field="id"
      text-field="name"
      class="mb-[16px]"
    />

    <div class="w-[153px] h-[1px] bg-[#E8EDF1] mt-[26px] mb-[21px]" />

    <b-form-checkbox-group
      v-model="filteredIsFree"
      :options="types"
      value-field="id"
      text-field="name"
      class="mb-[16px]"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TemplateFilters',
  data () {
    return {
      active: '',
      categories: [],
      themes: [],
      types: [
        {
          name: 'Free',
          id: 1
        },
        {
          name: 'Premium',
          id: 0
        }
      ],
      model: []
    }
  },
  computed: {
    ...mapState('templates', {
      filteredCategories: state => state.categories,
      filteredThemes: state => state.themes,
      filteredIsFree: state => state.isFree
    }),
    filteredCategories: {
      get () {
        return this.$store.state.templates.categories
      },
      set (val) {
        this.$store.state.templates.categories = val
      }
    },
    filteredThemes: {
      get () {
        return this.$store.state.templates.themes
      },
      set (val) {
        this.$store.state.templates.themes = val
      }
    },
    filteredIsFree: {
      get () {
        return this.$store.state.templates.isFree
      },
      set (val) {
        this.$store.state.templates.isFree = val
      }
    }
  },
  watch: {
    filteredCategories (val) {
      this.$store.dispatch('templates/getTemplates', val)
    },
    filteredThemes (val) {
      this.$store.dispatch('templates/getTemplates', val)
    },
    filteredIsFree (val) {
      this.$store.dispatch('templates/getTemplates', val)
    }
  },
  created () {
    axios.get('api/templates/categories')
      .then(({data}) => {
        this.categories = data.data
      })

    axios.get('api/templates/themes')
      .then(({data}) => {
        this.themes = data.data
      })
  },
  methods: {
    select (category) {
      this.active = category
      this.$emit('selected', category)
    }
  }
}
</script>

<style lang="scss">
@import "@builder/assets/scss/_variables.scss";

.filter-title {
  color: #202020;
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
}

.category-item {
  color: #44474A;
  font-size: 15px;
  padding: 10px 15px;
  border: 1px solid #E2E5EC;
  transition: .4s;
  border-radius: 3px;
  margin-right: 20px;

  &:hover {
    cursor: pointer;
    background-color: #eee;
  }

  &.active {
    color: #fff;
    background-color: $primary;
  }
}
</style>
