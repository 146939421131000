import {mergeDeep} from 'bootstrap-vue/esm/utils/object'
import shortid from 'shortid'

export default class SectionProvider {
  constructor (component, options = {}) {
    this.component = component
    this.options = options
    this.defaultOptions = null
    this.name = null
    this.css = null
    this.sids = null
    this.sid = null
    this.hideFromSections = false
    this.group = null
    this.stateSafly = true
    this.ignoreGroup = false
    this.beta = null
    this.disableVisibility = false
  }

  json () {
    return {
      uid: shortid.generate(),
      display: true,
      name: this.name,
      css: this.css,
      sid: this.sid,
      sids: this.sids,
      hideFromSections: this.hideFromSections,
      component: this.component,
      group: this.group,
      ignoreGroup: this.ignoreGroup,
      layout: this.layout || null,
      disableVisibility: this.disableVisibility,
      options: mergeDeep(this.defaultOptions, this.options)
    }
  }
}
