<template>
  <div>
    <b-modal
      v-model="modal"
      dialog-class="!max-w-[800px]"
      content-class="rounded-[10px]"
      body-class="!px-[64px] !py-[48px]"
      hide-header
      hide-footer
      centered
      lazy
    >
      <i 
        class="fa fa-close z-[50] absolute top-[15px] right-[17px] text-[16px] text-[#6E747A] transition cursor-pinter hover:opacity-60"
        @click="modal = false"
      />
  
      <div class="w-full flex flex-col items-center gap-y-[32px] font-[ProximaNova]">
        <div class="max-w-[600px] w-full flex flex-col items-center gap-[16px]">
          <h3 class="text-[32px] leading-[36px] font-[700] text-[#000000] mb-0">
            Modify Plan
          </h3>
  
          <p class="text-[16px] leading-[24px] font-[400] text-[#9D9D9D] mb-0">
            Choose from the options below
          </p>
        </div>
  
        <div class="w-full flex flex-col gap-[16px]">
          <div
            class="w-full inline-flex flex-row items-center justify-start px-[24px] py-[32px] gap-x-[20px] rounded-[10px] border-[1.5px] border-[#C8CACF] bg-[#FFFFFF] transition"
            :class="[isBusinessPlus ? 'cursor-not-allowed opacity-50' : 'cursor-pinter hover:!border-[#4F83E3]']"
            @click="upgradePlan"
          >
            <div>
              <div class="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#E9F0FD] text-[14px] text-[#4F83E3]">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 16.6668H14.6667C11.8664 16.6668 10.4663 16.6668 9.39671 16.1219C8.4559 15.6425 7.691 14.8776 7.21163 13.9368C6.66667 12.8672 6.66667 11.4671 6.66667 8.66683V3.3335M6.66667 3.3335L10.8333 7.50016M6.66667 3.3335L2.5 7.50016"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
  
            <div class="w-full flex flex-col">
              <p class="text-[16px] leading-[20px] font-[400] text-[#9D9D9D] mb-0">
                Upgrade your current plan
              </p>
  
              <p class="text-[20px] leading-[28px] font-[600] text-[#000000] mb-0">
                ${{ parseInt(userSubscription.paid_amount) }}/{{ userSubscription.plan.interval }}
              </p>
            </div>
  
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="text-[24px] font-[600] text-[#6D6D6E]"
            >
              <path
                d="M9 18L15 12L9 6"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <div
            class="w-full inline-flex flex-row items-center justify-start px-[24px] py-[32px] gap-x-[20px] rounded-[10px] border-[1.5px] border-[#C8CACF] bg-[#FFFFFF] transition"
            :class="[isBusinessPlus ? 'cursor-pinter hover:!border-[#4F83E3]' : 'cursor-not-allowed opacity-50']"
            @click="downgradePlan"
          >
            <div>
              <div class="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#FFF8EB] text-[14px] text-[#DBAA48]">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 3.33317H14.6667C11.8664 3.33317 10.4663 3.33317 9.39671 3.87814C8.4559 4.3575 7.691 5.12241 7.21163 6.06322C6.66667 7.13278 6.66667 8.53291 6.66667 11.3332V16.6665M6.66667 16.6665L10.8333 12.4998M6.66667 16.6665L2.5 12.4998"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
  
            <div class="w-full flex flex-col">
              <p class="text-[20px] leading-[28px] font-[600] text-[#000000] mb-0">
                Downgrade
              </p>

              <p class="text-[16px] leading-[20px] font-[400] text-[#9D9D9D] mb-0">
                {{ isBusinessPlus ? 'I wish to downgrade my plan' : 'You are already on the lowest plan' }}
              </p>
            </div>
  
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="text-[16px] text-[#6D6D6E]"
            >
              <path
                d="M9 18L15 12L9 6"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
  
          <div 
            class="w-full inline-flex flex-row items-center justify-start px-[24px] py-[32px] gap-x-[20px] rounded-[10px] border-[1.5px] border-[#C8CACF] bg-[#FFFFFF] transition cursor-pinter hover:!border-[#4F83E3]"
            @click="holdAccount"
          >
            <div>
              <div class="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#E3F7F5] text-[14px] text-[#57C6BF]">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5 15V9M14.5 15V9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
  
            <div class="w-full flex flex-col">
              <p class="text-[20px] leading-[28px] font-[600] text-[#000000] mb-0">
                Put Account on Hold
              </p>
  
              <p class="text-[16px] leading-[20px] font-[400] text-[#9D9D9D] mb-0">
                Put account on hold due to an unforeseen situation
              </p>
            </div>
  
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="text-[16px] text-[#6D6D6E]"
            >
              <path
                d="M9 18L15 12L9 6"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
  
          <div 
            class="w-full inline-flex flex-row items-center justify-start px-[24px] py-[32px] gap-x-[20px] rounded-[10px] border-[1.5px] border-[#C8CACF] bg-[#FFFFFF] transition cursor-pinter hover:!border-[#4F83E3]"
            @click="cancelPlan"
          >
            <div>
              <div class="w-[40px] h-[40px] flex items-center justify-center rounded-full bg-[#FFEAEA] text-[14px] text-[#DB4848]">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 5L5 15M5 5L15 15"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            
            <div class="w-full flex flex-col">
              <p class="text-[20px] leading-[28px] font-[600] text-[#000000] mb-0">
                Cancel Plan
              </p>
            
              <p class="text-[16px] leading-[20px] font-[400] text-[#9D9D9D] mb-0">
                I wish to cancel my subscription
              </p>
            </div>
            
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="text-[16px] text-[#6D6D6E]"
            >
              <path
                d="M9 18L15 12L9 6"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </b-modal>
    
    <purchase-plan-modal
      v-model="upgrade"
      business-plus
    />
    <downgrade-plan-modal v-model="downgrade" />
    <hold-account-modal v-model="hold" />
    <cancel-plan-modal v-model="cancel" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import HoldAccountModal from '@/layouts/dashboard/compontens/account/billing/components/HoldAccountModal.vue'
import PurchasePlanModal from '@/components/editor/modals/purchase-plan/PurchasePlanModal.vue'
import CancelPlanModal from '@/layouts/dashboard/compontens/account/billing/components/CancelPlanModal.vue'
import DowngradePlanModal from '@/layouts/dashboard/compontens/account/billing/components/DowngradePlanModal.vue'
export default {
  name: 'ModifyPlanModal',
  components: {
    DowngradePlanModal,
    CancelPlanModal,
    PurchasePlanModal,
    HoldAccountModal
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      upgrade: false,
      downgrade: false,
      hold: false,
      cancel: false
    }
  },
  computed: {
    ...mapGetters('editor', {
      isBusinessPlus: 'isBusinessPlus'
    }),
    ...mapGetters('auth', {
      userSubscription: 'userSubscription'
    }),
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    upgradePlan () {
      if (!this.isBusinessPlus) {
        this.upgrade = true
        this.modal = false
      }
    },
    downgradePlan () {
      if (this.isBusinessPlus) {
        this.downgrade = true
        this.modal = false
      }
    },
    holdAccount () {
      this.hold = true
      this.modal = false
    },
    cancelPlan () {
      this.cancel = true
      this.modal = false
    }
  }
}
</script>