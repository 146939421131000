import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Alert Message'
export const group = 'alert-bar'
export const defaultOptions = {
  content: {
    promo: {
      type: 'promo-mod',
      overlay: true,
      display: true,
      dockTo: {
        lg: 'top',
        md: 'top',
        sm: 'top'
      },
      closeIcon: {
        enabled: {
          lg: true,
          md: true,
          sm: true
        },
        color: {
          lg: '#fff',
          md: '#fff',
          sm: '#fff'
        }
      },
      subject: {
        'display': true,
        'name': 'Flex Wrapper',
        'hideFromSections': false,
        'component': 'ElFlexWrapperV2',
        mod: 'flex-mod-v2',
        'group': '',
        'layout': null,
        'options': {
          'content': {
            'display': true,
            'cols': [{
              'display': true,
              'name': 'Flex Column',
              'elements': [{
                'uid': 'bQ6xzdeGb',
                'display': true,
                'name': 'Paragraph',
                'sid': 't3x95ha--LIEhRqDK3r',
                'hideFromSections': false,
                'component': 'ElParagraphV2',
                'group': 'Text',
                'layout': null,
                'options': {
                  'content': {
                    'description': {
                      'type': 'tiptap-mod-v2',
                      'display': true,
                      'resolutionStyle': {'sm': {}, 'md': {}, 'lg': {}},
                      'text': '<p>Edit Your Text here</p>',
                      'animations': {'animation': null, 'once': true, 'offset': 200, 'delay': 50}
                    }
                  }, 'customize': null
                },
                'editor': 'project',
                'temp_id': 'VQO1DCUOrH'
              }],
              'sortIndex': {'lg': 0, 'md': 0, 'sm': 0},
              'uid': 'k6b0qLtfi',
              'sid': 't3x95ha--_TT8UU6MNF',
              'highlight': false
            }]
          }
        },
        'uid': 'LFAeRyNB0',
        'sid': 'aqmspkw--tj3WAB5M9p',
        'temp_id': 'DmzlpW9fRx',
        'css': [['.--aqmspkw--tj3WAB5M9p--container { background-color: rgb(61, 130, 234); width: 100%; }', '.--aqmspkw--tj3WAB5M9p--flex { align-items: center; justify-content: center; padding: 20px; }', '.--t3x95ha--_TT8UU6MNF--flex-col-el { width: 100%; }', '.--t3x95ha--_TT8UU6MNF--flex-col-padding { padding: 0px; }', '.--t3x95ha--LIEhRqDK3r-text { padding: 0px; margin-bottom: 1px; }', '.--t3x95ha--_TT8UU6MNF--flex-col > div { align-items: center; }', '.--t3x95ha--LIEhRqDK3r-text div { color: rgb(255, 255, 255); }'], [], []],
        'sids': ['aqmspkw--tj3WAB5M9p', 't3x95ha--_TT8UU6MNF', 't3x95ha--LIEhRqDK3r']
      }
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@builder/assets/svg/previews/others/promo.svg')
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElPromoV2', options)

    this.name = name
    this.group = group
    this.hideFromSections = true
    this.defaultOptions = defaultOptions
  }
}
