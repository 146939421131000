<template>
  <div class="sid-select w-full py-[14px] px-[16px]">
    <sid-range-slider
      rule-name="width"
      :sid="`.el-product-cover.--${element.sid}--image`"
      default-dimension="%"
      :dimensions="['%', 'vw', 'px']"
      label="Image Size"
      var="--width"
      measurement="width"
    />

    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0 w-full">Display Setting</label>

        <sid-select
          :sid="`.--${element.sid}--wrapper`"
          rule-name="display"
          :searchable="false"
          :options="[
            {
              label: 'Block',
              value: ''
            },
            {
              label: 'Inline',
              value: 'inline-flex',
            }
          ]"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0 w-full">Position</label>

        <sid-select
          :sid="`.--${element.sid}--wrapper`"
          rule-name="position"
          :searchable="false"
          :options="[
            {
              label: 'Static',
              value: ''
            },
            {
              label: 'Fixed',
              value: 'fixed',
            },
            {
              label: 'Absolute',
              value: 'absolute',
            }
          ]"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center justify-between">
        <label class="pb-0">Z-Index</label>

        <sid-input
          :sid="`.--${element.sid}--image`"
          rule-name="z-index"
          default-dimension=""
          class="!w-[59px]"
        />
      </div>
    </b-form-group>

    <sid-flip
      :sid="`.--${element.sid}--image`"
      rule-name="scale"
    />

    <sid-align
      :sid="`.--${element.sid}--container`"
      rule-name="justify-content"
      label="Alignment"
    />

    <SidbearSettingsGroup 
      title="Border & Radius"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: '0 -16px'}"
    >
      <rule-tabs>
        <template slot="Normal">
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${element.sid}--image`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${element.sid}--image`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${element.sid}--image`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${element.sid}--image`"
            rule-name="border"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>

        <template slot="Hover">
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${element.sid}--image`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${element.sid}--image`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${element.sid}--image`"
                presudo=":hover"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${element.sid}--image`"
            rule-name="border"
            presudo=":hover"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: '0 -16px'}"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${element.sid}--wrapper`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${element.sid}--wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidFlip from '@/components/editor/components/sid-controls/SidFlip.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'
import SidInput from '@/components/editor/components/sid-controls/SidInput.vue'

export default {
  name: 'ProductCoverSettings',
  components: {
    SidInput,
    SidAlign, SidFlip,
    SidRangeSlider,
    SidSelect, SidColor,
    RuleTabs,
    SidLinkedGroup, 
    SidbearSettingsGroup
  },
  props: {
    element: {
      type: Object,
      required: true
    }
  }
}
</script>