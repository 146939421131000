<template>
  <highliter
    v-model="section.name"
    :uid="section.uid"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings()"
  >
    <ImageItem3Template :section="section">
      <highliter
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @dublicate="$emit('dublicate')"
        @delete="$emit('delete')"
        @click.stop.native="sectionSettings('image')"
      >
        <image-fit
          :customs="section.options.content.image"
          :src="section.options.content.image.src || section.options.content.image.placeholder"
          :size="cols(section.options.content.image.size, 'cover')"
          :position="section.options.content.image.position"
          height="308px"
          width="290px"
        />
      </highliter>
    </ImageItem3Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ImageItem3Template from '@builder/templates/sections/images/ImageItem3Template'
import ImageFit from '../../../utils/ImageFit'

export default {
  name: 'ImageItem3',

  components: {ImageFit, ImageItem3Template, Highliter},

  mixins: [SectionMixin]
}
</script>
