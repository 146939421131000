import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Order Form'
export const group = 'Forms'
export const beta = true

const stepInformation = {
  options: {
    name: 'Step 1: Information'
  },
  content: [
    {
      uid: 'Sp1XpRZu5',
      name: 'Registration',
      sid: 'lbem50zr--u-TYvpTDvT',
      hideMod: true,
      hideRight: true,
      component: 'ElFieldAuth',
      fieldSettings: true,
      options: {
        content: {
          control: [
            {
              label: 'First Name',
              placeholder: 'Enter your first name',
              field_name: 'first_name',
              field_type: 'text',
              values: null,
              validators: '[]',
              is_required: true,
              is_hide: false,
              mapping: []
            },
            {
              label: 'Last Name',
              placeholder: 'Enter your last name',
              field_name: 'last_name',
              field_type: 'text',
              values: null,
              validators: '[]',
              is_required: true,
              is_hide: false,
              mapping: []
            },
            {
              label: 'Email',
              placeholder: 'Enter your email address',
              field_name: 'email',
              field_type: 'email',
              values: null,
              validators: '[]',
              is_required: true,
              is_hide: false,
              mapping: []
            },
            {
              label: 'Password',
              placeholder: 'Enter your password',
              field_name: 'password',
              field_type: 'password',
              values: null,
              validators: '[]',
              is_required: true,
              is_hide: false,
              mapping: []
            },
            {
              label: 'Confirm Password',
              placeholder: 'Confirm your password',
              field_name: 'password_confirmation',
              field_type: 'password',
              values: null,
              validators: '[]',
              is_required: true,
              is_hide: false,
              mapping: []
            },
            {
              text: 'Already have account?',
              link: 'login',
              is_hide: false,
              show: true
            }
          ]
        },
        customize: null
      }
    },
    {
      uid: '3T54SbV6o',
      display: true,
      name: 'Submit Button',
      class: 'form-button',
      sid: 'lbem50zr--8-isuWLxLw',
      hideMod: true,
      hideRight: true,
      hideFromSections: false,
      component: 'ElButtonV2',
      fieldSettings: true,
      group: 'Form',
      ignoreGroup: false,
      layout: null,
      disableVisibility: false,
      options: {
        content: {
          button: {
            pressetID: 0,
            display: true,
            type: 'button-mod-v2',
            text: 'Click Me',
            buttonType: 'submit',
            realTimeVisibility: true,
            icon: {
              lottie: {
                jsonPath: '',
                loop: true,
                autoplay: true
              }
            },
            hoverAnimation: {},
            animations: {
              animation: null,
              once: true,
              offset: 200,
              delay: 50
            }
          }
        },
        customize: null
      },
      temp_id: 'GJ3MzsHvO9',
      editor: 'project'
    }
  ]
}

const stepPayments = {
  options: {
    name: 'Step 2: Payment'
  },
  content: [
    {
      uid: 'xBAe8wbto',
      name: 'Previous Button',
      sid: 'ldfg8s4r--O2eo80MASi',
      component: 'ElPreviewsButton',
      fieldSettings: true,
      options: {
        content: {
          previousButton: {
            text: 'Previous Step',
            icon: null
          }
        },
        customize: null
      }
    },
    {
      uid: 'dqMhhM_sn',
      name: 'Product Variants',
      sid: 'ldfg8s4r--uz75WC1Mfb',
      component: 'ElProductVariant',
      fieldSettings: true,
      options: {
        content: {
          productOptions: {
            plan: {
              text: 'Plan'
            },
            price: {
              text: 'Price'
            }
          }
        },
        customize: null
      }
    },
    {
      uid: 'ISTgYK61Q',
      name: 'Product Cover',
      sid: 'lbem50zr--58_mFcLe1N',
      component: 'ElProductCover',
      fieldSettings: true,
      options: {
        content: {
          productImage: {
            animations: {
              animation: null,
              delay: 50,
              offset: 200,
              once: true
            },
            display: true,
            resolutionStyle: {
              lg: 12,
              md: 12,
              sm: 12
            },
            url: ''
          }
        },
        customize: null
      }
    },
    {
      uid: 'apv1qyMXA',
      name: 'Product Title',
      sid: 'lbem50zr--53o6qSF0DG',
      component: 'ElProductTitle',
      fieldSettings: true,
      options: {
        content: {
          productTitle: {
            animations: {
              animation: null,
              delay: 50,
              offset: 200,
              once: true
            },
            display: true,
            resolutionStyle: {
              lg: 12,
              md: 12,
              sm: 12
            },
            text: 'CyberNuts Course'
          }
        },
        customize: null
      }
    },
    {
      uid: 'EsroQTvYc',
      name: 'Product Description',
      sid: 'lbem50zr--8w1lv-R2A6',
      component: 'ElProductDescription',
      fieldSettings: true,
      options: {
        content: {
          productDescription: {
            animations: {
              animation: null,
              delay: 50,
              offset: 200,
              once: true
            },
            display: true,
            resolutionStyle: {
              lg: 12,
              md: 12,
              sm: 12
            },
            text: 'Sed at neque accumsan, mollis tellus sit amet, egestas justo. Vivamus tellus lacus, blandit vel molestie vitae, varius.'
          }
        },
        customize: null
      }
    },
    {
      uid: 'SmkCeDl8A',
      name: 'Credit Card Details',
      sid: 'lbem50zr--g-wvVeN4Sq',
      hideMod: true,
      hideRight: true,
      component: 'ElFieldCardDetails',
      fieldSettings: true,
      options: {
        content: {
          showPaymentMethods: true,
          paymentMethods: {
            visa: true,
            dinersClub: true,
            amex: true,
            discover: true,
            mastercard: true,
            paypal: true
          },
          control: [
            {
              label: 'Payment Method',
              placeholder: null,
              field_name: 'payment_method',
              field_type: 'text',
              values: 'new-method',
              validators: '[]',
              is_required: true,
              mapping: []
            },
            {
              label: 'Card Number',
              placeholder: null,
              field_name: 'card_number',
              field_type: 'text',
              values: null,
              validators: '[]',
              is_required: true,
              mapping: []
            },
            {
              label: 'Expiry Month',
              placeholder: null,
              field_name: 'card_exp_month',
              field_type: 'text',
              values: null,
              validators: '[]',
              is_required: true,
              mapping: []
            },
            {
              label: 'Expiry Year',
              placeholder: null,
              field_name: 'card_exp_year',
              field_type: 'text',
              values: null,
              validators: '[]',
              is_required: true,
              mapping: []
            },
            {
              label: 'CVC/CVV',
              placeholder: null,
              field_name: 'card_cvc',
              field_type: 'text',
              values: null,
              validators: '[]',
              is_required: true,
              mapping: []
            }
          ]
        },
        customize: null
      }
    },
    {
      uid: 'l2j9a1bLK',
      name: 'Payment Summary',
      sid: 'lbem50zr--kdU9TX3Qj7',
      hideMod: true,
      hideRight: true,
      component: 'ElPaymentSummary',
      fieldSettings: true,
      options: {
        content: {
          paymentSummary: {}
        },
        customize: null
      }
    },
    {
      uid: 'zOVxlV7Li',
      display: true,
      name: 'Submit Button',
      class: 'form-button',
      sid: 'lbem50zr--RYvCAbfLGi',
      hideMod: true,
      hideRight: true,
      hideFromSections: false,
      component: 'ElButtonV2',
      fieldSettings: true,
      group: 'Form',
      ignoreGroup: false,
      layout: null,
      disableVisibility: false,
      options: {
        content: {
          button: {
            pressetID: 0,
            display: true,
            type: 'button-mod-v2',
            text: 'Click Me',
            buttonType: 'submit',
            realTimeVisibility: true,
            icon: {
              lottie: {
                jsonPath: '',
                loop: true,
                autoplay: true
              }
            },
            hoverAnimation: {},
            animations: {
              animation: null,
              once: true,
              offset: 200,
              delay: 50
            }
          }
        },
        customize: null
      },
      temp_id: 'RSfMebaiz8',
      editor: 'project'
    },
    {
      uid: 'biU03Qm1e',
      name: 'Billing Terms',
      sid: 'lbem50zr--tKcRNTueuF',
      component: 'ElBillingTerms',
      fieldSettings: true,
      options: {
        content: {
          description: {
            animations: {
              animation: null,
              delay: 50,
              offset: 200,
              once: true
            },
            display: true,
            resolutionStyle: {
              lg: 12,
              md: 12,
              sm: 12
            },
            text: '<p>By joining, you accept my <u>terms</u>. You allow them to charge your card $75 now and $75 every month after that. You can cancel anytime.</p>',
            type: 'tiptap-mod-v2'
          }
        },
        customize: null
      }
    }
  ]
}

const stepConfirmation = {
  options: {
    name: 'Confirmation',
    state: 'confirmation',
    show: true
  },
  content: [
    {
      component: 'ElIcon',
      disableVisibility: false,
      display: true,
      editor: 'project',
      group: 'Basic',
      hideFromSections: false,
      ignoreGroup: false,
      layout: null,
      name: 'Icon',
      options: {
        content: {
          icon: {
            display: true,
            icon: 'far fa-check-circle',
            iconColor: '',
            lottie: {
              autoplay: true,
              loop: true
            },
            type: 'icon-mod'
          }
        }
      },
      sid: 'l7wtfw8k--3Dag6R_0x6',
      uid: '6OTy29VSQ'
    },
    {
      component: 'ElHeadlineV2',
      disableVisibility: false,
      display: true,
      editor: 'project',
      group: 'Typography',
      hideFromSections: false,
      ignoreGroup: false,
      layout: null,
      name: 'Headline',
      options: {
        content: {
          description: {
            display: true,
            resolutionStyle: {
              lg: 12,
              md: 12,
              sm: 12
            },
            tag: 'h3',
            text: 'Payment Successful',
            type: 'tiptap-mod-v2'
          }
        }
      },
      sid: 'l7wtfw8k--p51Sr0XP4k',
      uid: 'yA_3kA21y'
    },
    {
      component: 'ElButtonV2',
      class: 'form-button-redirect',
      disableVisibility: false,
      display: true,
      editor: 'project',
      group: 'Basic',
      hideMod: true,
      hideRight: true,
      hideFromSections: false,
      ignoreGroup: false,
      layout: null,
      name: 'Button',
      options: {
        content: {
          button: {
            animations: {
              animation: null,
              delay: 50,
              offset: 200,
              once: true
            },
            buttonType: 'button',
            display: true,
            hoverAnimation: {},
            icon: {
              lottie: {
                autoplay: true,
                jsonPath: '',
                loop: true
              }
            },
            pressetID: 0,
            realTimeVisibility: true,
            text: 'Access Product',
            type: 'button-mod-v2'
          }
        },
        customize: null
      },
      sid: 'l7wtfw8k--a1DReWm1B2',
      uid: 'eLziKiGDF'
    }
  ]
}

export const defaultOptions = {
  content: {
    orderForm: {
      display: true,
      type: 'order-form-mod',
      stepType: 'tabs',
      product: null,
      steps: [
        {
          uui: 1,
          ...stepInformation
        },
        {
          uui: 2,
          ...stepPayments
        }
      ],
      confirmation: [
        stepConfirmation
      ]
    }
  }
}

export const icon = `
  <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4H14C14.2652 4 14.5196 4.10536 14.7071 4.29289C14.8946 4.48043 15 4.73478 15 5V9C15 9.26522 14.8946 9.51957 14.7071 9.70711C14.5196 9.89464 14.2652 10 14 10H10V11H14C14.5304 11 15.0391 10.7893 15.4142 10.4142C15.7893 10.0391 16 9.53043 16 9V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10V4ZM6 4V3H2C1.46957 3 0.960859 3.21071 0.585786 3.58579C0.210714 3.96086 0 4.46957 0 5L0 9C0 9.53043 0.210714 10.0391 0.585786 10.4142C0.960859 10.7893 1.46957 11 2 11H6V10H2C1.73478 10 1.48043 9.89464 1.29289 9.70711C1.10536 9.51957 1 9.26522 1 9V5C1 4.73478 1.10536 4.48043 1.29289 4.29289C1.48043 4.10536 1.73478 4 2 4H6Z" fill="#1D1D1D"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C8.13261 0 8.25979 0.0526784 8.35355 0.146447C8.44732 0.240215 8.5 0.367392 8.5 0.5V13.5C8.5 13.6326 8.44732 13.7598 8.35355 13.8536C8.25979 13.9473 8.13261 14 8 14C7.86739 14 7.74021 13.9473 7.64645 13.8536C7.55268 13.7598 7.5 13.6326 7.5 13.5V0.5C7.5 0.367392 7.55268 0.240215 7.64645 0.146447C7.74021 0.0526784 7.86739 0 8 0V0Z" fill="#1D1D1D"/>
  </svg>
`

export default class ElOrderForm extends SectionProvider {
  constructor (options = {}) {
    super('ElOrderForm', options)

    this.name = name
    this.group = group
    this.css = [[
      // Order Form
      '.--lbem50zr--0ADnExMODM--order-form { border: solid 1px #E3E3E3; border-radius: 4px; background-color: #FFFFFF; }',
      '.--lbem50zr--0ADnExMODM--content { padding: 40px; }',
      '.--lbem50zr--0ADnExMODM--content .--lbem50zr--0ADnExMODM--form { row-gap: 20px; column-gap: 20px; }',
      '.--lbem50zr--0ADnExMODM--form .form-control.input { height: 50px; }',
      '.--lbem50zr--0ADnExMODM--form .form-control.input::placeholder { color: #495057; }',
      '.--lbem50zr--0ADnExMODM--form legend { text-align: left; }',
      '.--lbem50zr--0ADnExMODM--header { margin-right: -1px; margin-left: -1px; margin-top: -1px; }',
      '.--lbem50zr--0ADnExMODM--step { font-size: 15px; line-height: 18px; font-weight: 500; color: #929292; border: 1px solid #E3E3E3; padding: 20px 40px; background-color: #EDEDED; }',
      '.--lbem50zr--0ADnExMODM--step.active { color: #333333; background-color: #FFFFFF; }',
      '.--lbem50zr--0ADnExMODM--progress { padding: 20px 40px; gap: 12px; }',
      '.--lbem50zr--0ADnExMODM--progress .progress-counter { font-size: 14px; line-height: 20px; font-weight: 400; color: #7F8897; }',
      '.--lbem50zr--0ADnExMODM--progress .progress-title { font-size: 27px; line-height: 32px; font-weight: 400; color: #000000; }',
      '.--lbem50zr--0ADnExMODM--progress .progress { width: 65%; height: 8px; border-radius: 30px; background-color: #E7EDF8; }',
      '.--lbem50zr--0ADnExMODM--progress .progress-bar { background-color: #3D82EA;  }',

      // Auth Fields
      '.--lbem50zr--u-TYvpTDvT--form-fields { gap: 20px; }',
      '.--lbem50zr--u-TYvpTDvT--form-field { width: 100%; }',
      '.--lbem50zr--u-TYvpTDvT--login { width: 100%; font-size: 14px; line-height: 18px; color: #979797; padding: 0; margin: 0; }',
      '.--lbem50zr--u-TYvpTDvT--login-link { font-size: 14px; line-height: 18px; color: #77abff; }',

      // Button Step 1
      '.--lbem50zr--8-isuWLxLw--1 { width: 100%; --width: 100%; }',

      // Product Options
      '.--ldfg8s4r--uz75WC1Mfb--wrapper { padding: 0; margin: 0; }',
      '.--ldfg8s4r--uz75WC1Mfb--product-variant { gap: 0; }',
      '.--ldfg8s4r--uz75WC1Mfb--header { padding-bottom: 8px; }',
      '.--ldfg8s4r--uz75WC1Mfb--header-name { font-size: 15px; line-height: 18px; font-weight: 600; color: #202020; padding: 0; margin: 0; }',
      '.--ldfg8s4r--uz75WC1Mfb--header-price { font-size: 15px; line-height: 18px; font-weight: 600; color: #202020; padding: 0; margin: 0; }',
      '.--ldfg8s4r--uz75WC1Mfb--products { gap: 0; }',
      '.--ldfg8s4r--uz75WC1Mfb--product { margin: 0; padding: 16px 0; border-top: 1px solid #D0D5DD; }',
      '.--ldfg8s4r--uz75WC1Mfb--product-name { font-size: 17px; line-height: 20x; font-weight: 500; color: #202020; padding: 0; margin-bottom: 12px; }',
      '.--ldfg8s4r--uz75WC1Mfb--variants { gap: 4px; }',
      '.--ldfg8s4r--uz75WC1Mfb--variant-name { font-size: 15px; line-height: 18x; font-weight: 400; color: #464646; padding: 0; margin: 0; }',
      '.--ldfg8s4r--uz75WC1Mfb--variant-name.active { font-weight: 600; color: #202020; }',
      '.--ldfg8s4r--uz75WC1Mfb--variant-price { font-size: 15px; line-height: 18x; font-weight: 400; color: #464646; padding: 0; margin: 0; }',
      '.--ldfg8s4r--uz75WC1Mfb--variant-price.active { font-weight: 600; color: #202020; }',

      // Product Cover
      '.--lbem50zr--58_mFcLe1N--image { border-radius: 4px; width: 100%; }',

      // Product Title
      '.--lbem50zr--53o6qSF0DG-text { font-size: 25px; line-height: 30px; font-weight: 700; color: #000000; text-align: center; }',

      // Product Description
      '.--lbem50zr--8w1lv-R2A6-text { font-size: 14px; line-height: 21px; font-weight: 400; color: #8A8A8A; text-align: center; }',

      // Credit Card Details
      '.--lbem50zr--g-wvVeN4Sq--form-fields { row-gap: 20px; column-gap: 20px; }',
      '.--lbem50zr--g-wvVeN4Sq--card-details { row-gap: 20px; column-gap: 20px; }',
      '.--lbem50zr--g-wvVeN4Sq--form-field-2 { width: 96px; }',
      '.--lbem50zr--g-wvVeN4Sq--form-field-3 { width: 96px; }',
      '.--lbem50zr--g-wvVeN4Sq--form-field-4 { width: 96px; }',
      '.--lbem50zr--g-wvVeN4Sq--secure-payment { font-size: 14px; line-height: 18px; color: #979797; gap: 9px; padding-bottom: 20px; border-bottom: 1px solid #E3E3E3; }',
      '.--lbem50zr--g-wvVeN4Sq--secure-payment-icon { height: 14px; color: #B0B0B0; }',

      // Payment Summary
      '.--lbem50zr--kdU9TX3Qj7--container { row-gap: 20px; column-gap: 20px; }',
      '.--lbem50zr--kdU9TX3Qj7--item { row-gap: 20px; column-gap: 20px; }',
      '.--lbem50zr--kdU9TX3Qj7--title { font-size: 13px; line-height: 16px; font-weight: 500; color: #363636; }',
      '.--lbem50zr--kdU9TX3Qj7--product { font-size: 15px; line-height: 18px; font-weight: 600; color: #363636; }',
      '.--lbem50zr--kdU9TX3Qj7--price { font-size: 15px; line-height: 18px; font-weight: 400; color: #363636; }',

      // Button Step 2
      '.--lbem50zr--RYvCAbfLGi--1 { width: 100%; --width: 100%; }',

      // Billing Terms
      '.--lbem50zr--tKcRNTueuF-text div { font-size: 12px; line-height: 1.5; color: #9B9B9B; }',
      '.--lbem50zr--tKcRNTueuF-margin { margin-left: 32px; margin-right: 32px; }',

      // Previews Button
      '.--ldfg8s4r--O2eo80MASi--container { justify-content: start; }',
      '.--ldfg8s4r--O2eo80MASi--previews-button { font-size: 14px; line-height: 20px; font-weight: 400; color: #929292; }',
      '.--ldfg8s4r--O2eo80MASi--previews-button:hover { color: #333333; }',

      // Confirmation Icon
      '.--l7wtfw8k--3Dag6R_0x6--1 { font-size: 100px; color: #62AD62; }',

      // Confirmation Title
      '.--l7wtfw8k--p51Sr0XP4k-margin { margin-bottom: 20px; }',
      '.--l7wtfw8k--p51Sr0XP4k-text div { font-size: 24px; font-weight: 400; color: #363636; }',

      // Confirmation Button
      '.--l7wtfw8k--a1DReWm1B2--1 { width: 100%; --width: 100%; }'
    ], [], []]
    this.sid = 'lbem50zr--0ADnExMODM'
    this.sids = [
      'lbem50zr--0ADnExMODM',
      'lbem50zr--u-TYvpTDvT',
      'lbem50zr--8-isuWLxLw',
      'ldfg8s4r--uz75WC1Mfb',
      'lbem50zr--58_mFcLe1N',
      'lbem50zr--53o6qSF0DG',
      'lbem50zr--8w1lv-R2A6',
      'lbem50zr--g-wvVeN4Sq',
      'lbem50zr--kdU9TX3Qj7',
      'lbem50zr--RYvCAbfLGi',
      'lbem50zr--tKcRNTueuF',
      'ldfg8s4r--O2eo80MASi',
      'l7wtfw8k--3Dag6R_0x6',
      'l7wtfw8k--p51Sr0XP4k',
      'l7wtfw8k--a1DReWm1B2'
    ]
    this.defaultOptions = defaultOptions
  }
}