<template>
  <v-select
    v-model="fontWeight"
    class="custom-select"
    :reduce="option => option.value"
    :searchable="false"
    :options="options"
  />
</template>

<script>
export default {
  name: 'FontWeight',
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      options: [
        {
          label: 'Light',
          value: 300
        },
        {
          label: 'Regular',
          value: 400
        },
        {
          label: 'Medium',
          value: 500
        },
        {
          label: 'Bold',
          value: 600
        }
      ]
    }
  },
  computed: {
    fontWeight: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>