<template>
  <b-modal
    v-model="modal"
    class="position-relative"
    lazy
    centered
    body-class="py-5"
    content-class="new-section-modal"
    size="lg"
    hide-footer
    hide-header
  >
    <h3 class="text-center font-weight-bold mb-12 px-0">
      {{ blockId ? 'Update Block' : 'Upload New Block' }}
    </h3>

    <b-row>
      <b-col
        offset="2"
        cols="8"
      >
        <b-form @submit.prevent="onsubmit">
          <b-form-group
            class="estage-form-group"
            label="Enter Block Name"
          >
            <b-input
              v-model="form.name"
              v-validate="'required'"
              name="name"
              :state="errors.has('name') ? false : null"
            />
          </b-form-group>

          <b-form-group
            class="estage-form-group"
            label="Preview URL"
          >
            <b-input
              v-model="form.prev_url"
              v-validate="'required'"
              name="preview"
              :state="errors.has('preview') ? false : null"
            />
          </b-form-group>

          <b-form-group
            label="Group"
            class="estage-form-group"
          >
            <v-select
              v-model="form.group"
              :options="groups"
            />
          </b-form-group>

          <div class="flex items-center justify-center gap-5 mt-5">
            <b-btn
              type="submit"
              variant="primary"
              class="font-weight-bold font-size-14 px-5"
              :disabled="loading"
            >
              {{ blockId ? 'Update' : 'Upload' }}

              <b-spinner
                v-if="loading"
                variant="white"
                small
                class="ml-2"
              />
            </b-btn>

            <b-btn
              v-if="blockId"
              variant="danger"
              @click="deleteBlock"
            >
              Delete
            </b-btn>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'AddToBlocksModal',
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      modal: false,
      loading: false,
      form: {
        name: '',
        group: '',
        prev_url: ''
      },
      groups: [
        'empty-blocks',
        'navbar',
        'hero',
        'featured',
        'blog',
        'resource',
        'call-to-actions',
        'opt-in-forms',
        'faq',
        'cards',
        'logos',
        'gallery',
        'showcase',
        'newsLetter',
        'contact-forms',
        'about-us',
        'testimonials',
        'pricing',
        'videos',
        'carousel',
        'quotes',
        'alert-bar',
        'footer',
        'others'
      ]
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    blockId () {
      return this.block.original ? this.block.original.block_manage_id : false
    }
  },
  watch: {
    block (val) {
      if (Object.keys(val).length) {
        this.modal = true
        this.formSetup()
      }
    }
  },
  methods: {
    formSetup () {
      if (this.blockId) {
        axios.get(`api/projects/block-management/${this.blockId}`)
          .then(({data}) => {
            this.form = {
              name: data.data.name,
              group: data.data.group,
              prev_url: `https://${this.project.subdomain}.estage.site`
            }
          })
      } else {
        this.form = {
          name: this.block.section.name,
          group: '',
          prev_url: `https://${this.project.subdomain}.estage.site`
        }
      }
    },
    deleteBlock () {
      this.loading = true
      axios.delete(`api/projects/block-management/${this.blockId}`)
        .then(() => {
          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Deleted.',
            showConfirmButton: false,
            timer: 3000
          })

          delete this.block.original.block_manage_id
          this.$store.dispatch('pages/save')
          this.modal = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    onsubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true

          axios[this.blockId ? 'put' : 'post'](`api/projects/block-management${this.blockId ? '/' + this.blockId : ''}`, {
            ...this.form,
            block: JSON.stringify(this.block.section)
          })
            .then(({data}) => {
              this.$swal({
                icon: 'success',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: 'Published!',
                showConfirmButton: false,
                timer: 3000
              })

              this.$set(this.block.original, 'block_manage_id', data.data.id)
              this.$store.dispatch('pages/save')
              this.modal = false
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>