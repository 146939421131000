<template>
  <div
    :style="{...border, ...radius, ...fullPaddingSubject(section.options.customize.style, 'padding', [20,20,20,20]), ...fullMarginSubject(section.options.customize.style, 'marginY'), ...shadow}"
    class="el-card position-relative transitioner w-100"
    :class="section.options.content.card.hoverAnimation"
    @mouseenter="() => isHover = true"
    @mouseleave="() => isHover = false"
  >
    <div
      :style="{...backgroundColor, ...imageRadius}"
      class="image-background"
    />
    <div
      :style="{...backgroundImage, ...imageRadius}"
      class="image-background"
    />
    <background-slider
      :style="{...borderRadius}"
      :styles="{...backgroundImage}"
      :options="backgroundSlider"
    />
    <div
      :style="{...backgroundImageOverlay, ...imageRadius}"
      class="image-background"
    />

    <div class="h-100 w-100 position-absolute left-0 top-0 overflow-hidden">
      <float-image
        v-if="floatImage.enabled"
        :config="floatImage"
      />
    </div>

    <div class="position-relative z-index-100">
      <slot />
    </div>

    <shape-divider
      :config="section.options.customize.style"
      :styles="{...imageRadius}"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import ShapeDivider from '@/components/editor/components/shape-divider/ShapeDivider'
import FloatImage from '@/components/builder/utils/FloatImage'
import BorderMixin from '@/components/mixins/BorderMixin'
import BackgroundSlider from '@/components/builder/utils/BackgroundSlider'

export default {
  name: 'ImageItem1Template',
  components: {BackgroundSlider, FloatImage, ShapeDivider},
  mixins: [TemplateMixin, BorderMixin],
  data () {
    return {
      sticky: null,
      isHover: false
    }
  },
  computed: {
    isSticky () {
      return this.section.options.content.card.sticky ? !!this.section.options.content.card.sticky.enabled : false
    },
    shadow () {
      const shadow = this.isHover ? _.get(this.section.options.content.card, ['hover', 'shadowOptions'], this.section.options.content.card.shadowOptions) : this.section.options.content.card.shadowOptions
      const color = this.isHover ? _.get(this.section.options.content.card, ['hover', 'shadowColor'], this.section.options.content.card.shadowColor || '#DADADA') : this.section.options.content.card.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    floatImage () {
      if (!this.section.options.customize.style.hasOwnProperty('floatImage')) {
        this.$set(this.section.options.customize.style, 'floatImage', {
          display: true,
          enabled: false,
          type: 'float-image-customs',
          src: '',
          top: 0,
          x: {left: 0}
        })
      }

      return this.section.options.customize.style.floatImage
    },
    border () {
      const border = this.section.options.content.card.border || [1, 1, 1, 1]
      return {
        borderColor: this.section.options.content.card.borderColor,
        borderStyle: 'solid',
        borderTopWidth: `${border[0]}px`,
        borderRightWidth: `${border[1]}px`,
        borderBottomWidth: `${border[2]}px`,
        borderLeftWidth: `${border[3]}px`
      }
    },
    radius () {
      const radius = this.section.options.content.card.radius || [5, 5, 5, 5]

      return {
        borderTopLeftRadius: `${radius[0]}px`,
        borderTopRightRadius: `${radius[1]}px`,
        borderBottomLeftRadius: `${radius[2]}px`,
        borderBottomRightRadius: `${radius[3]}px`
      }
    },
    imageRadius () {
      const radius = this.section.options.content.card.radius || [5, 5, 5, 5]
      const border = this.section.options.content.card.border || [1, 1, 1, 1]

      const getAngle = (n1, n2) => {
        return Math.max(n1 || 0, n2 || 0)
      }

      return {
        borderTopLeftRadius: `calc(${radius[0]}px - ${getAngle(border[0], border[3])}px)`,
        borderTopRightRadius: `calc(${radius[1]}px - ${getAngle(border[0], border[1])}px)`,
        borderBottomLeftRadius: `calc(${radius[2]}px - ${getAngle(border[2], border[3])}px)`,
        borderBottomRightRadius: `calc(${radius[3]}px - ${getAngle(border[2], border[1])}px)`
      }
    }
  },
  mounted () {
    if (this.isSticky) {
      // eslint-disable-next-line no-undef
      this.sticky = new Sticksy(this.$el, {
        topSpacing: this.section.options.content.card.sticky.top || 0
      })
    }
  }
}
</script>
