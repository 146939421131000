<template>
  <div class="dashboard-space dashboard-background min-vh-100">
    <dashboard-header />

    <!-- Topbar -->
    <dashboard-topbar :title="folder && !search ? folder.name : 'My Projects'">
      <div class="ml-auto" />
      <create-folder-modal v-if="!search" />
      <b-btn
        class="d-flex align-items-center px-4"
        variant="primary"
        @click="createNewProjectProcess"
      >
        Create New Project
        <svg
          class="ml-2"
          fill="none"
          height="12"
          viewBox="0 0 11 12"
          width="11"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.808 11.815H4.807V7.146H0.667V5.329H4.807V0.843999H6.808V5.329H10.925V7.146H6.808V11.815Z"
            fill="white"
          />
        </svg>
      </b-btn>
    </dashboard-topbar>
    <!-- End Topbar -->

    <div class="dashboard-container">
      <!-- Dashbaord Controls Bar -->
      <dashboard-header-controls />
      <!-- End Dashbaord Controls Bar -->

      <div
        v-if="loading"
        class="d-flex align-items-center justify-content-center min-vh-50"
      >
        <b-spinner variant="primary" />
      </div>

      <template v-else>
        <!-- Folders -->
        <dashboard-fodlers />
        <!-- End Folders -->

        <div
          v-if="loadingMoreProjects"
          class="text-center py-5"
        >
          <b-spinner variant="primary" />
        </div>
      </template>
    </div>

    <last-changes-modal />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import DashboardHeader from '../../layouts/dashboard/DashboardHeader'
import DashboardTopbar from '../../layouts/dashboard/DashboardTopbar'
import DashboardHeaderControls from '../../layouts/dashboard/compontens/topbar/DashboardHeaderControls'
import DashboardFodlers from '../../layouts/dashboard/compontens/folders/DashboardFodlers'
import CreateFolderModal from '../../layouts/dashboard/compontens/templates/CreateFolderModal'
import Cookie from '../../components/mixins/Cookie'
import RouterMixin from '../../components/mixins/RouterMixin'
import LastChangesModal from '@/components/builder/LastChangesModal.vue'

export default {
  name: 'Dashboard',
  components: {
    LastChangesModal,
    CreateFolderModal, DashboardFodlers, DashboardHeaderControls, DashboardTopbar, DashboardHeader},
  mixins: [Cookie, RouterMixin],
  computed: {
    ...mapState('dashboard', {
      folder: state => state.folder,
      loading: state => state.loading,
      loadingMoreProjects: state => state.loadingMoreProjects,
      search: state => state.search
    })
  },
  async created () {
    await this.checkIntegrationRedirect()
    this.$store.dispatch('dashboard/getDashboardData')
  },
  mounted () {
    this.$store.commit('projects/RESET')
    this.$store.state.pages.page = {}
    this.$store.dispatch('sections/setSections', [])
    document.addEventListener('scroll', this.loadMoreProjects)
  },
  destroyed () {
    document.removeEventListener('scroll', this.loadMoreProjects)
  },
  methods: {
    createNewProjectProcess () {
      this.$router.push('/templates')    
    },
    loadMoreProjects () {
      if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
        this.$store.dispatch('dashboard/loadProjects')
      }
    },
    async checkIntegrationRedirect () {
      if (this.$route.query.code && this.get_cookie('mailServiceAPI')) {
        const awerb = this.get_cookie('mailServiceAPI').split(',')

        await axios.get(`api/projects/${awerb[0]}/auto-responders/${awerb[1]}/token`, {params: {code: this.$route.query.code}})
          .then(() => {
            this.delete_cookie('mailServiceAPI')

            this.$swal({
              icon: 'success',
              title: 'Mail service integrated!',
              text: 'Redirecting to the editor...',
              showConfirmButton: false,
              timer: 1500,
              willClose: () => {
                window.close()
              }
            })
          })
      }
    }
  }
}
</script>

<style lang="scss">
.dashboard-background {
  background-color: #F7F8F9;
  padding-top: 0px;
}
</style>
