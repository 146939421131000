import SectionProvider from '@builder/sections/SectionProvider'
import Navbar from './Navbar'

export const name = 'Hero_010'
export const group = 'hero'
export const defaultOptions = {
  'content': {
    'items': {
      'type': null,
      'children': [
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'BdxxyaW0h',
                      'display': true,
                      'name': 'Headline',
                      'sid': 'lmkmgsy8--99zbMSRE9X',
                      'hideFromSections': false,
                      'component': 'ElHeadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h1',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>Unleash Your Creativity With Our Software</p>',
                            'animations': {
                              'animation': 'zoom-in',
                              'once': true,
                              'offset': 200,
                              'delay': '90',
                              'duration': '900'
                            }
                          }
                        }
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': 'hRmRZR2wT',
                      'display': true,
                      'name': 'Subheadline',
                      'sid': 'lmkmgsy8--YWrEgOFj9W',
                      'hideFromSections': false,
                      'component': 'ElSubheadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h4',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>Start to Revolutionise your Business With This Brand New Software that will make your life easier.</p>',
                            'animations': {
                              'animation': 'fade-up',
                              'once': true,
                              'offset': 200,
                              'delay': '170'
                            }
                          }
                        },
                        'customize': null
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': 'zfVrZObOh',
                      'display': true,
                      'name': 'Button',
                      'sid': 'lmkmgsy8--RazxjtAKb7',
                      'hideFromSections': false,
                      'component': 'ElButtonV2',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'button': {
                            'pressetID': 0,
                            'display': true,
                            'type': 'button-mod-v2',
                            'text': 'Get It Now',
                            'buttonType': 'button',
                            'icon': {
                              'lottie': {
                                'jsonPath': '',
                                'loop': true,
                                'autoplay': true
                              }
                            },
                            'hoverAnimation': {},
                            'animations': {
                              'animation': 'fade-up',
                              'once': true,
                              'offset': 200,
                              'delay': '200',
                              'duration': '900'
                            }
                          }
                        },
                        'customize': null
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': 'QF8HxM_rA',
                      'display': true,
                      'name': 'Paragraph',
                      'sid': 'lmkmgsy8--biqAyCTWEl',
                      'hideFromSections': false,
                      'component': 'ElParagraphV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>30-Day Money Back Guarantee</p>',
                            'animations': {
                              'animation': 'fade-in',
                              'once': true,
                              'offset': 200,
                              'delay': '1200',
                              'duration': '900'
                            }
                          }
                        },
                        'customize': null
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': '754ke5AtRr',
                  'sid': 'lmkmgsy8--v-zdV-VakE',
                  '_uid': 'WqleujnGEs',
                  'highlight': false,
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': 'LcBkSe_lu',
          'sid': 'lmkmgsy8--RY0zqrSDsV',
          'temp_id': 'j0pbRmZok'
        }
      ]
    },
    'lottie': {}
  },
  'backgroundImage': {
    'src': ''
  },
  'backgroundVideo': {
    'enabled': false,
    'url': '',
    'loop': true,
    'ratio': 1
  },
  'shapeDivider': {
    'bottom': {
      'type': {
        'lg': 5,
        'md': 5,
        'sm': 5
      },
      'height': {
        'lg': 205,
        'md': 205,
        'sm': 205
      },
      'color': '#000000FF'
    }
  }
}

export const iframeURL = 'https://my-web-1690949180049.estage.site'

export const beforeBlock = new Navbar().json()

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('BlankV2', options)

    this.name = name
    this.group = group
    this.sid = 'lmkmgsy8--K2P2nt_gLt'
    this.sids = [
      'lmkmgsy8--K2P2nt_gLt',
      'lmkmgsy8--RY0zqrSDsV',
      'lmkmgsy8--v-zdV-VakE',
      'lmkmgsy8--99zbMSRE9X',
      'lmkmgsy8--YWrEgOFj9W',
      'lmkmgsy8--RazxjtAKb7',
      'lmkmgsy8--biqAyCTWEl'
    ]
    this.css = [
      [
        '.--lmkmgsy8--K2P2nt_gLt--container { max-width: 100vw; padding-top: 130px; }',
        '.--lmkmgsy8--RY0zqrSDsV--flex { max-width: 800px; --width: 800px; }',
        '.--lmkmgsy8--v-zdV-VakE--flex-col-el { width: 100%; --width: 100%; }',
        '.--lmkmgsy8--K2P2nt_gLt--bg { background-color: rgb(255, 228, 65); }',
        '.--lmkmgsy8--99zbMSRE9X-text { padding: 20px 20px 0px; }',
        '.--lmkmgsy8--99zbMSRE9X-text div { color: rgb(0, 0, 0); font-size: 65px; line-height: 1.1; --line-height: 1.1; font-family: Barlow; }',
        '.--lmkmgsy8--YWrEgOFj9W-text { padding: 20px; }',
        '.--lmkmgsy8--YWrEgOFj9W-text div { font-weight: 300; font-family: Barlow; color: rgb(149, 140, 88); font-size: 22px; line-height: 1.5; --line-height: 1.5; }',
        '.--lmkmgsy8--RazxjtAKb7--1 { margin: 20px; font-family: Barlow; font-size: 18px; color: rgb(0, 0, 0); font-weight: 400; background-color: rgb(255, 255, 255); }',
        '.--lmkmgsy8--v-zdV-VakE--flex-col-padding { padding-bottom: 70px; }',
        '.--lmkmgsy8--biqAyCTWEl-text { padding: 0px 20px 20px; }',
        '.--lmkmgsy8--biqAyCTWEl-text div { font-size: 15px; color: rgba(56, 56, 56, 0.46); }',
        '.--lmkmgsy8--RY0zqrSDsV--flex.flex-wrapper { padding-bottom: 190px; }',
        '.--lmkmgsy8--RazxjtAKb7--1:hover { color: rgb(255, 255, 255); background-color: rgb(3, 3, 3); }'
      ],
      [
        '.--lmkmgsy8--RY0zqrSDsV--flex { max-width: 100%; --width: 100%; }',
        '.--lmkmgsy8--99zbMSRE9X-text div { font-size: 59px; }',
        '.--lmkmgsy8--YWrEgOFj9W-text div { font-size: 20px; }',
        '.--lmkmgsy8--v-zdV-VakE--flex-col-padding { padding-bottom: 70px; }'
      ],
      [
        '.--lmkmgsy8--RY0zqrSDsV--flex { max-width: 100%; --width: 100%; }',
        '.--lmkmgsy8--RY0zqrSDsV--flex.flex-wrapper { padding-top: 0px; padding-left: 0px; padding-right: 0px; }',
        '.--lmkmgsy8--99zbMSRE9X-text { padding-top: 0px; }',
        '.--lmkmgsy8--99zbMSRE9X-text div { font-size: 36px; }',
        '.--lmkmgsy8--YWrEgOFj9W-text div { font-size: 18px; }',
        '.--lmkmgsy8--RazxjtAKb7--1 { margin-top: 0px; }',
        '.--lmkmgsy8--v-zdV-VakE--flex-col-padding { padding-bottom: 30px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
