<template>
  <button
    class="group flex justify-between items-center rounded-[3px] text-[15px] font-[700] text-white bg-[#4F83E3] hover:bg-[#6795EB] p-0 h-[38px] min-w-[218px]"
  >
    <div class="px-[16px]">
      Buy Template - <span>${{ template.price }}</span>
    </div>

    <div class="relative flex items-center justify-center overflow-hidden h-[38px] w-[42px]">
      <div class="h-[38px] w-[1px] bg-[#4273CE] absolute top-0 left-0" />
      <div class="h-[38px] w-[1px] bg-[#6795EB] absolute top-0 left-[1px]" />

      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="transition translate-x-[-40px] group-hover:translate-x-[8px]"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.5673 0.666626C1.19911 0.666626 0.900635 0.965103 0.900635 1.33329C0.900635 1.70148 1.19911 1.99996 1.5673 1.99996H3.14639L3.87871 3.53769L4.77484 8.67052C3.72887 8.73543 2.90063 9.60431 2.90063 10.6666C2.90063 11.3748 3.2687 11.997 3.82396 12.3524C3.66054 12.6422 3.5673 12.9769 3.5673 13.3333C3.5673 14.4379 4.46273 15.3333 5.5673 15.3333C6.67187 15.3333 7.5673 14.4379 7.5673 13.3333C7.5673 13.0995 7.5272 12.8751 7.4535 12.6666H11.0144C10.9407 12.8751 10.9006 13.0995 10.9006 13.3333C10.9006 14.4379 11.7961 15.3333 12.9006 15.3333C14.0052 15.3333 14.9006 14.4379 14.9006 13.3333C14.9006 12.2287 14.0052 11.3333 12.9006 11.3333H4.90063C4.53244 11.3333 4.23397 11.0348 4.23397 10.6666C4.23397 10.2984 4.53244 9.99996 4.90063 9.99996H5.5579C5.58721 10.0003 5.61687 9.99879 5.64673 9.99521L13.9801 8.99521C14.2662 8.96088 14.4978 8.7466 14.5544 8.46404L15.5544 3.46404C15.5935 3.26819 15.5428 3.06509 15.4162 2.91063C15.2896 2.75617 15.1004 2.66663 14.9006 2.66663H4.94069L4.1692 1.04665C4.05864 0.814499 3.82443 0.666626 3.5673 0.666626H1.5673ZM12.9006 12.6666C12.5324 12.6666 12.234 12.9651 12.234 13.3333C12.234 13.7015 12.5324 14 12.9006 14C13.2688 14 13.5673 13.7015 13.5673 13.3333C13.5673 12.9651 13.2688 12.6666 12.9006 12.6666ZM4.90063 13.3333C4.90063 12.9651 5.19911 12.6666 5.5673 12.6666C5.93549 12.6666 6.23397 12.9651 6.23397 13.3333C6.23397 13.7015 5.93549 14 5.5673 14C5.19911 14 4.90063 13.7015 4.90063 13.3333ZM6.11534 8.59608L5.31292 3.99996H14.0874L13.3416 7.72892L6.11534 8.59608Z"
          fill="white"
        />
      </svg>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="transition translate-x-[-8px] group-hover:translate-x-[30px]"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.5673 0.666626C1.19911 0.666626 0.900635 0.965103 0.900635 1.33329C0.900635 1.70148 1.19911 1.99996 1.5673 1.99996H3.14639L3.87871 3.53769L4.77484 8.67052C3.72887 8.73543 2.90063 9.60431 2.90063 10.6666C2.90063 11.3748 3.2687 11.997 3.82396 12.3524C3.66054 12.6422 3.5673 12.9769 3.5673 13.3333C3.5673 14.4379 4.46273 15.3333 5.5673 15.3333C6.67187 15.3333 7.5673 14.4379 7.5673 13.3333C7.5673 13.0995 7.5272 12.8751 7.4535 12.6666H11.0144C10.9407 12.8751 10.9006 13.0995 10.9006 13.3333C10.9006 14.4379 11.7961 15.3333 12.9006 15.3333C14.0052 15.3333 14.9006 14.4379 14.9006 13.3333C14.9006 12.2287 14.0052 11.3333 12.9006 11.3333H4.90063C4.53244 11.3333 4.23397 11.0348 4.23397 10.6666C4.23397 10.2984 4.53244 9.99996 4.90063 9.99996H5.5579C5.58721 10.0003 5.61687 9.99879 5.64673 9.99521L13.9801 8.99521C14.2662 8.96088 14.4978 8.7466 14.5544 8.46404L15.5544 3.46404C15.5935 3.26819 15.5428 3.06509 15.4162 2.91063C15.2896 2.75617 15.1004 2.66663 14.9006 2.66663H4.94069L4.1692 1.04665C4.05864 0.814499 3.82443 0.666626 3.5673 0.666626H1.5673ZM12.9006 12.6666C12.5324 12.6666 12.234 12.9651 12.234 13.3333C12.234 13.7015 12.5324 14 12.9006 14C13.2688 14 13.5673 13.7015 13.5673 13.3333C13.5673 12.9651 13.2688 12.6666 12.9006 12.6666ZM4.90063 13.3333C4.90063 12.9651 5.19911 12.6666 5.5673 12.6666C5.93549 12.6666 6.23397 12.9651 6.23397 13.3333C6.23397 13.7015 5.93549 14 5.5673 14C5.19911 14 4.90063 13.7015 4.90063 13.3333ZM6.11534 8.59608L5.31292 3.99996H14.0874L13.3416 7.72892L6.11534 8.59608Z"
          fill="white"
        />
      </svg>
    </div>
  </button>
</template>

<script>
export default {
  name: 'PurchaseButton',
  props: {
    template: {
      type: Object,
      required: true
    }
  }
}
</script>