<template />

<script>
import ControlMixin from '../../mixins/ControlMixin'
import {mapState} from 'vuex'

const hexRgb = require('hex-rgb')

export default {
  name: 'BackgroundImageCustoms',
  mixins: [ControlMixin],

  data () {
    return {
      selectedAddon: null,
      addons: []
    }
  },

  computed: {
    color: {
      get () {
        return this.config.settings.color || this.theme()
      },
      set (val) {
        this.config.settings.color = val
      }
    },
    backgroundColor: {
      get () {
        return this.config.settings.backgroundColor || this.globalStyles.colors.primary
      },
      set (val) {
        this.config.settings.backgroundColor = val
      }
    },
    position: {
      get () {
        return this.config.settings.position
      },
      set (val) {
        this.config.settings.position = val
      }
    },
    ...mapState({
      globalStyles: state => state.editor.globalStyles
    }),
    selectedAddons () {
      return this.config.addons
    }
  },

  watch: {
    selectedAddon (val) {
      if (val) {
        this.config.addons.push(val)
        this.selectedAddon = null
      }
    }
  },

  mounted () {
    this.addons = [
      {
        name: 'Badge',
        type: 'badge',
        settings: {
          position: 'top-left',
          text: 'Badge title',
          color: this.theme(),
          backgroundColor: this.globalStyles.colors.primary,
          rounded: false
        }
      }
    ]
  },

  methods: {
    theme () {
      const rgb = this.toRGBArray(this.globalStyles.colors.primary)
      const o = Math.round(((parseInt(rgb[0]) * 265) + (parseInt(rgb[1]) * 287) + (parseInt(rgb[2]) * 114)) / 1000)

      if (o > 125) {
        return '#1d1d1d'
      } else {
        return '#fff'
      }
    },
    toRGBArray (color) {
      if (color === 'Global Style') return ''

      if (color.indexOf('rgb') !== -1) {
        return color.split('(')[1].split(')')[0].split(',')
      }

      return hexRgb(color, {format: 'array'})
    }
  }
}
</script>
