<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="el-dynamic"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('data')"
  >
    <div :data-sid="section.sid">
      <div
        class="flex blog-search"
        :class="className()"
      >
        <div class="relative w-100">
          <input
            class="input"
            type="text"
            :placeholder="getData.placeholder"
          >

          <svg
            class="input-icon"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.4554 11.7144C9.37802 12.596 8.00078 13.125 6.5 13.125C3.04822 13.125 0.25 10.3268 0.25 6.875C0.25 3.42322 3.04822 0.625 6.5 0.625C9.95178 0.625 12.75 3.42322 12.75 6.875C12.75 8.3758 12.221 9.75306 11.3393 10.8305L13.192 12.6831C13.436 12.9272 13.436 13.3229 13.192 13.567C12.9479 13.8111 12.5521 13.8111 12.3081 13.567L10.4554 11.7144ZM11.5 6.875C11.5 9.63642 9.26142 11.875 6.5 11.875C3.73858 11.875 1.5 9.63642 1.5 6.875C1.5 4.11358 3.73858 1.875 6.5 1.875C9.26142 1.875 11.5 4.11358 11.5 6.875Z"
            />
          </svg>
        </div>

        <button
          class="search-button"
          v-text="getData.btnText"
        />
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'

export default {
  name: 'ElBlogCategories',
  components: {Highliter},
  mixins: [SectionMixin],
  computed: {
    getData () {
      return this.section.options.content.data
    }
  },
  methods: {
    className (name = '') {
      return `--blog-search-${this.section.sid}${name}`
    }
  }
}
</script>

<style lang="scss">
.blog-search {
  display: flex;
  align-items: stretch;
  --width: 100%;
  width: var(--width);

  .input {
    --height: 39px;
    border: 1px solid #D1DBE9;
    width: 100%;
    height: var(--height);
    color: #878787;
    padding: 0 15px;
    font-size: 14px;
  }

  .input-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    path {
      fill: #565656;
    }
  }

  .search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    color: #fff;
    background-color: #3D82EA;
    padding: 0 15px;
  }
}
</style>