<template>
  <div>
    <SidbearSettingsGroup
      title="Shorts"
      active
    >
      <b-form-group label="URL">
        <b-input v-model="url" />
      </b-form-group>

      <b-form-group>
        <label>Max Width <span class="lowercase">(px)</span></label>
        <div class="flex items-center gap-[10px]">
          <vue-slider
            v-model="width"
            class="editor-slider-1 !w-[160px]"
            :interval="1"
            :dot-size="17"
            tooltip="none"
            :min="10"
            :max="2000"
          />

          <linked-control-item
            v-model="width"
            :min="10"
            :max="2000"
            class="d-block !w-[60px]"
          />
        </div>
      </b-form-group>

      <b-form-group label="Height">
        <b-input
          v-model="height"
          readonly
        />
      </b-form-group>

      <sid-buttons-group
        label="Horizontal Alignment"
        :options="[
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'start',
            tooltip: 'Start'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'center',
            tooltip: 'Center'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'end',
            tooltip: 'End'
          }
        ]"
        :sid="`.--${section.sid}--yt-shorts .yt-shorts-iframe-container`"
        rule-name="justify-content"
      />
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Drop Shadow">
      <rule-tabs>
        <template #Normal>
          <b-form-group
            label="Shadow"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--yt-shorts .yt-iframe`"
              rule-name="box-shadow"
            />
          </b-form-group>
        </template>

        <template #Hover>
          <b-form-group
            label="Shadow"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--yt-shorts`"
              rule-name="box-shadow"
              presudo=" .yt-iframe:hover"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--yt-shorts .yt-iframe`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--yt-shorts .yt-iframe`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--yt-shorts .yt-iframe`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--yt-shorts .yt-iframe`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>

        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--yt-shorts`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=" .yt-iframe:hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--yt-shorts`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              presudo=" .yt-iframe:hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--yt-shorts`"
                rule-name="border-color"
                presudo=" .yt-iframe:hover"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--yt-shorts`"
            rule-name="border"
            presudo=" .yt-iframe:hover"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--yt-shorts .yt-shorts-iframe-container`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--yt-shorts .yt-shorts-iframe-container`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import $getEmbedUrl from 'better-video-embeds'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import ModMixin from '@/components/mixins/ModMixin'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem.vue'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'

export default {
  name: 'YtShorts',
  components: {
    SidColor,
    RuleTabs,
    SidSelect,
    SidBoxShadow, SidLinkedGroup, SidButtonsGroup, LinkedControlItem, SidbearSettingsGroup},
  mixins: [ModMixin],
  computed: {
    width: {
      get () {
        return parseInt(this.section.options.content.video.width)
      },
      set (val) {
        this.section.options.content.video.width = `${val}px`
        this.section.options.content.video.height = `${(16/9) * val}px`
      }
    },
    height () {
      return `${parseInt(this.section.options.content.video.height)}px`
    },
    url: {
      get () {
        return this.section.options.content.video.url
      },
      set (val) {
        const id = $getEmbedUrl(val).split('/').slice(-1)[0].slice(0, 11)
        this.section.options.content.video.url = val
        this.section.options.content.video.iframeURL = `https://www.youtube.com/embed/${id}?autoplay=1&loop=1&controls=0`
      }
    }
  }
}
</script>
