const state = () => ({
  mediaList: [],
  explorerTotalPages: null,
  unsplashTotalPages: null
})

const getters = {
  user (state) {
    return state.user
  }
}

const actions = {
  async images ({state}, query) {
    return await axios.get(`api/media?page=${query.page}&per_page=${query.perPage}`)
      .then(({data}) => {
        state.mediaList = data.data.data
        state.explorerTotalPages = data.data.total
      })
  },

  async unsplash ({state}, query) {
    return await this._vm.$unsplash.photos.list(query)
      .then(({response}) => {
        state.mediaList = response.results
        state.unsplashTotalPages = response.total_pages
      })
  },

  async unsplashSearch ({state}, query) {
    return await this._vm.$unsplash.search.getPhotos(query)
      .then(({response}) => {
        state.mediaList = response.results
        state.unsplashTotalPages = response.total_pages
      })
  }
}

const mutations = {
  SET_AUTHENTICATED (state, paylaod) {
    state.authenticated = paylaod
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
