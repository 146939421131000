import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Progress Bar'
export const group = 'Basic'

export const defaultOptions = {
  content: {
    progress: {
      display: true,
      type: 'progressbar-mod-v2',
      animated: false,
      text: 'Almost Complete',
      max: 100,
      value: 40
    }
  }
}

export const icon = `
<svg width="23" height="9" viewBox="0 0 23 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0.1875V8.8125H23V0.1875H0ZM21.5625 7.375H1.4375V1.625H21.5625V7.375Z" fill="black"/>
<path d="M2.875 3.0625H12.9375V5.9375H2.875V3.0625Z" fill="black"/>
</svg>
`
export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElProgressBarV2', options)

    this.name = name
    this.group = group
    this.css = [['.--t3x95ha--07LNjU5Qxe--wrapper { padding: 20px; }'], [], []]
    this.sids = ['t3x95ha--07LNjU5Qxe']
    this.sid = 't3x95ha--07LNjU5Qxe'
    this.defaultOptions = defaultOptions
  }
}

