<template>
  <div :style="{...fullMarginSubject(section.options.customize.style)}">
    <div
      :class="{'hide-label': !property(section.options.content.time, 'displayLabels', true)}"
      :style="scale"
    >
      <div
        :class="container"
        :style="{...color, ...fullPaddingSubject(section.options.customize.style)}"
        class="position-relative time-item-6"
      >
        <slot
          name="time"
          v-bind="{shadow, color: colorCustom('Subtitle'), backgroundColor, scale, sizing}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../../mixins/TemplateMixin'
import ThemeMixin from '../../../../mixins/ThemeMixin'
import PropertiesManager from '../../../../mixins/PropertiesManager'
import _ from 'lodash'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'Carousel1Template',
  mixins: [TemplateMixin, ThemeMixin, PropertiesManager, BorderMixin],
  computed: {
    shadow () {
      const shadow = this.section.options.content.time.shadowOptions
      const color = this.section.options.content.time.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    scale () {
      return _.get(this.section.options.content.time, [`size${this.tablet ? 'md' : this.resolution}`], this.section.options.content.time.size) || 0.8
    },
    sizing () {
      return this.section.options.content.time.sizing || {
        width: 6,
        counter: 3.5,
        divider: 1.5,
        subtitle: 0.8
      }
    }
  }
}
</script>

<style lang="scss">
.time-item-6 {
  .timer-block {
    .timer-item {
      .time {
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        position: relative;
        display: block;
        font-weight: 700;
        text-align: center;
        line-height: 1;
      }

      .subtitle {
        text-align: center;
        font-weight: 700;
        display: block;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      &.border-right-custom {
        border-right: 1px solid #D1D1D1 !important;
      }
    }
  }
}

.resolution-sm {
  .time-item-6 {
    .timer-block {
      .timer-item {
        .time {
          padding: 10px 10px;
          font-size: 40px;
          width: auto;
        }

        .time-divider {
          font-size: 30px;
          margin-right: 10px;
          margin-left: 10px;
        }

        .subtitle {
          font-size: 10px;
        }

        &.border-right-custom {
          border-color: 1px solid #000 !important;
        }
      }
    }
  }
}
</style>
