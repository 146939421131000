<template>
  <div class="d-flex justify-content-center w-100">
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item">
          <a
            class="page-link"
            href="#"
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li class="page-item">
          <a
            class="page-link"
            href="#"
          >1</a>
        </li>
        <li class="page-item">
          <a
            class="page-link"
            href="#"
          >2</a>
        </li>
        <li class="page-item">
          <a
            class="page-link"
            href="#"
          >3</a>
        </li>
        <li class="page-item">
          <a
            class="page-link"
            href="#"
            aria-label="Next"
          >
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import BlogPostsMixin from '../../mixins/BlogPostsMixin'
import ThemeMixin from '../../../../components/mixins/ThemeMixin'
import {mapState} from 'vuex'

export default {
  name: 'Pagination',
  mixins: [BlogPostsMixin, ThemeMixin],
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    })
  },
  methods: {
    themer () {
      const rgb = this.toRGBArray(this.globalStyles.colors.primary)
      const o = Math.round(((parseInt(rgb[0]) * 265) + (parseInt(rgb[1]) * 287) + (parseInt(rgb[2]) * 114)) / 1000)

      if (o > 125) {
        return 'light'
      } else {
        return 'dark'
      }
    }
  }
}
</script>

<style lang="scss">
.dark.pagination > li.active > .page-link {
  color: #fff !important;
}
</style>
