var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SidbearSettingsGroup',{attrs:{"title":"Stars","active":""}},[(_vm.linked)?_c('b-form-group',[_c('button',{staticClass:"flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]",on:{"click":function () { return _vm.unlinkSid(); }}},[_vm._v(" Unlink Styles "),_c('i',{staticClass:"fa fa-link text-primary"})])]):_vm._e(),_c('b-form-group',{attrs:{"label":"Rating"}},[_c('div',{staticClass:"flex items-center gap-[10px]"},[_c('LinkedControlItem',{staticClass:"d-block !w-[60px]",attrs:{"max":_vm.section.options.content.star.max,"min":0,"step":.5},model:{value:(_vm.section.options.content.star.stars),callback:function ($$v) {_vm.$set(_vm.section.options.content.star, "stars", $$v)},expression:"section.options.content.star.stars"}}),_c('div',[_vm._v("/")]),_c('LinkedControlItem',{staticClass:"d-block !w-[60px]",attrs:{"max":10},model:{value:(_vm.section.options.content.star.max),callback:function ($$v) {_vm.$set(_vm.section.options.content.star, "max", $$v)},expression:"section.options.content.star.max"}})],1)]),_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Color")]),_c('sid-color',{attrs:{"sid":(".--" + (_vm.section.sid) + "--wrapper"),"rule-name":"color"}})],1)]),_c('sid-buttons-group',{attrs:{"label":"Horizontal Alignment","options":[
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'start',
          tooltip: 'Start'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'center',
          tooltip: 'Center'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'end',
          tooltip: 'End'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'space-between',
          tooltip: 'Between'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'space-around',
          tooltip: 'Around'
        }
      ],"sid":(".--" + (_vm.section.sid) + "--wrapper"),"rule-name":"justify-content"}}),_c('sid-range-slider',{attrs:{"label":"Size","sid":(".--" + (_vm.section.sid) + "--wrapper"),"rule-name":"font-size"}}),_c('sid-range-slider',{attrs:{"label":"Gap","sid":(".--" + (_vm.section.sid) + "--wrapper"),"rule-name":"gap"}}),_c('sid-background',{attrs:{"sid":(".--" + (_vm.section.sid) + "--wrapper")}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Border & Radius"}},[_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Border Type")]),_c('sid-select',{staticClass:"w-[118px]",attrs:{"sid":(".--" + (_vm.section.sid) + "--wrapper"),"rule-name":"border-style","searchable":false,"options":[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]}})],1)]),_c('b-form-group',[_c('sid-linked-group',{staticClass:"!mb-[7px]",attrs:{"sid":(".--" + (_vm.section.sid) + "--wrapper"),"label":"Border Width","rule-name":"border","angles":[
          {
            text: '',
            value: 'top-width'
          },
          {
            text: '',
            value: 'right-width'
          },
          {
            text: '',
            value: 'bottom-width'
          },
          {
            text: '',
            value: 'left-width'
          }
        ]}}),_c('div',{staticClass:"flex w-full justify-end"},[_c('sid-color',{attrs:{"sid":(".--" + (_vm.section.sid) + "--wrapper"),"rule-name":"border-color"}})],1)],1),_c('sid-linked-group',{attrs:{"label":"Radius","sid":(".--" + (_vm.section.sid) + "--wrapper"),"rule-name":"border","angles":[
        {
          text: '',
          value: 'top-left-radius'
        },
        {
          text: '',
          value: 'top-right-radius'
        },
        {
          text: '',
          value: 'bottom-left-radius'
        },
        {
          text: '',
          value: 'bottom-right-radius'
        }
      ]}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Padding & Margins"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":(".--" + (_vm.section.sid) + "--wrapper"),"rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":(".--" + (_vm.section.sid) + "--wrapper"),"rule-name":"margin","min":-2000,"max":2000,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }