import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Star Rating'
export const group = 'Advanced'
export const defaultOptions = {
  'content': {'star': {'type': 'star-rating-mod-v2', 'display': true, 'stars': 4, 'max': 5}}
}

export const previews = {
  left: null,
  right: null,
  main: require('@builder/assets/svg/previews/time/main-1.png')
}

export const icon = `
<svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.5 2.42705L13.3686 8.17807L13.537 8.6963H14.0819H20.1289L15.2368 12.2506L14.7959 12.5709L14.9643 13.0892L16.8329 18.8402L11.9408 15.2858L11.5 14.9656L11.0592 15.2858L6.16705 18.8402L8.03567 13.0892L8.20406 12.5709L7.76322 12.2506L2.87111 8.6963H8.91809H9.463L9.63138 8.17807L11.5 2.42705Z" fill="white" stroke="black" stroke-width="1.5"/>
</svg>
`

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElStarRatingV2', options)

    this.name = name
    this.group = group
    this.css = [['.--cea967j--wxKIr3L4d--wrapper { justify-content: center; font-size: 27px; gap: 0px; color: rgb(252, 176, 69); padding: 20px; }'], [], []]
    this.sid = 'cea967j--wxKIr3L4d'
    this.sids = ['cea967j--wxKIr3L4d']
    this.defaultOptions = defaultOptions
  }
}
