<template>
  <div>
    <b-form-group label="Mask">
      <sid-select
        :options="masksOptions"
        rule-name="-webkit-mask-image"
        :sid="sid"
        var="--mask"
      />
    </b-form-group>

    <slot />

    <b-form-group>
      <sid-range-slider
        label="Mask Position Y"
        rule-name="-webkit-mask-position-y"
        :sid="sid"
      />

      <sid-range-slider
        label="Mask Position X"
        rule-name="-webkit-mask-position-x"
        :sid="sid"
      />

      <sid-range-slider
        label="Mask Size"
        rule-name="-webkit-mask-size"
        :sid="sid"
        default-dimension="%"
      />
    </b-form-group>

    <div class="flex items-center justify-between">
      <label class="mb-0">Mask Repeat</label>

      <sid-checkbox
        rule-name="-webkit-mask-repeat"
        :sid="sid"
        value="repeat"
        :default-value="'no-repeat'"
        unchecked-value="no-repeat"
      />
    </div>
  </div>
</template>

<script>
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox'
export default {
  name: 'SidBackgroundMask',
  components: {SidCheckbox, SidSelect, SidRangeSlider},
  props: {
    sid: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      masksOptions: [
        {
          label: 'No Mask',
          value: 'unset'
        },
        {
          label: 'Mask 1',
          value: 'url("data:image/svg+xml,%3Csvg width=\'200\' height=\'200\' viewBox=\'0 0 200 200\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Ccircle cx=\'100\' cy=\'100\' r=\'100\' fill=\'%23EDEDED\'/%3E%3C/svg%3E%0A")'
        },
        {
          label: 'Mask 2',
          value: 'url("data:image/svg+xml,%3Csvg width=\'1019\' height=\'953\' viewBox=\'0 0 1019 953\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M846 423C846 656.616 656.616 846 423 846C189.384 846 0 656.616 0 423C0 189.384 189.384 0 423 0C656.616 0 846 189.384 846 423Z\' fill=\'%23D9D9D9\'/%3E%3Cpath d=\'M1019 688C1019 834.355 898.565 953 750 953C601.435 953 481 834.355 481 688C481 541.645 601.435 423 750 423C898.565 423 1019 541.645 1019 688Z\' fill=\'%23D9D9D9\'/%3E%3C/svg%3E%0A")'
        },
        {
          label: 'Mask 3',
          value: 'url("data:image/svg+xml,%3Csvg width=\'1241\' height=\'1716\' viewBox=\'0 0 1241 1716\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M458 414C458 540.473 355.473 643 229 643C102.527 643 0 540.473 0 414C0 287.527 102.527 185 229 185C355.473 185 458 287.527 458 414Z\' fill=\'%23D9D9D9\'/%3E%3Cpath d=\'M776 881C776 1000.85 677.279 1098 555.5 1098C433.721 1098 335 1000.85 335 881C335 761.154 433.721 664 555.5 664C677.279 664 776 761.154 776 881Z\' fill=\'%23D9D9D9\'/%3E%3Cpath d=\'M1050 217C1050 336.846 951.279 434 829.5 434C707.721 434 609 336.846 609 217C609 97.1542 707.721 0 829.5 0C951.279 0 1050 97.1542 1050 217Z\' fill=\'%23D9D9D9\'/%3E%3Cpath d=\'M1241 1315C1241 1434.85 1142.28 1532 1020.5 1532C898.721 1532 800 1434.85 800 1315C800 1195.15 898.721 1098 1020.5 1098C1142.28 1098 1241 1195.15 1241 1315Z\' fill=\'%23D9D9D9\'/%3E%3Cpath d=\'M458 1499C458 1618.85 359.279 1716 237.5 1716C115.721 1716 17 1618.85 17 1499C17 1379.15 115.721 1282 237.5 1282C359.279 1282 458 1379.15 458 1499Z\' fill=\'%23D9D9D9\'/%3E%3C/svg%3E%0A")'
        },
        {
          label: 'Mask 4',
          value: 'url("data:image/svg+xml,%3Csvg width=\'1336\' height=\'1271\' viewBox=\'0 0 1336 1271\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M667.858 .570312L825.467 485.64H1335.5L922.875 785.43L1080.48 1270.5L667.858 970.71L255.233 1270.5L412.842 785.43L0.216797 485.64H510.25L667.858 .570312Z\' fill=\'%23D9D9D9\'/%3E%3C/svg%3E%0A")'
        },
        {
          label: 'Mask 5',
          value: 'url("data:image/svg+xml,%3Csvg width=\'1867\' height=\'966\' viewBox=\'0 0 1867 966\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M516.5 0L655.497 240.75H377.503L516.5 0Z\' fill=\'%23D9D9D9\'/%3E%3Cpath d=\'M1031.5 204L1170.5 444.75H892.503L1031.5 204Z\' fill=\'%23D9D9D9\'/%3E%3Cpath d=\'M1706.5 508L1845.5 748.75H1567.5L1706.5 508Z\' fill=\'%23D9D9D9\'/%3E%3Cpath d=\'M160.5 645L299.497 885.75H21.5029L160.5 645Z\' fill=\'%23D9D9D9\'/%3E%3C/svg%3E%0A")'
        },
        {
          label: 'Mask 6',
          value: 'url("data:image/svg+xml,%3Csvg width=\'360\' height=\'363\' viewBox=\'0 0 360 363\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M248.878 363H111.122V179.667H0L180 0L360 179.667H248.878V363Z\' fill=\'black\'/%3E%3C/svg%3E%0A")'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>