import SectionProvider from '@builder/sections/SectionProvider'
import shortid from 'shortid'

export const name = 'Menu Links'
export const group = 'Navigation'

export const defaultOptions = {
  content: {
    menu: {
      display: true,
      type: 'menu-links-mod-v4',
      links: [
        {
          id: shortid.generate(),
          href: '#',
          text: 'About',
          customLink: false,
          blank: false,
          itemType: '',
          children: []
        },
        {
          id: shortid.generate(),
          href: '#',
          text: 'Work with Me',
          customLink: false,
          blank: false,
          itemType: '',
          children: []
        },
        {
          id: shortid.generate(),
          href: '#',
          text: 'Resources',
          customLink: false,
          blank: false,
          itemType: '',
          children: []
        },
        {
          id: shortid.generate(),
          href: '#',
          text: 'Blog',
          customLink: false,
          blank: false,
          itemType: '',
          children: []
        },
        {
          id: shortid.generate(),
          href: '#',
          text: 'Contact',
          customLink: false,
          blank: false,
          children: []
        }
      ]
    }
  }
}

export const icon = `
<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.9777 14.625H6.27266C5.70781 14.625 5.25 14.2333 5.25 13.75C5.25 13.2667 5.70781 12.875 6.27266 12.875H19.9773C20.5422 12.875 21 13.2667 21 13.75C21 14.2333 20.5425 14.625 19.9777 14.625Z" fill="black"/>
<path d="M19.9777 8.5H6.27266C5.70781 8.5 5.25 8.10829 5.25 7.625C5.25 7.14171 5.70781 6.75 6.27266 6.75H19.9773C20.5422 6.75 21 7.14171 21 7.625C21.0003 8.10829 20.5425 8.5 19.9777 8.5Z" fill="black"/>
<path d="M19.9777 2.375H6.27266C5.70781 2.375 5.25 1.98329 5.25 1.5C5.25 1.01671 5.70781 0.625 6.27266 0.625H19.9773C20.5422 0.625 21 1.01671 21 1.5C21 1.98329 20.5425 2.375 19.9777 2.375Z" fill="black"/>
<circle cx="1.3125" cy="1.0625" r="1.3125" fill="black"/>
<circle cx="1.3125" cy="8.0625" r="1.3125" fill="black"/>
<circle cx="1.3125" cy="14.1875" r="1.3125" fill="black"/>
</svg>
`
export default class ElMenuLinks extends SectionProvider {
  constructor (options = {}) {
    super('ElMenuLinksV4', options)

    this.name = name
    this.group = group
    this.css = [['.--t3x95ha--07LNjU5Qxe--wrapper { padding: 20px; }', '.--t3x95ha--07LNjU5Qxe--link { font-size: 14px; }'], [], []]
    this.sids = ['t3x95ha--07LNjU5Qxe']
    this.sid = 't3x95ha--07LNjU5Qxe'
    this.defaultOptions = defaultOptions
  }
}

