<template>
  <b-card-header
    header-class="editor-toolbar-collapse-header !h-[43px] transition-all d-flex align-items-center rounded-0"
    @mouseenter="menu = true"
    @mouseleave="menu = false"
  >
    <slot />

    <div
      v-if="menu"
      class="ml-auto"
    >
      <slot name="menu" />
    </div>
  </b-card-header>
</template>

<script>
export default {
  name: 'SectionItemHover',

  data () {
    return {
      menu: false
    }
  }
}
</script>

<style scoped>

</style>
