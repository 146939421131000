<template>
  <div
    class="card h-100 testimonial-item-1"
    :style="{...border, ...shadow, ...backgroundColor, padding}"
    :class="[cols('', 'mb-4'), theme]"
  >
    <div class="card-body">
      <slot />
    </div>
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'

export default {
  name: 'TestimonialItem4Template',
  mixins: [TemplateMixin]
}
</script>
