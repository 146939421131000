<template>
  <div class="flex flex-column relative max-h-full h-[calc(100vh-185px)]">
    <div class="relative overflow-auto">
      <component
        :is="isClickEvent ? 'div' : 'draggable'"
        v-model="flex"
        :clone="cloneFlex"
        :group="{ name: 'rows', pull: 'clone', put: false }"
        :sort="false"
        class="d-flex flex-wrap justify-content-center mt-4"
        :scroll="true"
        :force-fallback="true"
        fallback-class="rows-sortable-fallback"
        :force-autoscroll-fallback="true"
        :scroll-sensitivity="60"
        :bubble-scroll="true"
        :fallback-on-body="true"
      >
        <div
          v-for="(block, index) in flex"
          :key="index"
          class=""
        >
          <div class="row-card-wrapper">
            <div
              class="flex-card mb-3"
              :class="{'cursor-pointer': isClickEvent}"
              @click="() => isClickEvent ? addFlex(block) : null"
              v-html="block.preview"
            />
          </div>
        </div>
      </component>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ElRow from '@builder/components/builder/sections/utils/el-row/Section.js'
import TextMixins from '../../mixins/TextMixins'
import Icon from '@builder/components/editor/utils/Icon'
import SectionItemHover from '../utils/SectionItemHover'
import {mapState} from 'vuex'
import {insert} from '../../../sections/utils/helpers'
import flexWrappers from '@/composition/flex-wrappers'
import shortid from 'shortid'
import _ from 'lodash'

export default {
  name: 'RowsDragItems',
  components: {SectionItemHover, Icon, draggable},

  mixins: [TextMixins],

  data () {
    return {
      flex: flexWrappers,
      rows: [
        {
          columns: 1,
          section: ElRow
        },
        {
          columns: 2,
          section: ElRow
        },
        {
          columns: 3,
          section: ElRow
        },
        {
          columns: 4,
          section: ElRow
        }
      ]
    }
  },

  computed: {
    isClickEvent () {
      return this.addSubjectEvent === 'click'
    },
    ...mapState('editor', {
      currentSubject: state => state.currentSubject,
      addSubjectEvent: state => state.addSubjectEvent
    })
  },

  methods: {
    addFlex (flex) {
      const _flex = _.cloneDeep(flex.section)
      _flex.uid = shortid.generate()
      if (this.currentSubject.options.originKey === 'custom') {
        if (!this.currentSubject.subject.options.content.items) {
          this.$store.state.editor.addSubjectEvent = ''
        }

        this.currentSubject.subject.options.content.items.children.push(_flex)
      } else {
        this.$set(this.currentSubject.subject, this.currentSubject.options.originKey, insert(this.currentSubject.subject.children || this.currentSubject.subject.elements, this.currentSubject.options.index + 1, _flex))
      }

      this.$store.commit('editor/SET_PAGE', {
        name: 'add-element',
        event: 'click',
        subject: {
          subject: _flex,
          options: {index: 0, elIndex: 0, subject: this, originKey: 'custom'}
        }
      })
    },
    onClick (columns) {
      this.$set(this.currentSubject.subject, this.currentSubject.options.originKey, insert(this.currentSubject.subject.children, this.currentSubject.options.index + 1, new ElRow({}, columns).json()))
      this.$store.commit('editor/SET_PAGE', 'sections')
    },
    cloneRow (row) {
      return new row.section({}, row.columns).json()
    },
    cloneFlex (flex) {
      const _flex = _.cloneDeep(flex.section)
      _flex.uid = shortid.generate()
      return _flex
    }
  }
}
</script>

<style lang="scss">
.flex-card {
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  cursor: move;

  &:hover {
    box-shadow: 0px 0 0 2px #1681F2;

    .label {
      color: #1681F2;
    }

    path {
      stroke: #1681F2;
    }
  }
}
</style>