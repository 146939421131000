<template>
  <ElementPresset
    v-model="pressetID"
    :pressets="pressets"
    class="mb-[27px]"
    title="Divider Style"
  >
    <template
      slot="preview"
      slot-scope="{ content }"
    >
      <img
        v-if="content.preview"
        :src="content.preview"
        alt="element presset image"
      >
      <span
        v-else
        v-text="content.text"
      />
    </template>
  </ElementPresset>
</template>

<script>
import ModControlMixin from '@/components/mixins/ModControlMixin'
import ElementPresset from '@/components/editor/components/ElementPresset'

export default {
  name: 'DividerStyle',
  components: {ElementPresset},
  mixins: [ModControlMixin],
  props: {
    pressets: {
      type: Array,
      required: true
    }
  },
  computed: {
    pressetID: {
      get () {
        return this.config.pressetID || 0
      },
      set (val) {
        this.$set(this.config, 'pressetID', val)
      }
    }
  }
}
</script>