<template />

<script>
export default {
  name: 'PostAuthorInfoMod'
}
</script>

<style scoped>

</style>