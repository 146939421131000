<template>
  <b-modal
    :visible="modal"
    size="813"
    header-class="!border-b-0"
    body-class="p-0"
    hide-footer
    @change="handleModalChange"
  >
    <template #modal-header>
      <icon
        class="ml-auto cursor-pinter"
        icon="close.svg"
        @click.native="handleModalChange(false)"
      />
    </template>
    <div class="max-w-[470px] mx-auto">
      <div class="mx-auto">
        <div class="capitalize text-center font-semibold text-[27px] leading-[27px] text-[#1D1D1D] mb-[48px] mt-[12px]">
          {{ action || 'Save' }} Folder
        </div>
        <div class="w-100">
          <b-form-group
            v-if="action ? action == 'rename': true"
            label="Folder Name"
            label-class="!text-[13px] !leading-[16px] !text-[#6E747A] !font-normal !pb-[4px]"
            class="mb-[12px]"
          >
            <b-input
              v-model="form.name"
              required
              class="!rounded-[3px] !h-[38px] !text-[#6E747A]"
              :class="[$v.form.name.$error ? '!border-red-500' : '!border-[#E2E5EC] focus:!border-[#80bdff]']"
            />
          </b-form-group>

          <b-form-group
            v-if="action == 'move'"
            label="Select Folder"
            label-class="!text-[13px] !leading-[16px] !text-[#6E747A] !font-normal !pb-[4px] !text-[#6E747A]"
            class="pm-form-group mb-[12px]"
          >
            <div class="d-flex position-relative page-folder-tree">
              <div
                class="chevron-down-icon position-absolute z-10 right-[18px] top-[16px]"
              />
              <treeselect
                v-model="form.parent_id"
                :options="folders"
                :searchable="false"
                :normalizer="normalizer"
                no-options-text="No folders created"
                placeholder="Select folder"
                required
              >
                <label
                  slot="value-label"
                  slot-scope="{node}"
                >
                  {{ node.raw.name }}
                </label>

                <label
                  slot="option-label"
                  slot-scope="{ node, shouldShowCount, count, labelClassName }"
                  :class="labelClassName"
                >
                  {{ node.raw.name }}
                </label>
              </treeselect>
            </div>
          </b-form-group>



          <p
            v-if="action == 'delete'"
            class="text-center"
          >
            Are you sure you want to delete this folder ?
          </p>

          <b-form-group
            v-if="action == 'delete'"
            v-slot="{ ariaDescribedby }"
            class="text-center mt-[25px]"
          >
            <b-form-radio-group
              v-model="form.is_delete_items"
              :options="[
                {
                  text:'Move All Files Menu',
                  value:false,
                },
                {
                  text:'Delete All Files',
                  value:true,
                }
              ]"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
            />
          </b-form-group>



          <div class="d-flex align-items-center justify-content-center">
            <div class="d-flex justify-content-center mt-[46px] mb-[75px]">
              <b-btn
                v-if="action == 'delete'"
                class="btn-wide mr-2"
                @click="handleModalChange(false)"
              >
                No
              </b-btn>

              <b-btn
                class="capitalize !inline-flex !text-[15px] btn-wide align-items-center"
                type="button"
                variant="primary"
                :disabled="loading"
                @click="submit"
              >
                {{ action =='delete' ? 'Yes' :(action || 'Save') }}
                <b-spinner
                  v-if="loading"
                  class="ml-2 !w-4 !h-4"
                  variant="white"
                />
              </b-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Treeselect from '@riophae/vue-treeselect'
import {required, requiredIf} from 'vuelidate/lib/validators'

export default {
  components: {
    Treeselect
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default:()=> new Object()
    },
    action: {
      type: String,
      default:()=> ''
    }

  },
  validations: {
    form: {
      name:{required},
      parent_id: {
        required: requiredIf(function () {
          return this.action == 'move'
        })
      }
    }
  },
  data () {
    return {
      form: {},
      loading:false
    }
  },
  computed: {
    ...mapState('assetsLibrary', {
      allFolders: state => state.folders,
      activeFolder: state => state.activeFolder
    }),
    isImg (){
      if(this.activeFolder){
        return this.activeFolder.type == 'image'
      }
      return false
    },
    folders (){
      let data = this.allFolders
      if(this.activeFolder){
        data = data.filter(d=> this.isImg ? d.type == 'image' : d.type == 'pdf')
      }
      return data
    }
  },
  watch: {
    modal () {
      this.initForm(this.item)
    }
  },
  methods: {
    ...mapActions({
      updateFolder:'assetsLibrary/updateFolder',
      createFolder:'assetsLibrary/createFolder',
      deleteFolder:'assetsLibrary/deleteFolder'
    }),
    handleModalChange (val) {
      this.$emit('update:modal',val)
    },
    initForm (params = {}){
      this.form = {
        id:params.id||'',
        name:params.name || '',
        parent_id:params.parent_id,
        is_delete_items:false
      }
      if(this.activeFolder) this.form.parent_id = this.activeFolder.id
      this.$v.$reset()
    },
    normalizer (node) {
      const params = {}
      params.id = node.id
      params.label = node.name
      if(params.id == this.form.id) params.isDisabled = true
      params.children =
        node.items && node.items.length != 0 ? node.items : undefined
      return params
    },
    async submit (){
      if(this.$v.$invalid) return this.$v.$touch()
      this.loading = true
      let params  = this.form
      let action  = this.createFolder
      if(this.action == 'delete'){
        action =  this.deleteFolder
      } else{
        if(this.action) params.action = this.action
      }

      action(params)
        .then(() => {
          this.loading = false
          this.$emit('submited')
        }).catch((err) => {
          console.error(err)
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
