<template>
  <b-row>
    <b-col cols="12">
      <b-form-group>
        <b-checkbox v-model="enabled">
          Sticky position
        </b-checkbox>
      </b-form-group>
    </b-col>

    <b-col v-if="enabled">
      <b-form-group label="Offset Top">
        <div class="position-relative">
          <b-input
            v-model="offsetTop"
            class="pr-5"
            type="number"
          />
          <span class="position-absolute right-5 top-1/2 transform -translate-y-1/2">px</span>
        </div>
      </b-form-group>
    </b-col>
    <!--    <b-col>-->
    <!--      <b-form-group label="Translate X">-->
    <!--        <div class="position-relative">-->
    <!--          <b-input v-model="x" class="pr-5" type="number" />-->
    <!--          <span class="position-absolute right-5 top-1/2 transform -translate-y-1/2">px</span>-->
    <!--        </div>-->
    <!--      </b-form-group>-->
    <!--    </b-col>-->
  </b-row>
</template>

<script>
import _ from 'lodash'
import ResolutionMixin from '../../mixins/ResolutionMixin'

export default {
  name: 'StickyControl',
  mixins: [ResolutionMixin],
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  computed: {
    stickyOriginal () {
      return _.get(this.config, ['sticky'], {
        enabled: false,
        offset: {
          top: 0,
          bottom: 0
        }
      })
    },

    enabled: {
      get () {
        return _.get(this.config, ['sticky', 'enabled'], false)
      },
      set (val) {
        this.$set(this.config, 'sticky', {
          ...this.stickyOriginal,
          enabled: val
        })
      }
    },

    offsetTop: {
      get () {
        return _.get(this.config, ['sticky', 'offset', 'top'], 0)
      },
      set (val) {
        this.$set(this.config, 'sticky', {
          ...this.stickyOriginal,
          offset: {
            ...this.stickyOriginal.offset,
            top: val
          }
        })
      }
    }
  }
}
</script>
