import {mapState} from 'vuex'
import _ from 'lodash'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import SidStyles from '@/components/mixins/SidStyles'

export default {
  mixins: [ResolutionMixin, SidStyles],
  props: {
    globalStylePresset: {
      type: Boolean,
      default: false
    },
    componentName: {
      type: String,
      required: true
    },
    groupPrefix: {
      type: String,
      default: ''
    },
    config: {
      type: Object,
      required: true
    },
    showActiveGroup: {
      type: Boolean,
      default: true
    },
    showOthers: {
      type: Boolean,
      default: true
    },
    localSection: {
      type: Object,
      default: null
    },
    localSectionType: {
      type: String,
      default: ''
    },
    hide: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      activeGroup: 0
    }
  },
  computed: {
    ...mapState('editor', {
      pickSubject: state => state.pickSubject,
      allConfig: state => state.config,
      globalSection: state => state.section,
      openedItem: state => state.openedItem,
      resolution: state => state.resolution,
      globalStyles: state => state.globalStyles,
      element: state => state.element,
      tablet: state => state.tablet
    }),
    ...mapState('blogEditor', {
      post: state => state.post
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    temp_id () {
      return this.section.uid
    },
    subjectHide () {
      return this.section.hide || []
    },
    section () {
      return this.localSection || this.globalSection
    },
    uid () {
      return this.section.uid
    },
    resolutionSync: {
      get () {
        return this.resolution
      },
      set (val) {
        this.$store.commit('editor/SET_RESOLUTION', val)
      }
    },
    getOpenedItemObj: {
      get () {
        if (typeof this.openedItem === 'object' && this.openedItem !== null) {
          return this.openedItem
        } else {
          return false
        }
      }
    },
    resolutioner () {
      return this.tablet ? 'md' : this.resolution
    },
    openedItemOptions () {
      return this.$store.state.editor.openedItemOptions || {}
    },
    hoverConfig () {
      if (!this.config.hover) {
        this.$set(this.config, 'hover', {})
      }
      return this.config.hover
    }
  },
  methods: {
    sectionSettings (openedItem = null, options = {}) {
      const _openedItem = options.openedItem || openedItem
      const _section = options.section || this.section

      this.$store.dispatch('editor/sectionSettings', {section: _section, openedItem: _openedItem, options})
    },
    labelCase (label) {
      return _.startCase(label)
    }
  },
  watch: {
    temp_id () {
      this.checkLinked()
    }
  },
  mounted () {
    this.checkLinked()
  }
}
