<template>
  <div>
    <SidbearSettingsGroup
      title="Comments List"
      active
    >
      <sid-checkbox
        unchecked-value="block"
        value="none"
        rule-name="display"
        :sid="`.--${section.sid} .comment-avatar`"
      >
        Hide Avatar
      </sid-checkbox>

      <sid-checkbox
        unchecked-value="block"
        value="none"
        rule-name="display"
        :sid="`.--${section.sid} .comment-user-name`"
      >
        Hide Author Name
      </sid-checkbox>

      <sid-checkbox
        unchecked-value="block"
        value="none"
        rule-name="display"
        :sid="`.--${section.sid} .comment-date`"
      >
        Hide Date
      </sid-checkbox>

      <sid-checkbox
        unchecked-value="block"
        value="none"
        rule-name="display"
        :sid="`.--${section.sid} .total-comments`"
      >
        Hide Total Comments
      </sid-checkbox>

      <sid-checkbox
        unchecked-value="column"
        value="column-reverse"
        rule-name="flex-direction"
        :sid="`.--${section.sid}.comments-container`"
      >
        Swap Comments Box
      </sid-checkbox>

      <b-form-group>
        <b-checkbox v-model="section.options.content.comments.gravatar">
          Gravatar
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <b-checkbox v-model="section.options.content.comments.reply">
          Allow Reply
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <sid-range-slider
          label="Height"
          :sid="`.--${section.sid} .comments-box`"
          rule-name="max-height"
          var="--height"
          reset-value="300px"
          measurement="height"
          default-dimension="px"
          :dimensions="[]"
        />
      </b-form-group>

      <b-form-group>
        <sid-range-slider
          label="Vertical Gap"
          :sid="`.--${section.sid} .comments-box`"
          rule-name="row-gap"
        />
      </b-form-group>

      <sid-background
        :sid="`.--${section.sid} .comments-box`"
      />

      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid} .comments-box`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid} .comments-box`"
        rule-name="margin"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Comment">
      <b-form-group label="Text Color">
        <sid-color
          :sid="`.--${section.sid} .comments-box .comment-text`"
          rule-name="color"
        />
      </b-form-group>

      <sid-range-slider
        label="Font Size"
        :sid="`.--${section.sid} .comments-box .comment-text`"
        rule-name="font-size"
        default-dimension="px"
        :dimensions="[]"
      />

      <sid-font :sid="`.--${section.sid} .comments-box .comment-text`" />

      <sid-background
        :sid="`.--${section.sid} .comments-box .comment-text`"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid} .comments-box .comment-text`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid} .comments-box .comment-text`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          css-var="--border-size"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid} .comments-box .comment-text`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid} .comments-box .comment-text`"
        rule-name="border"
        :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
        :angles="[
          {
            text: '',
            value: 'top-left-radius',
            cssVar: '--border-top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius',
            cssVar: '--border-top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius',
            cssVar: '--border-bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius',
            cssVar: '--border-bottom-right-radius'
          }
        ]"
      />

      <b-form-group
        label="Shadow"
      >
        <sid-box-shadow
          :sid="`.--${section.sid} .comments-box .comment-text`"
          rule-name="box-shadow"
        />
      </b-form-group>

      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid} .comments-box .comment-text`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Author">
      <b-form-group label="Text Color">
        <sid-color
          :sid="`.--${section.sid} .comments-box .comment-user-name`"
          rule-name="color"
        />
      </b-form-group>

      <sid-range-slider
        label="Font Size"
        :sid="`.--${section.sid} .comments-box .comment-user-name`"
        rule-name="font-size"
        default-dimension="px"
        :dimensions="[]"
      />

      <sid-font :sid="`.--${section.sid} .comments-box .comment-user-name`" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Date">
      <b-form-group label="Text Color">
        <sid-color
          :sid="`.--${section.sid} .comments-box .comment-date`"
          rule-name="color"
        />
      </b-form-group>

      <sid-range-slider
        label="Font Size"
        :sid="`.--${section.sid} .comments-box .comment-date`"
        rule-name="font-size"
        default-dimension="px"
        :dimensions="[]"
      />

      <sid-font :sid="`.--${section.sid} .comments-box .comment-date`" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Total Comments">
      <b-form-group label="Text Color">
        <sid-color
          :sid="`.--${section.sid} .total-comments`"
          rule-name="color"
        />
      </b-form-group>

      <sid-range-slider
        label="Font Size"
        :sid="`.--${section.sid} .total-comments`"
        rule-name="font-size"
        default-dimension="px"
        :dimensions="[]"
      />

      <sid-font :sid="`.--${section.sid} .total-comments`" />

      <sid-align
        label="Text Align"
        rule-name="text-align"
        :sid="`.--${section.sid} .total-comments`"
      />

      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid} .total-comments`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid} .total-comments`"
        rule-name="margin"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Reply Button">
      <rule-tabs>
        <template #Normal>
          <b-form-group label="Text Color">
            <sid-color
              :sid="`.--${section.sid} .comment-reply-button`"
              rule-name="color"
            />
          </b-form-group>
        </template>

        <template #Hover>
          <b-form-group label="Text Color">
            <sid-color
              :sid="`.--${section.sid} .comment-reply-button:hover`"
              rule-name="color"
              presudo=":hover"
            />
          </b-form-group>
        </template>
      </rule-tabs>

      <sid-range-slider
        label="Font Size"
        :sid="`.--${section.sid} .comment-reply-button`"
        rule-name="font-size"
        default-dimension="px"
        :dimensions="[]"
      />

      <sid-font :sid="`.--${section.sid} .comment-reply-button`" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Fields">
      <sid-range-slider
        rule-name="width"
        :sid="`.--${section.sid}.comments-container input.comment-field`"
        default-dimension="px"
        :dimensions="['%', 'vw', 'px']"
        label="Input Width"
        var="--width"
        measurement="width"
      />

      <sid-checkbox
        :sid="`.--${section.sid} .guest-details`"
        rule-name="flex-wrap"
        value="nowrap"
      >
        No Wrap
      </sid-checkbox>

      <sid-align
        :sid="`.--${section.sid} .guest-details`"
        rule-name="justify-content"
        label="Alignment"
      />

      <b-form-group label="Text Color">
        <sid-color
          :sid="`.--${section.sid}.comments-container .comment-field`"
          rule-name="color"
        />
      </b-form-group>

      <b-form-group label="Placeholder Color">
        <sid-color
          :sid="`.--${section.sid}.comments-container .comment-field::placeholder`"
          rule-name="color"
        />
      </b-form-group>

      <sid-range-slider
        label="Font Size"
        :sid="`.--${section.sid}.comments-container .comment-field`"
        rule-name="font-size"
        default-dimension="px"
        :dimensions="[]"
      />

      <sid-font :sid="`.--${section.sid}.comments-container .comment-field`" />

      <sid-background
        :sid="`.--${section.sid}.comments-container .comment-field`"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}.comments-container .comment-field`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}.comments-container .comment-field`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          css-var="--border-size"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}.comments-container .comment-field`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid}.comments-container .comment-field`"
        rule-name="border"
        :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
        :angles="[
          {
            text: '',
            value: 'top-left-radius',
            cssVar: '--border-top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius',
            cssVar: '--border-top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius',
            cssVar: '--border-bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius',
            cssVar: '--border-bottom-right-radius'
          }
        ]"
      />

      <b-form-group
        label="Shadow"
      >
        <sid-box-shadow
          :sid="`.--${section.sid}.comments-container .comment-field`"
          rule-name="box-shadow"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Button">
      <rule-tabs>
        <template #Normal>
          <b-form-group label="Button Text">
            <b-input v-model="section.options.content.comments.btnText" />
          </b-form-group>

          <b-form-group label="Text Color">
            <sid-color
              :sid="`.--${section.sid}.comments-container button`"
              rule-name="color"
            />
          </b-form-group>

          <sid-range-slider
            label="Font Size"
            :sid="`.--${section.sid}.comments-container button`"
            rule-name="font-size"
            default-dimension="px"
            :dimensions="[]"
          />

          <sid-font :sid="`.--${section.sid}.comments-container button`" />

          <sid-background
            :sid="`.--${section.sid}.comments-container button`"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}.comments-container button`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}.comments-container button`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}.comments-container button`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}.comments-container button`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />

          <b-form-group
            label="Shadow"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}.comments-container button`"
              rule-name="box-shadow"
            />
          </b-form-group>

          <sid-linked-group
            label="Padding"
            :sid="`.--${section.sid}.comments-container button`"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>

        <template #Hover>
          <b-form-group label="Text Color">
            <sid-color
              :sid="`.--${section.sid}.comments-container button`"
              rule-name="color"
              presudo=":hover"
            />
          </b-form-group>

          <sid-background
            :sid="`.--${section.sid}.comments-container button`"
            presudo=":hover"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}.comments-container button`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}.comments-container button`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=":hover"
              css-var="--border-size"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}.comments-container button`"
                rule-name="border-color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <b-form-group
            label="Shadow"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}.comments-container button`"
              rule-name="box-shadow"
              presudo=":hover"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}.comments-container`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}.comments-container`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'

export default {
  name: 'CommentsMod',
  components: {
    SidAlign,
    RuleTabs,
    SidFont,
    SidColor,
    SidBoxShadow,
    SidSelect,
    SidLinkedGroup, SidBackground, SidCheckbox, SidRangeSlider, SidbearSettingsGroup},
  mixins: [ModMixin]
}
</script>