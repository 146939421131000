<template>
  <highliter
    v-model="section.name"
    :class="{'element-hidden': !visability('menu')}"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('menu')"
  >
    <template slot="section-type-icon">
      <svg
        fill="none"
        height="16"
        viewBox="0 0 21 16"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9777 14.625H6.27266C5.70781 14.625 5.25 14.2333 5.25 13.75C5.25 13.2667 5.70781 12.875 6.27266 12.875H19.9773C20.5422 12.875 21 13.2667 21 13.75C21 14.2333 20.5425 14.625 19.9777 14.625Z"
          fill="black"
        />
        <path
          d="M19.9777 8.5H6.27266C5.70781 8.5 5.25 8.10829 5.25 7.625C5.25 7.14171 5.70781 6.75 6.27266 6.75H19.9773C20.5422 6.75 21 7.14171 21 7.625C21.0003 8.10829 20.5425 8.5 19.9777 8.5Z"
          fill="black"
        />
        <path
          d="M19.9777 2.375H6.27266C5.70781 2.375 5.25 1.98329 5.25 1.5C5.25 1.01671 5.70781 0.625 6.27266 0.625H19.9773C20.5422 0.625 21 1.01671 21 1.5C21 1.98329 20.5425 2.375 19.9777 2.375Z"
          fill="black"
        />
        <circle
          cx="1.3125"
          cy="1.0625"
          fill="black"
          r="1.3125"
        />
        <circle
          cx="1.3125"
          cy="8.0625"
          fill="black"
          r="1.3125"
        />
        <circle
          cx="1.3125"
          cy="14.1875"
          fill="black"
          r="1.3125"
        />
      </svg>
    </template>

    <Template :section="section">
      <ul
        :class="align"
        class="list-unstyled list-inline mb-0 pl-0 w-100 flex-wrap"
      >
        <li
          v-for="(link, index) in section.options.content.menu.links"
          :key="index"
          :class="[{'list-inline-item mx-3': !section.options.content.menu.column[resolution]}]"
        >
          <a
            :style="section.options.content.menu.style"
            href="javascript:;"
          >
            {{ link.text }}
          </a>
        </li>
      </ul>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import RouterMixin from '../../../mixins/RouterMixin'

export default {
  name: 'FeaturedItems1',

  components: {Template, Highliter},

  mixins: [SectionMixin, RouterMixin],

  computed: {
    align () {
      if (this.section.options.content.menu.column[this.resolution]) return `text-${this.section.options.content.menu.align}`

      const align = {
        left: '',
        center: 'justify-content-center',
        right: 'justify-content-end',
        justify: 'justify-content-between'
      }

      return `d-flex ${align[this.section.options.content.menu.align]}`
    }
  }
}
</script>
