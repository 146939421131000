<template>
  <b-form-group :label="label">
    <div class="flex items-center btn-group">
      <button
        v-for="(option, index) in options"
        :key="index"
        v-b-tooltip.hover
        :class="{'!bg-[#F2F4F5]': sidValueMutation === (option.default || option.value)}"
        class="btn flex justify-center !transition items-center h-[29px] !px-[10px] py-0 text-[12px] !font-[600] border rounded-[4px] !text-[#44474A] hover:!bg-[#F2F4F5]"
        :title="option.tooltip"
        @click="() => apply(option)"
      >
        <img
          v-if="option.svg"
          :src="option.svg"
          alt="icon"
        >
        <span
          v-else
          v-text="option.text"
        />
      </button>
    </div>
  </b-form-group>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'

export default {
  name: 'SidButtonsGroup',
  mixins: [SidConcept],
  props: {
    label: {
      type: String
    },
    options: {
      type: Array,
      required: true
    }
  },
  mounted () {
    this.$emit('mount', this.sidValueMutation)
  },
  methods: {
    apply (option) {
      if (option.customProperties) {
        return this.addSidRules(option.customProperties)
      }

      this.addSidRules([[this.getSid(), [this.ruleName, this.sidValueMutation === option.value  ? '' : option.value]]])
      this.$emit('change', this.sidValueMutation)
    }
  }
}
</script>