<template>
  <div>
    <div
      ref="form"
      class="mb-[28px]"
    >
      <label
        class="pb-0 flex font-[700] text-[15px]] items-center hover:opacity-80 justify-center !bg-[#3D82EA] rounded-[3px] text-white h-[42px] w-[146px] gap-[9px] mb-[5px]"
        :class="loading ? 'opacity-80' : 'cursor-pinter'"
      >
        <b-spinner
          v-if="loading"
          small
          variant="white"
        />
        Upload
        <svg
          width="17"
          height="15"
          viewBox="0 0 17 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.49967 1.83335C6.54367 1.83335 4.95801 3.41901 4.95801 5.37502C4.95801 5.76622 4.64088 6.08335 4.24967 6.08335C2.98427 6.08335 2.12467 6.96207 2.12467 7.85419C2.12467 8.7463 2.98427 9.62502 4.24968 9.62502C4.64088 9.62502 4.95801 9.94215 4.95801 10.3334C4.95801 10.7246 4.64088 11.0417 4.24968 11.0417C2.38547 11.0417 0.708008 9.70048 0.708008 7.85419C0.708008 6.23134 2.00401 4.99872 3.58368 4.72414C3.90241 2.29332 5.98189 0.416687 8.49967 0.416687C11.0175 0.416687 13.0969 2.29332 13.4157 4.72414C14.9953 4.99872 16.2913 6.23134 16.2913 7.85419C16.2913 9.70048 14.6139 11.0417 12.7497 11.0417C12.3585 11.0417 12.0413 10.7246 12.0413 10.3334C12.0413 9.94215 12.3585 9.62502 12.7497 9.62502C14.0151 9.62502 14.8747 8.7463 14.8747 7.85419C14.8747 6.96207 14.0151 6.08335 12.7497 6.08335C12.3585 6.08335 12.0413 5.76622 12.0413 5.37502C12.0413 3.41901 10.4557 1.83335 8.49967 1.83335Z"
            fill="white"
          />
          <path
            d="M11.1255 8.70922C10.8489 8.98584 10.4004 8.98584 10.1238 8.70922L9.20801 7.79342V13.875C9.20801 14.2662 8.89088 14.5834 8.49967 14.5834C8.10847 14.5834 7.79134 14.2662 7.79134 13.875V7.79342L6.87554 8.70922C6.59892 8.98584 6.15043 8.98584 5.87381 8.70922C5.59719 8.4326 5.59719 7.98411 5.87381 7.70749L7.99881 5.58249C8.13165 5.44965 8.31181 5.37502 8.49967 5.37502C8.68754 5.37502 8.8677 5.44965 9.00054 5.58249L11.1255 7.70749C11.4022 7.98411 11.4022 8.4326 11.1255 8.70922Z"
            fill="white"
          />
        </svg>

        <b-form-file
          v-show="false"
          v-model="files"
          multiple
          accept=".ttf, .otf, .eot, .woff, .woff2"
          :disabled="loading"
          @change="onChange"
        />
      </label>
      <div class="text-[14px] text-[#6E747A] leading-[26px]">
        Upload custom font file(s)
      </div>
    </div>

    <div
      v-if="fileData.length"
    >
      <transition-group
        name="transition"
        class="flex flex-column w-full gap-[12px] mb-[34px]"
      >
        <uploaded-font
          v-for="(file, index) in fileData"
          :key="file.tmp_file"
          :file="file"
          :loading="loading"
          @cancel="() => remove(index)"
          @uploading="isLoading => loading = isLoading"
          @uploaded="uploaded"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import UploadedFont from '@/views/dashboard/fonts/custom-fonts/UploadedFont'
import {mapState} from 'vuex'

export default {
  name: 'UploadCustomFont',
  components: {UploadedFont},
  data () {
    return {
      files: [],
      loading: false,
      fileData: []
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  beforeDestroy () {
    let $styleEl = document.head.querySelector('#custom-font-loader')
    if ($styleEl) $styleEl.remove()
  },
  mounted () {
    this.setFontStyles()
  },
  methods: {
    uploaded () {
      this.$store.dispatch('font/fetchCustomFonts')
      this.setFontStyles()
    },
    setFontStyles () {
      this.$store.dispatch('font/fetchCustomFontsCSS')
        .then((css) => {
          let $styleEl = document.head.querySelector('#custom-font-loader')

          if ($styleEl) $styleEl.remove()

          $styleEl = document.createElement('style')
          $styleEl.type = 'text/css'
          $styleEl.id = 'custom-font-loader'
          $styleEl.innerHTML = css
          document.head.appendChild($styleEl)
        })
    },
    remove (index) {
      this.fileData.splice(index, 1)
    },
    onSubmit () {
      const formData = new FormData()
      this.files.forEach(file => formData.append('fonts[]', file))

      this.loading = true
      axios.post(`api/projects/${this.project.id}/fonts/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(({data}) => {
          this.fileData = data.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    onChange () {
      setTimeout(() => this.onSubmit())
    }
  }
}
</script>