<template>
  <div>
    <template v-if="section.options.version === 2">
      <draggable
        v-model="config.subjects"
        handle=".move"
      >
        <transition-group name="shufle">
          <SidbearSettingsGroup
            v-for="(image, index) in config.subjects"
            :key="`index-${index}`"
            :active="getActiveImage === index"
            :title="`${image.id} Image`"
            content-class="pb-0"
            move
          >
            <component
              :is="image.options.content.image.type"
              :config="image.options.content.image"
              :hide="['scale', 'align']"
              :local-section="image"
              component-group-name="div"
              component-name="Image"
            />
          </SidbearSettingsGroup>
        </transition-group>
      </draggable>
    </template>

    <div
      v-else
      class="pt-[9px]"
    >
      <b-form-group
        v-for="(image, index) in config.items"
        :key="index"
      >
        <div class="d-flex align-items-center">
          <image-upload
            :image="image"
            class="w-100 mb-[14px]"
            @uploaded="$store.dispatch('editor/sectionSettings', {section: section, openedItem: {item: 'image', index}})"
          >
            <template slot="prepend">
              <div
                :class="{'text-[#3D82EA]': getActiveImage === index}"
                class="border text-[14px] font-[600] h-[38px] w-[38px] flex items-center justify-center rounded-left mr-[-2px] bg-white z-index-10000000"
                v-text="index + 1"
              />
            </template>
          </image-upload>
        </div>

        <b-form-group label="Opacity">
          <vue-slider
            v-model="config.items[index]['opacity']"
            :dot-size="17"
            :interval="0.1"
            :max="1.0"
            :min="0"
            class="editor-slider-2"
            tooltip="none"
          />
        </b-form-group>
      </b-form-group>

      <SidbearSettingsGroup
        class="border-top"
        title="Drop Shadow"
      >
        <shadow-customs :config="config" />
      </SidbearSettingsGroup>
    </div>

    <SidbearSettingsGroup>
      <template slot="title">
        <span>Padding & Margins <span class="small text-muted ml-1">(Main)</span></span>
      </template>
      <padding-full-customs :config="section.options.customize.style.padding" />
      <margin-full-customs :config="margin" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ModMixin from '@builder/components/mixins/ModMixin'
import ImageUpload from '../utils/ImageUpload'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'

export default {
  name: 'ImagesMod',
  components: {draggable, SidbearSettingsGroup, ImageUpload},
  mixins: [ModMixin],
  computed: {
    margin () {
      if (!this.section.options.customize.style.margin) {
        this.$set(this.section.options.customize.style, 'margin', {})
      }

      return this.section.options.customize.style.margin
    },
    getActiveImage () {
      if (this.config.subjects) {
        this.config.subjects.forEach((image, index) => {
          if (!image.id) {
            this.$set(image, 'id', index + 1)
          }
        })
      }

      if (this.getOpenedItemObj) {
        return this.getOpenedItemObj.index
      }

      return 0
    }
  }
}
</script>
