<template>
  <div>
    <SidbearSettingsGroup
      title="Thumbnail"
      active
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <b-form-group label="Related Post">
        <tags-select
          v-model="relatedPosts"
          label="title"
          :prepend-options="[{slug: 'most-recent-post', title: 'most recent post', id: 'most-recent-post'}]"
          :reduce="val => val.slug"
          :url="`https://blog-api.estage.com/api/projects/${project.id}/blog/manage/posts`"
        />
      </b-form-group>

      <sid-range-slider
        rule-name="height"
        :sid="className(' .img')"
        default-dimension="%"
        :dimensions="['%', 'vw', 'px']"
        label="Image Height"
        var="--height"
        measurement="height"
      />

      <sid-range-slider
        rule-name="width"
        :sid="className(' .img')"
        default-dimension="%"
        :dimensions="['%', 'vw', 'px']"
        label="Image Width"
        var="--width"
        measurement="width"
      />

      <b-form-group
        label="Image Position"
      >
        <sid-select
          :sid="className(' .img')"
          rule-name="object-position"
          :searchable="false"
          :options="[{label: 'Top', value: 'top'}, {label: 'Center', value: 'center'}, {label: 'Right', value: 'right'}, {label: 'Left', value: 'left'}, {label: 'Bottom', value: 'bottom'}]"
        />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Display Setting</label>

          <sid-select
            :sid="className(' .img')"
            rule-name="display"
            :searchable="false"
            class="w-[100px]"
            :options="[
              {
                label: 'Block',
                value: ''
              },
              {
                label: 'Inline',
                value: 'inline-flex',
              }
            ]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Position</label>

          <sid-select
            :sid="className(' .img')"
            rule-name="position"
            :searchable="false"
            class="w-[138px]"
            :options="[
              {
                label: 'Static',
                value: ''
              },
              {
                label: 'Fixed',
                value: 'fixed',
              },
              {
                label: 'Absolute',
                value: 'absolute',
              }
            ]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Z-Index</label>

          <sid-input
            :sid="className(' .img')"
            rule-name="z-index"
            default-dimension=""
            class="!w-[59px]"
          />
        </div>
      </b-form-group>

      <sid-flip
        :sid="className(' .img')"
        rule-name="scale"
      />

      <sid-align
        :sid="className()"
        rule-name="justify-content"
        label="Alignment"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Filters">
      <rule-tabs>
        <template #Normal>
          <sid-background-filters
            :sid="className(' .img')"
            rule-name="filter"
          />

          <sid-range-slider
            label="Opacity"
            :sid="className(' .img')"
            rule-name="opacity"
            :default-dimension="''"
            :dimensions="[]"
            :min="0"
            :max="1"
            :step="0.1"
          />
        </template>

        <template #Hover>
          <sid-checkbox
            value=".3s"
            unchecked-value=""
            rule-name="transition"
            var="--transition"
            :sid="className(' .img')"
          >
            Transition <span
              class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
            >BETA</span>
          </sid-checkbox>
          
          <sid-background-filters
            :sid="className(' .img')"
            rule-name="filter"
            presudo=":hover"
          />

          <sid-range-slider
            label="Opacity"
            :sid="className(' .img')"
            rule-name="opacity"
            :default-dimension="''"
            :dimensions="[]"
            :min="0"
            presudo=":hover"
            :max="1"
            :step="0.1"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Mask">
      <sid-background-mask :sid="className(' .img')" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Transform">
      <rule-tabs>
        <template #Normal>
          <sid-transform
            :sid="className(' .img')"
            rule-name="transform"
            :section="section"
          />

          <sid-translate
            :sid="className()"
            rule-name="translate"
          />
        </template>

        <template #Hover>
          <sid-transform
            :sid="className(' .img')"
            rule-name="transform"
            presudo=":hover"
            :section="section"
          />

          <sid-translate
            :sid="className()"
            presudo=":hover"
            rule-name="translate"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <sidbear-settings-group title="Border & Radius">
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="className(' .img')"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="className(' .img')"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="className(' .img')"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="className(' .img')"
        rule-name="border"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </sidbear-settings-group>

    <sidbear-settings-group title="Drop Shadow">
      <b-form-group
        label="Shadow Color"
        class="mb-0"
      >
        <sid-box-shadow
          :sid="className(' .img')"
          rule-name="box-shadow"
        />
      </b-form-group>
    </sidbear-settings-group>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import TagsSelect from '@/components/editor/components/TagsSelect.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidFlip from '@/components/editor/components/sid-controls/SidFlip.vue'
import SidInput from '@/components/editor/components/sid-controls/SidInput.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidTransform from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTransform.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidBackgroundFilters
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundFilters.vue'
import SidBackgroundMask
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundMask.vue'
import SidTranslate from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTranslate.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox.vue'

export default {
  name: 'FeaturedPostMod',
  components: {
    SidCheckbox,
    SidColor,
    SidTranslate, SidBackgroundMask, SidBackgroundFilters, RuleTabs, SidTransform,
    SidRangeSlider, SidAlign, SidInput, SidFlip,
    SidLinkedGroup,
    SidBoxShadow,
    SidSelect,
    SidbearSettingsGroup,
    TagsSelect},
  mixins: [ModMixin],
  computed: {
    relatedPosts: {
      get () {
        return !this.section.options.content.data.post ? 'most recent post' : this.section.options.content.data.post
      },
      set (val) {
        this.$set(this.section.options.content.data, 'post', val)
      }
    }
  },
  methods: {
    className (name = '') {
      return `.--post-featured-thumb-${this.section.sid}${name}`
    }
  }
}
</script>