import router from '@builder/router/index'
import moment from 'moment-timezone'
import _ from 'lodash'
import {applyPageStyles, pageStylesToString} from '@/utils/useStylesheet'

const defaultLayout = () => {
  return {
    id: 2,
    preview: require('@builder/assets/images/blog-layout-1.png'),
    static: {
      top: {
        component: 'BlogPostLayout1'
      },
      left: null,
      right: null,
      bottom: null
    },
    styles: {
      'font': {
        'style': null,
        'h1': {
          'fontFamily': 'Proxima Nova',
          'fontSize': '23px'
        },
        'h2': {
          'fontFamily': 'Proxima Nova',
          'fontSize': '23px'
        },
        'h3': {
          'fontFamily': 'Proxima Nova',
          'fontSize': '16px'
        },
        'body': {
          'fontFamily': 'Proxima Nova',
          'fontSize': '16px'
        },
        'loader': [
          'Roboto'
        ]
      },
      'colors': {
        'primary': '#3D82EA',
        'accent': '#B83636',
        'siteBackground': '#ffffff',
        'buttons': '#3D82EA'
      },
      'buttons': {
        'shape': null,
        'outlined': false
      },
      'paragraph': {
        'font': {
          'sm': 'Roboto',
          'lg': 'Roboto'
        },
        'fontSize': {
          'sm': '16px',
          'lg': '18px'
        },
        'align': {
          'sm': 'left',
          'lg': 'left'
        },
        'bold': {
          'sm': false,
          'lg': false
        },
        'italic': {
          'sm': false,
          'lg': false
        },
        'underline': {
          'sm': false,
          'lg': false
        },
        'lineHeight': {
          sm: '1.5',
          lg: '2.2'
        },
        'fontWeight': '300',
        'color': 'rgb(68,71,74)'
      },
      'headline': {
        'font': {
          'sm': 'Roboto',
          'lg': 'Roboto'
        },
        'fontSize': {
          'sm': '25px',
          'lg': '25px'
        },
        'align': {
          'sm': 'left',
          'lg': 'left'
        },
        'bold': {
          'sm': false,
          'lg': false
        },
        'italic': {
          'sm': false,
          'lg': false
        },
        'underline': {
          'sm': false,
          'lg': false
        },
        'color': 'rgba(0, 0, 0, 1)'
      }
    }
  }
}

const theme = (blogSettings) => {
  return _.get(blogSettings, 'layout.theme', false) ? 'dark' : 'light'
}

const postDraft = () => {
  return {
    title: '',
    categories: [],
    preview: {
      display: true,
      type: 'image-mod',
      size: 'cover',
      position: 'center',
      placeholder: require('@builder/assets/images/placeholder.png'),
      src: null,
      ratio: {
        type: 'vh',
        sm: {
          h: 20,
          w: 50
        },
        lg: {
          h: 40,
          w: 50
        }
      }
    },
    fromDate: null,
    show_author: 1,
    show_date: 1,
    author_id: null,
    'show_categories': 1,
    author: {
      name: 'Default User',
      avatar: null
    },
    author_settings: {
      show: true
    },
    social_settings: {
      show_likes: true,
      allow_comments: true,
      allow_share: true
    },
    sections: {
      title: {'uid':'iVcCnwQTg','display':true,'name':'Headline','sid':'70wn1bm--3Cgoy1JJOO','hideFromSections':false,'component':'ElHeadlineV2','group':'Text','layout':null,'options':{'content':{'description':{'type':'tiptap-mod-v2','display':true,'tag':'h3','resolutionStyle':{'sm':{},'md':{},'lg':{}},'text':'<p>THIS IS YOUR COMPELLING BLOG POST </p><p>HEADLINE</p>','animations':{'animation':null,'once':true,'offset':200,'delay':50}}}},'editor':'blog','temp_id':'4DpwnmAWx','css':[['.--70wn1bm--3Cgoy1JJOO-text { padding: 20px; }','.--70wn1bm--3Cgoy1JJOO-text div { font-size: 42px; color: rgb(0, 0, 0); }'],[],[]],'sids':['70wn1bm--3Cgoy1JJOO']},
      sections: [
        {'uid':'ZOz6L6S26','display':true,'name':'Block','css':[['.--70wn1bm--FqT6IVybjT--flex-col-el { width: 100%; }'],[],[]],'sid':'70wn1bm--YP-hF9A2YD','sids':['70wn1bm--YP-hF9A2YD','70wn1bm--uHGdpCtUG2','70wn1bm--FqT6IVybjT'],'hideFromSections':false,'component':'BlankV2','group':'empty-blocks','layout':null,'options':{'content':{'items':{'type':null,'children':[{'display':true,'name':'Flex Wrapper','hideFromSections':false,'component':'ElFlexWrapperV2','group':'','layout':null,'options':{'content':{'display':true,'cols':[{'display':true,'name':'Flex Column','elements':[],'sortIndex':{'lg':0,'md':0,'sm':0},'uid':'WZAR2gSEyt','sid':'70wn1bm--FqT6IVybjT'}]}},'uid':'gYE6q75kU','sid':'70wn1bm--uHGdpCtUG2','temp_id':'qZRnwL3HYL'}]}},'backgroundImage':{'src':''},'backgroundVideo':{'enabled':false,'url':'','loop':true,'ratio':1}},'render_id':'Lbj1ualDgd','temp_id':'vIMYmwlzpn'}
      ]
    },
    description: '',
    post_date: null
  }
}

const state = () => ({
  post: {
    preview: {},
    categories: [],
    seo_description: '',
    social_settings: {
      allow_comments: true
    }
  },
  blogTemplate: {},
  layouts: [
    {
      id: 1,
      preview: require('@builder/assets/images/blog-layout-blank.png'),
      static: {
        top: {
          component: 'BlogPostLayout1'
        },
        left: null,
        right: null,
        bottom: null
      },
      styles: {
        'colors': {
          'primary': 'rgb(31, 59, 156)',
          'accent': '#B83636',
          'siteBackground': '#ffffff',
          'buttons': 'rgb(252, 176, 69)'
        },
        'font': {
          'style': null,
          'h1': {
            'fontFamily': 'Proxima Nova',
            'fontSize': '23px'
          },
          'h2': {
            'fontFamily': 'Proxima Nova',
            'fontSize': '23px'
          },
          'h3': {
            'fontFamily': 'Proxima Nova',
            'fontSize': '16px'
          },
          'body': {
            'fontFamily': 'Proxima Nova',
            'fontSize': '16px'
          },
          'loader': [
            'Roboto'
          ]
        },
        'buttons': {
          'shape': null,
          'outlined': false
        },
        'paragraph': {
          'font': {
            'sm': 'Roboto',
            'lg': 'Roboto'
          },
          'fontSize': {
            'sm': '16px',
            'lg': '18px'
          },
          'align': {
            'sm': 'center',
            'lg': 'center'
          },
          'bold': {
            'sm': false,
            'lg': false
          },
          'italic': {
            'sm': false,
            'lg': false
          },
          'underline': {
            'sm': false,
            'lg': false
          },
          'color': 'rgb(68, 71, 74)'
        },
        'headline': {
          'font': {
            'sm': 'Roboto',
            'lg': 'Roboto'
          },
          'fontSize': {
            'sm': '36px',
            'lg': '48px'
          },
          'align': {
            'sm': 'center',
            'lg': 'center'
          },
          'bold': {
            'sm': false,
            'lg': false
          },
          'italic': {
            'sm': false,
            'lg': false
          },
          'underline': {
            'sm': false,
            'lg': false
          },
          'color': '#0C226C'
        }
      },
      sections: []
    },
    defaultLayout
  ],
  submit: false
})

const getters = {}

const actions = {
  selectLayout ({state}, layout) {
    state.post.layout = layout
  },
  async publishPost ({rootState, state, dispatch}) {
    return dispatch('font/prepareFonts', state.post.sections, {root: true})
      .then(async (fonts) => {
        return await axios.post(`api/projects/${rootState.projects.project.id}/blog/manage`, {
          author_id: state.post.author_id,
          show_author: state.post.show_author,
          ...state.post.social_settings,
          show_categories: state.post.show_categories,
          seo_title: state.post.seo_title || state.post.title,
          seo_description: state.post.seo_description || state.post.description,
          code_injection: state.post.code_injection || state.post.code_injection,
          seo_keywords: state.post.seo_keywords,
          'cover': JSON.stringify(state.post.preview),
          'title': state.post.title,
          'description': state.post.description,
          content: JSON.stringify(state.post.sections),
          categories: state.post.categories,
          'status': 'publish',
          sections: state.post.sections,
          password_id: state.post.password_id || null,
          post_date: state.post.post_date ? moment.tz(state.post.post_date, Intl.DateTimeFormat().resolvedOptions().timeZone).tz('Europe/London').format('YYYY-MM-DD HH:mm') : moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).tz('Europe/London').format('YYYY-MM-DD HH:mm'),
          google_fonts: fonts.googleFonts,
          css: pageStylesToString('subject-styles')
        })
      })
  },
  async editPost ({rootState, state, dispatch}, status = 'publish') {
    return dispatch('font/prepareFonts', state.post.sections, {root: true})
      .then(async (fonts) => {
        return await axios.put(`api/projects/${rootState.projects.project.id}/blog/manage/${state.post.id}`, {
          ...state.post,
          status: status,
          status_code: status,
          content: JSON.stringify(state.blogTemplate.content),
          css: pageStylesToString('subject-styles'),
          fonts: fonts.googleFonts + fonts.customFonts
        })
      })
  },
  async draftPost ({rootState, state}) {
    return await axios.post(`api/projects/${rootState.projects.project.id}/blog/manage`, {
      author_id: state.post.author_id,
      show_author: state.post.show_author,
      ...state.post.social_settings,
      show_categories: state.post.show_categories,
      seo_title: state.post.seo_title || state.post.title,
      seo_description: state.post.seo_description || state.post.description,
      code_injection: state.post.code_injection || state.post.code_injection,
      seo_keywords: state.post.seo_keywords,
      'cover': JSON.stringify(state.post.preview),
      'title': state.post.title,
      'description': state.post.description,
      content: JSON.stringify(state.post.sections),
      categories: state.post.categories,
      'status': 'draft',
      sections: state.post.sections,
      password_id: state.post.password_id || null,
      post_date: state.post.post_date ? moment.tz(state.post.post_date, Intl.DateTimeFormat().resolvedOptions().timeZone).tz('Europe/London').format('YYYY-MM-DD HH:mm') : moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).tz('Europe/London').format('YYYY-MM-DD HH:mm'),
      css: pageStylesToString('subject-styles')
    })
  },
  async postDelete ({rootState}, post) {
    return await axios.delete(`api/projects/${rootState.projects.project.id}/blog/manage/${post.id}`)
  },
  async startNewPost ({commit, dispatch, rootState}) {
    applyPageStyles('@media screen {}\n' +
      '    @media (max-width: 1100px) {}\n' +
      '    @media (max-width: 520px) {}', 'subject-styles')

    await dispatch('blogSettings/loadSettings', null, {root: true})
      .then(() => {
        setTimeout(() => {
          commit('EDIT_POST', {
            ...JSON.parse(JSON.stringify(postDraft(theme(rootState.blogSettings.settings)))),
            social_settings: {
              show_date: 1,
              show_likes: rootState.blogSettings.settings.display_like_button,
              allow_comments: 1,
              allow_sharing: rootState.blogSettings.settings.display_social_share
            }
          })

          router.push({name: 'editor.post', params: {post_id: 'new-post'}})
        })
      })
  },
  startEdit ({commit, dispatch, rootState}, post) {
    if (post.id === 'new-post') {
      return dispatch('startNewPost')
    }

    dispatch('blogSettings/loadSettings', null, {root: true})
      .then(() => {
        VEvent.fire('loader', true)
        axios.get(`api/projects/${rootState.projects.project.id}/blog/manage/${post.id}`)
          .then(({data}) => {
            const post = data.data
            applyPageStyles(post.css, 'subject-styles')

            commit('EDIT_POST', {
              ...JSON.parse(JSON.stringify(postDraft(theme(rootState.blogSettings.settings)))),
              ...post,
              'preview': JSON.parse(post.cover),
              'title': post.title,
              'sections': JSON.parse(post.content),
              'fromDate': post.created_at,
              'status': post.status_code,
              password_id: post.password_id || null,
              categories: post.categories,
              social_settings: {
                show_date: post.show_date,
                show_likes: post.show_likes,
                allow_comments: post.allow_comments,
                allow_sharing: post.allow_sharing
              },
              post_date: moment.tz(post.post_date, 'Europe/London').tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm')
            })

            router.push({name: 'editor.post', params: {post_id: post.id}})
          })
          .finally(() => {
            VEvent.fire('loader', false)
          })
      })
  }
}

const mutations = {
  EDIT_POST (state, payload) {
    state.post = payload
  },
  EDIT_BLOG_TEMPLATE (state, payload) {
    state.blogTemplate = payload
  },
  SET_SECTIONS (state, payload) {
    state.post.sections.sections = payload
  }
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
