<template>
  <div class="bg-white">
    <div class="dashboard-container">
      <div class="dashboard-topbar d-flex align-items-center bg-white">
        <h2
          class="dashboard-topbar-title mb-0"
          v-text="title"
        />

        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardTopbar',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.dashboard-topbar {
  padding-top: 58px;
  padding-bottom: 58px;
}

.dashboard-topbar-title {
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 29px;
}
</style>