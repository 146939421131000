import { Mark } from 'tiptap'
import { updateMark, removeMark } from 'tiptap-commands'

export default class TextGradientColor extends Mark {

  get name () {
    return 'textgradientcolor'
  }

  get defaultOptions () {
    return {
      backgroundImage: ['red'],
      raw: []
    }
  }

  get schema () {
    return {
      attrs: {
        raw: {
          default: []
        },
        backgroundImage: {
          default: 'red'
        }
      },
      parseDOM: this.options.backgroundImage.map(backgroundImage => ({
        tag: 'gradient-span',
        attrs: { backgroundImage },
        getAttrs (dom) {
          return {
            backgroundImage: dom.style.backgroundImage,
            raw: dom.getAttribute('dataraw')
          }
        }
      })),
      toDOM:
        node => {
          return ['gradient-span', {
            style: `background-image:${node.attrs.backgroundImage}`,
            dataRaw: node.attrs.raw
          }, 0]
        }
    }
  }

  commands ({ type }) {
    return (attrs) => {
      if (!attrs) {
        return removeMark(type, attrs)
      }

      const backgroundColorSubject = (attrs) => {
        if (!attrs) return null

        const gradient = () => {
          const values = attrs.map(gradient => {
            return `${gradient.color} ${gradient.position}%`
          })
          return `linear-gradient(90deg,${values})`
        }

        return {
          backgroundImage: gradient()
        }
      }

      return updateMark(type, {...backgroundColorSubject(attrs), raw: JSON.stringify(attrs)})
    }
  }
}
