const state = () => ({
  authors: []
})

const getters = {}

const actions = {
  fetchAuthors ({state, rootState}) {
    return axios.get(`api/projects/${rootState.projects.project.id}/blog/authors`)
      .then(({data}) => {
        state.authors = data.data.map(author => {
          return {
            ...author,
            avatar: JSON.parse(author.avatar)
          }
        })
      })
  },
  store ({state, rootState}, payload) {
    VEvent.fire('loader', true)
    return axios.post(`api/projects/${rootState.projects.project.id}/blog/authors`, {
      ...payload,
      avatar: JSON.stringify(payload.avatar)
    })
      .then(({data}) => {
        state.authors.push({
          ...data.data,
          avatar: JSON.parse(data.data.avatar)
        })
      })
      .finally(() => {
        VEvent.fire('loader', false)
      })
  },
  update ({rootState}, payload) {
    VEvent.fire('loader', true)
    return axios.put(`api/projects/${rootState.projects.project.id}/blog/authors/${payload.id}`, {
      ...payload,
      avatar: JSON.stringify(payload.avatar)
    })
      .finally(() => {
        VEvent.fire('loader', false)
      })
  },
  remove ({state, rootState}, payload) {
    VEvent.fire('loader', true)
    return axios.delete(`api/projects/${rootState.projects.project.id}/blog/authors/${payload.author.id}`)
      .then(() => {
        state.authors.splice(payload.index, 1)
      })
      .finally(() => {
        VEvent.fire('loader', false)
      })
  }
}

const mutations = {}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
