<template>
  <div class="pt-[9px] min-h-[500px]">
    <b-alert
      :show="(position === 'right' || position === 'left') && !insideDots"
      variant="danger"
    >
      Arrows are disabled at the current position of the dots.
    </b-alert>

    <b-form-group>
      <draggable
        v-model="section.options.content.carousel.slides"
        handle=".move"
      >
        <transition-group name="shufle">
          <carousel-drag-item
            v-for="(slide, index) in slides"
            :key="`key-${index}`"
            :item="slide"
            @delete="() => deleteSlide(index)"
            @open="() => goToSlide(index)"
          />
        </transition-group>
      </draggable>

      <button
        class="flex items-center border rounded-[3px] font-[600] text-[#44474A] text-[14px] h-[38px] px-[17px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[18px]"
        @click="addSlide"
      >
        <span class="text-[#3D82EA] text-[22px] mr-[9px]">+</span>
        Add Slide
      </button>
    </b-form-group>

    <b-form-group
      label="Item Gap"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="gap"
            :interval="1"
            :max="40"
            :min="1"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="gap"
            :max="40"
            :min="1"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Slides To Show"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="slidesToShow"
            :interval="1"
            :max="slides.length"
            :min="1"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="slidesToShow"
            :max="slides.length"
            :min="1"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Slides To Scroll"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="slidesToScroll"
            :interval="1"
            :max="slides.length"
            :min="1"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="slidesToScroll"
            :max="slides.length"
            :min="1"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group label="Slide Icon">
      <div class="flex items-center w-[130px] gap-[10px]">
        <icon-select v-model="section.options.content.carousel.arrows.leftIcon" />

        <color-picker
          :color="section.options.content.carousel.arrows.color"
          @input="event => section.options.content.carousel.arrows.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </div>
    </b-form-group>


    <b-form-group
      label="Arrow Size"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="arrowSize"
            :interval="1"
            :max="40"
            :min="1"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="arrowSize"
            :max="40"
            :min="1"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>


    <b-form-group label="Dots icon">
      <div class="flex items-center w-[130px] gap-[10px]">
        <icon-select v-model="section.options.content.carousel.dotOptions.icon" />
        <color-picker
          :color="section.options.content.carousel.dotOptions.color"
          @input="event => section.options.content.carousel.dotOptions.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </div>
    </b-form-group>
    <b-form-group
      label="Dots Size"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="dotsSize"
            :dot-size="17"
            :interval="1"
            :max="24"
            :min="1"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="dotsSize"
            :max="24"
            :min="1"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group label="Dots position">
      <div class="d-flex align-items-center w-100">
        <v-select
          v-model="section.options.content.carousel.dotOptions.position"
          :options="[{label: 'Top', value: 'top'}, {label: 'Bottom', value: 'bottom'}, {label: 'Right', value: 'right'}, {label: 'Left', value: 'left'}]"
          :reduce="option => option.value"
          :searchable="false"
          class="custom-select"
          placeholder="Select dots position"
        />
      </div>
    </b-form-group>
    <b-form-group>
      <div class="flex items-center gap-[24px]">
        <label class="mb-0 pb-0">Content Align</label>
        <b-btn-group class="d-block">
          <b-btn
            variant="menubar-button"
            @click="align = 'start'"
          >
            <svg
              fill="none"
              height="13"
              viewBox="0 0 14 13"
              width="14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :fill="align === 'start' ? '#3D82EA' : '#333333'"
                d="M0.583313 1.41662C0.583313 1.09445 0.84448 0.833282 1.16665 0.833282H12.8333C13.1555 0.833282 13.4166 1.09445 13.4166 1.41662C13.4166 1.73878 13.1555 1.99995 12.8333 1.99995H1.16665C0.84448 1.99995 0.583313 1.73878 0.583313 1.41662Z"
              />
              <path
                :fill="align === 'start' ? '#3D82EA' : '#333333'"
                clip-rule="evenodd"
                d="M1.74998 3.74995C1.74998 3.42778 2.01115 3.16662 2.33331 3.16662H5.83331C6.15548 3.16662 6.41665 3.42778 6.41665 3.74995V11.9166C6.41665 12.2388 6.15548 12.5 5.83331 12.5H2.33331C2.01115 12.5 1.74998 12.2388 1.74998 11.9166V3.74995ZM2.91665 4.33328V11.3333H5.24998V4.33328H2.91665Z"
                fill-rule="evenodd"
              />
              <path
                :fill="align === 'start' ? '#3D82EA' : '#333333'"
                clip-rule="evenodd"
                d="M8.16665 3.16662C7.84448 3.16662 7.58331 3.42778 7.58331 3.74995V8.41662C7.58331 8.73878 7.84448 8.99995 8.16665 8.99995H11.6666C11.9888 8.99995 12.25 8.73878 12.25 8.41662V3.74995C12.25 3.42778 11.9888 3.16662 11.6666 3.16662H8.16665ZM8.74998 7.83328V4.33328H11.0833V7.83328H8.74998Z"
                fill-rule="evenodd"
              />
            </svg>
          </b-btn>
          <b-btn
            variant="menubar-button"
            @click="align = 'center'"
          >
            <svg
              fill="none"
              height="14"
              viewBox="0 0 12 14"
              width="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :fill="align === 'center' ? '#3D82EA' : '#333333'"
                clip-rule="evenodd"
                d="M6.58335 0.833303C6.58335 0.511137 6.32219 0.249969 6.00002 0.249969C5.67785 0.249969 5.41669 0.511137 5.41669 0.833303V1.41664H3.66669C3.34452 1.41664 3.08335 1.6778 3.08335 1.99997V5.49997C3.08335 5.82213 3.34452 6.0833 3.66669 6.0833H5.41669V7.24997H0.75002C0.427854 7.24997 0.166687 7.51114 0.166687 7.8333V11.3333C0.166687 11.6555 0.427854 11.9166 0.75002 11.9166H5.41669V12.5C5.41669 12.8221 5.67785 13.0833 6.00002 13.0833C6.32219 13.0833 6.58335 12.8221 6.58335 12.5V11.9166H11.25C11.5722 11.9166 11.8334 11.6555 11.8334 11.3333V7.8333C11.8334 7.51114 11.5722 7.24997 11.25 7.24997H6.58335V6.0833H8.33335C8.65552 6.0833 8.91669 5.82213 8.91669 5.49997V1.99997C8.91669 1.6778 8.65552 1.41664 8.33335 1.41664H6.58335V0.833303ZM5.41669 2.5833H4.25002V4.91664H5.41669V2.5833ZM6.58335 4.91664V2.5833H7.75002V4.91664H6.58335ZM5.41669 8.41664H1.33335V10.75H5.41669V8.41664ZM6.58335 10.75V8.41664H10.6667V10.75H6.58335Z"
                fill-rule="evenodd"
              />
            </svg>
          </b-btn>
          <b-btn
            variant="menubar-button"
            @click="align = 'end'"
          >
            <svg
              fill="none"
              height="13"
              viewBox="0 0 14 13"
              width="14"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                :fill="align === 'end' ? '#3D82EA' : '#333333'"
                clip-rule="evenodd"
                d="M1.74998 1.41662C1.74998 1.09445 2.01115 0.833282 2.33331 0.833282H5.83331C6.15548 0.833282 6.41665 1.09445 6.41665 1.41662V9.58328C6.41665 9.90545 6.15548 10.1666 5.83331 10.1666H2.33331C2.01115 10.1666 1.74998 9.90545 1.74998 9.58328V1.41662ZM2.91665 1.99995V8.99995H5.24998V1.99995H2.91665Z"
                fill-rule="evenodd"
              />
              <path
                :fill="align === 'end' ? '#3D82EA' : '#333333'"
                d="M0.583313 11.9166C0.583313 11.5945 0.84448 11.3333 1.16665 11.3333H12.8333C13.1555 11.3333 13.4166 11.5945 13.4166 11.9166C13.4166 12.2388 13.1555 12.5 12.8333 12.5H1.16665C0.84448 12.5 0.583313 12.2388 0.583313 11.9166Z"
              />
              <path
                :fill="align === 'end' ? '#3D82EA' : '#333333'"
                clip-rule="evenodd"
                d="M8.16665 3.74995C7.84448 3.74995 7.58331 4.01112 7.58331 4.33328V9.58328C7.58331 9.90545 7.84448 10.1666 8.16665 10.1666H11.6666C11.9888 10.1666 12.25 9.90545 12.25 9.58328V4.33328C12.25 4.01112 11.9888 3.74995 11.6666 3.74995H8.16665ZM8.74998 8.99995V4.91662H11.0833V8.99995H8.74998Z"
                fill-rule="evenodd"
              />
            </svg>
          </b-btn>
        </b-btn-group>
      </div>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="section.options.content.carousel.autoplay">
        Autoplay
      </b-checkbox>
    </b-form-group>

    <div
      v-show="section.options.content.carousel.autoplay"
      class="border p-2 mb-[14px]"
    >
      <b-form-group>
        <b-checkbox v-model="section.options.content.carousel.pauseOnHover">
          Pause on hover
        </b-checkbox>
      </b-form-group>

      <b-form-group
        class="mb-0"
        label="Slide Speed"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="7"
          >
            <vue-slider
              v-model="speed"
              :interval="1"
              :max="4000"
              :min="300"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col>
            <b-input
              v-model="speed"
              :max="4000"
              :min="300"
              :step="1"
              size="sm"
              type="number"
            />
          </b-col>
        </b-row>
      </b-form-group>
    </div>

    <b-form-group>
      <b-checkbox
        v-model="section.options.content.carousel.centerMode"
        value="true"
      >
        Center mode
      </b-checkbox>
    </b-form-group>

    <b-form-group>
      <b-checkbox
        v-model="section.options.content.carousel.dots"
        value="true"
      >
        Dots
      </b-checkbox>
    </b-form-group>

    <b-form-group v-if="section.options.content.carousel.dots">
      <b-checkbox
        v-model="insideDots"
        value="true"
      >
        Inside dots
      </b-checkbox>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="section.options.content.carousel.arrows.display">
        Arrows
      </b-checkbox>
      <div
        v-if="(position === 'right' || position === 'left') && !insideDots"
        class="text-danger mt-1"
      >
        Arrows are disabled at the current position of the dots.
      </div>
    </b-form-group>

    <SidbearSettingsGroup
      class="border-top"
      title="Padding & Margins"
    >
      <padding-full-customs
        :config="padding"
        :default-value="[20,20,20,20]"
      />
      <margin-full-customs :config="margin" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import _ from 'lodash'
import ModMixin from '@builder/components/mixins/ModMixin'
import ColorPicker from '../utils/ColorPicker'
import draggable from 'vuedraggable'
import IconSelect from '@/components/editor/components/IconSelect'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import CarouselDragItem from '@/components/editor/components/CarouselDragItem'

export default {
  name: 'ImagesMod',
  components: {CarouselDragItem, SidbearSettingsGroup, IconSelect, ColorPicker, draggable},
  mixins: [ModMixin],

  computed: {
    position () {
      return this.section.options.content.carousel.dotOptions.position
    },
    slides () {
      return this.section.options.content.carousel.slides
    },
    slidesToShow: {
      get () {
        const _slides = _.get(this.section.options.content.carousel.slidesToShow, this.resolutioner, _.get(this.section.options.content.carousel.slidesToShow, 'lg', this.section.options.content.carousel.slidesToShow))
        if (_slides === null) return _.get(this.section.options.content.carousel.slidesToShow, 'lg', this.section.options.content.carousel.slidesToShow)
        
        return _slides
      },
      set (val) {
        const obj = typeof this.section.options.content.carousel.slidesToShow === 'object' ? this.section.options.content.carousel.slidesToShow : {}
        this.$set(this.section.options.content.carousel, 'slidesToShow', {..._.set(obj, [this.resolutioner], parseInt(val))})
      }
    },
    gap: {
      get () {
        return this.config.gap || 0
      },
      set (val) {
        this.$set(this.config, 'gap', val)
      }
    },
    padding () {
      if (!this.config.padding) {
        this.$set(this.config, 'padding', {})
      }

      return this.config.padding
    },
    margin () {
      if (!this.config.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.margin
    },
    speed: {
      get () {
        return this.config.speed || 300
      },
      set (val) {
        this.$set(this.config, 'speed', parseInt(val))
      }
    },
    insideDots: {
      get () {
        return this.section.options.content.carousel.insideDots
      },
      set (val) {
        this.$set(this.section.options.content.carousel, 'insideDots', val)
      }
    },
    align: {
      get () {
        return this.section.options.content.carousel.align
      },
      set (val) {
        this.section.options.content.carousel.align = val
      }
    },
    arrowSize: {
      get () {
        return this.section.options.content.carousel.arrows.arrowSize
      },
      set (val) {
        this.section.options.content.carousel.arrows.arrowSize = val
      }
    },
    dotsSize: {
      get () {
        return this.section.options.content.carousel.dotOptions.size
      },
      set (val) {
        this.section.options.content.carousel.dotOptions.size = val
      }
    },
    slidesToScroll: {
      get () {
        return this.section.options.content.carousel.slidesToScroll
      },
      set (val) {
        this.section.options.content.carousel.slidesToScroll = parseInt(val)
      }
    }
  },

  methods: {
    goToSlide (index) {
      this.getOpenedItemObj.$slick.goTo(index)
    },
    deleteSlide (index) {
      this.section.options.content.carousel.slides.splice(index, 1)
    },
    addSlide () {
      this.section.options.content.carousel.slides.push({
        uid: this.slides.length + 1,
        options: {},
        content: []
      })
    }
  }
}
</script>
