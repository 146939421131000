import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Counter'
export const group = 'Advanced'
export const defaultOptions = {
  content: {
    counter: {
      type: 'counter-mod-v2',
      display: true,
      from: 0,
      to: 100,
      speed: 2,
      postfix: {
        text: {
          lg: '',
          md: null,
          sm: null
        }
      },
      prefix: {
        text: {
          lg: '',
          md: null,
          sm: null
        }
      },
      decimal: {
        enabled: {
          lg: false,
          md: null,
          sm: null
        },
        char: ','
      },
      shapeEnabled: false,
      animations: {
        animation: null,
        once: true,
        offset: 200,
        delay: 50
      }
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@builder/assets/svg/previews/time/main-1.png')
}

export const icon = `
<svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_45_34" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="18">
<rect width="25.5" height="18" rx="2" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_45_34)">
<path d="M7.80966 5.72727V13H6.92898V6.65057H6.88636L5.1108 7.82955V6.93466L6.92898 5.72727H7.80966Z" fill="black"/>
<path d="M18.2102 19.0994C17.7415 19.0994 17.3236 19.0189 16.9567 18.858C16.5921 18.697 16.3021 18.4732 16.0866 18.1868C15.8736 17.898 15.7576 17.563 15.7386 17.1818H16.6335C16.6525 17.4162 16.733 17.6186 16.875 17.7891C17.017 17.9571 17.2029 18.0874 17.4325 18.1797C17.6622 18.272 17.9167 18.3182 18.196 18.3182C18.5085 18.3182 18.7855 18.2637 19.027 18.1548C19.2685 18.0459 19.4579 17.8944 19.5952 17.7003C19.7325 17.5062 19.8011 17.2812 19.8011 17.0256C19.8011 16.758 19.7348 16.5225 19.6023 16.3189C19.4697 16.1129 19.2756 15.9519 19.0199 15.8359C18.7642 15.7199 18.4517 15.6619 18.0824 15.6619H17.5V14.8807H18.0824C18.3712 14.8807 18.6245 14.8286 18.8423 14.7244C19.0625 14.6203 19.2341 14.4735 19.3572 14.2841C19.4827 14.0947 19.5455 13.8722 19.5455 13.6165C19.5455 13.3703 19.491 13.156 19.3821 12.9737C19.2732 12.7914 19.1193 12.6494 18.9205 12.5476C18.724 12.4458 18.492 12.3949 18.2244 12.3949C17.9735 12.3949 17.7367 12.4411 17.5142 12.5334C17.294 12.6233 17.1141 12.7547 16.9744 12.9276C16.8348 13.098 16.759 13.304 16.7472 13.5455H15.8949C15.9091 13.1643 16.0239 12.8305 16.2393 12.544C16.4548 12.2552 16.7365 12.0303 17.0845 11.8693C17.4349 11.7083 17.8196 11.6278 18.2386 11.6278C18.6884 11.6278 19.0743 11.719 19.3963 11.9013C19.7183 12.0812 19.9657 12.3191 20.1385 12.6151C20.3113 12.911 20.3977 13.2306 20.3977 13.5739C20.3977 13.9834 20.29 14.3326 20.0746 14.6214C19.8615 14.9103 19.5715 15.1103 19.2045 15.2216V15.2784C19.6638 15.3542 20.0225 15.5495 20.2805 15.8643C20.5386 16.1768 20.6676 16.5639 20.6676 17.0256C20.6676 17.4209 20.5599 17.776 20.3445 18.0909C20.1314 18.4034 19.8402 18.6496 19.4709 18.8295C19.1016 19.0095 18.6813 19.0994 18.2102 19.0994Z" fill="black"/>
<path d="M15.7528 8.5V7.8608L18.1534 5.23295C18.4351 4.92519 18.6671 4.65767 18.8494 4.4304C19.0317 4.20076 19.1667 3.98532 19.2543 3.78409C19.3442 3.58049 19.3892 3.36742 19.3892 3.14489C19.3892 2.8892 19.3277 2.66785 19.2045 2.48082C19.0838 2.2938 18.9181 2.14938 18.7074 2.04759C18.4967 1.94579 18.2599 1.89489 17.9972 1.89489C17.7178 1.89489 17.474 1.95289 17.2656 2.06889C17.0597 2.18253 16.8999 2.34233 16.7862 2.5483C16.675 2.75426 16.6193 2.99574 16.6193 3.27273H15.7812C15.7812 2.84659 15.8795 2.47254 16.076 2.15057C16.2725 1.8286 16.54 1.57765 16.8786 1.39773C17.2195 1.2178 17.6018 1.12784 18.0256 1.12784C18.4517 1.12784 18.8293 1.2178 19.1584 1.39773C19.4875 1.57765 19.7455 1.82031 19.9325 2.12571C20.1196 2.43111 20.2131 2.77083 20.2131 3.14489C20.2131 3.41241 20.1645 3.67401 20.0675 3.92969C19.9728 4.183 19.8071 4.46591 19.5703 4.77841C19.3359 5.08854 19.0104 5.46733 18.5938 5.91477L16.9602 7.66193V7.71875H20.3409V8.5H15.7528Z" fill="black"/>
<path d="M25 2V16C25 16.8284 24.3284 17.5 23.5 17.5H2C1.17157 17.5 0.5 16.8284 0.5 16V2C0.5 1.17157 1.17157 0.5 2 0.5H12.75H23.5C24.3284 0.5 25 1.17157 25 2Z" stroke="black"/>
</g>
<line x1="12.5" y1="0.75" x2="12.5" y2="17.25" stroke="black"/>
</svg>
`

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElCounterV2', options)

    this.name = name
    this.group = group
    this.css = [['.--t3x95ha--07LNjU5Qxe--wrapper { padding: 20px; --counter-size: 24px; font-size: 24px; }'], [], []]
    this.sids = ['t3x95ha--07LNjU5Qxe']
    this.sid = 't3x95ha--07LNjU5Qxe'
    this.defaultOptions = defaultOptions
  }
}
