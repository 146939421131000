<template>
  <b-modal
    v-model="showModal"
    v-bind="loadedModal.settings"
    hide-header
    hide-footer
    content-class="bg-transparent border-0 custom-modal"
    body-class="web es-root bg-transparent p-0"
  >
    <link
      rel="stylesheet"
      :href="loadedModal.css_file"
    >
    <div v-if="loadedModal.hasOwnProperty('content')">
      <div class="relative">
        <component
          :is="section.component"
          v-for="section in loadedModal.content"
          :key="section.uid"
          :section="section"
        >
          <div
            v-if="loadedModal.settings.closeIcon"
            class="text-right position-absolute right-0 my-3 mx-4 z-index-100"
          >
            <b-link @click="showModal = false">
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
                  :fill="loadedModal.settings.closeIconColor"
                />
              </svg>
            </b-link>
          </div>
        </component>
        <div class="absolute w-full h-full top-0 left-0 bg-transparent" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'CustomModal',
  computed: {
    ...mapState('modal', {
      loadedModal: state => state.loadedModal
    }),
    showModal: {
      get () {
        return this.$store.state.modal.showModal
      },
      set (val) {
        this.$store.commit('modal/SHOW_MODAL', val)
      }
    }
  }
}
</script>
