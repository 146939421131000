<template>
  <b-row>
    <b-col>
      <b-form-group label="Translate Y">
        <div class="position-relative">
          <b-input
            v-model="y"
            class="pr-5"
            type="number"
          />
          <span class="position-absolute right-5 top-1/2 transform -translate-y-1/2">px</span>
        </div>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Translate X">
        <div class="position-relative">
          <b-input
            v-model="x"
            class="pr-5"
            type="number"
          />
          <span class="position-absolute right-5 top-1/2 transform -translate-y-1/2">px</span>
        </div>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import ResolutionMixin from '../../mixins/ResolutionMixin'

export default {
  name: 'TranslateControl',
  mixins: [ResolutionMixin],
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  computed: {
    xOriginal () {
      return _.get(this.config, ['translateX'], {})
    },
    yOriginal () {
      return _.get(this.config, ['translateY'], {})
    },

    x: {
      get () {
        return _.get(this.config, ['translateX', this.resolutioner], 0)
      },
      set (val) {
        this.$set(this.config, 'translateX', {
          ...this.xOriginal,
          [this.resolutioner]: val
        })
      }
    },
    y: {
      get () {
        return _.get(this.config, ['translateY', this.resolutioner], 0)
      },
      set (val) {
        this.$set(this.config, 'translateY', {
          ...this.yOriginal,
          [this.resolutioner]: val
        })
      }
    }
  }
}
</script>
