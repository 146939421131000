import Vue from 'vue'
import {getIndexByUID} from '@builder/store/utils/helpers'
import shortid from 'shortid'

import _ from 'lodash'
import {insert} from '../../sections/utils/helpers'

const state = () => ({
  sections: [],
  navbar: null,
  footer: null
})

const getters = {
  getNavLinks (state) {
    return state.navbar.nav
  },
  getSectionsJson (state) {
    return state.sections.map()
  },
  getSections (state) {
    return state.sections
  },
  getNavbar (state, rootGetter, rootState) {
    return rootState.editor.globalStyles.navbar.section || null
  },
  getFooter (state, rootGetter, rootState) {
    return rootState.editor.globalStyles.footer.section || null
  }
}

const actions = {
  addLink ({state}, payload) {
    const key = _.kebabCase(payload.name)

    if (!payload.key && !payload.activeItem) {
      state.navbar.nav.push({
        ...payload,
        key
      })

      Vue.set(state.sections, key, {
        sections: []
      })

      state.page = key

    } else if (payload.key && !payload.activeItem) {
      state.navbar.nav.push({
        ...payload
      })
    } else if (!payload.key && payload.activeItem) {
      if (!payload.activeItem.page.hasOwnProperty('children')) {
        Vue.set(payload.activeItem.page, 'children', [])
      }

      payload.activeItem.page.children.push({
        name: payload.name,
        path: payload.path,
        key
      })

      Vue.set(state.sections, key, {
        sections: []
      })

      state.page = key
    } else if (payload.key && payload.activeItem) {
      if (!payload.activeItem.page.hasOwnProperty('children')) {
        Vue.set(payload.activeItem.page, 'children', [])
      }

      payload.activeItem.page.children.push({
        name: payload.name,
        path: payload.path,
        key: payload.key
      })
    }
  },
  addPage ({state}, payload) {
    Vue.set(state.sections, _.kebabCase(payload.name), {
      sections: []
    })
  },
  editPage ({state, dispatch }, payload) {
    Vue.set(state.sections[payload.key], 'name', payload.name)

    dispatch('projects/save', null, {root: true})
      .finally(() => {
        VEvent.fire('loader', false)
      })
  },
  deletePage ({state, dispatch }, payload) {
    Vue.delete(state.sections, _.kebabCase(payload))

    dispatch('projects/save', null, {root: true})
      .finally(() => {
        VEvent.fire('loader', false)
      })
  },
  removeLink (_, payload) {
    const index = payload.list.findIndex(nav => nav.name.toLowerCase() === payload.page.name.toLowerCase())

    if (index !== -1) {
      payload.list.splice(index, 1)

      // if (state.page === _.kebabCase(payload.page.name)) {
      //   state.page = _.kebabCase(state.navbar.nav[0].key)
      // }
      //
      // Vue.delete(state.sections, _.kebabCase(payload.page.name));
    }
  },
  addSection ({commit, state}, payload) {
    if (payload.after) {
      const afterIndex = _.findIndex(state.sections, ['uid', payload.after])

      if (payload.deep) {
        commit('ADD_SECTION_DEEP', {
          index: afterIndex,
          section: payload.section
        })
      } else if (afterIndex !== -1) {
        commit('ADD_SECTION_AFTER', {
          index: afterIndex,
          section: payload.section
        })
      } else {
        commit('ADD_SECTION', payload)
      }
    } else {
      commit('ADD_SECTION', payload)
    }
  },
  removeSection ({commit, state}, payload) {
    if (typeof payload !== 'object') {
      payload = {uid: payload, deep: null}
    }

    if (payload.uid === '#navigation') {
      commit('editor/REMOVE_NAVBAR', '', {root: true})
      return false
    }

    if (payload.uid === '#footer') {
      commit('editor/REMOVE_FOOTER', '', {root: true})
      return false
    }

    if (payload.deep) {
      const parentIndex = state.sections.findIndex(s => s.uid === payload.deep)
      const index = state.sections[parentIndex].options.content.group.items.findIndex(s => s.uid === payload.uid)
      commit('REMOVE_SECTION_DEEP', {parentIndex, index})
    } else {
      const index = state.sections.findIndex(s => s.uid === payload.uid)
      commit('REMOVE_SECTION', index)
    }
  },
  changeLayout ({state, dispatch}, payload) {
    const sectionIndex = getIndexByUID(state.sections, payload.uid)

    if (sectionIndex !== -1) {
      const newSection = new payload.layout(JSON.parse(JSON.stringify(payload.oldConfig)).options).json()
      Vue.set(state.sections, sectionIndex, newSection)
      dispatch('editor/sectionSettings', {section: newSection}, {root: true})
    }
  },
  changeNavbarLayout ({state, dispatch}, payload) {
    const newSection = new payload.layout(JSON.parse(JSON.stringify(payload.oldConfig)).options).json()
    Vue.set(state.navbar, 'section', newSection)
    dispatch('editor/sectionSettings', {section: newSection}, {root: true})
  },
  setSections ({state, commit}, paylaod) {
    state.sections = paylaod
    commit('editor/SET_PAGE', 'sections', {root: true})
  }
}

const mutations = {
  SET_SECTIONS (state, payload) {
    state.sections = (payload || []).map(section => {
      return {
        ...section,
        render_id: shortid.generate()
      }
    })
  },
  ADD_SECTION (state, payload) {
    state.sections.unshift(payload.section)
  },
  UNSHIFT_SECTION (state, payload) {
    state.sections.unshift(payload.section)
  },
  ADD_SECTION_AFTER (state, payload) {
    state.sections.splice(payload.index + 1, 0, payload.section)
  },
  ADD_SECTION_DEEP (state, payload) {
    state.sections[payload.index].options.content.group.items.push(payload.section)
  },
  REMOVE_SECTION (state, index) {
    state.sections.splice(index, 1)
  },
  REMOVE_SECTION_DEEP (state, payload) {
    state.sections[payload.parentIndex].options.content.group.items.splice(payload.index, 1)
  },
  SET_PAGE (state, payload) {
    state.page = payload
    document.title = _.startCase(payload)
    document.querySelectorAll('[custom-embed-wrapper]')
      .forEach(item => item.parentNode.removeChild(item))
  },
  DUBLICATE_SECTION (state, {sections, section, index}) {
    state.sections = insert(sections, index, JSON.parse(JSON.stringify({
      ...section,
      uid: shortid.generate(),
      render_id: shortid.generate()
    })))
  },
  MOVE_SECTION (state, paylaod) {
    const copySection = JSON.parse(JSON.stringify(state.sections[paylaod.index]))

    if (paylaod.to === 'up') {
      if (paylaod.index === 0) return
      state.sections.splice(paylaod.index, 1)
      state.sections = insert(state.sections, paylaod.index - 1, copySection)
    } else if (paylaod.to === 'down') {
      if (paylaod.index + 1 === state.sections.length) return
      state.sections.splice(paylaod.index, 1)
      state.sections = insert(state.sections, paylaod.index + 1, copySection)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
