<template>
  <highliter
    v-model="section.name"
    class="time-section highlight-element"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    section-type="element"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="remove"
    @click.stop.native="sectionSettings('time')"
    @add-subject="$emit('add-subject')"
  >
    <Template :section="section">
      <template v-slot:time="{shadow, backgroundColor, color, sizing, scale}">
        <vac
          ref="vac"
          :end-time="timeZoneTransform"
        >
          <template
            v-slot:process="{ timeObj }"
          >
            <div class="d-flex justify-content-center">
              <div
                class="timer d-flex align-items-center flex-wrap justify-content-center timer-block"
                :style="{...shadow, ...backgroundColor, padding: `${sizing.padding * scale}rem`}"
              >
                <template v-if="!style.dontShow.value.includes('Days')">
                  <div class="timer-item">
                    <span
                      class="time"
                      :style="{fontFamily: dialFontFamily, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale}rem`}"
                      data-color
                    >{{ timeObj.d < 10 ? '0' + timeObj.d : timeObj.d }}</span>
                    <span
                      class="subtitle"
                      :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                    >days</span>
                  </div>
                  <div class="timer-item">
                    <span
                      class="time-divider"
                      :style="{fontSize: `${sizing.divider * scale}rem`}"
                    >:</span>
                  </div>
                </template>
                <template v-if="!style.dontShow.value.includes('Hours')">
                  <div class="timer-item">
                    <span
                      class="time"
                      :style="{fontFamily: dialFontFamily, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale}rem`}"
                      data-color
                    >{{ timeObj.h }}</span>
                    <span
                      class="subtitle"
                      :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                    >hours</span>
                  </div>
                  <div class="timer-item">
                    <span
                      class="time-divider"
                      :style="{fontSize: `${sizing.divider * scale}rem`}"
                    >:</span>
                  </div>
                </template>
                <template v-if="!style.dontShow.value.includes('Minutes')">
                  <div class="timer-item">
                    <span
                      class="time"
                      :style="{fontFamily: dialFontFamily, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale}rem`}"
                      data-color
                    >{{ timeObj.m }}</span>
                    <span
                      class="subtitle"
                      :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                    >minutes</span>
                  </div>
                  <div class="timer-item">
                    <span
                      class="time-divider"
                      :style="{fontSize: `${sizing.divider * scale}rem`}"
                    >:</span>
                  </div>
                </template>
                <div
                  v-if="!style.dontShow.value.includes('Seconds')"
                  class="timer-item"
                >
                  <span
                    class="time"
                    :style="{fontFamily: dialFontFamily, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale}rem`}"
                    data-color
                  >{{ timeObj.s }}</span>
                  <span
                    class="subtitle"
                    :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  >seconds</span>
                </div>
              </div>
            </div>
          </template>
          <template
            v-slot:finish
          >
            <div class="d-flex justify-content-center">
              <div
                class="timer d-flex align-items-center justify-content-center timer-block"
                :style="{...shadow, ...backgroundColor}"
              >
                <template v-if="!style.dontShow.value.includes('Days')">
                  <div class="timer-item">
                    <span
                      class="time"
                      :style="{fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale}rem`}"
                      data-color
                    >00</span>
                    <span
                      class="subtitle"
                      :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                    >days</span>
                  </div>
                  <div class="timer-item">
                    <span
                      class="time-divider"
                      :style="{fontSize: `${sizing.divider * scale}rem`}"
                    >:</span>
                  </div>
                </template>
                <template v-if="!style.dontShow.value.includes('Hours')">
                  <div class="timer-item">
                    <span
                      class="time"
                      :style="{fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale}rem`}"
                      data-color
                    >00</span>
                    <span
                      class="subtitle"
                      :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                    >hours</span>
                  </div>
                  <div class="timer-item">
                    <span
                      class="time-divider"
                      :style="{fontSize: `${sizing.divider * scale}rem`}"
                    >:</span>
                  </div>
                </template>
                <template v-if="!style.dontShow.value.includes('Minutes')">
                  <div class="timer-item">
                    <span
                      class="time"
                      :style="{fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale}rem`}"
                      data-color
                    >00</span>
                    <span
                      class="subtitle"
                      :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                    >minutes</span>
                  </div>
                  <div class="timer-item">
                    <span
                      class="time-divider"
                      :style="{fontSize: `${sizing.divider * scale}rem`}"
                    >:</span>
                  </div>
                </template>
                <div
                  v-if="!style.dontShow.value.includes('Seconds')"
                  class="timer-item"
                >
                  <span
                    class="time"
                    :style="{fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale}rem`}"
                    data-color
                  >00</span>
                  <span
                    class="subtitle"
                    :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  >seconds</span>
                </div>
              </div>
            </div>
          </template>
        </vac>
      </template>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import TimerService from '../TimerService'

export default {
  name: 'TimeItem1',

  components: {Template, Highliter},

  mixins: [SectionMixin, TimerService],

  created () {
    VEvent.listen('timer-update', () => {
      setTimeout(() => {
        if (this.$refs.vac === undefined) return
        this.$refs.vac.startCountdown(true)
      })
    })
  }
}
</script>
