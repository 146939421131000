import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Lottie Animations'
export const group = 'Others'
export const defaultOptions = {
  content: {
    lottie: {
      type: 'lottie-mod',
      display: true,
      loop: true,
      autoplay: true,
      controls: false,
      renderer: 'svg',
      jsonPath: ''
    }
  },
  customize: {}
}

export const previews = {
  left: null,
  right: null,
  main: require('@builder/assets/svg/previews/time/main-1.png')
}

export const icon = `
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.75 3.4C10.37 3.4 9.01 5.78 7.735 8.075C6.715 10.03 5.695 11.9 4.25 11.9C3.74 11.9 3.4 12.24 3.4 12.75C3.4 13.26 3.74 13.6 4.25 13.6C6.63 13.6 7.99 11.22 9.265 8.925C10.285 6.97 11.305 5.1 12.75 5.1C13.26 5.1 13.6 4.76 13.6 4.25C13.6 3.74 13.26 3.4 12.75 3.4ZM14.45 0H2.55C1.105 0 0 1.105 0 2.55V14.45C0 15.895 1.105 17 2.55 17H14.45C15.895 17 17 15.895 17 14.45V2.55C17 1.105 15.895 0 14.45 0ZM15.3 14.45C15.3 14.96 14.96 15.3 14.45 15.3H2.55C2.04 15.3 1.7 14.96 1.7 14.45V2.55C1.7 2.04 2.04 1.7 2.55 1.7H14.45C14.96 1.7 15.3 2.04 15.3 2.55V14.45Z" fill="black"/>
</svg>
`

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElLottie', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
