<template>
  <div class="pt-[9px]">
    <b-form-group
      label="Code"
      class="mb-2"
    >
      <b-input
        v-model="config.code"
      />
    </b-form-group>

    <!--    <b-form-group v-if="premium">-->
    <!--      <div class="flex flex-col gap-[12px]">-->
    <!--        <b-btn-->
    <!--          variant="primary"-->
    <!--          class="mt-3"-->
    <!--          @click="modal = true"-->
    <!--        >-->
    <!--          Upgrade to Business Plus-->
    <!--        </b-btn>-->

    <!--        <span class="text-secondary text-[12px]">-->
    <!--          Access to certain elements requires a Business Plus subscription.-->
    <!--        </span>-->
    <!--      </div>-->
    <!--    </b-form-group>-->
    <!--    -->
    <!--    <purchase-plan-modal-->
    <!--      v-model="modal"-->
    <!--      business-plus-->
    <!--    />-->
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import {findDeep} from '@/utils/helpers'
// import PurchasePlanModal from '@/components/editor/modals/purchase-plan/PurchasePlanModal.vue'

export default {
  name: 'ButtonMod',
  // components: {PurchasePlanModal},
  mixins: [ModMixin],
  data () {
    return {
      premium: false,
      modal: false
    }
  },
  computed: {
    code () {
      return this.section.options.content.code.code
    },
    isChatSubscription () {
      return this.$store.state.editor.chatSubscription.has_available_subscriptions || this.$store.state.editor.businessPlusSubscription
    },
    isCommunityPlus () {
      return this.$store.state.editor.communityPlusSubscription
    },
    isBusinessPlus () {
      return this.$store.state.editor.businessPlusSubscription
    },
    isPlus () {
      return this.$store.state.editor.communityPlusSubscription || this.$store.state.editor.businessPlusSubscription
    }
  },
  methods: {
    async checkPremium () {
      let block
      if (this.section.options.content.code.code) {
        await axios.get(`https://api.estage.com/api/projects/favorite-blocks/${this.code}`)
          .then(({data}) => {
            block = data ? JSON.parse(data.data.block) : null
          })
          .catch(() => {
            this.premium = false
          })
        const realtimeChat = findDeep(block, 'component', 'ElRealtimeChat')
        const liveStream = findDeep(block, 'component', 'ElLiveStream')
        if (realtimeChat !== undefined) {
          this.premium = !this.isChatSubscription
        }
        if (liveStream !== undefined) {
          this.premium = !this.isPlus
        }
      }
    }
  }
  // async mounted () {
  //   await this.checkPremium()
  // },
  // watch: {
  //   code () {
  //     this.checkPremium()
  //   }
  // }
}
</script>
