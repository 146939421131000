<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="el-dynamic"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('post')"
  >
    <div class="flex w-full align-items-center post-categories">
      <div class="mb-0">
        Categories:
      </div>
      <a href="#">SOCIAL MEDIA</a>
      <svg
        width="5"
        height="5"
        viewBox="0 0 5 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="2.5"
          cy="2.5"
          r="2.5"
          fill="#D9D9D9"
        />
      </svg>
      <a href="#">STRATEGY</a>
    </div>
  </highliter>
</template>

<script>
import Highliter from '@/components/builder/utils/Highliter.vue'
import SectionMixin from '@/components/mixins/SectionMixin'

export default {
  name: 'ElPostGrid',
  components: {Highliter},
  mixins: [SectionMixin],

  computed: {
    className () {
      return `post-grid-${this.section.sid}`
    }
  }
}
</script>

<style lang="scss">
.post-categories {
  gap: 14px;
  color: #757575;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 11px;

  a {
    color: #3D82EA
  }
}
</style>