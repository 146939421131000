<template>
  <div class="pt-[9px] sid-select">
    <div>
      <DividerStyle
        :config="config"
        :pressets="stylePressets"
      />

      <b-form-group
        label="Line Thickness"
      >
        <div class="flex items-center gap-[9px]">
          <b-input-group class="w-auto">
            <b-input
              v-model="width"
              :disabled="config.pressetID === 2 || config.pressetID === 1"
              :max="40"
              :min="0"
              class="!min-w-[60px] !h-[29px]"
              type="number"
            />

            <div class="form-control p-0 !min-w-[37px] !h-[29px] items-center justify-center d-flex pr-1">
              px
            </div>
          </b-input-group>

          <color-picker
            :color="config.color"
            @input="event => config.color = event.hex8"
          />
        </div>
      </b-form-group>
    </div>

    <b-form-group label="Line Width">
      <vue-slider
        v-model="widthLine"
        :dot-size="17"
        :max="100"
        :min="1"
        class="editor-slider-1"
        tooltip="none"
      />
    </b-form-group>

    <b-form-group label="Alignment">
      <b-form-group>
        <b-btn-group>
          <b-btn
            variant="white"
            class="h-[29px] py-0 flex items-center justify-center"
            @click="align = 'start'"
          >
            <i
              :class="{'text-primary': align === 'start'}"
              class="fa fa-outdent"
            />
          </b-btn>
          <b-btn
            variant="white"
            class="h-[29px] py-0 flex  items-center justify-center"
            @click="align = 'center'"
          >
            <i
              :class="{'text-primary': align === 'center'}"
              class="fa fa-align-justify"
            />
          </b-btn>
          <b-btn
            variant="white"
            class="h-[29px] py-0 flex items-center justify-center"
            @click="align = 'end'"
          >
            <i
              :class="{'text-primary': align === 'end'}"
              class="fa fa-indent"
            />
          </b-btn>
        </b-btn-group>
      </b-form-group>
    </b-form-group>

    <template v-if="config.pressetID === 0">
      <b-form-group>
        <v-select
          v-model="style"
          :calculate-position="withPopper"
          :options="styles"
          :reduce="style => style.value"
          :searchable="false"
          append-to-body
          class="custom-select"
        />
      </b-form-group>

      <b-form-group>
        <b-checkbox v-model="config.pilled">
          Pilled
        </b-checkbox>
      </b-form-group>
    </template>

    <SidbearSettingsGroup
      class="border-top"
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--divider-${section.sid}`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--divider-${section.sid}`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import _ from 'lodash'
import ModMixin from '@builder/components/mixins/ModMixin'
import popperMixin from '../mixins/popperMixin'
import DividerStyle from '@/components/editor/controls/divider-mod/DividerStyle'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'

export default {
  name: 'TiptapMod',
  components: {SidLinkedGroup, SidbearSettingsGroup, DividerStyle},
  mixins: [ModMixin, popperMixin],

  data () {
    return {
      styles: [
        {
          value: 'none',
          label: 'No Border'
        },
        {
          value: 'solid',
          label: 'Solid'
        },
        {
          value: 'dashed',
          label: 'Dashed'
        },
        {
          value: 'dotted',
          label: 'Dotted'
        },
        {
          value: 'double',
          label: 'Double'
        },
        {
          value: 'outset',
          label: 'Outset'
        }
      ],
      stylePressets: [
        {
          id: 0,
          text: 'Custom',
          onSelect: () => {
            this.style = 'solid'
            this.width = 2
          }
        },
        {
          id: 1,
          preview: require('@/assets/images/element-presset/divider-mod/divider-1.svg'),
          onSelect: () => {
            this.style = 'none'
            this.width = 25
          }
        },
        {
          id: 2,
          preview: require('@/assets/images/element-presset/divider-mod/divider-2.svg'),
          onSelect: () => {
            this.style = 'none'
            this.config.color = '#333333ff'
            this.$set(this.config, 'backgroundImage', 'M0 10.2066L12.9709 0L25.9419 10.2066V19.1374H0V10.2066Z')
          }
        },
        {
          id: 3,
          preview: require('@/assets/images/element-presset/divider-mod/divider-3.svg'),
          onSelect: () => {
            this.style = 'dotted'
            this.width = 2
          }
        }
      ]
    }
  },

  computed: {
    margin () {
      if (!this.config.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.margin
    },
    widthLine: {
      get () {
        return _.get(this.config.widthLine, this.resolutioner, _.get(this.config.widthLine, 'lg', this.config.widthLine)) || 100
      },
      set (val) {
        this.$set(this.config, 'widthLine', {..._.set(this.config.widthLine, [this.resolutioner], val)})
      }
    },
    style: {
      get () {
        return this.config.style || 'solid'
      },
      set (val) {
        this.$set(this.config, 'style', val)
      }
    },
    align: {
      get () {
        return _.get(this.config.align, this.resolutioner, _.get(this.config, 'align', 'center'))
      },
      set (val) {
        const align = typeof this.config.align === 'object'
          ? this.config.align
          : {
            lg: this.config.align || 'center',
            md: this.config.align || 'center',
            sm: this.config.align || 'center'
          }

        this.$set(this.config, 'align', {
          ...align,
          [this.resolutioner]: val
        })
      }
    },
    width: {
      get () {
        return parseInt(_.get(this.config.width, this.resolutioner, _.get(this.config.width, 'lg', this.config.width)))
      },
      set (val) {
        this.$set(this.config, 'width', {..._.set(typeof this.config.width === 'object' ? this.config.width : {}, [this.resolutioner], val)})
      }
    }
  }
}
</script>
