<template>
  <div>
    <div class="flex items-center h-[160px] px-[55px]">
      <div
        class="media align-items-center"
      >
        <div
          class="media-aside align-self-start"
        >
          <svg
            data-v-4faf4c28=""
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              data-v-4faf4c28=""
              d="M50.2715 31.671C50.0782 31.671 49.8929 31.5942 49.7563 31.4576C49.6197 31.321 49.5429 31.1357 49.5429 30.9424V28.8223C49.5429 28.6291 49.6197 28.4437 49.7563 28.3071C49.8929 28.1705 50.0782 28.0937 50.2715 28.0937C50.4647 28.0937 50.65 28.1705 50.7867 28.3071C50.9233 28.4437 51.0001 28.6291 51.0001 28.8223V30.9424C51.0001 31.1357 50.9233 31.321 50.7867 31.4576C50.65 31.5942 50.4647 31.671 50.2715 31.671Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M48.0857 51H2.91429C2.14137 51 1.40011 50.693 0.853575 50.1464C0.30704 49.5999 0 48.8586 0 48.0857V2.91429C0 2.14137 0.30704 1.40011 0.853575 0.853575C1.40011 0.30704 2.14137 0 2.91429 0H48.0857C48.8586 0 49.5999 0.30704 50.1464 0.853575C50.693 1.40011 51 2.14137 51 2.91429V23.7223C51 23.9155 50.9232 24.1008 50.7866 24.2375C50.65 24.3741 50.4647 24.4509 50.2714 24.4509C50.0782 24.4509 49.8929 24.3741 49.7562 24.2375C49.6196 24.1008 49.5429 23.9155 49.5429 23.7223V2.91429C49.5429 2.52783 49.3893 2.1572 49.1161 1.88393C48.8428 1.61066 48.4722 1.45714 48.0857 1.45714H2.91429C2.52783 1.45714 2.1572 1.61066 1.88393 1.88393C1.61066 2.1572 1.45714 2.52783 1.45714 2.91429V48.0857C1.45714 48.4722 1.61066 48.8428 1.88393 49.1161C2.1572 49.3893 2.52783 49.5429 2.91429 49.5429H48.0857C48.4722 49.5429 48.8428 49.3893 49.1161 49.1161C49.3893 48.8428 49.5429 48.4722 49.5429 48.0857V43.3063C49.5429 43.1131 49.6196 42.9277 49.7562 42.7911C49.8929 42.6545 50.0782 42.5777 50.2714 42.5777C50.4647 42.5777 50.65 42.6545 50.7866 42.7911C50.9232 42.9277 51 43.1131 51 43.3063V48.0857C51 48.8586 50.693 49.5999 50.1464 50.1464C49.5999 50.693 48.8586 51 48.0857 51Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M50.2715 38.9348C50.0782 38.9348 49.8929 38.8581 49.7563 38.7214C49.6197 38.5848 49.5429 38.3995 49.5429 38.2063V36.0424C49.5429 35.8492 49.6197 35.6639 49.7563 35.5272C49.8929 35.3906 50.0782 35.3138 50.2715 35.3138C50.4647 35.3138 50.65 35.3906 50.7867 35.5272C50.9233 35.6639 51.0001 35.8492 51.0001 36.0424V38.2063C51.0001 38.3995 50.9233 38.5848 50.7867 38.7214C50.65 38.8581 50.4647 38.9348 50.2715 38.9348Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M50.2714 13.1143H0.728571C0.535342 13.1143 0.350027 13.0375 0.213394 12.9009C0.0767599 12.7643 0 12.579 0 12.3857C0 12.1925 0.0767599 12.0072 0.213394 11.8706C0.350027 11.7339 0.535342 11.6572 0.728571 11.6572H50.2714C50.4647 11.6572 50.65 11.7339 50.7866 11.8706C50.9232 12.0072 51 12.1925 51 12.3857C51 12.579 50.9232 12.7643 50.7866 12.9009C50.65 13.0375 50.4647 13.1143 50.2714 13.1143Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M45.7332 7.26311H43.7595C43.5662 7.26311 43.3809 7.18635 43.2443 7.04972C43.1076 6.91308 43.0309 6.72777 43.0309 6.53454C43.0309 6.34131 43.1076 6.156 43.2443 6.01936C43.3809 5.88273 43.5662 5.80597 43.7595 5.80597H45.7332C45.9264 5.80597 46.1117 5.88273 46.2483 6.01936C46.385 6.156 46.4617 6.34131 46.4617 6.53454C46.4617 6.72777 46.385 6.91308 46.2483 7.04972C46.1117 7.18635 45.9264 7.26311 45.7332 7.26311Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M39.1761 7.26311H37.2024C37.0092 7.26311 36.8238 7.18635 36.6872 7.04972C36.5506 6.91308 36.4738 6.72777 36.4738 6.53454C36.4738 6.34131 36.5506 6.156 36.6872 6.01936C36.8238 5.88273 37.0092 5.80597 37.2024 5.80597H39.1761C39.3693 5.80597 39.5546 5.88273 39.6913 6.01936C39.8279 6.156 39.9047 6.34131 39.9047 6.53454C39.9047 6.72777 39.8279 6.91308 39.6913 7.04972C39.5546 7.18635 39.3693 7.26311 39.1761 7.26311Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M32.6188 7.26311H30.6451C30.4519 7.26311 30.2666 7.18635 30.13 7.04972C29.9933 6.91308 29.9166 6.72777 29.9166 6.53454C29.9166 6.34131 29.9933 6.156 30.13 6.01936C30.2666 5.88273 30.4519 5.80597 30.6451 5.80597H32.6188C32.8121 5.80597 32.9974 5.88273 33.134 6.01936C33.2706 6.156 33.3474 6.34131 33.3474 6.53454C33.3474 6.72777 33.2706 6.91308 33.134 7.04972C32.9974 7.18635 32.8121 7.26311 32.6188 7.26311Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M25.4272 7.21724H5.44903C5.2558 7.21724 5.07049 7.14048 4.93385 7.00385C4.79722 6.86722 4.72046 6.6819 4.72046 6.48867C4.72046 6.29544 4.79722 6.11013 4.93385 5.9735C5.07049 5.83686 5.2558 5.7601 5.44903 5.7601H25.4272C25.6204 5.7601 25.8057 5.83686 25.9424 5.9735C26.079 6.11013 26.1558 6.29544 26.1558 6.48867C26.1558 6.6819 26.079 6.86722 25.9424 7.00385C25.8057 7.14048 25.6204 7.21724 25.4272 7.21724Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M20.4001 31.8626H7.28582C7.09259 31.8626 6.90728 31.7859 6.77064 31.6492C6.63401 31.5126 6.55725 31.3273 6.55725 31.134V18.2143C6.55725 18.0211 6.63401 17.8357 6.77064 17.6991C6.90728 17.5625 7.09259 17.4857 7.28582 17.4857H20.4001C20.5933 17.4857 20.7787 17.5625 20.9153 17.6991C21.0519 17.8357 21.1287 18.0211 21.1287 18.2143V31.134C21.1287 31.3273 21.0519 31.5126 20.9153 31.6492C20.7787 31.7859 20.5933 31.8626 20.4001 31.8626ZM8.01439 30.4055H19.6715V18.9429H8.01439V30.4055Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M43.7144 19.0645H25.5001C25.3069 19.0645 25.1216 18.9878 24.9849 18.8511C24.8483 18.7145 24.7715 18.5292 24.7715 18.336C24.7715 18.1427 24.8483 17.9574 24.9849 17.8208C25.1216 17.6842 25.3069 17.6074 25.5001 17.6074H43.7144C43.9076 17.6074 44.0929 17.6842 44.2296 17.8208C44.3662 17.9574 44.443 18.1427 44.443 18.336C44.443 18.5292 44.3662 18.7145 44.2296 18.8511C44.0929 18.9878 43.9076 19.0645 43.7144 19.0645Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M43.7144 25.5284H25.5001C25.3069 25.5284 25.1216 25.4517 24.9849 25.315C24.8483 25.1784 24.7715 24.9931 24.7715 24.7999C24.7715 24.6066 24.8483 24.4213 24.9849 24.2847C25.1216 24.148 25.3069 24.0713 25.5001 24.0713H43.7144C43.9076 24.0713 44.0929 24.148 44.2296 24.2847C44.3662 24.4213 44.443 24.6066 44.443 24.7999C44.443 24.9931 44.3662 25.1784 44.2296 25.315C44.0929 25.4517 43.9076 25.5284 43.7144 25.5284Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M43.7144 31.9923H25.5001C25.3069 31.9923 25.1216 31.9155 24.9849 31.7789C24.8483 31.6423 24.7715 31.457 24.7715 31.2637C24.7715 31.0705 24.8483 30.8852 24.9849 30.7485C25.1216 30.6119 25.3069 30.5352 25.5001 30.5352H43.7144C43.9076 30.5352 44.0929 30.6119 44.2296 30.7485C44.3662 30.8852 44.443 31.0705 44.443 31.2637C44.443 31.457 44.3662 31.6423 44.2296 31.7789C44.0929 31.9155 43.9076 31.9923 43.7144 31.9923Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M43.7142 38.4562H7.6499C7.45667 38.4562 7.27135 38.3794 7.13472 38.2428C6.99809 38.1062 6.92133 37.9209 6.92133 37.7276C6.92133 37.5344 6.99809 37.3491 7.13472 37.2124C7.27135 37.0758 7.45667 36.9991 7.6499 36.9991H43.7142C43.9074 36.9991 44.0927 37.0758 44.2294 37.2124C44.366 37.3491 44.4428 37.5344 44.4428 37.7276C44.4428 37.9209 44.366 38.1062 44.2294 38.2428C44.0927 38.3794 43.9074 38.4562 43.7142 38.4562Z"
              fill="#3D82EA"
            />
            <path
              data-v-4faf4c28=""
              d="M43.7142 44.9208H7.6499C7.45667 44.9208 7.27135 44.8441 7.13472 44.7074C6.99809 44.5708 6.92133 44.3855 6.92133 44.1923C6.92133 43.999 6.99809 43.8137 7.13472 43.6771C7.27135 43.5404 7.45667 43.4637 7.6499 43.4637H43.7142C43.9074 43.4637 44.0927 43.5404 44.2294 43.6771C44.366 43.8137 44.4428 43.999 44.4428 44.1923C44.4428 44.3855 44.366 44.5708 44.2294 44.7074C44.0927 44.8441 43.9074 44.9208 43.7142 44.9208Z"
              fill="#3D82EA"
            />
          </svg>
        </div>
        <div
          class="media-body"
        >
          <h4
            class="font-weight-bold mb-1"
          >
            Comments Module
          </h4>
          <h6
            class="text-muted mb-0"
          >
            Manage Comments
          </h6>
        </div>
      </div>
    </div>

    <div class="bg-[#F7F8F9] px-[55px] py-[50px]">
      <div class="flex justify-between items-start mb-[26px]">
        <div>
          <div class="flex gap-[18px]">
            <apply-action
              v-if="selectedIds.length"
              @apply="emitAction"
            />

            <comments-filter :query-filters="queryFilters" />

            <v-select
              v-model="apiPoint"
              :searchable="false"
              class="!w-[220px]"
              :reduce="val => val.api"
              label="name"
              :options="[{name: 'Blog', api: 'blog/manage/comments'}, {name: 'Blocks', api: 'comments-block/manage'}]"
            />
          </div>
        </div>

        <search :query-filters="queryFilters" />
      </div>

      <comments-table
        v-if="comments.data"
        v-model="selectedIds"
        :comments="comments"
        @refresh="fetchData"
      >
        <div class="flex justify-center w-100">
          <b-pagination
            v-if="comments.total > comments.per_page"
            v-model="currentPage"
            :per-page="comments.per_page"
            :total-rows="comments.total"
          />
        </div>
      </comments-table>
      <div
        v-else
        class="flex justify-center w-100"
      >
        <b-spinner variant="primary" />
      </div>
    </div>

    <multiple-reply
      v-model="replyModal" 
      :is-all-child-comments="isAllChildComments"
      :selected-ids="selectedIds"
    />
  </div>
</template>

<script>
import ApplyAction from '@/views/dashboard/comments/ApplyAction.vue'
import CommentsFilter from '@/views/dashboard/comments/Filter.vue'
import Search from '@/views/dashboard/comments/Search.vue'
import CommentsTable from '@/views/dashboard/comments/CommentsTable.vue'
import MultipleReply from '@/views/dashboard/comments/MultipleReply.vue'
import {mapState} from 'vuex'

export default {
  name: 'CommentsDashboard',
  components: {MultipleReply, CommentsTable, Search, ApplyAction, CommentsFilter},
  data () {
    return {
      replyModal: false,
      comments: {},
      currentPage: 1,
      queryFilters: {
        search: null,
        searchBy: null,
        category: null,
        filter: null
      },
      selectedIds: []
    }
  },
  computed: {
    query () {
      const filterObject = Object.keys(this.queryFilters).filter(key => !!this.queryFilters[key])
      return filterObject.reduce((accumulator, currentValue) => {
        return accumulator += `&${currentValue}=${this.queryFilters[currentValue]}`
      }, '')
    },
    isAllChildComments (){
      const c = this.get(this.comments,'data',[]).filter(c=> this.selectedIds.includes(c.id)  && Boolean(c.parent_id))
      return c.length == this.selectedIds.length
    },
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('editor', {
      commentsManagementAPI: state => state.commentsManagementAPI
    }),
    apiPoint: {
      get () {
        return this.commentsManagementAPI
      },
      set (val) {
        this.$store.state.editor.commentsManagementAPI = val
      }
    }
  },
  watch: {
    query () {
      this.fetchData()
    },
    currentPage () {
      this.fetchData()
    },
    commentsManagementAPI () {
      this.fetchData()
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.comments = {}
      this.selectedIds = []

      axios.get(`api/projects/${this.project.id}/${this.commentsManagementAPI}?page=${this.currentPage}${this.query}`)
        .then(({data}) => {
          this.comments = data.data
          this.currentPage = data.data.current_page
        })
    },
    emitAction (action) {
      this[action]()
    },
    reply () {
      this.replyModal = true
    },
    hide () {
      axios.put(`api/projects/${this.project.id}/${this.commentsManagementAPI}/mass-actions`, {
        ids: this.selectedIds,
        action: 'hide'
      })
        .then(() => {
          this.fetchData()

          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Hidden.',
            showConfirmButton: false,
            timer: 3000
          })
        })
    },
    delete () {
      axios.put(`api/projects/${this.project.id}/${this.commentsManagementAPI}/mass-actions`, {
        ids: this.selectedIds,
        action: 'delete'
      })
        .then(() => {
          this.fetchData()

          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Deleted.',
            showConfirmButton: false,
            timer: 3000
          })
        })
    },
    approve () {
      axios.put(`api/projects/${this.project.id}/${this.commentsManagementAPI}/mass-actions`, {
        ids: this.selectedIds,
        action: 'approve'
      })
        .then(() => {
          this.fetchData()

          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Approved.',
            showConfirmButton: false,
            timer: 3000
          })
        })
    },
    reject () {
      axios.put(`api/projects/${this.project.id}/${this.commentsManagementAPI}/mass-actions`, {
        ids: this.selectedIds,
        action: 'reject'
      })
        .then(() => {
          this.fetchData()

          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Rejected.',
            showConfirmButton: false,
            timer: 3000
          })
        })
    },
    blockUserComments () {

    },
    viewAllUserComments () {

    }
  }
}
</script>