<template>
  <div>
    <SidbearSettingsGroup
      title="Layout"
      active
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <draggable
        v-model="getCols"
        class="mt-3"
        handle=".move"
        @change="onChange"
      >
        <transition-group name="shufle">
          <flex-column-drag-item
            v-for="(column, index) in getCols"
            :key="`key-${index}`"
            :item="column.special ? section.specialCols[column.key] : column"
            @open="column.mod ? sectionSettings(column.mod, {subject: section.specialCols[column.key], title: column.name, back: () => {sectionSettings('flex-mod-v2', customBackOptions)}}) : sectionSettings('flex-column-mod-v2', {subjectIndex: column.uid, title: column.name, back: () => {sectionSettings('flex-mod-v2', customBackOptions)}})"
            @delete="() => deleteColumn(index, column)"
            @copy="item => copyColumn(item)"
          />
        </transition-group>
      </draggable>

      <button
        class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
        @click="addNewColumn"
      >
        Add Colum
        <span class="text-[#3D82EA] text-[20px]">+</span>
      </button>

      <rule-tabs>
        <template #Normal>
          <sid-background :sid="`.--${section.sid}--bg`" />

          <!--          <sid-range-slider-->
          <!--            label="Width"-->
          <!--            :sid="`.&#45;&#45;${section.sid}&#45;&#45;flex`"-->
          <!--            rule-name="width"-->
          <!--            default-dimension="%"-->
          <!--            var="&#45;&#45;width"-->
          <!--          />-->

          <sid-range-slider
            label="Horizontal Gap"
            :sid="`.--${section.sid}--flex`"
            rule-name="column-gap"
            :dimensions="[]"
          />

          <sid-range-slider
            label="Vertical Gap"
            :sid="`.--${section.sid}--flex`"
            rule-name="row-gap"
            :dimensions="[]"
          />

          <sid-checkbox
            :sid="`.--${section.sid}--flex`"
            rule-name="flex-wrap"
            value="wrap"
          >
            Wrap
          </sid-checkbox>

          <sid-range-slider
            rule-name="max-width"
            :sid="`.--${section.sid}--flex`"
            default-dimension="%"
            :dimensions="['%', 'vw', 'px']"
            label="Width"
            var="--width"
            measurement="width"
          />

          <b-form-group>
            <sid-range-slider
              rule-name="height"
              :sid="`.--${section.sid}--flex`"
              default-dimension="%"
              :dimensions="['%', 'vh', 'px']"
              label="Height"
              :ignore-zero="true"
              var="--height"
              measurement="height"
            />
          </b-form-group>

          <sid-width-and-height
            v-if="false"
            :sid="`.--${section.sid}--flex`"
            width-rule="max-width"
            default-value="100%"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Z-Index</label>

              <sid-input
                :sid="`.--${section.sid}--container`"
                rule-name="z-index"
                default-dimension=""
                class="!w-[59px]"
              />
            </div>
          </b-form-group>

          <sid-flex-direction
            :sid="`.--${section.sid}--flex`"
            rule-name="flex-direction"
          >
            <template #column>
              <sid-buttons-group
                label="Horizontal Alignment"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  }
                ]"
                :sid="`.--${section.sid}--flex.flex-wrapper`"
                rule-name="align-items"
              />

              <sid-buttons-group
                label="Vertical Alignment"
                class="mb-0"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'space-between',
                    tooltip: 'Between'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'space-around',
                    tooltip: 'Around'
                  }
                ]"
                :sid="`.--${section.sid}--flex.flex-wrapper`"
                rule-name="justify-content"
              />
            </template>

            <template #column-reverse>
              <sid-buttons-group
                label="Horizontal Alignment"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  }
                ]"
                :sid="`.--${section.sid}--flex.flex-wrapper`"
                rule-name="align-items"
              />

              <sid-buttons-group
                label="Vertical Alignment"
                class="mb-0"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'space-between',
                    tooltip: 'Between'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'space-around',
                    tooltip: 'Around'
                  }
                ]"
                :sid="`.--${section.sid}--flex.flex-wrapper`"
                rule-name="justify-content"
              />
            </template>

            <template #row>
              <sid-buttons-group
                label="Vertical Alignment"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'12\' viewBox=\'0 0 13 12\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.26855 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 6.86035 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 0.721313)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 11.0599)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E',
                    value: 'stretch',
                    tooltip: 'Stretch'
                  }
                ]"
                :sid="`.--${section.sid}--flex.flex-wrapper`"
                rule-name="align-items"
              />

              <sid-buttons-group
                label="Horizontal Alignment"
                class="mb-0"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'space-between',
                    tooltip: 'Between'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'space-around',
                    tooltip: 'Around'
                  }
                ]"
                :sid="`.--${section.sid}--flex.flex-wrapper`"
                rule-name="justify-content"
              />
            </template>

            <template #row-reverse>
              <sid-buttons-group
                label="Vertical Alignment"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'12\' viewBox=\'0 0 13 12\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.26855 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 6.86035 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 0.721313)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 11.0599)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E',
                    value: 'stretch',
                    tooltip: 'Stretch'
                  }
                ]"
                :sid="`.--${section.sid}--flex.flex-wrapper`"
                rule-name="align-items"
              />

              <sid-buttons-group
                label="Horizontal Alignment"
                class="mb-0"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'space-between',
                    tooltip: 'Between'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'space-around',
                    tooltip: 'Around'
                  }
                ]"
                :sid="`.--${section.sid}--flex.flex-wrapper`"
                rule-name="justify-content"
              />
            </template>
          </sid-flex-direction>
        </template>

        <template #Hover>
          <sid-background
            :sid="`.--${section.sid}--container .--${section.sid}--bg`"
            presudo=":hover"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Background Image"
    >
      <rule-tabs :tabs="['Static', 'Animation']">
        <template #Static>
          <sid-upload-image
            :sid="`.--${section.sid}--bg`"
            rule-name="background-image"
          />

          <b-form-group label="Background Image Size">
            <sid-select
              priority
              :searchable="false"
              :options="[
                {
                  label: 'Normal',
                  value: 'auto',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'auto']], [`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Full Center (Parallax)',
                  value: 'fixed',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'cover']], [`.--${section.sid}--bg`, ['background-attachment', 'fixed']]]
                },
                {
                  label: 'Fill 100 % Width',
                  value: '100% auto',
                  customProperties: [[`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Fill 100 % Width & Height',
                  value: '100% 100%',
                  customProperties: [[`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat',
                  value: 'repeat',
                  property: 'background-repeat',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'auto']], [`.--${section.sid}--bg`, ['background-repeat', 'repeat']], [`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat Horizontally',
                  value: 'repeat-x',
                  property: 'background-repeat',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'auto']], [`.--${section.sid}--bg`, ['background-repeat', 'repeat-x']], [`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat Vertically',
                  value: 'repeat-y',
                  property: 'background-repeat',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'auto']], [`.--${section.sid}--bg`, ['background-repeat', 'repeat-y']], [`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Cover',
                  value: 'cover',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'cover']], [`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Contain',
                  value: 'contain',
                  customProperties: [[`.--${section.sid}--bg`, ['background-size', 'contain']], [`.--${section.sid}--bg`, ['background-attachment', 'unset']]]
                }
              ]"
              :sid="`.--${section.sid}--bg`"
              var="--background-size"
              rule-name="background-size"
            />
          </b-form-group>

          <b-form-group label="Background Image Position">
            <sid-select
              :searchable="false"
              :options="[
                {label: 'Left Top', value: 'left top'},
                {label: 'Left Center', value: 'left center'},
                {label: 'Left Bottom', value: 'left bottom'},
                {label: 'Right Top', value: 'right top'},
                {label: 'Right Center', value: 'right center'},
                {label: 'Right Bottom', value: 'right bottom'},
                {label: 'Center Top', value: 'center top'},
                {label: 'Center Center', value: 'center center'},
                {label: 'Center Bottom', value: 'center bottom'},
              ]"
              :sid="`.--${section.sid}--bg`"
              rule-name="background-position"
            />
          </b-form-group>

          <sid-flip
            :sid="`.--${section.sid}--bg`"
            rule-name="scale"
          />

          <sid-range-slider
            :sid="`.--${section.sid}--bg`"
            label="Custom Size"
            rule-name="background-size"
            var="--custom-bg-size"
            unlim
            :dimensions="['px', '%', 'vh', 'vw']"
          />

          <b-form-group label="Transform-Y">
            <sid-range-slider
              :sid="`.--${section.sid}--bg`"
              rule-name="background-position-y"
            />
          </b-form-group>

          <b-form-group label="Transform-X">
            <sid-range-slider
              :sid="`.--${section.sid}--bg`"
              rule-name="background-position-X"
            />
          </b-form-group>

          <hr>

          <sid-overlay
            :sid="`.--${section.sid}--container > .el-overlay`"
            rule-name=""
          />

          <sid-background-filters
            :sid="`.--${section.sid}--bg`"
            rule-name="filter"
          />

          <hr>

          <sid-background-mask
            :sid="`.--${section.sid}--bg`"
          />
        </template>
        <template #Animation>
          <backgroun-images-slider-customs :config="section.options" />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Lottie Background">
      <lottie-config
        :config="lottieBackground"
        :sid="section.sid"
      />

      <sid-translate
        :sid="`.--lottie-container-${section.sid} > div`"
        rule-name="translate"
        :min="-100"
        :max="100"
        dimension="%"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Shape Divider"
    >
      <shape-divider-customs :config="section.options" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Drop Shadow">
      <rule-tabs>
        <template #Normal>
          <b-form-group
            label="Shadow"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--container`"
              rule-name="box-shadow"
            />
          </b-form-group>
        </template>

        <template #Hover>
          <b-form-group
            label="Shadow"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--container`"
              rule-name="box-shadow"
              presudo=":hover"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Animations">
      <b-form-group>
        <animations
          :sid="section.uid"
          :animations="animations"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--container`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--container`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--container`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--container`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>

        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--container`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--container`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--container`"
                rule-name="border-color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--container`"
            rule-name="border"
            presudo=":hover"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Click Action"
    >
      <ButtonAction
        :config="config"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="EGT Event Actions"
    >
      <EstageTracking
        :section="section"
        :events="[
          {
            label: 'On Show',
            value: 'on-show',
            actions: []
          },
          {
            label: 'On Hide',
            value: 'on-hide',
            actions: []
          }
        ]"
      />
    </SidbearSettingsGroup>

    <visibility-controls :section="section" />

    <SidbearSettingsGroup
      title="Real-Time Show/Hide"
    >
      <b-form-group>
        <b-checkbox
          v-model="realTimeVisibility"
          switch
          :disabled="realTimeLoading"
        >
          {{ realTimeVisibility ? 'Shown' : 'Hidden' }}
          <b-spinner
            v-if="realTimeLoading"
            small
            class="ml-2"
            variant="primary"
          />
        </b-checkbox>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--flex.flex-wrapper`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--container`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import _ from 'lodash'
import ModMixin from '@/components/mixins/ModMixin'
import draggable from 'vuedraggable'
import FlexColumnDragItem from '@/components/editor/components/FlexColumnDragItem'
import {col} from '@/components/builder/sections/utils/el-inline/Section'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidInput from '@/components/editor/components/sid-controls/SidInput'
import SidFlexDirection from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup'
import SidWidthAndHeight from '@/components/editor/components/sid-controls/sid-control-pressets/SidWidthAndHeight'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import SidUploadImage
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidUploadImage'
import SidBackgroundFilters
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundFilters'
import SidOverlay from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidOverlay'
import BackgrounImagesSliderCustoms from '@/components/editor/controls/BackgrounImagesSliderCustoms'
import ShapeDividerCustoms from '@/components/editor/controls/ShapeDividerCustoms'
import SidStyles from '@/components/mixins/SidStyles'
import Animations from '@/components/editor/controls/Animations'
import SidFlip from '@/components/editor/components/sid-controls/SidFlip'
import SidBackgroundMask
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundMask'
import ButtonAction from '@/components/editor/controls/button-mod/ButtonAction'
import VisibilityControls from '@/components/builder/components/visibility-controls/VisibilityControls.vue'
import {mapGetters, mapState} from 'vuex'
import EstageTracking from '@/components/editor/controls/es-tracking/EstageTracking.vue'
import {doc, getDoc, collection} from 'firebase/firestore'
import {db} from '@/firebase'
import SidTranslate from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTranslate.vue'
import LottieConfig from '@/components/editor/components/LottieConfig.vue'


export default {
  name: 'FlexModV2',
  components: {
    LottieConfig, SidTranslate,
    EstageTracking,
    VisibilityControls,
    ButtonAction,
    SidBackgroundMask,
    SidFlip,
    Animations,
    ShapeDividerCustoms,
    BackgrounImagesSliderCustoms,
    SidOverlay,
    SidBackgroundFilters,
    SidUploadImage,
    SidBoxShadow,
    SidColor,
    SidLinkedGroup,
    SidSelect,
    SidbearSettingsGroup,
    SidCheckbox,
    SidWidthAndHeight,
    SidButtonsGroup,
    SidFlexDirection, SidInput, SidRangeSlider, SidBackground, RuleTabs, FlexColumnDragItem, draggable
  },
  mixins: [ModMixin, SidStyles],
  data () {
    return {
      realTimeLoading: false
    }
  },
  async mounted () {
    if (this.section.options.content.fbBtnID) {
      this.section.options.content.realTimeVisibility = (await getDoc(doc(collection(db, 'visibilityElements'), this.section.options.content.fbBtnID.toString()))).data().state
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),
    realTimeVisibility: {
      get () {
        return this.section.options.content.hasOwnProperty('realTimeVisibility') ? this.section.options.content.realTimeVisibility : true
      },
      set (val) {
        this.$set(this.section.options.content, 'realTimeVisibility', val)
        this.$set(this.section.options.content, 'point', this.project.subdomain)
      }
    },
    getCols: {
      get () {
        return _.sortBy([...this.config.options.content.cols], [(o) => {
          return _.get(o, ['sortIndex', this.resolutioner], 0)
        }])
      },
      set (val) {
        this.config.options.content.cols = val
      }
    },
    lottieBackground () {
      if (!this.section.options.content.lottie) {
        this.$set(this.section.options.content, 'lottie', {})
      }

      return this.section.options.content.lottie
    },
    animations () {
      if (!this.section.options.content.animations) {
        this.$set(this.section.options.content, 'animations', {
          animation: null,
          once: true,
          offset: 200,
          delay: 50
        })
      }

      return this.section.options.content.animations
    }
  },
  watch: {
    realTimeVisibility (val) {
      this.realTimeLoading = true
      if (!this.section.options.content.fbBtnID) {
        return axios.get('https://estage.site/api/visibility/update-state/create')
          .then(({data}) => {
            this.$set(this.section.options.content, 'fbBtnID', data.id)

            axios.get(`https://estage.site/api/visibility/update-state/${data.id}?state=${val}`)
              .finally(() => {
                this.realTimeLoading = false

                this.$swal({
                  icon: 'success',
                  iconColor: '#4F83E3',
                  toast: true,
                  position: 'top-right',
                  title: 'State is updated!.',
                  showConfirmButton: false,
                  timer: 3000
                })
              })
          })
      }

      axios.get(`https://estage.site/api/visibility/update-state/${this.section.options.content.fbBtnID}?state=${val}`)
        .finally(() => {
          this.realTimeLoading = false

          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'State is updated!.',
            showConfirmButton: false,
            timer: 3000
          })
        })
    }
  },
  methods: {
    updateIndex () {
      this.config.options.content.cols.forEach((col, index) => {
        col.sortIndex[this.resolutioner] = index
        col.highlight = false
      })
    },
    onChange () {
      this.updateIndex()
    },
    setSection (section) {
      this.$store.commit('editor/SET_SECTION', section)
      this.$store.commit('editor/SET_OPENED_MENU', null)
      this.$store.commit('editor/SET_PAGE', 'section-settings')
    },
    newSectionModal () {
      this.$store.commit('editor/SET_SECTION_MODAL', {
        uid: this.section.uid
      })
    },
    deleteColumn (index, column = null) {
      const _index = this.config.options.content.cols.findIndex(col => _.get(col, ['sortIndex', this.resolutioner], null) === index)

      if (_index !== -1) {
        this.config.options.content.cols.splice(_index, 1)
        this.updateIndex()
      }

      if (column) {
        this.clearStyles(document.querySelector(`[data-sid="${column.sid}"]`))
      }
    },
    copyColumn (item) {
      this.config.options.content.cols.push(_.cloneDeep({
        ...item,
        sortIndex: {
          lg: this.config.options.content.cols.length,
          md: this.config.options.content.cols.length,
          sm: this.config.options.content.cols.length
        }
      }))
    },
    addNewColumn () {
      this.config.options.content.cols.push(_.cloneDeep({
        ...col,
        sortIndex: {
          lg: this.config.options.content.cols.length,
          md: this.config.options.content.cols.length,
          sm: this.config.options.content.cols.length
        }
      }))
    }
  }
}
</script>