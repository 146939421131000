<template>
  <div v-if="settings.showStats">
    <highliter
      class="el-dynamic"
      value="Post Info"
      hide-add
      hide-right
    >
      <div
        class="post-info flex flex-wrap w-full align-items-center"
      >
        <div class="flex align-items-center post-info-author display-subject-1">
          <div
            v-if="settings.showAuthorAvatar"
            class="post-info-avatar-container"
          >
            <b-img
              v-if="l.get(customPostData,'author.avatar')"
              height="32"
              width="32"
              rounded="circle"
              class="avatar post-info-avatar-img"
              :src="l.get(customPostData,'author.avatar')"
              alt="Author Avatar"
            />
            <svg
              v-else
              width="32"
              height="32"
              viewBox="0 0 32 32"
              class="w-100 h-100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="16"
                cy="16"
                r="16"
                fill="#C4C4C4"
              />
              <path
                d="M16.2097 15.4993C17.3047 15.4993 18.2528 15.1066 19.0275 14.3318C19.8022 13.5571 20.1949 12.6092 20.1949 11.5142C20.1949 10.4195 19.8022 9.4715 19.0274 8.69656C18.2525 7.922 17.3045 7.5293 16.2097 7.5293C15.1147 7.5293 14.1668 7.922 13.3921 8.69669C12.6174 9.47137 12.2246 10.4194 12.2246 11.5142C12.2246 12.6092 12.6174 13.5572 13.3923 14.3319C14.1671 15.1065 15.1151 15.4993 16.2097 15.4993Z"
                fill="white"
              />
              <path
                d="M23.1826 20.2519C23.1602 19.9295 23.115 19.5778 23.0485 19.2065C22.9814 18.8323 22.8949 18.4786 22.7914 18.1553C22.6845 17.8212 22.5391 17.4912 22.3593 17.175C22.1727 16.8468 21.9536 16.561 21.7077 16.3259C21.4506 16.0798 21.1357 15.882 20.7717 15.7378C20.4089 15.5942 20.0068 15.5215 19.5768 15.5215C19.4079 15.5215 19.2445 15.5908 18.9291 15.7962C18.7349 15.9228 18.5078 16.0692 18.2544 16.2312C18.0376 16.3693 17.744 16.4987 17.3814 16.6158C17.0275 16.7303 16.6683 16.7884 16.3137 16.7884C15.9591 16.7884 15.6 16.7303 15.2458 16.6158C14.8835 16.4988 14.5899 16.3694 14.3734 16.2313C14.1223 16.0709 13.8951 15.9245 13.698 15.7961C13.383 15.5907 13.2195 15.5214 13.0506 15.5214C12.6204 15.5214 12.2185 15.5942 11.8558 15.7379C11.492 15.8819 11.1771 16.0797 10.9197 16.326C10.6739 16.5613 10.4547 16.847 10.2683 17.175C10.0887 17.4912 9.94329 17.8211 9.83625 18.1555C9.73286 18.4787 9.6464 18.8323 9.57924 19.2065C9.51272 19.5773 9.46753 19.9291 9.44518 20.2523C9.42322 20.5689 9.41211 20.8975 9.41211 21.2293C9.41211 22.093 9.68666 22.7922 10.2281 23.3079C10.7628 23.8167 11.4703 24.0748 12.3307 24.0748H20.2974C21.1578 24.0748 21.8651 23.8168 22.3999 23.3079C22.9415 22.7926 23.216 22.0933 23.216 21.2292C23.2159 20.8958 23.2047 20.567 23.1826 20.2519Z"
                fill="white"
              />
            </svg>
          </div>

          <div
            v-if="settings.showAuthorName"
            class="post-info-name text-nowrap"
          >
            by <strong> {{ l.get(customPostData,'author.name','John Smith') }}</strong>
          </div>
        </div>

        <div
          v-if="settings.showAuthorAvatar || settings.showAuthorName"
          class="post-info-separator display-subject-1"
        >
          <svg
            width="1"
            height="13"
            viewBox="0 0 1 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="0.5"
              y1="2.18557e-08"
              x2="0.499999"
              y2="13"
            />
          </svg>
        </div>

        <time
          v-if="settings.showDate"
          class="post-info-time text-nowrap display-subject-2"
          datetime="2023-04-09"
        >
          June 10, 2022
        </time>

        <div
          v-if="settings.showDate && settings.showStats"
          class="post-info-separator display-subject-2 display-subject-3"
        >
          <svg
            width="1"
            height="13"
            viewBox="0 0 1 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="0.5"
              y1="2.18557e-08"
              x2="0.499999"
              y2="13"
            />
          </svg>
        </div>

        <el-post-comments-and-likes v-if="settings.showStats" />
      </div>
    </highliter>
  </div>
</template>

<script>
import ElPostCommentsAndLikes from '@/components/builder/elements/blog/post-info/ElPostCommentsAndLikes.vue'
import Highliter from '@/components/builder/utils/Highliter.vue'
import _ from 'lodash'

export default {
  name: 'PostInfo',
  components: {Highliter, ElPostCommentsAndLikes},
  inject: {
    getCustomPostEditorData: {
      default:() => () => null
    }
  },
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      l: _
    }
  },
  computed: {
    customPostData () {
      return this.getCustomPostEditorData() 
    }
  }
}
</script>

<style lang="scss">
.post-info {
  gap: 14px;
  padding: 20px;
}

.post-info-avatar-container {
  margin-right: 10px;
  --size: 32px;
  width: var(--size);
  height: var(--size);
}

.post-info-name {
  font-size: 14px;
}

.post-info-data {
  font-size: 14px;
}

.post-info-separator {
  line {
    stroke: #ccc;
  }
}
</style>