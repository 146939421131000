<template>
  <div
    class="dashboard-space p-5 w-100"
  >
    <global-styles-render />

    <!-- Modal -->
    <b-container fluid>
      <b-row class="mb-5">
        <b-col md="3">
          <h4 class="font-weight-bold mb-0">
            Modal Builder
          </h4>
        </b-col>

        <b-col class="text-right">
          <b-btn
            v-b-modal.new-modal
            variant="primary"
          >
            <div class="d-flex align-items-center font-weight-bold">
              <div class="mr-2">
                Add New Modal
              </div>
              <svg
                fill="none"
                height="18"
                viewBox="0 0 18 18"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M17.6 9.3C17.6 13.884 13.884 17.6 9.3 17.6C4.71604 17.6 1 13.884 1 9.3C1 4.71604 4.71604 1 9.3 1C13.884 1 17.6 4.71604 17.6 9.3ZM9.3 16.3C13.166 16.3 16.3 13.166 16.3 9.3C16.3 5.43401 13.166 2.3 9.3 2.3C5.43401 2.3 2.3 5.43401 2.3 9.3C2.3 13.166 5.43401 16.3 9.3 16.3Z"
                  fill="white"
                  fill-rule="evenodd"
                />
                <path
                  d="M12.9 9.53198V8.26798H10.036V5.14798H8.64398V8.26798H5.76398V9.53198H8.64398V12.78H10.036V9.53198H12.9Z"
                  fill="white"
                />
              </svg>
            </div>
          </b-btn>
        </b-col>
      </b-row>

      <div class="py-5">
        <div v-if="!loading">
          <div v-if="modal.total !== 0">
            <b-list-group>
              <es-modal-list-item
                v-for="modal in modal.data"
                :key="modal.id"
                :modal="modal"
                @deleted="fetchModals"
                @duplicated="fetchModals"
              />
            </b-list-group>

            <b-pagination
              v-if="modal.total > modal.per_page"
              v-model="currentPage"
              :per-page="modal.per_page"
              :total-rows="modal.total"
            />
          </div>
          <div v-else>
            <p class="text-muted text-center">
              You have not created any modals yet
            </p>
          </div>
        </div>
        <div
          v-else
          class="d-flex align-items-center justify-content-center py-5"
        >
          <b-spinner variant="primary" />
        </div>
      </div>
    </b-container>
    <!-- End Modal -->

    <!-- Modal -->
    <new-modal />
    <!-- End Modal -->

    <custom-modal />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import OveralSettingsMixin from '../../components/mixins/OveralSettingsMixin'
import RouterMixin from '../../components/mixins/RouterMixin'
import NewModal from './components/NewModal'
import EsModalListItem from './components/EsModalListItem'
import CustomModal from '../../components/editor/modals/CustomModal'
import GlobalStylesRender from '@/components/builder/utils/GlobalStylesRender'

export default {
  name: 'EsModal',
  components: {GlobalStylesRender, CustomModal, EsModalListItem, NewModal},

  filters: {
    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/ig, ' ')
    }
  },
  mixins: [OveralSettingsMixin, RouterMixin],

  data () {
    return {
      search: '',
      loading: true,
      modal: {},
      currentPage: 1
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },

  watch: {
    currentPage () {
      this.fetchModals()
    }
  },

  mounted () {
    document.body.classList.add('es-modal')
  },

  beforeDestroy () {
    document.body.classList.remove('es-modal')
  },

  created () {
    this.fetchModals()
  },

  methods: {
    async fetchModals () {
      this.loading = true

      await axios.get(`api/projects/${this.project.id}/modals?page=${this.currentPage}&q=${this.search}`)
        .then(({data}) => {
          this.modal = data.data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.es-modal .custom-modal {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10000000;
  }
}
</style>
