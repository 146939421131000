<template>
  <div
    class="d-flex justify-content-center"
    :style="{...fullMarginSubject(section.options.customize.style, 'marginTop')}"
  >
    <div
      :style="{...width}"
      class="mx-auto w-100"
    >
      <div
        :style="{...backgroundColor, ...border, ...borderRadius, ...shadow, ...fullPaddingSubject(section.options.customize.style)}"
        class="position-relative scrollbar-hide"
        :class="[overflowIsEnabled, scrollIsEnabled, horizontalScrollSnapping, {'element-hidden': !subjectVisability(section.options.content.display)}]"
      >
        <div
          :style="{...borderRadius, ...backgroundImage}"
          class="image-background"
        />

        <div
          :style="{...borderRadius, ...backgroundImageOverlay}"
          class="image-background"
        />

        <shape-divider
          :config="section.options.customize.style"
          :styles="{...borderRadius}"
        />

        <slot />

        <div
          v-if="!getColumns.length"
          class="empty-row-drag h-[200px]"
          @click="addNewColumn"
        />

        <div
          v-else
          :class="[direction, wrap, verticalAlign, horizontalAlign]"
          :style="{...height, ...gap}"
          class="flex"
        >
          <slot name="before-col" />
          <div
            v-for="(col, index) in getColumns"
            :key="index"
            class="contents"
          >
            <slot
              v-if="col.special"
              :name="col.key"
              v-bind="{handleFlow, index, column: section.specialCols[col.key]}"
            />
            <flex-wrapper-col
              v-else
              :col="col"
              :index="index"
              :section="section"
              :custom-back-options="customBackOptions"
              @flow="handleFlow"
            />
          </div>
          <slot name="after-col" />
        </div>

        <slot name="after" />
      </div>
    </div>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import CustomStylesMixin from '@/components/mixins/CustomStylesMixin'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'
import FlexWrapperCol from '@/components/builder/sections/utils/el-inline/FlexWrapperCol'
import ShapeDivider from '@/components/editor/components/shape-divider/ShapeDivider'
import {col} from '@/components/builder/sections/utils/el-inline/Section'
import _ from 'lodash'
import FlexScrollMixin from '@/components/builder/sections/utils/el-inline/flex-mixins/FlexScrollMixin'

export default {
  components: {ShapeDivider, FlexWrapperCol},
  mixins: [SectionMixin,
    CustomStylesMixin,
    ResolutionMixin,
    BorderMixin,
    FlexScrollMixin
  ],

  props: {
    customBackOptions: {
      type: Object,
      required: true
    }
  },

  computed: {
    getColumns () {
      // return _.sortBy(this.section.options.content.cols.filter(col => this.subjectVisability(col.display)), [(o) => { return _.get(o, ['sortIndex', this.resolutioner], _.get(o, ['sortIndex', 'lg'], 0)) }])
      return this.section.options.content.cols
    },
    widthFixedEnabled () {
      return this.section.options.content.fixedWidthEnabled[this.resolutioner]
    },
    heightFixedEnabled () {
      return this.section.options.content.fixedHeightEnabled[this.resolutioner]
    },
    height () {
      const _height = _.get(this.section.options.content.fixedHeightV2, [this.resolutioner, 'value'], _.get(this.section.options.content, ['fixedHeight', this.resolutioner], 50))
      const _heightType = _.get(this.section.options.content.fixedHeightV2, [this.resolutioner, 'type'], 'vh')

      return {
        minHeight: this.heightFixedEnabled ? `${_height}${_heightType}` : '100%'
      }
    },
    width () {
      return {
        maxWidth: this.widthFixedEnabled ? `${this.section.options.content.fixedWidth[this.resolutioner]}px` : `${this.section.options.content.width[this.resolutioner]}%`
      }
    },
    gap () {
      return {
        columnGap: `${this.section.options.content.gap.x[this.resolutioner]}px`,
        rowGap: `${this.section.options.content.gap.y[this.resolutioner]}px `
      }
    },
    reverse () {
      return this.section.options.content.reverse[this.resolutioner]
    },
    direction () {
      return this.section.options.content.column[this.resolutioner] ? this.reverse ? 'flex-column-reverse' : 'flex-column' : this.reverse ? 'flex-row-reverse' : ''
    },
    wrap () {
      return this.section.options.content.wrap[this.resolutioner] ? 'flex-wrap' : ''
    },
    horizontalAlign () {
      return `justify-content-${this.section.options.content.horizontalAlign[this.resolutioner]}`
    },
    verticalAlign () {
      return `align-items-${this.section.options.content.verticalAlign[this.resolutioner]}`
    }
  },

  methods: {
    addNewColumn () {
      this.section.options.content.cols.push(col)
    },
    handleFlow () {
      this.$emit('flow')
    }
  }
}
</script>