<template>
  <div>
    <b-form-group label="Trigger Event">
      <v-select
        v-model="trigger"
        :options="options"
        :searchable="false"
      />
    </b-form-group>

    <component
      :is="component"
      v-for="component in triggerComponents()"
      :key="component"
      :trigger="trigger"
    />

    <b-form-group>
      <pages-select-menu
        :others="[{id: 'blog', title: 'ESTAGE Blog', code: project.blog_slug}]"
        input-class="rounded-[4px] !h-[29px]"
        other-title="Other:"
        input-placeholder="Type to search pages and posts"
        title="Pages and Posts"
        menu-class="!top-[unset] bottom-4 !left-[-10px]"
        add-posts
        all-pages
        @selectPage="item => trigger.pages = item"
        @selectOther="item => trigger.pages = item"
      >
        <template
          v-if="trigger.pages"
          slot="placeholder"
        >
          <div class="px-3">
            {{ trigger.pages.title || 'All pages' }}
          </div>
        </template>
      </pages-select-menu>
    </b-form-group>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import _ from 'lodash'
import ModalOnScrollTrigger from './triggers/ModalOnScrollTrigger'
import ModalOnTimerTrigger from './triggers/ModalOnTimerTrigger'
import PagesSelectMenu from '@/components/editor/components/pagesSelectMenu/PagesSelectMenu'

export default {
  name: 'ModalTriggers',
  components: {PagesSelectMenu, ModalOnTimerTrigger, ModalOnScrollTrigger},
  data () {
    return {
      options: [
        {
          trigger: null,
          label: 'None'
        },
        {
          trigger: 'onScroll',
          value: 20,
          label: 'Scroll page',
          pages: []
        },
        {
          trigger: 'onClick',
          label: 'Page click',
          pages: []
        },
        {
          trigger: 'onTimer',
          value: '00:00:30',
          label: 'Timer',
          pages: []
        },
        {
          trigger: 'onExit',
          label: 'Website exit',
          pages: []
        }
      ]
    }
  },

  computed: {
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('modal', {
      modal: state => state.modal
    }),
    trigger: {
      get () {
        const trigger = _.get(this.modal.settings, 'trigger.trigger', false)
        if (trigger === false) { return this.options[0] }
        return this.modal.settings.trigger
      },
      set (val) {
        this.$set(this.modal.settings, 'trigger', val)
      }
    }
  },

  methods: {
    triggerComponents () {
      return this.options.filter(t => t.hasOwnProperty('value') && t.trigger === this.trigger.trigger)
        .map(t => `Modal${_.startCase(t.trigger).replace(' ', '')}Trigger`)
    }
  }
}
</script>
