<template>
  <div>
    <div class="mb-[18px]">
      <label class="block !mb-[10px]">Margin</label>
      <b-form-radio-group
        v-model="position"
        :options="[{text: 'TOP', key: 'top'}, {text: 'BOTTOM', key: 'bottom'}]"
        buttons
        buttons-variant="light"
        class="resolution-style-tabs"
        size="sm"
        text-field="text"
        value-field="key"
      />
    </div>

    <b-row
      class="align-items-center pl-2"
      no-gutters
    >
      <b-col
        class="pr-3  pb-2"
        cols="8"
      >
        <vue-slider
          v-model="margin"
          :dot-size="17"
          :max="500"
          class="editor-slider-1 mt-2"
          tooltip="none"
        />
      </b-col>

      <b-col cols="3">
        <b-input
          v-model="margin"
          min="0"
          size="sm"
          style="width:71px;"
          type="number"
        />
      </b-col>
    </b-row>

    <div class="mt-2 mb-2">
      <b-checkbox v-model="enableSync">
        Sync margin settings
      </b-checkbox>
      <Panel
        v-if="enableSync"
        :config="display"
        :hide-all="false"
        class="bg-white mt-1"
      />
    </div>

    <div class="form-group">
      <span
        class="text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
        @click="resetMargin"
      >
        Remove margin for all devices
      </span>
    </div>
  </div>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'
import _ from 'lodash'
import ResolutionMixin from '../../mixins/ResolutionMixin'
import Panel from '@/components/editor/controls/Panel'
import SyncSettingsMixin from '@/components/mixins/SyncSettingsMixin'

export default {
  name: 'MarginCustomsV2',
  components: {Panel},
  mixins: [ControlMixin, ResolutionMixin, SyncSettingsMixin],

  data () {
    return {
      position: 'top'
    }
  },

  computed: {
    marginObject () {
      return _.get(this.config, [this.display.display !== true ? this.getDisplays[0] : this.resolutioner], {
        top: _.get(this.config, ['sm', 'top'], 0),
        bottom: _.get(this.config, ['sm', 'bottom'], 0)
      })
    },
    margin: {
      get () {
        return this.marginObject[this.position] || 0
      },
      set (val) {
        this.getDisplays.forEach(display => {
          this.$set(this.config, display, {
            ...this.marginObject,
            [this.position]: val
          })
        })
      }
    }
  },

  methods: {
    resetMargin () {
      this.$set(this.config, 'lg', {
        top: 0,
        bottom: 0,
        x: 0
      })
      this.$set(this.config, 'md', {
        top: 0,
        bottom: 0,
        x: 0
      })
      this.$set(this.config, 'sm', {
        top: 0,
        bottom: 0,
        x: 0
      })
    }
  }
}
</script>

<style lang="scss">
.btn-outline-custom {
  border-color: #e2e5ec;
  text-transform: uppercase;
  font-size: 15px;
  color: #6e747a;

  &.active {
    background-color: #7CACFA;
    color: #fff;
    box-shadow: inset 0 0 0 2px #7CACFA !important;
  }
}
</style>
