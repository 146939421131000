<template>
  <div
    v-if="settings.widget_sidebar.display_search"
    class="search -mx-6 p-4"
  >
    <h6 class="title mb-3">
      Blog Search
    </h6>

    <div class="position-relative">
      <b-input
        v-model="searchModel"
        class="form-control-pill search-input lg:placeholder-text-sm"
        placeholder="enter search term..."
        @keydown.enter="search"
      />
      <svg
        class="input-append-icon cursor-pinter"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="search"
      >
        <path
          d="M6.83332 1C3.61682 1 1 3.67369 1 6.96011C1 10.2465 3.61682 12.9202 6.83332 12.9202C10.0498 12.9202 12.6666 10.2465 12.6666 6.96011C12.6666 3.67369 10.0498 1 6.83332 1ZM6.83332 11.8776C4.17942 11.8776 2.02042 9.67169 2.02042 6.96011C2.02042 4.24852 4.17942 2.0426 6.83332 2.0426C9.48706 2.0426 11.6462 4.24852 11.6462 6.96011C11.6462 9.67169 9.48723 11.8776 6.83332 11.8776Z"
          fill="#7D7D7D"
          stroke="#7D7D7D"
          stroke-width="0.5"
        />
        <path
          d="M14.8485 15.054L11.0898 11.0269C10.8878 10.8104 10.5605 10.8104 10.3584 11.0269C10.1564 11.2432 10.1564 11.5942 10.3584 11.8105L14.1171 15.8376C14.2181 15.9459 14.3504 16 14.4828 16C14.6152 16 14.7474 15.9459 14.8485 15.8376C15.0505 15.6213 15.0505 15.2703 14.8485 15.054Z"
          fill="#7D7D7D"
          stroke="#7D7D7D"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import BlogSearchMixin from '../../mixins/BlogSearchMixin'

export default {
  name: 'SideSearch',
  mixins: [BlogSearchMixin]
}
</script>

<style lang="scss" scoped>
.search {
  background: #F2F2F2;
}

.form-control-pill {
  border-radius: 99px !important;
  font-size: 14px;
}

.input-append-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.search-input {
  height: 42px !important;
  &::placeholder {
    color: #A9A9A9;
  }
}

.title {
  font-weight: bold;
  font-size: 21px;
}
</style>

