<template>
  <div
    :style="{...fullMarginSubject(section.options.customize.style), ...fullPaddingSubject(section.options.customize.style)}"
  >
    <div
      class="flex w-100 flex-wrap align-items-center"
      :class="[alignment]"
      :style="{fontSize, color, gap, fontFamily, fontWeight}"
    >
      <div
        :style="prefixStyles"
        v-text="section.options.content.counter.prefix.text.lg"
      />
      <slot />
      <div
        :style="postfixStyles"
        v-text="section.options.content.counter.postfix.text.lg"
      />
    </div>
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin, BorderMixin],

  computed: {
    prefixStyles () {
      const fontSize = `${this.section.options.content.counter.prefix.fontSize[this.resolutioner] || this.section.options.content.counter.prefix.fontSize.lg}px`
      const fontFamily = this.section.options.content.counter.prefix.fontFamily || this.section.options.content.counter.fontFamily
      const fontWeight = this.section.options.content.counter.prefix.fontWeight || this.section.options.content.counter.fontWeight
      const color = this.section.options.content.counter.prefix.color || this.section.options.content.counter.color
      return {
        fontSize,
        fontFamily,
        fontWeight,
        color,
        ...this.fullMarginSubject(this.section.options.content.counter.prefix)
      }
    },
    postfixStyles () {
      const fontSize = `${this.section.options.content.counter.postfix.fontSize[this.resolutioner] || this.section.options.content.counter.postfix.fontSize.lg}px`
      const fontFamily = this.section.options.content.counter.postfix.fontFamily || this.section.options.content.counter.fontFamily
      const fontWeight = this.section.options.content.counter.postfix.fontWeight || this.section.options.content.counter.fontWeight
      const color = this.section.options.content.counter.postfix.color || this.section.options.content.counter.color
      return {
        fontSize,
        fontFamily,
        fontWeight,
        color,
        ...this.fullMarginSubject(this.section.options.content.counter.postfix)
      }
    },
    color () {
      return this.section.options.content.counter.color
    },
    fontFamily () {
      return this.section.options.content.counter.fontFamily
    },
    fontWeight () {
      return this.section.options.content.counter.fontWeight
    },
    fontSize () {
      let _fontSize = this.section.options.content.counter.size[this.resolutioner]
      if (_fontSize === null) {
        _fontSize = this.section.options.content.counter.size.lg
      }

      return `${_fontSize}px`
    },
    gap () {
      let _gap = this.section.options.content.counter.gap[this.resolutioner]
      if (_gap === null) {
        _gap = this.section.options.content.counter.gap.lg
      }

      return `${_gap}px`
    },
    alignment () {
      let _align = this.section.options.content.counter.align[this.resolutioner]
      if (_align === null) {
        _align = this.section.options.content.counter.size.lg
      }

      return `justify-content-${_align}`
    }
  }
}
</script>
