<template>
  <settings-page-card>
    <div class="px-4">
      <div class=" mb-[29px]">
        <h5 class="text-[25px] text-dark mb-[15px]">
          Google Fonts
        </h5>
        <p class="text-[16px] text-[#6E747A] max-w-[476px]">
          See <a
            href="https://fonts.google.com/"
            target="_blank"
          >Google Fonts</a> for live previews of Google's collection of free web
          fonts. Selected fonts will be
          available in the Webflow designer the next time you load it.
        </p>
      </div>

      <div>
        <div>
          <div class="mb-[34px]">
            <font-options />
            <edit-google-font-options v-if="editableGoogleFont !== false" />
          </div>

          <google-fonts />

          <hr class="mt-[39px] mb-[46px]">

          <div class=" mb-[29px]">
            <h5 class="text-[25px] text-dark mb-[15px]">
              Custom Fonts
            </h5>
            <p class="text-[16px] text-[#6E747A] max-w-[476px]">
              You can upload any web fonts and they will be added to the font family list. For maximum browser
              support upload TTF, OTF, EOT and WOFF.
            </p>
          </div>

          <custom-fonts class="mb-[41px]" />

          <font-fallback-settings />
        </div>
      </div>
    </div>
  </settings-page-card>
</template>

<script>
import {mapState} from 'vuex'
import SettingsPageCard from '@/layouts/dashboard/SettingsPageCard'
import FontOptions from '@/views/dashboard/fonts/FontOptions'
import GoogleFonts from '@/views/dashboard/fonts/GoogleFonts'
import EditGoogleFontOptions from '@/views/dashboard/fonts/EditGoogleFontOptions'
import CustomFonts from '@/views/dashboard/fonts/custom-fonts/CustomFonts'
import FontFallbackSettings from '@/views/dashboard/fonts/FontFallbackSettings'

export default {
  name: 'ProjectFonts',
  components: {
    FontFallbackSettings, CustomFonts, EditGoogleFontOptions, GoogleFonts, FontOptions, SettingsPageCard
  },
  computed: {
    ...mapState({
      project: state => state.projects.project,
      globalStyles: state => state.editor.globalStyles,
      editableGoogleFont: state => state.font.editableGoogleFont
    })
  },
  created () {
    this.$store.dispatch('font/fetchGoogleFonts')
    this.$store.dispatch('font/fetchCustomFonts')
  }
}
</script>