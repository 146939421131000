import SectionProvider from '@builder/sections/SectionProvider'
import {border, borderRadius} from '@builder/composition'

export const name = 'Row'
export const group = ''
export const standalone = true
export const allowRow = true
export const defaultOptions = {}

export const previews = {
  left: null,
  right: null,
  main: null
}

export default class Section extends SectionProvider {
  constructor (options = {}, cols) {
    super('ElRow', options)

    this.name = name
    this.group = group

    this.defaultOptions = {
      content: {
        columns: [
          {
            display: true,
            cols: 12 / cols,
            elements: []
          },
          {
            display: true,
            cols: 12 / cols,
            elements: []
          },
          {
            display: true,
            cols: 12 / cols,
            elements: []
          },
          {
            display: true,
            cols: 12 / cols,
            elements: []
          }
        ]
      },
      customize: {
        columnsToShow: cols,
        noGutters: false,
        layouts: false,
        style: {
          alignRow: {
            type: 'align-row-customs',
            align: 'start'
          },
          backgroundImage: {
            type: 'background-image-customs',
            src: null,
            position: 'center',
            size: 'cover',
            opacity: 1
          },
          background: {
            background: 'rgba(225,225,225,0)'
          },
          padding: {
            type: 'height-customs',
            sm: {
              top: 10,
              bottom: 10,
              x: 5
            },
            lg: {
              top: 20,
              bottom: 20,
              x: 10
            }
          },
          marginTop: {
            type: 'margin-top-customs',
            sm: 10,
            lg: 20
          },
          border,
          borderRadius
        }
      }
    }
  }
}
