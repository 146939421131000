<template>
  <div class="pt-[56px] pb-[70px] text-center">
    <h5 class="font-[700] text-[36px] mb-[50px]">
      Trusted by the biggest brands worldwide
    </h5>

    <div class="flex items-center justify-center flex-wrap gap-[24px]">
      <img
        v-for="(item, index) in parthners"
        :key="index"
        :src="item"
        class="img-fluid"
        alt="Parthner image"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'CloudflareEnterpriseBrands',
  data () {
    return {
      parthners: [
        'https://www.dropbox.com/s/kdh1lc4nk76gw69/image%2041.png?raw=1',
        'https://www.dropbox.com/s/xsga6v2v260ihza/image%2036.png?raw=1',
        'https://www.dropbox.com/s/lev7ogjpjkumdn5/image%2037.png?raw=1',
        'https://www.dropbox.com/s/zcvadnur4jv78d3/image%2038.png?raw=1',
        'https://www.dropbox.com/s/sgcthnf7852pvkb/image%2039.png?raw=1',
        'https://www.dropbox.com/s/d31wujj1rqjqm6k/image%2040.png?raw=1',
        'https://www.dropbox.com/s/pi4wn872f15jowj/Main%20Logo.png?raw=1'
      ]
    }
  }
}
</script>