import { Mark } from 'tiptap'
import { updateMark, removeMark } from 'tiptap-commands'

export default class Typed extends Mark {

  get name () {
    return 'typed'
  }

  get defaultOptions () {
    return {
      words: [''],
      speed: [],
      backspeed: [],
      fade: []
    }
  }

  get schema () {
    return {
      attrs: {
        speed: {
          default: []
        },
        backspeed: {
          default: []
        },
        fade: {
          default: []
        },
        words: {
          default: ''
        }
      },
      parseDOM: this.options.words.map(words => ({
        tag: 'typed',
        attrs: { words },
        getAttrs (dom) {
          return {
            words: dom.getAttribute('words'),
            speed: dom.getAttribute('speed'),
            backspeed: dom.getAttribute('backspeed'),
            fade: dom.getAttribute('fade')
          }
        }
      })),
      toDOM:
        node => {
          return ['typed', {
            speed: node.attrs.speed,
            backspeed: node.attrs.backspeed,
            fade: node.attrs.fade,
            words: node.attrs.words
          }, 0]
        }
    }
  }

  commands ({ type }) {
    return (attrs) => {
      if (!attrs) {
        return removeMark(type, attrs)
      }

      return updateMark(type, attrs)
    }
  }
}
