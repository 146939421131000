<template>
  <b-modal
    v-model="modal"
    body-class="text-center py-5"
    size="lg"
    hide-footer
    hide-header
    centered
  >
    <h4
      class="h2 font-weight-bold mb-4"
      v-html="title"
    />

    <p
      class="my-4"
      v-html="description"
    />


    <div class="d-flex align-items-center justify-content-center">
      <b-btn
        variant="danger"
        class="btn-wide mr-2"
        @click="() => $store.dispatch('confirmationDialog/confirm')"
      >
        Yes
      </b-btn>
      <b-btn
        class="btn-wide"
        @click="() => $store.dispatch('confirmationDialog/cancel')"
      >
        Cancel
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'

export default  {
  name: 'ProgrammaticallyConfirmationDialog',
  computed: {
    ...mapState('confirmationDialog', {
      modalState: state => state.modal,
      title: state => state.title,
      description: state => state.description
    }),
    modal: {
      get () {
        return this.modalState
      },
      set (val) {
        if (!val) {
          this.$store.dispatch('confirmationDialog/cancel')
        }
      }
    }
  }
}
</script>