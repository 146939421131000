<template>
  <div
    :data-uid="section.uid"
    :class="`--${section.sid}--margin`"
  >
    <highliter
      v-model="section.name"
      active-class="z-100"
      class="highlight-element"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <div
        :data-sid="section.sid"
        :class="`--${section.sid}--container`"
        class="flex flex-row"
      >
        <div
          :class="`--${section.sid}--previews-button`"
          class="flex items-baseline gap-[12px] cursor-pinter transition"
        >
          <i 
            class="fa fa-arrow-left"
            :class="`--${section.sid}--previous-button-icon`"
          />
  
          {{ section.options.content.previousButton.text }}
        </div>
      </div>
    </highliter>
  </div>
</template>

<script>
import Highliter from '@/components/builder/utils/Highliter.vue'
import SectionMixin from '@/components/mixins/SectionMixin'

export default {
  name: 'ElPreviewsButton',
  components: {Highliter},
  mixins: [SectionMixin]
}
</script>