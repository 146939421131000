<template>
  <div>
    <SidbearSettingsGroup title="Label Styles">
      <sid-font
        prefix="Label"
        :sid="`.--${section.sid}--form legend`"
      />

      <sid-range-slider
        label="Label Font Size"
        :sid="`.--${section.sid}--form legend`"
        rule-name="font-size"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Label Font Color</label>

          <sid-color
            :sid="`.--${section.sid}--form legend`"
            rule-name="color"
          />
        </div>
      </b-form-group>

      <sid-align 
        label="Label Alignment" 
        :sid="`.--${section.sid}--form legend`"
        rule-name="text-align"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Placeholder Styles">
      <sid-font
        prefix="Placeholder"
        :sid="`.--${section.sid}--form .input.form-control::placeholder`"
      />

      <sid-range-slider
        label="Placeholder Font Size"
        :sid="`.--${section.sid}--form .input.form-control::placeholder`"
        rule-name="font-size"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Placeholder Font Color</label>

          <sid-color
            :sid="`.--${section.sid}--form .input.form-control::placeholder`"
            rule-name="color"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Input Styles">
      <sid-font
        prefix="Input"
        :sid="`.--${section.sid}--form .input.form-control`"
      />

      <sid-range-slider
        label="Input Font Size"
        :sid="`.--${section.sid}--form .input.form-control`"
        rule-name="font-size"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Input Font Color</label>

          <sid-color
            :sid="`.--${section.sid}--form .input.form-control`"
            rule-name="color"
          />
        </div>
      </b-form-group>

      <sid-range-slider
        label="Input Height"
        :sid="`.--${section.sid}--form .input.form-control`"
        rule-name="height"
      />
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Background">
      <rule-tabs>
        <template #Normal>
          <sid-background :sid="`.--${section.sid}--form .input.form-control`" />
        </template>
        <template #Hover>
          <sid-background
            :sid="`.--${section.sid}--form .input.form-control`"
            presudo=":hover"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--form .input.form-control`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  },
                  {
                    label: 'Dashed',
                    value: 'dashed'
                  },
                  {
                    label: 'Dotted',
                    value: 'dotted'
                  },
                  {
                    label: 'Double',
                    value: 'double'
                  },
                  {
                    label: 'Outset',
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--form .input.form-control`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--form .input.form-control`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--form .input.form-control`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--form .input.form-control`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  },
                  {
                    label: 'Dashed',
                    value: 'dashed'
                  },
                  {
                    label: 'Dotted',
                    value: 'dotted'
                  },
                  {
                    label: 'Double',
                    value: 'double'
                  },
                  {
                    label: 'Outset',
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--form .input.form-control`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--form .input.form-control`"
                rule-name="border-color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--form .input.form-control`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            presudo=":hover"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Drop Shadow">
      <rule-tabs>
        <template #Normal>
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--form .form-control.input`"
              rule-name="box-shadow"
            />
          </b-form-group>
        </template>
        <template #Hover>
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.form-control.input`"
              rule-name="box-shadow"
              presudo=":hover"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Padding, Margins & Gaps">
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--form .form-control.input`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--form .form-margin`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-range-slider
        label="Horizontal Gap"
        :sid="`.--${section.sid}--content .--${section.sid}--form`"
        rule-name="column-gap"
      />

      <sid-range-slider
        label="Vertical Gap"
        :sid="`.--${section.sid}--content .--${section.sid}--form`"
        rule-name="row-gap"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'

export default {
  name: 'OrderFormFieldStyle',
  components: {
    SidBoxShadow,
    SidAlign,
    SidBackground,
    SidSelect,
    SidLinkedGroup,
    SidColor,
    SidRangeSlider,
    SidFont,
    SidbearSettingsGroup,
    RuleTabs
  },
  props: {
    section: {
      type: Object,
      required: true
    }
  }
}
</script>