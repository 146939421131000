<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element"
    :dublicate-deep="parentUID"
    :dublicate="section"
    :hide-bar="hideBar"
    :hide-add="hideAdd"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="onDelete"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('chat')"
  >
    <div
      :data-sid="section.sid"
    >
      <div
        class="es-live-chat overflow-hidden"
        :class="[`--${section.sid}--chat`, {'element-hidden': !visability()}]"
      >
        <div class="info">
          <div class="flex items-center info-title">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 8H20C20.5523 8 21 8.44772 21 9V20L17.6667 17.2308C17.4872 17.0817 17.2611 17 17.0277 17H9C8.44771 17 8 16.5523 8 16V13"
                stroke="#3D82EA"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15 4H4C3.44772 4 3 4.44772 3 5V16L6.33329 13.2308C6.51283 13.0817 6.73889 13 6.97231 13H15C15.5523 13 16 12.5523 16 12V5C16 4.44772 15.5523 4 15 4Z"
                stroke="#3D82EA"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Live Chat
          </div>
        </div>
        <div class="online-status flex items-center w-100">
          {{ online }} Currently Online
          <div class="cursor-pointer ml-auto">
            Logout
          </div>
        </div>
        <div
          v-show="section.options.content.pinnedMessage"
          class="pinned-message"
          v-html="section.options.content.pinnedMessage"
        />
        <div class="chat-box messages">
          <template v-if="!messages.length">
            <div>
              <div class="message flex">
                <img
                  class="user-avatar"
                  src="https://gravatar.com/avatar/6ef803b24975a2455543d40f3f0d9f8d"
                  alt="Avatar"
                >
                <div class="msg">
                  <span class="message-user-name cursor-pointer">Demo User</span> Lorem ipsum dolor sit amet, consectetur adipisicing elit...
                </div>
              </div>
            </div>
            <div>
              <div class="message flex">
                <img
                  class="user-avatar"
                  src="https://gravatar.com/avatar/6ef803b24975a2455543d40f3f0d9f8d"
                  alt="Avatar"
                >
                <div class="msg">
                  <span class="message-user-name cursor-pointer">Demo User</span> Lorem ipsum dolor sit amet, consectetur.
                </div>
              </div>
            </div>
            <div>
              <div class="message replied flex">
                <img
                  class="user-avatar"
                  src="https://gravatar.com/avatar/6ef803b24975a2455543d40f3f0d9f8d"
                  alt="Avatar"
                >
                <div class="msg">
                  <span class="message-user-name cursor-pointer">Demo User</span> @Usernane, Lorem ipsum dolor sit amet, consectetur.
                </div>
              </div>
            </div>
            <div>
              <div class="message flex">
                <img
                  class="user-avatar"
                  src="https://gravatar.com/avatar/6ef803b24975a2455543d40f3f0d9f8d"
                  alt="Avatar"
                >
                <div class="msg">
                  <span class="message-user-name cursor-pointer">Demo User</span>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit 😃
                </div>
              </div>
            </div>
            <div>
              <div class="message flex">
                <img
                  class="user-avatar"
                  src="https://gravatar.com/avatar/6ef803b24975a2455543d40f3f0d9f8d"
                  alt="Avatar"
                >
                <div class="msg">
                  <span class="message-user-name cursor-pointer">Demo User</span> Lorem ipsum dolor 😄 😍 💗
                </div>
              </div>
            </div>
            <div>
              <div class="message flex">
                <img
                  class="user-avatar"
                  src="https://gravatar.com/avatar/6ef803b24975a2455543d40f3f0d9f8d"
                  alt="Avatar"
                >
                <div class="msg">
                  <span class="message-user-name cursor-pointer">Demo User</span> Lorem ipsum dolor sit amet, consectetur adipisicing elir ....
                </div>
              </div>
            </div>
            <div>
              <div class="message flex">
                <img
                  class="user-avatar"
                  src="https://gravatar.com/avatar/6ef803b24975a2455543d40f3f0d9f8d"
                  alt="Avatar"
                >
                <div class="msg">
                  <span class="message-user-name cursor-pointer">Demo User</span> Lorem ipsum dolor sit amet, consectetur.
                </div>
              </div>
            </div>
          </template>
          <div
            v-for="message in messages"
            :key="message.id"
          >
            <div class="message flex">
              <img
                class="user-avatar"
                :src="gravatarUrl(message.email)"
                alt="Avatar"
              >
              <div class="msg">
                <span class="message-user-name cursor-pointer">{{ message.username }}</span> {{ message.text }}
              </div>
            </div>
          </div>
        </div>
        <div class="composer-wrapper">
          <form class="position-relative">
            <input
              class="composer"
              placeholder="Type a message..."
              maxlength="100"
              required=""
              type="text"
            >
            <div class="v3-input-picker-icon">
              😄
            </div>
            <button
              class="submit-button"
              type="submit"
            >
              <i class="fa-regular fa-paper-plane" />
            </button>
          </form>
        </div>
        <div class="d-none ban-message" />
      </div>
    </div>
  </highliter>
</template>

<script>
import {mapState} from 'vuex'
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import gravatar from 'gravatar'
import {db as $firebase, realTimeDB as $database} from '@/firebase'
import {collection, doc, onSnapshot, where, query} from 'firebase/firestore'
import {ref, onValue} from 'firebase/database'

export default {
  name: 'ElCardCarousel',

  components: {Highliter},

  mixins: [SectionMixin],

  data () {
    return {
      online: 0,
      messages: [],
      unsubMessages: null
    }
  },

  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    roomID () {
      return this.section.options.content.chat.roomID
    }
  },

  created () {
    if (!this.section.options.content.chat.roomID) {
      axios.get(`https://estage.site/api/live-chat/create?hash=${this.user.hash}`)
        .then(({data}) => {
          this.section.options.content.chat.roomID = data.id
        })
    }
  },

  methods: {
    gravatarUrl (email) {
      return gravatar.url(email)
    },
    onDelete () {
      this.$emit('delete')
    }
  },

  async mounted () {
    const roomRef = doc(collection($firebase, 'rooms'), this.roomID)
    const messagesCollectionRef = collection(roomRef, 'messages')
    const counterRef = ref($database, `/online-countersV2/${this.roomID}`)

    let lastFetchedTimestamp = new Date()
    this.unsubMessages = onSnapshot(query(messagesCollectionRef, where('timestamp', '>', lastFetchedTimestamp)), snapshot => {
      let newMessages = []
      
      snapshot.docChanges().forEach(change => {
        if (change.type === 'added') {
          let docTimestamp = change.doc.data().timestamp.toDate()

          if (docTimestamp > lastFetchedTimestamp) {
            lastFetchedTimestamp = docTimestamp
          }

          newMessages.push({id: change.doc.id, ...change.doc.data()})
        }
      })

      this.messages = [...this.messages, ...newMessages].slice(-100)
        .filter((obj, index, self) =>
          index === self.findIndex((el) => (
            el.id === obj.id
          ))
        )
    })

    onValue(counterRef, snapshot => {
      this.online = snapshot.size
    })
  },
  
  beforeDestroy () {
    if (this.unsubMessages) {
      this.unsubMessages()
    }
  }
}
</script>

<style lang="scss">
.es-live-chat {
  display: flex;
  flex-direction: column;
  height: 600px;
  --height: 600px;
  background-color: #F2F5FA;

  .info {
    padding: 15px 23px;

    .info-title {
      gap: 7px;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;

      svg path {
        stroke: #3D82EA;
      }
    }
  }

  .online-status {
    background-color: rgba(242, 245, 250, 1);
    color: #3D82EA;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    padding: 8px 24px;
  }

  .pinned-message {
    background-color: #F2F5FA;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    padding: 8px 24px;

    p {
      margin-bottom: 0 !important;
    }
  }

  .ban-message {
    text-align: center;
    padding: 30px;
    font-size: 14px;
    color: #000;
  }

  .chat-box {
    background-color: #fff;
    overflow: auto;
    padding: 25px 0;
    height: 100%;

    .message {
      gap: 10px;
      padding: 10px 20px;

      .user-avatar {
        width: 24px;
        height: 24px;
        border-radius: 50px;
      }

      .message-user-name {
        color: #3D3D3D;
        font-size: 12px;
        font-weight: 700;
        max-width: 80px;
        margin-right: 2px;
      }

      .msg {
        font-size: 12px;
        color: #717171;
      }
    }

    &.chat-login {
      form {
        padding: 20px;

        input {
          background-color: #F2F5FA;
          height: 48px;
          width: 100%;
          margin-bottom: 9px;
          padding: 0 15px;
        }

        button {
          position: relative;
          color: #666666;
          font-size: 16px;
          background-color: #F2F5FA;
          height: 48px;
          width: 100%;

          i {
            position: absolute;
            left: 0;
            top: 0;
            padding: 16px;
            background: #E2E6EE;
          }
        }
      }
    }

    &.disabled-chat {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #717171;
      font-size: 12px;

      svg {
        margin-bottom: 15px;
      }
    }
  }

  .composer-wrapper {
    background-color: #F2F5FA;
    padding: 20px 18px;

    .submit-button {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background: transparent;
      padding: 0;

      i {
        color: #005CE8;
        font-size: 16px;
      }
    }

    .composer {
      height: 48px;
      --height: 48px;
      padding: 0 20px;
      width: 100%;
      padding-right: 75px;

      &::placeholder {
        color: #8a8d90;
        font-size: 16px;
      }
    }
  }
}

.es-live-chat-fixed {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 300px;
  z-index: 1000;

  .chat-box {
    height: calc(100% - 200px);
  }
}

.v3-input-picker-icon {
  top: 50% !important;
  font-size: 20px;
  transform: translateY(-50%);
  right: 40px !important;
  position: absolute !important;
}
</style>