<template>
  <b-list-group-item class="blog-item">
    <b-row class="align-items-center">
      <b-col class="text-truncate">
        <div class="created_at mb-2">
          {{ modal.created_at | moment("DD/MM/YY [at] h:mm a") }}
        </div>
        <div
          class="blog-label text-truncate mb-0"
          v-text="modal.name"
        />
      </b-col>
      <b-col class="text-truncate">
        <div class="d-flex align-items-center text-muted overflow-auto">
          <span
            class="indicator mr-2"
            :class="modal.is_active ? 'active' : 'disabled'"
          />
          {{ modal.is_active ? 'Active' : 'Deactivated' }}
        </div>
      </b-col>
      <b-col>
        <b-btn
          v-if="modal.is_active"
          variant="white"
          @click="$store.dispatch('modal/deactivate', modal)"
        >
          Deactivate
        </b-btn>
        <b-btn
          v-else
          variant="white"
          @click="$store.dispatch('modal/activate', modal)"
        >
          Activate
        </b-btn>
      </b-col>
      <b-col>
        <transition name="slide-fade">
          <div
            v-if="showButtons"
            class="d-flex justify-content-end align-items-center"
          >
            <b-link
              v-b-tooltip.top
              class="mr-4"
              title="Preview"
              @click="preview"
            >
              <svg
                width="21"
                height="13"
                viewBox="0 0 21 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.7354 5.5986C19.695 5.54112 18.7259 4.17461 17.0646 2.8026C14.8446 0.969127 12.4289 0 10.0789 0C7.72898 0 5.31336 0.969127 3.09324 2.8026C1.43193 4.17457 0.462872 5.54112 0.42246 5.5986L0.0789795 6.08693L0.422503 6.57531C0.462915 6.63279 1.43197 7.99935 3.09329 9.37136C5.31341 11.2048 7.72902 12.1739 10.079 12.1739C12.4289 12.1739 14.8446 11.2048 17.0646 9.37136C18.7259 7.99935 19.695 6.63279 19.7354 6.57531L20.079 6.08693L19.7354 5.5986ZM10.0789 10.3512C7.98721 10.3512 6.28544 8.43826 6.28544 6.08693C6.28544 5.71854 6.32721 5.36093 6.40571 5.01972L5.53963 4.85587C5.45478 5.25084 5.40977 5.66308 5.40977 6.08693C5.40977 7.60576 5.98692 8.97576 6.90734 9.93504C5.72252 9.44307 4.71502 8.75271 3.95463 8.12834C3.01259 7.3549 2.30658 6.57265 1.90408 6.08693C2.30676 5.60106 3.01268 4.81891 3.95463 4.04552C4.71502 3.42115 5.72256 2.73079 6.90734 2.23877L7.48834 2.97496C8.16689 2.26072 9.0782 1.82269 10.0789 1.82269C12.1706 1.82269 13.8723 3.7356 13.8723 6.08693C13.8723 8.43826 12.1706 10.3512 10.0789 10.3512ZM13.2505 9.93504C14.4353 9.44307 15.4427 8.75271 16.2032 8.12835C17.1451 7.355 17.8511 6.5728 18.2537 6.08683C17.8512 5.60116 17.1452 4.81891 16.2032 4.04547C15.4427 3.42111 14.4353 2.73074 13.2505 2.23872C14.1709 3.19801 14.748 4.56796 14.748 6.08688C14.748 7.60581 14.1709 8.97581 13.2505 9.93504Z"
                  fill="#6E747A"
                />
                <path
                  d="M7.87161 5.32471C7.80651 5.56588 7.77124 5.82172 7.77124 6.08697C7.77124 7.52767 8.80443 8.69566 10.079 8.69566C11.3534 8.69566 12.3866 7.52772 12.3866 6.08697C12.3866 4.64622 11.3534 3.47827 10.079 3.47827C9.45817 3.47827 8.89507 3.75588 8.48027 4.20664L9.61855 5.65703L7.87161 5.32471Z"
                  fill="#6E747A"
                />
              </svg>
            </b-link>

            <b-link
              v-b-tooltip.top
              class="mr-4"
              title="Edit"
              @click="() => $router.push({name: 'modal.editor', params: {modal_id: modal.id}})"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M13.1427 8.00003C12.8271 8.00003 12.5712 8.25586 12.5712 8.57146V14.2857C12.5712 14.6012 12.3154 14.8571 11.9998 14.8571H1.71426C1.39866 14.8571 1.14283 14.6012 1.14283 14.2857V2.85724C1.14283 2.54164 1.39866 2.28581 1.71426 2.28581H8.57131C8.88691 2.28581 9.14275 2.02997 9.14275 1.71437C9.14275 1.39878 8.88691 1.14297 8.57131 1.14297H1.71426C0.767501 1.14297 0 1.91048 0 2.85724V14.2857C0 15.2324 0.767501 15.9999 1.71426 15.9999H11.9998C12.9466 15.9999 13.7141 15.2324 13.7141 14.2857V8.57143C13.7141 8.25586 13.4583 8.00003 13.1427 8.00003Z"
                    fill="#6E747A"
                  />
                  <path
                    d="M15.3595 0.640904C14.9492 0.230552 14.3927 6.41474e-05 13.8124 0.000131111C13.2318 -0.00154297 12.6748 0.229347 12.2656 0.641272L4.73882 8.16742C4.67638 8.23033 4.62927 8.30681 4.60111 8.39085L3.45828 11.8194C3.35853 12.1188 3.52042 12.4424 3.81985 12.5421C3.87794 12.5614 3.93877 12.5713 3.99998 12.5714C4.06132 12.5713 4.12229 12.5614 4.18054 12.5422L7.60907 11.3994C7.69328 11.3713 7.76979 11.3239 7.8325 11.2611L15.3593 3.73438C16.2135 2.88019 16.2136 1.49519 15.3595 0.640904ZM14.5513 2.92693L7.12279 10.3554L4.90338 11.0965L5.64222 8.87998L13.0736 1.45153C13.4821 1.04379 14.1439 1.04446 14.5516 1.453C14.7464 1.64817 14.8561 1.91237 14.857 2.18809C14.8577 2.46529 14.7476 2.73127 14.5513 2.92693Z"
                    fill="#6E747A"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </b-link>

            <b-link
              v-b-tooltip.top
              class="mr-4 mt-2"
              title="Duplicate"
              @click="duplicateModal"
            >
              <i class="copy-icon" />
            </b-link>

            <delete-confirm
              title="Are you sure?"
              description="The modal will be deleted, the action cannot be undone."
              @confirm="deleteModal"
            >
              <template v-slot:activator="{on}">
                <b-link
                  v-b-tooltip.top
                  title="Delete"
                  v-on="on"
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6395 6.03584L9.37117 5.98779L9.09619 13.7428L10.3645 13.7907L10.6395 6.03584Z"
                      fill="#6E747A"
                    />
                    <path
                      d="M8.18616 6.01184H6.91699V13.7669H8.18616V6.01184Z"
                      fill="#6E747A"
                    />
                    <path
                      d="M6.00676 13.7427L5.73178 5.98761L4.46344 6.03569L4.73845 13.7907L6.00676 13.7427Z"
                      fill="#6E747A"
                    />
                    <path
                      d="M0 2.77875V4.08945H1.32249L2.37165 16.4021C2.40041 16.7405 2.67479 17 3.0037 17H12.0781C12.4071 17 12.6816 16.7403 12.7102 16.4019L13.7594 4.08945H15.1032V2.77875H0ZM11.4969 15.6893H3.5848L2.59653 4.08945H12.4853L11.4969 15.6893Z"
                      fill="#6E747A"
                    />
                    <path
                      d="M9.94428 0H5.84654C5.27014 0 4.80121 0.53181 4.80121 1.1855V3.438H6.0556V1.42261H9.73521V3.438H10.9896V1.1855C10.9896 0.53181 10.5207 0 9.94428 0Z"
                      fill="#6E747A"
                    />
                  </svg>
                </b-link>
              </template>
            </delete-confirm>
          </div>
        </transition>
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
import RouterMixin from '../../../components/mixins/RouterMixin'
import DeleteConfirm from '../../../components/editor/modals/DeleteConfirm'
import {mapState} from 'vuex'

export default {
  name: 'EsModalListItem',
  components: {DeleteConfirm},
  mixins: [RouterMixin],
  props: {
    modal: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      showButtons: true
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  methods: {
    duplicateModal () {
      VEvent.fire('loader', true)
      axios.post(`api/projects/${this.project.id}/modals/${this.modal.id}/duplicate`)
        .then(() => {
          this.$emit('duplicated')
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    },
    preview () {
      this.$store.commit('modal/SHOW_MODAL', false)
      return this.$store.dispatch('modal/load', this.modal.id)
    },
    deleteModal () {
      this.$store.dispatch('modal/modalDelete', this.modal)
        .then(() => {
          this.$emit('deleted')
          this.$swal({
            icon: 'success',
            title: 'Modal Deleted',
            showConfirmButton: false,
            timer: 1500
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@builder/assets/scss/_variables.scss";

.created_at {
  color: #888d92;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
}

.blog-label {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
  transition: .3s;
}

.blog-item {
  border-color: #E2E5EC;
  padding: 28px 40px;
  transition: .3s;

  &:hover {
    background: #FAFCFF;

    .blog-label {
      color: $primary;
    }
  }

  .blog-stat-info {
    color: #6E747A;

    svg path {
      fill: #6E747A;
    }

    &.disabled {
      color: #D1D2D3;

      svg path {
        fill: #D1D2D3;
      }
    }
  }
}
</style>
