<template>
  <div>
    <SidbearSettingsGroup
      title="Card Settings"
      active
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>
      
      <b-form-group>
        <b-checkbox
          v-model="section.options.content.flipCard.active"
          switch
        >
          <div class="mt-1">
            Flip to Edit Front/Back
          </div>
        </b-checkbox>
      </b-form-group>

      <b-form-group
        v-if="section.options.content.flipCard.direction"
        label="Flip Direction"
      >
        <v-select
          v-model="section.options.content.flipCard.direction[resolutioner]"
          :reduce="val => val.key"
          :options="variants"
          :searchable="false"
          append-to-body
          class="custom-select"
        />
      </b-form-group>

      <b-form-group>
        <sid-range-slider
          rule-name="width"
          :sid="`.--${section.sid}--card`"
          default-dimension="%"
          :dimensions="['%', 'vw', 'px']"
          label="Width"
          var="--width"
          measurement="width"
        />
      </b-form-group>

      <sid-range-slider
        rule-name="height"
        :sid="`.--${section.sid}--card`"
        default-dimension="px"
        :dimensions="['px', 'vh']"
        label="Height"
        var="--height"
        reset-value="300px"
        measurement="height"
      />

      <sid-buttons-group
        label="Vertical Content Alignment"
        :options="[
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'start',
            tooltip: 'Start'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'center',
            tooltip: 'Center'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'end',
            tooltip: 'End'
          }
        ]"
        :sid="`.--${section.sid}--card ${currentSideSid}`"
        rule-name="align-items"
      />

      <sid-buttons-group
        label="Horizontal Alignment"
        :options="[
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'start',
            tooltip: 'Start'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'center',
            tooltip: 'Center'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'end',
            tooltip: 'End'
          }
        ]"
        :sid="`.--${section.sid}--card ${currentSideSid}`"
        rule-name="justify-content"
      />
    </SidbearSettingsGroup>

    <div
      v-for="n in [{key: 'front', label: 'Front'}, {key: 'back', label: 'Back'}]"
      :key="n.key"
    >
      <SidbearSettingsGroup
        :title="`${n.label} Side`"
      >
        <b-form-group>
          <div class="flex items-center justify-between">
            <label class="pb-0">Border Type</label>

            <sid-select
              :sid="`.--${section.sid}--card .es_card-content`"
              rule-name="border-style"
              :searchable="false"
              class="w-[118px]"
              :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
            />
          </div>
        </b-form-group>

        <b-form-group>
          <sid-linked-group
            :sid="`.--${section.sid}--card .es_card-content`"
            label="Border Width"
            rule-name="border"
            css-var="--border-size"
            class="!mb-[7px]"
            :angles="[
              {
                text: '',
                value: 'top-width'
              },
              {
                text: '',
                value: 'right-width'
              },
              {
                text: '',
                value: 'bottom-width'
              },
              {
                text: '',
                value: 'left-width'
              }
            ]"
          />

          <div class="flex w-full justify-end">
            <sid-color
              :sid="`.--${section.sid}--card .es_card-content`"
              rule-name="border-color"
            />
          </div>
        </b-form-group>

        <sid-linked-group
          label="Radius"
          :sid="`.--${section.sid}--card .es_card-content`"
          rule-name="border"
          :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
          :angles="[
            {
              text: '',
              value: 'top-left-radius',
              cssVar: '--border-top-left-radius'
            },
            {
              text: '',
              value: 'top-right-radius',
              cssVar: '--border-top-right-radius'
            },
            {
              text: '',
              value: 'bottom-left-radius',
              cssVar: '--border-bottom-left-radius'
            },
            {
              text: '',
              value: 'bottom-right-radius',
              cssVar: '--border-bottom-right-radius'
            }
          ]"
        />

        <b-form-group
          label="Shadow Color"
        >
          <sid-box-shadow
            :sid="`.--${section.sid}--card .es_card-${n.key}`"
            rule-name="box-shadow"
          />
        </b-form-group>

        <sid-linked-group
          label="Padding"
          :sid="`.--${section.sid}--card .es_card-${n.key}`"
          rule-name="padding"
          :min="0"
          :angles="[
            {
              text: '',
              value: 'top'
            },
            {
              text: '',
              value: 'right'
            },
            {
              text: '',
              value: 'bottom'
            },
            {
              text: '',
              value: 'left'
            }
          ]"
        />
      </SidbearSettingsGroup>
      <SidbearSettingsGroup
        :title="`${n.label} Side Background`"
      >
        <sid-background
          class="mb-0"
          :sid="`.--${section.sid}--card .es_card-${n.key}`"
        />

        <sid-upload-image
          :sid="`.--${section.sid}--card .es_card-${n.key} .block-bg`"
          rule-name="background-image"
        />

        <b-form-group label="Background Image Size">
          <sid-select
            priority
            :searchable="false"
            :options="[
              {
                label: 'Normal',
                value: '',
                customProperties: [[`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-attachment', 'unset']]]
              },
              {
                label: 'Full Center (Parallax)',
                value: 'fixed',
                customProperties: [[`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-size', 'cover']], [`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-attachment', 'fixed']]]
              },
              {
                label: 'Fill 100 % Width',
                value: '100% auto',
                customProperties: [[`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-attachment', 'unset']]]
              },
              {
                label: 'Fill 100 % Width & Height',
                value: '100% 100%',
                customProperties: [[`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-attachment', 'unset']]]
              },
              {
                label: 'Repeat',
                value: 'repeat',
                property: 'background-repeat',
                customProperties: [[`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-size', 'contain']], [`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-repeat', 'repeat']], [`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-attachment', 'unset']]]
              },
              {
                label: 'Repeat Horizontally',
                value: 'repeat-x',
                property: 'background-repeat',
                customProperties: [[`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-size', 'contain']], [`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-repeat', 'repeat-x']], [`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-attachment', 'unset']]]
              },
              {
                label: 'Repeat Vertically',
                value: 'repeat-y',
                property: 'background-repeat',
                customProperties: [[`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-size', 'contain']], [`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-repeat', 'repeat-y']], [`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-attachment', 'unset']]]
              },
              {
                label: 'Cover',
                value: 'cover',
                customProperties: [[`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-size', 'cover']], [`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-attachment', 'unset']]]
              },
              {
                label: 'Contain',
                value: 'contain',
                customProperties: [[`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-size', 'contain']], [`.--${section.sid}--card .es_card-${n.key} > .block-bg`, ['background-attachment', 'unset']]]
              }
            ]"
            :sid="`.--${section.sid}--card .es_card-${n.key} > .block-bg`"
            var="--background-size"
            rule-name="background-size"
          />
        </b-form-group>

        <b-form-group label="Background Image Position">
          <sid-select
            :searchable="false"
            :options="[
              {label: 'Left Top', value: 'left top'},
              {label: 'Left Center', value: 'left center'},
              {label: 'Left Bottom', value: 'left bottom'},
              {label: 'Right Top', value: 'right top'},
              {label: 'Right Center', value: 'right center'},
              {label: 'Right Bottom', value: 'right bottom'},
              {label: 'Center Top', value: 'center top'},
              {label: 'Center Center', value: 'center center'},
              {label: 'Center Bottom', value: 'center bottom'},
            ]"
            :sid="`.--${section.sid}--card .es_card-${n.key} > .block-bg`"
            rule-name="background-position"
          />
        </b-form-group>

        <sid-range-slider
          :sid="`.--${section.sid}--card .es_card-${n.key} > .block-bg`"
          label="Custom Size"
          rule-name="background-size"
          var="--custom-bg-size"
          unlim
          :dimensions="['px', '%', 'vh', 'vw']"
        />

        <b-form-group label="Transform-Y">
          <sid-range-slider
            :sid="`.--${section.sid}--card .es_card-${n.key} > .block-bg`"
            rule-name="background-position-y"
          />
        </b-form-group>

        <b-form-group label="Transform-X">
          <sid-range-slider
            :sid="`.--${section.sid}--card .es_card-${n.key} > .block-bg`"
            rule-name="background-position-X"
          />
        </b-form-group>

        <sid-overlay
          :sid="`.--${section.sid}--card .es_card-${n.key} > .block-bg + .el-overlay`"
          rule-name=""
        />

        <hr>

        <sid-background-filters
          :sid="`.--${section.sid}--card .es_card-${n.key} > .block-bg`"
          rule-name="filter"
        />

        <b-form-group>
          <sid-range-slider
            label="Opacity"
            rule-name="opacity"
            :dimensions="[]"
            default-dimension=""
            :sid="`.--${section.sid}--card .es_card-${n.key} > .block-bg`"
            :min="0"
            :max="1"
            :step="0.1"
          />
        </b-form-group>
      </SidbearSettingsGroup>
    </div>

    <SidbearSettingsGroup title="Border & Radius">
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--card .es_card-content`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--card .es_card-content`"
              label="Border Width"
              rule-name="border"
              css-var="--border-size"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--card .es_card-content`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--card .es_card-content`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--card .es_card-content`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--card .es_card-content`"
              label="Border Width"
              rule-name="border"
              css-var="--border-size"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--card .es_card-content`"
                rule-name="border-color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--card .es_card-content`"
            rule-name="border"
            presudo=":hover"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <rule-tabs>
        <template #Normal>
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--card .es_card-content`"
              rule-name="box-shadow"
            />
          </b-form-group>
        </template>

        <template #Hover>
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--card .es_card-content`"
              presudo=":hover"
              rule-name="box-shadow"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--card .es_card-content`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--card`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidUploadImage
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidUploadImage'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidBackgroundFilters
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundFilters'
import SidOverlay from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidOverlay'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup'
import RuleTabs from '@/components/editor/components/RuleTabs'
export default {
  name: 'FlipCardMod',
  components: {
    RuleTabs,
    SidButtonsGroup,
    SidLinkedGroup,
    SidColor,
    SidBoxShadow,
    SidOverlay,
    SidBackgroundFilters, SidRangeSlider, SidUploadImage, SidSelect, SidBackground, SidbearSettingsGroup},
  mixins: [ModMixin],
  data () {
    return {
      variants: [
        {
          key: 1,
          label: 'Flip-X'
        },
        {
          key: 2,
          label: 'Flip-Y'
        },
        {
          key: 3,
          label: 'Slide Up'
        },
        {
          key: 4,
          label: 'Slide Down'
        },
        {
          key: 5,
          label: 'Slide Left'
        },
        {
          key: 6,
          label: 'Slide Right'
        }
      ]
    }
  },
  computed: {
    currentSideSid () {
      return this.section.options.content.flipCard.active ? '.es_card-back' : '.es_card-front'
    },
    horizontalAlignOptions () {
      return [
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'start',
          tooltip: 'Start',
          customProperties: [[`.--${this.section.sid}--card ${this.currentSideSid}`, ['margin-left', '']], [`.--${this.section.sid}--card ${this.currentSideSid}`, ['margin-right', 'auto']]]
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'center',
          tooltip: 'Center',
          customProperties: [[`.--${this.section.sid}--card ${this.currentSideSid}`, ['margin-left', 'auto']], [`.--${this.section.sid}--card ${this.currentSideSid}`, ['margin-right', 'auto']]]
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'end',
          tooltip: 'End',
          customProperties: [[`.--${this.section.sid}--card ${this.currentSideSid}`, ['margin-left', 'auto']], [`.--${this.section.sid}--card ${this.currentSideSid}`, ['margin-right', '']]]
        }
      ]
    }
  }
}
</script>