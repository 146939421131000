<template>
  <div class="overflow-hidden rounded-[4px] border border-[#E3E5EB] font-[ProximaNova]">
    <table class="border-collapse table-auto min-w-full text-[16px] line-height-[24px] font-[400] color-[#000000]">
      <tbody class="bg-transparent">
        <tr class="border-b border-[#E3E5EB] last:border-b-0">
          <td class="w-[160px] pl-[32px] pr-[16px] py-[16px]">
            {{ moment.unix(data.created).format('MMM DD, YYYY') }}
          </td>
          <td class="p-[16px] font-[500]">
            {{ data.price }}
          </td>
          <td class="w-[200px] text-right pl-[16px] pr-[32px] py-[16px]">
            USD ${{ data.amount_due / 100 }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'UpcomingPaymentsTable',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>