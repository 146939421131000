<template>
  <b-form-group label="Categories">
    <div class="d-flex align-items-center gap-[8px] mb-2">
      <tags-select
        ref="select"
        v-model="postCategory"
        class="w-full"
        multiple
        label="name"
        :url="`https://blog-api.estage.com/api/projects/${project.id}/blog/manage/categories`"
      />
    </div>

    <div class="mb-2">
      <slot />
    </div>

    <b-link
      target="_blank"
      class="mb-2"
      @click="modal = true"
    >
      Add new category
    </b-link>

    <!--    <b-checkbox-->
    <!--      v-model="post.show_categories"-->
    <!--      :value="1"-->
    <!--    >-->
    <!--      Show Categories-->
    <!--    </b-checkbox>-->

    <blog-add-category />

    <b-modal
      v-model="modal"
      hide-footer
      hide-header
      body-class="p-0"
      size="xl"
    >
      <iframe
        :src="`${blogHost}/${project.id}/categories/iframe/create?auth-token=${token}&subdomain=${project.subdomain}`"
        class="w-full h-[500px]"
      />
    </b-modal>
  </b-form-group>
</template>

<script>
import {mapState} from 'vuex'
import BlogAddCategory from './modals/BlogAddCategory'
import TagsSelect from '@/components/editor/components/TagsSelect.vue'
import Cookie from 'js-cookie'

export default {
  name: 'PostCategories',
  components: {TagsSelect, BlogAddCategory},
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      modal: false
    }
  },
  computed: {
    token () {
      return process.env.NODE_ENV === 'development'
        ? localStorage.getItem('sanctum_token')
        : Cookie.get('cross_estage_token')
    },
    blogHost (){
      return process.env.VUE_APP_BLOG_HOST
    },
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('blogCategories', {
      categories: state => state.categories
    }),
    ...mapState('blogEditor', {
      post: state => state.post
    }),

    postCategory: {
      get () {
        return this.value || []
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    this.$store.dispatch('blogCategories/laodCategories')
    window.addEventListener('message', this.onMessage)
  },
  destroyed () {
    window.removeEventListener('message', this.onMessage)
  },
  methods: {
    onMessage (event) {
      const data = JSON.parse(event.data)

      if (data.name === 'estage-added-category') {
        this.modal = false
        this.postCategory.push(data.data.data.data.id)
        this.$refs.select.refresh()
      }
    }
  }
}
</script>
