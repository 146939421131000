<template>
  <div class="pt-[24px] min-h-[500px]">
    <b-form-group>
      <image-upload
        :image="config.image"
        class="w-100"
      />
    </b-form-group>

    <div v-if="config.image.src">
      <b-form-group label="Size">
        <v-select
          v-model="imageSize"
          :options="logoSizeOptions"
          :reduce="option => option.key"
          class="custom-select"
          left
        >
          <template #selected-option="{label}">
            <div v-text="isCustomSize ? 'Custom size' : label" />
          </template>
        </v-select>
      </b-form-group>

      <b-form-group
        class="pl-2"
        label="Custom size"
      >
        <b-row
          class="align-items-center"
          no-gutters
        >
          <b-col
            class="mr-2"
            cols="9"
          >
            <vue-slider
              v-model="imageSize"
              :dot-size="17"
              :interval="1"
              :max="150"
              :min="20"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col>
            <b-input
              v-model="imageSize"
              max="150"
              min="0"
              size="sm"
              step="1"
              type="number"
            />
          </b-col>
        </b-row>
      </b-form-group>
    </div>

    <div
      v-else
      class="mt-3"
    >
      <b-form-group>
        <b-input
          v-model="config.text"
          placeholder="Logo text"
        />
      </b-form-group>

      <b-row
        class="align-items-end"
        no-gutters
      >
        <b-col
          class="mr-2"
          cols="12"
        >
          <b-form-group label="Font">
            <v-select
              v-model="config.fontFamily"
              :options="['Global Style', ...fonts]"
              class="custom-select"
              placeholder="Global Style"
              width="auto"
            />
          </b-form-group>
        </b-col>

        <b-col
          class="mr-2"
          cols="6"
        >
          <b-form-group label="Size">
            <v-select
              v-model="fontSize"
              :options="fontSizes()"
              class="custom-select"
              left
            />
          </b-form-group>
        </b-col>

        <b-col cols="1">
          <b-form-group>
            <text-color v-model="config.color" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ModMixin from '@builder/components/mixins/ModMixin'
import ImageUpload from '../utils/ImageUpload'
import TextColor from '../controls/TextColor'
import {mapState} from 'vuex'

export default {
  name: 'LogoMod',
  components: {TextColor, ImageUpload},
  mixins: [ModMixin],

  data () {
    return {
      logoSizeOptions: [
        {
          key: 30,
          label: 'Small'
        },
        {
          key: 40,
          label: 'Default'
        },
        {
          key: 60,
          label: 'Large'
        },
        {
          key: 120,
          label: 'Extra Large'
        }
      ]
    }
  },

  computed: {
    ...mapState('font', {
      fonts: state => state.fonts
    }),
    isCustomSize () {
      return !this.logoSizeOptions.filter(size => size.key === this.imageSize).length
    },
    fontSize: {
      get () {
        return this.config.textLogoSize[this.resolutioner] || this.config.fontSize
      },
      set (val) {
        this.$set(this.config.textLogoSize, this.resolutioner, val)
      }
    },
    imageSize: {
      get () {
        return parseInt(this.config.logoSize[this.resolutioner] || _.get(this.config, 'image.logoSize', '40px'))
      },
      set (val) {
        this.$set(this.config.logoSize, this.resolutioner, `${parseInt(val)}px`)
      }
    }
  },

  created () {
    if (!this.config.hasOwnProperty('logoSize')) {
      this.$set(this.config, 'logoSize', {
        sm: _.get(this.config, 'image.logoSize', '20px'),
        md: _.get(this.config, 'image.logoSize', '30px'),
        lg: _.get(this.config, 'image.logoSize', '30px')
      })
    }

    if (!this.config.hasOwnProperty('textLogoSize')) {
      this.$set(this.config, 'textLogoSize', {
        sm: _.get(this.config, 'image.textLogoSize', '20px'),
        md: _.get(this.config, 'image.textLogoSize', '30px'),
        lg: _.get(this.config, 'image.textLogoSize', '30px')
      })
    }


    if (!this.config.hasOwnProperty('image')) {
      this.$set(this.config, 'image', {
        src: null
      })
    }
  },

  methods: {
    fontSizes () {
      return Array(70)
        .fill({})
        .map((_, i) => `${i + 1}px`)
    }
  }
}
</script>
