<template>
  <highliter
    v-model="section.name"
    :class="{'element-hidden': !visability('menu')}"
    section-type="element"
    class="highlight-element"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @add-subject="$emit('add-subject')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings('menu')"
  >
    <Template
      :config="section.options.content.menu"
      :section="section"
    >
      <div class="step-progress position-relative z-index-2">
        <div
          v-for="(link, index) in section.options.content.menu.links"
          :key="index"
          class="step-item"
          :class="{'active focus': link.href.slug === $store.state.pages.page.slug}"
          :style="section.options.content.menu.style"
          v-text="link.text"
        />
      </div>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import RouterMixin from '@builder/components/mixins/RouterMixin'

export default {
  name: 'FeaturedItems1',

  components: {Template, Highliter},

  mixins: [SectionMixin, RouterMixin],

  methods: {
    goPage () {
      // VEvent.fire('loader', true)
      // this.$store.dispatch('projects/save')
      //     .then(() => {
      //       this.$store.dispatch('pages/loadPage', val)
      //           .finally(() => {
      //             VEvent.fire('loader', false)
      //           })
      //     })
    }
  }
}
</script>

<style lang="scss">
.step-progress {
  position: relative;
}

.step-progress::before {
  content: "";
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 0;
  width: 4px;
  background-color: #ECECEC;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.23);
}

.step-progress .step-item {
  position: relative;
  padding-left: 1.5rem;
  line-height: 1.2;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 500;
}

.step-progress .step-item:not(:last-child) {
  margin-bottom: 2rem;
}

.step-progress .step-item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -7px;
  width: 18px;
  height: 18px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  background: #ECECEC url(../../../../../../assets/images/step-dot.svg) no-repeat 50% 50%;
}

.step-progress .step-item:hover {
  font-weight: 700;
}

.step-progress .step-item.active {
  font-weight: 700;
}

.step-progress .step-item.active::before {
  background: #ECECEC url(../../../../../../assets/images/step-dot-active.svg) no-repeat 50% 50%;
}

.step-progress .step-item.active::after {
  content: "\f054";
  position: relative;
  top: -1px;
  font-family: "Font Awesome 5 Free";
  font-size: .75rem;
  margin-left: .75rem;
  color: #000;
}
</style>
