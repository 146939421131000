<template>
  <b-card
    class="border-0"
    no-body
    @click="() => modal = true"
  >
    <section-item-hover class="d-flex align-items-center select-none cursor-move border-0">
      <icon
        class="d-block mr-3"
        icon="move.svg"
        style="display: block !important;"
      />
      <div
        class="mr-3 opacity-50"
        v-html="element.icon"
      />
      <div class="pt-1 opacity-50">
        {{ startCase(element.name) }}
      </div>


      <span class="bg-primary text-center ml-2 mt-[2px] text-white flex items-center justify-center h-[22px] w-[80px] rounded-pill text-[12px] font-bold">
        Pre Release
      </span>
    </section-item-hover>

    <pre-release-modal 
      v-model="modal"
      :element="element" 
    />
  </b-card>
</template>

<script>
import Icon from '@/components/editor/utils/Icon.vue'
import SectionItemHover from '@/components/editor/utils/SectionItemHover.vue'
import TextMixins from '@/components/mixins/TextMixins'
import PreReleaseModal from '@/components/editor/modals/pre-release/PreReleaseModal.vue'

export default {
  name: 'PreReleaseItem',
  mixins: [TextMixins],
  components: {
    PreReleaseModal,
    SectionItemHover,
    Icon
  },
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      modal: false
    }
  }
}
</script>