<template>
  <div>
    <component
      :is="banner.section.component"
      v-for="(banner, index) in getPromoBlocks"
      :key="index"
      :section="banner.section"
      :docked="true"
      @favorite="() => onFavorite(banner.section)"
      @delete="() => onDelete(banner)"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'PromoBarDockedWrapper',
  props: {
    bottom: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('promoBar', {
      topBanners: 'topBanners',
      bottomBanners: 'bottomBanners'
    }),
    getPromoBlocks () {
      return this.bottom ? this.bottomBanners : this.topBanners
    }
  },
  beforeDestroy () {
    this.$store.commit('promoBar/CLEAR')
  },
  methods: {
    onFavorite (section) {
      this.$emit('favorite', {section, node: this.$el})
    },
    onDelete (banner) {
      banner.onDelete()
      this.$store.dispatch('promoBar/remove', banner.section)
    }
  }
}
</script>