<template>
  <div class="pt-[20px]">
    <b-form-group
      label="Width (%)"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="width"
            :interval="1"
            :max="100"
            :min="0"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col class="p-0">
          <LinkedControlItem
            v-model="width"
            :max="100"
            :min="0"
            class="d-block !w-[60px]"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center gap-[10px]">
        <b-checkbox v-model="getSubject.fixedWidthEnabled[resolutioner]">
          Fixed Width
        </b-checkbox>

        <LinkedControlItem
          v-model="getSubject.fixedWidth[resolutioner]"
          :max="4000"
          :min="0"
          class="d-block !w-[80px]"
        />
      </div>
    </b-form-group>

    <b-form-group v-if="getSubject.hasOwnProperty('fixedHeightEnabled')">
      <div class="flex items-center gap-[10px]">
        <b-checkbox v-model="getSubject.fixedHeightEnabled[resolutioner]">
          Fixed Height
        </b-checkbox>

        <multi-select
          v-model="fixedHeightV2"
          :default-type="1"
          width="70px"
          :options="[{
                       name: 'px',
                       value: 'px',
                       max: 4000,
                       min: 0,
                       defaultValue: 300
                     },
                     {
                       name: 'Vh',
                       value: 'vh',
                       max: 100,
                       min: 0,
                       defaultValue: 100
                     }]"
        />
      </div>
    </b-form-group>

    <b-form-group label="Vertical Position">
      <div class="flex items-center">
        <button
          v-b-tooltip
          :class="{'bg-[#F8F8F8]': verticalAlign === 'start'}"
          class="w-[47px] h-[43px] py-0 px-[11px] border rounded-l-[4px] hover:bg-[#F8F8F8]"
          title="Top"
          @click="() => verticalAlign = 'start'"
        >
          <svg
            fill="none"
            height="15"
            viewBox="0 0 24 15"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_128_80)">
              <path
                :fill="verticalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
                d="M10.8591 3.03638H4.72412V14.4114H10.8591V3.03638Z"
              />
              <path
                :fill="verticalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
                d="M17.9377 3.03638H11.8027V11.0937H17.9377V3.03638Z"
              />
              <path
                :fill="verticalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
                d="M23.6006 2.08826H0.948242V0.666377H23.6006V2.08826Z"
              />
            </g>
            <defs>
              <clipPath id="clip0_128_80">
                <rect
                  fill="white"
                  height="15"
                  width="24"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
        <button
          v-b-tooltip
          :class="{'bg-[#F8F8F8]': verticalAlign === 'center'}"
          class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom border-right hover:bg-[#F8F8F8]"
          title="Center"
          @click="() => verticalAlign = 'center'"
        >
          <svg
            fill="none"
            height="14"
            viewBox="0 0 23 14"
            width="23"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              :fill="verticalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
              height="12.5494"
              width="6.01636"
              x="4.9118"
              y="0.666626"
            />
            <rect
              :fill="verticalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
              height="10.2254"
              width="6.01636"
              x="11.8538"
              y="1.59619"
            />
            <rect
              :fill="verticalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
              height="1.39437"
              transform="matrix(1 0 0 -1 0.746765 7.63843)"
              width="22.2142"
            />
          </svg>
        </button>
        <button
          v-b-tooltip
          :class="{'bg-[#F8F8F8]': verticalAlign === 'end'}"
          class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom border-right hover:bg-[#F8F8F8]"
          title="Bottom"
          @click="() => verticalAlign = 'end'"
        >
          <svg
            fill="none"
            height="15"
            viewBox="0 0 23 15"
            width="23"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              :fill="verticalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
              height="11.14"
              transform="matrix(1 -8.77996e-08 -8.70476e-08 -1 4.70483 11.8066)"
              width="6.00827"
            />
            <rect
              :fill="verticalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
              height="7.89083"
              transform="matrix(1 -8.77996e-08 -8.70476e-08 -1 11.637 11.8066)"
              width="6.00827"
            />
            <rect
              :fill="verticalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
              height="1.3925"
              transform="matrix(1 0 0 -1 0.545044 14.1278)"
              width="22.1844"
            />
          </svg>
        </button>
        <button
          v-b-tooltip
          :class="{'bg-[#F8F8F8]': verticalAlign === 'space-between'}"
          class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom border-right hover:bg-[#F8F8F8]"
          title="Stretch"
          @click="() => verticalAlign = 'space-between'"
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1_2)">
              <path
                d="M3.78358 3.63059L8.81294 3.63059L8.81294 17.6181L3.78358 17.6181L3.78358 3.63059Z"
                :fill="verticalAlign === 'space-between' ? '#4F5255' : '#7E7E7E'"
              />
              <path
                d="M10.7835 3.63059L15.8129 3.63059L15.8129 17.6181L10.7835 17.6181L10.7835 3.63059Z"
                :fill="verticalAlign === 'space-between' ? '#4F5255' : '#7E7E7E'"
              />
              <path
                d="M19.1821 2.35339L0.612106 2.35339L0.612106 1.18777L19.1821 1.18777L19.1821 2.35339Z"
                :fill="verticalAlign === 'space-between' ? '#4F5255' : '#7E7E7E'"
              />
              <path
                d="M19.1821 20.0602L0.612106 20.0602L0.612106 18.8946L19.1821 18.8946L19.1821 20.0602Z"
                :fill="verticalAlign === 'space-between' ? '#4F5255' : '#7E7E7E'"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_2">
                <rect
                  width="20"
                  height="19"
                  fill="white"
                  transform="translate(19.5 0.5) rotate(90)"
                />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </b-form-group>

    <b-form-group label="Alignment">
      <div class="flex items-center">
        <button
          v-b-tooltip
          :class="{'bg-[#F8F8F8]': horizontalAlign === 'unset'}"
          class="w-[47px] h-[43px] py-0 px-[11px] border hover:bg-[#F8F8F8]"
          title="Auto"
          @click="() => horizontalAlign = 'unset'"
        >
          <svg
            fill="none"
            height="19"
            viewBox="0 0 20 19"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              :fill="horizontalAlign === 'unset' ? '#4F5255' : '#7E7E7E'"
              height="13.9875"
              transform="matrix(-1 8.74228e-08 8.66707e-08 1 9.13904 2.70801)"
              width="5.02936"
            />
            <rect
              :fill="horizontalAlign === 'unset' ? '#4F5255' : '#7E7E7E'"
              height="13.9875"
              transform="matrix(-1 8.74228e-08 8.66707e-08 1 16.139 2.70801)"
              width="5.02936"
            />
            <rect
              :fill="horizontalAlign === 'unset' ? '#4F5255' : '#7E7E7E'"
              height="1.16562"
              transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 1.85339 18.8879)"
              width="18.57"
            />
            <rect
              :fill="horizontalAlign === 'unset' ? '#4F5255' : '#7E7E7E'"
              height="1.16562"
              transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 19.5602 18.8879)"
              width="18.57"
            />
          </svg>
        </button>
        <button
          v-b-tooltip
          :class="{'bg-[#F8F8F8]': horizontalAlign === 'start'}"
          class="w-[47px] h-[43px] py-0 px-[11px] border border-left-0 rounded-0 hover:bg-[#F8F8F8]"
          title="Start"
          @click="() => horizontalAlign = 'start'"
        >
          <svg
            fill="none"
            height="23"
            viewBox="0 0 17 23"
            width="17"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              :fill="horizontalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
              height="11.375"
              transform="rotate(180 16.0269 17.2097)"
              width="6.13501"
              x="16.0269"
              y="17.2097"
            />
            <rect
              :fill="horizontalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
              height="11.375"
              transform="rotate(180 9.02686 17.2097)"
              width="6.13501"
              x="9.02686"
              y="17.2097"
            />
            <rect
              :fill="horizontalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
              height="1.42188"
              transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 1.82373 22.8652)"
              width="22.6524"
            />
          </svg>
        </button>
        <button
          v-b-tooltip
          :class="{'bg-[#F8F8F8]': horizontalAlign === 'center'}"
          class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom border-right hover:bg-[#F8F8F8]"
          title="Center"
          @click="() => horizontalAlign = 'center'"
        >
          <svg
            fill="none"
            height="13"
            viewBox="0 0 18 13"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              :fill="horizontalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
              height="11.375"
              transform="rotate(180 7.02686 12.2097)"
              width="6.13501"
              x="7.02686"
              y="12.2097"
            />
            <rect
              :fill="horizontalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
              height="11.375"
              transform="rotate(180 17.0269 12.2097)"
              width="6.13501"
              x="17.0269"
              y="12.2097"
            />
          </svg>
        </button>
        <button
          v-b-tooltip
          :class="{'bg-[#F8F8F8]': horizontalAlign === 'end'}"
          class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom border-right hover:bg-[#F8F8F8]"
          title="End"
          @click="() => horizontalAlign = 'end'"
        >
          <svg
            fill="none"
            height="23"
            viewBox="0 0 17 23"
            width="17"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              :fill="horizontalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
              height="11.375"
              transform="matrix(1 8.74228e-08 8.74228e-08 -1 0.401855 17.2097)"
              width="6.13501"
            />
            <rect
              :fill="horizontalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
              height="11.375"
              transform="matrix(1 8.74228e-08 8.74228e-08 -1 7.40186 17.2097)"
              width="6.13501"
            />
            <rect
              :fill="horizontalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
              height="1.42188"
              transform="rotate(-90 14.605 22.8652)"
              width="22.6524"
              x="14.605"
              y="22.8652"
            />
          </svg>
        </button>
      </div>
    </b-form-group>

    <background-color-with-hover-effect :subject="getSubject" />

    <SidbearSettingsGroup
      class="border-top"
      title="Background Image"
    >
      <background-image-customs :config="getSubject.customize.style.backgroundImage" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Shape Divider"
    >
      <shape-divider-customs :config="getSubject.customize.style" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Float Image"
    >
      <float-image-customs :config="floatImage" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Animations"
    >
      <b-form-group label="Onload effect">
        <animations :animations="animations" />
      </b-form-group>

      <hover-animations v-model="hoverAnimation" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Border & Radius"
    >
      <border-customs-v3
        v-if="!borderStyle.border.type"
        :config="borderStyle"
        :default-border="[0,0,0,0]"
        :default-radius="[0,0,0,0]"
      />
      <border-customs
        v-else
        :config="borderStyle"
        :default-border="[0,0,0,0]"
        :default-radius="[0,0,0,0]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <StateTabs
        :states-text="['Normal State', 'Hover State']"
        class="pb-[12px]"
      >
        <shadow-customs :config="getSubject.customize.style" />

        <template slot="action-state">
          <shadow-customs :config="hoverConfig" />
        </template>
      </StateTabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="getSubject.customize.style.padding" />
      <margin-full-customs
        :config="getSubject.customize.style.marginTop"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Free Position"
      beta
    >
      <b-form-group>
        <b-checkbox v-model="absolute">
          Enable Free Position
        </b-checkbox>
      </b-form-group>

      <b-form-group
        label="Transform Y"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="transformY"
              :interval="1"
              :max="100"
              :min="-100"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col class="p-0">
            <LinkedControlItem
              v-model="transformY"
              :max="100"
              :min="-100"
              class="d-block !w-[60px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        label="Transform X"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="transformX"
              :interval="1"
              :max="100"
              :min="-100"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col class="p-0">
            <LinkedControlItem
              v-model="transformX"
              :max="100"
              :min="-100"
              class="d-block !w-[60px]"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group label="Z-Index">
        <LinkedControlItem
          v-model="zIndex"
          :max="1000"
          :min="0"
          class="d-block !w-[60px]"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Motions"
      beta
    >
      <Motions :subject="getSubject" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Click Action">
      <ButtonAction
        :config="getSubject"
        class="mb-0"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Device Visibility">
      <Panel :config="getSubject" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import _ from 'lodash'
import ModMixin from '@/components/mixins/ModMixin'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import Panel from '@/components/editor/controls/Panel'
import HoverAnimations from '@/components/editor/controls/HoverAnimations'
import StateTabs from '@/components/editor/components/StateTabs'
import Animations from '@/components/editor/controls/Animations'
import BackgroundColorWithHoverEffect from '@/components/editor/controls/BackgroundColorWithHoverEffect'
import ButtonAction from '@/components/editor/controls/button-mod/ButtonAction'
import Motions from '@/components/editor/components/motions/Motions'

export default {
  name: 'ColumnMod',
  components: {
    Motions,
    ButtonAction,
    BackgroundColorWithHoverEffect,
    Animations, StateTabs, HoverAnimations, Panel, SidbearSettingsGroup, LinkedControlItem
  },
  mixins: [ModMixin],
  data () {
    return {
      hoverShadowPlaceholder: {}
    }
  },
  computed: {
    borderStyle () {
      if (Array.isArray(this.getSubject.customize.style.border)) {
        this.$set(this.getSubject.customize.style, 'border', {
          lg: _.clone(this.getSubject.customize.style.border || [0, 0, 0, 0]),
          md: _.clone(this.getSubject.customize.style.border || [0, 0, 0, 0]),
          sm: _.clone(this.getSubject.customize.style.border || [0, 0, 0, 0])
        })
      }

      if (Array.isArray(this.getSubject.customize.style.radius)) {
        this.$set(this.getSubject.customize.style, 'radius', {
          lg: _.clone(this.getSubject.customize.style.radius || [0, 0, 0, 0]),
          md: _.clone(this.getSubject.customize.style.radius || [0, 0, 0, 0]),
          sm: _.clone(this.getSubject.customize.style.radius || [0, 0, 0, 0])
        })
      }

      return this.getSubject.customize.style
    },
    horizontalAlign: {
      get () {
        return _.get(this.getSubject, ['horizontalAlign', this.resolutioner], _.get(this.getSubject, ['horizontalAlign', 'lg'], 'unset'))
      },
      set (val) {
        this.$set(this.getSubject, 'horizontalAlign', {..._.set(this.getSubject['horizontalAlign'] || {}, [this.resolutioner], val)})
      }
    },
    absolute: {
      get () {
        return _.get(this.getSubject, ['absolute', this.resolutioner], false)
      },
      set (val) {
        this.$set(this.getSubject, 'absolute', {..._.set(this.getSubject['absolute'] || {}, [this.resolutioner], val)})
      }
    },
    transformY: {
      get () {
        return _.get(this.getSubject, ['transformY', this.resolutioner], 0)
      },
      set (val) {
        this.$set(this.getSubject, 'transformY', {..._.set(this.getSubject['transformY'] || {}, [this.resolutioner], val)})
      }
    },
    transformX: {
      get () {
        return _.get(this.getSubject, ['transformX', this.resolutioner], 0)
      },
      set (val) {
        this.$set(this.getSubject, 'transformX', {..._.set(this.getSubject['transformX'] || {}, [this.resolutioner], val)})
      }
    },
    zIndex: {
      get () {
        return _.get(this.getSubject, ['zIndex', this.resolutioner], 0)
      },
      set (val) {
        this.$set(this.getSubject, 'zIndex', {..._.set(this.getSubject['zIndex'] || {}, [this.resolutioner], val)})
      }
    },
    width: {
      get () {
        const width = this.getSubject.width[this.resolutioner]
        if (width === null) return this.getSubject.width.lg
        return width
      },
      set (val) {
        this.getSubject.width[this.resolutioner] = val
      }
    },
    hoverConfig: {
      get () {
        if (!this.getSubject.hover) {
          this.$set(this.getSubject, 'hover', {})
        }
        return this.getSubject.hover
      },
      set (val) {
        this.getSubject.hover = val
      }
    },
    verticalAlign: {
      get () {
        const align = _.get(this.getSubject, ['verticalAlign', this.resolutioner], null)
        if (align === null) return _.get(this.getSubject, ['verticalAlign', 'lg'], null)
        return align
      },
      set (val) {
        this.$set(this.getSubject, 'verticalAlign', {..._.set(this.getSubject.verticalAlign || {}, [this.resolutioner], val)})
      }
    },
    getSubject () {
      return this.openedItemOptions.subject ? this.openedItemOptions.subject : this.config.options.content.cols.find(col => col.uid === this.openedItemOptions.subjectIndex)
    },
    hoverAnimation: {
      get () {
        return this.getSubject.hoverAnimation || null
      },
      set (val) {
        this.$set(this.getSubject, 'hoverAnimation', val)
      }
    },
    animations () {
      if (!this.getSubject.animations) {
        this.$set(this.getSubject, 'animations', {
          animation: null,
          once: true,
          offset: 200,
          delay: 50
        })
      }

      return this.getSubject.animations
    },
    fixedHeightV2: {
      get () {
        if (!this.getSubject.fixedHeightV2) {
          return {
            lg: {
              value: this.getSubject.fixedHeight[this.resolutioner],
              type: 'vh'
            },
            md: {
              value: this.getSubject.fixedHeight[this.resolutioner],
              type: 'vh'
            },
            sm: {
              value: this.getSubject.fixedHeight[this.resolutioner],
              type: 'vh'
            }
          }
        }

        return this.getSubject.fixedHeightV2
      },
      set (val) {
        this.$set(this.getSubject, 'fixedHeightV2', val)
      }
    },
    floatImage () {
      if (!this.getSubject.customize.style.floatImage) {
        this.$set(this.getSubject.customize.style, 'floatImage', {
          'display': true,
          'enabled': false,
          'type': 'float-image-customs',
          'src': 'https://res.cloudinary.com/estage/image/upload/v1633894730/users/user-3/X8AqLb8FUZvjuT3QizVEg3WuUO4BKyzFa3JjcDMH.png',
          'top': 0,
          'x': {
            'left': 0
          }
        })
      }

      return this.getSubject.customize.style.floatImage
    }
  }
}
</script>