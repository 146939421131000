<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('horizontalLine')"
  >
    <div :data-sid="section.sid">
      <Template :section="section">
        <div
          class="p-[20px]"
          :class="[{'element-hidden': !visability('horizontalLine')}, `--divider-${section.sid}`]"
        >
          <div v-if="section.options.content.horizontalLine.pressetID === 1">
            <div
              :style="{...shadowDivider}"
              class="shadow-container"
            />
          </div>
          <div v-if="section.options.content.horizontalLine.pressetID === 2">
            <div :style="backgroundDivider" />
          </div>
          <div
            v-else
            :style="{borderRadius: section.options.content.horizontalLine.pilled ? '25rem' : 0}"
            class="overflow-hidden"
          >
            <div
              :style="{borderTopStyle: `${section.options.content.horizontalLine.style || 'solid'}`, borderTopWidth: `${width}px`, borderTopColor: section.options.content.horizontalLine.color}"
              class="headline-divider w-100"
            />
          </div>
        </div>
      </Template>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import BorderMixin from '@/components/mixins/BorderMixin'
import _ from 'lodash'

export default {
  name: 'FeaturedItems1',

  components: {Template, Highliter},

  mixins: [SectionMixin, BorderMixin],

  computed: {
    width () {
      return parseInt(_.get(this.section.options.content.horizontalLine.width, this.resolutioner, _.get(this.section.options.content.horizontalLine.width, 'lg', this.section.options.content.horizontalLine.width)))
    },
    backgroundDivider () {
      return {
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='26' height='20' viewBox='0 0 26 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='${this.section.options.content.horizontalLine.backgroundImage}' fill='%23${this.section.options.content.horizontalLine.color.replace('#', '')}'/%3E%3C/svg%3E%0A")`,
        backgroundRepeat: 'repeat-x',
        height: '25px',
        width: '100%'
      }
    },
    shadowDivider () {
      return {
        boxShadow: `0px 15px 10px -15px ${this.section.options.content.horizontalLine.color}`
      }
    }
  }
}
</script>
