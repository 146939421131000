<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="el-dynamic"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('data')"
  >
    <div :data-sid="section.sid">
      <div
        class="blog-author-box position-relative"
        :class="className()"
      >
        <div class="block-bg inherit-radius" />
        <div class="el-overlay inherit-radius" />
        <div class="position-relative">
          <div class="d-flex align-items-center blog-author-box-name-container">
            <b-img
              v-if="l.get(customPostData,'author.avatar')"
              height="52"
              width="52"
              rounded="circle"
              class="avatar blog-author-box-avatar-img"
              :src="l.get(customPostData,'author.avatar')"
              alt="Author Avatar"
            />
            <div
              v-else
              class="blog-author-box-avatar"
            />

            <div>
              <div class="blog-author-box-about">
                About The Author
              </div>
              <a
                class="d-flex align-items-center blog-author-box-name text-decoration-none"
                href="javascript:;"
              >
                {{ l.get(customPostData,'author.name','John Smith') }}
                <i class="fa fa-envelope-o" />
                <i
                  v-if="l.get(customPostData,'author.personal_link')"
                  class="fas fa-globe"
                />
              </a>
            </div>
          </div>

          <p class="blog-author-box-text">
            {{ l.get(customPostData,'author.bio','Curabitur convallis urna non sem faucibus, vitae egestas odio facilisis. Proin egestas vestibulum eros id posuere. Maecenas eu libero facilisis, egestas purus ac, ullamcorper elit. Fusce tristique ante mollis lacinia ullamcorper. Fusce bibendum cursus mauris eu gravida. Morbi non elementum enim, sit amet pellentesque magna.') }}
          </p>
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
import _ from 'lodash'
export default {
  name: 'ElBlogCategories',
  components: {Highliter},
  mixins: [SectionMixin],
  inject: {
    getCustomPostEditorData: {
      default:() => () => null
    }
  },
  data () {
    return {
      l: _
    }
  },
  computed: {
    customPostData () {
      return this.getCustomPostEditorData() 
    }
  },
  methods: {
    className (name = '') {
      return `--author-box-${this.section.sid}${name}`
    }
  }
}
</script>

<style lang="scss">
.blog-author-box {
  padding: 50px;
  background-color: #FCFAF6;
  border-radius: 4px;
}

.blog-author-box-name-container {
  gap: 26px;
  margin-bottom: 25px;
}

.blog-author-box-avatar {
  width: 52px;
  height: 52px;
  background-color: #dddddd;
  border-radius: 50%;
}

.blog-author-box-avatar-img{
  min-width: 52px;
  min-height: 52px;
  max-width: 52px;
  max-height: 52px;
  object-fit: cover;
}

.blog-author-box-about {
  color: #303030;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
}

.blog-author-box-name {
  gap: 11px;
  font-weight: 700;
  font-size: 26px;
  color: #3D82EA;
}

.blog-author-box-text {
  font-weight: 400;
  font-size: 15px;
  color: #393939;
}
</style>