<template>
  <div
    class="dashboard"
    :class="[{'editor-wrapper': $route.meta.editor}, {'unpin-layout': (unpin && $route.meta.editor)}]"
  >
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'DashboardLayout',
  computed: {
    unpin () {
      return this.$store.state.editor.unpin
    }
  }
}
</script>
