import router from '@builder/router/index'

const state = () => ({
  folders: [],
  projects: {},
  folder: null,
  loading: true,
  loadingMoreProjects: false,
  breadcrumbs: [],
  gridView: true,
  search: '',
  loads: 1
})

const getters = {
  breadcrumbs (state) {
    function flatten (data) {
      return data.reduce(function (result, next) {
        result.push(next)
        if (next.parent_recursive) {
          result = result.concat(flatten([next.parent_recursive]))
          next.parent_recursive = []
        }
        return result
      }, [])
    }

    return [{id: 0, name: 'All Sites'}, ...flatten(state.breadcrumbs).reverse()]
  }
}

const actions = {
  async getDashboardData ({state, commit}) {
    state.loading = true

    commit('RESET')

    const promise1 = axios.get('api/projects/categories')
    const promise2 = axios.get('api/projects')

    return Promise.all([promise1, promise2])
      .then((values) => {
        state.folder = null
        state.breadcrumbs = []
        state.folders = values[0].data.data
        state.projects = values[1].data.data
      })
      .finally(() => {
        state.loading = false
      })
  },
  loadFolder ({state, dispatch, commit}, id) {
    if (router.currentRoute.name !== 'dashboard') {
      router.push({name: 'dashboard'})
    }

    if (!id) {
      dispatch('getDashboardData')
    }

    state.loading = true

    commit('RESET')

    const promise1 = axios.get(`api/projects/categories/${id}`)
    const promise2 = axios.get(`api/projects/categories?parentId=${id}`)
    const promise3 = axios.get(`api/projects?category=${id}`)
    const promise4 = axios.get(`api/projects/categories/bread-crumbs/${id}`)

    return Promise.all([promise1, promise2, promise3, promise4])
      .then((values) => {

        state.folder = values[0].data.data
        state.folders = values[1].data.data
        state.projects = values[2].data.data
        state.breadcrumbs = values[3].data.data
      })
      .finally(() => {
        state.loading = false
      })
  },
  loadProjects ({state}) {
    if (state.loads !== state.projects.last_page && !state.loadingMoreProjects && !state.loading) {
      state.loadingMoreProjects = true

      state.loads++

      axios.get(`api/projects?page=${state.loads}${state.search ? `&q=${state.search}` : ''}${state.folder && !state.search ? `&category=${state.folder.id}` : ''}`)
        .then(({data}) => {
          state.projects.data = [...state.projects.data, ...data.data.data]
          state.projects.last_page = data.data.last_page
          state.loadingMoreProjects = false
        })
    }
  },
  searchProject ({state}) {
    state.loading = true
    state.loads = 1

    axios.get(`api/projects?q=${state.search}`)
      .then(({data}) => {
        state.projects = data.data
        state.loading = false
      })
  }
}

const mutations = {
  RESET (state) {
    state.loads = 1
    state.search = ''
  }
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
