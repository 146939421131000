import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Card Details'
export const group = ''

export default class ElFieldCardDetails extends SectionProvider {
  constructor (options = {}) {
    super('ElFieldCardDetails', options)

    this.name = name
    this.group = group
  }
}