<template>
  <div class="dashboard-folders">
    <div
      v-if="folder && !projects.data.length && !folders.length"
      class="d-flex align-items-center justify-content-center min-vh-50 empty-text"
    >
      <div class="text-center">
        <h4>Oops “{{ folder.name }}” is Empty</h4>
        <p>To move sites here, hover on any site and Move to Folder via Ste Actions.</p>
        <b-link
          class="text-primary"
          @click="$store.dispatch('dashboard/getDashboardData')"
        >
          Back to All Sites
        </b-link>
      </div>
    </div>

    <template v-if="gridView">
      <transition name="fade-fast">
        <div v-if="folders.length && !search || breadcrumbs.length === 0">
          <dashboard-grid-folders :folders="folders" />
          <hr class="hr-dashboard">
        </div>
      </transition>

      <dashboard-projects-grid :projects="projects.data" />
    </template>

    <template v-else>
      <dashboard-list-folders
        :folders="folders"
        :projects="projects.data"
      />
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import DashboardGridFolders from './DashboardGridFolders'
import DashboardProjectsGrid from './DashboardProjectsGrid'
import DashboardListFolders from './DashboardListFolders'
export default {
  name: 'DashboardFodlers',
  components: {DashboardListFolders, DashboardProjectsGrid, DashboardGridFolders},
  computed: {
    ...mapState('dashboard', {
      breadcrumbs: state => state.breadcrumbs,
      projects: state => state.projects,
      folders: state => state.folders,
      gridView: state => state.gridView,
      search: state => state.search,
      folder: state => state.folder
    })
  }
}
</script>

<style lang="scss">
.hr-dashboard {
  margin-bottom: 60px;
  margin-top: 60px;
  margin-left: -77px;
  margin-right: -77px;
}

.empty-text {
  font-family: Raleway;

  h4 {
    font-weight: 600 !important;
    font-size: 22px;
    color: #44474A;
  }

  p {
    font-size: 16px;
    color: #717477;
  }
}
</style>