<template>
  <div :style="{...fullMarginSubject(section.options.customize.style), ...fullPaddingSubject(section.options.customize.style)}">
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '../../../../mixins/TemplateMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'Carousel1Template',
  mixins: [TemplateMixin, BorderMixin]
}
</script>
