<template>
  <div>
    <h2 class="page-title mb-14">
      Notifications
    </h2>

    <b-row class="phone-number-control align-items-center">
      <b-col cols="3">
        <b-form-group label="Phone Number">
          <b-input-group class="align-items-center">
            <template #prepend>
              <div style="width: 60px;">
                <phone-code-select v-model="form.phone_code" />
              </div>
            </template>
            <div class="dial-code">
              {{ form.phone_code.dial_code }}
            </div>
            <b-form-input
              v-model="form.phone"
              :maxlength="15"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col class="pt-3">
        <b-checkbox
          v-model="form.sms_notification"
        >
          Receive SMS Notifications
        </b-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PhoneCodeSelect from './components/PhoneCodeSelect'

export default {
  name: 'Notifications',
  inject: ['$validator'],
  components: {PhoneCodeSelect},
  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>
