<template>
  <div class="relative">
    <input
      ref="input"
      :value="value"
      :type="type"
      v-bind="$attrs"
      class="w-full h-[38px] p-[12px] pr-[30px] border border-[#E2E5EC] rounded-[3px] text-[15px] leading-[15px] placeholder:text-[#B5BBC5] "
      :placeholder="placeholder"
      @input="onInput"
    >
    <icon
      class="absolute right-[5px] top-[50%] translate-y-[-50%]"
      icon="search.svg"
    />
  </div>
</template>

<script>
export default {
  name: 'FormSearch',
  props: {
    value:{
      type:String,
      default:''
    },
    autoFocus: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: 'Search...'
    }
  },
  data () {
    return {
      timer: null
    }
  },
  mounted () {
    if (this.autoFocus) {
      setTimeout(() => {
        this.$refs.input.focus()
      }, 10)
    }
  },
  methods: {
    onInput (e) {
      const val = e.target.value
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.$emit('input', val)
        this.$emit('onSearch', val)
      }, 300)
    }
  }
}
</script>