<template>
  <highliter
    v-model="section.name"
    :uid="section.uid"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings()"
  >
    <ImageItem2Template :section="section">
      <highliter
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @dublicate="$emit('dublicate')"
        @delete="$emit('delete')"
        @click.stop.native="sectionSettings('image')"
      >
        <image-fit
          :customs="section.options.content.image"
          :src="section.options.content.image.src || section.options.content.image.placeholder"
          :size="cols(section.options.content.image.size, 'cover')"
          :position="section.options.content.image.position"
        />
      </highliter>
    </ImageItem2Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ImageItem2Template from '@builder/templates/sections/images/ImageItem2Template'
import ImageFit from '../../../utils/ImageFit'

export default {
  name: 'ImageItem2',

  components: {ImageFit, ImageItem2Template, Highliter},

  mixins: [SectionMixin]
}
</script>
