import _ from 'lodash'
import ResolutionMixin from './ResolutionMixin'

export default {
  mixins: [ResolutionMixin],

  computed: {
    backgroundImageFullHeight () {
      return _.get(this.section, 'options.customize.style.backgroundImage.fullHeight', false)
    },
    backgroundImageURL () {
      return _.get(this.section, 'options.customize.style.backgroundImage.src', null)
    },
    imageFullHeightContentPosition () {
      const positions = {
        top: 'start',
        center: 'center',
        bottom: 'end'
      }

      return {
        alignItems: positions[this.backgroundImage.backgroundPosition]
      }
    },
    backgroundSize () {
      return _.get(this.section.options.customize.style, ['backgroundImage', 'backgroundSizeV2', this.resolutioner], _.get(this.section.options.customize.style, ['backgroundImage', 'backgroundSizeV2', 'lg'], _.get(this.section.options.customize.style, 'backgroundImage.backgroundSize', 'parallax')))
    },
    customBackgroundSize () {
      const _enabled = _.get(this.section.options.customize.style, ['backgroundImage', 'customSizeEnabled', this.resolutioner], _.get(this.section.options.customize.style, ['backgroundImage', 'customSizeEnabled', 'lg'], false))
      const size = _.get(this.section.options.customize.style, ['backgroundImage', 'customSize', this.resolutioner], {
        value: '100',
        type: '%'
      })
      return _enabled ? {
        backgroundSize: `${size.value}${size.type}`
      } : {}
    },
    customBackgroundPosition () {
      const _enabled = _.get(this.section.options.customize.style, ['backgroundImage', 'customPositionEnabled', this.resolutioner], _.get(this.section.options.customize.style, ['backgroundImage', 'customPositionEnabled', 'lg'], false))
      const positionX = _.get(this.section.options.customize.style, ['backgroundImage', 'positionX', this.resolutioner], _.get(this.section.options.customize.style, ['backgroundImage', 'positionX', 'lg'], 0))
      const positionY = _.get(this.section.options.customize.style, ['backgroundImage', 'positionY', this.resolutioner], _.get(this.section.options.customize.style, ['backgroundImage', 'positionY', 'lg'], 0))

      return _enabled ? {
        backgroundPosition: `${positionX}% ${positionY}%`
      } : {}
    },
    backgroundPosition () {
      return _.get(this.section.options.customize.style, ['backgroundImage', 'backgroundPositionV2', this.resolutioner], _.get(this.section.options.customize.style, ['backgroundImage', 'backgroundPositionV2', 'lg'], _.get(this.section.options.customize.style, 'backgroundImage.backgroundPosition', 'center')))
    },
    backgroundImagePath () {
      return _.get(this.section.options.customize.style, 'backgroundImage.src', null)
    },
    backgroundImageOverlay () {
      const enabled = _.get(this.section.options.customize.style, 'backgroundImage.overlay.enabled', false)
      if (!enabled) return false

      return {
        background: _.get(this.section.options.customize.style, 'backgroundImage.overlay.color', null),
        opacity: _.get(this.section.options.customize.style, 'backgroundImage.overlay.opacity', .5)
      }
    },
    backgroundSlider () {
      const enabled = _.get(this.section.options.customize.style, 'backgroundImage.bgType', 0) || 0
      if (!enabled) return null
      return _.get(this.section.options.customize.style, 'backgroundImage.backgroundSlider', null) || null
    },
    backgroundImage () {
      // if (!_.get(this.section.options.customize.style, 'backgroundImage.enabled', false)) {
      //   return false
      // }

      const styles = {
        null: {},
        'fill-y': {
          backgroundSize: '100% auto',
          backgroundPosition: '',
          backgroundRepeat: 'no-repeat'
        },
        'parallax': {
          backgroundSize: 'cover',
          backgroundPosition: '',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed'
        },
        'fill': {
          backgroundSize: '100% 100%',
          backgroundPosition: this.backgroundPosition,
          backgroundRepeat: 'no-repeat'
        },
        'repeat': {
          backgroundSize: 'auto',
          backgroundPosition: this.backgroundPosition,
          backgroundRepeat: 'repeat'
        },
        'repeat-x': {
          backgroundSize: 'auto',
          backgroundPosition: this.backgroundPosition,
          backgroundRepeat: 'repeat-x'
        },
        'repeat-y': {
          backgroundSize: 'auto',
          backgroundPosition: this.backgroundPosition,
          backgroundRepeat: 'repeat-y'
        },
        'cover': {
          backgroundSize: 'cover',
          backgroundPosition: this.backgroundPosition,
          backgroundRepeat: 'no-repeat'
        },
        'contain': {
          backgroundSize: 'contain',
          backgroundPosition: this.backgroundPosition,
          backgroundRepeat: 'no-repeat'
        },
        'normal': {
          backgroundSize: 'auto',
          backgroundPosition: this.backgroundPosition,
          backgroundRepeat: 'no-repeat'
        }
      }

      if (!this.backgroundSize) return {}

      const filters = _.get(this.section.options.customize.style, 'backgroundImage.filters', null)

      const flipX = _.get(this.section.options.customize.style.backgroundImage, ['flipX', this.resolutioner], _.get(this.section.options.customize.style.backgroundImage, ['flipX', 'lg'], false))
      const flipY = _.get(this.section.options.customize.style.backgroundImage, ['flipY', this.resolutioner], _.get(this.section.options.customize.style.backgroundImage, ['flipY', 'lg'], false))
      
      return {
        backgroundImage: this.backgroundSize ? `url(${this.backgroundImagePath})` : 'parallax',
        ...styles[this.backgroundSize],
        opacity: this.bgImageOpacity,
        filter: !filters ? '' : `blur(${filters.blur || '0px'}) grayscale(${filters.grayscale || '0%'}) invert(${filters.invert || '0%'}) contrast(${filters.contrast || '100%'}) sepia(${filters.sepia || '0%'})`,
        ...this.customBackgroundSize,
        ...this.customBackgroundPosition,
        transform: `${flipX ? 'scaleX(-1)' : ''}${flipY ? 'scaleY(-1)' : ''}`
      }
    },
    margin () {
      return {
        marginTop: this.section.options.customize.style.margin[this.resolution].top + 'px',
        marginBottom: this.section.options.customize.style.margin[this.resolution].bottom + 'px',
        marginLeft: this.section.options.customize.style.margin[this.resolution].x + 'px',
        marginRight: this.section.options.customize.style.margin[this.resolution].x + 'px'
      }
    },
    marginY () {
      return {
        marginTop: _.get(this.section.options.customize.style.marginY, [this.resolutioner, 'top'], 0) + 'px',
        marginBottom: _.get(this.section.options.customize.style.marginY, [this.resolutioner, 'bottom'], 0) + 'px'
      }
    },
    marginTop () {
      if (!this.section.options.customize.style.hasOwnProperty('marginTop')) {
        this.$set(this.section.options.customize.style, 'marginTop', {
          sm: 0,
          lg: 0
        })
      }

      return {
        marginTop: this.section.options.customize.style.marginTop[this.resolution] + 'px'
      }
    },
    marginV2 () {
      return {
        marginTop: `${_.get(this.section, ['options', 'customize', 'style', 'marginTop', this.resolutioner, 'top'], 0)}px`,
        marginBottom: `${_.get(this.section, ['options', 'customize', 'style', 'marginTop', this.resolutioner, 'bottom'], 0)}px`,
        marginLeft: `${_.get(this.section, ['options', 'customize', 'style', 'marginTop', this.resolutioner, 'left'], null)}px`,
        marginRight: `${_.get(this.section, ['options', 'customize', 'style', 'marginTop', this.resolutioner, 'right'], null)}px`
      }
    },
    marginBottom () {
      if (!this.section.options.customize.style.hasOwnProperty('marginBottom')) {
        this.$set(this.section.options.customize.style, 'marginBottom', {
          sm: 0,
          lg: 0
        })
      }

      return {
        marginBottom: this.section.options.customize.style.marginBottom[this.resolution] + 'px'
      }
    },
    border () {
      const border = _.get(this.section.options.customize.style.border, this.resolutioner, this.section.options.customize.style.border) || [0, 0, 0, 0]

      return {
        borderColor: this.section.options.customize.style.borderColor || border.color,
        borderStyle: 'solid',
        borderTopWidth: `${border[0]}px`,
        borderRightWidth: `${border[1]}px`,
        borderBottomWidth: `${border[2]}px`,
        borderLeftWidth: `${border[3]}px`
      }
    },
    borderRadius () {
      const radius = _.get(this.section.options.customize.style.radius, this.resolutioner, this.section.options.customize.style.radius) || [0, 0, 0, 0]

      return {
        borderTopLeftRadius: `${radius[0]}px`,
        borderTopRightRadius: `${radius[1]}px`,
        borderBottomLeftRadius: `${radius[2]}px`,
        borderBottomRightRadius: `${radius[3]}px`
      }
    },
    imageRadius () {
      const radius = this.section.options.customize.style.radius || [0, 0, 0, 0]
      const border = this.section.options.customize.style.border || [0, 0, 0, 0]

      const getAngle = (n1, n2) => {
        return Math.max(n1 || 0, n2 || 0)
      }

      return {
        borderTopLeftRadius: `calc(${radius[0]}px - ${getAngle(border[0], border[3])}px)`,
        borderTopRightRadius: `calc(${radius[1]}px - ${getAngle(border[0], border[1])}px)`,
        borderBottomLeftRadius: `calc(${radius[2]}px - ${getAngle(border[2], border[3])}px)`,
        borderBottomRightRadius: `calc(${radius[3]}px - ${getAngle(border[2], border[1])}px)`
      }
    },
    padding () {
      return {
        paddingTop: `${_.get(this.section.options.customize.style.padding, [this.resolutioner, 'top'], _.get(this.section.options.customize.style.padding, ['sm', 'top'], 0))}px`,
        paddingBottom: `${_.get(this.section.options.customize.style.padding, [this.resolutioner, 'bottom'], _.get(this.section.options.customize.style.padding, ['sm', 'bottom'], 0))}px`,
        paddingLeft: `${_.get(this.section.options.customize.style.padding, [this.resolutioner, 'x'], _.get(this.section.options.customize.style.padding, ['sm', 'x'], 0))}px`,
        paddingRight: `${_.get(this.section.options.customize.style.padding, [this.resolutioner, 'x'], _.get(this.section.options.customize.style.padding, ['sm', 'x'], 0))}px`
      }
    },
    container () {
      return this.globalStyles.container || 'container'
    },
    shadow () {
      const shadow = this.section.options.customize.style.shadowOptions
      const color = this.section.options.customize.style.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    dropShadow () {
      if (!this.section.options.customize.style.shadow) {
        this.$set(this.section.options.customize.style, 'shadow', {
          type: 'shadow-customs',
          shadow: null
        })
      }

      return {
        filter: this.section.options.customize.style.shadow.shadow !== 'none' ? `drop-shadow(${this.section.options.customize.style.shadow.shadow})` : null
      }
    },
    bgImageOpacity () {
      return _.get(this.section.options.customize.style.backgroundImage.opacity, this.resolutioner, this.section.options.customize.style.backgroundImage.opacity)
    },
    opacity () {
      if (!this.section.options.customize.style.hasOwnProperty('opacity')) {
        this.$set(this.section.options.customize.style, 'opacity', {
          'type': 'opacity-customs',
          'opacity': 1
        })
      }

      return {
        opacity: this.section.options.customize.style.opacity.opacity
      }
    },
    gradient () {
      if (this.section.options.customize.style.background.backgroundType === 'gradient' && this.section.options.customize.style.background.hasOwnProperty('backgroundGradient')) {
        const values = this.section.options.customize.style.background.backgroundGradient.map(gradient => {
          return `${gradient.color} ${gradient.position}%`
        })
        return `linear-gradient(${this.section.options.customize.style.background.gradientDirection || 90}deg,${values})`
      }

      return null
    },
    backgroundColor () {
      const _backgroundColor = _.get(this.section.options.customize.style.background, ['background', this.resolutioner], _.get(this.section.options.customize.style.background, ['background', 'lg'], this.section.options.customize.style.background.background || ''))
      return {
        backgroundColor: _backgroundColor,
        backgroundImage: this.gradient
      }
    },
    color () {
      return {
        color: this.section.options.customize.style.color.color
      }
    }
  },

  methods: {
    backgroundImageSubject (subject) {
      if (!_.get(subject, 'backgroundImage.enabled', false)) {
        return false
      }

      const size = _.get(subject, ['backgroundImage', 'backgroundSizeV2', this.resolutioner], _.get(subject, ['backgroundImage', 'backgroundSizeV2', 'lg'], _.get(subject, 'backgroundImage.backgroundSize', null)))
      const position = _.get(subject, ['backgroundImage', 'backgroundPositionV2', this.resolutioner], _.get(subject, ['backgroundImage', 'backgroundPositionV2', 'lg'], _.get(subject, 'backgroundImage.backgroundPosition', 'center')))

      const styles = {
        null: {},
        'fill-y': {
          backgroundSize: '100% auto',
          backgroundPosition: '',
          backgroundRepeat: 'no-repeat'
        },
        'parallax': {
          backgroundSize: 'cover',
          backgroundPosition: '',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed'
        },
        'fill': {
          backgroundSize: '100% 100%',
          backgroundPosition: position,
          backgroundRepeat: 'no-repeat'
        },
        'repeat': {
          backgroundSize: 'auto',
          backgroundPosition: position,
          backgroundRepeat: 'repeat'
        },
        'repeat-x': {
          backgroundSize: 'auto',
          backgroundPosition: position,
          backgroundRepeat: 'repeat-x'
        },
        'repeat-y': {
          backgroundSize: 'auto',
          backgroundPosition: position,
          backgroundRepeat: 'repeat-y'
        },
        'cover': {
          backgroundSize: 'cover',
          backgroundPosition: position,
          backgroundRepeat: 'no-repeat'
        },
        'contain': {
          backgroundSize: 'auto',
          backgroundPosition: position,
          backgroundRepeat: 'no-repeat'
        },
        'normal': {
          backgroundSize: 'normal',
          backgroundPosition: position,
          backgroundRepeat: 'no-repeat'
        }
      }

      if (!size) return {}
      const filters = _.get(subject, 'backgroundImage.filters', null)
      const flipX = _.get(subject, ['backgroundImage', 'flipX', this.resolutioner], _.get(subject, ['backgroundImage', 'flipX', 'lg'], false))
      const flipY = _.get(subject, ['backgroundImage', 'flipY', this.resolutioner], _.get(subject, ['backgroundImage', 'flipY', 'lg'], false))

      return {
        backgroundImage: `url(${_.get(subject, 'backgroundImage.src', null)})`,
        ...styles[size],
        opacity: _.get(subject, ['backgroundImage', 'opacity', this.resolutioner], 1),
        filter: !filters ? '' : `blur(${filters.blur || '0px'}) grayscale(${filters.grayscale || '0%'}) invert(${filters.invert || '0%'}) contrast(${filters.contrast || '100%'}) sepia(${filters.sepia || '0%'})`,
        ...this.customBackgroundSize,
        ...this.customBackgroundPosition,
        transform: `${flipX ? 'scaleX(-1)' : ''}${flipY ? 'scaleY(-1)' : ''}`
      }
    },
    backgroundImageOverlaySubject (subject) {
      const enabled = _.get(subject, 'backgroundImage.overlay.enabled', false)
      if (!enabled) return false

      return {
        background: _.get(subject, 'backgroundImage.overlay.color', null),
        opacity: _.get(subject, 'backgroundImage.overlay.opacity', .5)
      }
    },
    paddingSubject (subject, defaultValues) {
      if (!subject.padding) return {}

      return {
        paddingTop: `${_.get(subject.padding, [this.resolutioner, 'top'], _.get(subject.padding, ['sm', 'top'], defaultValues[0]))}px`,
        paddingBottom: `${_.get(subject.padding, [this.resolutioner, 'bottom'], _.get(subject.padding, ['sm', 'bottom'], defaultValues[1]))}px`,
        paddingLeft: `${_.get(subject.padding, [this.resolutioner, 'x'], _.get(subject.padding, ['sm', 'x'], defaultValues[2]))}px`,
        paddingRight: `${_.get(subject.padding, [this.resolutioner, 'x'], _.get(subject.padding, ['sm', 'x'], defaultValues[3]))}px`
      }
    },

    backgroundColorCustom (name) {
      return {
        backgroundColor: this.section.options.customize.style['background' + name].background === 'colored' ? this.globalStyles.colors.primary : this.section.options.customize.style['background' + name].background
      }
    },
    colorCustom (name) {
      return {
        color: this.section.options.customize.style['color' + name].color === 'adaptive' ? '' : this.section.options.customize.style['color' + name].color
      }
    }
  }
}
