<template>
  <div class="w-100 p-5">
    <b-container fluid>
      <b-row class="align-items-center mb-20">
        <b-col
          md="3"
          class="mb-4 mb-md-0"
        >
          <b-media class="align-items-center">
            <h4 class="font-weight-bold mb-1">
              Blog Module
            </h4>
            <h6 class="text-muted mb-0">
              My New Blog
            </h6>

            <template slot="aside">
              <svg
                width="51"
                height="51"
                viewBox="0 0 51 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M50.2715 31.671C50.0782 31.671 49.8929 31.5942 49.7563 31.4576C49.6197 31.321 49.5429 31.1357 49.5429 30.9424V28.8223C49.5429 28.6291 49.6197 28.4437 49.7563 28.3071C49.8929 28.1705 50.0782 28.0937 50.2715 28.0937C50.4647 28.0937 50.65 28.1705 50.7867 28.3071C50.9233 28.4437 51.0001 28.6291 51.0001 28.8223V30.9424C51.0001 31.1357 50.9233 31.321 50.7867 31.4576C50.65 31.5942 50.4647 31.671 50.2715 31.671Z"
                  fill="#3D82EA"
                />
                <path
                  d="M48.0857 51H2.91429C2.14137 51 1.40011 50.693 0.853575 50.1464C0.30704 49.5999 0 48.8586 0 48.0857V2.91429C0 2.14137 0.30704 1.40011 0.853575 0.853575C1.40011 0.30704 2.14137 0 2.91429 0H48.0857C48.8586 0 49.5999 0.30704 50.1464 0.853575C50.693 1.40011 51 2.14137 51 2.91429V23.7223C51 23.9155 50.9232 24.1008 50.7866 24.2375C50.65 24.3741 50.4647 24.4509 50.2714 24.4509C50.0782 24.4509 49.8929 24.3741 49.7562 24.2375C49.6196 24.1008 49.5429 23.9155 49.5429 23.7223V2.91429C49.5429 2.52783 49.3893 2.1572 49.1161 1.88393C48.8428 1.61066 48.4722 1.45714 48.0857 1.45714H2.91429C2.52783 1.45714 2.1572 1.61066 1.88393 1.88393C1.61066 2.1572 1.45714 2.52783 1.45714 2.91429V48.0857C1.45714 48.4722 1.61066 48.8428 1.88393 49.1161C2.1572 49.3893 2.52783 49.5429 2.91429 49.5429H48.0857C48.4722 49.5429 48.8428 49.3893 49.1161 49.1161C49.3893 48.8428 49.5429 48.4722 49.5429 48.0857V43.3063C49.5429 43.1131 49.6196 42.9277 49.7562 42.7911C49.8929 42.6545 50.0782 42.5777 50.2714 42.5777C50.4647 42.5777 50.65 42.6545 50.7866 42.7911C50.9232 42.9277 51 43.1131 51 43.3063V48.0857C51 48.8586 50.693 49.5999 50.1464 50.1464C49.5999 50.693 48.8586 51 48.0857 51Z"
                  fill="#3D82EA"
                />
                <path
                  d="M50.2715 38.9348C50.0782 38.9348 49.8929 38.8581 49.7563 38.7214C49.6197 38.5848 49.5429 38.3995 49.5429 38.2063V36.0424C49.5429 35.8492 49.6197 35.6639 49.7563 35.5272C49.8929 35.3906 50.0782 35.3138 50.2715 35.3138C50.4647 35.3138 50.65 35.3906 50.7867 35.5272C50.9233 35.6639 51.0001 35.8492 51.0001 36.0424V38.2063C51.0001 38.3995 50.9233 38.5848 50.7867 38.7214C50.65 38.8581 50.4647 38.9348 50.2715 38.9348Z"
                  fill="#3D82EA"
                />
                <path
                  d="M50.2714 13.1143H0.728571C0.535342 13.1143 0.350027 13.0375 0.213394 12.9009C0.0767599 12.7643 0 12.579 0 12.3857C0 12.1925 0.0767599 12.0072 0.213394 11.8706C0.350027 11.7339 0.535342 11.6572 0.728571 11.6572H50.2714C50.4647 11.6572 50.65 11.7339 50.7866 11.8706C50.9232 12.0072 51 12.1925 51 12.3857C51 12.579 50.9232 12.7643 50.7866 12.9009C50.65 13.0375 50.4647 13.1143 50.2714 13.1143Z"
                  fill="#3D82EA"
                />
                <path
                  d="M45.7332 7.26311H43.7595C43.5662 7.26311 43.3809 7.18635 43.2443 7.04972C43.1076 6.91308 43.0309 6.72777 43.0309 6.53454C43.0309 6.34131 43.1076 6.156 43.2443 6.01936C43.3809 5.88273 43.5662 5.80597 43.7595 5.80597H45.7332C45.9264 5.80597 46.1117 5.88273 46.2483 6.01936C46.385 6.156 46.4617 6.34131 46.4617 6.53454C46.4617 6.72777 46.385 6.91308 46.2483 7.04972C46.1117 7.18635 45.9264 7.26311 45.7332 7.26311Z"
                  fill="#3D82EA"
                />
                <path
                  d="M39.1761 7.26311H37.2024C37.0092 7.26311 36.8238 7.18635 36.6872 7.04972C36.5506 6.91308 36.4738 6.72777 36.4738 6.53454C36.4738 6.34131 36.5506 6.156 36.6872 6.01936C36.8238 5.88273 37.0092 5.80597 37.2024 5.80597H39.1761C39.3693 5.80597 39.5546 5.88273 39.6913 6.01936C39.8279 6.156 39.9047 6.34131 39.9047 6.53454C39.9047 6.72777 39.8279 6.91308 39.6913 7.04972C39.5546 7.18635 39.3693 7.26311 39.1761 7.26311Z"
                  fill="#3D82EA"
                />
                <path
                  d="M32.6188 7.26311H30.6451C30.4519 7.26311 30.2666 7.18635 30.13 7.04972C29.9933 6.91308 29.9166 6.72777 29.9166 6.53454C29.9166 6.34131 29.9933 6.156 30.13 6.01936C30.2666 5.88273 30.4519 5.80597 30.6451 5.80597H32.6188C32.8121 5.80597 32.9974 5.88273 33.134 6.01936C33.2706 6.156 33.3474 6.34131 33.3474 6.53454C33.3474 6.72777 33.2706 6.91308 33.134 7.04972C32.9974 7.18635 32.8121 7.26311 32.6188 7.26311Z"
                  fill="#3D82EA"
                />
                <path
                  d="M25.4272 7.21724H5.44903C5.2558 7.21724 5.07049 7.14048 4.93385 7.00385C4.79722 6.86722 4.72046 6.6819 4.72046 6.48867C4.72046 6.29544 4.79722 6.11013 4.93385 5.9735C5.07049 5.83686 5.2558 5.7601 5.44903 5.7601H25.4272C25.6204 5.7601 25.8057 5.83686 25.9424 5.9735C26.079 6.11013 26.1558 6.29544 26.1558 6.48867C26.1558 6.6819 26.079 6.86722 25.9424 7.00385C25.8057 7.14048 25.6204 7.21724 25.4272 7.21724Z"
                  fill="#3D82EA"
                />
                <path
                  d="M20.4001 31.8626H7.28582C7.09259 31.8626 6.90728 31.7859 6.77064 31.6492C6.63401 31.5126 6.55725 31.3273 6.55725 31.134V18.2143C6.55725 18.0211 6.63401 17.8357 6.77064 17.6991C6.90728 17.5625 7.09259 17.4857 7.28582 17.4857H20.4001C20.5933 17.4857 20.7787 17.5625 20.9153 17.6991C21.0519 17.8357 21.1287 18.0211 21.1287 18.2143V31.134C21.1287 31.3273 21.0519 31.5126 20.9153 31.6492C20.7787 31.7859 20.5933 31.8626 20.4001 31.8626ZM8.01439 30.4055H19.6715V18.9429H8.01439V30.4055Z"
                  fill="#3D82EA"
                />
                <path
                  d="M43.7144 19.0645H25.5001C25.3069 19.0645 25.1216 18.9878 24.9849 18.8511C24.8483 18.7145 24.7715 18.5292 24.7715 18.336C24.7715 18.1427 24.8483 17.9574 24.9849 17.8208C25.1216 17.6842 25.3069 17.6074 25.5001 17.6074H43.7144C43.9076 17.6074 44.0929 17.6842 44.2296 17.8208C44.3662 17.9574 44.443 18.1427 44.443 18.336C44.443 18.5292 44.3662 18.7145 44.2296 18.8511C44.0929 18.9878 43.9076 19.0645 43.7144 19.0645Z"
                  fill="#3D82EA"
                />
                <path
                  d="M43.7144 25.5284H25.5001C25.3069 25.5284 25.1216 25.4517 24.9849 25.315C24.8483 25.1784 24.7715 24.9931 24.7715 24.7999C24.7715 24.6066 24.8483 24.4213 24.9849 24.2847C25.1216 24.148 25.3069 24.0713 25.5001 24.0713H43.7144C43.9076 24.0713 44.0929 24.148 44.2296 24.2847C44.3662 24.4213 44.443 24.6066 44.443 24.7999C44.443 24.9931 44.3662 25.1784 44.2296 25.315C44.0929 25.4517 43.9076 25.5284 43.7144 25.5284Z"
                  fill="#3D82EA"
                />
                <path
                  d="M43.7144 31.9923H25.5001C25.3069 31.9923 25.1216 31.9155 24.9849 31.7789C24.8483 31.6423 24.7715 31.457 24.7715 31.2637C24.7715 31.0705 24.8483 30.8852 24.9849 30.7485C25.1216 30.6119 25.3069 30.5352 25.5001 30.5352H43.7144C43.9076 30.5352 44.0929 30.6119 44.2296 30.7485C44.3662 30.8852 44.443 31.0705 44.443 31.2637C44.443 31.457 44.3662 31.6423 44.2296 31.7789C44.0929 31.9155 43.9076 31.9923 43.7144 31.9923Z"
                  fill="#3D82EA"
                />
                <path
                  d="M43.7142 38.4562H7.6499C7.45667 38.4562 7.27135 38.3794 7.13472 38.2428C6.99809 38.1062 6.92133 37.9209 6.92133 37.7276C6.92133 37.5344 6.99809 37.3491 7.13472 37.2124C7.27135 37.0758 7.45667 36.9991 7.6499 36.9991H43.7142C43.9074 36.9991 44.0927 37.0758 44.2294 37.2124C44.366 37.3491 44.4428 37.5344 44.4428 37.7276C44.4428 37.9209 44.366 38.1062 44.2294 38.2428C44.0927 38.3794 43.9074 38.4562 43.7142 38.4562Z"
                  fill="#3D82EA"
                />
                <path
                  d="M43.7142 44.9208H7.6499C7.45667 44.9208 7.27135 44.8441 7.13472 44.7074C6.99809 44.5708 6.92133 44.3855 6.92133 44.1923C6.92133 43.999 6.99809 43.8137 7.13472 43.6771C7.27135 43.5404 7.45667 43.4637 7.6499 43.4637H43.7142C43.9074 43.4637 44.0927 43.5404 44.2294 43.6771C44.366 43.8137 44.4428 43.999 44.4428 44.1923C44.4428 44.3855 44.366 44.5708 44.2294 44.7074C44.0927 44.8441 43.9074 44.9208 43.7142 44.9208Z"
                  fill="#3D82EA"
                />
              </svg>
            </template>
          </b-media>
        </b-col>

        <b-col
          md="4"
          offset-md="1"
          class="mb-4 mb-md-0"
        >
          <b-input
            v-model="filter"
            placeholder="Search for a comment"
          />
        </b-col>
      </b-row>

      <h2 class="font-weight-bold mb-4">
        Manage Comments
      </h2>

      <b-card
        class="mb-5"
        body-class="px-0 py-0"
        header-class="sticky-top border-bottom table-sticky-offset-top bg-white position-relative px-5 py-4"
      >
        <template #header>
          <div class="d-flex align-items-center justify-content-between flex-wrap">
            <b-skeleton-wrapper :loading="!post">
              <template #loading>
                <b-skeleton
                  height="17px"
                  width="200px"
                />
                <b-skeleton
                  height="28px"
                  width="300px"
                />
              </template>

              <div class="mb-4 mb-md-0">
                <span class="text-muted">{{ post.created_at | moment('MM/DD/YY [at] hh:mma') }}</span>
                <h4>{{ post.title }}</h4>
              </div>
            </b-skeleton-wrapper>

            <transition
              name="fade-fast"
              mode="out-in"
            >
              <blog-manager-comments-menu-items
                v-if="selectedItems.length"
                class="selected-all-menu position-relative d-flex flex-wrap dropdown-menu border-none py-0"
                @hide="hideAll"
                @show="showAll"
                @approve="approveAll"
                @delete="deleteAll"
              />
              <div
                v-else
                class="d-flex align-items-center"
              >
                <div class="d-flex align-items-center text-muted">
                  <svg
                    class="mr-2"
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.59766 2.36658H14.9428V3.38081H4.59766V2.36658Z"
                      fill="#6E747A"
                    />
                    <path
                      d="M4.59766 4.39502H11.224V5.40925H4.59766V4.39502Z"
                      fill="#6E747A"
                    />
                    <path
                      d="M4.59766 6.42346H12.9143V7.43769H4.59766V6.42346Z"
                      fill="#6E747A"
                    />
                    <path
                      d="M2.2313 0V3.38076H0V15.66L4.06577 12.4074H12.475V9.80421H13.2437L17.3095 13.0568V0H2.2313ZM11.4608 11.3932H3.70998L1.01423 13.5498V4.39499H2.2313V9.80421H11.4608V11.3932ZM16.2953 10.9466L13.5995 8.78998H3.24553V1.01423H16.2953V10.9466Z"
                      fill="#6E747A"
                    />
                  </svg>

                  <span><span class="font-weight-bold">{{ totalRows }}</span> Comments (<span
                    class="font-weight-bold"
                  >{{ count_approved }}</span> approved)</span>
                </div>
              </div>
            </transition>
          </div>
        </template>

        <b-table
          ref="table"
          class="module-table"
          :items="provider"
          :fields="headers"
          :per-page="perPage"
          :current-page="currentPage"
          borderless
          :busy.sync="isBusy"
          responsive
          :filter="filter"
          show-empty
        >
          <template #head(id)>
            <b-checkbox
              v-if="items.length"
              :checked="selectItem"
              @change="onSelectAll"
            />
          </template>

          <template #cell(id)="{item}">
            <b-checkbox
              v-model="selectedItems"
              :value="item.id"
            />
          </template>

          <template #cell(comment)="{item}">
            <div class="d-flex">
              <div
                v-if="item.moderation"
                class="awaiting-badge p-1 px-2 rounded font-size-12 font-weight-bold mb-2 mr-2"
              >
                Awaiting Approval
              </div>
              <div
                v-else
                class="approved-badge p-1 px-2 rounded font-size-12 font-weight-bold mb-2 mr-2"
              >
                <svg
                  class="mr-1"
                  width="9"
                  height="7"
                  viewBox="0 0 9 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.14017 0.984835C8.28661 1.13128 8.28661 1.36872 8.14017 1.51517L3.64016 6.01516C3.49372 6.16161 3.25628 6.16161 3.10984 6.01516L0.859835 3.76516C0.713388 3.61872 0.713388 3.38128 0.859835 3.23484C1.00628 3.08839 1.24372 3.08839 1.39017 3.23484L3.375 5.21967L7.60983 0.984835C7.75628 0.838388 7.99372 0.838388 8.14017 0.984835Z"
                    fill="#67ADF2"
                    stroke="#67ADF2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Approved
              </div>

              <div
                v-if="item.is_hide"
                class="hidden-badge p-1 px-2 rounded font-size-12 font-weight-bold mb-2"
              >
                <svg
                  class="mr-1"
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.5 6C8.5 7.38071 7.38071 8.5 6 8.5C4.61929 8.5 3.5 7.38071 3.5 6C3.5 4.61929 4.61929 3.5 6 3.5C7.38071 3.5 8.5 4.61929 8.5 6ZM7.5 6C7.5 6.82843 6.82843 7.5 6 7.5C5.17157 7.5 4.5 6.82843 4.5 6C4.5 5.17157 5.17157 4.5 6 4.5C6.82843 4.5 7.5 5.17157 7.5 6Z"
                    fill="#A7A7A7"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 6C12 7.25 9.31371 10.5 6 10.5C2.68629 10.5 0 7.5 0 6C0 4.5 2.68629 1.5 6 1.5C9.31371 1.5 12 4.75 12 6ZM10.9998 5.99946L10.9998 6L10.9998 6.00054C10.9986 6.01043 10.9911 6.07245 10.9444 6.1943C10.8929 6.32853 10.8079 6.49886 10.6831 6.69627C10.4334 7.09145 10.0564 7.54472 9.57689 7.97257C8.60547 8.83928 7.33248 9.5 6 9.5C4.64359 9.5 3.36677 8.88094 2.41089 8.0556C1.93687 7.64632 1.56704 7.20718 1.3226 6.81092C1.06443 6.3924 1 6.1108 1 6C1 5.8892 1.06443 5.6076 1.3226 5.18908C1.56704 4.79282 1.93687 4.35368 2.41089 3.9444C3.36677 3.11906 4.64359 2.5 6 2.5C7.33248 2.5 8.60547 3.16071 9.57689 4.02743C10.0564 4.45528 10.4334 4.90855 10.6831 5.30373C10.8079 5.50114 10.8929 5.67147 10.9444 5.8057C10.9911 5.92755 10.9986 5.98957 10.9998 5.99946Z"
                    fill="#A7A7A7"
                  />
                  <line
                    x1="11.7305"
                    y1="0.53033"
                    x2="0.530526"
                    y2="11.7303"
                    stroke="#A7A7A7"
                    stroke-width="1.5"
                  />
                </svg>

                Hidden
              </div>
            </div>
            <p
              class="table-comment pr-5"
              v-text="item.comment"
            />
          </template>

          <template #cell(name)="{item}">
            {{ item.guest_full_name || item.user.name }}
          </template>

          <template #cell(email)="{item}">
            {{ item.guest_email || item.user.email }}
          </template>

          <template #cell(actions)="{index, item}">
            <b-dropdown
              variant="link"
              toggle-class="link-muted bg-transparent border-none"
            >
              <template #button-content>
                <svg
                  width="24"
                  height="5"
                  viewBox="0 0 24 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="2.5"
                    cy="2.5"
                    r="2.5"
                    fill="#4F83E3"
                  />
                  <circle
                    cx="12.0713"
                    cy="2.5"
                    r="2.5"
                    fill="#4F83E3"
                  />
                  <circle
                    cx="21.3574"
                    cy="2.5"
                    r="2.5"
                    fill="#4F83E3"
                  />
                </svg>
              </template>

              <blog-manager-comments-menu-items
                :item="item"
                @approve="() => singleApprove(item)"
                @hide="() => singleHide(item)"
                @show="() => singleShow(item)"
                @delete="() => singleDelete(item)"
                @reply="() => reply(item)"
              />
            </b-dropdown>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-5">
              <b-spinner
                small
                class="align-middle mr-2"
              />
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>

        <div class="d-flex justify-content-center w-100 border-top py-4">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            class="my-0"
          />
        </div>
      </b-card>

      <!--    <blog-manage-reply-modal ref="modal" />-->
    </b-container>
  </div>
</template>

<script>
import BlogManagerCommentsMenuItems from './components/BlogManagerCommentsMenuItems'

export default {
  name: 'BlogManageCommnets',
  components: {BlogManagerCommentsMenuItems},
  data () {
    return {
      post: false,
      isBusy: false,
      perPage: 5,
      currentPage: 1,
      totalRows: 0,
      headers: [
        {
          key: 'id',
          label: '',
          thStyle: {width: '50px'}
        },
        {
          key: 'comment',
          label: 'Comment',
          thStyle: {width: '700px'},
          tdClass: 'table-comment'
        },
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      items: [],
      selectedItems: [],
      filter: '',
      count_approved: 0
    }
  },

  computed: {
    selectItem () {
      return this.selectedItems.length === this.getAllIDs.length ? true : false
    },
    getAllIDs () {
      return this.items.map(item => item.id)
    }
  },

  created () {
    this.$store.dispatch('blog/getPost', this.$route.params.post_id)
      .then(({data}) => {
        this.post = data.data
      })
  },

  methods: {
    hideAll () {
      this.updateComments({
        data: {
          action: 'hide',
          ids: this.selectedItems
        }, message: 'Comments has been hidden'
      })
        .then(() => {
          this.$refs.table.refresh()
        })
    },
    approveAll () {
      this.updateComments({
        data: {
          action: 'approve',
          ids: this.selectedItems
        }, message: 'Comments has been approved'
      })
        .then(() => {
          this.$refs.table.refresh()
        })
    },
    deleteAll () {
      this.updateComments({
        data: {
          action: 'delete',
          ids: this.selectedItems
        }, message: 'Comments has been deleted'
      })
        .then(() => {
          this.$refs.table.refresh()
        })
    },
    showAll () {
      this.updateComments({
        data: {
          action: 'public',
          ids: this.selectedItems
        }, message: 'Comments has been published'
      })
        .then(() => {
          this.$refs.table.refresh()
        })
    },
    singleApprove (item) {
      this.updateComment({item: {...item, moderation: 0}, message: 'Comment has been approved'})
        .then(() => {
          item.moderation = 0
        })
    },
    singleHide (item) {
      this.updateComment({item: {...item, is_hide: 1}, message: 'Comment has been hidden'})
        .then(() => {
          item.is_hide = 1
        })
    },
    singleShow (item) {
      this.updateComment({item: {...item, is_hide: 0}, message: 'Comment has been published'})
        .then(() => {
          item.is_hide = 0
        })
    },
    reply (item) {
      this.$refs.modal.open(item)
    },
    singleDelete (item) {
      const deleteComment = (resolve) => {
        this.$store.dispatch('blog/deleteComment', item)
          .then(data => {
            this.$swal({
              icon: 'success',
              iconColor: '#4F83E3',
              title: 'Comment has been deleted',
              showConfirmButton: false,
              timer: 1500
            })

            return resolve(data)
          })
      }

      return this.loading(deleteComment)
        .then(() => {
          this.$refs.table.refresh()
        })
    },
    loading (callback) {
      return new Promise(resolve => {
        this.$swal.fire({
          title: 'Please wait...',
          timerProgressBar: true,
          iconColor: '#4F83E3',
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
            return callback(resolve)
          }
        })
      })
    },
    updateComment ({item, message}) {
      const update = (resolve) => {
        this.$store.dispatch('blog/updateComment', item)
          .then(data => {
            this.$swal({
              icon: 'success',
              iconColor: '#4F83E3',
              title: message,
              showConfirmButton: false,
              timer: 1500
            })

            return resolve(data)
          })
      }

      return this.loading(update)
    },
    updateComments ({data, message}) {
      const update = (resolve) => {
        this.$store.dispatch('blog/updateComments', {...data, blog_id: this.post.id})
          .then(data => {
            this.$swal({
              icon: 'success',
              iconColor: '#4F83E3',
              title: message,
              showConfirmButton: false,
              timer: 1500
            })

            return resolve(data)
          })
      }

      return this.loading(update)
    },
    onSelectAll (val) {
      if (val) {
        return this.selectedItems = this.getAllIDs
      }

      this.selectedItems = []
    },
    provider ({currentPage, filter}, callback) {
      this.isBusy = true
      this.selectedItems = []

      axios.get(`api/projects/${this.$route.params.id}/blog/manage/${this.$route.params.post_id}/comments?page=${currentPage}&perPage=${this.perPage}&search=${filter}`)
        .then(({data}) => {
          this.totalRows = data.data.total
          this.items = data.data.data
          this.count_approved = data.data.count_approved
          callback(this.items)
        })
        .catch(() => {
          callback([])
        })
        .finally(() => {
          this.isBusy = false
          return []
        })
    }
  }
}
</script>

<style>
.table-comment {
  font-size: 14px;
  color: #5E5E5E;
}
</style>