<template>
  <div
    class="p-[10px] cursor-pointer text-nowrap hover:bg-gray-100 transition text-truncate"
    :class="{'bg-soft-primary text-primary' : currentPage.id === page.id}"
    :style="{maxWidth: '254px'}"
  >
    {{ page.title }}
    <span
      v-if="page.category"
      class="text-muted"
    >| {{ page.category.name }}</span>
  </div>
</template>

<script>
export default {
  name: 'PagesSelectMenuPageItem',
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  computed: {
    currentPage () {
      return this.$store.state.pages.page
    }
  }
}
</script>