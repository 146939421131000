import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Post Grid'
export const group = 'Dynamic Blog Home'
export const beta = false

export const defaultOptions = {
  content: {
    post: {
      type: 'post-grid-mod',
      settings: {
        author: [],
        categories: [],
        exclude: [],
        postsPerPage: 6,
        pagination: true,
        order: 'date',
        OrderBy: 'desc',
        maxExcerptWords: '',
        maxTitleWords: '',
        showImage: true,
        showExcerpt: true,
        showButton: true,
        showReadMoreText: true,
        showAuthorAvatar: true,
        showAuthorName: true,
        showStats: true,
        showDate: true,
        showCategories: true,
        PostThumb: {
          display: true
        },
        PostTitle: {
          display: true
        },
        PostText: {
          display: true
        },
        PostButton: {
          display: true
        },
        PostCategories: {
          display: true
        },
        PostInfo: {
          display: true
        }
      },
      subjects: [
        {
          mod: 'post-thumb-mod',
          component: 'PostThumb'
        },
        {
          mod: 'post-category-mod',
          component: 'PostCategories'
        },
        {
          mod: 'post-info-mod',
          component: 'PostInfo'
        },
        {
          mod: 'post-title-mod',
          component: 'PostTitle'
        },
        {
          mod: 'post-text-mod',
          component: 'PostText'
        },
        {
          mod: 'post-button-mod',
          component: 'PostButton'
        }
      ]
    }
  }
}

export const icon = `
`

export default class ElPostGrid extends SectionProvider {
  constructor (options = {}) {
    super('ElPostGrid', options)

    this.name = name
    this.group = group
    this.disableVisibility = true
    this.defaultOptions = defaultOptions
  }
}
