<template>
  <highliter
    v-if="section.options.customize.display"
    v-model="section.name"
    :can-delete="false"
    :can-duplicate="false"
    :dublicate="section"
    :class="'navbar-' + resolution"
    class="navbar-2 highlight-section"
    label="Navigation"
    uid="#navigation"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="(to) => $emit('move-to', to)"
    @click.stop.native="sectionSettings('menuItems')"
  >
    <div :style="{ ...backgroundColor, ...shadow }">
      <b-navbar :toggleable="isOutside || resolution === 'sm'">
        <b-container>
          <b-navbar-brand>
            <highliter
              :class="{'element-hidden': !visability('logo')}"
              :disable-toolbar="true"
              @delete="$emit('delete')"
              @dublicate="$emit('dublicate')"
              @favorite="$emit('favorite', $el)"
              @flow="handleFlow"
              @move-to="(to) => $emit('move-to', to)"
              @click.stop.native="sectionSettings('logo')"
            >
              <es-logo :customs="section.options.content.logo" />
            </highliter>
          </b-navbar-brand>

          <b-navbar-toggle
            class="pr-0"
            target="nav-collapse"
          >
            <template #default="{ expanded }">
              <i
                v-if="expanded"
                :class="iconClose"
                :style="{color: iconColor}"
              />
              <i
                v-else
                :class="iconOpen"
                :style="{color: iconColor}"
              />
            </template>
          </b-navbar-toggle>

          <b-collapse
            id="nav-collapse"
            :class="{'opacity-0': !initialized}"
            is-nav
          >
            <highliter
              v-if="visability('menuItems')"
              :disable-toolbar="true"
              class="links"
              @delete="$emit('delete')"
              @dublicate="$emit('dublicate')"
              @favorite="$emit('favorite', $el)"
              @flow="handleFlow"
              @move-to="(to) => $emit('move-to', to)"
              @click.stop.native="sectionSettings('menuItems')"
            >
              <es-menu-items
                :customs="section.options.content.menuItems"
              />
            </highliter>

            <b-navbar-nav
              v-if="showButton"
              :class="{ 'ml-auto': !visability('menuItems') }"
            >
              <highliter
                :class="{ 'element-hidden': !visability('button') }"
                :disable-toolbar="true"
                auto-width
                @delete="$emit('delete')"
                @dublicate="$emit('dublicate')"
                @favorite="$emit('favorite', $el)"
                @flow="handleFlow"
                @move-to="(to) => $emit('move-to', to)"
                @click.stop.native="sectionSettings('button')"
              >
                <es-button :customs="section.options.content.button" />
              </highliter>
            </b-navbar-nav>
          </b-collapse>
        </b-container>
      </b-navbar>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import EsMenuItems from '@builder/components/builder/utils/EsMenuItems'
import _ from 'lodash'
import NavbarScroller from '../../../../mixins/NavbarScroller'

export default {
  name: 'Navbar1',

  components: {EsMenuItems, Highliter},

  mixins: [SectionMixin, NavbarScroller],

  computed: {
    iconColor () {
      return _.get(this.section.options.content.menuItems, 'hamburgerIcon_color', '#656565')
    },
    showButton () {
      return _.get(this.section.options.content.menuItems, 'showButton', true)
    },
    iconOpen () {
      return _.get(
        this.section.options.content.menuItems,
        'hamburgerIcon_open',
        'fa fa-bars'
      )
    },
    iconClose () {
      return _.get(
        this.section.options.content.menuItems,
        'hamburgerIcon_close',
        'fa fa-times'
      )
    }
  },

  created () {
    if (!this.section.options.content.menuItems.hasOwnProperty('align')) {
      this.$set(this.section.options.content.menuItems, 'align', '')
    }
  }
}
</script>

<style lang="scss">
.navbar-2 {
  .nav-item {
    font-weight: bold;
  }
}

.resolution-lg {
  .navbar-2 {
    .links {
      margin-right: auto;

      .nav-item {
        margin-right: 20px !important;
      }
    }

    .nav-item:not(:last-child) {
      margin-right: 70px !important;
    }
  }
}

.resolution-sm {
  .navbar-2 {
    .btn {
      display: block;
      width: 100%;
    }
  }
}
</style>
