<template>
  <div>
    <!--    <div class="d-flex align-items-center">-->
    <!--      <b-link class="flex items-center text-decoration-none text-dark mt-[-1px] font-weight-bold transition border h-[73px] text-[23px] px-[59px]">-->
    <!--        Domain Management-->
    <!--      </b-link>-->
    <!--      <b-link-->
    <!--        :to="{name: 'settings.cloudflare-enterprise'}"-->
    <!--        class="flex items-center text-decoration-none text-[#B6B6B6] bg-[#E2E5EC] hover:text-black hover:bg-white transition mt-[-1px] font-weight-bold border h-[73px] text-[23px] px-[59px] ml-[-1px]"-->
    <!--      >-->
    <!--        Cloudfare Enterprise-->
    <!--      </b-link>-->
    <!--    </div>-->
    <settings-page-card>
      <div class="px-4 max-w-[900px]">
        <doamin-connect-form />

        <div>
          <hr class="my-5">

          <b-alert
            show
            variant="info"
            class="mb-4"
          >
            <b>Note: </b>
            <span>it can take 24-48 hours for DNS Records to fully propagate.</span>
          </b-alert>

          <h5>Target domain for CNAME record</h5>
          <p>
            Below in the text field, you can copy the address that you must specify for the CNAME record from your
            registrar.
          </p>
          <b-row>
            <b-col md="6">
              <b-input-group>
                <b-input
                  :value="target"
                  readonly
                />
                <b-input-group-append>
                  <b-btn
                    v-b-tooltip.top="tooltipText"
                    v-clipboard:copy="target"
                    v-clipboard:success="onCopy"
                    variant="primary"
                  >
                    <i class="fa fa-clipboard" />
                  </b-btn>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <hr class="my-5">

          <h4
            class="font-bold"
            style="font-size:22px;"
          >
            How To Connect Your Custom Domain to ESTAGE
          </h4>
          <p
            class="mb-4"
            style="font-size: 16px;"
          >
            ESTAGE application allows its users to set up and connect a custom domain for each project.
            By default, your project will be given a sub-domain from ESTAGE <span class="text-[#6E747A]">(Ex: https://project-1234.estage.site)</span>, <strong>but we highly recommend registering and setting up your own custom domain.</strong>
          </p>

          <p
            class="mb-4"
            style="font-size: 16px;"
          >
            Setting up a custom domain is simple and easy!
          </p>

          <hr class="my-5">

          <div class="border rounded-[7px] overflow-hidden mb-[50px] shadow-[0px_1px_6px_rgba(0,0,0,.07)]">
            <h4
              class="font-bold bg-[#F9F9FB] h-[59px] flex items-center !text-[18px] px-[38px]"
            >
              <span class="text-[#3D82EA] mr-2">STEP 1:</span> Register a new domain or choose from an existing domain you would like to use.
            </h4>
            <div class="p-[38px]">
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                You can register a new domain on <a
                  class="text-primary"
                  href="https://godaddy.com"
                  target="_blank"
                >GoDaddy.com</a> or
                <a
                  class="text-primary"
                  href="https://namecheap.com"
                  target="_blank"
                >Namecheap.com</a> or at any other domain registrar of your choice. (we recommend GoDaddy or Namecheap as they’re the most popular and offer the most flexibility and tools)
              </p>
              <p
                style="font-size: 16px;"
              >
                Once you’ve registered a domain, your next step is to connect it to your ESTAGE project.
              </p>
            </div>
          </div>

          <div class="border rounded-[7px] overflow-hidden mb-[50px] shadow-[0px_1px_6px_rgba(0,0,0,.07)]">
            <h4
              class="font-bold bg-[#F9F9FB] h-[59px] flex items-center !text-[18px] px-[38px]"
              style="font-size:22px;"
            >
              <span class="text-[#3D82EA] mr-2">STEP 2:</span> Set Up Cloudflare.
            </h4>
            <div class="p-[38px]">
              <p
                style="font-size: 16px;"
              >
                Cloudflare is a global network designed to make everything you connect to the Internet secure, private, fast, and reliable.
              </p>

              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                1. Sign up for a ‘FREE’ Cloudflare account if you don’t already have one. (click here to create a
                <a
                  class="text-primary"
                  href="https://www.cloudflare.com/"
                  target="_blank"
                >Cloudflare account</a>).
              </p>
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                2. Add your domain name into your Cloudflare account.
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/3 - Add new domain into cloudflare.png"
              >
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                3. Choose free Cloudflare option and click continue.
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/4 - Choose Free Cloudflare option.png"
              >
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                4. Delete all records previously imported by Cloudflare.
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/5 - Delete all records automatically imported by Cloudflare.png"
              >
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                5. Add a new “www” CNAME record pointing to “target.estage.com”.
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/6 - Add 'www' CNAME pointing to 'target.estage.com'.png"
              >
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                6. Add a new “@” CNAME record pointing to “target.estage.com”.
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/6b - Add '@' CNAME pointing to 'target.estage.com'.png"
              >
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                7. Add a new TXT record. Put “@” for NAME and “estage” in the CONTENT box, as you see in the image below.
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/6c - Add TXT record for domain value = 'estage'.png"
              >
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                8. Please make sure that the orange cloud (Proxy status) is enabled for all records. (Clicking on the cloud will enable/disable).
              </p>
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                9. Change nameservers in your domain registrar (GoDaddy or Namecheap, etc.) to point to your Cloudflare nameservers, as shown in your Cloudflare account. (If the domain is registered with Cloudflare, skip this step).
              </p>
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                10. If you’re prompted to check the “Quick Start Guide,” click “Get Started” and check to make sure the following settings are as follows:
                <br> <strong>Automatic HTTPS Rewrites</strong> = ‘ON’
                <br> <strong>Always Use HTTPS</strong> = ‘ON’
                <br> <strong>Brotli</strong> = ‘ON’
                <br><br> Be sure to save it all and click “Finish”.
              </p>
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                11. On the left side, click on <strong>“SSL/TLS”</strong> menu and make sure that your “SSL/TLS encryption mode” is set to <strong>“Full.”</strong>.
              </p>
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                12. On the left side, click on “Speed,” then go to “Optimization” - scroll down and turn ‘ON’ Rocket Loader. This will help improve your page performance.
              </p>
            </div>
          </div>

          <div class="border rounded-[7px] overflow-hidden mb-[50px] shadow-[0px_1px_6px_rgba(0,0,0,.07)]">
            <h4
              class="font-bold bg-[#F9F9FB] h-[59px] flex items-center !text-[18px] px-[38px]"
              style="font-size:22px;"
            >
              <span class="text-[#3D82EA] mr-2">STEP 3:</span> Cloudflare API Token Setup
            </h4>

            <div class="p-[38px]">
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                We have developed a proprietary technology in collaboration with Cloudflare to take your HUB or project security and performance to the next level. Please follow the steps outlined below to create and connect your private token. This is VERY IMPORTANT.
              </p>

              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                1. Make sure that you are logged in to your Cloudflare account, and then
                <a
                  class="text-primary underline"
                  href="https://dash.cloudflare.com/profile/api-tokens"
                  target="_blank"
                >CLICK HERE</a>.
                <br> (this should take you to your Cloudflare profile page ⇒ API Tokens)
              </p>
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                2. Click on <strong>‘Create Token’</strong>
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/1.jpg"
              >
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                3. Then click on the blue <strong>“Use Template”</strong> button for <strong>WordPress</strong>.
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/2.jpg"
              >
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                4. Click on the <strong>“pencil” icon and name your token</strong>. Since you’re creating this token for this specific project or HUB, we recommend using the same name as your project or HUB.
                (For example, if your project domain is www.AskVickDemo.com, you can name your token ‘AskVickDemo’ for easy reference later.)
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/3.jpg"
              >

              <hr class="my-4">

              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/3.1.jpg"
              >
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                5. Under ‘Account’ drop-down, select <strong>‘Zone’</strong>, under ‘Select Item’ drop-down, select <strong>‘Page Rules’</strong> and set permission to <strong>‘Edit’</strong>. Here’s a screenshot of how it should look:
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/4.jpg"
              >

              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                6. Next, click on the same <strong>“Add More”</strong> link to add another permission, and under the <strong>“Account”</strong> drop-down, select <strong>“Zone”</strong>, under the <strong>“Select Item”</strong> drop-down, choose <strong>“DNS”</strong> and set permission to <strong>“Edit”</strong>. Here’s a screenshot of how it should look:
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/3.7.jpg"
              >

              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                7. Scroll down and click on the blue <strong>“Continue to summary”</strong> button.
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/3.8.jpg"
              >
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                8. Next, click on the blue <strong>“Create Token”</strong> button.
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/6.jpg"
              >

              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                9. Copy your API Token code by clicking the “Copy” button.
              </p>
              <img
                class="img-fluid mb-4"
                src="@builder/assets/images/domain-settings/7.jpg"
              >

              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                10. Paste your API Token code into the field below and click the blue <strong>“Save”</strong> button.
              </p>
              <div class="mb-4">
                <DoaminTokenConnectForm />
              </div>
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                11. That’s it! You’re ready for your next and final step.
              </p>
            </div>
          </div>

          <div class="border rounded-[7px] overflow-hidden mb-[50px] shadow-[0px_1px_6px_rgba(0,0,0,.07)]">
            <h4
              class="font-bold bg-[#F9F9FB] h-[59px] flex items-center !text-[18px] px-[38px]"
              style="font-size:22px;"
            >
              <span class="text-[#3D82EA] mr-2">STEP 4:</span> Connect Your Domain!
            </h4>
            <div class="p-[38px]">
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                Once you’ve completed steps 1, 2 and 3, your final step is to connect your custom domain to your project/website/hub.
              </p>
              <p
                class="mb-4"
                style="font-size: 16px;"
              >
                Simply enter your custom domain in the field above and click the “Add Custom Domain” button.
              </p>

              <img
                class="img-fluid mx-[-38px] mb-4"
                src="@builder/assets/images/domain-settings/8.jpg"
              >

              <p
                class="mb-4 font-bold"
                style="font-size: 19px;"
              >
                That’s all. Congratulation, you’ve set up your custom domain!
              </p>
              <b-alert
                show
              >
                Please allow up to 24-72 hours for possible propagation/update to take place, and when it’s ready, you’ll see a green “Domain Connected” status showing as confirmation that your domain is connected and ready to be used!
              </b-alert>
            </div>
          </div>
        </div>
      </div>
    </settings-page-card>
  </div>
</template>

<script>
import DoaminConnectForm from './DoaminConnectForm'
import SettingsPageCard from '../../layouts/dashboard/SettingsPageCard'
import DoaminTokenConnectForm from '@/views/domain/DoaminTokenConnectForm'

export default {
  name: 'Domain',
  components: {DoaminTokenConnectForm, SettingsPageCard, DoaminConnectForm},
  data () {
    return {
      tooltipText: 'Copy',
      target: 'target.estage.com'
    }
  },
  methods: {
    onCopy () {
      this.tooltipText = 'Copied!'

      setTimeout(() => {
        this.tooltipText = 'Copy'
      }, 800)
    }
  }
}
</script>