import _ from 'lodash'

export default {
  props: {
    effectTemplate: {
      type: Object,
      required: true
    }
  },
  methods: {
    removePoint () {
      // if (this.motion.values) {
      const index = this.motion.values.findIndex(point => point.progress == this.progress)
      if (index !== -1) {
        return this.motion.values.splice(index, 1)
      }
      // }

      // const index = this.motion.x.findIndex(point => point.progress == this.progress)
      // this.motion.x.splice(index, 1)
      // this.motion.y.splice(index, 1)
    },
    addPoint () {
      // if (this.motion.values) {
      this.motion.values.push(_.clone(this.effectTemplate))
      // }
      //
      // this.motion.x.push(_.clone(this.effectTemplate))
      // this.motion.y.push(_.clone(this.effectTemplate))
    }
  }
}