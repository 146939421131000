<template>
  <div class="new-project-card">
    <div
      class="preview-project-image"
      :style="{backgroundImage: `url(${project.screenshot ? project.screenshot.replace('/upload', '/upload/q_10') : ''})`}"
    >
      <div class="overlay">
        <b-btn
          class="mr-2"
          variant="outline-light"
          :disabled="restoring || deleting"
          @click="restore"
        >
          Restore <b-spinner
            v-if="restoring"
            class="ml-2"
            small
          />
        </b-btn>

        <delete-confirm
          title="Are you sure?"
          :description="`The <b>${project.name}</b> project will be removed along with the content.`"
          @confirm="deletePage"
        >
          <template v-slot:activator="{on}">
            <b-btn
              variant="outline-light"
              :disabled="restoring || deleting"
              v-on="on"
            >
              Delete <b-spinner
                v-if="deleting"
                class="ml-2"
                small
              />
            </b-btn>
          </template>
        </delete-confirm>
      </div>
    </div>

    <div class="d-flex align-items-center footer">
      <div class="mr-auto">
        <h5 class="new-project-card-name text-truncate mb-2">
          {{ project.name }}
        </h5>
        <div class="new-project-card-status">
          <div class="d-flex align-items-center">
            Will be deleted after {{ project.deletion_date | moment("from", true) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteConfirm from '../../../../components/editor/modals/DeleteConfirm'
export default {
  name: 'ArchiveProjectCard',
  components: {DeleteConfirm},
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      restoring: false,
      deleting: false
    }
  },
  methods: {
    deletePage () {
      this.deleting = true
      axios.delete(`api/projects/${this.project.id}/permanently`)
        .then(() => {
          this.$emit('deleted')
          this.$swal({
            icon: 'success',
            title: 'Done!',
            text: 'The page have been delete from your project',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          this.deleting = false
        })
    },
    restore () {
      this.restoring = true
      axios.get(`api/projects/${this.project.id}/restore`)
        .then(() => {
          this.$emit('restored')

          this.$swal({
            icon: 'success',
            title: 'Done!',
            text: 'The page have been restored.',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          this.restoring = false
        })
    }
  }
}
</script>

<style lang="scss">
.new-project-card {
  font-family: Raleway;
  min-height: 350px;
  background: #FFFFFF;
  border: 1px solid #E2E5EC;
  box-sizing: border-box;
  border-radius: 4px;
  transition: .4s;

  &:hover {
    filter: unset !important;
    background: #F1F5FD;
  }

  .new-project-card-name {
    color: #44474A;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 3px;
    max-width: 260px;
  }

  .new-project-card-status {
    font-size: 15px;
    color: #9FA9BA;
  }

  .preview-project-image {
    position: relative;
    height: 190px;
    background-position: top;
    background-size: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    background-color: #c9cfd7;
    overflow: hidden;

    .overlay {
      opacity: 0;
    }

    &:hover {
      .overlay {
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #252525D4;
        transition: .3s;
      }
    }
  }

  .published-indicator {
    width: 8px;
    height: 8px;
    background: #86C158;
    border-radius: 100%;
  }

  .btn-new-project-card-dropdown {
    border: unset !important;

    svg {
      z-index: 2;
    }

    &:hover {
      &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: '';
        width: 40px;
        height: 40px;
        background: rgba(226, 229, 236, 0.53);
        border-radius: 50%;
        transition: .3s;
        z-index: 1;
      }
    }
  }

  .show .btn-new-project-card-dropdown {
   &:before {
     position: absolute;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     content: '';
     width: 40px;
     height: 40px;
     background: rgba(226, 229, 236, 0.63);
     border-radius: 50%;
     transition: .3s;
     z-index: 1;
   }
 }

  & .footer {
    padding: 25px 25px;
  }

  .dashboard-menu-link {
    padding: 10px 20px !important;
  }

  .project-menu {
    padding-top: 0;
    min-width: 250px !important;
  }
}
</style>