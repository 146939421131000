<template>
  <b-modal
    v-model="newLinkDialog"
    hide-footer
    hide-header
    size="lg"
  >
    <icon
      class="cursor-pinter ml-3 mt-3"
      icon="close.svg"
      @click.native="newLinkDialog = false"
    />

    <h4 class="h2 text-center my-4">
      Add Menu Item
    </h4>

    <b-form @submit.prevent="saveLink">
      <b-row class="pb-5">
        <b-col
          cols="8"
          offset="2"
        >
          <div class="w-100">
            <b-form-group label="Name">
              <b-input
                v-model="name"
                required
              />
            </b-form-group>

            <b-form-group label="Item Type">
              <v-select
                v-model="isCustomLink"
                :options="[{label: 'Page', value: false}, {label: 'Custom Link', value: true}, {label: 'Scroll to element', value: 'scroll-to-element'}]"
                :reduce="val => val.value"
                class="custom-select"
              />
            </b-form-group>

            <b-form-group
              v-if="!isCustomLink"
              label="Page"
            >
              <div class="position-relative">
                <v-select
                  v-model="page"
                  :options="[{title: 'Create new page', create: true}, {item_type: 'module.blog', title: 'ESTAGE Blog'}, ...pages.data]"
                  class="custom-select"
                  label="title"
                  required
                  @close="onClose"
                  @open="onOpen"
                  @search="onSearch"
                >
                  <option
                    selected
                    value="null"
                  >
                    Create new page
                  </option>
                </v-select>
                <b-spinner
                  v-if="searching || pageIsLoading"
                  class="position-absolute z-index-100 right-3 top-3 bg-white"
                  small
                  variant="primary"
                />
              </div>
            </b-form-group>

            <b-form-group
              v-else-if="isCustomLink === true"
              label="Custom Link"
            >
              <b-input
                v-model="custom_link"
                required
                type="url"
              />
            </b-form-group>

            <b-form-group
              v-else-if="isCustomLink === 'scroll-to-element'"
              label="Block Element"
            >
              <select-block-page v-model="custom_link" />
            </b-form-group>

            <div class="d-flex">
              <b-btn
                :disabled="loading"
                class="btn-wide mx-auto"
                type="submit"
                variant="primary"
              >
                Add Item
              </b-btn>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {mapGetters} from 'vuex'
import PagesPaginationMixin from '../mixins/PagesPaginationMixin'
import SelectBlockPage from '../controls/SelectBlockPage'
import EventsMixin from '@/components/editor/mixins/EventsMixin'

export default {
  name: 'NewLinkModal',
  components: {SelectBlockPage},
  mixins: [PagesPaginationMixin, EventsMixin],
  data () {
    return {
      newLinkDialog: false,
      name: null,
      page: {title: 'Create new page', create: true},
      custom_link: null,
      isCustomLink: false,
      activeItem: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters('pages', {
      pages: 'getPages'
    })
  },
  watch: {
    newLinkDialog (val) {
      if (val) {
        this.$store.dispatch('pages/loadPages')
      }
    }
  },
  mounted () {
    VEvent.listen('newPageDialog', (activeItem) => {
      this.activeItem = activeItem
      this.newLinkDialog = true
    })
  },
  methods: {
    async onOpen () {
      setTimeout(() => {
        document.querySelector('.vs__dropdown-menu').addEventListener('scroll', this.scrollLoadingPages)
      })
    },
    onClose () {
      this.searching = false
      document.querySelector('.vs__dropdown-menu').removeEventListener('scroll', this.scrollLoadingPages)
    },
    saveLink () {
      VEvent.fire('alt-loader', {
        state: true,
        message: 'Saving...'
      })

      this.loading = true

      if (this.page.create && !this.isCustomLink) {
        return this.$store.dispatch('pages/addPage', {
          title: this.name,
          meta_title: this.name,
          content: '[]'
        })
          .then((data) => {
            this.page = data
            this.addLink()
          })
      }

      this.addLink()
    },
    addLink () {
      const isModule = this.page.hasOwnProperty('item_type') && this.page.item_type.search('module') === 0
      return this.$store.dispatch('menu/addNavbarItem', {
        'item_name': this.name,
        'item_type': isModule ? 'module' : this.isCustomLink === true ? 'custom' : this.isCustomLink === 'scroll-to-element' ? 'scroll-to-element' : 'page',
        'item_url': isModule ? this.page.item_type.split('.')[1] : this.custom_link,
        'item_object_id': this.page.id,
        'item_parent': this.activeItem ? this.activeItem.id : 0
      })
        .then(({data}) => {
          if (!this.activeItem) return
          this.activeItem.children.push(data.data)
        })
        .finally(() => {
          this.name = ''
          this.custom_link = ''
          this.newLinkDialog = false
          this.fire('add-navigation-menu-close')
          this.page = {'title': 'Create new page', 'create': true}
          VEvent.fire('alt-loader', {
            state: false,
            message: 'Saved.'
          })
          this.loading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
