import shortid from 'shortid'
import {mapState} from 'vuex'
import {getStylesheetEl} from '@/utils/useStylesheet'

export default {
  data () {
    return {
      linked: false
    }
  },
  computed: {
    ...mapState('pages', {
      page: state => state.page
    }),
    styleSheet () {
      const styleEl = getStylesheetEl()
      const keys = {
        lg: 0,
        md: 1,
        sm: 2
      }

      return styleEl.sheet.cssRules[keys[this.resolutioner]]
    },
    styleSheets () {
      const styleEl = getStylesheetEl()
      return [...styleEl.sheet.cssRules]
    }
  },
  methods: {
    checkLinked () {
      if (!this.section) return
      this.linked = document.body.querySelectorAll(`[data-sid="${this.section.sid}"]`).length > 1
    },
    multiSidIndexOf (selector = null) {
      const _selector = selector || this.section.sid
      var idxs = [[], [], []]

      this.styleSheets.forEach((sheet, index) => {
        for (let i = sheet.cssRules.length - 1; i >= 0; i--) {
          if (sheet.cssRules[i].selectorText.indexOf(_selector) !== -1) {
            idxs[index].unshift(i)
          }
        }
      })

      return idxs
    },
    multiSidSelectors (selector = null) {
      const _selector = selector || this.section.sid
      var idxs = [[], [], []]

      this.styleSheets.forEach((sheet, index) => {
        for (let i = sheet.cssRules.length - 1; i >= 0; i--) {
          if (sheet.cssRules[i].selectorText.indexOf(_selector) !== -1) {
            idxs[index].unshift(sheet.cssRules[i].selectorText)
          }
        }
      })

      return idxs
    },

    deleteSid (selector, sheetIndex) {
      const index = [...this.styleSheets[sheetIndex].cssRules].findIndex(_s => _s.selectorText === selector)

      if (index !== -1) {
        this.styleSheets[sheetIndex].deleteRule(index)
      }
    },

    clearStyles (wrapper) {
      wrapper.classList.add('delete-action')

      const selectors = [...wrapper.querySelectorAll('[data-sid]')].map(el => {
        return el.getAttribute('data-sid')
      })

      if (wrapper.getAttribute('data-sid')) {
        selectors.unshift(wrapper.getAttribute('data-sid'))
      }

      selectors.forEach(selector => {
        const usedStyles = [...document.querySelectorAll(`[data-sid="${selector}"]`)].filter(sel => !sel.closest('.delete-action')).length

        if (!usedStyles) {
          this.multiSidSelectors(selector)
            .forEach((sheet, index) => {
              sheet.forEach(selector => this.deleteSid(selector, index))
            })
        }
      })
    },

    unlinkSid (subject = this.section) {
      const newSid = subject.global ? `global--${shortid.generate()}` : `${this.page.css_hash}--${shortid.generate()}`

      this.multiSidIndexOf(subject.sid).forEach((sheet, sheetIndex) => {
        sheet.forEach(index => {
          this.styleSheets[sheetIndex].insertRule(this.styleSheets[sheetIndex].cssRules[index].cssText.replace(subject.sid, newSid), this.styleSheets[sheetIndex].cssRules.length)
        })
      })

      this.$set(subject, 'sid', newSid)
      this.$set(subject, '_uid', newSid)
      this.linked = false
    }
  },
  mounted () {
    window.addEventListener('contentChanged', this.checkLinked)
  },
  beforeDestroy () {
    window.removeEventListener('contentChanged', this.checkLinked)
  }
}