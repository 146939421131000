import _ from 'lodash'
import {applyPageStyles, pageStylesToString} from '@/utils/useStylesheet'

const state = () => ({
  undo: [],
  redo: [],
  disable: false,
  old: null
})

const getters = {}

const actions = {
  loadUndo ({rootGetters, commit, state}) {
    if (!state.undo.length) return
    commit('SET_DISABLE', true)
    commit('ADD_REDO', {
      content: _.cloneDeep(rootGetters['sections/getSections']),
      css: pageStylesToString()
    })
    commit('editor/SET_PAGE', 'sections', {root: true})
    commit('sections/SET_SECTIONS', _.last(state.undo).content, {root: true})
    applyPageStyles(_.last(state.undo).css)
    state.undo.splice(state.undo.length - 1, 1)
    setTimeout(() => {
      state.old = {
        section: _.cloneDeep(rootGetters['sections/getSections']),
        css: pageStylesToString()
      }
      commit('SET_DISABLE', false)
    }, 100)
  },
  loadRedo ({rootGetters, commit, state}) {
    if (!state.redo.length) return
    commit('SET_DISABLE', true)
    commit('ADD_UNDO', _.clone(rootGetters['sections/getSections']))
    commit('sections/SET_SECTIONS', [], {root: true})
    commit('editor/SET_PAGE', 'sections', {root: true})
    commit('sections/SET_SECTIONS', _.last(state.redo).content, {root: true})
    applyPageStyles(_.last(state.redo).css)
    state.old = {
      section: _.cloneDeep(rootGetters['sections/getSections']),
      css: pageStylesToString()
    }
    state.redo.splice(state.redo.length - 1, 1)
    setTimeout(() => {
      commit('SET_DISABLE', false)
    }, 100)
  },

  loadRedoBlog ({rootState, commit, state}) {
    if (!state.redo.length) return
    commit('SET_DISABLE', true)
    commit('ADD_UNDO', {
      content: rootState.blogEditor.post.sections.sections,
      css: pageStylesToString('subject-styles')
    })
    commit('blogEditor/SET_SECTIONS', [], {root: true})
    commit('blogEditor/SET_SECTIONS', _.last(state.redo).content, {root: true})
    applyPageStyles(_.last(state.redo).css, 'subject-styles')
    state.old = {
      content: JSON.parse(JSON.stringify(_.last(state.redo).content)),
      css: pageStylesToString('subject-styles')
    }
    state.redo.splice(state.redo.length - 1, 1)
    setTimeout(() => {
      commit('SET_DISABLE', false)
    })
  },

  loadUndoBlog ({rootState, commit, state}) {
    if (!state.undo.length) return
    commit('SET_DISABLE', true)
    commit('ADD_REDO', {
      content: rootState.blogEditor.post.sections.sections,
      css: pageStylesToString('subject-styles')
    })
    commit('blogEditor/SET_SECTIONS', [], {root: true})
    commit('blogEditor/SET_SECTIONS', _.last(state.undo).content, {root: true})
    applyPageStyles(_.last(state.undo).css, 'subject-styles')
    state.old = {
      css: pageStylesToString('subject-styles'),
      content: JSON.parse(JSON.stringify(_.last(state.undo).content))
    }
    state.undo.splice(state.undo.length - 1, 1)
    setTimeout(() => {
      commit('SET_DISABLE', false)
    })
  }
}

const mutations = {
  ADD_UNDO (state, payload) {
    if (!payload.content) return

    if (state.undo.length === 20) {
      state.undo.splice(0, 1)
    }

    state.undo.push({
      content: JSON.parse(JSON.stringify(payload.content)),
      css: payload.css
    })

    // localStorage.setItem('local-page', {
    //   content: JSON.parse(JSON.stringify(payload.content)),
    //   css: payload.css
    // })
  },
  ADD_REDO (state, payload) {
    if (!payload.content) return

    if (state.redo.length === 20) {
      state.redo.splice(0, 1)
    }
    state.redo.push({
      content: JSON.parse(JSON.stringify(payload.content)),
      css: payload.css
    })
  },
  SET_UNDO (state, payload) {
    state.undo = payload
  },
  SET_REDO (state, payload) {
    state.redo = payload
  },
  CLEAR (state, payload) {
    state.undo = []
    state.redo = []
    state.old = JSON.parse(JSON.stringify(payload))
  },
  CLEAR_HARD (state) {
    state.undo = []
    state.redo = []
    state.old = []
    state.disable = true
  },
  SET_DISABLE (state, payload) {
    state.disable = payload
  },
  SET_OLD (state, payload) {
    state.old = payload
  }
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
