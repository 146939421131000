<template>
  <div>
    <b-form-group label="Motion Type">
      <v-select
        v-model="selectedMotionTriggerMutation"
        :options="filteredOptions"
        :searchable="false"
        :reduce="val => val.value"
        placeholder="Select motion type"
        :disabled="!!value"
      />
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'
import {setup} from '@/components/editor/components/motions/motions-effects/motions-effects-list'
export default {
  name: 'MotionTypes',
  props: {
    value: {
      required: true
    },
    motion: {
      type: Object,
      required: true
    }
  },
  computed: {
    selectedMotionTriggerMutation: {
      get () {
        return this.value
      },
      set (val) {
        if (!this.motion[val]) {
          this.$set(this.motion, val, {
            lg: _.cloneDeep(setup[val]),
            md: _.cloneDeep(setup[val]),
            sm: _.cloneDeep(setup[val])
          })
        }

        this.$emit('input', val)
      }
    },
    filteredOptions () {
      return [
        {
          label: 'Mouse Move',
          value: 'mouseMove'
        },
        {
          label: 'Scroll',
          value: 'scroll'
        },
        {
          label: 'On Page Load',
          value: 'static'
        },
        {
          label: 'Viewport',
          value: 'viewport'
        }
        // {
        //   label: 'Click (comming soon)',
        //   value: 'mouseMove'
        // }
      ]
    }
  }
}
</script>
