<template>
  <LinkedControlItem
    v-model="val"
    :label="label"
    :min="min"
    @input="val => $emit('change', val)"
  />
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

export default {
  name: 'SidInput',
  components: {LinkedControlItem},
  mixins: [SidConcept],
  props: {
    label: {
      type: String
    },
    defaultDimension: {
      type: String,
      default: 'px'
    },
    min: {
      default: -100
    }
  },
  data () {
    return {
      d: null
    }
  },
  computed: {
    val: {
      get () {
        return parseInt(this.d !== null ? this.d : this.sidValueMutation)
      },
      set (val) {
        this.d = isNaN(val) ? 0 : val
        this.addSidRules([[this.getSid(), [this.ruleName, `${this.d}${this.defaultDimension}`]]])
      }
    }
  },
  watch: {
    sid () {
      this.d = this.sidValue || this.getSidStyle()
    }
  }
}
</script>