<template>
  <div
    :style="{...backgroundColor, ...shadow, ...border, ...radius, ...fullPaddingSubject(section.options.customize.style, 'padding', [20,20,20,20]), ...fullMarginSubject(section.options.customize.style)}"
  >
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin, BorderMixin],
  computed: {
    border () {
      const border = this.section.options.content.list.border || [0, 0, 0, 0]
      return {
        borderColor: this.section.options.content.list.borderColor,
        borderStyle: 'solid',
        borderTopWidth: `${border[0]}px`,
        borderRightWidth: `${border[1]}px`,
        borderBottomWidth: `${border[2]}px`,
        borderLeftWidth: `${border[3]}px`
      }
    },
    radius () {
      const radius = this.section.options.content.list.radius || [5, 5, 5, 5]

      return {
        borderTopLeftRadius: `${radius[0]}px`,
        borderTopRightRadius: `${radius[1]}px`,
        borderBottomLeftRadius: `${radius[2]}px`,
        borderBottomRightRadius: `${radius[3]}px`
      }
    },
    shadow () {
      const shadow = this.section.options.content.list.shadowOptions
      const color = this.section.options.content.list.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    }
  },
  created () {
    if (!this.section.options.content.list.hasOwnProperty('fontSize')) {
      this.$set(this.section.options.content.list, 'fontSize', '16px')
    }
    if (!this.section.options.content.list.hasOwnProperty('fontWeight')) {
      this.$set(this.section.options.content.list, 'fontWeight', '400')
    }
    if (!this.section.options.content.list.hasOwnProperty('font')) {
      this.$set(this.section.options.content.list, 'font', null)
    }
  }
}
</script>
