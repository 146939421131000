<template>
  <TimeItemV2
    :section="section"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @flow="handleFlow"
  />
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import TimeItemV2 from '@/components/builder/sections/time/time-v2/TimeItemV2'

export default {
  name: 'FeaturedItems1',

  components: {TimeItemV2},

  mixins: [SectionMixin],

  data () {
    return {
      visible: true
    }
  },

  computed: {
    timeZone () {
      return this.section.options.content.time.timeZone
    }
  },

  watch: {
    timeZone () {
      this.visible = false
      setTimeout(() => this.visible = true, 100)
    }
  }
}
</script>

<style lang="scss">
.el-timer-wrapper {
  font-size: 30px;
  gap: 20px;
}

.el-timer-label {
  font-size: 16px;
}
</style>