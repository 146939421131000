<template>
  <div class="pt-[25px]">
    <b-form-group>
      <image-upload
        :image="config"
        class="w-100"
        disable-settigns
      />
    </b-form-group>

    <b-form-group label="Alt image text">
      <b-input v-model="config.alt" />
    </b-form-group>

    <b-form-group label="Link">
      <b-input placeholder="URL" />
    </b-form-group>

    <image-translate :config="config" />

    <b-form-group
      label="Alignment"
    >
      <b-btn-group>
        <b-btn
          variant="white"
          @click="align = 'start'"
        >
          <i
            :class="{'text-primary': align === 'start'}"
            class="fa fa-outdent"
          />
        </b-btn>
        <b-btn
          variant="white"
          @click="align = 'center'"
        >
          <i
            :class="{'text-primary': align === 'center'}"
            class="fa fa-align-justify"
          />
        </b-btn>
        <b-btn
          variant="white"
          @click="align = 'end'"
        >
          <i
            :class="{'text-primary': align === 'end'}"
            class="fa fa-indent"
          />
        </b-btn>
      </b-btn-group>
    </b-form-group>

    <image-flip :config="config" />

    <b-form-group>
      <template slot="label">
        Shape ({{ resolutioner }})
      </template>

      <v-select
        v-model="shape"
        :options="masks"
        :searchable="false"
        label="name"
        placeholder="Select Shape"
      />
    </b-form-group>

    <b-form-group label="Сlick Action">
      <v-select
        v-model="config.action"
        :options="['No Action', 'Link', 'Modal']"
        class="custom-select"
        left
        placeholder="Select action"
      />
    </b-form-group>

    <action-modal
      v-if="config.action === 'Modal'"
      :service="config.modal"
      class="px-0"
    />

    <action-redirect
      v-else-if="config.action === 'Link'"
      :config="config"
    />

    <SidbearSettingsGroup
      class="border-top"
      title="Border & Radius"
    >
      ...
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <shadow-customs :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Image Effects"
    >
      <image-filters :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <height-customs :config="config" />
      <margin-top-customs :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Animations"
    >
      <animations :animations="config.animations" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import _ from 'lodash'
import ModMixin from '../../mixins/ModMixin'
import Animations from '../controls/Animations'
import ImageFilters from '../components/ImageFilters'
import ImageFlip from '@/components/editor/controls/image-mod/ImageFlip'
import ImageTranslate from '@/components/editor/controls/image-mod/ImageTranslate'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'

export default {
  name: 'ImageModV2',
  components: {
    SidbearSettingsGroup,
    ImageTranslate, ImageFlip, Animations, ImageFilters
  },
  mixins: [ModMixin],
  data () {
    return {
      masks: [
        {code: null, name: 'No Shape'},
        {code: 'circle', name: 'Circle'},
        {code: 'rounded', name: 'Rounded'},
        {code: 'bubble', name: 'Bubble'}
      ]
    }
  },
  computed: {
    align: {
      get () {
        return _.get(this.config, ['align', this.resolutioner], 'center')
      },
      set (val) {
        this.$set(this.config, 'align', {
          ..._.get(this.config, 'align', {}),
          [this.resolutioner]: val
        })
      }
    },
    offsetTop: {
      get () {
        return this.config.offsetTop[this.resolutioner]
      },
      set (val) {
        this.config.offsetTop[this.resolutioner] = val
      }
    },
    shape: {
      get () {
        return this.config.shape[this.resolutioner]
      },
      set (val) {
        this.config.shape[this.resolutioner] = val
      }
    },
    shapeCode () {
      return _.get(this.config.shape, [this.resolutioner, 'code'], null)
    }
  }
}
</script>
