<template>
  <label
    :class="[{'cursor-pinter': !module.is_coming_soon}, {'!pb-[70px]': activated}]"
    class="noselect mb-0 text-center w-[358px] px-[38px] !py-[46px] border border-[#E2E5EC] rounded-[6px] shadow-[0_2px_6px_rgba(242,244,245,1)]  mb-[45px]"
  >
    <div class="flex flex-column h-100">
      <img
        :src="icon"
        alt="Module icon"
        class="mx-auto h-[133px] mb-[45px]"
      >
      <h5
        class="text-[21px] text-dark font-bold mb-[18px]"
        v-text="module.title"
      />
      <p
        class="text-[16px] leading-[26px] text-[#6E747A] mb-[29px]"
        v-text="module.description"
      />


      <div
        v-if="!module.is_coming_soon"
        class="flex items-center justify-center h-[29px] flex-wrap"
      >
        <div class="flex items-center cursor-pointer mx-auto mb-[22px]">
          <div class="text-[#000] font-[400] mr-[13px] text-[16px]">
            {{ activated ? 'Activated' : 'Activate Module' }}
          </div>

          <div class="relative">
            <!-- input -->
            <input
              v-model="activated"
              class="sr-only"
              type="checkbox"
            >
            <!-- line -->
            <div class="dot-bg block bg-[#E9ECF0] w-[59px] h-[29px] rounded-full" />
            <!-- dot -->
            <div
              class="dot flex items-center justify-center absolute left-1 top-1/2 transform -translate-y-1/2 bg-white w-6 h-6 rounded-full transition"
            >
              <svg
                class="icon hidden"
                fill="none"
                height="10"
                viewBox="0 0 12 10"
                width="12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M10.6731 1.50115C10.8733 1.70134 10.8733 2.02592 10.6731 2.22611L4.52155 8.37763C4.32135 8.57782 3.99677 8.57782 3.79658 8.37763L0.720824 5.30187C0.52063 5.10168 0.52063 4.7771 0.720824 4.57691C0.921017 4.37672 1.24559 4.37672 1.44579 4.57691L4.15906 7.29018L9.9481 1.50115C10.1483 1.30096 10.4729 1.30096 10.6731 1.50115Z"
                  fill="#3D82EA"
                  fill-rule="evenodd"
                  stroke="#3D82EA"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>


        </div>

        <div class="w-full mx-auto flex justify-content-center mb-[40px]">
          <button
            v-if="activated"
            class="flex hover:opacity-80 items-center bg-[#3D82EA] py-0 rounded-[3px] min-w-[213px] justify-content-center text-nowrap h-[38px] text-[15px] font-[700] text-[#FFFFFF] gap-[12px]"
            @click="() => $emit('access', module)"
          >
            <span>
              Access {{ module.title }}
            </span>
            
            <svg
              width="9"
              height="13"
              viewBox="0 0 9 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 6.28571L2.02661 -2.82961e-07L0.5 1.4824L5.44673 6.28571L0.5 11.089L2.02661 12.5714L8.5 6.28571Z"
                fill="white"
              />
            </svg>
          </button>
          <button
            v-else
            class="flex items-center bg-[#E9ECF0] py-0 rounded-[3px] min-w-[213px] justify-content-center text-nowrap h-[38px] text-[15px] font-[700] text-[#C3C9D0] gap-[12px]"
          >
            <span>
              Access {{ module.title }}
            </span>
            <svg
              width="8"
              height="13"
              viewBox="0 0 8 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 6.28571L1.52661 -2.82961e-07L-2.09172e-07 1.4824L4.94673 6.28571L-6.29091e-07 11.089L1.52661 12.5714L8 6.28571Z"
                fill="#C3C9D0"
              />
            </svg>
          </button>
        </div>
      </div>

      <p
        v-else
        class="mb-0 text-[16px] text-[#BBC0C3] font-[500] mt-auto h-[29px] flex items-center w-full justify-center"
      >
        Coming Soon</p>
    </div>
  </label>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ModuleCard',
  props: {
    module: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    activated: {
      get () {
        return this.module.user_status ? this.module.user_status.is_visible : false
      },
      set (val) {
        axios.put('api/modules/status', {
          module_id: this.module.id,
          is_visible: val,
          project_id: this.project.id
        })

        this.$set(this.module, 'user_status', {
          is_visible: val
        })

        this.$bvToast.toast(`The ${this.module.title} module has been ${val ? 'enabled' : 'disabled'}`, {
          title: 'Module settings',
          autoHideDelay: 2000,
          appendToast: false,
          variant: 'white',
          solid: true
        })
      }
    }
  }
}
</script>