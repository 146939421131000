<template>
  <div>
    <motion-triggers
      :motion="subjectMotions"
    />

    <motion-scroll-bounding
      :motion="subjectMotions"
    />

    <motion-progress
      v-model="progress"
      label="Progress"
    />

    <motion-effects-list
      v-model="subjectMotions.scroll[resolutioner]"
      :options="scrollEffectOptions"
      :progress="progress"
    />

    <motion-smooth
      :subject-motions="subjectMotions"
    />
  </div>
</template>

<script>
import MotionTriggers from '@/components/editor/components/motions/MotionTriggers'
import MotionProgress from '@/components/editor/components/motions/MotionProgress'
import MotionEffectsList from '@/components/editor/components/motions/MotionEffectsList'
import {scrollEffectOptions} from '@/components/editor/components/motions/motions-effects/motions-effects-list'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import MotionScrollBounding from '@/components/editor/components/motions/MotionScrollBounding'
import MotionSmooth from '@/components/editor/components/motions/MotionSmooth'
export default {
  name: 'ScrollMotion',
  components: {MotionSmooth, MotionScrollBounding, MotionEffectsList, MotionProgress, MotionTriggers},
  mixins: [ResolutionMixin],
  props: {
    subjectMotions: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      progress: 0,
      scrollEffectOptions
    }
  }
}
</script>

<style scoped>

</style>