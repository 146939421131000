<template>
  <highliter
    v-model="section.name"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings(true)"
  >
    <VideoItems2Template :section="section">
      <b-col cols="12">
        <component
          :is="section.options.content.group.items[0].component"
          :section="section.options.content.group.items[0]"
        />
      </b-col>
    </VideoItems2Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditable from '@builder/components/builder/utils/ContentEditable'
import VideoItems2Template from '@builder/templates/sections/videos/VideoItems2Template'

export default {
  name: 'VideoItem2',

  components: {VideoItems2Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  created () {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>
