<template>
  <div>
    <draggable
      :list="form.controls"
      handle=".move"
    >
      <div
        v-for="(control, index) in form.controls"
        :key="index"
      >
        <form-controls-list-item
          :control="control"
          :form_id="form.id"
          @deleted="form.controls.splice(index, 1)"
          @open-settings="active === index ? active = null : active = index"
        />
        <form-control-field-settings
          v-if="active === index"
          :control="control"
          :form="form"
          :config="config"
        />
      </div>
    </draggable>

    <add-new-control-in-list
      :form_id="form.id"
      :items-count="form.controls.length"
      @added-item="item => form.controls.push(item)"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import AddNewControlInList from './AddNewControlInList'
import FormControlFieldSettings from './FormControlFieldSettings'
import FormControlsListItem from './FormControlsListItem'

export default {
  name: 'FormControlsList',
  components: {FormControlsListItem, FormControlFieldSettings, AddNewControlInList, draggable},
  props: {
    form: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      controls: [
        {
          name: 'Email',
          type: 'email'
        },
        {
          name: 'Full Name',
          text: 'email'
        }
      ],
      active: null
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control-list-item {
  font-size: 16px;
}

.move {
  cursor: ns-resize;
}
</style>
