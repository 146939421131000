<template>
  <div class="position-relative h-100 custom-menu overflow-hidden">
    <div
      class="flex items-center justify-between h-[43px] bg-gradient-to-b from-white to-[#F1F3F4] px-[18px] border-b border-[#E2E5EC]"
    >
      <span class="text-[#44474A] font-[700] text-[15px] mb-0">
        {{ name }}
      </span>
    </div>
    <b-link
      class="flex items-center !text-[#949697] hover:no-underline hover:bg-gray-50 text-[14px] h-[53px] px-[20px] border-b border-[#E2E5EC]"
      @click="$store.commit('editor/SET_PAGE', 'blocks')"
    >
      <icon
        class="mr-[11px]"
        icon="left-arrow.svg"
      />
      Go Back
    </b-link>

    <div class="flex flex-column relative max-h-full h-[calc(100vh-209px)]">
      <div class="overflow-auto">
        <div class="blocks-menu animate__animated animate__fadeInLeft py-2 pl-2">
          <component
            :is="isClickEvent ? 'div' : 'draggable'"
            v-model="group"
            :clone="cloneBlock"
            :group="{ name: 'blocks', pull: 'clone', put: false }"
            :sort="false"
            :scroll="true"
            :force-fallback="true"
            :fallback-on-body="true"
            fallback-class="block-sortable-fallback"
          >
            <div
              v-for="(block, index) in group"
              :key="index"
              :class="{'cursor-pinter': isClickEvent}"
              class="block-card d-flex align-items-center justify-content-center animate__animated animate__zoomIn animate__fast"
              @click="() => isClickEvent ? onClick(block) : null"
            >
              <div class="label">
                {{ block.name }}
              </div>
              <b-img
                v-if="block.previews"
                :src="block.previews.main"
                fluid
              />
              <template
                v-else
              >
                <i
                  v-if="userRole.includes('support') || userRole.includes('superadministrator')"
                  class="fa fa-trash absolute top-0 hover:text-red-500 right-0 bg-white !cursor-pointer p-2 z-[1000]"
                  @click="deleteBlock(block.id)"
                />
                <iframe
                  width="1200"
                  height="600"
                  class="block-card-iframe"
                  :src="block.iframeURL || block.prev_url"
                />
              </template>
            </div>
          </component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {mapGetters, mapState} from 'vuex'
import shortid from 'shortid'
import _ from 'lodash'

export default {
  name: 'BlocksMenu',

  components: {draggable},

  data () {
    return {
      group: [],
      name: 'Blocks'
    }
  },

  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),
    isClickEvent () {
      return this.addSubjectEvent === 'click'
    },
    ...mapState('editor', {
      currentSubject: state => state.currentSubject,
      addSubjectEvent: state => state.addSubjectEvent,
      globalStyles: state => state.globalStyles
    })
  },

  created () {
    VEvent.listen('blocks-menu', ({group, name}) => {
      this.group = []
      this.name = name
      setTimeout(() => this.group = group, 100)
    })
  },

  destroyed () {
    this.closeMenu()
  },

  methods: {
    deleteBlock (id) {
      this.$store.dispatch('confirmationDialog/confirmation', {
        title: 'Are you sure?'
      })
        .then(() => {
          axios.delete(`api/projects/block-management/${id}`)
            .then(() => {
              this.$swal({
                icon: 'success',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: 'Deleted.',
                showConfirmButton: false,
                timer: 3000
              })

              this.$store.commit('editor/SET_PAGE', 'sections')
            })
        })
    },
    onClick (block) {
      // eslint-disable-next-line no-unreachable
      if (!block.ignoreGroup && this.globalStyles.hasOwnProperty(block.group.toLowerCase())) {
        this.$store.commit('editor/SET_PAGE', 'sections')
        return this.$set(this.globalStyles[block.group.toLowerCase()], 'block', this.cloneBlock(block))
      }

      const _block = this.cloneBlock(block)

      if (_block.beforeBlock) {
        const insertGlobalBlock = () => {
          this.$set(this.globalStyles[_block.beforeBlock.group.toLowerCase()], 'block', null)

          setTimeout(() => {
            this.$set(this.globalStyles[_block.beforeBlock.group.toLowerCase()], 'block', JSON.parse(JSON.stringify(_block.beforeBlock)))
            delete _block.beforeBlock
          })
        }

        if (!_.isEmpty(_.get(this.globalStyles, [_block.beforeBlock.group.toLowerCase(), 'block']))) {
          this.$store.dispatch('confirmationDialog/confirmation', {
            title: `The ${_block.beforeBlock.group} block already exists.`,
            description: `Are you sure you want to replace the current ${_block.beforeBlock.group.toLowerCase()}?`
          })
            .then(() => insertGlobalBlock())
        } else {
          insertGlobalBlock()
        }
      }

      this.$store.commit('sections/ADD_SECTION_AFTER', {
        index: this.currentSubject.options.index,
        section: _block
      })

      this.$store.commit('editor/SET_PAGE', {
        name: 'add-row',
        event: 'click',
        subject: {
          subject: _block,
          options: {subject: this, originKey: 'custom'}
        }
      })
    },
    openMenu () {
      document.body.classList.add('block-menu')
    },
    closeMenu () {
      document.body.classList.remove('block-menu')
    },
    cloneBlock (block) {
      return JSON.parse(JSON.stringify({
        ...(typeof block.section === 'object' ? block.section : new block.section().json()),
        ...(block.beforeBlock && {beforeBlock: block.beforeBlock}),
        render_id: shortid.generate()
      }).replace(/"id":\d+,?/g, '')
        .replace(/"form_id":\d+,?/g, '')
        .replace(/,\}/g, '}'))
    }
  }
}
</script>

<style lang="scss">
@import "@builder/assets/scss/_variables.scss";

.block-menu .es-root {
  margin-left: $editor-toolbar-width - 40;
}

.blocks-menu {
  z-index: 2;
  background: #fff;

  .block-card {
    position: relative;
    width: 251px;
    height: 152px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: move;
    transition: .4s;

    .label {
      transition: .4s;
      opacity: 0 !important;
    }

    &:hover {
      box-shadow: 0 0 0 1px #82CEC9;

      .label {
        opacity: 1 !important;
      }
    }
  }
}

.blocks-menu,
.es-root {
  .block-card {
    overflow: hidden;

    .label {
      opacity: 0 !important;
      position: absolute;
      top: 0;
      left: 0;
      background: #82CEC9;
      opacity: 0.87;
      border-bottom-right-radius: 2px;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
      padding: 6px 12px 6px 9px;
      font-size: 10px;
      letter-spacing: 1px;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
    }
  }
}

.block-card-iframe {
  //border: none;
  //margin-left: 0.3225rem;
  //padding-top: 1.875rem;
  //pointer-events: none;
  //transform: scale(0.18) translateY(-50%);
  //transform-origin: 0 0;
  //top: 50%;
  //position: absolute;
  //left: -5px;
  //border-radius: 30px;

  transform: scale(0.21);
  transform-origin: top left;
  height: 731px;
  left: 0;
  top: 0;
  width: 1200px;
  overflow: hidden;
  position: absolute;
}
</style>
