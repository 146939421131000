<template>
  <div class="login-page d-flex flex-column justify-content-center">
    <div class="body">
      <div class="card-wrapper mx-auto text-center">
        <b-link
          class="navbar-brand"
          :to="{name: 'dashboard'}"
          aria-label="FourPercent"
        >
          <img
            src="@builder/assets/svg/estage-logo-dark.svg"
            alt="Estage | FourPercent Logo"
          >
        </b-link>

        <div class="card text-left">
          <div class="card-body">
            <div
              v-if="error"
              class="custom-aler-danger d-flex align-items-center mb-4"
            >
              <i class="fa fa-exclamation-triangle mr-3" />
              <div>
                The email or password is incorrect
              </div>
            </div>

            <b-form
              v-if="!message"
              class="js-validate"
              @submit.prevent="signIn"
            >
              <h3 class="font-family-base-3 font-weight-medium mb-1 mb-4">
                Create new password
              </h3>

              <div class="form-group">
                <label class="font-family-base-2 font-size-lg">Your Email</label>
                <input
                  v-model="$v.form.email.$model"
                  type="email"
                  class="form-control"
                  :class="{'is-invalid': $v.form.email.$error}"
                  placeholder="Email"
                  autocomplete="off"
                  style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAkCAYAAADo6zjiAAAAAXNSR0IArs4c6QAAAbNJREFUWAntV8FqwkAQnaymUkpChB7tKSfxWCie/Yb+gbdeCqGf0YsQ+hU95QNyDoWCF/HkqdeiIaEUqyZ1ArvodrOHxanQOiCzO28y781skKwFW3scPV1/febP69XqarNeNTB2KGs07U3Ttt/Ozp3bh/u7V7muheQf6ftLUWyYDB5yz1ijuPAub2QRDDunJsdGkAO55KYYjl0OUu1VXOzQZ64Tr+IiPXedGI79bQHdbheCIAD0dUY6gV6vB67rAvo6IxVgWVbFy71KBKkAFaEc2xPQarXA931ot9tyHphiPwpJgSbfe54Hw+EQHMfZ/msVEEURjMfjCjbFeG2dFxPo9/sVOSYzxmAwGIjnTDFRQLMQAjQ5pJAQkCQJ5HlekeERxHEsiE0xUUCzEO9AmqYQhiF0Oh2Yz+ewWCzEY6aYKKBZCAGYs1wuYTabKdNNMWWxnaA4gp3Yry5JBZRlWTXDvaozUgGTyQSyLAP0dbb3DtQlmcan0yngT2ekE9ARc+z4AvC7nauh9iouhpcGamJeX8XF8MaClwaeROWRA7nk+tUnyzGvZrKg0/40gdME/t8EvgG0/NOS6v9NHQAAAABJRU5ErkJggg==&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%; cursor: auto;"
                >
                <template v-if="$v.form.email.$error">
                  <div
                    v-if="!$v.form.email.required"
                    class="text-danger"
                  >
                    Email address field is required.
                  </div>
                  <div
                    v-if="!$v.form.email.email"
                    class="text-danger"
                  >
                    Enter valid email address.
                  </div>
                </template>
              </div>
              <div class="form-group">
                <label class="d-flex justify-content-between align-items-center font-family-base-2 font-size-lg">
                  Password
                </label>
                <input
                  v-model="$v.form.password.$model"
                  type="password"
                  class="form-control"
                  placeholder="*******"
                  autocomplete="off"
                  style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAkCAYAAADo6zjiAAAAAXNSR0IArs4c6QAAAbNJREFUWAntV8FqwkAQnaymUkpChB7tKSfxWCie/Yb+gbdeCqGf0YsQ+hU95QNyDoWCF/HkqdeiIaEUqyZ1ArvodrOHxanQOiCzO28y781skKwFW3scPV1/febP69XqarNeNTB2KGs07U3Ttt/Ozp3bh/u7V7muheQf6ftLUWyYDB5yz1ijuPAub2QRDDunJsdGkAO55KYYjl0OUu1VXOzQZ64Tr+IiPXedGI79bQHdbheCIAD0dUY6gV6vB67rAvo6IxVgWVbFy71KBKkAFaEc2xPQarXA931ot9tyHphiPwpJgSbfe54Hw+EQHMfZ/msVEEURjMfjCjbFeG2dFxPo9/sVOSYzxmAwGIjnTDFRQLMQAjQ5pJAQkCQJ5HlekeERxHEsiE0xUUCzEO9AmqYQhiF0Oh2Yz+ewWCzEY6aYKKBZCAGYs1wuYTabKdNNMWWxnaA4gp3Yry5JBZRlWTXDvaozUgGTyQSyLAP0dbb3DtQlmcan0yngT2ekE9ARc+z4AvC7nauh9iouhpcGamJeX8XF8MaClwaeROWRA7nk+tUnyzGvZrKg0/40gdME/t8EvgG0/NOS6v9NHQAAAABJRU5ErkJggg==&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%; cursor: auto;"
                >
                <template v-if="$v.form.password.$error">
                  <div
                    v-if="!$v.form.password.required"
                    class="text-danger"
                  >
                    Password field is required.
                  </div>
                  <div
                    v-if="!$v.form.password.minLength"
                    class="text-danger"
                  >
                    Password must have at least {{ $v.form.password.$params.minLength.min }} letters.
                  </div>
                </template>
              </div>
              <div class="form-group">
                <label class="d-flex justify-content-between align-items-center font-family-base-2 font-size-lg">
                  Password Confirmation
                </label>
                <input
                  v-model="$v.form.password_confirmation.$model"
                  type="password"
                  class="form-control"
                  placeholder="*******"
                  autocomplete="off"
                  style="background-image: url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAkCAYAAADo6zjiAAAAAXNSR0IArs4c6QAAAbNJREFUWAntV8FqwkAQnaymUkpChB7tKSfxWCie/Yb+gbdeCqGf0YsQ+hU95QNyDoWCF/HkqdeiIaEUqyZ1ArvodrOHxanQOiCzO28y781skKwFW3scPV1/febP69XqarNeNTB2KGs07U3Ttt/Ozp3bh/u7V7muheQf6ftLUWyYDB5yz1ijuPAub2QRDDunJsdGkAO55KYYjl0OUu1VXOzQZ64Tr+IiPXedGI79bQHdbheCIAD0dUY6gV6vB67rAvo6IxVgWVbFy71KBKkAFaEc2xPQarXA931ot9tyHphiPwpJgSbfe54Hw+EQHMfZ/msVEEURjMfjCjbFeG2dFxPo9/sVOSYzxmAwGIjnTDFRQLMQAjQ5pJAQkCQJ5HlekeERxHEsiE0xUUCzEO9AmqYQhiF0Oh2Yz+ewWCzEY6aYKKBZCAGYs1wuYTabKdNNMWWxnaA4gp3Yry5JBZRlWTXDvaozUgGTyQSyLAP0dbb3DtQlmcan0yngT2ekE9ARc+z4AvC7nauh9iouhpcGamJeX8XF8MaClwaeROWRA7nk+tUnyzGvZrKg0/40gdME/t8EvgG0/NOS6v9NHQAAAABJRU5ErkJggg==&quot;); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%; cursor: auto;"
                >
                <template v-if="$v.form.password_confirmation.$error">
                  <div
                    v-if="!$v.form.password_confirmation.required"
                    class="text-danger"
                  >
                    Password field is required.
                  </div>
                  <div
                    v-if="$v.form.password_confirmation.required && !$v.form.password_confirmation.sameAsPassword"
                    class="text-danger"
                  >
                    Password must be identical.
                  </div>
                </template>
              </div>
              <b-btn
                type="submit"
                variant="primary"
                class="d-flex align-items-center justify-content-center btn btn-primary btn-fill btn-block"
                :disabled="loading"
              >
                <span class="mr-2">Reset</span>
                <b-spinner
                  v-if="loading"
                  small
                />
              </b-btn>
            </b-form>

            <div
              v-else
              class="text-center"
            >
              <svg
                class="mb-5"
                width="226"
                height="226"
                viewBox="0 0 226 226"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M61 113L100 152L165 74"
                  stroke="#598AEB"
                  stroke-width="12"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="4.5"
                  y="4.5"
                  width="217"
                  height="217"
                  rx="108.5"
                  stroke="#598AEB"
                  stroke-width="9"
                />
              </svg>

              <h5
                class="mb-0"
                v-text="message"
              />

              <div class="pt-5">
                <b-link
                  to="/login"
                  class="js-change-block link-muted"
                  href="javascript:;"
                >
                  Login
                  <svg
                    style="transform: rotateY(180deg)"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <line
                      x1="19"
                      y1="12"
                      x2="5"
                      y2="12"
                    />
                    <polyline points="12 19 5 12 12 5" />
                  </svg>
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {required, email, minLength, sameAs} from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  data () {
    return {
      error: false,
      loading: false,
      message: null,
      form: {
        email: '',
        password: '',
        password_confirmation: ''
      }
    }
  },

  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(7)
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },

  methods: {
    signIn () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.loading = true

        this.$store.dispatch('auth/reset', this.form)
          .then(({data}) => {
            this.message = data.data
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&Oxygen:wght@400;700&family=Roboto:wght@300;400;700;900&display=swap');

.login-page {
  .link-muted.text-underdashed {
    border-color: #6E747A;
  }

  .text-underdashed {
    border-bottom: dashed 1px #545C65;
  }

  .link-muted {
    color: #6E747A !important;

    &:hover {
      text-decoration: none;
    }
  }

  .link-muted.text-underdashed:hover {
    border-color: #3D82EA;
  }

  .link-muted:hover {
    color: #3D82EA !important;
  }

  min-height: 100%;

  color: #545C65;

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #000;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .font-family-base-2 {
    font-family: "Roboto", sans-serif;
  }

  .font-size-lg {
    font-size: 1.05469rem;
  }

  .btn-fill {
    position: relative;
    overflow: hidden;
    box-shadow: none !important;
  }

  .btn {
    font-size: 20px;
    font-weight: 700;
    padding: 0.70312rem 0.9375rem;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
  }

  .btn-fill:hover::before {
    width: 100%;
  }

  .btn-fill.btn-primary::before {
    background: #2E73D7;
  }

  .btn-fill::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0;
    height: 100%;
    transition: all .3s ease;
    border-radius: 3px;
  }

  .btn-fill span {
    position: relative;
  }

  .btn-primary:not(label.btn), .btn-primary:not(label.btn):not([href]):not(:disabled):not(.disabled), .btn-primary:not([href]), .btn-primary:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #3D82EA !important;
    border-color: #3D82EA !important;
    opacity: 1 !important;
  }

  .font-weight-medium {
    font-weight: 500 !important;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.6em + 1.53125rem);
    padding: 0.70312rem 0.9375rem;
    font-size: 0.9375rem !important;
    font-weight: 400;
    font-family: "Roboto";
    line-height: 1.6;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.0625rem solid #AEB1B4;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.login-page .body {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #FDFDFD;
}

@media (min-width: 992px) {
  .login-page .body {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.login-page .navbar-brand {
  margin-bottom: 21px;
}

@media (min-width: 992px) {
  .login-page .navbar-brand {
    margin-bottom: 41px;
  }
}

.login-page h3 {
  font-size: 28px;
}

.login-page .card-wrapper {
  max-width: 615px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.login-page .card {
  box-shadow: 0px 4px 104px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 36px;
}

@media (min-width: 992px) {
  .login-page .card {
    margin-bottom: 66px;
  }
}

.login-page .card-body {
  padding: 36px;
}

@media (min-width: 992px) {
  .login-page .card-body {
    padding: 56px 70px 70px;
  }
}

.login-page [type="password"] {
  letter-spacing: 7px;
}

.font-family-base-3 {
  font-family: "Montserrat", sans-serif;
}
</style>
