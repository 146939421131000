<template>
  <div>
    <div class="ml-[6px]">
      <h5 class="font-semibold text-[25px] leading-[30px] text-black mb-[10px]">
        Marketing Integrations
      </h5>
      <p class="text-[16px] leading-[26px] text-[#6E747A] mb-[65px]">
        Take your marketing to the next level by connecting Google Tag Manager and/or adding Facebook tracking pixels to your project!
      </p>
 
      <div
        v-if="integrationsLoading"
        class="w-full h-[250px] flex items-center justify-center "
      >
        <b-spinner
          class="!w-12 !h-12"
          variant="primary"
        />
      </div>

      <div v-else>
        <integration-item
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          :class="{'mb-[16px]':index + 1 != items.length }"
          @submit="val => handleSubmit(val,item)"
          @toggle-status="val => handleToggleStatus(val,item)"
          @remove="val => handleDelete(val,item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash'
// import {mapState} from 'vuex'
import IntegrationItem from '@/views/dashboard/integration/components/IntegrationItem'
import IntegrationsMixin from '@/mixins/IntegrationsMixin'
export default {
  components: {IntegrationItem},
  mixins: [IntegrationsMixin],
  data () {
    return {
      items: [
        {
          title: 'Google Tag Manager',
          active: false,
          image: require('@/assets/images/integrations/google-tag.png'),
          icon: require('@/assets/images/integrations/google-tag-icon.png'),
          alpha_code: 'GoogleTagManager',
          data: [
            {
              label: 'Container ID',
              value: '',
              type: 'text',
              key:'container_id',
              dirty:false,
              validation: (val) => {
                return {
                  valid: val.indexOf('GTM-') === -1 ? false : true,
                  message: 'Invalid Google Tag'
                }
              }
            }
          ]
        },
        {
          title: 'Facebook Pixel',
          active: false,
          image: require('@/assets/images/integrations/fbpixel.svg'),
          icon: require('@/assets/images/integrations/fbpixel-icon.svg'),
          img_styles:'height:40px;',
          alpha_code: 'FacebookPixel',
          data: [
            {
              label: 'Facebook Pixel Code',
              value: '',
              type: 'textarea',
              key:'code',
              dirty:false,
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Facebook Pixel Code'
                }
              }
            }
          ]
        },
        {
          title: 'Ads.txt',
          active: false,
          image: require('@/assets/images/integrations/txt-extension.svg'),
          image_text: 'Ads.txt',
          icon: require('@/assets/images/integrations/txt-extension.svg'),
          img_styles:'height:40px;',
          alpha_code: 'AdsTxt',
          full_title:'Add your ads.txt content',
          desc:'Paste the publisher ID and associated data from your ads.txt file below. To add multiple entries use a new line for each unique publisher ID record.',
          submit_text:'Save My Data',
          data: [
            {
              label: 'Ads.txt content',
              value: '',
              type: 'textarea',
              key:'code',
              dirty:false,
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid ads.txt content'
                }
              }
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>