<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :uid="section.uid"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings('collapse')"
  >
    <CollapseItems1Template :section="section">
      <template #header="content">
        <editable
          v-model="content.content.title"
          class="d-block w-100"
        />
      </template>

      <template #body="content">
        <elements v-model="content.content.elements" />
      </template>
    </CollapseItems1Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import CollapseItems1Template from '@builder/templates/sections/collapse/CollapseItems1Template'
import Editable from '../../utils/Editable'
import Elements from '../../utils/Elements/Elements'

export default {
  name: 'CollapseItem1',

  components: {Elements, Editable, CollapseItems1Template, Highliter},

  mixins: [SectionMixin]
}
</script>
