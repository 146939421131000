<template>
  <settings-page-card
    v-if="form"
    :class="{'bottom-offset': preview && form.bannerPosition === 'bottom'}"
    class="cookie-settings mb-5"
    title="Cookie Consent"
  >
    <div class="px-4 pt-3">
      <div class="max-w-[952px]">
        <div class="mb-5">
          <div class="d-flex align-items-center mb-4">
            <div class="font-size-15 mt-2">
              Enable Cookie Consent
            </div>
            <b-checkbox
              v-model="form.enabled"
              class="ml-3 mb-2"
              size="lg"
              switch
              @change="save"
            />
          </div>

          <p
            v-if="!form.enabled"
            class="text-danger font-size-16"
          >
            <strong class="font-[500]">Cookie consent is disabled. </strong>
            <span class="text-dark">Enable it to make the banner available again for display on the user’s side.</span>
          </p>
        </div>


        <div v-if="form.enabled">
          <div class="content-tabs">
            <div class="content-tabs__tabs">
              <div
                v-for="tab in tabContents"
                :key="tab.key"
                class="content-tabs__tabs__label"
                :class="{'content-tabs__tabs__active': tabActive === tab.key}"
                @click="tabActive = tab.key"
                v-html="tab.label "
              />
            </div>

            <div
              v-for="tab in tabContents"
              :key="tab.key"
              class="content-tabs__content"
              @click="tabActive = tab.key"
            >
              <content-editor
                v-show="tabActive === tab.key"
                :ref="tab.key"
                v-model="form[tab.form]"
                :value="form[tab.form]"
              />
            </div>
          </div>

          <div class="mb-4 mt-1 text-right">
            <div
              class="btn-reset"
              @click="contentRestoreToDefault"
            >
              <img src="@/assets/icons/restore.svg">
              Restore to Default
            </div>
          </div>

          <design-and-position :form="form">
            <template slot="switcher">
              <div class="d-flex align-items-center">
                <div class="font-size-16">
                  Preview
                </div>
                <b-checkbox
                  v-model="preview"
                  class="ml-3 mt-0"
                  size="lg"
                  switch
                />
              </div>
            </template>
          </design-and-position>

          <b-btn
            size="lg"
            variant="primary"
            :disabled="loading"
            @click="save"
          >
            Save Settings

            <svg
              v-if="!loading"
              class="ml-3"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8404 2H3.75781C2.78855 2 2 2.78855 2 3.75781V15.2422C2 16.2114 2.78855 17 3.75781 17H15.2422C16.2114 17 17 16.2114 17 15.2422V4.15965L14.8404 2ZM5.51562 3.17188H12.2539V6.62891H5.51562V3.17188ZM13.4844 15.8281H5.51562V11.1992H13.4844V15.8281ZM15.8281 15.2422C15.8281 15.5653 15.5653 15.8281 15.2422 15.8281H14.6562V10.0273H4.34375V15.8281H3.75781C3.43473 15.8281 3.17188 15.5653 3.17188 15.2422V3.75781C3.17188 3.43473 3.43473 3.17188 3.75781 3.17188H4.34375V7.80078H13.4258V3.17188H14.355L15.8281 4.64504V15.2422Z"
                fill="white"
              />
              <path
                d="M11.1992 3.72852H10.0273V6.04297H11.1992V3.72852Z"
                fill="white"
              />
            </svg>
            <b-spinner
              v-else
              variant="white"
              small
              class="ml-3"
            />
          </b-btn>
        </div>

        <cookie-banner
          v-if="preview"
          :cookie-content="form"
        />
      </div>
    </div>
  </settings-page-card>
  <div
    v-else
    class="d-flex align-items-center justify-content-center min-vh-50"
  >
    <b-spinner
      variant="primary"
      large
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import SettingsPageCard from '../../layouts/dashboard/SettingsPageCard'
import ContentEditor from '../../components/builder/components/cookie-consent/Content'
import DesignAndPosition from '../../components/builder/components/cookie-consent/DesignAndPosition'
import CookieBanner from '../../components/editor/components/CookieBanner'
import _ from 'lodash'

const formDefault = {
  'enabled': false,
  'content': '<p><strong>Cookie Consent</strong></p><p>This website uses cookies to enable essential site functionality and to enhance your site experience.&nbsp;By continuing to use this website, you consent to the use of cookies in accordance with our&nbsp;<strong><em>Cookie Policy.</em></strong></p>',
  'contentNecessary': '<p><strong>Strictly necessary cookies</strong></p><p>The ones to provide the service, application, and other essential functionalities of our website.</p>',
  'contentFunctional': '<p><strong>Functional cookies</strong></p><p>The ones to provide enhanced performance for some website resources and services as well as sometimes to adopt a higher level of personalisation of the user experience. </p>',
  'contentPerformance': '<p><strong>Performance cookies</strong></p><p>The ones to provide quantitative measurement of our website and its resources used for purposes like troubleshooting and analytics.</p>',
  'contentTargeting': '<p><strong>Targeting cookies</strong></p><p>The ones to provide behavioral advertising and re-marketing of analytical data.</p>',
  'bannerType': 'bar',
  'bannerPosition': 'bottom',
  'popUpPosition': 'top-right',
  'bannerCtaAllowAllType': 'button',
  'bannerCtaAllowAllText': 'Allow All',
  'bannerCtaDisableAllType': 'button',
  'bannerCtaDisableAllText': 'Disable All',
  'bannerCtaCustomizeSettingsType': 'text',
  'bannerCtaCustomizeSettingsText': 'Customize Settings',
  'bannerCtaSettingsSaveType': 'button',
  'bannerCtaSettingsSaveText': 'Save Settings',
  'bannerStyle': 'dark',
  'colors': {
    'bannerBackground': '#393939',
    'bannerText': '#DDDDDD',
    'ctaBackgroundColor': '#FFFFFF',
    'ctaButtonText': '#393939',
    'ctaLinkText': '#393939'
  }
}

export default {
  name: 'CookieConsent',

  components: {CookieBanner, DesignAndPosition, SettingsPageCard, ContentEditor},

  data () {
    return {
      form: {},
      loading: false,
      preview: false,
      tabActive: 'content',

      tabContents: [
        { label: 'Consent', key: 'content', form: 'content' },
        { label: 'Content Necessary', key: 'contentNecessary', form: 'contentNecessary' },
        { label: 'Content Functional', key: 'contentFunctional', form: 'contentFunctional' },
        { label: 'Content Performance', key: 'contentPerformance', form: 'contentPerformance' },
        { label: 'Content Targeting', key: 'contentTargeting', form: 'contentTargeting' }
      ]
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('pages', {
      pages: state => state.pages
    })
  },

  created () {
    this.setForm()
  },

  methods: {
    save () {
      console.log(this.form)
      this.loading = true
      axios.put(`api/projects/${this.project.id}/cookie-content`, {
        cookie_content: JSON.stringify(this.form)
      })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Updated!',
            showConfirmButton: false,
            timer: 1500
          })
          this.loading = false
        })
    },

    setForm () {
      if (this.project.cookie_content) {
        this.form = JSON.parse(JSON.stringify(formDefault))

        _.each(JSON.parse(this.project.cookie_content), (val, key) => {
          this.form[key] = val
        })
      } else {
        this.form = JSON.parse(JSON.stringify(formDefault))
      }
    },

    contentRestoreToDefault () {
      if (this.$refs.content) {
        this.form.content = formDefault.content
        this.$refs.content[0].$refs.editor.$props.editor.setContent(formDefault.content)
      }

      if (this.$refs.contentNecessary) {
        this.form.contentNecessary = formDefault.contentNecessary
        this.$refs.contentNecessary[0].$refs.editor.$props.editor.setContent(formDefault.contentNecessary)
      }

      if (this.$refs.contentFunctional) {
        this.form.contentFunctional = formDefault.contentFunctional
        this.$refs.contentFunctional[0].$refs.editor.$props.editor.setContent(formDefault.contentFunctional)
      }

      if (this.$refs.contentPerformance) {
        this.form.contentPerformance = formDefault.contentPerformance
        this.$refs.contentPerformance[0].$refs.editor.$props.editor.setContent(formDefault.contentPerformance)
      }

      if (this.$refs.contentPerformance) {
        this.form.contentTargeting = formDefault.contentTargeting
        this.$refs.contentTargeting[0].$refs.editor.$props.editor.setContent(formDefault.contentTargeting)
      }
    }
  }
}
</script>

<style lang="scss">
  .cookie-settings {
    font-family: Inter, serif;

    .btn-reset {
      color: #6F7479;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;

      img {
        margin-right: 5px;
        margin-top: -2px;
      }
    }

    .vs--open {
      .vs__selected {
        margin-top: 7px !important;
      }
    }

    .label {
      font-weight: 500;
      font-size: 15px;
      color: #000000;
    }

    &.bottom-offset {
      margin-bottom: 300px !important;
    }

    .radio-group-border {
      .custom-control {
        display: initial;

        label {
          span {
            border-radius: 5px;
            border: 1px solid #E3E6E8;
            background: #FFF;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
            padding: 0 28px;
            align-items: center;
            display: flex;
            height: 85px;
            width: 108px;
            line-height: 18px;
            text-align: center;
          }

          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;

          &::before, &::after {
            margin-top: 30px;
          }
        }
      }
    }
  }
</style>
