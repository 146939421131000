<template>
  <div class="pt-[9px] sid-select">
    <SidbearSettingsGroup
      title="Card"
      active
    >
      <sid-range-slider
        rule-name="gap"
        label="Gap"
        :sid="`.--${section.sid}--container`"
        :dimensions="[]"
        default-dimension="px"
      />

      <sid-background :sid="`.--${section.sid}--card`" />

      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--card .padding-20`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--card`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--card`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--card`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              label="Radius"
              :sid="`.--${section.sid}--card`"
              rule-name="border"
              :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius',
                  cssVar: '--border-top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius',
                  cssVar: '--border-top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius',
                  cssVar: '--border-bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius',
                  cssVar: '--border-bottom-right-radius'
                }
              ]"
            />
          </b-form-group>

          <b-form-group label="Box Shadow">
            <sid-box-shadow
              :sid="`.--${section.sid}--card`"
              rule-name="box-shadow"
            />
          </b-form-group>
        </template>

        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--card`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--card`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              css-var="--border-size"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--card`"
                rule-name="border-color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              label="Radius"
              :sid="`.--${section.sid}--card`"
              rule-name="border"
              presudo=":hover"
              css-var="--border-radius"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius'
                }
              ]"
            />
          </b-form-group>

          <b-form-group label="Box Shadow">
            <sid-box-shadow
              :sid="`.--${section.sid}--card`"
              rule-name="box-shadow"
              presudo=":hover"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Title">
      <b-form-group label="Text Color">
        <sid-color
          :sid="`.--${section.sid}--title`"
          rule-name="color"
        />
      </b-form-group>
      
      <sid-range-slider
        label="Font Size"
        :sid="`.--${section.sid}--title`"
        rule-name="font-size"
      />

      <sid-range-slider
        label="Line-Height"
        :sid="`.--${section.sid}--title`"
        rule-name="line-height"
      />

      <sid-range-slider
        label="Letter-Spacing"
        :sid="`.--${section.sid}--title`"
        rule-name="letter-spacing"
      />

      <sid-font
        :sid="`.--${section.sid}--title`"
        rule-name="color"
      />

      <sid-align
        :sid="`.--${section.sid}--title`"
        rule-name="text-align"
        label="Text Alignment"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Date">
      <b-form-group label="Text Color">
        <sid-color
          :sid="`.--${section.sid}--date`"
          rule-name="color"
        />
      </b-form-group>

      <sid-range-slider
        label="Font Size"
        :sid="`.--${section.sid}--date`"
        rule-name="font-size"
      />

      <sid-range-slider
        label="Line-Height"
        :sid="`.--${section.sid}--date`"
        rule-name="line-height"
      />

      <sid-range-slider
        label="Letter-Spacing"
        :sid="`.--${section.sid}--date`"
        rule-name="letter-spacing"
      />

      <sid-font
        :sid="`.--${section.sid}--date`"
        rule-name="color"
      />

      <sid-align
        :sid="`.--${section.sid}--date`"
        rule-name="text-align"
        label="Text Alignment"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Description">
      <b-form-group label="Text Color">
        <sid-color
          :sid="`.--${section.sid}--description`"
          rule-name="color"
        />
      </b-form-group>

      <sid-range-slider
        label="Font Size"
        :sid="`.--${section.sid}--description`"
        rule-name="font-size"
      />

      <sid-range-slider
        label="Line-Height"
        :sid="`.--${section.sid}--description`"
        rule-name="line-height"
      />

      <sid-range-slider
        label="Letter-Spacing"
        :sid="`.--${section.sid}--description`"
        rule-name="letter-spacing"
      />


      <sid-font
        :sid="`.--${section.sid}--description`"
        rule-name="color"
      />

      <sid-align
        :sid="`.--${section.sid}--description`"
        rule-name="text-align"
        label="Text Alignment"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Pagination">
      <b-form-group label="Show posts per page">
        <v-select
          v-model="config.perPage"
          :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
          :searchable="false"
        />
      </b-form-group>

      <b-form-group label="Sort by category">
        <v-select
          v-model="byCategory"
          :options="[{name: 'Not selected', id: false}, ...categories]"
          :reduce="val => val.id"
          label="name"
        />
      </b-form-group>
    </SidbearSettingsGroup>
    <!--    <template v-if="section.options.content.button.subject" />-->
  </div>
</template>

<script>
import {mapState} from 'vuex'
import _ from 'lodash'
import ModMixin from '../../mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'

export default {
  name: 'BlogTextMod',
  components: {
    SidRangeSlider,
    SidAlign,
    SidFont, SidBoxShadow, RuleTabs, SidColor, SidSelect, SidLinkedGroup, SidBackground, SidbearSettingsGroup},
  mixins: [ModMixin],

  computed: {
    ...mapState('blogCategories', {
      categories: state => state.categories
    }),
    byCategory: {
      get () {
        return _.get(this.config, 'byCategory', false)
      },
      set (val) {
        this.$set(this.config, 'byCategory', val)
      }
    }
  },

  created () {
    this.$store.dispatch('blogCategories/laodCategories')
  }
}
</script>
