<template>
  <b-container class="d-flex">
    <b-card
      class="price-card border-0 p-3"
      :style="{...backgroundColor, ...color, ...shadow}"
      :class="theme"
    >
      <slot />
    </b-card>
  </b-container>
</template>

<script>
import TemplateMixin from '../../../../mixins/TemplateMixin'
import ThemeMixin from '../../../../mixins/ThemeMixin'

export default {
  name: 'Carousel1Template',
  mixins: [TemplateMixin, ThemeMixin]
}
</script>

<style lang="scss">
.price-card {
  border-radius: 9px;

  .btn {
    width: 100%;
    font-size: 16px;
    border-width: 1px !important;
  }

  .list-item {
    font-size: 14px;

    .list-icon {
      width: 20px;
      height: 20px;
      background: #1d1d1d;
    }
  }

  &.dark,
  &.dark .editor__content,
  &.dark .pub__content,
  &.dark .list-item {
    color: #fff;
  }

  &.light,
  &.light .editor__content,
  &.light .pub__content,
  &.light .list-item {
    color: #2d2d2d;
  }
}
</style>
