<template>
  <div>
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin],
  mounted () {
    if (this.section.options.content.button.buttonType !== 'submit') return
    this.$store.dispatch('forms/addAvaliableButton', this.section)
  },
  destroyed () {
    this.section.options.content.button.buttonType === 'submit'
    this.$store.dispatch('forms/removeAvaliableButton', this.section)
  }
}
</script>
