<template>
  <div>
    <module-header />
    <b-container
      v-if="draftSettings"
      class="mb-5"
      fluid
    >
      <h1 class="text-3xl font-bold mb-12">
        Blog Settings
      </h1>

      <b-row>
        <b-col
          class="mb-5"
          md="8"
        >
          <!-- Select Your Layout -->
          <div class="custom-card-header">
            <div class="text-xl">
              Select Your Layout
            </div>
          </div>
          <div class="custom-card-body">
            <blog-layouts :settings="draftSettings" />
          </div>
          <!-- End Select Your Layout -->
        </b-col>
        <b-col
          class="mb-5"
          md="2"
        >
          <div class="d-flex">
            <div class="mr-2">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 0C3.36433 0 0 3.36433 0 7.5C0 11.6357 3.36433 15 7.5 15C11.6357 15 15 11.6357 15 7.5C15 3.36433 11.6357 0 7.5 0ZM7.5 11.875C7.15496 11.875 6.87504 11.595 6.87504 11.25C6.87504 10.905 7.15496 10.625 7.5 10.625C7.84504 10.625 8.12496 10.905 8.12496 11.25C8.12496 11.595 7.84504 11.875 7.5 11.875ZM8.48934 7.90123C8.26813 8.00308 8.12496 8.22624 8.12496 8.46943V8.75004C8.12496 9.09496 7.84561 9.375 7.5 9.375C7.15439 9.375 6.87504 9.09496 6.87504 8.75004V8.46943C6.87504 7.73998 7.30373 7.0713 7.96566 6.76563C8.60252 6.47255 9.06246 5.69435 9.06246 5.31246C9.06246 4.45129 8.36185 3.75 7.5 3.75C6.63815 3.75 5.93754 4.45129 5.93754 5.31246C5.93754 5.6575 5.65807 5.93754 5.31246 5.93754C4.96685 5.93754 4.6875 5.6575 4.6875 5.31246C4.6875 3.7619 5.94933 2.49996 7.5 2.49996C9.05067 2.49996 10.3125 3.7619 10.3125 5.31246C10.3125 6.15692 9.57996 7.39815 8.48934 7.90123Z"
                  fill="#AEB1B4"
                />
              </svg>
            </div>

            <p class="text-muted">
              Your blog layout can consist of the three layouts to your left. You can choose if you’d like to have a right-hand sidebar. Your Blog style will follow that which you have set in your global style settings (color, typography etc.)
            </p>
          </div>
        </b-col>
        <b-col
          class="mb-5"
          md="8"
        >
          <!-- Select Your Layout -->
          <div class="custom-card-header">
            <div class="text-xl">
              Hero Banner
            </div>
          </div>
          <div class="custom-card-body py-0 px-0">
            <blog-hero-settings :settings="draftSettings" />
          </div>
          <!-- End Select Your Layout -->
        </b-col>
        <b-col
          class="mb-5"
          md="2"
        >
          <div class="d-flex">
            <div class="mr-2">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 0C3.36433 0 0 3.36433 0 7.5C0 11.6357 3.36433 15 7.5 15C11.6357 15 15 11.6357 15 7.5C15 3.36433 11.6357 0 7.5 0ZM7.5 11.875C7.15496 11.875 6.87504 11.595 6.87504 11.25C6.87504 10.905 7.15496 10.625 7.5 10.625C7.84504 10.625 8.12496 10.905 8.12496 11.25C8.12496 11.595 7.84504 11.875 7.5 11.875ZM8.48934 7.90123C8.26813 8.00308 8.12496 8.22624 8.12496 8.46943V8.75004C8.12496 9.09496 7.84561 9.375 7.5 9.375C7.15439 9.375 6.87504 9.09496 6.87504 8.75004V8.46943C6.87504 7.73998 7.30373 7.0713 7.96566 6.76563C8.60252 6.47255 9.06246 5.69435 9.06246 5.31246C9.06246 4.45129 8.36185 3.75 7.5 3.75C6.63815 3.75 5.93754 4.45129 5.93754 5.31246C5.93754 5.6575 5.65807 5.93754 5.31246 5.93754C4.96685 5.93754 4.6875 5.6575 4.6875 5.31246C4.6875 3.7619 5.94933 2.49996 7.5 2.49996C9.05067 2.49996 10.3125 3.7619 10.3125 5.31246C10.3125 6.15692 9.57996 7.39815 8.48934 7.90123Z"
                  fill="#AEB1B4"
                />
              </svg>
            </div>

            <p class="text-muted">
              Here you can adjust your hero banner which will appear on your blog home page.
            </p>
          </div>
        </b-col>
        <b-col
          class="mb-5"
          md="8"
        >
          <!-- Select Your Layout -->
          <div class="custom-card-header">
            <div class="text-xl">
              Widget Settings
            </div>
          </div>
          <div class="custom-card-body">
            <blog-social-settings :settings="draftSettings" />
          </div>
          <!-- End Select Your Layout -->
        </b-col>
        <b-col
          class="mb-5"
          md="8"
        >
          <!-- Select Your Layout -->
          <div class="custom-card-header">
            <div class="text-xl">
              Advertisement Banner
            </div>
          </div>
          <div class="custom-card-body">
            <blog-banner-settings :settings="draftSettings" />
          </div>
          <!-- End Select Your Layout -->
        </b-col>
        <b-col
          class="mb-5"
          md="2"
        >
          <div class="d-flex">
            <div class="mr-2">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 0C3.36433 0 0 3.36433 0 7.5C0 11.6357 3.36433 15 7.5 15C11.6357 15 15 11.6357 15 7.5C15 3.36433 11.6357 0 7.5 0ZM7.5 11.875C7.15496 11.875 6.87504 11.595 6.87504 11.25C6.87504 10.905 7.15496 10.625 7.5 10.625C7.84504 10.625 8.12496 10.905 8.12496 11.25C8.12496 11.595 7.84504 11.875 7.5 11.875ZM8.48934 7.90123C8.26813 8.00308 8.12496 8.22624 8.12496 8.46943V8.75004C8.12496 9.09496 7.84561 9.375 7.5 9.375C7.15439 9.375 6.87504 9.09496 6.87504 8.75004V8.46943C6.87504 7.73998 7.30373 7.0713 7.96566 6.76563C8.60252 6.47255 9.06246 5.69435 9.06246 5.31246C9.06246 4.45129 8.36185 3.75 7.5 3.75C6.63815 3.75 5.93754 4.45129 5.93754 5.31246C5.93754 5.6575 5.65807 5.93754 5.31246 5.93754C4.96685 5.93754 4.6875 5.6575 4.6875 5.31246C4.6875 3.7619 5.94933 2.49996 7.5 2.49996C9.05067 2.49996 10.3125 3.7619 10.3125 5.31246C10.3125 6.15692 9.57996 7.39815 8.48934 7.90123Z"
                  fill="#AEB1B4"
                />
              </svg>
            </div>

            <p class="text-muted">
              Depending on the theme/layout you decide, will determine what size banner you can use.
            </p>
          </div>
        </b-col>
        <b-col
          class="mb-5"
          md="8"
        >
          <!-- Categories -->
          <div class="custom-card-header">
            <div class="text-xl">
              Blog Categories
            </div>
          </div>
          <div class="custom-card-body">
            <blog-categories-settings />
          </div>
          <!-- End Categories -->
        </b-col>
        <b-col
          class="mb-5"
          md="8"
        >
          <!-- Select Your Layout -->
          <div class="custom-card-header">
            <div class="text-xl">
              Blog Post Author
            </div>
          </div>
          <div class="custom-card-body">
            <blog-author-settings />
          </div>
          <!-- End Select Your Layout -->
        </b-col>
      </b-row>
  
      <b-btn
        :disabled="loading"
        variant="primary"
        class="font-size-16 font-weight-bold btn-with-icon rounded-sm btn-custom-lg"
        size="lg"
        @click="save"
      >
        Save Settings

        <b-spinner
          v-if="loading"
          small
          class="ml-2"
        />
        <svg
          v-else
          class="ml-2"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8404 0H1.75781C0.788555 0 0 0.788555 0 1.75781V13.2422C0 14.2114 0.788555 15 1.75781 15H13.2422C14.2114 15 15 14.2114 15 13.2422V2.15965L12.8404 0ZM3.51562 1.17188H10.2539V4.62891H3.51562V1.17188ZM11.4844 13.8281H3.51562V9.19922H11.4844V13.8281ZM13.8281 13.2422C13.8281 13.5653 13.5653 13.8281 13.2422 13.8281H12.6562V8.02734H2.34375V13.8281H1.75781C1.43473 13.8281 1.17188 13.5653 1.17188 13.2422V1.75781C1.17188 1.43473 1.43473 1.17188 1.75781 1.17188H2.34375V5.80078H11.4258V1.17188H12.355L13.8281 2.64504V13.2422Z"
            fill="white"
          />
        </svg>
      </b-btn>
    </b-container>
  </div>
</template>

<script>
import BlogLayouts from '../../components/builder/components/blog/BlogLayouts'
import ModuleHeader from '../../components/editor/header/ModuleHeader'
import BlogHeroSettings from '../../components/builder/components/blog/BlogHeroSettings'
import BlogSocialSettings from '../../components/builder/components/blog/BlogSocialSettings'
import BlogBannerSettings from '../../components/builder/components/blog/BlogBannerSettings'
import BlogAuthorSettings from '../../components/builder/components/blog/BlogAuthorSettings'
import {mapState} from 'vuex'
import BlogCategoriesSettings from '../../components/builder/components/blog/BlogCategoriesSettings'
export default {
  name: 'BlogSettings',
  components: {
    BlogCategoriesSettings,
    BlogAuthorSettings, BlogBannerSettings, BlogSocialSettings, BlogHeroSettings, ModuleHeader, BlogLayouts},

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState('blogSettings', {
      draftSettings: state => state.draftSettings
    })
  },

  created () {
    this.$store.dispatch('blogSettings/loadSettings')
  },

  methods: {
    save () {
      this.loading = true
      this.$store.dispatch('blogSettings/store')
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.custom-card-header {
  background: #FAFCFF;
  border: 1px solid #E2E5EC;
  font-size: 20px;
  padding: 18px 21px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.custom-card-body {
  background: #FFFFFF;
  border: 1px solid #E2E5EC;
  box-sizing: border-box;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  padding: 50px 60px;
}
</style>
