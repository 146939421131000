<template>
  <div>
    <div
      v-if="loaded"
      class="d-flex align-items-center p-[2px]"
    >
      <img
        v-if="customs.image.src"
        :src="customs.image.src"
        :style="{height: imageSize}"
      >
      <div
        v-else-if="customs.text"
        :style="styles"
      >
        {{ customs.text }}
      </div>
      <div
        v-else
        class="logo-placeholder"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ComponentProvider from '@builder/components/mixins/ComponentProvider'
import ResolutionMixin from '../../mixins/ResolutionMixin'

export default {
  name: 'EsLogo',

  mixins: [ComponentProvider, ResolutionMixin],

  computed: {
    fontSize () {
      return _.get(this.customs, ['textLogoSize', this.resolutioner], _.get(this.customs, 'fontSize', '24px'))
    },
    imageSize () {
      return _.get(this.customs, ['logoSize', this.resolutioner], _.get(this.customs, 'image.logoSize', '24px'))
    },
    loaded () {
      return this.customs.hasOwnProperty('image')
    },
    styles () {
      return {
        fontWeight: this.customs.fontWeight,
        fontSize: this.fontSize,
        fontFamily: this.customs.fontFamily === 'Global Style' ? this.globalStyles.font.style : this.customs.fontFamily,
        color: this.customs.color
      }
    }
  },

  created () {
    if (!this.customs.hasOwnProperty('image')) {
      this.$set(this.customs, 'image', {
        src: null
      })
    }
  }
}
</script>

<style lang="scss">
.logo-placeholder {
  position: relative;
  height: 40px;
  width: 120px;
  border: 1px dashed #bfbfbf;

  &:after {
    content: 'LOGO';
    position: absolute;
    top: 50%;
    left: 50%;
    color: #bfbfbf;
    transform: translate(-50%, -50%);
  }
}
</style>
