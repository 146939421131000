import {mapGetters, mapState} from 'vuex'
import _ from 'lodash'

export default {
  props: {
    service: {
      required: true,
      type: Object
    },
    config: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      listOptions: [],
      tagOptions: [],
      optionsLoading:false,
      APIFieldsByList: [],
      APIFields: null
    }
  },

  methods: {
    async fetchAPIFieldsByList () {
      return await axios.get(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.service.name)}/custom-fields?listId=${this.config.form.api_list_or_tag[this.service.name]}`)
        .then(({data}) => {
          this.APIFieldsByList = data.data
        })
    },
    async integrateMailService () {
      VEvent.fire('loader', true)

      await this.$store.dispatch('apiIntegration/integrateMailService', {
        ...this.service,
        auto_responder_id: this.service.id
      })
        .then(({data}) => {
          this.service.status = data.data.status
          this.mailAPIServices.forEach(el => {
            if(el.id == this.service.id){
              el.client_secret = this.service.client_secret
              el.client_id = this.service.client_id
              el.status = this.service.status
            }
          })
          this.prepare()
          VEvent.fire('autoresponder-integrated', {
            ...this.service
          })
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    },

    async deleteMailService () {
      VEvent.fire('loader', true)
      const data = {
        ...this.service,
        auto_responder_id: this.service.id
      }
      await this.$store.dispatch('apiIntegration/deleteMailService', data)
        .then(() => {
          this.service.status = false
          this.$emit('remove-integration')
          this.$delete(this.config.form.api_list_or_tag, this.service.name)
          this.$emit('deleteResponder', this.service)
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    },

    async fetchAPIFields () {
      await axios.get(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.service.name)}/custom-fields`)
        .then(({data}) => {
          this.APIFields = data.data
        })
    },

    async fetchList () {
      await axios.get(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.service.name)}/lists?per_page=100`)
        .then(({data}) => {
          this.listOptions = data.data
        })
    },

    async fetchTags () {
      await axios.get(`api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.service.name)}/tags`)
        .then(({data}) => {
          this.tagOptions = data.data
        })
    },
    async prepare () {
      this.optionsLoading = true
      try {
        if (this.service.has_list) {
          await this.fetchList()

          if (this.config.form.api_list_or_tag[this.service.name]) {
            await this.fetchAPIFieldsByList()
          }
        } else {
          if (this.service.has_tag) {
            await this.fetchTags()
          }

          await this.fetchAPIFields()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.optionsLoading = false
      }
    }
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    ...mapGetters('apiIntegration', {
      hasAPI: 'hasMailAPIService'
    }),
    ...mapState('apiIntegration', {
      mailAPIServices: state => state.mailAPIServices
    }),
    ...mapState('projects', {
      project: state => state.project
    }),

    fieldsForMapping () {
      return this.config.controls
    },

    list_id: {
      get () {
        if (this.config.form.api_list_or_tag[this.service.name]) this.fetchAPIFieldsByList()
        return this.config.form.api_list_or_tag[this.service.name] || null
      },
      set (val) {
        this.$set(this.config.form.api_list_or_tag, this.service.name, val)
        this.prepare()
      }
    },
    tags_id: {
      get () {
        let val = this.config.form.api_list_or_tag[this.service.name]

        if (val && val.length) {
          val = val.filter(val => val)
        }

        return val || null
      },
      set (val) {
        this.$set(this.config.form.api_list_or_tag, this.service.name, val)
      }
    },
    mailAPIService () {
      return _.find(this.mailAPIServices, ['name', this.service.name])
    }
  },

  created () {
    if (this.mailAPIService.status !== 'ACTIVE') return
    this.prepare()
  }
}
