import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Navbar'
export const group = 'navbar'
export const defaultOptions = {
  'customize': {
    'style': {
      'navbarScroller': {
        'type': 'navbar-scroller-customs',
        'scrollType': null
      }
    },
    'display': true
  },
  'content': {
    'logo': {
      'type': 'logo-mod',
      'display': true,
      'fontFamily': null,
      'fontWeight': 'bold',
      'fontSize': '24px',
      'color': '#0C226C',
      'text': 'AppStarter'
    },
    'menuItems': {
      'type': 'menu-items-mod-v2',
      'display': true,
      'bringToFront': {
        'lg': false
      }
    },
    'subject': {
      'uid': 'WKtPEOYr2',
      'sid': 'global--gvznrcLzKR',
      'name': 'Flex Wrapper',
      'hideFromSections': false,
      'component': 'ElFlexWrapperV2',
      'group': 'navbar',
      'layout': null,
      'specialCols': {
        'navigation': {
          'special': true,
          'sid': 'ljmvyd1y--J_Sf22zPt5',
          'placement': 'bottom',
          'hamburgerIcon_open': 'fa fa-angle-down',
          'hamburgerIcon_close': 'fa fa-times',
          'hamburgerIcon_color': '#656565',
          'showOn': 'lg',
          'name': 'Nav Column',
          'elements': [
            {
              'uid': 'KHd5_z26R',
              'display': true,
              'name': 'Menu Links',
              'sid': 'global--CYG4k_8nL2',
              'hideFromSections': false,
              'component': 'ElMenuLinksV4',
              'group': 'Navigation',
              'ignoreGroup': false,
              'layout': null,
              'options': {
                'content': {
                  'menu': {
                    'display': true,
                    'type': 'menu-links-mod-v4',
                    'links': [
                      {
                        'id': 'YDWIZ7uCh_',
                        'href': '#',
                        'text': 'About',
                        'customLink': false,
                        'blank': false,
                        'itemType': '',
                        'children': []
                      },
                      {
                        'id': 'cBLaT6RW5Z',
                        'href': '#',
                        'text': 'Work with Me',
                        'customLink': false,
                        'blank': false,
                        'itemType': '',
                        'children': []
                      },
                      {
                        'id': 'k7uidn4JZm',
                        'href': '#',
                        'text': 'Resources',
                        'customLink': false,
                        'blank': false,
                        'itemType': '',
                        'children': []
                      },
                      {
                        'id': 'rMaFPTTI6r',
                        'href': '#',
                        'text': 'Blog',
                        'customLink': false,
                        'blank': false,
                        'itemType': '',
                        'children': []
                      },
                      {
                        'id': '0dmaLDV_cZ',
                        'href': '#',
                        'text': 'Contact',
                        'customLink': false,
                        'blank': false,
                        'children': []
                      }
                    ]
                  }
                }
              },
              'editor': 'project'
            }
          ],
          'highlight': false,
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          },
          'sortIndex': {
            'lg': 1,
            'md': 1,
            'sm': 1
          },
          'uid': '_PUnhqPWl',
          'navbarBackground': {
            'background': '',
            'gradient': {
              'position': '',
              'color': ''
            }
          },
          '_uid': 'e6DVhHPti8'
        }
      },
      'options': {
        'content': {
          'display': true,
          'cols': [
            {
              'display': true,
              'name': 'Flex Column',
              'elements': [
                {
                  'uid': 'RJgHutYSB',
                  'display': true,
                  'name': 'Subheadline',
                  'sid': 'global--NroGKLqaJc',
                  'hideFromSections': false,
                  'component': 'ElSubheadlineV2',
                  'group': 'Text',
                  'ignoreGroup': false,
                  'layout': null,
                  'options': {
                    'content': {
                      'description': {
                        'type': 'tiptap-mod-v2',
                        'display': true,
                        'tag': 'h4',
                        'resolutionStyle': {
                          'sm': {},
                          'md': {},
                          'lg': {}
                        },
                        'text': '<p>Sitelogo</p>',
                        'animations': {
                          'animation': null,
                          'once': true,
                          'offset': 200,
                          'delay': 50
                        }
                      }
                    },
                    'customize': null
                  },
                  'editor': 'project'
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              },
              'sortIndex': {
                'lg': 0,
                'md': 0,
                'sm': 0
              },
              'sid': 'ljmvyd1y--dJaKluRGVk',
              'uid': 'LJkq3dxtuU',
              'highlight': false,
              '_uid': 'OWXS_EAAg'
            },
            {
              'special': true,
              'key': 'navigation',
              'mod': 'navigation-flex-col-mod-v2',
              'name': 'Nav Column',
              'sid': 'Uz96GLEcGy',
              'sortIndex': {
                'lg': 1,
                'md': 1,
                'sm': 1
              }
            }
          ],
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          }
        }
      },
      'temp_id': 'd3G2s-XNq'
    }
  }
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('NavbarV3', options)

    this.name = name
    this.group = group
    this.sid = ['global']
    this.sids = [
      'global',
      'global--gvznrcLzKR',
      'ljmvyd1y--dJaKluRGVk',
      'global--NroGKLqaJc',
      'ljmvyd1y--J_Sf22zPt5',
      'global--CYG4k_8nL2'
    ]
    this.css = [
      [
        '.es-navbar-bg {background-color: rgb(0, 0, 0); }',
        '.--global--9D4r12Vc4-text { padding: 20px; }',
        '.--global--9D4r12Vc4-text div { color: rgb(0, 0, 0); font-family: Poppins; text-align: left; }',
        '.--ljmvyd1y--dJaKluRGVk--flex-col-padding { padding: 0px; }',
        '.--ljmvyd1y--J_Sf22zPt5--flex-col-padding { padding: 0px; }',
        '.--ljmvyd1y--dJaKluRGVk--flex-col-el { width: 20%; background-color: rgba(255, 255, 255, 0); }',
        '.--ljmvyd1y--J_Sf22zPt5--flex-col-el { width: 100%; }',
        '.--global--gvznrcLzKR--container { padding-right: 0px; }',
        '.--global--gvznrcLzKR--container { padding-right: 0px; }',
        '.--global--gvznrcLzKR--flex { max-width: 1230px; --width: 1230px; }',
        '.--global--gvznrcLzKR--flex { max-width: 1230px; --width: 1230px; }',
        '.--global--gvznrcLzKR--bg { background-color: rgba(0, 0, 0, 0); }',
        '.--global--gvznrcLzKR--bg { background-color: rgba(0, 0, 0, 0); }',
        '.--global--CYG4k_8nL2--wrapper { padding: 20px; justify-content: end; align-items: end; font-family: Poppins; font-weight: 300; gap: 16px; }',
        '.--global--CYG4k_8nL2--wrapper { padding: 20px; justify-content: end; align-items: end; font-family: Poppins; font-weight: 300; gap: 16px; }',
        '.--global--CYG4k_8nL2--link { font-size: 15px; color: rgb(255, 255, 255); letter-spacing: 1px; }',
        '.--global--CYG4k_8nL2--link { font-size: 15px; color: rgb(255, 255, 255); letter-spacing: 1px; }',
        '.--global--gvznrcLzKR--flex.flex-wrapper { justify-content: center; }',
        '.--global--gvznrcLzKR--flex.flex-wrapper { justify-content: center; }',
        '.--global--NroGKLqaJc-text { padding: 20px; }',
        '.--global--NroGKLqaJc-text { padding: 20px; }',
        '.--global--NroGKLqaJc-text div { color: rgb(255, 255, 255); text-align: left; }',
        '.--global--NroGKLqaJc-text div { color: rgb(255, 255, 255); text-align: left; }',
        '.--global--CYG4k_8nL2--link:hover { border-bottom-width: 2px; }',
        '.--global--CYG4k_8nL2--link:hover { border-bottom-width: 2px; }'
      ],
      [
        '.--global--gvznrcLzKR--open-icon { color: rgb(255, 255, 255); }',
        '.--global--gvznrcLzKR--open-icon { color: rgb(255, 255, 255); }',
        '.--global--gvznrcLzKR--close-icon { color: rgb(255, 255, 255); }',
        '.--global--gvznrcLzKR--close-icon { color: rgb(255, 255, 255); }',
        '.--global--gvznrcLzKR--icon { font-size: 22px; translate: -28px; }',
        '.--global--gvznrcLzKR--icon { font-size: 22px; translate: -28px; }',
        '.--global--gvznrcLzKR--flex.flex-wrapper { justify-content: start; padding-top: 10px; padding-bottom: 10px; }',
        '.--global--gvznrcLzKR--flex.flex-wrapper { justify-content: start; padding-top: 10px; padding-bottom: 10px; }',
        '.--global--NroGKLqaJc-text div { font-size: 22px; }',
        '.--global--NroGKLqaJc-text div { font-size: 22px; }',
        '.--global--CYG4k_8nL2--wrapper { justify-content: center; border-top-width: 1px; }',
        '.--global--CYG4k_8nL2--wrapper { justify-content: center; border-top-width: 1px; }'
      ],
      [
        '.--ljmvyd1y--dJaKluRGVk--flex-col-el { width: 64%; --width: 64%; }',
        '.--global--CYG4k_8nL2--wrapper { flex-direction: column; }',
        '.--global--CYG4k_8nL2--wrapper { flex-direction: column; }',
        '.--global--CYG4k_8nL2--link { font-size: 14px; }',
        '.--global--CYG4k_8nL2--link { font-size: 14px; }',
        '.--global--gvznrcLzKR--icon { font-size: 17px; translate: -20px 4px; }',
        '.--global--gvznrcLzKR--icon { font-size: 17px; translate: -20px 4px; }',
        '.--global--NroGKLqaJc-text div { font-size: 18px; }',
        '.--global--NroGKLqaJc-text div { font-size: 18px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
