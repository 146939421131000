<template>
  <div class="p-[10px] cursor-pointer hover:bg-gray-100 transition">
    {{ item.title }}
  </div>
</template>

<script>
export default {
  name: 'PageSelectMenuOtherItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>