<template>
  <div>
    <b-form-group
      v-for="control in controls"
      :key="control.key"
      :label="control.label"
    >
      <div class="flex items-center gap-[22px]">
        <vue-slider
          v-model="parts[control.key]"
          class="editor-slider-1 !w-[160px]"
          :dot-size="17"
          tooltip="none"
          :min="control.min"
          :max="control.max"
          :interval="control.step"
          @change="onChange"
        />

        <LinkedControlItem
          v-model="parts[control.key]"
          :min="control.min"
          :max="control.max"
          :step="control.step"
          class="d-block !w-[60px]"
          @input="onChange"
        />
      </div>
    </b-form-group>

    <div class="flex justify-end pt-2 pr-1">
      <span
        class="text-[13px] text-[#44474A] hover:opacity-80 cursor-pointer underline"
        @click="reset"
      >Reset Filters</span>
    </div>
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

export default {
  name: 'SidBackgroundFilters',
  components: {LinkedControlItem},
  mixins: [SidConcept],
  data () {
    return {
      controls: [
        {
          label: 'Blur',
          key: 'blur',
          min: 0,
          max: 10,
          step: 1,
          dimension: 'px'
        },
        {
          label: 'Grayscale',
          key: 'grayscale',
          min: 0,
          max: 1,
          step: 0.1,
          dimension: ''
        },
        {
          label: 'Invert',
          key: 'invert',
          min: 0,
          max: 1,
          step: 0.1,
          dimension: ''
        },
        {
          label: 'Sepia',
          key: 'sepia',
          min: 0,
          max: 1,
          step: 0.1,
          dimension: ''
        },
        {
          label: 'Contrast',
          key: 'contrast',
          min: 0,
          max: 2,
          step: 0.1,
          dimension: ''
        }
      ],
      parts: {
        blur: 0,
        grayscale: 0,
        invert: 0,
        sepia: 0,
        contrast: 1
      }
    }
  },
  created () {
    this.transformParts()
  },
  methods: {
    transformParts () {
      if (this.sidValueMutation === 'none') return

      this.sidValueMutation.split(' ').map(prop => {
        return {
          type: prop.replace(/\(([^)]+)\)/, ''),
          value: prop ? parseFloat(/\(([^)]+)\)/.exec(prop)[1]) : ''
        }
      }).forEach(prop => this.parts[prop.type] = prop.value)
    },
    onChange () {
      let filters = ''
      this.controls.forEach(control => {
        const value = this.parts[control.key]
        if (isNaN(value)) return
        filters += `${control.key}(${value}${control.dimension})`
      })

      this.addSidRules([[this.getSid(), [this.ruleName, filters]]])
    },
    reset () {
      this.transformParts = {
        blur: 0,
        grayscale: 0,
        invert: 0,
        sepia: 0,
        contrast: 1
      }

      this.addSidRules([[this.getSid(), [this.ruleName, '']]])
    }
  }
}
</script>