import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Internal Embed'
export const group = 'Others'

export const defaultOptions = {
  content: {
    code: {
      type: 'estage-internal-embed-mod',
      display: true,
      code: ''
    }
  },
  customize: {
    style: {
      shadow: null,
      background: null,
      padding: {
        type: 'height-customs',
        sm: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        },
        md: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        },
        lg: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        }
      }
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@builder/assets/svg/previews/time/main-1.png')
}

export const icon = `
<svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.0188 13.3043C4.86943 13.3043 4.72007 13.2504 4.60649 13.1417C4.37855 12.9252 4.37855 12.5741 4.60649 12.3576L6.72173 10.3481L4.60571 8.33785C4.37777 8.1213 4.37777 7.77025 4.60571 7.55371C4.83365 7.33716 5.20317 7.33716 5.43111 7.55371L7.95945 9.95565C8.18739 10.1722 8.18739 10.5233 7.95945 10.7398L5.43111 13.1417C5.31753 13.2504 5.16817 13.3043 5.0188 13.3043Z" fill="black"/>
<path d="M13.4893 13.3044H10.1632C9.85717 13.3044 9.60883 12.9732 9.60883 12.5652C9.60883 12.1572 9.85717 11.8261 10.1632 11.8261H13.4893C13.7953 11.8261 14.0436 12.1572 14.0436 12.5652C14.0436 12.9732 13.7953 13.3044 13.4893 13.3044Z" fill="black"/>
<path d="M16.361 17H2.1173C0.949321 17 0 16.0472 0 14.875V2.125C0 0.952773 0.949321 0 2.1173 0H16.361C17.5289 0 18.4783 0.952773 18.4783 2.125V14.875C18.4783 16.0472 17.5289 17 16.361 17ZM2.1173 1.15909C1.58682 1.15909 1.15489 1.59259 1.15489 2.125V14.875C1.15489 15.4074 1.58682 15.8409 2.1173 15.8409H16.361C16.8914 15.8409 17.3234 15.4074 17.3234 14.875V2.125C17.3234 1.59259 16.8914 1.15909 16.361 1.15909H2.1173Z" fill="black"/>
<path d="M17.9008 3.69567H0.577446C0.258696 3.69567 0 3.36454 0 2.95654C0 2.54854 0.258696 2.21741 0.577446 2.21741H17.9008C18.2196 2.21741 18.4783 2.54854 18.4783 2.95654C18.4783 3.36454 18.2196 3.69567 17.9008 3.69567Z" fill="black"/>
</svg>`

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElEstageInternalEmbed', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
