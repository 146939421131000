<template>
  <div>
    <div
      v-show="imgSrc"
      class="content"
    >
      <section class="cropper-area">
        <div class="img-cropper mb-3">
          <vue-cropper
            ref="cropper"
            :zoom-on-wheel="false"
            :auto-crop-area="1"
            :src="imgSrc"
            @crop="handleCropChange"
          />
        </div>

        <div class="mb-2">
          Size: {{ formatBytes(croppedSize) }}
        </div>

        <div class="d-flex align-items-center justify-content-between align-items-start w-100">
          <div class="btn-group">
            <b-btn
              variant="light"
              @click.prevent="aspect(1/1)"
            >
              1:1
            </b-btn>

            <b-btn
              variant="light"
              @click.prevent="aspect(2/3)"
            >
              2:3
            </b-btn>

            <b-btn
              variant="light"
              @click.prevent="aspect(3/4)"
            >
              3:4
            </b-btn>

            <b-btn
              variant="light"
              @click.prevent="aspect(4/3)"
            >
              4:3
            </b-btn>

            <b-btn
              variant="light"
              @click.prevent="aspect(16/9)"
            >
              16:9
            </b-btn>

            <b-btn
              ref="flipX"
              variant="light"
              @click.prevent="flipX"
            >
              <svg
                fill="none"
                height="14"
                viewBox="0 0 13 14"
                width="13"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                fill="#44474A"
                d="M7.20833 1.13709C7.20833 0.745888 6.8912 0.428757 6.5 0.428757C6.1088 0.428757 5.79167 0.745888 5.79167 1.13709L5.79167 12.4704C5.79167 12.8616 6.1088 13.1788 6.5 13.1788C6.8912 13.1788 7.20833 12.8616 7.20833 12.4704L7.20833 1.13709Z"
              /><path
                fill="#44474A"
                d="M4.375 6.80376C4.375 6.99162 4.30037 7.17179 4.16753 7.30462L1.3342 10.138C1.05758 10.4146 0.609087 10.4146 0.332466 10.138C0.0558446 9.86134 0.0558446 9.41284 0.332466 9.13622L2.66493 6.80376L0.332467 4.47129C0.0558452 4.19467 0.0558452 3.74618 0.332467 3.46956C0.609088 3.19293 1.05758 3.19293 1.3342 3.46956L4.16753 6.30289C4.30037 6.43573 4.375 6.61589 4.375 6.80376Z"
              /><path
                fill="#44474A"
                d="M12.6675 3.46956C12.9442 3.74618 12.9442 4.19467 12.6675 4.47129L10.3351 6.80376L12.6675 9.13622C12.9442 9.41284 12.9442 9.86134 12.6675 10.138C12.3909 10.4146 11.9424 10.4146 11.6658 10.138L8.83247 7.30463C8.55584 7.028 8.55584 6.57951 8.83247 6.30289L11.6658 3.46956C11.9424 3.19293 12.3909 3.19293 12.6675 3.46956Z"
              /></svg>
            </b-btn>

            <b-btn
              ref="flipY"
              variant="light"
              @click.prevent="flipY"
            >
              <svg
                fill="none"
                height="13"
                viewBox="0 0 12 13"
                width="12"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                fill="#44474A"
                d="M6 4.38004C5.82319 4.38004 5.65362 4.3098 5.5286 4.18477L2.86193 1.51811C2.60158 1.25776 2.60158 0.835647 2.86193 0.575298C3.12228 0.314948 3.54439 0.314948 3.80474 0.575298L6 2.77056L8.19526 0.575298C8.45561 0.314949 8.87772 0.314949 9.13807 0.575298C9.39842 0.835648 9.39842 1.25776 9.13807 1.51811L6.4714 4.18477C6.34638 4.3098 6.17681 4.38004 6 4.38004Z"
              /><path
                fill="#44474A"
                d="M9.13807 12.1848C8.87772 12.4451 8.45561 12.4451 8.19526 12.1848L6 9.98951L3.80474 12.1848C3.54439 12.4451 3.12228 12.4451 2.86193 12.1848C2.60158 11.9244 2.60158 11.5023 2.86193 11.242L5.52859 8.5753C5.78894 8.31495 6.21105 8.31495 6.4714 8.5753L9.13807 11.242C9.39842 11.5023 9.39842 11.9244 9.13807 12.1848Z"
              /><path
                fill="#44474A"
                d="M11.3333 7.0467C11.7015 7.0467 12 6.74823 12 6.38003C12 6.01185 11.7015 5.71337 11.3333 5.71337H0.666667C0.298476 5.71337 0 6.01185 0 6.38003C0 6.74823 0.298476 7.0467 0.666667 7.0467H11.3333Z"
              /></svg>
            </b-btn>

            <b-btn
              variant="light"
              @click.prevent="aspect(0)"
            >
              Reset
            </b-btn>
            <b-btn
              v-if="!hideSubmitBtn"
              variant="primary"
              @click="cropImage"
            >
              Crop And Upload
            </b-btn>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
// import Compressor from 'compressorjs'
import FileMixin from '@/mixins/FileMixin'

export default {
  name: 'ImageEditor',
  components: {
    VueCropper
  },
  mixins: [FileMixin],
  props: {
    hideSubmitBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      imgSrc: null,
      cropImg: '',
      data: null,
      file: [],
      type: null,
      originalFile:null,
      croppedSize:null
    }
  },
  methods: {
    aspect (aspect) {
      this.$refs.cropper.setAspectRatio(aspect)
    },
    cropImage () {
      const canvas = this.$refs.cropper.getCroppedCanvas()

      canvas.toBlob((file) => {
        const preFormData = new FormData()
        preFormData.append('file', file)
        this.$emit('startUpload', preFormData)
      }, this.type)
    },
    async getCropedImage () {
      return new Promise((resolve ) => {
        const canvas = this.$refs.cropper.getCroppedCanvas()
        canvas.toBlob((file) => {
          resolve(file)
        }, this.originalFile.type)
      })
    },
    flipX () {
      const dom = this.$refs.flipX
      let scale = dom.getAttribute('data-scale')
      scale = scale ? -scale : -1
      this.$refs.cropper.scaleX(scale)
      dom.setAttribute('data-scale', scale)
    },
    flipY () {
      const dom = this.$refs.flipY
      let scale = dom.getAttribute('data-scale')
      scale = scale ? -scale : -1
      this.$refs.cropper.scaleY(scale)
      dom.setAttribute('data-scale', scale)
    },
    getCropBoxData () {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4)
    },
    getData () {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4)
    },
    move (offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY)
    },
    reset () {
      this.$refs.cropper.reset()
    },
    rotate (deg) {
      this.$refs.cropper.rotate(deg)
    },
    setCropBoxData () {
      if (!this.data) return
      this.$refs.cropper.setCropBoxData(JSON.parse(this.data))
    },
    setData () {
      if (!this.data) return
      this.$refs.cropper.setData(JSON.parse(this.data))
    },
    setImage (e) {
      const setImg = (srt) => {
        this.imgSrc = srt
        this.$refs.cropper.replace(srt)
      }

      if (!e) return

      const file = e.target.files[0]
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          setImg(event.target.result)
        }
        reader.readAsDataURL(file)
      }
    },
    setImageFromFile (file) {
      this.originalFile = file
      const setImg = (srt) => {
        this.imgSrc = srt
        this.$refs.cropper.replace(srt)
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = (event) => {
          setImg(event.target.result)
        }
        reader.readAsDataURL(file)
      }
    },
    showFileChooser () {
      this.$refs.input.click()
    },
    zoom (percent) {
      this.$refs.cropper.relativeZoom(percent)
    },
    async handleCropChange (){
      const file = await this.getCropedImage()
      this.croppedSize = file.size
    }
  }
}
</script>

<style lang="scss">
.preview-area {
  width: 307px;

  .edtor-image-preview {
    width: 100%;
    height: calc(372px * (9 / 16));
    overflow: hidden;
  }
}
</style>
