import SectionProvider from '@builder/sections/SectionProvider'
import Navbar from './Navbar'

export const name = 'Hero_003'
export const group = 'hero'
export const defaultOptions = {
  'content': {
    'items': {
      'type': null,
      'children': [
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': 'hMr6QkIGV',
                      'display': true,
                      'name': 'Subheadline',
                      'sid': 'lle5rye7--ll9gRQz-D',
                      'hideFromSections': false,
                      'component': 'ElSubheadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h4',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>CREATE THINGS</p>',
                            'animations': {
                              'animation': 'fade-up',
                              'once': true,
                              'offset': 200,
                              'delay': 50,
                              'duration': '1200'
                            }
                          }
                        },
                        'customize': null
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': '7-73y8dmv',
                      'display': true,
                      'name': 'Headline',
                      'sid': 'lle5rye7--GRj16Il7i_',
                      'hideFromSections': false,
                      'component': 'ElHeadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h3',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>I create many great <typed speed="70" backspeed="70" fade="true" words="<span style=&quot;color:&quot;>things</span>|<span style=&quot;color:&quot;>plans</span>|<span style=&quot;color:&quot;>products</span>">things</typed></p>',
                            'animations': {
                              'animation': 'fade-up',
                              'once': true,
                              'offset': 200,
                              'delay': '150',
                              'duration': '900'
                            }
                          }
                        }
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': '1f8LzEQrF',
                      'display': true,
                      'name': 'Paragraph',
                      'sid': 'lle5rye7--WjCRxju3P-',
                      'hideFromSections': false,
                      'component': 'ElParagraphV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>Cras faucibus purus tincidunt tincidunt mollis. Fusce placerat augue et porttitor sodales. Ut tincidunt leo in arcu suscipit ultrices. Morbi volutpat imperdiet molestie. </p>',
                            'animations': {
                              'animation': 'fade-up',
                              'once': true,
                              'offset': 200,
                              'delay': '110',
                              'duration': '1200'
                            }
                          }
                        },
                        'customize': null
                      },
                      'editor': 'project'
                    },
                    {
                      'uid': 'pmaFO-YTv',
                      'display': true,
                      'name': 'Button',
                      'sid': 'lle5rye7--0oKGAkNSu2',
                      'hideFromSections': false,
                      'component': 'ElButtonV2',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'button': {
                            'pressetID': 0,
                            'display': true,
                            'type': 'button-mod-v2',
                            'text': 'GET A DEMO',
                            'buttonType': 'button',
                            'icon': {
                              'lottie': {
                                'jsonPath': '',
                                'loop': true,
                                'autoplay': true
                              }
                            },
                            'hoverAnimation': {},
                            'animations': {
                              'animation': 'fade-in',
                              'once': true,
                              'offset': 200,
                              'delay': '230',
                              'duration': '1500'
                            }
                          }
                        },
                        'customize': null
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': 'SB8eryQp8I',
                  'sid': 'lle5rye7--fVrSSyQCwK',
                  'highlight': false,
                  '_uid': '11b6sEmZ-y',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                },
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'uid': '8EAReXuD2',
                      'display': true,
                      'name': 'Image',
                      'sid': 'lle5rye7--JTa9EezpJB',
                      'hideFromSections': false,
                      'component': 'ElImageV3',
                      'group': 'Media',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'image': {
                            'display': true,
                            'type': 'ImageModV3',
                            'src': null,
                            'alt': 'Image',
                            'modal': {
                              'data': {
                                'modal_id': null
                              }
                            },
                            'animations': {
                              'animation': 'slide-left',
                              'once': true,
                              'offset': '500',
                              'delay': '350',
                              'duration': '900'
                            },
                            'url': ''
                          }
                        }
                      },
                      'editor': 'project'
                    }
                  ],
                  'sortIndex': {
                    'lg': 1,
                    'md': 1,
                    'sm': 1
                  },
                  'uid': 'mqHK6zQFtw',
                  'sid': 'lle5rye7--dLUsjHANHK',
                  'highlight': false,
                  '_uid': 'h5ojZCKvaw',
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': '9Gc_atfFc',
          'sid': 'lle5rye7--5uTNk_zyEo',
          'temp_id': 'SpR-HP7X_'
        }
      ]
    },
    'lottie': {}
  },
  'backgroundImage': {
    'src': ''
  },
  'backgroundVideo': {
    'enabled': false,
    'url': '',
    'loop': true,
    'ratio': 1
  },
  'shapeDivider': {
    'bottom': {
      'type': {
        'lg': 4,
        'md': 4,
        'sm': 4
      },
      'height': {
        'lg': 83,
        'md': 83,
        'sm': 43
      },
      'color': '#FFFFFFFF'
    },
    'top': {
      'height': {
        'sm': 133
      }
    }
  }
}

export const iframeURL = 'https://my-web-1690249894728.estage.site'

export const beforeBlock = new Navbar().json()

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('BlankV2', options)

    this.name = name
    this.group = group
    this.sid = 'lle5rye7--Nbtcf9GEgy'
    this.sids = [
      'lle5rye7--Nbtcf9GEgy',
      'lle5rye7--5uTNk_zyEo',
      'lle5rye7--fVrSSyQCwK',
      'lle5rye7--ll9gRQz-D',
      'lle5rye7--GRj16Il7i_',
      'lle5rye7--WjCRxju3P-',
      'lle5rye7--0oKGAkNSu2',
      'lle5rye7--dLUsjHANHK',
      'lle5rye7--JTa9EezpJB'
    ]
    this.css = [
      [
        '.--lle5rye7--Nbtcf9GEgy--container { max-width: 100vw; padding-bottom: 120px; padding-top: 170px; --width: 100vw; }',
        '.--lle5rye7--fVrSSyQCwK--flex { max-width: 80vw; --width: 80vw; }',
        '.--lle5rye7--fVrSSyQCwK--flex-col-el { width: 100%; --width: 100%; }',
        '.--lle5rye7--dLUsjHANHK--flex-col-el { width: 100%; --width: 100%; }',
        '.--lle5rye7--5uTNk_zyEo--flex { max-width: 1200px; --width: 1200px; gap: 0px 49px; }',
        '.--lle5rye7--fVrSSyQCwK--flex-col-padding { padding: 0px 19px 0px 0px; }',
        '.--lle5rye7--GRj16Il7i_-text { padding: 0px 0px 20px 20px; }',
        '.--lle5rye7--GRj16Il7i_-text div { font-family: Roboto; font-weight: 700; font-size: 72px; text-align: left; color: rgb(24, 24, 89); line-height: 1; --line-height: 1; }',
        '.--lle5rye7--ll9gRQz-D-text { padding: 20px 20px 9px; }',
        '.--lle5rye7--ll9gRQz-D-text div { text-align: left; color: rgb(103, 107, 154); font-family: Poppins; font-size: 16px; letter-spacing: 1px; }',
        '.--lle5rye7--WjCRxju3P--text { padding: 9px 20px 20px; }',
        '.--lle5rye7--WjCRxju3P--text div { text-align: left; font-family: Roboto; font-weight: 300; font-size: 17px; line-height: 1.6; --line-height: 1.6; color: rgb(78, 81, 119); }',
        '.--lle5rye7--0oKGAkNSu2--1 { margin: 20px; font-family: Roboto; font-size: 14px; font-weight: 700; background-color: rgba(0, 0, 0, 0); border-color: rgb(43, 44, 170); border-width: 2px; border-radius: 95px; color: rgb(43, 44, 170); }',
        '.--lle5rye7--0oKGAkNSu2--1 .main-text { justify-content: start; }',
        '.--lle5rye7--0oKGAkNSu2--2 { justify-content: start; }',
        '.--lle5rye7--Nbtcf9GEgy--bg { background-color: rgba(0, 0, 0, 0); background-image: linear-gradient(45deg, rgb(227, 241, 255) 0px, rgb(255, 255, 255) 100%); }',
        '.--lle5rye7--JTa9EezpJB--container { justify-content: center; }',
        '.--lle5rye7--JTa9EezpJB--wrapper { padding: 0px; }',
        '.el-image.--lle5rye7--JTa9EezpJB--image { width: 100%; }',
        '.--lle5rye7--JTa9EezpJB--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690326881/users/user-572/38404c66c1f066ba5ecbc4f69aa47ad4.png"); --bg-id: 167139; }',
        '.--lle5rye7--dLUsjHANHK--flex-col-padding { padding: 0px; }',
        '.--lle5rye7--0oKGAkNSu2--1:hover { background-color: rgb(43, 44, 170); border-color: rgb(43, 44, 170); color: rgb(255, 255, 255); }'
      ],
      [
        '.--lle5rye7--fVrSSyQCwK--flex { max-width: 100%; --width: 100%; }',
        '.--lle5rye7--5uTNk_zyEo--flex { max-width: 100%; --width: 100%; flex-flow: column wrap; }',
        '.--lle5rye7--GRj16Il7i_-text div { text-align: center; font-size: 65px; }',
        '.--lle5rye7--fVrSSyQCwK--flex-col-el { width: 575px; --width: 575px; }',
        '.--lle5rye7--fVrSSyQCwK--flex-col { text-align: center; justify-content: center; align-items: center; }',
        '.--lle5rye7--ll9gRQz-D-text div { text-align: center; }',
        '.--lle5rye7--WjCRxju3P--text div { text-align: center; }',
        '.--lle5rye7--fVrSSyQCwK--flex-col > div { display: contents; --display: contents; }',
        '.--lle5rye7--0oKGAkNSu2--1 .main-text { justify-content: center; }',
        '.--lle5rye7--0oKGAkNSu2--2 { justify-content: center; }',
        '.--lle5rye7--fVrSSyQCwK--flex-col-padding { padding-right: 0px; }',
        '.--lle5rye7--5uTNk_zyEo--flex.flex-wrapper { padding-top: 0px; }',
        '.--lle5rye7--Nbtcf9GEgy--container { padding-top: 140px; padding-bottom: 50px; }',
        '.el-image.--lle5rye7--JTa9EezpJB--image { width: 65%; --width: 65%; }'
      ],
      [
        '.--lle5rye7--fVrSSyQCwK--flex { max-width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lle5rye7--5uTNk_zyEo--flex { max-width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lle5rye7--fVrSSyQCwK--flex-col-el { width: 100%; --width: 100%; }',
        '.--lle5rye7--GRj16Il7i_-text div { font-size: 34px; }',
        '.--lle5rye7--WjCRxju3P--text div { font-size: 15px; }',
        '.--lle5rye7--GRj16Il7i_-text { padding-bottom: 7px; }',
        '.--lle5rye7--WjCRxju3P--text { padding-left: 0px; padding-right: 0px; padding-bottom: 0px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
