<template>
  <div
    v-if="project"
    class="dashboard-space pt-[87px]"
  >
    <module-header />

    <div>
      <!--      <div class="d-flex align-items-center justify-content-between mb-4">-->
      <!--        <h3 class="mr-2">-->
      <!--          {{ project.name }}-->
      <!--        </h3>-->
      <!--      </div>-->

      <div class="flex w-full">
        <project-settings-nav />


        <div
          v-if="loading"
          class="w-100"
        >
          <b-card
            class="settings-page-card"
            body-class="pt-0"
          >
            <div

              class="w-full h-[250px] flex items-center justify-center "
            >
              <b-spinner
                class="!w-12 !h-12"
                variant="primary"
              />
            </div>
          </b-card>
        </div>
        <div
          v-else
          class="w-100 border-l"
        >
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectSettingsNav from './ProjectSettingsNav'
import {mapState} from 'vuex'
import ModuleHeader from '../../components/editor/header/ModuleHeader'

export default {
  name: 'Settings',

  components: {ModuleHeader, ProjectSettingsNav},

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },

  async created () {
    VEvent.fire('loader', true)
    this.loading = true
    try {
      if (!this.project) {
        await this.$store.dispatch('projects/load', {id: this.$route.params.id, path: this.$route.params.path})
      }

      await this.$store.dispatch('apiIntegration/getlAllMailServices')
    } catch (error) {
      console.error(error)
    } finally {
      VEvent.fire('loader', false)
      this.loading = false
    }
  }
}
</script>
