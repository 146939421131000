<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element hide-copy-favorite"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('form')"
  >
    <Template :section="section">
      <form-layout
        v-if="section.options.content.form.controls.length"
        :form="section.options.content.form"
      />
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import FormLayout from '../../components/form/FormLayout'
import PropertiesManager from '../../../mixins/PropertiesManager'
import {toJSON} from '../../../../utils/helpers'

export default {
  name: 'FeaturedItems1',

  components: {FormLayout, Template, Highliter},

  mixins: [SectionMixin, PropertiesManager],
  
  computed: {
    padding () {
      return ''
      // if (!this.customs.resolutionStyle[this.resolution].padding) return {}
      //
      // return {
      //   paddingTop: this.customs.resolutionStyle[this.resolution].padding.top + 'px',
      //   paddingBottom: this.customs.resolutionStyle[this.resolution].padding.bottom + 'px',
      //   paddingLeft: this.customs.resolutionStyle[this.resolution].padding.x + 'px',
      //   paddingRight: this.customs.resolutionStyle[this.resolution].padding.x + 'px'
      // }
    }
  },

  async created () {
    await this.createForm()
  },

  mounted () {
    VEvent.listen('autoresponder-integrated', this.handleAutoresponderIntegrated)
  },

  methods: {
    async createForm () {
      if (!this.section.options.content.form.id) {
        VEvent.fire('loader', true)
        await this.$store.dispatch('forms/createForm', this.section.options.content.form.controls)
          .then(form => {
            this.section.options.content.form.id = form.form.id
            this.getForm()

            const controls = form.controls.map(control => {
              const columns = toJSON(control.columns)
              return {
                ...control,
                columns: Object.keys(columns).length ? columns : {
                  lg: control.columns,
                  md: control.columns,
                  sm: control.columns
                }
              }
            })

            this.$set(this.section.options.content.form, 'controls', controls)
            return this.$store.dispatch('pages/save')
          })
          .finally(() => {
            VEvent.fire('loader', false)
          })
      } else {
        await this.getForm()
        await this.fetchControls()
      }
    },
    fetchControls () {
      this.$store.dispatch('forms/fetchControls', this.section.options.content.form.id)
        .then(({data}) => {
          const controls = data.data.map(control => {
            const columns = toJSON(control.columns)
            return {
              ...control,
              columns: Object.keys(columns).length ? columns : {
                lg: control.columns,
                md: control.columns,
                sm: control.columns
              }
            }
          })
          this.$set(this.section.options.content.form, 'controls', controls)
        })
    },
    getForm () {
      this.$store.dispatch('forms/getForm', this.section.options.content.form.id)
        .then(({data}) => {
          this.$set(this.section.options.content.form, 'form', {
            ...data.data,
            actions: data.data.actions !== '{}' ? JSON.parse(data.data.actions) : [],
            api_list_or_tag: (data.data.api_list_or_tag !== '[]' && data.data.api_list_or_tag !== null) ? JSON.parse(data.data.api_list_or_tag) : {},
            mappedFields: {}
          })
        })
    },
    handleAutoresponderIntegrated (val){
      const form = this.section.options.content.form
      if(this.get(form,'form.actions',false)){
        const index = form.form.actions.findIndex(action => this.get(action,'id') && this.get(action,'alpha_code') && action.id == val.id && action.alpha_code == val.alpha_code) 
        if(index != -1){
          return this.$set(this.section.options.content.form.form.actions, index,val)
        }
      }
    }
  }
}
</script>
