<template>
  <div class="pt-[10px]">
    <b-form-group label="Color">
      <color-picker
        :color="config.color"
        @input="event => config.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
      />
    </b-form-group>

    <!--      <b-form-group label="Max Value">-->
    <!--        <b-input-->
    <!--          v-model="config.max"-->
    <!--          :min="0"-->
    <!--          type="number"-->
    <!--        />-->
    <!--      </b-form-group>-->

    <b-form-group
      label="Progress"
      label-class="!pb-[3px]"
    >
      <b-row
        class="align-items-center"
        no-gutters
      >
        <b-col
          class="pr-2"
          cols="9"
        >
          <vue-slider
            v-model="config.complite"
            :dot-size="17"
            :max="parseInt(config.max)"
            :min="0"
            class="editor-slider-1 px-2"
            tooltip="none"
          />
        </b-col>

        <b-col cols="3">
          <b-input
            v-model="config.complite"
            :max="100"
            :min="0"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group label-class="!pb-[3px]">
      <template slot="label">
        Height ({{ resolutioner }})
      </template>

      <b-row
        class="align-items-center"
        no-gutters
      >
        <b-col
          class="pr-2"
          cols="9"
        >
          <vue-slider
            v-model="height"
            :dot-size="17"
            :max="70"
            :min="10"
            class="editor-slider-1 px-2"
            tooltip="none"
          />
        </b-col>

        <b-col cols="3">
          <b-input
            v-model="height"
            :max="70"
            :min="10"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="config.animated">
        Animated
      </b-checkbox>
    </b-form-group>

    <b-row
      class="align-items-end"
      no-gutters
    >
      <b-col
        class="pr-2"
        cols="10"
      >
        <b-form-group label="Progress Text">
          <b-input
            v-model="config.text.value"
            placeholder="Enter progress text"
          />
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group>
          <color-picker
            :color="config.text.color"
            @input="event => config.text.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <template v-if="config.text.value">
      <b-form-group label="Font Size">
        <v-select
          v-model="config.text.fontSize"
          :options="fontSizes()"
          class="custom-select"
          placeholder="Select Font Size"
        />
      </b-form-group>

      <b-form-group label="Font Family">
        <v-select
          v-model="config.text.fontFamily"
          :options="fonts"
          class="custom-select"
          placeholder="Select Font Family"
        >
          <template #selected-option="{label}">
            <div
              :style="{fontFamily: label}"
              class="font-size-14"
              v-text="label"
            />
          </template>

          <template #option="{label}">
            <div
              :style="{fontFamily: label}"
              v-text="label"
            />
          </template>
        </v-select>
      </b-form-group>

      <b-form-group label="Font Weight">
        <v-select
          v-model="config.text.fontWeight"
          :options="fontsWeight"
          :reduce="option => option.value"
          class="custom-select"
        />
      </b-form-group>
    </template>

    <SidbearSettingsGroup
      class="border-top"
      title="Drop Shadow"
    >
      <shadow-customs :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="section.options.customize.style.padding" />
      <margin-full-customs :config="margin" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import {mapState} from 'vuex'
import ColorPicker from '../utils/ColorPicker'
import _ from 'lodash'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'

export default {
  name: 'ImageMod',
  components: {SidbearSettingsGroup, ColorPicker},
  mixins: [ModMixin],
  data () {
    return {
      fontsWeight: [300, 400, 600, 900]
    }
  },
  computed: {
    ...mapState('font', {
      fonts: state => state.fonts
    }),
    margin () {
      if (!this.config.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.margin
    },
    heightObj () {
      return _.isObject(this.config.height) ? this.config.height : {
        lg: this.config.height,
        md: this.config.height,
        sm: this.config.height
      }
    },
    height: {
      get () {
        return this.heightObj[this.resolutioner]
      },
      set (val) {
        this.$set(this.config, 'height', {
          ...this.heightObj,
          [this.resolutioner]: val
        })
      }
    }
  },
  methods: {
    fontSizes () {
      return Array(70)
        .fill({})
        .map((_, i) => `${i + 1}px`)
    }
  }
}
</script>
