import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Comments'
export const group = 'Advanced'

export const defaultOptions = {
  content: {
    comments: {
      btnText: 'Post Comment',
      type: 'comments-mod',
      comments_container_id: null,
      name: '',
      auth_only: false
    }
  }
}

export const icon = `<svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.5 13.5L4.99993 10.9998L19.9999 8.99983L23.5 13.5Z" fill="black"/>
<rect width="20" height="11" rx="2" fill="black"/>
<rect x="1" y="1" width="18" height="9" rx="2" fill="white"/>
<rect x="2" y="3" width="16" height="2" fill="black"/>
<rect x="2" y="6" width="16" height="2" fill="black"/>
</svg>
`
export default class ElComments extends SectionProvider {
  constructor (options = {}) {
    super('ElComments', options)

    this.name = name
    this.group = group
    this.beta = true
    this.defaultOptions = defaultOptions
  }
}
