<template>
  <b-btn-group :class="{'d-xxl-none': zoom === 1}">
    <b-btn
      v-if="zoom !== 1"
      v-b-tooltip.hover
      class="d-flex align-items-center"
      variant="white"
      title="Zoom In"
      @click="$store.dispatch('editorZoom/zoomer', zoom + .1)"
    >
      <i class="fa fa-search-plus" />
    </b-btn>
    <b-btn
      v-if="zoom !== 0.7"
      v-b-tooltip.hover
      class="d-flex align-items-center"
      variant="white"
      title="Zoom Out"
      @click="$store.dispatch('editorZoom/zoomer', zoom - .1)"
    >
      <i class="fa fa-search-minus" />
    </b-btn>
  </b-btn-group>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Zoomer',
  computed: {
    ...mapState('editorZoom', {
      zoom: state => state.zoom
    })
  }
}
</script>
