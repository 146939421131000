<template>
  <div>
    <SidbearSettingsGroup
      title="Breadcrumbs"
      active
    >
      <rule-tabs :tabs="['Normal', 'Hover', 'Active']">
        <template #Normal>
          <sid-range-slider
            label="Font Size"
            :sid="className(' a')"
            rule-name="font-size"
          />

          <b-form-group label="Link Color">
            <sid-color
              rule-name="color"
              allow-reset
              :sid="className(' a')"
            />
          </b-form-group>

          <b-form-group label="Icon Color">
            <sid-color
              rule-name="stroke"
              allow-reset
              :sid="className(' path')"
            />
          </b-form-group>


          <sid-font :sid="className(' a')" />

          <sid-background
            :sid="className()"
          />

          <sid-range-slider
            label="Gap"
            :sid="className()"
            rule-name="gap"
            default-dimension="px"
            :dimensions="[]"
          />
        </template>

        <template #Hover>
          <b-form-group label="Link Color">
            <sid-color
              rule-name="color"
              :sid="className(' a:hover')"
              presudo=":hover"
            />
          </b-form-group>

          <sid-font
            :sid="className(' a:hover')"
            presudo=":hover"
          />
        </template>

        <template #Active>
          <b-form-group label="Link Color">
            <sid-color
              rule-name="color"
              :sid="className(' a.active')"
            />
          </b-form-group>

          <sid-font
            :sid="className(' a.active')"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    <SidbearSettingsGroup title="Drop Shadow">
      <b-form-group
        label="Shadow Color"
        class="mb-0"
      >
        <sid-box-shadow
          :sid="className()"
          rule-name="box-shadow"
        />
      </b-form-group>
    </SidbearSettingsGroup>
    <SidbearSettingsGroup title="Border & Radius">
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="className()"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="className()"
          label="Border Width"
          rule-name="border"
          css-var="--border-size"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="className()"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="className()"
        rule-name="border"
        :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
        :angles="[
          {
            text: '',
            value: 'top-left-radius',
            cssVar: '--border-top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius',
            cssVar: '--border-top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius',
            cssVar: '--border-bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius',
            cssVar: '--border-bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>
    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="className()"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="className()"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'

export default {
  name: 'PostBreadcrumbsMod',
  components: {
    SidRangeSlider,
    SidBoxShadow, SidSelect, SidLinkedGroup, SidBackground, RuleTabs, SidFont, SidColor, SidbearSettingsGroup},
  mixins: [ModMixin],
  methods: {
    className (name = '') {
      return `.--post-breadcrumbs-${this.section.sid}.post-breadcrumbs${name}`
    }
  }
}
</script>