const state = () => ({
  zoom: parseFloat(parseFloat(localStorage.getItem('zoom') || 1).toFixed(1))
})

const getters = {}

const actions = {
  zoomer ({commit}, zoom) {
    localStorage.setItem('zoom', zoom)
    commit('SET_ZOOM', zoom)
  }
}

const mutations = {
  SET_ZOOM (state, zoom) {
    state.zoom = parseFloat(zoom.toFixed(1))
  }
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
