<template>
  <div class="shadow border-t border-gray-200 py-6 bg-white z-20">
    <div class="px-[4rem] lg:container lg:mx-auto h-full flex items-center justify-between">
      <footer
        class="bottom-0 left-0 z-20 w-full bg-white  md:flex md:items-center md:justify-between"
      >
        <img
          class="h-[17px] w-[90px]"
          src="@/assets/icons/main-logo.svg"
          alt="Logo"
        >

        <span
          class="text-sm text-gray-500 sm:text-center"
        >© {{ new Date().getFullYear() }}
          <a
            href="https://estage.com/"
            class="text-gray-500 hover:underline"
            target="_blank"
          >ESTAGE,LLC</a>. All Rights
          Reserved.
        </span>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
        
}
</script>