<template>
  <b-form-group :label="label">
    <v-select
      v-model="model"
      :options="fontSizes()"
      class="custom-select"
    />
  </b-form-group>
</template>

<script>
import _ from 'lodash'
import ResolutionMixin from '../../mixins/ResolutionMixin'

export default {
  name: 'FontSizeControl',

  mixins: [ResolutionMixin],

  props: {
    config: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: 'Font size'
    }
  },

  computed: {
    modelObject () {
      return this.config.fontSize || {
        sm: '14px',
        md: '14px',
        lg: '14px'
      }
    },
    model: {
      get () {
        return _.get(this.config, ['fontSize', this.resolutioner], '14px')
      },
      set (val) {
        this.$set(this.config, 'fontSize', {
          ...this.modelObject,
          [this.resolutioner]: val
        })
      }
    }
  },
  methods: {
    fontSizes () {
      const arr = Array(24)
        .fill({})
        .map((_, i) => `${i+1}px`)
      return arr.slice(10)
    }
  }
}
</script>
