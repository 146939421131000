export function getIndexByUID (array, uid) {

  const index = array.findIndex(s => s.uid === uid)

  if (index !== -1) {
    return index
  } else {
    return false
  }
}
