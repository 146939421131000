<template>
  <div class="global-styles overflow-y-auto pb-3">
    <b-card-header header-class="editor-toolbar-card-header d-flex justify-content-between align-items-center">
      <h5 class="editor-toolbar-title mb-0">
        Page Settings
      </h5>

      <b-link
        class="d-flex align-items-center font-weight-light small link-muted"
        @click="back"
      >
        <icon
          class="mr-2"
          icon="left-arrow.svg"
        />
        Go Back
      </b-link>
    </b-card-header>

    <b-card class="border-0 mb-4">
      <div>
        <div class="d-flex align-items-center mb-4">
          <span class="indicator mr-2" />
          <p class="text-capitalize mb-0">
            Blog
          </p>
        </div>

        <b-form-group>
          <b-input
            type="search"
            placeholder="Search in the blog"
          />
        </b-form-group>


        <b-btn
          v-b-modal.blog-modal
          variant="outline-primary"
          block
        >
          New Post
        </b-btn>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'PageSettings',
  methods: {
    back () {
      this.$store.dispatch('router/setPage', 'home')
      this.$store.commit('editor/SET_PAGE', 'page-settings')
    }
  }
}
</script>

