<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="time-section highlight-element"
    section-type="element"
    @delete="remove"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings('time')"
    @add-subject="$emit('add-subject')"
  >
    <Template :section="section">
      <template v-slot:time="{color, backgroundColor, sizing, scale}">
        <vac
          ref="vac"
          :end-time="timeZoneTransform"
        >
          <template
            v-slot:process="{ timeObj }"
          >
            <div
              class="d-flex align-items-center flex-wrap justify-content-center timer-block"
              data-bg
              data-opacity
              data-shadow
            >
              <div
                v-if="!style.dontShow.value.includes('Days')"
                class="timer-item mr-2"
              >
                <div
                  :style="{fontFamily: dialFontFamily, ...shadow, ...backgroundColor, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale + 1}rem`}"
                  class="time"
                >
                  {{ timeObj.d < 10 ? '0' + timeObj.d : timeObj.d }}
                </div>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >days</span>
              </div>
              <div
                v-if="!style.dontShow.value.includes('Hours')"
                class="timer-item mr-2"
              >
                <div
                  :style="{fontFamily: dialFontFamily, ...shadow, ...backgroundColor, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale + 1}rem`}"
                  class="time"
                >
                  {{ timeObj.h }}
                </div>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >hours</span>
              </div>
              <div
                v-if="!style.dontShow.value.includes('Minutes')"
                class="timer-item mr-2"
              >
                <div
                  :style="{fontFamily: dialFontFamily, ...shadow, ...backgroundColor, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale + 1}rem`}"
                  class="time"
                >
                  {{ timeObj.m }}
                </div>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >minutes</span>
              </div>
              <div
                v-if="!style.dontShow.value.includes('Seconds')"
                class="timer-item"
              >
                <div
                  :style="{fontFamily: dialFontFamily, ...shadow, ...backgroundColor, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale + 1}rem`}"
                  class="time"
                >
                  {{ timeObj.s }}
                </div>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >seconds</span>
              </div>
            </div>
          </template>
        </vac>
      </template>
      <template v-slot:time="{color, backgroundColor, sizing, scale}">
        <vac
          ref="vac"
          :end-time="section.options.content.time.time"
        >
          <template
            v-slot:process="{ timeObj }"
          >
            <div
              class="d-flex align-items-center flex-wrap justify-content-center timer-block"
              data-bg
              data-opacity
              data-shadow
            >
              <div
                v-if="!style.dontShow.value.includes('Days')"
                class="timer-item mr-2"
              >
                <div
                  :style="{fontFamily: dialFontFamily, ...shadow, ...backgroundColor, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale + 1}rem`}"
                  class="time"
                >
                  {{ timeObj.d < 10 ? '0' + timeObj.d : timeObj.d }}
                </div>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >days</span>
              </div>
              <div
                v-if="!style.dontShow.value.includes('Hours')"
                class="timer-item mr-2"
              >
                <div
                  :style="{fontFamily: dialFontFamily, ...shadow, ...backgroundColor, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale + 1}rem`}"
                  class="time"
                >
                  {{ timeObj.h }}
                </div>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >hours</span>
              </div>
              <div
                v-if="!style.dontShow.value.includes('Minutes')"
                class="timer-item mr-2"
              >
                <div
                  :style="{fontFamily: dialFontFamily, ...shadow, ...backgroundColor, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale + 1}rem`}"
                  class="time"
                >
                  {{ timeObj.m }}
                </div>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >minutes</span>
              </div>
              <div
                v-if="!style.dontShow.value.includes('Seconds')"
                class="timer-item"
              >
                <div
                  :style="{fontFamily: dialFontFamily, ...shadow, ...backgroundColor, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale + 1}rem`}"
                  class="time"
                >
                  {{ timeObj.s }}
                </div>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >seconds</span>
              </div>
            </div>
          </template>
          <template
            v-slot:finish
          >
            <div
              class="d-flex flex-wrap align-items-center justify-content-center timer-block"
              data-bg
              data-opacity
              data-shadow
            >
              <div
                v-if="!style.dontShow.value.includes('Days')"
                class="timer-item mr-2"
              >
                <div
                  :style="{fontFamily: dialFontFamily, ...backgroundColor, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale + 1}rem`}"
                  class="time"
                >
                  00
                </div>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >days</span>
              </div>
              <div
                v-if="!style.dontShow.value.includes('Hours')"
                class="timer-item mr-2"
              >
                <div
                  :style="{fontFamily: dialFontFamily, ...backgroundColor, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale + 1}rem`}"
                  class="time"
                >
                  00
                </div>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >hours</span>
              </div>
              <div
                v-if="!style.dontShow.value.includes('Minutes')"
                class="timer-item mr-2"
              >
                <div
                  :style="{fontFamily: dialFontFamily, ...backgroundColor, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale + 1}rem`}"
                  class="time"
                >
                  00
                </div>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >minutes</span>
              </div>
              <div
                v-if="!style.dontShow.value.includes('Seconds')"
                class="timer-item"
              >
                <div
                  :style="{fontFamily: dialFontFamily, ...backgroundColor, fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale + 1}rem`}"
                  class="time"
                >
                  00
                </div>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >seconds</span>
              </div>
            </div>
          </template>
        </vac>
      </template>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import TimerService from '../TimerService'

export default {
  name: 'TimeItem1',

  components: {Template, Highliter},

  mixins: [SectionMixin, TimerService],

  computed: {
    shadow () {
      const shadow = this.section.options.content.time.shadowOptions
      const color = this.section.options.content.time.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    }
  },

  created () {
    VEvent.listen('timer-update', () => {
      setTimeout(() => {
        if (this.$refs.vac === undefined) return
        this.$refs.vac.startCountdown(true)
      })
    })
  }
}
</script>
