<template>
  <div>
    <b-form-group label="Section Width">
      <b-row no-gutters>
        <b-col
          class="pr-3"
          cols="12"
        >
          <v-select
            v-model="config.width"
            :calculate-position="withPopper"
            :options="widths"
            :reduce="width => width.value"
            :searchable="false"
            append-to-body
            class="custom-select"
          />
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'
import ControlMixin from '../../mixins/ControlMixin'
import popperMixin from '../mixins/popperMixin'

export default {
  name: 'Shadow',
  mixins: [ControlMixin, popperMixin],

  data () {
    return {
      widths: [
        {
          value: 'full-width',
          label: 'Full Width'
        },
        {
          value: 'wide',
          label: 'Wide'
        },
        {
          value: 'medium',
          label: 'Medium'
        },
        {
          value: 'small',
          label: 'Small'
        }
      ]
    }
  },

  computed: {
    sticky: {
      get () {
        return _.get(this.section.options.customize.style, 'sticky', _.get(this.section.options, 'sticky', false))
      },
      set (val) {
        this.$set(this.section.options.customize.style, 'sticky', val)
      }
    }
  }
}
</script>
