<template>
  <div>
    <b-form-group>
      <form-controls-list
        :form="form"
        :config="config"
      />
    </b-form-group>
  </div>
</template>

<script>
import FormControlsList from './FormControlsList'
import resolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  name: 'FormFieldsV2',
  components: { FormControlsList},
  mixins: [resolutionMixin],
  props: {
    form: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  }
}
</script>
