<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element hide-copy-favorite"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('collapse')"
  >
    <Template :section="section">
      <template
        #header="content"
      >
        <editable
          v-model="content.content.title"
          class="d-block w-100"
        />
      </template>

      <template
        #body="content"
      >
        <elements
          v-model="content.content.elements"
          @flow="handleFlow"
        />
      </template>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import Elements from '../../utils/Elements/Elements'

export default {
  name: 'FeaturedItems1',

  components: {Elements, Template, Highliter},

  mixins: [SectionMixin]
}
</script>
