<template>
  <div
    :data-sid="`form-field-${sid}`"
    :class="`--${section.sid}--margin`"
    class="form-margin"
  >
    <highliter
      v-model="section.name"
      section-type="element"
      class="highlight-element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <div
        class="flex flex-wrap mb-0"
        :class="`--${sid}--form-fields`"
      >
        <template v-for="(item, index) in section.options.content.control">
          <template v-if="item.field_name">
            <b-form-group
              v-if="!item.is_hide"
              :key="index"
              class="estage-form-element-group mb-0"
              :class="`--${section.sid}--form-field --${section.sid}--form-field-${item.field_name}`"
            >
              <template
                #label
                class="flex items-center gap-x-[4px]"
                :class="`--${section.sid}--label`"
              >
                {{ item.label }}
                <span
                  v-if="fieldRequired"
                  class="text-danger"
                >
                  *
                </span>
              </template>

              <legend class="d-none active" />

              <b-form-input
                v-model="form[item.field_name]"
                :placeholder="item.placeholder"
                :type="fieldType(item.field_name)"
                v-bind="item.$attrs"
                :class="`--${section.sid}--input`"
                class="input"
                :required="fieldRequired"
              />
            </b-form-group>
          </template>

          <template v-else>
            <p
              v-if="item.show"
              :key="index"
              :class="`--${section.sid}--login`"
            >
              {{ item.text }}
              <span
                class="m-0 cursor-pointer hover:opacity-80"
                :class="`--${section.sid}--login-link`"
              >
                {{ item.link }}
              </span>
            </p>
          </template>
        </template>
      </div>
    </highliter>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import BorderMixin from '@/components/mixins/BorderMixin'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElFieldInput',
  mixins: [BorderMixin, SectionMixin],
  components: {Highliter},
  data () {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: ''
      }
    }
  },
  computed: {
    ...mapState('payments', {
      product: state => state.product
    }),
    fieldRequired () {
      return this.product?.need_account
    }
  },
  methods: {
    control (index) {
      return this.section.options.content.control[index]
    },
    fieldType (name) {
      switch (name) {
      case 'email': return 'email'
      case 'password': return 'password'
      case 'password_confirmation': return 'password'
      default: return 'text'
      }
    }
  }
}
</script>

<style>
.editor-container-sm .editor-container-sm-items-start {
  align-items: flex-start;
}

.editor-container-sm .editor-container-sm-w-full {
  width: 100%;
}

.editor-container-sm .editor-container-sm-flex-col {
  flex-direction: column;
}

.editor-container-sm .editor-container-sm-flex-row {
  flex-direction: column;
}

.editor-container-md .editor-container-md-flex-col {
  flex-direction: column;
}

.editor-container-md .editor-container-md-flex-row {
  flex-direction: column;
}

.editor-container-lg .editor-container-lg-flex-col {
  flex-direction: column;
}

.editor-container-lg .editor-container-lg-flex-row {
  flex-direction: column;
}
</style>