<template>
  <div>
    <SidbearSettingsGroup
      title="Images"
      active
    >
      <image-upload
        :image="image"
        :no-delete="false"
        disable-settigns
        class="mb-2"
      />

      <div class="flex flex-column gap-2">
        <div
          v-for="(image, index) in images"
          :key="index"
          class="flex items-center justify-between border rounded-[2px] h-[30px] w-full px-2"
        >
          <div
            class="bg-danger h-[25px] w-[25px] bg-cover"
            :style="{backgroundImage: `url(${image.src})`}"
          />

          <a
            href="javascript:;"
            class="text-gray-400"
            @click="removeImage(index)"
          >
            <i class="fa fa-trash" />
          </a>
        </div>
      </div>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Gallery">
      <b-form-group
        label="Columns"
      >
        <div class="flex items-center gap-[10px]">
          <vue-slider
            v-model="section.options.content.gallery.rows"
            class="editor-slider-1 !w-[170px]"
            :interval="1"
            :dot-size="17"
            tooltip="none"
            :min="1"
            :max="10"
          />

          <LinkedControlItem
            v-model="section.options.content.gallery.rows"
            :min="1"
            :max="10"
            class="d-block !w-[60px]"
          />
        </div>
      </b-form-group>

      <sid-range-slider
        label="Gap"
        rule-name="gap"
        :sid="`.--gallery-gap-${section.sid}`"
        default-dimension="px"
        :dimensions="[]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <rule-tabs>
        <template slot="Normal">
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}-image`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}-image`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}-image`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}-image`"
            rule-name="border"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>

        <template slot="Hover">
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}-image`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}-image`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}-image`"
                presudo=":hover"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}-image`"
            rule-name="border"
            presudo=":hover"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Transform">
      <rule-tabs>
        <template #Normal>
          <sid-transform
            :sid="`.--${section.sid}-image`"
            rule-name="transform"
            :section="section"
          />
        </template>

        <template #Hover>
          <sid-transform
            :sid="`.--${section.sid}-image`"
            rule-name="transform"
            presudo=":hover"
            :section="section"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Filters">
      <rule-tabs>
        <template #Normal>
          <sid-background-filters
            :sid="`.--${section.sid}-image`"
            rule-name="filter"
          />
        </template>

        <template #Hover>
          <sid-background-filters
            :sid="`.--${section.sid}-image`"
            presudo=":hover"
            rule-name="filter"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      v-if="getOpenedItemObj.image"
      title="Click Action"
      content-class="min-h-[500px]"
    >
      <ButtonAction
        :config="getOpenedItemObj.image"
        class="mb-0"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--gallery-container-${section.sid}`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--gallery-container-${section.sid}`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import ImageUpload from '@/components/editor/utils/ImageUpload.vue'
import {backgroundImage} from '@tailwindcss/postcss7-compat/lib/plugins'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidTransform from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTransform.vue'
import ButtonAction from '@/components/editor/controls/button-mod/ButtonAction.vue'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem.vue'
import SidBackgroundFilters
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundFilters.vue'

export default {
  name: 'GalleryMod',
  components: {
    SidBackgroundFilters,
    LinkedControlItem,
    ButtonAction,
    SidTransform,
    RuleTabs,
    SidLinkedGroup,
    SidColor,
    SidSelect,
    SidRangeSlider, ImageUpload, SidbearSettingsGroup},
  mixins: [ModMixin],
  data () {
    return {
      image: {}
    }
  },
  computed: {
    images () {
      return this.section.options.content.gallery.images
    }
  },
  watch: {
    image: {
      deep: true,
      handler: function (val) {
        if (val.src) {
          this.handleNewImage()
        }
      }
    }
  },
  methods: {
    backgroundImage () {
      return backgroundImage
    },
    removeImage (index) {
      this.images.splice(index, 1)
    },
    handleNewImage () {
      this.images.push(this.image)
      this.image = {}
    }
  }
}
</script>