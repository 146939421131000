<template>
  <b-modal
    id="blog-modal"
    v-model="modal"
    size="xl"
    body-class="blog-modal"
    dialog-class="editor"
    header-class="border-bottom-0"
    hide-footer
    title-class="w-100"
    hide-header-close
  >
    <template #modal-title>
      <b-link
        class="link-muted"
        @click="modal = false"
      >
        <svg
          width="9"
          height="9"
          viewBox="0 0 9 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.49124 4.5L8.85622 1.13466C8.94878 1.04202 8.99985 0.918426 9 0.786637C9 0.654774 8.94893 0.531035 8.85622 0.438541L8.56134 0.143717C8.46863 0.050857 8.34505 0 8.21312 0C8.08142 0 7.95783 0.050857 7.86512 0.143717L4.50015 3.50884L1.13502 0.143717C1.04246 0.050857 0.918805 0 0.786951 0C0.655244 0 0.531585 0.050857 0.439024 0.143717L0.144 0.438541C-0.048 0.630553 -0.048 0.942866 0.144 1.13466L3.50905 4.5L0.144 7.86519C0.0513658 7.95798 0.000365854 8.08157 0.000365854 8.21336C0.000365854 8.34515 0.0513658 8.46875 0.144 8.56146L0.438951 8.85628C0.531512 8.94907 0.655244 9 0.786878 9C0.918732 9 1.04239 8.94907 1.13495 8.85628L4.50007 5.49109L7.86505 8.85628C7.95776 8.94907 8.08134 9 8.21305 9H8.21319C8.34498 9 8.46856 8.94907 8.56127 8.85628L8.85615 8.56146C8.94871 8.46882 8.99978 8.34515 8.99978 8.21336C8.99978 8.08157 8.94871 7.95798 8.85615 7.86527L5.49124 4.5Z"
            fill="#1D1D1D"
          />
        </svg>
      </b-link>

      <div class="d-flex align-items-center w-100 px-5 my-3">
        <h4 class="font-weight-bold mr-auto">
          Create New Post
        </h4>

        <!--        <b-btn class="d-flex align-items-center mr-2" @click="addPost" variant="white" :disabled="loading">-->
        <!--          Save As Draft-->
        <!--          <b-spinner v-if="loading" class="ml-2" variant="white" small></b-spinner>-->
        <!--        </b-btn>-->
        <!--        <b-btn class="d-flex align-items-center mr-2" @click="addPost" variant="white" :disabled="loading">-->
        <!--          Shedule Post-->
        <!--          <svg class="ml-2" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--            <path d="M15.1786 2.32143H13.3571V1.10715C13.3571 0.771827 13.0853 0.5 12.75 0.5C12.4147 0.5 12.1428 0.771827 12.1428 1.10715V2.32143H4.85713V1.10715C4.85713 0.771827 4.5853 0.5 4.25001 0.5C3.91472 0.5 3.64285 0.771827 3.64285 1.10715V2.32143H1.82143C0.81548 2.32143 0 3.13691 0 4.14285V15.6786C0 16.6845 0.81548 17.5 1.82143 17.5H15.1786C16.1845 17.5 17 16.6845 17 15.6786V4.14285C17 3.13691 16.1845 2.32143 15.1786 2.32143ZM15.7857 15.6786C15.7857 16.0139 15.5139 16.2857 15.1785 16.2857H1.82143C1.4861 16.2857 1.21427 16.0139 1.21427 15.6786V7.78571H15.7857V15.6786ZM15.7857 6.57144H1.21427V4.14285C1.21427 3.80753 1.4861 3.5357 1.82143 3.5357H3.64285V4.74997C3.64285 5.0853 3.91468 5.35713 4.25001 5.35713C4.58534 5.35713 4.85716 5.0853 4.85716 4.74997V3.5357H12.1429V4.74997C12.1429 5.0853 12.4147 5.35713 12.75 5.35713C13.0854 5.35713 13.3572 5.0853 13.3572 4.74997V3.5357H15.1786C15.5139 3.5357 15.7858 3.80753 15.7858 4.14285V6.57144H15.7857Z" fill="#6E747A"/>-->
        <!--            <path d="M4.85707 8.99998H3.6428C3.30747 8.99998 3.03564 9.27181 3.03564 9.60714C3.03564 9.94247 3.30747 10.2143 3.6428 10.2143H4.85707C5.1924 10.2143 5.46423 9.94247 5.46423 9.60714C5.46423 9.27181 5.1924 8.99998 4.85707 8.99998Z" fill="#6E747A"/>-->
        <!--            <path d="M9.10707 8.99998H7.8928C7.55747 8.99998 7.28564 9.27181 7.28564 9.60714C7.28564 9.94247 7.55747 10.2143 7.8928 10.2143H9.10707C9.4424 10.2143 9.71423 9.94247 9.71423 9.60714C9.71423 9.27181 9.4424 8.99998 9.10707 8.99998Z" fill="#6E747A"/>-->
        <!--            <path d="M13.3571 8.99998H12.1428C11.8075 8.99998 11.5356 9.27181 11.5356 9.60714C11.5356 9.94247 11.8075 10.2143 12.1428 10.2143H13.3571C13.6924 10.2143 13.9642 9.94247 13.9642 9.60714C13.9642 9.27181 13.6924 8.99998 13.3571 8.99998Z" fill="#6E747A"/>-->
        <!--            <path d="M4.85707 11.4286H3.6428C3.30747 11.4286 3.03564 11.7004 3.03564 12.0357C3.03564 12.3711 3.30747 12.6429 3.6428 12.6429H4.85707C5.1924 12.6429 5.46423 12.3711 5.46423 12.0357C5.46423 11.7004 5.1924 11.4286 4.85707 11.4286Z" fill="#6E747A"/>-->
        <!--            <path d="M9.10707 11.4286H7.8928C7.55747 11.4286 7.28564 11.7004 7.28564 12.0357C7.28564 12.3711 7.55747 12.6429 7.8928 12.6429H9.10707C9.4424 12.6429 9.71423 12.3711 9.71423 12.0357C9.71423 11.7004 9.4424 11.4286 9.10707 11.4286Z" fill="#6E747A"/>-->
        <!--            <path d="M13.3571 11.4286H12.1428C11.8075 11.4286 11.5356 11.7004 11.5356 12.0357C11.5356 12.3711 11.8075 12.6429 12.1428 12.6429H13.3571C13.6924 12.6429 13.9642 12.3711 13.9642 12.0357C13.9642 11.7004 13.6924 11.4286 13.3571 11.4286Z" fill="#6E747A"/>-->
        <!--            <path d="M4.85707 13.8571H3.6428C3.30747 13.8571 3.03564 14.129 3.03564 14.4643C3.03564 14.7996 3.30747 15.0714 3.6428 15.0714H4.85707C5.1924 15.0714 5.46423 14.7996 5.46423 14.4643C5.46423 14.1289 5.1924 13.8571 4.85707 13.8571Z" fill="#6E747A"/>-->
        <!--            <path d="M9.10707 13.8571H7.8928C7.55747 13.8571 7.28564 14.129 7.28564 14.4643C7.28564 14.7996 7.55747 15.0715 7.8928 15.0715H9.10707C9.4424 15.0715 9.71423 14.7996 9.71423 14.4643C9.71423 14.129 9.4424 13.8571 9.10707 13.8571Z" fill="#6E747A"/>-->
        <!--            <path d="M13.3571 13.8571H12.1428C11.8075 13.8571 11.5356 14.129 11.5356 14.4643C11.5356 14.7996 11.8075 15.0715 12.1428 15.0715H13.3571C13.6924 15.0715 13.9642 14.7996 13.9642 14.4643C13.9642 14.129 13.6924 13.8571 13.3571 13.8571Z" fill="#6E747A"/>-->
        <!--          </svg>-->
        <!--        </b-btn>-->
        <b-btn
          class="d-flex align-items-center"
          variant="primary"
          :disabled="loading"
          @click="addPost"
        >
          Publish
          <b-spinner
            v-if="loading"
            class="ml-2"
            variant="white"
            small
          />
          <svg
            v-else
            class="ml-2"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.6 8.3C16.6 12.884 12.884 16.6 8.3 16.6C3.71604 16.6 0 12.884 0 8.3C0 3.71604 3.71604 0 8.3 0C12.884 0 16.6 3.71604 16.6 8.3ZM8.3 15.3C12.166 15.3 15.3 12.166 15.3 8.3C15.3 4.43401 12.166 1.3 8.3 1.3C4.43401 1.3 1.3 4.43401 1.3 8.3C1.3 12.166 4.43401 15.3 8.3 15.3Z"
              fill="white"
            />
            <path
              d="M11.8999 8.53198V7.26798H9.03592V4.14798H7.64392V7.26798H4.76392V8.53198H7.64392V11.78H9.03592V8.53198H11.8999Z"
              fill="white"
            />
          </svg>
        </b-btn>
        <!--        <b-btn variant="light" @click="cancel()">Cancel</b-btn>-->
      </div>
    </template>

    <div class="px-5">
      <b-form-group>
        <b-input
          v-model="form.title"
          placeholder="Enter The Title"
        />
      </b-form-group>

      <b-form-group>
        <b-input
          v-model="form.author"
          placeholder="Author (optional)"
        />
      </b-form-group>

      <image-upload
        class="mb-3"
        :image="form.image"
      />

      <!--      <b-form-group>-->
      <!--        <image-fit-->
      <!--            v-if="form.image.src"-->
      <!--            :customs="{}"-->
      <!--            :src="form.image.src"-->
      <!--            :size="form.image.size"-->
      <!--            height="400px"-->
      <!--            :position="form.image.position"-->
      <!--        ></image-fit>-->
      <!--      </b-form-group>-->

      <!--      <b-form-group label="Video">-->
      <!--        <b-input placeholder="Embed video share link (optional)" v-model="form.video"></b-input>-->
      <!--      </b-form-group>-->
    </div>

    <div class="p-5">
      <NewPostEditor v-model="form.content" />
    </div>
  </b-modal>
</template>

<script>
import ImageUpload from '../utils/ImageUpload'
import NewPostEditor from '../controls/NewPostEditor'
import {mapState} from 'vuex'

const form = {
  image: {
    src: ''
  },
  content: `
            <h2>
                My own blog with Estage!
            </h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aperiam dignissimos explicabo quo. Adipisci asperiores consequatur delectus, fugit nemo perferendis.
            </p>
        `,
  title: '',
  video: '',
  author : ''
}

export default {
  name: 'NewPostModal',
  components: {NewPostEditor, ImageUpload},
  data () {
    return {
      form: JSON.parse(JSON.stringify(form)),
      modal: false,
      loading: false,
      edit: false
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },

  watch: {
    modal (val) {
      if (!val) {
        this.form = JSON.parse(JSON.stringify(form))
      }
    }
  },

  mounted () {
    VEvent.listen('blog.post-edit', this.editPost)
  },

  methods: {
    editPost (post) {
      this.form.content = post.description
      this.form.title = post.title
      this.form.image = JSON.parse(post.cover)
      this.form.video = post.video
      this.form.author = post.author

      this.edit = post.id
      this.modal = true
    },
    cancel () {
      this.form = JSON.parse(JSON.stringify(form))
      this.modal = false
      this.edit = false
    },
    canPublish () {
      let state = true

      for (let f in this.form) {
        if (!state) return false

        if (typeof this.form[f] === 'object' && f !== 'video') {
          state = !!this.form[f].src
        } else if (f !== 'video' && f !== 'author') {
          state = !!this.form[f].length
        }
      }

      return state
    },
    addPost () {
      this.loading = true

      if (!this.canPublish()) {
        this.loading = false
        return this.$swal({
          icon: 'warning',
          iconColor: '#4F83E3',
          toast: true,
          position: 'top-right',
          title: 'Title, image and content fields is required.',
          showConfirmButton: false,
          timer: 3000
        })
      }

      axios[this.edit ? 'put' : 'post'](`api/projects/${this.project.id}/blog/manage${this.edit ? '/' + this.edit : ''}`, {
        'cover': JSON.stringify(this.form.image),
        'title': this.form.title,
        'description': this.form.content,
        'video': this.form.video,
        'author': this.form.author,
        'status': 'publish'
      })
        .then(({data}) => {
          this.$emit('addedPost', data)
          this.cancel()
          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Post published.',
            showConfirmButton: false,
            timer: 3000
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.blog-modal {
  .ProseMirror {
    min-height: 100vh;
  }
}
</style>
