<template>
  <div>
    <div class="d-none">
      <component
        :is="globalStyles.navbar.block.component"
        v-if="globalStyles.navbar.block"
        :section="globalStyles.navbar.block"
      />
    </div>

    <slot />
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'BlogWrapper',
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    })
  }
}
</script>