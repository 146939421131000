import {mapState} from 'vuex'

export default {
  data () {
    return {
      resizeEvent: null,
      timeout: null,
      initialized: false,
      isOutsideState: false
    }
  },

  computed: {
    unpin () {
      return this.$store.state.editor.unpin
    },
    ...mapState('menu', {
      menu: state => state.navbarMenu
    }),
    isOutside () {
      return this.isOutsideState && !this.wrapLinks
    },
    wrapLinks () {
      return this.section.options.content.menuItems.wrapLinks
    }
  },

  methods: {
    checkIfOutside () {
      this.initialized = false
      this.isOutsideState = false

      setTimeout(() => {
        let collapseRight = this.$el.querySelector('.navbar-collapse').getBoundingClientRect().right,
          esRoot = document.querySelector('.es-root')

        this.isOutsideState = collapseRight > esRoot.getBoundingClientRect().right
        this.initialized = true
      })
    }
  },

  watch: {
    unpin () {
      this.checkIfOutside()
    },
    wrapLinks () {
      this.checkIfOutside()
    },
    menu () {
      this.checkIfOutside()
    }
  },

  mounted () {
    this.resizeEvent = window.addEventListener('resize', () => {
      this.checkIfOutside()
    })
  },

  destroyed () {
    removeEventListener('resize', this.resizeEvent)
  }
}
