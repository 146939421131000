<template>
  <div :class="[section.options.content.image.customClass, {'element-hidden': !visability('image')}]">
    <highliter
      v-model="section.name"
      :disable-toolbar="section.options.content.image.disableToolbar"
      :dublicate="section"
      :dublicate-deep="parentUID"
      class="highlight-element"
      section-type="element"
      @delete="$emit('delete')"
      @dublicate="$emit('dublicate')"
      @favorite="$emit('favorite', $el)"
      @flow="handleFlow"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
      @click.stop.native="sectionSettings('image')"
    >
      <div
        :data-sid="section.sid"
        class="position-relative overflow-hidden cursor-pinter rounded"
        :class="[{'video-popup': overlayEnabled}]"
      >
        <preview-el-image-v3
          v-if="section.options.content.image.subject"
          :section="section.options.content.image.subject"
        >
          <div
            class="video-popup-overlay transition"
            :class="`--${section.sid}--video-popup-overlay`"
          />

          <div
            class="play-btn"
            style="background-color: rgb(255, 255, 255);"
          >
            <i
              aria-hidden="true"
              class="fa fa-play"
              style="color: rgb(0, 0, 0);"
            />
          </div>
        </preview-el-image-v3>
      </div>
    </highliter>
  </div>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import _ from 'lodash'

export default {
  name: 'FeaturedItems1',

  components: {Highliter},

  mixins: [SectionMixin],

  data () {
    return {
      overlay: false
    }
  },

  computed: {
    overlayEnabled () {
      return _.get(this.section.options.content.image.video.overlay, ['enable', this.resolutioner], _.get(this.section.options.content.image.video.overlay, ['enable', 'lg'], true))
    },
    ratio () {
      if (!this.section.options.content.image.image.hasOwnProperty('ratio')) {
        this.$set(this.section.options.content.image.image, 'ratio', {
          type: 'vh',
          sm: {
            h: 60,
            w: 50
          },
          lg: {
            h: 60,
            w: 50
          }
        })
      }

      return {
        height: `${this.cols(this.section.options.content.image.image.ratio.lg.h, this.section.options.content.image.image.ratio.sm.h)}${this.section.options.content.image.image.ratio.type}`,
        width: `${this.cols(this.section.options.content.image.image.ratio.lg.w, this.section.options.content.image.image.ratio.sm.w)}${this.section.options.content.image.image.ratio.type}`
      }
    }
  }
}
</script>

<style lang='scss'>
.video-popup-overlay {
  opacity: 0;
}

.video-popup {
  img {
    object-fit: cover !important;
    width: 100% !important;
    height: 100% !important;
  }

  &:hover {
    .video-popup-overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 1;
    }

    .play-btn {
      z-index: 100;
      opacity: 1;
    }
  }
}
</style>