<template>
  <div
    class="position-relative dropdown-item"
    @mouseover="openMenu"
    @mouseleave="closeMenu"
  >
    <div
      class="d-flex align-items-center py-1"
      :style="{color: isActive ? globalStyles.colors.primary : '', backgroundColor}"
    >
      {{ label }} <i class="fa fa-angle-right text-muted ml-auto" />
    </div>

    <div
      v-if="menu"
      class="hs-sub-menu w-100"
    >
      <div
        class="hs-mega-menu w-100"
        :style="{borderTopColor: globalStyles.colors.primary, }"
      >
        <ul class="list-unstyled">
          <template v-for="(item, index) in items">
            <div
              v-if="!item.children || !item.children.length"
              :key="index"
              class="dropdown-item cursor-pinter"
              @click="$store.dispatch('router/setPage', item.key)"
            >
              <div
                class="py-1"
                :style="{color: item.key === $store.state.sections.page ? globalStyles.colors.primary : ''}"
              >
                {{ item.name }}
              </div>
            </div>
            <!--            <submenu v-else :items="item.children" :label="item.name"></submenu>-->
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapState} from 'vuex'

export default {
  name: 'Submenu',

  props: {
    label: String,
    items: Array
  },

  data () {
    return {
      menu: false,
      active: false,
      timer: null,
      opened: false
    }
  },

  computed: {
    backgroundColor () {
      return _.get(this.customs, 'dropdownColor', '#fff')
    },
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    isActive () {
      return this.items.filter(item => item.key === this.$store.state.sections.page).length
    }
  },

  mounted () {
    VEvent.listen('close-all-sub-menus', () => {
      clearTimeout(this.timer)
      this.menu = false
      this.opened = false
    })
  },

  methods: {
    closeMenu () {
      this.timer = setTimeout(() => {
        this.menu = false
        this.opened = false
      }, 300)
    },
    openMenu () {
      VEvent.fire('close-all-sub-menus')
      this.menu = true

      setTimeout(() => {
        const $submenu = this.$el.querySelector('.hs-sub-menu')

        if ($submenu && !this.opened) {
          if ($submenu.getBoundingClientRect().right > window.innerWidth) {
            $submenu.style.left = '-100%'
          }

          $submenu.style.opacity = 1
        }
      })
    }
  }
}
</script>

<style lang="scss">
.resolution-lg {
  .hs-sub-menu {
    opacity: 0;
    position: absolute;
    top: -2px;
    left: 100%;
  }
}
</style>