import _ from 'lodash'
import {mapState} from 'vuex'

export default {
  props: {
    form: {
      type: Object,
      required: true
    },
    control: {
      type: Object,
      required: true
    }
  },
  computed: {
    controlsFontSize () {
      return _.get(this.form, ['controlsFontSize', this.resolutioner], _.get(this.form, ['controlsFontSize', 'lg'], 16))
    },
    labelsFontSize () {
      return _.get(this.form, ['labelsFontSize', this.resolutioner], _.get(this.form, ['labelsFontSize', 'lg'], 16))
    },
    fontFamily () {
      return _.get(this.form, ['fontFamily', this.resolutioner], _.get(this.form, ['fontFamily', 'lg'], null))
    },
    fontWeight () {
      return _.get(this.form, ['fontWeight', this.resolutioner], _.get(this.form, ['fontWeight', 'lg'], null))
    },
    labelStyle () {
      return {
        color: _.get(this.form, 'style.labelColor', '#6e747a'),
        fontSize: `${this.labelsFontSize}px`,
        fontFamily: this.fontFamily,
        fontWeight: this.fontWeight
      }
    },
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    style () {
      // const btnShape = this.form.button.shape
      // return btnShape ? `form-control-${btnShape}` : `form-control-${this.globalStyles.buttons.shape}`
    },
    controlsSize () {
      return _.get(this.form, ['controlsSize', this.resolutioner], _.get(this.form, ['controlsSize', 'lg'], this.form.controlsSize || 40))
    },
    label () {
      return `
      ${this.control.label}
      <span class="text-danger">${this.control.is_required ? '*' : ''}</span>`
    }
  },
  methods: {
    focus () {
      this.$el.querySelector('legend').classList.add('active')
    },
    blur () {
      this.$el.querySelector('legend').classList.remove('active')
    }
  }
}
