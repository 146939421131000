<template>
  <div>
    <SidbearSettingsGroup
      title="QR"
      active
    >
      <b-form-group label="Content / URL">
        <b-input v-model="section.options.content.qr.content" />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center gap-[12px]">
          <label class="pb-0">QR Color</label>

          <color-picker
            :color="section.options.content.qr.color"
            @input="val => section.options.content.qr.color = val.hex"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center gap-[12px]">
          <label class="pb-0">QR Background Color</label>

          <color-picker
            :color="section.options.content.qr.background"
            @input="val => section.options.content.qr.background = val.hex"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-range-slider
          label="Size"
          rule-name="--size"
          :sid="`.--${section.sid}--`"
          var="--size"
          :px-range="[100, 1000]"
        />
      </b-form-group>

      <sid-align
        :sid="`.--${section.sid}--`"
        rule-name="justify-content"
        label="Alignment"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Border & Radius"
    >
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}-- div`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}-- div`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}-- div`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid}-- div`"
        rule-name="border"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import QRCode from 'qrcode-svg'
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import ColorPicker from '@/components/editor/utils/ColorPicker.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'

export default {
  name: 'QRMod',
  components: {SidColor, SidSelect, SidLinkedGroup, SidAlign, SidRangeSlider, ColorPicker, SidbearSettingsGroup},
  mixins: [ModMixin],
  computed: {
    qrOptions () {
      return this.section.options.content.qr
    }
  },
  watch: {
    qrOptions: {
      deep: true,
      handler: function () {
        this.generate()
      }
    }
  },
  methods: {
    generate () {
      this.$set(this.qrOptions, 'svg', new QRCode({
        content: this.qrOptions.content,
        color: this.qrOptions.color,
        background: this.qrOptions.background,
        join: true,
        predefined: true,
        container: 'svg-viewbox'
      }).svg())
    }
  }
}
</script>