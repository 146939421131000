<template>
  <div
    class="es-dropdown position-relative"
    @mouseenter="openMenu"
    @mouseleave="closeMenu"
  >
    <div
      ref="activator"
      class="contents"
      @click="activatorClick"
    >
      <slot name="activator" />
    </div>
  
    <div
      v-show="!dropdownItem"
      ref="menu"
      class="position-absolute es-dropdown-menu z-[10000]"
    >
      <div
        class="es-dropdown-menu-content d-flex flex-column w-100"
        :class="{'animate': animate}"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import {createPopper} from '@popperjs/core'
import BorderMixin from '@/components/mixins/BorderMixin'
import _ from 'lodash'

export default {
  name: 'EsDropdown',
  mixins: [BorderMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    dropdownItem: {
      type: Boolean,
      default: false
    },
    dropdownStyle: {
      type: Object,
      default: () => {
        return {
          backgroundColor: '#fff'
        }
      }
    },
    placement: {
      type: String,
      default: 'bottom-start'
    }
  },

  data () {
    return {
      open: false,
      animate: false,
      timer: null,
      $popper: null
    }
  },
  computed: {
    offsetY () {
      return _.get(this.dropdownStyle, ['offsetY', this.resolutioner], _.get(this.dropdownStyle, ['offsetY', 'lg'], 8))
    },
    offsetX () {
      return _.get(this.dropdownStyle, ['offsetX', this.resolutioner], _.get(this.dropdownStyle, ['offsetX', 'lg'], 0))
    }
  },
  beforeDestroy () {
    if (this.$popper) {
      this.$popper.destroy
    }
  },
  methods: {
    activatorClick () {
      if (this.open) return this.closeMenu()

      this.openMenu()
    },
    closeMenu () {
      this.timer = setTimeout(() => {
        if (this.disabled) return
        this.animate = false

        this.$refs.menu.addEventListener('transitionend', this.afterTransitioned)
      }, 200)
    },
    afterTransitioned () {
      this.$refs.menu.removeEventListener('transitionend', this.afterTransitioned)

      this.open = false
      this.$refs.menu.style.display = 'none'
      if (this.$popper) {
        this.$popper.destroy
      }
    },
    openMenu () {
      if (this.disabled) return
      clearTimeout(this.timer)
      this.$refs.menu.removeEventListener('transitionend', this.afterTransitioned)
      setTimeout(() => {
        this.$refs.menu.style.display = null

        this.open = true
        this.$popper = createPopper(this.$el, this.$refs.menu, {
          placement: this.placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [this.offsetX, this.offsetY]
              }
            }
          ]
        })

        this.animate = true
      })
    }
  }
}
</script>

<style lang="scss">
.es-dropdown-menu {
  min-width: 200px;

  .es-dropdown-menu-content {
    transition: .4s;
    opacity: 0;
    transform: scale(.9);
    transform-origin: left;

    &.animate {
      transform: scale(1);
      opacity: 1;
    }
  }
}
</style>