<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="time-section highlight-element"
    section-type="element"
    @delete="remove"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings('time')"
    @add-subject="$emit('add-subject')"
  >
    <Template :section="section">
      <template v-slot:time="{color, sizing, scale}">
        <div
          class="flip-clock d-flex flex-wrap justify-content-center align-items-center"
          @click="update"
        >
          <div
            v-for="(tracker, index) in trackers"
            :key="index"
          >
            <flip-tracker
              v-if="!style.dontShow.value.includes(tracker)"
              ref="trackers"
              :color="color"
              :property="tracker"
              :scale="scale"
              :sizing="sizing"
              :styles="{...shadow}"
              :section="section"
            />
          </div>
        </div>
      </template>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import FlipTracker from './FlipTracker'
import moment from 'moment'
import TimerService from '../TimerService'

export default {
  name: 'TimeItem1',

  components: {FlipTracker, Template, Highliter},

  mixins: [SectionMixin, TimerService],

  data: () => ({
    date: moment().format('YYYY-MM-DD HH:mm:ss'),
    time: {},
    i: 0,
    trackers: ['Days', 'Hours', 'Minutes', 'Seconds']
  }),

  computed: {
    shadow () {
      const shadow = this.section.options.content.time.shadowOptions
      const color = this.section.options.content.time.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    }
  },

  watch: {
    date: function (newVal) {
      this.setCountdown(newVal)
    }
  },

  beforeDestroy () {
    if (window['cancelAnimationFrame']) {
      cancelAnimationFrame(this.frame)
    }
  },

  mounted () {
    if (window['requestAnimationFrame']) {
      this.setCountdown(this.date)
      this.callback = this.callback || function () {
      }
      this.update()
    }

    VEvent.listen('timer-update', () => {
      setTimeout(() => {
        this.setCountdown()
      })
    })
  },

  methods: {
    callback () {

    },
    setCountdown () {
      this.countdown = moment(this.timeZoneTransform)
    },

    update () {
      if (this.time.Total <= 0) {
        this.restart()
      }
      this.frame = requestAnimationFrame(this.update.bind(this))
      if (this.i++ % 10) {
        return
      }
      var t = moment(new Date())
      if (this.countdown) {
        t = this.countdown.diff(t)

        this.time.Days = Math.floor(t / (1000 * 60 * 60 * 24))
        this.time.Hours = Math.floor((t / (1000 * 60 * 60)) % 24)
        this.time.Minutes = Math.floor((t / 1000 / 60) % 60)
        this.time.Seconds = Math.floor((t / 1000) % 60)
      } else {
        this.time.Days = undefined
        this.time.Hours = t.hours() % 13
        this.time.Minutes = t.minutes()
        this.time.Seconds = t.seconds()
      }

      this.time.Total = t
      if (this.$refs.trackers) {
        this.$refs.trackers.forEach(tracker => {
          tracker.time(this.time)
        })
      }
      return this.time
    }
  }
}
</script>
