<template>
  <settings-page-card
    title="Recaptcha Settings"
  >
    <div class="px-4 max-w-[900px] mb-[48px]">
      <p class="text-dark font-size-16">
        Protect your site effortlessly with
        <a
          href="https://www.google.com/recaptcha/about/"
          target="_blank"
        >
          reCAPTCHA v3
        </a>!
        It works silently in the background, analyzing behavior to block bots while ensuring a smooth experience for real users.
      </p>
    </div>
    <b-form @submit.prevent="onSubmit">
      <div class="px-4">
        <div class="max-w-[600px]">
          <b-form-group>
            <slot name="label">
              <label class="flex items-center gap-x-[30px] mb-0">
                <h6 class="font-weight-sami-bold text-dark my-0">
                  Enable Recaptcha
                </h6>
                <b-checkbox
                  v-model="captcha_enable"
                  class="my-0 translate-y-[-2px]"
                  size="lg"
                  switch
                />
              </label>
            </slot>
          </b-form-group>
          <b-form-group>
            <slot name="label">
              <h6 class="font-weight-sami-bold text-dark">
                Public Key
              </h6>
            </slot>
            <b-input
              v-model="captcha_p_key"
              v-validate="'required'"
              name="captcha_p_key"
              :state="errors.has('captcha_p_key') ? false : null"
            />
          </b-form-group>
          <b-form-group>
            <slot name="label">
              <h6 class="font-weight-sami-bold text-dark">
                Private Key
              </h6>
            </slot>
            <b-input
              v-model="captcha_s_key"
              v-validate="'required'"
              name="captcha_s_key"
              :state="errors.has('captcha_s_key') ? false : null"
            />
          </b-form-group>
        </div>


        <b-btn
          variant="primary"
          type="submit"
        >
          Save Changes
        </b-btn>
      </div>
    </b-form>
  </settings-page-card>
</template>

<script>
import {mapState} from 'vuex'
import SettingsPageCard from '../../layouts/dashboard/SettingsPageCard'

export default {
  name: 'RecaptchaSettings',

  components: { SettingsPageCard},

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    captcha_enable: {
      get () {
        return this.project.captcha_enable
      },
      set (val) {
        this.$set(this.project,'captcha_enable',val)
      }
    },
    captcha_p_key: {
      get () {
        return this.project.captcha_p_key
      },
      set (val) {
        this.$set(this.project,'captcha_p_key',val)
      }
    },
    captcha_s_key: {
      get () {
        return this.project.captcha_s_key
      },
      set (val) {
        this.$set(this.project,'captcha_s_key',val)
      }
    }
  },

  methods: {
    onSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          VEvent.fire('loader', true)
          this.$store.dispatch('projects/save', {}, { root: true })
            .catch(errors => {
              const arrErrors = Object.keys(errors.response.data.errors),
                errorMsg = arrErrors.length ? errors.response.data.errors[Object.keys(errors.response.data.errors)[0]] : errors.response.data.message

              this.$swal({
                icon: 'error',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: errorMsg,
                showConfirmButton: false,
                timer: 3000
              })
            })
            .finally(() => {
              VEvent.fire('loader', false)
            })
        }
      })
    }
  }
}
</script>
