<template>
  <div
    :style="{...fullPaddingSubject(section.options.content.gridCollapse, 'padding', [20,20,20,20]), ...fullMarginSubject(section.options.content.gridCollapse)}"
  >
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import _ from 'lodash'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin, BorderMixin],
  computed: {
    margin () {
      if (!this.section.options.content.gridCollapse.margin || !this.section.options.content.gridCollapse.margin[this.resolutioner]) return {}

      return {
        marginTop: this.section.options.content.gridCollapse.margin[this.resolution].top + 'px',
        marginBottom: this.section.options.content.gridCollapse.margin[this.resolution].bottom + 'px'
      }
    },
    padding () {
      return {
        paddingTop: `${_.get(this.section.options.content.gridCollapse, ['padding', this.resolutioner, 'top'], 0)}px`,
        paddingBottom: `${_.get(this.section.options.content.gridCollapse, ['padding', this.resolutioner, 'bottom'], 0)}px`,
        paddingLeft: `${_.get(this.section.options.content.gridCollapse, ['padding', this.resolutioner, 'x'], 0)}px`,
        paddingRight: `${_.get(this.section.options.content.gridCollapse, ['padding', this.resolutioner, 'x'], 0)}px`
      }
    }
  }
}
</script>


<style lang="scss">
.el-headline {
  p {
    margin-bottom: 0 !important;
  }
}
</style>
