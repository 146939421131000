<template>
  <div>
    <SidbearSettingsGroup
      title="Compare Slider"
      active
    >
      <b-form-group class="mb-0">
        <b-checkbox v-model="section.options.content.compare.vertical">
          Vertical
        </b-checkbox>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Before Image"
    >
      <sid-upload-image
        :sid="`.--${section.sid}-- .after-image`"
        title=""
        rule-name="content"
      />

      <sid-width-and-height
        :sid="`.--${section.sid}-- .after-image`"
      />

      <sid-background-filters
        :sid="`.--${section.sid}-- .after-image`"
        rule-name="filter"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="After Image">
      <sid-upload-image
        :sid="`.--${section.sid}-- .before-image`"
        title=""
        rule-name="content"
      />

      <sid-width-and-height
        :sid="`.--${section.sid}-- .before-image`"
      />

      <sid-background-filters
        :sid="`.--${section.sid}-- .before-image`"
        rule-name="filter"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Handler Button">
      <b-form-group>
        <sid-checkbox
          unchecked-value="flex"
          value="none"
          rule-name="display"
          :sid="`.--${section.sid}-- .handler-button`"
        >
          Hide Button
        </sid-checkbox>
      </b-form-group>

      <b-form-group>
        <icon-select v-model="section.options.content.compare.handleIcon" />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="mb-0">Icon Color</label>
          <sid-color
            rule-name="color"
            :sid="`.--${section.sid}-- .handler-button .handler-icon`"
          />
        </div>
      </b-form-group>

      <sid-range-slider
        label="Width"
        rule-name="width"
        :sid="`.--${section.sid}-- .handler-button`"
      />

      <sid-range-slider
        label="Height"
        rule-name="height"
        :sid="`.--${section.sid}-- .handler-button`"
      />

      <sid-background
        :sid="`.--${section.sid}-- .handler-button`"
      />

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}-- .handler-button`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}-- .handler-button`"
            rule-name="border-color"
            allow-reset
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Link Border Radius"
        :sid="`.--${section.sid}-- .handler-button`"
        rule-name="border"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Handler Line">
      <b-form-group>
        <sid-range-slider
          label="Width"
          rule-name="--handler-size"
          :sid="`.--${section.sid}--`"
        />
      </b-form-group>

      <sid-background
        :sid="`.--${section.sid}-- .handler`"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid}--`"
        rule-name="border"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup
      title="Margins"
    >
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import ModMixin from '@/components/mixins/ModMixin'
import SidUploadImage
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidUploadImage.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import IconSelect from '@/components/editor/components/IconSelect.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox.vue'
import SidWidthAndHeight from '@/components/editor/components/sid-controls/sid-control-pressets/SidWidthAndHeight.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidBackgroundFilters
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundFilters.vue'

export default {
  name: 'CompareMod',
  components: {
    SidBackgroundFilters,
    SidSelect,
    SidWidthAndHeight,
    SidCheckbox,
    SidBackground, IconSelect, SidColor, SidLinkedGroup, SidRangeSlider, SidUploadImage, SidbearSettingsGroup},
  mixins: [ModMixin]
}
</script>