import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Product Cover'
export const group = ''

export default class ElProductCover extends SectionProvider {
  constructor (options = {}) {
    super('ElProductCover', options)

    this.name = name
    this.group = group
  }
}