<template>
  <div :class="container">
    <div
      class="image-background"
      :style="{...backgroundImage}"
    />
    <div
      class="list-2"
      :style="{...backgroundColor, ...shadow, ...padding}"
    >
      <b-list-group :style="{...color}">
        <slot name="list" />
      </b-list-group>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'ListItem1Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.list-2 {
  .list-group {
    .list-group-item {
      position: relative;
      font-size: 18px;

      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
