import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Small'
export const group = 'empty-blocks'
export const defaultOptions = {
  content: {
    items: {
      type: null,
      children: []
    }
  },
  backgroundImage: {
    src: ''
  },
  backgroundVideo: {
    enabled: false,
    url: '',
    loop: true,
    ratio: 1
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@builder/assets/svg/previews/blanks/main-3.svg')
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('BlankV2', options)

    this.name = name
    this.css = [['.--l5g51d5a--0bhquymSU--container { max-width: 40vw; }'], [], []]
    this.sid = 'l5g51d5a--0bhquymSU'
    this.sids = ['l5g51d5a--0bhquymSU']
    this.group = group

    this.defaultOptions = defaultOptions
  }
}
