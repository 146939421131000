<template>
  <div>
    <b-form-group>
      <background-type
        :config="config"
        :label="typeLabel"
      />
 
      <b-form-group
        v-if="!config.backgroundType"
        class="mb-0"
      >
        <div class="flex items-center gap-[13px] mb-[9px]">
          <color-picker
            :color="backgroundColor"
            @input="event => backgroundColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />

          <label class="mb-0 pb-0">{{ config.label || 'Color' }}</label>
        </div>

        <slot />
      </b-form-group>

      <gradient-background
        v-else
        :config="config"
      />

      <span
        class="mt-2 block text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
        @click="reset"
      >
        Reset color
      </span>
    </b-form-group>
  </div>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'
import ColorPicker from '../utils/ColorPicker'
import {mapState} from 'vuex'
import BackgroundType from './BackgroundType'
import GradientBackground from './GradientBackground'
import _ from 'lodash'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  name: 'Background',
  components: {GradientBackground, BackgroundType, ColorPicker},
  mixins: [ControlMixin, ResolutionMixin],

  props: {
    typeLabel: {
      type: String,
      default: 'Background Type'
    }
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    backgroundColor: {
      get () {
        return _.get(this.config, ['background', this.resolutioner], _.get(this.config, ['background', 'lg'], this.config.background || ''))
      },
      set (val) {
        const _background = typeof this.config.background === 'object' ? this.config.background : {}
        this.$set(this.config, 'background', {..._.set(_background, [this.resolutioner], val)})
      }
    },
    gradient () {
      if (this.config.backgroundType === 'gradient' && this.config.hasOwnProperty('backgroundGradient')) {
        const values = this.config.backgroundGradient.map(gradient => {
          return `${gradient.color} ${gradient.position}%`
        })
        return `linear-gradient(${this.config.gradientDirection || 90}deg,${values})`
      }

      return null
    }
  },

  methods: {
    reset () {
      this.$set(this.config, 'backgroundType', null)
      this.$set(this.config, 'background', 'rgba(255, 255, 255 ,0)')
    }
  }
}
</script>
