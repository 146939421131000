<template>
  <div class="d-flex position-relative">
    <treeselect
      v-model="folder"
      :options="folders"
      :searchable="false"
      :show-count="true"
      no-options-text="No folders created"
      placeholder="All Sites"
      readonly
    >
      <label
        slot="value-label"
        slot-scope="{ node}"
      >
        {{ node.raw.name }}
      </label>

      <label
        slot="option-label"
        slot-scope="{ node, shouldShowCount, count, labelClassName }"
        :class="labelClassName"
      >
        {{ node.raw.name }}
      </label>
    </treeselect>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'

export default {
  name: 'FoldersTreeSelect',
  components: {Treeselect},
  props: {
    value: {
      required: true
    },
    ignoreId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      folders: []
    }
  },
  computed: {
    folder: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    axios.get(`api/projects/categories/tree?ignoreId=${this.ignoreId}`)
      .then(({data}) => {
        this.folders = [{id: 0, name: 'All Sites'}, ...data.data]
      })
  }
}
</script>