<template>
  <div>
    <SidbearSettingsGroup
      title="Live Chat Settings"
      active
    >
      <!--      <b-form-group v-if="subscriptions.length">-->
      <!--        <div class="flex items-center gap-[10px]">-->
      <!--          <b-checkbox-->
      <!--            v-model="isActivated"-->
      <!--            size="lg"-->
      <!--            class="mt-0"-->
      <!--            switch-->
      <!--          />-->

      <!--          <label class="mb-0 mt-1 pb-0">{{ isActivated ? 'Activated' : 'Deactivated' }}</label>-->
      <!--        </div>-->
      <!--      </b-form-group>-->

      <b-form-group v-if="userRole.includes('support') || userRole.includes('superadministrator')">
        <b-btn @click="authorize">
          Authorize
        </b-btn>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="mb-0 pb-0">Status</label>

          <div class="w-[129px]">
            <v-select
              v-model="section.options.content.chat.status"
              :options="[{label: 'Open Chat', value: '1'}, {label: 'Close Chat', value: '0'}]"
              :searchable="false"
              :reduce="val => val.value"
              @option:selected="onStatusChange"
            />
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Icon Color</label>

          <sid-color
            rule-name="stroke"
            var="--icon-color"
            :sid="className(' .info .info-title svg path')"
          />
        </div>
      </b-form-group>
      
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            rule-name="color"
            var="--title-color"
            :sid="className(' .info .info-title')"
          />
        </div>
      </b-form-group>

      <sid-background :sid="className()" />

      <sid-range-slider
        rule-name="gap"
        label="Gap"
        :sid="className()"
        :dimensions="[]"
        default-dimension="px"
      />

      <!--      <b-form-group>-->
      <!--        <b-checkbox v-model="section.options.content.chat.fixed">-->
      <!--          Fixed layout-->
      <!--        </b-checkbox>-->
      <!--      </b-form-group>-->

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="className()"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="className()"
          label="Border Width"
          rule-name="border"
          css-var="--border-size"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="className()"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="className()"
        rule-name="border"
        :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
        :angles="[
          {
            text: '',
            value: 'top-left-radius',
            cssVar: '--border-top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius',
            cssVar: '--border-top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius',
            cssVar: '--border-bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius',
            cssVar: '--border-bottom-right-radius'
          }
        ]"
      />

      <b-form-group
        label="Shadow Color"
      >
        <sid-box-shadow
          :sid="className()"
          rule-name="box-shadow"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Online Counter">
      <sid-font :sid="className(' .online-status')" />

      <b-form-group>
        <sid-range-slider
          label="Font Size"
          :sid="className(' .online-status')"
          rule-name="font-size"
        />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            rule-name="color"
            var="--title-color"
            :sid="className(' .online-status')"
          />
        </div>
      </b-form-group>

      <sid-background :sid="className(' .online-status')" />

      <sid-linked-group
        label="Padding"
        :sid="className(' .online-status')"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Chat Box">
      <b-form-group>
        <sid-range-slider
          rule-name="height"
          :sid="className()"
          default-dimension="px"
          :dimensions="['px', 'vh', '%']"
          label="Height"
          var="--height"
          reset-value="300px"
          measurement="height"
        />
      </b-form-group>

      <sid-background :sid="className(' .chat-box')" />

      <sid-linked-group
        label="Padding"
        :sid="className(' .chat-box')"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Username">
      <sid-font :sid="className(' .chat-box .message .message-user-name')" />

      <b-form-group>
        <sid-range-slider
          label="Font Size"
          :sid="className(' .chat-box .message .message-user-name')"
          rule-name="font-size"
        />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            rule-name="color"
            var="--title-color"
            :sid="className(' .chat-box .message .message-user-name')"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Text Messages">
      <rule-tabs :tabs="['Normal', 'Replied']">
        <template #Normal>
          <sid-font :sid="className(' .chat-box .message .msg')" />

          <sid-background :sid="className(' .chat-box .message')" />

          <b-form-group>
            <sid-range-slider
              label="Font Size"
              :sid="className(' .chat-box .message .msg')"
              rule-name="font-size"
            />
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                rule-name="color"
                var="--title-color"
                :sid="className(' .chat-box .message .msg')"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="className(' .chat-box .message')"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="className(' .chat-box .message')"
              label="Border Width"
              rule-name="border"
              css-var="--border-size"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="className(' .chat-box .message')"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              label="Radius"
              :sid="className(' .chat-box .message')"
              rule-name="border"
              :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius',
                  cssVar: '--border-top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius',
                  cssVar: '--border-top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius',
                  cssVar: '--border-bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius',
                  cssVar: '--border-bottom-right-radius'
                }
              ]"
            />
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              label="Margin"
              :sid="className(' .chat-box .message')"
              rule-name="margin"
              :min="-2000"
              :max="2000"
              class="mb-0"
              :angles="[
                {
                  text: '',
                  value: 'top'
                },
                {
                  text: '',
                  value: 'right'
                },
                {
                  text: '',
                  value: 'bottom'
                },
                {
                  text: '',
                  value: 'left'
                }
              ]"
            />
          </b-form-group>

          <sid-linked-group
            label="Padding"
            :sid="className(' .chat-box .message')"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>

        <template #Replied>
          <sid-font :sid="className(' .chat-box .message.replied .msg')" />

          <sid-background :sid="className(' .chat-box .message.replied')" />

          <b-form-group>
            <sid-range-slider
              label="Font Size"
              :sid="className(' .chat-box .message.replied .msg')"
              rule-name="font-size"
            />
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                rule-name="color"
                var="--title-color"
                :sid="className(' .chat-box .message.replied .msg')"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="className(' .chat-box .message.replied')"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="className(' .chat-box .message.replied')"
              label="Border Width"
              rule-name="border"
              css-var="--border-size"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="className(' .chat-box .message.replied')"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              label="Radius"
              :sid="className(' .chat-box .message.replied')"
              rule-name="border"
              :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius',
                  cssVar: '--border-top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius',
                  cssVar: '--border-top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius',
                  cssVar: '--border-bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius',
                  cssVar: '--border-bottom-right-radius'
                }
              ]"
            />
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              label="Margin"
              :sid="className(' .chat-box .message.replied')"
              rule-name="margin"
              :min="-2000"
              :max="2000"
              class="mb-0"
              :angles="[
                {
                  text: '',
                  value: 'top'
                },
                {
                  text: '',
                  value: 'right'
                },
                {
                  text: '',
                  value: 'bottom'
                },
                {
                  text: '',
                  value: 'left'
                }
              ]"
            />
          </b-form-group>

          <sid-linked-group
            label="Padding"
            :sid="className(' .chat-box .message.replied')"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Composer">
      <rule-tabs :tabs="['Normal', 'Placeholder']">
        <template #Normal>
          <sid-font :sid="className(' .composer-wrapper .composer')" />

          <b-form-group>
            <sid-range-slider
              label="Font Size"
              :sid="className(' .composer-wrapper .composer')"
              rule-name="font-size"
            />
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                rule-name="color"
                var="--title-color"
                :sid="className(' .composer-wrapper .composer')"
              />
            </div>
          </b-form-group>

          <sid-background :sid="className(' .composer-wrapper .composer')" />

          <sid-background
            label="Wrapper"
            :sid="className(' .composer-wrapper')"
          />

          <b-form-group>
            <sid-range-slider
              rule-name="height"
              :sid="className(' .composer-wrapper .composer')"
              default-dimension="px"
              :dimensions="['px', 'vh', '%']"
              label="Height"
              var="--height"
              reset-value="300px"
              measurement="height"
            />
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="className(' .composer-wrapper .composer')"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="className(' .composer-wrapper .composer')"
              label="Border Width"
              rule-name="border"
              css-var="--border-size"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="className(' .composer-wrapper .composer')"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="className(' .composer-wrapper .composer')"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />

          <sid-linked-group
            label="Padding"
            :sid="className(' .composer-wrapper .composer')"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>

        <template #Placeholder>
          <sid-font :sid="className(' .online-status::placeholder')" />

          <b-form-group>
            <sid-range-slider
              label="Font Size"
              :sid="className(' .composer-wrapper .composer::placeholder')"
              rule-name="font-size"
            />
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                rule-name="color"
                var="--title-color"
                :sid="className(' .composer-wrapper .composer::placeholder')"
              />
            </div>
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Time & Date">
      <b-checkbox
        v-model="section.options.content.chat.showTimestamp"
        switch
      >
        Show Timestamp
      </b-checkbox>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Live Emojis">
      <b-checkbox
        v-model="section.options.content.chat.showFlyEmojis"
        switch
      >
        Enable
      </b-checkbox>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Live Chat Sticky Message">
      <tiptap-v2 v-model="section.options.content.pinnedMessage" />

      <b-form-group>
        <div class="flex gap-[6px]">
          <button
            class="hover:opacity-60 flex justify-center font-bold text-dark border items-center text-[13px] rounded h-[29px] !px-[16px] py-0"
            @click="setPinnedMessage"
          >
            Update Message
          </button>

          <button
            class="hover:opacity-60 flex justify-center font-bold text-dark border bg-[#F4F4F4] items-center text-[13px] rounded h-[29px] !px-[16px] py-0"
            @click="removePinnedMessage"
          >
            Remove
          </button>
        </div>
      </b-form-group>

      <sid-font :sid="className(' .pinned-message')" />

      <b-form-group>
        <sid-range-slider
          label="Font Size"
          :sid="className(' .pinned-message')"
          rule-name="font-size"
        />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            rule-name="color"
            var="--title-color"
            :sid="className(' .pinned-message')"
          />
        </div>
      </b-form-group>

      <sid-background :sid="className(' .pinned-message')" />

      <sid-linked-group
        label="Padding"
        :sid="className(' .pinned-message')"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="User Ban Message">
      <b-form-group>
        <b-textarea v-model="section.options.content.chat.banMessage" />
      </b-form-group>

      <sid-font :sid="className(' .ban-message')" />

      <b-form-group>
        <sid-range-slider
          label="Font Size"
          :sid="className(' .ban-message')"
          rule-name="font-size"
        />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            rule-name="color"
            var="--title-color"
            :sid="className(' .ban-message')"
          />
        </div>
      </b-form-group>

      <sid-background :sid="className(' .ban-message')" />

      <sid-linked-group
        label="Padding"
        :sid="className(' .ban-message')"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <!--    <SidbearSettingsGroup title="Admin">-->
    <!--      <p>Create a new admin account.</p>-->
    <!--      <b-form @submit.prevent="addAccount">-->
    <!--        <b-form-group label="Email">-->
    <!--          <b-input-->
    <!--            v-model="email"-->
    <!--            type="email"-->
    <!--          />-->
    <!--        </b-form-group>-->

    <!--        <b-form-group>-->
    <!--          <b-input-->
    <!--            v-model="password"-->
    <!--            type="password"-->
    <!--          />-->
    <!--        </b-form-group>-->
    <!--      </b-form>-->
    <!--    </SidbearSettingsGroup>-->
  </div>
</template>

<script>
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import ModMixin from '@/components/mixins/ModMixin'
import {mapGetters, mapState} from 'vuex'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import TiptapV2 from '@/components/builder/utils/TiptapV2.vue'

export default {
  name: 'ChatMod',
  components: {
    TiptapV2,
    RuleTabs,
    SidBoxShadow,
    SidSelect,
    SidFont, SidLinkedGroup, SidColor, SidRangeSlider, SidBackground, SidbearSettingsGroup},
  mixins: [ModMixin],
  data () {
    return {
      email: '',
      password: '',
      subscriptions: [],
      activated: false,
      allow: false
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),
    isActivated: {
      get () {
        return this.activated
      },
      set (val) {
        if (val) {
          axios.post('/api/estage-modules/activate-subscription', {
            subscription_id: this.subscriptions[0].id,
            project_id: this.project.id
          })
            .then(async () => {
              this.activated = true
              await axios.get(`http://localhost:3000/api/live-chat/update-state/${this.section.options.content.chat.roomID}?hash=${this.user.hash}&activated=1`)
            })
        }
        else {
          axios.delete(`/api/estage-modules/deactivate-subscription?project_id=${this.project.id}&subscription_id=${this.subscriptions[0].id}`)
            .then(async () => {
              this.activated = false
              await axios.get(`https://estage.site/api/live-chat/update-state/${this.section.options.content.chat.roomID}?hash=${this.user.hash}&activated=0`)
            })
        }
      }
    }
  },
  created () {
    axios.get(`/api/estage-modules/check-subscription?project_id=${this.project.id}&user_id=${this.user.id}`)
      .then(({data}) => {
        console.log(data)
        if (!data.data.has_available_subscriptions) {
          axios.get('/api/estage-modules/subscriptions/CHAT?only_active=1')
            .then(({data}) => {
              this.subscriptions = data.data.has_available_subscriptions
            })
        } else {
          axios.get(`/api/estage-modules/subscriptions/CHAT?project_id=${this.project.id}`)
            .then(({data}) => {
              this.subscriptions = data.data.has_available_subscriptions
            })
        }

        this.activated = data.data.has_available_subscriptions > 0
      })
  },
  methods: {
    authorize () {
      window.open(`https://${this.project.domain ? this.project.domain : this.project.subdomain + '.estage.site'}?e_hash=${this.user.hash}`)
    },
    onStatusChange () {
      axios.get(`https://estage.site/api/live-chat/update-state/${this.section.options.content.chat.roomID}?hash=${this.user.hash}&live=${this.section.options.content.chat.status}`)

      this.$swal({
        icon: 'success',
        iconColor: '#4F83E3',
        toast: true,
        position: 'top-right',
        title: 'The chat status is changed!.',
        showConfirmButton: false,
        timer: 3000
      })
    },
    setPinnedMessage () {
      axios.get(`https://estage.site/api/live-chat/update-state/${this.section.options.content.chat.roomID}?hash=${this.user.hash}&message=${this.section.options.content.pinnedMessage}`)

      this.$swal({
        icon: 'success',
        iconColor: '#4F83E3',
        toast: true,
        position: 'top-right',
        title: 'The message is pinned!.',
        showConfirmButton: false,
        timer: 3000
      })
    },

    removePinnedMessage () {
      this.section.options.content.pinnedMessage = ''
      axios.get(`https://estage.site/api/live-chat/update-state/${this.section.options.content.chat.roomID}?hash=${this.user.hash}&message=${this.section.options.content.pinnedMessage}`)

      this.$swal({
        icon: 'success',
        iconColor: '#4F83E3',
        toast: true,
        position: 'top-right',
        title: 'The message has been removed!.',
        showConfirmButton: false,
        timer: 3000
      })
    },

    className (className = '') {
      return `.--${this.section.sid}--chat${className}`
    }
  }
}
</script>