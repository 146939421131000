<template>
  <highliter
    class="el-dynamic"
    value="Post Categories"
    hide-add
    hide-right
  >
    <div class="post-categories">
      Sport
    </div>
  </highliter>
</template>

<script>
import Highliter from '@/components/builder/utils/Highliter.vue'

export default {
  name: 'PostCategories',
  components: {Highliter}
}
</script>

<style>
.post-categories {
    padding: 0 20px;
}
</style>