<template>
  <div :style="{...backgroundColor}">
    <div
      class="image-background"
      :style="{...backgroundImage}"
    />
    <div
      class="action-item-3"
      :style="{...padding}"
      :class="container"
    >
      <slot name="elements" />

      <div class="d-flex justify-content-center">
        <b-card
          class="border-0"
          :style="{...shadow}"
        >
          <slot name="price" />
          <div class="w-100 text-center">
            <slot name="elements2" />
          </div>
          <div class="my-4" />
          <div class="d-flex justify-content-center">
            <slot name="icons" />
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'FeaturedItems3Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.action-item-3 {
  .card {
    border-radius: 2px;
    padding: 10px;
  }

  p {
    margin-bottom: 0;
  }
}
</style>
