import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState('editor', {
      resolution: state => state.resolution,
      tablet: state => state.tablet
    }),
    resolutioner () {
      return this.tablet ? 'md' : this.resolution
    }
  }
}
