<template>
  <span
    v-show="show"
    class="flip-clock__piece"
  >
    <span
      :style="{...styles, paddingBottom: `${sizing.padding * scale}rem`}"
      class="flip-clock__card flip-card mb-2"
    >
      <b
        :style="{fontFamily: section.options.content.time.dialFontFamily, fontSize: `${sizing.counter * scale}rem`}"
        class="flip-card__top"
      >{{ current | zerofill }}</b>
      <b
        :data-value="current | zerofill"
        :style="{fontFamily: section.options.content.time.dialFontFamily, fontSize: `${sizing.counter * scale}rem`}"
        class="flip-card__bottom"
      />
      <b
        :data-value="previous | zerofill"
        :style="{fontFamily: section.options.content.time.dialFontFamily, fontSize: `${sizing.counter * scale}rem`}"
        class="flip-card__back"
      />
      <b
        :data-value="previous | zerofill"
        :style="{fontFamily: section.options.content.time.dialFontFamily, fontSize: `${sizing.counter * scale}rem`}"
        class="flip-card__back-bottom"
      />
    </span>
    <span
      :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
      class="flip-clock__slot"
    >{{ property }}</span>
  </span>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'FlipTracker',
  filters: {
    zerofill (value) {
      return (value < 10 && value > -1 ? '0' : '') + value
    }
  },
  props: ['property', 'color', 'sizing', 'scale', 'styles', 'section'],
  data: () => ({
    current: 0,
    previous: 0,
    show: false
  }),
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    dialFontFamily () {
      return this.globalStyles.font.body.fontFamily
    }
  },
  methods: {
    time (newValue) {
      if (newValue[this.property] === undefined) {
        this.show = false
        return
      }

      var val = newValue[this.property]
      this.show = true

      val = val < 0 ? 0 : val

      if (val !== this.current) {
        this.previous = this.current
        this.current = val

        this.$el.classList.remove('flip')
        void this.$el.offsetWidth
        this.$el.classList.add('flip')
      }
    }
  }
}
</script>
