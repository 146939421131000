<template>
  <div class="mb-4">
    <label class="mb-2">{{ config.label }}</label>

    <b-row>
      <b-col
        v-for="(item, index) in config.items"
        :key="index"
        cols="6"
      >
        <b-form-checkbox
          v-model="config.value"
          :value="item.value"
        >
          {{ item.label }}
        </b-form-checkbox>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'

export default {
  name: 'ChecklistCustomsV2',

  mixins: [ControlMixin]
}
</script>
