<template>
  <b-modal
    v-model="modal"
    body-class="py-5"
    centered
    class="position-relative"
    content-class="overflow-visible delete-pages-folder-modal"
    hide-footer
    hide-header
    lazy
    size="lg"
  >
    <h3 class="text-center font-weight-bold mb-4 px-0 h4">
      Are You Sure?
    </h3>

    <b-row v-if="category">
      <b-col
        cols="8"
        offset="2"
      >
        <p class="text-center">
          The <b>{{ category.name }}</b> folder will be removed. Folder pages will be moved to the root folder.
        </p>

        <div class="d-flex align-items-center justify-content-center">
          <b-btn
            :disabled="loading"  
            class="btn-wide mr-2"
            variant="danger"
            @click="onsubmit"
          >
            <b-spinner
              v-if="loading"
              class="mr-2"
              small
              variant="light"
            />

            Yes
          </b-btn>
          <b-btn
            :disabled="loading"  
            class="btn-wide"
            @click="modal = false"
          >
            No
          </b-btn>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'PageSelectMenuDeleteFolder',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    category: {
      required: true
    }
  },
  data () {
    return {
      modal: false,
      loading: false
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  watch: {
    modal (val) {
      this.$emit('input', val)
    },
    value (val) {
      this.modal = val
    }
  },
  methods: {
    onsubmit () {
      if (!this.category) return

      this.loading = true

      axios.delete(`api/projects/${this.project.id}/pages/categories/${this.category.id}`)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Success!',
            text: 'The folder has been deleted',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Server error',
            text: 'Please try again later or let us know about your problem.',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          this.modal = false
          this.loading = false
          this.$emit('deleted')
        })
    }
  }
}
</script>