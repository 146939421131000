<template>
  <b-row
    class="folder-list-item align-items-center"
    @click="$store.dispatch('dashboard/loadFolder', folder.id)"
  >
    <b-col cols="1">
      <svg
        width="38"
        height="30"
        viewBox="0 0 38 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.5686 30H0.99903C0.341583 30 -0.136503 29.3758 0.0351996 28.7406L6.52414 10.9506C6.64158 10.5153 7.03669 10.2128 7.48797 10.2128H36.0576C36.715 10.2128 37.0214 10.8409 37.0214 11.4721L30.5324 29.2621C30.415 29.6975 30.0199 30 29.5686 30Z"
          fill="#94B8FC"
        />
        <path
          d="M32.086 4.46809H15.9575L12.766 0H1.10559C0.49538 0 6.10352e-05 0.494681 6.10352e-05 1.10553V28.3787C0.0441036 28.38 0.0881462 28.3826 0.130912 28.3851L6.52347 10.9506C6.64091 10.5147 7.03602 10.2128 7.4873 10.2128H33.1916V5.57362C33.1916 4.96277 32.6962 4.46809 32.086 4.46809Z"
          fill="#4F83E3"
        />
      </svg>
    </b-col>

    <b-col>
      <h5
        class="item-name text-truncate"
        v-text="folder.name"
      />
      <h6 class="folder-sites-count mb-0">
        <span v-text="folder.countProjects" /> Sites
      </h6>
    </b-col>

    <b-col
      class="date-stamp"
      cols="2"
    >
      {{ folder.updated_at | moment('MMM DD, YYYY') }}
    </b-col>

    <b-col
      class="date-stamp"
      cols="2"
    >
      {{ folder.created_at | moment('MMM DD, YYYY') }}
    </b-col>

    <b-col cols="1">
      <b-dropdown
        variant="folder-dropdown"
        menu-class="project-menu py-0"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <svg
            width="24"
            height="5"
            viewBox="0 0 24 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="2.5"
              cy="2.5"
              r="2.5"
              fill="#4F83E3"
            />
            <circle
              cx="12.0714"
              cy="2.5"
              r="2.5"
              fill="#4F83E3"
            />
            <circle
              cx="21.3572"
              cy="2.5"
              r="2.5"
              fill="#4F83E3"
            />
          </svg>
        </template>

        <move-folder-modal :folder="folder" />
        <edit-folder-modal :folder="folder" />
        <delete-folder-modal :folder="folder" />
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import EditFolderModal from '../../templates/EditFolderModal'
import DeleteFolderModal from '../../templates/DeleteFolderModal'
import MoveFolderModal from '../../templates/MoveFolderModal'
export default {
  name: 'FolderListItem',
  components: {MoveFolderModal, DeleteFolderModal, EditFolderModal},
  props: {
    folder: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.folder-list-item {
  .folder-sites-count {
    font-size: 12px;
    color: #9FA9BA;
  }
}
</style>