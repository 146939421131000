import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Button'
export const group = 'Basic'

export const defaultOptions = {
  content: {
    'button': {
      'pressetID': 0,
      'display': true,
      'type': 'button-mod-v2',
      'text': 'Click Me',
      'buttonType': 'button',
      realTimeVisibility: true,
      icon: {
        lottie: {
          jsonPath: '',
          loop: true,
          autoplay: true
        }
      },
      hoverAnimation: {},
      'animations': {
        'animation': null,
        'once': true,
        'offset': 200,
        'delay': 50
      }
    }
  },
  customize: null
}

export const icon = `
<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path class="no-fill just-stroke" d="M14.65 1H5.55C3.0371 1 1 3.0371 1 5.55C1 8.0629 3.0371 10.1 5.55 10.1H14.65C17.1629 10.1 19.2 8.0629 19.2 5.55C19.2 3.0371 17.1629 1 14.65 1Z" stroke="#1D1D1D" stroke-width="1.3"/>
<path d="M16.4001 10.5215L8.70519 3.10004L8.70007 14.0644L11.0351 11.721L12.4065 15L14.5462 14.0155L13.1748 10.7365L16.4001 10.5215Z" fill="black"/>
</svg>
`
export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElButtonV2', options)

    this.name = name
    this.group = group
    this.css = [['.--t3x95ha---GAYeA4pa2--1 { margin: 20px; }'], [], []]
    this.sid = 't3x95ha---GAYeA4pa2'
    this.sids = ['t3x95ha---GAYeA4pa2']

    this.defaultOptions = defaultOptions
  }
}

