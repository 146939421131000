<template>
  <div class="images-group-5 d-flex justify-content-center align-items-center">
    <div class="position-relative">
      <div
        class="card"
        style="width: 336px;"
        :style="{...dropShadow, ...backgroundColor}"
      >
        <slot
          v-if="visability('image')"
          name="image"
        />
        <div class="card-body">
          <h5
            v-if="visability('title')"
            class="card-title"
          >
            <slot name="title" />
          </h5>
          <p
            v-if="visability('description')"
            class="card-text"
          >
            <slot name="description" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'ImageItem5Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.images-group-5 {
  padding-top: 30px;
  padding-bottom: 60px;

  .card {
    border-radius: 12px;
    overflow: hidden;
  }
}
</style>
