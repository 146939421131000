<template>
  <div>
    <b-navbar-nav
      v-if="menu"
      :class="[align, {'text-uppercase': customs.uppercase}, cols('', 'py-3'), {'flex-wrap': wrapLinks}]"
    >
      <div
        v-for="(page, index) in menu.items"
        :key="index"
      >
        <b-nav-item
          v-if="!page.children || !page.children.length"
          :class="[{'es-active-link': (page.item_object_id || page.item_url) === $store.state.pages.page.id}, onHoverClass]"
          :style="itemStyle(page)"
          class="mr-0"
          @click="$store.dispatch('pages/goToPage', page)"
        >
          <div
            class="text-nowrap"
          >
            {{ page.item_name }}
          </div>
        </b-nav-item>

        <Menu
          v-else
          :customs="customs"
          :items="page.children"
          :label="page.item_name"
          :link-style="itemStyle"
          :page="page"
        />
      </div>
    </b-navbar-nav>

    <div
      v-if="customs.onHover === 'underline'"
      v-html="underlineStyles"
    />
    <div v-html="linkHoverColor" />
    <div
      v-if="customs.activeLinkStyle === 'underline'"
      v-html="activeUnderlineStyles"
    />
    <div
      v-if="wrapLinks"
      v-html="wrapLinksStyles"
    />
    <div v-html="spacing" />
  </div>
</template>

<script>
import ComponentProvider from '@builder/components/mixins/ComponentProvider'
import {mapState} from 'vuex'
import Menu from '@builder/components/builder/utils/menu/Menu'
import _ from 'lodash'
import ResolutionMixin from '../../mixins/ResolutionMixin'

export default {
  name: 'EsMenuItems',
  components: {Menu},
  mixins: [ComponentProvider, ResolutionMixin],

  computed: {
    align () {
      return `justify-content-${_.get(this.customs, ['align', this.resolutioner], 'end') || 'end'}`
    },
    wrapLinks () {
      return this.customs.wrapLinks
    },
    wrapLinksStyles () {
      return `
      <style lang="text/css">
        .es-root .navbar.navbar-light.navbar-expand .container,
        .es-root .navbar-collapse.collapse {
            align-items: start !important;
        }

       .es-root .navbar.navbar-light.navbar-expand .links {
        margin-top: -10px;
       }
      </style>
      `
    },
    fontWeight () {
      return this.customs.fontWeight || '400'
    },
    activeStyle () {
      return {
        color: `${this.customs.color} !important`,
        background: this.customs.activeLinkStyle === 'background' ? this.customs.activeBackgroundColor || 'rgba(225, 225, 225, 0)' : null,
        borderRadius: this.customs.shape || '6px',
        padding: `0 ${this.customs.activeBackgroundColor !== 'rgba(225, 225, 225, 0)' ? '10px' : null}`
      }
    },
    ...mapState('sections', {
      sections: state => state.sections
    }),
    ...mapState('menu', {
      menu: state => state.navbarMenu
    }),
    onHoverClass () {
      return `hover-style-${this.customs.onHover || 'color'}`
    },
    linkHoverColor () {
      return `
      <style lang="text/css">
         .${this.onHoverClass}:not(.es-active-link):hover {
          transition: .2s;
          color: ${this.customs.linkHoverColor || '#C5C5C5B3'} !important;
          background: ${this.customs.onHover === 'background' ? this.customs.linkHoverBackground || '#7A7B7C' : ''};
          border-radius: ${this.customs.onHover === 'background' ? this.customs.linkHoverBackgroundShape : ''};
         }

         .hs-mega-menu .dropdown-item:hover {
          transition: .2s;
          color: ${this.customs.linkHoverColor || '#C5C5C5B3'} !important;
          background: ${this.customs.onHover === 'background' ? this.customs.linkHoverBackground || '#7A7B7C' : ''};
         }

         .hs-mega-menu .dropdown-item:hover div {
            color: ${this.customs.linkHoverColor || '#C5C5C5B3'} !important;
         }

         .hs-mega-menu .dropdown-item,
         .nav-item .dropdown-link,
         .navbar-nav .nav-link {
           ${this.fontFamily}
         }
      </style>
      `
    },
    fontFamily () {
      let family = this.customs.fontFamily
      if (family === 'Global Style') family = _.get(this.globalStyles, ['textStyles', 'link', 'fontFamily', this.resolutioner], 'Roboto')
      return `font-family: ${family} !important;`
    },
    spacing () {
      return `
      <style lang="text/css">
        .navbar-light:not(.navbar-expand) .navbar-nav .nav-link {
          margin-top: ${this.customs.spacing}px;
          margin-bottom: ${this.customs.spacing}px;
         }
      </style>
      `
    },
    underlineStyles () {
      return `
      <style lang="text/css">
        .${this.onHoverClass}:not(.es-active-link):hover:before {
            height: ${this.fontWeight <= 400 ? '2px' : '3px'};
            background: ${this.customs.linkHoverColor || this.customs.linksColor || this.globalStyles.colors.primary};
         }
      </style>
      `
    },
    activeUnderlineStyles () {
      return `
        <style lang="text/css">
           nav .nav-item.es-active-link {position: relative;}
           nav .nav-item.es-active-link:before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: ${this.fontWeight <= 400 ? '2px' : '3px'};
              background: ${this.customs.activeBackgroundColor || this.globalStyles.colors.primary};
           }
        </style>
      `
    }
  },

  mounted () {
    this.$store.dispatch('menu/loadNavbarMenu')
  },

  methods: {
    itemStyle (page) {
      return {
        fontWeight: this.fontWeight,
        color: this.customs.linksColor || '#000',
        ...((page.item_object_id || page.item_url) === this.$store.state.pages.page.id && !page.children.length)
          ? this.activeStyle
          : null,
        fontSize: _.get(this.customs, ['fontSize', this.resolutioner], '14px'),
        paddingRight: this.customs.onHover === 'background' ? '8px' : '',
        paddingLeft: this.customs.onHover === 'background' ? '8px' : ''
      }
    }
  }
}
</script>

<style lang="scss">
.navbar-expand {
  .navbar-nav {
    align-items: center;
  }

  .hover-style-transform {
    a {
      transition: .4s;
    }

    &:not(.es-active-link):hover {
      a {
        transform: translateY(-5px);
      }
    }
  }

  .hover-style-underline {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0;
      transition: .2s;
    }

    &:hover {
      a {
        opacity: 1 !important;
      }
    }
  }
}

.navbar-nav {
  &.justify-content {
    &-start {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-end {
      text-align: right;
    }
  }
}
</style>