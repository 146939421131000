<template>
  <b-form-group
    class="estage-form-element-group mb-0"
  >
    <template
      v-if="control.label"
      slot="label"
    >
      <div
        v-html="label"
      />
    </template>

    <legend class="d-none active" />

    <div class="flex items-center">
      <div class="flex items-center justify-center phone-code-select cursor-pinter text-nowrap">
        <div class="flex items-center">
          <img
            width="18"
            height="13"
            src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"
            alt="US"
          >
          <i class="fa fa-angle-down ml-2" />
        </div>

        <div>+1</div>
      </div>
      
      <b-input
        v-model="control.value"
        type="tel"
        :maxlength="15"
        v-bind="$attrs"
        class="input"
        placeholder="Phone"
        @focus="focus"
        @blur="blur"
      />
    </div>
  </b-form-group>
</template>

<script>
import FormControlMixin from './FormControlMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'FormPhone',
  mixins: [FormControlMixin, BorderMixin],
  computed: {
    code: {
      get () {
        return this.control.code ? this.control.code : { 'name': 'United States', 'dial_code': '+1', 'code': 'US' }
      },
      set (val) {
        this.$set(this.control, 'code', val)
      }
    }
  }
}
</script>

<style lang="scss">
.phone-code-select {
  height: 50px;
  padding: 0 20px 0 0;
  border-color: #E2E5EC;
  font-size: 16px;
  gap: 20px;

  i {
    font-size: 12px;
  }
}
</style>