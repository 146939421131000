<template>
  <div class="pt-[9px]">
    <b-form-group label="Navbar Collapsable">
      <v-select
        v-model="config.specialCols.navigation.showOn"
        :options="breackOptions"
        :reduce="val => val.value"
        :searchable="false"
      />
    </b-form-group>

    <b-form-group label="Placement">
      <v-select
        v-model="config.specialCols.navigation.placement"
        :options="options"
        :reduce="val => val.value"
        :searchable="false"
      />
    </b-form-group>

    <background-customs
      :config="navbarBackground"
      type-label="Navbar background color"
    />

    <b-form-group label="Open icon">
      <div class="flex items-center gap-[10px] w-[150px]">
        <icon-select v-model="iconOpen" />
        <color-picker
          :color="iconColor"
          @input="event => iconColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </div>
    </b-form-group>

    <b-form-group label="Close icon">
      <div class="flex items-center gap-[10px] w-[150px]">
        <icon-select v-model="iconClose" />
        <color-picker
          :color="closeIconColor"
          @input="event => closeIconColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </div>
    </b-form-group>

    <b-form-group
      label="Hamburger Icon Size"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="hamburgerIconSize"
            :interval="1"
            :max="100"
            :min="0"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col class="p-0">
          <LinkedControlItem
            v-model="hamburgerIconSize"
            :max="100"
            :min="0"
            class="d-block !w-[60px]"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <flex-column-mod
      component-name="Navigation"
      :config="config.specialCols.navigation"
    />
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import IconSelect from '@/components/editor/components/IconSelect'
import _ from 'lodash'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

export default {
  name: 'NavigationFlexColMod',
  components: {LinkedControlItem, IconSelect},
  mixins: [ModMixin],
  data () {
    return {
      breackOptions: [
        {
          label: 'Tablet And Down',
          value: 'lg'
        },
        {
          label: 'Mobile And Down',
          value: 'md'
        },
        {
          label: 'Always',
          value: false
        }
      ],
      options: [
        {
          label: 'Top',
          value: 'top'
        },
        {
          label: 'Bottom',
          value: 'bottom'
        }
      ]
    }
  },
  computed: {
    navbarBackground () {
      if (!this.config.specialCols.navigation.navbarBackground) {
        this.$set(this.config.specialCols.navigation, 'navbarBackground', {
          background: '',
          gradient: {
            position: '',
            color: ''
          }
        })
      }

      return this.config.specialCols.navigation.navbarBackground
    },
    iconOpen: {
      get () {
        return _.get(this.config.specialCols.navigation, 'hamburgerIcon_open', 'fa fa-bars')
      },
      set (val) {
        this.$set(this.config.specialCols.navigation, 'hamburgerIcon_open', val)
      }
    },
    iconClose: {
      get () {
        return _.get(this.config.specialCols.navigation, 'hamburgerIcon_close', 'fa fa-times')
      },
      set (val) {
        this.$set(this.config.specialCols.navigation, 'hamburgerIcon_close', val)
      }
    },
    iconColor: {
      get () {
        return _.get(this.config.specialCols.navigation, 'hamburgerIcon_color', '#656565')
      },
      set (val) {
        this.$set(this.config.specialCols.navigation, 'hamburgerIcon_color', val)
      }
    },
    closeIconColor: {
      get () {
        return _.get(this.config.specialCols.navigation, 'hamburgerIcon_close_color', this.iconColor)
      },
      set (val) {
        this.$set(this.config.specialCols.navigation, 'hamburgerIcon_close_color', val)
      }
    },
    hamburgerIconSize: {
      get () {
        return _.get(this.config.specialCols.navigation, ['hamburgerIconSize', this.resolutioner], _.get(this.config.specialCols.navigation, ['hamburgerIconSize', 'lg'], 16))
      },
      set (val) {
        this.$set(this.config.specialCols.navigation, 'hamburgerIconSize', {..._.set(this.config.specialCols.navigation['hamburgerIconSize'] || {}, [this.resolutioner], val)})
      }
    }
  }
}
</script>