<template>
  <div>
    <b-form-group>
      <b-checkbox v-model="customSizeEnable">
        Custom Size
      </b-checkbox>
    </b-form-group>

    <div class="flex relative w-100 gap-[10px]">
      <button
        class="absolute top-0 right-0 p-0 m-0"
        @click="linked = !linked"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.0389 0.961082C11.7575 -0.320356 9.67997 -0.320356 8.39853 0.961055L5.65611 3.70347C4.33062 5.02902 4.45334 7.14105 5.65611 8.34387C5.85753 8.54529 6.08038 8.70952 6.3157 8.8444L6.81623 8.34387C7.14452 8.01553 7.02897 7.63171 7.02306 7.35474C6.95101 7.30366 6.88093 7.24846 6.81623 7.18376C6.199 6.56658 6.17124 5.50854 6.81623 4.86356C6.91202 4.76777 9.50046 2.17933 9.5586 2.1212C10.1984 1.48138 11.239 1.48138 11.8788 2.1212C12.5186 2.76101 12.5186 3.80158 11.8788 4.4414L10.0661 6.2541C10.1185 6.54413 10.4331 7.23117 10.2713 8.36452C10.2792 8.35675 10.2886 8.35175 10.2965 8.3439L13.0389 5.60149C14.3203 4.32005 14.3203 2.24252 13.0389 0.961082Z"
            :fill="linked ? '#3D82EA' : '#9E9E9E'"
          />
          <path
            d="M8.55635 5.44361C8.35497 5.24219 8.13209 5.07797 7.89682 4.94308L7.39629 5.44361C7.06795 5.7719 7.1835 6.15572 7.18941 6.43269C7.26152 6.48376 7.3316 6.53897 7.39629 6.60367C8.01353 7.2209 8.04128 8.27888 7.39629 8.92387C7.30029 9.01987 4.49744 11.8227 4.44136 11.8788C3.80154 12.5186 2.76097 12.5186 2.12115 11.8788C1.48134 11.239 1.48134 10.1984 2.12115 9.5586L4.14643 7.53333C4.09401 7.24329 3.77939 6.55625 3.94124 5.42291C3.93334 5.43067 3.92382 5.43573 3.91595 5.44358L0.961039 8.39854C-0.320346 9.67998 -0.320346 11.7575 0.961039 13.0389C2.24248 14.3203 4.31998 14.3203 5.60142 13.0389L8.55635 10.084C9.85726 8.7831 9.78617 6.67339 8.55635 5.44361Z"
            :fill="linked ? '#3D82EA' : '#9E9E9E'"
          />
        </svg>
      </button>

      <b-form-group label="Height">
        <multi-select
          v-model="height"
          @input="val => onInput(val, 'width')"
        />
      </b-form-group>

      <b-form-group label="Width">
        <multi-select
          v-model="width"
          @input="val => onInput(val, 'height')"
        />
      </b-form-group>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import MultiSelect from '@/components/editor/controls/MultiSelect'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  name: 'ImageHeightWidth',
  components: {MultiSelect},
  mixins: [ResolutionMixin],
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      linked: false
    }
  },
  computed: {
    customSizeEnable: {
      get () {
        return _.get(this.config, ['customSize', 'enabled', this.resolutioner], false)
      },
      set (val) {
        this.$set(this.config, 'customSize', {..._.set(this.config.customSize || {}, ['enabled', this.resolutioner], val)})
      }
    },
    height: {
      get () {
        return _.get(this.config, ['customSize', 'height'], {})
      },
      set (val) {
        this.$set(this.config, 'customSize', {..._.set(this.config.customSize || {}, ['height'], val)})
      }
    },
    width: {
      get () {
        return _.get(this.config, ['customSize', 'width'], {})
      },
      set (val) {
        this.$set(this.config, 'customSize', {..._.set(this.config.customSize || {}, ['width'], val)})
      }
    }
  },
  methods: {
    onInput (val, linkedTarget) {
      if (this.linked) {
        this[linkedTarget] = _.cloneDeep({..._.set(this[linkedTarget], [this.resolutioner], _.get(val, [this.resolutioner]))})
      }
    }
  }
}
</script>