<template>
  <b-img
    crossorigin="anonymous"
    v-bind="$attrs"
    :src="customs.src || customs.placeholder"
    :style="style"
    :class="classes"
    fluid
  />
</template>

<script>
import ComponentProvider from '@builder/components/mixins/ComponentProvider'

export default {
  name: 'EsImage',

  mixins: [ComponentProvider],

  computed: {
    classes () {
      return [
        {'rounded-circle' : this.customs.shape === 'circle'},
        {'rounded' : this.customs.shape === 'rounded'}
      ]
    },
    style () {
      return {
        // color: this.customs.resolutionStyle[this.resolution].color
      }
    }
  }
}
</script>
