<template>
  <div class="dashboard-background min-vh-100">
    <dashboard-header />

    <!-- Topbar -->
    <dashboard-topbar title="Archive" />
    <!-- End Topbar -->


    <div class="dashboard-container py-20">
      <dashboard-breadcrumb
        class="-mt-2 mb-5"
        :breadcrumbs="breadcrumbs"
      />

      <archived-projects
        v-if="!loadingMoreProjects"
        :projects="projects"
      />

      <div
        v-if="loadingMoreProjects"
        class="text-center py-5"
      >
        <b-spinner variant="primary" />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardHeader from '../../layouts/dashboard/DashboardHeader'
import DashboardTopbar from '../../layouts/dashboard/DashboardTopbar'
import ArchivedProjects from '../../layouts/dashboard/compontens/archive/ArchivedProjects'
import DashboardBreadcrumb from '../../layouts/dashboard/compontens/topbar/DashboardBreadcrumb'

export default {
  name: 'Archive',
  components: {DashboardBreadcrumb, ArchivedProjects, DashboardTopbar, DashboardHeader},
  data () {
    return {
      projects: [],
      loadingMoreProjects: true,
      breadcrumbs: [{id: 0, name: 'All Sites'}, {name: 'Archive'}]
    }
  },

  created () {
    axios.get('api/projects/trash')
      .then(({data}) => {
        this.projects = data.data.data
      })
      .finally(() => {
        this.loadingMoreProjects = false
      })
  }
}
</script>