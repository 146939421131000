<template>
  <div>
    <div v-if="values">
      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <label class="mb-0 pb-0">X</label>
          <NumberControl
            v-model="values.value.x"
            class="d-block !w-[60px]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <label class="mb-0 pb-0">Y</label>
          <NumberControl
            v-model="values.value.y"
            class="d-block !w-[60px]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <label class="mb-0 pb-0">Z</label>
          <NumberControl
            v-model="values.value.z"
            class="d-block !w-[60px]"
          />
        </div>
      </b-form-group>

      <span
        v-if="progress !== 0 && progress !== 100"
        class="mt-[-10px] block mb-3 text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
        @click="removePoint"
      >
        Remove
      </span>
    </div>

    <button
      v-else
      class="bg-[#007bff] rounded-[4px] text-white w-[105px] p-0 m-0 h-[38px] mb-3"
      @click="addPoint"
    >
      Add Point
    </button>
  </div>
</template>

<script>
import _ from 'lodash'
import MotionEffectMixin from '@/mixins/MotionEffectMixin'
import NumberControl from '@/components/editor/components/NumberControl'
export default {
  name: 'MotionRotate',
  components: {NumberControl},
  mixins: [MotionEffectMixin],
  props: {
    progress: {
      type: Number,
      required: true
    },
    motion: {
      type: Object,
      required: true
    }
  },
  computed: {
    values: {
      get () {
        return _.find(this.motion.values, ['progress', this.progress])
      }
    }
  }
}
</script>