<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">
        {{ labelCase(componentName) }}
      </p>
    </div>

    <b-row no-gutters>
      <b-col cols="12">
        <b-form-group>
          <b-checkbox
            v-model="collapsable"
            switch
          >
            Collapsable
          </b-checkbox>
        </b-form-group>
      </b-col>

      <template v-if="collapsable">
        <b-col cols="12">
          <b-form-group label="Toggle background color">
            <color-picker
              :color="toggleBackgroundColor"
              @input="event => toggleBackgroundColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
            />
          </b-form-group>

          <b-form-group label="Toggle icon color">
            <color-picker
              :color="toggleIconColor"
              @input="event => toggleIconColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
            />
          </b-form-group>

          <hr class="mt-3">
        </b-col>
      </template>


      <b-col class="pr-2">
        <b-form-group label="Size">
          <b-select
            v-model="config.style.fontSize"
            :options="fontSizes"
          />
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group label="Color">
          <color-picker
            :color="config.style.color"
            @input="event => config.style.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <draggable
      v-model="config.links"
      handle=".move"
      class="list-group mb-3"
    >
      <b-list-group-item
        v-for="(item, index) in config.links"
        :key="index"
        class="d-flex align-items-center text-capitalize"
      >
        <icon
          class="move mr-3"
          icon="move.svg"
        />
        <div class="text-truncate">
          {{ item.text }}
        </div>

        <icon
          class="cursor-pinter ml-auto mr-3"
          icon="edit.svg"
          @click.native="newListItemModal = index"
        />
        <icon
          class="cursor-pinter"
          icon="trash.svg"
          @click.native="config.links.splice(index, 1)"
        />
      </b-list-group-item>
    </draggable>

    <div
      class="d-flex align-items-center justify-content-end cursor-pinter font-weight-bold text-primary w-100"
      @click="newListItemModal = true"
    >
      <icon
        class="mr-2"
        icon="plus.svg"
      />
      Add Link
    </div>


    <b-modal
      v-model="getNewListItemModal"
      hide-footer
      hide-header
    >
      <icon
        class="cursor-pinter ml-3"
        icon="close.svg"
        @click.native="newListItemModal = false"
      />

      <h4 class="h2 font-weight-bold text-center my-4">
        Add Link
      </h4>

      <div class="p-5">
        <b-form-group label="Text">
          <b-input
            v-if="typeof newListItemModal !== 'number'"
            v-model="text"
          />
          <b-input
            v-else
            v-model="config.links[newListItemModal].text"
          />

          <b-checkbox
            v-if="typeof newListItemModal !== 'number'"
            v-model="customLink"
            class="mt-2"
          >
            Custom Link
          </b-checkbox>
          <b-checkbox
            v-else
            v-model="config.links[newListItemModal].customLink"
            class="mt-2"
          >
            Custom Link
          </b-checkbox>
        </b-form-group>

        <b-form-group
          v-if="typeof newListItemModal !== 'number' && customLink"
          label="Link"
        >
          <b-input v-model="href" />
        </b-form-group>

        <b-form-group
          v-else-if="typeof newListItemModal === 'number' && config.links[newListItemModal].customLink"
          label="Link"
        >
          <b-input v-model="config.links[newListItemModal].href" />
        </b-form-group>

        <b-form-group
          v-else-if="typeof newListItemModal !== 'number' && !customLink"
          label="Page"
        >
          <v-select
            v-model="href"
            class="custom-select"
            label="title"
            :options="[{item_type: 'module', id: 'blog', title: 'ESTAGE Blog'}, ...pages.data]"
            @option:selected="option => text = option.title"
          />
        </b-form-group>

        <b-form-group
          v-else-if="typeof newListItemModal === 'number' && !config.links[newListItemModal].customLink"
          label="Page"
        >
          <v-select
            v-model="config.links[newListItemModal].href"
            class="custom-select"
            label="title"
            :options="[{id: null, title: 'Not selected'}, ...pages.data]"
          />
        </b-form-group>

        <!--        <b-form-group v-if="typeof newListItemModal !== 'number'">-->
        <!--          <b-checkbox v-model="blank">Open in new tab</b-checkbox>-->
        <!--        </b-form-group>-->

        <!--        <b-form-group v-else>-->
        <!--          <b-checkbox v-model="config.links[newListItemModal].blank">Open in new tab</b-checkbox>-->
        <!--        </b-form-group>-->

        <div
          v-if="typeof newListItemModal !== 'number'"
          class="d-flex justify-content-center"
        >
          <b-btn
            variant="primary"
            @click="addListItem"
          >
            Add Link
          </b-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import draggable from 'vuedraggable'
import {mapGetters} from 'vuex'

export default {
  name: 'ListMod',
  components: {draggable},
  mixins: [ModMixin],
  data () {
    return {
      newListItemModal: false,
      text: '',
      customLink: '',
      href: '',
      blank: '',
      fontSizes: ['1px',
        '2px',
        '3px',
        '4px',
        '5px',
        '6px',
        '7px',
        '8px',
        '9px',
        '10px',
        '11px',
        '12px',
        '13px',
        '14px',
        '15px',
        '16px',
        '17px',
        '18px',
        '19px',
        '20px',
        '21px',
        '22px',
        '23px',
        '24px',
        '25px',
        '26px',
        '27px',
        '28px',
        '29px',
        '30px',
        '31px',
        '32px',
        '33px',
        '34px',
        '35px',
        '36px',
        '37px',
        '38px',
        '39px',
        '40px',
        '41px',
        '42px',
        '43px',
        '44px',
        '45px',
        '46px',
        '47px',
        '48px',
        '49px',
        '50px',
        '51px',
        '52px',
        '53px',
        '54px',
        '55px',
        '56px',
        '57px',
        '58px',
        '59px',
        '60px',
        '61px',
        '62px',
        '63px',
        '64px',
        '65px',
        '66px',
        '67px',
        '68px',
        '69px',
        '70px',
        '71px',
        '72px',
        '73px',
        '74px',
        '75px',
        '76px',
        '77px',
        '78px',
        '79px',
        '80px',
        '81px',
        '82px',
        '83px',
        '84px',
        '85px',
        '86px',
        '87px',
        '88px',
        '89px',
        '90px',
        '91px',
        '92px',
        '93px',
        '94px',
        '95px',
        '96px',
        '97px',
        '98px',
        '99px',
        '100px']
    }
  },
  computed: {
    collapsable: {
      get () {
        return this.config.collapsable || false
      },
      set (val) {
        this.$set(this.config, 'collapsable', val)
      }
    },
    toggleBackgroundColor: {
      get () {
        return this.config.toggleBackgroundColor || '#F8FAFD'
      },
      set (val) {
        this.$set(this.config, 'toggleBackgroundColor', val)
      }
    },
    toggleIconColor: {
      get () {
        return this.config.toggleIconColor || '#1b386b'
      },
      set (val) {
        this.$set(this.config, 'toggleIconColor', val)
      }
    },
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    align: {
      get () {
        return this.config.align || 'left'
      },
      set (val) {
        this.$set(this.config, 'align', val)
      }
    },
    getNewListItemModal: {
      get () {
        if (this.newListItemModal === 0) {
          return true
        }

        return !!this.newListItemModal
      },
      set (val) {
        this.newListItemModal = val
      }
    }
  },
  methods: {
    addListItem () {
      this.config.links.push({
        text: this.text,
        customLink: this.customLink,
        href: this.href,
        blank: this.blank
      })

      this.newListItemModal = false
    }
  }
}
</script>
