import router from '@builder/router/index'
import shortid from 'shortid'

const computedProperty = [
  'width', 'height', 'font-size', 'max-width', 'max-height', 'min-width', 'min-height'
]

const getStylesheetEl = () => {
  return router.currentRoute.name === 'editor' ? document.getElementById('sty') : document.getElementById('subject-styles')
}

const getVarValue = (sid, varName, pseudo = null, defaultValue = 0) => {
  const $el = document.querySelector(sid)
  return window.getComputedStyle($el, pseudo || '').getPropertyValue(varName) || defaultValue
}

const retriveStyles = (css, css_hash) => {
  const $styleEl = document.createElement('style')
  document.head.appendChild($styleEl)
  $styleEl.textContent = css

  const styleRules = [...$styleEl.sheet.cssRules]

  const styles = [[], [], []]

  styleRules.forEach((rule, index) => {
    let j = 0, len = [...rule.cssRules].length
    while (j < len) {
      if (rule.cssRules[j].selectorText.indexOf(css_hash) !== -1) {
        styles[index].push(rule.cssRules[j].cssText)
      }

      j++
    }
  })

  $styleEl.remove()

  return styles
}

const getPageStyles = (css_hash) => {
  const styleRules = [...getStylesheetEl().sheet.cssRules]

  const styles = [[], [], []]

  styleRules.forEach((rule, index) => {
    let j = 0, len = [...rule.cssRules].length
    while (j < len) {
      if (rule.cssRules[j].selectorText.indexOf(css_hash) !== -1) {
        rule.cssRules.deleteRule(j)

        styles[index].push(rule.cssRules[j].cssText)
      }

      j++
    }
  })

  return styles
}

const pageStylesToString = (selector = 'sty') => {
  const $styEl = document.querySelector(`#${selector}`)

  const cssRules = [...$styEl.sheet.cssRules]
  const $cloneStyle = document.createElement('style')
  const media = ['screen', '(max-width: 1100px)', '(max-width: 520px)']
  let currentScreenIndex = ''

  document.head.appendChild($cloneStyle)

  let j = 0, len = cssRules.length

  while (j < len) {
    $cloneStyle.textContent += ` ${cssRules[j].cssText}`
    j++
  }

  const output = [...$cloneStyle.sheet.cssRules].reduce((accumulator, currentValue, currentIndex) => {
    currentScreenIndex = currentValue.media.mediaText === 'screen' ? currentIndex : 0
    currentValue.media.mediaText = media[currentIndex]
    clearEmptyAndDuplicateRules(currentValue)
    return accumulator += currentValue.cssText
  }, '')

  $cloneStyle.sheet.cssRules[currentScreenIndex].media.mediaText = 'screen'

  $cloneStyle.remove()
  return output
}

const pageStylesToStringAsync = (selector = 'sty') => {
  return new Promise((resolve) => {
    const $styEl = document.querySelector(`#${selector}`)

    const cssRules = [...$styEl.sheet.cssRules]
    const $cloneStyle = document.createElement('style')
    const media = ['screen', '(max-width: 1100px)', '(max-width: 520px)']
    let currentScreenIndex = ''

    document.head.appendChild($cloneStyle)

    let j = 0, len = cssRules.length

    while (j < len) {
      $cloneStyle.textContent += ` ${cssRules[j].cssText}`
      j++
    }

    const output = [...$cloneStyle.sheet.cssRules].reduce((accumulator, currentValue, currentIndex) => {
      currentScreenIndex = currentValue.media.mediaText === 'screen' ? currentIndex : 0
      currentValue.media.mediaText = media[currentIndex]
      clearEmptyAndDuplicateRules(currentValue)
      return accumulator += currentValue.cssText
    }, '')

    $cloneStyle.sheet.cssRules[currentScreenIndex].media.mediaText = 'screen'

    $cloneStyle.remove()

    return resolve(output)
  })
}

const origPageStylesToString = (selector = 'sty') => {
  const $styEl = document.querySelector(`#${selector}`)


  const output = [...$styEl.sheet.cssRules].reduce((accumulator, currentValue) => {
    clearEmptyAndDuplicateRules(currentValue)
    return accumulator += currentValue.cssText
  }, '')

  return output
}

const getOriginalComponentPropertyValue = (selector, property, pseudo = '') => {
  if (!computedProperty.includes(property)) return ''

  const $componentsStyleEl = document.getElementById('components-style')
  const selectorName = `${selector}${pseudo}`

  let j = 0, len = [...$componentsStyleEl.sheet.cssRules].length
  while (j < len) {
    const selector = $componentsStyleEl.sheet.cssRules[j].selectorText
    if (selector && selector.replaceAll('\\', '') === selectorName) {
      return $componentsStyleEl.sheet.cssRules[j].style[property]
    }

    j++
  }
}

const deleteStylesBySid = (sid) => {
  const styleRules = [...getStylesheetEl().sheet.cssRules]

  for (let i = 0; i < styleRules.length; i++) {
    const styleSheet = styleRules[i]
    try {
      const rules = styleSheet.cssRules || styleSheet.rules
      for (let j = rules.length - 1; j >= 0; j--) {
        const rule = rules[j]
        if (rule.selectorText && rule.selectorText.indexOf(sid) !== -1) {
          styleSheet.deleteRule(j)
        }
      }
    } catch (e) {
      // Catch possible cross-origin stylesheet access errors
      console.error('Error accessing stylesheet:', e)
    }
  }
}

const clearEmptyAndDuplicateRules = (stylesheet) => {
  const seen = new Set()
  let j = 0, len = stylesheet.cssRules.length

  while (j < len) {
    const rule = stylesheet.cssRules[j]

    if (rule) {
      if (!rule.style.cssText.trim()) {
        stylesheet.deleteRule(j)
      } else if (seen.has(rule.selectorText)) {
        stylesheet.deleteRule(j)
      } else {
        seen.add(rule.selectorText)
      }
    }

    j++
  }
}

const getAffectingClass = (element, property) => {
  let originalClassList = element.classList
  let classList = Array.from(originalClassList)
  const value = getComputedStyle(element).getPropertyValue(property)

  for (let i = 0; i < classList.length; i++) {
    element.className = classList[i]
    let computedStyle = getComputedStyle(element)
    let propValue = computedStyle.getPropertyValue(property)
    if (propValue === value) {
      element.className = classList.join(' ')
      return `.${classList[i]}`
    }
  }
  element.className = classList.join(' ')
  return null
}

const applyPageStyles = (styles, selector = 'sty') => {
  if (!styles) return
  const $styEl = document.getElementById(selector)
  $styEl.textContent = styles

  const styleRules = [...$styEl.sheet.cssRules]

  styleRules.forEach((rule) => {
    const selectors = ['.estage-btn:hover', '.estage-btn', '.el-block', '.el-container', '.el-flex-wrapper', '.flex-wrapper', '.col-hover-v2', '.flex-col-inner', '.link-active', '.el-links-wrapper', '.el-menu-link', '.el-menu-link:hover']

    selectors.forEach(selector => {
      if (![...rule.cssRules].filter(r => r.selectorText === selector).length) {
        rule.insertRule(`${selector}{}`, 0)
      }
    })
  })
}

const getUsedFonts = (prefix = false) => {
  const styleRules = [...getStylesheetEl().sheet.cssRules]
  const usedFonts = []

  styleRules.forEach((rule) => {
    let i = 0, len = [...rule.cssRules].length
    while (i < len) {
      const font = rule.cssRules[i].style.getPropertyValue('font-family').replaceAll('"', '')
      if (usedFonts.indexOf(font) === -1 && prefix ? rule.cssRules[i].selectorText.indexOf(prefix) === 0 : true) {
        usedFonts.push(font)
      }
      i++
    }
  })

  return usedFonts
}

const insertSectionStyle = (styles) => {
  const $styEl = getStylesheetEl()

  styles.forEach((css, index) => {
    css.forEach(c => {
      $styEl.sheet.cssRules[index].insertRule(c, $styEl.sheet.cssRules[index].cssRules.length)
    })
  })
}

const getStylesBySid = (sids) => {
  const arr = [
    [],
    [],
    []
  ]
  const styleRules = [...getStylesheetEl().sheet.cssRules]

  styleRules.forEach((rule, index) => {
    let j = 0, len = [...rule.cssRules].length
    while (j < len) {
      sids.forEach(sid => {
        if (rule.cssRules[j].selectorText.indexOf(sid) !== -1) {
          arr[index].push(rule.cssRules[j].cssText)
        }
      })

      j++
    }
  })

  return arr
}

// const getRelatedToStyleSids = (sids) => {
//   const arr = []
//
//   const styleRules = [...document.getElementById('sty').sheet.cssRules]
//
//   styleRules.forEach((rule) => {
//     let j = 0, len = [...rule.cssRules].length
//     while (j < len) {
//       sids.forEach(sid => {
//         if (rule.cssRules[j].selectorText.indexOf(sid) !== -1 && arr.indexOf(sid) === -1) {
//           arr.push(rule.cssRules[j].cssText)
//         }
//       })
//
//       j++
//     }
//   })
//
//   return arr
// }

const clonePart = (json, node) => {
  const sids = Array.from(new Set([...node.querySelectorAll('[data-sid]')].map(node => node.getAttribute('data-sid'))))
  let jsonString = JSON.stringify({
    ...json,
    css: getStylesBySid(sids),
    sids
  })
  //
  // sids.forEach(sid => {
  //   const newSid = shortid.generate()
  //   jsonString = jsonString.replaceAll(sid, newSid)
  // })

  return jsonString
}

const debug = (json, node) => {
  const sids = Array.from(new Set([...node.querySelectorAll('[data-sid]')].map(node => node.getAttribute('data-sid'))))

  console.log({
    content: json.options.content,
    css: `this.css = '${getStylesBySid(sids)}'`,
    sids: `this.sids = [${sids}]`,
    sid: `this.sid = '${json.sid}'`
  })
}

const generateSID = (node, hash, sid = null) => {
  return isGlobalPreset(node) ? `global--${sid || shortid.generate()}` : `l${hash}--${sid || shortid.generate()}`
}

const isGlobalSid = (sid) => {
  return sid.indexOf('global') !== -1
}

const isGlobalPreset = (node) => {
  if (!node) return false
  return node.closest('.global-block')
}

const isChangedPreset = (node, sid) => {
  if (isGlobalPreset(node)) {
    return !isGlobalSid(sid)
  }

  return isGlobalSid(sid)
}

const renameSid = (oldSid, newSid) => {
  const styleRules = [...getStylesheetEl().sheet.cssRules]

  styleRules.forEach(rule => {
    let j = 0, len = [...rule.cssRules].length
    while (j < len) {
      if (rule.cssRules[j].selectorText.indexOf(oldSid) !== -1) {
        rule.cssRules[j].selectorText = rule.cssRules[j].selectorText.replaceAll(oldSid, newSid)
      }

      j++
    }
  })
}

const duplicateStyles = (oldSid, newSid) => {
  const css = JSON.parse(JSON.stringify(getStylesBySid([oldSid])).replaceAll(oldSid, newSid))
  insertSectionStyle(css)
}

const convertStyles = (oldSid, newSid) => {
  if (document.querySelectorAll(`[data-sid="${oldSid}"]`).length === 1) {
    return renameSid(oldSid, newSid)
  }

  duplicateStyles(oldSid, newSid)
}

const getSidStyles = (sid) => {
  return [...getStylesheetEl().sheet.cssRules].filter(_s => {
    return _s.selectorText === sid
  })
}

const addSidRules = (rules) => {
  rules.forEach((rule) => {
    let j = 1,
      selector = rule[0],
      cleanSelector = selector.replace(/::after|::before|:hover/, ''),
      propStr = ''
    if (Array.isArray(rule[1][0])) {
      rule = rule[1]
      j = 0
    }
    let pl = rule.length

    while (j < pl) {
      const prop = rule[j]

      if (getSidStyles(cleanSelector).length) {
        const styleObject = getSidStyles(cleanSelector)[0].style

        if (prop[1] !== '') {
          styleObject.setProperty(prop[0], prop[1])

          if (this.var) {
            styleObject.setProperty(this.var, prop[1])
          }
        } else {
          styleObject.removeProperty(prop[0])

          if (this.var) {
            styleObject.removeProperty(this.var)
          }
        }
        return
      }

      getSidStyles(cleanSelector).forEach(_s => {
        propStr += _s.style.cssText
      })

      propStr += `${prop[0]}: ${prop[1]}${prop[2] ? ' !important' : ''};\n`

      j++
    }

    getStylesheetEl().sheet.insertRule(`${selector}{${propStr}}`, getStylesheetEl().sheet.cssRules.length)
  })
}

export {
  pageStylesToString,
  pageStylesToStringAsync,
  getOriginalComponentPropertyValue,
  getAffectingClass,
  applyPageStyles,
  getPageStyles,
  insertSectionStyle,
  clonePart,
  getUsedFonts,
  deleteStylesBySid,
  getStylesBySid,
  generateSID,
  isGlobalSid,
  isGlobalPreset,
  isChangedPreset,
  convertStyles,
  debug,
  getStylesheetEl,
  addSidRules,
  getSidStyles,
  retriveStyles,
  getVarValue
}

window.pageStylesToString = pageStylesToString
window.origPageStylesToString = origPageStylesToString
