import {getStylesheetEl} from '@/utils/useStylesheet'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  mixins: [ResolutionMixin],
  watch: {
    resolutioner () {
      this.updateViewport()
    }
  },
  methods: {
    updateViewport () {
      const styleEl = getStylesheetEl()

      const keys = {
        lg: 0,
        md: 1,
        sm: 2
      }

      const resIndex = keys[this.resolutioner]
      const media = ['screen', '(max-width: 1100px)', '(max-width: 520px)']

      console.log(styleEl)
      console.log(styleEl.sheet)
      Array.from(styleEl.sheet.cssRules).forEach((_media, index) => {
        if (resIndex >= index) {
          _media.media.mediaText = 'screen'
        } else {
          _media.media.mediaText = media[index]
        }
      })
    }
  }
}