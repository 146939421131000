<template>
  <div
    class="min-h-[500px]"
  >
    <SidbearSettingsGroup
      active
      title="Video"
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <b-form-group label="Video URL">
        <b-input
          v-model="config.video.src"
          placeholder="URL"
        />
      </b-form-group>

      <b-form-group>
        <b-checkbox v-model="overlayEnabled">
          Overlay
        </b-checkbox>
      </b-form-group>

      <b-form-group
        class="mb-0"
        label="Overlay Color"
      >
        <sid-color
          :sid="`.video-popup .--${section.sid}--video-popup-overlay`"
          presudo=":hover"
          rule-name="background-color"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <div>
      <component
        :is="config.subject.options.content.image.type"
        :config="config.subject.options.content.image"
        :local-section="config.subject"
        :show-active-group="false"
        component-name="Thumbnail"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ModMixin from '@builder/components/mixins/ModMixin'
import ImageUpload from '../utils/ImageUpload'
import Animations from '../controls/Animations'
import ColorPicker from '../utils/ColorPicker'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidColor from '@/components/editor/components/sid-controls/SidColor'

export default {
  name: 'ImageMod',
  components: {SidColor, SidbearSettingsGroup, ColorPicker, Animations, ImageUpload},
  mixins: [ModMixin],

  data () {
    return {
      file: [],
      url: ''
    }
  },

  computed: {
    overlayEnabled: {
      get () {
        return _.get(this.config.video.overlay, ['enable', this.resolutioner], _.get(this.config.video.overlay, ['enable', 'lg'], true))
      },
      set (val) {
        this.$set(this.config.video.overlay, 'enable', {..._.set(this.config.video.overlay['enable'] || {}, [this.resolutioner], val)})
      }
    },
    customModal () {
      if (!this.config.hasOwnProperty('modal')) {
        this.$set(this.config, 'modal', {
          data: {
            modal_id: null
          }
        })
      }

      return this.config.modal
    },
    animations: {
      get () {
        if (!this.config.hasOwnProperty('animations')) {
          this.$set(this.config, 'animations', {
            animation: null,
            once: true,
            offset: 200,
            delay: 50
          })
        }

        return this.config.animations
      },
      set (val) {
        this.config.animations = val
      }
    },
    ratio () {
      return {
        type: this.config.ratio.type,
        ...this.config.ratio[this.resolution]
      }
    },
    align: {
      get () {
        return this.config.align[this.resolution]
      },
      set (val) {
        this.config.align[this.resolution] = val
      }
    }
  },

  watch: {
    file () {
      // this.url = URL.createObjectURL(this.file)
      // this.config.src= this.url

      let formData = new FormData()

      formData.append('file', this.file)

      axios.post('api/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(({data}) => {
          this.config.src = data.data.url
          this.config.id = data.data.id
        })
    }
  },

  created () {
    if (!this.config.hasOwnProperty('align') || typeof this.config.align === 'string') {
      this.$set(this.config, 'align', {
        sm: 'center',
        lg: 'center'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-es-image-upload {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #E2E5EC;
  font-size: 14px;
  font-weight: 600;
  color: #6E747A;
  height: 38px;
  box-shadow: 0 1px 2px 0 rgba(226, 226, 226, 0.5)
}
</style>
