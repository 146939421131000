<template>
  <div>
    <b-btn
      variant="white"
      block
      @click="modal = true"
    >
      Validation
    </b-btn>

    <b-modal
      v-model="modal"
      hide-header
      hide-footer
      size="lg"
      body-class="px-5"
    >
      <b-link class="mb-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="11"
          viewBox="0 0 11 11"
        >
          <path
            id="Path"
            d="M6.712,5.5l4.113-4.113a.6.6,0,0,0,0-.851l-.36-.36a.6.6,0,0,0-.851,0L5.5,4.289,1.387.176a.6.6,0,0,0-.851,0L.176.536a.6.6,0,0,0,0,.851L4.289,5.5.176,9.613a.6.6,0,0,0,0,.851l.36.36a.6.6,0,0,0,.851,0L5.5,6.711l4.113,4.113a.6.6,0,0,0,.425.176h0a.6.6,0,0,0,.425-.176l.36-.36a.6.6,0,0,0,0-.851Z"
            transform="translate(0 0)"
            fill="#6e747a"
          />
        </svg>
      </b-link>

      <h4 class="h2 text-center my-4">
        Validaion
      </h4>

      <b-row
        v-for="rule in toggableValidators"
        :key="rule.key"
        class="align-items-center mb-4 pb-4 border-bottom"
      >
        <b-col md="6">
          {{ rule.title }}
          <div class="text-muted small">
            {{ rule.description }}
          </div>
        </b-col>
        <b-col
          md="6"
          class="text-right"
        >
          <b-checkbox
            switch
            size="lg"
            :checked="config.validators[rule.key]"
            @change="onToggleValidator(rule.key)"
          />
        </b-col>
      </b-row>

      <b-row
        v-for="rule in enterValidators"
        :key="rule.key"
        class="align-items-center mb-4 pb-4 border-bottom"
      >
        <b-col md="8">
          {{ rule.title }}
          <div class="text-muted small">
            {{ rule.description }}
          </div>
        </b-col>
        <b-col
          md="4"
          class="text-right"
        >
          <b-input
            type="number"
            :value="config.validators[rule.key] || 0"
            :min="0"
            @input="val => onEnterValidator(rule.key, val)"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'FormControlValidation',
  props: {
    config: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      modal: false,
      toggableValidators: [
        {
          title: 'Required',
          key: 'required',
          description: 'Required field.'
        },
        {
          title: 'Email',
          key: 'email',
          description: 'Check valid email.'
        },
        {
          title: 'IP Address',
          key: 'ipAddress',
          description: 'Check valid IP address.'
        },
        {
          title: 'URL',
          key: 'url',
          description: 'Check valid URL.'
        },
        {
          title: 'Numeric',
          key: 'numeric',
          description: 'Accepts only numerics.'
        },
        {
          title: 'Integer',
          key: 'integer',
          description: 'Accepts positive and negative integers.'
        },
        {
          title: 'Decimal',
          key: 'decimal',
          description: 'Accepts positive and negative decimal numbers.'
        }
      ],
      enterValidators: [
        {
          title: 'Min length',
          description: 'Requires the input to have a minimum specified length.',
          key: 'minLength'
        },
        {
          title: 'Max length',
          description: 'Requires the input to have a maximum specified length, inclusive.',
          key: 'maxLength'
        }
      ]
    }
  },

  methods: {
    onToggleValidator (key) {
      if (this.config.validators.hasOwnProperty(key)) {
        this.$delete(this.config.validators, key)
      } else {
        this.$set(this.config.validators, key, true)
      }
    },
    onEnterValidator (key, val) {
      if (this.config.validators.hasOwnProperty(key)) {
        if (parseInt(val, 10) > 0) {
          this.$set(this.config.validators, key, parseInt(val, 10))
        } else {
          this.$delete(this.config.validators, key)
        }
      } else {
        this.$set(this.config.validators, key, parseInt(val, 10))
      }
    }
  }
}
</script>
