<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="time-section highlight-element"
    section-type="element"
    @delete="remove"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="$emit('flow')"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings('time')"
    @add-subject="$emit('add-subject')"
  >
    <div
      class="timer"
      :data-sid="section.sid"
    >
      <vac
        ref="vac"
        :end-time="timeZoneTransform"
      >
        <template
          v-slot:process="{ timeObj }"
        >
          <div
            class="flex flex-wrap hide-last-separator justify-center items-center el-timer-wrapper"
            :class="`--${section.sid}-wrapper`"
          >
            <div
              v-for="(time, index) in [timeObj.d, timeObj.h, timeObj.m, timeObj.s]"
              v-if="!section.options.customize.style.dontShow.value.includes(index)"
              :key="index"
              class="contents"
            >
              <div
                class="flex flex-column items-center"
                :class="[`--${section.sid}-label-gap`, `--${section.sid}--digit`, shapeEnabled ? 'bg' : '']"
              >
                <div
                  class="leading-[1]"
                  :class="`--${section.sid}-dial dial`"
                  v-text="time"
                />
                <div
                  v-if="displayLabels"
                  :class="`--${section.sid}-label el-timer-label`"
                  v-text="titles[index]"
                />
              </div>
              <div
                class="flex flex-column items-center separator"
              >
                <span
                  v-if="enabledSeparator"
                  :class="`--${section.sid}-dial separator`"
                >:</span>
                <div
                  v-if="displayLabels"
                  class="opacity-0 w-0"
                  :class="`--${section.sid}-label el-timer-label`"
                  v-text="titles[index]"
                />
              </div>
            </div>
          </div>
        </template>
        <template
          v-slot:finish="{ timeObj }"
        >
          <div
            class="flex flex-wrap hide-last-separator justify-center items-center el-timer-wrapper"
            :class="`--${section.sid}-wrapper`"
          >
            <div
              v-for="(time, index) in [timeObj.d, timeObj.h, timeObj.m, timeObj.s]"
              v-if="!section.options.customize.style.dontShow.value.includes(index)"
              :key="index"
              class="contents"
            >
              <div
                class="flex flex-column items-center"
                :class="[`--${section.sid}-label-gap`, `--${section.sid}--digit`, shapeEnabled ? 'bg' : '']"
              >
                <div
                  class="leading-[1]"
                  :class="`--${section.sid}-dial dial`"
                  v-text="time || '00'"
                />
                <div
                  v-if="displayLabels"
                  :class="`--${section.sid}-label`"
                  v-text="titles[index]"
                />
              </div>
              <div
                class="flex flex-column items-center separator"
              >
                <span
                  v-if="enabledSeparator"
                  class="flex items-center justify-center"
                  :class="`--${section.sid}-dial separator separator-rune`"
                />
                <div
                  v-if="displayLabels"
                  class="text-nowrap opacity-0 w-0"
                  :class="`--${section.sid}-label`"
                  v-text="titles[index]"
                />
              </div>
            </div>
          </div>
        </template>
      </vac>
    </div>
  </highliter>
</template>

<script>
import _ from 'lodash'
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import TimerService from '../TimerService'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'TimeItemV2',

  components: { Highliter},

  mixins: [SectionMixin, TimerService, BorderMixin],

  data () {
    return {
      titles: ['Days', 'Hours', 'Minutes', 'Seconds']
    }
  },

  computed: {
    displayLabels () {
      return _.get(this.section.options.content.time.title, ['displayLabels', this.resolutioner], _.get(this.section.options.content.time.title, ['displayLabels', 'lg'], true))
    },
    enabledSeparator () {
      return _.get(this.section.options.content.time.separator, ['enabled', this.resolutioner], _.get(this.section.options.content.time.separator.enabled, ['lg'], true))
    },
    shapeEnabled () {
      return _.get(this.section.options.content.time, ['shapeEnabled', this.resolutioner], _.get(this.section.options.content.time, ['shapeEnabled', 'lg'], false))
    }
  },

  created () {
    VEvent.listen('timer-update', () => {
      setTimeout(() => {
        if (this.$refs.vac === undefined) return
        this.$refs.vac.startCountdown(true)
      })
    })
  }
}
</script>

<style lang="scss">
.timer {
  .bg .dial {
    --counter-height:20px;
    --counter-width:20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--counter-size, auto) + var(--counter-height, 20px));
    width: calc(var(--counter-size, auto) + var(--counter-width, 20px));
  }
}

.separator-rune {
  line-height: 1;
}

.separator-rune::after {
  content: '\003A';
  position: relative;
  top: -.1em;
}
</style>
