<template>
  <div>
    <module-editor
      v-if="!loading"
      v-model="content"
      crumb-title="Mega Menu"
      aside-title="Mega Menu"
      block-container-class="py-5"
      :highliter-options="{hideAdd: true, hideDelete: true, hideMove: true, hideRight: true}"
      @save="onSave"
    >
      <template #header-before>
        <button
          class="flex disabled:opacity-60 items-center rounded-left justify-center h-[36px] w-[41px] border bg-white p-0"
          :disabled="!isActiveUndo"
          @click="undo"
        >
          <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.4649 10.7396C16.6349 10.704 16.7547 10.5567 16.7547 10.3867C16.7547 4.51518 9.7847 3.26446 8.16565 3.05318V0.35908C8.16565 0.226318 8.0928 0.10489 7.97541 0.0409374C7.85641 -0.0205864 7.71556 -0.0116817 7.60627 0.0603659L0.157034 5.07456C0.059082 5.14094 -1.52588e-05 5.25103 -1.52588e-05 5.37327C-1.52588e-05 5.49227 0.059082 5.60318 0.157034 5.66956L7.60222 10.6829C7.71313 10.7558 7.8556 10.7639 7.97218 10.7016C8.09037 10.6384 8.16322 10.5162 8.16322 10.3826V7.48451C9.3346 7.49179 10.3044 7.5436 11.1115 7.64075C14.9851 8.09975 16.0205 10.4255 16.0626 10.5251C16.12 10.6587 16.2512 10.7445 16.3945 10.7445C16.4187 10.7461 16.443 10.7445 16.4649 10.7396Z"
              fill="#6E747A"
            />
          </svg>
        </button>
        <button
          class="flex disabled:opacity-60 items-center rounded-right border-left-0 justify-center h-[36px] w-[41px] border bg-white p-0"
          :disabled="!isActiveRedo"
          @click="redo"
        >
          <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.28981 10.7396C0.11981 10.704 0 10.5567 0 10.3867C0 4.51518 6.97 3.26446 8.58905 3.05318V0.35908C8.58905 0.226318 8.6619 0.10489 8.77929 0.0409374C8.89829 -0.0205864 9.03914 -0.0116817 9.14843 0.0603659L16.5977 5.07456C16.6956 5.14094 16.7547 5.25103 16.7547 5.37327C16.7547 5.49227 16.6956 5.60318 16.5977 5.66956L9.15248 10.6829C9.04157 10.7558 8.8991 10.7639 8.78252 10.7016C8.66433 10.6384 8.59148 10.5162 8.59148 10.3826V7.48451C7.4201 7.49179 6.45029 7.5436 5.64319 7.64075C1.76962 8.09975 0.734238 10.4255 0.692143 10.5251C0.634667 10.6587 0.503524 10.7445 0.360238 10.7445C0.335952 10.7461 0.311667 10.7445 0.28981 10.7396Z"
              fill="#6E747A"
            />
          </svg>
        </button>
      </template>

      <template #container-prepend>
        <div
          class="absolute top-0 left-0 h-full w-full bg-top bg-[url('https://www.dropbox.com/s/nrg3r3wkbhv9g7n/Group%20163.jpg?raw=1')]"
        />
      </template>

      <template #first-element>
        <div
          v-show="resolutioner !== 'lg'"
          class="mega-menu-close-icon"
        >
          <i class="fa fa-close" />
        </div>
      </template>
      
      <template #aside>
        <SidbearSettingsGroup
          title="Rows"
          content-class="p-0 no-scrollbar"
        >
          <rows-drag-items class="h-100" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Elements"
          content-class="p-0"
        >
          <elements-drag-items />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Favorites"
          content-class="p-0"
        >
          <favorites-drag-items class="border-0" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Close Icon (Mobile Only)"
        >
          <rule-tabs>
            <template #Normal>
              <b-form-group label="Icon Color">
                <sid-color
                  sid=".mega-menu-close-icon"
                  rule-name="color"
                />
              </b-form-group>

              <sid-background sid=".mega-menu-close-icon" />

              <sid-range-slider
                label="Icon Size"
                sid=".mega-menu-close-icon"
                rule-name="font-size"
                default-dimension="px"
                :dimensions="[]"
              />

              <sid-align
                label="Icon Alignment"
                sid=".mega-menu-close-icon"
                rule-name="text-align"
              />

              <sid-linked-group
                label="Padding"
                sid=".mega-menu-close-icon"
                rule-name="padding"
                :min="0"
                :angles="[
                  {
                    text: '',
                    value: 'top'
                  },
                  {
                    text: '',
                    value: 'right'
                  },
                  {
                    text: '',
                    value: 'bottom'
                  },
                  {
                    text: '',
                    value: 'left'
                  }
                ]"
              />
            </template>

            <template #Hover>
              <b-form-group label="Icon Color">
                <sid-color
                  sid=".mega-menu-close-icon"
                  rule-name="color"
                  presudo=":hover"
                />
              </b-form-group>
            </template>
          </rule-tabs>
        </SidbearSettingsGroup>
      </template>
    </module-editor>
    <div
      v-else
      class="flex justify-center items-center vh-100"
    >
      <b-spinner variant="primary" />
    </div>
  </div>
</template>

<script>
import ModuleEditor from '@/views/module-editor/ModuleEditor'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import RowsDragItems from '@/components/editor/components/RowsDragItems'
import ElementsDragItems from '@/components/editor/components/ElementsDragItems'
import FavoritesDragItems from '@/components/editor/components/FavoritesDragItems'
import {mapState} from 'vuex'
import {applyPageStyles, pageStylesToString} from '@/utils/useStylesheet'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import UndoRedoSupport from '@/components/mixins/UndoRedoSupport'
export default {
  name: 'MegaMenuEditor',
  components: {
    SidBackground,
    SidLinkedGroup,
    SidAlign,
    SidRangeSlider,
    RuleTabs,
    SidColor, FavoritesDragItems, ElementsDragItems, RowsDragItems, SidbearSettingsGroup, ModuleEditor},
  mixins: [ResolutionMixin, UndoRedoSupport],
  data () {
    return {
      id: null,
      loading: true,
      name: '',
      content: []
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    getTargetContent: {
      get () {
        return this.content
      },
      set (val) {
        this.content = val
      }
    }
  },
  created () {
    this.$store.commit('editor/SET_PAGE', '')

    axios.get(`api/projects/${this.project.id}/mega-menu/${this.$route.params.menu_id}`)
      .catch(() => {
        return this.$swal({
          icon: 'error',
          title: 'Not Found',
          showConfirmButton: false
        })
      })
      .then(({data}) => {
        this.loading = false
        this.id = data.data.id
        this.name = data.data.name
        this.content = JSON.parse(data.data.content)
        applyPageStyles(data.data.css, 'subject-styles')
        this.initUndoRedoSupport()
      })
  },

  methods: {
    onSave () {
      VEvent.fire('page.save')

      this.loading = true
      
      axios.put(`api/projects/${this.project.id}/mega-menu/${this.$route.params.menu_id}`, {
        name: this.name,
        content: JSON.stringify(this.content),
        css: pageStylesToString('subject-styles')
      })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Mega Menu Saved!',
            showConfirmButton: false,
            timer: 2000
          })
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Server Error',
            showConfirmButton: false
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    onGroupChanged (isOpen, page) {
      this.$store.commit('editor/SET_PAGE', isOpen ? page : '')
    }
  }
}
</script>

<style lang="scss">
.mega-menu-close-icon {
  position: relative;
  z-index: 1;
  background: white;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  text-align: right;
  color: #2d2d2d;
  transition: color .4s;

  &:hover {
    color: rgba(45, 45, 45, 0.5);
  }
}
</style>