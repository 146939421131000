<template>
  <div
    :style="{...padding, ...backgroundColor, ...shadow}"
    class="blog-post-grid-section"
  >
    <div :class="container">
      <div
        v-if="loading"
        class="text-center"
      >
        <b-spinner variant="primary" />
      </div>

      <template v-else-if="getBlogPosts.length > 0">
        <b-row deck>
          <b-col
            v-for="(post, index) in getBlogPosts.slice(0, section.options.content.pagination.perPage)"
            :key="index"
            :class="cols('mb-5', 'mb-4')"
            :cols="cols(4, 12)"
          >
            <b-card
              body-class="d-flex flex-column"
              class="h-100 border-0"
            >
              <image-fit
                :customs="{}"
                :src="post.cover.src"
                class="mb-3"
                height="180px"
                size="cover"
              />
              <p
                :style="section.options.content.date"
                class="font-size-14"
              >
                {{
                  post.created_at | moment("from", "now")
                }}
              </p>
              <b-card-title
                :style="section.options.content.title"
                v-text="post.title"
              />
              <b-card-text
                :style="section.options.content.description"
                class="line-clamp h-100"
              >
                {{ post.description | strippedContent }}
              </b-card-text>

              <div
                v-if="button"
                class="button-wrapper mt-auto"
              >
                <pub-es-button :customs="button.options.content.button" />
              </div>
            </b-card>
          </b-col>
        </b-row>

        <div
          v-if="blog.data.total > section.options.content.pagination.perPage"
          class="d-flex justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :per-page="section.options.content.pagination.perPage"
            :total-rows="blog.data.total"
            aria-controls="my-table"
          >
            <template #page="{ page }">
              <div :style="section.options.content.pagination">
                {{ page }}
              </div>
            </template>
          </b-pagination>
        </div>
      </template>

      <div
        v-else
        class="d-flex justify-content-center align-items-center min-vh-50"
      >
        <div
          :style="{color: globalStyles.colors.primary}"
          class="text-center"
        >
          <i class="fa fa-compass fa-7x mb-3" />
          <h4 class="h2 font-weight-bold">
            Blog is empty
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../../mixins/TemplateMixin'
import {mapState} from 'vuex'
import ImageFit from '../../../../utils/ImageFit'
import RouterMixin from '../../../../mixins/RouterMixin'
import PubEsButton from '@/components/builder/utils/buttons/PubEsButton'

export default {
  name: 'FeaturedItems4Template',
  components: {PubEsButton, ImageFit},

  filters: {
    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/ig, ' ')
    }
  },
  mixins: [TemplateMixin, RouterMixin],

  data () {
    return {
      loading: true,
      blog: {},
      currentPage: 1
    }
  },

  computed: {
    button () {
      return this.section.options.content.button.subject
    },
    byCategory () {
      return this.section.options.content.pagination.byCategory || ''
    },
    ...mapState('projects', {
      project: state => state.project
    }),
    getBlogPosts () {
      const posts = this.blog.data.data
      if (posts.length === 0) return []

      return posts.map(post => {
        return {
          ...post,
          cover: JSON.parse(post.cover)
        }
      })
    }
  },

  watch: {
    currentPage (val) {
      this.fetchBlog(val)
    },
    byCategory () {
      this.fetchBlog()
    }
  },

  created () {
    this.fetchBlog()

    VEvent.listen('es-blog-updated', post => this.blog.data.data.unshift(post))
  },

  methods: {
    openPost (post) {
      if (this.$route.name === 'preview') {
        return window.open(`${this.getSiteHome}/blog/${post.slug}`, '_blank')
      }
      this.$router.push({name: 'blog.post', params: {slug: post.slug}})
    },
    async fetchBlog (page = 1) {
      this.loading = true
      await axios.get(`api/projects/${this.project.id}/blog?page=${page}&perPage=${this.section.options.content.pagination.perPage}&categoryId=${this.byCategory}`)
        .then(({data}) => {
          this.blog = data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang='scss'>
.blog-post-grid-section {
  p {
    font-size: 1rem;
  }

  img {
    height: 180px;
  }

  .button-wrapper {
    .d-flex {
      justify-content: start !important;
    }
  }
}
</style>