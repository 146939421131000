<template>
  <div
    :style="{...fullPaddingSubject(section.options.customize.style), ...fullMarginSubject(section.options.content.socialLinks)}"
  >
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin, BorderMixin]
}
</script>
