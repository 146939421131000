<template>
  <div class="post-text-container w-[100px]">
    <highliter
      class="el-dynamic"
      value="Post Text"
      hide-add
      hide-right
    >
      <p
        class="post-text"
        v-text="text"
      />
    </highliter>
  </div>
</template>

<script>
import Highliter from '@/components/builder/utils/Highliter.vue'

export default {
  name: 'PostText',
  components: {Highliter},
  props: {
    settings: {
      type: Object
    }
  },
  computed: {
    text () {
      const text = 'This setting will be the default for every new post you create. It can be turned on/off for each individual post This setting will be the default for every new post you create'

      if (!this.settings.maxExcerptWords) return text

      // Split the text into an array of words
      const words = text.split(' ')

      // Extract the first "limit" words
      const limitedWords = words.slice(0, this.settings.maxExcerptWords)

      // Join the words back into a string
      const limitedText = limitedWords.join(' ')

      // Add ellipsis if the text was truncated
      if (words.length > this.settings.maxExcerptWords) {
        return `${limitedText}...`
      }

      return limitedText
    }
  }
}
</script>

<style>
.post-text-container {
    width: 100%;
    --width: 100%;
}

.post-text {
    font-weight: 400;
    font-size: 15px;
    color: #4F4F4F;
    padding: 0 20px;
}
</style>