<template>
  <es-dropdown
    :disabled="disabled"
    :dropdown-style="menu.dropdownMenuStyles"
    :placement="dropdownPlacement"
  >
    <template v-slot:activator>
      <div :class="menu.hoverAnimation.lg">
        <div
          class="transitioner el-menu-link-background absolute w-full h-full top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
          :class="menu.backgroundColor.shape"
          :style="{backgroundColor, ...getMenuBorderStyle}"
        />
        <div
          :style="{color, fontSize, fontWeight, fontFamily, fontStyle, textDecoration, ...fullPaddingSubject(dropdownItem ? menu.dropdownMenuStyles : {}, 'paddingItem', dropdownItem ? [15,8,15,8] : [0,0,0,0])}"
          class="cursor-pinter el-menu-link transitioner"
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
          @click.stop="onClick"
        >
          <div
            class="d-flex align-items-center px-2"
          >
            <i
              v-if="link.icon && !link.iconAppend"
              :class="link.icon"
              class="mr-2"
            />

            <span
              v-text="link.text"
            />

            <i
              v-if="link.icon && link.iconAppend"
              :class="link.icon"
              class="ml-2"
            />

            <i
              v-if="link.children && link.children.length"
              class="fa fa-angle-down mx-2"
            />
          </div>
        </div>
      </div>
    </template>

    <div
      v-if="link.children"
      class="d-flex flex-column"
      :style="gap"
    >
      <menu-link-v3
        v-for="(link, index) in link.children"
        :key="index"
        :link="link"
        :menu="menu"
        :dropdown-item="true"
      />
    </div>
  </es-dropdown>
</template>

<script>
import _ from 'lodash'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import {mapState} from 'vuex'
import EsDropdown from '@/components/utils/estage-dropdown/EsDropdown'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'MenuLinkV3',
  components: {EsDropdown},
  mixins: [ResolutionMixin, BorderMixin],
  props: {
    dropdownItem: {
      type: Boolean,
      default: false
    },
    link: {
      type: Object,
      required: true
    },
    menu: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isHover: false
    }
  },
  computed: {
    ...mapState({
      globalStyles: state => state.editor.globalStyles,
      page: state => state.pages.page
    }),
    disabled () {
      return !this.link.children || !this.link.children.length
    },
    getMenuBorderStyle () {
      if (!this.dropdownItem) return {}

      return {
        ...this.radiusSubject(this.menu.dropdownMenuStyles.borderItem, [0,0,0,0]),
        ...this.borderSubject(this.menu.dropdownMenuStyles.borderItem, [0,0,0,0], 'transparent')
      }
    },
    dropdownPlacement () {
      return _.get(this.menu, ['dropdownPlacement', this.resolutioner], _.get(this.menu, ['dropdownPlacement', 'lg'], 'bottom-start'))
    },
    isActive () {
      return _.get(this.link, ['href', 'id'], false) === this.page.id
    },
    gap () {
      return {
        gap: `${_.get(this.menu.dropdownMenuStyles, ['gap', this.resolutioner], _.get(this.menu.dropdownMenuStyles, ['gap', 'lg'], 0))}px`
      }
    },
    backgroundColor () {
      if (this.isHover) {
        if (this.dropdownItem) {
          return this.menu.dropdownMenuItemStyles.backgroundColor.hover.color
        }

        return this.menu.backgroundColor.hover.color
      }

      if (this.isActive) {
        return this.menu.backgroundColor.active.color || this.menu.backgroundColor.color
      }

      if (this.dropdownItem) {
        return this.menu.dropdownMenuItemStyles.backgroundColor.color
      }

      return this.menu.backgroundColor.color
    },
    color () {
      const _color = _.get(this.menu.color.color, this.resolutioner, _.get(this.menu.color.color, 'lg', this.menu.color.color))

      if (this.isHover) {
        if (this.dropdownItem) {
          return this.menu.dropdownMenuItemStyles.color.hover.color || _color
        }

        if (this.menu.color.hover.color === null) return _color
        return this.menu.color.hover.color
      }

      if (this.isActive) {
        return this.menu.color.active.color || _color
      }

      if (this.dropdownItem) {
        return this.menu.dropdownMenuItemStyles.color.color || _color
      }

      return _color
    },
    fontSize () {
      if (this.isHover) {
        if (this.menu.fontSize.hover[this.resolutioner] === null) return `${this.menu.fontSize[this.resolutioner] || this.menu.fontSize.lg}px`
        return `${this.menu.fontSize.hover[this.resolutioner] || this.menu.fontSize.lg}px`
      }

      return `${this.menu.fontSize[this.resolutioner] || this.menu.fontSize.lg}px`
    },
    fontWeight () {
      const _fontWeight = this.menu.fontWeight.lg || this.globalStyles.textStyles.link.fontWeight[this.resolutioner]

      if (this.isHover) {
        if (this.menu.fontWeight.hover[this.resolutioner] === null) return this.menu.fontWeight[this.resolutioner] || _fontWeight
        return this.menu.fontWeight.hover[this.resolutioner] || _fontWeight
      }

      if (this.isActive && this.menu.bold.active.bold) {
        return this.menu.bold.active.bold ? 'bold' : null
      }

      return this.menu.fontWeight[this.resolutioner] || _fontWeight
    },
    fontFamily () {
      const _fontFamily = this.menu.fontFamily.lg || this.globalStyles.textStyles.link.fontFamily[this.resolutioner]

      if (this.isHover) {
        if (this.menu.fontFamily.hover[this.resolutioner] === null) return this.menu.fontFamily[this.resolutioner] || _fontFamily
        return this.menu.fontFamily.hover[this.resolutioner] || _fontFamily
      }

      return this.menu.fontFamily[this.resolutioner] || _fontFamily
    },
    fontStyle () {
      if (this.isHover) {
        if (this.menu.italic.hover.italic === null) return this.menu.italic.italic ? 'italic' : null
        return this.menu.italic.hover.italic ? 'italic' : null
      }

      if (this.isActive && this.menu.italic.active.italic) {
        return this.menu.italic.active.italic ? 'italic' : null
      }

      return this.menu.italic.italic ? 'italic' : null
    },
    textDecoration () {
      if (this.isHover) {
        if (this.menu.underline.hover.underline === null) return this.menu.underline.underline ? 'underline' : null
        return this.menu.underline.hover.underline ? 'underline' : null
      }

      if (this.isActive && this.menu.underline.active.underline) {
        return this.menu.underline.active.underline ? 'underline' : null
      }

      return this.menu.underline.underline ? 'underline' : null
    }
  },
  methods: {
    onClick (e) {
      e.preventDefault()
      const pageID = _.get(this.link, 'href.id', null)
      const pageSlug = _.get(this.link, 'href.slug', null)

      if (pageID === 'blog') {
        return this.$router.push({ name: 'blog.manage-posts' })
      }

      if (pageSlug) {
        VEvent.fire('loader', true)
        return this.$store.dispatch('projects/save').then(() => {
          setTimeout(() => {
            this.$store.dispatch('pages/loadPage', pageSlug).finally(() => {
              this.$store.commit('editor/SET_PAGE', 'sections')
              VEvent.fire('loader', false)
            })
          }, 100)
        })
      }
    }
  }
}
</script>

<style lang="scss">
.el-menu-link {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 120%;
    height: 120%;
  }
}
</style>