<template>
  <b-form-group label="Easing">
    <v-select
      v-model="easing"
      :options="options"
      :searchable="false"
      :reduce="val => val.value"
    />
  </b-form-group>
</template>

<script>
export default {
  name: 'MotionEase',
  props: {
    subjectMotions: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      options: [
        {
          value: 'linear',
          label: 'Linear'
        },
        {
          value: 'ease',
          label: 'Ease'
        },
        {
          value: 'ease-in',
          label: 'Ease In'
        },
        {
          value: 'ease-out',
          label: 'Ease Out'
        },
        {
          value: 'ease-in-out',
          label: 'Ease In Out'
        }
      ]
    }
  },
  computed: {
    easing: {
      get () {
        return this.subjectMotions.easing || 'linear'
      },
      set (val) {
        this.$set(this.subjectMotions, 'easing', val)
      }
    }
  }
}
</script>