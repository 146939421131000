<template>
  <b-form-group>
    <div
      v-if="!hideCheckbox"
      class="flex justify-between items-center"
    >
      <label class="pb-0">Lottie Icon</label>
      <b-checkbox
        v-model="customIcon"
        switch
        value="lottie"
      />
    </div>

    <lottie-config
      :config="config.lottie"
      :sid="sid"
    />
  </b-form-group>
</template>

<script>
import LottieConfig from '@/components/editor/components/LottieConfig'

export default {
  name: 'SidLottieIcon',
  components: {LottieConfig},
  props: {
    sid: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      default: () => {
      }
    },
    hideCheckbox: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    customIcon: {
      get () {
        return this.config.customIcon || false
      },
      set (val) {
        this.$set(this.config, 'customIcon', val)
      }
    }
  }
}
</script>