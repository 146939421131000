<template>
  <div class="position-relative">
    <highliter
      v-model="nameModifire"
      :dublicate="section"
      :dublicate-deep="parentUID"
      :style="{...marginTop, ...marginBottom}"
      :uid="section.uid"
      :hide-favorite="section.group === 'Footer'"
      :hide-copy="section.group === 'Footer'"
      :hide-move="section.group === 'Footer'"
      class="highlight-section"
      @delete="$emit('delete')"
      @dublicate="$emit('dublicate')"
      @favorite="$emit('favorite', $el)"
      @flow="handleFlow"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
      @click.stop.native="!section.options.content.items.children.length ? addRow() : sectionSettings('blank-mod')"
    >
      <Template
        :class="{'element-hidden': !blankVisability}"
        :section="section"
      >
        <template slot="overlays">
          <div
            :style="{...backgroundColor, ...radiusSubject(section.options.customize.style, [0,0,0,0])}"
            class="image-background"
          />
          <div
            :style="{...backgroundImage, ...radiusSubject(section.options.customize.style, [0,0,0,0])}"
            class="image-background"
          />

          <background-slider
            :options="backgroundSlider"
            :styles="backgroundImage"
          />

          <div
            :style="{...backgroundImageOverlay}"
            class="image-background"
          />

          <float-image
            v-if="floatImage.enabled"
            :config="floatImage"
          />
        </template>
        <draggable
          v-model="section.options.content.items.children"
          :class="{'empty-section-drag': !section.options.content.items.children.length}"
          :group="{ name: 'rows', pull: true, put: ['rows'] }"
          class="children-container w-100"
          handle=".move"
          ghost-class="rows-sortable-ghost"
          :scroll="true"
          :force-fallback="true"
          fallback-class="rows-sortable-fallback"
          :force-autoscroll-fallback="true"
          :scroll-sensitivity="60"
          :bubble-scroll="true"
          :fallback-on-body="true"
          @end="onDragEnd"
          @start="onDragStart"
          @add="onAddedRow"
        >
          <component
            :is="section.component"
            v-for="(section, index) in section.options.content.items.children"
            :key="section.uid"
            :section="section"
            @delete="() => deleteSection(index)"
            @dublicate="() => dublicateElement(index)"
            @favorite="() => favoriteBlock = JSON.parse(JSON.stringify(section))"
            @flow="handleFlow"
            @add-subject="() => addSubject(index)"
            @move-to="to => moveTo(index, to)"
          >
            <slot />
          </component>
        </draggable>
      </Template>
    </highliter>

    <add-to-favorite-modal
      :favorite="favoriteBlock"
      @submit="favorite"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import shortid from 'shortid'
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditable from '@builder/components/builder/utils/ContentEditable'
import Template from './Template'
import draggable from 'vuedraggable'
import {insert} from '../../../../../sections/utils/helpers'
import FloatImage from '../../../utils/FloatImage'
import ResolutionMixin from '../../../../mixins/ResolutionMixin'
import DraggableElementsHandler from '../../../../mixins/DraggableElementsHandler'
import AddToFavoriteModal from '../../../components/modals/AddToFavoriteModal'
import {blockWidth} from '@/composition'
import BackgroundSlider from '@/components/builder/utils/BackgroundSlider'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'ActionItem2',

  components: {BackgroundSlider, AddToFavoriteModal, FloatImage, Template, ContentEditable, Highliter, draggable},

  mixins: [SectionMixin, ResolutionMixin, DraggableElementsHandler, BorderMixin],

  data () {
    return {
      name: '',
      favoriteBlock: {}
    }
  },

  computed: {
    blankVisability () {
      if (this.section.display === true || this.section.display === undefined) {
        return true
      }

      if (typeof this.section.display === 'object') {
        return !this.section.display.length || this.section.display.includes(this.resolutioner)
      }

      return this.section.display
    },
    marginTop () {
      if (!this.section.options.customize.style.hasOwnProperty('marginTop')) {
        this.$set(this.section.options.customize.style, 'marginTop', {
          sm: 0,
          lg: 0
        })
      }

      return {
        marginTop: this.section.options.customize.style.marginTop[this.resolution] + 'px'
      }
    },
    marginBottom () {
      if (!this.section.options.customize.style.hasOwnProperty('marginBottom')) {
        this.$set(this.section.options.customize.style, 'marginBottom', {
          sm: 0,
          lg: 0
        })
      }

      return {
        marginBottom: this.section.options.customize.style.marginBottom[this.resolution] + 'px'
      }
    },
    nameModifire: {
      get () {
        return this.name
      },
      set (val) {
        this.name = val
        this.$set(this.section, 'name', val)
      }
    },
    floatImage () {
      if (!this.section.options.customize.style.hasOwnProperty('floatImage')) {
        this.$set(this.section.options.customize.style, 'floatImage', {
          display: true,
          enabled: false,
          type: 'float-image-customs',
          src: '',
          top: 0,
          x: {left: 0}
        })
      }

      return this.section.options.customize.style.floatImage
    }
  },

  created () {
    if (this.section.display === undefined) {
      this.$set(this.section, 'display', true)
    }

    if (!this.section.options.customize.style.hasOwnProperty('blockWidth')) {
      this.$set(this.section.options.customize.style, 'blockWidth', JSON.parse(JSON.stringify(blockWidth)))
    }

    this.name = _.clone(this.section.name)
  },

  methods: {
    onAddedRow ({newIndex}) {
      if (this.section.options.content.items.children[newIndex].name !== 'Flex Wrapper') return

      this.$store.commit('editor/SET_PAGE', {
        name: 'add-element',
        event: 'click',
        subject: {
          subject: this.section.options.content.items.children[newIndex].options.content.cols[0],
          options: {subject: this, originKey: 'elements'}
        }
      })
    },
    addRow () {
      this.$store.commit('editor/SET_PAGE', {
        name: 'add-row',
        event: 'click',
        subject: {
          subject: this.section.options.content.items,
          options: {subject: this, originKey: 'children'}
        }
      })
    },
    blank () {
      return this.$el
    },
    deleteSection (section) {
      this.section.options.content.items.children.splice(section, 1)
    },
    addSubject (index) {
      this.$store.commit('editor/SET_PAGE', {
        name: 'add-row',
        event: 'click',
        subject: {
          subject: this.section.options.content.items,
          options: {index, originKey: 'children'}
        }
      })
    },
    dublicateElement (element) {
      this.section.options.content.items.children = insert(this.section.options.content.items.children, element, JSON.parse(JSON.stringify({
        ...this.section.options.content.items.children[element],
        uid: shortid.generate()
      })))
    },
    moveTo (element, to) {
      const copySection = JSON.parse(JSON.stringify(this.section.options.content.items.children[element]))

      if (to === 'up') {
        if (element === 0) return
        this.section.options.content.items.children.splice(element, 1)
        this.section.options.content.items.children = insert(this.section.options.content.items.children, element - 1, copySection)
      } else if (to === 'down') {
        if (element + 1 === this.section.options.content.items.children.length) return
        this.section.options.content.items.children.splice(element, 1)
        this.section.options.content.items.children = insert(this.section.options.content.items.children, element + 1, copySection)
      }
    },
    favorite (element) {
      this.$store.dispatch('favorites/addFavorite', {
        favorite: {
          section: element.favorite,
          group: 'rows'
        },
        inGlobal: element.globally
      })
    },
    debug () {
      console.log(this.section.options)
    }
  }
}
</script>

<style lang="scss">
.empty-section-drag {
  height: 200px;
  border: 1px solid #82CEC9;
  position: relative;
  cursor: pointer;

  &:before {
    content: '+';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    color: #82CEC9;
  }
}
</style>
