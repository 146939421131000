<template>
  <div>
    <b-form-group
      label="Text"
    >
      <b-input v-model="text" />
    </b-form-group>

    <b-form-group
      label="Subtext"
    >
      <b-input v-model="subtext" />
    </b-form-group>
  </div>
</template>

<script>
import ModControlMixin from '@/components/mixins/ModControlMixin'
import ModControlHoverMixin from '@/components/mixins/ModControlHoverMixin'
import _ from 'lodash'

export default {
  name: 'ButtonHoverText',
  mixins: [ModControlMixin, ModControlHoverMixin],
  computed: {
    text: {
      get () {
        return _.get(this.hoverConfig, 'text', this.config.text)
      },
      set (val) {
        this.$set(this.config, 'hover', {..._.set(this.hoverConfig, 'text', val)})
      }
    },
    subtext: {
      get () {
        return _.get(this.hoverConfig, 'subtext', this.config.subtext)
      },
      set (val) {
        this.$set(this.config, 'hover', {..._.set(this.hoverConfig, 'subtext', val)})
      }
    }
  }
}
</script>