<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element"
    :dublicate-deep="parentUID"
    :dublicate="section"
    :data-sid="section.sid"
    @add-subject="$emit('add-subject')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings('lottie')"
  >
    <div
      class="d-flex"
      :class="`--lottie-container-${section.sid}`"
    >
      <div
        class="w-full el-lottie"
        :class="`--lottie-${section.sid}`"
      >
        <div
          v-if="showRender"
          ref="renderEl"
        />
        <div v-else>
          <div class="bg-gray-300 h5 p-4 text-center rounded">
            Lottie Animations
          </div>
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import lottie from 'lottie-web'
import SectionMixin from '@/components/mixins/SectionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'
import CustomStylesMixin from '@/components/mixins/CustomStylesMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElLottie',
  components: {Highliter},
  mixins: [
    SectionMixin, 
    BorderMixin, 
    CustomStylesMixin
  ],
  data () {
    return {
      showRender: false
    }
  },
  computed: {
    lottie () {
      return this.section.options.content.lottie
    }
  },
  watch: {
    lottie: {
      deep: true,
      handler: function (val) {
        this.showRender = false
        if (val.jsonPath) {
          setTimeout(() => {
            this.showRender = true
            this.render()
          })
        }
      }
    }
  },
  mounted () {
    if (this.lottie.jsonPath) {
      this.showRender = true
      this.render()
    }
  },
  methods: {
    render () {
      setTimeout(() => {
        lottie.loadAnimation({
          container: this.$refs.renderEl,
          renderer: this.lottie.renderer,
          loop: this.lottie.loop,
          autoplay: this.lottie.autoplay,
          path: this.lottie.jsonPath
        })
      })
    }
  }
}
</script>

<style>
.el-lottie {
  --width:100%;
  --width-fixed: '';
  --height-fixed: '';
}
</style>