<template>
  <div>
    <upload-custom-font />

    <template v-if="allCustomFonts.length">
      <div class="font-[600] text-[16px] mb-[28px]">
        Installed Fonts
      </div>

      <transition-group
        name="transition"
        class="flex flex-column w-full gap-[12px] mb-[34px]"
      >
        <editable-custom-font
          v-for="(font, index) in getEditableGroupFonts"
          :key="font.id"
          :font="font"
          @onDelete="() => onDelete(index)"
        />
      </transition-group>

      <div class="flex gap-[18px] flex-wrap">
        <custom-font-card
          v-for="group in allCustomFonts"
          :key="group.id"
          :group="group"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import UploadCustomFont from '@/views/dashboard/fonts/custom-fonts/UploadCustomFont'
import EditableCustomFont from '@/views/dashboard/fonts/custom-fonts/EditableCustomFont'
import CustomFontCard from '@/views/dashboard/fonts/custom-fonts/CustomFontCard'

export default {
  name: 'CustomFonts',
  components: {CustomFontCard, EditableCustomFont, UploadCustomFont},
  computed: {
    ...mapState('font', {
      editableCustomFont: state => state.editableCustomFont,
      allCustomFonts: state => state.allCustomFonts
    }),
    getEditableGroupFonts () {
      if (!this.editableCustomFont) return []
      return this.editableCustomFont.fonts
    }
  },
  methods: {
    onDelete (index) {
      this.getEditableGroupFonts.splice(index, 1)
    }
  }
}
</script>