import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'FieldPhone'
export const group = ''

export default class ElFieldPhone extends SectionProvider {
  constructor (options = {}) {
    super('ElFieldPhone', options)

    this.name = name
    this.group = group
  }
}