<template>
  <div>
    <image-upload
      :editabel="false"
      :image="background"
      @uploaded="apply"
      @imageDeleted="apply"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import ImageUpload from '../utils/ImageUpload'

export default {
  name: 'TiptapBackground',

  components: {ImageUpload},

  props: {
    config: {
      type: Object,
      required: true
    },
    value: {
      type: [String, Array, Object]
    }
  },

  computed: {
    background () {
      if (_.isEmpty(this.value)) {
        return {
          src: ''
        }
      }

      return {
        src: JSON.stringify(this.value).match(/\((.*?)\)/)[1].match(/\\"(.*?)\\"/)[1]
      }
    }
  },

  methods: {
    apply (val) {
      this.$emit('apply', _.get(val, 'src', false))
    }
  }
}
</script>
