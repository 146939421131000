<template>
  <div
    class="el-inline-wrapper"
    :style="{...fullMarginSubject(section.options.content.inline), ...fullPaddingSubject(section.options.content.inline)}"
  >
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'ImageItem1Template',
  mixins: [TemplateMixin, BorderMixin]
}
</script>
