<template>
  <div class="relative flex flex-col px-[40px] mt-[30px] mb-[50px]">
    <div
      v-if="loading"
      class="w-full h-[250px] flex items-center justify-center "
    >
      <b-spinner
        class="!w-12 !h-12"
        variant="primary"
      />
    </div>
    <template v-else>
      <div class="grid-cols-4 gap-x-[16px] gap-y-[12px]">
        <div
          v-for="(folder, index) in folders"
          :key="`${folder.id}_${index}`"
        >
          <video-lib-folder
            :folder="folder"
            @click="openFolder"
          />
        </div>
      </div>
      <div class="grid-cols-4 gap-x-[16px] gap-y-[12px] mt-[30px]">
        <div
          v-for="(file, index) in files"
          :key="`${file.id}_${index}`"
        >
          <video-lib-file
            :file="file"
            :active="activeFile && activeFile.id === file.id"
            @click="selectFile"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import VideoLibFolder from '@/components/video-library/elements/VideoLibFolder.vue'
import VideoLibFile from '@/components/video-library/elements/VideoLibFile.vue'
export default {
  components: {
    VideoLibFolder,
    VideoLibFile
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    folders: {
      type: Array,
      default: null
    },
    files: {
      type: Array,
      default: null
    },
    activeFile: {
      type: Object,
      default: null
    },
    openFolder: Function,
    selectFile: Function
  }
}
</script>

<style lang="scss">
  .grid-cols-4 {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
</style>