<template>
  <b-form-group label="Smoothness">
    <v-select
      v-model="motionSmoothnes"
      :options="options"
      :searchable="false"
      :reduce="val => val.value"
      placeholder="Smooth"
    />
  </b-form-group>
</template>

<script>
export default {
  name: 'MotionSmooth',
  props: {
    subjectMotions: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      options: [
        {
          label: 'Extra Smooth',
          value: 4000
        },
        {
          label: 'Super Smooth',
          value: 3000
        },
        {
          label: 'Very Smooth',
          value: 2000
        },
        {
          label: 'Smooth',
          value: 1000
        },
        {
          label: 'Normal',
          value: 500
        },
        {
          label: 'Fast',
          value: 400
        },
        {
          label: 'Very Fast',
          value: 200
        },
        {
          label: 'Extra Fast',
          value: 100
        },
        {
          label: 'No Smooth',
          value: 0
        }
      ]
    }
  },
  computed: {
    motionSmoothnes: {
      get () {
        return this.subjectMotions.smoothnes || 0
      },
      set (val) {
        this.$set(this.subjectMotions, 'smoothnes', val)
      }
    }
  }
}
</script>