import { Mark } from 'tiptap'
import { updateMark, removeMark } from 'tiptap-commands'

export default class TiptapBackgroundColor extends Mark {

  get name () {
    return 'backgroundColor'
  }

  get defaultOptions () {
    return {
      backgroundColor: ['#dbc590']
    }
  }

  get schema () {
    return {
      attrs: {
        backgroundColor: {
          default: '#dbc590'
        }
      },
      parseDOM: this.options.backgroundColor.map(() => ({
        tag: 'mark',
        getAttrs (dom){
          return {
            backgroundColor:dom.style.backgroundColor
          }
        }
      })),
      toDOM:
        node => {
          return ['mark', {
            style: `background-color:${node.attrs.backgroundColor}`
          }, 0]
        }
    }
  }

  commands ({ type }) {
    return (attrs) => {
      if (!attrs) {
        return removeMark(type, attrs)
      }

      return updateMark(type, attrs)
    }
  }
}
