<template>
  <input
    v-model="valueMutation"
    type="text"
    placeholder="-"
    class="border h-[28px] rounded text-[13px] text-[#7E7E7E] pl-[10px]"
    v-bind="$attrs"
    @keypress="numCheck"
  >
</template>

<script>
export default {
  name: 'NumberControl',
  props: {
    value: {
      required: true
    }
  },
  computed: {
    valueMutation: {
      get () {
        return this.value
      },
      set (val) {
        const value = val === '' ? null : val !== '-' ? parseFloat(val) : val
        this.$emit('input', value)
      }
    }
  },
  methods: {
    numCheck (evt) {
      evt = (evt) ? evt : window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 45) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>