<template>
  <div v-if="settings.ad_banner.enabled">
    <hr class="my-4 -mx-6">
    <a
      :href="settings.ad_banner.href"
      target="_blank"
    >
      <b-img
        :src="settings.ad_banner.image.src || settings.ad_banner.image.placeholder"
        fluid
      />
    </a>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Ad',
  computed: {
    ...mapState({
      settings: state => state.blogSettings.settings
    })
  }
}
</script>
