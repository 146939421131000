<template>
  <highliter
    v-model="section.name"
    class="feature-section"
    :uid="section.uid"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings('images')"
  >
    <Price1Template :section="section">
      <highliter
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @dublicate="$emit('dublicate')"
        @delete="$emit('delete')"
        @click.stop.native="sectionSettings('title')"
      >
        <content-editable
          v-model="section.options.content.title.text"
          :uid="tiptapUID('title')"
          :customs="section.options.content.title"
        />
      </highliter>

      <highliter
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @dublicate="$emit('dublicate')"
        @delete="$emit('delete')"
        @click.stop.native="sectionSettings('price')"
      >
        <content-editable
          v-model="section.options.content.price.text"
          :uid="tiptapUID('price')"
          :customs="section.options.content.price"
        />
      </highliter>

      <highliter
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @dublicate="$emit('dublicate')"
        @delete="$emit('delete')"
        @click.stop.native="sectionSettings('description')"
      >
        <content-editable
          v-model="section.options.content.description.text"
          :uid="tiptapUID('description')"
          :customs="section.options.content.description"
        />
      </highliter>

      <highliter
        v-show="visability('list')"
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @dublicate="$emit('dublicate')"
        @delete="$emit('delete')"
        @click.stop.native="sectionSettings('list')"
      >
        <ul class="list-unstyled my-4">
          <li
            v-for="item in section.options.content.list.items"
            :key="item.label"
            class="list-item d-flex align-items-center mb-4"
          >
            <!--            <div :style="{webkitMask: `url(${section.options.customize.style.listIcon.icon}) center / contain no-repeat`, backgroundColor: section.options.customize.style.listIcon.color}"  class="list-icon mr-2" alt="List Icon"></div>-->
            <i
              class="mr-2"
              :style="{color: section.options.customize.style.listIcon.color !== 'adaptive' ? section.options.customize.style.listIcon.color : null}"
              :class="section.options.customize.style.listIcon.icon"
            />
            <editable v-model="item.text" />
          </li>
        </ul>
      </highliter>

      <highliter
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @dublicate="$emit('dublicate')"
        @delete="$emit('delete')"
        @click.stop.native="sectionSettings('button')"
      >
        <es-button :customs="section.options.content.button" />
      </highliter>
    </Price1Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditable from '@builder/components/builder/utils/ContentEditable'
import Price1Template from './Price1Template'
import Editable from '../../../utils/Editable'

export default {
  name: 'PriceItem',

  components: {Editable, Price1Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
