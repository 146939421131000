export default {
  methods: {
    property (obj, name, defaultValue) {
      if (!obj.hasOwnProperty(name)) {
        this.$set(obj, name, defaultValue)
      }

      return obj[name]
    }
  }
}
