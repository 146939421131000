<template>
  <div>
    <b-form-group>
      <b-checkbox v-model="overflowIsEnabled">
        Overflow Hidden
      </b-checkbox>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="horizontalScroll">
        Horizontal Scroll
      </b-checkbox>
    </b-form-group>

    <b-form-group class="mb-0">
      <b-checkbox v-model="horizontalScrollSnapping">
        Horizontal scroll snapping
      </b-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'
import ControlMixin from '@/components/mixins/ControlMixin'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  name: 'FlexScrollCustoms',
  mixins: [ControlMixin, ResolutionMixin],
  computed: {
    scrollConfig () {
      if (!this.config.scroll) {
        this.$set(this.config, 'scroll', {})
      }

      return this.config.scroll
    },
    overflowIsEnabled: {
      get () {
        return _.get(this.scrollConfig, ['overflowIsEnabled', this.resolutioner], _.get(this.scrollConfig, ['overflowIsEnabled', 'lg'], false))
      },
      set (val) {
        this.$set(this.scrollConfig, 'overflowIsEnabled', {..._.set(this.scrollConfig['overflowIsEnabled'] || {}, [this.resolutioner], val)})
      }
    },
    horizontalScroll: {
      get () {
        return _.get(this.scrollConfig, ['horizontalScroll', this.resolutioner], _.get(this.scrollConfig, ['horizontalScroll', 'lg'], false))
      },
      set (val) {
        this.$set(this.scrollConfig, 'horizontalScroll', {..._.set(this.scrollConfig['horizontalScroll'] || {}, [this.resolutioner], val)})
      }
    },
    horizontalScrollSnapping: {
      get () {
        return _.get(this.scrollConfig, ['horizontalScrollSnapping', this.resolutioner], _.get(this.scrollConfig, ['horizontalScrollSnapping', 'lg'], false))
      },
      set (val) {
        this.$set(this.scrollConfig, 'horizontalScrollSnapping', {..._.set(this.scrollConfig['horizontalScrollSnapping'] || {}, [this.resolutioner], val)})
      }
    }
  }
}
</script>
