<template>
  <b-form-group label="Trigger options">
    <date-picker
      v-model="trigger.value"
      class="w-100"
      input-class="form-control"
      :clearable="false"
      value-type="format"
      format="HH:mm:ss"
      type="time"
    />
  </b-form-group>
</template>

<script>
import ModalTriggerMixin from './ModalTriggerMixin'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'ModalOnTimerTrigger',
  components: { DatePicker },
  mixins: [ModalTriggerMixin]
}
</script>
