<template>
  <div class="sticky top-0 z-100">
    <div class="bg-white flex flex-col px-[40px] pt-[10px]">
      <div class="flex items-center justify-between gap-x-[40px] mb-[16px]">
        <div class="flex flex-col shrink">
          <h4 class="text-black text-[35px] leading-[43px] font-bold">
            Estage Videos
          </h4>
          <span class="text-[#6E747A] text-[15px] leading-[18px] font-medium">
            Videos listed are from your
            <b-link
              class="no-underline"
              href="https://video.estage.com"
              target="_blank"
            >
              Video Hosting Library
            </b-link>
          </span>
        </div>
        <div class="flex grow 1200px:grow-0 gap-[12px] justify-end text-end">
          <form-search 
            v-model="filters.search"
            class="grow 1200px:grow-0 w-[420px]"
          />
          <b-btn
            variant="primary"
            type="submit"
            :disabled="!activeFile"
            @click="insert(activeFile)"
          >
            Insert Video
            <svg
              class="ml-1 w-[18px] h-[18px]"
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.6 8.3C16.6 12.884 12.884 16.6 8.3 16.6C3.71604 16.6 0 12.884 0 8.3C0 3.71604 3.71604 0 8.3 0C12.884 0 16.6 3.71604 16.6 8.3ZM8.3 15.3C12.166 15.3 15.3 12.166 15.3 8.3C15.3 4.43401 12.166 1.3 8.3 1.3C4.43401 1.3 1.3 4.43401 1.3 8.3C1.3 12.166 4.43401 15.3 8.3 15.3Z"
                fill="white"
              />
              <path
                d="M11.8999 8.53198V7.26798H9.03592V4.14798H7.64392V7.26798H4.76392V8.53198H7.64392V11.78H9.03592V8.53198H11.8999Z"
                fill="white"
              />
            </svg>
          </b-btn>
        </div>
      </div>
      <div class="justify-self-end text-end text-[15px] leading-[18px] mb-[16px]">
        <div class="text-[#6E747A]">
          Total: <span class="text-black">{{ total }}</span>
        </div>
      </div>
    </div>
    <div class="w-full border-t border-t-[#D7D9DC]">
      <div class="w-full inline-flex gap-[12px] px-[40px] py-[14px] bg-[#F9F9F9]">
        <div
          v-for="(breadcrumb, index) in breadcrumbs"
          :key="index"
        >
          <span
            class="text-[15px] leading-[18px] font-normal"
            :class="[index + 1 !== breadcrumbs.length ? 'cursor-pointer text-[#6e747a]' : 'text-[#A5AEBE]']"
            @click="index + 1 !== breadcrumbs.length ? openFolder(breadcrumb) : null"
          >
            {{ breadcrumb.name }}
          </span>
          <icon
            v-if="index + 1 !== breadcrumbs.length"
            :key="index + '_>'"
            icon="chevron-left.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Icon from '@/components/editor/utils/Icon.vue'
export default {
  components: {Icon},
  props: {
    search: {
      type: String,
      default: null
    },
    breadcrumbs: {
      type: Array,
      default: null
    },
    total: {
      type: Number,
      default: 0
    },
    activeFile: {
      type: Object,
      default: null
    },
    openFolder: Function,
    insert: Function
  },
  computed: {
    ...mapState('videosLibrary', {
      filters: state => state.filters
    })
  }
}
</script>