<template>
  <div class="sid-select">
    <SidFontFamily
      :sid="sid"
      :prefix="prefix"
      rule-name="font-family"
      :editor="editor"
      :presudo="presudo"
      :selected-font="selectedFont.fontFamily"
      :selected-font-weight="selectedFontWeight.fontWeight"
      @onFontChange="val => $emit('onFontChange', val)"
      @onFontWeightChange="val => $emit('onFontWeightChange', val)"
    />
  </div>
</template>

<script>
import SidFontFamily from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFontFamily'
export default {
  name: 'SidFont',
  components: {SidFontFamily},
  props: {
    sid: {
      type: String,
      required: true
    },
    prefix: {
      type: String,
      default: ''
    },
    presudo: {
      type: String,
      default: ''
    },
    editor: {
      type: Boolean,
      default: false
    },
    selectedFont: {
      default: () => {
        return {
          fontFamily: null
        }
      }
    },
    selectedFontWeight: {
      default: () => {
        return {
          fontFamily: null
        }
      }
    }
  }
}
</script>