<template>
  <b-form-group>
    <b-form-group
      label="Blur"
      class="pl-2"
    >
      <div class="flex items-center gap-[10px]">
        <vue-slider
          v-model="blur"
          class="editor-slider-1 !w-[160px]"
          :interval="1"
          :dot-size="17"
          tooltip="none"
          :min="0"
          :max="10"
        />

        <LinkedControlItem
          v-model="blur"
          :min="0"
          :max="10"
          class="d-block !w-[60px]"
        />
      </div>
    </b-form-group>

    <b-form-group
      label="Grayscale"
      class="pl-2"
    >
      <div class="flex items-center gap-[10px]">
        <vue-slider
          v-model="grayscale"
          class="editor-slider-1 !w-[160px]"
          :interval="1"
          :dot-size="17"
          tooltip="none"
          :min="0"
          :max="100"
        />

        <LinkedControlItem
          v-model="grayscale"
          :max="100"
          :min="0"
          class="d-block !w-[60px]"
        />
      </div>
    </b-form-group>

    <b-form-group
      label="Invert"
      class="pl-2"
    >
      <div class="flex items-center gap-[10px]">
        <vue-slider
          v-model="invert"
          class="editor-slider-1 !w-[160px]"
          :interval="1"
          :dot-size="17"
          tooltip="none"
          :min="0"
          :max="100"
        />

        <LinkedControlItem
          v-model="invert"
          :min="0"
          :max="100"
          class="d-block !w-[60px]"
        />
      </div>
    </b-form-group>

    <b-form-group
      label="Sepia"
      class="pl-2"
    >
      <div class="flex items-center gap-[10px]">
        <vue-slider
          v-model="sepia"
          class="editor-slider-1 !w-[160px]"
          :interval="1"
          :dot-size="17"
          tooltip="none"
          :min="0"
          :max="100"
        />

        <LinkedControlItem
          v-model="sepia"
          :min="0"
          :max="100"
          class="d-block !w-[60px]"
        />
      </div>
    </b-form-group>

    <b-form-group
      label="Contrast"
      class="pl-2 mb-0"
    >
      <div class="flex items-center gap-[10px]">
        <vue-slider
          v-model="contrast"
          class="editor-slider-1 !w-[160px]"
          :interval="1"
          :dot-size="17"
          tooltip="none"
          :min="0"
          :max="200"
        />

        <LinkedControlItem
          v-model="contrast"
          :min="0"
          :max="200"
          class="d-block !w-[60px]"
        />
      </div>
    </b-form-group>
  </b-form-group>
</template>

<script>
import _ from 'lodash'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

export default {
  name: 'ImageFilters',
  components: {LinkedControlItem},
  props: {
    config: {
      type: Object,
      required: true
    }
  },

  computed: {
    filters () {
      return _.get(this.config, 'filters', {})
    },
    blur: {
      get () {
        return parseInt(_.get(this.config, 'filters.blur', '0').replace('px', ''))
      },
      set (val) {
        this.$set(this.config, 'filters', {
          ...this.filters,
          blur: `${val}px`
        })
      }
    },
    sepia: {
      get () {
        return parseInt(_.get(this.config, 'filters.sepia', '0').replace('%', ''))
      },
      set (val) {
        this.$set(this.config, 'filters', {
          ...this.filters,
          'sepia': `${val}%`
        })
      }
    },
    contrast: {
      get () {
        return parseInt(_.get(this.config, 'filters.contrast', '100').replace('%', ''))
      },
      set (val) {
        this.$set(this.config, 'filters', {
          ...this.filters,
          'contrast': `${val}%`
        })
      }
    },
    grayscale: {
      get () {
        return parseInt(_.get(this.config, 'filters.grayscale', '0').replace('%', ''))
      },
      set (val) {
        this.$set(this.config, 'filters', {
          ...this.filters,
          grayscale: `${val}%`
        })
      }
    },
    invert: {
      get () {
        return parseInt(_.get(this.config, 'filters.invert', '0').replace('%', ''))
      },
      set (val) {
        this.$set(this.config, 'filters', {
          ...this.filters,
          invert: `${val}%`
        })
      }
    }
  }
}
</script>
