<template>
  <div
    class="pt-[20px]"
  >
    <draggable
      v-model="orderColumns"
      handle=".move"
    >
      <transition-group name="shufle">
        <div
          v-for="(column, index) in orderColumns.slice(0, columnsToShow)"
          :key="`key-${index}`"
          class="flex items-center border rounded-[2px] text-[15px] text-[#7E7E7E] h-[38px] gap-[11px] pl-[14px] mb-[17px]"
        >
          <svg
            class="move cursor-move"
            fill="none"
            height="10"
            viewBox="0 0 11 10"
            width="11"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.57745 0H0.568359V3H3.57745V0Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 0H7.58936V3H10.5984V0Z"
              fill="#3D82EA"
            />
            <path
              d="M3.57745 7H0.568359V10H3.57745V7Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 7H7.58936V10H10.5984V7Z"
              fill="#3D82EA"
            />
          </svg>

          Column {{ column + 1 }}

          <button
            class="flex h-[36px] items-center justify-center border-left w-[38px] ml-auto p-0 hover:bg-gray-100"
            @click="sectionSettings('column-mod', {subjectIndex: index, title: `Column ${index + 1}`, back: () => {sectionSettings('row-mod')}})"
          >
            <svg
              fill="none"
              height="10"
              viewBox="0 0 7 10"
              width="7"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.879944 9.23245L4.98805 4.89924L0.879944 0.566031"
                stroke="#6E747A"
                stroke-width="1.5"
              />
            </svg>
          </button>
        </div>
      </transition-group>
    </draggable>

    <b-form-group>
      <b-checkbox v-model="overflow">
        Overflow hidden
      </b-checkbox>
    </b-form-group>

    <b-form-group>
      <b-checkbox
        v-model="config.options.customize.vh100"
      >
        Stretch row to screen height
      </b-checkbox>
    </b-form-group>


    <AlignRow :config="config.options.customize.style.alignRow" />

    <background-customs :config="config.options.customize.style.background" />

    <SidbearSettingsGroup
      class="border-top"
      title="Background Image"
    >
      <background-image-customs :config="config.options.customize.style.backgroundImage" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Shape Divider"
    >
      <shape-divider-customs :config="config.options.customize.style" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <shadow-customs :config="config.options.customize.style" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Border & Radius"
    >
      <border-customs
        :config="config.options.customize.style"
        :default-border="[0,0,0,0]"
        :default-radius="[0,0,0,0]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="config.options.customize.style.padding" />
      <margin-full-customs :config="config.options.customize.style.marginTop" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Device Visibility"
      content-class="py-0"
    >
      <Panel
        :config="display"
        class="bg-white"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import draggable from 'vuedraggable'
import {mapState} from 'vuex'
import AlignRow from '@/components/editor/controls/AlignRowCustoms'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import Panel from '@/components/editor/controls/Panel'

export default {
  name: 'RowMod',
  components: {Panel, SidbearSettingsGroup, AlignRow, draggable},
  mixins: [ModMixin],

  data () {
    return {
      activeColumn: false
    }
  },

  computed: {
    ...mapState('editor', {
      resolutionMut: state => state.resolution,
      tablet: state => state.tablet
    }),
    resolutioner () {
      return this.tablet ? 'md' : this.resolutionMut
    },
    display () {
      if (!this.section.display) {
        this.$set(this.section, 'display', true)
      }

      return this.section
    },
    columns () {
      return new Array(this.config.customize.columnsToShow)
        .fill({})
        .map((val, key) => this.config.options.content.columns[this.orderColumns[key]])
    },
    overflow: {
      get () {
        return this.config.options.content.overflow
      },
      set (val) {
        this.$set(this.config.options.content, 'overflow', val)
      }
    },
    orderColumns: {
      get () {
        return this.config.options.customize.orderColumns[this.resolutioner]
      }
      ,
      set (val) {
        this.$set(this.config.options.customize.orderColumns, this.resolutioner, val)
      }
    },
    columnsToShow: {
      get () {
        return this.config.options.customize.columnsToShow
      },
      set (val) {
        this.config.options.content.columns.forEach(col => {
          this.$set(col, 'cols', 12 / val)
        })
        this.config.options.customize.columnsToShow = val
      }
    }
  },

  methods: {
    setSection (section) {
      this.$store.commit('editor/SET_SECTION', section)
      this.$store.commit('editor/SET_OPENED_MENU', null)
      this.$store.commit('editor/SET_PAGE', 'section-settings')
    },
    newSectionModal () {
      this.$store.commit('editor/SET_SECTION_MODAL', {
        uid: this.section.uid
      })
    },
    deleteSection (index) {
      this.config.items.splice(index, 1)
    }
  }
}
</script>

<style lang="scss">
.row-panel-settings {
  transform: translateX(-10px);

  .btn {
    background: #f8f9fa !important;
    min-width: 56.8px;

    &:last-child {
      border-right: none !important;
    }
  }
}

.panel-settings-width {
  .btn-group .btn {
    min-width: 48px !important;
  }
}
</style>
