const state = () => ({
  navbarMenu: null
})

const getters = {}

const actions = {
  async updateOrder ({rootState}, payload) {
    if (!payload.orders.length) return
    axios.put(`api/projects/${rootState.projects.project.id}/menu/${rootState.editor.globalStyles.navbar.menu_id}/order-items`, payload)
  },
  async createMenu ({rootState}, name) {
    return await axios.post(`api/projects/${rootState.projects.project.id}/menu`, {name})
  },
  async getNavbarTemplateMenu ({rootState}) {
    return await axios.get(`api/projects/${rootState.projects.project.id}/menu/get-first-id`)
  },
  async loadNavbarMenu ({state, rootState}) {
    return await axios.get(`api/projects/${rootState.projects.project.id}/menu/${rootState.editor.globalStyles.navbar.menu_id}`)
      .then(({data}) => {
        state.navbarMenu = data.data
      })
  },
  async editLink ({rootState}, payload) {
    VEvent.fire('alt-loader', {
      state: true,
      message: 'Saving...'
    })
    return await axios.put(`api/projects/${rootState.projects.project.id}/menu/${rootState.editor.globalStyles.navbar.menu_id}/${payload.id}`, payload)
  },
  async addNavbarItem ({rootState, dispatch}, payload) {
    return await axios.post(`api/projects/${rootState.projects.project.id}/menu/${rootState.editor.globalStyles.navbar.menu_id}`, payload)
      .then(reponse => {
        dispatch('loadNavbarMenu')
        return reponse
      })
  },
  async deleteNavbarItem ({rootState, dispatch}, payload) {
    return await axios.delete(`api/projects/${rootState.projects.project.id}/menu/${rootState.editor.globalStyles.navbar.menu_id}/${payload.id}`)
      .then(reponse => {
        dispatch('loadNavbarMenu')
        return reponse
      })
  }
}

const mutations = {}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
