<template>
  <b-form
    class="flex el-form-wrapper"
    @submit.prevent="submit"
  >
    <div
      v-for="control in form.controls"
      :key="control.id"
      :class="control.styles"
      class="w-full"
    >
      <component
        :is="controlComponent(control.field_type)"
        :control="control"
        :disabled="loading"
        :form="form"
        :type="control.field_type"
      />
    </div>

    <el-button-v2
      :highlither="false"
      :section="form.subject"
      :disabled="loading || (gdpr.enabled && !gdprCheck)"
      button-type="submit"
      class="form-button"
    >
      <template #before>
        <gdpr
          v-model="gdprCheck"
          :form="form"
          :gdpr="gdpr"
        />
      </template>
    </el-button-v2>

    <p
      v-show="false"
      class="secondary-text"
    />
  </b-form>
</template>

<script>
import PropertiesManager from '../../../mixins/PropertiesManager'
import {mapState} from 'vuex'
import _ from 'lodash'
import ButtonForm from './ButtonForm'
import FormCheckbox from './controls/FormCheckbox'
import FormInput from './controls/FormInput'
import FormTextarea from './controls/FormTextarea'
import FormPhone from './controls/FormPhone.vue'
import Gdpr from './controls/Gdpr'

export default {
  name: 'FormLayoutV2',
  components: {Gdpr, FormTextarea, FormInput, FormPhone, ButtonForm, FormCheckbox},
  mixins: [PropertiesManager],

  props: {
    preview: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      gdprCheck: false,
      loading: false,
      mailAPIServices: {
        1: {
          'id': 1,
          'name': 'MailChimp',
          'short_description': 'Mailchimp helps small businesses do big things, with the right tools and guidance every step of the way. ',
          'logo': null,
          'status': null,
          'oauth': null,
          'has_list': 1,
          'has_tag': 0
        },
        2: {
          'id': 2,
          'name': 'GetResponse',
          'short_description': 'Powerful, simplified tool to send emails,\r\ncreate pages, and automate your marketing.',
          'logo': '',
          'status': 'ACTIVE',
          'oauth': null,
          'has_list': 1,
          'has_tag': 0
        },
        3: {
          'id': 3,
          'name': 'AWeber',
          'short_description': 'Email Marketing Software | Email Marketing Newsletters from AWeber',
          'logo': null,
          'status': null,
          'oauth': 1,
          'has_list': 1,
          'has_tag': 0
        },
        4: {
          'id': 4,
          'name': 'Ontraport',
          'short_description': 'The sales and marketing platform for growing businesses',
          'logo': null,
          'status': 'ACTIVE',
          'oauth': null,
          'has_list': 0,
          'has_tag': 1
        },
        5: {
          'id': 5,
          'name': 'ActiveCampaign',
          'short_description': ' Customer Experience Automation Platform',
          'logo': '',
          'status': 'ACTIVE',
          'oauth': 0,
          'has_list': 1,
          'has_tag': 0
        },
        7: {
          'id': 7,
          'name': 'ConvertKit',
          'short_description': ' Customer Experience Automation Platform',
          'logo': '',
          'status': 'ACTIVE',
          'oauth': 0,
          'has_list': 0,
          'has_tag': 0
        },
        8: {
          'id': 9,
          'name': 'Sendlane',
          'short_description': 'Sendlane helps you generate more revenue, increase retention, and automate personalized experiences for your customers.',
          'logo': null,
          'status': 'ACTIVE',
          'oauth': 0,
          'has_list': 1,
          'has_tag': 0
        }
      }
    }
  },

  computed: {
    gdpr () {
      if (!this.form.hasOwnProperty('gdpr')) {
        this.$set(this.form, 'gdpr', {
          enabled: false,
          text: 'I consent to the processing of data.'
        })
      }

      return this.form.gdpr
    },
    ...mapState('projects', {
      project: state => state.project
    })
  },

  methods: {
    submit () {
      if (!this.preview) return
      this.loading = true
      let mapping = _.reduce(_.flatten(this.form.controls.map(c => c.mapping)), (acc, mapped) => {
        (acc[this.mailAPIServices[mapped.auto_responder_id].name.toLowerCase()] || (acc[this.mailAPIServices[mapped.auto_responder_id].name.toLowerCase()] = {
          auto_responder_id: mapped.auto_responder_id,
          ...this.form.form.api_list_or_tag[this.mailAPIServices[mapped.auto_responder_id].name],
          tags: this.form.form.api_list_or_tag[this.mailAPIServices[mapped.auto_responder_id].name],
          listId: this.form.form.api_list_or_tag[this.mailAPIServices[mapped.auto_responder_id].name],
          fields: []
        })).fields.push({
          ...mapped,
          id: mapped.field_id,
          type: _.get(_.find(this.form.controls, ['id', mapped.field_id]), 'field_type'),
          value: _.get(_.find(this.form.controls, ['id', mapped.field_id]), 'value')
        })
        return acc
      }, {})

      const emailToAdmin = _.find(this.form.form.actions, ['name', 'Email To Admin'])
      if (emailToAdmin) {
        mapping['email'] = {
          ...emailToAdmin.data,
          fields: this.form.controls
        }
      }

      // eslint-disable-next-line no-undef
      grecaptcha.ready(() => {
        // eslint-disable-next-line no-undef
        grecaptcha.execute('6LfRZGMaAAAAADsWlpAN8lZQuVOL1sTNZTyGZZkA', {action: 'submit'}).then((token) => {
          axios.post(`api/site/${this.project.subdomain}/contact-form`,
            {
              g_token: token,
              ...mapping
            })
            .then(() => {
              this.$store.commit('modal/SHOW_MODAL', false)
              const successMessage = _.find(this.form.form.actions, ['name', 'Success Message'])
              if (successMessage) {
                this.$swal({
                  icon: 'success',
                  title: successMessage.data.title || 'Thank you!',
                  html: successMessage.data.text || '',
                  showConfirmButton: successMessage.data.showConfirmButton || false,
                  confirmButtonText: successMessage.data.confirmButtonText || false,
                  timer: successMessage.data.timer !== '0' ? successMessage.data.timer : undefined,
                  willClose: () => {
                    const redirect = _.find(this.form.form.actions, ['name', 'Redirect'])
                    if (redirect) {
                      this.goPage(redirect.data)
                    }
                  }
                })
              } else {
                const redirect = _.find(this.form.form.actions, ['name', 'Redirect'])
                if (redirect) {
                  this.goPage(redirect.data)
                }

                const customModal = _.find(this.form.form.actions, ['name', 'Action Modal'])
                if (customModal) {
                  this.$store.dispatch('modal/load', customModal.data.modal_id)
                }
              }
            })
            .catch(() => {
              this.$swal({
                icon: 'error',
                title: 'Error message',
                showConfirmButton: false,
                timer: 1500
              })
            })
            .finally(() => {
              this.loading = false
            })
        })
      })
    },
    controlComponent (type) {
      if (type === 'textarea') return 'FormTextarea'
      if (type === 'checkbox') return 'FormCheckbox'
      if (type === 'tel') return 'FormPhone'
      return 'FormInput'
    }
  }
}
</script>

<style lang="scss">
.form-button-space {
  margin: 0 12px;
}

.placeholder-center {
  input::placeholder,
  textarea::placeholder {
   text-align: center;
  }
}

.placeholder-end {
  input::placeholder,
  textarea::placeholder {
   text-align: right;
  }
}

</style>