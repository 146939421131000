<template>
  <div
    class="border rounded-[3px] py-[18px] px-[22px] max-w-[659px]"
  >
    <div class="text-[13px] text-[#6B6B6B] mb-[15px]">
      Edit variants:
    </div>


    <div class="mb-[10px]">
      <b-form-checkbox-group
        v-model="font.variants"
        stacked
        :options="variants"
      />
    </div>

    <button
      class="h-[42px] w-[146px] disabled:opacity-80 text-white bg-[#3D82EA] rounded-[3px] hover:opacity-80 p-0"
      :disabled="!font.variants.length"
      @click="saveFont"
    >
      Save
    </button>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'EditGoogleFontOptions',
  computed: {
    ...mapState({
      fonts: state => state.font.allGoogleFonts,
      addedGoogleFonts: state => state.editor.globalStyles.font.addedGoogleFonts,
      editableGoogleFont: state => state.font.editableGoogleFont
    }),
    options () {
      return this.fonts.find(font => font.family === this.font.family)
    },
    variants () {
      if (!this.options) return
      return this.options.variants
    },
    font () {
      return {...this.addedGoogleFonts[this.editableGoogleFont]}
    }
  },
  methods: {
    saveFont () {
      this.$store.dispatch('font/updateGoogleFont', {...this.font})
      this.$store.dispatch('font/editGoogleFont', false)

      this.$swal({
        icon: 'success',
        iconColor: '#4F83E3',
        toast: true,
        position: 'top-right',
        title: 'The font has been saved.',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }
}
</script>