<template>
  <div>
    <div @click="config.align = 'left'">
      Left
    </div>
    <div @click="config.align = 'center'">
      Center
    </div>
    <div @click="config.align = 'right'">
      Right
    </div>
  </div>
</template>

<script>
export default {
  name: 'Align',

  props: {
    config: {
      type: Object,
      required: true
    }
  }
}
</script>
