<template>
  <b-modal
    v-model="modal"
    title="Update 2.25"
    size="lg"
    hide-footer
  >
    ...
  </b-modal>
</template>

<script>
export default {
  name: 'LastChangesModal',
  data () {
    return {
      modal: false
    }
  },
  mounted () {
    const version = parseInt(localStorage.getItem('version') || 1)

    if (3 > version) {
      // this.modal = true
      localStorage.setItem('version', '2')
    }
  }
}
</script>