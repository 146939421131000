import _ from 'lodash'

const state = () => ({
  errors: []
})

const getters = {

}

const actions = {

}

const mutations = {
  SET_ERRORS (state, payload) {
    payload.forEach(err => {
      err.id = _.uniqueId()
    })
    state.errors = payload
  },
  PUSH_ERROR (state, payload) {
    payload.id = _.uniqueId()
    state.errors.push(payload)
  },
  REMOVE_ERROR_BY_ID (state, id) {
    state.errors = state.errors.filter(err=> err.id != id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
