<template>
  <div 
    class="h-[256px] flex flex-col px-[24px] py-[32px] gap-y-[16px] items-start justify-between border border-[#E3E5EB] bg-[#FFFFFF] rounded-[8px] text-[#6E747A] text-[16px] leading-[20px] font-[400] shadow-[0_3px_9px_0_rgba(0,0,0,0.05)]"
  >
    <div class="w-full flex items-center justify-between gap-x-[16px]">
      <img 
        :src="brand"
        class="w-[32px] md:w-[48px]"
      >

      <b-dropdown
        variant="link"
        toggle-class="text-primary text-decoration-none text-[14px] font-weight-normal bg-transparent border-0"
        right
      >
        <template slot="button-content">
          Actions <i class="fa fa-angle-down ml-[8px]" />
        </template>
        
        <b-dropdown-item
          class="text-[14px]"
          @click="makePrimary"
        >
          Make Primary
        </b-dropdown-item>
        <delete-confirm
          title="Are you sure?"
          description="The car will be deleted."
          @confirm="removeCard"
        >
          <template v-slot:activator="{on}">
            <b-dropdown-item 
              class="text-[14px]"
              v-on="on"
            >
              Remove
            </b-dropdown-item>
          </template>
        </delete-confirm>
      </b-dropdown>
    </div>

    <div class="flex flex-col gap-y-[8px]">
      <div class="flex items-center gap-x-[20px]">
        <span>**** {{ method.last4 }}</span>
        <span>Expires {{ method.exp_month }}/{{ method.exp_year.toString().slice(-2) }}</span>
        <span
          v-if="isPrimary"
          class="flex items-center justify-center h-[24px] px-[8px] py-[4px] rounded-[4px] text-[12px] leading-[14px] font-[400] bg-[#EBF2FF] text-[#4F83E3]"
        >
          Primary
        </span>
      </div>

      <span
        v-if="method.name"
        class="text-[#000000] font-[600] mb-0"
      >
        {{ method.name }}
      </span>
    </div>
    <add-method-modal
      v-model="modal"
      :form="method"
      update
    />
  </div>
</template>

<script>
import DeleteConfirm from '@/components/editor/modals/DeleteConfirm.vue'
import AddMethodModal from '@/layouts/dashboard/compontens/account/billing/components/AddMethodModal.vue'
import Amex from '@/assets/icons/payment-systems/amex.svg'
import DinersClub from '@/assets/icons/payment-systems/diners-club.svg'
import Discover from '@/assets/icons/payment-systems/discover.svg'
import Mastercard from '@/assets/icons/payment-systems/master-card.svg'
import Visa from '@/assets/icons/payment-systems/visa.svg'
export default {
  name: 'Card',
  components: {
    AddMethodModal,
    DeleteConfirm
  },
  props: {
    method: {
      type: Object,
      required: true
    },
    primary: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      modal: false,
      loading: false
    }
  },
  computed: {
    brand () {
      const brandMap = {
        'visa': Visa,
        'mastercard': Mastercard,
        'amex': Amex,
        'discover': Discover,
        'dinersclub': DinersClub
      }

      return brandMap[this.method.display_brand]
    },
    isPrimary () {
      return this.primary === this.method.id
    }
  },
  methods: {
    async removeCard () {
      this.loading = true
      await axios.delete(`/api/user/settings/payment-methods/${this.method.id}`)
      this.$emit('refresh')
    },
    update () {
      this.modal = true
    },
    async makePrimary () {
      this.loading = true
      await axios.get(`/api/user/settings/payment-methods/default/${this.method.id}`)
      this.$emit('refresh')
      this.loading = false

      this.$swal({
        icon: 'success',
        iconColor: '#4F83E3',
        toast: true,
        position: 'top-right',
        title: 'Set as primary card',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }
}
</script>
