import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Icon'
export const group = 'Basic'

export const defaultOptions = {
  'content': {
    'icon': {
      'type': 'icon-mod',
      'display': true,
      'icon': 'fa fa-gem',
      'lottie': {},
      'iconColor': ''
    }
  }
}

export const icon = `
<svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.074 0C15.4796 0 5.26117 0 4.60598 0L1 4.80976L10.3782 16.9L19.6789 4.80837L16.074 0ZM6.1269 5.33817L8.7691 13.0593L2.77992 5.33817H6.1269ZM7.27794 5.33817H13.4053L10.3626 14.3524L7.27794 5.33817ZM14.5547 5.33817H17.9055L11.9346 13.1008L14.5547 5.33817ZM15.5208 1.06763L17.9221 4.27053H14.5555L13.481 1.06763H15.5208ZM12.3323 1.06763L13.4067 4.27053H7.27321L8.34764 1.06763H12.3323ZM5.15908 1.06763H7.19885L6.12442 4.27053H2.7578L5.15908 1.06763Z" fill="black"/>
</svg>
`
export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElIcon', options)

    this.name = name
    this.group = group
    this.css = [['.--cea967j--QCCucchPN--1 { font-size: 46px; color: rgb(117, 147, 255); padding: 20px; scale: 1; }'], [], []]
    this.sid = 'cea967j--QCCucchPN'
    this.sids = ['cea967j--QCCucchPN']

    this.defaultOptions = defaultOptions
  }
}

