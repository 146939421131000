<template>
  <div>
    <gradient-picker-item
      v-for="(color, index) in defaultColors"
      :key="index"
      :color="color"
      @delete="() => deleteColor(index)"
      @change="onChange"
    />

    <button
      class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
      @click="addColor"
    >
      Add Color
      <span class="text-[#3D82EA] text-[20px]">+</span>
    </button>

    <b-form-group
      class="sid-range"
      label="Direction (Deg)"
    >
      <div class="flex items-center gap-[17px] justify-between">
        <vue-slider
          v-model="orientation"
          :interval="1"
          :max="360"
          :min="0"
          class="editor-slider-1 w-100"
          tooltip="none"
          @change="onChange"
        />

        <LinkedControlItem
          v-model="orientation"
          :max="360"
          :min="0"
          class="d-block !w-[80px] !text-[#7E7E7E] !text-[13px] !h-[29px]"
          @input="onChange"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'
import GradientPickerItem
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/gradient/GradientPickerItem'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
var gradient = require('gradient-parser')

export default {
  name: 'SidGradient',
  components: {LinkedControlItem, GradientPickerItem},
  mixins: [SidConcept],
  props: {
    legacySid: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      orientation: 45,
      defaultColors: [
        {
          color: '#77abff',
          position: 0
        },
        {
          color: '#c977ff',
          position: 100
        }
      ]
    }
  },
  created () {
    this.parseGradient()
  },
  methods: {
    parseGradient () {
      if (this.sidValueMutation.indexOf('linear-gradient') === 0) {
        console.log(gradient.parse(this.sidValueMutation))
        this.orientation = gradient.parse(this.sidValueMutation)[0].orientation ? gradient.parse(this.sidValueMutation)[0].orientation.value : 45
        this.defaultColors = gradient.parse(this.sidValueMutation)[0].colorStops.map(color => {
          return {
            color: `${color.type}(${color.value.join()})`,
            position: color.length.value || 0
          }
        })
      }
    },
    parseGradientToString () {
      if (!this.defaultColors.length) return ''
      let str = `linear-gradient(${this.orientation}deg`
      this.defaultColors.forEach(gradient => {
        str += `, ${gradient.color} ${gradient.position}%`
      })
      return str += ')'
    },
    onChange () {
      this.addSidRules([[this.getSid(), [this.ruleName, this.parseGradientToString()]]])
    },
    addColor () {
      this.defaultColors.push({
        color: '#77abff',
        position: 25
      })
    },
    deleteColor (index) {
      this.defaultColors.splice(index, 1)
      this.onChange()
    },
    removeGradient () {
      if (this.legacySid !== null) {
        this.addSidRules([[this.getSid(this.legacySid), [this.ruleName, 'unset']]])
      }

      this.addSidRules([[this.getSid(), [this.ruleName, '']]])
    },
    reset () {
      this.defaultColors = [
        {
          color: '#77abff',
          position: 0
        },
        {
          color: '#c977ff',
          position: 100
        }
      ]

      if (this.legacySid !== null) {
        this.addSidRules([[this.getSid(this.legacySid), [this.ruleName, 'unset']]])
      }

      this.addSidRules([[this.getSid(), [this.ruleName, this.parseGradientToString()]]])
    }
  }
}
</script>
