<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="el-dynamic"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('data')"
  >
    <div
      :data-sid="section.sid"
    >
      <div
        class="d-flex align-items-center post-breadcrumbs"
        :class="className()"
      >
        <a href="#">Home</a>
        <svg
          width="6"
          height="9"
          viewBox="0 0 6 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 0.5L5 4.5L1 8.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <a href="#">Blog</a>
        <svg
          width="6"
          height="9"
          viewBox="0 0 6 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 0.5L5 4.5L1 8.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <a
          href="#"
          class="active"
        >Post Title</a>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
import {mapState} from 'vuex'

export default {
  name: 'ElBlogCategories',
  components: {Highliter},
  mixins: [SectionMixin],
  computed: {
    ...mapState('blogEditor', {
      post: state => state.post
    })
  },
  methods: {
    className (name = '') {
      return `--post-breadcrumbs-${this.section.sid}${name}`
    }
  }
}
</script>

<style lang="scss">
.post-breadcrumbs {
  gap: 14px;

  a {
    text-decoration: none;
  }

  path {
    stroke: #667085
  }
}
</style>