var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SidbearSettingsGroup',{attrs:{"title":"Content Wrapper","active":""}},[_c('sid-range-slider',{attrs:{"rule-name":"max-width","sid":".post-middle","default-dimension":"%","dimensions":['%', 'vw', 'px'],"label":"Width","var":"--width","measurement":"width"}}),_c('sid-buttons-group',{attrs:{"label":"Horizontal Alignment","options":[
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'start',
          tooltip: 'Start'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'center',
          tooltip: 'Center'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'end',
          tooltip: 'End'
        }
      ],"sid":".post-middle","rule-name":"justify-content"}}),_c('sid-buttons-group',{attrs:{"label":"Vertical Alignment","options":[
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'start',
          tooltip: 'Start'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'center',
          tooltip: 'Center'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'end',
          tooltip: 'End'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'12\' viewBox=\'0 0 13 12\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.26855 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 6.86035 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 0.721313)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 11.0599)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E',
          value: 'stretch',
          tooltip: 'Stretch'
        }
      ],"sid":".post-middle","rule-name":"align-items"}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Background"}},[_c('sid-background',{attrs:{"sid":".post-middle-bg"}}),_c('sid-upload-image',{attrs:{"sid":".post-middle-bg","rule-name":"background-image"}}),_c('b-form-group',{attrs:{"label":"Background Image Size"}},[_c('sid-select',{attrs:{"priority":"","searchable":false,"options":[
          {
            label: 'Normal',
            value: 'auto',
            customProperties: [['.post-middle-bg', ['background-size', 'auto']], ['.post-middle-bg', ['background-attachment', 'unset']]]
          },
          {
            label: 'Full Center (Parallax)',
            value: 'fixed',
            customProperties: [['.post-middle-bg', ['background-size', 'cover']], ['.post-middle-bg', ['background-attachment', 'fixed']]]
          },
          {
            label: 'Fill 100 % Width',
            value: '100% auto',
            customProperties: [['.post-middle-bg', ['background-attachment', 'unset']]]
          },
          {
            label: 'Fill 100 % Width & Height',
            value: '100% 100%',
            customProperties: [['.post-middle-bg', ['background-attachment', 'unset']]]
          },
          {
            label: 'Repeat',
            value: 'repeat',
            property: 'background-repeat',
            customProperties: [['.post-middle-bg', ['background-size', 'auto']], ['.post-middle-bg', ['background-repeat', 'repeat']], ['.post-middle-bg', ['background-attachment', 'unset']]]
          },
          {
            label: 'Repeat Horizontally',
            value: 'repeat-x',
            property: 'background-repeat',
            customProperties: [['.post-middle-bg', ['background-size', 'auto']], ['.post-middle-bg', ['background-repeat', 'repeat-x']], ['.post-middle-bg', ['background-attachment', 'unset']]]
          },
          {
            label: 'Repeat Vertically',
            value: 'repeat-y',
            property: 'background-repeat',
            customProperties: [['.post-middle-bg', ['background-size', 'auto']], ['.post-middle-bg', ['background-repeat', 'repeat-y']], ['.post-middle-bg', ['background-attachment', 'unset']]]
          },
          {
            label: 'Cover',
            value: 'cover',
            customProperties: [['.post-middle-bg', ['background-size', 'cover']], ['.post-middle-bg', ['background-attachment', 'unset']]]
          },
          {
            label: 'Contain',
            value: 'contain',
            customProperties: [['.post-middle-bg', ['background-size', 'contain']], ['.post-middle-bg', ['background-attachment', 'unset']]]
          }
        ],"sid":".post-middle-bg","var":"--background-size","rule-name":"background-size"}})],1),_c('b-form-group',{attrs:{"label":"Background Image Position"}},[_c('sid-select',{attrs:{"searchable":false,"options":[
          {label: 'Left Top', value: 'left top'},
          {label: 'Left Center', value: 'left center'},
          {label: 'Left Bottom', value: 'left bottom'},
          {label: 'Right Top', value: 'right top'},
          {label: 'Right Center', value: 'right center'},
          {label: 'Right Bottom', value: 'right bottom'},
          {label: 'Center Top', value: 'center top'},
          {label: 'Center Center', value: 'center center'},
          {label: 'Center Bottom', value: 'center bottom'} ],"sid":".post-middle-bg","rule-name":"background-position"}})],1),_c('sid-flip',{attrs:{"sid":".post-middle-bg","rule-name":"scale"}}),_c('sid-range-slider',{attrs:{"sid":".post-middle-bg","label":"Custom Size","rule-name":"background-size","var":"--custom-bg-size","unlim":"","dimensions":['px', '%', 'vh', 'vw']}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Padding & Margins"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":".post-middle","rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":".post-middle","rule-name":"margin","min":-2000,"max":2000,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }