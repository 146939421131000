<template>
  <div class="w-full flex items-center gap-[2px]">
    <div
      v-for="index in 10"
      :key="index"
      class="w-full h-[6px]"
      :class="[index <= volumeLevel ? 'bg-[#6AD76A]' : 'bg-[#D9D9D9]']"
    />
  </div>
</template>

<script>
export default {
  name: 'VolumeLevel',
  props: {
    deviceId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      audioContext: null,
      analyser: null,
      dataArray: null,
      audioSource: null,
      volumeLevel: 0,
      scalingFactor: 3.2
    }
  },
  methods: {
    async startMonitoring () {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: this.deviceId,
          sampleRate: 48000,
          channelCount: 2
        }
      })

      this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
      this.analyser = this.audioContext.createAnalyser()
      this.audioSource = this.audioContext.createMediaStreamSource(stream)

      this.audioSource.connect(this.analyser)

      this.analyser.fftSize = 256 // Size of the FFT window
      const bufferLength = this.analyser.frequencyBinCount
      this.dataArray = new Uint8Array(bufferLength)

      this.updateVolume()
    },
    updateVolume () {
      this.analyser.getByteTimeDomainData(this.dataArray)

      let sum = 0
      for (let i = 0; i < this.dataArray.length; i++) {
        const value = this.dataArray[i] / 128 - 1.0
        sum += value * value
      }

      const rms = Math.sqrt(sum / this.dataArray.length)
      const normalizedVolume = rms * this.scalingFactor

      this.volumeLevel = Math.min(10, Math.floor(normalizedVolume * 10))

      requestAnimationFrame(this.updateVolume)
    }
  },
  async mounted () {
    await this.startMonitoring()
  },
  watch: {
    async deviceId () {
      await this.startMonitoring()
    }
  },
  beforeDestroy () {
    if (this.audioContext) {
      this.audioContext.close()
    }
  }
}
</script>