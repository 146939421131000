import router from '@builder/router/index'
import _ from 'lodash'
import {isCurrentCustomDomain} from '../../utils/urlHelper'

const state = () => ({
  page: 'home'
})

const actions = {
  setPage ({commit, rootState}, page) {
    const kebabPage = _.kebabCase(rootState.sections.sections[page].name) || _.kebabCase(page)

    if (isCurrentCustomDomain()) {
      router.push(`/site/${kebabPage}`)
    } else {
      if (router.currentRoute.name.search('published') !== -1) {
        router.push(`/site/${rootState.projects.project.subdomain}/${kebabPage}`)
      } else if (router.currentRoute.name === 'es-reserve') {
        if (router.currentRoute.matched.filter(route => route.name === 'published').length) {
          router.push(`/site/${rootState.projects.project.subdomain}/${kebabPage}`)
        } else {
          router.push(`/builder/${router.currentRoute.params.id}`)
        }
      }
    }

    commit('SET_PAGE', kebabPage)
    commit('sections/SET_PAGE', kebabPage, {root: true})

    window.scrollTo(0, 0)
  }
}

const mutations = {
  SET_PAGE (state, page) {
    state.page = page
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
