<template>
  <highliter
    v-model="section.name"
    class="feature-section"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings('images')"
  >
    <Carousel1Template :section="section">
      <highliter
        v-for="(image, index) in section.options.content.images.items"
        :key="index"
        disable-toolbar
        class="inner-slide"
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @dublicate="$emit('dublicate')"
        @delete="$emit('delete')"
        @click.stop.native="sectionSettings({item: 'images', index})"
      >
        <image-fit
          :customs="image"
          :src="image.src || image.placeholder"
          :size="cols(image.size, 'contain')"
          :position="image.position"
          height="285px"
        />
      </highliter>
    </Carousel1Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Carousel1Template from './Carousel1Template'
import ImageFit from '../../../../utils/ImageFit'

export default {
  name: 'Carousel1',

  components: {ImageFit, Carousel1Template, Highliter},

  mixins: [SectionMixin]
}
</script>
