import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Blog Container'
export const group = 'blog'
export const defaultOptions = {
  content: {
    items: {
      type: null,
      children: [{
        'display': true,
        'name': 'Feed',
        'component': 'BlogContainerV2',
        'type': 'pagination-mod',
        'group': 'blog',
        'layout': null,
        'options': {
          'content': {
            'buttonSubject': {
              'uid': 'aQjpp1l-v',
              'display': true,
              'name': 'Button',
              'sid': 'l9fp0job--hhJD4qz0O6',
              sids: ['l9fp0job--hhJD4qz0O6'],
              css: [['.--l9fp0job--hhJD4qz0O6--2 {justify-content: normal;}'],[],[]],
              'hideFromSections': false,
              'component': 'ElButtonV2',
              'group': 'Media',
              hide: ['actions', 'unlink'],
              'layout': null,
              'options': {
                'content': {
                  'button': {
                    'pressetID': 0,
                    'display': true,
                    'type': 'button-mod-v2',
                    'text': 'Read More',
                    'buttonType': 'button',
                    'icon': {'lottie': {'jsonPath': '', 'loop': true, 'autoplay': true}},
                    'hoverAnimation': {},
                    'animations': {'animation': null, 'once': true, 'offset': 200, 'delay': 50}
                  }
                }, 'customize': null
              },
              'temp_id': 'MXpb8KZorW'
            }, 'pagination': {'type': 'pagination-mod', 'perPage': 9, 'color': ''}
          }
        },
        'sid': 'l9fp0job--kbLYLS8AN',
        sids: ['l9fp0job--kbLYLS8AN'],
        css: [['.--l9fp0job--kbLYLS8AN--card {border-width: 1px; --border-size: 1px; border-radius: 7px; --border-radius: 7px; box-shadow: rgb(0 0 0 / 8%) 0px 0px 2rem 5px;}'], [], []],
        'temp_id': '___hh0cxhS'
      }]
    }
  },
  backgroundImage: {
    src: ''
  },
  backgroundVideo: {
    enabled: false,
    url: '',
    loop: true,
    ratio: 1
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@builder/assets/svg/previews/blog/main-3.jpg')
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('BlankV2', options)

    this.name = name
    this.group = group
    this.sid = 'l9fp0job--4bmveXWAGw'
    this.sids = ['l9fp0job--4bmveXWAGw']
    this.css = [['.--l9fp0job--4bmveXWAGw--container { max-width: 100vw; }'], [], []]

    this.defaultOptions = defaultOptions
  }
}
