<template>
  <div
    :class="{'element-hidden': !visability('collapse')}"
    :style="{...fullMarginSubject(section.options.content.collapse)}"
  >
    <div
      :class="container"
      :style="{...shadow}"
      class="accordion px-0"
      role="tablist"
    >
      <b-card
        v-for="(collapse, index) in section.options.content.collapse.items"
        :key="collapse.uid"
        class="rounded-0"
        no-body
      >
        <b-card-header
          :class="{'cursor-pointer': active !== index}"
          :style="{...backgroundColor, ...color, fontSize, fontFamily, fontWeight}"
          class="d-flex justify-content-between align-items-center rounded-0 py-3"
          header-tag="header"
          role="tab"
          @click="active = index"
        >
          <slot
            :content="collapse"
            name="header"
          />
          <i
            v-if="active === index"
            :class="closeIcon"
            :style="{color: closeIconColor}"
          />
          <i
            v-else
            :class="openIcon"
            :style="{color: openIconColor}"
          />
        </b-card-header>
        <b-collapse
          :visible="active === index"
          role="tabpanel"
        >
          <b-card-body
            :style="{color: section.options.customize.style.bodyColor.color, ...backgroundColorSubject(section.options.customize.style, 'backgroundBody'), ...fullPaddingSubject(section.options.customize.style)}"
          >
            <slot
              :content="collapse"
              name="body"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import ResolutionMixin from '../../../mixins/ResolutionMixin'
import _ from 'lodash'
import BorderMixin from '@/components/mixins/BorderMixin'
import BackgroundMixin from '@/components/mixins/BackgroundMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin, ResolutionMixin, BorderMixin, BackgroundMixin],
  data () {
    return {
      active: 0
    }
  },
  computed: {
    fontSize () {
      return `${parseInt(this.section.options.content.collapse.fontSize[this.resolutioner])}px`
    },
    shadow () {
      const shadow = this.section.options.content.collapse.shadowOptions
      const color = this.section.options.content.collapse.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    openIcon () {
      return _.get(this.section.options.content.collapse, 'icon.open.iocn', 'fa fa-plus')
    },
    closeIcon () {
      return _.get(this.section.options.content.collapse, 'icon.close.iocn', 'fa fa-minus')
    },
    openIconColor () {
      return _.get(this.section.options.content.collapse, 'icon.open.color', this.section.options.customize.style.color.color)
    },
    closeIconColor () {
      return _.get(this.section.options.content.collapse, 'icon.close.color', this.section.options.customize.style.color.color)
    },
    fontFamily () {
      return _.get(this.section.options.content.collapse, ['fontFamily', this.resolutioner], _.get(this.section.options.content.collapse, ['fontFamily', 'lg'], ''))
    },
    fontWeight () {
      return _.get(this.section.options.content.collapse, ['fontWeight', this.resolutioner], _.get(this.section.options.content.collapse, ['fontWeight', 'lg'], ''))
    }
  }
}
</script>
