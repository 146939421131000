<template>
  <Shadow :config="config" />
</template>

<script>
import ModControlMixin from '@/components/mixins/ModControlMixin'
import Shadow from '@/components/editor/controls/ShadowCustoms'

export default {
  name: 'ButtonShadow',
  components: {Shadow},
  mixins: [ModControlMixin]
}
</script>