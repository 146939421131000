<template>
  <b-form-group>
    <Shadow :config="shadow" />
  </b-form-group>
</template>

<script>
import ModControlMixin from '@/components/mixins/ModControlMixin'
import Shadow from '@/components/editor/controls/ShadowCustoms'
import ModControlHoverMixin from '@/components/mixins/ModControlHoverMixin'

export default {
  name: 'ButtonHoverShadow',
  components: {Shadow},
  mixins: [ModControlMixin, ModControlHoverMixin],
  computed: {
    shadow () {
      if (!this.config.hoverShadow) {
        this.$set(this.config, 'hoverShadow', {
          lg: {},
          md: {},
          sm: {}
        })
      }

      return this.config.hoverShadow.lg
    }
  }
}
</script>