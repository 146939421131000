<template>
  <settings-page-card title="Integrations">
    <div class="d-flex align-content-center border-top p-4">
      <icon icon="api-email.svg" />
      <h6 class="api-service ml-3 mb-0">
        Autoresponder
      </h6>
    </div>

    <div class="px-4 mb-4">
      <b-alert
        :show="!!hasAPI.name"
        variant="danger"
        class="bg-white"
      >
        When changing the autoresponder, the previous forms associated with the current autoresponder will be deactivated
      </b-alert>

      <b-form-group label="Select service">
        <v-select
          v-model="selectedService"
          :disabled="!!hasAPI.name"
          left
          label="name"
          :reduce="serveice => serveice.name"
          class="custom-select"
          :searchable="false"
          :options="mailAPIServices"
        />
      </b-form-group>
    </div>

    <component
      :is="camelCase(service.name)"
      v-for="service in mailAPIServices"
      v-if="service.name === selectedService"
      :key="service.id"
      :service="service"
      @integrated="interationData => $emit('integrated', interationData)"
      @removeIntegration="$emit('remove-integration')"
    />
  </settings-page-card>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import SettingsPageCard from '../../layouts/dashboard/SettingsPageCard'
import TextMixins from '../../components/mixins/TextMixins'

export default {
  name: 'Integrations',

  components: {SettingsPageCard},

  mixins: [TextMixins],

  data () {
    return {
      selectedService: ''
    }
  },

  computed: {
    ...mapGetters('apiIntegration', {
      hasAPI: 'hasMailAPIService'
    }),
    ...mapState('apiIntegration', {
      mailAPIServices: state => state.mailAPIServices
    })
  },

  mounted () {
    this.selectedService = this.hasAPI.name || this.mailAPIServices[0].name
  }
}
</script>

<style lang="scss">
.api-service {
  font-size: 17px;
  line-height: 1.5
}
</style>
