<template>
  <b-form-group>
    <b-checkbox
      v-model="sidMutation"
      :unchecked-value="uncheckedValue"
      :value="value"
    >
      <slot />
    </b-checkbox>
  </b-form-group>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'

export default {
  name: 'SidCheckbox',
  mixins: [SidConcept],
  props: {
    value: {
      required: true
    },
    uncheckedValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    sidMutation: {
      get () {
        return this.sidValueMutation
      },
      set (val) {
        if (val !== this.uncheckedValue && val !== this.value) return
        this.addSidRules([[this.getSid(), [this.ruleName, val]]])
      }
    }
  }
}
</script>