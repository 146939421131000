<template>
  <button
    :disabled="loading"
    class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
    @click="addItem"
  >
    Add List Item
    <span class="text-[#3D82EA] text-[20px]">+</span>
  </button>
</template>

<script>
import {toJSON, toStringify} from '@/utils/helpers'
import shortid from 'shortid'

export default {
  name: 'AddNewControlInList',
  props: {
    itemsCount: {
      required: true,
      type: Number
    },
    // eslint-disable-next-line vue/prop-name-casing
    form_id: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    addItem () {
      this.loading = true
      this.$store.dispatch('forms/addControl', {
        form_id: this.form_id,
        field_type: 'text',
        field_name: `Item #${this.itemsCount + 1}`,
        label: `Item #${this.itemsCount + 1}`,
        columns: toStringify({
          lg: 12,
          md: 12,
          sm: 12
        }),
        order: this.itemsCount + 1,
        is_required: false,
        styles: `el-${shortid.generate()}`
      })
        .then(({data}) => {
          const control = data.data
          const columns = toJSON(control.columns)

          this.$emit('added-item', {
            ...control,
            columns: Object.keys(columns).length ? columns : {
              lg: control.columns,
              md: control.columns,
              sm: control.columns
            },
            mapping: []
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
