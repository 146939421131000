<template>
  <div>
    <div class="tabs d-flex font-size-16 border-bottom mb-4">
      <div
        v-for="tab in tabs"
        :key="tab.key"
        class="tab py-4 pr-8 hover:primary text-muted cursor-pointer transition duration-400"
        :class="{'text-primary': active === tab.key}"
        @click="active = tab.key"
        v-text="tab.label"
      />
    </div>

    <meta-data-facebook
      v-if="active === 0"
      :form="form.facebook"
    />
    <meta-data-twitter
      v-if="active === 1"
      :form="form.twitter"
    />
    <meta-data-pinterest
      v-if="active === 2"
      :form="form.pinterest"
    />
  </div>
</template>

<script>
import MetaDataFacebook from './Facebook'
import MetaDataTwitter from './Twitter'
import MetaDataPinterest from './Pinterest'
export default {
  name: 'ProjectSeoMetaData',
  components: {MetaDataPinterest, MetaDataTwitter, MetaDataFacebook},
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tabs: [{key: 0, label: 'Facebook'}, {key: 1, label: 'Twitter'}, {key: 2, label: 'Pinterest'}],
      active: 0
    }
  }
}
</script>