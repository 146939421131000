<template>
  <div
    v-if="settings.widget_sidebar.display_categories"
    class="top-categories"
  >
    <div class="d-flex align-items-center">
      <h6 class="text-xs xl:text-sm font-weight-bold text-uppercase mb-0 mr-4">
        Categories:
      </h6>

      <div class="d-flex align-items-center overflow-x-auto py-7">
        <div
          v-for="(category, index) in [{
            id: '',
            name: 'All Categories'
          }, ...categories]"
          :key="index"
          class="category text-uppercase text-nowrap text-xs xl:text-sm px-4"
          :class="{'active': categoryModel === category.id}"
          @click="categoryModel = category.id"
        >
          <span
            class="position-relative z-index-1000 cursor-pinter"
            v-text="category.name"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogCategoriesMixin from '../../mixins/BlogCategoriesMixin'

export default {
  name: 'TopCategories',
  mixins: [BlogCategoriesMixin],
  data () {
    return {
      active: 1,
      categoreis: [
        {
          id: 1,
          text: 'All Categories'
        },
        {
          id: 2,
          text: 'Marketing'
        },
        {
          id: 3,
          text: 'Business'
        },
        {
          id: 4,
          text: 'Reviews'
        },
        {
          id: 5,
          text: 'Strategies'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.top-categories {
  .category {
    font-weight: bold;
    color: #6E747A;
    transition: color .4s;

    &:hover {
      cursor: pointer;
    }

    &.active {
      position: relative;

      &:before {
        content: '';
        z-index: 50;
        border-radius: 90px;
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 210%;
        top: 50%;
        opacity: .1;
      }
    }
  }
}
</style>
