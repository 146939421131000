<template>
  <div>
    <b-form-group label="Links to">
      <pages-select-menu
        :others="[{id: 'custom-link', title: 'Custom Link'}, {id: 'blog', title: 'ESTAGE Blog', code: project.blog_slug}]"
        input-class="rounded-[4px]"
        other-title="Other:"
        @selectOther="selectPage"
        @selectPage="selectPage"
      >
        <template
          v-if="page"
          slot="placeholder"
        >
          <div class="px-3">
            {{ page.title }}
          </div>
        </template>
      </pages-select-menu>
    </b-form-group>

    <b-form-group v-if="page.id === 'custom-link'">
      <label>Custom link</label>
      <b-input
        v-model="customLink"
        type="url"
      />
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="config.blank_page">
        Open In New Tab
      </b-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'
import {mapGetters, mapState} from 'vuex'
import PagesPaginationMixin from '../mixins/PagesPaginationMixin'
import popperMixin from '../mixins/popperMixin'
import PagesSelectMenu from '@/components/editor/components/pagesSelectMenu/PagesSelectMenu'

export default {
  name: 'ActionRedirect',
  components: {PagesSelectMenu},
  mixins: [ControlMixin, popperMixin, PagesPaginationMixin],

  computed: {
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    page: {
      get () {
        return this.config.page || {id: null, title: 'Not selected'}
      },
      set (val) {
        this.$set(this.config, 'page', val)
      }
    },
    customLink: {
      get () {
        return this.config.customLink || ''
      },
      set (val) {
        this.$set(this.config, 'customLink', val)
      }
    }
  },

  methods: {
    selectPage (page) {
      this.page = {
        ...page,
        blank_page: false
      }
    }
  }
}
</script>
