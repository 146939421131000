<template>
  <div class="mb-5">
    <label class="mb-2">Opacity</label>
    <vue-slider
      v-model="config.opacity"
      class="editor-slider-2"
      :interval="0.1"
      :dot-size="17"
      tooltip="none"
      :min="0"
      :max="1"
    />
  </div>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'

export default {
  name: 'Opacity',

  mixins: [ControlMixin]
}
</script>
