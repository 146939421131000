<template>
  <div class="dashboard-projects-grid">
    <b-row v-if="projects.length">
      <b-col
        v-for="(project, index) in projects"
        :key="project.id"
        cols="12"
        md="6"
        lg="4"
        xl="3"
        class="mb-4"
      >
        <archive-project-card
          :project="project"
          @restored="() => removeFromList(index)"
          @deleted="() => removeFromList(index)"
        />
      </b-col>
    </b-row>

    <div v-else>
      <p class="text-muted text-center">
        No archived projects.
      </p>
    </div>
  </div>
</template>

<script>
import ArchiveProjectCard from './ArchiveProjectCard'

export default {
  name: 'ArchivedProjects',
  components: {ArchiveProjectCard},
  props: {
    projects: {
      type: Array,
      required: true
    }
  },
  methods: {
    removeFromList (index) {
      this.projects.splice(index, 1)
    }
  }
}
</script>