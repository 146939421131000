<template>
  <div v-if="false">
    <div class="font-[600] text-[16px] mb-[28px]">
      Fallbacks for Fonts
    </div>

    <b-form-group label="Falls Back To">
      <div class="flex items-center gap-[15px]">
        <v-select
          v-model="fallback"
          :options="options"
          :searchable="false"
          class="!w-[238px]"
        />
        <button class="h-[37px] w-[100px] text-white rounded-[3px] bg-[#3D82EA] hover:opacity-80 p-0">
          Save
        </button>
      </div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'FontFallbackSettings',
  data () {
    return {
      fallback: 'Arial',
      options: [
        'Arial',
        'Georgia',
        'Impact',
        'Palatino Linotype',
        'Tahoma',
        'Times',
        'Trebuchet',
        'Verdana'
      ]
    }
  }
}
</script>