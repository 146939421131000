<template>
  <div>
    <h6 class="font-weight-bold mb-3">
      Pinterest Settings
    </h6>

    <p class="mb-3 text-muted">
      Pinterest uses Open Graph metadata just like Facebook, so be sure to keep the "Add Open Graph meta data" setting on the Facebook tab enabled if you want to optimize your site for Pinterest.
    </p>
    <p class="mb-3 text-muted">
      To <a
        class="text-primary"
        href="https://www.pinterest.com/settings/claim"
        target="_blank"
      >confirm your site with Pinterest</a>, add the meta tag here:
    </p>

    <b-form-group label="Pinterest confirmation">
      <b-input v-model="form.pinterest_confirmation" />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'MetaDataPinterest',
  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>