<template>
  <div>
    ...
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'

export default {
  name: 'RealTimeVisibility',
  mixins: [ModMixin]
}
</script>