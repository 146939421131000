import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Price'
export const group = ''

export const defaultOptions = {
  content: {
    title: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          fontSize: null,
          fontFamily: null,
          color: 'adaptive',
          align: 'left'
        },
        lg: {
          fontSize: null,
          fontFamily: null,
          color: 'adaptive',
          align: 'left'
        }
      },
      'text': '<p><strong><span>Basic</span></strong></p>'
    },
    description: {
      type: 'tiptap-mod',
      display: true,
      resolutionStyle: {
        sm: {
          lineHeight: 1.4,
          fontSize: '14px',
          fontFamily: null,
          color: '#6E747A',
          align: 'left'
        },
        lg: {
          lineHeight: 1.4,
          fontSize: '14px',
          fontFamily: null,
          color: '#6E747A',
          align: 'left'
        }
      },
      text: {
        'type': 'doc',
        'content': [{
          'type': 'paragraph',
          'content': [{
            'type': 'text',
            'text': 'For most business that want to overhaul their business'
          }]
        }]
      }
    },
    'price': {
      'type': 'tiptap-mod',
      'display': true,
      'resolutionStyle': {
        'sm': {
          'fontSize': '14px',
          'fontFamily': 'Proxima Nova',
          'color': 'adaptive',
          'align': 'left',
          'padding': {'top': 0, 'bottom': 0, 'x': 0}
        },
        'lg': {
          'fontSize': '19px',
          'fontFamily': 'Proxima Nova',
          'color': 'adaptive',
          'align': 'left',
          'padding': {'top': 0, 'bottom': 0, 'x': 0},
          'lineHeight': 1
        }
      },
      'text': '<p><strong><span><spanr style="font-size:48px">36$</spanr></span> </strong>/ <spanr style="font-size:14px;color:#6E747A">Month</spanr></p>'
    },
    button: {
      display: true,
      hover: false,
      type: 'button-mod',
      text: 'Choose Plan',
      icon: null,
      iconPlacement: 'append',
      resolutionStyle: {
        lg: {
          color: 'adaptive',
          backgroundColor: 'colored',
          size: 'lg',
          shape: null,
          btnStyle: 'outlined',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        },
        sm: {
          color: '#ffffff',
          backgroundColor: 'colored',
          size: 'lg',
          shape: null,
          btnStyle: 'outlined',
          align: 'center',
          padding: {
            top: 0,
            bottom: 0,
            x: 0
          }
        }
      }
    },
    list: {
      type: 'list-mod',
      display: true,
      items: [
        {
          label: 'Item 1',
          text: 'Improved Performance'
        },
        {
          label: 'Item 2',
          text: 'Attention to Detail'
        },
        {
          label: 'Item 3',
          text: 'Customer Support'
        },
        {
          label: 'Item 4',
          text: 'All Deliverables'
        },
        {
          label: 'Item 5',
          text: 'Unlimited Requests'
        }
      ]
    }
  },
  customize: {
    layouts: false,
    style: {
      listIcon: {
        type: 'list-icon-customs',
        icon: 'fa fa-check-circle',
        color: 'adaptive'
      },
      background: {
        background: '#F5F5F5'
      },
      color: {
        type: 'color-customs',
        color: '#000000'
      }
    }
  }
}

export const previews = {
  left: require('@builder/assets/svg/previews/price/left-1.svg'),
  right: null,
  main: require('@builder/assets/svg/previews/price/main-1.svg')
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('Price1', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
