var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sid-select w-full py-[14px] px-[16px]"},[_c('sid-range-slider',{attrs:{"rule-name":"width","sid":(".el-product-cover.--" + (_vm.element.sid) + "--image"),"default-dimension":"%","dimensions":['%', 'vw', 'px'],"label":"Image Size","var":"--width","measurement":"width"}}),_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0 w-full"},[_vm._v("Display Setting")]),_c('sid-select',{attrs:{"sid":(".--" + (_vm.element.sid) + "--wrapper"),"rule-name":"display","searchable":false,"options":[
          {
            label: 'Block',
            value: ''
          },
          {
            label: 'Inline',
            value: 'inline-flex',
          }
        ]}})],1)]),_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0 w-full"},[_vm._v("Position")]),_c('sid-select',{attrs:{"sid":(".--" + (_vm.element.sid) + "--wrapper"),"rule-name":"position","searchable":false,"options":[
          {
            label: 'Static',
            value: ''
          },
          {
            label: 'Fixed',
            value: 'fixed',
          },
          {
            label: 'Absolute',
            value: 'absolute',
          }
        ]}})],1)]),_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Z-Index")]),_c('sid-input',{staticClass:"!w-[59px]",attrs:{"sid":(".--" + (_vm.element.sid) + "--image"),"rule-name":"z-index","default-dimension":""}})],1)]),_c('sid-flip',{attrs:{"sid":(".--" + (_vm.element.sid) + "--image"),"rule-name":"scale"}}),_c('sid-align',{attrs:{"sid":(".--" + (_vm.element.sid) + "--container"),"rule-name":"justify-content","label":"Alignment"}}),_c('SidbearSettingsGroup',{style:({margin: '0 -16px'}),attrs:{"title":"Border & Radius","button-class":"!px-[16px]","content-class":"!py-[14px] !px-[16px]"}},[_c('rule-tabs',[_c('template',{slot:"Normal"},[_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Border Type")]),_c('sid-select',{staticClass:"w-[118px]",attrs:{"sid":(".--" + (_vm.element.sid) + "--image"),"rule-name":"border-style","searchable":false,"options":[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]}})],1)]),_c('b-form-group',[_c('sid-linked-group',{staticClass:"!mb-[7px]",attrs:{"sid":(".--" + (_vm.element.sid) + "--image"),"label":"Border Width","rule-name":"border","angles":[
              {
                text: '',
                value: 'top-width'
              },
              {
                text: '',
                value: 'right-width'
              },
              {
                text: '',
                value: 'bottom-width'
              },
              {
                text: '',
                value: 'left-width'
              }
            ]}}),_c('div',{staticClass:"flex w-full justify-end"},[_c('sid-color',{attrs:{"sid":(".--" + (_vm.element.sid) + "--image"),"rule-name":"border-color"}})],1)],1),_c('sid-linked-group',{staticClass:"mb-0",attrs:{"label":"Radius","sid":(".--" + (_vm.element.sid) + "--image"),"rule-name":"border","angles":[
            {
              text: '',
              value: 'top-left-radius'
            },
            {
              text: '',
              value: 'top-right-radius'
            },
            {
              text: '',
              value: 'bottom-left-radius'
            },
            {
              text: '',
              value: 'bottom-right-radius'
            }
          ]}})],1),_c('template',{slot:"Hover"},[_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Border Type")]),_c('sid-select',{staticClass:"w-[118px]",attrs:{"sid":(".--" + (_vm.element.sid) + "--image"),"rule-name":"border-style","searchable":false,"presudo":":hover","options":[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]}})],1)]),_c('b-form-group',[_c('sid-linked-group',{staticClass:"!mb-[7px]",attrs:{"sid":(".--" + (_vm.element.sid) + "--image"),"label":"Border Width","rule-name":"border","presudo":":hover","angles":[
              {
                text: '',
                value: 'top-width'
              },
              {
                text: '',
                value: 'right-width'
              },
              {
                text: '',
                value: 'bottom-width'
              },
              {
                text: '',
                value: 'left-width'
              }
            ]}}),_c('div',{staticClass:"flex w-full justify-end"},[_c('sid-color',{attrs:{"sid":(".--" + (_vm.element.sid) + "--image"),"presudo":":hover","rule-name":"border-color"}})],1)],1),_c('sid-linked-group',{staticClass:"mb-0",attrs:{"label":"Radius","sid":(".--" + (_vm.element.sid) + "--image"),"rule-name":"border","presudo":":hover","angles":[
            {
              text: '',
              value: 'top-left-radius'
            },
            {
              text: '',
              value: 'top-right-radius'
            },
            {
              text: '',
              value: 'bottom-left-radius'
            },
            {
              text: '',
              value: 'bottom-right-radius'
            }
          ]}})],1)],2)],1),_c('SidbearSettingsGroup',{style:({margin: '0 -16px'}),attrs:{"title":"Padding & Margins","button-class":"!px-[16px]","content-class":"!py-[14px] !px-[16px]"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":(".--" + (_vm.element.sid) + "--wrapper"),"rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":(".--" + (_vm.element.sid) + "--wrapper"),"rule-name":"margin","min":-2000,"max":2000,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }