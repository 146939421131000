<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">
        Play List
      </p>
    </div>


    <label class="mb-2">Videos</label>

    <draggable
      v-model="config.items"
      handle=".move"
    >
      <transition-group name="shufle">
        <b-card
          v-for="(section, index) in config.items"
          :key="section.uid"
          no-body
          class="border-0"
          @click="$store.dispatch('editor/sectionSettings', {section: section})"
        >
          <b-card-header header-class="editor-toolbar-collapse-header d-flex align-items-center rounded-0">
            <icon
              class="chevron mr-3"
              icon="chevron.svg"
            />
            <icon
              class="move mr-3"
              icon="move.svg"
            />
            <div block>
              {{ section.name }}
            </div>
            <icon
              class="ml-auto"
              icon="trash.svg"
              @click.native.stop="deleteSection(index)"
            />
          </b-card-header>
        </b-card>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import draggable from 'vuedraggable'

export default {
  name: 'RowMod',
  components: {draggable},
  mixins: [ModMixin],

  methods: {
    setSection (section) {
      this.$store.commit('editor/SET_SECTION', section)
      this.$store.commit('editor/SET_OPENED_MENU', null)
      this.$store.commit('editor/SET_PAGE', 'section-settings')
    },
    newSectionModal () {
      this.$store.commit('editor/SET_SECTION_MODAL', {
        uid: this.section.uid
      })
    },
    deleteSection (index) {
      this.config.items.splice(index, 1)
    }
  }
}
</script>
