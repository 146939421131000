<template>
  <div>
    <div
      v-if="blog.data && !loading"
      class="min-vh-100 py-5"
    >
      <div
        class="max-w mx-auto grid gap-11 lg:max-w-none"
        :class="sidebar ? `md:grid-cols-2` : `md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3`"
      >
        <div
          v-for="post in postDivider[1]"
          :key="post.id"
        >
          <post
            class="h-100"
            :post="post"
          />
        </div>
      </div>

      <ad v-if="!sidebar" />

      <div
        v-if="!sidebar"
        class="max-w mx-auto grid gap-11 lg:max-w-none `md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3"
      >
        <div
          v-for="post in postDivider[2]"
          :key="post.id"
        >
          <post :post="post" />
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center min-vh-100"
    >
      <b-spinner
        variant="primary"
        large
      />
    </div>
  </div>
</template>

<script>
import Post from './Post'
import Ad from './Ad'
import BlogPostsMixin from '../../mixins/BlogPostsMixin'

export default {
  name: 'PostsContainer',
  components: {Post, Ad},
  mixins: [BlogPostsMixin],
  props: {
    sidebar: {
      type: [Number, Boolean]
    }
  },
  data () {
    return {
      posts: [
        {
          id: 1,
          author: {
            name: 'Vick'
          },
          created_at: 'May 4, 2021',
          comments_count: 0,
          title: 'This is a stardard post with preview image',
          description: 'Cras blandit dapibus lacus, vel posuere lectus aliquet sit amet. Cras id sapien purus. Curabitur accumsan...',
          image: 'https://images.unsplash.com/photo-1620887143027-d5d964247c31?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80',
          categories: [
            {
              id: 1,
              name: 'Marketing'
            },
            {
              id: 2,
              name: 'Business'
            }
          ]
        },
        {
          id: 2,
          author: {
            name: 'Mark'
          },
          created_at: 'May 3, 2021',
          comments_count: 3,
          title: 'This is a stardard post with preview image',
          description: 'Cras blandit dapibus lacus, vel posuere lectus aliquet sit amet. Cras id sapien purus. Curabitur accumsan...',
          image: 'https://images.unsplash.com/photo-1611095560192-ccc932f617e1?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2251&q=80',
          categories: [
            {
              id: 3,
              name: 'Reviews'
            }
          ]
        },
        {
          id: 3,
          author: {
            name: 'Vick'
          },
          created_at: 'May 1, 2021',
          comments_count: 11,
          title: 'This is a stardard post with preview image',
          description: 'Cras blandit dapibus lacus, vel posuere lectus aliquet sit amet. Cras id sapien purus. Curabitur accumsan...',
          image: 'https://images.unsplash.com/photo-1620853724545-78c2ab1ffa82?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80',
          categories: [
            {
              id: 1,
              name: 'Marketing'
            },
            {
              id: 2,
              name: 'Business'
            },
            {
              id: 3,
              name: 'Reviews'
            }
          ]
        },
        {
          id: 4,
          author: {
            name: 'Mark'
          },
          created_at: 'May 3, 2021',
          comments_count: 3,
          title: 'This is a stardard post with preview image',
          description: 'Cras blandit dapibus lacus, vel posuere lectus aliquet sit amet. Cras id sapien purus. Curabitur accumsan...',
          image: 'https://images.unsplash.com/photo-1620830958756-46335282c574?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80',
          categories: [
            {
              id: 3,
              name: 'Reviews'
            }
          ]
        },
        {
          id: 5,
          author: {
            name: 'Vick'
          },
          created_at: 'May 1, 2021',
          comments_count: 11,
          title: 'This is a stardard post with preview image',
          description: 'Cras blandit dapibus lacus, vel posuere lectus aliquet sit amet. Cras id sapien purus. Curabitur accumsan...',
          image: 'https://images.unsplash.com/photo-1620920709484-995504fbecd2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80',
          categories: [
            {
              id: 1,
              name: 'Marketing'
            },
            {
              id: 2,
              name: 'Business'
            },
            {
              id: 3,
              name: 'Reviews'
            }
          ]
        },
        {
          id: 6,
          author: {
            name: 'Vick'
          },
          created_at: 'May 4, 2021',
          comments_count: 0,
          title: 'This is a stardard post with preview image',
          description: 'Cras blandit dapibus lacus, vel posuere lectus aliquet sit amet. Cras id sapien purus. Curabitur accumsan...',
          image: 'https://images.unsplash.com/photo-1620925641579-3762587b77da?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1300&q=80',
          categories: [
            {
              id: 1,
              name: 'Marketing'
            },
            {
              id: 2,
              name: 'Business'
            }
          ]
        },
        {
          id: 7,
          author: {
            name: 'Mark'
          },
          created_at: 'May 3, 2021',
          comments_count: 3,
          title: 'This is a stardard post with preview image',
          description: 'Cras blandit dapibus lacus, vel posuere lectus aliquet sit amet. Cras id sapien purus. Curabitur accumsan...',
          image: 'https://images.unsplash.com/photo-1611095560192-ccc932f617e1?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2251&q=80',
          categories: [
            {
              id: 3,
              name: 'Reviews'
            }
          ]
        },
        {
          id: 8,
          author: {
            name: 'Vick'
          },
          created_at: 'May 1, 2021',
          comments_count: 11,
          title: 'This is a stardard post with preview image',
          description: 'Cras blandit dapibus lacus, vel posuere lectus aliquet sit amet. Cras id sapien purus. Curabitur accumsan...',
          image: 'https://images.unsplash.com/photo-1620853724545-78c2ab1ffa82?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80',
          categories: [
            {
              id: 1,
              name: 'Marketing'
            },
            {
              id: 2,
              name: 'Business'
            },
            {
              id: 3,
              name: 'Reviews'
            }
          ]
        },
        {
          id: 9,
          author: {
            name: 'Mark'
          },
          created_at: 'May 3, 2021',
          comments_count: 3,
          title: 'This is a stardard post with preview image',
          description: 'Cras blandit dapibus lacus, vel posuere lectus aliquet sit amet. Cras id sapien purus. Curabitur accumsan...',
          image: 'https://images.unsplash.com/photo-1620830958756-46335282c574?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80',
          categories: [
            {
              id: 3,
              name: 'Reviews'
            }
          ]
        },
        {
          id: 10,
          author: {
            name: 'Vick'
          },
          created_at: 'May 4, 2021',
          comments_count: 0,
          title: 'This is a stardard post with preview image',
          description: 'Cras blandit dapibus lacus, vel posuere lectus aliquet sit amet. Cras id sapien purus. Curabitur accumsan...',
          image: 'https://images.unsplash.com/photo-1620887143027-d5d964247c31?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80',
          categories: [
            {
              id: 1,
              name: 'Marketing'
            },
            {
              id: 2,
              name: 'Business'
            }
          ]
        },
        {
          id: 12,
          author: {
            name: 'Vick'
          },
          created_at: 'May 4, 2021',
          comments_count: 0,
          title: 'This is a stardard post with preview image',
          description: 'Cras blandit dapibus lacus, vel posuere lectus aliquet sit amet. Cras id sapien purus. Curabitur accumsan...',
          image: 'https://images.unsplash.com/photo-1620887143027-d5d964247c31?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80',
          categories: [
            {
              id: 1,
              name: 'Marketing'
            },
            {
              id: 2,
              name: 'Business'
            }
          ]
        },
        {
          id: 11,
          author: {
            name: 'Mark'
          },
          created_at: 'May 3, 2021',
          comments_count: 3,
          title: 'This is a stardard post with preview image',
          description: 'Cras blandit dapibus lacus, vel posuere lectus aliquet sit amet. Cras id sapien purus. Curabitur accumsan...',
          image: 'https://images.unsplash.com/photo-1611095560192-ccc932f617e1?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2251&q=80',
          categories: [
            {
              id: 3,
              name: 'Reviews'
            }
          ]
        }
      ]
    }
  },
  computed: {
    postDivider () {
      return {
        1: this.blog.data.slice(0, this.sidebar ? 9 : 6),
        2: this.blog.data.slice(6, 9)
      }
    }
  }
}
</script>
