<template>
  <div>
    <b-form-group
      v-for="control in controls"
      :key="control.key"
      :label="control.label"
    >
      <div class="flex items-center justify-between gap-[10px]">
        <vue-slider
          v-model="control.value"
          class="editor-slider-1 !w-[160px]"
          :interval="1"
          :dot-size="17"
          tooltip="none"
          :min="min"
          :max="max"
          @change="onChange"
        />

        <LinkedControlItem
          v-model="control.value"
          :min="min"
          :max="max"
          class="d-block !w-[60px]"
          @input="onChange"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

export default {
  name: 'SidTranslate',
  components: {LinkedControlItem},
  mixins: [SidConcept],
  props: {
    dimension: {
      default: 'px'
    },
    max: {
      default: 4000
    },
    min: {
      default: -4000
    }
  },
  data () {
    return {
      controls: [
        {
          label: 'Translate-X',
          value: 0
        },
        {
          label: 'Translate-Y',
          value: 0
        }
      ]
    }
  },
  created () {
    let value = this.getSidStyle(this.getSid(), 'translate')
    if (value === 'none') return

    value = value.split(' ')

    if (value.length > 1) {
      this.controls[0].value = parseInt(value[0])
      this.controls[1].value = parseInt(value[1])
      return
    }

    this.controls[0].value = parseInt(value[0])
    this.controls[0].value = parseInt(value[0])
  },
  methods: {
    onChange () {
      const x = `${this.controls[0].value}${this.dimension}`
      const y = `${this.controls[1].value}${this.dimension}`

      this.addSidRules([[this.getSid(), [this.ruleName, `${x} ${y}`]]])
    }
  }
}
</script>