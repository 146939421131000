<template>
  <div class="inline mr-[-1px] w-[46px]">
    <div class="relative">
      <input
        ref="$input"
        :value="item"
        :max="max"
        :min="min"
        class="disable-arrows !border-[#E2E5EC] rounded-[4px] h-[29px] w-100 text-[14px] text-[#7E7E7E] pl-[10px]"
        style="border:1px solid;"
        type="number"
        :placeholder="placeholder"
        @input.stop="updateValue"
      >

      <div class="absolute top-1/2 -translate-y-1/2 right-[4px] bg-white">
        <button
          class="flex items-center justify-center w-[10px] h-[8px] hover:bg-gray-300 p-0"
          @click="up"
        >
          <svg
            fill="none"
            height="5"
            viewBox="0 0 7 5"
            width="7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.8333 0.890514C3.70828 0.765489 3.53871 0.695251 3.3619 0.695251C3.18509 0.695251 3.01552 0.765489 2.89049 0.890513L0.223827 3.55718C-0.036523 3.81753 -0.0365229 4.23964 0.223827 4.49999C0.484176 4.76034 0.906285 4.76034 1.16663 4.49999L3.3619 2.30473L5.55716 4.49999C5.81751 4.76034 6.23962 4.76034 6.49997 4.49999C6.76032 4.23964 6.76032 3.81753 6.49997 3.55718L3.8333 0.890514Z"
              fill="#44474A"
            />
          </svg>
        </button>

        <button
          class="flex items-center justify-center w-[10px] h-[8px] hover:bg-gray-300 p-0"
          @click="down"
        >
          <svg
            fill="none"
            height="5"
            viewBox="0 0 7 5"
            width="7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.89057 4.49999C3.0156 4.62501 3.18517 4.69525 3.36198 4.69525C3.53879 4.69525 3.70836 4.62501 3.83338 4.49999L6.50005 1.83332C6.7604 1.57297 6.7604 1.15086 6.50005 0.890514C6.2397 0.630164 5.81759 0.630164 5.55724 0.890514L3.36198 3.08578L1.16672 0.890514C0.906367 0.630164 0.484257 0.630164 0.223908 0.890514C-0.0364418 1.15086 -0.0364418 1.57297 0.223908 1.83332L2.89057 4.49999Z"
              fill="#44474A"
            />
          </svg>
        </button>
      </div>
    </div>

    <span
      class="text-[10px] text-[#6E747A]"
      v-text="label"
    />
  </div>
</template>

<script>
export default {
  name: 'LinkedControlItem',
  props: {
    value: {
      required: true
    },
    ignoreZero: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      default: 1000
    },
    min: {
      type: Number,
      default: -1000
    },
    step: {
      type: Number,
      default: 1
    },
    delay: {
      type: Boolean,
      default: false
    },
    smart: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: '-'
    }
  },
  data () {
    return {
      touched: false,
      timeout: null
    }
  },
  computed: {
    item: {
      get () {
        return this.value || ''
      },
      set (val) {
        this.touched = true
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$emit('input', parseFloat(this.ignoreZero ? val : val === '' ? 0 : val))
        }, this.delay ? 200 : 0)
      }
    }
  },
  mounted () {
    if (this.value !== '') {
      this.touched = true
    }
  },
  methods: {
    updateValue (e) {
      if (e.target.value >= this.min && e.target.value <= this.max) this.item = e.target.value
    },
    up () {
      const val = this.item || 0
      const max = parseFloat(this.$refs.$input.max)
      if (val < max) this.item = (val + this.step).toFixed(2)
    },
    down () {
      const val = this.item || 0
      const min = parseFloat(this.$refs.$input.min)
      if (val > min) this.item = (val - this.step).toFixed(2)
    }
  }
}
</script>
