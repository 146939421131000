<template>
  <div>
    <module-editor
      v-if="!loading"
      ref="editor"
      module-type="modal"
      crumb-title="Modal Layout"
      aside-title="Modal Layout"
      group="blocks"
      :draggable="true"
      @save="onSave"
    >
      <template #header-before>
        <button
          class="flex disabled:opacity-60 items-center rounded-left justify-center h-[36px] w-[41px] border bg-white p-0"
          :disabled="!isActiveUndo"
          @click="undo"
        >
          <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.4649 10.7396C16.6349 10.704 16.7547 10.5567 16.7547 10.3867C16.7547 4.51518 9.7847 3.26446 8.16565 3.05318V0.35908C8.16565 0.226318 8.0928 0.10489 7.97541 0.0409374C7.85641 -0.0205864 7.71556 -0.0116817 7.60627 0.0603659L0.157034 5.07456C0.059082 5.14094 -1.52588e-05 5.25103 -1.52588e-05 5.37327C-1.52588e-05 5.49227 0.059082 5.60318 0.157034 5.66956L7.60222 10.6829C7.71313 10.7558 7.8556 10.7639 7.97218 10.7016C8.09037 10.6384 8.16322 10.5162 8.16322 10.3826V7.48451C9.3346 7.49179 10.3044 7.5436 11.1115 7.64075C14.9851 8.09975 16.0205 10.4255 16.0626 10.5251C16.12 10.6587 16.2512 10.7445 16.3945 10.7445C16.4187 10.7461 16.443 10.7445 16.4649 10.7396Z"
              fill="#6E747A"
            />
          </svg>
        </button>
        <button
          class="flex disabled:opacity-60 items-center rounded-right border-left-0 justify-center h-[36px] w-[41px] border bg-white p-0"
          :disabled="!isActiveRedo"
          @click="redo"
        >
          <svg
            width="17"
            height="11"
            viewBox="0 0 17 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.28981 10.7396C0.11981 10.704 0 10.5567 0 10.3867C0 4.51518 6.97 3.26446 8.58905 3.05318V0.35908C8.58905 0.226318 8.6619 0.10489 8.77929 0.0409374C8.89829 -0.0205864 9.03914 -0.0116817 9.14843 0.0603659L16.5977 5.07456C16.6956 5.14094 16.7547 5.25103 16.7547 5.37327C16.7547 5.49227 16.6956 5.60318 16.5977 5.66956L9.15248 10.6829C9.04157 10.7558 8.8991 10.7639 8.78252 10.7016C8.66433 10.6384 8.59148 10.5162 8.59148 10.3826V7.48451C7.4201 7.49179 6.45029 7.5436 5.64319 7.64075C1.76962 8.09975 0.734238 10.4255 0.692143 10.5251C0.634667 10.6587 0.503524 10.7445 0.360238 10.7445C0.335952 10.7461 0.311667 10.7445 0.28981 10.7396Z"
              fill="#6E747A"
            />
          </svg>
        </button>
      </template>

      <template #aside>
        <SidbearSettingsGroup
          title="Blocks"
          content-class="p-0 no-scrollbar"
        >
          <blocks-drag-items
            class="h-100"
            :filtered-blocks="['empty-blocks']"
          />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Rows"
          content-class="p-0 no-scrollbar"
          :active="$store.state.editor.page === 'add-row'"
        >
          <rows-drag-items class="h-100" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Elements"
          content-class="p-0"
          :active="$store.state.editor.page === 'add-element'"
        >
          <elements-drag-items />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Favorites"
          content-class="p-0"
        >
          <favorites-drag-items class="border-0" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Modal Settings"
          active
        >
          <modal-settings />
        </SidbearSettingsGroup>
      </template>

      <template v-if="!loadingRedoUndo">
        <div class="absolute top-0 left-0 h-full w-full bg-top bg-[url('https://www.dropbox.com/s/nrg3r3wkbhv9g7n/Group%20163.jpg?raw=1')]" />
        <div
          class="absolute top-0 left-0 w-full h-full"
          :style="getEditorBackground"
        />

        <div
          ref="modalElement"
          class="modal-dialog mx-0 !max-w-full flex h-full"
          :style="{minHeight: 'inherit', alignItems: modal.settings.verticalAlign || 'center'}"
        >
          <div class="modal-content bg-transparent border-0">
            <div
              class="modal-body p-0"
            >
              <content-drop-area
                v-if="modal.content"
                v-model="modal.content"
                group="rows"
                class="position-static d-flex flex-column w-100"
                add-group="rows"
                :style="{alignItems: modal.settings.horizontalAlign || 'center'}"
              >
                <div
                  v-if="modal.settings.closeIcon"
                  class="text-right position-absolute right-0 my-3 mx-4 z-index-100"
                >
                  <b-link>
                    <svg
                      fill="none"
                      height="11"
                      viewBox="0 0 11 11"
                      width="11"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        :fill="modal.settings.closeIconColor"
                        d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
                      />
                    </svg>
                  </b-link>
                </div>
              </content-drop-area>
            </div>
          </div>
        </div>
      </template>
    </module-editor>

    <!--    <div-->
    <!--      class="modal-dialog"-->
    <!--      :style="{maxWidth: '100%'}"-->
    <!--    >-->
    <!--      <div-->
    <!--        class="modal-content bg-transparent border-0"-->
    <!--      >-->
    <!--        <div class="modal-body bo p-0">-->
    <!--          <draggable-->
    <!--            v-model="modal.content"-->
    <!--            :style="{minHeight: `${!modal.content.length ? '50vh': '100%'}`}"-->
    <!--            chosen-class="block-chosen"-->
    <!--            class="position-static d-flex align-items-center flex-column w-100"-->
    <!--            :class="{'empty-row-drag': !modal.content.length}"-->
    <!--            group="rows"-->
    <!--            handle=".move"-->
    <!--          >-->
    <!--            <component-->
    <!--              :is="section.component"-->
    <!--              v-for="(section, index) in modal.content"-->
    <!--              :key="section.uid"-->
    <!--              :section="section"-->
    <!--              :highliter-options="{hideAdd: true, hideCopy: true}"-->
    <!--              @delete="() => deleteSection(index)"-->
    <!--              @dublicate="() => dublicateElement(index)"-->
    <!--              @move-to="to => moveTo(index, to)"-->
    <!--              @add-subject="() => addBlock(index)"-->
    <!--              @favorite="node => favoriteBlock = {section: JSON.parse(JSON.stringify(section)), node}"-->
    <!--            >-->
    <!--                  <div-->
    <!--                    v-if="modal.settings.closeIcon && !index"-->
    <!--                    class="text-right position-absolute right-0 my-3 mx-4 z-index-100"-->
    <!--                  >-->
    <!--                    <b-link>-->
    <!--                      <svg-->
    <!--                        fill="none"-->
    <!--                        height="11"-->
    <!--                        viewBox="0 0 11 11"-->
    <!--                        width="11"-->
    <!--                        xmlns="http://www.w3.org/2000/svg"-->
    <!--                      >-->
    <!--                        <path-->
    <!--                          :fill="modal.settings.closeIconColor"-->
    <!--                          d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"-->
    <!--                        />-->
    <!--                      </svg>-->
    <!--                    </b-link>-->
    <!--                  </div>-->
    <!--            </component>-->
    <!--          </draggable>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <add-to-favorite-modal-->
    <!--      :favorite="favoriteBlock"-->
    <!--      @submit="favorite"-->
    <!--    />-->
  </div>
</template>

<script>
import {mapState} from 'vuex'
import BorderMixin from '@/components/mixins/BorderMixin'
import ViewportUpdate from '@/mixins/viewportUpdate'
import ElementsDragItems from '@/components/editor/components/ElementsDragItems.vue'
import FavoritesDragItems from '@/components/editor/components/FavoritesDragItems.vue'
import RowsDragItems from '@/components/editor/components/RowsDragItems.vue'
import ContentDropArea from '@/components/editor/components/ContentDropArea.vue'
import BlocksDragItems from '@/components/editor/components/BlocksDragItems.vue'
import ModuleEditor from '@/views/module-editor/ModuleEditor.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import UndoRedoSupport from '@/components/mixins/UndoRedoSupport'
import ModalSettings from '@/views/modal/components/ModalSettings.vue'
import BackgroundMixin from '@/components/mixins/BackgroundMixin'

export default {
  name: 'ModalEditor',
  components: {
    ModalSettings,
    SidbearSettingsGroup,
    ModuleEditor,
    BlocksDragItems,
    ContentDropArea,
    RowsDragItems,
    FavoritesDragItems,
    ElementsDragItems
  },
  mixins: [BorderMixin, ViewportUpdate, UndoRedoSupport, BackgroundMixin],
  data () {
    return {
      loading: false,
      model: true,
      favoriteBlock: {}
    }
  },
  computed: {
    ...mapState('modal', {
      modal: state => state.modal
    }),
    ...mapState('editor', {
      resolution: state => state.resolution
    }),
    getTargetContent: {
      get () {
        return this.modal.content
      },
      set (val) {
        this.$set(this.modal, 'content', val)
      }
    },
    getEditorBackground () {
      if (this.modal.settings.hideBackdrop) return {}

      return {
        ...this.backgroundColorSubject(this.modal.settings),
        backdropFilter: this.modal.settings.blurred ? 'blur(10px)' : null
      }
    }
  },
  async beforeRouteLeave (to, from, next) {
    const answer = await this.$refs.editor.confirmLeave()
    if (!answer) return false
    next()
  },
  async created () {
    this.$store.commit('editor/SET_PAGE', '')

    if (this.$route.params.modal_id !== 'new-modal') {
      VEvent.fire('loader', true)
      this.loading = true
      await this.$store.dispatch('modal/startEdit', {
        id: this.$route.params.modal_id
      })
      VEvent.fire('loader', false)
      this.loading = false
    }

    this.$store.state.editor.backLink = {
      name: 'module.modal'
    }

    this.initUndoRedoSupport()
  },
  methods: {
    onSave () {
      if (!this.modal.name) {
        return this.$swal({
          icon: 'error',
          title: 'Name is required',
          showConfirmButton: false,
          timer: 1500
        })
      }

      VEvent.fire('loader', true)
      this.loading = true
      this.$store.dispatch('modal/save')
        .then(() => {
          this.loading = false

          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Modal has been saved.',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    }
  }
}
</script>

<style lang="scss">
.modal-editor {
  .highlight-section.active {
    .right,
    .higliter-add,
    .move,
    .arrows {
      display: none !important;
    }
  }

  .editor-wrapper {
    padding-top: 20px;
    align-items: center !important;
    min-height: 80vh;
    background: rgba(236, 236, 236, 0.6);

    .es-root {
      min-height: 400px !important;
      background: transparent;
      box-shadow: none !important;
    }

    .blocks-container {
      min-height: 400px !important;
    }
  }

  .save-btn {
    position: fixed;
    top: 180px;
    right: 85px;
    z-index: 1000;
  }
}
</style>
