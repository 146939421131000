<template>
  <b-form-group>
    <div class="flex items-center justify-between">
      <label class="pb-0">Flip</label>

      <div class="flex gap-[6px]">
        <b-btn
          variant="white"
          class="w-[29px] h-[29px] flex items-center justify-center p-0"
          @click="() => flipXMutation === '1' ? flipXMutation = '-1' : flipXMutation = '1'"
        >
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.04167 2.16667C7.04167 1.86751 6.79916 1.625 6.5 1.625C6.20085 1.625 5.95833 1.86751 5.95833 2.16667L5.95833 10.8333C5.95833 11.1325 6.20085 11.375 6.5 11.375C6.79916 11.375 7.04167 11.1325 7.04167 10.8333L7.04167 2.16667Z"
              :fill="flipXMutation === '-1' ? '#7CACFA' : '#44474A'"
            />
            <path
              d="M4.875 6.5C4.875 6.64366 4.81793 6.78143 4.71635 6.88302L2.54968 9.04968C2.33815 9.26122 1.99518 9.26122 1.78365 9.04968C1.57212 8.83815 1.57212 8.49518 1.78365 8.28365L3.5673 6.5L1.78365 4.71635C1.57212 4.50482 1.57212 4.16185 1.78365 3.95032C1.99518 3.73878 2.33815 3.73878 2.54968 3.95032L4.71635 6.11698C4.81793 6.21857 4.875 6.35634 4.875 6.5Z"
              :fill="flipXMutation === '-1' ? '#7CACFA' : '#44474A'"
            />
            <path
              d="M11.2164 3.95032C11.4279 4.16185 11.4279 4.50482 11.2164 4.71635L9.4327 6.5L11.2163 8.28365C11.4279 8.49518 11.4279 8.83815 11.2163 9.04968C11.0048 9.26122 10.6619 9.26122 10.4503 9.04968L8.28365 6.88302C8.07212 6.67148 8.07212 6.32852 8.28365 6.11698L10.4503 3.95032C10.6619 3.73878 11.0048 3.73878 11.2164 3.95032Z"
              :fill="flipXMutation === '-1' ? '#7CACFA' : '#44474A'"
            />
          </svg>
        </b-btn>
        <b-btn
          variant="white"
          class="w-[29px] h-[29px] flex items-center justify-center p-0"
          @click="() => flipYMutation === '1' ? flipYMutation = '-1' : flipYMutation = '1'"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 10.3333C11.8527 10.3333 11.7113 10.2748 11.6072 10.1706L9.38494 7.94839C9.16798 7.73143 9.16798 7.37968 9.38494 7.16272C9.6019 6.94576 9.95366 6.94576 10.1706 7.16272L12 8.9921L13.8294 7.16272C14.0463 6.94576 14.3981 6.94576 14.6151 7.16272C14.832 7.37968 14.832 7.73144 14.6151 7.94839L12.3928 10.1706C12.2887 10.2748 12.1473 10.3333 12 10.3333Z"
              :fill="flipYMutation === '-1' ? '#7CACFA' : '#44474A'"
            />
            <path
              d="M14.6151 16.8373C14.3981 17.0542 14.0463 17.0542 13.8294 16.8373L12 15.0079L10.1706 16.8373C9.95366 17.0542 9.6019 17.0542 9.38494 16.8373C9.16798 16.6203 9.16798 16.2686 9.38494 16.0516L11.6072 13.8294C11.8241 13.6124 12.1759 13.6124 12.3928 13.8294L14.6151 16.0516C14.832 16.2686 14.832 16.6203 14.6151 16.8373Z"
              :fill="flipYMutation === '-1' ? '#7CACFA' : '#44474A'"
            />
            <path
              d="M16.4444 12.5556C16.7513 12.5556 17 12.3068 17 12C17 11.6932 16.7513 11.4444 16.4444 11.4444H7.55556C7.24873 11.4444 7 11.6932 7 12C7 12.3068 7.24873 12.5556 7.55556 12.5556H16.4444Z"
              :fill="flipYMutation === '-1' ? '#7CACFA' : '#44474A'"
            />
          </svg>
        </b-btn>
      </div>
    </div>
  </b-form-group>
</template>

<script>

import SidConcept from '@/components/mixins/SidConcept'

export default {
  name: 'SidFlip',
  mixins: [SidConcept],
  data () {
    return {
      flipX: '1',
      flipY: '1'
    }
  },
  computed: {
    flipXMutation: {
      get () {
        return this.flipX
      },
      set (val) {
        this.flipX = val

        if (val == 1 && this.flipYMutation == 1) {
          return this.addSidRules([[this.getSid(), [this.ruleName, '']]])
        }

        this.addSidRules([[this.getSid(), [this.ruleName, `${this.flipX} ${this.flipYMutation}`]]])
      }
    },
    flipYMutation: {
      get () {
        return this.flipY
      },
      set (val) {
        this.flipY = val

        if (val == 1 && this.flipXMutation == 1) {
          return this.addSidRules([[this.getSid(), [this.ruleName, '']]])
        }
        
        this.addSidRules([[this.getSid(), [this.ruleName, `${this.flipXMutation} ${this.flipY}`]]])
      }
    }
  },
  created () {
    if (this.sidValueMutation !== 'none') {
      const parts = this.sidValueMutation.split(' ')
      if (parts.length > 1) {
        this.flipX = parts[0]
        this.flipY = parts[1]
        return
      }

      this.flipX = parts[0]
      this.flipY = parts[0]
    }
  }
}
</script>