<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    style="min-height: 100px;"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('gridCollapse')"
  >
    <Template
      :class="{'element-hidden': !visability('gridCollapse')}"
      :section="section"
    >
      <div
        v-for="(items, rowIndex) in rows"
        :key="rowIndex"
        class="collapase-grid"
      >
        <b-row
          class="align-items-center"
          no-gutters
        >
          <b-col
            v-for="(item, itemIndex) in items"
            :key="itemIndex"
            :class="{'shadow rounded-top': root.activeShadow && getGlobalIndex(rowIndex, itemIndex) === globalActiveIndex}"
            :cols="cols[resolutioner]"
            :data-col="getGlobalIndex(rowIndex, itemIndex)"
            class="collapase-grid-item position-relative h-100 mb-4"
          >
            <elements
              v-model="item.trigger"
              @flow="handleFlow"
            />
            <div
              :class="{'collapase-grid-active-line': getGlobalIndex(rowIndex, itemIndex) === globalActiveIndex}"
              :style="{backgroundColor: root.activeBorderColor}"
            />
          </b-col>
        </b-row>

        <div class="mb-4">
          <b-collapse
            :ref="getCollapseID(rowIndex)"
            :accordion="accordionID"
            :visible="activeRow === rowIndex"
            role="tabpanel"
          >
            <b-card>
              <b-card-body>
                <b-link
                  class="link-muted position-absolute top-3 right-3"
                  @click="collapse(getCollapseID(rowIndex))"
                >
                  <i class="fas fa-times" />
                </b-link>

                <elements
                  v-if="root.items[globalActiveIndex]"
                  v-model="root.items[globalActiveIndex].content"
                  @flow="handleFlow"
                />
              </b-card-body>
            </b-card>
          </b-collapse>
        </div>
      </div>
    </Template>
  </highliter>
</template>

<script>
import _ from 'lodash'
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import shortid from 'shortid'
import ResolutionMixin from '../../../mixins/ResolutionMixin'
import Template from './Template'

export default {
  name: 'FeaturedItems1',

  components: {Highliter, Template},

  mixins: [SectionMixin, ResolutionMixin],

  data () {
    return {
      accordionID: shortid.generate()
    }
  },

  computed: {
    globalActiveIndex: {
      get () {
        return this.root.active
      },
      set (val) {
        this.root.active = val
      }
    },

    root () {
      return this.section.options.content.gridCollapse
    },

    rows () {
      return _.chunk(this.root.items, (12 / this.root.cols[this.resolutioner]))
    },

    activeRow () {
      if (this.globalActiveIndex === false) {
        return -1
      }

      return Math.floor(this.globalActiveIndex / (12 / this.root.cols[this.resolutioner]))
    }
  },

  watch: {
    globalActiveIndex (val) {
      if (this.root.autoscroll) {
        const $target = this.$el.querySelector(`[data-col='${val}']`)
        if ($target) {
          const top = ($target.getBoundingClientRect().y + window.scrollY) - 100
          window.scrollTo({top: top, behavior: 'smooth'})
        }
      }
    }
  },

  created () {
    this.globalActiveIndex = false
  },

  methods: {
    getCollapseID (rowIndex) {
      return `collapse-${this.accordionID}-${rowIndex}`
    },

    collapse (ref) {
      this.$refs[ref][0].toggle()
      setTimeout(() => {
        this.globalActiveIndex = false
      }, 300)
    },

    getGlobalIndex (rowIndex, itemIndex) {
      if (this.root.items.length <= (12 / this.root.cols[this.resolutioner])) {
        return itemIndex
      }

      const prevRowsCount = this.rows.length - (this.rows.length - rowIndex)
      const globalItemIndex = (prevRowsCount * (12 / this.root.cols[this.resolutioner]) + itemIndex)

      return globalItemIndex
    }
  }
}
</script>
