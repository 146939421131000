<template>
  <div>
    <div class="flex flex-col rounded-lg bg-white overflow-hidden h-100">
      <div class="flex-shrink-0">
        <img
          class="h-52 w-full object-cover rounded-bottom-0"
          :src="post.cover.src"
          alt="Blog Image"
        >
      </div>

      <div class="p-4">
        <div class="font-size-13 text-gray-blog text-muted mb-3">
          <span
            :style="{color: globalStyles.colors.primary}"
            v-text="post.author ? post.author.name : 'Default User'"
          /> <time>{{ post.created_at | moment("MMM DD, YYYY") }}</time> · <span
            v-text="post.comments_count ? `${post.comments_count} Comments` : 'No Comments'"
          />
        </div>

        <b-link
          class="text-decoration-none"
          :to="{name: 'blog.post', params: {slug: post.slug}}"
        >
          <h5
            class="post-title cursor-pinter mb-3"
            v-text="post.title"
          />
        </b-link>

        <p
          class="post-text"
          v-text="post.description"
        />

        <div
          v-if="categories()"
          class="d-flex align-items-center"
        >
          <svg
            class="mr-2"
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.583374 1.91666C0.583374 0.950161 1.36688 0.166656 2.33337 0.166656H5.83337C6.02844 0.166656 6.21056 0.264132 6.31877 0.426415L7.31224 1.91666H11.6667C12.6332 1.91666 13.4167 2.70016 13.4167 3.66666V10.0833C13.4167 11.0498 12.6332 11.8333 11.6667 11.8333H2.33337C1.36688 11.8333 0.583374 11.0498 0.583374 10.0833V1.91666ZM2.33337 1.33332C2.01121 1.33332 1.75004 1.59449 1.75004 1.91666V10.0833C1.75004 10.4055 2.01121 10.6667 2.33337 10.6667H11.6667C11.9889 10.6667 12.25 10.4055 12.25 10.0833V3.66666C12.25 3.34449 11.9889 3.08332 11.6667 3.08332H7.00004C6.80497 3.08332 6.62286 2.98585 6.51465 2.82357L5.52119 1.33332H2.33337Z"
              fill="#6E747A"
            />
          </svg>

          <span
            :style="{color: globalStyles.colors.primary}"
            class="font-size-11 text-uppercase"
            v-text="categories()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Post',
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    })
  },
  methods: {
    // description() {
    //   return
    // },
    categories () {
      return this.post.categories
        .map(cat => cat.name)
        .join(', ')
    }
  }
}
</script>

<style scoped>
.text-gray-blog {
  color: #B2B2B2;
}

.post-title {
  font-weight: bold;
  font-size: 21px;
}

.post-text {
  color: #6E747A;
  font-size: 15px;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
