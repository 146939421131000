<template>
  <div
    class="dashboard-space"
    style="padding-top: 130px"
  >
    <module-header />

    <div class="d-flex justify-content-center">
      <div class="px-[20px] lg:px-[150px] xl:px-[280px] pt-[40px] pb-[190px]">
        <h1
          class="
            text-[21px] text-center text-lg-left
            w-100
            mb-[68px]
            block
            font-[500]
          "
        >
          Modules
        </h1>

        <div
          class="
            grid
            sm:grid-cols-1
            lg:grid-cols-2
            xl:grid-cols-3
            2xl:grid-cols-4
            gap-[51px]
          "
        >
          <module-card
            v-for="module in modules"
            :key="module.id"
            :icon="icons[module.code]"
            :module="module"
            @access="access"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleHeader from '../../components/editor/header/ModuleHeader'
import ModuleCard from '@/components/editor/components/ModuleCard'
import {mapState} from 'vuex'
import Cookie from 'js-cookie'

export default {
  name: 'Modules',
  components: {ModuleCard, ModuleHeader},
  data () {
    return {
      modules: [],
      icons: {
        'advanced-analytics': require('@builder/assets/svg/modules/advanced-analytics.svg'),
        'affiliates': require('@builder/assets/svg/modules/affiliates.svg'),
        'blog': require('@builder/assets/svg/modules/blog.svg'),
        'contacts': require('@builder/assets/svg/modules/contacts.svg'),
        'deadline-redirects': require('@builder/assets/svg/modules/deadline-redirects.svg'),
        'ecommerce': require('@builder/assets/svg/modules/ecommerce.svg'),
        'interactive-community': require('@builder/assets/svg/modules/interactive-community.svg'),
        'link-shortner': require('@builder/assets/svg/modules/link-shortner.svg'),
        'live-chat': require('@builder/assets/svg/modules/live-chat.svg'),
        'marketing-automation': require('@builder/assets/svg/modules/marketing-automation.svg'),
        'marketing-funnels': require('@builder/assets/svg/modules/marketing-funnels.svg'),
        'memberships': require('@builder/assets/svg/modules/memberships.svg'),
        'products': require('@builder/assets/svg/modules/products.svg'),
        'support-desk': require('@builder/assets/svg/modules/support-desk.svg'),
        'surveys': require('@builder/assets/svg/modules/surveys.svg'),
        'webinars': require('@builder/assets/svg/modules/webinar.svg')
      }
    }
  },
  computed: {
    ...mapState('projects', {
      project: (state) => state.project
    })
  },
  created () {
    axios.get(`api/modules?project_id=${this.project.id}`).then(({data}) => {
      this.modules = data.data
    })
  },
  methods: {
    access (module) {
      if (module.code === 'blog') {
        return window.location.href = `http://localhost:3000?auth-token=${Cookie.get('cross_estage_token')}`
      }

      if (module.code === 'marketing-funnels') {
        return window.location.href = `https://marketing-funnel-module.vercel.app/?project=${this.project.id}`
      }
    }
  }
}
</script>

<style lang="scss">
.module-card {
  width: 288px;
  height: 191px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  border: 1px solid #e3e5eb;
  transition: 0.4s;

  &.active {
    &:hover {
      background-color: #f8fcff;
      border: 1px solid #c6dbfc;

      path {
        fill: #3d82ea;
      }
    }

    .title {
      font-weight: 600;
    }
  }

  .title {
    color: #6e747a;
    font-size: 17px;
  }

  &.coming-soon {
    .title {
      color: #7e7e7e;
      opacity: 0.5;
    }
  }
}
</style>
