<template>
  <div class="position-relative">
    <iframe
      :src="getSrc()"
      frameborder="0"
      style="overflow:hidden;height:100vh;width:100%"
      height="100%"
      width="100%"
    />
  </div>
</template>

<script>
import {addhttp} from '../../utils/urlHelper'
import {mapState} from 'vuex'

export default {
  name: 'PreviewModuleIframeblog',
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  methods: {
    getSrc () {
      return this.project.domain ? addhttp(this.project.domain) : `${process.env.VUE_APP_ESTAGE_SITE_HOST}` + `/${this.project.subdomain}/blog?hide_navigation=true`
    }
  }
}
</script>
