<script>
import EstageTrackingActions from '@/components/editor/controls/es-tracking/EstageTrackingActions.vue'

export default {
  name: 'EstageTracking',
  components: {EstageTrackingActions},
  props: {
    section: {
      type: Object,
      required: true
    },
    events: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      event: ''
    }
  },
  computed: {
    model () {
      if (!this.section.hasOwnProperty('estageTracking')) {
        this.$set(this.section, 'estageTracking', {
          trackName: '',
          events: []
        })
      }

      return this.section.estageTracking
    }
  },
  methods: {
    selectedEvent (val) {
      this.event = ''
      this.model.events = [val, ...this.model.events]
    },
    removeEvent (index) {
      this.model.events.splice(index, 1)
    }
  }
}
</script>

<template>
  <div>
    <b-form-group label="Tracking Name">
      <b-input v-model="model.trackName" />
    </b-form-group>

    <b-form-group label="Estage Tracking Event">
      <v-select
        v-model="event"
        :options="events"
        :searchable="false"
        class="custom-select"
        left
        placeholder="Select Event"
        @option:selected="selectedEvent"
      />
    </b-form-group>

    <template v-if="model.events.length">
      <b-form-group>
        <div class="flex flex-wrap gap-[3px] border p-[9px] rounded-[2px]">
          <div
            v-for="(event, index) in model.events"
            :key="event.value"
            class="flex items-center bg-[#3D82EA] py-[3px] px-[7px] rounded-[3px] text-[11px] font-[700] text-white"
          >
            <span
              class="mr-[7px]"
              v-text="event.label"
            />

            <button
              class="hover:opacity-80 p-0 m-0"
              @click="() => removeEvent(index)"
            >
              <svg
                fill="none"
                height="7"
                viewBox="0 0 6 7"
                width="6"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.640159 0.609834C0.493714 0.463389 0.256279 0.463389 0.109834 0.609834C-0.0366113 0.756279 -0.0366113 0.993714 0.109834 1.14016L2.46968 3.5L0.109834 5.85984C-0.0366111 6.00629 -0.0366113 6.24372 0.109834 6.39017C0.256279 6.53661 0.493714 6.53661 0.640159 6.39017L3 4.03033L5.35978 6.39011C5.50623 6.53656 5.74366 6.53656 5.89011 6.39011C6.03656 6.24366 6.03656 6.00623 5.89011 5.85978L3.53033 3.5L5.89011 1.14022C6.03656 0.99377 6.03656 0.756336 5.89011 0.609891C5.74366 0.463445 5.50623 0.463445 5.35978 0.609891L3 2.96968L0.640159 0.609834Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </b-form-group>

      <b-form-group>
        <EstageTrackingActions
          v-for="event in model.events"
          :key="event.value"
          :event="event"
          :section="section"
        />
      </b-form-group>
    </template>
  </div>
</template>