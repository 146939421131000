<template>
  <div class="sid-select">
    <SidbearSettingsGroup
      title="Compare Slider"
      active
    >
      <b-form-group class="mb-4">
        <b-checkbox v-model="section.options.content.compare.vertical">
          Vertical
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <sid-range-slider
          rule-name="width"
          :sid="`.--${section.sid}--compare-slider`"
          default-dimension="%"
          :dimensions="['%', 'vw', 'px']"
          label="Image Size"
          var="--width"
          measurement="width"
        />
      </b-form-group>

      <sid-align
        :sid="`.--${section.sid}--container`"
        rule-name="justify-content"
        label="Alignment"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Before Image"
    >
      <sid-upload-image
        :sid="`.--${section.sid}--compare-slider .after-image`"
        title=""
        rule-name="content"
      />

      <sid-background-filters
        :sid="`.--${section.sid}--compare-slider .after-image`"
        rule-name="filter"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="After Image">
      <sid-upload-image
        :sid="`.--${section.sid}--compare-slider .before-image`"
        title=""
        rule-name="content"
      />

      <sid-background-filters
        :sid="`.--${section.sid}--compare-slider .before-image`"
        rule-name="filter"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Handler Button"
      group
    >
      <b-form-group class="mb-0">
        <sid-checkbox
          unchecked-value="flex"
          value="none"
          rule-name="display"
          :sid="`.--${section.sid}--handler-button`"
        >
          Hide Button
        </sid-checkbox>
      </b-form-group>

      <b-form-group>
        <icon-select v-model="section.options.content.compare.handleIcon" />
      </b-form-group>

      <SidbearSettingsGroup 
        title="Layout & Style"
        button-class="ml-[4px]"
        class="mb-0"
      >
        <rule-tabs>
          <template slot="Normal">
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="mb-0">Icon Color</label>
                <sid-color
                  rule-name="color"
                  :sid="`.--${section.sid}--handler-icon`"
                />
              </div>
            </b-form-group>

            <sid-range-slider
              label="Width"
              rule-name="width"
              :sid="`.--${section.sid}--handler-button`"
            />

            <sid-range-slider
              label="Height"
              rule-name="height"
              :sid="`.--${section.sid}--handler-button`"
            />

            <sid-background
              :sid="`.--${section.sid}--handler-button`"
            />
          </template>
          <template slot="Hover">
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="mb-0">Icon Color</label>
                <sid-color
                  rule-name="color"
                  :sid="`.--${section.sid}--handler-icon`"
                  presudo=":hover"
                />
              </div>
            </b-form-group>

            <sid-range-slider
              label="Width"
              rule-name="width"
              presudo=":hover"
              :sid="`.--${section.sid}--handler-button`"
            />

            <sid-range-slider
              label="Height"
              rule-name="height"
              presudo=":hover"
              :sid="`.--${section.sid}--handler-button`"
            />

            <sid-background
              :sid="`.--${section.sid}--handler-button`"
              presudo=":hover"
            />
          </template>
        </rule-tabs>
      </SidbearSettingsGroup>


      <SidbearSettingsGroup
        title="Border & Radius"
        button-class="ml-[4px]"
        class="mb-0"
      >
        <rule-tabs>
          <template slot="Normal">
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Border Type</label>

                <sid-select
                  :sid="`.--${section.sid}--handler-button`"
                  rule-name="border-style"
                  :searchable="false"
                  class="w-[118px]"
                  :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
                />
              </div>
            </b-form-group>

            <b-form-group>
              <sid-linked-group
                :sid="`.--${section.sid}--handler-button`"
                label="Border Width"
                rule-name="border"
                class="!mb-[7px]"
                :angles="[
                  {
                    text: '',
                    value: 'top-width'
                  },
                  {
                    text: '',
                    value: 'right-width'
                  },
                  {
                    text: '',
                    value: 'bottom-width'
                  },
                  {
                    text: '',
                    value: 'left-width'
                  }
                ]"
              />

              <div class="flex w-full justify-end">
                <sid-color
                  :sid="`.--${section.sid}--handler-button`"
                  rule-name="border-color"
                />
              </div>
            </b-form-group>

            <sid-linked-group
              label="Radius"
              :sid="`.--${section.sid}--handler-button`"
              rule-name="border"
              class="mb-0"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius'
                }
              ]"
            />
          </template>

          <template slot="Hover">
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Border Type</label>

                <sid-select
                  :sid="`.--${section.sid}--handler-button`"
                  rule-name="border-style"
                  :searchable="false"
                  class="w-[118px]"
                  presudo=":hover"
                  :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
                />
              </div>
            </b-form-group>

            <b-form-group>
              <sid-linked-group
                :sid="`.--${section.sid}--handler-button`"
                label="Border Width"
                rule-name="border"
                class="!mb-[7px]"
                presudo=":hover"
                :angles="[
                  {
                    text: '',
                    value: 'top-width'
                  },
                  {
                    text: '',
                    value: 'right-width'
                  },
                  {
                    text: '',
                    value: 'bottom-width'
                  },
                  {
                    text: '',
                    value: 'left-width'
                  }
                ]"
              />

              <div class="flex w-full justify-end">
                <sid-color
                  :sid="`.--${section.sid}--handler-button`"
                  presudo=":hover"
                  rule-name="border-color"
                />
              </div>
            </b-form-group>

            <sid-linked-group
              label="Radius"
              :sid="`.--${section.sid}--handler-button`"
              rule-name="border"
              presudo=":hover"
              class="mb-0"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius'
                }
              ]"
            />
          </template>
        </rule-tabs>
      </SidbearSettingsGroup>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Handler Line">
      <rule-tabs>
        <template slot="Normal">
          <b-form-group>
            <sid-range-slider
              label="Width"
              rule-name="--handler-size"
              :sid="`.--${section.sid}--handler`"
            />
          </b-form-group>

          <sid-background
            :sid="`.--${section.sid}--handler`"
          />
        </template>
        <template slot="Hover">
          <b-form-group>
            <sid-range-slider
              label="Width"
              rule-name="--handler-size"
              :sid="`.--${section.sid}--handler`"
              presudo=":hover"
            />
          </b-form-group>

          <sid-background
            :sid="`.--${section.sid}--handler`"
            presudo=":hover"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <rule-tabs>
        <template slot="Normal">
          <sid-box-shadow
            :sid="`.--${section.sid}--compare-slider`"
            rule-name="box-shadow"
          />
        </template>

        <template slot="Hover">
          <sid-box-shadow
            :sid="`.--${section.sid}--compare-slider`"
            presudo=":hover"
            rule-name="box-shadow"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Border & Radius">
      <rule-tabs>
        <template slot="Normal">
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--container`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--container`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--container`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--container`"
            rule-name="border"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>

        <template slot="Hover">
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--container`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--container`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--container`"
                presudo=":hover"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--container`"
            rule-name="border"
            presudo=":hover"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Padding & Margins">
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--container`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidStyles from '@/components/mixins/SidStyles'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidUploadImage
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidUploadImage.vue'
import SidBackgroundFilters
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundFilters.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import IconSelect from '@/components/editor/components/IconSelect.vue'
export default {
  name: 'CompareSliderMod',
  components: {
    IconSelect,
    SidBackground,
    SidCheckbox,
    SidBoxShadow,
    SidAlign,
    SidRangeSlider,
    SidBackgroundFilters,
    SidUploadImage,
    SidColor,
    SidSelect,
    RuleTabs,
    SidLinkedGroup,
    SidbearSettingsGroup
  },
  mixins: [ModMixin, SidStyles]
}
</script>