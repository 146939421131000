import SectionProvider from '@builder/sections/SectionProvider'
import shortid from 'shortid'

export const name = 'Card Slider'
export const group = 'Advanced'
export const dev = true

export const defaultOptions = {
  'content': {
    carousel: {
      type: 'card-carousel-mod',
      animationType: 1,
      cards: [
        {
          sid: `col-${shortid.generate()}`,
          name: 'Card #1',
          elements: []
        },
        {
          sid: `col-${shortid.generate()}`,
          name: 'Card #1',
          elements: []
        },
        {
          sid: `col-${shortid.generate()}`,
          name: 'Card #2',
          elements: []
        },
        {
          sid: `col-${shortid.generate()}`,
          name: 'Card #3',
          elements: []
        },
        {
          sid: `col-${shortid.generate()}`,
          name: 'Card #4',
          elements: []
        },
        {
          sid: `col-${shortid.generate()}`,
          name: 'Card #5',
          elements: []
        },
        {
          sid: `col-${shortid.generate()}`,
          name: 'Card #6',
          elements: []
        }
      ]
    }
  }
}

export const icon = `<svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="5.5" width="5" height="11" rx="1.5" stroke="black"/>
<rect x="19.5" y="5.5" width="5" height="11" rx="1.5" stroke="black"/>
<rect x="8.5" y="0.5" width="8" height="16" rx="1.5" stroke="black"/>
</svg>
`
export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElCardCarousel', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}

