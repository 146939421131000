<template>
  <b-form-group>
    <div class="flex items-center w-full justify-between">
      <label class="pt-0">Background Overlay</label>

      <sid-checkbox
        rule-name="display"
        :sid="sid"
        unchecked-value="none"
        value="block"
      />
    </div>

    <b-form-group>
      <sid-range-slider
        label="Opacity"
        :sid="sid"
        default-dimension=""
        rule-name="opacity"
        :dimensions="[]"
        :min="0"
        :max="1"
        :step="0.1"
      />
    </b-form-group>

    <b-form-group class="mb-4">
      <sid-background
        :sid="sid"
        rule-name="background-color"
      />
    </b-form-group>
  </b-form-group>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'

export default {
  name: 'SidOverlay',
  components: {SidBackground, SidCheckbox, SidRangeSlider},
  mixins: [SidConcept]
}
</script>