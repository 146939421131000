import { Mark } from 'tiptap'
import { updateMark, removeMark } from 'tiptap-commands'

export default class TiptapSelection extends Mark {

  get name () {
    return 'selection'
  }

  get defaultOptions () {
    return {
      selection: ['selection']
    }
  }

  get schema () {
    return {
      attrs: {
        selection: {
          default: 'selection'
        }
      },
      parseDOM: this.options.selection.map(() => ({
        tag: 'selection'
      })),
      toDOM:
          () => {
            return ['selection', {}, 0]
          }
    }
  }

  commands ({ type }) {
    return (attrs) => {
      if (!attrs) {
        return removeMark(type, attrs)
      }

      return updateMark(type, attrs)
    }
  }
}
