<template>
  <div
    class="flex items-center gap-[19px] h-[90px] px-[23px] py-[23px] bg-white rounded-[7px] border cursor-pointer folders-shadow transition"
    @click="$emit('click', folder)"
  >
    <div class="flex flex-row w-full items-start gap-[19px]">
      <img
        class="w-[34px] h-[34]"
        src="@/assets/icons/folder.svg"
      >
      <div class="flex flex-col">
        <span class="text-[#44474A] folder-title text-[17px] font-[500] truncate">{{ folder.name }}</span>
        <div
          v-if="folder.videosCount"
          class="inline-flex items-center gap-2 text-[#9FA9BA] text-[15px]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            width="16"
            height="16"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path 
              d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z" 
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ folder.videosCount }} Videos
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    folder: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss">
.folder-title {
  max-width: 155px;
}
.folders-shadow {
  &:hover {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  }
}
</style>