import _ from 'lodash'
import shortid from 'shortid'

export default {
  methods: {
    GetResponse () {
      let data = {}

      data.email = this.getEmail()
      data.list_id = this.customs.selectedList

      if (this.customs.fields.length >= 2) {
        data.custom_fields = {}

        this.customs.fields.forEach(field => {
          if (field.name !== 'email') {
            data.custom_fields[field.customFieldId] = field
          }
        })
      }

      return data
    },

    Sendlane () {
      let data = {}

      data.email = this.getEmail()
      data.list_id = this.customs.selectedList

      if (this.customs.fields.length >= 2) {
        data.custom_fields = {}

        this.customs.fields.forEach(field => {
          if (field.name !== 'email') {
            data.custom_fields[field.customFieldId] = field
          }
        })
      }

      return data
    },

    MailChimp () {
      let data = {}
      data.email = this.getEmail()
      data.list_id = this.customs.selectedList

      if (this.customs.fields.length >= 2) {
        data.custom_fields = {}

        this.customs.fields.forEach(field => {
          if (field.name !== 'email') {
            data.custom_fields[field.tag] = field
          }
        })
      }

      return data
    },

    Ontraport () {
      let data = {}
      data.email = this.getEmail()
      data.tags = !this.customs.selectedTags ? [] : this.customs.selectedTags.map(tag => parseInt(tag.tag_id))

      if (this.customs.fields.length >= 2) {
        data.custom_fields = {}

        this.customs.fields.forEach(field => {
          if (field.name !== 'email') {
            data.custom_fields[field.label] = field
          }
        })
      }

      return data
    },

    ContactForm (fields, form) {
      let data = {}
      data.email = form.recipientEmailAddress
      data.custom_fields = {}

      fields.forEach(field => {
        data.custom_fields[shortid.generate()] = {
          value: field.v.$model,
          label: this.getLabel(field),
          required: false
        }
      })

      return data
    },

    ActiveCampaign () {
      let data = {}
      data.email = this.getEmail()

      if (this.customs.fields.length >= 2) {
        data.custom_fields = {}

        this.customs.fields.forEach(field => {
          if (field.name !== 'email') {
            data.custom_fields[field.name] = field
          }
        })
      }

      return data
    },

    AWeber () {
      let data = {}
      data.email = this.getEmail()
      data.list_id = this.customs.selectedList

      if (this.customs.fields.length >= 2) {
        data.custom_fields = {}

        this.customs.fields.forEach(field => {
          if (field.name !== 'email') {
            data.custom_fields[field.id] = {
              ...field,
              label: field.apiLabel || _.startCase(field.name)
            }
          }
        })
      }

      return data
    },
    getLabel (field) {
      const inputType = Object.keys(field.section.options.content)
      return  _.startCase(field.section.options.content[inputType].fieldName)
    },

    getEmail (fields) {
      const email = _.head(fields.filter(field => field.v.$params.email))
      return email ? email.v.$model : null
    }
  }
}
