export const removeWWW = host => {
  if (!host) host = window.location.host
  const result = host.match(/^www\.(.*)/)
  return result ? result[1] : host
}

export const isCurrentCustomDomain = () => {
  return window.location.host !== process.env.VUE_APP_LOCAL_HOST && removeWWW(window.location.host) !== process.env.VUE_APP_EDITOR_HOST
}

export const addhttp = (url) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = 'http://' + url
  }
  return url
}
