<template>
  <div
    v-if="visability('group')"
    :style="{...backgroundColor, ...padding, ...shadow}"
  >
    <div
      class="image-background"
      :style="{...backgroundImage}"
    />
    <div :class="container">
      <div class="video-item-3">
        <b-row>
          <slot />
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'VideoItems3Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.video-item-3 {
}
</style>
