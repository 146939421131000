<template>
  <div>
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '../../../../mixins/TemplateMixin'

export default {
  name: 'Carousel1Template',
  mixins: [TemplateMixin]
}
</script>
