<template>
  <div>
    <SidbearSettingsGroup
      active
      title="Carousel"
    >
      <b-form-group v-if="linked && !subjectHide.includes('unlink')">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <b-form-group>
        <draggable
          v-model="section.options.content.carousel.slides"
          handle=".move"
        >
          <transition-group name="shufle">
            <carousel-drag-item
              v-for="(slide, index) in section.options.content.carousel.slides"
              :key="`key-${index}`"
              :item="slide"
              :active="activeSlide === slide.uid"
              @delete="() => deleteSlide(index, slide)"
              @open="() => selectSlide(slide.uid, index)"
            >
              <template #active>
                <sid-background :sid="`.--${section.sid}--${slide.uid}--bg`" />

                <sid-upload-image
                  :sid="`.--${section.sid}--${slide.uid}--bg`"
                  rule-name="background-image"
                />

                <b-form-group label="Background Image Size">
                  <sid-select
                    priority
                    :searchable="false"
                    :options="[
                      {
                        label: 'Normal',
                        value: 'auto',
                        customProperties: [[`.--${section.sid}--${slide.uid}--bg`, ['background-size', 'auto']], [`.--${section.sid}--${slide.uid}--bg`, ['background-attachment', 'unset']]]
                      },
                      {
                        label: 'Full Center (Parallax)',
                        value: 'fixed',
                        customProperties: [[`.--${section.sid}--${slide.uid}--bg`, ['background-size', 'cover']], [`.--${section.sid}--${slide.uid}--bg`, ['background-attachment', 'fixed']]]
                      },
                      {
                        label: 'Fill 100 % Width',
                        value: '100% auto',
                        customProperties: [[`.--${section.sid}--${slide.uid}--bg`, ['background-attachment', 'unset']]]
                      },
                      {
                        label: 'Fill 100 % Width & Height',
                        value: '100% 100%',
                        customProperties: [[`.--${section.sid}--${slide.uid}--bg`, ['background-attachment', 'unset']]]
                      },
                      {
                        label: 'Repeat',
                        value: 'repeat',
                        property: 'background-repeat',
                        customProperties: [[`.--${section.sid}--${slide.uid}--bg`, ['background-size', 'auto']], [`.--${section.sid}--${slide.uid}--bg`, ['background-repeat', 'repeat']], [`.--${section.sid}--${slide.uid}--bg`, ['background-attachment', 'unset']]]
                      },
                      {
                        label: 'Repeat Horizontally',
                        value: 'repeat-x',
                        property: 'background-repeat',
                        customProperties: [[`.--${section.sid}--${slide.uid}--bg`, ['background-size', 'auto']], [`.--${section.sid}--${slide.uid}--bg`, ['background-repeat', 'repeat-x']], [`.--${section.sid}--${slide.uid}--bg`, ['background-attachment', 'unset']]]
                      },
                      {
                        label: 'Repeat Vertically',
                        value: 'repeat-y',
                        property: 'background-repeat',
                        customProperties: [[`.--${section.sid}--${slide.uid}--bg`, ['background-size', 'auto']], [`.--${section.sid}--${slide.uid}--bg`, ['background-repeat', 'repeat-y']], [`.--${section.sid}--${slide.uid}--bg`, ['background-attachment', 'unset']]]
                      },
                      {
                        label: 'Cover',
                        value: 'cover',
                        customProperties: [[`.--${section.sid}--${slide.uid}--bg`, ['background-size', 'cover']], [`.--${section.sid}--${slide.uid}--bg`, ['background-attachment', 'unset']]]
                      },
                      {
                        label: 'Contain',
                        value: 'contain',
                        customProperties: [[`.--${section.sid}--${slide.uid}--bg`, ['background-size', 'contain']], [`.--${section.sid}--${slide.uid}--bg`, ['background-attachment', 'unset']]]
                      }
                    ]"
                    :sid="`.--${section.sid}--${slide.uid}--bg`"
                    var="--background-size"
                    rule-name="background-size"
                  />
                </b-form-group>

                <b-form-group label="Background Image Position">
                  <sid-select
                    :searchable="false"
                    :options="[
                      {label: 'Left Top', value: 'left top'},
                      {label: 'Left Center', value: 'left center'},
                      {label: 'Left Bottom', value: 'left bottom'},
                      {label: 'Right Top', value: 'right top'},
                      {label: 'Right Center', value: 'right center'},
                      {label: 'Right Bottom', value: 'right bottom'},
                      {label: 'Center Top', value: 'center top'},
                      {label: 'Center Center', value: 'center center'},
                      {label: 'Center Bottom', value: 'center bottom'},
                    ]"
                    :sid="`.--${section.sid}--${slide.uid}--bg`"
                    rule-name="background-position"
                  />
                </b-form-group>

                <b-form-group
                  label="Shadow Color"
                  class="mb-0"
                >
                  <sid-box-shadow
                    :sid="`.--${section.sid}--${slide.uid}--bg`"
                    rule-name="box-shadow"
                  />
                </b-form-group>
              </template>
            </carousel-drag-item>
          </transition-group>
        </draggable>

        <button
          class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
          @click="addSlide"
        >
          Add Slide
          <span class="text-[#3D82EA] text-[20px]">+</span>
        </button>

        <b-form-group label="Gap">
          <div class="flex gap-[10px] align-items-center justify-between">
            <vue-slider
              v-model="section.options.content.carousel.gap[resolutioner]"
              :dot-size="17"
              :max="200"
              :min="0"
              class="editor-slider-1 !w-[160px]"
              tooltip="none"
            />

            <LinkedControlItem
              v-model="section.options.content.carousel.gap[resolutioner]"
              :min="0"
              :max="200"
              class="d-block !w-[60px]"
            />
          </div>
        </b-form-group>

        <sid-range-slider
          :sid="`.--${section.sid}--slider`"
          rule-name="width"
          label="Slider Width"
          var="--width"
          :dimensions="['px', 'vw', '%']"
          default-dimension="%"
          measurement="width"
        />

        <div class="h-[81px]">
          <div
            v-if="updateHeight"
          >
            <sid-range-slider
              v-if="direction === 'vertical'"
              :sid="`.--${section.sid}--slider.swiper-direction-vertical`"
              rule-name="height"
              label="Slider Height"
              :dimensions="['px', 'vh']"
              default-dimension="vh"
              var="--height"
              measurement="height"
            />

            <sid-range-slider
              v-else
              :sid="`.--${section.sid}--slider`"
              rule-name="height"
              label="Slider Height"
              :dimensions="['px', 'vh']"
              default-dimension="vh"
              var="--horizontal-height"
              measurement="height"
            />
          </div>
        </div>

        <sid-buttons-group
          label="Vertical Alignment"
          :options="[
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              value: 'start',
              tooltip: 'Start'
            },
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              value: 'center',
              tooltip: 'Center'
            },
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              value: 'end',
              tooltip: 'End'
            }
          ]"
          :sid="`.--${section.sid}--slider`"
          rule-name="--slide-vertical-alignment"
        />

        <b-form-group>
          <b-form-radio-group
            v-model="section.options.content.carousel.direction[resolutioner]"
            :options="[{value: 'horizontal', text: 'Horizontal'}, {value: 'vertical', text: 'Vertical'}]"
          />
        </b-form-group>

        <b-form-group label="Slides Per View">
          <div class="flex gap-[10px] align-items-center justify-between">
            <vue-slider
              v-model="section.options.content.carousel.slidesPerView[resolutioner]"
              :dot-size="17"
              :max="section.options.content.carousel.slides.length-2"
              :min="1"
              :interval=".25"
              class="editor-slider-1 !w-[160px]"
              tooltip="none"
            />

            <LinkedControlItem
              v-model="section.options.content.carousel.slidesPerView[resolutioner]"
              :min="1"
              :step=".25"
              :max="section.options.content.carousel.slides.length"
              class="d-block !w-[60px]"
            />
          </div>
        </b-form-group>

        <b-form-group>
          <b-checkbox v-model="section.options.content.carousel.loop[resolutioner]">
            Loop
          </b-checkbox>
        </b-form-group>

        <b-form-group>
          <sid-checkbox
            :sid="`.--${section.sid}--slider`"
            rule-name="transition-timing-function"
            :unchecked-value="''"
            value="linear"
            var="--swiper-wrapper-transition-timing-function"
          >
            Linear Animation
          </sid-checkbox>
        </b-form-group>

        <b-form-group>
          <b-checkbox v-model="section.options.content.carousel.autoplay">
            Autoplay
          </b-checkbox>
        </b-form-group>

        <b-form-group>
          <b-checkbox v-model="section.options.content.carousel.centeredSlides">
            Centered Slides
          </b-checkbox>
        </b-form-group>

        <b-form-group>
          <b-checkbox v-model="section.options.content.carousel.autoplayPauseOnMouseEnter">
            Pause On Hover
          </b-checkbox>
        </b-form-group>

        <b-form-group>
          <b-checkbox v-model="section.options.content.carousel.freeMode">
            Free Mode
          </b-checkbox>
        </b-form-group>

        <b-form-group>
          <b-checkbox v-model="section.options.content.carousel.mousewheel">
            Mouse Wheel Support
          </b-checkbox>
        </b-form-group>

        <b-form-group>
          <b-checkbox v-model="section.options.content.carousel.reverseDirection">
            Reverse Direction
          </b-checkbox>
        </b-form-group>

        <b-form-group label="Autoplay Speed (MS)">
          <LinkedControlItem
            v-model="section.options.content.carousel.autoplayDelay"
            :max="10000"
            :min="1000"
            :step="1000"
            class="d-block !w-[120px]"
          />
        </b-form-group>

        <b-form-group class="mb-0">
          <label class="flex items-center justify-between">
            <span>Transfer Control</span>

            <i
              v-b-tooltip.hover
              title="You can transfer control of this Carousel to another on this page. Click the 'Select' button first and then click on another Carousel to connect."
              class="fa fa-question-circle cursor-pointer hover:text-blue-500 ml-1"
            />
          </label>

          <pick-element
            v-model="section"
            :allow-picks="['ElCarouselV3']"
          />
        </b-form-group>
      </b-form-group>

      <b-form-group label="Animation Speed (MS)">
        <LinkedControlItem
          v-model="section.options.content.carousel.speed"
          :max="30000"
          :min="1000"
          :step="1000"
          class="d-block !w-[120px]"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Slides"
    >
      <b-form-group
        label="Transition Speed (MS)"
        class="mb-2"
      >
        <div class="w-[120px]">
          <sid-input
            rule-name="--transition"
            default-dimension="ms"
            :sid="`.--${section.sid}--slider > .slide-container`"
          />
        </div>
      </b-form-group>

      <rule-tabs
        :stack="true"
        :tabs="['Normal', 'Prev', 'Next', 'Active', 'Behind', 'Forward']"
      >
        <template #Normal>
          <sid-transform
            :sid="`.--${section.sid}--slider > .slide-container`"
            rule-name="transform"
            :section="section"
          />

          <sid-range-slider
            label="Opacity"
            rule-name="opacity"
            :dimensions="[]"
            default-dimension=""
            :sid="`.--${section.sid}--slider > swiper-slide`"
            :min="0"
            :max="1"
            :step="0.1"
          />
        </template>

        <template #Prev>
          <sid-transform
            :sid="`.--${section.sid}--slider > .swiper-slide-prev`"
            rule-name="transform"
            :section="section"
            var="--prev-transform"
          />

          <sid-range-slider
            label="Opacity"
            rule-name="opacity"
            :dimensions="[]"
            default-dimension=""
            :sid="`.--${section.sid}--slider > .swiper-slide-prev`"
            :min="0"
            :max="1"
            :step="0.1"
          />
        </template>

        <template #Next>
          <sid-transform
            :sid="`.--${section.sid}--slider > .swiper-slide-next`"
            rule-name="transform"
            :section="section"
            var="--next-transform"
          />

          <sid-range-slider
            label="Opacity"
            rule-name="opacity"
            :dimensions="[]"
            default-dimension=""
            :sid="`.--${section.sid}--slider > .swiper-slide-next`"
            :min="0"
            :max="1"
            :step="0.1"
          />
        </template>

        <template #Active>
          <sid-transform
            :sid="`.--${section.sid}--slider > .swiper-slide-active`"
            rule-name="transform"
            :section="section"
            var="--active-transform"
          />

          <sid-range-slider
            label="Opacity"
            rule-name="opacity"
            :dimensions="[]"
            default-dimension=""
            :sid="`.--${section.sid}--slider > .swiper-slide-active`"
            :min="0"
            :max="1"
            :step="0.1"
          />
        </template>

        <template #Behind>
          <sid-transform
            :sid="`.--${section.sid}--slider > .swiper-slide-backward`"
            rule-name="transform"
            :section="section"
            var="--behind-transform"
          />

          <sid-range-slider
            label="Opacity"
            rule-name="opacity"
            :dimensions="[]"
            default-dimension=""
            :sid="`.--${section.sid}--slider > .swiper-slide-backward`"
            :min="0"
            :max="1"
            :step="0.1"
          />
        </template>

        <template #Forward>
          <sid-transform
            :sid="`.--${section.sid}--slider > .swiper-slide-forward`"
            rule-name="transform"
            :section="section"
            var="--forward-transform"
          />

          <sid-range-slider
            label="Opacity"
            rule-name="opacity"
            :dimensions="[]"
            default-dimension=""
            :sid="`.--${section.sid}--slider > .swiper-slide-forward`"
            :min="0"
            :max="1"
            :step="0.1"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Prev Button">
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <sid-checkbox
              value="block"
              unchecked-value="none"
              rule-name="display"
              :sid="`.--${section.sid}--prev-btn`"
            >
              Show Icon
            </sid-checkbox>
          </b-form-group>

          <b-form-group>
            <icon-select
              v-model="section.options.content.carousel.prevIcon"
            />
          </b-form-group>

          <sid-upload-image
            :sid="`.--${section.sid}--prev-btn`"
            rule-name="background-image"
          />

          <sid-background
            :sid="`.--${section.sid}--prev-btn`"
          />

          <sid-range-slider
            :sid="`.--${section.sid}--prev-btn i`"
            label="Font Size"
            rule-name="font-size"
          />

          <b-form-group label="Text Color">
            <sid-color
              :sid="`.--${section.sid}--prev-btn i`"
              rule-name="color"
            />
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--prev-btn`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--prev-btn`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--prev-btn`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--prev-btn`"
            rule-name="border"

            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>

        <template #Hover>
          <sid-background
            :sid="`.--${section.sid}--prev-btn`"
            presudo=":hover"
          />

          <sid-range-slider
            :sid="`.--${section.sid}--prev-btn`"
            rule-name="font-size"
            label="Font Size"
            presudo=":hover"
          />

          <b-form-group label="Text Color">
            <sid-color
              :sid="`.--${section.sid}--prev-btn i`"
              rule-name="color"
              presudo=":hover"
            />
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--prev-btn`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--prev-btn`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--prev-btn`"
                presudo=":hover"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--prev-btn`"
            rule-name="border"
            presudo=":hover"

            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>

      <sid-width-and-height
        :sid="`.--${section.sid}--prev-btn`"
      />

      <sid-range-slider
        label="Position X"
        :sid="`.--${section.sid}--prev-btn`"
        rule-name="left"
        :dimensions="[]"
        default-dimension="%"
        var="--left"
      />

      <sid-range-slider
        label="Position Y"
        :sid="`.--${section.sid}--prev-btn`"
        rule-name="top"
        :dimensions="[]"
        default-dimension="%"
        var="--top"
      />

      <sid-translate
        :sid="`.--${section.sid}--prev-btn`"
        rule-name="translate"
        dimension="%"
        :min="-100"
        :max="100"
        :step="1"
      />

      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--prev-btn`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--prev-btn`"
        rule-name="margin"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Next Button">
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <sid-checkbox
              value="block"
              unchecked-value="none"
              rule-name="display"
              :sid="`.--${section.sid}--next-btn`"
            >
              Show Icon
            </sid-checkbox>
          </b-form-group>

          <b-form-group>
            <icon-select
              v-model="section.options.content.carousel.nextIcon"
            />
          </b-form-group>

          <sid-upload-image
            :sid="`.--${section.sid}--next-btn`"
            rule-name="background-image"
          />

          <sid-background
            :sid="`.--${section.sid}--next-btn`"
          />

          <sid-range-slider
            :sid="`.--${section.sid}--next-btn i`"
            label="Font Size"
            rule-name="font-size"
          />

          <b-form-group label="Text Color">
            <sid-color
              :sid="`.--${section.sid}--next-btn i`"
              rule-name="color"
            />
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--next-btn`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--next-btn`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--next-btn`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--next-btn`"
            rule-name="border"

            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>

        <template #Hover>
          <sid-background
            :sid="`.--${section.sid}--next-btn`"
            presudo=":hover"
          />

          <sid-range-slider
            :sid="`.--${section.sid}--next-btn`"
            rule-name="font-size"
            label="Font Size"
            presudo=":hover"
          />

          <b-form-group label="Text Color">
            <sid-color
              :sid="`.--${section.sid}--next-btn i`"
              rule-name="color"
              presudo=":hover"
            />
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--next-btn`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--next-btn`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--next-btn`"
                presudo=":hover"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--next-btn`"
            rule-name="border"
            presudo=":hover"

            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>

      <sid-width-and-height
        :sid="`.--${section.sid}--next-btn`"
      />

      <sid-range-slider
        label="Position X"
        :sid="`.--${section.sid}--next-btn`"
        rule-name="left"
        :dimensions="[]"
        default-dimension="%"
        var="--left"
      />

      <sid-range-slider
        label="Position Y"
        :sid="`.--${section.sid}--next-btn`"
        rule-name="top"
        :dimensions="[]"
        default-dimension="%"
        var="--top"
      />

      <sid-translate
        :sid="`.--${section.sid}--next-btn`"
        rule-name="translate"
        dimension="%"
        :min="-100"
        :max="100"
        :step="1"
      />

      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--next-btn`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--next-btn`"
        rule-name="margin"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Pagination">
      <b-form-group>
        <sid-checkbox
          :sid="`.--pagination--${section.sid}`"
          rule-name="display"
          value="flex"
          unchecked-value="none"
        >
          <div class="translate-y-[-1px]">
            Enable
          </div>
        </sid-checkbox>
      </b-form-group>

      <b-form-group>
        <sid-flex-direction
          :sid="`.--pagination--${section.sid}`"
          rule-name="flex-direction"
        />
      </b-form-group>

      <b-form-group>
        <sid-range-slider
          label="Vertical Position"
          rule-name="--swiper-pagination-bottom"
          :dimensions="[]"
          default-dimension="%"
          :min="-200"
          :max="200"
          :sid="`.--pagination--${section.sid}`"
        />
      </b-form-group>

      <b-form-group>
        <sid-range-slider
          label="Horizontal Position"
          rule-name="--swiper-pagination-left"
          :dimensions="[]"
          default-dimension="%"
          :min="-100"
          :max="100"
          :sid="`.--pagination--${section.sid}`"
        />
      </b-form-group>

      <b-form-group>
        <sid-range-slider
          label="Size"
          rule-name="--swiper-pagination-bullet-size"
          :dimensions="[]"
          default-dimension="px"
          :min="1"
          :max="100"
          :sid="`.--pagination--${section.sid}`"
        />
      </b-form-group>

      <b-form-group>
        <sid-range-slider
          label="Gap"
          rule-name="gap"
          :dimensions="[]"
          default-dimension="px"
          :min="0"
          :max="50"
          :sid="`.--pagination--${section.sid}`"
        />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Active Color</label>

          <sid-color
            rule-name="--swiper-pagination-color"
            :sid="`.--pagination--${section.sid}`"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Inactive Color</label>

          <sid-color
            rule-name="--swiper-pagination-bullet-inactive-color"
            :sid="`.--pagination--${section.sid}`"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Slide Style">
      <sid-background :sid="`.--${section.sid}--slider .slide`" />

      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--slider .slide`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--slider .slide`"
              label="Border Width"
              rule-name="border"
              css-var="--border-size"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--slider .slide`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--slider .slide`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--slider .slide`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--slider .slide`"
              label="Border Width"
              rule-name="border"
              css-var="--border-size"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--slider .slide`"
                rule-name="border-color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--slider .slide`"
            rule-name="border"
            presudo=":hover"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--slider`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--slider`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidTransform from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTransform'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
import SidTranslate from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTranslate'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidWidthAndHeight from '@/components/editor/components/sid-controls/sid-control-pressets/SidWidthAndHeight'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import IconSelect from '@/components/editor/components/IconSelect'
import SidUploadImage
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidUploadImage'
import PickElement from '@/components/editor/controls/PickElement'
import draggable from 'vuedraggable'
import CarouselDragItem from '@/components/editor/components/CarouselDragItem'
import EventsMixin from '@/components/editor/mixins/EventsMixin'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox'
import SidInput from '@/components/editor/components/sid-controls/SidInput'
import SidFlexDirection
  from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection.vue'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup.vue'

export default {
  name: 'CarouselModV2',
  components: {
    SidButtonsGroup,
    SidFlexDirection,
    SidInput,
    SidCheckbox,
    SidBoxShadow,
    CarouselDragItem,
    draggable,
    PickElement,
    SidUploadImage,
    IconSelect,
    SidColor,
    SidSelect,
    SidWidthAndHeight,
    SidBackground,
    SidLinkedGroup,
    SidTranslate, LinkedControlItem, SidTransform, RuleTabs, SidbearSettingsGroup, SidRangeSlider
  },
  mixins: [ModMixin, EventsMixin],
  data () {
    return {
      selectedSlide: null,
      updateHeight: true
    }
  },
  computed: {
    direction () {
      return this.section.options.content.carousel.direction[this.resolutioner]
    },
    activeSlide: {
      get () {
        return this.selectedSlide
      },
      set (val) {
        this.selectedSlide = val
      }
    }
  },
  watch: {
    direction () {
      this.updateHeight = false
      setTimeout(() => this.updateHeight = true)
    }
  },
  methods: {
    selectSlide (uid, index) {
      this.activeSlide = uid
      this.fire('onServiceSlideChange', {slide: index})
    },
    deleteSlide (index, slide) {
      this.clearStyles(document.querySelector(`[data-sid="${this.section.sid}--${slide.uid}"]`))

      this.section.options.content.carousel.slides.splice(index, 1)
      this.activeSlide = null
    },
    addSlide () {
      this.section.options.content.carousel.slides.push({
        uid: this.section.options.content.carousel.slides.length + 1,
        options: {},
        content: []
      })
    }
  }
}
</script>
