<template>
  <div
    class="el-flex-wrapper d-flex justify-content-center"
    :style="{...fullMarginSubject(section.options.customize.style, 'marginTop')}"
  >
    <div
      :style="{...width}"
      class="mx-auto w-100"
    >
      <highliter
        v-model="section.name"
        :dublicate="section"
        :uid="section.uid"
        class="el-row"
        delay
        section-type="row"
        v-bind="highliterOptions"
        @active="state => active = state"
        @delete="$emit('delete')"
        @dublicate="$emit('dublicate')"
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @add-subject="$emit('add-subject')"
        @click.stop.native="sectionSettings('flex-mod')"
      >
        <div
          :style="{...backgroundColor, ...border, ...borderRadius, ...shadow, ...fullPaddingSubject(section.options.customize.style)}"
          class="position-relative scrollbar-hide"
          :class="[overflowIsEnabled, scrollIsEnabled, horizontalScrollSnapping, {'element-hidden': !subjectVisability(section.options.content.display)}]"
        >
          <div
            :style="{...borderRadius, ...backgroundImage}"
            class="image-background"
          />
          <background-slider
            :style="{...borderRadius}"
            :styles="backgroundImage"
            :options="backgroundSlider"
          />
          <div
            :style="{...borderRadius, ...backgroundImageOverlay}"
            class="image-background"
          />

          <shape-divider
            :config="section.options.customize.style"
            :styles="{...borderRadius}"
          />

          <slot />

          <div
            v-if="!getColumns.length"
            class="empty-row-drag h-[200px]"
            @click="addNewColumn"
          />

          <div
            v-else
            :class="[direction, wrap, verticalAlign, horizontalAlign]"
            :style="{...height, ...gap}"
            class="flex"
          >
            <flex-wrapper-col
              v-for="(col, index) in getColumns"
              :key="index"
              :col="col"
              :index="index"
              :section="section"
              @flow="handleFlow"
            />
          </div>
        </div>
      </highliter>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import SectionMixin from '@/components/mixins/SectionMixin'
import CustomStylesMixin from '@/components/mixins/CustomStylesMixin'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'
import Highliter from '@/components/builder/utils/Highliter'
import FlexWrapperCol from '@/components/builder/sections/utils/el-inline/FlexWrapperCol'
import ShapeDivider from '@/components/editor/components/shape-divider/ShapeDivider'
import {col} from '@/components/builder/sections/utils/el-inline/Section'
import BackgroundSlider from '@/components/builder/utils/BackgroundSlider'
import FlexScrollMixin from '@/components/builder/sections/utils/el-inline/flex-mixins/FlexScrollMixin'

export default {
  components: {BackgroundSlider, ShapeDivider, FlexWrapperCol, Highliter},
  mixins: [SectionMixin,
    CustomStylesMixin,
    ResolutionMixin,
    BorderMixin,
    FlexScrollMixin],

  props: {
    highliterOptions: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    getColumns () {
      return _.sortBy(this.section.options.content.cols.filter(col => this.subjectVisability(col.display)), [(o) => { return _.get(o, ['sortIndex', this.resolutioner], 0) }])
      // return this.section.options.content.cols
    },
    widthFixedEnabled () {
      return this.section.options.content.fixedWidthEnabled[this.resolutioner]
    },
    heightFixedEnabled () {
      return this.section.options.content.fixedHeightEnabled[this.resolutioner]
    },
    height () {
      const _height = _.get(this.section.options.content.fixedHeightV2, [this.resolutioner, 'value'], _.get(this.section.options.content, ['fixedHeight', this.resolutioner], 50))
      const _heightType = _.get(this.section.options.content.fixedHeightV2, [this.resolutioner, 'type'], 'vh')

      return {
        minHeight: this.heightFixedEnabled ? `${_height}${_heightType}` : '100%'
      }
    },
    width () {
      return {
        maxWidth: this.widthFixedEnabled ? `${this.section.options.content.fixedWidth[this.resolutioner]}px` : `${this.section.options.content.width[this.resolutioner]}%`
      }
    },
    gap () {
      return {
        columnGap: `${this.section.options.content.gap.x[this.resolutioner]}px`,
        rowGap: `${this.section.options.content.gap.y[this.resolutioner]}px `
      }
    },
    reverse () {
      return this.section.options.content.reverse[this.resolutioner]
    },
    direction () {
      return this.section.options.content.column[this.resolutioner] ? this.reverse ? 'flex-column-reverse' : 'flex-column' : this.reverse ? 'flex-row-reverse' : ''
    },
    wrap () {
      return this.section.options.content.wrap[this.resolutioner] ? 'flex-wrap' : ''
    },
    horizontalAlign () {
      return `justify-content-${this.section.options.content.horizontalAlign[this.resolutioner]}`
    },
    verticalAlign () {
      return `align-items-${this.section.options.content.verticalAlign[this.resolutioner]}`
    }
  },

  methods: {
    addNewColumn () {
      this.section.options.content.cols.push(col)
    }
  }
}
</script>