<template>
  <div />
</template>

<script>
import {mapState} from 'vuex'
import router from '@/router'

export default {
  name: 'ProjectImport',

  computed: {
    ...mapState('pages', {
      pages: state => state.pages
    })
  },

  mounted () {
    VEvent.fire('loader', true)
    VEvent.fire('loader.message', 'Please Wait...')

    axios.get(`api/project/by-hash/${this.$route.params.id}`)
      .then(({data}) => {
        if (Array.isArray(data.data)) {
          this.$swal({
            icon: 'error',
            title: 'Invalid Share Code. Please Check and Try Again',
            showConfirmButton: false,
            timer: 2500
          })

          return router.push('/')
        }

        this.$store.dispatch('projects/clone', {
          data,
          css: data.data.css,
          safeMode: this.$route.query.saveMode
        })
      })
  }
}
</script>
