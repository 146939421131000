<template>
  <b-card-body class="border-top-0 pr-3">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="d-flex align-items-center">
        <span class="indicator mr-2" />
        <p class="text-capitalize mb-0">
          {{ labelCase(componentName) }}
        </p>
      </div>
    </div>

    <b-form-radio-group
      v-model="column"
      class="resolution-style-tabs mb-3"
      buttons-variant="light"
      value-field="key"
      text-field="text"
      size="sm"
      buttons
      :options="activeColumns"
    />
    <draggable
      v-model="filledColumns[column].elements"
      handle=".move"
    >
      <transition-group name="shufle">
        <b-card
          v-for="(element, index) in filledColumns[column].elements"
          :key="element.uid"
          no-body
          class="border-0"
          @click="$store.dispatch('editor/sectionSettings', {section: element})"
        >
          <section-item-hover>
            <icon
              class="chevron mr-3"
              icon="chevron.svg"
            />
            <icon
              class="move mr-3"
              icon="move.svg"
            />
            <div block>
              {{ element.name }}
            </div>

            <template slot="menu">
              <b-link
                class="d-flex align-items-center"
                @click.stop="deleteElement(index)"
              >
                <i class="fa fa-trash" />
              </b-link>
            </template>
          </section-item-hover>
        </b-card>
      </transition-group>
    </draggable>
  </b-card-body>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ThemeMixin from '@builder/components/mixins/ThemeMixin'
import draggable from 'vuedraggable'

export default {
  name: 'ColumnsMod',

  components: {draggable},
  mixins: [ModMixin, ThemeMixin],

  data () {
    return {
      column: 0
    }
  },

  computed: {
    filledColumns () {
      return this.config.options.content.columns.filter(col => col.elements.length)
    },
    activeColumns () {
      const activeColumns = []
      Array(this.filledColumns.length)
        .fill({})
        .forEach((col, index) => activeColumns.push({
          key: index,
          text: `Column ${index+1}`
        }))

      return activeColumns
    }
  },

  methods: {
    deleteElement (index) {
      this.config.options.content.columns[this.column].elements.splice(index, 1)
    }
  }
}
</script>
