import {mapState} from 'vuex'
import {removeWWW} from '@/utils/urlHelper'

export default {
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    getSubdomainIfNotDomain () {
      return this.project.domain ? '' : this.project.subdomain
    },
    isCurrentCustomDomain () {
      return removeWWW(window.location.host) !== process.env.VUE_APP_ESTAGE_HOST
    },
    getPageBlankURL () {
      return `${window.location.origin}/site${!this.isCurrentCustomDomain ? '/' + this.project.subdomain : ''}`
    },
    getSiteHome () {
      return this.project.domain ? this.addhttp(this.project.domain).replace('http://', 'https://') : `https://${this.project.subdomain}.${process.env.VUE_APP_ESTAGE_SITE_HOST.replace('http://', '')}`
    }
  },

  methods: {
    addhttp (url) {
      if (!/^(?:f|ht)tps?:\/\//.test(url)) {
        url = 'http://' + url
      }
      return url
    },
    removeWWW (host) {
      if (!host) host = window.location.host
      const result = host.match(/^www\.(.*)/)
      return result ? result[1] : host
    }
  }
}
