<template>
  <div class="mb-10">
    <b-card
      class="settings-page-card"
      body-class="pt-0"
    >
      <div class="border-b border-[#E3E5EB]">
        <router-link
          v-for="(route,i) in routes"
          :key="i"
          :class="{'bg-[#F2F2F2] font-bold':[route.route,...route.actives||[]].includes($route.name)}"
          :to="{name: route.route}"
          class="cursor-pointer inline-flex items-center py-[16px] pl-[25px] pr-[19px] border-r border-[#E3E5EB] text-[17px] text-black leading-[17px] font-normal hover:bg-[#F2F2F2]"
          tag="div"
        >
          <span class="mr-[13px]"> {{ route.title }}</span>
          <svg
            width="7"
            height="11"
            viewBox="0 0 7 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 10L5.10811 5.75L1 1.5"
              stroke="#4E4E4F"
              stroke-width="1.5"
            />
          </svg>
        </router-link>
      </div>
      <div class="pt-[60px] pl-[36px] pr-[36px]">
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>
    </b-card>
  </div>
</template>

<script>

export default {
  computed: {
    routes () {
      return [
        {
          title:'Autoresponders',
          route: 'settings.integrations.autoresponders'
        },
        {
          title:'Payment Processing',
          route: 'settings.integrations.payment-processing'
        },
        {
          title:'Marketing',
          route: 'settings.integrations.marketing'
        },
        {
          title:'Analytics',
          route: 'settings.integrations.analytics'
        },
        {
          title:'Chat',
          route: 'settings.integrations.chat'
        }
      ] 
    }
  }
}
</script>

<style lang="scss" scoped>

</style>