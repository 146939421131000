<template>
  <div>
    <b-form-group label="Title">
      <b-input
        v-model="service.data.title"
        placeholder="Success message title"
      />
    </b-form-group>

    <b-form-group label="Text">
      <b-textarea
        v-model="service.data.text"
        placeholder="Success message text (HTML allowed)"
        rows="5"
      />
    </b-form-group>

    <b-form-group label="Timer (ms)">
      <b-input
        v-model="service.data.timer"
        class="mb-1"
        type="number"
      />
      <span>0 - disabled timer.</span>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="service.data.showConfirmButton">
        Show button confirmation
      </b-checkbox>
    </b-form-group>

    <b-form-group
      v-if="service.data.showConfirmButton"
      label="Confirmation button text"
    >
      <b-input v-model="service.data.confirmButtonText" />
    </b-form-group>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'EmailToAdmin',
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('pages', {
      pages: 'getPages'
    })
  }
}
</script>
