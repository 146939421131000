<template>
  <div class="colors-palette">
    <ul
      class="flex gap-[10px] flex-wrap list-unstyled"
      role="listbox"
    >
      <li
        v-for="(color, index) in palette"
        :key="index"
        :style="{background: color}"
        aria-label="color:#1F3B9C"
        class="w-[38px] h-[38px] border rounded position-relative group"
        role="option"
        @click="model = color"
      >
        <div
          class="group-hover:opacity-100 opacity-0 duration-[.4s] absolute top-[-6px] right-[-6px] bg-gray-200 rounded-circle h-[20px] w-[20px] flex items-center justify-center cursor-pinter"
          @click.stop="palette.splice(index, 1)"
        >
          <i class="fa fa-close text-gray-500" />
        </div>
        <div
          v-if="model === color"
          class="vc-compact-dot"
        />
      </li>
    </ul>

    <b-dropdown
      menu-class="p-0"
      toggle-class="btn-icon h-[38px] w-[38px] border mt-[1px]"
      @hide="pickNewColor"
    >
      <template slot="button-content">
        <i class="fa fa-plus" />
      </template>

      <chrome-picker
        :preset-colors="[]"
        :value="colors"
        @input="e => newColor = e.hex8"
      />
    </b-dropdown>
  </div>
</template>

<script>
const colors = {
  hex: '#194d33e6'
}

export default {
  name: 'ColorPollite',
  props: {
    palette: {
      type: Array
    },
    value: {
      required: true
    }
  },
  data () {
    return {
      colors: colors,
      newColor: '#fff'
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    pickNewColor () {
      this.palette.push(this.newColor)
    }
  }
}
</script>

<style lang="scss">
.vc-compact-color-item {
  .pollite-remove {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 14px;
    width: 14px;
    border-radius: 20rem;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    background: #fff;
  }

  &:hover .pollite-remove {
    display: flex;
  }
}
</style>
