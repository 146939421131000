<template>
  <div
    class="dashboard-space p-5 w-100"
  >
    <b-container fluid>
      <b-row class="mb-5">
        <b-col md="3">
          <h4 class="font-weight-bold mb-0">
            Mega Menu Builder
          </h4>
        </b-col>

        <b-col class="text-right">
          <b-btn
            v-b-modal.new-modal
            variant="primary"
          >
            <div class="d-flex align-items-center font-weight-bold">
              <div class="mr-2">
                Add New Mega Menu
              </div>
              <svg
                fill="none"
                height="18"
                viewBox="0 0 18 18"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clip-rule="evenodd"
                  d="M17.6 9.3C17.6 13.884 13.884 17.6 9.3 17.6C4.71604 17.6 1 13.884 1 9.3C1 4.71604 4.71604 1 9.3 1C13.884 1 17.6 4.71604 17.6 9.3ZM9.3 16.3C13.166 16.3 16.3 13.166 16.3 9.3C16.3 5.43401 13.166 2.3 9.3 2.3C5.43401 2.3 2.3 5.43401 2.3 9.3C2.3 13.166 5.43401 16.3 9.3 16.3Z"
                  fill="white"
                  fill-rule="evenodd"
                />
                <path
                  d="M12.9 9.53198V8.26798H10.036V5.14798H8.64398V8.26798H5.76398V9.53198H8.64398V12.78H10.036V9.53198H12.9Z"
                  fill="white"
                />
              </svg>
            </div>
          </b-btn>
        </b-col>
      </b-row>

      <div class="py-5">
        <div v-if="!loading">
          <div
            v-if="menus.length"
            class="py-5 list-group"
          >
            <div
              v-for="(menu, index) in menus"
              :key="index"
              class="d-flex w-100 list-group-item justify-content-between !px-[40px] !py-[28px]"
            >
              <div>
                <div class="font-[600] text-[20px] text-truncate">
                  {{ menu.name }}
                </div>
              </div>

              <div class="flex items-center gap-[14px]">
                <b-link
                  :to="{name: 'mega-menu.editor', params: {menu_id: menu.id}}"
                  class="hover:opacity-60 p-0"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0)">
                      <path
                        d="M14.1427 9.00002C13.8271 9.00002 13.5712 9.25585 13.5712 9.57145V15.2856C13.5712 15.6012 13.3154 15.8571 12.9998 15.8571H2.71426C2.39866 15.8571 2.14283 15.6012 2.14283 15.2856V3.85723C2.14283 3.54163 2.39866 3.2858 2.71426 3.2858H9.57131C9.88691 3.2858 10.1427 3.02996 10.1427 2.71437C10.1427 2.39877 9.88691 2.14297 9.57131 2.14297H2.71426C1.7675 2.14297 1 2.91047 1 3.85723V15.2856C1 16.2324 1.7675 16.9999 2.71426 16.9999H12.9998C13.9466 16.9999 14.7141 16.2324 14.7141 15.2856V9.57142C14.7141 9.25585 14.4583 9.00002 14.1427 9.00002Z"
                        fill="#6E747A"
                      />
                      <path
                        d="M16.3595 1.64091C15.9492 1.23056 15.3927 1.00007 14.8124 1.00014C14.2318 0.998465 13.6748 1.22935 13.2656 1.64128L5.73882 9.16743C5.67638 9.23034 5.62927 9.30681 5.60111 9.39085L4.45828 12.8194C4.35853 13.1188 4.52042 13.4424 4.81985 13.5421C4.87794 13.5614 4.93877 13.5713 4.99998 13.5714C5.06132 13.5713 5.12229 13.5614 5.18054 13.5422L8.60907 12.3994C8.69328 12.3713 8.76979 12.324 8.8325 12.2611L16.3593 4.73439C17.2135 3.8802 17.2136 2.4952 16.3595 1.64091ZM15.5513 3.92694L8.12279 11.3554L5.90338 12.0965L6.64222 9.87999L14.0736 2.45154C14.4821 2.0438 15.1439 2.04447 15.5516 2.45301C15.7464 2.64817 15.8561 2.91238 15.857 3.1881C15.8577 3.4653 15.7476 3.73127 15.5513 3.92694Z"
                        fill="#6E747A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                          transform="translate(1 1)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </b-link>

                <delete-confirm
                  title="Are you sure?"
                  description="The mega menu will be deleted, the action cannot be undone."
                  @confirm="() => deleteAction(menu.id)"
                >
                  <template v-slot:activator="{on}">
                    <button
                      class="hover:opacity-60 p-0"
                      v-on="on"
                    >
                      <svg
                        class="ml-1"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.6395 6.03585L10.3712 5.98781L10.0962 13.7429L11.3645 13.7907L11.6395 6.03585Z"
                          fill="#6E747A"
                        />
                        <path
                          d="M9.18616 6.01183H7.91699V13.7669H9.18616V6.01183Z"
                          fill="#6E747A"
                        />
                        <path
                          d="M7.00676 13.7427L6.73178 5.98759L5.46344 6.03567L5.73845 13.7907L7.00676 13.7427Z"
                          fill="#6E747A"
                        />
                        <path
                          d="M1 2.77875V4.08945H2.32249L3.37165 16.4021C3.40041 16.7405 3.67479 17 4.0037 17H13.0781C13.4071 17 13.6816 16.7403 13.7102 16.4019L14.7594 4.08945H16.1032V2.77875H1ZM12.4969 15.6893H4.5848L3.59653 4.08945H13.4853L12.4969 15.6893Z"
                          fill="#6E747A"
                        />
                        <path
                          d="M10.9443 0H6.84654C6.27014 0 5.80121 0.53181 5.80121 1.1855V3.438H7.0556V1.42261H10.7352V3.438H11.9896V1.1855C11.9896 0.53181 11.5207 0 10.9443 0Z"
                          fill="#6E747A"
                        />
                      </svg>
                    </button>
                  </template>
                </delete-confirm>
              </div>
            </div>
          </div>

          <p
            v-else
            class="text-muted text-center"
          >
            You have not created any mega menus yet
          </p>

          <div class="flex w-full justify-content-center">
            <b-pagination
              v-if="pagination.total > pagination.per_page"
              v-model="currentPage"
              :per-page="pagination.per_page"
              :total-rows="pagination.total"
            />
          </div>
        </div>
        <div
          v-else
          class="w-100 flex justify-content-center align-items-center h-[50vh] py-5"
        >
          <b-spinner variant="primary" />
        </div>
      </div>
    </b-container>

    <new-mega-menu-modal />
  </div>
</template>

<script>
import NewMegaMenuModal from '@/components/editor/controls/menu-links-mod/NewMegaMenuModal'
import DeleteConfirm from '@/components/editor/modals/DeleteConfirm'
import {mapState} from 'vuex'

export default {
  name: 'EsMegaMenu',
  components: {DeleteConfirm, NewMegaMenuModal},
  data () {
    return {
      loading: true,
      menus: [],
      pagination: {},
      currentPage: 1
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  watch: {
    currentPage () {
      this.load()
    }
  },
  created () {
    this.load()
  },
  methods: {
    deleteAction (id) {
      this.loading = true
      axios.delete(`api/projects/${this.project.id}/mega-menu/${id}`)
        .then(() => {
          this.load()
        })
    },
    load () {
      this.loading = true
      axios.get(`api/projects/${this.project.id}/mega-menu?perPage=20&page=${this.currentPage}`)
        .then(({data}) => {
          this.menus = data.data.data
          this.pagination = data.data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>