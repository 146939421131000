<template>
  <div
    class="el-flex-wrapper d-flex justify-content-center position-relative w-full p-0 gap-0"
    :class="[`--${section.sid}--container`, `--${section.sid}--flex`]"
    :data-sid="section.sid"
  >
    <div
      class="block-bg inherit-radius"
      :class="`--${section.sid}--bg`"
    />
    
    <div class="el-overlay inherit-radius" />

    <shape-divider
      :config="section.options"
    />
    
    <div class="mx-auto w-100">
      <div
        class="position-relative scrollbar-hide"
        :class="[{'element-hidden': !subjectVisability(section.options.content.display)}]"
      >
        <slot />

        <div
          v-if="!getColumns.length"
          class="empty-row-drag h-[200px]"
          :class="`--${section.sid}--container`"
          @click="addNewColumn"
        />

        <div
          v-else
          class="flex flex-wrapper p-[20px] !max-w-full w-100"
          :class="`--${section.sid}--flex`"
        >
          <slot name="before-col" />
          <div
            v-for="(col, index) in getColumns"
            :key="index"
            class="contents"
          >
            <slot
              v-if="col.special"
              :name="col.key"
              v-bind="{handleFlow, index, column: section.specialCols[col.key]}"
            />
            <flex-wrapper-col-v2
              v-else
              :col="col"
              :index="index"
              :section="section"
              @flow="handleFlow"
            />
          </div>
          <slot name="after-col" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import SectionMixin from '@/components/mixins/SectionMixin'
import CustomStylesMixin from '@/components/mixins/CustomStylesMixin'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'
import {col} from '@/components/builder/sections/utils/el-inline/Section'
import FlexScrollMixin from '@/components/builder/sections/utils/el-inline/flex-mixins/FlexScrollMixin'
import FlexWrapperColV2 from '@/components/builder/sections/utils/el-inline/FlexWrapperColV2'
import ShapeDivider from '@/components/editor/components/shape-divider/ShapeDivider'

export default {
  components: {ShapeDivider, FlexWrapperColV2},
  mixins: [SectionMixin,
    CustomStylesMixin,
    ResolutionMixin,
    BorderMixin,
    FlexScrollMixin],

  props: {
    customBackOptions: {
      type: Object,
      required: true
    }
  },

  computed: {
    getColumns () {
      return _.sortBy(this.section.options.content.cols.filter(col => this.subjectVisability(col.display)), [(o) => { return _.get(o, ['sortIndex', this.resolutioner], 0) }])
    }
  },

  methods: {
    addNewColumn () {
      this.section.options.content.cols.push(col)
    },
    handleFlow () {
      this.$emit('flow')
    }
  }
}
</script>

<style lang="scss">
.flex-col-placeholder .empty-row-drag {
  height: 100% !important;
}

.prevent-select {
  &:before {
    content: '';
    width: 200px;
    height: 200px;
    background: transparent;
    position: absolute;
    top: -100px;
    left: -100px;
    z-index: 1;
  }
}
</style>