<template>
  <div>
    <b-form-group
      label="Offset Y"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="offsetY"
            :interval="1"
            :max="200"
            :min="-200"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col class="p-0">
          <LinkedControlItem
            v-model="offsetY"
            :max="200"
            :min="-200"
            class="d-block !w-[60px]"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Offset X"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="offsetX"
            :interval="1"
            :max="200"
            :min="-200"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col class="p-0">
          <LinkedControlItem
            v-model="offsetX"
            :max="200"
            :min="-200"
            class="d-block !w-[60px]"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Items Gap"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="gap"
            :interval="1"
            :max="200"
            :min="0"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col class="p-0">
          <LinkedControlItem
            v-model="gap"
            :max="200"
            :min="0"
            class="d-block !w-[60px]"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <padding-full-customs :config="padding" />

    <padding-full-customs
      label="Item Padding"
      :config="paddingItem"
    />

    <b-form-group
      class="mb-5"
      label="Border & Radius"
    >
      <border-customs :config="border" />
    </b-form-group>

    <b-form-group
      class="mb-5"
      label="Item Border & Radius"
    >
      <border-customs :config="borderItem" />
    </b-form-group>

    <shadow-customs :config="shadow" />
  </div>
</template>

<script>
import _ from 'lodash'
import ControlMixin from '@/components/mixins/ControlMixin'
import BorderMixin from '@/components/mixins/BorderMixin'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

export default {
  name: 'DropdownCustoms',
  components: {LinkedControlItem},
  mixins: [ControlMixin, BorderMixin],
  computed: {
    gap: {
      get () {
        return _.get(this.config, ['gap', this.resolutioner], _.get(this.config, ['gap', 'lg'], 0))
      },
      set (val) {
        this.$set(this.config, 'gap', {..._.set(this.config['gap'] || {}, [this.resolutioner], val)})
      }
    },
    offsetY: {
      get () {
        return _.get(this.config, ['offsetY', this.resolutioner], _.get(this.config, ['offsetY', 'lg'], 8))
      },
      set (val) {
        this.$set(this.config, 'offsetY', {..._.set(this.config['offsetY'] || {}, [this.resolutioner], val)})
      }
    },
    offsetX: {
      get () {
        return _.get(this.config, ['offsetX', this.resolutioner], _.get(this.config, ['offsetX', 'lg'], 0))
      },
      set (val) {
        this.$set(this.config, 'offsetX', {..._.set(this.config['offsetX'] || {}, [this.resolutioner], val)})
      }
    },
    padding () {
      if (!this.config.padding) {
        this.$set(this.config, 'padding', {
          lg: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          },
          md: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          },
          sm: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
          }
        })
      }

      return this.config.padding
    },
    paddingItem () {
      if (!this.config.paddingItem) {
        this.$set(this.config, 'paddingItem', {
          lg: {
            top: 8,
            bottom: 8,
            left: 15,
            right: 15
          },
          md: {
            top: 8,
            bottom: 8,
            left: 15,
            right: 15
          },
          sm: {
            top: 8,
            bottom: 8,
            left: 15,
            right: 15
          }
        })
      }

      return this.config.paddingItem
    },
    border () {
      if (!this.config.border) {
        this.$set(this.config, 'border', {})
      }

      return this.config.border
    },
    borderItem () {
      if (!this.config.borderItem) {
        this.$set(this.config, 'borderItem', {})
      }

      return this.config.borderItem
    },
    shadow () {
      if (!this.config.shadow) {
        this.$set(this.config, 'shadow', {
          shadowOptions: {
            offsetX: 0,
            blur: 15,
            offsetY: 5,
            spread: 2
          },
          shadowColor: 'rgba(0, 0, 0, 0.15)'
        })
      }

      return this.config.shadow
    }
  }
}
</script>