<template>
  <div class="h-100">
    <iframe
      :src="`${blogHost}/${project.id}/${$route.query.page || 'overview'}?auth-token=${token}&subdomain=${project.subdomain}`"
      class="w-100 h-100"
      @load="onLoad"
    />

    <div
      v-if="loading"
      class="h-[100vh] flex justify-center items-center absolute top-0 left-0 w-100 bg-transparent"
    >
      <b-spinner
        variant="primary"
      />
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import {mapState} from 'vuex'

export default {
  name: 'GeneralSettings',
  data () {
    return {
      loading: true,
      blogHost: 'https://blog-dashboard.estage.com'
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    // blogHost () {
    //   return process.env.VUE_APP_BLOG_HOST
    // },
    token () {
      return process.env.NODE_ENV === 'development'
        ? localStorage.getItem('sanctum_token')
        : Cookie.get('cross_estage_token')
    }
  },
  mounted () {
    document.body.style.overflow = 'hidden'
    const aside = document.getElementById('settings-aside')
    if(aside) aside.style.height = 'calc(100vh - 90px)'
  },
  beforeDestroy () {
    document.body.style.overflow = null
    const aside = document.getElementById('settings-aside')
    if(aside) aside.style.height = null
  },
  methods: {
    onLoad () {
      this.loading = false
    }
  }
}
</script>
