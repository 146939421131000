<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="openMod"
  >
    <template slot="section-type-icon">
      <svg
        fill="none"
        height="16"
        viewBox="0 0 21 16"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9777 14.625H6.27266C5.70781 14.625 5.25 14.2333 5.25 13.75C5.25 13.2667 5.70781 12.875 6.27266 12.875H19.9773C20.5422 12.875 21 13.2667 21 13.75C21 14.2333 20.5425 14.625 19.9777 14.625Z"
          fill="black"
        />
        <path
          d="M19.9777 8.5H6.27266C5.70781 8.5 5.25 8.10829 5.25 7.625C5.25 7.14171 5.70781 6.75 6.27266 6.75H19.9773C20.5422 6.75 21 7.14171 21 7.625C21.0003 8.10829 20.5425 8.5 19.9777 8.5Z"
          fill="black"
        />
        <path
          d="M19.9777 2.375H6.27266C5.70781 2.375 5.25 1.98329 5.25 1.5C5.25 1.01671 5.70781 0.625 6.27266 0.625H19.9773C20.5422 0.625 21 1.01671 21 1.5C21 1.98329 20.5425 2.375 19.9777 2.375Z"
          fill="black"
        />
        <circle
          cx="1.3125"
          cy="1.0625"
          fill="black"
          r="1.3125"
        />
        <circle
          cx="1.3125"
          cy="8.0625"
          fill="black"
          r="1.3125"
        />
        <circle
          cx="1.3125"
          cy="14.1875"
          fill="black"
          r="1.3125"
        />
      </svg>
    </template>

    <Template :section="section">
      <template slot="default">
        <div
          class="flex"
          :style="{...align}"
        >
          <ul
            :class="{'element-hidden': !visability('list')}"
            class="list-unstyled list-element"
          >
            <li
              v-for="(item, index) in section.options.content.list.items"
              :key="item.label"
              :style="space"
            >
              <div
                class="flex"
                :style="{...verticalAlign, ...horizontalGap}"
              >
                <i
                  :class="section.options.customize.style.listIcon.icon"
                  :style="{color: section.options.customize.style.listIcon.color, fontSize: fontSize}"
                  class="mt-1"
                />

                <editable
                  v-if="item.text"
                  v-model="item.text"
                  :style="{...textColor, fontStyle: property(section.options.content.list, 'italic', false) ? 'italic' : '', textDecoration: property(section.options.content.list, 'underline', false) ? 'underline' : ''}"
                  class="w-100"
                />

                <div
                  v-else
                  class="w-full"
                >
                  <div>
                    <content-editable
                      ref="content"
                      v-model="item.subject.options.content.description.text"
                      :customs="item.subject.options.content.description"
                      :style="textColor"
                      custom-class="text-inherit"
                      type="paragraph"
                      @click.native="() => selectSubjectIndex(index)"
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </template>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import PropertiesManager from '../../../mixins/PropertiesManager'
import ResolutionMixin from '../../../mixins/ResolutionMixin'
import _ from 'lodash'
import {mapState} from 'vuex'

export default {
  name: 'FeaturedItems1',

  components: {Template, Highliter},

  mixins: [SectionMixin, PropertiesManager, ResolutionMixin],

  computed: {
    ...mapState('editor', {
      globalSection: state => state.section
    }),
    fontSize () {
      return `${parseInt(_.get(this.section.options.content.list, ['fontSize', this.resolutioner], _.get(this.section.options.content.list, 'fontSize', 16)))}px`
    },
    space () {
      return {
        marginBottom: `${this.section.options.content.list.space || 0}px`
      }
    },
    horizontalGap () {
      return {
        gap: `${_.get(this.section.options.content.list, ['horizontalGap', this.resolutioner], _.get(this.section.options.content.list, ['horizontalGap', 'lg'], 0))}px`
      }
    },
    textColor () {
      return {
        color: _.get(this.section.options.content.list, ['color', this.resolutioner], _.get(this.section.options.content.list, ['color', 'lg'], null))
      }
    },
    verticalAlign () {
      return {
        alignItems: _.get(this.section.options.content.list, ['verticalAlign', this.resolutioner], _.get(this.section.options.content.list, ['verticalAlign', 'lg'], 'center'))
      }
    },
    align () {
      return {
        justifyContent: _.get(this.section.options.content.list, ['align', this.resolutioner], _.get(this.section.options.content.list, ['align', 'lg'], 'start'))
      }
    }
  },

  methods: {
    selectSubjectIndex (index) {
      this.$set(this.section, 'selectedSubjectIndex', index)
      this.sectionSettings('list')
    },
    openMod () {
      this.sectionSettings('list')
    }
  }
}
</script>

<style>
.list-element .paragraph {
  text-align: left !important;
  font-size: 1rem;
  color: inherit;
}
</style>