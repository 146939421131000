<template>
  <div
    :data-sid="`card-details-${sid}`"
    :class="`--${section.sid}--margin`"
    class="form-margin"
  >
    <highliter
      v-model="section.name"
      active-class="z-100"
      class="highlight-element"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <div
        class="flex flex-col mb-0"
        :class="`--${sid}--form-fields`"
      >
        <b-form-group
          class="estage-form-element-group mb-0"
          :class="`--${sid}--form-field --${sid}--form-field-0`"
        >
          <template
            #label
            class="flex items-center gap-x-[4px]"
            :class="`--${sid}--label`"
          >
            Payment Method
            <span
              class="text-danger"
            >
              *
            </span>
          </template>

          <div class="w-full flex flex-wrap gap-[15px] items-center editor-container-sm-flex-col editor-container-sm-items-start">
            <v-select
              v-model="field.paymentMethods"
              :options="paymentMethods"
              label="label"
              required
              class="form-control input w-auto grow"
              :class="`--${sid}--input`"
            />
            <div
              v-if="section.options.content.showPaymentMethods"
              class="w-auto flex items-center gap-[4px]"
            >
              <img
                v-if="section.options.content.paymentMethods.visa"
                src="@/assets/icons/payment-systems/visa.svg"
                class="max-h-[24px]"
              >
              <img
                v-if="section.options.content.paymentMethods.dinersClub"
                src="@/assets/icons/payment-systems/diners-club.svg"
                class="max-h-[24px]"
              >
              <img
                v-if="section.options.content.paymentMethods.amex"
                src="@/assets/icons/payment-systems/amex.svg"
                class="max-h-[24px]"
              >
              <img
                v-if="section.options.content.paymentMethods.discover"
                src="@/assets/icons/payment-systems/discover.svg"
                class="max-h-[24px]"
              >
              <img
                v-if="section.options.content.paymentMethods.mastercard"
                src="@/assets/icons/payment-systems/master-card.svg"
                class="max-h-[24px]"
              >
            </div>
          </div>
        </b-form-group>

        <b-form-group
          class="estage-form-element-group mb-0"
          :class="`--${sid}--form-field --${sid}--form-field-1`"
        >
          <template
            #label
            class="flex items-center gap-x-[4px]"
            :class="`--${sid}--label`"
          >
            Card Number
            <span
              class="text-danger"
            >
              *
            </span>
          </template>

          <b-form-input
            v-model="field.cardNumber"
            v-mask="'#### #### #### ####'"
            placeholder="**** **** **** ****"
            required
            class="input"
            :class="`--${sid}--input`"
          />
        </b-form-group>
      
        <div 
          class="inline-flex flex-wrap w-full items-end editor-container-sm-flex-col editor-container-sm-items-start"
          :class="`--${sid}--card-details`"
        >
          <b-form-group
            class="estage-form-element-group mb-0 editor-container-sm-w-full"
            :class="`--${sid}--form-field --${sid}--form-field-2`"
          >
            <template
              #label
              class="flex items-center gap-x-[4px]"
              :class="`--${sid}--label`"
            >
              Expiry Date
              <span
                class="text-danger"
              >
                *
              </span>
            </template>

            <v-select
              v-model="field.cardExpMonth"
              :options="expMonthsOptions"
              label="label"
              required
              class="form-control input"
              :class="`--${sid}--input`"
            />
          </b-form-group>

          <b-form-group
            class="estage-form-element-group mb-0 editor-container-sm-w-full"
            :class="`--${sid}--form-field --${sid}--form-field-3`"
          >
            <v-select
              v-model="field.cardExpYear"
              :options="expYearsOptions(2050)"
              required
              class="form-control input"
              :class="`--${sid}--input`"
            />
          </b-form-group>
        
          <b-form-group
            class="estage-form-element-group mb-0 editor-container-sm-w-full"
            :class="`--${sid}--form-field --${sid}--form-field-4`"
          >
            <template
              #label
              class="flex items-center gap-x-[4px]"
              :class="`--${sid}--label`"
            >
              CVC/CVV
              <span
                class="text-danger"
              >
                *
              </span>
            </template>

            <b-form-input
              v-model="field.cardCvc"
              v-mask="'###'"
              placeholder="***"
              required
              class="input"
              :class="`--${sid}--input`"
            />
          </b-form-group>
        </div>

        <p
          class="flex flex-row items-center m-0 pb-[16px] border-b border-[#E3E3E3]"
          :class="`--${sid}--secure-payment`"
        >
          <svg
            viewBox="0 0 10 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :class="`--${sid}--secure-payment-icon`"
            class="secure-payment-icon"
          >
            <path
              d="M9.72222 5H8.88888V3.88888C8.88888 1.74453 7.14438 0 5 0C2.85563 0 1.11112 1.74453 1.11112 3.88888V5H0.277786C0.124245 5 0 5.12425 0 5.27779V12.2222C0 12.835 0.498307 13.3333 1.11112 13.3333H8.88891C9.50169 13.3333 10 12.835 10 12.2222V5.27779C10 5.12425 9.87576 5 9.72222 5ZM5.83172 10.8027C5.84039 10.8811 5.81518 10.9597 5.76255 11.0186C5.70992 11.0775 5.63451 11.1111 5.55557 11.1111H4.44445C4.36552 11.1111 4.2901 11.0775 4.23747 11.0186C4.18484 10.9598 4.15961 10.8811 4.16831 10.8027L4.34354 9.2269C4.05899 9.01992 3.88891 8.6925 3.88891 8.33333C3.88891 7.72055 4.38721 7.22222 5.00003 7.22222C5.61284 7.22222 6.11115 7.72052 6.11115 8.33333C6.11115 8.6925 5.94107 9.01992 5.65651 9.2269L5.83172 10.8027ZM7.22222 5H2.77779V3.88888C2.77779 2.66357 3.77469 1.66667 5 1.66667C6.22531 1.66667 7.22222 2.66357 7.22222 3.88888V5Z"
              fill="#B0B0B0"
            />
          </svg>
        
          Your transaction is secured with SSL encryption
        </p>
      </div>
    </highliter>
  </div>
</template>

<script>
import {VueMaskDirective} from 'v-mask'
import BorderMixin from '@/components/mixins/BorderMixin'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElFieldCardDetails',
  directives: {
    mask: VueMaskDirective
  },
  mixins: [BorderMixin, SectionMixin],
  components: {Highliter},
  data () {
    return {
      field: {
        paymentMethods: {
          label: 'Add New Card',
          value: 'new-method'
        },
        cardNumber: null,
        cardExpMonth: null,
        cardExpYear: null,
        cardCvc: null
      },
      expMonthsOptions: [
        {
          value: 1,
          label: 'Jan'
        },
        {
          value: 2,
          label: 'Feb'
        },
        {
          value: 3,
          label: 'Mar'
        },
        {
          value: 4,
          label: 'Apr'
        },
        {
          value: 5,
          label: 'May'
        },
        {
          value: 6,
          label: 'Jun'
        },
        {
          value: 7,
          label: 'Jul'
        },
        {
          value: 8,
          label: 'Aug'
        },
        {
          value: 9,
          label: 'Sep'
        },
        {
          value: 10,
          label: 'Oct'
        },
        {
          value: 11,
          label: 'Nov'
        },
        {
          value: 12,
          label: 'Dec'
        }
      ]
    }
  },
  computed: {
    paymentMethods () {
      return [
        {
          label: 'Add New Card',
          value: 'new-method'
        }
      ]
    }
  },
  methods: {
    expYearsOptions (endYear) {
      let startYear = new Date().getFullYear()
      const endDate = endYear || new Date().getFullYear()
      let years = []
      while (startYear <= endDate) {
        years.push(startYear)
        startYear++
      }
      return years
    }
  }
}
</script>