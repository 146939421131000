<template>
  <div>
    <b-form-group label="Author">
      <div class="d-flex align-items-center mb-2">
        <tags-select
          ref="select"
          v-model="author"
          class="w-full"
          label="name"
          :url="`https://blog-api.estage.com/api/projects/${project.id}/blog/manage/authors`"
        />
      </div>

      <div class="mb-2">
        <slot />
      </div>

      <b-link
        target="_blank"
        class="mb-2"
        @click="modal = true"
      >
        Add new author
      </b-link>
    </b-form-group>

    <b-modal
      v-model="modal"
      hide-footer
      hide-header
      body-class="p-0"
      size="xl"
    >
      <iframe
        :src="`${blogHost}/${project.id}/authors/iframe/create?auth-token=${token}&subdomain=${project.subdomain}`"
        class="w-full h-[500px]"
      />
    </b-modal>
    <blog-add-autor-modal :author="selectedAuthor" />
  </div>
</template>

<script>
import BlogAddAutorModal from './BlogAddAutorModal'
import {mapState} from 'vuex'
import TagsSelect from '@/components/editor/components/TagsSelect.vue'
import Cookie from 'js-cookie'
import _ from 'lodash'
export default {
  name: 'PostSettingsAuthors',
  components: {TagsSelect, BlogAddAutorModal},

  props: {
    post: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      modal: false,
      selectedAuthor: {}
    }
  },
  computed: {
    token () {
      return process.env.NODE_ENV === 'development'
        ? localStorage.getItem('sanctum_token')
        : Cookie.get('cross_estage_token')
    },
    blogHost (){
      return process.env.VUE_APP_BLOG_HOST
    },
    ...mapState('authors', {
      authors: state => state.authors
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    author: {
      get () {
        return this.post.author_id || ''
      },
      set (val) {
        const author = _.get(this.$refs,'select.options').find(a=>a.id == val)
        author.project_id = _.get(this.post,'author.project_id')
        this.$set(this.post, 'author', author)
        this.$set(this.post, 'author_id', val)
      }
    }
  },
  created () {
    window.addEventListener('message', this.onMessage)
  },
  destroyed () {
    window.removeEventListener('message', this.onMessage)
  },
  methods: {
    onMessage (event) {
      const data = JSON.parse(event.data)

      if (data.name === 'estage-added-author') {
        this.modal = false
        this.author = data.data.data.data.id
        this.$refs.select.refresh()
      }
    },
    authorAction (author = null) {
      this.selectedAuthor = author || {
        name: '',
        avatar: {
          src: null
        },
        email: ''
      }

      this.$root.$emit('bv::show::modal', 'author-modal-settings')
    }
  }
}
</script>

<style scoped>

</style>
