<template>
  <div
    v-if="showRender"
    ref="renderEl"
  />
</template>

<script>
import lottie from 'lottie-web'

export default {
  name: 'LottieRender',
  props: {
    url: {
      default: ''
    }
  },
  data () {
    return {
      showRender: false
    }
  },
  watch: {
    url () {
      this.showRender = false
      if (this.url) {
        setTimeout(() => {
          this.showRender = true
          this.render()
        })
      }
    }
  },
  mounted () {
    if (this.url) {
      this.showRender = true
      this.render()
    }
  },
  methods: {
    render () {
      setTimeout(() => {
        lottie.loadAnimation({
          container: this.$refs.renderEl,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: this.url
        })
      })
    }
  }
}
</script>