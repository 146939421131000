<template>
  <div class="account-settings mb-5">
    <module-header hide-back-to-editor />

    <div class="px-[75px] py-[150px]">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <h2 class="page-title">
          Account Settings
        </h2>
      </div>

      <account-settings-nav />
      <b-card class="rounded-tl-none p-4">
        <router-view />
      </b-card>
    </div>
  </div>
</template>

<script>
import AccountSettingsNav from './AccountSettingsNav'
import ModuleHeader from '../../components/editor/header/ModuleHeader'

export default {
  name: 'Account',

  components: { ModuleHeader, AccountSettingsNav }
}
</script>

<style lang="scss">
.account-settings {
  .page-title {
    font-weight: 600;
    font-size: 21px !important;
  }

  legend {
    font-size: 16px !important;
    color: #000 !important;
    font-weight: normal !important;
  }

  .form-control {
    min-height: 38px;
  }

  .vs--open .vs__selected {
    margin-top: 7px !important;
  }
}
</style>
