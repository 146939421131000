<template>
  <div>
    <b-form-group label="Expire Actions">
      <v-select
        v-model="action"
        :options="allActions"
        class="custom-select"
        placeholder="Select Action"
        @option:selected="selected"
      />
    </b-form-group>

    <b-form-group v-if="value.length">
      <div class="flex flex-wrap gap-[3px] border p-[9px] rounded-[2px]">
        <div
          v-for="(item, index) in value"
          :key="index"
          class="flex items-center bg-[#3D82EA] py-[3px] px-[7px] rounded-[3px] text-[11px] font-[700] text-white"
        >
          <span
            class="mr-[7px]"
            v-text="item.label"
          />

          <button
            class="hover:opacity-80 p-0 m-0"
            @click="() => removeItem(index)"
          >
            <svg
              fill="none"
              height="7"
              viewBox="0 0 6 7"
              width="6"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.640159 0.609834C0.493714 0.463389 0.256279 0.463389 0.109834 0.609834C-0.0366113 0.756279 -0.0366113 0.993714 0.109834 1.14016L2.46968 3.5L0.109834 5.85984C-0.0366111 6.00629 -0.0366113 6.24372 0.109834 6.39017C0.256279 6.53661 0.493714 6.53661 0.640159 6.39017L3 4.03033L5.35978 6.39011C5.50623 6.53656 5.74366 6.53656 5.89011 6.39011C6.03656 6.24366 6.03656 6.00623 5.89011 5.85978L3.53033 3.5L5.89011 1.14022C6.03656 0.99377 6.03656 0.756336 5.89011 0.609891C5.74366 0.463445 5.50623 0.463445 5.35978 0.609891L3 2.96968L0.640159 0.609834Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </b-form-group>

    <b-form-group>
      <div>
        <div
          v-for="(selectedAction, index) in getValue"
          :key="index"
        >
          <div
            :class="{'bg-[#F9F9F9]': active === index}"
            class="border mt-[-1px] d-flex align-items-center justify-content-between cursor-pinter hover:bg-[#F9F9F9] h-[40px] px-[23px] font-[700] text-[#44474A]"
            @click="active === index ? active = null : active = index"
          >
            {{ selectedAction.label }}

            <svg
              :class="{'transform rotate-180': active === index}"
              fill="none"
              height="7"
              viewBox="0 0 10 7"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.719727 1.00006L4.85989 5.10817L9.00005 1.00006"
                stroke="#6E747A"
                stroke-width="1.5"
              />
            </svg>
          </div>

          <div
            v-if="active === index"
            class="pt-[16px]"
          >
            <action-modal
              v-if="selectedAction.value.type === 'modal'"
              :service="selectedAction.value"
              class="px-0"
            />
            <template v-else-if="selectedAction.value.type === 'redirect'">
              <b-form-group label="Page">
                <pages-select-menu
                  :others="[{id: 'custom-link', title: 'Custom Link'}, {title: 'Global Variable Link', id: 'global-variable'}, {id: 'blog', title: 'ESTAGE Blog', code: project.blog_slug}]"
                  input-class="rounded-[4px]"
                  other-title="Other:"
                  @selectOther="item => selectedAction.value.href = item.slug || item.id"
                  @selectPage="item => selectedAction.value.href = item.slug || item.id"
                >
                  <template
                    v-if="selectedAction.value.href"
                    slot="placeholder"
                  >
                    <div class="px-3 text-capitalize">
                      {{ selectedAction.value.href }}
                    </div>
                  </template>
                </pages-select-menu>
              </b-form-group>

              <b-form-group
                v-if="selectedAction.value.href === 'custom-link'"
                label="Custom link"
              >
                <b-input
                  v-model="config.customLink"
                  type="url"
                />
              </b-form-group>


              <b-form-group
                v-else-if="selectedAction.value.href === 'global-variable'"
                label="Global variable link"
              >
                <v-select
                  v-model="selectedAction.value.variable"
                  :options="variables"
                  label="name"
                  :reduce="val => val.key"
                />
              </b-form-group>

              <b-form-group>
                <b-checkbox v-model="selectedAction.value.blank">
                  Open In New Tab
                </b-checkbox>
              </b-form-group>
            </template>
            <b-form-group
              v-else-if="selectedAction.value.type === 'show-element'"
              label="Show Element After"
            >
              <pick-element
                v-model="selectedAction.value.data"
              />
            </b-form-group>
            <b-form-group
              v-else-if="selectedAction.value.type === 'hide-element'"
              label="Hide Element"
            >
              <pick-element
                v-model="selectedAction.value.data"
              />
            </b-form-group>
          </div>
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import PagesSelectMenu from '@/components/editor/components/pagesSelectMenu/PagesSelectMenu'
import PickElement from '@/components/editor/controls/PickElement'
import ActionModal from '@/components/builder/utils/auto-responders/ActionModal'
import {mapState} from 'vuex'
import {toJSON} from '@/utils/helpers'

export default {
  name: 'TimerExpireActions',
  components: {ActionModal, PickElement, PagesSelectMenu},
  props: {
    value: {
      required: true
    },
    config: {
      type: Object
    }
  },
  data () {
    return {
      action: '',
      active: null
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    variables () {
      const obj = toJSON(this.project.variables)
      return !Object.values(obj).length ? [] : obj
    },
    getValue () {
      return this.value.filter(action => action.value.type !== 'hide-timer')
    },
    allActions () {
      return [
        {
          label: 'Modal',
          value: {
            type: 'modal',
            data: {
              modal_id: null
            }
          }
        },
        {
          label: 'Redirect',
          value: {
            type: 'redirect',
            customLink: '',
            href: '',
            variable: '',
            blank: false
          }
        },
        {
          label: 'Show Element',
          value: {
            type: 'show-element',
            data: {
              pickedUID: null
            }
          }
        },
        {
          label: 'Hide Element',
          value: {
            type: 'hide-element',
            data: {
              pickedUID: null
            }
          }
        },
        {
          label: 'Hide Timer',
          value: {
            type: 'hide-timer'
          }
        }
      ].filter(e => {
        return this.value.findIndex(_e => _e.label === e.label) === -1
      })
    }
  },
  methods: {
    selected (val) {
      this.action = ''
      this.$emit('input', [val, ...this.value])
    },
    removeItem (index) {
      this.value.splice(index, 1)
    }
  }
}
</script>