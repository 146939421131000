import router from '@builder/router/index'
import { getStylesBySid, insertSectionStyle, pageStylesToString } from '@/utils/useStylesheet'

export const PUSH_PAGE = 'pushPage'
export const REDIRECT_URL = 'redirectURL'
export const MODULE = 'openModule'

const itemTypes = {
  page: PUSH_PAGE,
  custom_url: REDIRECT_URL,
  custom: REDIRECT_URL,
  'module': MODULE
}

const state = () => ({
  pages: [],
  page: {},
  currentPage: null,
  routeItem: {},
  loading: false
})

const getters = {
  getPages (state) {
    return state.pages
  }
}

const actions = {
  async save ({state, rootState, dispatch, rootGetters}, payload = {clearCache: 0, all: 0, force: false}) {
    const draft = JSON.stringify(rootGetters['sections/getSections'])
    if (state.loading || (!rootGetters['sections/getSections'].length && !payload.force)) return
    state.loading = true
    VEvent.fire('page.save')
    return await dispatch('font/prepareFonts', {}, {root: true})
      .then(async (fonts) => {
        return await axios.put(`api/projects/${rootState.projects.project.id}/pages/${state.page.id}?autoSave=1&all=${payload.all ? 1 : 0}${payload.clearCache ? '&clearCache=1' : ''}`, {
          meta_keywords: state.page.meta_keywords,
          meta_title: state.page.meta_title,
          meta_description: state.page.meta_description,
          slug: state.page.slug,
          title: state.page.title,
          content: state.page.content,
          hideHeader: state.page.hideHeader,
          hideFooter: state.page.hideFooter,
          force_reload: state.page.force_reload,
          password_id: state.page.password_id,
          settings: JSON.stringify(state.page.settings),
          code_injection: JSON.stringify(state.page.code_injection),
          content_draft: draft,
          open_graph_image: state.page.open_graph_image,
          fonts: fonts.googleFonts + fonts.customFonts
        })
          .catch(() => {
            this.$swal({
              icon: 'error',
              title: 'Server error',
              text: 'Please try again later or let us know about your problem.',
              showConfirmButton: false,
              timer: 3500
            })
          })
          .finally(() => {
            state.loading = false
          })
      })
  },
  resetPages ({state}) {
    state.pages = []
  },
  async addPage ({rootState, dispatch}, payload) {
    return await axios.post(`api/projects/${rootState.projects.project.id}/pages`, payload)
      .then(reponse => {
        if (payload.dont_dispatch == undefined) dispatch('loadPages')
        return reponse.data.data
      })
  },
  async editPage ({state, rootState}, payload) {
    if (payload.id === state.page.id) {
      state.page.meta_title = payload.meta_title
    }

    return await axios.put(`api/projects/${rootState.projects.project.id}/pages/${payload.id}`, payload)
      .then((data) => {
        return data.data
      })
  },
  async deletePage ({rootState, dispatch}, payload) {
    return await axios.delete(`api/projects/${rootState.projects.project.id}/pages/${payload.id}`, payload)
      .then(() => {
        dispatch('menu/loadNavbarMenu', null, {root: true})
      })
  },
  async loadHome ({state, rootState}) {
    return await axios.get(`api/site/${rootState.projects.project.subdomain}/home-page?builder=1`)
      .then(({data}) => {
        state.page = {
          ...data.data,
          settings: data.data.settings ? JSON.parse(data.data.settings) : {},
          code_injection: JSON.parse(data.data.code_injection || '{}')
        }

        rootState.sections.sections = JSON.parse(data.data.content_draft)

        router.replace({
          query: {
            page: data.data.id
          }
        }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err
          }
        })
      })
  },
  async loadPage ({state, commit, rootState}, id, refresh = false) {
    commit('promoBar/CLEAR', null, {root: true})
    state.loading = true

    return await axios.get(`api/projects/${rootState.projects.project.id}/pages/${id}`)
      .then(({data}) => {
        const draftContent = data.data.content_draft === 'null' ? [] : JSON.parse(data.data.content_draft)
        state.page = {
          ...data.data,
          content_draft: draftContent,
          settings: data.data.settings ? JSON.parse(data.data.settings) : {},
          code_injection: JSON.parse(data.data.code_injection || '{}')
        }

        if (refresh) {
          commit('sections/SET_SECTIONS', [], {root: true})
        }

        setTimeout(() => {
          commit('sections/SET_SECTIONS', draftContent, {root: true})
          if (rootState.sections.sections.length === 0) {
            commit('editor/SET_PAGE', 'blocks', {root: true})
          }
        })
        state.loading = false

        router.replace({
          query: {
            page: id
          }
        }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err
          }
        })

        return state.page
      })
  },
  async duplicatePage ({dispatch, rootState}, {id, css_hash}) {
    VEvent.fire('loader', true)

    return await axios.post(`api/projects/${rootState.projects.project.id}/pages/${id}/duplicate`)
      .then(({data}) => {
        return dispatch('loadPage', data.data.id, true)
          .finally(() => {
            insertSectionStyle(JSON.parse(JSON.stringify(getStylesBySid([css_hash])).replaceAll(css_hash, data.data.css_hash)))
            VEvent.fire('loader', false)
          })
      })
  },
  async loadPages ({state, rootState}, page = 1) {
    let result = []
    await axios.get(`api/projects/${rootState.projects.project.id}/pages?perPage=50&page=${page}&orderId=${state.page.id ? state.page.id : ''}`)
      .then(({data}) => {
        state.pages = data.data
        result = data.data
      })
    return result
  },
  async backup ({state, rootState, rootGetters}) {
    const draft = JSON.stringify(rootGetters['sections/getSections'])

    await axios.post('/api/backup/pages', {
      original_id: state.page.id,
      slug: state.page.slug,
      title: state.page.title,
      content: state.page.content,
      content_draft: draft,
      settings: JSON.stringify(state.page.settings),
      fonts: state.page.fonts,
      css_hash: state.page.css_hash,
      hash: state.page.hash,
      original_css: pageStylesToString(),
      page_status: state.page.page_status,
      project_id: rootState.projects.project.id
    })
  },
  async loadPagesScrolling ({rootState}, params = {page: 1}) {
    return await axios.get(`api/projects/${rootState.projects.project.id}/pages`, {
      params: {
        perPage: 25,
        ...params
      }
    })
      .then(({data}) => {
        return data.data
      })
  },
  [PUSH_PAGE] ({dispatch}, item) {
    VEvent.fire('loader', true)
    dispatch('loadPage', item.item_object_id)
      .then(() => VEvent.fire('loader', false))
  },
  [REDIRECT_URL] (_, item) {
    if (item.open_new_tab || !item.hasOwnProperty('open_new_tab')) {
      return window.open(item.item_url)
    }
    return window.location = item.item_url
  },
  [MODULE] (_, item) {
    if (item.item_url === 'blog') {
      router.push({name: 'blog.manage-posts'})
    }
    // window.open(rootState.projects.project.domain ? addhttp(rootState.projects.project.domain) : `${process.env.VUE_APP_ESTAGE_SITE_HOST}` + `/${rootState.projects.project.subdomain}/${item.item_url}`)
    // window.open(`http://localhost:8081/${rootState.projects.project.subdomain}/${item.item_url}`)
  },
  goToPage ({state, dispatch}, payload) {
    const action = itemTypes[payload.item_type]
    if (action !== MODULE) {
      state.routeItem = payload
    }
    dispatch(action, payload)
  }
}

const mutations = {}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
