<template>
  <div>
    <div
      v-if="!hasEmailField"
      class="custom-aler-danger d-flex align-items-center"
    >
      <i class="fa fa-exclamation-triangle mr-3" />
      <div>
        The form does not contain the required email field
      </div>
    </div>

    <b-form-group label="Auto Responder">
      <v-select
        v-model="buttonResponder"
        class="custom-select"
        label="name"
        :options="getResponders()"
      />
    </b-form-group>

    <b-form-group
      v-if="buttonResponder.name === 'ContactForm'"
      label="Recipient Email Address"
    >
      <b-input v-model="recipientEmailAddress" />
    </b-form-group>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'LinkButtonResponder',
  props: {
    button: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('apiIntegration', {
      hasAPI: 'hasMailAPIService'
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('apiIntegration', {
      contactForm: state => state.contactForm
    }),
    ...mapState('forms', {
      allFormControls: state => state.allFormControls
    }),
    recipientEmailAddress: {
      get () {
        if (!this.button.hasOwnProperty('recipientEmailAddress')) {
          this.$set(this.button, 'recipientEmailAddress', JSON.parse(JSON.stringify(this.user)).email)
        }

        return this.button.recipientEmailAddress
      },
      set (val) {
        this.button.recipientEmailAddress = val
      }
    },
    buttonResponder: {
      get () {
        return this.button.autoresponder || this.contactForm
      },
      set (val) {
        this.$set(this.button, 'autoresponder', val)

      }
    },
    relatedControls () {
      return this.allFormControls.filter(control => control.section.options.content.input.relatedUID === this.button.buttonUID)
    },
    hasEmailField () {
      return this.relatedControls.filter(control => control.v.$params.email).length
    }
  },
  watch: {
    buttonResponder (val) {
      if (val.id) {
        const ids = this.relatedControls.map(control => {
          const key = Object.keys(control.section.options.content)[0]
          return control.section.options.content[key].id
        })

        this.$store.dispatch('apiIntegration/massUpdate', ids)
          .then(() => {
            this.$store.dispatch('apiIntegration/sync', ids)
          })
      }
    }
  },
  methods: {
    getResponders () {
      const reposponders = [this.contactForm]

      this.hasAPI ? reposponders.push({
        id: this.hasAPI.id,
        name: this.hasAPI.name
      }) : null

      return reposponders
    }
  }
}
</script>
