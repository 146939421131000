<template>
  <highliter
    class="d-flex justify-center w-100 post-middle"
    value="Content Wrapper"
    hide-add
    hide-move
    hide-right
    @click.stop.native="() => $store.dispatch('editor/sectionSettings', {
      section: {
        options: {
          content: {}
        }
      },
      openedItem: 'blog-wrapper-mod'
    })"
  >
    <div class="block-bg post-middle-bg" />
    <slot :flow="flow" />
  </highliter>
</template>

<script>
import Highliter from '@/components/builder/utils/Highliter.vue'

export default {
  name: 'BlogContentWrapper',
  components: {Highliter},
  methods: {
    flow () {
      this.$store.commit('editor/ADD_ELEMENT_IN_STACK', {
        name: 'Content Wrapper',
        $el: this.$el,
        uid: 'blog-wrapper'
      })
    }
  }
}
</script>

<style>
.post-middle {
    max-width: 1600px;
    --width: 1600px;

    & > .h-fit {
        position: static !important;

        .highlight-new-bar {
            top: 0;
        }
    }
}
</style>