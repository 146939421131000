import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Empty Nav'
export const group = 'navbar'


export const defaultOptions = {
  'customize': {
    'style': {'navbarScroller': {'type': 'navbar-scroller-customs', 'scrollType': null}},
    'display': true
  },
  'content': {
    'logo': {
      'type': 'logo-mod',
      'display': true,
      'fontFamily': null,
      'fontWeight': 'bold',
      'fontSize': '24px',
      'color': '#0C226C',
      'text': 'AppStarter'
    }, 'menuItems': {'type': 'menu-items-mod-v2', 'display': true}, 'subject': {
      'uid': 'WKtPEOYr2',
      'sid': '--global--IHhEpaz8HA',
      'name': 'Flex Wrapper',
      'hideFromSections': false,
      'component': 'ElFlexWrapperV2',
      'group': 'navbar',
      'layout': null,
      'specialCols': {
        'navigation': {
          'special': true,
          'sid': 'KwmIM6xiuC',
          'placement': 'bottom',
          'hamburgerIcon_open': 'fa fa-bars',
          'hamburgerIcon_close': 'fa fa-times',
          'hamburgerIcon_color': '#656565',
          'showOn': 'lg',
          'name': 'Nav Column',
          'elements': [],
          'highlight': false,
          'animations': {'animation': null, 'once': true, 'offset': 200, 'delay': 50},
          'sortIndex': {'lg': 1, 'md': 1, 'sm': 1},
          'uid': 'PaScdwwQ9',
          'navbarBackground': {'background': '', 'gradient': {'position': '', 'color': ''}}
        }
      },
      'options': {
        'content': {
          'display': true,
          'cols': [{
            'display': true,
            'name': 'Flex Column',
            'elements': [],
            'animations': {'animation': null, 'once': true, 'offset': 200, 'delay': 50},
            'sortIndex': {'lg': 0, 'md': 0, 'sm': 0},
            'sid': 'KU9GZVu-9',
            'uid': 'LJkq3dxtuU',
            'highlight': false
          }, {
            'special': true,
            'key': 'navigation',
            'mod': 'navigation-flex-col-mod-v2',
            'name': 'Nav Column',
            'sid': 'Uz96GLEcGy',
            'sortIndex': {'lg': 1, 'md': 1, 'sm': 1}
          }]
        }
      },
      'temp_id': 'd3G2s-XNq'
    }
  }
}

export const previews = {
  left: require('@builder/assets/svg/previews/price/left-1.svg'),
  right: null,
  main: require('@builder/assets/svg/previews/navbar/main-1.svg')
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('NavbarV3', options)

    this.name = name
    this.group = group
    this.css = [['.----global--IHhEpaz8HA--container { padding-right: 0px; }', '.--KU9GZVu-9--flex-col-padding { padding: 0px; }', '.--KwmIM6xiuC--flex-col-padding { padding: 0px; }', '.--KU9GZVu-9--flex-col-el { width: 20%; background-color: rgba(255, 255, 255, 0); }', '.--KwmIM6xiuC--flex-col-el { width: 100%; }'], [], []],
    this.sids = ['--global--1S0bCfMneN', '--global--IHhEpaz8HA', 'KU9GZVu-9', 'KwmIM6xiuC']
    this.sid = ['global']
    this.defaultOptions = defaultOptions
  }
}
