import _ from 'lodash'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  mixins: [ResolutionMixin],
  methods: {
    paddingSubject (subject) {
      if (!subject.padding || !subject.padding[this.resolutioner]) return {}

      return {
        paddingTop: subject.padding[this.resolutioner].top + 'px',
        paddingBottom: subject.padding[this.resolutioner].bottom + 'px',
        paddingLeft: subject.padding[this.resolutioner].x + 'px',
        paddingRight: subject.padding[this.resolutioner].x + 'px'
      }
    },
    fullPaddingSubject (subject, key = 'padding', defaultValue = [0, 0, 0, 0]) {
      return {
        paddingTop: _.get(subject, [key, this.resolutioner, 'top'], defaultValue[0]) + 'px',
        paddingBottom: _.get(subject, [key, this.resolutioner, 'bottom'], defaultValue[1]) + 'px',
        paddingLeft: _.get(subject, [key, this.resolutioner, 'left'], defaultValue[2]) + 'px',
        paddingRight: _.get(subject, [key, this.resolutioner, 'right'], defaultValue[3]) + 'px'
      }
    },
    fullMarginSubject (subject, key = 'margin') {
      if (!subject[key] || !subject[key][this.resolutioner]) return {}

      return {
        marginTop: subject[key][this.resolutioner].top + 'px',
        marginBottom: subject[key][this.resolutioner].bottom + 'px',
        marginLeft: subject[key][this.resolutioner].left + 'px',
        marginRight: subject[key][this.resolutioner].right + 'px'
      }
    },
    marginSubject (subject) {
      if (!subject.margin || !subject.margin[this.resolutioner]) return {}

      return {
        marginTop: subject.margin[this.resolutioner].top + 'px',
        marginBottom: subject.margin[this.resolutioner].bottom + 'px'
      }
    },
    shadowSubject (subject) {
      const shadow = subject.shadowOptions
      const color = subject.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    borderSubject (subject, defaultValue = [1, 1, 1, 1], defaultColor = '#fff') {
      if (!subject) return {}
      const border = _.get(subject.border, this.resolutioner, subject.border) || defaultValue
      return {
        borderColor: subject.borderColor || defaultColor,
        borderStyle: 'solid',
        borderTopWidth: `${border[0]}px`,
        borderRightWidth: `${border[1]}px`,
        borderBottomWidth: `${border[2]}px`,
        borderLeftWidth: `${border[3]}px`
      }
    },
    radiusSubject (subject, defaultRadius = [5, 5, 5, 5]) {
      if (!subject) return {}
      const radius = _.get(subject.radius, this.resolutioner, subject.radius) || defaultRadius
      return {
        borderTopLeftRadius: `${radius[0]}px`,
        borderTopRightRadius: `${radius[1]}px`,
        borderBottomLeftRadius: `${radius[2]}px`,
        borderBottomRightRadius: `${radius[3]}px`
      }
    },
    imageRadiusSubject (subject, defaultRadius = [5, 5, 5, 5]) {
      const radius = _.get(subject.radius, this.resolutioner, subject.radius) || defaultRadius
      const border = _.get(subject.border, this.resolutioner, subject.border) || [1,1,1,1]

      const getAngle = (n1, n2) => {
        return Math.max(n1 || 0, n2 || 0)
      }

      return {
        borderTopLeftRadius: `calc(${radius[0]}px - ${getAngle(border[0], border[3])}px)`,
        borderTopRightRadius: `calc(${radius[1]}px - ${getAngle(border[0], border[1])}px)`,
        borderBottomLeftRadius: `calc(${radius[2]}px - ${getAngle(border[2], border[3])}px)`,
        borderBottomRightRadius: `calc(${radius[3]}px - ${getAngle(border[2], border[1])}px)`
      }
    }
  }
}