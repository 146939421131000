<template>
  <div class="sid-select">
    <b-form-group
      label="Hover Effect"
      class="mb-0"
    >
      <v-select
        v-model="valueMutated"
        :calculate-position="withPopper"
        :options="animationOptions"
        :reduce="option => option.key"
        :searchable="false"
        append-to-body
        class="custom-select"
        placeholder="Select Animation"
        left
        top
      />
    </b-form-group>
  </div>
</template>

<script>
import popperMixin from '../mixins/popperMixin'

export default {
  name: 'HoverAnimations',

  mixins: [popperMixin],

  props: {
    value: {
      required: true
    }
  },

  data () {
    return {
      animationOptions: [
        {
          label: 'Select Animation',
          key: null
        },
        {label: 'Grow', key: 'hover-grow'},
        {label: 'Sink', key: 'hover-sink'},
        {label: 'Forward', key: 'hover-slide'},
        {label: 'Backward', key: 'hover-slide-back'},
        {label: 'Skew', key: 'hover-skew'},
        {label: 'Skew Backward', key: 'hover-skew-back'},
        {label: 'Bob', key: 'hover-bob'},
        {label: 'Float', key: 'hover-float'},
        {label: 'Shrink', key: 'hover-shrink'},
        {label: 'Bounce In', key: 'hover-bounce-in'},
        {label: 'Bounce Out', key: 'hover-bounce-out'},
        {label: 'Rotate', key: 'hover-rotate'},
        {label: 'Rotate Grow', key: 'hover-rotate-grow'},
        {label: 'Pulse', key: 'hover-pulse'},
        {label: 'Push', key: 'hover-push'},
        {label: 'Pop', key: 'hover-pop'},
        {label: 'Buzz', key: 'hover-buzz'},
        {label: 'Buzz End', key: 'hover-buzz-end'},
        {label: 'Wobble horizontal', key: 'hover-jelly-horizontal'},
        {label: 'Wobble vertical', key: 'hover-jelly-vertical'}
      ]
    }
  },

  computed: {
    valueMutated: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
