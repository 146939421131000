<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="onDeleted"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('comments')"
  >
    <div :data-sid="section.sid">
      <div
        class="comments-container"
        :class="`--${section.sid}`"
      >
        <div class="comment-composer">
          <div class="guest-details">
            <input
              type="text"
              placeholder="Your Name"
              class="comment-field"
            >
            <input
              type="text"
              placeholder="Email Address"
              class="comment-field"
            >
          </div>

          <textarea
            placeholder="Add Comment..."
            class="comment-field"
          />

          <button v-text="section.options.content.comments.btnText" />
        </div>

        <div>
          <div class="total-comments">
            1000 Comments
          </div>

          <div class="comments-box">
            <div
              v-for="n in 10"
              :key="n"
              class="comment"
            >
              <svg
                class="comment-avatar"
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="23"
                  cy="23"
                  r="23"
                  fill="#F4F6F9"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M28.9995 18C28.9995 21.3137 26.3132 24 22.9995 24C19.6858 24 16.9995 21.3137 16.9995 18C16.9995 14.6863 19.6858 12 22.9995 12C26.3132 12 28.9995 14.6863 28.9995 18ZM26.9995 18C26.9995 20.2091 25.2087 22 22.9995 22C20.7904 22 18.9995 20.2091 18.9995 18C18.9995 15.7909 20.7904 14 22.9995 14C25.2087 14 26.9995 15.7909 26.9995 18Z"
                  fill="#C9D0DF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.9996 25C20.0645 25 17.9482 25.6088 16.4302 26.5217C14.906 27.4383 14.05 28.6224 13.5772 29.6581C13.1788 30.5311 13.3601 31.4207 13.8896 32.0546C14.3942 32.6587 15.1789 33 15.9996 33H29.9995C30.8202 33 31.6048 32.6588 32.1095 32.0546C32.6389 31.4208 32.8203 30.5311 32.4219 29.6582C31.9491 28.6225 31.0931 27.4383 29.569 26.5218C28.051 25.6088 25.9347 25 22.9996 25ZM15.3967 30.4886C15.7248 29.7697 16.3276 28.9172 17.4609 28.2357C18.6002 27.5506 20.3398 27 22.9996 27C25.6594 27 27.399 27.5506 28.5383 28.2357C29.6715 28.9172 30.2743 29.7697 30.6024 30.4886C30.6412 30.5735 30.6395 30.6245 30.6356 30.6506C30.6311 30.6803 30.6165 30.7222 30.5746 30.7724C30.4844 30.8804 30.2834 31 29.9995 31H15.9996C15.7156 31 15.5147 30.8804 15.4245 30.7724C15.3825 30.7222 15.3679 30.6803 15.3635 30.6506C15.3596 30.6245 15.3579 30.5735 15.3967 30.4886Z"
                  fill="#C9D0DF"
                />
              </svg>

              <div class="w-full">
                <div class="comment-text">
                  <div class="d-flex justify-content-between mb-2">
                    <span
                      class="comment-user-name"
                    >
                      {{ mock.user.name }} {{ mock.user.second_name }}
                    </span>
                    <span
                      class="comment-date ml-auto"
                      v-text="mock.date"
                    />
                  </div>

                  <p
                    class="mb-0"
                    v-text="mock.text"
                  />
                </div>

                <div
                  v-show="section.options.content.comments.reply"
                  class="text-left mt-2"
                >
                  <a
                    href="javascript:;"
                    class="comment-reply-button text-decoration-none"
                  >Reply</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'

export default {
  name: 'FeaturedItems1',

  components: {Highliter},

  mixins: [SectionMixin],

  data () {
    return {
      mock: {
        user: {
          name: 'John',
          second_name: 'Doe'
        },
        text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. At beatae eum exercitationem, hic iure voluptatibus? Animi doloremque et iste mollitia.',
        date: 'Jun 2, 2023'
      }
    }
  },

  mounted () {
    if (!this.section.options.content.comments.comments_container_id) this.createContainer()
  },

  methods: {
    onDeleted () {
      axios.delete(`api/comment-block/${this.section.options.content.comments.comments_container_id}`)
      this.$emit('delete')
    },
    createContainer () {
      axios.post('api/comment-block')
        .then(({data}) => {
          this.section.options.content.comments.comments_container_id = data.data.id
        })
    }
  }
}
</script>

<style lang="scss">
.comments-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  padding: 20px;

  .total-comments {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .comments-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 500px;
    overflow-y: auto;
    padding: 0 20px 0 0;
    --height: 500px;

    .comment {
      display: flex;
      gap: 20px;

      &-text {
        width: 100%;
        background: #F4F6F9;
        padding: 18px 28px;
        border-radius: 4px;
      }
    }
  }

  .guest-details {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  input {
    width: 300px;
    height: 43px;
    padding: 0 20px;
    background: #FFFFFF;
    border: 1px solid #D3DAE0;
    border-radius: 3px;
  }

  textarea {
    padding: 20px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #D3DAE0;
    border-radius: 3px;
    margin-bottom: 20px;
  }

  .comment-reply-button {
    cursor: pointer;
    font-size: 14px;
    color: #1a1e21;
    margin-top: 10px;

    &:hover {
      color: #3D82EA;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 38px;
    padding: 0 40px;
    background-color: #fff;
    border: 1px solid #DAE2ED;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
  }
}
</style>
