<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element"
    :dublicate-deep="parentUID"
    :dublicate="section"
    :hide-right="true"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="() => null"
  >
    <b-form-group
      class="estage-form-element-group mb-0"
      :class="`--${sid}--form-field-payment-method`"
    >
      <template
        v-if="control.label"
        #label
        class="flex items-center gap-x-[4px]"
        :class="`--${sid}--form-field-label`"
      >
        Payment Method
        <span
          class="text-danger"
        >
          *
        </span>
      </template>

      <div class="w-full inline-flex items-center gap-[15px]">
        <v-select
          v-model="control.value"
          :options="paymentMethods"
          label="label"
          required
          class="form-control input"
          :class="`--${sid}--form-field-input`"
        />
        <div class="inline-flex flex-shrink-0 items-center gap-[4px]">
          <img src="@/assets/icons/payment-systems/visa.svg">
          <img src="@/assets/icons/payment-systems/diners-club.svg">
          <img src="@/assets/icons/payment-systems/amex.svg">
          <img src="@/assets/icons/payment-systems/discover.svg">
          <img src="@/assets/icons/payment-systems/master-card.svg">
        </div>
      </div>
    </b-form-group>
  </highliter>
</template>

<script>
import _ from 'lodash'
import BorderMixin from '@/components/mixins/BorderMixin'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElFieldPaymentMethod',
  mixins: [BorderMixin, SectionMixin],
  components: {Highliter},
  data () {
    return {
      card: null,
      cards: []
    }
  },
  computed: {
    control () {
      return this.section.options.content.control
    },
    paymentMethods () {
      return [
        {
          label: 'Add New Card',
          value: 'new-method'
        },
        ..._.uniqBy(this.cards, (transaction) => transaction.card.last4)
          .map(card => {
            return {
              value: card.id,
              brand: card.card.brand,
              label: `Card ending in .. ${card.card.last4}`
            }
          })
      ]
    }
  },
  methods: {
    onCardSelect (card) {
      this.card = card
      this.$emit('change', card)
    }
  }
}
</script>