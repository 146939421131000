const state = () => ({
  blog: []
})

const getters = {}

const actions = {
  async getPosts ({state, rootState}) {
    await axios.get(`api/projects/${rootState.projects.project.id}/blog`)
      .then(({data}) => {
        state.blog = data
      })
  },
  async updateComment ({rootState}, payload) {
    return await axios.put(`api/projects/${rootState.projects.project.id}/blog/manage/${payload.blog_id}/comments/${payload.id}`, payload)
  },
  async updateComments ({rootState}, payload) {
    return await axios.put(`api/projects/${rootState.projects.project.id}/blog/manage/${payload.blog_id}/comments/mass-actions`, payload)
  },
  async deleteComment ({rootState}, payload) {
    return await axios.delete(`api/projects/${rootState.projects.project.id}/blog/manage/${payload.blog_id}/comments/${payload.id}`)
  },
  async getPost ({rootState}, id) {
    return await axios.get(`api/projects/${rootState.projects.project.id}/blog/manage/${id}`)
  }
}

const mutations = {
  ADD_POST (state, payload) {
    if (payload.status !== 'published') return false
    state.blog.data.unshift(payload.data)
  }
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
