<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="el-dynamic"
    section-type="element"
    v-bind="highliterOptions"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('post')"
  >
    <div
      class="flex w-100 post-grid"
      :data-sid="section.sid"
      :class="className"
    >
      <div
        v-for="(post, index) in perPage"
        :key="index"
        class="post-grid-card"
      >
        <draggable
          v-model="section.options.content.post.subjects"
          handle=".move"
          :data-sid="section.sid"
          :class="className"
          ghost-class="elements-sortable-ghost"
          :scroll="true"
          :force-fallback="true"
          fallback-class="elements-sortable-ghost"
          :scroll-sensitivity="60"
          :force-autoscroll-fallback="true"
          :bubble-scroll="true"
          :fallback-on-body="true"
          :group="{ name: 'grid-post-card', pull: false, put: false }"
        >
          <component
            :is="component.component"
            v-for="(component, index) in subjects"
            :key="`subject-${index}`"
            :settings="settings"
            @click.stop.native="sectionSettings(component.mod)"
          />
        </draggable>
      </div>
    </div>
  </highliter>
</template>

<script>
import draggable from 'vuedraggable'
import Highliter from '@/components/builder/utils/Highliter.vue'
import SectionMixin from '@/components/mixins/SectionMixin'
import PostTitle from '@/components/builder/elements/blog/post-grid/PostTitle.vue'
import PostText from '@/components/builder/elements/blog/post-grid/PostText.vue'
import PostCategories from '@/components/builder/elements/blog/post-grid/PostCategories.vue'
import PostButton from '@/components/builder/elements/blog/post-grid/PostButton.vue'
import PostThumb from '@/components/builder/elements/blog/post-grid/PostThumb.vue'
import PostInfo from '@/components/builder/elements/blog/post-grid/PostInfo.vue'

export default {
  name: 'ElPostGrid',
  components: {PostInfo, PostThumb, PostButton, PostText, PostTitle, PostCategories, Highliter, draggable},
  mixins: [SectionMixin],

  computed: {
    className () {
      return `post-grid-${this.section.sid}`
    },
    settings () {
      return this.section.options.content.post.settings
    },
    perPage () {
      return this.settings.postsPerPage
    },
    subjects () {
      return this.section.options.content.post.subjects.filter(sub => this.settings[sub.component].display)
    }
  },

  mounted () {
    /* Insert PostCategories for existing users since this was not initially included in the JSON data */
    const subjects = this.section.options.content.post.subjects
    const found = subjects.find(sub => sub.component === 'PostCategories')
    if(!found){
      this.section.options.content.post.subjects.push({
        mod: 'post-category-mod',
        component: 'PostCategories'
      })
    }
  }
}
</script>

<style lang="scss">
.post-grid {
    gap: 20px;
    flex-wrap: wrap;
}

.post-grid-card {
    width: 31%;
    --width: 31%;
    min-width: 200px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    background-color: #fff;
    transition: .3s;

    &:hover {
      box-shadow: var(--shadow-hover, inherit);
    }
}
</style>