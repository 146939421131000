<template>
  <div class="sid-select">
    <draggable
      :list="products"
      handle=".move"
    >
      <div
        v-for="(product, index) in products"
        :key="index"
        class="border-b mt-[-1px] gap-y-[12px]"
      >
        <div class="group rounded-[2px] h-[38px] flex items-center pl-[16px] hover:bg-[#F1F5FD] cursor-pinter">
          <svg
            class="move cursor-move mr-[14px]"
            fill="none"
            height="10"
            viewBox="0 0 10 10"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.57745 0H0.568359V3H3.57745V0Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 0H7.58936V3H10.5984V0Z"
              fill="#3D82EA"
            />
            <path
              d="M3.57745 7H0.568359V10H3.57745V7Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 7H7.58936V10H10.5984V7Z"
              fill="#3D82EA"
            />
          </svg>

          <span class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]">
            {{ product.name }}
          </span>
        </div>
      </div>
    </draggable>

    <SidbearSettingsGroup
      title="Plan Title"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <b-form-group label="Plan Text">
        <b-form-input v-model="element.options.content.productOptions.plan.text" />
      </b-form-group>

      <sid-font :sid="`.--${element.sid}--header-name`" />

      <sid-range-slider
        label="Font Size"
        :sid="`.--${element.sid}--header-name`"
        default-dimension="px"
        :px-range="[0, 300]"
        rule-name="font-size"
      />

      <sid-range-slider
        label="Letter-Spacing"
        :sid="`.--${element.sid}--header-name`"
        rule-name="letter-spacing"
        :min="-2"
        :dimensions="[]"
      />

      <sid-range-slider
        label="Line-Height"
        :sid="`.--${element.sid}--header-name`"
        rule-name="line-height"
        :default-dimension="''"
        :min="0"
        :max="5"
        :step=".1"
        var="--line-height"
        :dimensions="[]"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            :sid="`.--${element.sid}--header-name`"
            rule-name="color"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Price Title"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <b-form-group label="Price Text">
        <b-form-input v-model="element.options.content.productOptions.price.text" />
      </b-form-group>

      <sid-font :sid="`.--${element.sid}--product-name`" />

      <sid-range-slider
        label="Font Size"
        :sid="`.--${element.sid}--product-name`"
        default-dimension="px"
        :px-range="[0, 300]"
        rule-name="font-size"
      />

      <sid-range-slider
        label="Letter-Spacing"
        :sid="`.--${element.sid}--product-name`"
        rule-name="letter-spacing"
        :min="-2"
        :dimensions="[]"
      />

      <sid-range-slider
        label="Line-Height"
        :sid="`.--${element.sid}--product-name`"
        rule-name="line-height"
        :default-dimension="''"
        :min="0"
        :max="5"
        :step=".1"
        var="--line-height"
        :dimensions="[]"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            :sid="`.--${element.sid}--product-name`"
            rule-name="color"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Product"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${element.sid}--product`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[
              {
                label: 'Solid',
                value: 'solid'
              },
              {
                label: 'Dashed',
                value: 'dashed'
              },
              {
                label: 'Dotted',
                value: 'dotted'
              },
              {
                label: 'Double',
                value: 'double'
              },
              {
                label: 'Outset',
                value: 'outset'
              }
            ]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${element.sid}--product`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${element.sid}--product`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>
      
      <hr>
      
      <sid-linked-group
        label="Padding"
        :sid="`.--${element.sid}--product`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${element.sid}--product`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup
      title="Product Title"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <sid-font :sid="`.--${element.sid}--product-name`" />

      <sid-range-slider
        label="Font Size"
        :sid="`.--${element.sid}--product-name`"
        default-dimension="px"
        :px-range="[0, 300]"
        rule-name="font-size"
      />

      <sid-range-slider
        label="Letter-Spacing"
        :sid="`.--${element.sid}--product-name`"
        rule-name="letter-spacing"
        :min="-2"
        :dimensions="[]"
      />

      <sid-range-slider
        label="Line-Height"
        :sid="`.--${element.sid}--product-name`"
        rule-name="line-height"
        :default-dimension="''"
        :min="0"
        :max="5"
        :step=".1"
        var="--line-height"
        :dimensions="[]"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            :sid="`.--${element.sid}--product-name`"
            rule-name="color"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Variant Name"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <rule-tabs :tabs="['Normal', 'Active', 'Hover']">
        <template #Normal>
          <sid-font :sid="`.--${element.sid}--variant-name`" />

          <sid-range-slider
            label="Font Size"
            :sid="`.--${element.sid}--variant-name`"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />

          <sid-range-slider
            label="Letter-Spacing"
            :sid="`.--${element.sid}--variant-name`"
            rule-name="letter-spacing"
            :min="-2"
            :dimensions="[]"
          />

          <sid-range-slider
            label="Line-Height"
            :sid="`.--${element.sid}--variant-name`"
            rule-name="line-height"
            :default-dimension="''"
            :min="0"
            :max="5"
            :step=".1"
            var="--line-height"
            :dimensions="[]"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--variant-name`"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
        <template #Active>
          <sid-font :sid="`.--${element.sid}--variant-name.active`" />

          <sid-range-slider
            label="Font Size"
            :sid="`.--${element.sid}--variant-name.active`"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--variant-name.active`"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
        <template #Hover>
          <sid-font
            :sid="`.--${element.sid}--variant-name`"
            presudo=":hover"
          />

          <sid-range-slider
            label="Font Size"
            :sid="`.--${element.sid}--variant-name.active`"
            presudo=":hover"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--variant-name.active`"
                presudo=":hover"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Variant Price"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <rule-tabs :tabs="['Normal', 'Active', 'Hover']">
        <template #Normal>
          <sid-font :sid="`.--${element.sid}--variant-price`" />

          <sid-range-slider
            label="Font Size"
            :sid="`.--${element.sid}--variant-price`"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />

          <sid-range-slider
            label="Letter-Spacing"
            :sid="`.--${element.sid}--variant-price`"
            rule-name="letter-spacing"
            :min="-2"
            :dimensions="[]"
          />

          <sid-range-slider
            label="Line-Height"
            :sid="`.--${element.sid}--variant-price`"
            rule-name="line-height"
            :default-dimension="''"
            :min="0"
            :max="5"
            :step=".1"
            var="--line-height"
            :dimensions="[]"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--variant-price`"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
        <template #Active>
          <sid-font :sid="`.--${element.sid}--variant-price.active`" />

          <sid-range-slider
            label="Font Size"
            :sid="`.--${element.sid}--variant-price.active`"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--variant-price.active`"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
        <template #Hover>
          <sid-font
            :sid="`.--${element.sid}--variant-price`"
            presudo=":hover"
          />

          <sid-range-slider
            label="Font Size"
            :sid="`.--${element.sid}--variant-price.active`"
            presudo=":hover"
            default-dimension="px"
            :px-range="[0, 300]"
            rule-name="font-size"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--variant-price.active`"
                presudo=":hover"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup
      title="Padding, Margins"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${element.sid}--wrapper`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${element.sid}--wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import draggable from 'vuedraggable'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'

export default {
  name: 'ProductVariantSettings',
  components: {
    draggable,
    SidSelect,
    RuleTabs,
    SidRangeSlider,
    SidColor,
    SidFont,
    SidLinkedGroup,
    SidbearSettingsGroup
  },
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('products', {
      products: state => state.selected
    })
  }
}
</script>