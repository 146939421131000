import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Carousel'
export const group = 'Advanced'

const slide = {
  options: {},
  content: []
}

export const defaultOptions = {
  content: {
    carousel: {
      type: 'carousel-mod-v3',
      display: true,
      autoplay: false,
      pauseOnHover: false,
      speed: 2000,
      prevIcon: 'fa fa-arrow-circle-left',
      nextIcon: 'fa fa-arrow-circle-right',
      slidesPerView: {
        lg: 1,
        md: 1,
        sm: 1
      },
      centered: {
        lg: false,
        md: false,
        sm: false
      },
      direction: {
        lg: 'horizontal',
        md: 'horizontal',
        sm: 'horizontal'
      },
      autoplayDelay: 3000,
      autoplayPauseOnMouseEnter: false,
      gap: {
        lg: 0,
        md: 0,
        sm: 0
      },
      loop: {
        lg: false,
        md: false,
        sm: false
      },
      slides: [{uid: 1, ...slide}, {uid: 2, ...slide}, {uid: 3, ...slide}, {uid: 4, ...slide}]
    }
  }
}

export const icon = `<svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.7832 0H16.2168C17.7515 0 19 1.26086 19 2.81065V11.8047C19 13.3545 17.7515 14.6154 16.2168 14.6154H2.7832C1.24854 14.6154 0 13.3545 0 11.8047V2.81065C0 1.26086 1.24854 0 2.7832 0ZM16.2168 13.4911C17.1376 13.4911 17.8867 12.7346 17.8867 11.8047V2.81066C17.8867 1.88078 17.1376 1.12427 16.2168 1.12427H2.7832C1.86241 1.12427 1.11328 1.88078 1.11328 2.81066V11.8047C1.11328 12.7346 1.86241 13.4911 2.7832 13.4911H16.2168Z" fill="#333333"/>
<rect x="3" y="4" width="2" height="7" fill="#333333"/>
<rect x="8" y="4" width="3" height="7" fill="#333333"/>
<rect x="14" y="4" width="2" height="7" fill="#333333"/>
</svg>
`
export default class ElCarousel extends SectionProvider {
  constructor (options = {}) {
    super('ElCarouselV3', options)

    this.name = name
    this.group = group
    this.css = [[], ['.--l6cch77b--77yZGl4gXV--slider > .slide-container { min-width: 100%; --width:100%; }'], ['.--l6cch77b--77yZGl4gXV--slider > .slide-container { min-width: 100%; --width:100%; }']]
    this.sid = 'l6cch77b--77yZGl4gXV'
    this.sids = ['l6cch77b--77yZGl4gXV', 'l6cch77b--77yZGl4gXV--1', 'l6cch77b--77yZGl4gXV--2', 'l6cch77b--77yZGl4gXV--3', 'l6cch77b--77yZGl4gXV--4']
    this.defaultOptions = defaultOptions
  }
}
