import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState('blog', {
      categories: state => state.categories,
      category: state => state.category,
      latests: state => state.latest,
      mostPopular: state => state.mostPopular
    }),
    ...mapState('blogSettings', {
      settings: state => state.settings
    }),
    categoryModel: {
      get () {
        return this.category
      },
      set (val) {
        this.$store.commit('blog/SET_CATEGORY', val)
        this.search()
      }
    }
  },
  methods: {
    search () {
      this.$store.dispatch('blog/getPosts')
    }
  }
}
