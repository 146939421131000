<template>
  <div
    v-show="lottie.jsonPath"
    class="button-lottie-icon"
  >
    <div
      v-if="showRender"
      ref="renderEl"
    />
  </div>
</template>

<script>
import lottie from 'lottie-web'

export default {
  name: 'ButtonLottieIcon',
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showRender: false
    }
  },
  computed: {
    lottie () {
      return this.config.lottie
    }
  },
  watch: {
    lottie: {
      deep: true,
      handler: function (val) {
        this.showRender = false
        if (val.jsonPath) {
          setTimeout(() => {
            this.showRender = true
            this.render()
          })
        }
      }
    }
  },
  mounted () {
    if (this.lottie.jsonPath) {
      this.showRender = true
      this.render()
    }
  },
  methods: {
    render () {
      setTimeout(() => {
        lottie.loadAnimation({
          container: this.$refs.renderEl,
          renderer: this.lottie.renderer,
          loop: this.lottie.loop,
          autoplay: this.lottie.autoplay,
          path: this.lottie.jsonPath
        })
      })
    }
  }
}
</script>

<style>
.button-lottie-icon {
  width: 40%;
}
</style>