<template>
  <b-form @submit.prevent="submit">
    <b-form-group>
      <template slot="label">
        To <span class="text-danger">*</span>
      </template>
      <b-input
        v-model="service.data.to"
        v-validate="'required|email'"
        :state="errors.has('email') ? false : null"
        name="email"
      />
    </b-form-group>

    <b-form-group>
      <template slot="label">
        Subject <span class="text-danger">*</span>
      </template>
      <b-input
        v-model="service.data.subject"
        v-validate="'required'"
        :state="errors.has('subject') ? false : null"
        name="subject"
      />
    </b-form-group>

    <b-form-group label="Cc">
      <b-input
        v-model="service.data.cc"
        :class="{'border-danger': !validateEmail && service.data.cc}"
      />
      <div
        v-if="!validateEmail && service.data.cc"
        class="text-danger mt-1"
      >
        Enter a valid email address
      </div>
    </b-form-group>
  </b-form>
</template>

<script>
export default {
  name: 'EmailToAdmin',
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  computed: {
    validateEmail () {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.service.data.cc)
    }
  },
  created () {
    this.$store.commit('validation/SET_VALIDATOR', {
      validator: this.$validator
    })
  }
}
</script>
