<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">
        {{ labelCase(componentName) }}
      </p>

      <b-checkbox
        v-model="config.display"
        switch
        size="lg"
      />
    </div>

    <label class="mb-2">Price</label>
    <b-input v-model="config.text" />

    <label class="mb-2">Font</label>
    <div class="menubar">
      <b-row no-gutters>
        <b-col
          cols="6"
          class="mr-2"
        >
          <b-form-group>
            <v-select
              v-model="config.fontFamily"
              left
              class="custom-select"
              :options="fonts"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="3"
          class="mr-2"
        >
          <b-form-group>
            <v-select
              v-model="config.fontSize"
              left
              class="custom-select"
              :options="fontSizes"
            />
          </b-form-group>
        </b-col>

        <b-col cols="1">
          <b-form-group>
            <b-input
              v-model="config.color"
              type="color"
              style="width: 38px;"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'

export default {
  name: 'TiptapMod',

  mixins: [ModMixin],

  data () {
    return {
      fontSizes: ['8px','9px','10px','11px','12px','13px','14px','15px','16px','17px','18px','19px','20px','21px','22px','23px','24px','25px','26px','27px','28px','29px','31px','32px','33px','34px','35px','36px','37px', '60px'],
      fonts: [
        'Global Style',
        'Roboto',
        'Montserrat',
        'Georgia',
        'Playfair Display',
        'Raleway',
        'Spectral',
        'Rubik'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .menubar {
    .btn-menubar-button {
      border: 1px solid #E2E5EC;
      background-color: #fff;

      i {
        color: #aeb1b4;
      }

      &.is-active i {
        color: #44474A;
      }
    }
  }
</style>
