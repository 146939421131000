<template>
  <div
    class="card-template"
  >
    <div
      class="transition relative mb-[18px] cursor-pointer hover:-translate-y-5"
    >
      <div class="w-full h-[298px] bg-white rounded-[4px] shadow-[0_5px_6px_rgba(0,0,0,0.13)]" />
      <div class="absolute top-[-6px] left-[-5px] w-full h-[298px] bg-white rounded-[4px] shadow-[0_5px_6px_rgba(0,0,0,0.13)]" />
      <div
        :style="{background: `url(${preview})`}"
        class="overflow-hidden !bg-cover !bg-no-repeat absolute top-[-11px] left-[-10px] w-full h-[298px] bg-white rounded-[4px] shadow-[0_5px_6px_rgba(0,0,0,0.13)] !bg-[top_center]"
      />
    </div>

    <div class="flex justify-between text-[#44474A] text-[16px] font-[400] w-full cursor-pointer">
      <span>
        {{ name }} <span class="text-[#B5BBC5]">({{ pageCount }} {{ pageCount > 1 ? 'pages' : 'page' }})</span>
      </span>
      <span class="font-[600]">{{ !isFree ? getPrice : 'Free' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateCard',
  props: {
    template: {
      type: Object,
      required: false
    },
    isFree: {
      type: [Boolean, Number],
      default: true
    },
    name: {
      type: String,
      required: true
    },
    preview: {
      required: true
    },
    pageCount: {
      type: Number,
      required: true
    },
    price: {
      required: true
    },
    updateding: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    getPrice () {
      return '$' + parseInt(this.price).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    }
  }
}
</script>