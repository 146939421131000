<template>
  <div class="pt-[9px]">
    <sid-range-slider
      rule-name="max-width"
      :sid="`.--${section.sid}--wrapper`"
      default-dimension="%"
      :dimensions="['%', 'vw', 'px']"
      label="Width"
      var="--width"
      measurement="width"
    />

    <b-form-group>
      <sid-range-slider
        rule-name="height"
        :sid="`.--${section.sid}--wrapper`"
        default-dimension="%"
        :dimensions="['%', 'vh', 'px']"
        label="Height"
        var="--height"
        measurement="height"
      />
    </b-form-group>

    <b-form-group label="Code">
      <b-textarea
        v-model="config.code"
        rows="7"
      />
    </b-form-group>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'

export default {
  name: 'ButtonMod',
  components: {SidRangeSlider, SidLinkedGroup, SidbearSettingsGroup},
  mixins: [ModMixin],
  computed: {
    margin () {
      if (!this.config.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.margin
    }
  }
}
</script>
