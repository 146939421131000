<template>
  <div>
    <div class="d-flex align-items-center mb-4">
      <span class="indicator mr-2" />
      <p class="text-capitalize mb-0">
        {{ labelCase(componentName) }}
      </p>
    </div>

    <b-form-group label="Form Control Name">
      <b-input
        v-model="fieldName"
        :disabled="config.hasOwnProperty('id')"
      />
    </b-form-group>
    <b-link @click="reset">
      Reset
    </b-link>

    <b-form-group label="Related Button">
      <div
        v-if="!fieldName.length"
        class="text-danger mt-2"
      >
        Name for the field is not specified
      </div>
    </b-form-group>

    <b-form-group label="Label">
      <b-input
        v-model="config.label"
        placeholder="Input label"
      />
    </b-form-group>

    <b-form-group label="Placeholder">
      <b-input
        v-model="config.placeholder"
        placeholder="Input placeholder"
      />
    </b-form-group>

    <b-form-group label="Style">
      <v-select
        v-model="config.style"
        :options="styleOptions"
        label="text"
        :searchable="false"
        :reduce="option => option.value"
        class="custom-select"
      />
    </b-form-group>

    <b-form-group label="Size">
      <v-select
        v-model="config.size[resolution]"
        :options="sizeOptions"
        label="text"
        :searchable="false"
        :reduce="option => option.value"
        class="custom-select"
      />
    </b-form-group>

    <b-form-group>
      <form-control-validation :config="config" />
    </b-form-group>

    <b-form-group>
      <b-btn
        block
        variant="primary"
      >
        Save Changes
      </b-btn>
    </b-form-group>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import {mapGetters, mapState} from 'vuex'
import FormControlValidation from '../utils/FormControlValidation'
import FormCustomFieldsBuilder from '../../builder/utils/auto-responders/mixins/FormCustomFieldsBuilder'
import _ from 'lodash'
import {toStringify} from '@/utils/helpers'

const types = {
  input: 'text',
  textarea: 'text'
}

export default {
  name: 'ButtonMod',
  components: {FormControlValidation},
  mixins: [ModMixin, FormCustomFieldsBuilder],

  data () {
    return {
      inputTypes: [
        {
          name: 'Text',
          type: 'text'
        },
        {
          name: 'Email',
          type: 'email'
        },
        {
          name: 'Phone',
          type: 'phone'
        },
        {
          name: 'Number',
          type: 'number'
        }
      ],
      sizeOptions: [
        {
          text: 'Default',
          value: null
        },
        {
          text: 'Small',
          value: 'sm'
        },
        {
          text: 'Large',
          value: 'lg'
        }
      ],
      styleOptions: [
        {
          text: 'Default',
          value: null
        },
        {
          text: 'Pilled',
          value: 'pilled'
        },
        {
          text: 'Squared',
          value: 'squared'
        }
      ]
    }
  },

  computed: {
    getResponder () {
      if (!this.relatedButton) return {}
      return this.relatedButton.autoresponder ? this.relatedButton.autoresponder : this.$store.state.apiIntegration.contactForm
    },
    fieldName: {
      get () {
        return this.config.fieldName || ''
      },
      set (val) {
        this.$set(this.config, 'fieldName', val)
      }
    },
    ...mapState('apiIntegration', {
      mailServiceAPI: state => state.mailServiceAPI
    }),
    ...mapGetters('forms', [
      'getAvaliableForms'
    ]),
    relatedButton () {
      return this.relatedButtonUID
        ? _.head(this.getAvaliableForms.filter(button => button.options.content.button.buttonUID === this.relatedButtonUID))
        : null
    },
    relatedButtonUID: {
      get () {
        return this.config.relatedUID
      },
      set (val) {
        this.$store.dispatch('apiIntegration/addField', {
          label: this.config.fieldName,
          field_type: types[this.config.inputType],
          is_required: false,
          columns: toStringify({
            lg: 12,
            md: 12,
            sm: 12
          })
        })
          .then(({data}) => {
            this.$set(this.config, 'id', data.data.id)
            this.config.relatedUID = val

            if (this.getResponder.id) {
              this.$store.dispatch('apiIntegration/sync')
            }
          })
      }
    }
  },

  methods: {
    reset () {
      this.config.relatedUID = null
      this.config.fieldName = ''
    }
  }
}
</script>
