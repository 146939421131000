<template>
  <div>
    <b-btn
      class="font-weight-normal"
      pill
      variant="outline-primary"
      @click="modal = true"
    >
      Edit Password
    </b-btn>

    <b-modal
      v-model="modal"
      size="lg"
      hide-header
      hide-footer
      body-class="account-settings p-5"
      centered
    >
      <div class="px-md-5">
        <b-link
          class="position-absolute top-5 right-10"
          @click="modal = false"
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
              fill="#6E747A"
            />
          </svg>
        </b-link>

        <h4 class="font-weight-bold mb-4">
          Edit Password
        </h4>

        <b-form @submit.prevent="submit">
          <b-row class="mb-4">
            <!--            <b-col md="8">-->
            <!--              <b-form-group label="Old password">-->
            <!--                <div class="position-relative">-->
            <!--                  <b-input v-model="form.oldPassword"-->
            <!--                           :type="showPassword ? 'text' : 'password'"-->
            <!--                           name="old_password"-->
            <!--                           :state="errors.has('old_password') ? false : null"-->
            <!--                           v-validate="'required'"-->
            <!--                  />-->

            <!--                  <div class="position-absolute top-1/2 transform -translate-y-1/2 right-8">-->
            <!--                    <b-link @click="showPassword = !showPassword">-->
            <!--                      <i v-if="showPassword" class="fa fa-eye"></i>-->
            <!--                      <i v-else class="fa fa-eye-slash"></i>-->
            <!--                    </b-link>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </b-form-group>-->
            <!--            </b-col>-->

            <b-col md="8">
              <b-form-group label="New password">
                <div class="position-relative">
                  <input
                    ref="new_password"
                    v-model="form.newPassword"
                    v-validate="'required|min:7'"
                    :type="showPassword ? 'text' : 'password'"
                    name="new_password"
                    class="form-control"
                    :class="{'is-invalid': errors.has('new_password') ? true : null}"
                  >
                  <div class="position-absolute top-1/2 transform -translate-y-1/2 right-8">
                    <b-link @click="showPassword = !showPassword">
                      <i
                        v-if="showPassword"
                        class="fa fa-eye"
                      />
                      <i
                        v-else
                        class="fa fa-eye-slash"
                      />
                    </b-link>
                  </div>
                </div>
                <span class="d-block mt-2">The password field must be at least 7 characters.</span>
              </b-form-group>
            </b-col>

            <b-col md="8">
              <b-form-group label="Re-type new password">
                <input
                  v-model="form.passwordConfirmation"
                  v-validate="'required|confirmed:new_password'"
                  type="password"
                  class="form-control"
                  name="password_confiramtion"
                  :class="{'is-invalid': errors.has('password_confiramtion') ? true : null}"
                  data-vv-as="new_password"
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-btn
            type="submit"
            variant="primary"
            size="lg"
            class="font-weight-bold font-size-14 px-20"
            :disabled="loading"
          >
            Change Password
            <b-spinner
              v-if="loading"
              class="ml-3"
              variant="light"
              small
            />
          </b-btn>

          <!--          <div>-->
          <!--            Forgotten Password?-->
          <!--            <b-link class="text-primary">Click Here</b-link>-->
          <!--          </div>-->
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ChangePasswordModal',
  data () {
    return {
      modal: false,
      loading: false,
      showPassword: false,
      form: {
        newPassword: '',
        passwordConfirmation: ''
      }
    }
  },

  methods: {
    submit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true
          axios.put('api/user/profile/login-info', {
            password: this.form.newPassword
          })
            .then(() => {
              Object.keys(this.form).forEach(key => this.form[key] = '')
              this.modal = false
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>