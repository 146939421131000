<template>
  <div>
    <div
      v-if="integrationsLoading"
      class="w-full h-[50px] flex items-center justify-center "
    >
      <b-spinner
        class="!w-6 !h-6"
        variant="primary"
      />
    </div>
    <template v-else>
      <template v-for="(integration,i) in integrations">
        <b-checkbox
          :key="i"
          v-model="integration.is_excluded"
          :value="0"
          :unchecked-value="1"
          :class="{'mb-2':i+1 != integrations.length}"
        >
          {{ integration.name }}
        </b-checkbox>
      </template>
      <p
        v-if="!integrations.length"
        class="mb-0 text-center"
      >
        No integrations on this page
      </p>
    </template>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'IntegrationExclusionSettings',
  props: {
    projectId: {
      type: [Number,String],
      default: ''
    },
    pageId: {
      type: [Number,String],
      default: ''
    }
  },
  computed: {
    ...mapState('integrationExclusions', {
      integrationsData: state => state.list
    }),
    integrations (){
      return this.integrationsData.data
    },
    integrationsLoading (){
      return this.integrationsData.loading
    }
  },
  async created () {
    const {project_id, page_id} = this.integrationsData
    if((project_id != this.projectId || page_id != this.pageId || (project_id == this.projectId && page_id == this.pageId && !this.integrations.length)) && !this.integrationsLoading){
      await this.getIntegrations({projectId:this.projectId,pageId:this.pageId})
    }
  },
  methods: {
    ...mapActions({
      getIntegrations:'integrationExclusions/getList'
    })
  }
}
</script>

<style scoped>

</style>