<template>
  <highliter
    v-model="section.name"
    class="feature-section"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings(true)"
  >
    <ImageItem1Template
      v-show="visability('image')"
      :section="section"
    >
      <highliter
        v-for="(image, index) in section.options.content.image.items"
        :key="index"
        :style="{opacity: image.opacity}"
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @dublicate="$emit('dublicate')"
        @delete="$emit('delete')"
        @click.stop.native="sectionSettings({item: 'image', index})"
      >
        <image-fit
          :customs="image"
          :src="image.src || image.placeholder"
          :size="cols(image.size, 'contain')"
          :position="image.position"
        />
      </highliter>
    </ImageItem1Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ImageItem1Template from '@builder/templates/sections/images/ImageItem1Template'
import ImageFit from '../../../utils/ImageFit'

export default {
  name: 'ImageItem',

  components: {ImageFit, ImageItem1Template, Highliter},

  mixins: [SectionMixin],

  created () {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>
