<template>
  <b-row
    no-gutters
    class="d-flex justify-content-center align-items-end editor-toolbar"
  >
    <b-col
      cols="10"
      class="mx-auto"
    >
      <b-form-group
        label="Select Project"
        class="pm-form-group"
        label-class="pm-label"
      >
        <projects-tree-select
          v-model="templateMutation"
          show-chevron-icon
        />
      </b-form-group>
    </b-col>

    <b-col
      class="d-flex"
      cols="12"
    >
      <b-btn
        :disabled="!templateMutation.id || loading"
        class="!text-[15px] font-weight-bold py-2 px-3 d-flex import-page-select align-items-center mx-auto"
        variant="primary"
        type="submit"
        @click="nextStep"
      >
        Add From Template
        <svg
          v-if="!loading"
          class="ml-2"
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.6 8.3C16.6 12.884 12.884 16.6 8.3 16.6C3.71604 16.6 0 12.884 0 8.3C0 3.71604 3.71604 0 8.3 0C12.884 0 16.6 3.71604 16.6 8.3ZM8.3 15.3C12.166 15.3 15.3 12.166 15.3 8.3C15.3 4.43401 12.166 1.3 8.3 1.3C4.43401 1.3 1.3 4.43401 1.3 8.3C1.3 12.166 4.43401 15.3 8.3 15.3Z"
            fill="white"
          />
          <path
            d="M11.8999 8.53198V7.26798H9.03592V4.14798H7.64392V7.26798H4.76392V8.53198H7.64392V11.78H9.03592V8.53198H11.8999Z"
            fill="white"
          />
        </svg>

        <b-spinner
          v-else
          class="ml-2"
          small
        />
      </b-btn>
    </b-col>
  </b-row>
</template>

<script>
import {mapState} from 'vuex'
import ProjectsTreeSelect from '../../../../layouts/dashboard/compontens/templates/ProjectsTreeSelect'

export default {
  name: 'ImportPage',
  components: {ProjectsTreeSelect},
  data () {
    return {
      step: 2,
      template: {
        id: 0,
        label: '',
        setup: '{}'
      },
      page: null,
      pages: [],
      loading: false
    }
  },

  computed: {
    pageMutatuion: {
      get () {
        return this.page
      },
      set (val) {
        if (val.back) return this.step = 2
        this.page = val
      }
    },
    templateMutation: {
      get () {
        return this.template
      },
      set (val) {
        this.template = {
          ...val,
          pages: JSON.parse(val.setup).pages
        }
        this.step++
      }
    },
    ...mapState('templates', {
      templates: state => state.templates
    }),
    ...mapState('projects', {
      project: state => state.project
    })
  },

  created () {
    this.$store.dispatch('templates/getTemplates')
  },

  methods: {
    importPage () {
      this.loading = true

      axios.post(`api/projects/${this.project.id}/pages/mass-creation`, {
        pages: this.template.pages.map(page => {
          return {
            ...page,
            is_home: false
          }
        })
      })
        .finally(() => {
          this.loading = false
          this.$store.dispatch('pages/loadPages')

          this.template = {
            id: 0,
            label: '',
            setup: '{}'
          }

          this.step = 2

          this.$swal({
            icon: 'success',
            title: 'Done!',
            text: 'All pages have been added to your project',
            showConfirmButton: false,
            timer: 1500
          })
          this.$emit('page-added')
        })

      // this.$store.dispatch('pages/addPage', {
      //   title: this.page.title,
      //   meta_title: this.page.meta_title,
      //   content: this.page.content_draft,
      //   content_draft: this.page.content_draft
      // }).finally(() => {
      //   this.loading = false
      // })
    },
    nextStep () {
      if (!this.template) return
      this.importPage()

      // if (this.step === 1 && !this.template) return

      // this.step++
      // if (this.step === 3) {
      //   this.importPage()
      //   this.step = 2
      // }
    }
  }
}
</script>

<style lang="scss">
.import-page-select {
  height: 38px !important;
  border-radius: 5px !important;
}
</style>
