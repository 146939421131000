<template>
  <div>
    <div class="flex items-center btn-group w-100 sid-select">
      <button
        v-for="(text, index) in statesText"
        :key="index"
        :class="{'!bg-[#F2F4F5] !text-[#44474A]': activeActionState === index}"
        class="btn h-[33px] flex items-center justify-center !text-[13px] p-0 !font-[400] !text-[#44474A] !border-[#E2E5EC]"
        style="border:1px solid;"
        @click="() => activeActionState = index"
        v-text="text"
      />
    </div>

    <div class="pt-[12px]">
      <div v-if="!activeActionState">
        <slot />
      </div>
      <div v-else-if="activeActionState === 2">
        <slot
          name="active-state"
        />
      </div>
      <div v-else>
        <slot
          name="action-state"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StateTabs',
  props: {
    statesText: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    },
    defaultState: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      activeActionState: this.defaultState
    }
  },

  watch: {
    activeActionState () {
      this.$emit('input', !!this.activeActionState)
      this.$emit('onChange', this.activeActionState)
    }
  }
}
</script>