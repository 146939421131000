<template>
  <div>
    <b-form-group label="Products">
      <v-select
        v-model="product"
        :filterable="false"
        :options="paginated"
        class="custom-select"
        :style="{height: '38px !important'}"
        label="name"
        placeholder="Select Product"
        @option:selected="selected"
        @close="onClose"
        @open="onOpen"
        @search="(query) => (search = query)"
      >
        <template #list-footer>
          <li
            v-show="hasNextPage"
            ref="load"
            class="loader"
          >
            Loading more options...
          </li>
        </template>
      </v-select>
    </b-form-group>

    <b-form-group v-if="value.length">
      <div class="flex flex-wrap gap-[3px] border p-[9px] rounded-[2px]">
        <div
          v-for="(item, index) in value"
          :key="index"
          class="flex items-center bg-[#3D82EA] py-[3px] px-[7px] rounded-[3px] text-[11px] font-[700] text-white"
        >
          <span
            class="mr-[7px]"
            v-text="item.name"
          />

          <button
            class="hover:opacity-80 p-0 m-0"
            @click="removeItem(index)"
          >
            <svg
              fill="none"
              height="7"
              viewBox="0 0 6 7"
              width="6"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.640159 0.609834C0.493714 0.463389 0.256279 0.463389 0.109834 0.609834C-0.0366113 0.756279 -0.0366113 0.993714 0.109834 1.14016L2.46968 3.5L0.109834 5.85984C-0.0366111 6.00629 -0.0366113 6.24372 0.109834 6.39017C0.256279 6.53661 0.493714 6.53661 0.640159 6.39017L3 4.03033L5.35978 6.39011C5.50623 6.53656 5.74366 6.53656 5.89011 6.39011C6.03656 6.24366 6.03656 6.00623 5.89011 5.85978L3.53033 3.5L5.89011 1.14022C6.03656 0.99377 6.03656 0.756336 5.89011 0.609891C5.74366 0.463445 5.50623 0.463445 5.35978 0.609891L3 2.96968L0.640159 0.609834Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
export default {
  name: 'SelectProduct',
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      product: '',
      observer: null,
      limit: 25,
      search: ''
    }
  },
  computed: {
    ...mapState('products', {
      loading: state => state.loading,
      products: state => state.products
    }),
    list () {
      return this.products.map(item => !this.value.find(v => v.id === item.id && v.name === item.name) ? item : null).filter(item => item)
    },
    filtered () {
      return this.list.filter((item) => item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    }
  },
  methods: {
    ...mapMutations({
      PUSH_SELECTED: 'products/PUSH_SELECTED',
      SPLICE_SELECTED: 'products/SPLICE_SELECTED'
    }),
    selected (val) {
      this.product = ''
      this.PUSH_SELECTED(val)
    },
    removeItem (index) {
      this.SPLICE_SELECTED(index)
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        if (this.$refs.load) {
          this.observer.observe(this.$refs.load)
        }
      }
    },
    onClose () {
      this.limit = 10
      if (this.observer) {
        this.observer.disconnect()
      }
    },
    async infiniteScroll ([entry]) {
      const { isIntersecting, target } = entry
      if (isIntersecting && target instanceof Element) {
        const ul = target.offsetParent
        const scrollTop = ul.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    }
  },
  async mounted () {
    await this.$store.dispatch('products/getProductList')
    console.log(this.products)
    this.observer = new IntersectionObserver(this.infiniteScroll)
  }
}
</script>