<template>
  <div>
    <b-form-group
      label="Translate Y"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center no-gutters gap-[9px] pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="translateY"
            :interval="1"
            :max="200"
            :min="-200"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="translateY"
            :max="200"
            :min="-200"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Translate X"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center no-gutters gap-[9px] pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="translateX"
            :interval="1"
            :max="200"
            :min="-200"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="translateX"
            :max="200"
            :min="-200"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      v-if="!hide.includes('scale')"
      label="Scale"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center no-gutters gap-[9px] pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="config.scale[resolutioner]"
            :interval=".1"
            :max="2"
            :min="0.1"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="config.scale[resolutioner]"
            :max="2"
            :min="0.1"
            :step=".1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group v-if="config.scale[resolutioner] > 1">
      <b-checkbox v-model="overflowScale">
        Overflow Hidden
      </b-checkbox>
    </b-form-group>

    <image-height-width
      v-if="!hide.includes('scale')"
      :config="config"
    />

    <b-form-group label="Opacity">
      <vue-slider
        v-model="opacity"
        :dot-size="17"
        :interval="0.1"
        :max="1"
        :min="0"
        class="editor-slider-2"
        tooltip="none"
      />
    </b-form-group>
  </div>
</template>

<script>
import ModControlMixin from '@/components/mixins/ModControlMixin'
import _ from 'lodash'
import ImageHeightWidth from '@/components/editor/controls/image-mod/ImageHeightWidth'

export default {
  name: 'ImageTranslate',
  components: {ImageHeightWidth},
  mixins: [ModControlMixin],
  computed: {
    overflowScale: {
      get () {
        return _.get(this.config, ['overflowScale', this.resolutioner], false)
      },
      set (val) {
        this.$set(this.config, 'overflowScale', {
          ...(this.config.overflowScale || {}),
          [this.resolutioner]: val
        })
      }
    },
    translate () {
      if (!this.config.translate) {
        this.$set(this.config, 'translate', {})
      }

      return this.config.translate
    },
    translateY: {
      get () {
        return _.get(this.config, ['translate', 'y', this.resolutioner], 0)
      },
      set (val) {
        this.$set(this.config, 'translate', {..._.set(this.translate, ['y', this.resolutioner], val)})
      }
    },
    opacity: {
      get () {
        return _.get(this.config, ['opacity', this.resolutioner], 1)
      },
      set (val) {
        this.$set(this.config, 'opacity', {..._.set(this.config.opacity || {}, [this.resolutioner], val)})
      }
    },
    translateX: {
      get () {
        return _.get(this.config, ['translate', 'x', this.resolutioner], 0)
      },
      set (val) {
        this.$set(this.config, 'translate', {..._.set(this.translate, ['x', this.resolutioner], val)})
      }
    }
  }
}
</script>