<template>
  <ul>
    <li
      v-for="item in items"
      :key="item.uuid"
      class="text-truncate"
      :class="{'mb-0':item.children.length}"
    >
      <a
        class="toc-h-item"
        @click.stop.prevent="scrollToHeading(item.uuid)"
      >
        <!-- Dynamic numbering if marker is 'number' -->
        <span
          v-if="marker === 'number'"
          class="toc-li-number"
        >
          {{ item.numbering }}.
        </span>

        <span
          v-if="marker === 'bullet'"
          class="toc-li-bullet"
        >
          <i class="fa fa-circle" />
        </span>

        <!-- Display icon if marker is 'icon' -->
        <i
          v-if="marker === 'icon'"
          :class="iconClass"
          class="toc-h-item-icon"
        />
        
        <!-- Display item text -->
        {{ item.text }}
      </a>
      <el-table-of-contents-item
        v-if="item.children.length"
        :items="item.children"
        :marker="marker"
        :icon-class="iconClass"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ElTableOfContentsItem',
  props: {
    items: {
      type: Array,
      required: true
    },
    marker: {
      type: String,
      default: 'bullet' // Options: 'number', 'bullet', 'icon'
    },
    iconClass: {
      type: String,
      default: 'fas fa-chevron-right' // Icon class for 'icon' marker option
    }
  },
  methods: {
    scrollToHeading (uuid) {
      const elements = document.querySelectorAll(`[data-uid="${uuid}"]`)
      if (elements.length) {
        elements[0].scrollIntoView({ behavior: 'smooth' })
      }
    }
  },
  components: {
    ElTableOfContentsItem: () => import('./ElTableOfContentsItem.vue')
  }
}
</script>

<style lang="scss" scoped>
.toc-card{
  ul {
    padding-left: 20px;
    margin-bottom: 0;
  }

  .toc-li-bullet{
    font-size: 6px;
    margin-right: 5px;
    transform: translateY(-1.8px);
    display: inline-flex;
  }
  .toc-li-number{
    margin-right: 3px;
  }

  li {
    margin: 5px 0;
    list-style-type: none;
  }

  .toc-h-item {
    cursor: pointer;
    color: #333;
    text-decoration: none;
  }

  .toc-h-item-icon {
    margin-right: 5px;
    font-size: 10px;
  }
}

</style>
