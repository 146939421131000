import {mapState} from 'vuex'
import CustomStylesMixin from './CustomStylesMixin'
import OveralSettingsMixin from './OveralSettingsMixin'

export default {
  props: {
    section: Object
  },

  mixins: [CustomStylesMixin, OveralSettingsMixin],

  methods: {
    aligment (align) {
      try {
        return this.section.options.customize.style.aligment.align === align
      } catch {
        return false
      }
    },
    visability (el) {
      const display = this.section.options.content[el].display
      return display === true || (this.tablet && display === 'md') || display === this.resolution
    },
    align (el) {
      return {
        textAlign: this.section.options.content[el].align
      }
    }
  },

  mounted () {
    this.$el.setAttribute('id', this.section.uid)
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles,
      tablet: state => state.tablet
    })
  }
}
