<template>
  <div class="sid-select">
    <SidbearSettingsGroup 
      title="Text"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <rule-tabs>
        <template #Normal>
          <b-form-group
            label="Text"
          >
            <b-input v-model="element.options.content.previousButton.text" />
          </b-form-group>

          <sid-font
            :sid="`.--${element.sid}--previews-button`"
          />

          <sid-range-slider
            label="Text Size"
            :sid="`.--${element.sid}--previews-button`"
            rule-name="font-size"
          />

          <sid-range-slider
            label="Letter-Spacing"
            :sid="`.--${element.sid}--previews-button`"
            rule-name="letter-spacing"
          />

          <sid-range-slider
            label="Line-Height"
            :sid="`.--${element.sid}--previews-button`"
            rule-name="line-height"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--previews-button`"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
        <template #Hover>
          <sid-range-slider
            label="Text Size"
            :sid="`.--${element.sid}--previews-button`"
            rule-name="font-size"
            presudo=":hover"
          />

          <sid-range-slider
            label="Letter-Spacing"
            :sid="`.--${element.sid}--previews-button`"
            rule-name="letter-spacing"
            presudo=":hover"
          />


          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--previews-button`"
                presudo=":hover"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup
      title="Icon"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <rule-tabs>
        <template #Normal>
          <sid-range-slider
            label="Text Size"
            :sid="`.--${element.sid}--previous-button-icon`"
            rule-name="font-size"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--previous-button-icon`"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
        <template #Hover>
          <sid-range-slider
            label="Text Size"
            :sid="`.--${element.sid}--previous-button-icon`"
            rule-name="font-size"
            presudo=":hover"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${element.sid}--previous-button-icon`"
                rule-name="color"
                presudo=":hover"
              />
            </div>
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Layout & Style"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <rule-tabs>
        <template #Normal>
          <sid-align
            :sid="`.--${element.sid}--container`"
            rule-name="justify-content"
            label="Button Alignment"
          />

          <sid-background
            :sid="`.--${element.sid}--previews-button`"
            default-color="#77abff"
            small
          />
        </template>
        <template #Hover>
          <sid-background
            :sid="`.--${element.sid}--previews-button`"
            presudo=":hover"
            small
          />          
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Border & Radius"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${element.sid}--previews-button`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${element.sid}--previews-button`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${element.sid}--previews-button`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${element.sid}--previews-button`"
            rule-name="border"
            class="mb-0"
            :angles="[
              {
                text: 'Top Left',
                value: 'top-left-radius'
              },
              {
                text: 'Top Right',
                value: 'top-right-radius'
              },
              {
                text: 'Bottom Left',
                value: 'bottom-left-radius'
              },
              {
                text: 'Bottom Right',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>
        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${element.sid}--previews-button`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${element.sid}--previews-button`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${element.sid}--previews-button`"
                presudo=":hover"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${element.sid}--previews-button`"
            rule-name="border"
            presudo=":hover"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup 
      title="Padding & Margins"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: 0}"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${element.sid}--previews-button`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${element.sid}--margin`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'

export default {
  name: 'PreviewButtonSettings',
  components: {
    SidBackground,
    SidAlign,
    SidSelect,
    SidLinkedGroup,
    SidRangeSlider,
    SidColor,
    SidFont,
    RuleTabs, 
    SidbearSettingsGroup
  },
  props: {
    element: {
      type: Object,
      required: true
    }
  }
}
</script>