<template>
  <div class="w-100">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'EsBlog'

}
</script>
