<template>
  <div class="bg-white border rounded">
    <div class="flex justify-between items-center p-[30px]">
      <b-checkbox v-model="selectAllMutation">
        <span class="block text-[15px] translate-y-[-1px]">Select All</span>
      </b-checkbox>

      <div class="text-[15px] text-[#7C7C7C]">
        displaying <strong class="text-[#6E747A]">{{ comments.data.length }}</strong> of {{ comments.total }} comments
      </div>
    </div>

    <div class="table-responsive">
      <table class="w-100 mb-5">
        <thead class="bg-[#F9FBFF] text-[15px] text-[#74819B]">
          <tr>
            <th class="pl-[30px] py-[20px]">
              Author
            </th>
            <th class="py-[20px]">
              Comment
            </th>
            <th class="py-[20px]">
              Location
            </th>
            <th class="py-[20px]">
              Date
            </th>
            <th class="pr-[30px] py-[20px] w-[150px]">
              Actions
            </th>
          </tr>
        </thead>
        <tbody class="align-top">
          <comments-table-item
            v-for="comment in comments.data"
            :key="comment.id"
            v-model="selectedIds"
            :comment="comment"
            @refresh="$emit('refresh')"
          />
        </tbody>
      </table>
    </div>

    <slot />
  </div>
</template>

<script>
import CommentsTableItem from '@/views/dashboard/comments/CommentsTableItem.vue'

export default {
  name: 'CommentsTable',
  components: {CommentsTableItem},
  props: {
    value: {
      type: Array
    },
    comments: {
      type: Object
    }
  },
  data () {
    return {
      selectAll: false
    }
  },
  computed: {
    selectAllMutation: {
      get () {
        return this.selectAll
      },
      set (val) {
        this.selectAll = val

        if (val) {
          return this.selectedIds = this.comments.data.map(c=> c.id)
        }

        this.selectedIds = []
      }
    },
    selectedIds: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>