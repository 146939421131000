<template>
  <div>
    <label class="!mb-[7px] w-100 pb-0">
      <b-row
        class="items-end"
        no-gutters
      >
        <b-col
          cols="8"
          class="pr-3"
        >
          <span v-text="label" />
        </b-col>

        <b-col>
          <div class="flex items-center text-[13px] gap-[6px]">
            <span
              v-for="option in options"
              :key="option.value"
              class="text-[#333] cursor-pinter opacity-60 hover:opacity-80"
              :class="{'font-bold !opacity-100': option.value === type.value}"
              @click="type = option.value"
              v-text="option.name"
            />
          </div>
        </b-col>
      </b-row>
    </label>
    <b-row
      class="align-items-center pl-2 flex-nowrap"
      no-gutters
    >
      <b-col
        cols="8"
        class="pr-3"
      >
        <vue-slider
          v-model="valueMutated"
          :interval="1"
          :max="type.max"
          :min="type.min"
          class="editor-slider-1"
          tooltip="none"
        />
      </b-col>
      <b-col>
        <linked-control-item
          v-model="valueMutated"
          class="d-block w-[69px]"
          :max="type.max"
          :min="type.min"
          :smart="false"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from 'lodash'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import ControlMixin from '@/components/mixins/ControlMixin'

export default {
  name: 'MultiSlider',
  components: {LinkedControlItem},
  mixins: [ResolutionMixin, ControlMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    defaultType: {
      type: Number,
      default: 0
    },
    options: {
      type: Array,
      default: () => [
        {
          name: 'px',
          value: 'px',
          max: 4000,
          min: 0,
          defaultValue: 1140
        },
        {
          name: '%',
          value: '%',
          max: 100,
          min: 0,
          defaultValue: 100
        }
      ]
    }
  },
  computed: {
    type: {
      get () {
        const type = _.get(this.value, [this.resolutioner, 'type'], this.options[this.defaultType].value)
        return this.options.find(opt => opt.value === type)
      },
      set (val) {
        this.$emit('input', {
          ..._.set(this.value, [this.resolutioner, 'type'], val)
        })

        setTimeout(() => {
          const type = this.options.find(opt => opt.value === val)
          this.valueMutated = type.defaultValue
        })
      }
    },
    valueMutated: {
      get () {
        return _.get(this.value, [this.resolutioner, 'value'], this.options[this.defaultType].defaultValue)
      },
      set (val) {
        this.$emit('input', {
          ..._.set(this.value, [this.resolutioner], {
            value: val,
            type: this.type.value
          })
        })
      }
    }
  }
}
</script>
