var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('highliter',{staticClass:"d-flex justify-center w-100 post-middle",attrs:{"value":"Content Wrapper","hide-add":"","hide-move":"","hide-right":""},nativeOn:{"click":function($event){$event.stopPropagation();return (function () { return _vm.$store.dispatch('editor/sectionSettings', {
    section: {
      options: {
        content: {}
      }
    },
    openedItem: 'blog-wrapper-mod'
  }); })($event)}}},[_c('div',{staticClass:"block-bg post-middle-bg"}),_vm._t("default",null,{"flow":_vm.flow})],2)}
var staticRenderFns = []

export { render, staticRenderFns }