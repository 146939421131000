<template>
  <b-progress
    style="height: 4px;"
    :variant="variant"
    :value="value"
    :max="max"
  />
</template>

<script>
export default {
  name: 'StrongProgress',
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    }
  },
  computed: {
    variant () {
      return this.value >= this.min && this.value <= this.max ? 'success' : this.value > this.max ? 'danger' : 'warning'
    }
  }
}
</script>

<style scoped>

</style>