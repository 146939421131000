<template>
  <div class="border-right module-editor-aside">
    <pin-unpin-bar :error="error">
      <template v-if="page === 'section-settings'">
        <component
          :is="page"
          style="animation-duration: 0.3s"
        />
      </template>

      <blocks-menu v-else-if="page === 'blocks-menu'" />

      <div
        v-else
        class="position-relative h-100 custom-menu"
      >
        <div
          class="flex items-center justify-between h-[43px] bg-gradient-to-b from-white to-[#F1F3F4] px-[18px] border-b border-[#E2E5EC]"
        >
          <span
            class="text-[#44474A] font-[700] text-[15px] mb-0"
            v-text="asideTitle"
          />
        </div>

        <div class="flex bg-[#FAFAFA] flex-column relative max-h-full h-[calc(100vh-125px)] simple-scrollbar">
          <div class="overflow-auto pb-[1px] ml-[-7px]">
            <b-card
              class="border-0"
              no-body
            >
              <div class="section-settings sid-select">
                <b-card
                  class="border-0 noselect"
                  no-body
                >
                  <b-card-body class="border-top-0 bg-[#FAFAFA] pr-3 pt-0 pb-0">
                    <slot />
                  </b-card-body>
                </b-card>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </pin-unpin-bar>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import BlocksMenu from '@/components/editor/navigation/BlocksMenu'
import SectionSettings from '@/components/editor/navigation/SectionSettings'
import PinUnpinBar from '@/components/editor/components/PinUnpinBar'

export default {
  name: 'ModuleEditorObjects',
  components: {PinUnpinBar, SectionSettings, BlocksMenu},
  props: {
    asideTitle: {
      type: String
    }
  },
  data () {
    return {
      error: true
    }
  },
  computed: {
    ...mapState( {
      page: state => state.editor.page
    })
  }
}
</script>

<style lang="scss">
@import "@builder/assets/scss/_variables.scss";

.layout-editor-collapse-header {
  color: #333333;
  font-weight: bold;
  font-size: 16px;
  line-height: 63px;
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 0 !important;
  transition: .3s;

  &:hover {
    color: $primary;
    background: rgba($primary, .1) !important;
  }
}

.module-editor-aside {
  .simple-scrollbar {
    height: calc(100vh - 112px) !important;
  }
}
</style>
