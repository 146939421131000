<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('flipCard')"
  >
    <div :data-sid="section.sid">
      <div
        class="es_card"
        :class="[`--${section.sid}--card`, `es_card-${section.options.content.flipCard.direction ? section.options.content.flipCard.direction[resolutioner] : 1}`, {'active': section.options.content.flipCard.active}]"
      >
        <div class="es_card-inner">
          <div class="es_card-front es_card-content d-flex">
            <div class="block-bg" />
            <div class="el-overlay" />
            <elements
              v-if="!section.options.content.flipCard.active"
              v-model="section.options.content.flipCard.front"
              drag-height="100%"
              :classes="{'h-100': !section.options.content.flipCard.front.length}"
              :class="{'h-100': !section.options.content.flipCard.front.length}"
              @flow="handleFlow"
            />
          </div>
          <div class="es_card-back es_card-content d-flex">
            <div class="block-bg" />
            <div class="el-overlay" />
            <elements
              v-if="section.options.content.flipCard.active"
              v-model="section.options.content.flipCard.back"
              drag-height="100%"
              :classes="{'h-100': !section.options.content.flipCard.back.length}"
              :class="{'h-100': !section.options.content.flipCard.back.length}"
              @flow="handleFlow"
            />
          </div>
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Elements from '@/components/builder/utils/Elements/Elements'

export default {
  name: 'ElFlipCard',

  components: {Elements, Highliter },

  mixins: [SectionMixin]
}
</script>

<style lang="scss">
.es_card {
  width: 100%;
  height: 400px;
  --height: 300px;
  --width: 100%;
  perspective: 1000px;
  margin: 0 auto;
}

.es_card-front .block-bg {
  border-radius: var(--border-top-left-radius) var(--border-top-right-radius) var(--border-bottom-right-radius) var(--border-bottom-left-radius);
}

.es_card-front > .block-bg + .el-overlay {
    border-radius: var(--border-top-left-radius) var(--border-top-right-radius) var(--border-bottom-right-radius) var(--border-bottom-left-radius);
}

.es_card-1 {
  .es_card-inner {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s;
  }

  &.active .es_card-inner {
    transform: rotateY(180deg);
  }

  .es_card-front,
  .es_card-back {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  .es_card-front {
    background-color: #f0f0f0;
    color: #333;
  }

  .es_card-back {
    background-color: #333;
    color: #f0f0f0;
    transform: rotateY(180deg);
  }
}

.es_card-2 {
  .es_card-inner {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s;
  }

  &.active .es_card-inner {
    transform: rotateX(180deg);
  }

  .es_card-back {
    rotate: 180deg;
  }

  .es_card-front,
  .es_card-back {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  .es_card-front {
    background-color: #f0f0f0;
    color: #333;
  }

  .es_card-back {
    background-color: #333;
    color: #f0f0f0;
    transform: rotateY(180deg);
  }
}

.es_card-3 {
  & {
    overflow: hidden;
  }

  .es_card-front, .es_card-back {
    height: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: all 0.3s ease;
  }

  .es_card-front {
    background-color: #eee;
  }

  .es_card-back {
    background-color: #ddd;
    z-index: 1;
    transform: translateY(100%);
  }

  &.active .es_card-back {
    transform: translateY(0);
  }
}

.es_card-4 {
  & {
    overflow: hidden;
  }

  .es_card-front, .es_card-back {
    height: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: all 0.3s ease;
  }

  .es_card-front {
    background-color: #eee;
  }

  .es_card-back {
    background-color: #ddd;
    z-index: 1;
    transform: translateY(-100%);
  }

  &.active .es_card-back {
    transform: translateY(0);
  }
}

.es_card-5 {
  & {
    overflow: hidden;
  }

  .es_card-front, .es_card-back {
    height: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: all 0.3s ease;
  }

  .es_card-front {
    background-color: #eee;
  }

  .es_card-back {
    background-color: #ddd;
    z-index: 1;
    transform: translateX(100%);
  }

  &.active .es_card-back {
    transform: translateY(0);
  }
}

.es_card-6 {
  & {
    overflow: hidden;
  }

  .es_card-front, .es_card-back {
    height: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: all 0.3s ease;
  }

  .es_card-front {
    background-color: #eee;
  }

  .es_card-back {
    background-color: #ddd;
    z-index: 1;
    transform: translateX(-100%);
  }

  &.active .es_card-back {
    transform: translateY(0);
  }
}
</style>