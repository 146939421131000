import Vue from 'vue'

export function useWebRTC (token, streamName, project, type) {
  let vm = new Vue()
  const startDisabled = false
  let callDisabled = true
  let hangupDisabled = true

  let pc1 = null
  let whipLocation = null

  const offerOptions = {
    offerToReceiveAudio: 1,
    offerToReceiveVideo: 1
  }

  const configuration = {
    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' }
    ]
  }

  vm.$on('hook:beforeDestroy', () => {
    if (pc1) {
      pc1.close()
    }
  })

  const getSender = (kind) => {
    if (pc1) {
      const sender = pc1.getSenders().find(sender => sender.track.kind === kind)

      if (sender) {
        return sender
      } else {
        console.log(`No ${kind} sender`)
        return null
      }
    }
  }

  const replaceTrack = async (track, kind) => {
    const sender = getSender(kind)
    if (sender) {
      try {
        await sender.replaceTrack(track)
        console.log(`${kind} track replaced successfully`)
      } catch (error) {
        console.error(`Failed to replace ${kind} track:`, error)
      }
    } else {
      console.log(`No ${kind} senders found to replace track`)
    }
  }

  const call = async (localStream, bitrate = 3000) => {
    callDisabled = true
    hangupDisabled = false
    console.log('Starting call')

    const videoTracks = localStream.getVideoTracks()
    const audioTracks = localStream.getAudioTracks()
    if (videoTracks.length > 0) {
      console.log(`Using video device: ${videoTracks[0].label}`)
    }
    if (audioTracks.length > 0) {
      console.log(`Using audio device: ${audioTracks[0].label}`)
    }

    console.log('RTCPeerConnection configuration:', configuration)
    pc1 = new RTCPeerConnection(configuration)
    console.log('Created local peer connection object pc1')

    pc1.onicecandidate = (e) => onIceCandidate(pc1, e)
    pc1.onicegatheringstatechange = gatheringStateChange
    pc1.oniceconnectionstatechange = (e) => onIceStateChange(pc1, e)

    localStream.getTracks().forEach(track => {
      const sender = pc1.addTrack(track, localStream)
      const parameters = sender.getParameters()
      if (!parameters.encodings) {
        parameters.encodings = [{}]
      }

      if (track.kind === 'video') {
        parameters.encodings[0].maxBitrate = bitrate * 1000 // Set maximum bitrate to 500 Kbps
        parameters.encodings[0].maxFramerate = 30 // Set maximum framerate to 30fps
        parameters.encodings[0].keyFrameInterval = 60
        sender.setParameters(parameters)
      } else if (track.kind === 'audio') {
        parameters.encodings[0].maxBitrate = 128 * 1000 // Set maximum bitrate for audio to 128 Kbps
        console.log('Audio parameters set:', parameters)
      }
    })
    console.log('Added local stream to pc1')

    try {
      console.log('pc1 createOffer start')
      const offer = await pc1.createOffer(offerOptions)
      await pc1.setLocalDescription(offer)
      const answer = await postSDPOffer(`https://arm.estage-aws.com:1990/rtc/v1/whip/?app=live&stream=${streamName}&sign=${token}&p=${project}&t=${type}`, pc1.localDescription.sdp, bitrate)
      await pc1.setRemoteDescription(answer)
      whipLocation = answer.location
    } catch (e) {
      onCreateSessionDescriptionError(e)
    }
  }

  const postSDPOffer = async (url, sdp, bitrate) => {
    let result = {
      status: 0,
      type: 'answer'
    }
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/sdp',
          'video-bitrate': bitrate,
          'video-keyint': 2,
          'audio-bitrate': 96000
        },
        body: sdp
      })
      result.status = response.status
      result.sdp = await response.text()
      result.location = response.headers.get('Location')
    } catch (error) {
      result.error = error
    }

    return new RTCSessionDescription({ type: 'answer', sdp: result.sdp })
  }

  const gatheringStateChange = (event) => {
    console.log('ICE gathering state changed:', event.target.iceGatheringState)
  }

  const onIceCandidate = (pc, event) => {
    if (event.candidate) {
      console.log('ICE candidate:', event.candidate)
    } else {
      console.log('End of candidates.')
    }
  }

  const onIceStateChange = (pc, event) => {
    if (pc) {
      console.log('ICE state change event:', event)
      console.log('ICE state:', pc.iceConnectionState)
    }
  }

  const onCreateSessionDescriptionError = (error) => {
    console.error('Failed to create session description:', error)
  }

  const hangup = () => {
    if (pc1) {
      console.log('Ending call')
      pc1.close()
      pc1 = null
      hangupDisabled = true
      callDisabled = false
      if (whipLocation) {
        fetch(whipLocation, { method: 'DELETE' })
      }
    }
  }

  return {
    startDisabled,
    callDisabled,
    hangupDisabled,
    call,
    hangup,
    getSender,
    replaceVideoTrack: track => replaceTrack(track, 'video'),
    replaceAudioTrack: track => replaceTrack(track, 'audio')
  }
}