<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="el-dynamic"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('data')"
  >
    <div :data-sid="section.sid">
      <div
        class="d-flex blog-categories"
        :class="`--blog-categories-${section.sid}`"
      >
        <a
          class="active"
          href="#"
        >{{ section.options.content.data.mainBtnText || 'All Categories' }}</a>
        <a
          v-for="category in categories"
          :key="category.id"
          href="#"
          v-text="category.name"
        />
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'

export default {
  name: 'ElBlogCategories',
  components: {Highliter},
  mixins: [SectionMixin],
  data () {
    return {
      categories: [
        {
          id: 1,
          name: 'News'
        },
        {
          id: 2,
          name: 'Marketing'
        },
        {
          id: 3,
          name: 'Social Media'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.blog-categories {
  a {
    padding: 20px 32px;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    transition: background-color .3s, color .3s;
    border: 1px solid #F2EEEE;
    margin-left: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    --width: 0;
    --height: 0;

    &:hover,
    &.active {
      background-color: #F0F6FF;
      text-decoration: none;
    }
  }
}
</style>