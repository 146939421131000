<template>
  <div
    :style="{...width, ...height, ...backgroundColorSubject(background), ...borderSubject(col.customize.style, [0,0,0,0]), ...radiusSubject(col.customize.style, [0,0,0,0]), ...fullMarginSubject(col.customize.style, 'marginTop'), ...shadow}"
    class="position-relative transitioner h-min-[100px]"
    :class="col.hoverAnimation"
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
  >
    <div
      :style="{...imageRadiusSubject(col.customize.style, [0,0,0,0]), ...backgroundImageSubject(col.customize.style)}"
      class="image-background"
    />
    <background-slider
      :style="{...imageRadiusSubject(col.customize.style, [0,0,0,0])}"
      :options="backgroundSliderSubject(col.customize.style)"
    />
    <div
      :style="{...imageRadiusSubject(col.customize.style, [0,0,0,0]), ...backgroundImageOverlaySubject(col.customize.style)}"
      class="image-background"
    />
    <div class="h-100 w-100 position-absolute left-0 top-0 overflow-hidden">
      <float-image
        v-if="floatImage.enabled"
        :config="floatImage"
      />
    </div>
    <shape-divider
      :config="col.customize.style"
    />

    <elements
      v-model="col.elements"
      class="flex-col-placeholder flex-column flex h-100"
      drag-height="100px"
      :styles="{...highlight, ...fullPaddingSubject(col.customize.style)}"
      :put="['elements', 'rows']"
      :class="[`justify-content-${verticalAlign}`]"
      @flow="handleFlow"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'
import Elements from '@/components/builder/utils/Elements/Elements'
import BackgroundMixin from '@/components/mixins/BackgroundMixin'
import ShapeDivider from '@/components/editor/components/shape-divider/ShapeDivider'
import FloatImage from '@/components/builder/utils/FloatImage'
import Animations from '@/components/mixins/Animations'
import BackgroundSlider from '@/components/builder/utils/BackgroundSlider'

export default {
  name: 'FlexWrapperCol',
  components: {BackgroundSlider, FloatImage, ShapeDivider, Elements},
  mixins: [ResolutionMixin, BorderMixin, BackgroundMixin, Animations],
  props: {
    section: {
      type: Object,
      required: true
    },
    col: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    customBackOptions: {
      type: [Object, Boolean],
      default: () => false
    }
  },
  data () {
    return {
      isHover: false
    }
  },
  computed: {
    colWidth () {
      const width = this.col.width[this.resolutioner]
      if (width === null) return this.col.width.lg
      return width
    },
    background () {
      if (!this.isHover) {
        return this.col.customize.style
      }

      return _.get(this.col, ['hover'], null) || this.col.customize.style
    },
    floatImage () {
      return _.get(this.col.customize.style, 'floatImage', {enabled: false})
    },
    shadow () {
      const shadow = this.isHover ? _.get(this.col, ['hover', 'shadowOptions'], this.col.customize.style.shadowOptions) : this.col.customize.style.shadowOptions
      const color = this.isHover ? _.get(this.col, ['hover', 'shadowColor'], this.col.customize.style.shadowColor || '#EEEEEE') : this.col.customize.style.shadowColor || '#EEEEEE'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    verticalAlign () {
      const align = _.get(this.col, ['verticalAlign', this.resolutioner], null)
      if (align === null) return _.get(this.col, ['verticalAlign', 'lg'], null)
      return align
    },
    widthFixedEnabled () {
      return this.col.fixedWidthEnabled[this.resolutioner]
    },
    heightFixedEnabled () {
      return this.col.fixedHeightEnabled[this.resolutioner]
    },
    height () {
      const _height = _.get(this.col, ['fixedHeightV2', this.resolutioner, 'value'], _.get(this.col, ['fixedHeight', this.resolutioner], 50))
      const _heightType = _.get(this.col, ['fixedHeightV2', this.resolutioner, 'type'], 'vh')

      return {
        height: this.heightFixedEnabled ? `${_height}${_heightType}` : ''
      }
    },
    width () {
      return {
        width: this.widthFixedEnabled ? `${this.col.fixedWidth[this.resolutioner]}px` : `${this.colWidth}%`
      }
    },
    highlight () {
      return {
        boxShadow: this.col.highlight ? '0 0 0 4px #7cacfa' : null
      }
    }
  },
  created () {
    if (!this.col.sortIndex) {
      this.$set(this.col, 'sortIndex', {
        lg: this.index,
        md: this.index,
        sm: this.index
      })
    }
  },
  methods: {
    handleFlow () {
      this.$store.commit('editor/ADD_ELEMENT_IN_STACK', {
        name: this.col.name,
        $el: this.$el,
        uid: this.section.uid,
        onClick: () => {
          this.$store.commit('editor/CLEAR_ELEMENTS_IN_STACK')
          this.$store.commit('editor/ADD_ELEMENT_IN_STACK', {
            name: this.col.name,
            $el: this.$el,
            uid: this.section.uid,
            onClick: () => {
            }
          })
          this.$emit('flow')
          this.$store.dispatch('editor/sectionSettings', {
            section: this.section,
            openedItem: 'flex-column-mod',
            options: {
              subjectIndex: this.index,
              title: this.col.name,
              back: () => {
                this.$store.dispatch('editor/sectionSettings', this.customBackOptions || {
                  section: this.section,
                  openedItem: 'flex-mod'
                })
              }
            }
          })
        }
      })

      this.$emit('flow')
    }
  }
}
</script>

<style>
.flex-col-placeholder .empty-row-drag {
  height: 100% !important;
}
</style>