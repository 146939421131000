import {Plugin} from 'tiptap'
import {Link} from 'tiptap-extensions'

const addhttp = (url) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = 'https://' + url
  }
  return url
}

const getUrl = (href) => {
  if (href.indexOf('.') !== -1) {
    return addhttp(href)
  }

  return href
}

export default class CustomLink extends Link {
  get defaultOptions () {
    return {
      levels: ['id', 'href', 'target']
    }
  }


  get schema () {
    return {
      attrs: {
        href: {
          default: null
        },
        target: {
          default: '_self'
        },
        dataVueHref: {
          default: null
        },
        styleeffect: {
          default: null
        },
        fromGlobalStyle: {
          default: null
        },
        id: {
          default: null
        }
      },
      parseDOM: [
        {
          tag: 'a[href]',
          getAttrs: dom => {
            return ({
              dataVueHref: dom.getAttribute('dataVueHref'),
              target: '_blank',
              href: getUrl(dom.getAttribute('href')),
              styleeffect: dom.getAttribute('styleeffect'),
              fromGlobalStyle: dom.getAttribute('fromGlobalStyle'),
              id: dom.getAttribute('id')
            })
          }
        }
      ],
      toDOM: node => {
        return ['a', {
          ...node.attrs,
          id: node.attrs.id,
          dataVueHref: node.attrs.dataVueHref,
          styleeffect: node.attrs.styleeffect,
          fromGlobalStyle: node.attrs.fromGlobalStyle,
          href: getUrl(node.attrs.href)
        }, 0]
      }
    }
  }

  get plugins () {
    if (!this.options.openOnClick) {
      return []
    }

    return [new Plugin({
      props: {
        handleClick: () => {
          return false
        }
      }
    })]
  }
}
