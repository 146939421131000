<template>
  <div>
    <SidbearSettingsGroup
      title="Card Settings"
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>
      
      <b-form-group>
        <b-checkbox
          v-model="section.options.content.tableOfContents.sticky"
          switch
        >
          Sticky on Scroll
        </b-checkbox>
      </b-form-group>
      
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Overflow</label>

          <sid-select
            :sid="`.--${section.sid}--card.toc-card`"
            rule-name="overflow"
            class="w-[118px]"
            :options="[{label: 'Hidden', value: 'hidden'}, {label: 'Visible', value: 'visible'}]"
          />
        </div>
      </b-form-group>
      
      <sid-background
        :sid="`.--${section.sid}--card.toc-card`"
      />
        
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--card.toc-card`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--card.toc-card`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--card.toc-card`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          label="Radius"
          :sid="`.--${section.sid}--card.toc-card`"
          rule-name="border"
          :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
          :angles="[
            {
              text: '',
              value: 'top-left-radius',
              cssVar: '--border-top-left-radius'
            },
            {
              text: '',
              value: 'top-right-radius',
              cssVar: '--border-top-right-radius'
            },
            {
              text: '',
              value: 'bottom-left-radius',
              cssVar: '--border-bottom-left-radius'
            },
            {
              text: '',
              value: 'bottom-right-radius',
              cssVar: '--border-bottom-right-radius'
            }
          ]"
        />
      </b-form-group>

      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--card.toc-card`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--card.toc-card`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <b-form-group
        label="Shadow Color"
        class="mb-0"
      >
        <sid-box-shadow
          :sid="`.--${section.sid}--card.toc-card`"
          rule-name="box-shadow"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Card Header"
    >
      <b-form-group
        label="Title"
      >
        <b-input v-model="section.options.content.tableOfContents.title" />
      </b-form-group>

      <sid-font
        :sid="`.--${section.sid}--card.toc-card .toc-title`"
      />
      <b-form-group>
        <sid-range-slider
          label="Text Size"
          :sid="`.--${section.sid}--card.toc-card .toc-card--header`"
          rule-name="font-size"
        />
      </b-form-group>

      <b-form-group>
        <sid-align
          :sid="`.--${section.sid}--card.toc-card .toc-card--header`"
          rule-name="text-align"
          label="Text Alignment"
        />
      </b-form-group>
  
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>
          <sid-color
            :sid="`.--${section.sid}--card.toc-card .toc-card--header`"
            rule-name="color"
          />
        </div>
      </b-form-group>
      
      
      <b-form-group>
        <b-checkbox
          v-model="section.options.content.tableOfContents.toggler"
          switch
        >
          Show Toggle
        </b-checkbox>
      </b-form-group>

      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--card.toc-card .toc-card--header`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--card.toc-card .toc-card--header`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-background
        :sid="`.--${section.sid}--card.toc-card .toc-card--header`"
      />
        
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--card.toc-card .toc-card--header`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--card.toc-card .toc-card--header`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--card.toc-card .toc-card--header`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          label="Radius"
          :sid="`.--${section.sid}--card.toc-card .toc-card--header`"
          rule-name="border"
          :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
          :angles="[
            {
              text: '',
              value: 'top-left-radius',
              cssVar: '--border-top-left-radius'
            },
            {
              text: '',
              value: 'top-right-radius',
              cssVar: '--border-top-right-radius'
            },
            {
              text: '',
              value: 'bottom-left-radius',
              cssVar: '--border-bottom-left-radius'
            },
            {
              text: '',
              value: 'bottom-right-radius',
              cssVar: '--border-bottom-right-radius'
            }
          ]"
        />
      </b-form-group>

      <b-form-group
        label="Shadow Color"
        class="mb-0"
      >
        <sid-box-shadow
          :sid="`.--${section.sid}--card.toc-card .toc-card--header`"
          rule-name="box-shadow"
        />
      </b-form-group>
    </SidbearSettingsGroup>


    <SidbearSettingsGroup
      title="Card Body"
    >
      <b-form-group
        label="Anchors"
      >
        <div class="sid-select">
          <div class="vs-tags">
            <v-select
              v-model="section.options.content.tableOfContents.anchors"
              :options="[
                { id: 'h1', name: 'H1' },
                { id: 'h2', name: 'H2' },
                { id: 'h3', name: 'H3' },
                { id: 'h4', name: 'H4' },
                { id: 'h5', name: 'H5' },
                { id: 'h6', name: 'H6' }
              ]"
              append-to-body
              multiple
              class="custom-select tag-select-popped"
              label="name"
              :reduce="val => val.id"
            />
          </div>
        </div>
      </b-form-group>
     
      <b-form-group
        label="Exclude Anchors by ID"
      >
        <div class="sid-select">
          <div class="vs-tags">
            <v-select
              v-model="section.options.content.tableOfContents.excludes"
              :options="hIds"
              append-to-body
              multiple
              class="custom-select tag-select-popped"
            />
          </div>
        </div>
      </b-form-group>
     
      <b-form-group>
        <div 
          class="flex justify-between items-center select-wrapper"
        >
          <label 
            class=" pb-0"
          >
            Marker type
          </label>
          <v-select
            v-model="section.options.content.tableOfContents.marker"
            :options="[
              { id: 'number', name: 'Numbers' },
              { id: 'bullet', name: 'Bullets' },
              { id: 'icon', name: 'Icon' },
            ]"
            :reduce="item => item.id"
            label="name"
            :searchable="false"
            class="!w-[100px] cusstom-select"
          />
        </div>
      </b-form-group>

      <template v-if="section.options.content.tableOfContents.marker == 'icon'">
        <b-form-group>
          <icon-select v-model="section.options.content.tableOfContents.iconClass" />
        </b-form-group>

        <b-form-group>
          <sid-range-slider
            label="Icon Size"
            :sid="`.--${section.sid}--card.toc-card .toc-h-item-icon`"
            rule-name="font-size"
          />
        </b-form-group>
  
        <b-form-group>
          <div class="flex items-center justify-between">
            <label class="pb-0">Icon Color</label>
            <sid-color
              :sid="`.--${section.sid}--card.toc-card .toc-h-item-icon`"
              rule-name="color"
            />
          </div>
        </b-form-group>
      </template>


      <hr>
  
      <sid-font
        :sid="`.--${section.sid}--card.toc-card .toc-h-item`"
      />
      <b-form-group>
        <sid-range-slider
          label="Text Size"
          :sid="`.--${section.sid}--card.toc-card .toc-h-item`"
          rule-name="font-size"
        />
      </b-form-group>
      
      <b-form-group>
        <sid-align
          :sid="`.--${section.sid}--card.toc-card .toc-card--body`"
          rule-name="text-align"
          label="Text Alignment"
        />
      </b-form-group>

      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>
              <sid-color
                :sid="`.--${section.sid}--card.toc-card .toc-h-item`"
                rule-name="color"
              />
            </div>
          </b-form-group>
        </template>
        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>
              <sid-color
                :sid="`.--${section.sid}--card.toc-card .toc-h-item`"
                rule-name="color"
                presudo=":hover"
                :pseudo-index="1"
              />
            </div>
          </b-form-group>
        </template>
      </rule-tabs>
  
    
      
      <hr>

      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--card.toc-card .toc-card--body`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--card.toc-card .toc-card--body`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-background
        :sid="`.--${section.sid}--card.toc-card .toc-card--body`"
      />
        
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--card.toc-card .toc-card--body`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--card.toc-card .toc-card--body`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--card.toc-card .toc-card--body`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          label="Radius"
          :sid="`.--${section.sid}--card.toc-card .toc-card--body`"
          rule-name="border"
          :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
          :angles="[
            {
              text: '',
              value: 'top-left-radius',
              cssVar: '--border-top-left-radius'
            },
            {
              text: '',
              value: 'top-right-radius',
              cssVar: '--border-top-right-radius'
            },
            {
              text: '',
              value: 'bottom-left-radius',
              cssVar: '--border-bottom-left-radius'
            },
            {
              text: '',
              value: 'bottom-right-radius',
              cssVar: '--border-bottom-right-radius'
            }
          ]"
        />
      </b-form-group>

      <b-form-group
        label="Shadow Color"
        class="mb-0"
      >
        <sid-box-shadow
          :sid="`.--${section.sid}--card.toc-card .toc-card--body`"
          rule-name="box-shadow"
        />
      </b-form-group>
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidBackground from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont'
import IconSelect from '@/components/editor/components/IconSelect'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import { findSections } from '../../../utils/helpers'

export default {
  name: 'TableOfContents',
  components: {
    SidAlign,
    RuleTabs,
    SidFont,
    IconSelect,
    SidLinkedGroup,
    SidColor,
    SidBoxShadow,
    SidRangeSlider,
    SidSelect,
    SidBackground, 
    SidbearSettingsGroup
  },
  mixins: [ModMixin],
  data () {
    return {
    }
  },
  computed: {
    hIds () {
      let cd = this.page.content_draft
      if(typeof cd === 'string'){
        try {
          cd = JSON.parse(cd)
        } catch (error) {
          console.error('Error on parsing content draft', error)
        }
      }
      const section = findSections(cd, ['ElHeadlineV2','ElSubheadlineV2'])
      const ids = []
      section.forEach(s => {
        const id = this.get(s,'options.content.description.id')
        if(id) ids.push(id)
      })
      return ids
    }
  },
  methods: {
  
  }
}
</script>