<template>
  <div>
    <slot />
  </div>
</template>

<script>
import Translate3DAnimationMixin from '@/components/builder/utils/transform-animation/mixins/Translate3DAnimationMixin'

export default {
  name: 'MotionWrapper',
  mixins: [Translate3DAnimationMixin],
  props: {
    // motions: {
    //   type: Object,
    //   required: true
    // }
  },
  data () {
    return {
      lastTime: null,
      motions: [
        {
          name: 'scale',
          'trigger': {
            'type': 'viewport', // viewport, onload
            'offset': 0 // px
          },
          'motion': {
            'type': 'scroll', // scroll, infinite, once
            'values': {
              'start': 0, // px, %, vh
              'end': 1 // px, %, vh
            },
            'speed': 500, // ms,
            'delay': 0 // ms, delay after trigger
          }
        },
        {
          name: 'translateX',
          'trigger': {
            'type': 'viewport', // viewport, onload
            'offset': 0 // px
          },
          'motion': {
            'type': 'repeat', // scroll, infinite, once
            'values': {
              'start': 0, // px, %, vh
              'end': -1320 // px, %, vh
            },
            'speed': 500, // ms,
            'delay': 0 // ms, delay after trigger
          }
        },
        {
          name: 'translateY',
          'trigger': {
            'type': 'viewport', // viewport, onload
            'offset': 0 // px
          },
          'motion': {
            'type': 'infinite', // scroll, infinite, once
            'values': {
              'start': 0, // px, %, vh
              'end': 60 // px, %, vh
            },
            'speed': 500, // ms,
            'delay': 0 // ms, delay after trigger
          }
        }
      ],
      values: {}
    }
  },
  mounted () {
    this.motions.forEach(motion => {
      this.values[motion.name] = {
        value: motion.motion.values.start,
        ...motion.motion.values,
        increase: motion.motion.values.start < motion.motion.values.end
      }
    })

    this.calcPositions()
  },
  methods: {
    calcPositions (time) {
      if (this.lastTime != null) {
        const delta = time - this.lastTime
        this.motions.forEach(motion => this[motion.name] ? this[motion.name](motion, delta) : null)
      }

      this.lastTime = time
      requestAnimationFrame(this.calcPositions)
    }
  }
}
</script>