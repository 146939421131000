<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    hide-right
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings('pagination')"
  >
    <div
      class="blog-post-grid-section"
    >
      <div>
        <div
          v-if="loading"
          class="text-center"
        >
          <b-spinner variant="primary" />
        </div>

        <template v-else-if="getBlogPosts.length > 0">
          <div
            class="flex flex-wrap container gap-4 my-4 padding-20"
            :class="`--${section.sid}--container`"
          >
            <div
              v-for="(post, index) in getBlogPosts"
              :key="`${post.id}_${index}`"
              class="blog-feed-card overflow-hidden"
              :class="`--${section.sid}--card`"
            >
              <img
                :src="post.cover"
                :alt="post.title"
                class="object-top object-cover background-p w-full mb-3"
              >

              <div class="padding-20 hide-higlghter-toolbar">
                <h3
                  class="text-[24px]"
                  :class="`--${section.sid}--title`"
                  v-text="post.title"
                />

                <div :class="`--${section.sid}--date`">
                  {{ post.created_at | moment("from", "now") }}
                </div>

                <p
                  :style="section.options.content.description"
                  class="line-clamp"
                  :class="`--${section.sid}--description`"
                >
                  {{ post.description | strippedContent }}
                </p>

                <el-button-v2
                  :section="section.options.content.buttonSubject"
                />
              </div>
            </div>
          </div>

          <div
            v-if="blog.data.total > perPage"
            class="d-flex justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="blog.data.total"
              aria-controls="my-table"
            >
              <template #page="{ page }">
                <div :style="section.options.content.pagination">
                  {{ page }}
                </div>
              </template>
            </b-pagination>
          </div>
        </template>

        <div
          v-else
          class="d-flex justify-content-center align-items-center min-vh-50"
        >
          <div class="text-center">
            <i class="fa fa-compass fa-7x mb-3" />
            <h4 class="h2 font-weight-bold">
              Blog is empty
            </h4>
          </div>
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import {mapState} from 'vuex'

export default {
  name: 'ActionItem2',

  components: {Highliter},

  filters: {
    strippedContent: function (string) {
      return string.replace(/<\/?[^>]+>/ig, ' ')
    }
  },

  mixins: [SectionMixin],

  data () {
    return {
      loading: false,
      blog:{},
      currentPage: 1
    }
  },
  computed: {
    byCategory () {
      return this.section.options.content.pagination.byCategory || ''
    },
    perPage () {
      return this.section.options.content.pagination.perPage || 1
    },
    ...mapState('projects', {
      project: state => state.project
    }),
    getBlogPosts () {
      const posts = this.blog?.data?.data||[]
      if (posts.length === 0) return []
      return posts
    }
  },


  watch: {
    currentPage (val) {
      this.fetchBlog(val)
    },
    perPage () {
      this.fetchBlog()
    },
    byCategory () {
      this.fetchBlog()
    }
  },

  created () {
    this.fetchBlog()

    VEvent.listen('es-blog-updated', post => this.blog.data.data.unshift(post))
  },

  methods: {
    async fetchBlog (page = 1) {
      this.loading = true
      await axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/posts?page=${page}&perPage=${this.perPage}&categoryId=${this.byCategory}`)
        .then(({data}) => {
          this.blog = data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.blog-feed-card {
  width: 32%;
}

.resolution-md {
  .blog-feed-card {
    width: 100%;
  }
}

.resolution-sm {
  .blog-feed-card {
    width: 100%;
  }
}
</style>