<template>
  <div v-if="item">
    <div class="border rounded w-100">
      <div class="d-flex align-items-center w-100">
        <div class="d-flex align-items-center justify-content-center h-[87px] w-[60px] border-right">
          <div
            :style="item.active ? (item.is_status_active ? 'background:#B5DF3D' : 'background:#dc3545') : 'background:#D3DCF2'"
            class="w-[9px] h-[9px] rounded-circle"
          />
        </div>

        <div class="d-flex align-items-center w-100 px-[30px]">
          <b-img
            :src="item.icon"
            class="mr-[13px] rounded-[4px] max-h-[40px] max-w-[40px] object-cover"
            height="40px"
            width="40px"
          />
          <span class="font-bold text-[18px] mr-auto">{{ item.title }}</span>

          <button
            v-if="!item.active"
            class="border border-[#E3E3E3] !font-[600] text-[#393939] rounded h-[40px] px-[22px] d-flex align-items-center justify-content-center text-[15px] hover:bg-gray-100"
            @click="connect"
          >
            Connect
          </button>
          <div
            v-else
            class="d-flex"
          >
            <button
              :disabled="deletingLoading"
              class="border mr-[13px] border-[#E3E3E3] !font-[600] text-[#393939] rounded h-[40px] px-[22px] d-flex align-items-center justify-content-center text-[15px] hover:bg-gray-100"
              @click="remove"
            >
              Delete
              <b-spinner
                v-if="deletingLoading"
                class="ml-2"
                small
                variant="secondary"
              />
            </button>
            <button
              v-if="!hideStatusToggler"
              :disabled="statusToggleLoading"
              class="border border-[#E3E3E3] !font-[600] text-[#393939] rounded h-[40px] px-[22px] d-flex align-items-center justify-content-center text-[15px] hover:bg-gray-100"
              @click="toggleStatus"
            >
              {{ item.is_status_active ? 'Disconnect' : 'Reconnect' }}
              <b-spinner
                v-if="statusToggleLoading"
                class="ml-2"
                small
                variant="secondary"
              />
            </button>
            <button
              v-if="(item.data || []).length && item.data[0].type !== 'hidden'"
              class="border border-[#E3E3E3] !font-[600] text-[#393939] rounded h-[40px] px-[22px] d-flex align-items-center justify-content-center text-[15px] hover:bg-gray-100 ml-[13px]"
              @click="() => details = !details"
            >
              Details

              <svg
                :class="{'rotate-180&': details}"
                class="transform ml-2"
                fill="none"
                height="7"
                viewBox="0 0 11 7"
                width="11"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5.25 5.10811L9.5 1"
                  stroke="#4E4E4F"
                  stroke-width="1.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="details"
        class="d-block w-100 border-top px-[51px] py-[48px] "
      >
        <div class="text-right">
          <b-link
            class="text-primary underline text-[14px]"
            @click="connect"
          >
            Update details
          </b-link>
        </div>
        <b-row
          v-for="(dataItem, index) in item.data"
          :key="index"
          class="text-[#383838] text-[16px]"
          :class="{'mb-[15px]' : index + 1 != item.data.length}"
        >
          <b-col
            cols="3"
          >
            <div class="flex items-center">
              <b-btn-group class="resolution-toggle  mr-2">
                <b-btn
                  v-b-tooltip.hover
                  class="!flex items-center"
                  :title="copiedIndex == index ? 'Copied!':'Copy Value'"
                  variant="white"
                  @click="copy(index)"
                >
                  <i :class="[copiedIndex == index ? 'fas fa-check' : 'copy-icon']" />
                </b-btn>
                <b-btn
                  v-b-tooltip.hover
                  class="!flex items-center"
                  :title="isVisible(index) ? 'Hide' :'Show'"
                  variant="white"
                  @click="toggleVisiblity(index)"
                >
                  <i
                    class="fa"
                    :class="[isVisible(index) ? 'fa-eye' :'fa-eye-slash']"
                    aria-hidden="true"
                  />
                </b-btn>
              </b-btn-group>

              <span class="ml-2">{{ dataItem.label }}</span>
            </div>
          </b-col>
          <b-col
            cols="9"
            class="font-[600] !flex items-center"
          >
            <div class="w-full">
              {{ isVisible(index) ? dataItem.value : '******************' }}
            </div>
          </b-col>
        </b-row>
      </div>

      <IntegrationModal
        ref="$modal"
        :item="item"
        @submit="val => $emit('submit',val)"
      />
    </div>
  </div>
</template>

<script>
import IntegrationModal from '@/views/dashboard/integration/components/IntegrationModal'
export default {
  name: 'IntegrationItem',
  components: {IntegrationModal},
  props: {
    item: {
      type: Object,
      required: true
    },
    hideStatusToggler: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visibleIndexes: [],
      copiedIndex: -1,
      details: false,
      deletingLoading: false,
      statusToggleLoading: false
    }
  },
  methods: {
    connect () {
      this.$refs.$modal.modal = true
    },
    setStatusToggleLoading (val) {
      this.statusToggleLoading = val
    },
    setDeletingLoading (val) {
      this.deletingLoading = val
    },
    setDetails (val) {
      this.details = val
    },
    toggleStatus () {
      const {setStatusToggleLoading} = this
      this.$emit('toggle-status', {setLoading: setStatusToggleLoading})
    },
    remove () {
      const {setDeletingLoading, setDetails} = this
      this.$emit('remove', {setLoading: setDeletingLoading, setDetails})
    },
    isVisible (index) {
      return this.visibleIndexes.includes(index)
    },
    toggleVisiblity (index) {
      if (this.isVisible(index)) {
        const index = this.visibleIndexes.findIndex(i => i == index)
        this.visibleIndexes.splice(index, 1)
      } else {
        this.visibleIndexes.push(index)
      }
    },
    copy (index) {
      this.copiedIndex = index
      navigator.clipboard.writeText(this.item.data[index].value)
      setTimeout(() => {
        this.copiedIndex = -1
      }, 800)
    }
  }
}
</script>