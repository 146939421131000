import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Flex Wrapper'
export const group = ''
export const standalone = true
export const allowRow = true
export const defaultOptions = {}

export const col = {
  display: true,
  name: 'Flex Column',
  elements: [],
  'sortIndex': {
    'lg': 0,
    'md': 0,
    'sm': 0
  }
}

export const previews = {
  left: null,
  right: null,
  main: null
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElFlexWrapperV2', options)

    this.name = name
    this.group = group
    this.css = [['.--aqmspkw--tj3WAB5M9p--flex {max-width:80vw;}'],['.--aqmspkw--tj3WAB5M9p--flex {max-width:100%;}'],[]]
    this.sid = 'aqmspkw--tj3WAB5M9p'
    this.sids = ['aqmspkw--tj3WAB5M9p']

    this.defaultOptions = {
      content: {
        display: true,
        cols: [
          col,
          col
        ]
      }
    }
  }
}
