import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Navbar'
export const group = 'navbar'
export const defaultOptions = {
  'customize': {
    'style': {
      'navbarScroller': {
        'type': 'navbar-scroller-customs',
        'scrollType': null
      }
    },
    'display': true
  },
  'content': {
    'logo': {
      'type': 'logo-mod',
      'display': true,
      'fontFamily': null,
      'fontWeight': 'bold',
      'fontSize': '24px',
      'color': '#0C226C',
      'text': 'AppStarter'
    },
    'menuItems': {
      'type': 'menu-items-mod-v2',
      'display': true
    },
    'subject': {
      'uid': 'WKtPEOYr2',
      'sid': 'l68huqyj---7SmED-QpC',
      'name': 'Flex Wrapper',
      'hideFromSections': false,
      'component': 'ElFlexWrapperV2',
      'group': 'navbar',
      'layout': null,
      'specialCols': {
        'navigation': {
          'special': true,
          'sid': 'l68huqyj--Al4hl1mH22',
          'placement': 'bottom',
          'hamburgerIcon_open': 'fa fa-bars',
          'hamburgerIcon_close': 'fa fa-times',
          'hamburgerIcon_color': '#656565',
          'showOn': 'lg',
          'name': 'Nav Column',
          'elements': [
            {
              'display': true,
              'name': 'Flex Wrapper',
              'hideFromSections': false,
              'component': 'ElFlexWrapperV2',
              'group': '',
              'layout': null,
              'options': {
                'content': {
                  'display': true,
                  'cols': [
                    {
                      'display': true,
                      'name': 'Flex Column',
                      'elements': [
                        {
                          'uid': 'BlbxyqYc0',
                          'display': true,
                          'name': 'Menu Links',
                          'sid': 'l68huqyj--J2T4DVpMxk',
                          'hideFromSections': false,
                          'component': 'ElMenuLinksV4',
                          'group': 'Navigation',
                          'layout': null,
                          'options': {
                            'content': {
                              'menu': {
                                'display': true,
                                'type': 'menu-links-mod-v4',
                                'links': [
                                  {
                                    'id': '2lgcfYKlUS',
                                    'href': '#',
                                    'text': 'About',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'kE_0CEF0hZ',
                                    'href': '#',
                                    'text': 'Work with Me',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'wJGQ-hWnL1',
                                    'href': '#',
                                    'text': 'Resources',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'A_dvOV_wIV',
                                    'href': '#',
                                    'text': 'Blog',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'QlHAT-G7Lw',
                                    'href': '#',
                                    'text': 'Contact',
                                    'customLink': false,
                                    'blank': false,
                                    'children': []
                                  }
                                ],
                                'hoverAnimation': 'hover-float'
                              }
                            }
                          },
                          'editor': 'project',
                          'temp_id': 'KL8FXUhHuN'
                        }
                      ],
                      'sortIndex': {
                        'lg': 0,
                        'md': 0,
                        'sm': 0
                      },
                      'uid': 'yp6xVlz7ba',
                      'sid': 'l68huqyj--_Q7lazOh8b',
                      'highlight': false,
                      'animations': {
                        'animation': null,
                        'once': true,
                        'offset': 200,
                        'delay': 50
                      },
                      '_uid': 'DeKlSiLMM9'
                    },
                    {
                      'display': true,
                      'name': 'Flex Column',
                      'elements': [
                        {
                          'uid': 'oxkgJFBMC',
                          'display': true,
                          'name': 'Button',
                          'sid': 'global--iNOcAD8hId',
                          'hideFromSections': false,
                          'component': 'ElButtonV2',
                          'group': 'Media',
                          'ignoreGroup': false,
                          'layout': null,
                          'options': {
                            'content': {
                              'button': {
                                'pressetID': 0,
                                'display': true,
                                'type': 'button-mod-v2',
                                'text': 'SIGN UP FOR FREE',
                                'buttonType': 'button',
                                'icon': {
                                  'lottie': {
                                    'jsonPath': '',
                                    'loop': true,
                                    'autoplay': true
                                  }
                                },
                                'hoverAnimation': {},
                                'animations': {
                                  'animation': null,
                                  'once': true,
                                  'offset': 200,
                                  'delay': 50
                                }
                              }
                            },
                            'customize': null
                          },
                          'editor': 'project'
                        }
                      ],
                      'sortIndex': {
                        'lg': 1,
                        'md': 1,
                        'sm': 1
                      },
                      'uid': 'H7Zrn6bgia',
                      'sid': 'l68huqyj--9SEVpoQVax',
                      'highlight': false,
                      'animations': {
                        'animation': null,
                        'once': true,
                        'offset': 200,
                        'delay': 50
                      },
                      '_uid': 'b3cqHcc4R_'
                    }
                  ],
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              },
              'uid': '68Twket09',
              'sid': 'l68huqyj--5-e9oF07xU',
              'temp_id': 'kb5eyU6NYu'
            }
          ],
          'highlight': false,
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          },
          'sortIndex': {
            'lg': 1,
            'md': 1,
            'sm': 1
          },
          'uid': '_DELq2Pus',
          'navbarBackground': {
            'background': '',
            'gradient': {
              'position': '',
              'color': ''
            }
          },
          '_uid': 'JU4VAAlCo6'
        }
      },
      'options': {
        'content': {
          'display': true,
          'cols': [
            {
              'display': true,
              'name': 'Flex Column',
              'elements': [
                {
                  'uid': 'SDGYju5sU',
                  'display': true,
                  'name': 'Subheadline',
                  'sid': 'global--jji-M1KpA',
                  'hideFromSections': false,
                  'component': 'ElSubheadlineV2',
                  'group': 'Text',
                  'ignoreGroup': false,
                  'layout': null,
                  'options': {
                    'content': {
                      'description': {
                        'type': 'tiptap-mod-v2',
                        'display': true,
                        'tag': 'h4',
                        'resolutionStyle': {
                          'sm': {},
                          'md': {},
                          'lg': {}
                        },
                        'text': '<p>OpenSourceApp</p>',
                        'animations': {
                          'animation': null,
                          'once': true,
                          'offset': 200,
                          'delay': 50
                        }
                      }
                    },
                    'customize': null
                  },
                  'editor': 'project'
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              },
              'sortIndex': {
                'lg': 0,
                'md': 0,
                'sm': 0
              },
              'sid': 'l68huqyj--j0qQ_89zPB',
              'uid': 'A9jNNE0OWB8',
              'highlight': false,
              '_uid': 'tIJsHw--pu'
            },
            {
              'special': true,
              'key': 'navigation',
              'mod': 'navigation-flex-col-mod-v2',
              'name': 'Nav Column',
              'sid': 'Uz96GLEcGy',
              'sortIndex': {
                'lg': 1,
                'md': 1,
                'sm': 1
              },
              'highlight': false
            }
          ],
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          }
        }
      },
      'temp_id': '23MJlRTY6G'
    }
  }
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('NavbarV3', options)

    this.name = name
    this.group = group
    this.sid = ['global']
    this.sids = [
      'global',
      'l68huqyj---7SmED-QpC',
      'l68huqyj--j0qQ_89zPB',
      'global--jji-M1KpA',
      'l68huqyj--Al4hl1mH22',
      'l68huqyj--5-e9oF07xU',
      'l68huqyj--_Q7lazOh8b',
      'l68huqyj--J2T4DVpMxk',
      'l68huqyj--9SEVpoQVax',
      'global--iNOcAD8hId'
    ]

    this.css = [
      [
        '.--l68huqyj---7SmED-QpC--container { padding-right: 0px; }',
        '.--l68huqyj--j0qQ_89zPB--flex-col-padding { padding: 0px; }',
        '.--l68huqyj--Al4hl1mH22--flex-col-padding { padding: 0px; }',
        '.--l68huqyj--j0qQ_89zPB--flex-col-el { width: 20%; background-color: rgba(255, 255, 255, 0); }',
        '.--l68huqyj--Al4hl1mH22--flex-col-el { width: 100%; --width: 100%; }',
        '.--l68huqyj--Al4hl1mH22--flex-col > div { align-items: center; justify-content: center; }',
        '.--l68huqyj--_Q7lazOh8b--flex { width: 100%; --width: 100%; }',
        '.--l68huqyj--_Q7lazOh8b--flex-col-el { width: 100%; --width: 100%; }',
        '.--l68huqyj--9SEVpoQVax--flex-col-el { width: 20%; --width: 20%; }',
        '.--l68huqyj--5-e9oF07xU--flex { width: 100%; --width: 100%; align-items: center; padding: 0px; }',
        '.--l68huqyj---7SmED-QpC--flex { align-items: center; max-width: 100%; --width: 100%; }',
        '.--l68huqyj--J2T4DVpMxk--wrapper { padding: 10px; justify-content: end; gap: 4px; align-items: end; font-family: Nunito; }',
        '.--l68huqyj--J2T4DVpMxk--link { font-size: 15px; color: rgb(255, 255, 255); padding: 15px; background-color: rgba(34, 33, 47, 0.99); border-bottom-width: 1px; border-radius: 5px; border-color: rgb(51, 58, 69); }',
        '.--l68huqyj--J2T4DVpMxk--link:hover { color: rgb(191, 83, 242); border-bottom-width: 1px; }',
        '.--l68huqyj--_Q7lazOh8b--flex-col-padding { padding: 0px; }',
        '.--l68huqyj--9SEVpoQVax--flex-col-padding { padding: 0px; }',
        '.--l68huqyj---7SmED-QpC--bg { background-color: rgb(24, 24, 35); }',
        '.--l68huqyj---7SmED-QpC--flex.flex-wrapper { padding-bottom: 2px; padding-top: 2px; }',
        '.--global--iNOcAD8hId--1 { margin: 10px 7px; padding: 13px 20px; background-color: rgba(0, 0, 0, 0); border-width: 1px; border-color: rgb(155, 89, 182); font-size: 14px; font-family: Nunito; letter-spacing: 1px; }',
        '.--global--iNOcAD8hId--1 { margin: 10px 7px; padding: 13px 20px; background-color: rgba(0, 0, 0, 0); border-width: 1px; border-color: rgb(155, 89, 182); font-size: 14px; font-family: Nunito; letter-spacing: 1px; }',
        '.--global--jji-M1KpA-text { padding: 20px; }',
        '.--global--jji-M1KpA-text { padding: 20px; }',
        '.--global--jji-M1KpA-text div { color: rgb(255, 255, 255); text-align: left; }',
        '.--global--jji-M1KpA-text div { color: rgb(255, 255, 255); text-align: left; }',
        '.--global--iNOcAD8hId--container { display: inline-block; }',
        '.--global--iNOcAD8hId--container { display: inline-block; }',
        '.--l68huqyj--9SEVpoQVax--flex-col { text-align: end; justify-content: end; }'
      ],
      [
        '.--l68huqyj--_Q7lazOh8b--flex { width: 100%; --width: 100%; }',
        '.--l68huqyj--5-e9oF07xU--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--l68huqyj--9SEVpoQVax--flex-col-el { width: 100%; --width: 100%; }',
        '.--l68huqyj--J2T4DVpMxk--wrapper { flex-direction: row; justify-content: center; align-items: center; gap: 22px; }',
        '.--l68huqyj--Al4hl1mH22--flex-col-el { background-color: rgba(16, 16, 27, 0.95); }',
        '.--l68huqyj--Al4hl1mH22--flex-col-padding { padding-top: 20px; padding-bottom: 20px; }',
        '.--l68huqyj---7SmED-QpC--close-icon { color: rgb(255, 255, 255); }',
        '.--l68huqyj---7SmED-QpC--icon { font-size: 20px; translate: -32px; }',
        '.--l68huqyj---7SmED-QpC--open-icon { color: rgb(255, 255, 255); }',
        '.--l68huqyj---7SmED-QpC--flex.flex-wrapper { justify-content: start; }',
        '.--global--jji-M1KpA-text div { font-size: 17px; }',
        '.--global--jji-M1KpA-text div { font-size: 17px; }',
        '.--l68huqyj--j0qQ_89zPB--flex-col-el { width: 65%; --width: 65%; }',
        '.--l68huqyj--9SEVpoQVax--flex-col { text-align: center; justify-content: center; }'
      ],
      [
        '.--l68huqyj--_Q7lazOh8b--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--l68huqyj--5-e9oF07xU--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--l68huqyj--j0qQ_89zPB--flex-col-el { width: 50%; --width: 50%; }',
        '.--l68huqyj--9SEVpoQVax--flex-col-el { width: 100%; --width: 100%; }',
        '.--l68huqyj--J2T4DVpMxk--wrapper { gap: 11px; flex-direction: column; }',
        '.--l68huqyj--Al4hl1mH22--flex-col-padding { padding-top: 20px; padding-bottom: 20px; }',
        '.--l68huqyj--Al4hl1mH22--flex-col-el { width: 100%; --width: 100%; }',
        '.--l68huqyj--j0qQ_89zPB--flex-col-padding { padding-left: 0px; }',
        '.--l68huqyj---7SmED-QpC--icon { translate: -15px; }',
        '.--global--jji-M1KpA-text { padding-left: 0px; }',
        '.--global--jji-M1KpA-text { padding-left: 0px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
