const state = () => ({
  topMenu: null
})

const getters = {

}

const actions = {
  async getMenu ({rootState}) {
    return await axios.get(`api/projects/${rootState.projects.project.id}/menu`)
  },
  async addMenu ({rootState}, name) {
    return await axios.post(`api/projects/${rootState.projects.project.id}/menu`, {name})
  }
}

const mutations = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
