<template>
  <div
    class="text-center"
    :style="{...padding, ...opacity}"
  >
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin]
}
</script>


{
"favicon": null,
"colors": {
"primary": "rgb(31, 59, 156)",
"accent": "#B83636",
"siteBackground": "#ffffff",
"buttons": "rgb(252, 176, 69)"
},
"font": {
"style": "Rubik",
"h1": {
"fontFamily": "Proxima Nova",
"fontSize": "23px"
},
"h2": {
"fontFamily": "Proxima Nova",
"fontSize": "23px"
},
"h3": {
"fontFamily": "Proxima Nova",
"fontSize": "16px"
},
"body": {
"fontFamily": "Proxima Nova",
"fontSize": "16px"
},
"headingStyle": "Rubik"
},
"buttons": {
"shape": "pill",
"outlined": false
},
"navbar": {
"menu_id": 115,
"block": {
"uid": "YmDJZHsxN",
"name": "Navigation 1",
"component": "Navbar1",
"group": "navbar",
"options": {
"customize": {
"layouts": null,
"style": {
"background": {
"type": "background-customs",
"background": "rgba(255, 255, 255, 0)"
},
"shadow": {
"type": "shadow-customs",
"shadow": null
},
"opacity": {
"type": "opacity-customs",
"opacity": 1
},
"navbarScroller": {
"type": "navbar-scroller-customs",
"scrollType": null
}
},
"display": true
},
"content": {
"logo": {
"type": "logo-mod",
"display": true,
"fontFamily": "Global Style",
"fontWeight": "bold",
"fontSize": "24px",
"color": "#0C226C",
"text": "AppStarter",
"image": {
"src": "https://res.cloudinary.com/estage/image/upload/v1633894730/users/user-3/Y7gMf9QsjeH4Dr0RQybSplMlW2UDX3gcJLj5b4T7.png",
"id": 3486,
"logoSize": "40px"
}
},
"menuItems": {
"type": "menu-items-mod",
"display": true,
"color": "#0C226C",
"uppercase": false,
"align": ""
},
"button": {
"type": "button-mod",
"display": true,
"hover": false,
"text": "Book Now",
"resolutionStyle": {
"lg": {
"color": "#ffffff",
"backgroundColor":"colored",
"size": "md",
"btnStyle": "filled",
"shape": null,
"padding": {
"top": 0,
"bottom": 0,
"x": 0
},
"align": "start"
},
"sm": {
"color": "#ffffff",
"backgroundColor": "colored",
"size": "sm",
"btnStyle": "outlined",
"shape": null,
"padding": {
"top": 0,
"bottom": 0,
"x": 0
},
"align": "start"
}
},
"animations": {
"animation": null,
"once": true,
"offset": 200,
"delay": 50
},
"iconColor": "",
"page": {
"id": null,
"title": "Not selected"
}
}
}
}
}
},
"footer": {
"menu_id": 1247,
"block": {
"uid": "G8wTpUp2z",
"name": "Footer 3",
"component": "Blank",
"group": "Footer",
"options": {
"customize": {
"layouts": null,
"style": {
"background": {
"type": "background-customs",
"background": "colored"
},
"shadow": {
"type": "shadow-customs",
"shadow": null
},
"opacity": {
"type": "opacity-customs",
"opacity": 1
},
"backgroundImage": {
"type": "background-image-customs",
"src": "",
"position": "center",
"size": "cover",
"opacity": 1
},
"backgroundVideo": {
"type": "background-video-customs",
"enabled": false,
"url": "",
"overlay": {
"enabled": true,
"color": "rgba(0,0,0,1)",
"opacity": 0.5
},
"loop": true,
"ratio": 1
},
"padding": {
"type": "height-customs",
"sm": {
"top": 20,
"bottom": 20,
"x": 0
},
"lg": {
"top": "50",
"bottom": "50",
"x": 0
}
},
"marginTop": {
"type": "margin-top-customs",
"sm": 20,
"lg": 0
},
"container": {
"type": "container-customs",
"container": "container"
},
"border": {
"type": "border-customs",
"style": "none",
"width": 3,
"color": "#c0c0c0"
},
"borderRadius": {
"type": "border-radius-customs",
"radius": 0
},
"blockWidth": {
"type": "block-width-customs",
"width": "full-width"
},
"floatImage": {
"display": true,
"enabled": false,
"type": "float-image-customs",
"src": "",
"top": 0,
"x": {
"left": 0
}
}
}
},
"content": {
"items": {
"type": "section-mod",
"children": [
{
"uid": "s0G6I3wqu",
"name": "Row",
"component": "ElRow",
"group": "",
"options": {
"customize": {
"layouts": false,
"style": {
"background": {
"type": "background-customs",
"background": "rgba(225,225,225,0)"
},
"shadow": {
"type": "shadow-customs",
"shadow": null
},
"opacity": {
"type": "opacity-customs",
"opacity": 1
},
"alignRow": {
"type": "align-row-customs",
"align": "start"
},
"backgroundImage": {
"type": "background-image-customs",
"src": null,
"position": "center",
"size": "cover",
"opacity": 1
},
"padding": {
"type": "height-customs",
"sm": {
"top": 10,
"bottom": 10,
"x": 5
},
"lg": {
"top": 20,
"bottom": 20,
"x": "0"
}
},
"marginTop": {
"type": "margin-top-customs",
"sm": 10,
"lg": "20"
},
"border": {
"type": "border-customs",
"style": "none",
"width": 3,
"color": "#c0c0c0"
},
"borderRadius": {
"type": "border-radius-customs",
"radius": 0
}
},
"columnsToShow": 3,
"noGutters": false
},
"content": {
"columns": [
{
"cols": 3,
"elements": [
{
"uid": "ZS_JLfsLV",
"name": "Image",
"component": "ElImage",
"group": "Media",
"options": {
"customize": {
"layouts": null,
"style": {
"background": null,
"shadow": null,
"opacity": {
"type": "opacity-customs",
"opacity": 1
},
"padding": {
"type": "height-customs",
"sm": {
"top": 40,
"bottom": 18
},
"lg": {
"top": "0",
"bottom": "0"
}
}
}
},
"content": {
"image": {
"display": true,
"type": "image-mod",
"size": "contain",
"position": "left",
"placeholder": require('@builder/assets/images/placeholder.png'),
"src": "https://res.cloudinary.com/estage/image/upload/v1633894730/users/user-3/fHQQ8RC0Sr8368o04EiK2V2eYK3KlNRI6qlJEZRa.png",
"ratio": {
"type": "px",
"sm": {
"h": "40",
"w": 50
},
"lg": {
"h": "40",
"w": "47"
}
},
"animations": {
"animation": null,
"once": true,
"offset": 200,
"delay": 50
},
"align": {
"sm": "center",
"lg": "center"
},
"id": 3484
}
}
}
},
{
"uid": "O-uGoDe_9",
"name": "Paragraph",
"component": "ElParagraph",
"group": "Text",
"options": {
"customize": null,
"content": {
"description": {
"type": "tiptap-mod",
"display": true,
"resolutionStyle": {
"sm": {
"fontSize": "16px",
"fontFamily": "Global Style",
"color": "Global Style",
"align": "left",
"lineHeight": "1.5",
"padding": {
"top": 0,
"bottom": 20,
"x": 0
}
},
"lg": {
"fontSize": "12px",
"fontFamily": "Global Style",
"color": "rgba(255, 255, 255, 1)",
"align": "left",
"lineHeight": "1.5",
"padding": {
"top": 20,
"bottom": 20,
"x": 0
}
}
},
"text": "<p>© Copyright. Your Site. All Rights Reserved</p>",
"letterSpacing": 0,
"animations": {
"animation": null,
"once": true,
"offset": 200,
"delay": 50
}
}
}
}
}
]
},
{
"cols": 6,
"elements": [
{
"uid": "2GOs0C1xl",
"name": "Munu Links",
"component": "ElMenuLinks",
"group": "Navigation",
"options": {
"customize": {
"layouts": null,
"style": {
"background": null,
"shadow": null,
"opacity": {
"type": "opacity-customs",
"opacity": 1
},
"padding": {
"type": "height-customs",
"sm": {
"top": 10,
"bottom": 10,
"x": 0
},
"lg": {
"top": 10,
"bottom": 10,
"x": 0
}
}
}
},
"content": {
"menu": {
"display": true,
"type": "menu-links-mod",
"column": {
"sm": false,
"lg": false
},
"style": {
"fontSize": "15px",
"color": "#fff",
"fontWeight": "bold",
"lineHeight": 2.8
},
"links": [
{
"href": "#",
"text": "Help Center",
"customLink": false,
"blank": false
},
{
"href": "#",
"text": "Work with Me",
"customLink": false,
"blank": false
},
{
"href": "#",
"text": "Resources",
"customLink": false,
"blank": false
}
]
}
}
}
}
]
},
{
"cols": 3,
"elements": [
{
"uid": "FQ2oeebQB",
"name": "Social Icons",
"component": "ElSocialLinks",
"group": "Others",
"options": {
"customize": {
"layouts": null,
"style": {
"background": null,
"shadow": null,
"opacity": {
"type": "opacity-customs",
"opacity": 1
},
"padding": {
"type": "height-customs",
"sm": {
"top": 20,
"bottom": 20,
"x": 10
},
"lg": {
"top": 20,
"bottom": 20,
"x": 10
}
}
}
},
"content": {
"socialLinks": {
"display": true,
"type": "social-links-mod",
"style": {
"color": false,
"fontSize": "14px"
},
"resolutionStyle": {
"lg": {
"align": "end"
},
"sm": {
"align": "start"
}
},
"links": [
{
"href": "#",
"icon": "fa fa-facebook",
"background": "rgba(31, 59, 156, 1)",
"color": "#fff",
"shape": "circle"
},
{
"icon": "fa fa-google",
"href": "",
"blank": "",
"color": "#ffffff",
"shape": "None",
"background": "#1f3b9c"
},
{
"href": "#",
"icon": "fa fa-twitter",
"background": "rgba(31, 59, 156, 1)",
"color": "#fff",
"shape": "circle"
}
]
}
}
}
}
]
},
{
"cols": 4,
"elements": []
}
]
}
}
}
]
}
}
}
}
},
"home": {
"code_injection": {
"head": "",
"body": "",
"cdn": []
}
},
"code_injection": {
"head": "",
"body": ""
},
"paragraph": {
"font": {
"sm": "Rubik",
"lg": "Rubik"
},
"fontSize": {
"sm": "16px",
"lg": "18px"
},
"align": {
"sm": "center",
"lg": "center"
},
"bold": {
"sm": false,
"lg": false
},
"italic": {
"sm": false,
"lg": false
},
"underline": {
"sm": false,
"lg": false
},
"color": "rgba(68, 71, 74, 1)"
},
"headline": {
"font": {
"sm": "Rubik",
"lg": "Rubik"
},
"fontSize": {
"sm": "36px",
"lg": "48px"
},
"align": {
"sm": "center",
"lg": "center"
},
"bold": {
"sm": false,
"lg": false
},
"italic": {
"sm": false,
"lg": false
},
"underline": {
"sm": false,
"lg": false
},
"color": "#0C226C"
},
"libraryImages": [
"https://res.cloudinary.com/estage/image/upload/v1633894730/users/user-3/NLccEoUKbqlpQfbiJX3U0aqrIKpDR2gPerK6DGhl.jpg"
]
}
