<template>
  <b-form-group
    class="estage-form-element-group mb-0"
  >
    <template
      v-if="control.label"
      slot="label"
    >
      <div
        v-html="label"
      />
    </template>

    <legend class="d-none active" />

    <b-textarea
      v-model="control.value"
      :placeholder="control.placeholder"
      :required="!!control.is_required"
      class="input"
      rows="4"
      v-bind="$attrs"
    />
  </b-form-group>
</template>

<script>
import FormControlMixin from './FormControlMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'FormTextarea',
  mixins: [FormControlMixin, BorderMixin],
  computed: {

  }
}
</script>
