<template>
  <div class="pt-[20px]">
    <b-form-group label="Rating">
      <div class="flex items-center gap-[10px]">
        <LinkedControlItem
          v-model="section.options.content.star.stars"
          :max="section.options.content.star.max"
          :min="0"
          :step=".5"
          class="d-block !w-[60px]"
        />

        <div>/</div>

        <LinkedControlItem
          v-model="section.options.content.star.max"
          :max="10"
          class="d-block !w-[60px]"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center gap-[10px]">
        <color-picker
          :color="section.options.content.star.color"
          @input="event => section.options.content.star.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />

        <label class="mb-0 pb-0">Color</label>
      </div>
    </b-form-group>

    <alignment-items-control :config="config" />

    <b-form-group
      label="Size"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="section.options.content.star.size[resolutioner]"
            :interval="1"
            :max="40"
            :min="0"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col class="p-0">
          <LinkedControlItem
            v-model="section.options.content.star.size[resolutioner]"
            :max="40"
            :min="0"
            class="d-block !w-[60px]"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Gap"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="section.options.content.star.gap[resolutioner]"
            :interval="1"
            :max="40"
            :min="0"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col class="p-0">
          <LinkedControlItem
            v-model="section.options.content.star.gap[resolutioner]"
            :max="40"
            :min="0"
            class="d-block !w-[60px]"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <SidbearSettingsGroup
      title="Background"
      class="border-top"
      content-class="pb-0"
    >
      <background-customs
        :config="backgroundColor"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Border & Radius"
    >
      <border-customs :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="section.options.customize.style.padding" />
      <margin-full-customs
        :config="section.options.customize.style.margin"
        :min="-500"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
import AlignmentItemsControl from '@/components/editor/controls/AlignmentItemsControl'

export default {
  name: 'PromoMod',
  components: {AlignmentItemsControl, LinkedControlItem, SidbearSettingsGroup},
  mixins: [ModMixin],
  computed: {
    backgroundColor () {
      if (!this.section.options.customize.style.background) {
        this.$set(this.section.options.customize.style, 'background', {
          background: ''
        })
      }

      return this.section.options.customize.style.background
    }
  }
}
</script>