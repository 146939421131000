<template>
  <div>
    <SidbearSettingsGroup
      title="Header Layout"
      active
      content-class="pb-0"
    >
      <flex-mod
        component-name="Header Layout"
        :config="section.options.content.subject"
        :hide="['visibility']"
        :local-section="section.options.content.subject"
        :custom-back-options="{openedItem: 'MenuItemsV2Mod', section}"
      />
    </SidbearSettingsGroup>
    <SidbearSettingsGroup
      content-class="pb-0"
      title="Scroll Effect"
    >
      <navbar-scroller-customs :config="section.options.customize.style.navbarScroller" />
    </SidbearSettingsGroup>
    <SidbearSettingsGroup title="Header">
      <b-form-group>
        <b-checkbox v-model="bringToFront">
          bring to front / overlay page content
        </b-checkbox>
      </b-form-group>
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import PagesPaginationSelectMixin from '@/components/editor/mixins/PagesPaginationSelectMixin'
import EventsMixin from '@/components/editor/mixins/EventsMixin'
import FlexMod from '@/components/editor/mods/FlexMod'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import NavbarScrollerCustoms from '@/components/editor/controls/NavbarScrollerCustoms'
import _ from 'lodash'

export default {
  name: 'ButtonMod',
  components: {NavbarScrollerCustoms, SidbearSettingsGroup, FlexMod},
  mixins: [ModMixin, PagesPaginationSelectMixin, EventsMixin],
  computed: {
    bringToFront: {
      get () {
        return _.get(this.config.options.content.menuItems, ['bringToFront', this.resolutioner], _.get(this.config.options.content.menuItems, ['bringToFront', 'lg'], false))
      },
      set (val) {
        const _bringToFront = typeof this.config.options.content.menuItems.bringToFront === 'object' ? this.config.options.content.menuItems.bringToFront : {}
        this.$set(this.config.options.content.menuItems, 'bringToFront', {..._.set(_bringToFront, [this.resolutioner], val)})
      }
    }
  }
}
</script>
