import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Navbar'
export const group = 'navbar'
export const defaultOptions = {
  'customize': {
    'style': {
      'navbarScroller': {
        'type': 'navbar-scroller-customs',
        'scrollType': 'sticky',
        'onScrollStyles': {
          'background': {
            'lg': {
              'background': {
                'lg': 'rgba(251, 247, 233, 1)'
              },
              'backgroundType': null
            }
          }
        }
      }
    },
    'display': true
  },
  'content': {
    'logo': {
      'type': 'logo-mod',
      'display': true,
      'fontFamily': null,
      'fontWeight': 'bold',
      'fontSize': '24px',
      'color': '#0C226C',
      'text': 'AppStarter'
    },
    'menuItems': {
      'type': 'menu-items-mod-v2',
      'display': true,
      'bringToFront': {
        'lg': true
      }
    },
    'subject': {
      'uid': 'WKtPEOYr2',
      'sid': 'llt0nxbq--Gp3-oRHh4U',
      'name': 'Flex Wrapper',
      'hideFromSections': false,
      'component': 'ElFlexWrapperV2',
      'group': 'navbar',
      'layout': null,
      'specialCols': {
        'navigation': {
          'special': true,
          'sid': 'llt0nxbq--b5BdMW_8E3',
          'placement': 'bottom',
          'hamburgerIcon_open': 'fa fa-bars',
          'hamburgerIcon_close': 'fa fa-times',
          'hamburgerIcon_color': '#656565',
          'showOn': 'lg',
          'name': 'Nav Column',
          'elements': [
            {
              'display': true,
              'name': 'Flex Wrapper',
              'hideFromSections': false,
              'component': 'ElFlexWrapperV2',
              'group': '',
              'layout': null,
              'options': {
                'content': {
                  'display': true,
                  'cols': [
                    {
                      'display': true,
                      'name': 'Flex Column',
                      'elements': [
                        {
                          'uid': 'BlbxyqYc0',
                          'display': true,
                          'name': 'Menu Links',
                          'sid': 'llt0nxbq--wxoG5pED0p',
                          'hideFromSections': false,
                          'component': 'ElMenuLinksV4',
                          'group': 'Navigation',
                          'layout': null,
                          'options': {
                            'content': {
                              'menu': {
                                'display': true,
                                'type': 'menu-links-mod-v4',
                                'links': [
                                  {
                                    'id': '2lgcfYKlUS',
                                    'href': '#',
                                    'text': 'About',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'kE_0CEF0hZ',
                                    'href': '#',
                                    'text': 'Work with Me',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'wJGQ-hWnL1',
                                    'href': '#',
                                    'text': 'Resources',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'A_dvOV_wIV',
                                    'href': '#',
                                    'text': 'Blog',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'QlHAT-G7Lw',
                                    'href': '#',
                                    'text': 'Contact',
                                    'customLink': false,
                                    'blank': false,
                                    'children': []
                                  }
                                ]
                              }
                            }
                          },
                          'editor': 'project',
                          'temp_id': 'KL8FXUhHuN'
                        }
                      ],
                      'sortIndex': {
                        'lg': 0,
                        'md': 0,
                        'sm': 0
                      },
                      'uid': 'q3tygjZ7V6',
                      'sid': 'llt0nxbq--g5YTcjuhjX',
                      'highlight': false,
                      'animations': {
                        'animation': null,
                        'once': true,
                        'offset': 200,
                        'delay': 50
                      },
                      '_uid': 'aEWG_QirtV'
                    },
                    {
                      'display': true,
                      'name': 'Flex Column',
                      'elements': [
                        {
                          'uid': 'oCKQ7xxGP',
                          'display': true,
                          'name': 'Button',
                          'sid': 'llt0nxbq--y3YyCjm2-L',
                          'hideFromSections': false,
                          'component': 'ElButtonV2',
                          'group': 'Media',
                          'layout': null,
                          'options': {
                            'content': {
                              'button': {
                                'pressetID': 0,
                                'display': true,
                                'type': 'button-mod-v2',
                                'text': 'Get Started',
                                'buttonType': 'button',
                                'icon': {
                                  'lottie': {
                                    'jsonPath': '',
                                    'loop': true,
                                    'autoplay': true
                                  }
                                },
                                'hoverAnimation': {},
                                'animations': {
                                  'animation': null,
                                  'once': true,
                                  'offset': 200,
                                  'delay': 50
                                }
                              }
                            },
                            'customize': null
                          },
                          'editor': 'project',
                          'temp_id': '-BOgEagQ0x'
                        }
                      ],
                      'sortIndex': {
                        'lg': 1,
                        'md': 1,
                        'sm': 1
                      },
                      'uid': '5IJANpeMhX',
                      'sid': 'llt0nxbq--O-Jid3aian',
                      'highlight': false,
                      'animations': {
                        'animation': null,
                        'once': true,
                        'offset': 200,
                        'delay': 50
                      },
                      '_uid': 'K0Q3hwDmrw'
                    }
                  ],
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              },
              'uid': '68Twket09',
              'sid': 'llt0nxbq--DX8I6xExdM',
              'temp_id': 'kb5eyU6NYu'
            }
          ],
          'highlight': false,
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          },
          'sortIndex': {
            'lg': 1,
            'md': 1,
            'sm': 1
          },
          'uid': 'XWdqDWhEd',
          'navbarBackground': {
            'background': '',
            'gradient': {
              'position': '',
              'color': ''
            }
          },
          '_uid': 'uO85pzNyh8'
        }
      },
      'options': {
        'content': {
          'display': true,
          'cols': [
            {
              'display': true,
              'name': 'Flex Column',
              'elements': [
                {
                  'uid': 'CD9_q2Fvf',
                  'display': true,
                  'name': 'Image',
                  'sid': 'llt0nxbq--6k70qV1jsY',
                  'hideFromSections': false,
                  'component': 'ElImageV3',
                  'group': 'Media',
                  'layout': null,
                  'options': {
                    'content': {
                      'image': {
                        'display': true,
                        'type': 'ImageModV3',
                        'src': null,
                        'alt': 'Image',
                        'modal': {
                          'data': {
                            'modal_id': null
                          }
                        },
                        'animations': {
                          'animation': null,
                          'once': true,
                          'offset': 200,
                          'delay': 50
                        },
                        'url': ''
                      }
                    }
                  },
                  'editor': 'project',
                  'temp_id': 'VZmZ9hdaJo'
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              },
              'sortIndex': {
                'lg': 0,
                'md': 0,
                'sm': 0
              },
              'sid': 'llt0nxbq---cL5JuZsV1',
              'uid': '3C4dQ7_ZH2J',
              'highlight': false,
              '_uid': 'NegOsf8Wp'
            },
            {
              'special': true,
              'key': 'navigation',
              'mod': 'navigation-flex-col-mod-v2',
              'name': 'Nav Column',
              'sid': 'Uz96GLEcGy',
              'sortIndex': {
                'lg': 1,
                'md': 1,
                'sm': 1
              },
              'highlight': false
            }
          ],
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          }
        }
      },
      'temp_id': '23MJlRTY6G'
    }
  }
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('NavbarV3', options)

    this.name = name
    this.group = group
    this.sid = ['global']
    this.sids = [
      'global',
      'llt0nxbq--Gp3-oRHh4U',
      'llt0nxbq---cL5JuZsV1',
      'llt0nxbq--6k70qV1jsY',
      'llt0nxbq--b5BdMW_8E3',
      'llt0nxbq--DX8I6xExdM',
      'llt0nxbq--g5YTcjuhjX',
      'llt0nxbq--wxoG5pED0p',
      'llt0nxbq--O-Jid3aian',
      'llt0nxbq--y3YyCjm2-L'
    ]
    this.css = [
      [
        '.--llt0nxbq--Gp3-oRHh4U--container { padding-right: 0px; }',
        '.--llt0nxbq---cL5JuZsV1--flex-col-padding { padding: 0px; }',
        '.--llt0nxbq--b5BdMW_8E3--flex-col-padding { padding: 0px; }',
        '.--llt0nxbq---cL5JuZsV1--flex-col-el { width: 20%; background-color: rgba(255, 255, 255, 0); }',
        '.--llt0nxbq--b5BdMW_8E3--flex-col-el { width: 100%; --width: 100%; }',
        '.--llt0nxbq--6k70qV1jsY--container { justify-content: start; }',
        '.--llt0nxbq--6k70qV1jsY--wrapper { padding: 20px; }',
        '.--llt0nxbq--b5BdMW_8E3--flex-col > div { align-items: center; justify-content: center; }',
        '.--llt0nxbq--g5YTcjuhjX--flex { width: 100%; --width: 100%; }',
        '.--llt0nxbq--g5YTcjuhjX--flex-col-el { width: 100%; --width: 100%; }',
        '.--llt0nxbq--O-Jid3aian--flex-col-el { width: 17%; --width: 17%; }',
        '.--llt0nxbq--DX8I6xExdM--flex { width: 100%; --width: 100%; align-items: center; padding: 0px; }',
        '.--llt0nxbq--Gp3-oRHh4U--flex { align-items: center; }',
        '.--llt0nxbq--wxoG5pED0p--wrapper { padding: 10px; justify-content: end; gap: 56px; align-items: end; font-family: Inter; }',
        '.--llt0nxbq--y3YyCjm2-L--1 { margin: 20px; background-color: rgb(93, 192, 254); font-family: Inter; font-size: 15px; font-weight: 700; border-radius: 3px; width: 150px; --width: 150px; }',
        '.--llt0nxbq--wxoG5pED0p--link { font-size: 15px; color: rgb(0, 0, 0); }',
        '.--llt0nxbq--wxoG5pED0p--link:hover { color: rgb(93, 192, 254); }',
        '.--llt0nxbq--g5YTcjuhjX--flex-col-padding { padding: 0px; }',
        '.--llt0nxbq--O-Jid3aian--flex-col-padding { padding: 0px; }',
        '.--llt0nxbq--Gp3-oRHh4U--flex.flex-wrapper { padding-bottom: 10px; padding-top: 10px; }',
        '.--llt0nxbq--6k70qV1jsY--image { content: url("https://res.cloudinary.com/estage/image/upload/v1689723700/users/user-572/9610c6470128eaea7a78a41cc36714cd.png"); --bg-id: 166377; }',
        '.el-image.--llt0nxbq--6k70qV1jsY--image { width: 168px; --width: 168px; }',
        '.--llt0nxbq--y3YyCjm2-L--1 .main-text { justify-content: center; --text-wrap: nowrap; }',
        '.--llt0nxbq--y3YyCjm2-L--2 { justify-content: center; }',
        '.--llt0nxbq---cL5JuZsV1--flex-col { text-align: left; justify-content: start; }',
        '.--llt0nxbq--y3YyCjm2-L--1:hover { background-color: rgb(34, 156, 230); }'
      ],
      [
        '.--llt0nxbq--g5YTcjuhjX--flex { width: 100%; --width: 100%; }',
        '.--llt0nxbq--DX8I6xExdM--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--llt0nxbq--O-Jid3aian--flex-col-el { width: 100%; --width: 100%; }',
        '.--llt0nxbq--wxoG5pED0p--wrapper { flex-direction: column; justify-content: center; align-items: center; gap: 49px; }',
        '.--llt0nxbq--b5BdMW_8E3--flex-col-el { background-color: rgb(244, 234, 215); }',
        '.--llt0nxbq--b5BdMW_8E3--flex-col-padding { padding-top: 20px; padding-bottom: 20px; }',
        '.--llt0nxbq--Gp3-oRHh4U--close-icon { color: rgb(0, 0, 0); }',
        '.--llt0nxbq--Gp3-oRHh4U--icon { font-size: 20px; translate: -13px -8px; }',
        '.--llt0nxbq--Gp3-oRHh4U--open-icon { color: rgb(0, 0, 0); }',
        '.--llt0nxbq--Gp3-oRHh4U--flex.flex-wrapper { justify-content: start; }',
        '.el-image.--llt0nxbq--6k70qV1jsY--image { width: 168px; --width: 168px; }',
        '.--llt0nxbq---cL5JuZsV1--flex-col-el { width: 27%; --width: 27%; }',
        '.--llt0nxbq---cL5JuZsV1--flex-col-padding { padding: 0px; }',
        '.--llt0nxbq---cL5JuZsV1--flex-col { align-items: center; }'
      ],
      [
        '.--llt0nxbq--g5YTcjuhjX--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--llt0nxbq--DX8I6xExdM--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--llt0nxbq---cL5JuZsV1--flex-col-el { width: 50%; --width: 50%; }',
        '.--llt0nxbq--O-Jid3aian--flex-col-el { width: 100%; --width: 100%; }',
        '.--llt0nxbq--wxoG5pED0p--wrapper { gap: 31px; flex-direction: column; justify-content: center; align-items: center; }',
        '.--llt0nxbq--b5BdMW_8E3--flex-col-padding { padding-top: 20px; padding-bottom: 20px; }',
        '.--llt0nxbq--y3YyCjm2-L--1 { width: 100%; }',
        '.--llt0nxbq--b5BdMW_8E3--flex-col-el { width: 100%; --width: 100%; background-color: rgb(244, 234, 215); }',
        '.--llt0nxbq--6k70qV1jsY--container { justify-content: start; }',
        '.--llt0nxbq---cL5JuZsV1--flex-col-padding { padding: 0px; }',
        '.el-image.--llt0nxbq--6k70qV1jsY--image { width: 171px; --width: 171px; }',
        '.--llt0nxbq--Gp3-oRHh4U--icon { translate: -13px -1px; }',
        '.--llt0nxbq--6k70qV1jsY--wrapper { padding-left: 0px; padding-top: 16px; }',
        '.--llt0nxbq--b5BdMW_8E3--flex-col { text-align: center; justify-content: center; }',
        '.--llt0nxbq--wxoG5pED0p--link { font-size: 14px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
