<template>
  <b-navbar class="editor-header position-fixed">
    <b-navbar-brand
      class="d-flex align-items-center cursor-pinter"
      @click="$router.push('/')"
    >
      <icon icon="logo-e.svg" />
      <div class="h4 mb-0 ml-4">
        {{ title }}
      </div>
    </b-navbar-brand>

    <div
      v-if="!hideLinks"
      class="flex basis-auto grow items-center"
    >
      <b-navbar-nav class="align-items-center ml-auto">
        <slot name="prepent-left-menu" />

        <b-nav-item v-if="!hideBackToEditor">
          <b-btn
            variant="white"
            class="font-weight-bold"
            @click="$router.push({name: 'editor', query: {page: page.id}})"
          >
            Return to Editor
          </b-btn>
        </b-nav-item>
        <b-nav-item>
          <b-btn
            variant="white"
            class="d-flex align-items-center font-weight-bold"
            @click="$router.back()"
          >
            <svg
              class="mr-2"
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.55408 0.75L1.44597 5L5.55408 9.25"
                stroke="#6E747A"
                stroke-width="1.5"
              />
            </svg>
            Go Back
          </b-btn>
        </b-nav-item>

        <slot name="append-left-menu" />
      </b-navbar-nav>
    </div>
  </b-navbar>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ModuleHeader',

  props: {
    title: {
      type: String
    },

    hideLinks: {
      type: Boolean,
      default: false
    },

    hideBackToEditor: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('pages', {
      page: state => state.page
    })
  }
}
</script>
