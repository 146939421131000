<template>
  <div class="dasboard-list-folders">
    <div class="dashboard-list-item-head">
      <b-row>
        <b-col offset="1">
          Name
        </b-col>
        <b-col cols="2">
          Date Modified
        </b-col>
        <b-col cols="2">
          Date Created
        </b-col>
        <b-col cols="1">
          Actions
        </b-col>
      </b-row>
    </div>

    <draggable
      group="folders"
      :list="folders"
      filter=".static"
      :delay="200"
      @end="end"
    >
      <transition-group name="shufle">
        <div
          v-for="folder in folders"
          :key="folder.id"
          class="dashboard-list-item"
        >
          <folder-list-item :folder="folder" />
        </div>

        <div
          :key="'archive'"
          class="dashboard-list-item static"
        >
          <b-row
            class="folder-list-item align-items-center"
            @click="$router.push({name: 'archive-projects'})"
          >
            <b-col cols="1">
              <svg
                width="38"
                height="30"
                viewBox="0 0 38 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29.5686 30H0.99903C0.341583 30 -0.136503 29.3758 0.0351996 28.7406L6.52414 10.9506C6.64158 10.5153 7.03669 10.2128 7.48797 10.2128H36.0576C36.715 10.2128 37.0214 10.8409 37.0214 11.4721L30.5324 29.2621C30.415 29.6975 30.0199 30 29.5686 30Z"
                  fill="#94B8FC"
                />
                <path
                  d="M32.086 4.46809H15.9575L12.766 0H1.10559C0.49538 0 6.10352e-05 0.494681 6.10352e-05 1.10553V28.3787C0.0441036 28.38 0.0881462 28.3826 0.130912 28.3851L6.52347 10.9506C6.64091 10.5147 7.03602 10.2128 7.4873 10.2128H33.1916V5.57362C33.1916 4.96277 32.6962 4.46809 32.086 4.46809Z"
                  fill="#4F83E3"
                />
              </svg>
            </b-col>

            <b-col>
              <h5 class="item-name text-truncate">
                Archive
              </h5>
              <h6 class="folder-sites-count mb-0">
                <span v-text="totalArchiveProjects" /> Sites
              </h6>
            </b-col>
          </b-row>
        </div>
      </transition-group>
    </draggable>

    <div
      v-for="(item, index) in projects"
      :key="generateKey(item.id, index)"
      class="dashboard-list-item"
    >
      <project-list-item :project="item" />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {mapState} from 'vuex'
import FolderListItem from './components/FolderListItem'
import ProjectListItem from './components/ProjectListItem'

export default {
  name: 'DashboardListFolders',
  components: {ProjectListItem, FolderListItem, draggable},
  props: {
    folders: {
      type: Array,
      required: true
    },
    projects: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      totalArchiveProjects: 0
    }
  },
  computed: {
    ...mapState('dashboard', {
      search: state => state.search
    }),
    items () {
      return [
        ...this.folders.map(item => {
          return {
            isFolder: true,
            ...item
          }
        }),
        ...this.projects
      ]
    }
  },
  created () {
    axios.get('api/projects/trash')
      .then(({data}) => {
        this.totalArchiveProjects = data.data.total
      })
  },
  methods: {
    generateKey (id, index) {
      const uniqueKey = `${id}-${index}`
      return uniqueKey
    },
    end () {
      axios.put('api/projects/categories/sort', this.folders.map((folder, index) => {
        return {
          id: folder.id,
          order: index + 1
        }
      }))
    }
  }
}
</script>

<style lang="scss">
@import "@builder/assets/scss/_variables.scss";

.dasboard-list-folders {
  font-family: Raleway;
  padding-bottom: 60px;

  .dashboard-list-item-head {
    font-size: 15px;
    color: #74819B;
    background: #F9FBFF;
    border: 1px solid #EAEEF7;
    padding: 25px 45px;
  }

  .dashboard-list-item {
    background: #fff;
    border-bottom: 1px solid #EAEEF7;
    padding: 35px 45px;
    transition: .3s;

    &:hover {
      background: #F1F5FD;
      border-color: #EAEEF7;
      cursor: pointer;
    }

    .item-name {
      font-weight: bold;
      font-size: 17px;
      max-width: 200px;
    }

    .date-stamp {
      font-family: 'Poppins';
      font-size: 15px;
      color: #6E747A;
    }
  }
}

.folder:hover {
  background: #F1F5FD !important;
}

.sortable-chosen {
  &.dashboard-list-item {
    box-shadow: 0 0 0 3px $primary !important;
  }
}
</style>