<template>
  <b-form-group>
    <div class="flex items-center gap-[10px]">
      <text-color
        v-model="config.color"
        class="!h-[38px] !w-[38px]]"
      />
      <label
        class="mb-0 pb-0"
        v-text="config.label || 'Text Color'"
      />
    </div>
  </b-form-group>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'
import TextColor from './TextColor'

export default {
  name: 'Color',
  components: {TextColor},
  mixins: [ControlMixin]
}
</script>
