<template>
  <b-form-group class="mb-4">
    <div class="mb-[18px]">
      <label class="block !mb-[10px]">Padding</label>

      <div class="flex items-center justify-between">
        <b-form-radio-group
          v-model="position"
          class="resolution-style-tabs"
          buttons-variant="light"
          value-field="key"
          text-field="text"
          size="sm"
          buttons
          :options="[{text: 'TOP', key: 'top'}, {text: 'BOTTOM', key: 'bottom'}, {text: 'LEFT / RIGHT', key: 'x'}]"
        />

        <button
          class="h-[38px] w-[38px] flex items-center justify-center p-0 !border-[#E2E5EC] rounded"
          style="border:1px solid;"
          @click="linked = !linked"
        >
          <svg
            fill="none"
            height="17"
            viewBox="0 0 18 17"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              :fill="linked ? '#7CACFA' : '#6E747A'"
              d="M16.6721 1.25439C15.1096 -0.210115 12.5765 -0.210115 11.014 1.25435L7.67013 4.38854C6.05395 5.90345 6.20358 8.3172 7.67013 9.69186C7.91572 9.92205 8.18744 10.1097 8.47437 10.2639L9.08467 9.69186C9.48496 9.31661 9.34406 8.87795 9.33686 8.56142C9.249 8.50305 9.16355 8.43995 9.08467 8.36602C8.33207 7.66067 8.29823 6.45148 9.08467 5.71436C9.20146 5.60489 12.3576 2.64667 12.4285 2.58023C13.2086 1.84901 14.4774 1.84901 15.2575 2.58023C16.0376 3.31145 16.0376 4.50067 15.2575 5.23189L13.0473 7.30355C13.1112 7.63502 13.4948 8.4202 13.2974 9.71545C13.3071 9.70658 13.3186 9.70086 13.3282 9.69189L16.6721 6.5577C18.2345 5.0932 18.2345 2.71889 16.6721 1.25439Z"
            />
            <path
              :fill="linked ? '#7CACFA' : '#6E747A'"
              d="M11.2064 6.37727C10.9608 6.14708 10.6891 5.95939 10.4022 5.80524L9.79192 6.37727C9.39156 6.75246 9.53246 7.19111 9.53966 7.50764C9.62758 7.56602 9.71303 7.62911 9.79192 7.70305C10.5445 8.40846 10.5784 9.61758 9.79192 10.3547C9.67486 10.4644 6.25732 13.6677 6.18894 13.7318C5.40881 14.463 4.14003 14.463 3.3599 13.7318C2.57976 13.0006 2.57976 11.8113 3.3599 11.0801L5.82933 8.76552C5.76542 8.43405 5.3818 7.64886 5.57914 6.35361C5.56951 6.36249 5.55791 6.36827 5.5483 6.37724L1.94536 9.75433C0.382959 11.2188 0.382959 13.5931 1.94536 15.0576C3.50783 16.5221 6.04094 16.5221 7.60341 15.0576L11.2064 11.6806C12.7926 10.1938 12.7059 7.78274 11.2064 6.37727Z"
            />
          </svg>
        </button>
      </div>
    </div>

    <b-row
      no-gutters
      class="align-items-center"
    >
      <b-col
        cols="9"
        class="pr-2 !pl-[8px]"
      >
        <vue-slider
          v-model="padding"
          class="editor-slider-1"
          :interval="1"
          :dot-size="17"
          tooltip="none"
          :min="0"
          :max="100"
        />
      </b-col>
      <b-col>
        <b-input
          v-model="padding"
          min="0"
          max="100"
          type="number"
          step="1"
          size="sm"
        />
      </b-col>
    </b-row>

    <div class="mt-[3px] mb-2">
      <span
        class="text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
        @click="syncPadding"
      >
        {{ syncReady ? 'Done!' : 'Sync padding for all devices' }}
      </span>
    </div>

    <div class="mb-0">
      <span
        class="relative text-[#333333] text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
        @click="removePadding"
      >
        Remove padding for all devices
        <span class="absolute w-full h-[1px] bg-[#333333] left-0 bottom-0 translate-y-[-1px]" />
      </span>
    </div>
  </b-form-group>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'
import SyncSettingsMixin from '@/components/mixins/SyncSettingsMixin'

export default {
  name: 'Padding',
  mixins: [ControlMixin, SyncSettingsMixin],

  data () {
    return {
      position: 'top',
      syncReady: false,
      linked: false
    }
  },

  computed: {
    padding: {
      get () {
        return this.config.resolutionStyle[this.display.display !== true ? this.getDisplays[0] : this.resolution].padding[this.position]
      },
      set (val) {
        this.getDisplays.forEach(display => {
          this.config.resolutionStyle[display].padding[this.position] = val
        })

        if (this.linked) {
          this.$set(this.config.resolutionStyle[this.resolution], 'padding', {
            top: val,
            bottom: val,
            x: val
          })
        }
      }
    },
    paddingObject () {
      return this.config.resolutionStyle[this.display.display !== true ? this.getDisplays[0] : this.resolution].padding
    }
  },

  methods: {
    removePadding () {
      this.$set(this.config.resolutionStyle.lg, 'padding', {
        top: 0,
        bottom: 0,
        x: 0
      })
      this.$set(this.config.resolutionStyle.md, 'padding', {
        top: 0,
        bottom: 0,
        x: 0
      })
      this.$set(this.config.resolutionStyle.sm, 'padding', {
        top: 0,
        bottom: 0,
        x: 0
      })
    },
    syncPadding () {
      if (this.syncReady) return

      this.$set(this.config.resolutionStyle.lg, 'padding', {
        top: this.paddingObject.top || 0,
        bottom: this.paddingObject.bottom || 0,
        x: this.paddingObject.x || 0
      })
      this.$set(this.config.resolutionStyle.md, 'padding', {
        top: this.paddingObject.top || 0,
        bottom: this.paddingObject.bottom || 0,
        x: this.paddingObject.x || 0
      })
      this.$set(this.config.resolutionStyle.sm, 'padding', {
        top: this.paddingObject.top || 0,
        bottom: this.paddingObject.bottom || 0,
        x: this.paddingObject.x || 0
      })

      this.syncReady = true
      setTimeout(() => {
        this.syncReady = false
      }, 500)
    }
  }
}
</script>
