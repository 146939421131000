import {mapState} from 'vuex'

export default {
  computed: {
    searchModel: {
      get () {
        this.searchValue
      },
      set (val) {
        this.$store.commit('blog/SET_SEARCH', val)
      }
    },
    ...mapState('blogSettings', {
      settings: state => state.settings
    }),
    ...mapState('blog', {
      searchValue: state => state.search
    })
  },
  methods: {
    search () {
      this.$store.dispatch('blog/getPosts')
    }
  },
  watch: {
    searchValue (val) {
      if (!val) {
        this.search()
      }
    }
  }
}
