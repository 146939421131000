<template>
  <div class="el-parapgraph relative">
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.el-parapgraph {
  p {
    margin-bottom: 0 !important;
  }
}
</style>
