<template>
  <div class="w-full flex flex-grow flex-col justify-center items-center px-[2.5rem] py-[3rem]">
    <img
      :src="plan.image"
      :alt="`Estage ${plan.name}`"
      class="aspect-video w-full rounded-[.25rem] h-[223px]"
    >

    <h4 class="text-[1.5rem] leading-[2rem] font-[700] text-[#000000] mt-[1.25rem] font-[ProximaNova] text-center">
      Instantly Activate These Amazing Features!
    </h4>
    
    <template v-if="plan.name !== 'Business'">
      <ul class="w-full columns-2 gap-x-[.75rem] list-none !max-h-[362px] mt-[1.25rem]">
        <li
          v-for="(feature, index) in features"
          :key="index"
          class="relative w-full inline-block gap-x-[.5rem] text-[.875rem] leading-[2rem] text-[#333333] font-[ProximaNova] last:font-[700] last:text-[#333333]"
        >
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.72727 1L3.72727 7L1 4.27273"
              stroke="#3D82EA"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="w-full">
            {{ feature }}
          </span>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PurchasePlanOverview',
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      features: [
        'Unlimited Video Hosting',
        'Real-time CTA',
        'ESTAGE Public Live Streaming',
        'ESTAGE Private Live Streaming',
        'Live Streaming Instant Replays',
        'Community Live Video Posts',
        'ESTAGE Native Live Chat',
        'Community Private Events',
        'Courses - Daily Challenge Mode',
        'Courses - A/R Integration for Lessons',
        'Community - Opportunities',
        'Community - MSI Ecosystem',
        'Community - IPS (Integrated Product Suite) Licensing and Management',
        'Community - Featured Ad Widget (in feed)',
        'Community - Upcoming Events Widget (in feed)',
        'And much, much more!'
      ]
    }
  }
}
</script>