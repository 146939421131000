import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Featured Images'
export const group = 'Media'

export const defaultOptions = {
  version: 3,
  'content': {
    'display': true,
    'cols': [{
      'display': true,
      'name': 'Image 1',
      'elements': [],
      'sortIndex': {'lg': 0, 'md': 0, 'sm': 0},
      'uid': '71fYTdKWF',
      'sid': 'l5g51d5a--5IcyfPfk0_',
      'animations': {'animation': null, 'once': true, 'offset': 200, 'delay': 50},
      'highlight': false,
      'absolute': {'sm': false}
    }, {
      'display': true,
      'name': 'Image 2',
      'elements': [],
      'sortIndex': {'lg': 1, 'md': 1, 'sm': 1},
      'uid': 'yEJj2ZIY9v',
      'sid': '--209511--qKIHE4lMD',
      'animations': {'animation': null, 'once': true, 'offset': 200, 'delay': 50},
      'highlight': false,
      'absolute': {'lg': true, 'md': true, 'sm': true},
      'transformY': {'lg': 46, 'md': -3, 'sm': 40},
      'transformX': {'lg': 43, 'md': 49, 'sm': 37}
    }, {
      'display': true,
      'name': 'Image 3',
      'elements': [],
      'sortIndex': {'lg': 2, 'md': 2, 'sm': 2},
      'uid': 'Z5GOXPcTOG',
      'sid': '--209511--n6YQySI7J',
      'animations': {'animation': null, 'once': true, 'offset': 200, 'delay': 50},
      'highlight': false,
      'absolute': {'lg': true, 'md': true, 'sm': true},
      'transformY': {'lg': -7, 'md': 54, 'sm': -5},
      'transformX': {'lg': 38, 'md': 51, 'sm': 36}
    }],
    'animations': {'animation': null, 'once': true, 'offset': 200, 'delay': 50}
  }
}

export const icon = `<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect class="just-stroke no-fill" x="5.5" y="0.5" width="8" height="10.8154" rx="1.5" fill="white" stroke="#333333"/>
<rect class="just-stroke no-fill" x="7.5" y="8.37692" width="8" height="10.8154" rx="1.5" fill="white" stroke="#333333"/>
<rect class="just-stroke no-fill" x="0.5" y="5.42308" width="8" height="10.8154" rx="1.5" fill="white" stroke="#333333"/>
</svg>
`
export default class ElThreeImages extends SectionProvider {
  constructor (options = {}) {
    super('ElFlexWrapperV2', options)

    this.name = name
    this.group = group
    this.css = [['.--l5g51d5a--naxShL0Okm--flex { justify-content: start; width: 600px; --width:600px; flex-wrap: nowrap; padding-top: 40px; }', '.--l5g51d5a--5IcyfPfk0_--flex-col-el { width: 280px; --width:280px; height: 400px; --height:400px; background-image: url("https://res.cloudinary.com/estage/image/upload/v1649438965/users/user-3/364f73edd11c94aa3210b2e4a439b494.png"); --bg-id:89184; box-shadow: rgba(0, 0, 0, 0.176) 0px 1rem 3rem; z-index: 2; }', '.----209511--qKIHE4lMD--flex-col-el { width: 240px; --width:240px; height: 350px; --height:350px; background-image: url("https://res.cloudinary.com/estage/image/upload/v1649438965/users/user-3/364f73edd11c94aa3210b2e4a439b494.png"); --bg-id:89184; box-shadow: rgba(0, 0, 0, 0.176) 0px 1rem 3rem; z-index: 1; }', '.----209511--n6YQySI7J--flex-col-el { width: 200px; --width:200px; height: 300px; --height:300px; background-image: url("https://res.cloudinary.com/estage/image/upload/v1649438965/users/user-3/364f73edd11c94aa3210b2e4a439b494.png"); --bg-id:89184; box-shadow: rgba(0, 0, 0, 0.176) 0px 1rem 3rem; }', '.--l5g51d5a--naxShL0Okm--container { margin-top: 50px; }'], ['.--l5g51d5a--naxShL0Okm--flex { width: 500px; --width:500px; }'], ['.--l5g51d5a--naxShL0Okm--flex { width: 100%; --width:100%; }', '.--l5g51d5a--5IcyfPfk0_--flex-col-el { height: 300px; --height:300px; width: 200px; --width:200px; }', '.----209511--qKIHE4lMD--flex-col-el { width: 200px; --width:200px; height: 250px; --height:250px; }']]
    this.sids = ['l5g51d5a--naxShL0Okm', 'l5g51d5a--5IcyfPfk0_', '--209511--qKIHE4lMD', '--209511--n6YQySI7J']
    this.sid = 'l5g51d5a--naxShL0Okm'
    this.defaultOptions = defaultOptions
  }
}
