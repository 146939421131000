import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Compare Slider'
export const group = 'Advanced'

export const defaultOptions = {
  content: {
    compare: {
      type: 'compare-slider-mod-v2',
      display: true,
      beforeImageURL: require('@builder/assets/images/placeholder.png'),
      afterImageURL: require('@builder/assets/images/placeholder.png'),
      handleIcon: 'fa fa-arrows-h'
    }
  },
  customize: {}
}


export const icon = `<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.16" d="M3 3H9V19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3Z" fill="black"/>
<path d="M10 3H3C2.46957 3 1.96086 3.21071 1.58579 3.58579C1.21071 3.96086 1 4.46957 1 5V17C1 17.5304 1.21071 18.0391 1.58579 18.4142C1.96086 18.7893 2.46957 19 3 19H10M14 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V6M17 16V17C17 17.5304 16.7893 18.0391 16.4142 18.4142C16.0391 18.7893 15.5304 19 15 19H14M17 10V12M9 1V21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`

export default class ElCompareSlider extends SectionProvider {
  constructor (options = {}) {
    super('ElCompareSliderV2', options)

    this.name = name
    this.group = group
    this.css = [[
      '.--t9RT95ha--47OQjU5Qxe--container { padding: 20px; }',
      '.--t9RT95ha--47OQjU5Qxe--compare-slider { width: 100%; justify-content: center; }',
      '.--t9RT95ha--47OQjU5Qxe--handler { --handler-size: 2px; background-color: #3d82ea; }',
      '.--t9RT95ha--47OQjU5Qxe--handler-button { width: 40px; height: 40px; border-radius: 20px; border: 2px solid #3d82ea; background-color: #FFFFFF; }',
      '.--t9RT95ha--47OQjU5Qxe--handler-icon { font-size: 18px; color: #3d82ea; }'
    ], [], []]
    this.sids = ['t9RT95ha--47OQjU5Qxe']
    this.sid = 't9RT95ha--47OQjU5Qxe'
    this.defaultOptions = defaultOptions
  }
}
