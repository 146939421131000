<template>
  <div>
    <div class="ml-[6px]">
      <h5 class="font-semibold text-[25px] leading-[30px] text-black mb-[10px]">
        Analytics Integrations
      </h5>
      <p class="text-[16px] leading-[26px] text-[#6E747A] mb-[65px]">
        Know exactly what's happening on every page of your project by integrating some of the world's best analytics systems with your project.
      </p>
    </div>

    <div
      v-if="integrationsLoading"
      class="w-full h-[250px] flex items-center justify-center "
    >
      <b-spinner
        class="!w-12 !h-12"
        variant="primary"
      />
    </div>

    <div v-else>
      <integration-item
        v-for="(item, index) in items"
        :key="index"
        :item="item"
        :class="{'mb-[16px]':index + 1 != items.length }"
        @submit="val => handleSubmit(val,item)"
        @toggle-status="val => handleToggleStatus(val,item)"
        @remove="val => handleDelete(val,item)"
      />
    </div>
  </div>
</template>

<script>
import IntegrationItem from '@/views/dashboard/integration/components/IntegrationItem'
import IntegrationsMixin from '@/mixins/IntegrationsMixin'
export default {
  components: {IntegrationItem},
  mixins: [IntegrationsMixin],
  data () {
    return {
      items: [
        {
          title: 'Google Analytics',
          active: false,
          image: require('@/assets/images/integrations/google-analytics.svg'),
          icon: require('@/assets/images/integrations/google-analytics-icon.svg'),
          img_con_styles:'background-color:#F7F7F7;',
          alpha_code: 'GoogleAnalytics',
          data: [
            {
              label: 'Measurement ID',
              value: '',
              type: 'text',
              key:'measurement_id',
              dirty:false,
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Measurement ID'
                }
              }
            }
          ]
        },
        {
          title: 'HotJar',
          active: false,
          image: require('@/assets/images/integrations/hotjar.svg'),
          icon: require('@/assets/images/integrations/hotjar-icon.svg'),
          img_con_styles:'background-color:#F7F7F7;',
          img_styles:'height:120px;',
          alpha_code: 'HotJar',
          data: [
            {
              label: 'Tracking Code',
              value: '',
              key:'code',
              dirty:false,
              type: 'textarea',
              validation: (val) => {
                return {
                  valid: !!val,
                  message: 'Invalid Tracking Code'
                }
              }
            }
          ]
        }
        
      ]
    }
  }
        
}
</script>