<template>
  <div class="pt-[18px]">
    <b-form-group class="!mb-[4px]">
      <div class="flex items-center relative">
        <label class="absolute top-[-14px]">Font Size</label>
        <div class="pr-3 min-w-[175px]">
          <vue-slider
            v-model="fontSize"
            :dot-size="17"
            :max="80"
            :min="1"
            class="editor-slider-1"
            tooltip="none"
          />
        </div>

        <LinkedControlItem
          v-model="fontSize"
          :max="80"
          :min="1"
          class="d-block !w-[60px]"
        />
      </div>
    </b-form-group>

    <b-form-group
      label="Letter Spacing"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="letterSpacing"
            :interval="1"
            :max="20"
            :min="0"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <LinkedControlItem
            v-model="letterSpacing"
            :max="20"
            :min="0"
            class="d-block !w-[50px]"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="fullWidth">
        Full Width
      </b-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import ModControlMixin from '@/components/mixins/ModControlMixin'
import _ from 'lodash'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

export default {
  name: 'ButtonSize',
  components: {LinkedControlItem},
  mixins: [ModControlMixin],
  computed: {
    fontSize: {
      get () {
        return parseInt(_.get(this.config, ['fontSize', this.resolutioner], _.get(this.config, ['fontSize', 'lg'], 16)))
      },
      set (val) {
        this.$set(this.config, 'fontSize', {..._.set(this.config.fontSize || {}, [this.resolutioner], val)})
      }
    },
    letterSpacing: {
      get () {
        return _.get(this.config, ['letterSpacing', this.resolutioner], _.get(this.config, ['letterSpacing', 'lg'], 0))
      },
      set (val) {
        this.$set(this.config, 'letterSpacing', {..._.set(this.config.letterSpacing || {}, [this.resolutioner], val)})
      }
    },
    fullWidth: {
      get () {
        return _.get(this.config, ['btnBlock', this.resolutioner], this.config.btnBlock)
      },
      set (val) {
        const lg = _.get(this.config.btnBlock, ['lg'], this.config.btnBlock)
        const md = _.get(this.config.btnBlock, ['md'], this.config.btnBlock)
        const sm = _.get(this.config.btnBlock, ['sm'], this.config.btnBlock)


        if (this.resolutioner === 'lg') {
          const obj = {
            lg: val,
            md: md === lg ? val : md,
            sm: sm === lg ? val : sm
          }

          return this.$set(this.config, 'btnBlock', obj)
        }

        this.$set(this.config, 'btnBlock', {
          ..._.set({
            lg,
            md,
            sm
          }, [this.resolutioner], val)
        })
      }
    },
    size: {
      get () {
        const sizes = {
          sm: 0.8,
          md: 1,
          lg: 1
        }

        return _.get(this.config, ['size', this.resolutioner], sizes[this.resolutioner])
      },
      set (val) {
        const sizes = {
          sm: 0.8,
          md: 1,
          lg: 1
        }

        this.$set(this.config, 'size', {..._.set(sizes, this.resolutioner, val)})
      }
    }
  }
}
</script>