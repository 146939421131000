export default {
  methods: {
    onChoose (e) {
      if (e.from.children.length === 1) {
        // e.from.style.height = '100px'
      }
    },
    onUnchoose () {
      // e.from.style.height = null
    },
    onDragStart (e) {
      if (e.from.children.length === 1) {
        e.from.classList.add('empty-row-drag', 'h-100')
      }
      document.body.classList.add('on-drag-element')
    },
    onDragEnd (e) {
      if (e.from.children.length === 1) {
        e.from.classList.remove('empty-row-drag', 'h-100')
      }
      document.body.classList.remove('on-drag-element')
    }
  }
}