export default {
  computed: {
    hoverConfig () {
      if (!this.config.hover) {
        this.$set(this.config, 'hover', {})
      }

      return this.config.hover
    }
  }
}