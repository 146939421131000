<template>
  <div>
    <div class="flex items-center gap-[5px] sid-select">
      <b-input
        v-model="val"
        class="!w-[42px] text-center !text-[#7E7E7E] !text-[13px] !h-[29px] !px-[5px]"
        :placeholder="sidValueMutation == 'auto' ? 'auto' : '-'"
        @input="val => $emit('change', val)"
      />

      <v-select
        v-model="dimension"
        :options="dimensions"
        :searchable="false"
        append-to-body
        class="custom-select text-lowercase !w-[64px] pr-0"
        left
        top
      >
        <template v-slot:selected-option="option">
          <div
            class="text-lowercase"
            v-text="option.label"
          />
        </template>

        <template v-slot:option="option">
          <div
            class="text-lowercase"
            v-text="option.label"
          />
        </template>
      </v-select>
    </div>

    <div class="flex justify-end pt-2 pr-1">
      <span
        class="text-[13px] text-[#44474A] hover:opacity-80 cursor-pointer underline"
        @click="() => val = ''"
      >Reset</span>
    </div>
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  name: 'SidInputWithDimension',
  mixins: [SidConcept, ResolutionMixin],
  props: {
    defaultDimension: {
      type: String,
      default: 'px'
    }
  },
  data () {
    return {
      d: null,
      dim: null,
      dimensions: ['%', 'px', 'rem', 'vh', 'vw'],
      dimensionData: {
        px: {
          max: 4000,
          min: -4000
        },
        rem: {
          max: 50,
          min: -50
        },
        '%': {
          max: 200,
          min: -200
        },
        vh: {
          max: 100,
          min: 0
        },
        vw: {
          max: 100,
          min: 0
        }
      }
    }
  },
  computed: {
    dimension: {
      get () {
        if (this.dim) return this.dim
        const dim = this.sidValueMutation && this.sidValueMutation !== 'auto' ? /(-?\d*(?:\.\d+)?)\s*(\D+)/.exec(this.sidValueMutation.trim())[2] : this.dimensions[0]

        if (!this.dimensions.includes(dim)) return this.dimensions[0]

        return dim
        // return this.sidValueMutation !== '' ? dim : '%'
      },
      set (val) {
        this.dim = val
        if (!this.val) return
        this.addSidRules([[this.getSid(), [this.ruleName, `${parseInt(this.val)}${val}`]]])

        // if (this.val > this.dimensionData[val].max) {
        //   this.val = this.dimensionData[val].max
        // }
      }
    },
    val: {
      get () {
        return this.d !== null ? this.parseInt(this.d) : this.parseInt(this.sidValueMutation)
      },
      set (val) {
        this.d = val
        this.addSidRules([[this.getSid(), [this.ruleName, val !== '' ? `${val}${this.dimension}` : '']]])

        // if (this.d === '') {
        //   setTimeout(() => {
        //     console.log(this.getSidStyle())
        //     this.d = parseFloat(this.getSidStyle())
        //   })
        // }
      }
    }
  },
  watch: {
    sid () {
      this.d = null
      this.dim = null
    },
    resolutioner () {
      setTimeout(() => {
        this.d = this.getSidStyle()
        this.dimension = this.getSidStyle() && this.getSidStyle() !== 'auto' ? /(-?\d*(?:\.\d+)?)\s*(\D+)/.exec(this.getSidStyle().trim())[2] : this.dimensions[0]
      })
    }
  }
}
</script>