<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :style="{...fullMarginSubject(section.options.content.description)}"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('description')"
    @highlight-click="contentClick"
  >
    <template slot="section-type-icon">
      <svg
        fill="none"
        height="16"
        viewBox="0 0 21 16"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M11.8 1.57144H14.4V2.85714H17V-1H4V2.85714H6.60001V1.57144H9.20002V14.4286H6.60001V17H14.3987V14.4286H11.8V1.57144Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              fill="white"
              height="15.75"
              width="21"
            />
          </clipPath>
        </defs>
      </svg>
    </template>

    <Template :section="section">
      <content-editable
        ref="content"
        v-model="section.options.content.description.text"
        :customs="section.options.content.description"
        type="headline"
      />
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditable from '@builder/components/builder/utils/ContentEditable'
import Template from './Template'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'FeaturedItems1',

  components: {Template, ContentEditable, Highliter},

  mixins: [SectionMixin, BorderMixin],

  methods: {
    contentClick () {
      this.$refs.content.initer()
    }
  }
}
</script>
