const state = () => ({
  list:{
    loading:false,
    project_id:null,
    page_id:null,
    data:[]
  }
})
  
const getters = {}
  
const actions = {
  async getList ({state},{projectId,pageId}) {
    state.list.loading = true
    await axios.get(`api/projects/${projectId}/integrations/${pageId}/exclusions`)
      .then(({data}) => {
        state.list = {
          loading:false,
          project_id:projectId,
          page_id:pageId,
          data:data.data
        }
      }).finally(()=> state.list.loading = false)
  },
  async update (_, data) {
    return await axios.post(`api/projects/${data.project_id}/integrations/${data.page_id}/exclusions`, data)
  }
}
  
const mutations = {}
  
export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
  