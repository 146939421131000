<template>
  <div class="sid-select">
    <motion-types
      v-model="selectedMotionsType"
      :motion="subjectMotions"
    />

    <span
      v-if="selectedMotionsType"
      class="mt-[-10px] block mb-3 text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
      @click="reset"
    >
      Remove motion
    </span>

    <mouse-move-motion
      v-if="selectedMotionsType === 'mouseMove'"
      :subject-motions="subjectMotions"
    />
    <scroll-motion
      v-else-if="selectedMotionsType === 'scroll'"
      :subject-motions="subjectMotions"
    />
    <static-motion
      v-else-if="selectedMotionsType === 'static'"
      :subject-motions="subjectMotions"
    />
    <viewport-motion
      v-else-if="selectedMotionsType === 'viewport'"
      :subject-motions="subjectMotions"
    />
  </div>
</template>

<script>
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import MotionTypes from '@/components/editor/components/motions/MotionTypes'
import MouseMoveMotion from '@/components/editor/components/motions/motion-types/MouseMoveMotion'
import ScrollMotion from '@/components/editor/components/motions/motion-types/ScrollMotion'
import StaticMotion from '@/components/editor/components/motions/motion-types/StaticMotion'
import ViewportMotion from '@/components/editor/components/motions/motion-types/ViewportMotion'

export default {
  name: 'Motions',
  components: {
    ViewportMotion,
    StaticMotion,
    ScrollMotion,
    MouseMoveMotion, MotionTypes},
  mixins: [ResolutionMixin],
  props: {
    subject: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedMotionsType: null,
      progress: 0,
      selectedEffect: false
    }
  },
  computed: {
    subjectMotions () {
      if (!this.subject.motions) {
        this.$set(this.subject, 'motions', {})
      }
      return this.subject.motions
    }
  },
  created () {
    if (Object.keys(this.subjectMotions).length) {
      this.selectedMotionsType = Object.keys(this.subjectMotions)[0]
    }
  },
  methods: {
    reset () {
      this.$delete(this.subject.motions, this.selectedMotionsType)
      this.selectedMotionsType = null
    }
  }
}
</script>