import {mapState} from 'vuex'

export default {
  props: {
    config: {
      type: Object
    },
    hide: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: [Array, Object],
      default: null
    }
  },
  computed: {
    ...mapState('editor', {
      allConfig: state => state.config,
      section: state => state.section,
      openedItem: state => state.openedItem,
      resolution: state => state.resolution,
      tablet: state => state.tablet
    })
  }
}
