<template>
  <div>
    <b-form-group>
      <!--      <b-textarea-->
      <!--        v-model="words"-->
      <!--        rows="5"-->
      <!--        placeholder="Good morning, Good afternoon, Good evening"-->
      <!--      />-->

      <draggable
        v-model="localWords"
        handle=".move"
        @change="onChangeList"
      >
        <div
          v-for="(item, index) in localWords"
          :key="index"
        >
          <div
            class="!border-[#E2E5EC] group h-[29px] items-center rounded-[2px] flex pl-[13px] hover:bg-[#F1F5FD] cursor-pinter mb-[12px]"
            style="border:1px solid;"
            @click.stop="openItemSettings(index)"
          >
            <div class="mr-[11px] move cursor-move">
              <svg
                fill="none"
                height="11"
                viewBox="0 0 11 11"
                width="11"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.57745 0.894104H0.568359V3.8941H3.57745V0.894104Z"
                  fill="#3D82EA"
                />
                <path
                  d="M10.5984 0.894104H7.58936V3.8941H10.5984V0.894104Z"
                  fill="#3D82EA"
                />
                <path
                  d="M3.57745 7.8941H0.568359V10.8941H3.57745V7.8941Z"
                  fill="#3D82EA"
                />
                <path
                  d="M10.5984 7.8941H7.58936V10.8941H10.5984V7.8941Z"
                  fill="#3D82EA"
                />
              </svg>
            </div>

            <span
              class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]"
              :class="{'!text-[#3D82EA]': openedIndex === index}"
            >
              {{ item.text }}
            </span>

            <button
              v-b-tooltip
              class="w-[38px] h-[29px] text-center border-right-0 border bg-[#fff] p-0 ml-auto"
              title="Delete"
              @click="removeItem(index)"
            >
              <svg
                fill="none"
                height="10"
                viewBox="0 0 10 10"
                width="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
                  fill="#6E747A"
                />
              </svg>
            </button>
            <button
              class="w-[38px] h-[29px] text-center border-right-0 border bg-[#fff] p-0"
            >
              <svg
                :class="{'rotate-[90deg]': openedIndex === index}"
                fill="none"
                height="10"
                viewBox="0 0 7 10"
                width="7"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                data-v-c5c9f942=""
                d="M0.879944 9.23245L4.98805 4.89924L0.879944 0.566031"
                stroke="#6E747A"
                stroke-width="1.5"
              /></svg>
            </button>
          </div>

          <div
            v-if="openedIndex === index"
            class="mb-2"
          >
            <b-form-group label="Text">
              <b-input
                v-model="item.text"
                size="sm"
                autofocus
              />
            </b-form-group>

            <b-form-group label="Text Color (optional)">
              <div class="flex items-center gap-[10px]">
                <color-picker
                  :color="item.color"
                  @input="event => item.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
                />

                <b-link
                  class="link-muted"
                  @click="() => item.color = ''"
                >
                  Set Default
                </b-link>
              </div>
            </b-form-group>
          </div>
        </div>
      </draggable>

      <button
        class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
        @click="addItem"
      >
        Add Item
        <span class="text-[#3D82EA] text-[20px]">+</span>
      </button>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center gap-[10px]">
        <label class="pb-0">Speed <span class="text-uppercase">(MS)</span></label>
        <linked-control-item
          v-model="localSpeed"
          :min="0"
          :max="1500"
          :step="10"
          class="d-block !w-[60px]"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center gap-[10px]">
        <label class="pb-0">Back Speed <span class="text-uppercase">(MS)</span></label>
        <linked-control-item
          v-model="backSpeed"
          :min="0"
          :max="1500"
          :step="10"
          class="d-block !w-[60px]"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="fade">
        Fade Effect
      </b-checkbox>
    </b-form-group>

    <div class="flex items-center gap-[10px]">
      <b-btn
        variant="primary"
        :disabled="!localWords.length"
        @click="updateWords"
      >
        {{ saved ? 'Saved!' : 'Save' }}
      </b-btn>
      <b-btn
        variant="danger"
        @click="remove"
      >
        Remove
      </b-btn>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
import Typed from 'typed.js'
import ColorPicker from '@/components/editor/utils/ColorPicker'
export default {
  name: 'TypedAnimationControls',
  components: {ColorPicker, LinkedControlItem, draggable},
  props: {
    commands: {
      required: true
    },
    getMarkAttrs: {
      required: true
    },
    isActive: {
      required: true
    },
    editor: {
      required: true
    }
  },
  data () {
    return {
      localWords: [],
      styles: [],
      localSpeed: 70,
      backSpeed: 70,
      fade: false,
      openedIndex: -1,
      typed: null,
      saved: false
    }
  },
  mounted () {
    this.localWords = this.getMarkAttrs.words.split('|').map(this.parseItem)
    this.localSpeed = parseInt(this.getMarkAttrs.speed)
    this.backSpeed = parseInt(this.getMarkAttrs.backspeed)
    this.fade = this.getMarkAttrs.fade
  },
  methods: {
    parseItem (string) {
      const htmlString = string
      // Create a DOMParser instance
      const parser = new DOMParser()

      // Parse the HTML string
      const doc = parser.parseFromString(htmlString, 'text/html')

      // Get the first span element
      const spanElement = doc.querySelector('span')

      // // Extract the color value from the style attribute
      const colorValue = spanElement.style.color

      return {
        color: colorValue,
        text: spanElement.textContent
      }
    },
    addItem () {
      this.localWords.push({
        text: '',
        color: ''
      })
      this.openedIndex = this.localWords.length - 1
    },
    openItemSettings (index) {
      if (this.openedIndex === index) {
        return this.openedIndex = -1
      }
      this.openedIndex = index
    },
    updateWords () {
      if (this.typed) {
        this.typed.destroy()
      }

      const strings = this.localWords.map(item => {
        return `<span style="color:${item.color}">${item.text}</span>`
      })

      this.commands.typed({words: strings.join('|'), speed: this.localSpeed, backspeed: this.backSpeed, fade: this.fade})

      setTimeout(() => {
        const html = this.editor.getHTML()
        const $textGhostContainerEl = this.editor.view.dom.closest('.text-container').firstElementChild
        const $textGhostEl = $textGhostContainerEl.querySelector('.ProseMirror')
        const $typedEl = $textGhostEl.querySelector('typed')

        this.editor.view.dom.parentElement.classList.add('d-none')
        $textGhostContainerEl.classList.remove('text-ghost')
        $typedEl.textContent = ''

        this.typed = new Typed($typedEl, {
          strings: [...strings, strings[0]],
          typeSpeed: this.localSpeed,
          contentType: 'html',
          backDelay: 500,
          backSpeed: this.backSpeed,
          fadeOut: this.fade,
          onComplete: () => {
            $textGhostEl.innerHTML = html
            $textGhostContainerEl.classList.add('text-ghost')
            this.editor.view.dom.parentElement.classList.remove('d-none')
            this.typed.destroy()
          }
        })
      })

      this.saved = true
      setTimeout(() => this.saved = false, 1000)
    },
    update () {
      const strings = this.localWords.map(item => {
        return `<span style="color:${item.color}">${item.text}</span>`
      })

      this.commands.typed({words: strings.join('|'), speed: this.localSpeed, backspeed: this.backSpeed, fade: this.fade})
    },
    onChangeList () {
      this.update()
    },
    removeItem (index) {
      if (this.localWords.length === 1) {
        return this.remove()
      }

      this.localWords.splice(index, 1)
      this.update()
    },
    remove () {
      this.commands.typed(false)
    }
  }
}
</script>