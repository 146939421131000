<template>
  <div class="flex flex-shrink-0 flex-col w-[536px] bg-[#F6F6F6] px-[4rem] py-[3rem] border-l border-[#E3E3E3]">
    <h4 class="text-[1.25re] leading-[1.5rem] font-[700] text-[#363636] text-center font-[ProximaNova] m-0">
      Upgrade To {{ plan.name }} Today!
    </h4>

    <div
      v-if="!month && !year"
      class="w-full flex justify-center mt-[1.25rem]"
    >
      <plan-select
        v-model="active"
        :plans="plan.duration"
      />
    </div>

    <div class="w-full inline-flex items-center justify-between mt-[1.875rem] pb-[1rem] border-b border-[#E3E3E3]">
      <h4 class="text-[.875rem] leading-[1.25rem] font-[600] text-[#363636] font-[ProximaNova] m-0">
        Estage {{ plan.name }} ({{ startCase(selectedPlan.name) }})
      </h4>
      <p class="text-[.875rem] leading-[1.25rem] text-[#363636] font-[ProximaNova] m-0">
        ${{ selectedPlan.price }}/{{ selectedPlan.interval }}
      </p>
    </div>

    <purchase-plan-card-form 
      class="mt-[1.25rem]"
      :plan="plan"
      @submit="onUpgrade"
    />
  </div>
</template>

<script>
import PlanSelect from '@/components/editor/modals/purchase-element/business-plus/controls/PlanSelect.vue'
import TextMixins from '@/components/mixins/TextMixins'
import PurchasePlanCardForm from '@/components/editor/modals/purchase-plan/PurchasePlanForm.vue'
export default {
  name: 'PurchasePlanUpgrade',
  components: {
    PurchasePlanCardForm,
    PlanSelect
  },
  mixins: [TextMixins],
  props: {
    plan: {
      type: Object,
      required: true
    },
    month: {
      type: Boolean,
      require: false
    },
    year: {
      type: Boolean,
      require: false
    }
  },
  data () {
    return {
      active: 0,
      paymentSuccess: false
    }
  },
  computed: {
    selectedPlan () {
      return this.plan.duration[this.active]
    }
  },
  methods: {
    onUpgrade ({pm_id, setLoading}) {
      setLoading(true)
      axios.post('api/user/payment-systems/change-plan', {
        plan: this.selectedPlan.value,
        source: pm_id
      })
        .then(() => {
          this.paymentSuccess = true
          this.$emit('success')
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Payment Declined.',
            text: 'The card information was declined',
            showConfirmButton: false,
            timer: 5000
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  },
  mounted () {
    if (this.year && !this.month) {
      this.active = 0
    } else if (this.month && !this.year) {
      this.active = 1
    }
  }
}
</script>