<template>
  <div class="flex flex-col gap-[.625rem]">
    <iframe
      src="https://player.vimeo.com/video/877288323?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      title="ABS to ESTAGE Onboarding"
      class="aspect-video w-full rounded-[0.25rem] bg-[#D9D9D9]"
    />

    <div class="grid grid-cols-2 gap-[.625rem]">
      <b-form-group class="w-full !mb-[0]">
        <b-form-input
          id="first-name"
          v-model="form.first_name"
          v-validate="'required|min:2|max:24'"
          name="first-name"
          :class="{'is-invalid': errors.has('first-name') ? true : null}"
          placeholder="First Name"
          autocomplete="first-name"
          x-autocompletetype="first-name"
        />
      </b-form-group>

      <b-form-group class="w-full !mb-[0]">
        <b-form-input
          id="last-name"
          v-model="form.last_name"
          v-validate="'required|min:2|max:24'"
          name="last-name"
          :class="{'is-invalid': errors.has('last-name') ? true : null}"
          placeholder="Last Name"
          autocomplete="last-name"
          x-autocompletetype="last-name"
        />
      </b-form-group>
    </div>

    <b-form-group class="w-full !mb-[0]">
      <b-form-input
        id="email"
        v-model="form.email"
        v-validate="'required|email'"
        name="email"
        :class="{'is-invalid': errors.has('email') ? true : null}"
        placeholder="Email Address"
        autocomplete="email"
        x-autocompletetype="email"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'AccountDetailStep',
  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>