<template>
  <div>
    <b-form-group>
      <template slot="label">
        <div class="flex justify-between">
          <span v-text="label" />
          <span>{{ progress }}%</span>
        </div>
      </template>

      <vue-slider
        v-model="progress"
        :dot-size="17"
        :interval="10"
        :max="100"
        :min="0"
        class="editor-slider-1"
        tooltip="none"
        @change="onChange"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'MotionProgress',
  props: {
    label: {
      type: String
    }
  },
  data () {
    return {
      progress: 0
    }
  },
  methods: {
    onChange () {
      this.$emit('input', this.progress)
    }
  }
}
</script>