<template>
  <b-modal
    v-if="Object.keys(author).length"
    id="author-modal-settings"
    v-model="modal"
    size="lg"
    class="editor"
    hide-footer
    hide-header
    centered
    lazy
  >
    <div class="d-flex align-items-center mb-4">
      <b-link @click="modal = false">
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
            fill="#6E747A"
          />
        </svg>
      </b-link>
    </div>

    <div class="text-center mb-5">
      <h2 class="font-weight-bold">
        Add New Author Profile
      </h2>
    </div>

    <b-form @submit.prevent="submit">
      <b-row>
        <b-col
          cols="8"
          offset="2"
        >
          <b-form-group
            class="own-custom-control mb-4"
            label="Name"
          >
            <b-input
              v-model="author.name"
              required
              size="lg"
              placeholder="Enter Name..."
            />
          </b-form-group>

          <b-form-group class="mb-4">
            <b-row no-gutters>
              <b-col cols="1">
                <svg
                  v-if="!author.avatar.src"
                  class="mr-3"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="20"
                    cy="20"
                    r="20"
                    fill="#E2E5EC"
                  />
                  <path
                    opacity="0.3"
                    d="M26.4238 21.6514V20.3525H20.7158V14.2344H19.041V20.3525H13.333V21.6514H19.041V27.7695H20.7158V21.6514H26.4238Z"
                    fill="#6E747A"
                  />
                </svg>
                <b-img
                  v-else
                  rounded="circle"
                  height="40"
                  width="40"
                  class="!h-[40px] !w-[40px]"
                  :src="author.avatar.src"
                />
              </b-col>

              <b-col
                cols="5"
                class="pl-3 pt-2"
              >
                <image-upload
                  class="position-relative"
                  :image="author.avatar"
                >
                  <div class="absolute-center-x">
                    <div class="d-flex text-[14px] align-items-center justify-content-center flex-nowrap">
                      <div class="text-nowrap mr-2">
                        Upload Profile Image
                      </div>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.10627 0.375L11.1365 4.40813C11.2475 4.53033 11.3071 4.69054 11.303 4.85552C11.299 5.0205 11.2316 5.17759 11.1148 5.29422C10.9981 5.41084 10.8409 5.47803 10.6759 5.48187C10.5109 5.48571 10.3508 5.42589 10.2287 5.31483L7.74625 2.8301V10.4188C7.74625 10.5888 7.67871 10.7519 7.55847 10.8721C7.43824 10.9924 7.27516 11.0599 7.10513 11.0599C6.93509 11.0599 6.77201 10.9924 6.65178 10.8721C6.53154 10.7519 6.464 10.5888 6.464 10.4188V2.83124L3.98155 5.31483C3.92197 5.37436 3.85126 5.42158 3.77344 5.45379C3.69562 5.48599 3.61222 5.50255 3.52799 5.50253C3.44377 5.5025 3.36038 5.48589 3.28258 5.45363C3.20478 5.42138 3.1341 5.37411 3.07456 5.31454C3.01503 5.25497 2.96781 5.18426 2.9356 5.10644C2.9034 5.02861 2.88683 4.94521 2.88686 4.86099C2.88689 4.77677 2.9035 4.69338 2.93576 4.61558C2.96801 4.53778 3.01527 4.46709 3.07485 4.40756L7.10627 0.375ZM13.3386 12.3427C13.7039 12.3427 13.9997 12.63 13.9997 12.9839C13.9997 13.3378 13.7039 13.625 13.3386 13.625H0.985051C0.61861 13.625 0.322266 13.3378 0.322266 12.9839C0.322266 12.63 0.61861 12.3427 0.985051 12.3427H13.3386Z"
                          fill="#6E747A"
                        />
                      </svg>
                    </div>
                  </div>
                </image-upload>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group
            class="own-custom-control mb-4"
            label="Email Address"
          >
            <b-input
              v-model="author.email"
              type="email"
              required
              size="lg"
              placeholder="Enter Email..."
            />
          </b-form-group>

          <div class="d-flex justify-content-center mb-4">
            <b-form-group>
              <b-btn
                type="submit"
                variant="primary"
                class="font-size-16 font-weight-bold rounded-sm btn-custom-lg"
                size="lg"
              >
                {{ !author.id ? 'Add New' : 'Save' }} Profile
              </b-btn>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  name: 'BlogAddAutorModal',
  props: {
    author: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      modal: false
    }
  },
  methods: {
    submit () {
      this.modal = false

      if (!this.author.id) {
        return this.$store.dispatch('authors/store', this.author)
      }

      return this.$store.dispatch('authors/update', this.author)
    }
  }
}
</script>
