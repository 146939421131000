const sections = require.context('@builder/components/builder/elements', true, /.js/)

let sec = {}

const customGroupOrder = [
  'Typography',
  'Basic',
  'Media',
  'Live Interactions',
  'Forms',
  'Advanced',
  'Navigation',
  'Others'
]

sections.keys().forEach(fileName => {
  if (!sections(fileName).group) return false

  const group = sections(fileName).group

  if (!sec.hasOwnProperty(group)) {
    sec[group] = []
  }

  sec[group].push({
    ...sections(fileName),
    section: sections(fileName).default
  })
})

let orderedSec = {}

customGroupOrder.forEach(group => {
  if (sec[group]) {
    orderedSec[group] = sec[group]
  }
})

Object.keys(sec).forEach(group => {
  if (!orderedSec.hasOwnProperty(group)) {
    orderedSec[group] = sec[group]
  }
})

export default orderedSec
