export const timeMask = function (value) {
  const hours = [
    /[0-2]/,
    value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/
  ]
  const minutes = [/[0-5]/, /[0-9]/]
  const seconds = [/[0-5]/, /[0-9]/]
  if (value.length > 3) {
    return [...hours, ':', ...minutes, ':', ...seconds]
  } else if (value.length > 2) {
    return [...hours, ':', ...minutes]
  }
  return hours
}