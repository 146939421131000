<template>
  <div
    :class="[`justify-content-${btnAlign}`, {'btn-edge': alignEdges}]"
    :data-aos="animations.animation"
    :data-aos-delay="animations.delay"
    :data-aos-duration="animations.duration || 400"
    :style="{...fullMarginSubject(customs, 'margin', [10,10,0,0])}"
    class="d-flex z-[1] relative"
    data-aos-offset="200"
    data-aos-once="true"
  >
    <div
      :class="[hoverAnimation, {'btn-block': fullWidth}]"
    >
      <b-button
        :block="fullWidth"
        :class="[customs.resolutionStyle['lg'].shadow, size, {'btn-icon': !customs.text}]"
        :style="style"
        class="position-relative z-index-100"
        @click="onClick"
        @mouseenter="event => hover = true"
        @mouseleave="event => hover = false"
      >
        <div class="d-flex align-items-center justify-content-center">
          <i
            v-if="button.icon && position === 'prepend'"
            :class="button.icon"
            class="prepend-icon el-button-subtext"
            :style="{color: iconColor, marginRight: offset}"
          />
          <div class="text-center">
            <div class="opacity-0 h-[0px]">
              {{ customs.text }}
            </div>
            {{ text }}
          </div>
          <i
            v-if="button.icon && position === 'append'"
            :class="button.icon"
            class="append-icon el-button-subtext"
            :style="{color: iconColor, marginLeft: offset}"
          />
        </div>
        <div
          v-if="hover ? hoverSubtext : customs.subtext"
          :style="subTextStyle"
          class="d-flex align-items-center justify-content-center el-button-subtext"
        >
          <i
            v-if="button.subtextIcon && subtextIconPosition === 'prepend'"
            :class="button.subtextIcon"
            :style="{color: button.subtextIconColor, marginRight: subtextIconOffset}"
          />
          <div
            :class="{'mx-auto': button.subtextIconAlignEdges}"
            class="text-center"
          >
            {{ hover ? hoverSubtext : customs.subtext }}
          </div>
          <i
            v-if="button.subtextIcon && subtextIconPosition === 'append'"
            :class="button.subtextIcon"
            :style="{color: button.subtextIconColor, marginLeft: subtextIconOffset}"
          />
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
import ComponentProvider from '@builder/components/mixins/ComponentProvider'
import ThemeMixin from '@builder/components/mixins/ThemeMixin'
import Animations from '@builder/components/mixins/Animations'
import RouterMixin from '@builder/components/mixins/RouterMixin'
import BackgroundGradientMixin from '@builder/components/mixins/BackgroundGradientMixin'
import _ from 'lodash'
import ResolutionMixin from '../../../mixins/ResolutionMixin'
import ScrollToElementMixin from '../../../mixins/ScrollToElementMixin'
import GlobalStyleButton from '@/components/mixins/GlobalStyleButton'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'PubEsButton',

  mixins: [GlobalStyleButton, ComponentProvider, ResolutionMixin, ThemeMixin, Animations, RouterMixin, BackgroundGradientMixin, ScrollToElementMixin, BorderMixin],

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    buttonType: {
      type: String,
      default: 'button'
    }
  },

  data () {
    return {
      hover: false
    }
  },

  computed: {
    hoverAnimation () {
      return !this.customs.pressetID ? _.get(this.globalStyles, 'button.options.content.button.hoverAnimation', '') : this.customs.hoverAnimation
    },
    text () {
      return this.hover ? _.get(this.customs, 'hover.text', this.customs.text) || this.customs.text : this.customs.text
    },
    hoverSubtext () {
      return _.get(this.customs, 'hover.subtext', '')
    },
    iconColor () {
      return this.hover ? _.get(this.button, 'hover.iconColor', this.button.iconColor) : this.button.iconColor || this.button.resolutionStyle['lg'].color
    },
    button () {
      return this.customs.pressetID === 0 ? this.globalStyleButton.options.content.button : this.customs
    },
    border () {
      const border = this.button.border || [1, 1, 1, 1]
      return {
        borderTopWidth: `${border[0]}px`,
        borderRightWidth: `${border[1]}px`,
        borderBottomWidth: `${border[2]}px`,
        borderLeftWidth: `${border[3]}px`
      }
    },
    radius () {
      const transformValues = {
        rouneded: 6,
        squared: 0,
        pill: 40
      }
      const value = transformValues[this.button.resolutionStyle['lg'].shape || this.globalStyles.buttons.shape]
      const radius = this.button.radius || [value, value, value, value]

      return {
        borderTopLeftRadius: `${radius[0]}px`,
        borderTopRightRadius: `${radius[1]}px`,
        borderBottomLeftRadius: `${radius[2]}px`,
        borderBottomRightRadius: `${radius[3]}px`
      }
    },
    shadow () {
      const hoverShadow = !this.customs.pressetID ? _.get(this.globalStyles, 'button.options.content.button.hoverShadow.lg', {}) : _.get(this.customs, ['hoverShadow', 'lg'], {})
      const shadow = this.hover ? hoverShadow.shadowOptions || this.button.shadowOptions : this.button.shadowOptions
      const color = this.hover ? hoverShadow.shadowColor || this.button.shadowColor || '#DADADA' : this.button.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    size () {
      const sizes = {
        sm: 16,
        md: 16,
        lg: 16
      }

      return _.get(this.button, ['size', this.resolutioner], sizes[this.resolutioner])
    },
    fontSize () {
      const _fontSize = _.get(this.button, ['fontSize', this.resolutioner], _.get(this.button, ['fontSize', 'lg'], 16))
      return _fontSize
    },
    letterSpacing () {
      const _fontSize = _.get(this.button, ['letterSpacing', this.resolutioner], _.get(this.button, ['letterSpacing', 'lg'], 0))
      return _fontSize
    },
    hoverSize () {
      return this.hover ? `scale(${_.get(this.button, ['hover', 'resolutionStyle', this.resolutioner, 'size'], this.fontSize)})` : ''
    },
    offset () {
      return `${this.button.iconOffset || 10}px`
    },
    subtextIconOffset () {
      return `${this.button.subtextIconOffset || 10}px`
    },
    position () {
      let position = this.button.iconPosition || 'append'

      if (position === 'left-edge') {
        return 'prepend'
      }

      if (position === 'right-edge') {
        return 'append'
      }

      return position
    },
    alignEdges () {
      let position = this.button.iconPosition

      if (position === 'right-edge' || position === 'left-edge') {
        return true
      }

      return false
    },
    subtextIconPosition () {
      return this.button.subtextIconPosition || 'append'
    },
    btnStyle () {
      return !this.button.resolutionStyle['lg'].btnStyle ? this.globalStyles.buttons.outlined ? 'outlined' : 'filled' : this.button.resolutionStyle['lg'].btnStyle
    },
    btnAlign () {
      return _.get(this.button.resolutionStyle, [this.resolutioner, 'align'], this.button.resolutionStyle.sm.align)
    },
    backgroundColor () {
      const color = this.button.resolutionStyle['lg'].backgroundColor === 'colored' ? _.get(this.globalStyles, 'button.options.content.button.resolutionStyle.lg.backgroundColor', this.globalStyles.colors.buttons) : this.button.resolutionStyle['lg'].backgroundColor

      if (this.hover) {
        return _.get(this.button, 'hover.resolutionStyle.lg.backgroundColor', color)
      }

      return color
    },
    hoverGradient () {
      const gradient = _.get(this.button, 'hover.backgroundGradient', null)
      const gradientDirection = _.get(this.button, 'hover.gradientDirection', 90)

      if (_.get(this.button, 'hover.backgroundType') === 'gradient' && gradient) {
        const values = gradient.map(gradient => {
          return `${gradient.color} ${gradient.position}%`
        })
        return `linear-gradient(${gradientDirection}deg,${values})`
      }

      return this.gradient
    },
    style () {
      const padding = this.fullPaddingSubject(this.customs, 'paddingV2', [13, 13, 40, 40])

      return {
        fontFamily: this.button.fontFamily || _.get(this.globalStyles, ['button', 'options', 'content', 'button', 'fontFamily'], 'Roboto'),
        fontWeight: this.button.fontWeight,
        color: this.hover ? _.get(this.button, 'hover.color', this.button.resolutionStyle['lg'].color) : this.button.resolutionStyle['lg'].color,
        backgroundColor: this.backgroundColor,
        backgroundImage: this.hover ? this.hoverGradient : this.gradient,
        border: 'solid',
        borderColor: this.button.resolutionStyle['lg'].borderColor || 'transparent',
        fontSize: `${this.fontSize}px`,
        paddingTop: padding.paddingTop,
        paddingBottom: padding.paddingBottom,
        paddingLeft: padding.paddingLeft,
        paddingRight: padding.paddingRight,
        letterSpacing: `${this.letterSpacing}px`,
        lineHeight: _.get(this.button.resolutionStyle[this.resolutioner], 'fontFamily', 1.5),
        ...this.shadow,
        ...this.borderSubject(this.button),
        ...this.radiusSubject(this.button)
      }
    },
    subTextStyle () {
      return {
        color: this.hover ? _.get(this.button, 'hover.subtextColor', this.button.subTextColor) : this.button.subTextColor || this.button.resolutionStyle['lg'].color,
        fontSize: this.button.subTextFontSize === 'Global Style' ? _.get(this.globalStyles, 'buttons.fontSize', '16px') : this.button.subTextFontSize || '80%',
        fontFamily: (this.button.subTextFontFamily || 'Global Style') === 'Global Style' ? _.get(this.globalStyles, ['button', 'options', 'content', 'button', 'fontFamily'], 'Roboto') : this.button.subTextFontFamily || null
      }
    },
    fullWidth () {
      return _.get(this.button, ['btnBlock', this.resolutioner], !this.customs.pressetID ? _.get(this.globalStyles, 'button.options.content.button.btnBlock.lg', '') : false)
    }
  },
  methods: {
    validate () {
      let valid = true
      this.relatedControls.forEach(({v}) => {
        v.$touch()

        valid ? valid = !v.$error : null
      })

      if (valid) {
        this.submit()
      }
    },
    onClick () {
      if (this.customs.action === 'Scroll to element') {
        return this.scrollToElement({href: this.customs.page})
      }

      this.$store.commit('modal/SHOW_MODAL', false)
      const modal = _.get(this.customs, 'modal.data.modal_id')
      if (modal && this.customs.action === 'Modal') {
        return this.$store.dispatch('modal/load', modal)
      }

      return this.goPage()
    },
    goPage () {
      if (this.customs.action === 'Random Link') {
        this.$store.dispatch('pages/goToPage', {
          item_type: 'custom_url',
          item_object_id: null,
          item_url: this.addhttp(this.customs.randomLinks[this.randomLinkIteration].href)
        })

        this.randomLinkIteration = Math.floor(Math.random() * this.customs.randomLinks.length)

        return
      }

      if (this.$attrs.hasOwnProperty('to')) return
      if (this.customs.page === null || this.customs.page.title === 'Not selected') return
      const item_type = this.customs.page.id !== 'custom-link' ? 'page' : 'custom_url'

      this.$store.dispatch('pages/goToPage', {
        item_type,
        item_object_id: item_type === 'page' ? this.customs.page.id : null,
        item_url: this.customs.customLink ? this.addhttp(this.customs.customLink) : null
      })
    }
  }
}
</script>

<style lang="scss">
.btn-edge {
  .prepend-icon {
    position: absolute;
    left: 10px;
  }

  .append-icon {
    position: absolute;
    right: 10px;
  }
}
</style>