<template>
  <div>
    <border-customs-v3
      :config="getConfig"
      :default-border="[1,1,1,1]"
      :default-radius="[0,0,0,0]"
    />
  </div>
</template>

<script>
import ModControlMixin from '@/components/mixins/ModControlMixin'
import _ from 'lodash'

export default {
  name: 'ButtonBorder',
  mixins: [ModControlMixin],
  computed: {
    getConfig () {
      if (Array.isArray(this.config.radius) && Array.isArray(this.config.border)) {
        this.$set(this.config, 'border', {
          lg: _.clone(this.config.border || [0,0,0,0]),
          md: _.clone(this.config.border || [0,0,0,0]),
          sm: _.clone(this.config.border || [0,0,0,0])
        })

        this.$set(this.config, 'radius', {
          lg: _.clone(this.config.radius || [4,4,4,4]),
          md: _.clone(this.config.radius || [4,4,4,4]),
          sm: _.clone(this.config.radius || [4,4,4,4])
        })
      }

      return this.config
    },
    border () {
      if (!this.config.border) {
        this.$set(this.config, 'border', [1,1,1,1])
      }
      return  this.config.border
    },
    radius () {
      if (!this.config.radius) {
        this.$set(this.config, 'radius', [1,1,1,1])
      }
      return  this.config.radius
    },
    borderColor: {
      get () {
        return _.get(this.config.resolutionStyle['lg'], 'borderColor', 'rgba(255, 255, 255, 0)')
      },
      set (val) {
        this.$set(this.config.resolutionStyle['lg'], 'borderColor', val)
      }
    }
  }
}
</script>