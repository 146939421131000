import {mapState, mapGetters} from 'vuex'

export default {
  props: {
    customs: {
      type: Object,
      required: true
    }
  },

  computed: {
    globalStyles () {
      // let editor = 'project'
      // const styles = {
      //   project: this.globalProjectStyles,
      //   blog: _.get(this.post, 'layout.styles', {})
      // }
      //
      // editor = _.get(this.section, 'editor', null) || _.get(this.$parent.section, 'editor', 'project')

      return this.globalProjectStyles
    },
    visability () {
      if (!this.customs.hasOwnProperty('display')) return
      const display = this.customs.display

      if (typeof display === 'object') {
        return display.includes(this.fullResolution)
      }

      return display
    },
    ...mapState('editor', {
      globalProjectStyles: state => state.globalStyles,
      resolution: state => state.resolution
    }),
    ...mapGetters('editor', {
      fullResolution: 'resolution'
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('blogEditor', {
      post: state => state.post
    })
  },

  methods: {
    cols (lg, sm) {
      return this.resolution === 'lg' ? lg : sm
    }
  }
}
