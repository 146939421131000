<template>
  <div
    class="h-100"
    @click="onClick"
  >
    <div
      class="flex items-center gap-[16px] h-[43px] bg-gradient-to-b from-white to-[#F1F3F4] px-[18px] border-b border-[#E2E5EC]"
    >
      <b-link @click="backward">
        <svg
          width="11"
          height="9"
          viewBox="0 0 11 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.35225 8.83525C4.57192 9.05492 4.92808 9.05492 5.14775 8.83525C5.36742 8.61558 5.36742 8.25942 5.14775 8.03975L2.1705 5.0625H9.8125C10.1232 5.0625 10.375 4.81066 10.375 4.5C10.375 4.18934 10.1232 3.9375 9.8125 3.9375H2.1705L5.14775 0.960248C5.36742 0.740578 5.36742 0.384422 5.14775 0.164753C4.92808 -0.0549175 4.57192 -0.0549176 4.35225 0.164753L0.414753 4.10225C0.195082 4.32192 0.195082 4.67808 0.414753 4.89775L4.35225 8.83525Z"
            fill="#6E747A"
          />
        </svg>
      </b-link>

      <span class="text-[#44474A] font-[700] text-[15px] mb-0">
        {{ openedItemOptions.title || section.name }}
      </span>
    </div>

    <div class="flex bg-[#FAFAFA] flex-column relative max-h-full h-[calc(100vh-155px)] simple-scrollbar">
      <div class="overflow-auto pb-[1px] ml-[-7px]">
        <b-card
          v-if="section.options.content.hasOwnProperty(openedItem) && section.options.content[openedItem].type"
          class="border-0"
          no-body
        >
          <div class="section-settings">
            <b-card
              class="border-0 noselect"
              no-body
            >
              <b-card-body class="border-top-0 bg-[#FAFAFA] pr-3 pt-0 pb-0">
                <component
                  :is="section.options.content[openedItem].type"
                  :component-name="openedItem"
                  :config="section.options.content[openedItem]"
                />
                <SidbearSettingsGroup
                  v-if="section.options.content[openedItem].hasOwnProperty('display')"
                  title="Device Visibility"
                  content-class="p-0"
                >
                  <Panel
                    :config="section.options.content[openedItem]"
                    class="bg-white"
                  />
                </SidbearSettingsGroup>
              </b-card-body>
            </b-card>
          </div>
        </b-card>
        <b-card
          v-else
          class="border-0"
          no-body
        >
          <div class="section-settings">
            <b-card
              class="border-0 noselect"
              no-body
            >
              <b-card-body class="border-top-0 bg-[#FAFAFA] pr-3 pt-0 pb-0">
                <component
                  :is="openedItem"
                  :component-name="openedItem"
                  :config="section"
                />
                <SidbearSettingsGroup
                  v-if="!section.disableVisibility && section.hasOwnProperty('display') && (section.name !== 'Row' && openedItem !== 'flex-column-mod-v2')"
                  title="Device Visibility"
                  content-class="p-0"
                >
                  <Panel
                    :config="section"
                    class="bg-white"
                  />
                </SidbearSettingsGroup>
              </b-card-body>
            </b-card>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import LayoutMode from '@builder/components/editor/mods/LayoutMode'
import _ from 'lodash'
import NavigationSettings from '../../mixins/NavigationSettings'
import ColumnsMod from '../mods/ColumnsMod'
import Panel from '../controls/Panel'
import RowMod from '../mods/RowMod'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import QRMod from '@/components/editor/mods/QRMod.vue'

export default {
  name: 'SectionSettings',
  components: {QRMod, SidbearSettingsGroup, RowMod, Panel, ColumnsMod, LayoutMode},

  mixins: [NavigationSettings],

  data () {
    return {
      order: [
        'navbarScroller',
        'background',
        'backgroundBody',
        'backgroundImage',
        'backgroundVideo',
        'floatImage',
        'textColor',
        'color',
        'colorSubtitle',
        'height',
        'padding',
        'marginTop',
        'marginY',
        'opacity',
        'shadow',
        'tiptapLink',
        'aligment',
        'align',
        'alignRow',
        'backgroundTimeItem',
        'blockWidth',
        'border',
        'borderRadius',
        'checklist',
        'container',
        'faviconUploader',
        'listIcon',
        'sliederToggle',
        'animations',
        'addons'
      ]
    }
  },

  computed: {
    orderedStyles () {
      let sortedSections = {}
      this.order.forEach(key => {
        if (_.get(this.section.options.customize.style, [key], false)) {
          sortedSections[key] = this.section.options.customize.style[key]
        }
      })
      return sortedSections
    },
    ...mapState({
      section: state => state.editor.section
    }),
    openedItem: {
      get () {
        let openedItem = this.$store.state.editor.openedItem

        if (typeof openedItem === 'object' && openedItem !== null) {
          return openedItem.item
        }

        return openedItem
      }
    },
    openedItemOptions () {
      return this.$store.state.editor.openedItemOptions || {}
    },
    ...mapState('editor', {
      stackElements: state => state.stackElements
    }),
    isOpenedItem: {
      get () {
        return typeof this.openedItem === 'object' || typeof this.openedItem === 'string'
      },
      set (value) {
        this.$store.commit('editor/SET_OPENED_MENU', value ? this.openedItem : null)
      }
    }
  },

  methods: {
    getContainer (item) {
      let $el = item.$el

      if (!$el.classList.contains('highlight-new')) {
        $el = $el.querySelector('.highlight-new')
      }

      return $el
    },
    backward () {
      const index = this.stackElements.findIndex(_item => _item.uid === this.section.uid)
      const itemsCount = _.clone(this.stackElements.length)

      if (this.openedItemOptions.back) {
        this.openedItemOptions.back()

        setTimeout(() => {
          if (this.stackElements.length === itemsCount) {
            this.stackElements.splice(index + 1, 1)
          }
        })
        return
      }

      if (index > 0) {
        const subject = this.stackElements[index - 1]
        if (subject.onClick) return subject.onClick()
        return this.getContainer(subject).click()
      }

      this.stackElements.splice(index, 1)
      this.back('sections')
    },
    label (label) {
      return _.startCase(label)
    },
    openMenu (index) {
      this.$store.commit('editor/SET_OPENED_MENU', index)
    },
    onClick () {
      // setTimeout(() => this.$store.commit('editor/SET_EXPANDED_GROUP', e.target.closest('.sidebar-group')))
    }
  }
}
</script>

