<template>
  <div>
    <b-form-group>
      <div class="flex items-center gap-[13px]">
        <color-picker
          :color="borderColor"
          @input="event => borderColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />

        <label class="mb-0 pb-0">Color</label>
      </div>
    </b-form-group>

    <b-form-group label="Border Size (px)">
      <LinkedControlGroup
        v-model="border"
        :labels="['Top', 'Right', 'Bottom', 'Left']"
      />
    </b-form-group>

    <b-form-group
      class="mb-0"
      label="Radius"
    >
      <LinkedControlGroup
        v-model="radius"
        :labels="['Top-L', 'Top-R', 'Bottom-L', 'Bottom-R']"
        :max="100"
      />
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'
import ModControlMixin from '@/components/mixins/ModControlMixin'
import LinkedControlGroup from '@/components/editor/components/linked-control-group/LinkedControlGroup'

export default {
  name: 'BorderCustoms',
  components: {LinkedControlGroup},
  mixins: [ModControlMixin],
  props: {
    defaultBorder: {
      type: Array,
      default: () => [1, 1, 1, 1]
    },
    defaultRadius: {
      type: Array,
      default: () => [1, 1, 1, 1]
    },
    defaultColor: {
      type: String,
      default: '#ffffff'
    }
  },
  computed: {
    border () {
      if (!this.config.border || !Array.isArray(this.config.border)) {
        this.$set(this.config, 'border', this.defaultBorder)
      }
      return this.config.border
    },
    radius () {
      if (!this.config.radius) {
        this.$set(this.config, 'radius', this.defaultRadius)
      }
      return this.config.radius
    },
    borderColor: {
      get () {
        return _.get(this.config, 'borderColor', this.defaultColor)
      },
      set (val) {
        this.$set(this.config, 'borderColor', val)
      }
    }
  }
}
</script>