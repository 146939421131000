<template>
  <highliter
    v-model="section.name"
    class="feature-section"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings(true)"
  >
    <VideoItems5Template :section="section">
      <template slot="title">
        <highliter
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('title')"
        >
          <content-editable
            v-model="section.options.content.title.text"
            :uid="tiptapUID('title')"
            :customs="section.options.content.title"
          />
        </highliter>
      </template>

      <template slot="subtitle">
        <highliter
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('subtitle')"
        >
          <content-editable
            v-model="section.options.content.subtitle.text"
            :uid="tiptapUID('subtitle')"
            :customs="section.options.content.subtitle"
          />
        </highliter>
      </template>

      <template slot="button">
        <highliter
          auto-width
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('button')"
        >
          <es-button :customs="section.options.content.button" />
        </highliter>
      </template>

      <template slot="video">
        <pub-content-editable
          v-model="section.options.content.title.text"
          :hard-styles="{fontSize: '32px', color: '#0F0F31', textAlign: 'center', display: 'flex', justifyContent: 'center'}"
          :uid="tiptapUID('title')"
          :customs="section.options.content.title"
        />

        <pub-content-editable
          v-model="section.options.content.subtitle.text"
          :uid="tiptapUID('subtitle')"
          :customs="section.options.content.subtitle"
        />

        <div class="d-flex justify-content-center w-100">
          <div class="my-4 w-75">
            <video-play :customs="section.options.content.video" />
          </div>
        </div>

        <div class="d-block text-center">
          <es-button :customs="section.options.content.button" />
        </div>
      </template>
    </VideoItems5Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditable from '@builder/components/builder/utils/ContentEditable'
import VideoItems5Template from '@builder/templates/sections/videos/VideoItems5Template'
import PubContentEditable from '../../utils/PubContentEditable'

export default {
  name: 'FeaturedItems1',

  components: {PubContentEditable, VideoItems5Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
