<template>
  <div class="cookie-banner-container">
    <!--  V1  -->
    <div
      v-if="!cookieContent.contentNecessary"
      class="cookie-banner"
      :style="contentStyle"
    >
      <div
        @click="onClick"
        v-html="cookieContent.content"
      />

      <div class="text-right">
        <button
          class="cookie-cta btn"
          :style="cta.style"
          v-html="cta.content"
        />
      </div>
    </div>

    <!--  V2  -->
    <div
      v-if="cookieContent.contentNecessary"
      :style="contentStyle"
      class="cookie-banner-v2"
      :class="{
        [`cookie-banner-v2__popup`]: cookieContent.bannerType !== 'bar',
        [`cookie-banner-v2__${cookieContent.bannerStyle}`]: true,
        [`cookie-banner-v2__${cookieContent.bannerStyle}__edit`]: isShowModal,
      }"
    >
      <div
        class="bnt-close"
        href="javascript:void(0)"
        @click="() => isShowModal = false"
      >
        <svg
          width="9"
          height="9"
          viewBox="0 0 9 9"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 8L1 1"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />

          <path
            d="M1 8L8 1"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div class="cookie-banner-v2__cont">
        <div class="cookie-banner-v2__cont__left">
          <div
            :class="{ 'cookie-banner-v2__cont__left__left': !isShowButtonBottom}"
            @click="onClick"
            v-html="cookieContent.content"
          />

          <div
            v-if="!isShowButtonBottom"
            class="cookie-banner-v2__cont__left__right"
            :class="{ 'cookie-banner-v2__cont__edit': isShowModal }"
          >
            <button
              :class="{
                'btn-default': ctaAllowAll.type === 'button',
                'btn-link': ctaAllowAll.type === 'link',
                'btn-border': ctaAllowAll.type === 'buttonBorder'
              }"
              :style="cookieContent.bannerStyle === 'custom' ? ctaAllowAll.style : {}"
              v-html="ctaAllowAll.content"
            />

            <button
              :class="{
                'btn-default': ctaDisableAll.type === 'button',
                'btn-link': ctaDisableAll.type === 'link',
                'btn-border': ctaDisableAll.type === 'buttonBorder'
              }"
              :style="cookieContent.bannerStyle === 'custom' ? ctaDisableAll.style : {}"
              v-html="ctaDisableAll.content"
            />

            <button
              v-if="isShowModal"
              class="btn-save-settings"
              :class="{
                'btn-default': ctaSettingsSave.type === 'button',
                'btn-link': ctaSettingsSave.type === 'link',
                'btn-border': ctaSettingsSave.type === 'buttonBorder'
              }"
              :style="cookieContent.bannerStyle === 'custom' ? ctaSettingsSave.style : {}"
              type="button"
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 1.3125C0 0.587625 0.587625 0 1.3125 0H10.875C11.5999 0 12.1875 0.587631 12.1875 1.3125V10.875C12.1875 11.5999 11.5999 12.1875 10.875 12.1875H1.3125C0.587631 12.1875 0 11.5999 0 10.875V1.3125ZM1.3125 0.9375C1.10539 0.9375 0.9375 1.10539 0.9375 1.3125V10.875C0.9375 11.0821 1.10539 11.25 1.3125 11.25H10.875C11.0821 11.25 11.25 11.0821 11.25 10.875V1.3125C11.25 1.10539 11.0821 0.9375 10.875 0.9375H1.3125Z"
                  fill="currentColor"
                />

                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.8125 0.46875C2.8125 0.209869 3.02237 0 3.28125 0H8.59375C8.85263 0 9.0625 0.209869 9.0625 0.46875V6.09375C9.0625 6.35262 8.85263 6.5625 8.59375 6.5625H3.28125C3.02237 6.5625 2.8125 6.35262 2.8125 6.09375V0.46875ZM3.75 0.9375V5.625H8.125V0.9375H3.75Z"
                  fill="currentColor"
                />

                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.71875 2.1875C6.97763 2.1875 7.1875 2.39737 7.1875 2.65625V3.90625C7.1875 4.16513 6.97763 4.375 6.71875 4.375C6.45987 4.375 6.25 4.16513 6.25 3.90625V2.65625C6.25 2.39737 6.45987 2.1875 6.71875 2.1875Z"
                  fill="currentColor"
                />

                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.56055 0.46875C1.56055 0.209869 1.77041 0 2.0293 0H9.84227C10.1012 0 10.311 0.209869 10.311 0.46875C10.311 0.727631 10.1012 0.9375 9.84227 0.9375H2.0293C1.77041 0.9375 1.56055 0.727631 1.56055 0.46875Z"
                  fill="currentColor"
                />
              </svg>

              {{ ctaSettingsSave.content }}
            </button>
          </div>

          <div
            v-if="isShowModal"
            class="cookie-banner-v2__cont__left__settings"
          >
            <div class="cookie-banner-v2__cont__left__checkbox necessary">
              <div
                :class="{ active: activeTab === 'necessary' }"
                @click="activeTab = 'necessary'"
                v-html="cookieContent.contentNecessary"
              />

              <div class="cookie-banner-v2__always-active">
                Always active
              </div>
            </div>

            <div class="cookie-banner-v2__cont__left__checkbox">
              <BCheckbox
                v-if="isChecbox"
                v-model="acceptedFunctional"
                class="cookie-banner-v2__checkbox"
                :control="{ value: acceptedFunctional, id: 'functional' }"
              />

              <div
                :class="{ active: activeTab === 'functional' }"
                @click="activeTab = 'functional'"
                v-html="cookieContent.contentFunctional"
              />

              <b-checkbox
                v-if="!isChecbox"
                v-model="acceptedFunctional"
                size="lg"
                switch
              />
            </div>

            <div class="cookie-banner-v2__cont__left__checkbox">
              <BCheckbox
                v-if="isChecbox"
                v-model="acceptedPerformance"
                class="cookie-banner-v2__checkbox"
                :control="{ value: acceptedPerformance, id: 'performance' }"
              />

              <div
                :class="{ active: activeTab === 'performance' }"
                @click="activeTab = 'performance'"
                v-html="cookieContent.contentPerformance"
              />

              <b-checkbox
                v-if="!isChecbox"
                v-model="acceptedPerformance"
                size="lg"
                switch
              />
            </div>

            <div class="cookie-banner-v2__cont__left__checkbox">
              <BCheckbox
                v-if="isChecbox"
                v-model="acceptedTargeting"
                class="cookie-banner-v2__checkbox"
                :control="{ value: acceptedTargeting, id: 'targeting' }"
              />

              <div
                :class="{ active: activeTab === 'targeting' }"
                @click="activeTab = 'targeting'"
                v-html="cookieContent.contentTargeting"
              />

              <b-checkbox
                v-if="!isChecbox"
                v-model="acceptedTargeting"
                size="lg"
                switch
              />
            </div>
          </div>
        </div>

        <div
          v-if="isShowButtonBottom"
          class="cookie-banner-v2__cont__right"
          :class="{ 'cookie-banner-v2__cont__edit': isShowModal }"
        >
          <div class="cookie-banner-v2__cont__right__top">
            <button
              :class="{
                'btn-default': ctaAllowAll.type === 'button',
                'btn-link': ctaAllowAll.type === 'link',
                'btn-border': ctaAllowAll.type === 'buttonBorder'
              }"
              :style="cookieContent.bannerStyle === 'custom' ? ctaAllowAll.style : {}"
              v-html="ctaAllowAll.content"
            />

            <button
              :class="{
                'btn-default': ctaDisableAll.type === 'button',
                'btn-link': ctaDisableAll.type === 'link',
                'btn-border': ctaDisableAll.type === 'buttonBorder'
              }"
              :style="cookieContent.bannerStyle === 'custom' ? ctaDisableAll.style : {}"
              v-html="ctaDisableAll.content"
            />
          </div>

          <div class="cookie-banner-v2__cont__right__bottom">
            <button
              v-if="isShowModal && [ 'custom', 'dark', 'default', 'light' ].indexOf(cookieContent.bannerStyle) !== -1 && cookieContent.bannerType !== 'bar'"
              class="btn-save-settings"
              :class="{
                'btn-default': ctaSettingsSave.type === 'button',
                'btn-link': ctaSettingsSave.type === 'link',
                'btn-border': ctaSettingsSave.type === 'buttonBorder'
              }"
              :style="cookieContent.bannerStyle === 'custom' ? ctaSettingsSave.style : {}"
              type="button"
              @click="isShowModal = !isShowModal"
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.05078 12.9696H10.7174C12.6724 12.9696 14.2591 11.3829 14.2591 9.4279C14.2591 7.4729 12.6724 5.88623 10.7174 5.88623H2.92578"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />

                <path
                  d="M4.55552 7.65706L2.74219 5.84373L4.55552 4.0304"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              Go Back
            </button>

            <button
              v-if="!isShowModal"
              class="btn-save"
              :class="{
                'btn-default': ctaCustomizeSettings.type === 'button',
                'btn-link': ctaCustomizeSettings.type === 'link',
                'btn-border': ctaCustomizeSettings.type === 'buttonBorder'
              }"
              :style="cookieContent.bannerStyle === 'custom' ? ctaCustomizeSettings.style : {}"
              @click="isShowModal = !isShowModal"
              v-html="ctaCustomizeSettings.content"
            />

            <button
              v-if="isShowModal"
              class="btn-save-settings"
              :class="{
                'btn-default': ctaSettingsSave.type === 'button',
                'btn-link': ctaSettingsSave.type === 'link',
                'btn-border': ctaSettingsSave.type === 'buttonBorder'
              }"
              :style="cookieContent.bannerStyle === 'custom' ? ctaSettingsSave.style : {}"
              type="button"
            >
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0 1.3125C0 0.587625 0.587625 0 1.3125 0H10.875C11.5999 0 12.1875 0.587631 12.1875 1.3125V10.875C12.1875 11.5999 11.5999 12.1875 10.875 12.1875H1.3125C0.587631 12.1875 0 11.5999 0 10.875V1.3125ZM1.3125 0.9375C1.10539 0.9375 0.9375 1.10539 0.9375 1.3125V10.875C0.9375 11.0821 1.10539 11.25 1.3125 11.25H10.875C11.0821 11.25 11.25 11.0821 11.25 10.875V1.3125C11.25 1.10539 11.0821 0.9375 10.875 0.9375H1.3125Z"
                  fill="currentColor"
                />

                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2.8125 0.46875C2.8125 0.209869 3.02237 0 3.28125 0H8.59375C8.85263 0 9.0625 0.209869 9.0625 0.46875V6.09375C9.0625 6.35262 8.85263 6.5625 8.59375 6.5625H3.28125C3.02237 6.5625 2.8125 6.35262 2.8125 6.09375V0.46875ZM3.75 0.9375V5.625H8.125V0.9375H3.75Z"
                  fill="currentColor"
                />

                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.71875 2.1875C6.97763 2.1875 7.1875 2.39737 7.1875 2.65625V3.90625C7.1875 4.16513 6.97763 4.375 6.71875 4.375C6.45987 4.375 6.25 4.16513 6.25 3.90625V2.65625C6.25 2.39737 6.45987 2.1875 6.71875 2.1875Z"
                  fill="currentColor"
                />

                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.56055 0.46875C1.56055 0.209869 1.77041 0 2.0293 0H9.84227C10.1012 0 10.311 0.209869 10.311 0.46875C10.311 0.727631 10.1012 0.9375 9.84227 0.9375H2.0293C1.77041 0.9375 1.56055 0.727631 1.56055 0.46875Z"
                  fill="currentColor"
                />
              </svg>

              {{ ctaSettingsSave.content }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookieBanner',
  props: {
    cookieContent: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      cookieBannerShown: true,
      acceptedFunctional: true,
      acceptedPerformance: true,
      acceptedTargeting: true,
      isShowModal: false,
      activeTab: 'necessary'
    }
  },

  computed: {
    isChecbox () {
      if (this.cookieContent.bannerStyle === 'basic-b-w') {
        return this.cookieContent.bannerType === 'bar'
      }

      if (this.cookieContent.bannerStyle === 'modern-clean') {
        return false
      }

      return true
    },

    isShowButtonBottom () {
      if (this.cookieContent.bannerStyle === 'modern-clean' && this.isShowModal && this.cookieContent.bannerType === 'bar') {
        return false
      }

      return true
    },

    contentStyle () {
      if (this.cookieContent.bannerStyle !== 'custom') {
        return this.contentType
      }

      return Object.assign({}, {
        backgroundColor: this.cookieContent.colors.bannerBackground,
        color: this.cookieContent.colors.bannerText
      }, this.contentType)
    },

    cta () {
      if (this.cookieContent.bannerCtaType === 'button') {
        return {
          tag: 'b-btn',
          type: 'button',
          style: {
            backgroundColor: this.cookieContent.colors.ctaBackgroundColor,
            color: this.cookieContent.colors.ctaText
          },
          content: this.cookieContent.bannerCtaText
        }
      }

      if (this.cookieContent.bannerCtaType === 'buttonBorder') {
        return {
          tag: 'b-btn',
          type: 'buttonBorder',
          style: {
            backgroundColor: this.cookieContent.colors.ctaText,
            color: this.cookieContent.colors.ctaBackgroundColor,
            border: `solid 1px ${this.cookieContent.colors.ctaBackgroundColor}`
          },
          content: this.cookieContent.bannerCtaText
        }
      }

      if (this.cookieContent.bannerCtaType === 'icon') {
        return {
          tag: 'b-btn',
          type: 'icon',
          style: {
            width: '34px',
            height: '32px',
            padding: '0',
            backgroundColor: this.cookieContent.colors.ctaBackgroundColor,
            color: this.cookieContent.colors.ctaText
          },
          content: '<i class="fa fa-check" />'
        }
      }

      return {
        tag: 'div',
        type: 'link',
        style: null,
        content: this.cookieContent.bannerCtaText
      }
    },

    ctaDisableAll () {
      if (this.cookieContent.bannerCtaDisableAllType === 'button') {
        return {
          tag: 'b-btn',
          type: 'button',

          style: {
            backgroundColor: this.cookieContent.colors.ctaBackgroundColor,
            color: this.cookieContent.colors.ctaButtonText
          },

          content: this.cookieContent.bannerCtaDisableAllText
        }
      }

      if (this.cookieContent.bannerCtaDisableAllType === 'buttonBorder') {
        return {
          tag: 'b-btn',
          type: 'buttonBorder',

          style: {
            backgroundColor: this.cookieContent.colors.ctaButtonText,
            color: this.cookieContent.colors.ctaBackgroundColor,
            border: `solid 1px ${this.cookieContent.colors.ctaBackgroundColor}`
          },

          content: this.cookieContent.bannerCtaDisableAllText
        }
      }

      if (this.cookieContent.bannerCtaDisableAllType === 'icon') {
        return {
          tag: 'b-btn',
          type: 'icon',

          style: {
            width: '34px',
            height: '32px',
            padding: '0',
            backgroundColor: this.cookieContent.colors.ctaBackgroundColor,
            color: this.cookieContent.colors.ctaButtonText
          },

          content: '<i class="fa fa-close" />'
        }
      }

      return {
        tag: 'div',
        type: 'link',
        style: { color: this.cookieContent.colors.ctaLinkText },
        content: this.cookieContent.bannerCtaDisableAllText
      }
    },

    ctaCustomizeSettings: {
      cache: false,

      get () {
        if (this.cookieContent.bannerCtaCustomizeSettingsType === 'button') {
          return {
            tag: 'b-btn',
            type: 'button',

            style: {
              backgroundColor: this.cookieContent.colors.ctaBackgroundColor,
              color: this.cookieContent.colors.ctaButtonText
            },

            content: this.cookieContent.bannerCtaCustomizeSettingsText
          }
        }

        if (this.cookieContent.bannerCtaCustomizeSettingsType === 'buttonBorder') {
          return {
            tag: 'b-btn',
            type: 'buttonBorder',

            style: {
              backgroundColor: this.cookieContent.colors.ctaButtonText,
              color: this.cookieContent.colors.ctaBackgroundColor,
              border: `solid 1px ${this.cookieContent.colors.ctaBackgroundColor}`
            },

            content: this.cookieContent.bannerCtaCustomizeSettingsText
          }
        }

        if (this.cookieContent.bannerCtaCustomizeSettingsType === 'icon') {
          return {
            tag: 'b-btn',
            type: 'icon',

            style: {
              width: '34px',
              height: '32px',
              padding: '0',
              backgroundColor: this.cookieContent.colors.ctaBackgroundColor,
              color: this.cookieContent.colors.ctaButtonText
            },

            content: '<i class="fa fa-cog" />'
          }
        }

        return {
          tag: 'div',
          type: 'link',
          style: {color: this.cookieContent.colors.ctaLinkText},
          content: this.cookieContent.bannerCtaCustomizeSettingsText
        }
      }
    },

    ctaAllowAll () {
      if (this.cookieContent.bannerCtaAllowAllType === 'button') {
        return {
          tag: 'b-btn',
          type: 'button',

          style: {
            backgroundColor: this.cookieContent.colors.ctaBackgroundColor,
            color: this.cookieContent.colors.ctaButtonText
          },
          content: this.cookieContent.bannerCtaAllowAllText
        }
      }

      if (this.cookieContent.bannerCtaAllowAllType === 'buttonBorder') {
        return {
          tag: 'b-btn',
          type: 'buttonBorder',

          style: {
            backgroundColor: this.cookieContent.colors.ctaButtonText,
            color: this.cookieContent.colors.ctaBackgroundColor,
            border: `solid 1px ${this.cookieContent.colors.ctaBackgroundColor}`
          },
          content: this.cookieContent.bannerCtaAllowAllText
        }
      }

      if (this.cookieContent.bannerCtaAllowAllType === 'icon') {
        return {
          tag: 'b-btn',
          type: 'icon',

          style: {
            width: '34px',
            height: '32px',
            padding: '0',
            backgroundColor: this.cookieContent.colors.ctaBackgroundColor,
            color: this.cookieContent.colors.ctaButtonText
          },
          content: '<i class="fa fa-check" />'
        }
      }

      return {
        tag: 'div',
        type: 'link',
        style: { color: this.cookieContent.colors.ctaLinkText },
        content: this.cookieContent.bannerCtaAllowAllText
      }
    },

    ctaSettingsSave () {
      if (this.cookieContent.bannerCtaSettingsSaveType === 'button') {
        return {
          tag: 'b-btn',
          type: 'button',

          style: {
            backgroundColor: this.cookieContent.colors.ctaBackgroundColor,
            color: this.cookieContent.colors.ctaButtonText
          },
          content: this.cookieContent.bannerCtaSettingsSaveText
        }
      }

      if (this.cookieContent.bannerCtaSettingsSaveType === 'buttonBorder') {
        return {
          tag: 'b-btn',
          type: 'buttonBorder',

          style: {
            backgroundColor: this.cookieContent.colors.ctaButtonText,
            color: this.cookieContent.colors.ctaBackgroundColor,
            border: `solid 1px ${this.cookieContent.colors.ctaBackgroundColor}`
          },
          content: this.cookieContent.bannerCtaSettingsSaveText
        }
      }

      if (this.cookieContent.bannerCtaSettingsSaveType === 'icon') {
        return {
          tag: 'b-btn',
          type: 'icon',

          style: {
            width: '34px',
            height: '32px',
            padding: '0',
            backgroundColor: this.cookieContent.colors.ctaBackgroundColor,
            color: this.cookieContent.colors.ctaLinkText
          },
          content: '<i class="fa fa-check" />'
        }
      }

      return {
        tag: 'div',
        type: 'link',
        style: {
          color: this.cookieContent.colors.ctaLinkText
        },
        content: this.cookieContent.bannerCtaSettingsSaveText
      }
    },

    bannerStyle () {
      return {
        [this.cookieContent.bannerPosition]: 0,
        left: 0
      }
    },

    popupPosition () {
      const positions = {
        'top-left': {
          top: '20px',
          left: '20px'
        },
        'top-center': {
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)'
        },
        'top-right': {
          top: '20px',
          right: '20px'
        },
        'bottom-left': {
          bottom: '20px',
          left: '20px'
        },
        'bottom-center': {
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)'
        },
        'bottom-right': {
          bottom: '20px',
          right: '20px'
        }
      }

      return positions[this.cookieContent.popUpPosition]
    },

    popupStyle () {
      return {
        ...this.popupPosition
      }
    },

    contentType () {
      return this.cookieContent.bannerType === 'bar' ? this.bannerStyle : this.popupStyle
    }
  },

  methods: {
    onClick (e) {
      e.preventDefault()
    }
  }
}
</script>
