const state = () => ({
  modal: false,
  title: '',
  description: '',
  variants: [],
  promise: {}
})

const actions = {
  async confirmation ({state}, {title, description}) {
    state.title = title
    state.description =  description
    state.variants = []
    state.modal = true

    return await new Promise((resolve, reject) => {
      state.promise.resolve = resolve
      state.promise.reject = reject
    })
  },
  confirm ({state, dispatch}) {
    state.promise.resolve()
    dispatch('_reset')

  },
  cancel ({state, dispatch}) {
    state.promise.reject()
    dispatch('_reset')
  },
  _reset ({state}) {
    state.modal = false
    state.title = ''
    state.description = ''
    state.promise = {}
  }
}

export default {
  namespaced: true,
  state,
  actions
}