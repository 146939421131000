<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element"
    :uid="section.uid"
    :class="{'element-hidden': !visability('video')}"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('video')"
  >
    <div
      :class="`--${section.sid}--yt-shorts`"
    >
      <div
        class="flex w-100 yt-shorts-iframe-container"
      >
        <iframe
          ref="iframe"
          :src="section.options.content.video.iframeURL || 'https://www.youtube.com/embed/demo?autoplay=1&loop=1&controls=0'"
          frameborder="0"
          loading="lazy"
          :style="size"
          class="yt-iframe"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </div>
      <div class="ambient ambient-t" />
      <div class="ambient ambient-l" />
      <div class="ambient ambient-r" />
      <div class="ambient ambient-b" />
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'

export default {
  name: 'ElYTShortsMod',

  components: {Highliter},

  mixins: [SectionMixin],

  data () {
    return {
      responsiveHeight: 0
    }
  },

  computed: {
    size () {
      return {
        maxWidth: this.section.options.content.video.width,
        height: this.responsiveHeight || this.section.options.content.video.height
      }
    }
  },

  mounted () {
    const observedElement = this.$el

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const currentWidth = entry.contentRect.width

        if (currentWidth < parseInt(this.section.options.content.video.width)) {
          this.responsiveHeight = `${(16/9) * currentWidth}px`
        } else {
          this.responsiveHeight = 0
        }
      }
    })

    resizeObserver.observe(observedElement)
  }
}
</script>

<style lang="scss">
.yt-shorts-iframe-container {
  .yt-iframe {
    border-radius: 10px;
    width: 100%;
    transition: .3s;
  }
}
</style>
