<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="onClick"
  >
    <div
      :data-sid="section.sid"
      class="flex icon-box"
      :class="`--${section.sid}--`"
    >
      <div
        :class="{'element-hidden': !visability('iconBox')}"
        class="icon-box-container flex flex-column w-full"
      >
        <div
          class="flex icon-box-wrapper"
        >
          <div class="d-flex align-items-center">
            <div
              v-show="section.options.content.iconBox.customIcon === 'lottie'"
              :class="`--lottie-container-${section.sid}`"
            >
              <button-lottie-icon
                :config="section.options.content.iconBox"
              />
            </div>
            <i
              v-show="section.options.content.iconBox.customIcon === 'icon'"
              :class="section.options.content.iconBox.icon"
              class="icon"
            />
            <img
              v-show="section.options.content.iconBox.customIcon === 'image'"
              class="icon-image"
            >
          </div>

          <div class="w-full ml-2">
            <content-editable-v2
              ref="content"
              v-model="section.options.content.iconBox.subject.options.content.description.text"
              :customs="section.options.content.iconBox.subject.options.content.description"
              custom-class="text-inherit"
              type="typography"
              :section="section.options.content.iconBox.subject"
            />
          </div>
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import PropertiesManager from '../../../mixins/PropertiesManager'
import ResolutionMixin from '../../../mixins/ResolutionMixin'
import ButtonLottieIcon from '@/components/builder/elements/9.icon/ButtonLottieIcon'

export default {
  name: 'FeaturedItems1',

  components: {ButtonLottieIcon, Highliter},

  mixins: [SectionMixin, PropertiesManager, ResolutionMixin],

  methods: {
    onClick () {
      document.querySelector(`[data-sid="${this.section.options.content.iconBox.subject.sid}"]`).click()
      this.sectionSettings('iconBox')
    }
  }
}
</script>

<style lang="scss">
.icon-box {
  padding: 20px;

  .icon-box-container {
    align-items: center;

    .paragraph {
      text-align: left;
      font-size: 1rem;
      color: inherit;
    }

    i {
      line-height: 1.5;
    }
  }

  .icon-box-wrapper {
    gap: 15px;
    align-items: center;
  }

  .button-lottie-icon {
    width: 25px;
  }
}
</style>