<template>
  <div>
    <div class="sid-select">
      <SidbearSettingsGroup
        title="Content & Style"
        active
        content-class="border-0"
      >
        <rule-tabs>
          <template slot="Normal">
            <sid-align
              sid=".estage-btn"
              rule-name="justify-content"
              label="Button Alignment"
            />

            <sid-background sid=".estage-btn" />

            <sid-font
              sid=".estage-btn"
            />

            <sid-range-slider
              label="Font Size"
              sid=".estage-btn"
              rule-name="font-size"
            />

            <sid-range-slider
              label="Letter-Spacing"
              sid=".estage-btn"
              rule-name="letter-spacing"
            />

            <sid-range-slider
              label="Line-Height"
              sid=".estage-btn"
              rule-name="line-height"
            />

            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Text Color</label>

                <sid-color
                  sid=".estage-btn"
                  rule-name="color"
                />
              </div>
            </b-form-group>

            <sid-align
              sid=".estage-btn"
              rule-name="justify-content"
              label="Text Alignment"
            />

            <b-form-group>
              <sid-font-style
                sid=".estage-btn"
                rule-name="font"
              />
            </b-form-group>
          </template>

          <template slot="Hover">
            <sid-range-slider
              label="Text Size"
              sid=".estage-btn"
              rule-name="font-size"
              presudo=":hover"
            />

            <sid-range-slider
              label="Letter-Spacing"
              sid=".estage-btn"
              rule-name="letter-spacing"
              presudo=":hover"
            />


            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Text Color</label>

                <sid-color
                  sid=".estage-btn"
                  presudo=":hover"
                  rule-name="color"
                />
              </div>
            </b-form-group>

            <sid-background
              sid=".estage-btn"
              presudo=":hover"
            />
          </template>
        </rule-tabs>
      </SidbearSettingsGroup>

      <SidbearSettingsGroup title="Layout">
        <sid-linked-group
          label="Margin"
          sid=".estage-btn"
          rule-name="margin"
          :min="-2000"
          :max="2000"
          :angles="[
            {
              text: '',
              value: 'top'
            },
            {
              text: '',
              value: 'right'
            },
            {
              text: '',
              value: 'bottom'
            },
            {
              text: '',
              value: 'left'
            }
          ]"
        />

        <sid-linked-group
          label="Padding"
          sid=".estage-btn"
          rule-name="padding"
          :min="0"
          :angles="[
            {
              text: '',
              value: 'top'
            },
            {
              text: '',
              value: 'right'
            },
            {
              text: '',
              value: 'bottom'
            },
            {
              text: '',
              value: 'left'
            }
          ]"
        />

        <sid-range-slider
          label="Width"
          sid=".estage-btn"
          rule-name="width"
        />

        <sid-range-slider
          label="Height"
          sid=".estage-btn"
          rule-name="height"
        />
      </SidbearSettingsGroup>

      <SidbearSettingsGroup title="Border & Radius">
        <rule-tabs>
          <template slot="Normal">
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Border Type</label>

                <sid-select
                  sid=".estage-btn"
                  rule-name="border-style"
                  :searchable="false"
                  class="w-[118px]"
                  :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
                />
              </div>
            </b-form-group>

            <b-form-group>
              <sid-linked-group
                sid=".estage-btn"
                label="Border Width"
                rule-name="border"
                class="!mb-[7px]"
                :angles="[
                  {
                    text: '',
                    value: 'top-width'
                  },
                  {
                    text: '',
                    value: 'right-width'
                  },
                  {
                    text: '',
                    value: 'bottom-width'
                  },
                  {
                    text: '',
                    value: 'left-width'
                  }
                ]"
              />

              <div class="flex w-full justify-end">
                <sid-color
                  sid=".estage-btn"
                  rule-name="border-color"
                />
              </div>
            </b-form-group>

            <sid-linked-group
              label="Radius"
              sid=".estage-btn"
              rule-name="border"
              class="mb-0"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius'
                }
              ]"
            />
          </template>

          <template slot="Hover">
            <b-form-group>
              <div class="flex items-center justify-between">
                <label class="pb-0">Border Type</label>

                <sid-select
                  sid=".estage-btn"
                  rule-name="border-style"
                  :searchable="false"
                  class="w-[118px]"
                  presudo=":hover"
                  :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
                />
              </div>
            </b-form-group>

            <b-form-group>
              <sid-linked-group
                sid=".estage-btn"
                label="Border Width"
                rule-name="border"
                class="!mb-[7px]"
                presudo=":hover"
                :angles="[
                  {
                    text: '',
                    value: 'top-width'
                  },
                  {
                    text: '',
                    value: 'right-width'
                  },
                  {
                    text: '',
                    value: 'bottom-width'
                  },
                  {
                    text: '',
                    value: 'left-width'
                  }
                ]"
              />

              <div class="flex w-full justify-end">
                <sid-color
                  sid=".estage-btn"
                  presudo=":hover"
                  rule-name="border-color"
                />
              </div>
            </b-form-group>

            <sid-linked-group
              label="Radius"
              sid=".estage-btn"
              rule-name="border"
              presudo=":hover"
              class="mb-0"
              :angles="[
                {
                  text: '',
                  value: 'top-left-radius'
                },
                {
                  text: '',
                  value: 'top-right-radius'
                },
                {
                  text: '',
                  value: 'bottom-left-radius'
                },
                {
                  text: '',
                  value: 'bottom-right-radius'
                }
              ]"
            />
          </template>
        </rule-tabs>
      </SidbearSettingsGroup>

      <SidbearSettingsGroup title="Transform">
        <rule-tabs>
          <template #Normal>
            <sid-transform
              sid=".estage-btn"
              rule-name="transform"
              :section="section"
            />
          </template>

          <template #Hover>
            <sid-transform
              sid=".estage-btn"
              rule-name="transform"
              presudo=":hover"
              :section="section"
            />
          </template>
        </rule-tabs>
      </SidbearSettingsGroup>
    </div>
  </div>
</template>

<script>
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidFontStyle from '@/components/editor/components/sid-controls/SidFontStyle'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidTransform from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTransform'
export default {
  name: 'ButtonGlobalStyle',
  components: {
    SidTransform,
    SidLinkedGroup,
    SidSelect,
    SidFontStyle, SidColor, SidRangeSlider, SidFont, SidBackground, SidAlign, RuleTabs, SidbearSettingsGroup}
}
</script>