<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element"
    :dublicate-deep="parentUID"
    :dublicate="section"
    :hide-right="true"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="() => null"
  >
    <b-form-group
      class="estage-form-element-group mb-0"
      :class="`--${sid}--form-field --${sid}--form-field-card-cvc`"
    >
      <template
        v-if="control.label"
        #label
        class="flex items-center gap-x-[4px]"
        :class="`--${sid}--form-field-label`"
      >
        CVC/CVV
        <span
          class="text-danger"
        >
          *
        </span>
      </template>

      <b-form-input
        v-model="control.value"
        v-mask="'###'"
        placeholder="***"
        required
        class="input"
        :class="`--${sid}--form-field-input`"
      />
    </b-form-group>
  </highliter>
</template>

<script>
import {VueMaskDirective} from 'v-mask'
import BorderMixin from '@/components/mixins/BorderMixin'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElFIeldCardCvc',
  directives: {
    mask: VueMaskDirective
  },
  mixins: [BorderMixin, SectionMixin],
  components: {Highliter},
  computed: {
    control () {
      return this.section.options.content.control
    }
  }
}
</script>