<template>
  <div class="w-[275px]">
    <slot />
  </div>
</template>

<script>
import {Draggable} from 'draggable-vue-directive'
import {mapState} from 'vuex'

export default {
  name: 'PinUnpinBar',
  directives: {
    Draggable
  },
  data () {
    return {
      expand: false,
      isSettyingsPage: false,
      draggableOptions: {
        stopDragging: true
      }
    }
  },
  computed: {
    ...mapState({
      page: state => state.editor.page,
      section: state => state.editor.section
    }),
    unpin () {
      return this.$store.state.editor.unpin
    }
  },
  watch: {
    page (val) {
      this.expand = false

      if (val === 'section-settings') {
        return setTimeout(() => {
          this.isSettyingsPage = true
        }, 400)
      }

      this.isSettyingsPage = false
    },
    section () {
      this.expand = false
    }
  },
  mounted () {
    this.draggableOptions = {
      handle: document.getElementById('toolbar-drag-handle'),
      boundingelement: document.body
    }
  },
  destroyed () {
    this.$store.state.editor.unpin = false
  },
  methods: {
    pin () {
      this.$store.state.editor.unpin = !this.$store.state.editor.unpin
    }
  }
}
</script>
