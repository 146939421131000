import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Blog Search'
export const group = 'Dynamic Post Template'
export const beta = false
import { defaultOptions } from './ElBlogSearch'

export const icon = ''

export default class ElPostCategories extends SectionProvider {
  constructor (options = {}) {
    super('ElBlogSearch', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
