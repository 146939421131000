<template>
  <div>
    <StateTabs
      :states-text="animationEnabled ? ['Static', 'Animation'] : ['Static']"
      class="pb-[12px]"
      :default-state="bgType"
      @onChange="val => bgType = val"
    >
      <b-form-group label="Background Image">
        <image-upload
          :disable-settigns="fullHeight"
          :image="config"
          class="w-100"
          @uploaded="onUploaded"
        />
      </b-form-group>

      <b-form-group label="Background Image Size">
        <v-select
          v-model="size"
          :options="sizeOptions"
          :reduce="val => val.value"
          :searchable="false"
        />
      </b-form-group>

      <background-image-flip :config="config" />

      <template slot="action-state">
        <backgroun-images-slider-customs :config="config" />

        <b-form-group label="Background Image Size">
          <v-select
            v-model="size"
            :options="sliderSizeOptions"
            :reduce="val => val.value"
            :searchable="false"
          />
        </b-form-group>
      </template>
    </StateTabs>

    <b-form-group
      v-if="size !== 'parallax'"
      label="Background Image Position"
    >
      <v-select
        v-model="position"
        :options="['left top','left center','left bottom','right top','right center','right bottom','center top','center center','center bottom']"
        :searchable="false"
      />
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="customSizeEnabled">
        Custom Size
      </b-checkbox>
    </b-form-group>

    <b-form-group>
      <multi-select
        v-model="customSize"
        :default-type="1"
        width="70px"
        :options="[{
                     name: 'px',
                     value: 'px',
                     max: 4000,
                     min: 0,
                     defaultValue: 300
                   },
                   {
                     name: '%',
                     value: '%',
                     max: 300,
                     min: 0,
                     defaultValue: 100
                   }]"
      />
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="customPositionEnabled">
        Custom Position
      </b-checkbox>
    </b-form-group>

    <b-form-group
      label="Transform Y"
      label-class="!pb-[3px]"
    >
      <b-row
        class="align-items-center pl-2"
        no-gutters
      >
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="positionY"
            :interval="1"
            :max="100"
            :min="-100"
            class="editor-slider-1 pr-3"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <LinkedControlItem
            v-model="positionY"
            :max="100"
            :min="-100"
            class="d-block !w-[70px]"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Transform X"
      label-class="!pb-[3px]"
    >
      <b-row
        class="align-items-center pl-2"
        no-gutters
      >
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="positionX"
            :interval="1"
            :max="100"
            :min="-100"
            class="editor-slider-1 pr-3"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <LinkedControlItem
            v-model="positionX"
            :max="100"
            :min="-100"
            class="d-block !w-[70px]"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <image-filters :config="config" />

    <b-form-group
      v-if="config.src"
      label="Background Image Opacity"
    >
      <div class="flex items-center gap-[10px]">
        <vue-slider
          v-model="opacity"
          :dot-size="17"
          :interval="0.1"
          :max="1"
          :min="0"
          class="editor-slider-2 w-100"
          tooltip="none"
        />

        <LinkedControlItem
          v-model="opacity"
          :max="1"
          :min="0"
          :step=".1"
          class="d-block !w-[70px]"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <div class="flex items-center justify-between">
        <div class="flex items-center gap-[10px]">
          <color-picker
            :color="overlay.color"
            @input="event => overlay.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />

          <label class="mb-0 pb-0">Background Overlay</label>
        </div>

        <b-checkbox
          v-model="overlay.enabled"
          switch
        />
      </div>
    </b-form-group>

    <b-form-group
      label="Overlay Opacity"
    >
      <div class="flex items-center gap-[10px]">
        <vue-slider
          v-model="overlay.opacity"
          :dot-size="17"
          :interval="0.1"
          :max="1"
          :min="0"
          class="editor-slider-2 w-100"
          tooltip="none"
        />

        <LinkedControlItem
          v-model="overlay.opacity"
          :max="1"
          :min="0"
          :step=".1"
          class="d-block !w-[70px]"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'
import ControlMixin from '../../mixins/ControlMixin'
import ImageUpload from '../utils/ImageUpload'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import StateTabs from '@/components/editor/components/StateTabs'
import ImageFilters from '@/components/editor/components/ImageFilters'
import MultiSelect from '@/components/editor/controls/MultiSelect'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
import BackgroundImageFlip from '@/components/editor/controls/background-image-mod/BackgroundImageFlip'

export default {
  name: 'BackgroundImageCustoms',
  components: {BackgroundImageFlip, LinkedControlItem, MultiSelect, ImageFilters, StateTabs, ImageUpload},
  mixins: [ControlMixin, ResolutionMixin],

  props: {
    animationEnabled: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      file: [],
      url: '',
      sizeOptions: [
        {
          label: 'None',
          value: null
        },
        {
          label: 'Normal',
          value: 'normal'
        },
        {
          label: 'Full Center (Parallax)',
          value: 'parallax'
        },
        {
          label: 'Fill 100 % Width',
          value: 'fill-y'
        },
        {
          label: 'Fill 100 % Width & Height',
          value: 'fill'
        },
        {
          label: 'Repeat',
          value: 'repeat'
        },
        {
          label: 'Repeat Horizontally',
          value: 'repeat-x'
        },
        {
          label: 'Repeat Vertically',
          value: 'repeat-y'
        },
        {
          label: 'Cover',
          value: 'cover'
        },
        {
          label: 'Contain',
          value: 'contain'
        }
      ],
      sliderSizeOptions: [
        {
          label: 'Normal',
          value: 'normal'
        },
        {
          label: 'Fill 100 % Width',
          value: 'fill-y'
        },
        {
          label: 'Fill 100 % Width & Height',
          value: 'fill'
        },
        {
          label: 'Cover',
          value: 'cover'
        },
        {
          label: 'Contain',
          value: 'contain'
        }
      ]
    }
  },

  computed: {
    customSizeEnabled: {
      get () {
        return _.get(this.config, ['customSizeEnabled', this.resolutioner], _.get(this.config, ['customSizeEnabled', 'lg'], false))
      },
      set (val) {
        this.$set(this.config, 'customSizeEnabled', {..._.set(this.config['customSizeEnabled'] || {}, [this.resolutioner], val)})
      }
    },
    customSize: {
      get () {
        return _.get(this.config, 'customSize', {})
      },
      set (val) {
        this.$set(this.config, 'customSize', val)
      }
    },
    customPositionEnabled: {
      get () {
        return _.get(this.config, ['customPositionEnabled', this.resolutioner], _.get(this.config, ['customPositionEnabled', 'lg'], false))
      },
      set (val) {
        this.$set(this.config, 'customPositionEnabled', {..._.set(this.config['customPositionEnabled'] || {}, [this.resolutioner], val)})
      }
    },
    positionX: {
      get () {
        return _.get(this.config, ['positionX', this.resolutioner], _.get(this.config, ['positionX', 'lg'], 0))
      },
      set (val) {
        this.$set(this.config, 'positionX', {..._.set(this.config['positionX'] || {}, [this.resolutioner], val)})
      }
    },
    positionY: {
      get () {
        return _.get(this.config, ['positionY', this.resolutioner], _.get(this.config, ['positionY', 'lg'], 0))
      },
      set (val) {
        this.$set(this.config, 'positionY', {..._.set(this.config['positionY'] || {}, [this.resolutioner], val)})
      }
    },
    bgType: {
      get () {
        return this.config.bgType || 0
      },
      set (val) {
        this.$set(this.config, 'bgType', val)
      }
    },
    imagePath () {
      return this.config.src
    },
    size: {
      get () {
        return _.get(this.config, ['backgroundSizeV2', this.resolutioner], _.get(this.config, ['backgroundSizeV2', 'lg'], this.config.backgroundSize))
      },
      set (val) {
        this.$set(this.config, 'backgroundSizeV2', {..._.set(this.config['backgroundSizeV2'] || {}, [this.resolutioner], val)})
      }
    },
    overlay () {
      if (!this.config.overlay) {
        this.$set(this.config, 'overlay', {
          color: 'rgb(0,0,0)',
          opacity: .5,
          enabled: false
        })
      }

      return this.config.overlay || {}
    },
    position: {
      get () {
        return _.get(this.config, ['backgroundPositionV2', this.resolutioner], _.get(this.config, ['backgroundPositionV2', 'lg'], this.config.backgroundPosition || 'center center'))
      },
      set (val) {
        this.$set(this.config, 'backgroundPositionV2', {..._.set(this.config['backgroundPositionV2'] || {}, [this.resolutioner], val)})
      }
    },
    opacity: {
      get () {
        return _.get(this.config.opacity, this.resolutioner, 1)
      },
      set (val) {
        this.$set(this.config, 'opacity', {..._.set(this.config.opacity || {}, [this.resolutioner], val)})
      }
    },
    fullHeight: {
      get () {
        return _.get(this.config, 'fullHeight', false)
      },
      set (val) {
        this.$set(this.config, 'fullHeight', val)
      }
    }
  },

  watch: {
    imagePath () {
      this.$set(this.config, 'enabled', this.config.src ? 1 : 0)
    },
    file () {
      let formData = new FormData()

      formData.append('file', this.file)

      axios.post('api/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(({data}) => {
          this.config.src = data.data.url
          this.config.id = data.data.id
        })
    }
  },

  methods: {
    onUploaded () {
      if (!this.size) {
        this.size = 'parallax'
      }
    }
  }
}
</script>

<style lang="scss">
.btn-es-image-upload {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #E2E5EC;
  font-size: 14px;
  font-weight: 600;
  color: #6E747A;
  height: 30px;
  box-shadow: 0 1px 2px 0 rgba(226, 226, 226, 0.5)
}

.btn-es-image-upload {
  .dropdown-toggle {
    background: none !important;
    padding: 0;
    border: none !important;
    margin-left: auto;

    &:after {
      display: none;
    }
  }
}
</style>
