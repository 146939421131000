<template>
  <v-select
    v-model="queryFilters.filter"
    :searchable="false"
    placeholder="Filter"
    class="!w-[220px]"
    :reduce="val => val.id"
    label="name"
    :options="filters"
  />
</template>

<script>
export default {
  name: 'Filter',
  props: {
    queryFilters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      filters: [
        {
          id: null,
          name: 'All'
        },
        {
          id: 'approved',
          name: 'Approved'
        },
        {
          id: 'rejected',
          name: 'Rejected'
        },
        {
          id: 'pending',
          name: 'Awaiting Approval'
        },
        {
          id: 'hidden',
          name: 'Hidden'
        },
        {
          id: 'blocked',
          name: 'Blocked'
        },
        {
          id: 'mine',
          name: 'Mine'
        },
        {
          id: 'trash',
          name: 'Trash'
        }
      ]
    }
  }
}
</script>