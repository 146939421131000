import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Billing Terms'
export const group = ''

export default class ElBillingTerms extends SectionProvider {
  constructor (options = {}) {
    super('ElBillingTerms', options)

    this.name = name
    this.group = group
  }
}