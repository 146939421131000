<template>
  <div>
    <b-form @submit.prevent="submit">
      <b-form-group style="max-width: 350px;">
        <div slot="label">
          <h6 class="text-dark font-weight-bold">
            Cloudflare Token
          </h6>
        </div>

        <b-input
          v-model="token"
          v-validate="'required'"
          name="token"
          type="password"
          :state="errors.has('token') ? false : null"
          autocomplete="off"
        />

        <span
          v-show="errors.has('token')"
          class="d-block text-danger mt-1"
        >{{ errors.first('token') }}</span>
      </b-form-group>

      <b-btn
        :disabled="loading"
        type="submit"
        variant="primary"
        class="d-flex align-items-center"
      >
        Save
        <b-spinner
          v-if="loading"
          class="ml-2"
          small
        />
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import {VueMaskDirective} from 'v-mask'
import {mapState} from 'vuex'
import RouterMixin from '../../components/mixins/RouterMixin'

export default {
  name: 'DoaminTokenConnectForm',

  directives: {
    mask: VueMaskDirective
  },

  mixins: [RouterMixin],

  data () {
    return {
      token: '',
      loading: false
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },

  created () {
    if (this.project.cf_token) {
      this.token = this.project.cf_token
    }
  },

  methods: {
    submit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true
          this.project.cf_token = this.token

          this.$store.dispatch('projects/save')
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Settings saved successfully',
                showConfirmButton: false,
                timer: 1500
              })
            })
            .catch(errors => {
              const arrErrors = Object.keys(errors.response.data.errors),
                errorMsg = arrErrors.length ? errors.response.data.errors[Object.keys(errors.response.data.errors)[0]] : errors.response.data.message

              this.$swal({
                icon: 'error',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: errorMsg,
                showConfirmButton: false,
                timer: 3000
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
