<template>
  <div class="position-relative">
    <div class="w-100">
      <div class="mb-3 mr-3">
        <b-img
          class="mb-2"
          fluid
          :src="settings.ad_banner.image.placeholder || settings.ad_banner.image.src"
        />

        <p
          class="text-muted"
          v-text="settings.ad_banner.image.description"
        />
      </div>

      <div class="w-50">
        <b-form-group class="mb-4">
          <div class="d-flex align-items-center w-100">
            <div class="text-dark font-size-16 mt-1 mr-4">
              Display Banner
            </div>
            <b-checkbox
              v-model="settings.ad_banner.enabled"
              switch
              size="lg"
            />
          </div>
        </b-form-group>

        <b-form-group>
          <b-row class="align-items-center">
            <b-col cols="2">
              <div class="text-dark font-size-16">
                Link
              </div>
            </b-col>
            <b-col>
              <b-input
                v-model="settings.ad_banner.href"
                type="url"
                :style="{height: '38px'}"
                placeholder="URL"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group>
          <b-row>
            <b-col cols="6">
              <image-upload
                class="position-relative"
                :image="settings.ad_banner.image"
              >
                <div class="absolute-center-x">
                  <div class="d-flex align-items-center justify-content-center flex-nowrap">
                    <div class="text-nowrap mr-2">
                      Upload Image
                    </div>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.10627 0.375L11.1365 4.40813C11.2475 4.53033 11.3071 4.69054 11.303 4.85552C11.299 5.0205 11.2316 5.17759 11.1148 5.29422C10.9981 5.41084 10.8409 5.47803 10.6759 5.48187C10.5109 5.48571 10.3508 5.42589 10.2287 5.31483L7.74625 2.8301V10.4188C7.74625 10.5888 7.67871 10.7519 7.55847 10.8721C7.43824 10.9924 7.27516 11.0599 7.10513 11.0599C6.93509 11.0599 6.77201 10.9924 6.65178 10.8721C6.53154 10.7519 6.464 10.5888 6.464 10.4188V2.83124L3.98155 5.31483C3.92197 5.37436 3.85126 5.42158 3.77344 5.45379C3.69562 5.48599 3.61222 5.50255 3.52799 5.50253C3.44377 5.5025 3.36038 5.48589 3.28258 5.45363C3.20478 5.42138 3.1341 5.37411 3.07456 5.31454C3.01503 5.25497 2.96781 5.18426 2.9356 5.10644C2.9034 5.02861 2.88683 4.94521 2.88686 4.86099C2.88689 4.77677 2.9035 4.69338 2.93576 4.61558C2.96801 4.53778 3.01527 4.46709 3.07485 4.40756L7.10627 0.375ZM13.3386 12.3427C13.7039 12.3427 13.9997 12.63 13.9997 12.9839C13.9997 13.3378 13.7039 13.625 13.3386 13.625H0.985051C0.61861 13.625 0.322266 13.3378 0.322266 12.9839C0.322266 12.63 0.61861 12.3427 0.985051 12.3427H13.3386Z"
                        fill="#6E747A"
                      />
                    </svg>
                  </div>
                </div>
              </image-upload>
            </b-col>
          </b-row>
        </b-form-group>
      </div>
    </div>
    <div
      v-if="!settings.ad_banner.allowed"
      class="overlay-inner d-flex align-items-center opacity-90 justify-content-center bg-white"
    >
      <div class="h5">
        Not available in the selected layout.
      </div>
    </div>
  </div>
</template>

<script>
import ImageUpload from '../../../editor/utils/ImageUpload'
import {mapState} from 'vuex'
export default {
  name: 'BlogBannerSettings',
  components: {ImageUpload},
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      globalStyles: state => state.editor.globalStyles
    }),
    adBannerStyles () {
      return {
        backgroundImage: `url(${this.settings.ad_banner.image.src || this.settings.ad_banner.image.placeholder})`,
        backgroundSize: 'cover'
      }
    }
  }
}
</script>

<style scoped>

</style>
