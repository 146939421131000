import { Mark } from 'tiptap'
import { toggleMark } from 'tiptap-commands'

export default class TextTransform extends Mark {

  get name () {
    return 'textTransform'
  }

  get defaultOptions () {
    return {
      textTransform: null
    }
  }

  get schema () {
    return {
      attrs: {
        textTransform: {
          default: null
        }
      },
      parseDOM: [{
        style: 'text-transform',
        getAttrs: value => ({
          textTransform: value
        })
      } ],
      toDOM: mark => {
        return ['span', {
          style: `text-transform:${mark.attrs.textTransform};`
        }, 0]
      }
    }
  }

  commands ({ type }) {
    return (attrs) => toggleMark(type, attrs)
  }
}
