<template>
  <div
    class="position-relative dashboard-search"
    style="min-width: 325px;"
  >
    <b-input
      v-model="search"
      placeholder="Search projects…"
    />

    <transition name="fade-fast">
      <svg
        v-if="!search"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.1734 11.0365C15.052 8.40038 14.8089 4.71703 12.4439 2.35203C9.80783 -0.28401 5.53397 -0.28401 2.89793 2.35203C0.261889 4.98807 0.261889 9.26193 2.89793 11.898C5.26293 14.263 8.94628 14.5061 11.5824 12.6275L18.0123 19.0574L19.6033 17.4664L13.1734 11.0365C13.1734 11.0365 15.052 8.40038 13.1734 11.0365ZM10.8529 3.94302C12.6102 5.70038 12.6102 8.54962 10.8529 10.307C9.09552 12.0643 6.24628 12.0643 4.48892 10.307C2.73156 8.54962 2.73156 5.70038 4.48892 3.94302C6.24628 2.18566 9.09552 2.18566 10.8529 3.94302C10.8529 3.94302 12.6102 5.70038 10.8529 3.94302Z"
          fill="#6E747A"
        />
      </svg>

      <b-link
        v-else
        @click="search = ''"
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.15207 7.5L14.7604 1.8911C14.9146 1.7367 14.9998 1.53071 15 1.31106C15 1.09129 14.9149 0.885058 14.7604 0.730901L14.2689 0.239528C14.1144 0.0847616 13.9084 0 13.6885 0C13.469 0 13.263 0.0847616 13.1085 0.239528L7.50024 5.84806L1.89171 0.239528C1.73744 0.0847616 1.53134 0 1.31159 0C1.09207 0 0.885976 0.0847616 0.731707 0.239528L0.24 0.730901C-0.08 1.05092 -0.08 1.57144 0.24 1.8911L5.84841 7.5L0.24 13.1087C0.0856097 13.2633 0.000609756 13.4693 0.000609756 13.6889C0.000609756 13.9086 0.0856097 14.1146 0.24 14.2691L0.731585 14.7605C0.885854 14.9151 1.09207 15 1.31146 15C1.53122 15 1.73732 14.9151 1.89159 14.7605L7.50012 9.15181L13.1084 14.7605C13.2629 14.9151 13.4689 15 13.6884 15H13.6887C13.9083 15 14.1143 14.9151 14.2688 14.7605L14.7602 14.2691C14.9145 14.1147 14.9996 13.9086 14.9996 13.6889C14.9996 13.4693 14.9145 13.2633 14.7602 13.1088L9.15207 7.5Z"
            fill="#6E747A"
          />
        </svg>
      </b-link>
    </transition>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'DashboardSearch',

  data () {
    return {
      timer: null
    }
  },

  computed: {
    ...mapState('dashboard', {
      folder: state => state.folder
    }),
    search: {
      get () {
        return this.$store.state.dashboard.search
      },
      set (val) {
        this.$store.state.dashboard.search = val
      }
    }
  },

  watch: {
    search (val) {
      if (!val) {
        return this.$store.dispatch('dashboard/loadFolder', this.folder ? this.folder.id : null)
      }

      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.$store.dispatch('dashboard/searchProject')
      }, 300)
    }
  }
}
</script>

<style lang="scss">
.dashboard-search {
  margin-right: 30px;

  input {
    &:focus,
    &:active {
      box-shadow: none;
    }

    &::placeholder {
      color: #B5BBC5;
    }
  }

  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    content: '';
    position: absolute;
    right: -18px;
    top: 50%;
    transform: translateY(-50%);
    height: 38px;
    width: 1px;
    background-color: #DDDFE2;
  }
}
</style>