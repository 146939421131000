<template>
  <div
    :style="{...fullPaddingSubject(section.options.customize.style), ...fullMarginSubject(section.options.customize.style)}"
    class="images-group-1"
  >
    <div class="d-inline-block position-relative">
      <slot />
    </div>
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'ImageItem1Template',
  mixins: [TemplateMixin, BorderMixin]
}
</script>

<style lang="scss">
.images-group-1 {
  padding: 100px 60px;

  .img {
    border-radius: 3px;
    box-shadow: inset 0 0 0px 4px #ffffff;
  }


  .higliter:nth-child(1),
  .img-transform:nth-child(1) {
    img {
      max-width: unset !important;
      width: 188px !important;
      min-height: 242px !important;
      object-fit: cover;
      object-position: bottom;
    }

    .img-responsive-bg {
      width: 188px !important;
      min-height: 242px !important;
    }
  }

  .higliter:nth-child(3),
  .img-transform:nth-child(3) {
    position: absolute !important;
    top: -10%;
    left: 90%;

    img {
      max-width: unset !important;
      width: 155px !important;
      min-height: 182px !important;
      object-fit: cover;
      object-position: bottom;
    }

    .img-responsive-bg {
      width: 155px !important;
      min-height: 182px !important;
    }
  }

  .higliter:nth-child(2),
  .img-transform:nth-child(2) {
    position: absolute !important;
    top: 50%;
    left: 70%;

    img {
      max-width: unset !important;
      width: 155px !important;
      min-height: 182px !important;
      object-fit: cover;
      object-position: bottom;
    }

    .img-responsive-bg {
      width: 155px !important;
      min-height: 182px !important;
    }
  }
}
</style>
