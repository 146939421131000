<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="el-dynamic"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('data')"
  >
    <div
      :data-sid="section.sid"
    >
      <div
        class="d-flex post-list flex-wrap"
        :class="className()"
      >
        <a
          v-for="(post, index) in getPosts"
          :key="post.id"
          class="d-flex cursor-pointer"
          :class="{'active': !index}"
        >
          <span
            v-show="displayItems.featuredImage"
            class="featured-image"
          />
          <i
            v-show="displayItems.listIcon"
            class="icon"
            :class="section.options.content.data.icon"
          />
          <div>
            <span
              class="title"
              v-text="post.title"
            />

            <div
              class="secondary d-flex"
            >
              <div class="d-flex">
                <div v-show="displayItems.showAuthor">
                  by <a href="#">John</a>
                  <span
                    v-show="displayItems.showDate"
                    class="mx-2"
                  >|</span>
                </div>
                <span v-show="displayItems.showDate">June 10, 2022</span>
              </div>
              <span v-show="displayItems.showCategory">in: <strong>Social Media</strong></span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
import {mapState} from 'vuex'

export default {
  name: 'ElBlogCategories',
  components: {Highliter},
  mixins: [SectionMixin],
  computed: {
    ...mapState('blogEditor', {
      post: state => state.post
    }),
    getPosts () {
      return [
        {
          id: 1,
          title: 'Post Title'
        },
        {
          id: 2,
          title: 'Post Title'
        },
        {
          id: 3,
          title: 'Post Title'
        }
      ]
    },
    displayItems () {
      return this.section.options.content.data.display
    }
  },
  methods: {
    className (name = '') {
      return `--post-list-${this.section.sid}${name}`
    }
  }
}
</script>

<style lang="scss">
.post-list {
  flex-direction: column;
  gap: 35px;

  a {
    text-decoration: none;

    .title {
      color: #000;
      font-weight: 700;
      font-size: 18px;
      line-height: 1;
    }

    .secondary {
      font-weight: 400;
      font-size: 13px;
      color: #44474A;
      flex-direction: column;
    }

    .icon {
      color: #000;
      font-size: 18px;
      margin-right: 13px;
    }

    .featured-image {
      width: 103px;
      height: 60px;
      background-color: #c4c4c4;
      border-radius: 4px;
      margin-right: 18px;
    }
  }

  a:hover {
    .title {
      color: rgba(0, 0, 0, 0.7);
    }
  }
}
</style>