<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="d-flex align-items-center">
        <span class="indicator mr-2" />
        <p class="text-capitalize mb-0">
          {{ labelCase(componentName) }}
        </p>
      </div>
    </div>

    <draggable
      v-model="config.children"
      handle=".move"
    >
      <transition-group name="shufle">
        <b-card
          v-for="(section, index) in config.children"
          :key="section.uid"
          no-body
          class="border-0"
          @click="$store.dispatch('editor/sectionSettings', {section: section})"
        >
          <section-item-hover>
            <icon
              class="chevron mr-3"
              icon="chevron.svg"
            />
            <icon
              class="move mr-3"
              icon="move.svg"
            />
            <div block>
              {{ section.name }}
            </div>

            <template slot="menu">
              <b-link
                class="d-flex align-items-center"
                @click.stop="deleteSection(index)"
              >
                <i class="fa fa-trash" />
              </b-link>
            </template>
          </section-item-hover>
        </b-card>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ThemeMixin from '@builder/components/mixins/ThemeMixin'
import draggable from 'vuedraggable'

export default {
  name: 'ColumnsMod',

  components: {draggable},
  mixins: [ModMixin, ThemeMixin],

  methods: {
    deleteSection (index) {
      this.config.children.splice(index, 1)
    }
  }
}
</script>
