<template>
  <div>
    <module-editor
      v-if="!loading"
      ref="editor"
      v-model="blogTemplate.content"
      crumb-title="Post Content Template"
      aside-title="Post Content Template"
      group="blocks"
      :draggable="true"
      @save="onSave"
    >
      <template #aside>
        <SidbearSettingsGroup
          title="Layout Templates"
          content-class="py-0"
        >
          <div class="overflow-auto max-h-[470px] mr-[-21px] pr-[21px] pt-[14px] pb-[22px]">
            <div class="flex flex-col gap-[18px] w-100 align-items-center py-[18px]">
              <div
                v-for="(template, index) in templates"
                :key="template.id"
                class="shadow-sm h-[200px] overflow-hidden bg-center w-[200px] relative text-[14px] font-[700] flex justify-center items-center cursor-pointer transition bg-cover border-[1px] border-[#D7D7D7] rounded-[5px]"
                :class="{'border-[#4F83E3] border-[2px]': template.id === blogTemplate.id}"
                :style="{backgroundImage: `url(${template.screen})`}"
                @click="selectTemplate(index)"
              >
                <span
                  v-if="template.id === 'blank'"
                  v-text="template.name"
                />
                <div
                  v-else
                  class="absolute bottom-0 text-white font-[700] text-[14px] left-0 h-[40px] bg-[rgba(0,0,0,.65)] flex items-center px-[12px] text-truncate w-[200px]"
                  v-text="template.name"
                />
              </div>
            </div>
          </div>
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Blocks"
          content-class="p-0"
        >
          <blocks-drag-items :filtered-blocks="['empty-blocks']" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Rows"
          :active="$store.state.editor.page === 'add-row'"
          content-class="p-0 no-scrollbar"
        >
          <rows-drag-items />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Elements"
          content-class="p-0"
          :active="$store.state.editor.page === 'add-element'"
        >
          <elements-drag-items editor="blog" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Dynamic Content"
          content-class="p-0"
        >
          <dynamic-elements-drag-items :group="['Dynamic Post Template']" />
        </SidbearSettingsGroup>

        <SidbearSettingsGroup
          title="Favorites"
          content-class="p-0"
        >
          <favorites-drag-items class="border-top-0" />
        </SidbearSettingsGroup>
      </template>

      <content-drop-area
        v-if="blogTemplate.content"
        v-model="blogTemplate.content"
        :content-key="blogTemplate.id"
        drop-area-class="vh-70"
        group="blocks"
        add-group="blocks"
      />
    </module-editor>
    <div
      v-else
      class="flex justify-center items-center vh-100"
    >
      <b-spinner variant="primary" />
    </div>

    <b-modal
      v-model="modal"
      hide-header
      hide-footer
      size="lg"
      centered
    >
      <div class="py-[40px] px-[90px]">
        <h5 class="text-[27px] font-[600] text-center">
          Save Layout Template
        </h5>

        <b-form @submit.prevent="saveAsTemplate">
          <b-form-group
            label="Give your Template a name:"
            label-class="!text-[15px] !text-[#5B5B5B] !font-[400]"
            class="!mb-[35px]"
          >
            <b-input
              v-model="layoutName"
              required
            />
          </b-form-group>

          <modal-button
            :loading="modalLoading"
          >
            Save Layout Template
          </modal-button>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ResolutionMixin from '../../components/mixins/ResolutionMixin'
import {applyPageStyles, pageStylesToString} from '@/utils/useStylesheet'
import ViewportUpdate from '@/mixins/viewportUpdate'
import ContentDropArea from '@/components/editor/components/ContentDropArea.vue'
import ModuleEditor from '@/views/module-editor/ModuleEditor.vue'
import DynamicElementsDragItems from '@/components/editor/components/DynamicElementsDragItems.vue'
import BlocksDragItems from '@/components/editor/components/BlocksDragItems.vue'
import ElementsDragItems from '@/components/editor/components/ElementsDragItems.vue'
import FavoritesDragItems from '@/components/editor/components/FavoritesDragItems.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import RowsDragItems from '@/components/editor/components/RowsDragItems.vue'
import ModalButton from '@/components/editor/components/ModalButton.vue'
import Screenshot from '@/mixins/Screenshot'
import defaultTempaltes from './post-template/post-content-templates'

export default {
  name: 'PostContentTemplateEditor',
  components: {
    ModalButton,
    RowsDragItems,
    SidbearSettingsGroup,
    FavoritesDragItems,
    ElementsDragItems,
    BlocksDragItems,
    DynamicElementsDragItems, ModuleEditor, ContentDropArea},
  mixins: [ResolutionMixin, ViewportUpdate, Screenshot],
  data () {
    return {
      modal: false,
      layoutName: '',
      modalLoading: false,
      selectedTemplate: 0,
      loading: false,
      interval: null,
      hasOtherTemplates:false,
      settings: {
        hideHeader: false,
        hideFooter: false,
        sidebar: {
          enable: true,
          right: true
        }
      },
      templates: defaultTempaltes,
      favoriteBlock: {},
      blogTemplate: {},
      post: {
        allow_comment_guests: true,
        preview: {},
        'cover': '',
        'cover_thumb': '',
        'title': '',
        'description': '',
        'content': '',
        'code_injection': '',
        'status_code': 'publish',
        'fonts': '',
        'google_fonts': '',
        'custom_fonts': '',
        'categories': [],
        'author_id': null,
        'show_author': true,
        'show_date': true,
        'show_categories': true,
        'show_likes': true,
        'allow_comments': true,
        'allow_sharing': true,
        'seo_title': '',
        'post_date': '',
        'seo_keywords': '',
        'seo_description': '',
        'template_type': '',
        'template_id': 0
      }
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },

  async beforeRouteLeave (to, from, next) {
    const answer = await this.$refs.editor.confirmLeave()
    if (!answer) return false
    next()
  },

  created () {
    this.fetchTemplates()

    this.$store.commit('editor/SET_PAGE', '')

    this.$store.commit('historyChanges/SET_DISABLE', false)

    this.$store.state.editor.backLink = {
      name: 'settings.blog'
    }

    if (this.$route.params.template_id !== 'new') {
      axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/templates/${this.$route.params.template_id}`)
        .then(({data}) => {
          this.blogTemplate = {
            ...data.data,
            content: JSON.parse(data.data.content)
          }
          this.layoutName = data.data.name
          applyPageStyles(data.data.css, 'subject-styles')
        })
    } else {
      this.selectTemplate(1)
    }
  },

  methods: {
    onSave () {
      this.modal = true
    },
    saveAsTemplate () {
      this.modalLoading = true

      this.takeScreenshot(document.getElementById('editor-container').querySelector('div > div'))
        .then((screen) => {
          this.$store.dispatch('font/prepareFonts', {}, {root: true})
            .then((fonts) => {
              const params = {
                template_type: 'single',
                content: JSON.stringify(this.blogTemplate.content),
                css: pageStylesToString('subject-styles'),
                name: this.layoutName,
                screen,
                fonts
              }
              if(!this.hasOtherTemplates) params.is_main = true
              axios[this.$route.params.template_id === 'new' ? 'post' : 'put'](`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/templates${this.$route.params.template_id === 'new' ? '' : '/' + this.$route.params.template_id}?template_type=single`, 
                params
              ).then(({data}) => {
                this.$swal({
                  icon: 'success',
                  iconColor: '#4F83E3',
                  toast: true,
                  position: 'top-right',
                  title: 'Saved!',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.fetchTemplates()
                this.$router.push({name: '', params: {template_id: data.data.id}})
              })
                .catch(() => {
                  this.$swal({
                    icon: 'error',
                    iconColor: '#4F83E3',
                    toast: true,
                    position: 'top-right',
                    title: 'Server Error.',
                    showConfirmButton: false,
                    timer: 1500
                  })
                })
                .finally(() => {
                  this.modalLoading = false
                  this.modal = false
                })
            })
        })
    },
    async fetchTemplates () {
      this.loading = true
      return await axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/templates?template_type=single`)
        .then(({data}) => {
          const items = data.data.data
          this.hasOtherTemplates = Boolean(items.length)
          this.templates = [...this.templates, ...items.filter(temp => temp.screen)]
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectTemplate (index) {
      this.$store.commit('editor/SET_PAGE', '')

      if (!this.templates[index].css) {
        this.loading = true
        return axios.get(`https://blog-api.estage.com/api/projects/${this.project.id}/blog/manage/posts/templates/${this.templates[index].id}`)
          .then(({data}) => {
            this.templates[index].content = JSON.parse(data.data.content)
            this.templates[index].css = data.data.css
            this.blogTemplate = {
              ...data.data,
              content: JSON.parse(data.data.content)
            }
            applyPageStyles(data.data.css, 'subject-styles')
          })
          .finally(() => {
            this.loading = false
          })
      }

      this.blogTemplate = JSON.parse(JSON.stringify(this.templates[index]))
      applyPageStyles(this.blogTemplate.css, 'subject-styles')
    }
  }
}
</script>
