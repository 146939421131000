export default {
  computed: {
    marginTopFactory () {
      return {
        lg: {

        },
        md: {

        },
        sm: {

        }
      }
    },
    marginFactory () {
      if (!this.config.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.margin
    }
  }
}