<template>
  <div>
    <sidbear-settings-group
      title="Post Thumbnail"
      active
    >
      <sid-range-slider
        rule-name="width"
        :sid="className(' .post-thumb-container > div')"
        default-dimension="%"
        :dimensions="['%', 'vw', 'px']"
        label="Image Width"
        var="--width"
        measurement="width"
      />

      <sid-range-slider
        rule-name="height"
        :sid="className(' .post-thumb-container .post-thumb')"
        default-dimension="px"
        :dimensions="['vw', 'px']"
        label="Image Height"
        var="--height"
        measurement="height"
      />

      <sid-align
        :sid="className(' .post-thumb-container')"
        rule-name="justify-content"
        label="Alignment"
      />
    </sidbear-settings-group>

    <sidbear-settings-group title="Filters">
      <rule-tabs>
        <template #Normal>
          <sid-background-filters
            :sid="className(' .post-thumb')"
            rule-name="filter"
          />

          <sid-range-slider
            label="Opacity"
            :sid="className(' .post-thumb')"
            rule-name="opacity"
            :default-dimension="''"
            :dimensions="[]"
            :min="0"
            :max="1"
            :step="0.1"
          />
        </template>
        <template #Hover>
          <sid-checkbox
            value=".3s"
            unchecked-value=""
            rule-name="transition"
            var="--transition"
            :sid="className(' .post-thumb')"
          >
            Transition <span
              class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
            >BETA</span>
          </sid-checkbox>

          <sid-background-filters
            :sid="className()"
            rule-name="filter"
            presudo=" .post-grid-card:hover .post-thumb"
          />

          <sid-range-slider
            label="Opacity"
            :sid="className()"
            rule-name="opacity"
            :default-dimension="''"
            :dimensions="[]"
            :min="0"
            :max="1"
            presudo=" .post-grid-card:hover .post-thumb"
            :step="0.1"
          />
        </template>
      </rule-tabs>
    </sidbear-settings-group>

    <sidbear-settings-group title="Border & Radius">
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="className(' .post-thumb')"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="className(' .post-thumb')"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="className(' .post-thumb')"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="className(' .post-thumb')"
        rule-name="border"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </sidbear-settings-group>

    <sidbear-settings-group title="Drop Shadow">
      <b-form-group
        label="Shadow Color"
        class="mb-0"
      >
        <sid-box-shadow
          :sid="className(' .post-thumb')"
          rule-name="box-shadow"
        />
      </b-form-group>
    </sidbear-settings-group>

    <sidbear-settings-group title="Padding & Margins">
      <sid-linked-group
        label="Padding"
        :sid="className(' .post-thumb-container')"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="className(' .post-thumb-container')"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </sidbear-settings-group>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidBackgroundFilters
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundFilters.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox.vue'

export default {
  name: 'PostThumbMod',
  components: {
    SidCheckbox,
    RuleTabs,
    SidColor,
    SidBoxShadow,
    SidSelect,
    SidLinkedGroup, SidBackgroundFilters, SidRangeSlider, SidAlign, SidbearSettingsGroup},
  mixins: [ModMixin],
  methods: {
    className (name = '') {
      return `.post-grid-${this.section.sid}${name}`
    }
  }
}
</script>