<template>
  <b-modal
    ref="modal"
    :visible="modal"
    dialog-class="video-library-modal"
    size="xl"
    header-class="!border-b-0 bg-white"
    body-class="p-0"
    hide-footer
    @change="handleModalChange"
  >
    <template #modal-header>
      <icon
        class="ml-auto cursor-pointer"
        icon="close.svg"
        @click.native="handleModalChange(false)"
      />
    </template>

    <div
      v-if="loading"
      class="w-full h-[250px] flex items-center justify-center "
    >
      <b-spinner
        class="!w-12 !h-12"
        variant="primary"
      />
    </div>
    
    <template
      v-else
      class="relative"
    >
      <VideoLibHeader
        :search="filters.search"
        :total="total"
        :breadcrumbs="breadcrumbs"
        :active-file="activeFile"
        :open-folder="openFolder"
        :insert="uploaded && insertFile"
      />
      <VideoLibContent
        :folders="folders"
        :files="files"
        :active-file="activeFile"
        :loading="contentLoading"
        :open-folder="openFolder"
        :select-file="selectFile"
      />
    </template>
  </b-modal>
</template>

<script>
import {mapActions, mapGetters, mapState, mapMutations} from 'vuex'
import Icon from '@/components/editor/utils/Icon.vue'
import VideoLibHeader from '@/components/video-library/VideoLibHeader.vue'
import VideoLibContent from '@/components/video-library/VideoLibContent.vue'

export default {
  components: {
    Icon,
    VideoLibHeader,
    VideoLibContent
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    file: {
      type: Object,
      default: null
    },
    uploaded: Function
  },
  data () {
    return {
      loading: false,
      contentLoading: false,
      modalId: null
    }
  },
  computed: {
    ...mapState('videosLibrary', {
      total: state => state.total,
      folders: state => state.folders,
      files: state => state.files,
      filters: state => state.filters,
      activeFolder: state => state.activeFolder,
      activeFile: state => state.activeFile
    }),
    ...mapGetters({
      breadcrumbs: 'videosLibrary/breadcrumbs'
    })
  },
  async mounted () {
    await this.initLoadData()
    this.modalId = this.$refs.modal ? this.$refs.modal.modalId : null
  },
  watch: {
    'filters.search' () {
      if (this.filters.search !== '') {
        this.setActiveFolder(null)
        this.loadSearch()
      } else {
        this.setActiveFolder(null)
        this.loadData('0')
      }
    }
  },
  methods: {
    ...mapActions({
      getAll: 'videosLibrary/getAll',
      getTotal: 'videosLibrary/getTotal',
      getBreadcrumbs: 'videosLibrary/getBreadcrumbs',
      getFolders: 'videosLibrary/getFolders',
      getFiles: 'videosLibrary/getFiles',
      getSearch: 'videosLibrary/getSearch'
    }),
    ...mapMutations({
      setActiveFolder: 'videosLibrary/SET_ACTIVE_FOLDER',
      setActiveFile: 'videosLibrary/SET_ACTIVE_FILE'
    }),
    async initLoadData () {
      this.setActiveFile(null)
      this.loading = true
      await this.getAll('0')
      this.loading = false
    },
    async loadData (id) {
      this.setActiveFile(null)
      this.contentLoading = true
      await this.getAll(id)
      this.contentLoading = false
    },
    async loadSearch () {
      this.setActiveFile(null)
      this.contentLoading = true
      await this.getSearch()
      this.contentLoading = false
    },
    async openFolder (folder) {
      const id = folder?.id || null
      if (this.activeFolder && this.activeFolder.id === id) return

      this.setActiveFolder(folder)
      await this.loadData(id)
    },
    selectFile (file) {
      const id = file?.id || null

      if (this.activeFile && this.activeFile.id === id) {
        this.setActiveFile(null)
      } else {
        this.setActiveFile(file)
      }
    },
    insertFile (file) {
      if (!this.file) return
      this.$set(this.file, 'src', file.src)
      this.$set(this.file, 'id', file.id)

      if (this.uploaded) {
        this.uploaded({...file, src: file.src})
      }

      this.handleModalChange(false)
    },
    handleModalChange (val) {
      this.$emit('update:modal', val)
    }
  }
}
</script>

<style lang="scss" >
.video-library-modal .modal-content {
  min-height: 95vh;
}

@media (max-width: 1200px) {
  .video-library-modal {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 50px)!important;
  }
}
</style>