import Vue from 'vue'
import _ from 'lodash'

function recursiveSearch (obj, tags = { h1: 0, h2: 0, h3: 0, h4: 0, h5: 0, h6: 0 }) {
  if (Array.isArray(obj)) {
    obj.forEach(item => {
      recursiveSearch(item, tags)
    })
  } else if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach(key => {
      recursiveSearch(obj[key], tags)
    })

    const elements = ['ElHeadline', 'ElHeadlineV2', 'ElSubheadline', 'ElSubheadlineV2']

    if (obj.hasOwnProperty('component') && _.includes(elements, obj.component)) {
      if (obj.options?.content?.description?.tag) {
        ++tags[obj.options.content.description.tag]
      }
    }
  }

  return tags
}

export default function pageAudit (object) {
  if (!object) {
    return
  }

  let tags = recursiveSearch(object)
  let errorMessages = ''

  if (tags.h1 === 0) {
    errorMessages = 'This page does not contain a headline with an H1 tag. To help optimize the page for SEO and' +
      ' accessibility, add an H1 tag to the page title Headline element'
  }

  if (tags.h1 > 1) {
    errorMessages = 'This page has more than one heading with an H1 tag. To help optimize a page for SEO and' +
      ' accessibility, make sure the page title Headline element has an H1 tag and is the only one has tag H1 on that page.'
  }

  if (errorMessages) {
    Vue.swal({
      icon: 'warning',
      toast: true,
      position: 'top-start',
      title: errorMessages,
      timerProgressBar: true,
      showConfirmButton: false,
      timer: 5000
    })
  }
}
