<template>
  <b-form-group>
    <label
      v-if="title"
      class="pb-0"
      v-text="title"
    />
    <div
      class="flex items-center hover:bg-gray-100 justify-start text-dark px-[16px] text-[13px] font-[600] h-[38px] rounded-[4px] border gap-[9px] cursor-pointer"
      :class="{'w-100': !title}"
      @click="() => modal = true"
    >
      <i class="fa fa-plus text-primary" />
      Upload video
    </div>
    <video-lib-modal
      :modal.sync="modal"
      :file="video"
      :uploaded="uploaded"
    />
  </b-form-group>
</template>
<script>
import SidConcept from '@/components/mixins/SidConcept'
import {mapState} from 'vuex'
import VideoLibModal from '@/components/video-library/VideoLibModal.vue'

export default {
  name: 'SidUploadVideo',
  components: {VideoLibModal},
  mixins: [SidConcept],

  props: {
    title: {
      type: String
    },
    index: {
      type: Number
    }
  },

  data () {
    return {
      modal: false,
      video: {}
    }
  },

  computed: {
    ...mapState('editor', {
      section: state => state.section
    })
  },

  watch: {
    sid () {
      this.prepareOptions()
    }
  },

  created () {
    this.prepareOptions()
  },

  methods: {
    prepareOptions () {
      if (this.getSidStyle(this.getSid(), this.ruleName) === 'none') return
      const src = (this.section.component === 'ElVideoV2' || this.section.component === 'ElVideoAnimation') ? this.section.options.content.video?.src : null

      this.video = {
        id: this.getSidStyle(this.getSid(), '--bg-id') || 'rand',
        path: src ? src : this.getSidStyle(this.getSid(), this.ruleName)
      }
    },

    uploaded (file) {
      if (!this.section.options.content.video) {
        this.section.options.content.video = file
      }

      if (this.section.component === 'ElVideoV2' || this.section.component === 'ElVideoAnimation') {
        this.section.options.content.video.noIndex = file.no_index ? '1' : '0'
        this.section.options.content.video.src = 'https://player.estage.com/' + file.id
      } else if (this.section.component === 'ElVideoPlaylist') {
        this.section.options.content.playlist.items[this.index].url = 'https://player.estage.com/' + file.id
        this.section.options.content.playlist.items[this.index].src = 'https://video-upload.estage.com/video/preview/' + file.id
        this.$emit('uploaded-video', 'https://player.estage.com/' + file.id)
        this.$emit('uploaded-preview', 'https://video-upload.estage.com/video/preview/' + file.id)
      } else {
        this.addSidRules([[this.getSid(), [[this.ruleName, 'https://player.estage.com/' + file.id]]]])
      }

      this.addSidRules([[this.getSid(), [['--bg-id', file.id]]]])

      this.video = {
        id: file.id || 'rand',
        url: 'https://player.estage.com/' + file.id
      }
    }
  }
}
</script>