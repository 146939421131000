<template>
  <div
    class="container"
    :style="{...shadow, ...padding}"
  >
    <b-row
      no-gutters
      class="about-us-1 align-items-center"
    >
      <b-col :cols="cols(5, 12)">
        <image-fit
          no-parent
          :customs="section.options.customize.style.backgroundImage"
          :src="section.options.customize.style.backgroundImage.src"
          :size="section.options.customize.style.backgroundImage.size"
          :position="section.options.customize.style.backgroundImage.position"
        />
      </b-col>


      <b-col
        class="position-relative"
        :class="cols('offset-left-lg', '')"
        :cols="cols(7, 12)"
        :style="{...backgroundColor}"
      >
        <slot name="elements" />
        <div
          class="transform-image"
          :class="cols('transform-image-lg', '')"
        >
          <slot name="image" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TemplateMixin from '../../../../mixins/TemplateMixin'
import ImageFit from '../../../../utils/ImageFit'

export default {
  name: 'AboutUs1Template',
  components: {ImageFit},
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
  .about-us-1 {
    .headline-divider {
      width: 230px;
    }

    .headline-divider-sm {
      display: flex;
      justify-content: center
    }

    .offset-left-lg {
      padding-left: 10rem !important;
    }

    .transform-image-lg {
      position: absolute;
      top: 50%;
      left: -362px;
      transform: translateY(-50%);
      height: 285px;
      width: 459px;
    }

    .image-fit {
      width: 100%;
    }
  }
</style>
