<template>
  <div
    v-if="customs"
    class="flex"
    :class="[customs.hoverAnimation, align, (getScale > 1 && overflowScale) ? 'overflow-hidden' : '']"
    :style="{...shapeShadow, ...radius}"
  >
    <b-img
      :alt="customs.alt"
      :rounded="shape"
      :src="src"
      :style="styles"
      fluid
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Animations from '../mixins/Animations'
import ResolutionMixin from '../mixins/ResolutionMixin'

export default {
  name: 'ImageFitV2',

  mixins: [Animations, ResolutionMixin],

  props: {
    customs: {
      type: Object,
      required: true
    }
  },

  computed: {
    align () {
      return 'justify-content-' + _.get(this.customs, ['align', this.resolutioner], 'center')
    },
    styles () {
      return {
        marginTop: !this.customs.offsetTop ? 0 : `${this.customs.offsetTop[this.resolutioner]}px`,
        filter: !this.customs.filters ? '' : `blur(${this.customs.filters.blur}) grayscale(${this.customs.filters.grayscale}) invert(${this.customs.filters.invert}) contrast(${this.customs.filters.contrast}) sepia(${this.customs.filters.sepia})`,
        transform: `rotate(${parseInt(_.get(this.customs, ['rotate', this.resolutioner], _.get(this.customs, ['rotate', 'lg'], _.get(this.customs, 'rotate', 0))))}deg) ${this.flip} ${this.translate}`,
        ...this.scale,
        ...this.shadow,
        ...this.border,
        ...this.radius,
        ...this.customSize,
        ...this.opacity
      }
    },
    overflowScale () {
      return _.get(this.customs, ['overflowScale', this.resolutioner], false)
    },
    src () {
      return this.customs.url || this.customs.src || this.customs.placeholder
    },
    translate () {
      const translateY = _.get(this.customs, ['translate', 'y', this.resolutioner], 0)
      const translateX = _.get(this.customs, ['translate', 'x', this.resolutioner], 0)
      return `translate(${translateX}px, ${translateY}px)`
    },
    radius () {
      const transformValues = {
        rouneded: 5,
        null: 0,
        circle: 50
      }
      const value = transformValues[_.get(this.customs, ['shape', this.resolutioner, 'code'], null)]
      const radius = this.customs.radius || [value, value, value, value]

      return {
        borderTopLeftRadius: `${radius[0]}px`,
        borderTopRightRadius: `${radius[1]}px`,
        borderBottomLeftRadius: `${radius[2]}px`,
        borderBottomRightRadius: `${radius[3]}px`
      }
    },
    getScale () {
      if (!this.customs.scale) return 1
      return this.customs.scale[this.resolutioner]
    },
    customSize () {
      const enabled = _.get(this.customs, ['customSize', 'enabled', this.resolutioner], false)
      if (!enabled) return {}

      const heightData = _.get(this.customs, ['customSize', 'height', this.resolutioner], {
        value: 100,
        type: 'px'
      })

      const widthData = _.get(this.customs, ['customSize', 'width', this.resolutioner], {
        value: 100,
        type: 'px'
      })

      return {
        height: `${heightData.value}${heightData.type}`,
        width: `${widthData.value}${widthData.type}`
      }
    },
    scale () {
      if (!this.customs.scale) return 1

      const scale = this.customs.scale[this.resolutioner]
      if (scale < 1) {
        return {
          width: `${this.customs.scale[this.resolutioner] * 100}%`
        }
      }

      if (scale === 1) {
        return {
          width: 'auto'
        }
      }

      return {
        width: `${this.customs.scale[this.resolutioner] * 100}%`,
        maxWidth: 'unset'
      }
    },
    opacity () {
      return {
        opacity: _.get(this.customs, ['opacity', this.resolutioner], 1)
      }
    },
    border () {
      const border = this.customs.border || [0, 0, 0, 0]
      return {
        borderColor: this.customs.borderColor,
        borderStyle: 'solid',
        borderTopWidth: `${border[0]}px`,
        borderRightWidth: `${border[1]}px`,
        borderBottomWidth: `${border[2]}px`,
        borderLeftWidth: `${border[3]}px`
      }
    },
    flip () {
      const flip = this.customs.flip
      if (!flip) return ''
      return `${flip.v ? 'scaleX(-1)' : ''} ${flip.h ? 'scaleY(-1)' : ''}`
    },
    shadow () {
      const shadow = this.customs.shadowOptions
      const color = this.customs.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    shape () {
      const shape = _.get(this.customs.shape, [this.resolutioner, 'code'], null)
      return shape === 'rounded' || shape
    },
    shapeShadow () {
      const shadow = this.customs.shadowOptions
      const color = this.customs.shadowColor || '#DADADA'

      if (!shadow || shadow.spread === 0 || this.shape !== 'bubble') return {}

      return {
        '-webkit-filter': `drop-shadow(${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${color})`
      }
    }
  },

  methods: {
    deb () {
      console.log(this.customs)
    }
  }
}
</script>
