<template>
  <div
    v-if="loaded"
    class="h-100"
  >
    <div
      v-if="image"
      class="h-100"
    >
      <div
        v-if="!noParent"
        class="h-100"
        :style="{marginTop: `${customs.offsetTop || 0}px`}"
      >
        <component
          :is="tag"
          :class="{'cursor-pinter': modal || (customs.page && customs.page.title !== 'Not selected')}"
          :data-aos="animations.animation"
          data-aos-once="true"
          data-aos-offset="200"
          :data-aos-delay="animations.delay"
          :data-aos-duration="animations.duration || 400"
          v-bind="$attrs"
          class="img-responsive-bg position-relative"
          :style="{width: width, minHeight: heightMutation, transform}"
          @click="onClick"
        >
          <div
            v-show="hasBorder"
            class="mask-border"
            :style="maskBorder"
          />
          <div
            class="inner-container"
            :class="[{'position-static': sizeMutation === 'contain'}, {'rounded': rouneded}, innerContainerClass]"
          >
            <img
              width="100%"
              height="100%"
              :style="{objectFit: sizeMutation, objectPosition: position, filter: filters, ...imageBorder}"
              :src="src"
              :alt="customs.alt || 'Image'"
            >
          </div>
        </component>
      </div>

      <component
        :is="tag"
        v-else
        :class="{'cursor-pinter': modal}"
        :data-aos="animations.animation"
        data-aos-once="true"
        data-aos-offset="200"
        :data-aos-delay="animations.delay"
        :data-aos-duration="animations.duration || 400"
        v-bind="$attrs"
        class="img-responsive-bg position-relative"
        :style="{width: width, minHeight: heightMutation, transform}"
        @click="onClick"
      >
        <div
          v-show="hasBorder"
          class="mask-border"
          :style="maskBorder"
        />
        <div
          class="inner-container"
          :class="[{'position-static': sizeMutation === 'contain'}, {'rounded': rouneded}, innerContainerClass]"
        >
          <img
            width="100%"
            height="100%"
            :style="{objectFit: sizeMutation, objectPosition: position, filter: filters, ...imageBorder}"
            :src="src"
            :alt="customs.alt || 'Image'"
          >
        </div>
      </component>
    </div>

    <img
      ref="image"
      style="display: none;"
      width="100%"
      height="100%"
      :src="src"
      :alt="customs.alt || 'Image'"
      @load="setImageProperties"
    >
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import Animations from '../mixins/Animations'

export default {
  name: 'ImageFit',

  mixins: [Animations],

  props: {
    customs: {
      type: Object
    },
    noParent: {
      type: Boolean,
      default: false
    },
    innerContainerClass: {
      type: String,
      default: ''
    },
    tag: {
      default: 'div'
    },
    size: {
      required: true
    },
    src: {
      required: true
    },
    position: {
      default: 'center'
    },
    height: {
      type: String,
      default: '50vh'
    },
    width: {
      type: String,
      default: '100%'
    },
    rouneded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      image: null,
      loaded: true
    }
  },

  computed: {
    sizeMutation () {
      return !this.shape ? this.size : this.shape !== 'shape-1' ? 'contain' : this.size
    },
    transform () {
      return `rotate(${this.customs.rotate})`
    },
    filters () {
      let style = ''
      for (let key in this.customs.filters) {
        style += `${key}(${this.customs.filters[key]}) `
      }
      return style
    },
    shape () {
      return _.get(this.customs, 'shape.code')
    },
    maskBorder () {
      if (this.shape === 'shape-2' || !this.shape) return
      if (!Object.prototype.hasOwnProperty.call(this.customs, 'maskBorder')) {
        this.$set(this.customs, 'maskBorder', {
          sm: {
            color: '#000000',
            width: 0
          },
          md: {
            color: '#000000',
            width: 0
          },
          lg: {
            color: '#000000',
            width: 0
          }
        })
      }

      return {
        backgroundColor: this.customs.maskBorder[this.tablet ? 'md' : this.resolution].color,
        width: `${97 + parseInt(this.customs.maskBorder[this.tablet ? 'md' : this.resolution].width)}%`,
        height: `${99 + parseInt(this.customs.maskBorder[this.tablet ? 'md' : this.resolution].width)}%`
      }
    },
    imageBorder () {
      if (this.shape === 'shape-2' || !this.shape) {
        return {
          boxShadow: `0 0 0 ${parseInt(_.get(this.customs, ['maskBorder', this.tablet ? 'md' : this.resolution, 'width']))}px ${_.get(this.customs, ['maskBorder', this.tablet ? 'md' : this.resolution, 'color'])}`
        }
      }

      return {}
    },
    hasBorder () {
      return Object.prototype.hasOwnProperty.call(this.customs, 'maskBorder') && parseInt(this.customs.maskBorder[this.tablet ? 'md' : this.resolution].width)
    },
    heightMutation () {
      if (!this.image) {
        return
      }
      if (!Object.prototype.hasOwnProperty.call(this.customs, 'ratio') || this.sizeMutation === 'contain') { return this.height }
      return (this.customs.ratio.typeH || this.customs.ratio.type) === '%' ? `${this.image ? ((this.customs.ratio[this.resolution].h / 100) * this.image.naturalHeight).toFixed(2) : 0}px` : this.height
    },
    modal () {
      return _.get(this.customs, 'modal.data.modal_id')
    },
    ...mapState('editor', {
      resolution: state => state.resolution,
      tablet: state => state.tablet
    })
  },

  watch: {
    src () {
      this.reload()
    },
    size () {
      this.reload()
    }
  },

  methods: {
    reload () {
      this.loaded = false

      setTimeout(() => {
        this.loaded = true
      }, 100)
    },
    setImageProperties () {
      this.image = this.$refs.image
    },
    onClick () {
      if (this.customs.action === 'Modal') {
        return this.$store.dispatch('modal/load', this.modal)
      } else if (this.customs.action === 'Link') {
        if (!this.customs.page || this.customs.page.title === 'Not selected') { return }
      }
    }
  }
}
</script>

<style lang="scss">
.img-responsive-bg {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .inner-container {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
