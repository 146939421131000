<template>
  <b-modal
    v-model="modal"
    class="position-relative"
    lazy
    centered
    body-class="py-5"
    content-class="new-section-modal"
    size="lg"
    hide-footer
    hide-header
  >
    <h3 class="text-center font-weight-bold mb-12 px-0">
      {{ isSharable ? 'Update Favorite': 'New Favorite' }}
    </h3>

    <b-row>
      <b-col
        offset="2"
        cols="8"
      >
        <b-form @submit.prevent="onsubmit">
          <b-form-group
            class="estage-form-group"
            label="Enter Block Name"
          >
            <b-input
              v-model="name"
              v-validate="'required'"
              name="name"
              :state="errors.has('name') ? false : null"
            />
          </b-form-group>

          <div class="flex gap-[20px]">
            <b-form-group>
              <b-checkbox
                v-model="globally"
                :value="user.id"
                :unchecked-value="0"
              >
                <div class="ml-1 mt-[-7px]">
                  Add globally
                </div>
              </b-checkbox>
            </b-form-group>

            <b-form-group v-if="favorite.origSection">
              <b-checkbox
                v-model="sharable"
                :value="1"
                :disabled="!globally"
                :unchecked-value="0"
              >
                <div class="ml-1 mt-[-7px]">
                  ESTAGE Internal Embed
                </div>
              </b-checkbox>
            </b-form-group>
          </div>

          <div class="text-center mt-5">
            <b-btn
              type="submit"
              variant="primary"
              class="font-weight-bold font-size-14 px-5"
            >
              {{ isSharable ? 'Update': 'Add' }}
            </b-btn>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'AddToFavoriteModal',
  props: {
    favorite: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      modal: false,
      globally: 0,
      sharable: 0
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    isSharable () {
      return this.favorite.origSection ? this.favorite.origSection.isSharable : false
    },
    name: {
      get () {
        if (!this.favorite.section) return ''
        return this.favorite.section.name
      },
      set (val) {
        this.$set(this.favorite.section, 'name', val)
      }
    }
  },
  watch: {
    favorite (val) {
      if (Object.keys(val).length) {
        if (this.favorite.origSection) {
          this.globally = this.favorite.origSection.isGlobal ? 3 : 0
          this.sharable = this.favorite.origSection.isSharable ? 1 : 0
        }
        this.modal = true
      }
    }
  },
  methods: {
    onsubmit () {
      this.modal = false
      this.$emit('submit', {
        globally: this.globally,
        favorite: this.favorite.section,
        origSection: this.favorite.origSection,
        sharable: this.sharable,
        node: this.favorite.node
      })
    }
  }
}
</script>