<template>
  <div>
    <div
      class="image-background"
      :style="{...backgroundImage}"
    />
    <div
      class="list-1"
      :class="container"
      :style="{...backgroundColor, ...shadow, ...padding}"
    >
      <ul
        class="list-unstyled"
        :style="{...color}"
      >
        <slot name="list" />
      </ul>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'ListItem1Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.list-1 {
  ul {
    li {
      position: relative;
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}
</style>
