<template>
  <div class="relative">
    <div
      v-if="!!project.cf_enterprise_verified"
      class="relative flex justify-center w-full pt-[100px]"
    >
      <img
        class="absolute right-0 top-0"
        src="https://www.dropbox.com/s/gfjggxr2ou6i9s5/image%2032.png?raw=1"
        alt="Float image"
      >

      <div
        class="max-w-[1253px] w-full z-10 mb-[170px]"
      >
        <div class="border rounded-[7px] overflow-hidden shadow-[0px_1px_6px_rgba(0,0,0,.07)] bg-white">
          <template>
            <div>
              <cloudflare-enterprise-header />
              <cloudflare-enterprise-activated-hero />
              <cloudflare-enterprise-what-get-section :button="false" />

              <div class="flex justify-center mb-5">
                <button
                  class="flex items-center hover:opacity-80 justify-center rounded-[3px] !text-[17px] text-white h-[55px] bg-[#E93030] px-[40px]"
                  @click="deactivate"
                >
                  Deactivate Cloudflare Enterprise For This Project <b-spinner
                    v-if="loading"
                    variant="white"
                    small
                    class="ml-2"
                  />
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      v-else
      class="relative flex justify-center w-full pt-[100px]"
    >
      <img
        class="absolute right-0 top-0"
        src="https://www.dropbox.com/s/gfjggxr2ou6i9s5/image%2032.png?raw=1"
        alt="Float image"
      >
      <div
        class="max-w-[1253px] w-full z-10 mb-[170px]"
      >
        <div class="border rounded-[7px] overflow-hidden shadow-[0px_1px_6px_rgba(0,0,0,.07)] bg-white">
          <cloudflare-enterprise-header />
          <cloudflare-enterprise-purchased-hero />
          <cloudflare-enterprise-brands />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloudflareEnterpriseHeader from '@/views/cloudflare/components/CloudflareEnterpriseHeader'
import CloudflareEnterpriseWhatGetSection from '@/views/cloudflare/components/CloudflareEnterpriseWhatGetSection'
import CloudflareEnterpriseActivatedHero from '@/views/cloudflare/components/CloudflareEnterpriseActivatedHero'
import {mapState} from 'vuex'
import CloudflareEnterprisePurchasedHero from '@/views/cloudflare/components/CloudflareEnterprisePurchasedHero.vue'
import CloudflareEnterpriseBrands from '@/views/cloudflare/components/CloudflareEnterpriseBrands.vue'
// import CloudflareEnterprisePurchasedHero from '@/views/cloudflare/components/CloudflareEnterprisePurchasedHero.vue'
// import CloudflareEnterpriseBrands from '@/views/cloudflare/components/CloudflareEnterpriseBrands.vue'

export default {
  name: 'CloudflareEnterprise',
  components: {
    CloudflareEnterpriseBrands,
    CloudflareEnterprisePurchasedHero,
    // CloudflareEnterpriseBrands,
    // CloudflareEnterprisePurchasedHero,
    CloudflareEnterpriseActivatedHero,
    CloudflareEnterpriseWhatGetSection, CloudflareEnterpriseHeader
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('projects', {
      project: state => state.project
    })
  },
  methods: {
    deactivate () {
      this.loading = true
      axios.delete(`api/projects/${this.project.id}/cf-enterprise`)
        .then(() => {
          this.project.cf_enterprise_verified = 0
        })
        .finally(() => {
          window.scrollTo({top: 0, behavior: 'smooth'})
          this.loading = false
        })
    },
    submit () {
      this.loading = true
      axios.get(`api/projects/${this.project.id}/cf-enterprise`)
        .then(() => {
          this.project.cf_enterprise_verified = 1
          this.user.cf_enterprise_date_activation = 1
        })
        .catch(error => {
          this.$swal({
            icon: 'warning',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
          })
        })
        .finally(() => {
          window.scrollTo({top: 0, behavior: 'smooth'})
          this.loading = false
        })
    }
  }
}
</script>