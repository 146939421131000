<template>
  <div>
    <b-form-group>
      <div class="d-flex align-items-center justify-content-between">
        <div>Enabled</div>
        <b-checkbox
          v-model="config.gdpr.enabled"
          switch
          value="1"
        />
      </div>
    </b-form-group>

    <b-form-group label="Text">
      <b-textarea
        v-model="config.gdpr.text"
        rows="4"
      />
    </b-form-group>

    <!--    <b-form-group label="Checkbox Color">-->
    <!--      <sid-color-->
    <!--        :sid="`.&#45;&#45;${section.sid}&#45;&#45;form .custom-control-input:checked ~ .custom-control-label::before`"-->
    <!--        rule-name="background-color"-->
    <!--      />-->
    <!--    </b-form-group>-->

    <b-form-group label="Text Color">
      <sid-color
        :sid="`.--${section.sid}--form .custom-control-label .custom-control-legend`"
        rule-name="color"
      />
    </b-form-group>

    <sid-range-slider
      :sid="`.--${section.sid}--form .custom-control-label .custom-control-legend`"
      rule-name="font-size"
      label="Font Size"
    />

    <b-form-group>
      <sid-font :sid="`.--${section.sid}--form .custom-control-label`" />
    </b-form-group>

    <sid-align
      :sid="`.--${section.sid}--form .custom-checkbox`"
      rule-name="justify-content"
      label="Text Alignment"
    />

    <!--    <b-form-group label="Color">-->
    <!--      <text-color-->
    <!--        v-model="config.gdpr.color"-->
    <!--        :color="config.gdpr.color"-->
    <!--      />-->
    <!--    </b-form-group>-->

    <!--    <b-form-group :label="`Aligment (${resolution})`">-->
    <!--      <b-form-radio-group-->
    <!--        v-model="config.gdpr.aligment[resolution]"-->
    <!--        button-variant="white"-->
    <!--        buttons-->
    <!--      >-->
    <!--        <template-->
    <!--          v-for="option in aligmentOptions"-->
    <!--          v-key="option.value"-->
    <!--        >-->
    <!--          <b-form-radio-->
    <!--            :key="option.value"-->
    <!--            :value="option.value"-->
    <!--          >-->
    <!--            <i-->
    <!--              :class="[option.icon, {'text-primary': config.gdpr.aligment[resolution] === option.value}]"-->
    <!--              class="pt-[4px]"-->
    <!--            />-->
    <!--          </b-form-radio>-->
    <!--        </template>-->
    <!--      </b-form-radio-group>-->
    <!--    </b-form-group>-->

    <!--    <b-form-group class="mb-0">-->
    <!--      <b-checkbox v-model="gdprAlign">-->
    <!--        After Button-->
    <!--      </b-checkbox>-->
    <!--    </b-form-group>-->
  </div>
</template>

<script>
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont'
export default {
  name: 'FormGDPR',
  components: {SidFont, SidRangeSlider, SidColor, SidAlign},
  props: {
    config: {
      type: Object,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  }
}
</script>
