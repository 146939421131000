<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element !z-[1001]"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('video')"
  >
    <div class="p-1 -m-1">
      <Template :section="section">
        <video-play
          :class="{'element-hidden': !visability('video')}"
          :customs="section.options.content.video"
        />
      </Template>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'

export default {
  name: 'FeaturedItems1',

  components: {Template, Highliter},

  mixins: [SectionMixin],

  created () {
    if (this.section.options.customize.style.background) {
      this.$set(this.section.options.customize, 'style', {
        shadow: null,
        background: null,
        'opacity': {
          'type': 'opacity-customs',
          'opacity': 1
        },
        padding: {
          type: 'height-customs',
          sm: {
            top: 40,
            bottom: 40
          },
          lg: {
            top: 40,
            bottom: 40
          }
        }
      })
    }
  }
}
</script>
