import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Post Breadcrumbs'
export const group = 'Dynamic Post Template'
export const beta = false

export const defaultOptions = {
  content: {
    data: {
      type: 'post-breadcrumbs-mod'
    }
  }
}

export const icon = ''

export default class ElPostBreadcrumbs extends SectionProvider {
  constructor (options = {}) {
    super('ElPostBreadcrumbs', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
