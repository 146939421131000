import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Field Input'
export const group = ''

export default class ElFieldInput extends SectionProvider {
  constructor (options = {}) {
    super('ElFieldInput', options)

    this.name = name
    this.group = group
  }
}