<template>
  <div class="mr-3">
    <b-dropdown
      variant="dashboard-dropdown"
      menu-class="project-menu py-0"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        Folder Options

        <svg
          class="ml-8"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.64645 4.14645C1.84171 3.95118 2.15829 3.95118 2.35355 4.14645L6 7.79289L9.64645 4.14645C9.84171 3.95118 10.1583 3.95118 10.3536 4.14645C10.5488 4.34171 10.5488 4.65829 10.3536 4.85355L6.35355 8.85355C6.15829 9.04882 5.84171 9.04882 5.64645 8.85355L1.64645 4.85355C1.45118 4.65829 1.45118 4.34171 1.64645 4.14645Z"
            fill="#4F83E3"
          />
        </svg>
      </template>

      <move-folder-modal :folder="folder" />
      <edit-folder-modal :folder="folder" />
      <delete-folder-modal :folder="folder" />
    </b-dropdown>
  </div>
</template>

<script>
import EditFolderModal from '../templates/EditFolderModal'
import DeleteFolderModal from '../templates/DeleteFolderModal'
import {mapState} from 'vuex'
import MoveFolderModal from '../templates/MoveFolderModal'
export default {
  name: 'DashboardFolderOptionsMenu',
  components: {MoveFolderModal, DeleteFolderModal, EditFolderModal},
  computed: {
    ...mapState('dashboard', {
      folder: state => state.folder
    })
  }
}
</script>

<style lang="scss">
@import "@builder/assets/scss/_variables.scss";

.btn-dashboard-dropdown {
  height: 36px;
  background: #FFFFFF !important;
  border: 1px solid #E2E5EC !important;
  font-size: 15px !important;
  color: $primary !important;

  path {
    fill: $primary !important;
  }
}

.show .btn-dashboard-dropdown {
  svg {
    transform: rotate(180deg);
  }
}
</style>