var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-3"},[_c('SidbearSettingsGroup',{attrs:{"title":"Lottie","active":""}},[(_vm.linked)?_c('b-form-group',[_c('span',{staticClass:"inline cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E] gap-[10px]",on:{"click":function () { return _vm.unlinkSid(); }}},[_c('i',{staticClass:"fa fa-unlink mr-2"}),_vm._v(" Unlink styles ")])]):_vm._e(),_c('upload-asset',{attrs:{"title":"Lottie JSON"},on:{"uploaded":_vm.uploadedLottie}}),_c('b-form-group',{attrs:{"label":"JSON URL"}},[_c('div',{staticClass:"flex items-center gap-[10px]"},[_c('b-input',{model:{value:(_vm.config.jsonPath),callback:function ($$v) {_vm.$set(_vm.config, "jsonPath", $$v)},expression:"config.jsonPath"}}),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-trash cursor-pointer hover:opacity-60",attrs:{"title":"Delete"},on:{"click":function () { return _vm.config.jsonPath = ''; }}})],1)]),_c('sid-range-slider',{attrs:{"label":"Size","rule-name":"width","sid":(".--lottie-" + (_vm.section.sid)),"dimensions":[],"default-dimension":"%","default-value":'100',"reset-value":"100","var":"--width"}}),_c('sid-width-and-height',{attrs:{"sid":(".--lottie-" + (_vm.section.sid))}}),_c('sid-align',{attrs:{"label":"Alignment","sid":(".--lottie-container-" + (_vm.section.sid)),"rule-name":"justify-content"}}),_c('b-form-group',[_c('b-checkbox',{model:{value:(_vm.config.loop),callback:function ($$v) {_vm.$set(_vm.config, "loop", $$v)},expression:"config.loop"}},[_vm._v(" Loop ")])],1),_c('b-form-group',[_c('b-checkbox',{model:{value:(_vm.config.autoplay),callback:function ($$v) {_vm.$set(_vm.config, "autoplay", $$v)},expression:"config.autoplay"}},[_vm._v(" Autoplay ")])],1)],1),_c('SidbearSettingsGroup',{attrs:{"title":"Padding & Margins"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":(".--lottie-" + (_vm.section.sid)),"rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":(".--lottie-" + (_vm.section.sid)),"rule-name":"margin","min":-2000,"max":2000,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }