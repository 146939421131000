<template>
  <div class="position-relative">
    <!-- Blog Hero -->
    <hero />
    <!-- End Blog Hero -->

    <b-container v-if="!sidebar">
      <b-row class="align-items-center">
        <b-col
          class="mb-4 mb-lg-0"
          lg="8"
        >
          <!-- Top Categories -->
          <top-categories />
          <!-- End Top Categories -->
        </b-col>

        <b-col
          sm="12"
          lg="4"
        >
          <!-- Search -->
          <search />
          <!-- End Search -->
        </b-col>
      </b-row>
    </b-container>


    <div class="posts-container">
      <b-container>
        <b-row>
          <b-col
            order="2"
            order-md="1"
            :md="sidebar ? 8 : 12"
          >
            <!-- Blog Posts -->
            <posts-container :sidebar="sidebar" />
            <!-- End Blog Posts -->

            <!-- Pagination -->
            <pagination />
            <!-- End Pagination -->
          </b-col>
          <b-col
            v-if="sidebar"
            order="1"
            order-md="2"
            md="4"
          >
            <sidebar-wrapper />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Hero from './components/Hero'
import TopCategories from './components/TopCategories'
import Search from './components/Search'
import PostsContainer from './components/PostsContainer'
import Pagination from './components/Pagination'
import {mapState} from 'vuex'
import SidebarWrapper from './components/SidebarWrapper'

export default {
  name: 'GridThreeWrapper',
  components: {SidebarWrapper, Pagination, PostsContainer, Search, TopCategories, Hero},
  props: {
    sidebar: {
      type: [Number, Boolean]
    }
  },
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    })
  },
  mounted () {
    const css = `
       .top-categories .category:hover {color: ${this.globalStyles.colors.primary};}
       .post-title:hover {color: ${this.globalStyles.colors.primary};}
       .top-categories .category.active {color: ${this.globalStyles.colors.primary};}
       .color-primary {color: ${this.globalStyles.colors.primary} !important;}
       .top-categories .category.active:before,
        .page-item.active .page-link {
        background: ${this.globalStyles.colors.primary} !important;
      }
    `,
      style = document.createElement('style')

    this.$el.appendChild(style)

    style.type = 'text/css'
    style.appendChild(document.createTextNode(css))
    this.$el.append()
  }
}
</script>

<style lang="scss" scoped>
.posts-container {
  background: #F9F9F9;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.18);
}
</style>

