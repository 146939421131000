<template>
  <div class="position-relative">
    <div :style="{...padding}">
      <div
        class="image-background"
        :style="{...backgroundImage}"
      />
      <div
        v-if="visability('collapse')"
        class="accordion"
        :class="container"
        role="tablist"
      >
        <b-card
          v-for="(collapse, index) in section.options.content.collapse.items"
          :key="collapse.uid"
          no-body
          class="rounded-0"
        >
          <b-card-header
            header-tag="header"
            class="d-flex justify-content-between align-items-center rounded-0 py-3"
            role="tab"
            :style="{...backgroundColor, ...color}"
            @click="active = index"
          >
            <slot
              name="header"
              :content="collapse"
            />
            <i
              v-if="active === index"
              class="fa fa-minus"
            />
            <i
              v-else
              class="fa fa-plus"
            />
          </b-card-header>
          <b-collapse
            :visible="active === index"
            role="tabpanel"
          >
            <b-card-body :style="{color: section.options.customize.style.bodyColor.color}">
              <slot
                name="body"
                :content="collapse"
              />
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'HeroItem1Template',
  mixins: [TemplateMixin],

  data () {
    return {
      active: 0
    }
  }
}
</script>
