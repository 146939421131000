<template>
  <div>
    <h2 class="page-title mb-14">
      Shipping Info
    </h2>

    <b-row>
      <b-col md="7">
        <b-form-group
          label="Address"
          class="mb-4"
        >
          <b-input v-model="form.address" />
        </b-form-group>
      </b-col>

      <b-col md="8">
        <b-row>
          <b-col md="5">
            <b-form-group
              label="Country"
              class="mb-4"
            >
              <country-select v-model="form.country_id" />
            </b-form-group>
          </b-col>

          <b-col md="5">
            <b-form-group
              label="City"
              class="mb-4"
            >
              <b-input v-model="form.city" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="6">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="State"
              class="mb-4"
            >
              <state-select
                v-model="form.state_id"
                :country="form.country_id"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Zip Code"
              class="mb-4"
            >
              <b-input v-model="form.zip_code" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" />

      <b-col md="2">
        <b-form-group
          label="T-Shirt Size"
          class="my-4"
        >
          <div class="mb-2">
            (For promotional purposes)
          </div>
          <v-select
            v-model="form.t_shirt_size"
            :searchable="false"
            :options="options"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CountrySelect from './components/CountrySelect'
import StateSelect from './components/StateSelect'
export default {
  name: 'ShippingInfo',
  components: {StateSelect, CountrySelect},
  inject: ['$validator'],
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      options: ['Women XL', 'Women L', 'Women S', 'Women XS', 'Men XL', 'Men L', 'Men S', 'Men XS']
    }
  }
}
</script>