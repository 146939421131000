<template>
  <highliter
    v-if="section.options.customize.display"
    v-model="section.name"
    :can-delete="false"
    :class="['navbar-' + resolution, {'position-absolute w-100': bringToFront}]"
    class="navbar-1 highlight-section"
    hide-copy
    hide-favorite
    :dublicate="section"
    hide-move
    label="Navigation"
    uid="#navigation"
    @delete="$emit('delete')"
    @flow="handleFlow"
    @move-to="(to) => $emit('move-to', to)"
    @click.stop.native="sectionSettings('menuItems')"
    @add-subject="$emit('add-subject')"
  >
    <div class="flex justify-center position-relative z-10">
      <div :style="{...menuWidth}">
        <div
          :style="{ ...backgroundColor, ...fullMarginSubject(section.options.content.menuItems), ...fullPaddingSubject(section.options.content.menuItems), ...shadowSubject(section.options.content.menuItems), ...borderSubject(section.options.content.menuItems, [0,0,0,0]), ...radiusSubject(section.options.content.menuItems, [0,0,0,0])}"
        >
          <b-navbar
            v-model="collapsed"
            :toggleable="true"
            :class="{'px-0': fullWidthMenu}"
          >
            <b-container :fluid="fullWidthMenu">
              <b-navbar-brand>
                <highliter
                  :class="{'element-hidden': !visability('logo')}"
                  :disable-toolbar="true"
                  class="w-auto"
                  @delete="$emit('delete')"
                  @dublicate="$emit('dublicate')"
                  @favorite="$emit('favorite', $el)"
                  @flow="handleFlow"
                  @move-to="(to) => $emit('move-to', to)"
                  @click.stop.native="sectionSettings('logo')"
                >
                  <es-logo :customs="section.options.content.logo" />
                </highliter>
              </b-navbar-brand>

              <b-navbar-nav
                v-if="showButton"
                class="ml-auto mr-4"
              >
                <highliter
                  :class="{ 'element-hidden': !visability('button') }"
                  :disable-toolbar="true"
                  auto-width
                  @delete="$emit('delete')"
                  @dublicate="$emit('dublicate')"
                  @favorite="$emit('favorite', $el)"
                  @flow="handleFlow"
                  @move-to="(to) => $emit('move-to', to)"
                  @click.stop.native="sectionSettings('button')"
                >
                  <es-button :customs="section.options.content.button" />
                </highliter>
              </b-navbar-nav>

              <b-navbar-toggle
                class="pr-0 w-[20px]"
                target="nav-collapse"
              >
                <template #default="{ expanded }">
                  <i
                    v-if="expanded"
                    :class="iconClose"
                    :style="{color: iconColor}"
                  />
                  <i
                    v-else
                    :class="iconOpen"
                    :style="{color: iconCloseColor}"
                  />
                </template>
              </b-navbar-toggle>

              <b-collapse
                id="nav-collapse"
                :class="{'opacity-0': !initialized}"
                is-nav
              >
                <div class="links">
                  <es-menu-items
                    :customs="section.options.content.menuItems"
                  />
                </div>
              </b-collapse>
            </b-container>
          </b-navbar>
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import EsMenuItems from '@builder/components/builder/utils/EsMenuItems'
import _ from 'lodash'
import NavbarScroller from '../../../../mixins/NavbarScroller'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'Navbar1',

  components: {EsMenuItems, Highliter},

  mixins: [SectionMixin, NavbarScroller, BorderMixin],

  data () {
    return {
      collapsed: false
    }
  },

  computed: {
    bringToFront () {
      return _.get(this.section.options.content.menuItems, ['bringToFront', this.resolutioner], false)
    },
    menuWidth () {
      const menuWidth = _.get(this.section.options.content.menuItems, ['menuWidth', this.resolutioner], {
        value: 100,
        type: '%'
      })

      if (menuWidth.type === 'px') {
        return {
          maxWidth: `${menuWidth.value}${menuWidth.type}`,
          width: '100%'
        }
      }

      return {
        width: `${menuWidth.value}${menuWidth.type}`
      }
    },
    fullWidthMenu () {
      return _.get(this.section.options.content.menuItems, ['fullWidthMenu', this.resolutioner], false)
    },
    showButton () {
      return _.get(this.section.options.content.menuItems, 'showButton', true)
    },
    iconColor () {
      return _.get(this.section.options.content.menuItems, 'hamburgerIcon_color', '#656565')
    },
    iconCloseColor () {
      return _.get(this.section.options.content.menuItems, 'hamburgerIcon_close_color', this.iconColor)
    },
    iconOpen () {
      return _.get(
        this.section.options.content.menuItems,
        'hamburgerIcon_open',
        'fa fa-bars'
      )
    },
    iconClose () {
      return _.get(
        this.section.options.content.menuItems,
        'hamburgerIcon_close',
        'fa fa-times'
      )
    }
  },


  created () {
    if (!this.section.options.content.menuItems.hasOwnProperty('align')) {
      this.$set(this.section.options.content.menuItems, 'align', '')
    }
  }
}
</script>

<style lang="scss">
.resolution-lg {
  .navbar-1 {
    .links {
      width: 100%;
    }

    .nav-item {
      margin-right: 20px !important;
    }
  }
}

.resolution-sm {
  .navbar-1 {
    .btn {
      display: block;
      width: 100%;
    }
  }
}
</style>
