const DEFAULT_CONNECT_TIMEOUT = 2000

const WHEPType = {
  Client: 'Client',
  Server: 'Server'
}

class WHEPAdapter {
  constructor (peer, channelUrl, onError, mediaConstraints, authKey) {
    this.localPeer = undefined
    this.channelUrl = channelUrl
    if (typeof this.channelUrl === 'string') {
      throw new Error('channelUrl parameter expected to be a URL, not a string')
    }
    this.whepType = WHEPType.Client
    this.authKey = authKey
    this.debug = false
    this.waitingForCandidates = false
    this.iceGatheringTimeout = undefined
    this.resource = null
    this.onErrorHandler = onError
    this.audio = !mediaConstraints.videoOnly
    this.video = !mediaConstraints.audioOnly
    this.mediaConstraints = mediaConstraints

    this.resetPeer(peer)
  }

  enableDebug () {
    this.debug = true
  }

  resetPeer (newPeer) {
    this.localPeer = newPeer
    this.localPeer.onicegatheringstatechange = this.onIceGatheringStateChange.bind(this)
    this.localPeer.onicecandidate = this.onIceCandidate.bind(this)
  }

  getPeer () {
    return this.localPeer
  }

  async connect (opts) {
    this._opts = opts
    try {
      await this.initSdpExchange()
    } catch (error) {
      console.error(error.toString())
      this.onErrorHandler('connecterror')
    }
  }

  async disconnect () {
    if (this.resource) {
      this.log(`Disconnecting by removing resource ${this.resource}`)
      const headers = {}
      if (this.authKey) {
        headers['Authorization'] = this.authKey
      }
      const response = await fetch(this.resource, {
        method: 'DELETE',
        headers
      })
      if (response.ok) {
        this.log('Successfully removed resource')
      }
    }
  }

  async initSdpExchange () {
    clearTimeout(this.iceGatheringTimeout)

    if (this.localPeer && this.whepType === WHEPType.Client) {
      if (this.video) {
        this.localPeer.addTransceiver('video', { direction: 'recvonly' })
      }
      if (this.audio) {
        this.localPeer.addTransceiver('audio', { direction: 'recvonly' })
      }
      const offer = await this.localPeer.createOffer()

      // To add NACK in offer we have to add it manually
      if (offer.sdp) {
        const opusCodecId = offer.sdp.match(/a=rtpmap:(\d+) opus\/48000\/2/)

        if (opusCodecId !== null) {
          offer.sdp = offer.sdp.replace(
            'opus/48000/2\r\n',
            'opus/48000/2\r\na=rtcp-fb:' + opusCodecId[1] + ' nack\r\n'
          )
        }
      }

      await this.localPeer.setLocalDescription(offer)
      this.waitingForCandidates = true
      this.iceGatheringTimeout = setTimeout(
        this.onIceGatheringTimeout.bind(this),
        DEFAULT_CONNECT_TIMEOUT
      )
    } else {
      if (this.localPeer) {
        const offer = await this.requestOffer()
        await this.localPeer.setRemoteDescription({
          type: 'offer',
          sdp: offer
        })
        const answer = await this.localPeer.createAnswer()
        try {
          await this.localPeer.setLocalDescription(answer)
          this.waitingForCandidates = true
          this.iceGatheringTimeout = setTimeout(
            this.onIceGatheringTimeout.bind(this),
            DEFAULT_CONNECT_TIMEOUT
          )
        } catch (error) {
          this.log(answer.sdp)
          throw error
        }
      }
    }
  }

  async onIceCandidate (event) {
    if (event.type !== 'icecandidate') {
      return
    }
    const candidate = event.candidate
    if (!candidate) {
      return
    }

    this.log(candidate.candidate)
  }

  onIceGatheringStateChange () {
    if (this.localPeer) {
      this.log('IceGatheringState', this.localPeer.iceGatheringState)
      if (
        this.localPeer.iceGatheringState !== 'complete' ||
        !this.waitingForCandidates
      ) {
        return
      }

      this.onDoneWaitingForCandidates()
    }
  }

  onIceGatheringTimeout () {
    this.log('IceGatheringTimeout')

    if (!this.waitingForCandidates) {
      return
    }

    this.onDoneWaitingForCandidates()
  }

  async onDoneWaitingForCandidates () {
    this.waitingForCandidates = false
    clearTimeout(this.iceGatheringTimeout)

    if (this.whepType === WHEPType.Client) {
      await this.sendOffer()
    } else {
      await this.sendAnswer()
    }
  }

  getResourceUrlFromHeaders (headers) {
    const location = headers.get('Location')
    if (location && location.match(/^\//)) {
      const resourceUrl = new URL(location, this.channelUrl.origin)
      return resourceUrl.toString()
    } else {
      return location
    }
  }

  async requestOffer () {
    if (this.whepType === WHEPType.Server) {
      this.log(`Requesting offer from: ${this.channelUrl}`)
      const headers = {
        'Content-Type': 'application/sdp'
      }
      if (this.authKey) {
        headers['Authorization'] = this.authKey
      }

      const response = await fetch(this.channelUrl.toString(), {
        method: 'POST',
        headers,
        body: ''
      })
      if (response.ok) {
        this.resource = this.getResourceUrlFromHeaders(response.headers)
        this.log('WHEP Resource', this.resource)
        const offer = await response.text()
        this.log('Received offer', offer)
        return offer
      } else {
        const serverMessage = await response.text()
        throw new Error(serverMessage)
      }
    }
  }

  async sendAnswer () {
    if (!this.localPeer) {
      this.log('Local RTC peer not initialized')
      return
    }

    if (this.whepType === WHEPType.Server && this.resource) {
      const answer = this.localPeer.localDescription
      if (answer) {
        const headers = {
          'Content-Type': 'application/sdp'
        }
        if (this.authKey) {
          headers['Authorization'] = this.authKey
        }
        const response = await fetch(this.resource, {
          method: 'PATCH',
          headers,
          body: answer.sdp
        })
        if (!response.ok) {
          this.error(`sendAnswer response: ${response.status}`)
        }
      }
    }
  }

  async sendOffer () {
    if (!this.localPeer) {
      this.log('Local RTC peer not initialized')
      return
    }

    const offer = this.localPeer.localDescription

    if (this.whepType === WHEPType.Client && offer) {
      this.log(`Sending offer to ${this.channelUrl}`)
      const headers = {
        'Content-Type': 'application/sdp'
      }
      if (this.authKey) {
        headers['Authorization'] = this.authKey
      }
      const response = await fetch(this.channelUrl.toString(), {
        method: 'POST',
        headers,
        body: offer.sdp
      })

      if (response.ok) {
        this.resource = this.getResourceUrlFromHeaders(response.headers)
        this.log('WHEP Resource', this.resource)
        const answer = await response.text()
        await this.localPeer.setRemoteDescription({
          type: 'answer',
          sdp: answer
        })
      } else if (response.status === 400) {
        this.log('Server does not support client-offer, need to reconnect')
        this.whepType = WHEPType.Server
        this.onErrorHandler('reconnectneeded')
      } else if (
        response.status === 406 &&
        this.audio &&
        !this.mediaConstraints.audioOnly &&
        !this.mediaConstraints.videoOnly
      ) {
        this.log('Maybe server does not support audio. Let\'s retry without audio')
        this.audio = false
        this.video = true
        this.onErrorHandler('reconnectneeded')
      } else if (
        response.status === 406 &&
        this.video &&
        !this.mediaConstraints.audioOnly &&
        !this.mediaConstraints.videoOnly
      ) {
        this.log('Maybe server does not support video. Let\'s retry without video')
        this.audio = true
        this.video = false
        this.onErrorHandler('reconnectneeded')
      } else {
        this.error(`sendAnswer response: ${response.status}`)
        this.onErrorHandler('connectionfailed')
      }
    }
  }

  log (...args) {
    if (this.debug) {
      console.log('WebRTC-player', ...args)
    }
  }

  error (...args) {
    console.error('WebRTC-player', ...args)
  }
}

export { WHEPAdapter, WHEPType }
