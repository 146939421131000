<template>
  <b-modal
    id="add-social-account"
    v-model="modal"
    size="lg"
    body-class="editor editor-toolbar"
    hide-footer
    hide-header
    centered
  >
    <icon
      class="cursor-pinter ml-3"
      icon="close.svg"
      @click.native="modal = false"
    />

    <h4 class="h2 font-weight-bold text-center my-4">
      Add Social Account
    </h4>

    <b-form @submit.prevent="addListItem">
      <div class="px-5 py-4">
        <b-form-group
          class="mb-4"
          label="Select Social Channel"
        >
          <v-select
            v-model="item.icon"
            :options="options"
            placeholder="Select Icon"
            :reduce="social => social.key"
            required
            class="w-100 position-relative custom-select-lg"
          >
            <template v-slot:selected-option="option">
              <div class="d-flex align-items-center">
                <i
                  :class="option.key"
                  class="text-primary mr-2"
                />
                <div>{{ option.label }}</div>
              </div>
            </template>

            <template v-slot:option="option">
              <div class="d-flex align-items-center">
                <i
                  :class="option.key"
                  class="text-primary mr-3"
                />
                <div>{{ option.label }}</div>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <b-row>
          <b-col cols="3">
            <b-form-group
              class="mb-4"
              label="Background Color"
            >
              <color-picker
                :color="item.background"
                @input="event => item.background = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group
              class="mb-4"
              label="Color"
            >
              <color-picker
                :color="item.color"
                @input="event => item.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group
          class="mb-4"
          label="Shape"
        >
          <v-select
            v-model="item.shape"
            :options="shapes"
            :reduce="shape => shape.key"
            class="w-100 position-relative custom-select-lg"
            :searchable="false"
          />
        </b-form-group>

        <b-form-group
          class="mb-4"
          label="Link"
        >
          <b-input
            v-model="item.link"
            type="url"
            style="height: 51px;"
            size="lg"
            class="font-size-16"
          />
        </b-form-group>

        <div class="d-flex justify-content-center mt-5">
          <b-btn
            variant="primary"
            size="lg"
            class="font-size-16 font-weight-bold rounded-sm btn-custom-lg"
            type="submit"
          >
            Add New Social Account
          </b-btn>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
const draftItem = {
  shape: 'None',
  color: '#ffffff',
  background: '#1f3b9c',
  icon: 'fa fa-facebook',
  link: ''
}

export default {
  name: 'SocialLinksModal',
  data () {
    return {
      modal: false,
      shapes: [
        {
          key: null,
          label: 'None'
        },
        {
          key: 'circle',
          label: 'Circle'
        }
      ],
      options: [
        {
          key: 'fa fa-google',
          label: 'Google'
        },
        {
          key: 'fa fa-facebook',
          label: 'Facebook'
        },
        {
          key: 'fab fa-facebook',
          label: 'Facebook Circle'
        },
        {
          key: 'fab fa-whatsapp',
          label: 'Whatsapp'
        },
        {
          key: 'fab fa-skype',
          label: 'Skype'
        },
        {
          key: 'fas fa-sms',
          label: 'SMS'
        },
        {
          key: 'fa fa-twitter',
          label: 'Twitter'
        },
        {
          key: 'fa fa-telegram',
          label: 'Telegram'
        },
        {
          key: 'fa fa-vk',
          label: 'VKontakte'
        },
        {
          key: 'fa fa-youtube',
          label: 'YouTube'
        },
        {
          key: 'fa fa-instagram',
          label: 'Instagram'
        },
        {
          key: 'fab fa-linkedin-in',
          label: 'LinkedIn'
        },
        {
          key: 'fab fa-pinterest',
          label: 'Pinterest'
        },
        {
          key: 'fab fa-tiktok',
          label: 'TikTok'
        }
      ],
      item: {...draftItem}
    }
  },
  methods: {
    addListItem () {
      this.$emit('add-item', this.item)
      this.item = {...draftItem}
      this.modal = false
    }
  }
}
</script>
