<template />

<script>
import ControlMixin from '../../mixins/ControlMixin'

export default {
  name: 'Container',

  mixins: [ControlMixin]
}
</script>
