<template>
  <div class="post-thumb-container d-flex w-full">
    <highliter
      class="el-dynamic"
      section-type="element"
      value="FEATURED image (DYNAMIC)"
      hide-add
      hide-right
    >
      <div class="d-flex w-full">
        <img
          src="@builder/assets/svg/post-thumb.svg"
          alt="Post Thumbnail"
          class="post-thumb"
        >
      </div>
    </highliter>
  </div>
</template>

<script>
import Highliter from '@/components/builder/utils/Highliter.vue'

export default {
  name: 'PostThumb',
  components: {Highliter}
}
</script>

<style>
.post-thumb-container > div {
    margin-bottom: 20px;
    width: 100%;
    --width: 100%;
}

.post-thumb {
    object-fit: cover;
    width: 100%;
}
</style>