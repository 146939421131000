import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState('blog', {
      blog: state => state.blog,
      loading: state => state.loading,
      currentPage: state => state.page,
      perPage: state => state.perPage
    }),
    page: {
      get () {
        return this.currentPage
      },
      set (val) {
        this.$store.commit('blog/SET_PAGE', val)
        this.$store.dispatch('blog/getPosts')
      }
    }
  }
}
