import _ from 'lodash'
import {applyPageStyles} from '@/utils/useStylesheet'
import {deepUnset} from '@/utils/helpers'
import viewportUpdate from '@/mixins/viewportUpdate'

export default {

  mixins: [viewportUpdate],

  data () {
    return {
      subjectStyle: 'sty',
      undoArray: [],
      redoArray: [],
      undoRedoLastState: {
        content: null,
        css: null
      },
      undoRedoScrollTop: 0,
      loadingRedoUndo: false,
      undoRedoCheckInterval: null
    }
  },

  methods: {
    _undoRedoSetScrollBack () {
      const $el = document.querySelector('.scrollbale-container')
      if ($el) {

        $el.scrollTo({
          top: this.undoRedoScrollTop
        })
      }
    },

    resetUndoRedo () {
      clearInterval(this.undoRedoCheckInterval)

      this.undoArray = []
      this.redoArray = []
      this.undoRedoLastState = {
        content: null,
        css: null
      }

      setTimeout(() => this.initUndoRedoSupport())
    },

    stopUndoRedo () {
      clearInterval(this.undoRedoCheckInterval)

      this.undoArray = []
      this.redoArray = []
      this.undoRedoLastState = {
        content: null,
        css: null
      }
    },

    undo () {
      this.loadingRedoUndo = true

      new Promise((resolve) => {
        this.addRedo()
        applyPageStyles(this.getLastUndoStepData().css, this.subjectStyle)
        this.getTargetContent = this.getLastUndoStepData().content
        this.updateLastState()
        this.undoArray.splice(this.undoArray.length - 1, 1)
        this.$store.commit('editor/SET_PAGE', 'sections')

        setTimeout(resolve)
      }).finally(() => {
        this.loadingRedoUndo = false
        this.updateViewport()
        setTimeout(() => {
          this._undoRedoSetScrollBack()
        }, 100)
      })
    },

    redo () {
      this.loadingRedoUndo = true

      new Promise((resolve) => {
        this.addUndo()
        applyPageStyles(this.getLastRedoStepData().css, this.subjectStyle)
        this.getTargetContent = this.getLastRedoStepData().content
        this.updateLastState()
        this.redoArray.splice(this.redoArray.length - 1, 1)
        this.$store.commit('editor/SET_PAGE', 'sections')

        setTimeout(resolve)
      }).finally(() => {
        this.loadingRedoUndo = false
        this.updateViewport()
        setTimeout(() => {
          this._undoRedoSetScrollBack()
        }, 100)
      })
    },

    getLastUndoStepData () {
      return _.last(this.undoArray)
    },

    getLastRedoStepData () {
      return _.last(this.redoArray)
    },

    addUndo (data = {}) {
      if (this.undoArray.length > 29) {
        this.undoArray.splice(0, 1)
      }

      this.undoArray.push({
        content: data.content || _.cloneDeep(this.getTargetContent),
        css: data.css || this.getTargetCSS()
      })
    },

    addRedo (data = {}) {
      if (this.redoArray.length > 29) {
        this.redoArray.splice(0, 1)
      }

      this.redoArray.push({
        content: data.content || _.cloneDeep(this.getTargetContent),
        css: data.css || this.getTargetCSS()
      })
    },

    checkIsChanged () {
      if (!_.isEqual(deepUnset(_.cloneDeep(this.getTargetContent), 'uid'), deepUnset(_.cloneDeep(this.undoRedoLastState.content), 'uid')) || this.getTargetCSS() !== this.undoRedoLastState.css) {
        this.addUndo(_.cloneDeep(this.undoRedoLastState))
        this.updateLastState()
        this.redoArray = []
      }
    },

    updateLastState () {
      this.undoRedoLastState = {
        content: _.cloneDeep(this.getTargetContent),
        css: this.getTargetCSS()
      }
    },

    initUndoRedoSupport () {
      this.updateLastState()
      this.undoRedoCheckInterval = setInterval(this.checkIsChanged, 5000)
    },

    getTargetCSS () {},

    undoRedoSetScrolledOffset (e) {
      if (this.loadingRedoUndo) return
      this.undoRedoScrollTop = e.target.scrollTop
    }
  },

  beforeDestroy () {
    clearInterval(this.undoRedoCheckInterval)

    const $el = document.querySelector('.scrollbale-container')

    if ($el) {
      $el.removeEventListener('scroll', this.undoRedoSetScrolledOffset)
    }
  },

  computed: {
    getTargetContent () {},
    isActiveUndo () {
      return this.undoArray.length
    },
    isActiveRedo () {
      return this.redoArray.length
    }
  },

  mounted () {
    const $el = document.querySelector('.scrollbale-container')

    if ($el) {
      $el.addEventListener('scroll', this.undoRedoSetScrolledOffset)
    }
  }
}