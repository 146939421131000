<template>
  <highliter
    v-model="section.name"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings(true)"
  >
    <VideoItems1Template :section="section">
      <template slot="video">
        <highliter
          v-show="visability('video')"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('video')"
        >
          <!--          <b-embed v-if="section.options.content.video.src"-->
          <!--                   type="iframe"-->
          <!--                   aspect="16by9"-->
          <!--                   :src="section.options.content.video.src"-->
          <!--                   allowfullscreen-->
          <!--          ></b-embed>-->
          <!--          <b-img v-else class="w-100" :src="section.options.content.video.placeholder"></b-img>-->
          <video-play :customs="section.options.content.video" />
        </highliter>
      </template>

      <template slot="title">
        <highliter
          v-show="visability('title')"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('title')"
        >
          <content-editable
            v-model="section.options.content.title.text"
            type="paragraph"
            :uid="tiptapUID('title')"
            :customs="section.options.content.title"
          />
        </highliter>
      </template>


      <template slot="subtitle">
        <highliter
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('subtitle')"
        >
          <content-editable
            v-model="section.options.content.subtitle.text"
            type="paragraph"
            :uid="tiptapUID('subtitle')"
            :customs="section.options.content.subtitle"
          />
        </highliter>
      </template>
    </VideoItems1Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditable from '@builder/components/builder/utils/ContentEditable'
import VideoItems1Template from '@builder/templates/sections/videos/VideoItems1Template'

export default {
  name: 'FeaturedItems1',

  components: {VideoItems1Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  created () {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>
