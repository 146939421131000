import _ from 'lodash'

export default {
  computed: {
    scrollIsEnabled () {
      const enabled = _.get(this.section.options.customize, ['scroll', 'horizontalScroll', this.resolutioner], _.get(this.section.options.customize, ['scroll', 'horizontalScroll', 'lg'], false))
      return enabled ? 'overflow-x-auto' : ''
    },
    overflowIsEnabled () {
      const enabled = _.get(this.section.options.customize, ['scroll', 'overflowIsEnabled', this.resolutioner], _.get(this.section.options.customize, ['scroll', 'overflowIsEnabled', 'lg'], false))
      return enabled ? 'overflow-hidden' : ''
    },
    horizontalScrollSnapping () {
      const enabled = _.get(this.section.options.customize, ['scroll', 'horizontalScrollSnapping', this.resolutioner], _.get(this.section.options.customize, ['scroll', 'horizontalScrollSnapping', 'lg'], false))
      return enabled ? 'snap-x' : ''
    },
    mouseEnterParallax () {
      return _.get(this.col, ['mouseParallax', this.resolutioner], _.get(this.col, ['mouseParallax', 'lg'], false))
    },
    parallaxMouseDepth () {
      return _.get(this.col, ['parallaxMouseDepth', this.resolutioner], _.get(this.col, ['parallaxMouseDepth', 'lg'], 5))
    },
    parallaxMouseInertia () {
      return _.get(this.col, ['parallaxMouseInertia', this.resolutioner], _.get(this.col, ['parallaxMouseInertia', 'lg'], 200))
    }
  },
  methods: {
    mouseParallax (event) {
      if (this.$route.name !== 'preview' || this.$el.closest('section') !== event.target.closest('section') || !this.mouseEnterParallax) return

      if (this.absolute) {
        const shift = this.$el

        const x = (window.innerWidth - event.pageX * this.parallaxMouseDepth) / 90
        const y = (window.innerHeight - event.pageY * this.parallaxMouseDepth) / 90

        shift.style.transform = `translateX(${x}px) translateY(${y}px)`
        shift.style.transition = `${this.parallaxMouseInertia}ms ease-out`
      }
    }
  },
  mounted () {
    window.addEventListener('mousemove', this.mouseParallax)
  },
  beforeDestroy () {
    window.removeEventListener('mousemove', this.mouseParallax)
  }
}