<template>
  <div class="pt-[15px]">
    <b-form-group v-if="linked">
      <button
        class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
        @click="() => unlinkSid()"
      >
        Unlink Styles
        <i class="fa fa-link text-primary" />
      </button>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="config.options.content.gridCollapse.autoscroll">
        Auto scroll on open
      </b-checkbox>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="config.options.content.gridCollapse.activeShadow">
        Active shadow
      </b-checkbox>
    </b-form-group>

    <b-form-group :label="`Columns per row (${resolutioner})`">
      <v-select
        v-model="perRow"
        :options="perRowOptions"
        class="custom-select"
      />
    </b-form-group>

    <b-form-group>
      <div class="flex items-center gap-[13px]">
        <color-picker
          :color="config.options.content.gridCollapse.activeBorderColor"
          @input="event => config.options.content.gridCollapse.activeBorderColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />

        <label class="mb-0 pb-0">Active border color</label>
      </div>
    </b-form-group>

    <div class="flex flex-column gap-[12px] mb-[12px]">
      <div
        v-for="(item, index) in config.options.content.gridCollapse.items"
        :key="index"
        class="!border-[#E2E5EC] group rounded-[2px] h-[38px] flex items-center justify-between pl-[13px] hover:bg-[#F1F5FD] cursor-pinter"
        style="border:1px solid;"
        @click.stop="() => setActive(index)"
      >
        <span class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]">Item {{
          index + 1
        }}</span>

        <button
          v-b-tooltip
          class="w-[38px] h-[36px] text-center border-left bg-[#fff] p-0"
          title="Delete column"
          @click.stop="config.options.content.gridCollapse.items.splice(index, 1)"
        >
          <svg
            fill="none"
            height="10"
            viewBox="0 0 10 10"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
              fill="#6E747A"
            />
          </svg>
        </button>
      </div>
    </div>

    <button
      class="flex items-center border rounded-[3px] font-[600] text-[#44474A] text-[14px] h-[38px] px-[17px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
      @click="addCollapse"
    >
      <span class="text-[#3D82EA] text-[22px] mr-[9px]">+</span>
      Add New Item
    </button>

    <SidbearSettingsGroup
      class="border-top"
      title="Padding & Margins"
    >
      <padding-full-customs
        :config="padding"
        :default-value="[20,20,20,20]"
      />
      <margin-full-customs :config="margin" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ResolutionMixin from '../../mixins/ResolutionMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import shortid from 'shortid'
import SidStyles from '@/components/mixins/SidStyles'

export default {
  name: 'CollapseMod',
  components: {SidbearSettingsGroup},
  mixins: [ModMixin, ResolutionMixin, SidStyles],
  data () {
    return {
      perRowOptions: [1, 2, 3, 4, 5]
    }
  },
  computed: {
    perRow: {
      get () {
        return 12 / this.config.options.content.gridCollapse.cols[this.resolutioner]
      },
      set (val) {
        this.config.options.content.gridCollapse.cols[this.resolutioner] = 12 / val
      }
    },
    padding () {
      if (!this.config.options.content.gridCollapse.padding) {
        this.$set(this.config, 'padding', {})
      }

      return this.config.options.content.gridCollapse.padding
    },
    margin () {
      if (!this.config.options.content.gridCollapse.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.options.content.gridCollapse.margin
    }
  },
  methods: {
    setActive (index) {
      this.config.options.content.gridCollapse.active = index
      // this.sectionSettings('flex-mod', {
      //   section: this.config.options.content.gridCollapse.items[this.config.options.content.gridCollapse.active].subject
      // })
    },
    addCollapse () {
      this.config.options.content.gridCollapse.items.push({
        trigger: [],
        subject: {
          'uid': shortid.generate(),
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapper',
          'group': '',
          'layout': null,
          'options': {
            'customize': {
              'layouts': null,
              'style': {
                'background': {
                  'type': 'background-customs',
                  'background': 'rgba(255, 255, 255, 1)'
                },
                'shadow': {
                  'type': 'shadow-customs',
                  'shadow': null
                },
                'backgroundImage': {
                  'type': 'background-image-customs',
                  'src': null,
                  'position': 'center',
                  'size': 'cover',
                  'opacity': 1
                },
                'padding': {
                  'type': 'height-customs',
                  'lg': {
                    'top': '20',
                    'bottom': '20',
                    'left': '20',
                    'right': '20'
                  },
                  'md': {
                    'top': '20',
                    'bottom': '20',
                    'left': '20',
                    'right': '20'
                  },
                  'sm': {
                    'top': '10',
                    'bottom': '10',
                    'left': '20',
                    'right': '20'
                  }
                },
                'marginTop': {
                  'type': 'margin-top-customs',
                  'sm': 0,
                  'lg': 0
                },
                'border': [
                  0,
                  0,
                  0,
                  0
                ],
                'borderRadius': {
                  'type': 'border-radius-customs',
                  'radius': 0
                }
              }
            },
            'content': {
              'display': true,
              'fixedHeightV2': {
                'sm': {
                  'value': 50,
                  'type': 'vh'
                },
                'md': {
                  'value': 50,
                  'type': 'vh'
                },
                'lg': {
                  'value': 50,
                  'type': 'vh'
                }
              },
              'fixedHeightEnabled': {
                'sm': false,
                'md': false,
                'lg': false
              },
              'width': {
                'sm': 100,
                'md': 100,
                'lg': 100
              },
              'fixedWidthEnabled': {
                'sm': false,
                'md': false,
                'lg': false
              },
              'fixedWidth': {
                'sm': 280,
                'md': 920,
                'lg': 1200
              },
              'reverse': {
                'sm': false,
                'md': false,
                'lg': false
              },
              'wrap': {
                'sm': true,
                'md': true,
                'lg': true
              },
              'column': {
                'sm': false,
                'md': false,
                'lg': false
              },
              'gap': {
                'x': {
                  'sm': 0,
                  'md': 0,
                  'lg': 0
                },
                'y': {
                  'sm': 0,
                  'md': 0,
                  'lg': 0
                }
              },
              'verticalAlign': {
                'sm': 'start',
                'md': 'start',
                'lg': 'start'
              },
              'horizontalAlign': {
                'sm': 'start',
                'md': 'start',
                'lg': 'start'
              },
              'cols': [
                {
                  'display': true,
                  'margin': {},
                  'padding': {},
                  'name': 'Flex Column',
                  'background': {},
                  'hover': {
                    'background': null
                  },
                  'fixedHeightV2': {
                    'sm': {
                      'value': 50,
                      'type': 'vh'
                    },
                    'md': {
                      'value': 50,
                      'type': 'vh'
                    },
                    'lg': {
                      'value': 50,
                      'type': 'vh'
                    }
                  },
                  'fixedHeightEnabled': {
                    'sm': false,
                    'md': false,
                    'lg': false
                  },
                  'width': {
                    'sm': 100,
                    'md': 50,
                    'lg': 100
                  },
                  'fixedWidthEnabled': {
                    'sm': false,
                    'md': false,
                    'lg': false
                  },
                  'fixedWidth': {
                    'sm': 300,
                    'md': 300,
                    'lg': 300
                  },
                  'elements': [],
                  'customize': {
                    'style': {
                      'backgroundImage': {
                        'type': 'background-image-customs',
                        'src': null,
                        'position': 'center',
                        'size': 'cover',
                        'opacity': 1
                      },
                      'background': {
                        'background': 'rgba(225,225,225,0)'
                      },
                      'padding': {
                        'type': 'height-customs',
                        'lg': {
                          'top': '20',
                          'bottom': '20',
                          'left': '20',
                          'right': '20'
                        },
                        'md': {
                          'top': '20',
                          'bottom': '20',
                          'left': '20',
                          'right': '20'
                        },
                        'sm': {
                          'top': '10',
                          'bottom': '10',
                          'left': '20',
                          'right': '20'
                        }
                      },
                      'marginTop': {
                        'type': 'margin-top-customs',
                        'sm': 0,
                        'lg': 0
                      },
                      'border': {
                        'type': 'border-customs',
                        'style': 'none',
                        'width': 3,
                        'color': '#c0c0c0'
                      },
                      'borderRadius': {
                        'type': 'border-radius-customs',
                        'radius': 0
                      },
                      'floatImage': {
                        'display': true,
                        'enabled': false,
                        'type': 'float-image-customs',
                        'src': 'https://res.cloudinary.com/estage/image/upload/v1633894730/users/user-3/X8AqLb8FUZvjuT3QizVEg3WuUO4BKyzFa3JjcDMH.png',
                        'top': 0,
                        'x': {
                          'left': 0
                        }
                      }
                    }
                  },
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ]
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.editor-toolbar-collapse-header.active {
  background-color: #FAFCFF;
  color: #4F83E3;
}

.hover-text-red:hover {
  color: red !important;
}
</style>
