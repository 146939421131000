<template>
  <div :class="`--${section.sid}--wrapper`">
    <template v-if="!confirmation">
      <div
        v-if="orderForm.stepType !== 'hide'"
        :class="`--${section.sid}--header`"
      >
        <div
          class="form-wizard-steps flex flex-wrap items-stretch"
          :class="`--${section.sid}--control`"
        >
          <template v-if="orderForm.stepType === 'tabs'">
            <div
              v-for="(step, index) in steps"
              :key="step.uui"
              class="transition w-auto flex grow"
              :class="[`--${section.sid}--step`, {'active': currentStep === index}]"
            >
              <p class="step-title w-full m-0">
                {{ step.options.name }}
              </p>
            </div>
          </template>

          <div
            v-if="orderForm.stepType === 'progress' && !reload"
            class="transition w-full flex flex-col items-center justify-center text-center"
            :class="[`--${section.sid}--progress`]"
          >
            <p class="progress-counter m-0">
              Step {{ currentStep + 1 }} of {{ count }}
            </p>
            <p class="progress-title w-full m-0">
              {{ orderForm.steps[currentStep].options.name }}
            </p>
            <b-progress
              :value="currentStep + 1"
              :max="count"
            />
          </div>
        </div>
      </div>
    </template>

    <transition
      v-if="!reload"
      name="fade"
    >
      <div
        class="flex flex-col h-min-[300px]"
        :class="`--${section.sid}--content`"
      >
        <div
          v-for="(step, index) in orderForm.steps"
          :key="step.uui"
        >
          <OrderFormLayout
            v-show="index === currentStep"
            :step="step" 
            :section="section"
            :steps-count="stepsCount"
            :current-step="currentStep"
            :confirmation="confirmation"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import SectionMixin from '@/components/mixins/SectionMixin'
import OrderFormLayout from '@/components/builder/components/form/OrderFormLayout.vue'
export default {
  name: 'FormWizard',
  components: {
    OrderFormLayout
  },
  mixins: [SectionMixin],
  props: {
    orderForm: {
      type: Object,
      required: true
    },
    steps: {
      type: Array,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      reload: false
    }
  },
  computed: {
    ...mapState('forms', {
      currentStep: state => state.wizard.currentStep,
      stepsCount: state => state.wizard.count,
      confirmation: state => state.wizard.confirmation
    })
  },
  watch: {
    currentStep () {
      this.reload = true
      setTimeout(() => this.reload = false)
    }
  }
}
</script>

<style lang="scss">
.editor-container-sm .form-wizard-steps {
  flex-direction: column;
}

.form-wizard-steps {
  flex-direction: row;
}
</style>