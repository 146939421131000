<template>
  <div class="pt-[15px]">
    <b-form-group>
      <div class="flex items-center">
        <label class="mb-0 pb-0 mr-[12px]">Text Color</label>
        <text-color
          v-model="config.color"
          :color="config.color"
        />
      </div>
    </b-form-group>

    <font-size-control :config="config" />

    <b-form-group label="Font weight">
      <font-weight v-model="config.fontWeight" />
    </b-form-group>

    <b-form-group label="Font family">
      <font-family v-model="config.fontFamily" />
    </b-form-group>

    <!--    <b-form-group label="Shape">-->
    <!--      <shape v-model="config.shape" />-->
    <!--    </b-form-group>-->

    <alignment-items-control :config="config" />

    <background-customs :config="section.options.customize.style.background" />

    <SidbearSettingsGroup
      class="border-top"
      title="Border & Radius"
    >
      <border-customs
        :config="config"
        :default-border="[0,0,0,0]"
        :default-radius="[0,0,0,0]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <shadow-customs :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="section.options.customize.style.padding" />
      <margin-full-customs :config="section.options.customize.style.marginY" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ThemeMixin from '@builder/components/mixins/ThemeMixin'
import FontWeight from '../controls/FontWeight'
import FontFamily from '../controls/FontFamily'
import FontSizeControl from '../controls/FontSizeControl'
import AlignmentItemsControl from '../controls/AlignmentItemsControl'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'

export default {
  name: 'BadgeMod',
  components: {SidbearSettingsGroup, AlignmentItemsControl, FontSizeControl, FontFamily, FontWeight},
  mixins: [ModMixin, ThemeMixin],
  computed: {}
}
</script>
