var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SidbearSettingsGroup',{attrs:{"title":"Shorts","active":""}},[_c('b-form-group',{attrs:{"label":"URL"}},[_c('b-input',{model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1),_c('b-form-group',[_c('label',[_vm._v("Max Width "),_c('span',{staticClass:"lowercase"},[_vm._v("(px)")])]),_c('div',{staticClass:"flex items-center gap-[10px]"},[_c('vue-slider',{staticClass:"editor-slider-1 !w-[160px]",attrs:{"interval":1,"dot-size":17,"tooltip":"none","min":10,"max":2000},model:{value:(_vm.width),callback:function ($$v) {_vm.width=$$v},expression:"width"}}),_c('linked-control-item',{staticClass:"d-block !w-[60px]",attrs:{"min":10,"max":2000},model:{value:(_vm.width),callback:function ($$v) {_vm.width=$$v},expression:"width"}})],1)]),_c('b-form-group',{attrs:{"label":"Height"}},[_c('b-input',{attrs:{"readonly":""},model:{value:(_vm.height),callback:function ($$v) {_vm.height=$$v},expression:"height"}})],1),_c('sid-buttons-group',{attrs:{"label":"Horizontal Alignment","options":[
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'start',
          tooltip: 'Start'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'center',
          tooltip: 'Center'
        },
        {
          svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
          value: 'end',
          tooltip: 'End'
        }
      ],"sid":(".--" + (_vm.section.sid) + "--yt-shorts .yt-shorts-iframe-container"),"rule-name":"justify-content"}})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Drop Shadow"}},[_c('rule-tabs',{scopedSlots:_vm._u([{key:"Normal",fn:function(){return [_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Shadow"}},[_c('sid-box-shadow',{attrs:{"sid":(".--" + (_vm.section.sid) + "--yt-shorts .yt-iframe"),"rule-name":"box-shadow"}})],1)]},proxy:true},{key:"Hover",fn:function(){return [_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Shadow"}},[_c('sid-box-shadow',{attrs:{"sid":(".--" + (_vm.section.sid) + "--yt-shorts"),"rule-name":"box-shadow","presudo":" .yt-iframe:hover"}})],1)]},proxy:true}])})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Border & Radius"}},[_c('rule-tabs',{scopedSlots:_vm._u([{key:"Normal",fn:function(){return [_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Border Type")]),_c('sid-select',{staticClass:"w-[118px]",attrs:{"sid":(".--" + (_vm.section.sid) + "--yt-shorts .yt-iframe"),"rule-name":"border-style","searchable":false,"options":[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]}})],1)]),_c('b-form-group',[_c('sid-linked-group',{staticClass:"!mb-[7px]",attrs:{"sid":(".--" + (_vm.section.sid) + "--yt-shorts .yt-iframe"),"label":"Border Width","rule-name":"border","css-var":"--border-size","angles":[
              {
                text: '',
                value: 'top-width'
              },
              {
                text: '',
                value: 'right-width'
              },
              {
                text: '',
                value: 'bottom-width'
              },
              {
                text: '',
                value: 'left-width'
              }
            ]}}),_c('div',{staticClass:"flex w-full justify-end"},[_c('sid-color',{attrs:{"sid":(".--" + (_vm.section.sid) + "--yt-shorts .yt-iframe"),"rule-name":"border-color"}})],1)],1),_c('sid-linked-group',{attrs:{"label":"Radius","sid":(".--" + (_vm.section.sid) + "--yt-shorts .yt-iframe"),"rule-name":"border","titles":['top-l', 'top-r', 'bottom-l', 'bottom-r'],"angles":[
            {
              text: '',
              value: 'top-left-radius',
              cssVar: '--border-top-left-radius'
            },
            {
              text: '',
              value: 'top-right-radius',
              cssVar: '--border-top-right-radius'
            },
            {
              text: '',
              value: 'bottom-left-radius',
              cssVar: '--border-bottom-left-radius'
            },
            {
              text: '',
              value: 'bottom-right-radius',
              cssVar: '--border-bottom-right-radius'
            }
          ]}})]},proxy:true},{key:"Hover",fn:function(){return [_c('b-form-group',[_c('div',{staticClass:"flex items-center justify-between"},[_c('label',{staticClass:"pb-0"},[_vm._v("Border Type")]),_c('sid-select',{staticClass:"w-[118px]",attrs:{"sid":(".--" + (_vm.section.sid) + "--yt-shorts"),"rule-name":"border-style","searchable":false,"presudo":" .yt-iframe:hover","options":[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]}})],1)]),_c('b-form-group',[_c('sid-linked-group',{staticClass:"!mb-[7px]",attrs:{"sid":(".--" + (_vm.section.sid) + "--yt-shorts"),"label":"Border Width","rule-name":"border","css-var":"--border-size","presudo":" .yt-iframe:hover","angles":[
              {
                text: '',
                value: 'top-width'
              },
              {
                text: '',
                value: 'right-width'
              },
              {
                text: '',
                value: 'bottom-width'
              },
              {
                text: '',
                value: 'left-width'
              }
            ]}}),_c('div',{staticClass:"flex w-full justify-end"},[_c('sid-color',{attrs:{"sid":(".--" + (_vm.section.sid) + "--yt-shorts"),"rule-name":"border-color","presudo":" .yt-iframe:hover"}})],1)],1),_c('sid-linked-group',{attrs:{"label":"Radius","sid":(".--" + (_vm.section.sid) + "--yt-shorts"),"rule-name":"border","presudo":" .yt-iframe:hover","titles":['top-l', 'top-r', 'bottom-l', 'bottom-r'],"angles":[
            {
              text: '',
              value: 'top-left-radius',
              cssVar: '--border-top-left-radius'
            },
            {
              text: '',
              value: 'top-right-radius',
              cssVar: '--border-top-right-radius'
            },
            {
              text: '',
              value: 'bottom-left-radius',
              cssVar: '--border-bottom-left-radius'
            },
            {
              text: '',
              value: 'bottom-right-radius',
              cssVar: '--border-bottom-right-radius'
            }
          ]}})]},proxy:true}])})],1),_c('SidbearSettingsGroup',{attrs:{"title":"Padding & Margins"}},[_c('sid-linked-group',{attrs:{"label":"Padding","sid":(".--" + (_vm.section.sid) + "--yt-shorts .yt-shorts-iframe-container"),"rule-name":"padding","min":0,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}}),_c('sid-linked-group',{attrs:{"label":"Margin","sid":(".--" + (_vm.section.sid) + "--yt-shorts .yt-shorts-iframe-container"),"rule-name":"margin","min":-2000,"max":2000,"angles":[
        {
          text: '',
          value: 'top'
        },
        {
          text: '',
          value: 'right'
        },
        {
          text: '',
          value: 'bottom'
        },
        {
          text: '',
          value: 'left'
        }
      ]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }