<template>
  <div>
    <settings-page-card title="EGT Event Actions">
      <div class="px-4">
        <div
          v-for="(variable, index) in variables"
          :key="index"
          class="flex gap-[30px] w-full items-center"
        >
          <b-form-group
            label="Name"
            class="min-w-[250px] w-100"
          >
            <b-input v-model="variable.name" />
          </b-form-group>
          <b-form-group
            label="Key"
            class="min-w-[250px] w-100"
          >
            <b-input v-model="variable.key" />
          </b-form-group>
          <global-tracking :variable="variable" />
          <div class="mt-2">
            <svg
              class="cursor-pointer hover:opacity-50"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="removeOption(index)"
            >
              <path
                d="M6.71152 5.5L10.8243 1.38681C10.9374 1.27358 10.9998 1.12252 11 0.961445C11 0.80028 10.9376 0.649042 10.8243 0.535994L10.4639 0.175654C10.3506 0.0621585 10.1995 0 10.0383 0C9.87729 0 9.72624 0.0621585 9.61293 0.175654L5.50018 4.28858L1.38725 0.175654C1.27412 0.0621585 1.12298 0 0.961829 0C0.800854 0 0.649715 0.0621585 0.536585 0.175654L0.176 0.535994C-0.0586667 0.770676 -0.0586667 1.15239 0.176 1.38681L4.28884 5.5L0.176 9.61302C0.0627805 9.72642 0.000447154 9.87748 0.000447154 10.0386C0.000447154 10.1996 0.0627805 10.3507 0.176 10.464L0.536496 10.8243C0.649626 10.9378 0.800854 11 0.96174 11C1.12289 11 1.27403 10.9378 1.38716 10.8243L5.50009 6.71133L9.61284 10.8243C9.72615 10.9378 9.8772 11 10.0382 11H10.0383C10.1994 11 10.3505 10.9378 10.4638 10.8243L10.8242 10.464C10.9373 10.3508 10.9997 10.1996 10.9997 10.0386C10.9997 9.87748 10.9373 9.72642 10.8242 9.6131L6.71152 5.5Z"
                fill="#6E747A"
              />
            </svg>
          </div>
        </div>

        <div class="flex gap-[10px]">
          <b-btn
            variant="primary"
            @click="save"
          >
            Save
          </b-btn>
          <b-btn
            @click="addOption"
          >
            Add New
          </b-btn>
        </div>
      </div>
    </settings-page-card>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import SettingsPageCard from '@/layouts/dashboard/SettingsPageCard.vue'
import GlobalTracking from '@/components/editor/components/global-tracking/GlobalTrackingSelect.vue'
import {toJSON} from '@/utils/helpers'
// import {toJSON, toStringify} from '../../../utils/helpers'

export default {
  name: 'GlobalVariables',
  components: {SettingsPageCard, GlobalTracking},
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    variables () {
      if (!this.project.content || this.project.content === '{}') {
        this.$set(this.project, 'content', [
          {
            name: '',
            key: '',
            responder: '',
            tags: []
          }
        ])
      } else {
        this.$set(this.project, 'content', toJSON(this.project.content))
      }

      return toJSON(this.project.content)
    }
  },

  methods: {
    save () {
      VEvent.fire('loader', true)
      this.$store.dispatch('projects/save', true)
        .finally(() => {
          VEvent.fire('loader', false)
        })
    },
    removeOption (index) {
      this.project.content.splice(index, 1)
    },
    addOption () {
      this.project.content.push({
        name: '',
        key: '',
        responder: '',
        tags: []
      })
    }
  }
}
</script>
