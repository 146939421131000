<template>
  <v-select
    v-model="model"
    :options="paginated"
    :filterable="false"
    label="name"
    :disabled="!country"
    @open="onOpen"
    @close="onClose"
    @search="(query) => (search = query)"
  >
    <template v-slot:selected-option>
      {{ name }}
    </template>

    <template #list-footer>
      <li
        v-show="hasNextPage"
        ref="load"
        class="loader"
      >
        Loading more options...
      </li>
    </template>
  </v-select>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'StateSelect',
  props: {
    value: {
      required: true
    },
    country: {
      required: true
    }
  },
  data: () => ({
    states: [],
    observer: null,
    limit: 10,
    search: ''
  }),
  computed: {
    name () {
      return _.get(_.find(this.states, ['id', this.model]), 'name', '')
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val.id)
      }
    },
    filtered () {
      return this.states.filter((state) => state.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    }
  },

  watch: {
    country () {
      this.load()
    }
  },
  mounted () {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll)

    this.load()
  },
  methods: {
    load () {
      if (!this.country) return
      axios.get(`api/user/profile/states?country_id=${this.country}`)
        .then(({data}) => {
          this.states = data.data
        })
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    }
  }
}
</script>