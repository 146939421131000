<template>
  <b-form-group label="Delay (MS)">
    <LinkedControlItem
      v-model="delay"
      :max="50000"
      :min="0"
      class="d-block w-100"
    />
  </b-form-group>
</template>

<script>
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'
export default {
  name: 'MotionDelay',
  components: {LinkedControlItem},
  props: {
    subjectMotions: {
      type: Object,
      required: true
    }
  },
  computed: {
    delay: {
      get () {
        return this.subjectMotions.delay || 0
      },
      set (val) {
        this.$set(this.subjectMotions, 'delay', val)
      }
    }
  }
}
</script>