import Vue from 'vue'
import {clonePart} from '@/utils/useStylesheet'

const state = () => ({
  favorites: null
})

const getters = {}

const actions = {
  async addToGlobal ({rootState}, {favorite, inGlobal}) {
    const unset = inGlobal ? {unset: true} : null
    return await axios.put(`api/projects/${rootState.projects.project.id}/favorite-blocks/global/${favorite.id}`, unset)
  },

  async addFavorite ({dispatch,  rootState}, {favorite, inGlobal, inSharable, origSection, node}) {
    VEvent.fire('alt-loader', {
      state: true,
      message: 'Saving...'
    })

    const content = inSharable ? clonePart({
      ...favorite,
      isGlobal: !!(origSection && inGlobal || origSection && inSharable),
      isSharable: !!(origSection && inGlobal || origSection && inSharable)
    }, node) : clonePart({
      ...favorite,
      isGlobal: !!(origSection && inGlobal || origSection && inSharable),
      isSharable: !!(origSection && inGlobal || origSection && inSharable)
    }, node).replace(/"id":\d+,?/g, '')
      .replace(/"form_id":\d+,?/g, '')
      .replace(/,\}/g, '}')

    // const isSharable = origSection ? origSection.isSharable : false

    if (origSection && origSection.favId) {
      await axios.put(`api/projects/${rootState.projects.project.id}/favorite-blocks/${origSection.favId}?set_global=${inGlobal}`, {
        block: content,
        sharable: content.isSharable
      })
        .then(() => {
          dispatch('fetchFavorites')

          VEvent.fire('alt-loader', {
            state: false,
            message: 'Synced!'
          })
        })
    } else {
      await axios.post(`api/projects/${rootState.projects.project.id}/favorite-blocks?set_global=${inGlobal}`, {
        block: content,
        sharable: true
      })
        .then(({data}) => {
          dispatch('fetchFavorites')

          if (origSection && inGlobal || origSection && inSharable) {
            Vue.set(origSection, 'name', favorite.section.name)
            Vue.set(origSection, 'isGlobal', true)
            Vue.set(origSection, 'isSharable', true)
            Vue.set(origSection, 'favId', data.data.id)
          }

          VEvent.fire('alt-loader', {
            state: false,
            message: 'Added to favorites.'
          })
        })
    }
  },
  async removeFavorite ({state, rootState}, payload) {
    VEvent.fire('alt-loader', {
      state: true,
      message: 'Removing...'
    })

    await axios.delete(`api/projects/${rootState.projects.project.id}/favorite-blocks/${payload.favorite.id}`)
      .then(() => {
        state.favorites.splice(payload.index, 1)
        VEvent.fire('alt-loader', {
          state: false,
          message: 'Removed from favorites.'
        })
      })
  },
  async fetchFavorites ({state, rootState}) {
    await axios.get(`api/projects/${rootState.projects.project.id}/favorite-blocks?perPage=100`)
      .then(({data}) => {
        state.favorites = data.data.data.map(({id, block, user_id, hash}) => {
          return {
            id,
            block: JSON.parse(block),
            user_id,
            hash
          }
        })
      })
  }
}

const mutations = {}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
