import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Navbar'
export const group = 'navbar'
export const defaultOptions = {
  'customize': {
    'style': {
      'navbarScroller': {
        'type': 'navbar-scroller-customs',
        'scrollType': null
      }
    },
    'display': true
  },
  'content': {
    'logo': {
      'type': 'logo-mod',
      'display': true,
      'fontFamily': null,
      'fontWeight': 'bold',
      'fontSize': '24px',
      'color': '#0C226C',
      'text': 'AppStarter'
    },
    'menuItems': {
      'type': 'menu-items-mod-v2',
      'display': true,
      'bringToFront': {
        'lg': true
      }
    },
    'subject': {
      'uid': 'WKtPEOYr2',
      'sid': 'lle5rye7--JssZoz9iiC',
      'name': 'Flex Wrapper',
      'hideFromSections': false,
      'component': 'ElFlexWrapperV2',
      'group': 'navbar',
      'layout': null,
      'specialCols': {
        'navigation': {
          'special': true,
          'sid': 'lle5rye7--Qk1vGYEDO0',
          'placement': 'bottom',
          'hamburgerIcon_open': 'fa fa-bars',
          'hamburgerIcon_close': 'fa fa-times',
          'hamburgerIcon_color': '#656565',
          'showOn': 'lg',
          'name': 'Nav Column',
          'elements': [
            {
              'display': true,
              'name': 'Flex Wrapper',
              'hideFromSections': false,
              'component': 'ElFlexWrapperV2',
              'group': '',
              'layout': null,
              'options': {
                'content': {
                  'display': true,
                  'cols': [
                    {
                      'display': true,
                      'name': 'Flex Column',
                      'elements': [
                        {
                          'uid': 'BlbxyqYc0',
                          'display': true,
                          'name': 'Menu Links',
                          'sid': 'lle5rye7--LQGtp4lKNP',
                          'hideFromSections': false,
                          'component': 'ElMenuLinksV4',
                          'group': 'Navigation',
                          'layout': null,
                          'options': {
                            'content': {
                              'menu': {
                                'display': true,
                                'type': 'menu-links-mod-v4',
                                'links': [
                                  {
                                    'id': '2lgcfYKlUS',
                                    'href': '#',
                                    'text': 'About',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'kE_0CEF0hZ',
                                    'href': '#',
                                    'text': 'Work with Me',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'wJGQ-hWnL1',
                                    'href': '#',
                                    'text': 'Resources',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'A_dvOV_wIV',
                                    'href': '#',
                                    'text': 'Blog',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'QlHAT-G7Lw',
                                    'href': '#',
                                    'text': 'Contact',
                                    'customLink': false,
                                    'blank': false,
                                    'children': []
                                  }
                                ]
                              }
                            }
                          },
                          'editor': 'project',
                          'temp_id': 'KL8FXUhHuN'
                        }
                      ],
                      'sortIndex': {
                        'lg': 0,
                        'md': 0,
                        'sm': 0
                      },
                      'uid': 'T9_AmLt3hK',
                      'sid': 'lle5rye7--MuRd871gpc',
                      'highlight': false,
                      'animations': {
                        'animation': null,
                        'once': true,
                        'offset': 200,
                        'delay': 50
                      },
                      '_uid': 'Mt55b4ekpZ'
                    },
                    {
                      'display': true,
                      'name': 'Flex Column',
                      'elements': [
                        {
                          'uid': 'oCKQ7xxGP',
                          'display': true,
                          'name': 'Button',
                          'sid': 'lle5rye7--uZI8_RYsx4',
                          'hideFromSections': false,
                          'component': 'ElButtonV2',
                          'group': 'Media',
                          'layout': null,
                          'options': {
                            'content': {
                              'button': {
                                'pressetID': 0,
                                'display': true,
                                'type': 'button-mod-v2',
                                'text': 'LOGIN',
                                'buttonType': 'button',
                                'icon': {
                                  'lottie': {
                                    'jsonPath': '',
                                    'loop': true,
                                    'autoplay': true
                                  }
                                },
                                'hoverAnimation': {},
                                'animations': {
                                  'animation': null,
                                  'once': true,
                                  'offset': 200,
                                  'delay': 50
                                }
                              }
                            },
                            'customize': null
                          },
                          'editor': 'project',
                          'temp_id': '-BOgEagQ0x'
                        }
                      ],
                      'sortIndex': {
                        'lg': 1,
                        'md': 1,
                        'sm': 1
                      },
                      'uid': '6chowRqhQq',
                      'sid': 'lle5rye7--SA-olUCmyn',
                      'highlight': false,
                      'animations': {
                        'animation': null,
                        'once': true,
                        'offset': 200,
                        'delay': 50
                      },
                      '_uid': 'AY4k2cyUY6'
                    }
                  ],
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              },
              'uid': '68Twket09',
              'sid': 'lle5rye7---xI-syvhZX',
              'temp_id': 'kb5eyU6NYu'
            }
          ],
          'highlight': false,
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          },
          'sortIndex': {
            'lg': 1,
            'md': 1,
            'sm': 1
          },
          'uid': 'SxBF1ib89',
          'navbarBackground': {
            'background': '',
            'gradient': {
              'position': '',
              'color': ''
            }
          },
          '_uid': 'lrPqweYtFe'
        }
      },
      'options': {
        'content': {
          'display': true,
          'cols': [
            {
              'display': true,
              'name': 'Flex Column',
              'elements': [
                {
                  'uid': 'CD9_q2Fvf',
                  'display': true,
                  'name': 'Image',
                  'sid': 'lle5rye7--UML_icyvA2',
                  'hideFromSections': false,
                  'component': 'ElImageV3',
                  'group': 'Media',
                  'layout': null,
                  'options': {
                    'content': {
                      'image': {
                        'display': true,
                        'type': 'ImageModV3',
                        'src': null,
                        'alt': 'Image',
                        'modal': {
                          'data': {
                            'modal_id': null
                          }
                        },
                        'animations': {
                          'animation': null,
                          'once': true,
                          'offset': 200,
                          'delay': 50
                        },
                        'url': ''
                      }
                    }
                  },
                  'editor': 'project',
                  'temp_id': 'VZmZ9hdaJo'
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              },
              'sortIndex': {
                'lg': 0,
                'md': 0,
                'sm': 0
              },
              'sid': 'lle5rye7--3kGwV8HqiM',
              'uid': 'An_TRM4rdE0',
              'highlight': false,
              '_uid': '4FwpXs5aE'
            },
            {
              'special': true,
              'key': 'navigation',
              'mod': 'navigation-flex-col-mod-v2',
              'name': 'Nav Column',
              'sid': 'Uz96GLEcGy',
              'sortIndex': {
                'lg': 1,
                'md': 1,
                'sm': 1
              },
              'highlight': false
            }
          ],
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          }
        }
      },
      'temp_id': '23MJlRTY6G'
    }
  }
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('NavbarV3', options)

    this.name = name
    this.group = group
    this.sid = ['global']
    this.sids = [
      'global',
      'lle5rye7--JssZoz9iiC',
      'lle5rye7--3kGwV8HqiM',
      'lle5rye7--UML_icyvA2',
      'lle5rye7--Qk1vGYEDO0',
      'lle5rye7---xI-syvhZX',
      'lle5rye7--MuRd871gpc',
      'lle5rye7--LQGtp4lKNP',
      'lle5rye7--SA-olUCmyn',
      'lle5rye7--uZI8_RYsx4'
    ]
    this.css = [
      [
        '.--lle5rye7--JssZoz9iiC--container { padding-right: 0px; box-shadow: rgba(181, 198, 233, 0.24) 0px 0px 13px 2px; border-radius: 4px; --border-bottom-right-radius: 4px; --border-top-left-radius: 4px; --border-top-right-radius: 4px; --border-bottom-left-radius: 4px; margin-top: 14px; }',
        '.--lle5rye7--3kGwV8HqiM--flex-col-padding { padding: 0px; }',
        '.--lle5rye7--Qk1vGYEDO0--flex-col-padding { padding: 0px; }',
        '.--lle5rye7--3kGwV8HqiM--flex-col-el { width: 265px; background-color: rgba(255, 255, 255, 0); --width: 265px; }',
        '.--lle5rye7--Qk1vGYEDO0--flex-col-el { width: 100%; --width: 100%; }',
        '.--lle5rye7--UML_icyvA2--container { justify-content: center; }',
        '.--lle5rye7--UML_icyvA2--wrapper { padding: 20px; }',
        '.--lle5rye7--Qk1vGYEDO0--flex-col > div { align-items: center; justify-content: center; }',
        '.--lle5rye7--MuRd871gpc--flex { width: 100%; --width: 100%; }',
        '.--lle5rye7--MuRd871gpc--flex-col-el { width: 100%; --width: 100%; }',
        '.--lle5rye7--SA-olUCmyn--flex-col-el { width: 18%; --width: 18%; }',
        '.--lle5rye7---xI-syvhZX--flex { width: 100%; --width: 100%; align-items: center; padding: 0px; }',
        '.--lle5rye7--JssZoz9iiC--flex { align-items: center; max-width: 1200px; --width: 1200px; }',
        '.--lle5rye7--LQGtp4lKNP--wrapper { padding: 10px; justify-content: end; gap: 56px; font-family: Roboto; font-weight: 400; align-items: end; }',
        '.--lle5rye7--uZI8_RYsx4--1 { margin: 20px; background-color: rgba(0, 0, 0, 0); font-size: 14px; border-width: 2px; border-color: rgb(43, 44, 170); border-radius: 90px; color: rgb(43, 44, 170); font-family: Roboto; font-weight: 700; padding: 7px 20px; }',
        '.--lle5rye7--LQGtp4lKNP--link { font-size: 14px; color: rgb(103, 107, 154); transform: skew(0deg) scale(1) rotateY(0deg) rotateX(0deg) rotateZ(0deg) translateY(0px) translateX(0px) translateZ(0px); --transform: skew(0deg) scale(1) rotateY(0deg) rotateX(0deg) rotateZ(0deg) translateY(0px) translateX(0px) translateZ(0px); text-transform: uppercase; letter-spacing: 1px; }',
        '.--lle5rye7--LQGtp4lKNP--link:hover { color: rgb(43, 44, 170); transform: skew(0deg) scale(1.1) rotateY(0deg) rotateX(0deg) rotateZ(0deg) translateY(0px) translateX(0px) translateZ(0px); --transform: skew(0deg) scale(1.1) rotateY(0deg) rotateX(0deg) rotateZ(0deg) translateY(0px) translateX(0px) translateZ(0px); font-weight: 700; }',
        '.--lle5rye7--MuRd871gpc--flex-col-padding { padding: 0px; }',
        '.--lle5rye7--SA-olUCmyn--flex-col-padding { padding: 0px; }',
        '.--lle5rye7--UML_icyvA2--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690327663/users/user-572/d676b4dae72d66fcbaa64843e21bb797.png"); --bg-id: 167140; }',
        '.el-image.--lle5rye7--UML_icyvA2--image { width: 180px; --width: 180px; }',
        '.--lle5rye7--SA-olUCmyn--flex-col { text-align: end; justify-content: end; }',
        '.--lle5rye7--uZI8_RYsx4--1 .main-text { justify-content: center; }',
        '.--lle5rye7--uZI8_RYsx4--2 { justify-content: end; }',
        '.--lle5rye7--JssZoz9iiC--bg { background-color: rgb(255, 255, 255); }',
        '.--lle5rye7--uZI8_RYsx4--1:hover { background-color: rgb(43, 44, 170); color: rgb(255, 255, 255); }'
      ],
      [
        '.--lle5rye7--MuRd871gpc--flex { width: 100%; --width: 100%; }',
        '.--lle5rye7---xI-syvhZX--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lle5rye7--SA-olUCmyn--flex-col-el { width: 100%; --width: 100%; }',
        '.--lle5rye7--LQGtp4lKNP--wrapper { flex-direction: column; justify-content: center; align-items: center; gap: 22px; }',
        '.--lle5rye7--Qk1vGYEDO0--flex-col-el { background-color: rgb(255, 255, 255); box-shadow: rgba(206, 206, 225, 0.28) 0px 0px 27px 5px; }',
        '.--lle5rye7--Qk1vGYEDO0--flex-col-padding { padding-top: 20px; padding-bottom: 20px; }',
        '.--lle5rye7--JssZoz9iiC--close-icon { color: rgb(43, 44, 170); }',
        '.--lle5rye7--JssZoz9iiC--icon { font-size: 20px; translate: -20px; }',
        '.--lle5rye7--JssZoz9iiC--open-icon { color: rgb(43, 44, 170); }',
        '.--lle5rye7--JssZoz9iiC--container { margin-top: 0px; border-radius: 0px; --border-bottom-right-radius: 0; --border-top-left-radius: 0; --border-top-right-radius: 0; --border-bottom-left-radius: 0; }',
        '.--lle5rye7--3kGwV8HqiM--flex-col { text-align: left; justify-content: start; }',
        '.--lle5rye7--JssZoz9iiC--flex.flex-wrapper { justify-content: start; }',
        '.--lle5rye7--UML_icyvA2--container { justify-content: start; }',
        '.--lle5rye7--uZI8_RYsx4--2 { justify-content: center; }'
      ],
      [
        '.--lle5rye7--MuRd871gpc--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lle5rye7---xI-syvhZX--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lle5rye7--3kGwV8HqiM--flex-col-el { width: 50%; --width: 50%; }',
        '.--lle5rye7--SA-olUCmyn--flex-col-el { width: 100%; --width: 100%; }',
        '.--lle5rye7--LQGtp4lKNP--wrapper { gap: 27px; flex-direction: column; justify-content: center; align-items: center; }',
        '.--lle5rye7--Qk1vGYEDO0--flex-col-padding { padding-top: 20px; padding-bottom: 20px; }',
        '.--lle5rye7--uZI8_RYsx4--1 { width: 34%; --width: 34%; }',
        '.--lle5rye7--Qk1vGYEDO0--flex-col-el { width: 100%; --width: 100%; }',
        '.--lle5rye7--UML_icyvA2--container { justify-content: start; }',
        '.--lle5rye7--3kGwV8HqiM--flex-col-padding { padding-left: 0px; }',
        '.--lle5rye7--UML_icyvA2--wrapper { padding-left: 0px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
