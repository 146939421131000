<template>
  <es-dropdown
    :disabled="!link.children || !link.children.length"
    :dropdown-style="link.dropdownStyle"
  >
    <template v-slot:activator>
      <div :class="menu.hoverAnimation.lg">
        <div
          :style="{color, fontSize, fontWeight, fontFamily, fontStyle, textDecoration}"
          class="cursor-pinter el-menu-link transitioner"
          @mouseenter="isHover = true"
          @mouseleave="isHover = false"
        >
          <span v-text="link.text" />
          <i
            v-if="link.children && link.children.length"
            class="fa fa-angle-down ml-2"
          />
        </div>
      </div>
    </template>

    <div v-if="link.children">
      <menu-link
        v-for="(link, index) in link.children"
        :key="index"
        :link="link"
        :menu="menu"
      />
    </div>
  </es-dropdown>
</template>

<script>
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import {mapState} from 'vuex'
import EsDropdown from '@/components/utils/estage-dropdown/EsDropdown'

export default {
  name: 'MenuLink',
  components: {EsDropdown},
  mixins: [ResolutionMixin],
  props: {
    link: {
      type: Object,
      required: true
    },
    menu: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isHover: false
    }
  },
  computed: {
    ...mapState({
      globalStyles: state => state.editor.globalStyles
    }),
    color () {
      if (this.isHover) {
        if (this.menu.color.hover.color === null) return this.menu.color.color
        return this.menu.color.hover.color
      }

      return this.menu.color.color
    },
    fontSize () {
      if (this.isHover) {
        if (this.menu.fontSize.hover[this.resolutioner] === null) return `${this.menu.fontSize[this.resolutioner] || this.menu.fontSize.lg}px`
        return `${this.menu.fontSize.hover[this.resolutioner] || this.menu.fontSize.lg}px`
      }

      return `${this.menu.fontSize[this.resolutioner] || this.menu.fontSize.lg}px`
    },
    fontWeight () {
      const _fontWeight = this.menu.fontWeight.lg || this.globalStyles.textStyles.link.fontWeight[this.resolutioner]

      if (this.isHover) {
        if (this.menu.fontWeight.hover[this.resolutioner] === null) return this.menu.fontWeight[this.resolutioner] || _fontWeight
        return this.menu.fontWeight.hover[this.resolutioner] || _fontWeight
      }

      return this.menu.fontWeight[this.resolutioner] || _fontWeight
    },
    fontFamily () {
      const _fontFamily = this.menu.fontFamily.lg || this.globalStyles.textStyles.link.fontFamily[this.resolutioner]

      if (this.isHover) {
        if (this.menu.fontFamily.hover[this.resolutioner] === null) return this.menu.fontFamily[this.resolutioner] || _fontFamily
        return this.menu.fontFamily.hover[this.resolutioner] || _fontFamily
      }

      return this.menu.fontFamily[this.resolutioner] || _fontFamily
    },
    fontStyle () {
      if (this.isHover) {
        if (this.menu.italic.hover.italic === null) return this.menu.italic.italic ? 'italic' : null
        return this.menu.italic.hover.italic ? 'italic' : null
      }

      return this.menu.italic.italic ? 'italic' : null
    },
    textDecoration () {
      if (this.isHover) {
        if (this.menu.underline.hover.underline === null) return this.menu.underline.underline ? 'underline' : null
        return this.menu.underline.hover.underline ? 'underline' : null
      }

      return this.menu.underline.underline ? 'underline' : null
    }
  }
}
</script>

<style lang="scss">
.el-menu-link {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 120%;
    height: 120%;
  }
}
</style>