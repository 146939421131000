<template>
  <div>
    <div class="h6">
      Title
    </div>
    <!--    <blog-text-mod :config=""></blog-text-mod>-->
    <hr>

    <div class="h6">
      Description
    </div>

    <hr>

    <div class="h6">
      Date
    </div>

    <hr>

    <div class="h6">
      Button
    </div>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ThemeMixin from '@builder/components/mixins/ThemeMixin'

export default {
  name: 'ButtonMod',
  mixins: [ModMixin, ThemeMixin],

  data () {
    return {

    }
  }
}
</script>
