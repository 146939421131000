export const scrollEffectOptions = [
  {
    label: 'Rotate',
    effect: 'rotate',
    data: [
      {
        progress: 0,
        value: {
          x: null,
          y: null
        }
      },
      {
        progress: 100,
        value: {
          x: null,
          y: null
        }
      }
    ]
  },
  {
    label: 'Translate',
    effect: 'translate',
    data: [
      {
        progress: 0,
        value: {
          x: null,
          y: null
        }
      },
      {
        progress: 100,
        value: {
          x: null,
          y: null
        }
      }
    ]
  },
  {
    label: 'Scale',
    effect: 'scale',
    data: [
      {
        progress: 0,
        value: {
          x: 1,
          y: 1
        }
      },
      {
        progress: 100,
        value: {
          x: 1,
          y: 1
        }
      }
    ]
  },
  {
    label: 'Skew',
    effect: 'skew',
    data: [
      {
        progress: 0,
        value: {
          x: null,
          y: null
        }
      },
      {
        progress: 100,
        value: {
          x: null,
          y: null
        }
      }
    ]
  },
  {
    label: 'Opacity',
    effect: 'opacity',
    data: [
      {
        progress: 0,
        value: 1
      },
      {
        progress: 100,
        value: 0.1
      }
    ]
  },
  {
    label: 'Background Color',
    effect: 'backgroundColor',
    data: [
      {
        progress: 0,
        value: null
      },
      {
        progress: 100,
        value: null
      }
    ]
  }
]
export const staticEffectOptions = [
  {
    label: 'Rotate',
    effect: 'rotate',
    data: [
      {
        progress: 0,
        value: {
          x: null,
          y: null,
          z: null
        }
      },
      {
        progress: 100,
        value: {
          x: null,
          y: null,
          z: null
        }
      }
    ]
  },
  {
    label: 'Translate',
    effect: 'translate',
    data: [
      {
        progress: 0,
        value: {
          x: null,
          y: null
        }
      },
      {
        progress: 100,
        value: {
          x: null,
          y: null
        }
      }
    ]
  },
  {
    label: 'Scale',
    effect: 'scale',
    data: [
      {
        progress: 0,
        value: {
          x: 1,
          y: 1
        }
      },
      {
        progress: 100,
        value: {
          x: 1,
          y: 1
        }
      }
    ]
  },
  {
    label: 'Skew',
    effect: 'skew',
    data: [
      {
        progress: 0,
        value: {
          x: null,
          y: null
        }
      },
      {
        progress: 100,
        value: {
          x: null,
          y: null
        }
      }
    ]
  },
  {
    label: 'Opacity',
    effect: 'opacity',
    data: [
      {
        progress: 0,
        value: 1
      },
      {
        progress: 100,
        value: 0.1
      }
    ]
  },
  {
    label: 'Background Color',
    effect: 'backgroundColor',
    data: [
      {
        progress: 0,
        value: null
      },
      {
        progress: 100,
        value: null
      }
    ]
  }
]
export const viewportEffectOptions = [
  {
    label: 'Rotate',
    effect: 'rotate',
    data: [
      {
        progress: 0,
        value: {
          x: null,
          y: null,
          z: null
        }
      },
      {
        progress: 100,
        value: {
          x: null,
          y: null,
          z: null
        }
      }
    ]
  },
  {
    label: 'Translate',
    effect: 'translate',
    data: [
      {
        progress: 0,
        value: {
          x: null,
          y: null
        }
      },
      {
        progress: 100,
        value: {
          x: null,
          y: null
        }
      }
    ]
  },
  {
    label: 'Scale',
    effect: 'scale',
    data: [
      {
        progress: 0,
        value: {
          x: 1,
          y: 1
        }
      },
      {
        progress: 100,
        value: {
          x: 1,
          y: 1
        }
      }
    ]
  },
  {
    label: 'Skew',
    effect: 'skew',
    data: [
      {
        progress: 0,
        value: {
          x: null,
          y: null
        }
      },
      {
        progress: 100,
        value: {
          x: null,
          y: null
        }
      }
    ]
  },
  {
    label: 'Opacity',
    effect: 'opacity',
    data: [
      {
        progress: 0,
        value: 1
      },
      {
        progress: 100,
        value: 0.1
      }
    ]
  },
  {
    label: 'Background Color',
    effect: 'backgroundColor',
    data: [
      {
        progress: 0,
        value: null
      },
      {
        progress: 100,
        value: null
      }
    ]
  }
]
export const mouseMoveEffectOptions = [
  {
    label: 'Rotate',
    effect: 'rotate',
    data: [
      {
        progress: 0,
        value: {
          x: null,
          y: null,
          z: null
        }
      },
      {
        progress: 100,
        value: {
          x: null,
          y: null,
          z: null
        }
      }
    ]
  },
  {
    label: 'Translate',
    effect: 'translate',
    data: [
      {
        progress: 0,
        value: {
          x: null,
          y: null
        }
      },
      {
        progress: 100,
        value: {
          x: null,
          y: null
        }
      }
    ]
  },
  {
    label: 'Scale',
    effect: 'scale',
    data: [
      {
        progress: 0,
        value: {
          x: 1,
          y: 1
        }
      },
      {
        progress: 100,
        value: {
          x: 1,
          y: 1
        }
      }
    ]
  },
  {
    label: 'Skew',
    effect: 'skew',
    data: [
      {
        progress: 0,
        value: {
          x: null,
          y: null
        }
      },
      {
        progress: 100,
        value: {
          x: null,
          y: null
        }
      }
    ]
  }
]

export const setup = {
  mouseMove: {
    x: {},
    y: {}
  },
  scroll: {},
  static: {},
  viewport: {}
}