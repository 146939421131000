<template>
  <settings-page-card
    title="General Settings"
  >
    <b-form @submit.prevent="onSubmit">
      <div class="px-4">
        <div class="max-w-[600px]">
          <b-form-group>
            <slot name="label">
              <h6 class="font-weight-sami-bold text-dark">
                Project Name
              </h6>
            </slot>
            <b-input
              v-model="projectName"
              v-validate="'required'"
              name="name"
              :state="errors.has('name') ? false : null"
            />
          </b-form-group>

          <b-form-group>
            <slot name="label">
              <h6 class="font-weight-sami-bold text-dark">
                Project Description
              </h6>
            </slot>
            <b-textarea
              v-model="project.description"
              rows="4"
            />
          </b-form-group>

          <b-form-group v-show="false">
            <slot name="label">
              <h6 class="font-weight-sami-bold text-dark">
                Subdomain
              </h6>
            </slot>
            <b-input
              v-model="subdomain"
              v-validate="'required'"
              name="subdomain"
              :state="errors.has('subdomain') ? false : null"
            />
          </b-form-group>

          <b-form-group>
            <slot name="label">
              <h6 class="font-weight-sami-bold text-dark">
                Timezone
              </h6>
            </slot>
            <v-select
              v-model="project.timezone"
              :options="timezoneOptions"
              label="name"
              class="!w-[210px]"
              :reduce="val => val.value"
            />
          </b-form-group>
        </div>

        <hr class="my-4">

        <favicon-uploader class="mb-4" />

        <b-btn
          variant="primary"
          type="submit"
        >
          Save Changes
        </b-btn>
      </div>
    </b-form>
  </settings-page-card>
</template>

<script>
import _ from 'lodash'
import {mapState} from 'vuex'
import SettingsPageCard from '../../layouts/dashboard/SettingsPageCard'
import FaviconUploader from '../../components/editor/controls/FaviconUploader'
import TimezonesMixin from '@/components/mixins/TimezonesMixin'

export default {
  name: 'GeneralSettings',

  components: {
    FaviconUploader,
    SettingsPageCard
  },

  mixins: [TimezonesMixin],

  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    projectName: {
      get () {
        return this.project.name
      },
      set (val) {
        this.project.name = val
      }
    },
    subdomain: {
      get () {
        return this.project.subdomain
      },
      set (val) {
        this.project.subdomain = _.kebabCase(val)
      }
    },
    timezoneOptions () {
      return this.timezones.map(tz => {
        const name = tz.replace(/.+\/([^/_]+(?:_[^/_]+)*)$/, (match, p1) => {
          return p1.replace(/_/g, ' ')
        })

        return {
          value: tz,
          name: name
        }
      })
    }
  },

  methods: {
    onSubmit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          VEvent.fire('loader', true)
          this.$store.dispatch('projects/save', {}, { root: true })
            .then(() => {
              this.$swal({
                icon: 'success',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: 'Success',
                showConfirmButton: false,
                timer: 3000
              })
            })
            .catch(errors => {
              const arrErrors = Object.keys(errors.response.data.errors),
                errorMsg = arrErrors.length ? errors.response.data.errors[Object.keys(errors.response.data.errors)[0]] : errors.response.data.message

              this.$swal({
                icon: 'error',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: errorMsg,
                showConfirmButton: false,
                timer: 3000
              })
            })
            .finally(() => {
              VEvent.fire('loader', false)
            })
        }
      })
    }
  }
}
</script>
