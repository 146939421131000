<template>
  <div class="position-relative h-100 custom-menu overflow-hidden">
    <b-card-header header-class="editor-toolbar-card-header">
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="editor-toolbar-title mb-0">
          Add Element
        </h5>

        <svg
          class="svg-icon-hover"
          width="9"
          height="9"
          viewBox="0 0 9 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="$store.commit('editor/SET_PAGE', 'sections')"
        >
          <path
            d="M5.49124 4.5L8.85622 1.13466C8.94878 1.04202 8.99985 0.918426 9 0.786637C9 0.654774 8.94893 0.531035 8.85622 0.438541L8.56134 0.143717C8.46863 0.050857 8.34505 0 8.21312 0C8.08142 0 7.95783 0.050857 7.86512 0.143717L4.50015 3.50884L1.13502 0.143717C1.04246 0.050857 0.918805 0 0.786951 0C0.655244 0 0.531585 0.050857 0.439024 0.143717L0.144 0.438541C-0.048 0.630553 -0.048 0.942866 0.144 1.13466L3.50905 4.5L0.144 7.86519C0.0513658 7.95798 0.000365854 8.08157 0.000365854 8.21336C0.000365854 8.34515 0.0513658 8.46875 0.144 8.56146L0.438951 8.85628C0.531512 8.94907 0.655244 9 0.786878 9C0.918732 9 1.04239 8.94907 1.13495 8.85628L4.50007 5.49109L7.86505 8.85628C7.95776 8.94907 8.08134 9 8.21305 9H8.21319C8.34498 9 8.46856 8.94907 8.56127 8.85628L8.85615 8.56146C8.94871 8.46882 8.99978 8.34515 8.99978 8.21336C8.99978 8.08157 8.94871 7.95798 8.85615 7.86527L5.49124 4.5Z"
            fill="#1D1D1D"
          />
        </svg>
      </div>
    </b-card-header>

    <div class="flex flex-column relative max-h-full h-[calc(100vh-175px)]">
      <div class="overflow-auto">
        <elements-drag-items />
      </div>
    </div>
  </div>
</template>

<script>
import ElementsDragItems from '../components/ElementsDragItems'

export default {
  name: 'AddElement',
  components: {ElementsDragItems}
}
</script>
