<template>
  <div>
    <h5 class="title font-weight-sami-bold mb-3">
      Fav Icon
    </h5>
    <div class="w-[370px]">
      <image-upload
        :image="image"
        class="w-100 mb-3"
        @uploaded="uploaded"
        @imageDeleted="imageDeleted"
      />
      <small class="text-muted">Upload a 32 x 32 pixel <br> ICO, PNG, GIF, or JPG</small>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ImageUpload from '../utils/ImageUpload'

export default {
  name: 'FaviconUploader',
  components: {ImageUpload},

  data () {
    return {
      image: {
        src: ''
      }
    }
  },

  computed: {
    ...mapState({
      globalStyles: state => state.editor.globalStyles
    }),
    favicon: {
      get () {
        return this.globalStyles.favicon || ''
      },
      set (val) {
        this.globalStyles.favicon = val
      }
    }
  },

  mounted () {
    this.image.src = this.favicon
  },

  methods: {
    uploaded (image) {
      VEvent.fire('loader', true)
      this.favicon = image.src
      this.$store.dispatch('projects/save')
        .finally(() => {
          VEvent.fire('loader', false)
        })
    },
    imageDeleted () {
      this.uploaded({src: ''})
    }
  }
}
</script>