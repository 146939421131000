<template>
  <div class="control-box text-color position-relative flex flex-column">
    <color-picker
      :color="value"
      class="position-relative h-[23px]"
      @input="event => $emit('input', `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`)"
    >
      <div
        class="text-[23px] font-[600] text-[#000]"
      >
        A
      </div>
    </color-picker>
    <div
      :style="{backgroundColor: color || value}"
      class="h-[3px] w-[18px]"
    />
  </div>
</template>

<script>
import ColorPicker from '../utils/ColorPicker'

export default {
  name: 'TextColor',
  components: {ColorPicker},
  props: {
    color: {
      type: String,
      default: null
    },
    value: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
  .text-color {
    input {
      height: 0;
      padding: 0;
      margin: 0;
      opacity: 0;
    }
  }

  .reflector-line {
    position: absolute;
    top: 28px;
    left: 7px;
    height: 4px;
    background: #000;
    width: 24px;
    z-index: 2;
  }
</style>
