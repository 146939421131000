<template>
  <div class="flex flex-col gap-[.625rem]">
    <iframe
      src="https://player.vimeo.com/video/877305972?badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      title="ABS to ESTAGE Onboarding"
      class="aspect-video w-full rounded-[0.25rem] bg-[#D9D9D9]"
    />

    <div class="flex flex-col w-full gap-[.25rem] mt-[1.25rem]">
      <strong class="text-center text-[1.6875rem] text-[#000000] leading-[2rem] font-[800] font-[ProximaNova] mb-[0]">
        IMPORTANT!
      </strong>

      <p class="text-center text-[1rem] text-[#464646] leading-[1.25rem] font-[400] font-[ProximaNova] mb-[0]">
        Here are your account login details:
      </p>
    </div>

    <div class="success-control mt-[1.625rem]">
      <span class="flex items-center w-[116px] h-full px-[1rem] border-r border-[#D0D5DD] text-[#464646]">
        Email:
      </span>
      <span class="flex items-center h-full px-[1rem] text-[#000000] font-[600]">
        {{ form.email }}
      </span>
    </div>

    <div class="success-control">
      <span class="flex items-center w-[116px] h-full px-[1rem] border-r border-[#D0D5DD] text-[#464646]">
        Password:
      </span>
      <span class="flex items-center h-full px-[1rem] text-[#000000] font-[600]">
        {{ form.password }}
      </span>
    </div>

    <p class="text-center text-[.875rem] text-[#8A8A8A] leading-[1.125rem] font-[400] font-[ProximaNova] mt-[1rem] mb-[0]">
      (Please save this as you will need it to login to your ESTAGE platform)
    </p>
  </div>
</template>

<script>
export default {
  name: 'SuccessStep',
  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.welcome-step-form {
  .success-control {
    display: flex;
    align-items: stretch;
    width: 100%;
    font-size: 1rem;
    line-height: 1;
    font-weight: 400;
    font-family: ProximaNova;
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: .25rem;
    height: 2.5rem;
    box-shadow: 0 1px 2px 0 rgb(16 24 40 / 0.05);
  }
}
</style>