<template>
  <b-modal
    v-model="modal"
    dialog-class="!max-w-[800px]"
    content-class="rounded-[10px]"
    body-class="!px-[64px] !py-[48px]"
    hide-header
    hide-footer
    centered
    lazy
  >
    <i
      class="fa fa-close z-[50] absolute top-[15px] right-[17px] text-[16px] text-[#6E747A] transition cursor-pinter hover:opacity-60"
      @click="modal = false"
    />

    <div class="w-full flex flex-col items-center gap-y-[32px] font-[ProximaNova]">
      <div class="max-w-[600px] w-full flex flex-col items-center gap-[16px]">
        <h3 class="text-[32px] leading-[36px] font-[700] text-[#000000] mb-0">
          Change Billing Date
        </h3>

        <p class="text-[16px] leading-[24px] font-[400] text-[#9D9D9D] mb-0">
          Important: You are allowed to change your billing date once per year
        </p>
      </div>

      <div
        v-if="!nextChangeBilling"
        class="w-full flex flex-row items-center justify-center px-[40px] py-[32px] gap-x-[12px] rounded-[10px] border border-[#E3E5EB]"
      >
        <p class="text-[16px] leading-[20px] font-[400] text-[#A9A9A9] mb-0">
          Your next billing date is
          <span class="font-[600] text-[#000000]">
            {{ moment(billingDate).format('MMM DD, YYYY') }}
          </span>
        </p>
      </div>

      <b-form class="relative w-full flex flex-row items-center justify-between px-[40px] py-[32px] gap-x-[12px] rounded-[10px] border border-[#E3E5EB]">
        <template v-if="!nextChangeBilling">
          <div class="absolute top-[-20px] left-[30px] p-[10px] bg-[#FFFFFF]">
            <p class="text-[16px] leading-[20px] font-[600] text-[#000000] mb-0">
              Select New Billing Date:
            </p>
          </div>

          <b-form-group
            label="Day"
            class="w-full"
            label-class="!text-[11px] !leading-[12px] !font-[400] !text-[#000000]"
          >
            <date-picker
              v-model="newBillingDate"
              v-validate="'required'"
              :clearable="false"
              :disabled-date="disabledDates"
              class="w-100"
              format="YYYY-MM-DD"
              input-class="form-control"
              type="date"
              value-type="format"
            />
          </b-form-group>

          <b-btn
            variant="primary"
            size="md"
            class="min-w-[160px] h-[40px] !font-[700]"
            :disabled="validate || loading"
            @click="submit"
          >
            Confirm Date
          </b-btn>
        </template>

        <template v-else-if="nextChangeBilling || success">
          <div class="w-full flex flex-row items-center justify-center gap-x-[12px]">
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="text-[16px]"
            >
              <g clip-path="url(#clip0_13154_669)">
                <path
                  d="M5.93749 9.49992L8.31249 11.8749L13.0625 7.12492M17.4167 9.49992C17.4167 13.8722 13.8722 17.4166 9.49999 17.4166C5.12774 17.4166 1.58333 13.8722 1.58333 9.49992C1.58333 5.12766 5.12774 1.58325 9.49999 1.58325C13.8722 1.58325 17.4167 5.12766 17.4167 9.49992Z"
                  stroke="#4F83E3"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_13154_669">
                  <rect
                    width="19"
                    height="19"
                    fill="white"
                  />
                </clipPath>
              </defs>
            </svg>

            <p class="text-[16px] leading-[20px] font-[400] text-[#A9A9A9] mb-0">
              Your next billing date change can be done:
              <span class="font-[600] text-[#000000]">
                {{ moment(billingDate).format('MMM DD, YYYY') }}
              </span>
            </p>
          </div>
        </template>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'
import {VueMaskDirective} from 'v-mask'
import DatePicker from 'vue2-datepicker'
import {mapGetters} from 'vuex'
export default {
  name: 'ChangeBillingDateModal',
  directives: {
    mask: VueMaskDirective
  },
  components: {
    DatePicker
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    billingDate: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      success: false,
      day: null,
      month: null,
      year: null,
      newBillingDate: null
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user'
    }),
    nextChangeBilling () {
      return this.user.next_change_billing !== null
    },
    modal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    validate () {
      return this.$validator.errors.any() || this.billingDate === this.newBillingDate
    }
  },
  methods: {
    disabledDates (date) {
      const today = moment().startOf('day')
      const maxDate = moment().add(30, 'days').endOf('day')
      return moment(date).isBefore(today) || moment(date).isAfter(maxDate)
    },
    submit () {
      this.loading = true
      let valid = false
      this.$validator.validateAll()
        .then((v) => {
          valid = v
          if (valid) {
            axios.post('/api/user/payment-systems/change-billing', {
              date: this.newBillingDate
            })
              .then(() => {
                this.loading = false
                this.success = true
                window.location.reload()
                this.$swal({
                  icon: 'success',
                  iconColor: '#4F83E3',
                  toast: true,
                  position: 'top-right',
                  title: 'Success',
                  text: 'Your account is on hold',
                  showConfirmButton: false,
                  timer: 5000
                })
              })
              .catch(() => {
                this.loading = false
                this.$swal({
                  icon: 'error',
                  iconColor: '#4F83E3',
                  toast: true,
                  position: 'top-right',
                  title: 'Error',
                  text: 'The something went wrong',
                  showConfirmButton: false,
                  timer: 5000
                })
              })
          }
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    }
  },
  mounted () {
    this.newBillingDate = this.moment().format('YYYY-MM-DD')
  }
}
</script>