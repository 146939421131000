import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import storeRoot from './../store'
import Cookie from 'js-cookie'

Vue.use(VueRouter)

const router = new VueRouter({ mode: 'history', routes })

const getAuthToken = () => {
  return process.env.NODE_ENV === 'development'
    ? localStorage.getItem('sanctum_token')
    : Cookie.get('cross_estage_token')
}

router.beforeEach((to, from, next) => {
  const isAuthenticated = storeRoot.getters['auth/authenticated']
  const authToken = getAuthToken()

  if (isAuthenticated && authToken) {
    return next()
  }

  if (to.query.auth_token) {
    localStorage.setItem('sanctum_token', to.query.auth_token)
    Cookie.set('cross_estage_token', to.query.auth_token)
    return window.location.assign('/')
  }

  if (!['/signup', '/abs-welcome'].includes(to.path)) {
    if (process.env.NODE_ENV !== 'development') {
      window.location.href = `${process.env.VUE_APP_AUTH_HOST}/login`
    }
  }

  next()
})

export default router