<template>
  <div>
    <b-form-group>
      <div class="flex items-center gap-[13px] mb-[9px]">
        <color-picker
          :color="color"
          @input="event => color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />

        <label class="mb-0 pb-0">Color</label>
      </div>
    </b-form-group>
    
    <b-form-group
      label="Height"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="height"
            :interval="1"
            :max="10"
            :min="1"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="height"
            :max="10"
            :min="1"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="enableIndicator">
        Show Indicator
      </b-checkbox>
    </b-form-group>
  </div>
</template>>

<script>
import {mapState} from 'vuex'
import ColorPicker from '@/components/editor/utils/ColorPicker.vue'

export default {
  name: 'LoadingIndicator',
  components: {ColorPicker},
  data () {
    return {
      defaultColor: '#1ABC9C',
      defaultHeight: 3,
      defaultShow: true
    }
  },
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    color: {
      get () {
        return this.loadingIndicator.color
      },
      set (val) {
        this.setValue('color', val)
      }
    },
    height: {
      get () {
        return this.loadingIndicator.height
      },
      set (val) {
        this.setValue('height', val)
      }
    },
    enableIndicator: {
      get () {
        return this.loadingIndicator.show
      },
      set (val) {
        this.setValue('show', val)
      }
    },
    loadingIndicator () {
      if (this.globalStyles.loadingIndicator === undefined || this.globalStyles.loadingIndicator.length === 0) {
        this.$set(this.globalStyles, 'loadingIndicator', {
          color: this.defaultColor,
          height: this.defaultHeight,
          show: this.defaultShow
        })
      }
      
      return this.globalStyles.loadingIndicator
    }
  },
  methods: {
    setValue (key, val) {
      this.loadingIndicator[key] = val
    },
    reset () {
      this.$set(this.loadingIndicator, 'background', this.defaultColor)
    }
  }
}
</script>