import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Navbar'
export const group = 'navbar'
export const defaultOptions = {
  'customize': {
    'style': {
      'navbarScroller': {
        'type': 'navbar-scroller-customs',
        'scrollType': null
      }
    },
    'display': true
  },
  'content': {
    'logo': {
      'type': 'logo-mod',
      'display': true,
      'fontFamily': null,
      'fontWeight': 'bold',
      'fontSize': '24px',
      'color': '#0C226C',
      'text': 'AppStarter'
    },
    'menuItems': {
      'type': 'menu-items-mod-v2',
      'display': true,
      'bringToFront': {
        'lg': true
      }
    },
    'subject': {
      'uid': 'WKtPEOYr2',
      'sid': 'lrg8hk4l--xxpupEu_Sz',
      'name': 'Flex Wrapper',
      'hideFromSections': false,
      'component': 'ElFlexWrapperV2',
      'group': 'navbar',
      'layout': null,
      'specialCols': {
        'navigation': {
          'special': true,
          'sid': 'lrg8hk4l--E_cvtC0yrK',
          'placement': 'bottom',
          'hamburgerIcon_open': 'fa fa-bars',
          'hamburgerIcon_close': 'fa fa-times',
          'hamburgerIcon_color': '#656565',
          'showOn': 'lg',
          'name': 'Nav Column',
          'elements': [
            {
              'display': true,
              'name': 'Flex Wrapper',
              'hideFromSections': false,
              'component': 'ElFlexWrapperV2',
              'group': '',
              'layout': null,
              'options': {
                'content': {
                  'display': true,
                  'cols': [
                    {
                      'display': true,
                      'name': 'Flex Column',
                      'elements': [
                        {
                          'uid': 'BlbxyqYc0',
                          'display': true,
                          'name': 'Menu Links',
                          'sid': 'lrg8hk4l--HiRT8m7dru',
                          'hideFromSections': false,
                          'component': 'ElMenuLinksV4',
                          'group': 'Navigation',
                          'layout': null,
                          'options': {
                            'content': {
                              'menu': {
                                'display': true,
                                'type': 'menu-links-mod-v4',
                                'links': [
                                  {
                                    'id': '2lgcfYKlUS',
                                    'href': '#',
                                    'text': 'About',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'kE_0CEF0hZ',
                                    'href': '#',
                                    'text': 'Work with Me',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'wJGQ-hWnL1',
                                    'href': '#',
                                    'text': 'Resources',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'A_dvOV_wIV',
                                    'href': '#',
                                    'text': 'Blog',
                                    'customLink': false,
                                    'blank': false,
                                    'itemType': '',
                                    'children': []
                                  },
                                  {
                                    'id': 'QlHAT-G7Lw',
                                    'href': '#',
                                    'text': 'Contact',
                                    'customLink': false,
                                    'blank': false,
                                    'children': []
                                  }
                                ],
                                'hoverAnimation': 'hover-float'
                              }
                            }
                          },
                          'editor': 'project',
                          'temp_id': 'KL8FXUhHuN'
                        }
                      ],
                      'sortIndex': {
                        'lg': 0,
                        'md': 0,
                        'sm': 0
                      },
                      'uid': 'OPRhpcZXzP',
                      'sid': 'lrg8hk4l--LRlniyGTUq',
                      'highlight': false,
                      'animations': {
                        'animation': null,
                        'once': true,
                        'offset': 200,
                        'delay': 50
                      },
                      '_uid': 'c35jzK_Y0n'
                    },
                    {
                      'display': true,
                      'name': 'Flex Column',
                      'elements': [
                        {
                          'uid': 'oCKQ7xxGP',
                          'display': true,
                          'name': 'Button',
                          'sid': 'lrg8hk4l--smqG476Rfc',
                          'hideFromSections': false,
                          'component': 'ElButtonV2',
                          'group': 'Media',
                          'layout': null,
                          'options': {
                            'content': {
                              'button': {
                                'pressetID': 0,
                                'display': true,
                                'type': 'button-mod-v2',
                                'text': 'Sign Up Now',
                                'buttonType': 'button',
                                'icon': {
                                  'lottie': {
                                    'jsonPath': '',
                                    'loop': true,
                                    'autoplay': true
                                  }
                                },
                                'hoverAnimation': 'hover-float',
                                'animations': {
                                  'animation': null,
                                  'once': true,
                                  'offset': 200,
                                  'delay': 50
                                }
                              }
                            },
                            'customize': null
                          },
                          'editor': 'project',
                          'temp_id': '-BOgEagQ0x'
                        }
                      ],
                      'sortIndex': {
                        'lg': 1,
                        'md': 1,
                        'sm': 1
                      },
                      'uid': 'LdsX0g3Zut',
                      'sid': 'lrg8hk4l--NupXHUZcHS',
                      'highlight': false,
                      'animations': {
                        'animation': null,
                        'once': true,
                        'offset': 200,
                        'delay': 50
                      },
                      '_uid': 'Qq2XjAw8Tn'
                    }
                  ],
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              },
              'uid': '68Twket09',
              'sid': 'lrg8hk4l--DmrplUtN2Z',
              'temp_id': 'kb5eyU6NYu'
            }
          ],
          'highlight': false,
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          },
          'sortIndex': {
            'lg': 1,
            'md': 1,
            'sm': 1
          },
          'uid': 'Voh0Ln6gZ',
          'navbarBackground': {
            'background': '',
            'gradient': {
              'position': '',
              'color': ''
            }
          },
          '_uid': 'luVDHMdU-T'
        }
      },
      'options': {
        'content': {
          'display': true,
          'cols': [
            {
              'display': true,
              'name': 'Flex Column',
              'elements': [
                {
                  'uid': 'poiAbsZn-',
                  'display': true,
                  'name': 'Headline',
                  'sid': 'rg8hk4l--_ooy3y_OM',
                  'hideFromSections': false,
                  'component': 'ElHeadlineV2',
                  'group': 'Text',
                  'ignoreGroup': false,
                  'layout': null,
                  'options': {
                    'content': {
                      'description': {
                        'type': 'tiptap-mod-v2',
                        'display': true,
                        'tag': 'h3',
                        'resolutionStyle': {
                          'sm': {},
                          'md': {},
                          'lg': {}
                        },
                        'text': '<p>Courselogo</p>',
                        'animations': {
                          'animation': null,
                          'once': true,
                          'offset': 200,
                          'delay': 50
                        }
                      }
                    }
                  },
                  'editor': 'project',
                  '_uid': 'rg8hk4l--_ooy3y_OM'
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              },
              'sortIndex': {
                'lg': 0,
                'md': 0,
                'sm': 0
              },
              'sid': 'lrg8hk4l--_a3gh5CFAh',
              'uid': 'e6KodMa149H',
              'highlight': false,
              '_uid': 'kzjkouIlG'
            },
            {
              'special': true,
              'key': 'navigation',
              'mod': 'navigation-flex-col-mod-v2',
              'name': 'Nav Column',
              'sid': 'Uz96GLEcGy',
              'sortIndex': {
                'lg': 1,
                'md': 1,
                'sm': 1
              },
              'highlight': false
            }
          ],
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          }
        }
      },
      'temp_id': '23MJlRTY6G'
    }
  }
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('NavbarV3', options)

    this.name = name
    this.group = group
    this.sid = ['global']
    this.sids = [
      'global',
      'lrg8hk4l--xxpupEu_Sz',
      'lrg8hk4l--_a3gh5CFAh',
      'rg8hk4l--_ooy3y_OM',
      'lrg8hk4l--E_cvtC0yrK',
      'lrg8hk4l--DmrplUtN2Z',
      'lrg8hk4l--LRlniyGTUq',
      'lrg8hk4l--HiRT8m7dru',
      'lrg8hk4l--NupXHUZcHS',
      'lrg8hk4l--smqG476Rfc'
    ]
    this.css = [
      [
        '.--lrg8hk4l--xxpupEu_Sz--container { padding-right: 0px; }',
        '.--lrg8hk4l--_a3gh5CFAh--flex-col-padding { padding: 0px; }',
        '.--lrg8hk4l--E_cvtC0yrK--flex-col-padding { padding: 0px; }',
        '.--lrg8hk4l--_a3gh5CFAh--flex-col-el { width: 20%; background-color: rgba(255, 255, 255, 0); }',
        '.--lrg8hk4l--E_cvtC0yrK--flex-col-el { width: 100%; --width: 100%; }',
        '.--lrg8hk4l--E_cvtC0yrK--flex-col > div { align-items: center; justify-content: center; }',
        '.--lrg8hk4l--LRlniyGTUq--flex { width: 100%; --width: 100%; }',
        '.--lrg8hk4l--LRlniyGTUq--flex-col-el { width: 70%; --width: 70%; }',
        '.--lrg8hk4l--NupXHUZcHS--flex-col-el { width: 30%; --width: 30%; }',
        '.--lrg8hk4l--DmrplUtN2Z--flex { width: 100%; --width: 100%; align-items: center; padding: 0px; }',
        '.--lrg8hk4l--xxpupEu_Sz--flex { align-items: center; max-width: 1200px; --width: 1200px; }',
        '.--lrg8hk4l--HiRT8m7dru--wrapper { padding: 10px; justify-content: center; gap: 56px; font-family: "Noto Sans KR"; }',
        '.--lrg8hk4l--smqG476Rfc--1 { margin: 20px; background-color: rgb(231, 107, 60); font-size: 13px; font-family: "Noto Sans KR"; padding: 7px 20px; }',
        '.--lrg8hk4l--HiRT8m7dru--link { font-size: 14px; color: rgb(255, 255, 255); }',
        '.--lrg8hk4l--HiRT8m7dru--link:hover { color: rgb(188, 214, 254); background-color: rgb(31, 59, 156); padding: 9px; border-radius: 5px; }',
        '.--lrg8hk4l--LRlniyGTUq--flex-col-padding { padding: 0px; }',
        '.--lrg8hk4l--NupXHUZcHS--flex-col-padding { padding: 0px; }',
        '.--rg8hk4l--_ooy3y_OM-text { padding: 20px; }',
        '.--rg8hk4l--_ooy3y_OM-text div { text-align: left; color: rgb(255, 255, 255); font-family: "Noto Sans KR"; font-size: 20px; line-height: 1.2; --line-height: 1.2; }',
        '.--lrg8hk4l--smqG476Rfc--1:hover { background-color: rgb(250, 128, 82); }'
      ],
      [
        '.--lrg8hk4l--LRlniyGTUq--flex { width: 100%; --width: 100%; }',
        '.--lrg8hk4l--DmrplUtN2Z--flex { width: 100%; --width: 100%; flex-flow: wrap; }',
        '.--lrg8hk4l--NupXHUZcHS--flex-col-el { width: 100%; --width: 100%; }',
        '.--lrg8hk4l--HiRT8m7dru--wrapper { flex-direction: column; justify-content: end; align-items: end; gap: 22px; }',
        '.--lrg8hk4l--E_cvtC0yrK--flex-col-el { background-color: rgb(0, 22, 85); width: 100%; --width: 100%; }',
        '.--lrg8hk4l--E_cvtC0yrK--flex-col-padding { padding-top: 20px; padding-bottom: 20px; }',
        '.--lrg8hk4l--xxpupEu_Sz--close-icon { color: rgb(255, 255, 255); }',
        '.--lrg8hk4l--xxpupEu_Sz--icon { font-size: 20px; translate: -28px; }',
        '.--lrg8hk4l--xxpupEu_Sz--open-icon { color: rgb(255, 255, 255); }',
        '.--lrg8hk4l--xxpupEu_Sz--flex.flex-wrapper { justify-content: start; }',
        '.--rg8hk4l--_ooy3y_OM-text div { font-size: 19px; }',
        '.--lrg8hk4l--_a3gh5CFAh--flex-col-el { width: 100%; --width: 100%; }',
        '.--rg8hk4l--_ooy3y_OM-text { padding-left: 30px; }',
        '.--lrg8hk4l--E_cvtC0yrK--flex-col { text-align: center; justify-content: center; }',
        '.--lrg8hk4l--E_cvtC0yrK--flex-col > div { display: inherit; --display: inherit; }',
        '.--lrg8hk4l--LRlniyGTUq--flex-col-el { width: 100%; --width: 100%; }',
        '.--lrg8hk4l--LRlniyGTUq--flex-col-padding { padding-right: 20px; }',
        '.--lrg8hk4l--smqG476Rfc--1 .main-text { justify-content: center; }',
        '.--lrg8hk4l--smqG476Rfc--2 { justify-content: end; }',
        '.--lrg8hk4l--smqG476Rfc--1 { margin-right: 40px; padding-top: 12px; padding-bottom: 12px; }'
      ],
      [
        '.--lrg8hk4l--LRlniyGTUq--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lrg8hk4l--DmrplUtN2Z--flex { width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--lrg8hk4l--_a3gh5CFAh--flex-col-el { width: 50%; --width: 50%; }',
        '.--lrg8hk4l--NupXHUZcHS--flex-col-el { width: 100%; --width: 100%; }',
        '.--lrg8hk4l--HiRT8m7dru--wrapper { gap: 29px; flex-direction: column; justify-content: center; align-items: center; }',
        '.--lrg8hk4l--E_cvtC0yrK--flex-col-padding { padding-top: 20px; padding-bottom: 20px; }',
        '.--lrg8hk4l--smqG476Rfc--1 { width: 100%; padding-bottom: 17px; padding-top: 17px; margin-left: 50px; margin-right: 50px; }',
        '.--lrg8hk4l--E_cvtC0yrK--flex-col-el { width: 100%; --width: 100%; }',
        '.--lrg8hk4l--_a3gh5CFAh--flex-col-padding { padding-left: 0px; }',
        '.--rg8hk4l--_ooy3y_OM-text { padding-left: 15px; padding-top: 16px; }',
        '.--lrg8hk4l--LRlniyGTUq--flex-col-padding { padding-right: 0px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
