const state = () => ({
  promoBanners: []
})

const getters = {
  topBanners (state) {
    return state.promoBanners.filter(banner => {
      return banner.section.options.content.promo.dockTo.lg === 'top'
    })
  },
  bottomBanners (state) {
    return state.promoBanners.filter(banner => {
      return banner.section.options.content.promo.dockTo.lg === 'bottom'
    })
  }
}

const actions = {
  remove ({state}, payload) {
    const index = state.promoBanners.findIndex(banner => banner.section.uid === payload.uid)

    if (index !== -1) {
      state.promoBanners.splice(index, 1)
    }
  }
}

const mutations = {
  ADD_PROMO_BAR (state, banner) {
    if (state.promoBanners.findIndex(_banner => _banner.section.uid === banner.section.uid) === -1) state.promoBanners.push(banner)
  },
  CLEAR (state) {
    state.promoBanners = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
