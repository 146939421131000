<template>
  <div class="pt-[15px]">
    <b-form-group>
      <b-checkbox v-model="config.autoscroll">
        Auto scroll on open
      </b-checkbox>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="config.activeShadow">
        Active shadow
      </b-checkbox>
    </b-form-group>

    <b-form-group :label="`Columns per row (${resolutioner})`">
      <v-select
        v-model="perRow"
        :options="perRowOptions"
        class="custom-select"
      />
    </b-form-group>

    <b-form-group>
      <div class="flex items-center gap-[13px]">
        <color-picker
          :color="config.activeBorderColor"
          @input="event => config.activeBorderColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />

        <label class="mb-0 pb-0">Active border color</label>
      </div>
    </b-form-group>

    <div class="flex flex-column gap-[12px] mb-[12px]">
      <div
        v-for="(item, index) in config.items"
        :key="index"
        class="!border-[#E2E5EC] group rounded-[2px] h-[38px] flex items-center justify-between pl-[13px] hover:bg-[#F1F5FD] cursor-pinter"
        style="border:1px solid;"
        @click.stop="config.active = index"
      >
        <span class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]">Item {{
          index + 1
        }}</span>

        <button
          v-b-tooltip
          class="w-[38px] h-[36px] text-center border-left bg-[#fff] p-0"
          title="Delete column"
          @click.stop="config.items.splice(index, 1)"
        >
          <svg
            fill="none"
            height="10"
            viewBox="0 0 10 10"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
              fill="#6E747A"
            />
          </svg>
        </button>
      </div>
    </div>

    <button
      class="flex items-center border rounded-[3px] font-[600] text-[#44474A] text-[14px] h-[38px] px-[17px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
      @click="addCollapse"
    >
      <span class="text-[#3D82EA] text-[22px] mr-[9px]">+</span>
      Add New Item
    </button>

    <SidbearSettingsGroup
      class="border-top"
      title="Padding & Margins"
    >
      <padding-full-customs
        :config="padding"
        :default-value="[20,20,20,20]"
      />
      <margin-full-customs :config="margin" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ResolutionMixin from '../../mixins/ResolutionMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'

export default {
  name: 'CollapseMod',
  components: {SidbearSettingsGroup},
  mixins: [ModMixin, ResolutionMixin],
  data () {
    return {
      perRowOptions: [1, 2, 3, 4, 5]
    }
  },
  computed: {
    perRow: {
      get () {
        return 12 / this.config.cols[this.resolutioner]
      },
      set (val) {
        this.config.cols[this.resolutioner] = 12 / val
      }
    },
    padding () {
      if (!this.config.padding) {
        this.$set(this.config, 'padding', {})
      }

      return this.config.padding
    },
    margin () {
      if (!this.config.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.margin
    }
  },
  methods: {
    addCollapse () {
      this.config.items.push({
        trigger: [],
        content: []
      })
    }
  }
}
</script>

<style scoped>
.editor-toolbar-collapse-header.active {
  background-color: #FAFCFF;
  color: #4F83E3;
}

.hover-text-red:hover {
  color: red !important;
}
</style>
