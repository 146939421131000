<template>
  <div v-if="blog.hasOwnProperty('data') && settings.widget_sidebar.display_latest_posts">
    <h6 class="title">
      Latest Posts
    </h6>

    <ul class="list-unstyled mb-0 p-0">
      <li
        v-for="latest in blog.data.slice(0, 6)"
        :key="latest.id"
        class="category cursor-pinter truncate"
        @click="$router.push({name: 'blog.post', params: {slug: latest.slug}})"
      >
        <b-link v-text="latest.title" />
      </li>
    </ul>
  </div>
</template>

<script>
import BlogCategoriesMixin from '../../mixins/BlogCategoriesMixin'
import BlogPostsMixin from '../../mixins/BlogPostsMixin'

export default {
  name: 'LatestPosts',
  mixins: [BlogCategoriesMixin, BlogPostsMixin]
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 21px;
}

.category {
  font-size: 15px;
  color: #6E747A;
  padding: 13px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #E3E3E3;
  }

  &.active {
    font-weight: 600;
    color: #4F83E3;
  }

  &:hover {
    color: #4F83E3;
  }
}
</style>
