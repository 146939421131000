<template>
  <b-form-group :label="label">
    <b-button-group>
      <b-btn
        v-for="(option, index) in options.filter(item => !exlude.includes(item.value))"
        :key="index"
        :variant="model === option.value ? 'primary' : 'white'"
        class="!h-[38px]"
        @click="model = option.value"
      >
        <i :class="option.text" />
      </b-btn>
    </b-button-group>
  </b-form-group>
</template>

<script>
import ResolutionMixin from '../../mixins/ResolutionMixin'
import _ from 'lodash'

export default {
  name: 'AlignmentItemsControl',
  mixins: [ResolutionMixin],
  props: {
    config: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: 'Alignment'
    },
    mutationKey: {
      type: String,
      default: 'align'
    },
    exlude: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      options: [
        {
          text: 'fa fa-align-left',
          value: 'start'
        },
        {
          text: 'fa fa-align-center',
          value: 'center'
        },
        {
          text: 'fa fa-align-right',
          value: 'end'
        },
        {
          text: 'fa fa-align-justify',
          value: 'between'
        }
      ]
    }
  },
  computed: {
    modelObject () {
      return this.config[this.mutationKey] || {
        sm: null,
        md: null,
        lg: null
      }
    },
    model: {
      get () {
        return _.get(this.config, [this.mutationKey, this.resolutioner], 'start')
      },
      set (val) {
        this.$set(this.config, this.mutationKey, {
          ...this.modelObject,
          [this.resolutioner]: val
        })
      }
    }
  }
}
</script>

<style scoped>

</style>