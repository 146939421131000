<template>
  <div>
    <SidbearSettingsGroup
      active
      title="List"
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <template v-if="Array.isArray(config.items)">
        <draggable
          v-model="config.items"
          handle=".move"
        >
          <div
            v-for="(item, index) in config.items"
            :key="index"
            class="!border-[#E2E5EC] group rounded-[2px] h-[29px] flex items-center pl-[13px] hover:bg-[#F1F5FD] cursor-pinter mb-[12px]"
            style="border:1px solid;"
          >
            <div class="mr-[11px] move cursor-move">
              <svg
                fill="none"
                height="11"
                viewBox="0 0 11 11"
                width="11"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.57745 0.894104H0.568359V3.8941H3.57745V0.894104Z"
                  fill="#3D82EA"
                />
                <path
                  d="M10.5984 0.894104H7.58936V3.8941H10.5984V0.894104Z"
                  fill="#3D82EA"
                />
                <path
                  d="M3.57745 7.8941H0.568359V10.8941H3.57745V7.8941Z"
                  fill="#3D82EA"
                />
                <path
                  d="M10.5984 7.8941H7.58936V10.8941H10.5984V7.8941Z"
                  fill="#3D82EA"
                />
              </svg>
            </div>

            <span
              class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]"
            >
              {{ item.label }}
            </span>

            <button
              v-b-tooltip
              class="w-[38px] h-[29px] text-center border-right-0 border bg-[#fff] p-0 ml-auto"
              title="Delete column"
              @click.stop="config.items.splice(index, 1)"
            >
              <svg
                fill="none"
                height="10"
                viewBox="0 0 10 10"
                width="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
                  fill="#6E747A"
                />
              </svg>
            </button>
          </div>
        </draggable>

        <button
          class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
          @click="addListItem"
        >
          Add List Item
          <span class="text-[#3D82EA] text-[20px]">+</span>
        </button>
      </template>

      <b-form-group>
        <icon-select
          v-model="config.icon"
          class="mb-2"
        />

        <div class="flex items-center justify-end">
          <sid-color
            :sid="`.--${section.sid}--list .list-icon`"
            rule-name="color"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Icon Position</label>

          <sid-buttons-group
            class="mb-0"
            label=""
            :options="[
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'start',
                tooltip: 'Start'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'center',
                tooltip: 'Center'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'end',
                tooltip: 'End'
              }
            ]"
            :sid="`.--${section.sid}--list li`"
            rule-name="align-items"
          />
        </div>
      </b-form-group>

      <sid-range-slider
        label="Icon Size"
        :sid="`.--${section.sid}--list .list-icon`"
        rule-name="font-size"
      />

      <sid-range-slider
        label="Vertical Gap"
        :sid="`.--${section.sid}--list .list`"
        rule-name="row-gap"
      />

      <sid-range-slider
        label="Horizontal Gap"
        :sid="`.--${section.sid}--list .list li`"
        rule-name="column-gap"
      />

      <sid-align
        :sid="`.--${section.sid}--list .list`"
        rule-name="align-items"
        label="Alignment"
      />

      <sid-background :sid="`.--${section.sid}--list`" />

      <hr>

      <sid-lottie-icon
        :config="config"
        :sid="section.sid"
      />
    </SidbearSettingsGroup>

    <tiptap-mod-v2
      v-if="selectedSubject"
      :config="selectedSubject.options.content.description"
      :local-section="selectedSubject"
      :hide="['animations', 'align']"
      group-prefix="Text"
      :show-active-group="false"
      component-name="Text"
    />

    <SidbearSettingsGroup title="Padding & Margins">
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--list`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--list`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--list`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--list`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--list`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid}--list`"
        rule-name="border"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import draggable from 'vuedraggable'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import IconSelect from '@/components/editor/components/IconSelect'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidLottieIcon from '@/components/editor/components/sid-controls/sid-control-pressets/SidLottieIcon'
import SidStyles from '@/components/mixins/SidStyles'

export default {
  name: 'ListMod',
  components: {
    SidLottieIcon,
    SidSelect,
    SidLinkedGroup,
    SidAlign,
    SidColor, SidBackground, SidButtonsGroup, SidRangeSlider, IconSelect, SidbearSettingsGroup, draggable},
  mixins: [ModMixin, SidStyles],
  data () {
    return {
      newListItemModal: false,
      item: '',
      label: '',
      listIndex: null
    }
  },
  computed: {
    selectedSubject () {
      let subject = this.config.items[this.section.selectedSubjectIndex || 0]

      if (subject) {
        return subject.subject
      }

      return false
    }
  },
  methods: {
    addListItem () {
      const lastSubjectSid = this.config.items.length ? this.config.items[this.config.items.length - 1].subject.sid : 'cea967j--KY4mVUy1-B'
      const subject = {'uid':'Aix0I-8NC','display':true,'name':'Paragraph','css':[[`.--${lastSubjectSid}-text div { text-align: left; font-size: 16px; }`],[],[]],'sid': lastSubjectSid,'sids':[lastSubjectSid],'hideFromSections':false,'component':'ElParagraphV2','group':'Text','layout':null,'options':{'content':{'description':{'type':'tiptap-mod-v2','display':true,'resolutionStyle':{'sm':{},'md':{},'lg':{}},'text':'<p>Cras faucibus purus tincidunt tincidunt mollis.</p>','animations':{'animation':null,'once':true,'offset':200,'delay':50}}},'customize':null},'editor':'project','temp_id':'Wolqva4u8v'}

      if (this.listIndex) {
        this.config.items[this.listIndex].push({
          label: `Item ${this.config.items.length + 1}`,
          subject
        })
      } else {
        this.config.items.push({
          label: `Item ${this.config.items.length + 1}`,
          subject
        })
      }

      this.label = ''
      this.item = ''
      this.newListItemModal = false
    }
  }
}
</script>
