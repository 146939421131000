<template>
  <div>
    <motions-effects-select
      v-model="typeMotions"
      :options="options"
    />

    <div
      v-for="motion in typeMotions"
      :key="motion.effect"
    >
      <div>
        <div
          class="border flex items-center rounded justify-between px-[10px] text-[14px] h-[28px] cursor-pointer mb-[10px]"
          @click="() => selectedEffect === motion.effect ? selectedEffect = false : selectedEffect = motion.effect"
        >
          <span
            class="text-[#686e74]"
            v-text="motionData[motion.effect].label"
          />

          <div>
            <svg
              :class="{'rotate-[180deg]': selectedEffect === motion.effect }"
              data-v-559b90b9=""
              fill="none"
              height="7"
              viewBox="0 0 10 7"
              width="10"
              xmlns="http://www.w3.org/2000/svg"
              class=""
            ><path
              data-v-559b90b9=""
              d="M0.719727 1.00006L4.85989 5.10817L9.00005 1.00006"
              stroke="#6E747A"
              stroke-width="1.5"
            /></svg>
          </div>
        </div>

        <span
          class="mt-[-5px] w-[85px] block mb-3 text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
          @click="() => removeEffect(motion.effect)"
        >
          Remove effect
        </span>

        <div v-if="selectedEffect === motion.effect">
          <component
            :is="motionData[motion.effect].component"
            :motion="motion"
            :effect-template="motionData[motion.effect].effectTemplate"
            :progress="progress"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MotionsEffectsSelect from '@/components/editor/components/motions/motions-effects/MotionsEffectsSelect'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import MotionOpacity from '@/components/editor/components/motions/motions-effects/MotionOpacity'
import MotionSkew from '@/components/editor/components/motions/motions-effects/MotionSkew'
import MotionScale from '@/components/editor/components/motions/motions-effects/MotionScale'
import MotionTranslate from '@/components/editor/components/motions/motions-effects/MotionTranslate'
import MotionRotate from '@/components/editor/components/motions/motions-effects/MotionRotate'
import MotionBackgroundColor from '@/components/editor/components/motions/motions-effects/MotionBackgroundColor'
export default {
  name: 'ValueList',
  components: {
    MotionBackgroundColor, MotionsEffectsSelect, MotionOpacity,
    MotionSkew,
    MotionScale,
    MotionTranslate,
    MotionRotate},
  mixins: [ResolutionMixin],
  props: {
    progress: {
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedEffect: null
    }
  },
  computed: {
    motionData () {
      return {
        rotate: {
          label: 'Rotate',
          component: 'MotionRotate',
          effectTemplate: {
            progress: this.progress,
            value: {
              x: null,
              y: null,
              z: null
            }
          }
        },
        translate: {
          label: 'Translate',
          component: 'MotionTranslate',
          effectTemplate: {
            progress: this.progress,
            value: {
              x: null,
              y: null
            }
          }
        },
        scale: {
          label: 'Scale',
          component: 'MotionScale',
          effectTemplate: {
            progress: this.progress,
            value: {
              x: null,
              y: null
            }
          }
        },
        skew: {
          label: 'Skew',
          component: 'MotionSkew',
          effectTemplate: {
            progress: this.progress,
            value: {
              x: null,
              y: null
            }
          }
        },
        opacity: {
          label: 'Opacity',
          component: 'MotionOpacity',
          effectTemplate: {
            progress: this.progress,
            value: 0
          }
        },
        backgroundColor: {
          label: 'Background Color',
          component: 'MotionBackgroundColor',
          effectTemplate: {
            progress: this.progress,
            value: null
          }
        }
      }
    },
    typeMotions: {
      get () {
        return this.value || {}
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    removeEffect (effectName) {
      this.$delete(this.typeMotions, effectName)
    }
  }
}
</script>