import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Table of Contents'
export const group = 'Navigation'

export const defaultOptions = {
  content: {
    tableOfContents: {
      type: 'table-of-contents-mod',
      display: true,
      title:'Table Of Contents',
      toggler:true,
      anchors:['h1','h2','h3','h4','h5','h6'],
      excludes:[],
      sticky:false,
      /* number,icon,bullet */
      marker:'icon',
      iconClass:'fa fa-chevron-right'
    }
  }
}

export const icon = `
<svg  width="20"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <path
    d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zm64 0l0 64 64 0 0-64L64 96zm384 0L192 96l0 64 256 0 0-64zM64 224l0 64 64 0 0-64-64 0zm384 0l-256 0 0 64 256 0 0-64zM64 352l0 64 64 0 0-64-64 0zm384 0l-256 0 0 64 256 0 0-64z"
  />
</svg>
`

export default class ElTableOfContents extends SectionProvider {
  constructor (options = {}) {
    super('ElTableOfContents', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
