<template>
  <div>
    <SidbearSettingsGroup 
      title="Tooltip"
      :button-class="buttonClass"
    >
      <b-form-group>
        <b-checkbox v-model="section.tooltip">
          Enable
        </b-checkbox>
      </b-form-group>

      <b-form-group label="Text">
        <b-input
          v-model="config.tooltipText"
          size="sm"
          placeholder="My Tooltip"
        />
      </b-form-group>

      <b-form-group label="Placement">
        <v-select
          v-model="tooltipPlacement"
          :searchable="false"
          :reduce="val => val.value"
          :options="[{value: 'top', label: 'Top'}, {value: 'bottom', label: 'Bottom'}, {value: 'left', label: 'Left'}, {value: 'right', label: 'Right'}]"
        />
      </b-form-group>

      <sid-range-slider
        label="Text Size"
        :sid="`.--${config.sid}--tooltip`"
        rule-name="font-size"
      />
      
      <b-form-group label="Text Color">
        <sid-color
          :sid="`.--${config.sid}--tooltip`"
          rule-name="color"
        />
      </b-form-group>

      <sid-background :sid="`.--${config.sid}--tooltip`" />

      <sid-linked-group
        label="Padding"
        :sid="`.--${config.sid}--tooltip`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'

export default {
  name: 'TooltipMod',
  components: {SidRangeSlider, SidLinkedGroup, SidColor, SidBackground, SidbearSettingsGroup},
  mixins: [ModMixin],
  props: {
    buttonClass: {
      type: String,
      default: null
    }
  },
  computed: {
    tooltipPlacement: {
      get () {
        return this.config.tooltipPlacement || 'top'
      },
      set (val) {
        this.$set(this.config, 'tooltipPlacement', val)
      }
    }
  }
}
</script>