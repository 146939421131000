<template>
  <b-modal
    v-model="modal"
    hide-footer
    hide-header
    size="lg"
  >
    <icon
      class="cursor-pinter"
      icon="close.svg"
      @click.native="modal = false"
    />

    <h4 class="h2 font-weight-bold text-center my-4">
      Add Menu Item
    </h4>

    <b-row class="pb-5">
      <b-col
        cols="8"
        offset="2"
      >
        <div>
          <b-form-group label="Name">
            <b-input
              v-if="!link"
              v-model="text"
            />
            <b-input
              v-else
              v-model="link.text"
            />
          </b-form-group>

          <!--          <b-form-group label="Item Type">-->
          <!--            <v-select-->
          <!--              v-if="!link"-->
          <!--              v-model="customLink"-->
          <!--              :options="[{label: 'Page', value: false}, {label: 'Submenu Header', value: null}, {label: 'Custom Link', value: true}, {label: 'Scroll to element', value: 'scroll-to-element'}]"-->
          <!--              :reduce="val => val.value"-->
          <!--              class="custom-select"-->
          <!--            />-->
          <!--            <v-select-->
          <!--              v-else-->
          <!--              v-model="link.customLink"-->
          <!--              :options="[{label: 'Page', value: false}, {label: 'Submenu Header', value: null}, {label: 'Custom Link', value: true}, {label: 'Scroll to element', value: 'scroll-to-element'}]"-->
          <!--              :reduce="val => val.value"-->
          <!--              class="custom-select"-->
          <!--            />-->
          <!--          </b-form-group>-->


          <template v-if="customLink !== null">
            <b-form-group
              v-if="!link && !customLink"
              label="Link Type"
            >
              <pages-select-menu
                :others="[{id: 'new-page', title: 'Create New Page'}, {id: 'custom-link', title: 'Custom Link'}, {id: 'submenu-header', title: 'Submenu Header'}, {id: 'blog', title: 'ESTAGE Blog', code: project.blog_slug}]"
                input-class="rounded-[2px]"
                other-title="Other:"
                input-placeholder="Type to search pages and posts"
                title="Pages and Posts"
                add-posts
                @selectOther="item => href = item"
                @selectPage="item => href = item"
              >
                <template
                  v-if="href"
                  slot="placeholder"
                >
                  <div class="px-3">
                    {{ href.title }}
                  </div>
                </template>
              </pages-select-menu>
            </b-form-group>

            <b-form-group
              v-else-if="link && !link.customLink"
              label="Link Type"
            >
              <div class="position-relative">
                <pages-select-menu
                  :others="[{id: 'submenu-header', title: 'Submenu Header'}, {item_type: 'module', id: 'blog', title: 'ESTAGE Blog', code: project.blog_slug}]"
                  input-class="rounded-[2px]"
                  other-title="Other:"
                  @selectOther="item => link.href = item"
                  @selectPage="item => link.href = item"
                >
                  <template
                    v-if="typeof link.href === 'object' && link.href.title"
                    slot="placeholder"
                  >
                    <div class="px-3">
                      {{ link.href.title }}
                    </div>
                  </template>
                </pages-select-menu>
              </div>
            </b-form-group>
          </template>

          <b-form-group
            v-if="!link && href.id === 'custom-link'"
            label="Link"
          >
            <b-input v-model="customHref" />
          </b-form-group>

          <b-form-group
            v-else-if="link && link.customLink === true"
            label="Link"
          >
            <b-input v-model="link.customHref" />
          </b-form-group>

          <b-form-group
            v-else-if="link && link.customLink === 'scroll-to-element'"
            label="Block Element"
          >
            <select-block-page v-model="link.href" />
          </b-form-group>

          <b-form-group
            v-else-if="!link && customLink === 'scroll-to-element'"
            label="Block Element"
          >
            <select-block-page v-model="href" />
          </b-form-group>

          <b-form-group label="Icon">
            <div class="flex items-center w-full gap-[14px]">
              <div class="w-[130px]">
                <IconSelect v-model="icon" />
              </div>
              <b-checkbox v-model="iconAppend">
                <span class="block mt-[-7px]">Append Icon</span>
              </b-checkbox>
            </div>
          </b-form-group>

          <b-form-group v-if="customLink !== null">
            <b-checkbox
              v-if="!link"
              v-model="blank"
            >
              <div class="mt-[-6px]">
                Open In New Tab
              </div>
            </b-checkbox>
            <b-checkbox
              v-else
              v-model="link.blank"
            >
              <div class="mt-[-6px]">
                Open In New Tab
              </div>
            </b-checkbox>
          </b-form-group>

          <div
            class="d-flex justify-content-center mb-4"
          >
            <b-btn
              v-if="!link"
              variant="primary"
              :disabled="loading"
              class="d-flex items-center gap-[5px]"
              @click="addListItem"
            >
              <b-spinner
                v-if="loading"
                small
                variant="white"
              />

              Add Item
            </b-btn>
            <b-btn
              v-else
              variant="primary"
              @click="updateLink"
            >
              Save Link
            </b-btn>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import PagesSelectMenu from '@/components/editor/components/pagesSelectMenu/PagesSelectMenu'
import {mapGetters, mapState} from 'vuex'
import IconSelect from '@/components/editor/components/IconSelect'

export default {
  name: 'LinkModal',
  components: {IconSelect, PagesSelectMenu},
  props: {
    value: {
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      link: null,
      text: '',
      customLink: false,
      customHref: '',
      href: '',
      blank: false,
      loading: false,
      icon: '',
      iconAppend: false
    }
  },
  computed: {
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    modal: {
      get () {
        return typeof this.value === 'number' || !!this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    modal (val) {
      if (!val) {
        this.text = ''
        this.href = ''
        this.customLink = false
        this.blank = false
        this.customHref = ''
        this.link = null
        this.icon = ''
        this.iconAppend = false
      } else {
        if (typeof this.value === 'number') {
          this.link = _.cloneDeep(this.config.links[this.value])
        }
      }
    }
  },
  methods: {
    updateLink () {
      this.$set(this.config.links, [this.value], this.link)
      this.modal = false
    },
    addLink () {
      this.config.links.push({
        text: this.text,
        customLink: this.customLink,
        href: this.href,
        blank: this.blank,
        customHref: this.customHref,
        children: [],
        icon: this.icon,
        iconAppend: this.iconAppend
      })
      this.modal = false
    },
    addListItem () {
      if (this.href.id === 'new-page') {
        this.loading = true
        return this.$store.dispatch('pages/addPage', {
          title: this.text,
          meta_title: this.text,
          content: '[]'
        })
          .then((data) => {
            this.href = data
            this.addLink()
          })
          .finally(() => {
            this.loading = false
          })
      }

      this.addLink()
    }
  }
}
</script>