<template>
  <div
    :data-uid="section.uid"
    :class="`--${sid}--container`"
  >
    <highliter
      v-model="section.name"
      :dublicate="section"
      :dublicate-deep="parentUID"
      class="highlight-element w-auto"
      section-type="element"
      @delete="$emit('delete')"
      @dublicate="$emit('dublicate')"
      @favorite="$emit('favorite', $el)"
      @flow="handleFlow"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
      @click.stop.native="sectionSettings('description')"
      @highlight-click="contentClick"
    >
      <div
        :data-sid="`subheadline-${section.sid}`"
        :class="`text-container-${section.sid}`"
      >
        <content-editable-v2
          ref="content"
          v-model="section.options.content.description.text"
          :data-uid="section.uid"
          :customs="section.options.content.description"
          :section="section"
          type="headline"
        />
      </div>
    </highliter>
  </div>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditableV2 from '@builder/components/builder/utils/ContentEditableV2'

export default {
  name: 'FeaturedItems1',

  components: {ContentEditableV2, Highliter},

  mixins: [SectionMixin],

  methods: {
    contentClick () {
      this.$refs.content.initer()
    }
  }
}
</script>
