<template>
  <div
    class="flex w-full"
    @click.stop="$emit('click',folder)"
  > 
    <div class="w-[89px] border-r border-[#E2E5EC] p-[26px]">
      <img src="@/assets/icons/folder.svg">
    </div>
    <div class="grow flex justify-between items-center p-[26px]">
      <div class="flex flex-column">
        <span class="font-semibold text-[17px] leading-[17px] text-[#44474A]">{{ folder.name }}</span>
        <span
          v-if="folder.media_count"
          class="text-[15px] leading-[15px] text-[#9FA9BA] mt-[7px]"
        >
          {{ folder.media_count }} Assets
        </span>
      </div>
      <b-dropdown
        v-if="!folder.protected && !folder.is_my_saved_images"
        variant="cricle-bg"
        menu-class="!border-[#E2E5EC] !rounded-[4px] py-0"
        toggle-class="text-decoration-none"
        no-caret
        right
      > 
        <template #button-content>
          <svg
            width="24"
            height="5"  
            viewBox="0 0 24 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="2.5"
              cy="2.5"
              r="2.5"
              fill="#4F83E3"
            />
            <circle
              cx="12.0714"
              cy="2.5"
              r="2.5"
              fill="#4F83E3"
            />
            <circle
              cx="21.3572"
              cy="2.5"
              r="2.5"
              fill="#4F83E3"
            />
          </svg>
        </template>
        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click.stop="$emit('move',folder)"
        >
          <svg
            class="mr-2 mb-1"
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2738_22579)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.583374 2.91669C0.583374 1.95019 1.36688 1.16669 2.33337 1.16669H5.83337C6.02841 1.16669 6.21055 1.26416 6.31874 1.42645L7.31223 2.91669H11.6667C12.6332 2.91669 13.4167 3.70019 13.4167 4.66669V11.0834C13.4167 12.0499 12.6332 12.8334 11.6667 12.8334H2.33337C1.36688 12.8334 0.583374 12.0499 0.583374 11.0834V2.91669ZM2.33337 2.33335C2.01121 2.33335 1.75004 2.59452 1.75004 2.91669V11.0834C1.75004 11.4055 2.01121 11.6667 2.33337 11.6667H11.6667C11.9889 11.6667 12.25 11.4055 12.25 11.0834V4.66669C12.25 4.34452 11.9889 4.08335 11.6667 4.08335H7.00004C6.805 4.08335 6.62287 3.98588 6.51468 3.8236L5.52118 2.33335H2.33337Z"
                fill="#44474A"
              />
              <rect
                x="9.625"
                y="4.11249"
                width="7.875"
                height="7.525"
                fill="white"
              />
            </g>
            <g clip-path="url(#clip1_2738_22579)">
              <path
                d="M13.5661 10.0662C13.3668 10.2655 13.3668 10.5887 13.5661 10.788C13.7655 10.9873 14.0886 10.9873 14.288 10.788L16.84 8.23592C17.0394 8.03659 17.0394 7.71341 16.84 7.51408L14.288 4.962C14.0886 4.76267 13.7655 4.76267 13.5661 4.962C13.3668 5.16133 13.3668 5.48451 13.5661 5.68384L15.2469 7.36458H6.27079C5.9889 7.36458 5.76038 7.59311 5.76038 7.875C5.76038 8.1569 5.9889 8.38542 6.27079 8.38542H15.2469L13.5661 10.0662Z"
                fill="#44474A"
              />
            </g>
            <defs>
              <clipPath id="clip0_2738_22579">
                <rect
                  width="14"
                  height="14"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip1_2738_22579">
                <rect
                  width="12.25"
                  height="12.25"
                  fill="white"
                  transform="translate(5.25 1.75)"
                />
              </clipPath>
            </defs>
          </svg>

          Move To Folder
        </b-dropdown-item>
        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click.stop="$emit('rename',folder)"
        >
          <svg
            class="mr-2"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.8048 0.528575C10.5444 0.268226 10.1223 0.268226 9.86197 0.528575L0.528636 9.86191C0.403612 9.98693 0.333374 10.1565 0.333374 10.3333V13C0.333374 13.3682 0.631851 13.6666 1.00004 13.6666H3.66671C3.84352 13.6666 4.01309 13.5964 4.13811 13.4714L13.4714 4.13805C13.7318 3.8777 13.7318 3.45559 13.4714 3.19524L10.8048 0.528575ZM11 4.72384L9.27619 2.99998L10.3334 1.94279L12.0572 3.66665L11 4.72384ZM8.33338 3.94279L10.0572 5.66665L4.33337 11.3905L2.60952 9.66665L8.33338 3.94279ZM1.66671 10.6095L3.39057 12.3333H1.66671V10.6095Z"
              fill="#44474A"
            />
            <path
              d="M7.66671 12.3333C7.29852 12.3333 7.00004 12.6318 7.00004 13C7.00004 13.3682 7.29852 13.6666 7.66671 13.6666H13C13.3682 13.6666 13.6667 13.3682 13.6667 13C13.6667 12.6318 13.3682 12.3333 13 12.3333H7.66671Z"
              fill="#44474A"
            />
          </svg>

          Rename
        </b-dropdown-item>
        <b-dropdown-item
          class="d-flex align-items-center"
          link-class="dashboard-menu-link"
          @click.stop="$emit('delete',folder)"
        > 
          <svg
            class="mr-2"
            width="14"
            height="18"
            viewBox="0 0 14 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.5 0.75C5.08579 0.75 4.75 1.08579 4.75 1.5V3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H13C13.4142 4.5 13.75 4.16421 13.75 3.75C13.75 3.33579 13.4142 3 13 3H9.25V1.5C9.25 1.08579 8.91421 0.75 8.5 0.75H5.5ZM7.75 3H6.25V2.25H7.75V3Z"
              fill="#44474A"
            />
            <path
              d="M5.5 8.25C5.91421 8.25 6.25 8.58579 6.25 9V13.5C6.25 13.9142 5.91421 14.25 5.5 14.25C5.08579 14.25 4.75 13.9142 4.75 13.5V9C4.75 8.58579 5.08579 8.25 5.5 8.25Z"
              fill="#44474A"
            />
            <path
              d="M9.25 9C9.25 8.58579 8.91421 8.25 8.5 8.25C8.08579 8.25 7.75 8.58579 7.75 9V13.5C7.75 13.9142 8.08579 14.25 8.5 14.25C8.91421 14.25 9.25 13.9142 9.25 13.5V9Z"
              fill="#44474A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.00001 5.25C0.782586 5.25 0.575867 5.34435 0.433415 5.5086C0.290961 5.67285 0.226795 5.89083 0.257543 6.10607L1.57356 15.3182C1.73191 16.4267 2.68123 17.25 3.80095 17.25H10.1991C11.3188 17.25 12.2681 16.4267 12.4264 15.3182L13.7425 6.10607C13.7732 5.89083 13.709 5.67285 13.5666 5.5086C13.4241 5.34435 13.2174 5.25 13 5.25H1.00001ZM3.05849 15.1061L1.86476 6.75H12.1352L10.9415 15.1061C10.8887 15.4756 10.5723 15.75 10.1991 15.75H3.80095C3.42771 15.75 3.11127 15.4756 3.05849 15.1061Z"
              fill="#44474A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.5 0.75C5.08579 0.75 4.75 1.08579 4.75 1.5V3H1C0.585786 3 0.25 3.33579 0.25 3.75C0.25 4.16421 0.585786 4.5 1 4.5H13C13.4142 4.5 13.75 4.16421 13.75 3.75C13.75 3.33579 13.4142 3 13 3H9.25V1.5C9.25 1.08579 8.91421 0.75 8.5 0.75H5.5ZM7.75 3H6.25V2.25H7.75V3Z"
              stroke="white"
              stroke-width="0.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.5 8.25C5.91421 8.25 6.25 8.58579 6.25 9V13.5C6.25 13.9142 5.91421 14.25 5.5 14.25C5.08579 14.25 4.75 13.9142 4.75 13.5V9C4.75 8.58579 5.08579 8.25 5.5 8.25Z"
              stroke="white"
              stroke-width="0.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.25 9C9.25 8.58579 8.91421 8.25 8.5 8.25C8.08579 8.25 7.75 8.58579 7.75 9V13.5C7.75 13.9142 8.08579 14.25 8.5 14.25C8.91421 14.25 9.25 13.9142 9.25 13.5V9Z"
              stroke="white"
              stroke-width="0.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.00001 5.25C0.782586 5.25 0.575867 5.34435 0.433415 5.5086C0.290961 5.67285 0.226795 5.89083 0.257543 6.10607L1.57356 15.3182C1.73191 16.4267 2.68123 17.25 3.80095 17.25H10.1991C11.3188 17.25 12.2681 16.4267 12.4264 15.3182L13.7425 6.10607C13.7732 5.89083 13.709 5.67285 13.5666 5.5086C13.4241 5.34435 13.2174 5.25 13 5.25H1.00001ZM3.05849 15.1061L1.86476 6.75H12.1352L10.9415 15.1061C10.8887 15.4756 10.5723 15.75 10.1991 15.75H3.80095C3.42771 15.75 3.11127 15.4756 3.05849 15.1061Z"
              stroke="white"
              stroke-width="0.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          Delete Folder
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div> 
</template>

<script>
export default {
  props: {
    folder: {
      type: Object,
      default: new Object()
    }
  }
}
</script>

<style lang="scss" >
.btn-cricle-bg{
  border: unset!important;
  outline: unset!important;
  &:hover{
    
    &:before {
      opacity: 1!important;
    }
  }
}
.btn-cricle-bg:before {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 40px;
    height: 40px;
    background: rgba(226, 229, 236, 0.63);
    border-radius: 50%;
    transition: 0.3s;
    z-index: 1;
}
.btn-cricle-bg svg{
    z-index: 2;
}
.dropdown.show .btn-cricle-bg:not(.no-focus) {
    background-color: unset!important;
    border-color: unset!important;
    color: #fff;
    &:before {
      opacity: 1!important;
    }
}
</style>