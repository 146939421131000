<template>
  <b-form-group>
    <div class="flex items-center justify-between">
      <button
        class="flex items-center !px-[11px] py-0 gap-[9px] min-w-[110px] rounded-[2px] border h-[29px] text-[#44474A] !text-[11px]"
        @click="$store.commit('editor/PICK_SUBJECT', {pick: allowPicks, config: value, grabAll})"
      >
        <svg
          fill="none"
          height="17"
          viewBox="0 0 18 17"
          width="18"
          xmlns="http://www.w3.org/2000/svg"
          class="!scale-[.9]"
        >
          <path
            d="M11.4714 8.89999C11.5964 8.77497 11.6667 8.6054 11.6667 8.42859C11.6667 8.25178 11.5964 8.08221 11.4714 7.95719L8.80474 5.29052C8.54439 5.03017 8.12228 5.03017 7.86193 5.29052C7.60158 5.55087 7.60158 5.97298 7.86193 6.23333L10.0572 8.42859L7.86193 10.6239C7.60158 10.8842 7.60158 11.3063 7.86193 11.5667C8.12228 11.827 8.54439 11.827 8.80474 11.5667L11.4714 8.89999Z"
            fill="#3D82EA"
          />
          <path
            clip-rule="evenodd"
            d="M17 8.42859C17 12.8469 13.4183 16.4286 9 16.4286C4.58172 16.4286 1 12.8469 1 8.42859C1 4.01031 4.58172 0.428589 9 0.428589C13.4183 0.428589 17 4.01031 17 8.42859ZM15.6667 8.42859C15.6667 12.1105 12.6819 15.0953 9 15.0953C5.3181 15.0953 2.33333 12.1105 2.33333 8.42859C2.33333 4.74669 5.3181 1.76192 9 1.76192C12.6819 1.76192 15.6667 4.74669 15.6667 8.42859Z"
            fill="#3D82EA"
            fill-rule="evenodd"
          />
          <path
            d="M11.4714 8.89999C11.5964 8.77497 11.6667 8.6054 11.6667 8.42859C11.6667 8.25178 11.5964 8.08221 11.4714 7.95719L8.80474 5.29052C8.54439 5.03017 8.12228 5.03017 7.86193 5.29052C7.60158 5.55087 7.60158 5.97298 7.86193 6.23333L10.0572 8.42859L7.86193 10.6239C7.60158 10.8842 7.60158 11.3063 7.86193 11.5667C8.12228 11.827 8.54439 11.827 8.80474 11.5667L11.4714 8.89999Z"
            stroke="#3D82EA"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0.5"
          />
          <path
            clip-rule="evenodd"
            d="M17 8.42859C17 12.8469 13.4183 16.4286 9 16.4286C4.58172 16.4286 1 12.8469 1 8.42859C1 4.01031 4.58172 0.428589 9 0.428589C13.4183 0.428589 17 4.01031 17 8.42859ZM15.6667 8.42859C15.6667 12.1105 12.6819 15.0953 9 15.0953C5.3181 15.0953 2.33333 12.1105 2.33333 8.42859C2.33333 4.74669 5.3181 1.76192 9 1.76192C12.6819 1.76192 15.6667 4.74669 15.6667 8.42859Z"
            fill-rule="evenodd"
            stroke="#3D82EA"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0.5"
          />
        </svg>

        {{ pickSubject ? 'Selecting...' : value.pickedUid ? `ID: ${value.pickedUid}` : 'Select' }}
      </button>

      <b-link
        class="text-[13px] underline !text-gray-600 hover:!text-gray-500"
        @click="reset"
      >
        Reset
      </b-link>
    </div>
  </b-form-group>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'PickElement',
  props: {
    value: {
      type: Object
    },
    config: {
      type: Object
    },
    grabAll: {
      type: Boolean,
      default: false
    },
    allowPicks: {
      type: [Array, Boolean],
      default: () => true
    }
  },
  methods: {
    reset () {
      this.$set(this.value, 'pickedUid', null)
      if (this.grabAll) {
        this.$set(this.value, 'subject', null)
      }
    }
  },
  computed: {
    ...mapState('editor', {
      pickSubject: state => state.pickSubject
    })
  }
}
</script>
