<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="time-section highlight-element"
    section-type="element"
    @delete="remove"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings('time')"
    @add-subject="$emit('add-subject')"
  >
    <Template :section="section">
      <vac
        ref="vac"
        :end-time="timeZoneTransform"
        @finish="restart"
      >
        <template
          v-slot:process="{ timeObj }"
        >
          <div
            v-for="(time, index) in [timeObj.d, timeObj.h, timeObj.m, timeObj.s]"
            :key="index"
          >
            {{ time }}
          </div>
        </template>
      </vac>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import TimerService from '../TimerService'

export default {
  name: 'TimeItem1',

  components: {Template, Highliter},

  mixins: [SectionMixin, TimerService],

  created () {
    VEvent.listen('timer-update', () => {
      setTimeout(() => {
        if (this.$refs.vac === undefined) return
        this.$refs.vac.startCountdown(true)
      })
    })
  }
}
</script>
