<template>
  <div>
    <sidbear-settings-group
      title="Post Info"
      active
    >
      <sid-background
        :sid="className()"
      />

      <sid-range-slider
        label="Gap"
        rule-name="gap"
        :sid="className()"
      />

      <sid-buttons-group
        label="Horizontal Alignment"
        :options="[
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'start',
            tooltip: 'Start'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'center',
            tooltip: 'Center'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'end',
            tooltip: 'End'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'space-between',
            tooltip: 'Between'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'space-around',
            tooltip: 'Around'
          }
        ]"
        :sid="className()"
        rule-name="justify-content"
      />
    </sidbear-settings-group>

    <sidbear-settings-group
      title="Author"
    >
      <rule-tabs>
        <template #Normal>
          <sid-checkbox
            :sid="className(' .display-subject-1')"
            rule-name="display"
            value="none"
            unchecked-value=""
          >
            Hide
          </sid-checkbox>

          <sid-range-slider
            label="Avatar Size"
            rule-name="--size"
            :dimensions="[]"
            :min="20"
            default-dimension="px"
            :sid="className(' .post-info-avatar-container')"
          />

          <b-form-group
            v-for="(textRule, index) in textRules"
            :key="index"
            v-bind="textRule"
          >
            <component
              :is="textRule.component"
              v-bind="textRule"
              :label="textRule.sidLabel"
              :sid="className(' .post-info-name')"
            />
          </b-form-group>
        </template>

        <template #Hover>
          <sid-checkbox
            value=".3s"
            unchecked-value=""
            rule-name="transition"
            var="--transition"
            :sid="className(' .post-info-name')"
          >
            Transition <span
              class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
            >BETA</span>
          </sid-checkbox>

          <b-form-group
            v-for="(textRule, index) in textRules"
            :key="index"
            v-bind="textRule"
          >
            <component
              :is="textRule.component"
              v-bind="textRule"
              presudo=" .post-grid-card:hover .post-info .post-info-name"
              :sid="hoverClassName()"
              :label="textRule.sidLabel"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </sidbear-settings-group>

    <sidbear-settings-group
      title="Date & Time"
    >
      <rule-tabs>
        <template #Normal>
          <sid-checkbox
            :sid="className(' .post-info-time')"
            rule-name="display"
            value="none"
            unchecked-value=""
          >
            Hide
          </sid-checkbox>

          <b-form-group
            v-for="(textRule, index) in textRules"
            :key="index"
            v-bind="textRule"
          >
            <component
              :is="textRule.component"
              v-bind="textRule"
              :label="textRule.sidLabel"
              :sid="className(' .post-info-time')"
            />
          </b-form-group>
        </template>

        <template #Hover>
          <sid-checkbox
            value=".3s"
            unchecked-value=""
            rule-name="transition"
            var="--transition"
            :sid="className(' .post-info-time')"
          >
            Transition <span
              class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
            >BETA</span>
          </sid-checkbox>

          <b-form-group
            v-for="(textRule, index) in textRules"
            :key="index"
            v-bind="textRule"
          >
            <component
              :is="textRule.component"
              v-bind="textRule"
              :label="textRule.sidLabel"
              presudo=" .post-grid-card:hover .post-info .post-info-time"
              :sid="hoverClassName()"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </sidbear-settings-group>

    <sidbear-settings-group
      title="Comments"
    >
      <rule-tabs>
        <template #Normal>
          <sid-checkbox
            :sid="className(' .post-info-comments')"
            rule-name="display"
            value="none"
            unchecked-value=""
          >
            Hide
          </sid-checkbox>

          <b-form-group
            v-for="(textRule, index) in textRules"
            :key="index"
            v-bind="textRule"
          >
            <component
              :is="textRule.component"
              v-bind="textRule"
              :label="textRule.sidLabel"
              :sid="className(' .post-info-comments')"
            />
          </b-form-group>

          <b-form-group label="Icon Color">
            <sid-color
              rule-name="fill"
              :sid="className(' .post-info-comments path')"
            />
          </b-form-group>
        </template>

        <template #Hover>
          <sid-checkbox
            value=".3s"
            unchecked-value=""
            rule-name="transition"
            var="--transition"
            :sid="className(' .post-info-comments')"
          >
            Transition <span
              class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
            >BETA</span>
          </sid-checkbox>
          
          <b-form-group
            v-for="(textRule, index) in textRules"
            :key="index"
            v-bind="textRule"
          >
            <component
              :is="textRule.component"
              v-bind="textRule"
              :label="textRule.sidLabel"
              presudo=" .post-grid-card:hover .post-info .post-info-comments"
              :sid="hoverClassName()"
            />
          </b-form-group>

          <b-form-group label="Icon Color">
            <sid-color
              rule-name="fill"
              presudo=" .post-grid-card:hover .post-info .post-info-comments path"
              :sid="hoverClassName()"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </sidbear-settings-group>

    <sidbear-settings-group
      title="Likes"
    >
      <rule-tabs>
        <template #Normal>
          <sid-checkbox
            :sid="className(' .post-info-likes')"
            rule-name="display"
            value="none"
            unchecked-value=""
          >
            Hide
          </sid-checkbox>

          <b-form-group
            v-for="(textRule, index) in textRules"
            :key="index"
            v-bind="textRule"
          >
            <component
              :is="textRule.component"
              v-bind="textRule"
              :label="textRule.sidLabel"
              :sid="className(' .post-info-likes')"
            />
          </b-form-group>

          <b-form-group label="Icon Color">
            <sid-color
              rule-name="fill"
              :sid="className(' .post-info-likes path')"
            />
          </b-form-group>
        </template>

        <template #Hover>
          <sid-checkbox
            value=".3s"
            unchecked-value=""
            rule-name="transition"
            var="--transition"
            :sid="className(' .post-info-likes')"
          >
            Transition <span
              class="bg-primary text-center ml-2 px-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
            >BETA</span>
          </sid-checkbox>

          <b-form-group
            v-for="(textRule, index) in textRules"
            :key="index"
            v-bind="textRule"
          >
            <component
              :is="textRule.component"
              v-bind="textRule"
              :label="textRule.sidLabel"
              presudo=" .post-grid-card:hover .post-info .post-info-likes"
              :sid="hoverClassName()"
            />
          </b-form-group>

          <b-form-group label="Icon Color">
            <sid-color
              rule-name="fill"
              presudo=" .post-grid-card:hover .post-info .post-info-likes path"
              :sid="hoverClassName()"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </sidbear-settings-group>

    <sidbear-settings-group title="Separator">
      <sid-checkbox
        :sid="className(' .post-info-separator')"
        rule-name="display"
        value="none"
        unchecked-value=""
      >
        Hide
      </sid-checkbox>

      <b-form-group label="Color">
        <sid-color
          rule-name="stroke"
          :sid="className(' .post-info-separator line')"
        />
      </b-form-group>
    </sidbear-settings-group>

    <sidbear-settings-group
      title="Border & Radius"
    >
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="className()"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="className()"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="className()"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="className()"
        rule-name="border"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </sidbear-settings-group>

    <sidbear-settings-group
      title="Drop Shadow"
    >
      <sid-box-shadow
        :sid="className()"
        rule-name="box-shadow"
      />
    </sidbear-settings-group>

    <sidbear-settings-group
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="className()"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="className()"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </sidbear-settings-group>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox.vue'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'

export default {
  name: 'PostInfoMod',
  components: {
    RuleTabs,
    SidButtonsGroup,
    SidCheckbox,
    SidBoxShadow,
    SidSelect,
    SidBackground, SidLinkedGroup, SidRangeSlider, SidFont, SidColor, SidbearSettingsGroup
  },
  mixins: [ModMixin],
  data () {
    return {
      textRules: [
        {
          component: 'sid-color',
          ruleName: 'color',
          label: 'Text Color'
        },
        {
          component: 'sid-font',
          ruleName: 'font'
        },
        {
          component: 'sid-range-slider',
          ruleName: 'font-size',
          sidLabel: 'Text Size'
        }
      ]
    }
  },
  methods: {
    className (name = '') {
      return `.post-grid-${this.section.sid} .post-info${name}`
    },
    hoverClassName () {
      return `.post-grid-${this.section.sid}`
    }
  }
}
</script>