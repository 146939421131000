import shortid from 'shortid'

export default {
  1: {
    'pressetID': 1,
    'buttonUID': shortid.generate(),
    'display': true,
    'hover': {},
    'type': 'button-mod',
    'text': 'CTA GRADIENT BUTTON',
    paddingV2: {
      lg: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      md: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      sm: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      }
    },
    'resolutionStyle': {
      'lg': {
        'size': '1',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'colored',
        'shape': null,
        'shadow': null
      },
      'sm': {
        'size': 'md',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'colored',
        'shape': null,
        'shadow': null
      }
    },
    'buttonType': 'button',
    'animations': {
      'animation': null,
      'once': true,
      'offset': 200,
      'delay': 50
    },
    'backgroundType': 'gradient',
    'backgroundGradient': [
      {
        'color': 'rgba(177, 211, 103, 1)',
        'position': 0
      },
      {
        'color': 'rgba(102, 176, 83, 1)',
        'position': 100
      }
    ],
    'border': {
      lg: [0, 0, 0, 0],
      md: [0, 0, 0, 0],
      sm: [0, 0, 0, 0]
    },
    'radius': {
      lg: [40, 40, 40, 40],
      md: [40, 40, 40, 40],
      sm: [40, 40, 40, 40]
    },
    'gradientDirection': '180',
    'padding': {
      'lg': {
        'top': 0,
        'bottom': 0,
        'x': 0
      }
    },
    'margin': {
      'lg': {
        'top': 0,
        'bottom': '20'
      }
    },
    'shadowOptions': {
      'spread': 4,
      'blur': 20
    }
  },
  2: {
    'pressetID': 1,
    'buttonUID': shortid.generate(),
    'display': true,
    'hover': {},
    'type': 'button-mod',
    'text': 'CTA BUTTON WITH ICON',
    paddingV2: {
      lg: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      md: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      sm: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      }
    },
    'resolutionStyle': {
      'lg': {
        'size': '1',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'rgba(0, 0, 0, 1)',
        'shape': null,
        'shadow': null
      },
      'sm': {
        'size': 'md',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'colored',
        'shape': null,
        'shadow': null
      }
    },
    'buttonType': 'button',
    'animations': {
      'animation': null,
      'once': true,
      'offset': 200,
      'delay': 50
    },
    'backgroundType': null,
    'backgroundGradient': [
      {
        'color': 'rgba(177, 211, 103, 1)',
        'position': 0
      },
      {
        'color': 'rgba(102, 176, 83, 1)',
        'position': 100
      }
    ],
    'border': {
      lg: [0, 0, 0, 0],
      md: [0, 0, 0, 0],
      sm: [0, 0, 0, 0]
    },
    'radius': {
      lg: [40, 40, 40, 40],
      md: [40, 40, 40, 40],
      sm: [40, 40, 40, 40]
    },
    'gradientDirection': '180',
    'padding': {
      'lg': {
        'top': 0,
        'bottom': 0,
        'x': 0
      }
    },
    'margin': {},
    'icon': 'fa fa-share'
  },
  3: {
    'pressetID': 1,
    'buttonUID': shortid.generate(),
    'display': true,
    'hover': {},
    'type': 'button-mod',
    'text': 'CTA BUTTON',
    paddingV2: {
      lg: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      md: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      sm: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      }
    },
    'resolutionStyle': {
      'lg': {
        'size': '1',
        'align': 'center',
        'btnStyle': '',
        'color': 'rgba(39, 148, 227, 1)',
        'backgroundColor': 'rgba(252, 176, 69, 0)',
        'shape': null,
        'shadow': null,
        'borderColor': 'rgba(39, 148, 227, 1)'
      },
      'sm': {
        'size': 'md',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'colored',
        'shape': null,
        'shadow': null
      }
    },
    'buttonType': 'button',
    'animations': {
      'animation': null,
      'once': true,
      'offset': 200,
      'delay': 50
    },
    'backgroundType': null,
    'backgroundGradient': [
      {
        'color': 'rgba(177, 211, 103, 1)',
        'position': 0
      },
      {
        'color': 'rgba(102, 176, 83, 1)',
        'position': 100
      }
    ],
    'border': {
      lg: [2, 2, 2, 2],
      md: [2, 2, 2, 2],
      sm: [2, 2, 2, 2]
    },
    'radius': {
      lg: [7, 7, 7, 7],
      md: [7, 7, 7, 7],
      sm: [7, 7, 7, 7]
    },
    'gradientDirection': '180',
    'padding': {
      'lg': {
        'top': 0,
        'bottom': 0,
        'x': 33
      }
    },
    'margin': {
      'lg': {
        'top': 0,
        'bottom': '20'
      }
    },
    'shadowOptions': {
      'spread': 4,
      'blur': 20
    }
  },
  4: {
    'pressetID': 1,
    'buttonUID': shortid.generate(),
    'display': true,
    'hover': {},
    'type': 'button-mod',
    'text': 'Cta Button',
    paddingV2: {
      lg: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      md: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      sm: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      }
    },
    'resolutionStyle': {
      'lg': {
        'size': '1',
        'align': 'center',
        'btnStyle': '',
        'color': 'rgba(255, 255, 255, 1)',
        'backgroundColor': 'rgba(108, 28, 187, 1)',
        'shape': null,
        'shadow': null,
        'borderColor': 'rgba(136, 75, 197, 1)'
      },
      'sm': {
        'size': 'md',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'colored',
        'shape': null,
        'shadow': null
      }
    },
    'buttonType': 'button',
    'animations': {
      'animation': null,
      'once': true,
      'offset': 200,
      'delay': 50
    },
    'backgroundType': null,
    'backgroundGradient': [
      {
        'color': 'rgba(177, 211, 103, 1)',
        'position': 0
      },
      {
        'color': 'rgba(102, 176, 83, 1)',
        'position': 100
      }
    ],
    'border': {
      lg: [4, 0, 0, 0],
      md: [4, 0, 0, 0],
      sm: [4, 0, 0, 0]
    },
    'radius': {
      lg: [0, 0, 0, 0],
      md: [0, 0, 0, 0],
      sm: [0, 0, 0, 0]
    },
    'gradientDirection': '180',
    'padding': {
      'lg': {
        'top': 0,
        'bottom': 0,
        'x': 33
      }
    },
    'margin': {
      'lg': {
        'top': 0,
        'bottom': '20'
      }
    },
    'icon': 'fa fa-arrow-right',
    'shadowOptions': {
      'spread': 4,
      'blur': 20
    }
  },
  5: {
    'pressetID': 1,
    'buttonUID': shortid.generate(),
    'display': true,
    'hover': {},
    'type': 'button-mod',
    'text': 'CTA BUTTON',
    paddingV2: {
      lg: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      md: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      sm: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      }
    },
    'resolutionStyle': {
      'lg': {
        'size': '1',
        'align': 'center',
        'btnStyle': '',
        'color': 'rgba(0, 0, 0, 1)',
        'backgroundColor': 'rgba(235, 235, 235, 1)',
        'shape': null,
        'shadow': null,
        'borderColor': 'rgba(213, 213, 213, 1)'
      },
      'sm': {
        'size': 'md',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'colored',
        'shape': null,
        'shadow': null
      }
    },
    'buttonType': 'button',
    'animations': {
      'animation': null,
      'once': true,
      'offset': 200,
      'delay': 50
    },
    'backgroundType': null,
    'backgroundGradient': [
      {
        'color': 'rgba(177, 211, 103, 1)',
        'position': 0
      },
      {
        'color': 'rgba(102, 176, 83, 1)',
        'position': 100
      }
    ],
    'border': {
      lg: [0, 0, 4, 0],
      md: [0, 0, 4, 0],
      sm: [0, 0, 4, 0]
    },
    'radius': {
      lg: [0, 0, 0, 0],
      md: [0, 0, 0, 0],
      sm: [0, 0, 0, 0]
    },
    'gradientDirection': '180',
    'padding': {
      'lg': {
        'top': 0,
        'bottom': 0,
        'x': 33
      }
    },
    'margin': {
      'lg': {
        'top': 0,
        'bottom': '20'
      }
    },
    'icon': '',
    'shadowOptions': {
      'spread': 0,
      'blur': 20
    }
  },
  6: {
    'pressetID': 1,
    'buttonUID': shortid.generate(),
    'display': true,
    'hover': {},
    'type': 'button-mod',
    'text': 'GLOSSY CTA',
    paddingV2: {
      lg: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      md: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      sm: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      }
    },
    'resolutionStyle': {
      'lg': {
        'size': '1',
        'align': 'center',
        'btnStyle': '',
        'color': 'rgba(0, 0, 0, 1)',
        'backgroundColor': 'rgba(108, 28, 187, 1)',
        'shape': null,
        'shadow': null,
        'borderColor': 'rgba(234, 185, 13, 1)'
      },
      'sm': {
        'size': 'md',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'colored',
        'shape': null,
        'shadow': null
      }
    },
    'buttonType': 'button',
    'animations': {
      'animation': null,
      'once': true,
      'offset': 200,
      'delay': 50
    },
    'backgroundType': 'gradient',
    'backgroundGradient': [
      {
        'color': 'rgba(242, 218, 91, 1)',
        'position': 50
      },
      {
        'color': 'rgba(241, 206, 19, 1)',
        'position': 50
      }
    ],
    'border': {
      lg: [0, 0, 4, 0],
      md: [0, 0, 4, 0],
      sm: [0, 0, 4, 0]
    },
    'radius': {
      lg: [5, 5, 5, 5],
      md: [5, 5, 5, 5],
      sm: [5, 5, 5, 5]
    },
    'gradientDirection': '180',
    'padding': {
      'lg': {
        'top': 0,
        'bottom': 0,
        'x': 33
      }
    },
    'margin': {
      'lg': {
        'top': 0,
        'bottom': '20'
      }
    },
    'icon': '',
    'shadowColor': 'rgba(234, 185, 13, 1)',
    'shadowOptions': {
      'spread': 0,
      'blur': 0,
      'offsetX': -1
    }
  },
  7: {
    'pressetID': 1,
    'buttonUID': shortid.generate(),
    'display': true,
    'hover': {},
    'type': 'button-mod',
    'text': 'Two Lines Button',
    paddingV2: {
      lg: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      md: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      sm: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      }
    },
    'resolutionStyle': {
      'lg': {
        'size': '1',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'rgba(52, 128, 241, 1)',
        'shape': null,
        'shadow': null
      },
      'sm': {
        'size': 'md',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'colored',
        'shape': null,
        'shadow': null
      }
    },
    'buttonType': 'button',
    'animations': {
      'animation': null,
      'once': true,
      'offset': 200,
      'delay': 50
    },
    'backgroundType': 'gradient',
    'backgroundGradient': [
      {
        'color': 'rgba(52, 128, 241, 1)',
        'position': 0
      },
      {
        'color': 'rgba(91, 152, 242, 1)',
        'position': 100
      }
    ],
    'border': {
      lg: [0, 0, 0, 0],
      md: [0, 0, 0, 0],
      sm: [0, 0, 0, 0]
    },
    'radius': {
      lg: [40, 40, 40, 40],
      md: [40, 40, 40, 40],
      sm: [40, 40, 40, 40]
    },
    'gradientDirection': '180',
    'padding': {
      'lg': {
        'top': 0,
        'bottom': 0,
        left: '20px',
        right: '20px'
      },
      'md': {
        'top': 0,
        'bottom': 0,
        left: '20px',
        right: '20px'
      },
      'sm': {
        'top': 0,
        'bottom': 0,
        left: '20px',
        right: '20px'
      }
    },
    'margin': {
      'lg': {
        'top': 0,
        'bottom': '20'
      }
    },
    'shadowOptions': {
      'spread': 4,
      'blur': 20
    },
    'subtext': 'Two Lines Button',
    'subTextColor': 'rgba(177, 222, 255, 1)'
  },
  8: {
    'pressetID': 1,
    'buttonUID': shortid.generate(),
    'display': true,
    'hover': {},
    'type': 'button-mod',
    'text': 'Non-Styled Button',
    paddingV2: {
      lg: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      md: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      sm: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      }
    },
    'resolutionStyle': {
      'lg': {
        'size': '1',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'rgba(202, 82, 82, 1)',
        'shape': null,
        'shadow': null
      },
      'sm': {
        'size': 'md',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'colored',
        'shape': null,
        'shadow': null
      }
    },
    'buttonType': 'button',
    'animations': {
      'animation': null,
      'once': true,
      'offset': 200,
      'delay': 50
    },
    'backgroundType': null,
    'backgroundGradient': [
      {
        'color': 'rgba(177, 211, 103, 1)',
        'position': 0
      },
      {
        'color': 'rgba(102, 176, 83, 1)',
        'position': 100
      }
    ],
    'border': {
      lg: [0, 0, 0, 0],
      md: [0, 0, 0, 0],
      sm: [0, 0, 0, 0]
    },
    'radius': {
      lg: [40, 40, 40, 40],
      md: [40, 40, 40, 40],
      sm: [40, 40, 40, 40]
    },
    'gradientDirection': '180',
    'padding': {
      'lg': {
        'top': 0,
        'bottom': 0,
        'x': 22
      }
    },
    'margin': {
      'lg': {
        'top': 0,
        'bottom': '20'
      }
    },
    'shadowOptions': {
      'spread': 4,
      'blur': 20
    }
  },
  9: {
    'pressetID': 1,
    'buttonUID': shortid.generate(),
    'display': true,
    'hover': {},
    'type': 'button-mod',
    'text': 'Non-Styled Button',
    paddingV2: {
      lg: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      md: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      },
      sm: {
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      }
    },
    'resolutionStyle': {
      'lg': {
        'size': '1',
        'align': 'center',
        'btnStyle': '',
        'color': 'rgba(169, 75, 8, 1)',
        'backgroundColor': 'rgba(247, 242, 205, 1)',
        'shape': null,
        'shadow': null,
        'borderColor': 'rgba(136, 75, 197, 1)'
      },
      'sm': {
        'size': 'md',
        'align': 'center',
        'btnStyle': '',
        'color': '#ffffff',
        'backgroundColor': 'colored',
        'shape': null,
        'shadow': null
      }
    },
    'buttonType': 'button',
    'animations': {
      'animation': null,
      'once': true,
      'offset': 200,
      'delay': 50
    },
    'backgroundType': null,
    'backgroundGradient': [
      {
        'color': 'rgba(177, 211, 103, 1)',
        'position': 0
      },
      {
        'color': 'rgba(102, 176, 83, 1)',
        'position': 100
      }
    ],
    'border': {
      lg: [0, 0, 0, 0],
      md: [0, 0, 0, 0],
      sm: [0, 0, 0, 0]
    },
    'radius': {
      lg: [0, 0, 0, 0],
      md: [0, 0, 0, 0],
      sm: [0, 0, 0, 0]
    },
    'gradientDirection': '180',
    'padding': {
      'lg': {
        'top': 0,
        'bottom': 0,
        'x': 15
      }
    },
    'margin': {
      'lg': {
        'top': 0,
        'bottom': '20'
      }
    },
    'icon': '',
    'shadowOptions': {
      'spread': 0,
      'blur': 20
    }
  }
}