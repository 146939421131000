<template>
  <component
    :is="tagName"
    ref="editable"
    contenteditable
    v-on="listeners"
  />
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    tagName: {
      type: String,
      default: 'div'
    }
  },
  data () {
    return {
      timer: null
    }
  },
  computed: {
    listeners () {
      return { ...this.$listeners, input: this.onInput }
    },
    ...mapState('historyChanges', {
      historyDisabled: state => state.disable
    })
  },
  watch: {
    historyDisabled () {
      setTimeout(() => {
        this.$refs.editable.innerText = this.value
      })
    }
  },
  mounted () {
    this.$refs.editable.innerText = this.value
  },
  methods: {
    onInput (e) {
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => this.$emit('input', e.target.innerText), 500)
    }
  }
}
</script>
