<template>
  <vue-inline-svg
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import VueInlineSvg from 'vue-inline-svg'
export default {
  components: {
    VueInlineSvg
  }
}
</script>
