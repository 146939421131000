<template>
  <div
    v-if="visability('image')"
    class="images-group-2 d-flex justify-content-center align-items-center"
  >
    <div
      class="position-relative"
      :style="{...dropShadow}"
    >
      <div
        class="back-layer"
        :style="{...backgroundColor}"
      />
      <slot />
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'ImageItem2Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.images-group-2 {
  padding-top: 30px;
  padding-bottom: 60px;

  img {
    width: 340px;
    height: 455px;
    clip-path: polygon(0 16%, 80% 0, 78% 88%, 0% 100%);
  }

  .back-layer {
    position: absolute;
    top: 25px;
    left: 20px;
    background: red;
    width: 340px;
    height: 455px;
    clip-path: polygon(0 16%, 80% 0, 78% 88%, 0% 100%);
  }
}
</style>
