<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('button')"
  >
    <template slot="section-type-icon">
      <svg
        fill="none"
        height="16"
        viewBox="0 0 21 16"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.65 1H5.55C3.0371 1 1 3.0371 1 5.55C1 8.0629 3.0371 10.1 5.55 10.1H14.65C17.1629 10.1 19.2 8.0629 19.2 5.55C19.2 3.0371 17.1629 1 14.65 1Z"
          stroke="#1D1D1D"
          stroke-width="1.3"
        />
        <path
          d="M16.4001 10.5215L8.70519 3.10004L8.70007 14.0644L11.0351 11.721L12.4065 15L14.5462 14.0155L13.1748 10.7365L16.4001 10.5215Z"
          fill="black"
        />
      </svg>
    </template>
    <Template :section="section">
      <es-button
        :class="{'element-hidden': !visability('button')}"
        :customs="section.options.content.button"
      />
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'

export default {
  name: 'FeaturedItems1',

  components: {Template, Highliter},

  mixins: [SectionMixin]
}
</script>
