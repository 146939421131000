<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="d-flex align-items-center">
        <span class="indicator mr-2" />
        <p class="text-capitalize mb-0">
          {{ labelCase(componentName) }}
        </p>
      </div>
    </div>

    <b-form-group label="Count of posts per page">
      <!--      <v-select left v-model="config.action" placeholder="Select Button Action" class="custom-select" :options="['Link', 'Modal']"></v-select>-->
    </b-form-group>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ThemeMixin from '@builder/components/mixins/ThemeMixin'

export default {
  name: 'ButtonMod',
  mixins: [ModMixin, ThemeMixin],

  data () {
    return {

    }
  }
}
</script>
