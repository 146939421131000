<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element"
    :dublicate-deep="parentUID"
    :dublicate="section"
    :data-sid="section.sid"
    @add-subject="$emit('add-subject')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings('qr')"
  >
    <div :data-sid="section.sid">
      <div
        class="qr"
        :class="[`--${section.sid}--`, {'element-hidden': !visability('qr')}]"
      >
        <div v-html="section.options.content.qr.svg" />
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'

export default {
  name: 'ElCompareSlider',
  mixins: [SectionMixin]
}
</script>

<style lang="scss">
.qr {
  display: flex;
  justify-content: center;
  --size: 200px;
  padding: 20px;

  div {
    width: var(--size);
    height: var(--size);
  }
}
</style>