<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element"
    :dublicate-deep="parentUID"
    :dublicate="section"
    :hide-bar="hideBar"
    :hide-add="hideAdd"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('carousel')"
  >
    <div
      :data-sid="section.sid"
      :class="[`--${section.sid}--`, {'element-hidden': !visability()}]"
    >
      <div class="carousel-card-container">
        <div
          class="carousel-card-wrapper"
          :class="`animation-type-${section.options.content.carousel.animationType}`"
        >
          <div
            v-for="(card, index) in cards"
            :key="card.sid + index"
            class="carousel-card flex flex-col"
            :data-sid="card.sid"
            :class="card.sid"
            :style="{boxShadow: card.highlight ? '0 0 0 2px #3f51b5' : null}"
          >
            <div class="el-overlay" />
            <elements
              v-model="card.elements"
              class="contents"
              classes="contents"
              drag-height="200px"
              group-name="rows"
              :put="['elements']"
            />
          </div>
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Elements from '@/components/builder/utils/Elements/Elements.vue'

export default {
  name: 'ElCardCarousel',

  components: {Elements, Highliter},

  mixins: [SectionMixin],

  computed: {
    cards () {
      return this.section.options.content.carousel.cards
    }
  }
}
</script>

<style lang="scss">
.carousel-card-container {
  overflow-x: auto;
  overflow-y: hidden;
  padding: 1rem;
  scroll-timeline-name: --scroller;
  scroll-timeline-axis: inline;
  --carousel-scrollbar-height: 7px;

  &::-webkit-scrollbar {
    height: var(--carousel-scrollbar-height);
  }

  .carousel-card-wrapper {
    display: flex;
    gap: 20px;
    perspective: 9000px;
    transform-style: preserve-3d;
    perspective-origin: 50% 50%;

    &.animation-type-1 > .carousel-card {
      transition: .3s;
      min-width: var(--carouse-card-width, 300px);
      height: var(--carouse-card-height);
      animation: auto ease-in-out carousel-card-intro-1 forwards,
      auto ease-in-out carousel-card-outro-1 forwards;
      animation-timeline: view(inline);
      animation-range: entry, exit;
      transform-origin: bottom center;
    }

    &.animation-type-2 > .carousel-card {
      transition: .3s;
      min-width: var(--carouse-card-width, 300px);
      height: var(--carouse-card-height);
      animation: auto ease-in-out carousel-card-intro-2 forwards,
      auto ease-in-out carousel-card-outro-2 forwards;
      animation-timeline: view(inline);
      animation-range: entry, exit;
      transform-origin: bottom center;
    }

    &.animation-type-3 > .carousel-card {
      transition: .3s;
      min-width: var(--carouse-card-width, 300px);
      height: var(--carouse-card-height);
      animation: auto ease-in-out carousel-card-intro-3 forwards,
      auto ease-in-out carousel-card-outro-3 forwards;
      animation-timeline: view(inline);
      animation-range: entry, exit;
      transform-origin: bottom center;
    }
  }
}

@keyframes carousel-card-intro-1 {
  from {
    transform-origin: center left;
    opacity: 0;
    scale: 0;
  }
  to {
    transform-origin: center left;
    opacity: 1;
    scale: 1;
  }
}

@keyframes carousel-card-outro-1 {
  from {
    transform-origin: center right;
    opacity: 1;
    scale: 1;
  }
  to {
    transform-origin: center right;
    opacity: 0;
    scale: 0;
  }
}

@keyframes carousel-card-intro-2 {
  from {
    transform-origin: center left;
    opacity: 0;
    scale: 0;
    rotate: z -90deg;
    translate: 0 -100%;
  }
  to {
    transform-origin: center left;
    opacity: 1;
    scale: 1;
    rotate: z 0deg;
    translate: 0 0;
  }
}

@keyframes carousel-card-outro-2 {
  from {
    transform-origin: center right;
    opacity: 1;
    scale: 1;
  }
  to {
    transform-origin: center right;
    opacity: 0;
    scale: 0;
  }
}

@keyframes carousel-card-intro-3 {
  from {
    transform-origin: center left;
    opacity: 0;
    scale: 0;
    translate: 0 -100%;
  }
  to {
    transform-origin: center left;
    opacity: 1;
    scale: 1;
    translate: 0 0;
  }
}

@keyframes carousel-card-outro-3 {
  from {
    transform-origin: center right;
    opacity: 1;
    scale: 1;
    translate: 0 0;
  }
  to {
    transform-origin: center right;
    opacity: 0;
    scale: 0;
    translate: 0 100%;
  }
}
</style>