<template>
  <div>
    <span class="mr-[15px] font-semibold text-[15px] leading-[38px] text-black">Sort by:</span>
    <b-dropdown
      variant="unstyled"
      menu-class="sorting-menu !rounded-[3px] border !border-[#E2E5EC] !w-[164px] !py-0"
      toggle-class="!p-0 !m-0 !border-0 text-decoration-none"
    > 
      <template #button-content>
        <div class="inline-flex justify-between items-center pl-[18px] pr-[12px] w-[164px] border border-[#E2E5EC] rounded-[3px] hover:bg-[#e9ecef80]">
          <span class="!text-black text-[14px] leading-[34px]">
            {{ options.find(o=>o.id == value).name }}
          </span>
          <svg
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L5.325 5.10811L9.65 1"
              stroke="#6E747A"
              stroke-width="1.5"
            />
          </svg>
        </div>
      </template>
      <b-dropdown-item
        v-for="(option,i) in options"
        :key="i"
        class="d-flex align-items-center"
        :link-class="`text-[14px] leading-[34px] !text-black !py-[1px] dashboard-menu-link ${value == option.id ? '!bg-[#e9ecef80]':''}`"
        @click="$emit('input',option.id)"
      >
        {{ option.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
export default {
  props: {
    value: {
      type: [Number,String],
      default:'updated_at'
    }
  },
  computed: {
    ...mapState('assetsLibrary', {
      options: state => state.sortingOptions
    }),
    getName () {
      return _.get(_.find(this.options, ['id', this.value]), 'name', null)
    }
  }  
}
</script>

<style lang="scss" scoped>

</style>