import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Payment Summary'
export const group = ''

export default class ElPaymentSummary extends SectionProvider {
  constructor (options = {}) {
    super('ElPaymentSummary', options)

    this.name = name
    this.group = group
  }
}