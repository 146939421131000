<template>
  <v-select
    v-model="style"
    left
    :reduce="option => option.key"
    :options="options"
    :searchable="false"
  />
</template>

<script>
export default {
  name: 'SelectStyle',
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      options: [
        // {
        //   key: null,
        //   label: "Global Style"
        // },
        {
          key: 'filled',
          label: 'Filled'
        },
        {
          key: 'outlined',
          label: 'Outlined'
        }
      ]
    }
  },
  computed: {
    style: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>