<template>
  <div
    class="flex flex-col w-full bg-white rounded-[7px] border cursor-pointer border-[#dee2e6] file-shadow transition"
    :class="active ? 'video-lib-file-active' : ''"
    @click="$emit('click', file)"
  >
    <img
      :src="`https://video-upload.estage.com/video/preview/${file.id}`"
      class="video-lib-img !bg-black h-[144px] w-full rounded-[7px] bg-cover rounded-t-lg"
    >
    <div class="flex flex-col py-[14px] px-[20px]">
      <span class="text-[#44474A] text-[17px] font-[500] truncate mb-1">{{ file.title || file.name }}</span>
      <span
        v-if="file.timestamp"
        class="text-[#9FA9BA] text-[15px]"
      >{{ getRelativeDay(file.timestamp) }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    file: {
      type: Object,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    getRelativeDay (timestamp) {
      const today = moment().startOf('day')
      const yesterday = moment().subtract(1, 'days').startOf('day')
      const givenDay = moment(timestamp).startOf('day')

      if (givenDay.isSame(today)) {
        return 'Today'
      } else if (givenDay.isSame(yesterday)) {
        return 'Yesterday'
      } else {
        return givenDay.format('D MMMM YYYY')
      }
    }
  }
}
</script>

<style lang="scss">
.video-lib-img {
  background-color: black;
  object-fit: contain !important;
}
.video-lib-file-active {
  outline: #007bff solid 2px !important;
}
.file-shadow {
  &:hover {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  }
}
</style>