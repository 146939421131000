<template>
  <tr class="py-3">
    <td class="py-[30px] pl-[30px]">
      <div class="flex gap-[17px] max-w-[350px]">
        <b-checkbox
          v-model="selected"
          :value="comment.id"
        />

        <svg
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="16.5"
            cy="16.5"
            r="16.5"
            fill="#D9D9D9"
          />
        </svg>

        <div>
          <div
            class="text-[17px]"
            v-text="comment.guest_full_name || user.name"
          />
          <a
            class="blocktext-[16px]"
            :href="`mailto:${comment.guest_email || user.email}`"
          >{{ comment.guest_email || user.email }}</a>
        </div>
      </div>
    </td>
    <td class="py-[30px] text-[#5E5E5E] text-[14px]">
      <badge
        class="mb-[11px]"
        :variant="status"
      />

      <div class="max-w-[350px]">
        {{ commentText }}<div v-if="!showFullComment && comment.comment.length > 112">
          ...
          <span
            class="cursor-pointer hover:opacity-80 underline text-primary"
            @click="showFullComment = true"
          >View Full Comment</span>
        </div>
      </div>
    </td>
    <td class="py-[30px]">
      <div class="flex items-center flex-wrap max-w-[160px] text-[14] text-primary">
        <a
          :href="`https://${project.domain || project.subdomain + '.estage.site'}/${comment.location_url}`"
          target="_blank"
        >
          <span
            v-for="(location, index) in comment.location"
            :key="index"
          >
            {{ location }} <span
              v-if="index !== comment.location.length - 1"
              class="mx-2"
            >></span>
          </span>
        </a>
      </div>
    </td>
    <td class="text-[#5E5E5E] text-[14px] py-[30px]">
      {{ comment.created_at | moment("YYYY/MM/DD [at] h:mm a") }}
    </td>
    <td class="py-[30px] pr-[30px]">
      <b-dropdown
        toggle-class="bg-white px-0 border-0"
        menu-class="w-[237px]"
        right
      >
        <template #button-content>
          <svg
            width="24"
            height="5"
            viewBox="0 0 24 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="2.5"
              cy="2.5"
              r="2.5"
              fill="#4F83E3"
            />
            <circle
              cx="12.0713"
              cy="2.5"
              r="2.5"
              fill="#4F83E3"
            />
            <circle
              cx="21.3569"
              cy="2.5"
              r="2.5"
              fill="#4F83E3"
            />
          </svg>
        </template>
        <template v-for="action in actions">
          <b-dropdown-item
            v-if="checkActionAvaiale(action,comment)"
            :key="action.key"
            class="text-[14px]"
            @click="emitAction(action)"
          >
            {{ action.name }}
          </b-dropdown-item>
        </template>
      </b-dropdown>
      <reply
        v-model="replyModal"
        :comment="comment"
        @refresh="$emit('refresh')"
      />
    </td>
  </tr>
</template>

<script>
import Badge from '@/views/dashboard/comments/Badge.vue'
import Reply from '@/views/dashboard/comments/Reply.vue'
import {mapState} from 'vuex'

export default {
  name: 'CommentsTableItem',
  components: {Badge, Reply},
  props: {
    value: {
      type: Array
    },
    comment: {
      type: Object
    }
  },
  data () {
    return {
      replyModal: false,
      showFullComment: false,
      actions: [
        {
          name: 'Reply',
          key: 'reply'
        },
        {
          name: 'Hide',
          key: 'hide'
        },
        {
          name: 'Delete',
          key: 'delete'
        },
        {
          name: 'Approve',
          key: 'approve'
        },
        {
          name: 'Reject',
          key: 'reject'
        }
        // {
        //   name: 'Block User Comments',
        //   key: 'block-user-comments'
        // },
        // {
        //   name: 'View All User’s Comments',
        //   key: 'view-all-user-comments'
        // }
      ]
    }
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user
    }),
    status () {
      if (this.comment.status) return this.comment.status.name.toLowerCase()

      return 'awaiting'
      // if (this.comment.rejected) {
      //   return 'rejected'
      // }
      //
      // if (this.comment.is_hide) {
      //   return 'deleted'
      // }
      //
      // if (this.comment.moderation) {
      //   return 'approved'
      // }
      //
      // if (!this.comment.moderation) {
      //   return 'awaiting'
      // }
      //
      // return  'awaiting'
    },
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('editor', {
      commentsManagementAPI: state => state.commentsManagementAPI
    }),
    commentText () {
      if (this.showFullComment) {
        return this.comment.comment
      }

      return this.comment.comment.slice(0, 112)
    },
    selected: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    checkActionAvaiale (action,comment){
      if(action.key == 'reply') return !comment.parent_id
      return true
    },
    emitAction (action) {
      this[action.key]()
    },
    reply () {
      this.replyModal = true
    },
    hide () {
      axios.put(`api/projects/${this.project.id}/${this.commentsManagementAPI}/mass-actions`, {
        action: 'hide',
        ids: [this.comment.id],
        comment_block_id: this.comment.comment_block_id,
        blog_id: parseInt(this.comment.blog_id)
      })
        .then(() => {
          this.$emit('refresh')

          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Hidden.',
            showConfirmButton: false,
            timer: 3000
          })
        })
    },
    delete () {
      axios.delete(`api/projects/${this.project.id}/${this.commentsManagementAPI}/${this.comment.id}`)
        .then(() => {
          axios.delete(`api/projects/${this.project.id}/${this.commentsManagementAPI}/${this.comment.id}/force`)
            .then(() => {
              this.$emit('refresh')

              this.$swal({
                icon: 'success',
                iconColor: '#4F83E3',
                toast: true,
                position: 'top-right',
                title: 'Deleted.',
                showConfirmButton: false,
                timer: 3000
              })
            })
        })
    },
    approve () {
      axios.put(`api/projects/${this.project.id}/${this.commentsManagementAPI}/mass-actions`, {
        action: 'approve',
        ids: [this.comment.id],
        comment_block_id: this.comment.comment_block_id,
        blog_id: parseInt(this.comment.blog_id)
      })
        .then(() => {
          this.$emit('refresh')

          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Approved.',
            showConfirmButton: false,
            timer: 3000
          })
        })
    },
    reject () {
      axios.put(`api/projects/${this.project.id}/${this.commentsManagementAPI}/mass-actions`, {
        action: 'reject',
        ids: [this.comment.id],
        comment_block_id: this.comment.comment_block_id,
        blog_id: parseInt(this.comment.blog_id)
      })
        .then(() => {
          this.$emit('refresh')

          this.$swal({
            icon: 'success',
            iconColor: '#4F83E3',
            toast: true,
            position: 'top-right',
            title: 'Rejected.',
            showConfirmButton: false,
            timer: 3000
          })
        })
    },
    blockUserComments () {

    },
    viewAllUserComments () {

    }
  }
}
</script>