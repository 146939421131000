const state = () => ({
  preview: false,
  page: 'sections',
  config: {},
  section: null,
  openedItem: null,
  theme: 'demo',
  resolution: 'lg',
  commentsManagementAPI: 'blog/manage/comments',
  tablet: false,
  openedItemOptions: {},
  element: null,
  chatSubscription: false,
  communityPlusSubscription: false,
  businessPlusSubscription: false,
  addSubjectEvent: null,
  currentSubject: null,
  pickSubject: false,
  grabAll: false,
  expandedGroup: null,
  backLink: null,
  globalStyles: {
    colors: {
      primary: '',
      accent: '#B83636',
      siteBackground: '#ffffff',
      buttons: '#B83636'
    },
    font: {
      style: '',
      headingStyle: '',
      h1: {
        fontFamily: null,
        fontSize: '23px'
      },
      h2: {
        fontFamily: null,
        fontSize: '23px'
      },
      h3: {
        fontFamily: null,
        fontSize: '16px'
      },
      body: {
        fontFamily: null,
        fontSize: '16px'
      },
      addedGoogleFonts: [],
      installedFonts: []
    },
    button: false,
    buttons: {
      shape: null,
      outlined: false
    }
  },
  newSectionModal: false,
  tiptap: null,
  unpin: false,
  stackElements: []
})

const getters = {
  resolution (state) {
    return state.tablet ? 'md' : state.resolution
  },
  isChatSubscription (state) {
    return state.chatSubscription.has_available_subscriptions
  },
  isCommunityPlus (state) {
    return state.communityPlusSubscription
  },
  isBusinessPlus (state) {
    return state.businessPlusSubscription
  },
  isPlus () {
    return this.isBusinessPlus || this.isCommunityPlus
  }
}

const actions = {
  sectionSettings ({commit}, paylaod) {
    commit('SET_SECTION', paylaod.section)
    commit('SET_ELEMENT', paylaod.el)
    commit('SET_OPENED_ITEM_OPTIONS', paylaod.options || {})
    commit('SET_OPENED_MENU', paylaod.openedItem || null)
    commit('SET_OPENED_MENU', paylaod.openedItem || null)
    commit('SET_PAGE', 'section-settings')
  },
  codeInjection ({dispatch}) {
    VEvent.fire('loader', true)

    dispatch('projects/save', {}, {root: true})
      .finally(() => {
        VEvent.fire('loader', false)
      })
  }
}

const mutations = {
  ADD_ELEMENT_IN_STACK (state, element) {
    state.stackElements.unshift(element)
  },
  CLEAR_ELEMENTS_IN_STACK (state) {
    state.stackElements = []
  },
  ADD_NAVBAR (state, {section}) {
    state.globalStyles.navbar = Object.assign({}, state.globalStyles.navbar, {
      section
    })
  },
  ADD_FOOTER (state, {section}) {
    state.globalStyles.footer = Object.assign({}, state.globalStyles.footer, {
      section
    })
  },
  PICK_SUBJECT (state, {pick, config, grabAll = false}) {
    state.pickSubject = pick
    state.config = config
    state.grabAll = grabAll
  },
  REMOVE_NAVBAR (state) {
    state.globalStyles.navbar.section = null
  },
  REMOVE_FOOTER (state) {
    state.globalStyles.footer.section = null
  },
  SET_TIPTAP (state, payload) {
    state.tiptap = payload
  },
  SET_RESOLUTION (state, payload) {
    state.resolution = payload
  },
  SET_TABLET (state, payload) {
    state.tablet = payload
  },
  SET_CONFIG (state, payload) {
    state.config = payload
  },
  SET_SECTION_MODAL (state, payload) {
    state.newSectionModal = payload
  },
  SET_SECTION (state, payload) {
    state.section = payload
  },
  SET_OPENED_MENU (state, payload) {
    state.openedItem = payload
  },
  SET_EXPANDED_GROUP (state, payload) {
    state.expandedGroup = payload ? payload : null
  },
  SET_PAGE (state, payload) {
    document.querySelectorAll('[custom-embed-wrapper]')
      .forEach(item => item.parentNode.removeChild(item))
    window._wf = undefined

    if (typeof payload === 'object') {
      state.page = payload.name
      state.addSubjectEvent = payload.event
      state.currentSubject = payload.subject
    } else {
      if (payload !== 'blocks-menu') {
        state.addSubjectEvent = null
        state.currentSubject = null
      }

      state.page = payload
    }
  },
  SET_ELEMENT (state, payload) {
    state.element = payload
  },
  SET_OPENED_ITEM_OPTIONS (state, payload) {
    state.openedItemOptions = payload
  },
  RESET (state) {
    state.section = null
    state.openedItem = null
  },
  SET_SUBJECT_EVENT (state) {
    state.addSubjectEvent = state
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
