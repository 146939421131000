<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element"
    :dublicate-deep="parentUID"
    :dublicate="section"
    :data-sid="section.sid"
    @add-subject="$emit('add-subject')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings('compare')"
  >
    <div
      :data-sid="section.sid"
    >
      <div
        class="compare-slider"
        :class="[`--${section.sid}--`, {'element-hidden': !visability('compare')}, {'vertical': vertical}]"
        @mousedown="startDrag"
        @click="evt => drag(evt, true)"
      >
        <div
          class="wrapper"
          :style="{...(vertical ? {height: progress} : {width: progress})}"
        >
          <img
            :src="section.options.content.compare.beforeImageURL"
            alt=""
            class="after-image"
            draggable="false"
          >
        </div>
        <img
          :src="section.options.content.compare.beforeImageURL"
          alt=""
          class="before-image"
          draggable="false"
        >

        <div
          class="handler"
          :style="{...(vertical ? {top: progress} : {left: progress})}"
        >
          <div class="handler-button">
            <i
              class="handler-icon"
              :class="section.options.content.compare.handleIcon"
            />
          </div>
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'

export default {
  name: 'ElCompareSlider',
  components: {Highliter},
  mixins: [SectionMixin],
  data () {
    return {
      isDragging: false,
      progress: '50%'
    }
  },
  computed: {
    vertical () {
      return this.section.options.content.compare.vertical
    }
  },
  methods: {
    startDrag () {
      this.isDragging = true
      document.addEventListener('mousemove', this.drag)
      document.addEventListener('mouseup', this.stopDrag)
    },

    drag (event, force = false) {
      if (!this.isDragging && !force) return
      const offset = this.$el.querySelector('.compare-slider').getBoundingClientRect()
      const smoothOnClickEls = [this.$el.querySelector('.wrapper'), this.$el.querySelector('.handler')]

      if (force) {
        smoothOnClickEls.forEach(el => el.style.transition = '.25s ease-out')
      } else {
        smoothOnClickEls.forEach(el => el.style.transition = null)
      }

      if (this.vertical) {
        const iTopWidth = ((event.pageY - offset.top) / offset.height) * 100

        if (iTopWidth < 0 || iTopWidth > 100) return

        this.progress = `${iTopWidth}%`
      } else {
        const iTopWidth = ((event.pageX - offset.left) / offset.width) * 100

        if (iTopWidth < 0 || iTopWidth > 100) return

        this.progress = `${iTopWidth}%`
      }
    },

    stopDrag () {
      this.isDragging = false
      document.removeEventListener('mousemove', this.drag)
      document.removeEventListener('mouseup', this.stopDrag)
    }
  }
}
</script>

<style lang="scss">
.compare-slider {
  --handler-size: 2px;
  position: relative;
  overflow: hidden;
  cursor: col-resize;
  margin: 20px;

  img {
    user-select: none;
    max-width: none;
    width: 100%;
    top: 0;
    display: block;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .before-image {
    position: static;
    z-index: 1;
    left: 0px;
  }

  .after-image {
    height: 100%;
    position: absolute;
    z-index: 2;
    left: 0;
    width: auto;
  }

  .wrapper {
    height: 100%;
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
  }

  .handler {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: var(--handler-size);
    position: absolute;
    top: 0;
    right: 0;
    background: #3d82ea;
    z-index: 10;

    &-button {
      position: absolute;
      width: 40px;
      height: 40px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid #3d82ea;
    }

    &-icon {
      font-size: 18px;
      color: #3d82ea;
    }
  }

  &.vertical {
    cursor: row-resize;

    .wrapper {
      width: 100%;
      height: 50%;
    }

    .after-image {
      height: unset;
      width: 100%;
    }

    .handler {
      width: 100%;
      height: var(--handler-size);
    }
  }
}
</style>