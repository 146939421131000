<template>
  <div
    class="items-center flex gap-[6px] p-[6px] text-uppercase rounded-[3px] text-[10px] font-[700] w-[153px]"
    :class="variants[variant].class"
  >
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_107_494)">
        <path
          d="M6.75 8.25C6.75 8.66421 6.41421 9 6 9C5.58579 9 5.25 8.66421 5.25 8.25C5.25 7.83579 5.58579 7.5 6 7.5C6.41421 7.5 6.75 7.83579 6.75 8.25Z"
          :fill="variants[variant].color"
        />
        <path
          d="M5.78222 3C5.49381 3 5.2652 3.24334 5.28319 3.53119L5.48534 6.76559C5.49358 6.89735 5.60284 7 5.73485 7H6.26513C6.39714 7 6.50641 6.89735 6.51464 6.76559L6.71679 3.53119C6.73478 3.24334 6.50618 3 6.21776 3H5.78222Z"
          :fill="variants[variant].color"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1Z"
          :fill="variants[variant].color"
        />
      </g>
      <defs>
        <clipPath id="clip0_107_494">
          <rect
            width="12"
            height="12"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>

    {{ variants[variant].text }}
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    variant: {
      type: String,
      default: 'awaiting'
    }
  },
  data () {
    return {
      variants: {
        ['awaiting approval']: {
          color: '#D1AB49',
          class: 'text-[#D1AB49] bg-[#FFF0C9]',
          text: 'Awaiting Approval'
        },
        awaiting: {
          color: '#D1AB49',
          class: 'text-[#D1AB49] bg-[#FFF0C9]',
          text: 'Awaiting Approval'
        },
        approved: {
          color: '#5C8A46',
          class: 'text-[#5C8A46] bg-[#E2F5D9]',
          text: 'Approved'
        },
        rejected: {
          color: '#C11B1B',
          class: 'text-[#C11B1B] bg-[#FDEBEB]',
          text: 'Rejected'
        },
        blocked: {
          color: '#131A24',
          class: 'text-[#131A24] bg-[#D8D8D8]',
          text: 'Rejected'
        },
        hidden: {
          color: '#AAAAAA',
          class: 'text-[#AAAAAA] bg-[#F2F2F2]',
          text: 'Hidden'
        }
      }
    }
  }
}
</script>