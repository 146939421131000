<template>
  <div>
    <draggable
      v-model="config.subjects"
      handle=".move"
    >
      <transition-group name="shufle">
        <SidbearSettingsGroup
          v-for="(image, index) in config.subjects"
          :key="`index-${index}`"
          :active="getActiveImage === index"
          :title="`${image.id} Image`"
          content-class="pb-0"
          move
        >
          <component
            :is="image.options.content.image.type"
            :config="image.options.content.image"
            :hide="['scale', 'align']"
            :local-section="image"
            component-group-name="div"
            component-name="Image"
          />
        </SidbearSettingsGroup>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ModMixin from '@builder/components/mixins/ModMixin'
import ImageUpload from '../utils/ImageUpload'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'

export default {
  name: 'ImagesMod',
  components: {draggable, SidbearSettingsGroup, ImageUpload},
  mixins: [ModMixin],
  computed: {
    getActiveImage () {
      if (this.config.subjects) {
        this.config.subjects.forEach((image, index) => {
          if (!image.id) {
            this.$set(image, 'id', index + 1)
          }
        })
      }

      if (this.getOpenedItemObj) {
        return this.getOpenedItemObj.index
      }

      return 0
    }
  }
}
</script>
