import html2canvas from 'html2canvas'

export default {
  methods: {
    takeScreenshot (el) {
      return new Promise((resolve) => {
        const divElement = el
        const scrollContainer = document.querySelector('.scrollbale-container')

        if (scrollContainer) {
          scrollContainer.classList.remove('overflow-auto')
        }

        console.log(divElement.scrollHeight)

        html2canvas(divElement, {
          width: divElement.offsetWidth,
          height: divElement.scrollHeight,
          scrollX: 0,
          scrollY: 0,
          imageTimeout: 5000,
          useCORS: true,
          allowTaint: true
        }).then(function (canvas) {
          const imageDataUrl = canvas.toDataURL('image/png')
          return resolve(imageDataUrl)
        })

        if (scrollContainer) {
          scrollContainer.classList.add('overflow-auto')
        }
      })
    }
  }
}