<template>
  <span
    class="el-row-resizer"
    @mousedown="mousedown"
  />
</template>

<script>
export default {
  name: 'ElRowResizeHandler',

  props: {
    columnIndex: {
      type: Number,
      required: true
    }
  },

  methods: {
    mousedown (event) {
      this.$emit('on-mousedown', {
        $el: event.target,
        columnIndex: this.columnIndex,
        e: event
      })
    }
  }
}
</script>

<style lang="scss">
.el-row {
  .el-row-resizer.active {
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    right: 0px;
    background: #7CACFA;
    cursor: col-resize;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 56px;
      left: 50%;
      background: #FFFFFF;
      border: 2px solid #7CACFA;
      box-sizing: border-box;
      border-radius: 50px;
      z-index: 1;
    }
  }
}

.el-row {
  .el-row {
    .el-row-resizer.active {
      right: 0 !important;
    }
  }
}
</style>
