<script>
import {toJSON} from '@/utils/helpers'
import {mapState} from 'vuex'

export default {
  name: 'EstageTrackingActions',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      action: '',
      tagsResponders: ['ontraport']
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    actions () {
      const actions = [
        {
          label: 'Snapshot Users',
          value: 'SNAPSHOT_USERS',
          data: {}
        }
      ]

      if (this.tagsVariables.length) {
        actions.push({
          label: 'Add Tag',
          value: 'user_tag',
          data: {}
        })
      }

      if (this.listVariables.length) {
        actions.push( {
          label: 'Add List',
          value: 'add_list',
          data: {}
        })
      }

      return actions
    },
    tagsVariables () {
      if (!this.project.content || this.project.content === '{}') {
        return  []
      }

      return toJSON(this.project.content).filter(({responder}) => this.tagsResponders.includes(responder))
    },
    listVariables () {
      if (!this.project.content || this.project.content === '{}') {
        return  []
      }

      return toJSON(this.project.content).filter(({responder}) => !this.tagsResponders.includes(responder))
    }
  },
  methods: {
    selectedAction (val) {
      this.action = ''
      this.event.actions = [val, ...this.event.actions]
    },
    removeAction (index) {
      this.event.actions.splice(index, 1)
    }
  }
}
</script>

<template>
  <div class="mb-4">
    <b-form-group :label="`${event.label} Actions`">
      <v-select
        v-model="action"
        :options="actions"
        :searchable="false"
        class="custom-select"
        left
        placeholder="Select Action"
        @option:selected="selectedAction"
      />
    </b-form-group>

    <b-form-group v-if="event.actions.length">
      <div class="flex flex-wrap gap-[3px] border p-[9px] rounded-[2px] mb-4">
        <div
          v-for="(action, index) in event.actions"
          :key="action.value"
          class="flex items-center bg-[#3D82EA] py-[3px] px-[7px] rounded-[3px] text-[11px] font-[700] text-white"
        >
          <span
            class="mr-[7px]"
            v-text="action.label"
          />

          <button
            class="hover:opacity-80 p-0 m-0"
            @click="() => removeAction(index)"
          >
            <svg
              fill="none"
              height="7"
              viewBox="0 0 6 7"
              width="6"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.640159 0.609834C0.493714 0.463389 0.256279 0.463389 0.109834 0.609834C-0.0366113 0.756279 -0.0366113 0.993714 0.109834 1.14016L2.46968 3.5L0.109834 5.85984C-0.0366111 6.00629 -0.0366113 6.24372 0.109834 6.39017C0.256279 6.53661 0.493714 6.53661 0.640159 6.39017L3 4.03033L5.35978 6.39011C5.50623 6.53656 5.74366 6.53656 5.89011 6.39011C6.03656 6.24366 6.03656 6.00623 5.89011 5.85978L3.53033 3.5L5.89011 1.14022C6.03656 0.99377 6.03656 0.756336 5.89011 0.609891C5.74366 0.463445 5.50623 0.463445 5.35978 0.609891L3 2.96968L0.640159 0.609834Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>

      <div
        v-for="(action, index) in event.actions"
        :key="`action-${index}`"
      >
        <b-form-group
          v-if="action.value === 'user_tag'"
          label="Add tag action"
        >
          <v-select
            v-model="action.data"
            :options="tagsVariables"
            :searchable="false"
            append-to-body
            class="custom-select mb-2"
            :reduce="val => val.key"
            label="name"
          />

          <b-link
            :to="{name:'settings.global-tracking'}"
            class="text-decoration-none mb-[12px]"
          >
            <span
              class="flex relative w-[123px] text-[#333333] text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
            >
              Event Action Settings
              <span class="absolute w-full h-[1px] bg-[#333333] left-0 bottom-0 translate-y-[-1px]" />
            </span>
          </b-link>
        </b-form-group>

        <b-form-group
          v-else-if="action.value === 'add_list'"
          label="Add list action"
        >
          <v-select
            v-model="action.data"
            :options="listVariables"
            :searchable="false"
            append-to-body
            class="custom-select mb-2"
            :reduce="val => val.key"
            label="name"
          />

          <b-link
            :to="{name:'settings.global-tracking'}"
            class="text-decoration-none mb-[12px]"
          >
            <span
              class="flex relative w-[123px] text-[#333333] text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
            >
              Event Action Settings
              <span class="absolute w-full h-[1px] bg-[#333333] left-0 bottom-0 translate-y-[-1px]" />
            </span>
          </b-link>
        </b-form-group>
      </div>
    </b-form-group>
  </div>
</template>