<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="el-dynamic"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('data')"
  >
    <div
      :data-sid="section.sid"
      :class="`--post-featured-text-${section.sid}`"
    >
      <div
        class="post-featured-text"
        v-text="text"
      />
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'

export default {
  name: 'ElFeaturedPostText',
  components: {Highliter},
  mixins: [SectionMixin],
  computed: {
    text () {
      const text = 'Curabitur convallis urna non sem faucibus, vitae egestas odio facilisis. Proin egestas vestibulum eros id posuere. Maecenas eu libero facilisis, egestas purus ac, ullamcorper elit. Fusce tristique ante mollis lacinia ullamcorper. Fusce bibendum cursus mauris eu gravida. Morbi non elementum enim, sit amet pellentesque magna. Duis eu risus tincidunt diam aliquam pulvinar vel in ex. Duis eleifend ipsum vel nulla dapibus blandit.'
      let max = this.section.options.content.data.maxWords

      if (!max) return text

      // Split the text into an array of words
      const words = text.split(' ')

      // Extract the first "limit" words
      const limitedWords = words.slice(0, max)

      // Join the words back into a string
      const limitedText = limitedWords.join(' ')

      // Add ellipsis if the text was truncated
      if (words.length > max) {
        return `${limitedText}...`
      }

      return limitedText
    }
  }
}
</script>

<style lang="scss">
 .post-featured-text {
   font-weight: 400;
   font-size: 16px;
   line-height: 36px;
   color: #667085;
   margin-bottom: 20px;
 }
</style>