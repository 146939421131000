<template>
  <div>
    <b-form-group>
      <div class="d-flex align-items-center">
        <div
          :class="{'active': sidValueMutation === 'none'}"
          class="shadow-control-box text-uppercase"
          @click="() => selectShadow('')"
        >
          <icon icon="shadow-none.svg" />
        </div>

        <div
          v-for="(shadow, index) in shadows"
          :key="index"
          :class="['custom-shadow-' + shadow.key, {'active': sidValueMutation === shadow.css}]"
          class="shadow-control-box text-uppercase"
          @click="() => selectShadow(shadow.css)"
        >
          {{ shadow.key }}
        </div>
      </div>
    </b-form-group>

    <b-form-group>
      <color-picker
        :color="shadow.color"
        @input="updateColor"
      />
    </b-form-group>

    <b-form-group
      v-for="control in controls"
      :key="control.key"
      :label="control.label"
    >
      <div class="flex items-center gap-[10px]">
        <vue-slider
          v-model="shadow[control.key]"
          class="editor-slider-1 !w-[160px]"
          :interval="1"
          :dot-size="17"
          tooltip="none"
          :min="control.min"
          :max="control.max"
          @change="onChange"
        />

        <LinkedControlItem
          v-model="shadow[control.key]"
          :min="control.min"
          :max="control.max"
          class="d-block !w-[60px]"
          @input="onChange"
        />
      </div>
    </b-form-group>
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'
import LinkedControlItem from '@/components/editor/components/linked-control-group/LinkedControlItem'

import { parse, stringify } from '@/utils/box-shadow-parse'

export default {
  name: 'SidBoxShadow',
  components: {LinkedControlItem},
  mixins: [SidConcept],
  data () {
    return {
      shadows: [
        {
          key: 's',
          css: 'rgba(0, 0, 0, 0.075) 0px 0px 2rem 5px'
        },
        {
          key: 'm',
          css: 'rgba(0, 0, 0, 0.15) 0px 0px 15px 10px'
        },
        {
          key: 'l',
          css: 'rgba(0, 0, 0, 0.176) 0px 0px 20px 20px'
        }
      ],
      controls: [
        {
          label: 'Offset-X',
          key: 'offsetX',
          min: -100,
          max: 100
        },
        {
          label: 'Offset-Y',
          key: 'offsetY',
          min: -100,
          max: 100
        },
        {
          label: 'Blur',
          key: 'blurRadius',
          min: 0,
          max: 100
        },
        {
          label: 'Spread',
          key: 'spreadRadius',
          min: 0,
          max: 100
        }
      ],
      shadow: {
        inset: false,
        color: '#eee',
        offsetX: 0,
        offsetY: 0,
        blurRadius: 0,
        spreadRadius: 0
      }
    }
  },
  created () {
    this.parseShadow()
  },
  methods: {
    parseShadow () {
      if (this.sidValueMutation.length) {
        this.shadow = parse(this.sidValueMutation)[0]

        if (this.shadow.color === 'none') {
          this.shadow.color = 'rgba(0,0,0,.15)'
        }
      }
    },
    parseShadowToString () {
      if (!this.shadow.spreadRadius) return ''
      return stringify([this.shadow])
    },
    updateColor (event) {
      this.shadow.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`
      this.onChange()
    },
    onChange () {
      this.addSidRules([[this.getSid(), [this.ruleName, this.parseShadowToString()]]])
    },
    selectShadow (css) {
      this.addSidRules([[this.getSid(), [this.ruleName, css]]])
      this.parseShadow()
    }
  }
}
</script>