import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    globalStyleButton () {
      return this.globalStyles.button
    }
  }
}