<template>
  <b-form @submit.prevent="integrate">
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Enter access key"
          class="mb-1"
        >
          <b-input
            v-model="key"
            required
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <p class="d-flex">
          <span class="d-flex align-items-center mr-2">
            <b-link
              class="underline"
              target="_blank"
              href="https://unsplash.com/oauth/applications"
            >Create a new application
              <i class="fa fa-external-link-alt small ml-1" /></b-link>
          </span>
          to get access key in Unsplash.
        </p>

        <b-btn
          type="submit"
          variant="primary"
        >
          Integrate
        </b-btn>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
export default {
  name: 'UnsplashIntegration',

  data () {
    return {
      key: ''
    }
  },

  methods: {
    async integrate () {
      await this.$store.dispatch('apiIntegration/unsplashIntegrate', this.key)
        .then(() => {
          this.$emit('integrated')
        })
    }
  }
}
</script>
