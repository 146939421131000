<template>
  <highliter
    v-model="section.name"
    :class="{'element-hidden': !visability('menu')}"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('menu')"
  >
    <Template :section="section">
      <div
        class="flex flex-wrap"
        :style="{gap, fontWeight, textTransform, letterSpacing, lineHeight}"
        :class="[align, isColumn]"
      >
        <menu-link-v3
          v-for="(link, index) in links"
          :key="index"
          :link="link"
          :menu="menu"
        />
      </div>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import Template from './Template'
import MenuLinkV3 from '@/components/builder/elements/17.menu/v3/MenuLinkV3'

export default {
  name: 'FeaturedItems1',
  components: {MenuLinkV3, Highliter, Template},
  mixins: [SectionMixin],
  computed: {
    menu () {
      return this.section.options.content.menu
    },
    links () {
      return this.menu.links
    },
    align () {
      const className = this.isColumn === 'flex-column' ? 'align-items-' : 'justify-content-'

      if (this.menu.align[this.resolutioner] === null) return `${className}${this.menu.align.lg}`
      return `${className}${this.menu.align[this.resolutioner] || this.menu.align.lg}`
    },
    isColumn () {
      if (this.menu.column[this.resolutioner] === null) this.menu.column.lg ? 'flex-column' : ''
      return this.menu.column[this.resolutioner] ? 'flex-column' : ''
    },
    gap () {
      return `${this.menu.gap[this.resolutioner] || this.menu.gap.lg}px`
    },
    fontWeight () {
      return this.menu.bold.bold ? 900 : null
    },
    textTransform () {
      return this.menu.textTransform[this.resolutioner] || this.menu.textTransform.lg
    },
    letterSpacing () {
      return `${this.menu.letterSpacing[this.resolutioner] || this.menu.letterSpacing.lg}px`
    },
    lineHeight () {
      return this.menu.lineHeight[this.resolutioner] || this.menu.lineHeight.lg
    }
  }
}
</script>
