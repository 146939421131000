<template>
  <component
    :is="tag"
    v-if="visability"
    :data-aos="animations.animation"
    :data-aos-delay="animations.delay"
    :data-aos-duration="animations.duration || 400"
    data-aos-offset="200"
    data-aos-once="true"
    :class="[{'text-gradient': backgroundGradient}]"
    :style="{transform}"
    class="mb-0"
  >
    <div v-html="linksStyle" />
    <div
      :class="type"
      :style="style"
      class="pub__content editor__content"
      style="overflow-wrap: anywhere;"
      v-html="typeof value === 'object' ? editor.getHTML(): value"
    />
  </component>
</template>

<script>
import {Editor, EditorContent, EditorMenuBubble} from 'tiptap'
import {
  Blockquote,
  Bold,
  Code,
  CodeBlock,
  HardBreak,
  Heading,
  History,
  Italic,
  Strike,
  Underline
} from 'tiptap-extensions'

import TextColor from '@builder/plugins/TiptapTextColor'
import ComponentProvider from '@builder/components/mixins/ComponentProvider'
import {mapState} from 'vuex'
import FontWeight from '@builder/plugins/TiptapFontWeight'
import Animations from '@builder/components/mixins/Animations'
import _ from 'lodash'
import ResolutionMixin from '../../mixins/ResolutionMixin'
import Selection from '@/plugins/TiptapSelection'
import FontSize from '@/plugins/TiptapFontSize'
import BackgroundColor from '@/plugins/TiptapBackgroundColor'
import FontWeight2 from '@/plugins/TiptapFontWeight2'
import FontWeight3 from '@/plugins/TiptapFontWeight3'
import CustomLink from '@/plugins/TiptapCustomLink'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'PubContentEditable',

  components: {
    EditorContent,
    EditorMenuBubble
  },

  mixins: [ComponentProvider, ResolutionMixin, Animations, BorderMixin],

  props: {
    value: {
      required: true
    },
    hardStyles: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'paragraph'
    }
  },

  data () {
    return {
      keepInBounds: false,
      editor: new Editor({
        extensions: [
          new Selection(),
          new Blockquote(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({levels: [1, 2, 3]}),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new TextColor(),
          new Underline(),
          new History(),
          new FontSize(),
          new BackgroundColor(),
          new FontWeight(),
          new FontWeight2(),
          new FontWeight3(),
          new CustomLink()
        ],
        onUpdate: ({getJSON}) => {
          const content = getJSON()
          this.$emit('input', content)
        },
        content: this.value
      })
    }
  },

  computed: {
    backgroundGradient () {
      const _backgroundGradient = _.get(this.customs.resolutionStyle, [this.resolutioner, 'backgroundGradient'], null) || this.customs.resolutionStyle.lg.backgroundGradient
      if (_backgroundGradient) {
        const backgroundColorSubject = (attrs) => {
          if (!attrs) return null

          const gradient = () => {
            const values = attrs.map(gradient => {
              return `${gradient.color} ${gradient.position}%`
            })
            return `linear-gradient(90deg,${values})`
          }

          return gradient()
        }

        return {
          backgroundImage: backgroundColorSubject(_backgroundGradient)
        }
      }

      return null
    },
    transform () {
      return `rotate(${parseInt(_.get(this.customs, ['rotate', this.resolutioner], _.get(this.customs, ['rotate', 'lg'], 0)))}deg)`
    },
    linksStyle () {
      const styles = []
      const $virtualEl = document.createElement('div')
      $virtualEl.innerHTML = this.editor.getHTML()

      $virtualEl.querySelectorAll('[styleeffect]').forEach(item => {
        const _style = JSON.parse(item.getAttribute('styleeffect'))
        styles.push(`
          #${item.getAttribute('id')} {
            color:${_style.normal.color};
            font-weight:${_style.normal.bold ? 'bold' : 'inherit'};
            text-decoration:${_style.normal.underline ? 'underline' : 'inherit'};
            font-style:${_style.normal.italic ? 'italic' : 'inherit'};
          }
          #${item.getAttribute('id')}:hover {
            color:${_style.hover.color} !important;
            font-weight:${_style.hover.bold ? 'bold' : 'inherit'};
            text-decoration:${_style.hover.underline ? 'underline' : 'inherit'};
            font-style:${_style.hover.italic ? 'italic' : 'inherit'};
          }
        `)
      })

      return `
        <style type="text/css">${styles.join('')}</style>
      `
    },
    tag () {
      if (!this.customs.tag) {
        return this.type === 'headline' ? 'h3' : 'div'
      }

      return this.customs.tag
    },
    ...mapState('editor', {
      resolution: state => state.resolution
    }),
    fontFamaly () {
      return _.get(this.customs, ['resolutionStyle', 'lg', 'fontFamily'], null)
    },
    color () {
      return _.get(this.customs, ['resolutionStyle', 'lg', 'color'], null)
    },
    fontSize () {
      return _.get(this.customs.resolutionStyle, [this.resolutioner, 'fontSize'], null) || this.customs.resolutionStyle.lg.fontSize
    },
    fontWeight () {
      return _.get(this.customs, ['resolutionStyle', this.resolutioner, 'fontWeight'], null) || this.customs.resolutionStyle.lg.fontWeight
    },
    align () {
      return _.get(this.customs.resolutionStyle, [this.resolutioner, 'align'], null)
    },
    letterSpacing () {
      return `${_.get(this.customs, ['resolutionStyle', this.resolutioner, 'letterSpacing'], null)}px`
    },
    lineHeight () {
      if (Object.prototype.hasOwnProperty.call(this.customs.resolutionStyle[this.resolution], 'lineHeight')) {
        return this.customs.resolutionStyle[this.resolution].lineHeight
      }

      return null
    },
    textTransform () {
      return _.get(this.customs, ['textTransform', this.resolutioner], null) || _.get(this.customs, ['textTransform', 'lg'], null)
    },
    textShadow () {
      return _.get(this.customs.resolutionStyle[this.resolutioner], 'textShadow', {
        enabled: false,
        x: 0,
        y: 0,
        blur: 0,
        color: '#dddddd'
      })
    },
    style () {

      if (this.hardStyles) {
        return this.hardStyles
      }

      return {
        fontWeight: this.fontWeight,
        color: this.color,
        textAlign: this.align,
        fontFamily: this.fontFamaly,
        fontSize: this.fontSize,
        textTransform: this.textTransform,
        lineHeight: this.lineHeight,
        letterSpacing: this.letterSpacing,
        textShadow: parseInt(this.textShadow.blur) > 0 ? `${this.textShadow.x}px ${this.textShadow.y}px ${this.textShadow.blur}px ${this.textShadow.color}` : null,
        ...this.fullPaddingSubject(this.customs, 'padding', [0, 10, 0, 0]),
        ...this.backgroundGradient
      }
    }
  },

  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>

<style>
.menububble {
  position: absolute;
  display: -webkit-box;
  display: flex;
  z-index: 20;
  background: #000;
  border-radius: 5px;
  padding: .3rem;
  margin-bottom: .5rem;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .2s, visibility .2s;
  transition: opacity .2s, visibility .2s
}

.menububble.is-active {
  opacity: 1;
  visibility: visible
}

.menububble__button {
  display: -webkit-inline-box;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #fff;
  padding: .2rem .5rem;
  margin-right: .2rem;
  border-radius: 3px;
  cursor: pointer
}

.menububble__button:last-child {
  margin-right: 0
}

.menububble__button:hover {
  background-color: hsla(0, 0%, 100%, .1)
}

.menububble__button.is-active {
  background-color: hsla(0, 0%, 100%, .2)
}

.menububble__form {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center
}

.menububble__input {
  font: inherit;
  border: none;
  background: transparent;
  color: #fff
}

.editor__content {
  overflow-wrap: anywhere;
}

h3, .h3 {
  font-size: unset;
}
</style>
