import _ from 'lodash'
export default {
  methods: {
    getBy (arr, trackBy, value, falsyValue = {}) {
      if (!Array.isArray(arr)) return falsyValue
      return arr.find((el) => el[trackBy] == value) || falsyValue
    },
    get (obj, slug, falsyValue = '', prefix = '', suffix = '', func = '') {
      if ((!obj && typeof obj != 'object') || obj === null) return falsyValue
      let result = _.cloneDeep(obj)
      const nests = slug.split('.')

      for (const nest of nests) {
        if (result[nest] === null || typeof result[nest] === 'undefined') {
          result = falsyValue
          break
        } else {
          result = result[nest]
        }
      }

      if (result !== falsyValue) {
        if (prefix) result = prefix + result

        if (suffix) result = result + suffix

        if (func) result = func(result)
      }

      return result
    },
    set (obj, path, val) {
      const keys = path.split('.')
      const lastKey = keys.pop()
      const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj)
      lastObj[lastKey] = val
    },
    buildFormData (formData, data, parentKey, allowEmpty = true) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key, allowEmpty)
        })
      } else {
        const value = data == null ? '' : data
        if ((!allowEmpty && value) || allowEmpty) formData.append(parentKey, value)
      }
      return formData
    },
    jsonToFormData (data, allowEmpty = true) {
      const result = this.buildFormData(new FormData(), data, false, allowEmpty)
      return result
    }
  }
}
