<template>
  <div>
    <b-row class="align-items-center">
      <b-col
        cols="3"
        class="text-truncate"
      >
        <h6
          class="font-weight-bold mb-0"
          v-text="password.title"
        />
      </b-col>

      <b-col>
        <div class="position-relative">
          <b-input
            v-model="password.password"
            v-validate="'required'"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            :state="errors.has('password') ? false : null"
          />
          <div class="position-absolute top-1/2 transform -translate-y-1/2 right-8">
            <b-link @click="showPassword = !showPassword">
              <i
                v-if="showPassword"
                class="fa fa-eye"
              />
              <i
                v-else
                class="fa fa-eye-slash"
              />
            </b-link>
          </div>
        </div>
      </b-col>

      <b-col cols="4">
        <div class="d-flex align-items-center justify-content-end">
          <b-btn
            v-if="defaultState.password !== password.password"
            variant="primary"
            class="mr-2"
            :disabled="loading || deleteLoading"
            @click="startEdit"
          >
            Save Changes
            <svg
              v-if="!loading"
              class="ml-1"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M14.1427 9.00002C13.8271 9.00002 13.5712 9.25585 13.5712 9.57145V15.2856C13.5712 15.6012 13.3154 15.8571 12.9998 15.8571H2.71426C2.39866 15.8571 2.14283 15.6012 2.14283 15.2856V3.85723C2.14283 3.54163 2.39866 3.2858 2.71426 3.2858H9.57131C9.88691 3.2858 10.1427 3.02996 10.1427 2.71437C10.1427 2.39877 9.88691 2.14297 9.57131 2.14297H2.71426C1.7675 2.14297 1 2.91047 1 3.85723V15.2856C1 16.2324 1.7675 16.9999 2.71426 16.9999H12.9998C13.9466 16.9999 14.7141 16.2324 14.7141 15.2856V9.57142C14.7141 9.25585 14.4583 9.00002 14.1427 9.00002Z"
                  fill="white"
                />
                <path
                  d="M16.3595 1.64091C15.9492 1.23056 15.3927 1.00007 14.8124 1.00014C14.2318 0.998465 13.6748 1.22935 13.2656 1.64128L5.73882 9.16743C5.67638 9.23034 5.62927 9.30681 5.60111 9.39085L4.45828 12.8194C4.35853 13.1188 4.52042 13.4424 4.81985 13.5421C4.87794 13.5614 4.93877 13.5713 4.99998 13.5714C5.06132 13.5713 5.12229 13.5614 5.18054 13.5422L8.60907 12.3994C8.69328 12.3713 8.76979 12.324 8.8325 12.2611L16.3593 4.73439C17.2135 3.8802 17.2136 2.4952 16.3595 1.64091ZM15.5513 3.92694L8.12279 11.3554L5.90338 12.0965L6.64222 9.87999L14.0736 2.45154C14.4821 2.0438 15.1439 2.04447 15.5516 2.45301C15.7464 2.64817 15.8561 2.91238 15.857 3.1881C15.8577 3.4653 15.7476 3.73127 15.5513 3.92694Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(1 1)"
                  />
                </clipPath>
              </defs>
            </svg>
            <b-spinner
              v-else
              small
              variant="light"
              class="ml-1"
            />
          </b-btn>

          <delete-confirm
            title="Are you sure?"
            description="The password will be deleted, the action cannot be undone."
            @confirm="deletePassword"
          >
            <template v-slot:activator="{on}">
              <b-btn
                variant="danger"
                :disabled="loading || deleteLoading"
                v-on="on"
              >
                Delete
                <svg
                  v-if="!deleteLoading"
                  class="ml-1"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.6395 6.03585L10.3712 5.98781L10.0962 13.7429L11.3645 13.7907L11.6395 6.03585Z"
                    fill="white"
                  />
                  <path
                    d="M9.18616 6.01183H7.91699V13.7669H9.18616V6.01183Z"
                    fill="white"
                  />
                  <path
                    d="M7.00676 13.7427L6.73178 5.98759L5.46344 6.03567L5.73845 13.7907L7.00676 13.7427Z"
                    fill="white"
                  />
                  <path
                    d="M1 2.77875V4.08945H2.32249L3.37165 16.4021C3.40041 16.7405 3.67479 17 4.0037 17H13.0781C13.4071 17 13.6816 16.7403 13.7102 16.4019L14.7594 4.08945H16.1032V2.77875H1ZM12.4969 15.6893H4.5848L3.59653 4.08945H13.4853L12.4969 15.6893Z"
                    fill="white"
                  />
                  <path
                    d="M10.9443 0H6.84654C6.27014 0 5.80121 0.53181 5.80121 1.1855V3.438H7.0556V1.42261H10.7352V3.438H11.9896V1.1855C11.9896 0.53181 11.5207 0 10.9443 0Z"
                    fill="white"
                  />
                </svg>
                <b-spinner
                  v-else
                  small
                  variant="light"
                  class="ml-1"
                />
              </b-btn>
            </template>
          </delete-confirm>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DeleteConfirm from '../modals/DeleteConfirm'
import {mapState} from 'vuex'
export default {
  name: 'PagePassword',
  components: {DeleteConfirm},
  props: {
    password: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      deleteLoading: false,
      defaultState: {},
      showPassword: false,
      editModal: false
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  created () {
    this.defaultState = JSON.parse(JSON.stringify(this.password))
  },
  methods: {
    deletePassword () {
      this.deleteLoading = true

      axios.delete(`api/projects/${this.project.id}/passwords/${this.password.id}`)
        .finally(() => {
          this.$emit('deleted')

          this.deleteLoading = false

          this.$swal({
            icon: 'success',
            title: 'Restriction deleted successfully!',
            showConfirmButton: false,
            timer: 1500
          })
        })
    },
    startEdit () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true

          axios.put(`api/projects/${this.project.id}/passwords/${this.password.id}`, this.password)
            .finally(() => {
              this.$emit('submited')

              this.loading = false

              this.defaultState = JSON.parse(JSON.stringify(this.password))

              this.$swal({
                icon: 'success',
                title: 'Restriction updated successfully!',
                showConfirmButton: false,
                timer: 1500
              })
            })
        }
      })
    }
  }
}
</script>
