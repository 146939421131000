<template>
  <highliter
    v-model="nameModifire"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :style="{...marginTop, ...marginBottom}"
    :uid="section.uid"
    :hide-favorite="section.group === 'Footer'"
    :hide-copy="section.group === 'Footer'"
    :hide-move="section.group === 'Footer'"
    class="highlight-section"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="!section.options.content.items.children.length ? addRow() : sectionSettings('real-time-visibility-mod')"
  >
    asdasd
  </highliter>
</template>

<script>
import Highliter from '@/components/builder/utils/Highliter.vue'
import SectionMixin from '@/components/mixins/SectionMixin'

export default {
  name: 'RealTimeVisibility',
  components: {Highliter},
  mixins: [SectionMixin]
}
</script>

<style scoped>

</style>