<template>
  <div>
    <v-select
      v-model="fontFamily"
      placeholder="Select Font Family"
      :filterable="false"
      :options="['Global Style', ...paginated]"
      @open="onOpen"
      @close="onClose"
      @search="(query) => (search = query)"
    >
      <template #selected-option="{label}">
        <div
          :style="{fontFamily: label}"
          v-text="label"
        />
      </template>

      <template #option="{label}">
        <div
          :style="{fontFamily: label}"
          v-text="label"
        />
      </template>

      <template #list-footer>
        <li
          v-show="hasNextPage"
          ref="load"
          class="loader"
        >
          Loading more options...
        </li>
      </template>
    </v-select>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'FontFamily',
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      observer: null,
      limit: 10,
      search: ''
    }
  },
  computed: {
    ...mapState('font', {
      fonts: state => state.fonts
    }),
    fontFamily: {
      get () {
        return this.value ? this.value : 'Global Style'
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    filtered () {
      return this.fonts.filter((font) => font.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    }
  },
  mounted () {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.limit = 10
      this.observer.disconnect()
    },
    async infiniteScroll ([{isIntersecting, target}]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    }
  }
}
</script>