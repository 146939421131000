import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Navbar'
export const group = 'navbar'
export const defaultOptions = {
  'customize': {
    'style': {
      'navbarScroller': {
        'type': 'navbar-scroller-customs',
        'scrollType': null
      }
    },
    'display': true
  },
  'content': {
    'logo': {
      'type': 'logo-mod',
      'display': true,
      'fontFamily': null,
      'fontWeight': 'bold',
      'fontSize': '24px',
      'color': '#0C226C',
      'text': 'AppStarter'
    },
    'menuItems': {
      'type': 'menu-items-mod-v2',
      'display': true,
      'bringToFront': {
        'lg': true
      }
    },
    'subject': {
      'uid': 'WKtPEOYr2',
      'sid': 'lmkmgsy8--aPi17aj2IA',
      'name': 'Flex Wrapper',
      'hideFromSections': false,
      'component': 'ElFlexWrapperV2',
      'group': 'navbar',
      'layout': null,
      'specialCols': {
        'navigation': {
          'special': true,
          'sid': 'lmkmgsy8--m-2KPbuC_1',
          'placement': 'bottom',
          'hamburgerIcon_open': 'fa fa-bars',
          'hamburgerIcon_close': 'fa fa-times',
          'hamburgerIcon_color': '#656565',
          'showOn': 'lg',
          'name': 'Nav Column',
          'elements': [
            {
              'uid': 'cPiyGAmyr',
              'display': true,
              'name': 'Menu Links',
              'sid': 'global--8uQWVAoMUU',
              'hideFromSections': false,
              'component': 'ElMenuLinksV4',
              'group': 'Navigation',
              'ignoreGroup': false,
              'layout': null,
              'options': {
                'content': {
                  'menu': {
                    'display': true,
                    'type': 'menu-links-mod-v4',
                    'links': [
                      {
                        'id': 'WA5HzHs_E-',
                        'href': '#',
                        'text': 'Features',
                        'customLink': false,
                        'blank': false,
                        'itemType': '',
                        'children': []
                      },
                      {
                        'id': 'Jn-ucXajZ-',
                        'href': '#',
                        'text': 'Download',
                        'customLink': false,
                        'blank': false,
                        'itemType': '',
                        'children': []
                      },
                      {
                        'id': 'WxNom-a_dA',
                        'href': '#',
                        'text': 'Buy',
                        'customLink': false,
                        'blank': false,
                        'itemType': '',
                        'children': []
                      }
                    ],
                    'hoverAnimation': 'hover-float'
                  }
                }
              },
              'editor': 'project'
            }
          ],
          'highlight': false,
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          },
          'sortIndex': {
            'lg': 1,
            'md': 1,
            'sm': 1
          },
          'uid': 'f92axRLoH',
          'navbarBackground': {
            'background': '',
            'gradient': {
              'position': '',
              'color': ''
            }
          },
          '_uid': 'bRG-qBC_fJ'
        }
      },
      'options': {
        'content': {
          'display': true,
          'cols': [
            {
              'display': true,
              'name': 'Flex Column',
              'elements': [
                {
                  'uid': '5BuboX3cH',
                  'display': true,
                  'name': 'Subheadline',
                  'sid': 'global--CciAoOg7o9',
                  'hideFromSections': false,
                  'component': 'ElSubheadlineV2',
                  'group': 'Text',
                  'ignoreGroup': false,
                  'layout': null,
                  'options': {
                    'content': {
                      'description': {
                        'type': 'tiptap-mod-v2',
                        'display': true,
                        'tag': 'h4',
                        'resolutionStyle': {
                          'sm': {},
                          'md': {},
                          'lg': {}
                        },
                        'text': '<p>Applogo</p>',
                        'animations': {
                          'animation': null,
                          'once': true,
                          'offset': 200,
                          'delay': 50
                        }
                      }
                    },
                    'customize': null
                  },
                  'editor': 'project'
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              },
              'sortIndex': {
                'lg': 0,
                'md': 0,
                'sm': 0
              },
              'sid': 'lmkmgsy8--9hBtR2HuPG',
              'uid': 'BEP2RW2uqS',
              'highlight': false,
              '_uid': 'FKYV5-AHA'
            },
            {
              'special': true,
              'key': 'navigation',
              'mod': 'navigation-flex-col-mod-v2',
              'name': 'Nav Column',
              'sid': 'Uz96GLEcGy',
              'sortIndex': {
                'lg': 1,
                'md': 1,
                'sm': 1
              }
            }
          ],
          'animations': {
            'animation': null,
            'once': true,
            'offset': 200,
            'delay': 50
          }
        }
      },
      'temp_id': 'd3G2s-XNq'
    }
  }
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('NavbarV3', options)

    this.name = name
    this.group = group
    this.sid = ['global']
    this.sids = [
      'global',
      'lmkmgsy8--aPi17aj2IA',
      'lmkmgsy8--9hBtR2HuPG',
      'global--CciAoOg7o9',
      'lmkmgsy8--m-2KPbuC_1',
      'global--8uQWVAoMUU'
    ]

    this.css = [
      [
        '.--lmkmgsy8--aPi17aj2IA--container { padding-right: 0px; }',
        '.--lmkmgsy8--9hBtR2HuPG--flex-col-padding { padding: 0px; }',
        '.--lmkmgsy8--m-2KPbuC_1--flex-col-padding { padding: 0px; }',
        '.--lmkmgsy8--9hBtR2HuPG--flex-col-el { width: 20%; background-color: rgba(255, 255, 255, 0); }',
        '.--lmkmgsy8--m-2KPbuC_1--flex-col-el { width: 100%; }',
        '.--lmkmgsy8--aPi17aj2IA--flex { max-width: 1200px; --width: 1200px; }',
        '.--global--8uQWVAoMUU--wrapper { padding: 20px; justify-content: end; align-items: end; font-family: Barlow; font-weight: 700; gap: 30px; }',
        '.--global--8uQWVAoMUU--wrapper { padding: 20px; justify-content: end; align-items: end; font-family: Barlow; font-weight: 700; gap: 30px; }',
        '.--global--8uQWVAoMUU--link { font-size: 16px; padding: 7px; border-radius: 3px; }',
        '.--global--8uQWVAoMUU--link { font-size: 16px; padding: 7px; border-radius: 3px; }',
        '.--global--CciAoOg7o9-text { padding: 20px; }',
        '.--global--CciAoOg7o9-text { padding: 20px; }',
        '.--global--CciAoOg7o9-text div { text-align: left; color: rgb(0, 0, 0); font-family: Rubik; }',
        '.--global--CciAoOg7o9-text div { text-align: left; color: rgb(0, 0, 0); font-family: Rubik; }',
        '.--global--8uQWVAoMUU--link:hover { color: rgb(255, 255, 255); background-color: rgb(14, 14, 14); padding: 7px; }',
        '.--global--8uQWVAoMUU--link:hover { color: rgb(255, 255, 255); background-color: rgb(14, 14, 14); padding: 7px; }'
      ],
      [
        '.--lmkmgsy8--aPi17aj2IA--open-icon { color: rgb(0, 0, 0); }',
        '.--lmkmgsy8--aPi17aj2IA--close-icon { color: rgb(0, 0, 0); }',
        '.--lmkmgsy8--aPi17aj2IA--icon { translate: -29px; font-size: 23px; }',
        '.--global--CciAoOg7o9-text div { text-align: center; }',
        '.--global--CciAoOg7o9-text div { text-align: center; }',
        '.--global--8uQWVAoMUU--wrapper { justify-content: center; border-width: 1px 0px; border-color: rgb(0, 0, 0); margin-bottom: 0px; padding-bottom: 20px; }',
        '.--global--8uQWVAoMUU--wrapper { justify-content: center; border-width: 1px 0px; border-color: rgb(0, 0, 0); margin-bottom: 0px; padding-bottom: 20px; }'
      ],
      [
        '.--lmkmgsy8--9hBtR2HuPG--flex-col-el { width: 100%; --width: 100%; }',
        '.--global--CciAoOg7o9-text div { text-align: left; font-size: 19px; }',
        '.--global--CciAoOg7o9-text div { text-align: left; font-size: 19px; }',
        '.--lmkmgsy8--aPi17aj2IA--icon { font-size: 18px; translate: -28px 1px; }',
        '.--global--CciAoOg7o9-text { padding-bottom: 10px; padding-top: 10px; }',
        '.--global--CciAoOg7o9-text { padding-bottom: 10px; padding-top: 10px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
