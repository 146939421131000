<template>
  <div class="d-flex align-items-center">
    <div
      class="dashboard-control-view mr-2"
      :class="{'active': gridView}"
      @click="gridView = true"
    >
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.416748 1.12501C0.416748 0.733803 0.73388 0.416672 1.12508 0.416672H13.8751C14.2663 0.416672 14.5834 0.733803 14.5834 1.12501V13.875C14.5834 14.2662 14.2663 14.5833 13.8751 14.5833H1.12508C0.73388 14.5833 0.416748 14.2662 0.416748 13.875V1.12501ZM1.83341 1.83334V6.79167H6.79175V1.83334H1.83341ZM8.20842 1.83334V6.79167H13.1667V1.83334H8.20842ZM13.1667 8.20834H8.20842V13.1667H13.1667V8.20834ZM6.79175 13.1667V8.20834H1.83341V13.1667H6.79175Z"
          fill="#1E1E1E"
        />
      </svg>
    </div>

    <div
      class="dashboard-control-view"
      :class="{'active': !gridView}"
      @click="gridView = false"
    >
      <svg
        width="15"
        height="11"
        viewBox="0 0 15 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.47925 2.3125C2.06605 2.3125 2.54175 1.8368 2.54175 1.25C2.54175 0.663197 2.06605 0.1875 1.47925 0.1875C0.892446 0.1875 0.416748 0.663197 0.416748 1.25C0.416748 1.8368 0.892446 2.3125 1.47925 2.3125Z"
          fill="#1E1E1E"
        />
        <path
          d="M4.66675 0.541667C4.27555 0.541667 3.95842 0.858798 3.95842 1.25C3.95842 1.6412 4.27555 1.95833 4.66675 1.95833L13.8751 1.95833C14.2663 1.95833 14.5834 1.6412 14.5834 1.25C14.5834 0.858799 14.2663 0.541668 13.8751 0.541668L4.66675 0.541667Z"
          fill="#1E1E1E"
        />
        <path
          d="M4.66675 4.79167C4.27555 4.79167 3.95842 5.1088 3.95842 5.5C3.95842 5.8912 4.27555 6.20833 4.66675 6.20833L13.8751 6.20833C14.2663 6.20833 14.5834 5.8912 14.5834 5.5C14.5834 5.1088 14.2663 4.79167 13.8751 4.79167L4.66675 4.79167Z"
          fill="#1E1E1E"
        />
        <path
          d="M3.95842 9.75C3.95842 9.3588 4.27555 9.04167 4.66675 9.04167H13.8751C14.2663 9.04167 14.5834 9.3588 14.5834 9.75C14.5834 10.1412 14.2663 10.4583 13.8751 10.4583H4.66675C4.27555 10.4583 3.95842 10.1412 3.95842 9.75Z"
          fill="#1E1E1E"
        />
        <path
          d="M2.54175 5.5C2.54175 6.0868 2.06605 6.5625 1.47925 6.5625C0.892446 6.5625 0.416748 6.0868 0.416748 5.5C0.416748 4.9132 0.892446 4.4375 1.47925 4.4375C2.06605 4.4375 2.54175 4.9132 2.54175 5.5Z"
          fill="#1E1E1E"
        />
        <path
          d="M1.47925 10.8125C2.06605 10.8125 2.54175 10.3368 2.54175 9.75C2.54175 9.1632 2.06605 8.6875 1.47925 8.6875C0.892446 8.6875 0.416748 9.1632 0.416748 9.75C0.416748 10.3368 0.892446 10.8125 1.47925 10.8125Z"
          fill="#1E1E1E"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardViewControl',
  computed: {
    gridView: {
      get () {
        return this.$store.state.dashboard.gridView
      },
      set (val) {
        this.$store.state.dashboard.gridView = val
      }
    }
  }
}
</script>

<style lang="scss">
@import "@builder/assets/scss/_variables.scss";

.dashboard-control-view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 38px;
  background: #FFFFFF;
  border: 1px solid #E2E5EC;
  border-radius: 3px;

  &.active {
    box-shadow: inset 0 0 0 2px $primary;
  }

  &:hover {
    cursor: pointer;
    background: #f8f8f8;
  }
}
</style>