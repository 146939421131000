import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Timer'
export const group = 'Advanced'
export const defaultOptions = {
  'content': {
    'time': {
      'type': 'time-mod-v2',
      'display': true,
      time: 0,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      'shapeEnabled': {'lg': false},
      'separator': {'enabled': {'lg': true}},
      'onPageLoad': false
    }
  },
  customize: {
    style: {
      dontShow: {
        type: 'checklist-customs',
        label: 'Don\'t Show',
        value: [],
        items: [{label: 'Days', value: 0}, {label: 'Hours', value: 1}, {label: 'Minutes', value: 2}, {label: 'Seconds', value: 3}]
      }
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@builder/assets/svg/previews/time/main-1.png')
}

export const icon = `
<svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.18741 7.9375H9.81241V15.25H8.18741V7.9375Z" fill="black"/>
<path d="M6.56241 0.625H11.4374V2.25H6.56241V0.625Z" fill="black"/>
<path d="M18.7499 6.3125L17.5962 5.16688L15.768 6.995C14.2716 5.26684 12.1668 4.18167 9.8911 3.96495C7.61536 3.74824 5.34362 4.41664 3.54788 5.83129C1.75213 7.24594 0.570455 9.29807 0.248367 11.5613C-0.0737215 13.8245 0.488543 16.1248 1.81834 17.9843C3.14813 19.8438 5.14321 21.1194 7.38905 21.5462C9.63489 21.9729 11.9588 21.518 13.878 20.2759C15.7971 19.0339 17.164 17.1001 17.6945 14.8765C18.225 12.6529 17.8785 10.3104 16.7268 8.33563L18.7499 6.3125ZM8.99992 20.125C7.55365 20.125 6.13985 19.6961 4.93731 18.8926C3.73478 18.0891 2.79752 16.9471 2.24405 15.6109C1.69059 14.2747 1.54577 12.8044 1.82793 11.3859C2.11008 9.96742 2.80653 8.66445 3.8292 7.64178C4.85187 6.61911 6.15484 5.92266 7.57332 5.64051C8.99181 5.35836 10.4621 5.50317 11.7983 6.05663C13.1345 6.6101 14.2765 7.54736 15.08 8.74989C15.8835 9.95243 16.3124 11.3662 16.3124 12.8125C16.3124 14.7519 15.542 16.6119 14.1706 17.9832C12.7993 19.3546 10.9393 20.125 8.99992 20.125Z" fill="black"/>
</svg>`

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('ElTimerV2', options)

    this.name = name
    this.group = group
    this.css = [['.--cea967j--fEAP-Ut3R-wrapper { font-size: 30px; --counter-size:30px; gap: 20px; padding: 20px; }', '.--cea967j--fEAP-Ut3R-label { font-size: 16px; }', '.--cea967j--fEAP-Ut3R--digit.bg .dial { background-color: rgb(189, 195, 199); }'], [], []]
    this.sid = 'cea967j--fEAP-Ut3R'
    this.sids = ['cea967j--fEAP-Ut3R']
    this.defaultOptions = defaultOptions
    this.layout = 'TimeItemV2'
  }
}
