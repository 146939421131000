<template>
  <div>
    <padding-full-customs
      :config="padding"
      :default-value="{
        top: 13,
        bottom: 13,
        left: 40,
        right: 40
      }"
    />
    <margin-full-customs
      :config="margin"
      class="mb-0"
    />
  </div>
</template>

<script>
import ModControlMixin from '@/components/mixins/ModControlMixin'

export default {
  name: 'ButtonMargin',
  mixins: [ModControlMixin],
  computed: {
    padding () {
      if (!this.config.paddingV2) {
        this.$set(this.config, 'paddingV2', {
          lg: {
            top: 13,
            bottom: 13,
            left: 40,
            right: 40
          },
          md: {
            top: 13,
            bottom: 13,
            left: 40,
            right: 40
          },
          sm: {
            top: 13,
            bottom: 13,
            left: 40,
            right: 40
          }
        })
      }

      return this.config.paddingV2
    },
    margin () {
      if (!this.config.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.margin
    }
  }
}
</script>