<template>
  <div
    :class="{'element-hidden': !visability('form')}"
    :style="{...fullPaddingSubject(section.options.content.form), ...fullMarginSubject(section.options.content.form)}"
  >
    <slot />
  </div>
</template>

<script>
import _ from 'lodash'
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import borderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin, borderMixin],
  computed: {
    margin () {
      if (!this.section.options.content.form.margin || !this.section.options.content.form.margin[this.resolutioner]) return {}

      return {
        marginTop: this.section.options.content.form.margin[this.resolution].top + 'px',
        marginBottom: this.section.options.content.form.margin[this.resolution].bottom + 'px'
      }
    },
    padding () {
      return {
        paddingTop: `${_.get(this.section.options.content.form, ['padding', this.resolutioner, 'top'], 0)}px`,
        paddingBottom: `${_.get(this.section.options.content.form, ['padding', this.resolutioner, 'bottom'], 0)}px`,
        paddingLeft: `${_.get(this.section.options.content.form, ['padding', this.resolutioner, 'x'], 0)}px`,
        paddingRight: `${_.get(this.section.options.content.form, ['padding', this.resolutioner, 'x'], 0)}px`
      }
    }
  }
}
</script>
