<template>
  <div class="pt-[9px] min-h-[500px]">
    <b-form-group>
      <div
        v-for="(image, index) in config.items"
        :key="index"
        class="d-flex align-items-center"
      >
        <image-upload
          :class="{'active': getActiveImage === index}"
          :image="image"
          class="w-100 mb-4"
          @imageDeleted="deleted(index)"
          @uploaded="sectionSettings({item: 'images', index}, index)"
        >
          <template slot="prepend">
            <b-input-group-text class="num">
              {{ index + 1 }}
            </b-input-group-text>
          </template>
        </image-upload>
      </div>
    </b-form-group>

    <b-form-group label="Add slide">
      <image-upload
        :image="file"
        class="w-100 mb-3"
        @uploaded="uploaded"
      />
    </b-form-group>

    <b-form-group label="Arrows color">
      <color-picker
        :color="section.options.content.images.arrows.color || globalStyles.colors.primary"
        @input="event => section.options.content.images.arrows.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
      />
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="section.options.content.images.autoplay">
        Autoplay
      </b-checkbox>
    </b-form-group>

    <b-form-group>
      <b-checkbox
        v-model="section.options.content.images.centerMode"
        value="true"
      >
        Center mode
      </b-checkbox>
    </b-form-group>

    <b-form-group
      v-if="section.options.content.images.centerMode"
      label="Center padding"
    >
      <vue-slider
        v-model="section.options.content.images.centerPadding"
        :dot-size="17"
        :max="100"
        :min="0"
        class="editor-slider-1"
        tooltip="none"
      />
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="section.options.content.images.pauseOnHover">
        Pause on hover
      </b-checkbox>
    </b-form-group>

    <b-form-group v-if="!section.options.content.images.centerMode">
      <b-row>
        <b-col md="6">
          <b-form-group label="Slides to show">
            <b-input
              v-model="section.options.content.images.slidesToShow"
              :max="config.items.length"
              :min="1"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Slides to scroll">
            <b-input
              v-model="section.options.content.images.slidesToScroll"
              :max="config.items.length"
              :min="1"
              type="number"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ImageUpload from '../utils/ImageUpload'
import ColorPicker from '../utils/ColorPicker'

export default {
  name: 'ImagesMod',
  components: {ColorPicker, ImageUpload},
  mixins: [ModMixin],

  data () {
    return {
      file: {}
    }
  },
  computed: {
    getActiveImage () {
      if (this.getOpenedItemObj) {
        return this.getOpenedItemObj.index
      }

      return 0
    }
  },

  methods: {
    deleted (index) {
      if (this.config.items.length > 4) {
        this.config.items.splice(index, 1)
      }
    },

    uploaded (image) {
      this.config.items.push({
        position: 'center',
        size: 'cover',
        placeholder: require('@builder/assets/svg/placeholder-image.svg'),
        ...image
      })
      const index = this.config.items.length - 1
      this.sectionSettings({item: 'images', index}, index)
      this.file = {}
    }
  }
}
</script>

<style lang="scss">
@import "@builder/assets/scss/_variables.scss";

.images-mod {
  .selected {
    .num {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.active {
  .btn-es-image-upload {
    box-shadow: 0 0 0 2px $primary !important;
  }

  .num {
    box-shadow: 0 0 0 2px $primary;
    color: #fff;
    background: $primary;
    border-color: $primary;
  }
}

.num {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #E2E5EC;
  background: #fff;
  font-size: 16px;
  color: #BEC3CE;
}
</style>
