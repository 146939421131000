<template>
  <div class="pt-[9px] min-h-[500px]">
    <b-form-group label="Size">
      <div class="flex items-center gap-[10px]">
        <div class="flex items-center w-[80x] h-[38px] border rounded-[2px]">
          <input
            v-model="fontSize"
            :max="100"
            :min="6"
            class="h-full px-[8px]"
            type="number"
          >
          <div class="border-left flex items-center justify-center h-full text-[15px] w-[38px] text-[#333333]">
            px
          </div>
        </div>

        <color-picker
          :color="config.style.color"
          @input="event => config.style.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <b-checkbox v-model="config.column[resolution]">
        Column
      </b-checkbox>
    </b-form-group>

    <b-form-group label="Font Weight">
      <v-select
        v-model="config.style.fontWeight"
        :options="['lighter', 'normal', 'bold']"
        :searchable="false"
        append-to-body
        class="custom-select"
      />
    </b-form-group>

    <b-form-group label="Alignment">
      <b-btn-group>
        <b-btn
          variant="white"
          @click="align = 'left'"
        >
          <i
            :class="{'text-primary': align === 'left'}"
            class="fa fa-outdent"
          />
        </b-btn>
        <b-btn
          variant="white"
          @click="align = 'center'"
        >
          <i
            :class="{'text-primary': align === 'center'}"
            class="fa fa-align-center"
          />
        </b-btn>
        <b-btn
          variant="white"
          @click="align = 'right'"
        >
          <i
            :class="{'text-primary': align === 'right'}"
            class="fa fa-indent"
          />
        </b-btn>
        <b-btn
          variant="white"
          @click="align = 'justify'"
        >
          <i
            :class="{'text-primary': align === 'justify'}"
            class="fa fa-align-justify"
          />
        </b-btn>
      </b-btn-group>
    </b-form-group>

    <b-form-group
      label="Line Height"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="config.style.lineHeight"
            :interval="1"
            :max="5"
            :min="0"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="config.style.lineHeight"
            :max="5"
            :min="0"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <draggable
      v-model="config.links"
      handle=".move"
    >
      <div
        v-for="(item, index) in config.links"
        :key="index"
        class="border group rounded-[2px] h-[38px] flex items-center pl-[13px] hover:bg-[#F1F5FD] cursor-pinter mb-[12px]"
      >
        <div class="mr-[11px] move cursor-move">
          <svg
            fill="none"
            height="11"
            viewBox="0 0 11 11"
            width="11"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.57745 0.894104H0.568359V3.8941H3.57745V0.894104Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 0.894104H7.58936V3.8941H10.5984V0.894104Z"
              fill="#3D82EA"
            />
            <path
              d="M3.57745 7.8941H0.568359V10.8941H3.57745V7.8941Z"
              fill="#3D82EA"
            />
            <path
              d="M10.5984 7.8941H7.58936V10.8941H10.5984V7.8941Z"
              fill="#3D82EA"
            />
          </svg>
        </div>


        <span
          class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]"
          v-text="item.text"
        />

        <button
          class="w-[38px] h-[36px] text-center border-left bg-[#fff] p-0 ml-auto"
          @click="newListItemModal = index"
        >
          <svg
            fill="none"
            height="12"
            viewBox="0 0 12 12"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M8.34918 0.18252C8.57698 -0.0452861 8.94633 -0.0452861 9.17414 0.18252L11.5075 2.51585C11.7353 2.74366 11.7353 3.11301 11.5075 3.34081L3.3408 11.5075C3.23141 11.6169 3.08303 11.6783 2.92832 11.6783H0.594991C0.272825 11.6783 0.0116577 11.4172 0.0116577 11.095V8.76167C0.0116577 8.60696 0.0731159 8.45858 0.182512 8.34919L8.34918 0.18252ZM7.83662 2.345L9.34499 3.85337L10.27 2.92833L8.76166 1.41996L7.83662 2.345ZM8.52003 4.67833L7.01166 3.16996L2.00328 8.17833L3.51166 9.68671L8.52003 4.67833ZM2.6867 10.5117L1.17832 9.00329V10.5117H2.6867Z"
              fill="#6E747A"
              fill-rule="evenodd"
            />
          </svg>
        </button>
        <button
          class="w-[38px] h-[36px] text-center border-left bg-[#fff] p-0"
          @click="config.links.splice(index, 1)"
        >
          <svg
            fill="none"
            height="10"
            viewBox="0 0 10 10"
            width="10"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
              fill="#6E747A"
            />
          </svg>
        </button>
      </div>
    </draggable>

    <button
      class="flex items-center border rounded-[3px] font-[600] text-[#44474A] text-[14px] h-[38px] px-[17px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
      @click="newListItemModal = true"
    >
      <span class="text-[#3D82EA] text-[22px] mr-[9px]">+</span>
      Add Menu Item
    </button>

    <SidbearSettingsGroup
      class="border-top"
      title="Padding & Margins"
    >
      <padding-full-customs :config="section.options.customize.style.padding" />
      <margin-full-customs :config="margin" />
    </SidbearSettingsGroup>

    <b-modal
      v-model="getNewListItemModal"
      hide-footer
      hide-header
    >
      <icon
        class="cursor-pinter ml-3"
        icon="close.svg"
        @click.native="newListItemModal = false"
      />

      <h4 class="h2 font-weight-bold text-center my-4">
        Add Link
      </h4>

      <div class="p-5">
        <b-form-group label="Text">
          <b-input
            v-if="typeof newListItemModal !== 'number'"
            v-model="text"
          />
          <b-input
            v-else
            v-model="config.links[newListItemModal].text"
          />

          <!--          <b-checkbox v-if="typeof newListItemModal !== 'number'" class="mt-2" v-model="customLink">Custom Link</b-checkbox>-->
          <!--          <b-checkbox v-else class="mt-2" v-model="config.links[newListItemModal].customLink">Custom Link</b-checkbox>-->
        </b-form-group>

        <b-form-group label="Action">
          <v-select
            v-if="typeof newListItemModal !== 'number'"
            v-model="customLink"
            :options="[{label: 'Page', value: false}, {label: 'Custom Link', value: true}, {label: 'Scroll to element', value: 'scroll-to-element'}]"
            :reduce="val => val.value"
            class="custom-select"
          />
          <v-select
            v-else
            v-model="config.links[newListItemModal].customLink"
            :options="[{label: 'Page', value: false}, {label: 'Custom Link', value: true}, {label: 'Scroll to element', value: 'scroll-to-element'}]"
            :reduce="val => val.value"
            class="custom-select"
          />
        </b-form-group>

        <b-form-group
          v-if="typeof newListItemModal !== 'number' && customLink === true"
          label="Link"
        >
          <b-input v-model="href" />
        </b-form-group>

        <b-form-group
          v-else-if="typeof newListItemModal === 'number' && config.links[newListItemModal].customLink === true"
          label="Link"
        >
          <b-input v-model="config.links[newListItemModal].href" />
        </b-form-group>

        <b-form-group
          v-else-if="typeof newListItemModal === 'number' && config.links[newListItemModal].customLink === 'scroll-to-element'"
          label="Block Element"
        >
          <select-block-page v-model="config.links[newListItemModal].href" />
        </b-form-group>

        <b-form-group
          v-else-if="typeof newListItemModal !== 'number' && customLink === 'scroll-to-element'"
          label="Block Element"
        >
          <select-block-page v-model="href" />
        </b-form-group>

        <b-form-group
          v-else-if="typeof newListItemModal !== 'number' && !customLink"
          label="Page"
        >
          <div class="position-relative">
            <v-select
              v-model="href"
              :options="[{item_type: 'module', id: 'blog', title: 'ESTAGE Blog'}, ...pages.data]"
              class="custom-select"
              label="title"
              @close="onClose"
              @open="onOpen"
              @search="onSearch"
            />

            <b-spinner
              v-if="searching || pageIsLoading"
              class="position-absolute z-index-100 right-3 top-3 bg-white"
              small
              variant="primary"
            />
          </div>
        </b-form-group>

        <b-form-group
          v-else-if="typeof newListItemModal === 'number' && !config.links[newListItemModal].customLink"
          label="Page"
        >
          <div class="position-relative">
            <pages-select-menu
              :others="[{item_type: 'module', id: 'blog', title: 'ESTAGE Blog', code: project.blog_slug}]"
              input-class="rounded-[4px] font-size-16 text-dark"
              other-title="Other:"
              @selectOther="setPage"
              @selectPage="setPage"
            >
              <template
                v-if="typeof config.links[newListItemModal].href === 'object' && config.links[newListItemModal].href.title"
                slot="placeholder"
              >
                <div class="px-3">
                  {{ config.links[newListItemModal].href.title }}
                </div>
              </template>
            </pages-select-menu>
          </div>
        </b-form-group>

        <b-form-group>
          <b-checkbox
            v-if="typeof newListItemModal !== 'number'"
            v-model="blank"
          >
            <div class="mt-[-6px]">
              Open In New Tab
            </div>
          </b-checkbox>
          <b-checkbox
            v-else
            v-model="config.links[newListItemModal].blank"
          >
            <div class="mt-[-6px]">
              Open In New Tab
            </div>
          </b-checkbox>
        </b-form-group>

        <div
          class="d-flex justify-content-center mb-4"
        >
          <b-btn
            v-if="typeof newListItemModal !== 'number'"
            variant="primary"
            @click="addListItem"
          >
            Add Link
          </b-btn>
          <b-btn
            v-else
            variant="primary"
            @click="() => newListItemModal = false"
          >
            Save Link
          </b-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import draggable from 'vuedraggable'
import {mapGetters, mapState} from 'vuex'
import SelectBlockPage from '../controls/SelectBlockPage'
import PagesPaginationMixin from '../mixins/PagesPaginationMixin'
import PagesSelectMenu from '@/components/editor/components/pagesSelectMenu/PagesSelectMenu'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'

export default {
  name: 'ListMod',
  components: {SidbearSettingsGroup, PagesSelectMenu, SelectBlockPage, draggable},
  mixins: [ModMixin, PagesPaginationMixin],
  data () {
    return {
      newListItemModal: false,
      text: '',
      customLink: '',
      href: '',
      blank: false
    }
  },
  computed: {
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    margin () {
      if (!this.config.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.margin
    },
    align: {
      get () {
        return this.config.align || 'left'
      },
      set (val) {
        this.$set(this.config, 'align', val)
      }
    },
    getNewListItemModal: {
      get () {
        if (this.newListItemModal === 0) {
          return true
        }

        return !!this.newListItemModal
      },
      set (val) {
        this.newListItemModal = val
      }
    },
    fontSize: {
      get () {
        return parseInt(this.config.style.fontSize)
      },
      set (val) {
        this.config.style.fontSize = `${val}px`
      }
    }
  },
  methods: {
    setPage (item) {
      this.$set(this.config.links[this.newListItemModal], 'href', item)
    },
    addListItem () {
      this.config.links.push({
        text: this.text,
        customLink: this.customLink,
        href: this.href,
        blank: this.blank
      })

      this.newListItemModal = false
    }
  }
}
</script>
