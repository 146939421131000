import { WHEPAdapter } from './WHEPAdapter'

const WHEPAdapterFactory = (peer, channelUrl, onError, mediaConstraints, authKey) => {
  return new WHEPAdapter(peer, channelUrl, onError, mediaConstraints, authKey)
}

// Mapping of adapter types to their corresponding factory functions
const adapters = {
  whep: WHEPAdapterFactory
}

// Main function to create an adapter based on the type
export function AdapterFactory (type, peer, channelUrl, onError, mediaConstraints, authKey) {
  const factory = adapters[type]
  if (!factory) {
    throw new Error(`Unsupported adapter type: ${type}`)
  }
  return factory(peer, channelUrl, onError, mediaConstraints, authKey)
}

// Function to list all available adapter types
export function ListAvailableAdapters () {
  return Object.keys(adapters)
}
