<template>
  <div v-if="enabled">
    <b-form-group label="Shadow">
      <div class="d-flex align-items-center">
        <div
          :class="{'active': blur < 0}"
          class="shadow-control-box text-uppercase"
          @click="() => blur = 0"
        >
          <icon icon="shadow-none.svg" />
        </div>

        <div
          v-for="(shadow, index) in shadows"
          :key="index"
          :class="['custom-shadow-' + shadow.key, {'active': blur === shadow.shadow}]"
          class="shadow-control-box text-uppercase"
          @click="() => selectShadow(shadow.shadow)"
        >
          {{ shadow.key }}
        </div>
      </div>
    </b-form-group>

    <b-form-group label="Color">
      <color-picker
        :color="color"
        @input="event => color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
      />
    </b-form-group>

    <b-form-group
      label="Offset-X"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="x"
            :interval="1"
            :max="100"
            :min="-100"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="x"
            :max="100"
            :min="-100"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Offset-Y"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="y"
            :interval="1"
            :max="100"
            :min="-100"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="y"
            :max="100"
            :min="-100"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      class="mb-0"
      label="Blur"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="blur"
            :interval="1"
            :max="70"
            :min="0"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="blur"
            :max="70"
            :min="0"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'
import ResolutionMixin from '../../mixins/ResolutionMixin'

export default {
  name: 'TextShadowControl',
  mixins: [ResolutionMixin],
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      shadows: [
        {
          key: 's',
          shadow: 4
        },
        {
          key: 'm',
          shadow: 10
        },
        {
          key: 'l',
          shadow: 22
        }
      ]
    }
  },
  computed: {
    textShadow () {
      return _.get(this.config.resolutionStyle[this.resolutioner], 'textShadow', {
        enabled: false,
        x: 0,
        y: 0,
        blur: 0,
        color: '#655C5C'
      })
    },
    enabled () {
      if (!this.config.resolutionStyle[this.resolutioner]) {
        this.$set(this.config.resolutionStyle, this.resolutioner, {})
      }

      this.$set(this.config.resolutionStyle[this.resolutioner], 'textShadow', {
        ...this.textShadow,
        enabled: true
      })

      return this.textShadow.enabled
    },
    y: {
      get () {
        return this.textShadow.y
      },
      set (val) {
        this.$set(this.config.resolutionStyle[this.resolutioner], 'textShadow', {
          ...this.textShadow,
          y: val
        })
      }
    },
    x: {
      get () {
        return this.textShadow.x
      },
      set (val) {
        this.$set(this.config.resolutionStyle[this.resolutioner], 'textShadow', {
          ...this.textShadow,
          x: val
        })
      }
    },
    blur: {
      get () {
        return this.textShadow.blur
      },
      set (val) {
        this.$set(this.config.resolutionStyle[this.resolutioner], 'textShadow', {
          ...this.textShadow,
          blur: val
        })
      }
    },
    color: {
      get () {
        return this.textShadow.color
      },
      set (val) {
        this.$set(this.config.resolutionStyle[this.resolutioner], 'textShadow', {
          ...this.textShadow,
          color: val
        })
      }
    }
  },
  methods: {
    selectShadow (shadow) {
      this.blur = shadow
    }
  }
}
</script>