<template>
  <div
    :style="{...getPosition, ...width, ...height, ...fullMarginSubject(col.customize.style, 'marginTop')}"
    :class="{'prevent-select': isDrag}"
    class="group snap-center flex-col-el"
    :data-uid="col.uid"
  >
    <div
      class="position-relative transitioner h-min-[100px] h-100"
      :class="[col.hoverAnimation, {'col-hover': isHover}]"
      :style="{...backgroundColorSubject(background), ...borderSubject(col.customize.style, [0,0,0,0]), ...radiusSubject(col.customize.style, [0,0,0,0]), ...shadow}"
      :data-value="col.name"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
      @click.stop="selectCol"
    >
      <div
        :style="{...imageRadiusSubject(col.customize.style, [0,0,0,0]), ...backgroundImageSubject(col.customize.style)}"
        class="image-background"
      />
      <background-slider
        :style="{...imageRadiusSubject(col.customize.style, [0,0,0,0])}"
        :options="backgroundSliderSubject(col.customize.style)"
        :styles="backgroundImageSubject(col.customize.style)"
      />
      <div
        :style="{...imageRadiusSubject(col.customize.style, [0,0,0,0]), ...backgroundImageOverlaySubject(col.customize.style)}"
        class="image-background"
      />
      <div class="h-100 w-100 position-absolute left-0 top-0 overflow-hidden">
        <float-image
          v-if="floatImage.enabled"
          :config="floatImage"
        />
      </div>
      <shape-divider
        :config="col.customize.style"
      />

      <elements
        v-model="col.elements"
        class="flex-col-placeholder flex-column flex w-100 h-100"
        classes="w-100"
        drag-height="100px"
        :styles="colStyles"
        :put="['elements', 'rows']"
        @flow="handleFlow"
      />
    </div>

    <div
      v-if="isDrag"
      class="position-absolute top-0 left-0 w-100 h-100 z-[1000000]"
    />

    <div
      v-show="absolute"
      ref="move"
      class="move-col absolute top-0 left-0 z-index-100 hidden group-hover:block"
      @mouseenter="isHover = true"
      @mouseleave="isHover = false"
    >
      <svg
        class="cursor-move"
        fill="none"
        height="14"
        viewBox="0 0 14 14"
        width="14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.32215 3.37761C8.54706 3.60252 8.91171 3.60252 9.13661 3.37762C9.36152 3.15271 9.36152 2.78806 9.13661 2.56316L7.40889 0.835428C7.30088 0.727424 7.1544 0.666748 7.00166 0.666748C6.84892 0.666748 6.70243 0.727424 6.59443 0.835428L4.8667 2.56316C4.64179 2.78806 4.64179 3.15271 4.8667 3.37761C5.09161 3.60252 5.45625 3.60252 5.68116 3.37761L6.42575 2.63303V5.27402C6.42575 5.59209 6.68359 5.84993 7.00166 5.84993C7.31972 5.84993 7.57757 5.59209 7.57757 5.27402V2.63303L8.32215 3.37761Z"
          fill="#5e5e5e"
        />
        <path
          d="M6.59443 13.1681C6.70243 13.2761 6.84892 13.3367 7.00166 13.3367C7.1544 13.3367 7.30088 13.2761 7.40889 13.1681L9.13661 11.4403C9.36152 11.2154 9.36152 10.8508 9.13661 10.6259C8.91171 10.401 8.54706 10.401 8.32215 10.6259L7.57757 11.3705V8.72948C7.57757 8.41141 7.31972 8.15357 7.00166 8.15357C6.68359 8.15357 6.42575 8.41141 6.42575 8.72948V11.3705L5.68116 10.6259C5.45625 10.401 5.09161 10.401 4.8667 10.6259C4.6418 10.8508 4.6418 11.2154 4.8667 11.4403L6.59443 13.1681Z"
          fill="#5e5e5e"
        />
        <path
          d="M3.37752 5.68125C3.60243 5.45634 3.60243 5.0917 3.37752 4.86679C3.15262 4.64189 2.78797 4.64189 2.56306 4.86679L0.835336 6.59452C0.727332 6.70252 0.666656 6.84901 0.666656 7.00175C0.666656 7.15449 0.727333 7.30097 0.835337 7.40898L2.56306 9.13671C2.78797 9.36161 3.15262 9.36161 3.37752 9.13671C3.60243 8.9118 3.60243 8.54715 3.37752 8.32225L2.63293 7.57766H5.27393C5.592 7.57766 5.84984 7.31982 5.84984 7.00175C5.84984 6.68368 5.592 6.42584 5.27393 6.42584H2.63293L3.37752 5.68125Z"
          fill="#5e5e5e"
        />
        <path
          d="M11.3704 7.57766L10.6258 8.32225C10.4009 8.54715 10.4009 8.9118 10.6258 9.13671C10.8507 9.36161 11.2153 9.36161 11.4402 9.13671L13.168 7.40898C13.276 7.30097 13.3367 7.15449 13.3367 7.00175C13.3367 6.84901 13.276 6.70252 13.168 6.59452L11.4402 4.86679C11.2153 4.64189 10.8507 4.64189 10.6258 4.86679C10.4009 5.0917 10.4009 5.45634 10.6258 5.68125L11.3704 6.42584H8.72938C8.41132 6.42584 8.15347 6.68368 8.15347 7.00175C8.15347 7.31982 8.41132 7.57766 8.72938 7.57766H11.3704Z"
          fill="#5e5e5e"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'
import Elements from '@/components/builder/utils/Elements/Elements'
import BackgroundMixin from '@/components/mixins/BackgroundMixin'
import ShapeDivider from '@/components/editor/components/shape-divider/ShapeDivider'
import FloatImage from '@/components/builder/utils/FloatImage'
import Animations from '@/components/mixins/Animations'
import BackgroundSlider from '@/components/builder/utils/BackgroundSlider'
import DragMixin from '@/components/editor/mixins/DragMixin'
import shortid from 'shortid'
import FlexScrollMixin from '@/components/builder/sections/utils/el-inline/flex-mixins/FlexScrollMixin'
import {mapState} from 'vuex'

export default {
  name: 'FlexWrapperCol',
  components: {BackgroundSlider, FloatImage, ShapeDivider, Elements},
  mixins: [ResolutionMixin, BorderMixin, BackgroundMixin, Animations, DragMixin, FlexScrollMixin],
  props: {
    section: {
      type: Object,
      required: true
    },
    col: {
      type: Object,
      required: true
    },
    originalCol: {
      default: null
    },
    mod: {
      type: String,
      default: 'flex-column-mod'
    },
    index: {
      type: Number,
      required: true
    },
    customBackOptions: {
      type: [Object, Boolean],
      default: () => false
    }
  },
  data () {
    return {
      isHover: false
    }
  },
  computed: {
    ...mapState('editor', {
      currentSubjectConfig: state => state.config,
      pickSubject: state => state.pickSubject
    }),
    colStyles () {
      return {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: this.verticalAlign,
        alignItems: this.horizontalAlign,
        ...this.highlight,
        ...this.fullPaddingSubject(this.col.customize.style)
      }
    },
    absolute () {
      return _.get(this.col, ['absolute', this.resolutioner],false)
    },
    transformY () {
      return _.get(this.col, ['transformY', this.resolutioner], 0)
    },
    transformX () {
      return _.get(this.col, ['transformX', this.resolutioner], 0)
    },
    zIndex () {
      return _.get(this.col, ['zIndex', this.resolutioner], 0)
    },
    getPosition () {
      if (!this.absolute) return {
        zIndex: (this.isDrag || this.isHover) ? 1000000 : this.zIndex
      }

      return {
        position: this.absolute ? 'absolute' : null,
        top: `${this.transformY}%`,
        left: `${this.transformX}%`,
        zIndex: (this.isDrag || this.isHover) ? 1000000 : this.zIndex
      }
    },
    colWidth () {
      const width = this.col.width[this.resolutioner]
      if (width === null) return this.col.width.lg
      return width
    },
    background () {
      if (!this.isHover) {
        return this.col.customize.style
      }

      return !_.get(this.col, ['hover', 'background'], null) ? this.col.customize.style : _.get(this.col, ['hover'], null)
    },
    floatImage () {
      return _.get(this.col.customize.style, 'floatImage', {enabled: false})
    },
    shadow () {
      const shadow = this.isHover ? _.get(this.col, ['hover', 'shadowOptions'], this.col.customize.style.shadowOptions) : this.col.customize.style.shadowOptions
      const color = this.isHover ? _.get(this.col, ['hover', 'shadowColor'], this.col.customize.style.shadowColor || '#EEEEEE') : this.col.customize.style.shadowColor || '#EEEEEE'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    verticalAlign () {
      const align = _.get(this.col, ['verticalAlign', this.resolutioner], null)
      if (align === null) return _.get(this.col, ['verticalAlign', 'lg'], null)
      return align
    },
    widthFixedEnabled () {
      return this.col.fixedWidthEnabled[this.resolutioner]
    },
    heightFixedEnabled () {
      return this.col.fixedHeightEnabled[this.resolutioner]
    },
    height () {
      const _height = _.get(this.col, ['fixedHeightV2', this.resolutioner, 'value'], _.get(this.col, ['fixedHeight', this.resolutioner], 50))
      const _heightType = _.get(this.col, ['fixedHeightV2', this.resolutioner, 'type'], 'vh')

      return {
        height: this.heightFixedEnabled ? `${_height}${_heightType}` : ''
      }
    },
    width () {
      return {
        [this.scrollIsEnabled || this.overflowIsEnabled ? 'min-width' : 'width']: this.widthFixedEnabled ? `${this.col.fixedWidth[this.resolutioner]}px` : `${this.colWidth}%`
      }
    },
    highlight () {
      return {
        boxShadow: this.col.highlight || this.isHover ? '0 0 0 2px #3f51b5' : null
      }
    },
    horizontalAlign () {
      return _.get(this.col, ['horizontalAlign', this.resolutioner], _.get(this.col, ['horizontalAlign', 'lg'], 'unset'))
    }
  },
  watch: {
    absolute (val) {
      if (val) {
        setTimeout(() => this.setInitialPosition(), 100)
      }
    }
  },
  created () {
    const col = this.originalCol ? this.originalCol : this.col
    if (!col.uid || [...document.querySelectorAll(`[data-uid="${col.uid}"]`)].filter(el => el !== this.$el).length) {
      this.$set(col, 'uid', shortid.generate())
    }

    if (this.originalCol && !this.originalCol.sortIndex) {
      return this.$set(this.originalCol, 'sortIndex', {
        lg: this.index,
        md: this.index,
        sm: this.index
      })
    }

    if (this.col && !this.col.sortIndex) {
      this.$set(this.col, 'sortIndex', {
        lg: this.index,
        md: this.index,
        sm: this.index
      })
    }
  },
  methods: {
    handleFlow () {
      this.$store.commit('editor/ADD_ELEMENT_IN_STACK', {
        name: this.col.name,
        $el: this.$el,
        uid: this.section.uid,
        onClick: () => {
          this.$store.commit('editor/CLEAR_ELEMENTS_IN_STACK')
          this.$store.commit('editor/ADD_ELEMENT_IN_STACK', {
            name: this.col.name,
            $el: this.$el,
            uid: this.section.uid,
            onClick: () => {
            }
          })
          this.$emit('flow')
          this.$store.dispatch('editor/sectionSettings', {
            section: this.section,
            openedItem: this.mod,
            options: {
              subject: this.col,
              subjectIndex: this.index,
              title: this.col.name,
              back: () => {
                this.$store.dispatch('editor/sectionSettings', this.customBackOptions || {
                  section: this.section,
                  openedItem: 'flex-mod'
                })
              }
            }
          })
        }
      })

      this.$emit('flow')
    },
    selectCol () {
      if (this.pickSubject) {
        this.$set(this.currentSubjectConfig, 'pickedUid', this.col.uid)
        return this.$store.commit('editor/PICK_SUBJECT', {pick: false, config: {}})
      }

      this.$store.commit('editor/CLEAR_ELEMENTS_IN_STACK')
      this.$store.commit('editor/ADD_ELEMENT_IN_STACK', {
        name: this.col.name,
        $el: this.$el,
        uid: this.section.uid,
        onClick: () => {}
      })
      this.$emit('flow')
      this.$store.dispatch('editor/sectionSettings', {
        section: this.section,
        openedItem: this.mod,
        options: {
          subject: this.col,
          subjectIndex: this.index,
          title: this.col.name,
          back: () => {
            this.$store.dispatch('editor/sectionSettings', this.customBackOptions || {
              section: this.section,
              openedItem: 'flex-mod'
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
.flex-col-placeholder .empty-row-drag {
  height: 100% !important;
}

.prevent-select {
  &:before {
    content: '';
    width: 200px;
    height: 200px;
    background: transparent;
    position: absolute;
    top: -100px;
    left: -100px;
    z-index: 1;
  }
}

.col-hover {
  &:before {
    content: attr(data-value);
    width: 200px;
    height: 30px;
    background: #3f51b5;
    display: flex;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    position: absolute;
    top: 0;
    left: -2px;
    padding-left: 15px;
    transform: translateY(-100%);
    z-index: 1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}
</style>