import Vue from 'vue'
import Vuex from 'vuex'
import editor from '@builder/store/modules/editor'
import sections from '@builder/store/modules/sections'
import router from '@builder/store/modules/router'
import auth from '@builder/store/modules/auth'
import projects from '@builder/store/modules/projects'
import apiIntegration from '@builder/store/modules/api-integration'
import explorer from '@builder/store/modules/explorer'
import filter from '@builder/store/modules/filter'
import blog from '@builder/store/modules/blog'
import navigationMenu from '@builder/store/modules/navigationMenu'
import pages from '@builder/store/modules/pages'
import menu from '@builder/store/modules/menu'
import favorites from '@builder/store/modules/favorites'
import forms from '@builder/store/modules/forms'
import font from '@builder/store/modules/font'
import blogEditor from '@builder/store/modules/blogEditor.js'
import modal from '@builder/store/modules/modal.js'
import editorZoom from '@builder/store/modules/editorZoom.js'
import blogSettings from '@builder/store/modules/blog-settings.js'
import authors from '@builder/store/modules/authors.js'
import blogCategories from '@builder/store/modules/blogCategories.js'
import templates from '@builder/store/modules/templates.js'
import historyChanges from '@builder/store/modules/historyChanges.js'
import dashboard from '@builder/store/modules/dashboard.js'
import validation from '@builder/store/modules/validation.js'
import notifications from '@builder/store/modules/notifications.js'
import promoBar from '@builder/store/modules/promo-bar'
import assetsLibrary from '@builder/store/modules/assets-library'
import portalServices from '@builder/store/modules/portalServices'
import autoresponders from '@builder/store/modules/autoresponders'
import integrations from '@builder/store/modules/integrations'
import integrationExclusions from '@builder/store/modules/integrationExclusions'
import confirmationDialog from '@builder/store/modules/confirmation-dialog'
import purchaseDialog from '@builder/store/modules/purchase-dialog'
import videosLibrary from '@builder/store/modules/videos-library'
import liveStream from '@builder/store/modules/live-stream'
import products from '@builder/store/modules/products'
import payments from '@builder/store/modules/payments'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    validation,
    dashboard,
    templates,
    editor,
    sections,
    router,
    auth,
    projects,
    apiIntegration,
    explorer,
    filter,
    blog,
    navigationMenu,
    pages,
    menu,
    favorites,
    forms,
    font,
    blogEditor,
    modal,
    editorZoom,
    blogSettings,
    authors,
    blogCategories,
    historyChanges,
    notifications,
    promoBar,
    assetsLibrary,
    portalServices,
    autoresponders,
    integrations,
    integrationExclusions,
    confirmationDialog,
    purchaseDialog,
    videosLibrary,
    liveStream,
    products,
    payments
  }
})
