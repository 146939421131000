<template>
  <div
    v-if="visability('image')"
    class="images-group-3 d-flex justify-content-center align-items-center"
    :style="{...padding}"
  >
    <div
      class="position-relative"
      :style="{...dropShadow}"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'ImageItem2Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.images-group-3 {
  /*padding-top: 30px;*/
  /*padding-bottom: 60px;*/

  img {
    width: 261px;
    height: 319px;
    border-radius: 0px 34px;
  }
}
</style>
