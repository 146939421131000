<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    active-class="z-100"
    class="highlight-element"
    section-type="element"
    :hide-bar="hideBar"
    :hide-add="hideAdd"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('carousel-mod-v2')"
  >
    <div
      class="position-relative p-[2px]"
      :data-sid="section.sid"
      :class="{'element-hidden': !visability()}"
      @mouseenter="() => isHover = true"
      @mouseleave="() => isHover = false"
    >
      <div
        ref="slider"
        class="es-slider"
        :class="`--${section.sid}--slider`"
      >
        <div
          v-for="(slide, index) in section.options.content.carousel.slides"
          :key="index"
          class="slide-container min-w-[50%]"
          :data-sid="`${section.sid}--${slide.uid}`"
        >
          <div class="slide w-100 h-100 relative">
            <div
              class="block-bg inherit-radius"
              :class="`--${section.sid}--${slide.uid}--bg`"
            />

            <elements
              v-model="slide.content"
              class="h-100"
              drag-height="150px"
              @flow="handleFlow"
              @click.stop.native="$store.commit('editor/SET_PAGE', 'add-element')"
            />
          </div>
        </div>
      </div>

      <div
        v-show="false"
        :class="`--${section.sid}--slider`"
      >
        <div class="slide-container prev">
          <div class="slide" />
        </div>
        <div class="slide-container behind">
          <div class="slide" />
        </div>
        <div class="slide-container next">
          <div class="slide" />
        </div>
        <div class="slide-container forward">
          <div class="slide" />
        </div>
        <div class="slide-container active">
          <div class="slide" />
        </div>
      </div>

      <div class="slide-btn-wrapper">
        <button
          class="slide-btn slide-prev-btn custom"
          :class="`--${section.sid}--prev-btn`"
          @click.stop="previousSlide"
        >
          <i :class="section.options.content.carousel.prevIcon" />
        </button>

        <button
          class="slide-btn slide-next-btn custom"
          :class="`--${section.sid}--next-btn`"
          @click.stop="nextSlide"
        >
          <i :class="section.options.content.carousel.nextIcon" />
        </button>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import EventsMixin from '@/components/editor/mixins/EventsMixin'
import Elements from '@/components/builder/utils/Elements/Elements'

export default {
  name: 'ElCarouselV2',
  components: {Elements},
  mixins: [SectionMixin, EventsMixin],
  data () {
    return {
      currentIndex: 0,
      ignoreScrollEvents: false,
      timer: null,
      timer2: null,
      pauseOnHover: true,
      isHover: false,
      interval: false
    }
  },
  computed: {
    autoplay () {
      return this.section.options.content.carousel.autoplay
    },
    speed () {
      return this.section.options.content.carousel.speed
    }
  },
  watch: {
    autoplay (val) {
      clearInterval(this.interval)

      if (val) {
        this.autoPlay()
      }
    },
    speed () {
      if (this.autoplay) {
        clearInterval(this.interval)
        this.autoPlay()
      }
    }
  },
  mounted () {
    this.setActiveSlide(0)
    this.listen('onSlideChange', e => this.onSlideChange(e.detail))
    this.listen('onServiceSlideChange', e => this.onService(e.detail))
    // this.$refs.slider.addEventListener('scroll', this.onScroll)

    if (this.autoplay) {
      this.autoPlay()
    }
  },
  beforeDestroy () {
    this.$refs.slider.removeEventListener('scroll', this.onScroll)
    clearInterval(this.interval)
    clearTimeout(this.timer)
    clearTimeout(this.timer2)
  },
  methods: {
    goToSlide (index) {
      if (!this.$refs.slider) return
      const slides = [...this.$refs.slider.children]
      const slide = slides[index]

      if (!slide) return

      this.$refs.slider.scroll({
        left: slide.offsetLeft,
        behavior: 'smooth'
      })

      this.setActiveSlide(index)
    },
    nextSlide () {
      this.ignoreScrollEvents = true

      if (this.currentIndex < this.section.options.content.carousel.slides.length - 1) {
        this.goToSlide(this.currentIndex + 1)
      } else {
        this.goToSlide(0)
      }
    },
    previousSlide () {
      this.ignoreScrollEvents = true

      if (this.currentIndex - 1 >= 0) {
        this.goToSlide(this.currentIndex - 1)
      }
    },
    onSlideChange ({slide, uid}) {
      if (this.section.uid !== uid && this.section.pickedUid === uid) {
        this.goToSlide(slide)
      }
    },
    onService ({slide}) {
      this.goToSlide(slide)
    },
    setActiveSlide (index) {
      this.fire('onSlideChange', {slide: index, uid: this.section.uid})

      const slides = [...this.$refs.slider.children]
      slides.forEach(slide => {
        slide.classList.remove('active')
        slide.classList.remove('prev')
        slide.classList.remove('next')
        slide.classList.remove('forward')
        slide.classList.remove('behind')
      })

      slides[index].classList.add('active')

      if (index > 0) {
        slides[index - 1].classList.add('prev')
      }

      if (index > 1) {
        slides[index - 2].classList.add('behind')
      }

      if (index < this.section.options.content.carousel.slides.length - 1) {
        slides[index + 1].classList.remove('forward')
        slides[index + 1].classList.add('next')
      }

      if (index < this.section.options.content.carousel.slides.length - 2) {
        slides[index + 2].classList.add('forward')
      }

      this.currentIndex = index
    },
    onScroll () {
      clearTimeout(this.timer)
      clearTimeout(this.timer2)
      this.timer2 = setTimeout(() => this.ignoreScrollEvents = false, 1000)

      if (this.ignoreScrollEvents) return

      this.timer = setTimeout(() => {
        const slides = [...this.$refs.slider.children]
        const activeSlide = slides.reduce((closest, slide) => {
          const slideRect = slide.getBoundingClientRect()
          const sliderRect = this.$refs.slider.getBoundingClientRect()
          const slideCenter = slideRect.left + slideRect.width / 2
          const sliderCenter = sliderRect.left + sliderRect.width / 2
          const distance = Math.abs(slideCenter - sliderCenter)
          return distance < closest.distance ? {slide, distance} : closest
        }, {slide: null, distance: Infinity}).slide

        if (slides.indexOf(activeSlide) !== this.currentIndex) {
          this.setActiveSlide(slides.indexOf(activeSlide))
        }
      }, 100)
    },
    autoPlay () {
      this.interval = setInterval(() => {
        if (this.section.options.content.carousel.pauseOnHover && this.isHover) return
        this.nextSlide()
      }, this.section.options.content.carousel.speed)
    }
  }
}
</script>

<style lang="scss">
.slide-prev-btn {
  position: absolute;
  top: 50%;
  left: 0;
  --top: 50%;
  --left: 0;
  translate: 0 (calc(var(--top) * -1));
  transform: translateX(calc(var(--left) * -1));
  transition: transform 0s !important;
  background-repeat: no-repeat;
  background-position: center left;
  padding: 0;

  i {
    font-size: 24px;
  }
}

.slide-next-btn {
  position: absolute;
  top: 50%;
  left: 100%;
  --top: 50%;
  --left: 100%;
  translate: 0 (calc(var(--top) * -1));
  transform: translateX(calc(var(--left) * -1));
  transition: transform 0s !important;
  background-repeat: no-repeat;
  background-position: center left;
  padding: 0;

  i {
    font-size: 24px;
  }
}

.es-slider {
  display: flex;
  overflow: hidden;
  padding: 20px;
  width: 100%;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }
}

.slide-container {
  //scroll-snap-align: center;
}

.slide {
  transition: transform .6s ease, opacity .6s ease;
}
</style>