<template>
  <div>
    <SidbearSettingsGroup title="Alert Bar">
      <sid-buttons-group
        label="Close Icon Alignment"
        :options="[
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            customProperties: [[`.--${config.subject.sid}--flex .promo-close-icon`, ['left', '14px']], [`.--${config.subject.sid}--flex .promo-close-icon`, ['right', 'unset']]],
            value: 'start',
            tooltip: 'Start'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            customProperties: [[`.--${config.subject.sid}--flex .promo-close-icon`, ['left', 'unset']], [`.--${config.subject.sid}--flex .promo-close-icon`, ['right', '14px']]],
            value: 'end',
            tooltip: 'End'
          }
        ]"
        :sid="`.--${config.subject.sid}--flex`"
        rule-name="--align"
        @change="(value) => iconAlignment = value"
        @mount="(value) => iconAlignment = value"
      />

      <b-form-group label="Alert Bar Alignment">
        <div class="flex items-center btn-group">
          <button
            v-b-tooltip.hover
            :class="{'!bg-[#F2F4F5]': section.options.content.promo.dockTo.lg === 'top'}"
            class="btn flex justify-center !transition items-center h-[29px] !px-[10px] py-0 text-[12px] !font-[600] border rounded-[4px] !text-[#44474A] hover:!bg-[#F2F4F5]"
            title="Top"
            @click="() => section.options.content.promo.dockTo.lg = 'top'"
          >
            <img
              :src="'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A'"
              alt="icon"
            >
          </button>
          
          <button
            v-b-tooltip.hover
            :class="{'!bg-[#F2F4F5]': section.options.content.promo.dockTo.lg === 'bottom'}"
            class="btn flex justify-center !transition items-center h-[29px] !px-[10px] py-0 text-[12px] !font-[600] border rounded-[4px] !text-[#44474A] hover:!bg-[#F2F4F5]"
            title="Bottom"
            @click="() => section.options.content.promo.dockTo.lg = 'bottom'"
          >
            <img
              :src="'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A'"
              alt="icon"
            >
          </button>
        </div>
      </b-form-group>
  
      <b-form-group class="pl-[3px]">
        <b-checkbox
          v-model="section.options.content.promo.closeIcon.enabled.lg"
        >
          Show Close Icon
        </b-checkbox>
      </b-form-group>
  
      <div class="relative">
        <b-form-group>
          <div class="flex items-center gap-[10px]">
            <color-picker
              :color="section.options.content.promo.closeIcon.color.lg"
              @input="event => section.options.content.promo.closeIcon.color.lg = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
            />
  
            <label class="mb-0 pb-0">Close icon color</label>
          </div>
        </b-form-group>
  
        <b-form-group
          label="Set Cookie (optional)"
        >
          <div class="relative">
            <b-input
              v-model="section.options.content.promo.maxAge"
              placeholder="In minutes"
            />
  
            <div class="absolute top-1/2 right-[10px] -translate-y-1/2">
              <i
                v-b-tooltip.hover
                title="Determines how long the banner will be visible to the user again after closing it when visiting the site again."
                class="fa fa-question-circle cursor-pointer hover:text-blue-500 ml-1"
              />
            </div>
          </div>
        </b-form-group>
  
        <div
          v-if="!section.options.content.promo.closeIcon.enabled.lg"
          class="absolute top-0 left-0 bg-white w-100 h-100 z-index-100 opacity-80"
        />
      </div>
    </SidbearSettingsGroup>
    <component
      :is="section.options.content.promo.subject.mod || 'flex-mod'"
      component-name="Flex Wrapper"
      :config="section.options.content.promo.subject"
      :hide="['visibility']"
      :local-section="section.options.content.promo.subject"
      :custom-back-options="{openedItem: 'promo-mod', section}"
    />
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import FlexMod from '@/components/editor/mods/FlexMod'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign.vue'
import SidHorizontalAlign from '@/components/editor/components/sid-controls/SidHorizontalAlign.vue'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup.vue'

export default {
  name: 'PromoMod',
  components: {SidButtonsGroup, SidHorizontalAlign, SidAlign, SidbearSettingsGroup, FlexMod},
  mixins: [ModMixin],
  data () {
    return {
      iconAlignment: 'start'
    }
  },
  computed: {
    dockTo () {
      return this.section.options.content.promo.dockTo.lg
    }
  },
  watch: {
    dockTo (val) {
      const $scrollContainer = document.querySelector('.scrollbale-container')
      if (!$scrollContainer) return

      $scrollContainer.scrollTo({
        top: val === 'top' ? 0 : $scrollContainer.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>