<template>
  <div>
    <h6 class="font-weight-bold mb-3">
      Twitter Settings
    </h6>

    <b-form-radio-group
      v-model="form.tw_open_graph_enabled"
      class="mb-3"
      :options="[{value: '1', text: 'Enabled'}, {value: 0, text: 'Disabled'}]"
      button-variant="secondary"
      name="radios-btn-default"
      buttons
    />

    <p class="text-muted">
      Enable this feature if you want Twitter to display a preview with images and a text excerpt when a link to your site is shared.
    </p>
  </div>
</template>

<script>
export default {
  name: 'MetaDataTwitter',
  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>