<template>
  <div v-if="settings.widget_sidebar.display_popular_posts">
    <h6 class="title">
      Most Popular Posts
    </h6>

    <ul class="list-unstyled mb-0 p-0">
      <li
        v-for="popular in mostPopular"
        :key="popular.id"
        class="category cursor-pinter truncate"
        @click="$router.push({name: 'blog.post', params: {slug: popular.slug}})"
      >
        <b-link v-text="popular.title" />
      </li>
    </ul>
  </div>
</template>

<script>
import BlogCategoriesMixin from '../../mixins/BlogCategoriesMixin'

export default {
  name: 'MostPopular',
  mixins: [BlogCategoriesMixin]
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 21px;
}

.category {
  font-size: 15px;
  color: #6E747A;
  padding: 13px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #E3E3E3;
  }

  &.active {
    font-weight: 600;
    color: #4F83E3;
  }

  &:hover {
    color: #4F83E3;
  }
}
</style>
