<template>
  <div
    class="position-relative nav-item"
    @mouseleave="closeMenu"
    @mouseover="openMenu"
  >
    <div
      :style="linkStyle(page)"
      class="align-items-center dropdown-link text-nowrap"
    >
      {{ label }} <i class="fa fa-angle-down ml-2" />
    </div>

    <div
      v-if="menu"
      class="hs-mega-menu-container w-100"
    >
      <div
        :style="{borderTopColor: linkStyle(page).color || globalStyles.colors.primary, backgroundColor}"
        class="hs-mega-menu w-100"
      >
        <ul class="list-unstyled">
          <template v-for="(item, index) in items">
            <div
              v-if="!item.children || !item.children.length"
              :key="index"
              class="dropdown-item cursor-pinter"
              @click="$store.dispatch('pages/goToPage', page)"
            >
              <div
                :style="{...linkStyle(item), color: customs.dropdownTextColor || customs.linksColor}"
                class="py-1 text-wrap"
              >
                {{ item.item_name }}
              </div>
            </div>
            <submenu
              v-else
              :key="index"
              :items="item.children"
              :label="item.name"
            />
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {mapState} from 'vuex'
import Submenu from '@builder/components/builder/utils/menu/Submenu'

export default {
  name: 'Menu',
  components: {Submenu},
  props: {
    label: String,
    items: Array,
    customs: Object,
    linkStyle: {
      type: Function,
      required: true
    },
    page: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      menu: false,
      active: false,
      timer: null
    }
  },

  computed: {
    backgroundColor () {
      return _.get(this.customs, 'dropdownColor', '#fff')
    },
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    isActive () {
      let active = false

      const recurse = (arr) => {
        if (active) return false

        arr.forEach(item => {
          if (item.item_object_id === this.$store.state.pages.page.id) return active = true
          if (item.hasOwnProperty('children')) recurse(item.children)
        })
      }

      recurse(this.items)
      return active
    }
  },

  mounted () {
    VEvent.listen('close-all-menus', () => {
      clearTimeout(this.timer)
      this.menu = false
    })
  },

  methods: {
    closeMenu () {
      this.timer = setTimeout(() => {
        this.menu = false
      }, 300)
    },
    openMenu () {
      VEvent.fire('close-all-menus')
      this.menu = true
    }
  }
}
</script>

<style lang="scss">
@import "@builder/assets/scss/_variables.scss";

.resolution-lg {
  .hs-mega-menu-container {
    padding-top: 20px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    min-width: 250px;

    .hs-mega-menu {
      border-top: 2px solid;
      background: #fff;
      box-shadow: 0 1px 2px #ccc;
      z-index: 100;

      .dropdown-item {
        &:active,
        &:focus {
          background: inherit;
          color: inherit;
        }
      }
    }
  }
}

.resolution-sm,
.resolution-md {
  .dropdown-link {
    padding: 0.5rem 0;
  }

  .navbar-nav > div {
    width: 100%;
  }

  .hs-mega-menu {
    margin-top: 10px;
    border-top: 2px solid;
    background: #fff;
    box-shadow: 0 1px 2px #ccc;
  }
}
</style>
