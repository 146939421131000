const state = () => ({
  list:{
    loading:false,
    project_id:null,
    data:[]
  }
})
  
const getters = {}
  
const actions = {
  async getList ({state},projectId) {
    state.list.loading = true
    await axios.get(`api/projects/${projectId}/integrations`)
      .then(({data}) => {
        state.list = {
          project_id:projectId,
          data:data.data,
          loading:false
        }
      }).finally(()=> state.list.loading = false)
  },
  async create (_, data) {
    return await axios.post(`api/projects/${data.project_id}/integrations`, data)
  },
  async update (_, data) {
    return await axios.put(`api/projects/${data.project_id}/integrations/${data.integration_id}`, data)
  },
  async delete (_, data) {
    return await axios.delete(`api/projects/${data.project_id}/integrations/${data.integration_id}`)
  }
}
  
const mutations = {}
  
export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
  