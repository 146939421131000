<template>
  <div>
    <SidbearSettingsGroup
      active
      title="Accordion Settings"
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <rule-tabs :tabs="['Normal', 'Active', 'Hover']">
        <template #Normal>
          <draggable
            v-model="config.items"
            handle=".move"
          >
            <div
              v-for="(item, index) in config.items"
              :key="index"
              class="border group rounded-[2px] h-[29px] flex items-center pl-[13px] hover:bg-[#F1F5FD] cursor-pinter mb-[12px]"
            >
              <div class="mr-[11px] move cursor-move">
                <svg
                  fill="none"
                  height="11"
                  viewBox="0 0 11 11"
                  width="11"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.57745 0.894104H0.568359V3.8941H3.57745V0.894104Z"
                    fill="#3D82EA"
                  />
                  <path
                    d="M10.5984 0.894104H7.58936V3.8941H10.5984V0.894104Z"
                    fill="#3D82EA"
                  />
                  <path
                    d="M3.57745 7.8941H0.568359V10.8941H3.57745V7.8941Z"
                    fill="#3D82EA"
                  />
                  <path
                    d="M10.5984 7.8941H7.58936V10.8941H10.5984V7.8941Z"
                    fill="#3D82EA"
                  />
                </svg>
              </div>

              <span
                class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]"
                v-text="item.title"
              />

              <b-checkbox
                v-model="openIndex"
                :value="index"
                :unchecked-value="null"
              />

              <button
                class="w-[38px] h-[27px] text-center border-left bg-[#fff] p-0 ml-auto"
                @click="config.items.splice(index, 1)"
              >
                <svg
                  fill="none"
                  height="10"
                  viewBox="0 0 10 10"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
                    fill="#6E747A"
                  />
                </svg>
              </button>
            </div>
          </draggable>

          <button
            class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
            @click="addCollapse"
          >
            Add Item
            <span class="text-[#3D82EA] text-[20px]">+</span>
          </button>

          <sid-font
            :sid="`.--${section.sid}--tab-header-wrapper`"
          />

          <sid-range-slider
            label="Header Font Size"
            :sid="`.--${section.sid}--tab-header-wrapper`"
            rule-name="font-size"
          />

          <sid-range-slider
            label="Header Letter-Spacing"
            :sid="`.--${section.sid}--tab-header-wrapper`"
            rule-name="letter-spacing"
          />

          <sid-range-slider
            label="Gap"
            :sid="`.--${section.sid}--wrapper`"
            rule-name="gap"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Header Font Color</label>

              <sid-color
                :sid="`.--${section.sid}--tab-header-wrapper`"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <sid-align
            :sid="`.--${section.sid}--tab-header-wrapper`"
            rule-name="text-align"
            label="Text Alignment"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Decoration</label>

              <sid-font-style
                :sid="`.--${section.sid}--tab-header`"
                rule-name="font"
              />
            </div>
          </b-form-group>

          <sid-background
            :sid="`.--${section.sid}--tab-header-wrapper`"
          />

          <sid-linked-group
            label="Header Padding"
            :sid="`.--${section.sid}--tab-header-wrapper`"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />

          <hr>

          <sid-background
            label="Content Background"
            :sid="`.--${section.sid}--tab-content`"
          />

          <sid-linked-group
            label="Content Padding"
            :sid="`.--${section.sid}--tab-content`"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>

        <template #Active>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Header Font Color</label>

              <sid-color
                :sid="`.--${section.sid}--tab-header-wrapper.active`"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Decoration</label>

              <sid-font-style
                :sid="`.--${section.sid}--tab-header-wrapper.active`"
                rule-name="font"
              />
            </div>
          </b-form-group>

          <sid-background
            :sid="`.--${section.sid}--tab-header-wrapper.active`"
          />
        </template>

        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Header Font Color</label>

              <sid-color
                :sid="`.--${section.sid}--tab-header-wrapper`"
                rule-name="color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Decoration</label>

              <sid-font-style
                :sid="`.--${section.sid}--tab-header-wrapper`"
                rule-name="font"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-background
            :sid="`.--${section.sid}--tab-header-wrapper`"
            presudo=":hover"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Header Border & Radius">
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--tab-header-wrapper`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--tab-header-wrapper`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--tab-header-wrapper`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid}--tab-header-wrapper`"
        rule-name="border"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Content Border & Radius">
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--tab-content`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--tab-content`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--tab-content`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid}--tab-content`"
        rule-name="border"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Accordion Icons"
    >
      <rule-tabs :tabs="['Open Icon', 'Close Icon']">
        <template slot="Open Icon">
          <b-form-group>
            <icon-select
              v-model="config.icons.openIcon.icon"
              class="mb-2"
            />

            <div class="flex items-center justify-end">
              <sid-color
                :sid="`.--${section.sid}--tab-open-icon`"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Vertical Alignment</label>

              <sid-buttons-group
                class="mb-0"
                label=""
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  }
                ]"
                :sid="`.--${section.sid}--tab-header-wrapper`"
                rule-name="align-items"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Horizontal Alignment</label>

              <sid-buttons-group
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: '',
                    tooltip: 'Start',
                    default: 'row'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'row-reverse',
                    tooltip: 'End'
                  }
                ]"
                :sid="`.--${section.sid}--tab-header-wrapper`"
                rule-name="flex-direction"
              />
            </div>
          </b-form-group>

          <sid-range-slider
            label="Icon Size"
            :sid="`.--${section.sid}--tab-open-icon`"
            rule-name="font-size"
          />
        </template>
        <template slot="Close Icon">
          <b-form-group>
            <icon-select
              v-model="config.icons.closeIcon.icon"
              class="mb-2"
            />

            <div class="flex items-center justify-end">
              <sid-color
                :sid="`.--${section.sid}--tab-close-icon`"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Icon Position</label>

              <sid-buttons-group
                class="mb-0"
                label=""
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  }
                ]"
                :sid="`.--${section.sid}--tab-header-wrapper`"
                rule-name="align-items"
              />
            </div>
          </b-form-group>

          <sid-range-slider
            label="Icon Size"
            :sid="`.--${section.sid}--tab-close-icon`"
            rule-name="font-size"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import SidFontStyle from '@/components/editor/components/sid-controls/SidFontStyle'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup'
import IconSelect from '@/components/editor/components/IconSelect'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import draggable from 'vuedraggable'
import shortid from 'shortid'
import SidStyles from '@/components/mixins/SidStyles'

export default {
  name: 'CollapseModV2',
  components: {
    SidSelect,
    SidLinkedGroup,
    IconSelect,
    draggable,
    SidButtonsGroup,
    SidBackground, SidFontStyle, SidAlign, SidColor, SidRangeSlider, SidFont, RuleTabs, SidbearSettingsGroup},
  mixins: [ModMixin, SidStyles],
  computed: {
    openIndex: {
      get () {
        if (this.section.options.content.collapse.openIndex === null) return null
        return this.section.options.content.collapse.openIndex || 0
      },
      set (val) {
        this.$set(this.section.options.content.collapse, 'openIndex', val)
      }
    }
  },
  methods: {
    addCollapse () {
      this.config.items.push({
        uid: shortid.generate(),
        title: 'Title text',
        elements: []
      })
    }
  }
}
</script>