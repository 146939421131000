<template>
  <div :style="{...padding, ...backgroundColor}">
    <div
      class="image-background"
      :style="{...backgroundImage}"
    />
    <div :class="container">
      <b-row class="align-items-center">
        <b-col :cols="cols(4, 12)">
          <b-img
            class="cursor-pinter"
            :src="require('@builder/assets/svg/previews/videos/play-video.svg')"
            fluid
            @click="modal = true"
          />
        </b-col>

        <b-col :cols="cols(8, 12)">
          <template v-if="visability('title')">
            <slot name="title" />
          </template>

          <template v-if="visability('subtitle')">
            <slot name="subtitle" />
          </template>

          <template v-if="visability('button')">
            <slot
              name="button"
              :openModal="openModal"
            />
          </template>
        </b-col>
      </b-row>

      <b-modal
        v-model="modal"
        class="position-relative"
        body-class="py-0"
        content-class="new-section-modal"
        size="xl"
        centered
        hide-footer
        hide-header
      >
        <icon
          class="close-icon cursor-pinter ml-3"
          icon="close.svg"
          @click.native="modal = false"
        />

        <div class="py-5 mt-4">
          <slot name="video" />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'VideoItems3Template',
  mixins: [TemplateMixin],
  data () {
    return {
      modal: false
    }
  },
  methods: {
    openModal () {
      this.modal = true
    }
  }
}
</script>
