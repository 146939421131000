<template>
  <highliter
    v-model="section.name"
    :uid="section.uid"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings()"
  >
    <ImageItem5Template :section="section">
      <template slot="image">
        <highliter
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('image')"
        >
          <img
            class="card-img-top"
            :src="section.options.content.image.src || section.options.content.image.placeholder"
            alt="Card image cap"
          >
        </highliter>
      </template>

      <template slot="title">
        <highliter
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('title')"
        >
          <content-editable
            v-model="section.options.content.title.text"
            :uid="tiptapUID('title')"
            :customs="section.options.content.title"
          />
        </highliter>
      </template>

      <template slot="description">
        <highliter
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('description')"
        >
          <content-editable
            v-model="section.options.content.description.text"
            :uid="tiptapUID('description')"
            :customs="section.options.content.description"
          />
        </highliter>
      </template>
    </ImageItem5Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditable from '@builder/components/builder/utils/ContentEditable'
import ImageItem5Template from '@builder/templates/sections/images/ImageItem5Template'

export default {
  name: 'ImageItem3',

  components: {ImageItem5Template, ContentEditable, Highliter},

  mixins: [SectionMixin]
}
</script>
