<template>
  <b-form-group v-if="gdpr.enabled">
    <div>
      <b-checkbox v-model="gdprCheck">
        <div
          class="custom-control-legend"
          style="white-space: break-spaces;"
          v-text="gdpr.text"
        />
      </b-checkbox>
    </div>
  </b-form-group>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Gdpr',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    gdpr: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('editor', {
      resolution: state => state.resolution
    }),
    gdprCheck: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>
