<template>
  <div>
    <SidbearSettingsGroup
      title="Card"
      active
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <rule-tabs>
        <template #Normal>
          <sid-background :sid="`.--${section.sid}-- .${getSubject.sid}`" />
        </template>

        <template #Hover>
          <sid-background
            :sid="`.--${section.sid}--`"
            :presudo="` .${getSubject.sid}:hover`"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup title="Background Image">
      <rule-tabs>
        <template #Normal>
          <sid-upload-image
            :sid="`.--${section.sid}-- .${getSubject.sid}`"
            rule-name="background-image"
          />

          <b-form-group label="Background Image Size">
            <sid-select
              priority
              :searchable="false"
              :options="[
                {
                  label: 'Normal',
                  value: '',
                  customProperties: [[`.--${section.sid}-- .${getSubject.sid}`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Full Center (Parallax)',
                  value: 'fixed',
                  customProperties: [[`.--${section.sid}-- .${getSubject.sid}`, ['background-size', 'cover']], [`.--${section.sid}-- .${getSubject.sid}`, ['background-attachment', 'fixed']]]
                },
                {
                  label: 'Fill 100 % Width',
                  value: '100% auto',
                  customProperties: [[`.--${section.sid}-- .${getSubject.sid}`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Fill 100 % Width & Height',
                  value: '100% 100%',
                  customProperties: [[`.--${section.sid}-- .${getSubject.sid}`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat',
                  value: 'repeat',
                  property: 'background-repeat',
                  customProperties: [[`.--${section.sid}-- .${getSubject.sid}`, ['background-size', 'contain']], [`.--${section.sid}-- .${getSubject.sid}`, ['background-repeat', 'repeat']], [`.--${section.sid}-- .${getSubject.sid}`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat Horizontally',
                  value: 'repeat-x',
                  property: 'background-repeat',
                  customProperties: [[`.--${section.sid}-- .${getSubject.sid}`, ['background-size', 'contain']], [`.--${section.sid}-- .${getSubject.sid}`, ['background-repeat', 'repeat-x']], [`.--${section.sid}-- .${getSubject.sid}`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Repeat Vertically',
                  value: 'repeat-y',
                  property: 'background-repeat',
                  customProperties: [[`.--${section.sid}-- .${getSubject.sid}`, ['background-size', 'contain']], [`.--${section.sid}-- .${getSubject.sid}`, ['background-repeat', 'repeat-y']], [`.--${section.sid}-- .${getSubject.sid}`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Cover',
                  value: 'cover',
                  customProperties: [[`.--${section.sid}-- .${getSubject.sid}`, ['background-size', 'cover']], [`.--${section.sid}-- .${getSubject.sid}`, ['background-attachment', 'unset']]]
                },
                {
                  label: 'Contain',
                  value: 'contain',
                  customProperties: [[`.--${section.sid}-- .${getSubject.sid}`, ['background-size', 'contain']], [`.--${section.sid}-- .${getSubject.sid}`, ['background-attachment', 'unset']]]
                }
              ]"
              :sid="`.--${section.sid}-- .${getSubject.sid}`"
              var="--background-size"
              rule-name="background-size"
            />
          </b-form-group>

          <b-form-group label="Background Image Position">
            <sid-select
              :searchable="false"
              :options="[
                {label: 'Left Top', value: 'left top'},
                {label: 'Left Center', value: 'left center'},
                {label: 'Left Bottom', value: 'left bottom'},
                {label: 'Right Top', value: 'right top'},
                {label: 'Right Center', value: 'right center'},
                {label: 'Right Bottom', value: 'right bottom'},
                {label: 'Center Top', value: 'center top'},
                {label: 'Center Center', value: 'center center'},
                {label: 'Center Bottom', value: 'center bottom'},
              ]"
              :sid="`.--${section.sid}-- .${getSubject.sid}`"
              rule-name="background-position"
            />
          </b-form-group>

          <sid-range-slider
            :sid="`.--${section.sid}-- .${getSubject.sid}`"
            label="Custom Size"
            rule-name="background-size"
            var="--custom-bg-size"
            unlim
            :dimensions="['px', '%', 'vh', 'vw']"
          />

          <b-form-group label="Transform-Y">
            <sid-range-slider
              :sid="`.--${section.sid}-- .${getSubject.sid}`"
              rule-name="background-position-y"
            />
          </b-form-group>

          <b-form-group label="Transform-X">
            <sid-range-slider
              :sid="`.--${section.sid}-- .${getSubject.sid}`"
              rule-name="background-position-X"
            />
          </b-form-group>

          <sid-overlay
            :sid="`.${getSubject.sid} .el-overlay`"
            rule-name=""
          />

          <hr>

          <sid-background-filters
            :sid="`.--${section.sid}-- .${getSubject.sid}`"
            rule-name="filter"
          />

          <b-form-group>
            <sid-range-slider
              label="Opacity"
              rule-name="opacity"
              :dimensions="[]"
              default-dimension=""
              :sid="`.--${section.sid}-- .${getSubject.sid}`"
              :min="0"
              :max="1"
              :step="0.1"
            />
          </b-form-group>
        </template>
        <template #Hover>
          <sid-upload-image
            :sid="`.--${section.sid}--`"
            rule-name="background-image"
            :presudo="` .${getSubject.sid}:hover`"
          />

          <b-form-group label="Background Image Position">
            <sid-select
              :searchable="false"
              :presudo="` .${getSubject.sid}:hover`"
              :options="[
                {label: 'Left Top', value: 'left top'},
                {label: 'Left Center', value: 'left center'},
                {label: 'Left Bottom', value: 'left bottom'},
                {label: 'Right Top', value: 'right top'},
                {label: 'Right Center', value: 'right center'},
                {label: 'Right Bottom', value: 'right bottom'},
                {label: 'Center Top', value: 'center top'},
                {label: 'Center Center', value: 'center center'},
                {label: 'Center Bottom', value: 'center bottom'},
              ]"
              :sid="`.--${section.sid}--`"
              rule-name="background-position"
            />
          </b-form-group>

          <sid-range-slider
            :sid="`.--${section.sid}--`"
            label="Custom Size"
            rule-name="background-size"
            var="--custom-bg-size"
            :presudo="` .${getSubject.sid}:hover`"
            unlim
            :dimensions="['px', '%', 'vh', 'vw']"
          />

          <b-form-group label="Transform-Y">
            <sid-range-slider
              :presudo="` .${getSubject.sid}:hover`"
              :sid="`.--${section.sid}--`"
              rule-name="background-position-y"
            />
          </b-form-group>

          <b-form-group label="Transform-X">
            <sid-range-slider
              :presudo="` .${getSubject.sid}:hover`"
              :sid="`.--${section.sid}--`"
              rule-name="background-position-X"
            />
          </b-form-group>

          <sid-background-filters
            :sid="`.--${section.sid}--`"
            rule-name="filter"
            :presudo="` .${getSubject.sid}:hover`"
          />

          <b-form-group>
            <sid-range-slider
              label="Opacity"
              rule-name="opacity"
              :presudo="` .${getSubject.sid}:hover`"
              :dimensions="[]"
              default-dimension=""
              :sid="`.--${section.sid}--`"
              :min="0"
              :max="1"
              :step="0.1"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}-- .${getSubject.sid}`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}-- .${getSubject.sid}`"
              label="Border Width"
              rule-name="border"
              css-var="--border-size"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}-- .${getSubject.sid}`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}-- .${getSubject.sid}`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :presudo="` .${getSubject.sid}:hover`"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--`"
              label="Border Width"
              rule-name="border"
              css-var="--border-size"
              class="!mb-[7px]"
              :presudo="` .${getSubject.sid}:hover`"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--`"
                rule-name="border-color"
                :presudo="` .${getSubject.sid}:hover`"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--`"
            rule-name="border"
            :presudo="` .${getSubject.sid}:hover`"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Drop Shadow">
      <rule-tabs>
        <template #Normal>
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}-- .${getSubject.sid}`"
              rule-name="box-shadow"
            />
          </b-form-group>
        </template>
        <template #Hover>
          <b-form-group
            label="Shadow Color"
            class="mb-0"
          >
            <sid-box-shadow
              :sid="`.--${section.sid}--`"
              rule-name="box-shadow"
              :presudo="` .${getSubject.sid}:hover`"
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}-- .${getSubject.sid}`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidUploadImage
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidUploadImage.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import SidBackgroundFilters
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidBackgroundFilters.vue'
import SidOverlay
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidOverlay.vue'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import shortid from 'shortid'

export default {
  name: 'CardCarouselMod',
  components: {
    SidLinkedGroup, SidColor, SidBoxShadow,
    SidOverlay,
    SidBackgroundFilters,
    SidSelect,
    SidRangeSlider,
    SidUploadImage,
    RuleTabs, SidBackground, SidbearSettingsGroup},
  mixins: [ModMixin],
  computed: {
    getSubject () {
      return this.openedItemOptions.subject ? this.openedItemOptions.subject : this.config.options.content.carousel.cards.find(col => col.sid === this.openedItemOptions.subjectIndex)
    }
  },
  methods: {
    checkLinked () {
      this.linked = document.body.querySelectorAll(`[data-sid="${this.getSubject.sid}"]`).length > 1
    },
    unlinkSid () {
      const newSid = `col-${shortid.generate()}`

      this.multiSidIndexOf(this.getSubject.sid).forEach((sheet, sheetIndex) => {
        sheet.forEach(index => {
          this.styleSheets[sheetIndex].insertRule(this.styleSheets[sheetIndex].cssRules[index].cssText.replace(this.getSubject.sid, newSid), this.styleSheets[sheetIndex].cssRules.length)
        })
      })

      this.$set(this.getSubject, 'sid', newSid)
      this.linked = false

      this.sectionSettings('carousel')
    }
  }
}
</script>