<template>
  <div
    class="mb-[20px]"
    @mouseenter="() => $set(item, 'highlight', true)"
    @mouseleave="() => $set(item, 'highlight', false)"
  >
    <div
      class="overflow-hidden flex items-center border rounded-[2px] text-[15px] text-[#7E7E7E] h-[29px] gap-[11px] pl-[14px] mb-[6px]"
    >
      <div>
        <svg
          class="move cursor-move"
          fill="none"
          height="10"
          viewBox="0 0 11 10"
          width="11"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.57745 0H0.568359V3H3.57745V0Z"
            fill="#3D82EA"
          />
          <path
            d="M10.5984 0H7.58936V3H10.5984V0Z"
            fill="#3D82EA"
          />
          <path
            d="M3.57745 7H0.568359V10H3.57745V7Z"
            fill="#3D82EA"
          />
          <path
            d="M10.5984 7H7.58936V10H10.5984V7Z"
            fill="#3D82EA"
          />
        </svg>
      </div>

      <input
        v-model="name"
        type="text"
        class="w-[100px]"
        @blur="onBlur"
      >

      <div class="flex ml-auto">
        <button
          v-b-tooltip
          class="flex h-[27px] items-center justify-center border-left w-[29px] p-0 hover:bg-gray-100"
          title="Delete"
          @click="() => $emit('delete')"
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.32913 0.504198C1.10132 0.276392 0.731973 0.276392 0.504167 0.504198C0.276362 0.732004 0.276362 1.10135 0.504167 1.32916L4.17507 5.00005L0.504167 8.67095C0.276362 8.89876 0.276362 9.26811 0.504167 9.49591C0.731973 9.72372 1.10132 9.72372 1.32913 9.49591L5.00002 5.82501L8.67083 9.49582C8.89864 9.72363 9.26799 9.72363 9.49579 9.49582C9.7236 9.26802 9.7236 8.89867 9.49579 8.67087L5.82498 5.00005L9.49579 1.32924C9.7236 1.10144 9.7236 0.732092 9.49579 0.504286C9.26799 0.27648 8.89864 0.27648 8.67083 0.504286L5.00002 4.1751L1.32913 0.504198Z"
              fill="#6E747A"
            />
          </svg>
        </button>
        <button
          class="flex h-[27px] items-center justify-center border-left w-[29px] p-0 hover:bg-gray-100"
          :class="{'!bg-[#007bff]': active}"
          @click="() => $emit('open')"
        >
          <svg
            fill="none"
            height="10"
            viewBox="0 0 7 10"
            width="7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.879944 9.23245L4.98805 4.89924L0.879944 0.566031"
              :stroke="active ? '#fff' : '#6E747A'"
              stroke-width="1.5"
            />
          </svg>
        </button>
      </div>
    </div>

    <slot
      v-if="active"
      name="active"
    />
  </div>
</template>

<script>
import ResolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  name: 'ModDragItem',
  mixins: [ResolutionMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean
    }
  },
  computed: {
    name: {
      get () {
        return this.item.name || `Item #${this.item.uid}`
      },
      set (val) {
        this.$set(this.item, 'name', val)
      }
    }
  },
  methods: {
    onBlur (e) {
      if (!e.target.value) {
        this.item.name = 'Item Name'
        e.target.value = this.item.name
      }
    }
  }
}
</script>