<template>
  <img
    v-if="visibility && config.src"
    :src="config.src"
    alt="float image"
    class="img-fluid"
    :class="config.fixed ? 'position-fixed' : 'position-absolute'"
    :style="{...transform, ...size, ...opacity, ...zIndex}"
  >
</template>

<script>
import {mapState} from 'vuex'
import BorderMixin from '@/components/mixins/BorderMixin'
import _ from 'lodash'

export default {
  name: 'FloatImage',
  mixins: [BorderMixin],
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('editor', {
      resolution: state => state.resolution
    }),
    zIndex () {
      return {
        zIndex: this.config.action ? 10000 : _.get(this.config, 'zIndex', 0)
      }
    },
    size () {
      const width = _.get(this.config, ['size', this.resolutioner], -1)
      return {
        width: width ? `${width}px` : null
      }
    },
    opacity () {
      return {
        opacity: _.get(this.config, 'opacity', 1)
      }
    },
    rotate: {
      get () {
        return _.get(this.config, ['rotate', this.resolutioner], _.get(this.config, ['rotate', 'lg'], 0))
      },
      set (val) {
        this.$set(this.config, 'rotate', {..._.set(this.config['rotate'] || {}, [this.resolutioner], val)})
      }
    },
    top () {
      return _.get(this.config, ['top', this.resolutioner], 0)
    },
    left () {
      return _.get(this.config, ['left', this.resolutioner], 0)
    },
    flip () {
      const flip = this.config.flip
      if (!flip) return ''
      return `${flip.v ? 'scaleX(-1)' : ''} ${flip.h ? 'scaleY(-1)' : ''}`
    },
    transform () {
      return {
        top: `${this.top}%`,
        left: `${this.left}%`,
        transform: `translateY(-${this.top}%) translateX(-${this.left}%) rotate(${this.rotate}deg) ${this.flip}`
      }
    },
    visibility () {
      if (typeof this.config.display === 'boolean') {
        return this.config.display
      }

      return this.config.display.includes(this.resolutioner)
    }
  }
}
</script>
