<template>
  <div>
    <fieldset class="custom-label mb-2">
      Does Your Site Represent an Organization or a person?
    </fieldset>
    <b-row>
      <b-col md="6">
        <b-form-group>
          <v-select
            v-model="form.org_or_person"
            :searchable="false"
            :reduce="val => val.value"
            :options="[{label: 'Organization', value: 'organization'}, {label: 'Person', value: 'person'}]"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="form.org_or_person === 'organization'"
        md="7"
      >
        <b-form-group
          label-class="custom-label"
          label="Organization Name"
        >
          <b-input v-model="form.org_name" />
        </b-form-group>

        <b-form-group
          label-class="custom-label"
          label="Organization Logo"
        >
          <image-upload
            :image="form.org_logo"
            class="w-100 mb-3"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ProjectSeoOrganization',
  props: {
    form: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.custom-label {
  font-family: 'ProximaNova';
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #000 !important;
}
</style>