<template>
  <highliter
    v-model="section.name"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings(true)"
  >
    <ActionItem3Template :section="section">
      <template slot="elements">
        <elements v-model="section.options.content.elements" />
      </template>

      <template slot="price">
        <highliter
          v-show="visability('price')"
          class="hide-higlghter-toolbar"
          :style="{textAlign: section.options.content.price.align}"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('price')"
        >
          <es-price :customs="section.options.content.price" />
        </highliter>
      </template>

      <template slot="elements2">
        <elements v-model="section.options.content.elements2" />
      </template>

      <template
        v-if="!style.payments.value.includes('None')"
        slot="icons"
      >
        <icon
          v-if="style.payments.value.includes('Visa')"
          class="mr-3"
          icon="visa.svg"
        />
        <icon
          v-if="style.payments.value.includes('Amex')"
          class="mr-3"
          icon="amex.svg"
        />
        <icon
          v-if="style.payments.value.includes('MasterCard')"
          class="mr-3"
          icon="master-card.svg"
        />
        <icon
          v-if="style.payments.value.includes('Paypal')"
          icon="paypal.svg"
        />
      </template>
    </ActionItem3Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ActionItem3Template from '@builder/templates/sections/actions/ActionItem3Template'
import EsPrice from '../../utils/EsPrice'
import Elements from '../../utils/Elements/Elements'

export default {
  name: 'ActionItem2',

  components: {Elements, EsPrice, ActionItem3Template, Highliter},

  mixins: [SectionMixin]
}
</script>
