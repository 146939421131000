<template>
  <div class="pt-[9px]">
    <background-customs :config="section.options.customize.style.background" />

    <SidbearSettingsGroup
      class="border-top"
      title="Border & Radius"
    >
      <border-customs
        :config="config"
        :default-radius="[10,10,10,10]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <shadow-customs :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <height-customs :config="section.options.customize.style.padding" />
      <margin-top-customs :config="section" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'

export default {
  name: 'TestimonialMod',
  components: {SidbearSettingsGroup},
  mixins: [ModMixin],
  computed: {}
}
</script>
