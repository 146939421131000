<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="feature-section"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings(true)"
  >
    <AboutUs1Template :section="section">
      <template slot="image">
        <highliter
          :disable-toolbar="true"
          @delete="$emit('delete')"
          @dublicate="$emit('dublicate')"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @click.stop.native="sectionSettings('image')"
        >
          <image-fit
            :class="{'element-hidden': !visability('image')}"
            :customs="section.options.content.image"
            :height="cols('285px', '200px')"
            :position="section.options.content.image.position"
            :size="cols(section.options.content.image.size, 'contain')"
            :src="section.options.content.image.src || section.options.content.image.placeholder"
          />
        </highliter>
      </template>

      <template slot="elements">
        <elements
          v-model="section.options.content.elements"
          @flow="handleFlow"
        />
      </template>
    </AboutUs1Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import AboutUs1Template from './AboutUs1Template'
import ImageFit from '../../../../utils/ImageFit'
import Elements from '../../../utils/Elements/Elements'

export default {
  name: 'FeaturedItems1',

  components: {Elements, ImageFit, AboutUs1Template, Highliter},

  mixins: [SectionMixin]
}
</script>
