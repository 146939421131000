import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Footer 1'
export const group = 'Footer'

export const defaultOptions = {
  content: {
    items: {
      type: null,
      children: [
        {
          'uid': 'WKtPEOYr2',
          'sid': 'global--tWdGhRkC6v',
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': 'footer',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [{
                'display': true,
                'name': 'Flex Column',
                'elements': [],
                'animations': {'animation': null, 'once': true, 'offset': 200, 'delay': 50},
                'sortIndex': {'lg': 0, 'md': 0, 'sm': 0},
                'sid': 'l6cch77b--a3qQjTjb7e',
                'uid': 'O3_v-patF',
                'highlight': false
              }, {
                'display': true,
                'name': 'Flex Column',
                'elements': [],
                'animations': {'animation': null, 'once': true, 'offset': 200, 'delay': 50},
                'sortIndex': {'lg': 0, 'md': 0, 'sm': 0},
                'sid': 'l6cch77b--khhBkBuLA3',
                'uid': 'O0iKXDtYb1',
                'highlight': false
              }]
            }
          },
          'temp_id': 'rKQgPuy70'
        }
      ]
    }
  },
  backgroundImage: {
    src: ''
  },
  backgroundVideo: {
    enabled: false,
    url: '',
    loop: true,
    ratio: 1
  }
}

export const previews = {
  left: require('@builder/assets/svg/previews/price/left-1.svg'),
  right: null,
  main: require('@builder/assets/svg/previews/navbar/main-1.svg')
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('BlankV2', options)

    this.name = name
    this.group = group
    this.css = [['.--global--tWdGhRkC6v--container { padding-right: 0px; }', '.--l6cch77b--a3qQjTjb7e--flex-col-padding { padding: 0px; }', '.--l6cch77b--khhBkBuLA3--flex-col-padding { padding: 0px; }', '.--l6cch77b--a3qQjTjb7e--flex-col-el { width: 20%; background-color: rgba(255, 255, 255, 0); }', '.--l6cch77b--khhBkBuLA3--flex-col-el { width: 100%; }', '.--global--vAgYkFKut2--container { max-width: 100vw; }'], ['.--global--tWdGhRkC6v--flex { flex-wrap: wrap; }', '.--l6cch77b--a3qQjTjb7e--flex-col-el { width: 100%;}'], []],
    this.sids = ['global--vAgYkFKut2', 'global--tWdGhRkC6v', 'l6cch77b--a3qQjTjb7e', 'l6cch77b--khhBkBuLA3']
    this.sid = ['global--vAgYkFKut2']
    this.defaultOptions = defaultOptions
  }
}
