<template>
  <svg
    v-if="config.type === 1"
    :style="{height: `${config.height}px`}"
    fill="none"
    preserveAspectRatio="none"
    viewBox="0 0 1200 120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="config.color"
      d="M0 0V3.6H580.08C591.08 3.6 600 8.69 600 16.8C600 8.66 608.88 3.6 619.92 3.6H1200V0H0Z"
    />
  </svg>

  <svg
    v-else-if="config.type === 2"
    :style="{height: `${config.height}px`}"
    fill="none"
    preserveAspectRatio="none"
    viewBox="0 0 1200 120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="config.color"
      d="M1200 0H0L892.25 114.72L1200 0Z"
    />
  </svg>

  <svg
    v-else-if="config.type === 3"
    :style="{height: `${config.height}px`}"
    fill="none"
    preserveAspectRatio="none"
    viewBox="0 0 1200 120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="config.color"
      d="M0 0V7.23C0 65.52 268.63 112.77 600 112.77C931.37 112.77 1200 65.52 1200 7.23V0H0Z"
    />
  </svg>

  <svg
    v-else-if="config.type === 4"
    :style="{height: `${config.height}px`}"
    fill="none"
    preserveAspectRatio="none"
    viewBox="0 0 1200 110"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="config.color"
      d="M321.39 56.3541C379.39 45.5805 435.55 26.27 493.39 14.5578C575.78 -2.13674 661.58 -3.14521 743.84 14.1684C823.78 30.9528 906.67 71.8904 985.66 92.6887C1055.71 111.141 1132.19 118.739 1200 95.6841V0H0V27.3084C102.466 66.1034 213.613 76.1484 321.39 56.3541Z"
    />
  </svg>


  <svg
    v-else-if="config.type === 5"
    :style="{height: `${config.height}px`}"
    fill="none"
    preserveAspectRatio="none"
    viewBox="0 0 1200 120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_56_74)">
      <path
        :fill="config.color"
        d="M1156.85 92.3052C1078.69 82.009 928.02 83.8003 862.455 85.9829C797.151 87.2429 791.246 81.9921 796.456 79.2091L833.364 52.7912L966.664 43.7594L1156.85 52.7912C1189.41 70.2526 1235 102.601 1156.85 92.3052Z"
        fill-opacity="0.1"
      />
      <path
        :fill="config.color"
        d="M551.666 82.3308C357.666 68.797 147.777 87.9699 67.083 99.2481L102.5 63.1579L371.25 40.6015C512.222 60.1504 745.666 95.8647 551.666 82.3308Z"
        fill-opacity="0.1"
      />
      <path
        :fill="config.color"
        d="M-15.8335 120L24.1665 110.916C64.1665 101.662 144.167 83.7512 224.167 74.4971C304.167 65.4135 384.167 65.4135 464.167 76.7999C544.167 88.0157 624.167 111.044 704.167 108.614C784.167 106.353 864.167 79.0601 944.167 74.4971C1024.17 70.1046 1104.17 88.0157 1144.17 97.2698L1200 110.916V10.8271H1144.17C1104.17 10.8271 1024.17 10.8271 944.167 10.8271C864.167 10.8271 784.167 10.8271 704.167 10.8271C624.167 10.8271 544.167 10.8271 464.167 10.8271C384.167 10.8271 304.167 10.8271 224.167 10.8271C144.167 10.8271 64.1665 10.8271 24.1665 10.8271H-15.8335V120Z"
        fill-opacity="0.2"
      />
      <path
        :fill="config.color"
        d="M0 115.489L40 105.88C80 96.0902 160 77.1429 240 67.3534C320 57.7444 400 57.7444 480 69.7895C560 81.6541 640 106.015 720 103.444C800 101.053 880 72.1805 960 67.3534C1040 62.7068 1120 81.6541 1160 91.4436L1200 101.053V0H1160C1120 0 1040 0 960 0C880 0 800 0 720 0C640 0 560 0 480 0C400 0 320 0 240 0C160 0 80 0 40 0H0V115.489Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_56_74">
        <rect
          fill="white"
          height="120"
          width="1200"
        />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="config.type === 6"
    :style="{height: `${config.height}px`}"
    fill="none"
    preserveAspectRatio="none"
    viewBox="0 0 1200 120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_56_93)">
      <path
        :fill="config.color"
        d="M0 0V50.3678C47.79 74.5234 103.59 85.3717 158 80.8344C228.36 74.9913 294.33 44.59 364.8 40.0309C438.64 35.2868 512.34 58.3979 583 78.397C652.27 97.9827 721.3 105.469 792.4 92.6293C828.55 86.1007 862.25 73.2177 896.85 60.7047C989.49 27.2023 1113 -15.5488 1200 57.0922V0H0Z"
        opacity="0.25"
      />
      <path
        :fill="config.color"
        d="M0 0V17.249C13 40.2803 27.64 62.0352 47.69 78.6077C99.41 121.397 165 121.103 224.58 99.9153C255.73 88.8414 284.67 71.4725 314.25 56.4928C355.17 35.7635 398.98 6.30607 445.08 2.30204C481.34 -0.807352 515.98 12.5794 543.68 36.7345C575.45 64.4354 606 104.378 647.31 116.379C687.75 128.151 728.66 109.08 766.44 89.8888C804.22 70.6978 841.6 47.3392 883.36 42.9206C943.09 36.5381 996.64 67.883 1052.26 85.2956C1082.46 94.7438 1111.26 92.0272 1139.35 77.113C1161.78 65.2318 1187.35 47.732 1200 23.3914V0H0Z"
        opacity="0.5"
      />
      <path
        :fill="config.color"
        d="M0 0V6.13108C149.93 64.2511 314.09 77.6676 475.83 46.3588C518.83 38.0388 560.06 24.4481 603.44 17.5438C662.44 8.14573 715.92 30.8732 769 56.0945C827.93 84.0927 886 103.717 951.2 98.0101C1037.73 90.3871 1123.66 48.2319 1200 5.65192V0H0Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_56_93">
        <rect
          fill="white"
          height="120"
          width="1200"
        />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="config.type === 7"
    :style="{height: `${config.height}px`}"
    fill="none"
    preserveAspectRatio="none"
    viewBox="0 0 1200 120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="config.color"
      d="M1200 0H0L598.97 114.72L1200 0Z"
    />
  </svg>

  <svg
    v-else-if="config.type === 8"
    :style="{height: `${config.height}px`}"
    fill="none"
    preserveAspectRatio="none"
    viewBox="0 0 1200 120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_60_103)">
      <path
        :fill="config.color"
        d="M1200 0H0V120H281.94C572.9 116.24 602.45 3.86 602.45 3.86C602.45 3.86 632 116.24 923 120H1200V0Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_60_103">
        <rect
          fill="transparent"
          height="120"
          width="1200"
        />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="config.type === 9"
    :style="{height: `${config.height}px`}"
    fill="none"
    preserveAspectRatio="none"
    viewBox="0 0 1200 120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_60_101)">
      <path
        :fill="config.color"
        d="M1200 120L0 16.48V0H1200V120Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_60_101">
        <rect
          fill="white"
          height="120"
          width="1200"
        />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else-if="config.type === 10"
    :style="{height: `${config.height}px`}"
    fill="none"
    preserveAspectRatio="none"
    viewBox="0 0 1200 120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :fill="config.color"
      d="M649.97 0H550.03L599.91 54.12L649.97 0Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Shape',
  props: {
    config: {
      type: Object,
      required: true
    }
  }
}
</script>