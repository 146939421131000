<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="time-section highlight-element"
    section-type="element"
    @delete="remove"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @click.stop.native="sectionSettings('time')"
    @add-subject="$emit('add-subject')"
  >
    <Template :section="section">
      <template v-slot:time="{shadow, backgroundColor, color, scale, sizing}">
        <vac
          ref="vac"
          :end-time="timeZoneTransform"
        >
          <template
            v-slot:process="{ timeObj }"
          >
            <div
              class="timer d-flex flex-wrap align-items-center justify-content-center timer-block"
            >
              <template v-if="!style.dontShow.value.includes('Days')">
                <div
                  :style="{...backgroundColor, ...shadow}"
                  class="timer-item border-right-custom"
                >
                  <span
                    :style="{fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale}rem`}"
                    class="time"
                    data-color
                  >{{ timeObj.d < 10 ? '0' + timeObj.d : timeObj.d }}</span>
                  <span
                    :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                    class="subtitle"
                  >days</span>
                </div>
              </template>
              <template v-if="!style.dontShow.value.includes('Hours')">
                <div
                  :style="{...backgroundColor, ...shadow}"
                  class="timer-item border-right-custom"
                >
                  <span
                    :style="{fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale}rem`}"
                    class="time"
                    data-color
                  >{{ timeObj.h }}</span>
                  <span
                    :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                    class="subtitle"
                  >hours</span>
                </div>
              </template>
              <template v-if="!style.dontShow.value.includes('Minutes')">
                <div
                  :style="{...backgroundColor, ...shadow}"
                  class="timer-item border-right-custom"
                >
                  <span
                    :style="{fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale}rem`}"
                    class="time"
                    data-color
                  >{{ timeObj.m }}</span>
                  <span
                    :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                    class="subtitle"
                  >minutes</span>
                </div>
              </template>
              <div
                v-if="!style.dontShow.value.includes('Seconds')"
                :style="{...backgroundColor, ...shadow}"
                class="timer-item border-right-custom"
              >
                <span
                  :style="{fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale + 2}rem`, height: `${sizing.width * scale}rem`}"
                  class="time"
                  data-color
                >{{ timeObj.s }}</span>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >seconds</span>
              </div>
            </div>
          </template>
          <template
            v-slot:finish
          >
            <div
              :style="{...shadow, ...backgroundColor}"
              class="timer d-flex align-items-center justify-content-center timer-block"
            >
              <template v-if="!style.dontShow.value.includes('Days')">
                <div
                  :style="{...backgroundColor}"
                  class="timer-item border-right-custom"
                >
                  <span
                    :style="{fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale}rem`, height: `${sizing.width * scale}rem`}"
                    class="time"
                    data-color
                  >00</span>
                  <span
                    :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                    class="subtitle"
                  >days</span>
                </div>
              </template>
              <template v-if="!style.dontShow.value.includes('Hours')">
                <div
                  :style="{...backgroundColor}"
                  class="timer-item border-right-custom"
                >
                  <span
                    :style="{fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale}rem`, height: `${sizing.width * scale}rem`}"
                    class="time"
                    data-color
                  >00</span>
                  <span
                    :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                    class="subtitle"
                  >hours</span>
                </div>
              </template>
              <template v-if="!style.dontShow.value.includes('Minutes')">
                <div
                  :style="{...backgroundColor}"
                  class="timer-item border-right-custom"
                >
                  <span
                    :style="{fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale}rem`, height: `${sizing.width * scale}rem`}"
                    class="time"
                    data-color
                  >00</span>
                  <span
                    :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                    class="subtitle"
                  >minutes</span>
                </div>
              </template>
              <div
                v-if="!style.dontShow.value.includes('Seconds')"
                :style="{...backgroundColor}"
                class="timer-item border-right-custom"
              >
                <span
                  :style="{fontSize: `${sizing.counter * scale}rem`, width: `${sizing.width * scale}rem`, height: `${sizing.width * scale}rem`}"
                  class="time"
                  data-color
                >00</span>
                <span
                  :style="{...color, fontSize: `${sizing.subtitle * scale}rem`}"
                  class="subtitle"
                >seconds</span>
              </div>
            </div>
          </template>
        </vac>
      </template>
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import TimerService from '../TimerService'

export default {
  name: 'TimeItem1',

  components: {Template, Highliter},

  mixins: [SectionMixin, TimerService],

  created () {
    VEvent.listen('timer-update', () => {
      setTimeout(() => {
        if (this.$refs.vac === undefined) return
        this.$refs.vac.startCountdown(true)
      })
    })
  }
}
</script>
