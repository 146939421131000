<template>
  <highliter
    :is="highlither ? 'highliter': 'btn-wrapper'"
    v-model="section.name"
    :class="{'element-hidden': !visability('menu')}"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="highlither ? sectionSettings('menu') : null"
  >
    <span
      v-show="false"
      :class="`--${section.sid}--link-active link-active`"
    />
    <div
      class="flex flex-wrap el-links-wrapper"
      :data-sid="section.sid"
      :class="`--${section.sid}--wrapper`"
    >
      <menu-link-v4
        v-for="(link, index) in links"
        :key="index"
        :link="link"
        :menu="menu"
        :sid="section.sid"
        :link-class="section.options.content.menu.hoverAnimation"
      />

      <div class="d-none">
        <elements
          v-for="menu in section.options.content.menu.megaMenus"
          :key="menu.id"
          v-model="menu.content"
        />
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import MenuLinkV4 from '@/components/builder/elements/17.menu/v4/MenuLinkV4'
import BtnWrapper from '@/components/builder/elements/9.icon/BtnWrapper'
import Elements from '@/components/builder/utils/Elements/Elements'

export default {
  name: 'ElMenuLinksV4',
  components: {Elements, BtnWrapper, MenuLinkV4, Highliter},
  mixins: [SectionMixin],
  computed: {
    menu () {
      return this.section.options.content.menu
    },
    links () {
      return this.menu.links
    }
  }
}
</script>
