<template>
  <highliter
    v-model="section.name"
    class="feature-section"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings(true)"
  >
    <ListItem1Template :section="section">
      <template slot="list">
        <highliter
          v-show="visability('list')"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('list')"
        >
          <b-row>
            <b-col
              v-for="n in section.options.content.list.rows || 1"
              :key="n"
              :cols="cols(4, 12)"
              :class="cols('mb-0', 'mb-4')"
            >
              <li
                v-for="item in section.options.content.list.items[n]"
                :key="item.label"
                class="d-flex align-items-center"
              >
                <i
                  :style="{color: section.options.customize.style.listIcon.color}"
                  :class="section.options.customize.style.listIcon.icon"
                  class="mr-3"
                />
                <editable v-model="item.text" />
              </li>
            </b-col>
          </b-row>
        </highliter>
      </template>
    </ListItem1Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ListItem1Template from '@builder/templates/sections/list/ListItem1Template'

export default {
  name: 'FeaturedItems1',

  components: {ListItem1Template, Highliter},

  mixins: [SectionMixin],

  created () {
    this.section.classes = {
      tiptap: {}
    }
  },

  methods: {
    onInput (event, index) {
      this.section.options.content.list.items[index] = '123'
    }
  }
}
</script>
