<template>
  <div class="ml-[-20px] mr-[-21px] pl-[20px] pr-[21px] sid-select">
    <SidbearSettingsGroup
      active
      title="Timer"
    >
      <ElementPresset
        v-model="pressetID"
        :pressets="pressets"
        class="mb-[27px]"
        title="Timer Style"
      >
        <template
          slot="preview"
          slot-scope="{ content }"
        >
          <img
            :src="content.preview"
            alt="element presset image"
          >
        </template>
      </ElementPresset>

      <b-form-group label="Timezone">
        <v-select
          v-model="timeZone"
          :options="timezones"
        />
      </b-form-group>

      <b-form-group>
        <b-checkbox
          v-model="countdownType"
          value="date"
        >
          Set Countdown End Date & Time
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <date-picker
          v-model="fromDate"
          :clearable="false"
          :disabled-date="disabledBeforeToday"
          class="w-100"
          format="YYYY-MM-DD HH:mm"
          input-class="form-control"
          type="datetime"
          value-type="format"
        />
      </b-form-group>

      <b-form-group>
        <b-checkbox
          v-model="countdownType"
          value="time"
        >
          Set Countdown Duration hh:mm:ss
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <date-picker
          v-model="fromTime"
          :clearable="false"
          class="w-100"
          format="HH:mm:ss"
          input-class="form-control"
          type="time"
          value-type="format"
        />
      </b-form-group>

      <b-form-group>
        <b-checkbox
          v-if="countdownType === 'time'"
          v-model="onPageLoad"
          class="mb-2"
        >
          Start timer on page load
        </b-checkbox>
        <b-checkbox v-model="config.displayLabels">
          Display Labels
        </b-checkbox>
      </b-form-group>

      <checklist-customs :config="section.options.customize.style.dontShow" />

      <b-form-group
        class="px-1"
        label="Size"
      >
        <vue-slider
          v-model="size"
          :interval="0.1"
          :max="2"
          :min="0.1"
          class="editor-slider-1"
          tooltip="none"
        />
      </b-form-group>

      <b-form-group label="Expire Action">
        <v-select
          v-model="action"
          :options="actions"
          :reduce="option => option.value"
          class="custom-select"
          left
          placeholder="Select Action"
        />
      </b-form-group>

      <b-form-group label="Shoe Element After">
        <pick-element v-model="config" />
      </b-form-group>

      <template v-if="action">
        <action-modal
          v-if="action.type === 'modal'"
          :service="action"
          class="px-0"
        />
        <template v-else-if="action.type === 'redirect'">
          <b-form-group label="Page">
            <pages-select-menu
              :others="[{id: 'custom-link', title: 'Custom Link'}, {id: 'blog', title: 'ESTAGE Blog', code: project.blog_slug}]"
              input-class="rounded-[4px]"
              other-title="Other:"
              @selectOther="item => action.href = item.slug || item.id"
              @selectPage="item => action.href = item.slug || item.id"
            >
              <template
                v-if="action.href"
                slot="placeholder"
              >
                <div class="px-3 text-capitalize">
                  {{ action.href }}
                </div>
              </template>
            </pages-select-menu>
          </b-form-group>

          <b-form-group
            v-if="action.href === 'custom-link'"
            label="Custom link"
          >
            <b-input
              v-model="config.customLink"
              type="url"
            />
          </b-form-group>

          <b-form-group>
            <b-checkbox v-model="action.blank">
              Open In New Tab
            </b-checkbox>
          </b-form-group>
        </template>
      </template>

      <font-family-and-weight-control
        text-type="paragraph"
        :weight-enable="false"
        :family="dialFontFamily"
        @family="family => dialFontFamily = family"
      />

      <background-customs
        v-if="section.options.customize.style.background"
        :config="section.options.customize.style.background"
      />

      <color-customs
        v-if="section.options.customize.style.color"
        :config="section.options.customize.style.color"
      />

      <color-customs
        v-if="section.options.customize.style.colorSubtitle"
        :config="section.options.customize.style.colorSubtitle"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="section.options.customize.style.padding" />
      <margin-full-customs :config="margin" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <shadow-customs :config="config" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import {mapGetters, mapState} from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import * as Time1 from '../../../components/builder/sections/time/time-1/Section'
import * as Time2 from '../../../components/builder/sections/time/time-2/Section'
import * as Time3 from '../../../components/builder/sections/time/time-3/Section'
import * as Time4 from '../../../components/builder/sections/time/time-4/Section'
import * as Time5 from '../../../components/builder/sections/time/time-5/Section'
import * as Time6 from '../../../components/builder/sections/time/time-6/Section'
import ActionModal from '../../builder/utils/auto-responders/ActionModal'
import ChecklistCustoms from '../controls/ChecklistCustoms'
import _ from 'lodash'
import TimezonesMixin from '../../mixins/TimezonesMixin'
import PagesSelectMenu from '@/components/editor/components/pagesSelectMenu/PagesSelectMenu'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import ElementPresset from '@/components/editor/components/ElementPresset'
import PickElement from '@/components/editor/controls/PickElement'

export default {
  name: 'ButtonMod',
  components: {
    PickElement,
    ElementPresset, SidbearSettingsGroup, PagesSelectMenu, ChecklistCustoms, ActionModal, DatePicker},
  mixins: [ModMixin, TimezonesMixin],

  data () {
    return {
      actions: [
        {
          label: 'Modal',
          value: {
            type: 'modal',
            data: {
              modal_id: null
            }
          }
        },
        {
          label: 'Refresh Page',
          value: {
            type: 'refresh'
          }
        },
        {
          label: 'Redirect',
          value: {
            type: 'redirect',
            customLink: '',
            href: '',
            blank: false
          }
        },
        {
          label: 'Hide',
          value: null
        }
      ],
      fromDate: this.moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
      fromTime: this.config.offsetTime || this.moment().add(1, 'hours').format('HH:mm:ss'),
      pressets: [
        {
          id: 0,
          preview: Time1.previews.main,
          onSelect: () => {
            const section = _.cloneDeep(new Time1.default().json())
            const originalSection = _.cloneDeep(this.section)
            section.options.customize.style = originalSection.options.customize.style
            section.options.content.time.size = this.size

            this.selectLayout(section)
          }
        },
        {
          id: 1,
          preview: Time2.previews.main,
          onSelect: () => {
            const section = _.cloneDeep(new Time2.default().json())
            const originalSection = _.cloneDeep(this.section)
            section.options.customize.style = originalSection.options.customize.style
            section.options.content.time.size = this.size

            this.selectLayout(section)
          }
        },
        {
          id: 2,
          preview: Time3.previews.main,
          onSelect: () => {
            const section = _.cloneDeep(new Time3.default().json())
            const originalSection = _.cloneDeep(this.section)
            section.options.customize.style = originalSection.options.customize.style
            section.options.content.time.size = this.size

            this.selectLayout(section)
          }
        },
        {
          id: 3,
          preview: Time4.previews.main,
          onSelect: () => {
            const section = _.cloneDeep(new Time4.default().json())
            const originalSection = _.cloneDeep(this.section)
            section.options.customize.style = originalSection.options.customize.style
            section.options.content.time.size = this.size

            this.selectLayout(section)
          }
        },
        {
          id: 4,
          preview: Time5.previews.main,
          onSelect: () => {
            const section = _.cloneDeep(new Time5.default().json())
            const originalSection = _.cloneDeep(this.section)
            section.options.customize.style = originalSection.options.customize.style
            section.options.content.time.size = this.size

            this.selectLayout(section)
          }
        },
        {
          id: 5,
          preview: Time6.previews.main,
          onSelect: () => {
            const section = _.cloneDeep(new Time6.default().json())
            const originalSection = _.cloneDeep(this.section)
            section.options.customize.style = originalSection.options.customize.style
            section.options.content.time.size = this.size

            this.selectLayout(section)
          }
        }
      ]
    }
  },

  computed: {
    ...mapState({
      globalStyles: state => state.editor.globalStyles
    }),
    margin () {
      if (!this.section.options.customize.style.margin) {
        this.$set(this.section.options.customize.style, 'margin', {})
      }

      return this.section.options.customize.style.margin
    },
    pressetID: {
      get () {
        return this.section.pressetID || 0
      },
      set (val) {
        this.$set(this.section, 'pressetID', val)
      }
    },
    dialFontFamily: {
      get () {
        return _.get(this.config, 'dialFontFamily', this.globalStyles.font.body.fontFamily)
      },
      set (val) {
        this.$set(this.config, 'dialFontFamily', val)
      }
    },
    ...mapState('font', {
      googleFonts: state => state.fonts
    }),
    fonts () {
      return [
        'Global Style',
        ...this.googleFonts
      ]
    },
    ...mapState('editor', {
      resolution: state => state.resolution,
      tablet: state => state.tablet
    }),
    size: {
      get () {
        return _.get(this.config, [`size${this.tablet ? 'md' : this.resolution}`], this.config.size)
      },
      set (val) {
        this.$set(this.config, `size${this.tablet ? 'md' : this.resolution}`, val)
      }
    },
    ...mapState('projects', {
      project: state => state.project
    }),
    countdownType: {
      get () {
        return this.config.countdownType || 'date'
      },
      set (val) {
        this.$set(this.config, 'countdownType', val)
      }
    },
    timeZone: {
      get () {
        return this.config.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      set (val) {
        this.$set(this.config, 'timeZone', val)
      }
    },
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    sizing () {
      return this.config.sizing || null
    },
    action: {
      get () {
        return this.config.action || null
      },
      set (val) {
        this.$set(this.config, 'action', val)
      }
    },
    onPageLoad: {
      get () {
        return this.config.onPageLoad || false
      },
      set (val) {
        this.$set(this.config, 'onPageLoad', val)
      }
    },
    getTargetDate () {
      if (this.countdownType === 'date') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.onPageLoad = false
        return this.moment(this.fromDate).valueOf()
      } else {
        this.$set(this.config, 'offsetTime', this.fromTime)
        return new Date().getTime() + this.moment.duration(this.fromTime).asMilliseconds()
      }
    }
  },

  watch: {
    getTargetDate (val) {
      this.update(val)
    }
  },

  methods: {
    selectLayout (layout) {
      const section = layout
      this.$set(this.section, 'component', section.component)
      this.$set(this.section, 'name', section.name)
      this.$set(this.section, 'options', section.options)
    },
    disabledBeforeToday (date) {
      const today = this.moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm')

      return this.moment(date).isBefore(today)
    },
    update (val) {
      axios.put(`api/projects/${this.project.id}/timers/${this.config.id}`, {
        start_date: new Date().getTime(),
        end_date: val,
        content: '[]',
        is_active: true
      })
        .then(() => {
          VEvent.fire('timer-update')
          this.config.time = val
        })
    }
  }
}
</script>

<style lang="scss">
.section-timer-card {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.26);
  border-radius: 3px;
  height: 150px;
  margin-bottom: 30px;
  transition: .4s;

  &.active,
  &:hover {
    box-shadow: 0 0 0 3px #7CACFA;
  }
}
</style>
