<template>
  <div>
    <b-form-group label="Shadow">
      <div class="d-flex align-items-center">
        <div
          :class="{'active': spread === 0}"
          class="shadow-control-box text-uppercase"
          @click="() => spread = 0"
        >
          <icon icon="shadow-none.svg" />
        </div>

        <div
          v-for="(shadow, index) in shadows"
          :key="index"
          :class="['custom-shadow-' + shadow.key, {'active': spread === shadow.shadow}]"
          class="shadow-control-box text-uppercase"
          @click="() => selectShadow(shadow.shadow)"
        >
          {{ shadow.key }}
        </div>
      </div>
    </b-form-group>

    <b-form-group label="Color">
      <color-picker
        :color="shadowColor"
        @input="event => shadowColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
      />
    </b-form-group>

    <b-form-group
      label="Offset-X"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="offsetX"
            :interval="1"
            :max="100"
            :min="-100"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="offsetX"
            :max="100"
            :min="-100"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Offset-Y"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="offsetY"
            :interval="1"
            :max="100"
            :min="-100"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="offsetY"
            :max="100"
            :min="-100"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      label="Blur"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="blur"
            :interval="1"
            :max="70"
            :min="0"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="blur"
            :max="70"
            :min="1"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group
      v-if="!hide.includes('spread')"
      class="mb-0"
      label="Spread"
      label-class="!pb-[3px]"
    >
      <b-row class="align-items-center pl-2">
        <b-col
          cols="8"
        >
          <vue-slider
            v-model="spread"
            :interval="1"
            :max="70"
            :min="0"
            class="editor-slider-1"
            tooltip="none"
          />
        </b-col>
        <b-col>
          <b-input
            v-model="spread"
            :max="70"
            :min="0"
            :step="1"
            size="sm"
            type="number"
          />
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'
import _ from 'lodash'

export default {
  name: 'Shadow',

  mixins: [ControlMixin],

  data () {
    return {
      shadows: [
        {
          key: 's',
          shadow: 4
        },
        {
          key: 'm',
          shadow: 10
        },
        {
          key: 'l',
          shadow: 22
        }
      ]
    }
  },

  computed: {
    shadowOptions () {
      return _.get(this.config, 'shadowOptions', {})
    },
    offsetX: {
      get () {
        return _.get(this.config, ['shadowOptions', 'offsetX'], 0)
      },
      set (val) {
        this.$set(this.config, 'shadowOptions', {..._.set(this.shadowOptions, ['offsetX'], val)})
      }
    },
    offsetY: {
      get () {
        return _.get(this.config, ['shadowOptions', 'offsetY'], 0)
      },
      set (val) {
        this.$set(this.config, 'shadowOptions', {..._.set(this.shadowOptions, ['offsetY'], val)})
      }
    },
    blur: {
      get () {
        return _.get(this.config, ['shadowOptions', 'blur'], 30)
      },
      set (val) {
        this.$set(this.config, 'shadowOptions', {..._.set(this.shadowOptions, ['blur'], val)})
      }
    },
    spread: {
      get () {
        return _.get(this.config, ['shadowOptions', 'spread'], null)
      },
      set (val) {
        this.$set(this.config, 'shadowOptions', {..._.set(this.shadowOptions, ['spread'], val)})
      }
    },
    shadowColor: {
      get () {
        return this.config.shadowColor || '#eee'
      },
      set (val) {
        this.$set(this.config, 'shadowColor', val)
      }
    }
  },
  methods: {
    selectShadow (shadow) {
      this.spread = shadow
      this.blur = 20
    }
  }
}
</script>

