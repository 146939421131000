<template>
  <div
    v-if="true"
    class="min-h-[500px]"
  >
    <SidbearSettingsGroup
      active
      title="Video"
    >
      <b-form-group label="Video URL">
        <b-input
          v-model="config.video.src"
          placeholder="URL"
        />
      </b-form-group>

      <b-form-group>
        <b-checkbox v-model="overlayEnabled">
          Overlay
        </b-checkbox>
      </b-form-group>

      <b-form-group
        class="mb-0"
        label="Color"
      >
        <color-picker
          :color="config.video.overlay.color"
          @input="event => config.video.overlay.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <div>
      <component
        :is="config.subject.options.content.image.type"
        :config="config.subject.options.content.image"
        :local-section="config.subject"
        :show-active-group="false"
        component-name="Thumbnail"
      />
    </div>
  </div>
  <div
    v-else
    class="custom-menu"
  >
    <div class="d-flex align-items-center mb-4">
      <span class="indicator mr-2" />
      <p class="text-capitalize mb-0">
        {{ labelCase(componentName) }}
      </p>
    </div>

    <b-form-group label="Overlay Color">
      <color-picker
        :color="config.video.overlay.color"
        @input="event => config.video.overlay.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
      />
    </b-form-group>

    <b-form-group label="Video URL">
      <b-input
        v-model="config.video.src"
        placeholder="URL"
      />
    </b-form-group>

    <b-form-group label="Image">
      <image-upload
        :image="config.image"
        class="w-100"
      />
    </b-form-group>

    <template v-if="config.image.ratio">
      <b-form-group label="Height & Width Unit">
        <v-select
          v-model="config.image.ratio.type"
          :options="['px', 'vh']"
          :searchable="false"
          class="custom-select"
        />
      </b-form-group>

      <b-form-group label="Height">
        <b-input
          v-model="config.image.ratio[resolution].h"
          type="number"
        />
      </b-form-group>
    </template>

    <!--    <action-modal class="px-0" :service="customModal"></action-modal>-->

    <animations :animations="animations" />
  </div>
</template>

<script>
import _ from 'lodash'
import ModMixin from '@builder/components/mixins/ModMixin'
import ImageUpload from '../utils/ImageUpload'
import Animations from '../controls/Animations'
import ColorPicker from '../utils/ColorPicker'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'

export default {
  name: 'ImageMod',
  components: {SidbearSettingsGroup, ColorPicker, Animations, ImageUpload},
  mixins: [ModMixin],

  data () {
    return {
      file: [],
      url: ''
    }
  },

  computed: {
    overlayEnabled: {
      get () {
        return _.get(this.config.video.overlay, ['enable', this.resolutioner], _.get(this.config.video.overlay, ['enable', 'lg'], true))
      },
      set (val) {
        this.$set(this.config.video.overlay, 'enable', {..._.set(this.config.video.overlay['enable'] || {}, [this.resolutioner], val)})
      }
    },
    customModal () {
      if (!this.config.hasOwnProperty('modal')) {
        this.$set(this.config, 'modal', {
          data: {
            modal_id: null
          }
        })
      }

      return this.config.modal
    },
    animations: {
      get () {
        if (!this.config.hasOwnProperty('animations')) {
          this.$set(this.config, 'animations', {
            animation: null,
            once: true,
            offset: 200,
            delay: 50
          })
        }

        return this.config.animations
      },
      set (val) {
        this.config.animations = val
      }
    },
    ratio () {
      return {
        type: this.config.ratio.type,
        ...this.config.ratio[this.resolution]
      }
    },
    align: {
      get () {
        return this.config.align[this.resolution]
      },
      set (val) {
        this.config.align[this.resolution] = val
      }
    }
  },

  watch: {
    file () {
      // this.url = URL.createObjectURL(this.file)
      // this.config.src= this.url

      let formData = new FormData()

      formData.append('file', this.file)

      axios.post('api/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(({data}) => {
          this.config.src = data.data.url
          this.config.id = data.data.id
        })
    }
  },

  created () {
    if (!this.config.hasOwnProperty('align') || typeof this.config.align === 'string') {
      this.$set(this.config, 'align', {
        sm: 'center',
        lg: 'center'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-es-image-upload {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #E2E5EC;
  font-size: 14px;
  font-weight: 600;
  color: #6E747A;
  height: 38px;
  box-shadow: 0 1px 2px 0 rgba(226, 226, 226, 0.5)
}
</style>
