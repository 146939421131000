<template>
  <div>
    <b-form
      v-if="showForm()"
      :inline="inline"
      class="es-form"
      @submit.prevent="submit"
    >
      <b-form-group
        v-show="$slots.hasOwnProperty('top')"
        class="px-3"
      >
        <slot name="top" />
      </b-form-group>
      <b-row>
        <b-col
          v-for="(field, index) in customs.fields"
          v-if="field.display"
          :key="index"
          :cols="field.hasOwnProperty('cols') ? cols(field.cols.lg, field.cols.sm) : 12"
        >
          <b-form-group>
            <template
              v-if="field.label"
              slot="label"
            >
              {{ field.label }} <span
                v-if="field.required"
                class="text-danger"
              >*</span>
            </template>

            <b-input-group :class="[{'form-prepend': $slots.hasOwnProperty('prepend-' + field.name)}, {'form-append': $slots.hasOwnProperty('append-' + field.name)}]">
              <template slot="prepend">
                <div
                  v-if="$slots.hasOwnProperty('prepend-' + field.name)"
                  class="mr-3 d-flex align-items-center"
                >
                  <slot :name="'prepend-' + field.name" />
                </div>
              </template>

              <template slot="append">
                <div
                  v-if="$slots.hasOwnProperty('append-' + field.name)"
                  class="ml-3 d-flex align-items-center"
                >
                  <slot :name="'append-' + field.name" />
                </div>
              </template>

              <template v-if="!field.hasOwnProperty('fieldType') || field.fieldType === null || field.fieldType === 'text'">
                <b-form-input
                  v-if="isPereview"
                  v-model="field.value"
                  :size="size"
                  :type="field.type"
                  :name="field.name"
                  :placeholder="field.placeholder"
                  :required="field.required"
                />
                <b-form-input
                  v-else
                  :size="size"
                  :type="field.type"
                  :name="field.name"
                  :placeholder="field.placeholder"
                  :required="field.required"
                />
              </template>

              <template v-else-if="field.fieldType === 'textarea'">
                <b-textarea
                  v-if="isPereview"
                  v-model="field.value"
                  rows="5"
                  :name="field.name"
                  :placeholder="field.placeholder"
                  :required="field.required"
                />
                <b-textarea
                  v-else
                  rows="5"
                  :name="field.name"
                  :placeholder="field.placeholder"
                  :required="field.required"
                />
              </template>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group v-show="$slots.hasOwnProperty('submit')">
        <slot name="submit" />
      </b-form-group>
    </b-form>

    <b-alert
      v-else-if="$route.name == 'preview'"
      show
      variant="danger"
      class="bg-white"
    >
      Settings up the form to display it publicly
    </b-alert>

    <b-modal
      v-model="messageModal"
      centered
      size="lg"
      hide-footer
      hide-header
    >
      <icon
        class="cursor-pinter ml-3"
        icon="close.svg"
        @click.native="messageModal = false"
      />

      <pub-form-settings-editor
        v-model="customs.successMessage.text"
        class="py-4"
        :config="customs.successMessage"
      />
    </b-modal>
  </div>
</template>

<script>
import ComponentProvider from '@builder/components/mixins/ComponentProvider'
import PubFormSettingsEditor from '@builder/components/editor/utils/PubFormSettingsEditor'
import {mapGetters} from 'vuex'
import _ from 'lodash'
import SendForm from '@builder/components/builder/utils/auto-responders/mixins/SendForm'

export default {
  name: 'EsForm',
  components: {PubFormSettingsEditor},
  mixins: [ComponentProvider, SendForm],

  props: {
    size: {
      type: String,
      default: ''
    },
    inline: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      messageModal: false
    }
  },

  computed: {
    isPereview () {
      return this.$route.name === 'preview' || this.$route.name === 'published'
    },
    ...mapGetters('apiIntegration', {
      hasAPI: 'hasMailAPIService'
    })
  },

  created () {
    this.succesMessage()
    this.customs.fields.forEach(f => f.value = '')
  },

  methods: {
    submit () {

      VEvent.fire('loader', true)

      grecaptcha.ready(() => {
        grecaptcha.execute('6LfRZGMaAAAAADsWlpAN8lZQuVOL1sTNZTyGZZkA', {action: 'submit'}).then((token) => {
          axios.post(this.customs.action === 'ContactForm'
            ? `api/site/${this.project.subdomain}/contact-form`
            : `api/projects/${this.project.id}/auto-responders/${_.kebabCase(this.customs.action)}/send`,
          {
            g_token: token,
            ...this[this.customs.action]()
          })
            .then(() => {
              if (this.customs.successMessageType !== null) {
                if (this.customs.successMessageType === 'link') {
                  window.open(this.customs.successMessageLink)
                } else if (this.customs.successMessageType === 'inner_link') {
                  this.$store.dispatch('router/setPage', this.customs.successMessageInnerLink)
                } else if (this.customs.successMessageType === 'text') {
                  this.messageModal = true
                }
              } else {
                this.$swal({
                  icon: 'success',
                  title: 'Thank you!',
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            })
            .catch(() => {
              this.$swal({
                icon: 'error',
                title: 'Error message',
                showConfirmButton: false,
                timer: 1500
              })
            })
            .finally(() => {
              VEvent.fire('loader', false)
            })
        })
      })
    },
    showForm () {
      return true
    },
    getEmail () {
      const field = this.customs.fields.filter(field => field.name === 'email')

      if (typeof field !== 'undefined') {
        return field[0].value
      }
    },
    succesMessage () {
      if (!this.customs.successMessageType) {
        this.$set(this.customs, 'successMessageType', null)
      }

      if (!this.customs.successMessageLink) {
        this.$set(this.customs, 'successMessageLink', '')
      }

      if (!this.customs.successMessage) {
        this.$set(this.customs, 'successMessage', {
          fontSize: '16px',
          color: '#000',
          fontFamily: 'Global Style',
          align: '',
          text: ''
        })
      }
    },
    cols (lg, sm) {
      return this.resolution === 'lg' ? lg : sm
    }
  }
}
</script>

<style lang="scss">
  .es-form {
    button {
      margin: 0 !important;
    }

    input {
      height: 50px;
    }

    .form-control {
      font-size: 16px;
      border-color: #E7EAF3;

      &::placeholder {
        font-size: 16px;
        color: #8F95A0;
      }
    }
  }
</style>
