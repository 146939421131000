<template>
  <div>
    <b-form v-if="!mailAPIService.status">
      <template v-if="codeStep">
        <b-form-group
          v-if="true"
          label="Code after redirect"
        >
          <b-input
            v-model="service.client_secret"
            type="password"
          />
        </b-form-group>

        <b-btn
          :disabled="!service.client_secret"
          variant="primary"
          @click="integrateMailService"
        >
          Integrate
        </b-btn>
      </template>

      <b-btn
        v-else
        variant="primary"
        @click="authorize"
      >
        Authorize
      </b-btn>
    </b-form>

    <template v-else>
      <div class="mb-[10px] flex items-center gap-x-[10px]">
        <b-btn
          v-b-tooltip
          size="sm"
          variant="white"
          class="p-0 w-[32px] h-[32px] d-flex justify-center items-center"
          title="Remove from actions"
          @click="$emit('removeAction')"
        >
          <i
            class="delete-icon"
          />
        </b-btn>
        <b-btn
          v-b-tooltip
          :title="optionsLoading ? 'Refreshing...' : 'Refresh the list'"
          size="sm"
          variant="white"
          class="p-0 w-[32px] h-[32px] d-flex justify-center items-center"
          @click="prepare"
        >
          <i
            class="fa fa-refresh"
          />
        </b-btn>
      </div>
      <b-form-group label="List">
        <v-select
          v-model="list_id"
          :options="listOptions"
          :reduce="option => option.listkey"
          :loading="optionsLoading"
          searchable
          class="custom-select"
          label="listname"
          placeholder="Select List"
        />
      </b-form-group>

      <template v-if="list_id">
        <hr class="my-4">

        <h6 class="mb-4">
          Field Mapping
        </h6>

        <form-mapping
          :api-fields="APIFieldsByList"
          :autoresponder="service"
          :controls="fieldsForMapping"
        />
      </template>
    </template>
  </div>
</template>

<script>
import AutoResponderIntergationMixin
  from '@builder/components/builder/utils/auto-responders/mixins/AutoResponderIntergationMixin'
import {mapState} from 'vuex'
import Cookie from '@builder/components/mixins/Cookie'
import FormMapping from '../../../editor/components/form/FormMapping'

export default {
  name: 'Zoho',
  components: {FormMapping},
  mixins: [ AutoResponderIntergationMixin, Cookie ],

  data () {
    return {
      codeStep: false
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },

  destroyed () {
    if (this._oauthInterval) {
      window.clearInterval(this._oauthInterval)
    }
  },

  methods: {
    ShowAuthWindow (options) {
      options.windowName = options.windowName || 'ConnectWithOAuth' // should not include space for IE
      options.windowOptions = options.windowOptions || 'location=0,status=0,width=800,height=650'
      options.callback = options.callback || function () {
        window.location.reload()
      }
      this._oauthWindow = window.open(options.path, options.windowName, options.windowOptions)
      this._oauthInterval = window.setInterval(() => {
        if (this._oauthWindow.closed) {
          window.clearInterval(this._oauthInterval)
          options.callback()
        }
      }, 1000)
    },

    async authorize () {
      await axios.get(`api/projects/${this.project.id}/auto-responders/${this.service.id}/auth-url`)
        .then(({data}) => {
          this.set_cookie('mailServiceAPI', this.project.id + ',' + this.service.id)
          this.ShowAuthWindow({
            path: data.data,
            callback: () => {
              VEvent.fire('loader', true)

              this.$store.dispatch('apiIntegration/getlAllMailServices')
                .finally(() => {
                  this.fetchList()
                  VEvent.fire('loader', false)
                })
            }
          })
        })
    },

    async integrateMailService () {
      VEvent.fire('loader', true)

      console.log('integrateMailService', this.service)

      await axios.get(
        `api/projects/${this.project.id}/auto-responders/${this.service.id}/token`,
        { params: { code: this.service.client_secret, location: this.service.location }}
      )
        .then(({data}) => {
          this.service.status = data.status
          this.$emit('integrated')
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    }
  }
}
</script>