<template>
  <div>
    <settings-page-card title="Not Found Page Settings">
      <div class="px-4">
        <b-row class="mb-2">
          <b-col md="5">
            <b-form-group>
              <h6 class="font-weight-sami-bold text-dark">
                Title
              </h6>
              <b-input
                v-model="settings.title"
                placeholder="Page Not Found"
              />
            </b-form-group>

            <b-form-group>
              <h6 class="font-weight-sami-bold text-dark">
                Text
              </h6>
              <b-input
                v-model="settings.text"
                placeholder="The page you are looking for has been removed or is currently unavailable"
              />
            </b-form-group>

            <b-form-group>
              <div class="d-flex align-items-center">
                <h6 class="font-weight-sami-bold text-dark mt-2 mr-3">
                  Image
                </h6>
                <b-checkbox
                  v-model="settings.show_image"
                  switch
                />
              </div>
              <image-upload
                v-if="settings.show_image"
                :image="settings"
                class="w-100"
              />
            </b-form-group>

            <hr class="my-4">

            <b-form-group>
              <h6 class="font-weight-sami-bold text-dark">
                Background Image
              </h6>
              <image-upload
                :image="settings.backgroundImage"
                class="w-100"
              />
            </b-form-group>

            <b-form-group>
              <h6 class="font-weight-sami-bold text-dark">
                Background Color
              </h6>
              <color-picker
                :color="settings.backgroundColor"
                @input="event => settings.backgroundColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
              />
            </b-form-group>

            <hr class="my-4">

            <b-form-group>
              <div class="d-flex align-items-center">
                <h6 class="font-weight-sami-bold text-dark mt-2 mr-3">
                  Redirect Button
                </h6>
                <b-checkbox
                  v-model="settings.redirect_button"
                  switch
                />
              </div>

              <template v-if="settings.redirect_button">
                <b-form-group label="Button text">
                  <b-input
                    v-model="settings.buttonText"
                    placeholder="Go Home"
                  />
                </b-form-group>

                <action-redirect :config="settings" />
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-btn
          class="d-flex align-items-center"
          :disabled="loading"
          variant="primary"
          @click="save"
        >
          Save Changes
          <b-spinner
            v-if="loading"
            class="ml-2"
            small
          />
        </b-btn>
      </div>
    </settings-page-card>
  </div>
</template>

<script>
import SettingsPageCard from '../../layouts/dashboard/SettingsPageCard'
import {mapState} from 'vuex'

export default {
  name: 'NotFoundPage',
  components: {SettingsPageCard},
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    settings () {
      return this.globalStyles.not_found_page
    }
  },
  created () {
    if (!this.globalStyles.hasOwnProperty('not_found_page')) {
      this.$set(this.globalStyles, 'not_found_page', {
        buttonText: null,
        title: null,
        text: null,
        redirect_button: false,
        backgroundImage: {},
        backgroundColor: null,
        show_image: true
      })
    }
  },
  methods: {
    save () {
      this.loading = true
      this.$store.dispatch('projects/save')
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
