import SectionProvider from '@builder/sections/SectionProvider'
import moment from 'moment'

export const name = 'Time Item 4'
export const group = 'time'
export const defaultOptions = {
  content: {
    time: {
      type: 'time-mod',
      display: true,
      time: moment(moment().format('YYYY-MM-DD') + ' ' + '23:59').valueOf(),
      size: 1,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      sizing: {
        width: 6,
        counter: 3,
        padding: 2,
        subtitle: .9
      }
    }
  },
  customize: {
    layouts: null,
    style: {
      background: null,
      dontShow: {
        type: 'checklist-customs',
        label: 'Don\'t Show',
        value: [],
        items: ['Days', 'Hours', 'Minutes', 'Seconds']
      },
      colorSubtitle: {
        color: '#aeb1b4',
        type: 'color-customs',
        label: 'Subtitle color'
      },
      container: {
        type: 'container-customs',
        container: null
      },
      padding: {
        type: 'height-customs',
        sm: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        },
        md: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        },
        lg: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        }
      }
    }
  }
}

export const previews = {
  left: null,
  right: null,
  main: require('@builder/assets/svg/previews/time/main-4.png')
}

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('TimeItem4', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
