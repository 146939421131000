<template>
  <div>
    <b-card
      v-for="(group, index) in elements"
      :key="index"
      class="border-0"
      no-body
    >
      <div class="px-3 py-2 bg-gray-100">
        {{ startCase(index) }}
      </div>

      <draggable
        v-model="elements[index]"
        :clone="cloneElement"
        :group="{ name: 'elements', pull: 'clone', put: false }"
        :scroll="true"
        :force-fallback="true"
        fallback-class="elements-sortable-fallback"
        :force-autoscroll-fallback="true"
        :scroll-sensitivity="60"
        :bubble-scroll="true"
        :fallback-on-body="true"
        handle=".move"
        @end="end"
      >
        <div
          v-for="(element, indexEl) in group"
          v-if="element.dev ? userRole.includes('support') || userRole.includes('superadministrator') : true"
          :key="indexEl"
          :class="isClickEvent ? 'clickable' : 'draggable'"
        >
          <purchase-plan-item
            v-if="element.purchase"
            :element="element"
            @add-element="() => isClickEvent ? onClick(element) : null"
          />
          <pre-release-item
            v-else-if="element.preRelease"
            :element="element"
          />
          <b-card
            v-else
            v-show="!element.private"
            :class="!isClickEvent ? 'move' : null"
            class="border-0"
            no-body
            @click="() => isClickEvent ? onClick(element) : null"
          >
            <section-item-hover class="d-flex align-items-center select-none cursor-move border-0">
              <icon
                v-if="isClickEvent"
                class="move d-block mr-3"
                icon="move.svg"
                style="display: block !important;"
              />
              <div
                class="mr-3"
                v-html="element.icon"
              />
              <div class="pt-1">
                {{ startCase(element.name) }}
              </div>

              <span
                v-if="element.beta"
                class="bg-primary text-center ml-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
              >BETA</span>
            </section-item-hover>
          </b-card>
        </div>
      </draggable>
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'
import elements from '@builder/components/builder/elements'
import TextMixins from '../../mixins/TextMixins'
import Icon from '@builder/components/editor/utils/Icon'
import SectionItemHover from '../utils/SectionItemHover'
import {mapGetters, mapState} from 'vuex'
import {insert} from '../../../sections/utils/helpers'
import shortid from 'shortid'
import PreReleaseItem from '@/components/editor/modals/pre-release/PreReleaseItem.vue'
import PurchasePlanItem from '@/components/editor/modals/purchase-plan/PurchasePlanItem.vue'
export default {
  name: 'ElementsDragItems',
  components: {
    PurchasePlanItem,
    PreReleaseItem,
    SectionItemHover,
    Icon,
    draggable
  },

  mixins: [TextMixins],

  props: {
    editor: {
      type: String,
      default: 'project'
    }
  },

  data () {
    return {
      privateElementsUser: [4, 572, 8481]
    }
  },

  async created () {
    axios.get(`https://api.estage.com/api/estage-modules/check-subscription?project_id=${this.$route.params.id}&user_id=${this.user.id}`)
      .then(({data}) => {
        this.$store.state.editor.chatSubscription = data.data
      })

    axios.get(`https://api.estage.com/api/user/subscriptions-by-project/${this.project.id}?only_business_plus=1`)
      .then(({data}) => {
        this.$store.state.editor.communityPlusSubscription = data.data.business_plus
      })

    if (_.includes(['BUSINESS_PLUS_M', 'BUSINESS_PLUS_Y'], this.user.user_subscription?.plan.code)) {
      this.$store.state.editor.businessPlusSubscription = true
    } else {
      axios.get('https://api.estage.com/api/user/profile')
        .then(({data}) => {
          this.$store.state.editor.businessPlusSubscription = data.data.business_plus
        })
    }
  },

  computed: {
    ...mapGetters('auth', {
      userRole: 'userRole'
    }),
    ...mapState('auth', {
      user: state => state.user
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    isClickEvent () {
      return this.addSubjectEvent === 'click'
    },
    ...mapState('editor', {
      currentSubject: state => state.currentSubject,
      addSubjectEvent: state => state.addSubjectEvent
    }),
    isCommunityPlus () {
      return this.$store.state.editor.communityPlusSubscription
    },
    isBusinessPlus () {
      return this.$store.state.editor.businessPlusSubscription
    },
    isPlus () {
      return this.$store.state.editor.communityPlusSubscription || this.$store.state.editor.businessPlusSubscription
    },
    elements () {
      const allowedGroups = Object.entries(elements)
      const filtered = allowedGroups.filter(key => !key[0].startsWith('Dynamic'))
        .map(group => {
          const elements = group[1].map(el => {
            if (el.elementCode || el.paid) {
              const module = this.user.estage_modules.find(module => module.code === el.elementCode)
              if (el.elementCode === 'CHAT' && this.$store.state.editor.chatSubscription.has_available_subscriptions && !this.isBusinessPlus) {
                return {
                  ...el
                }
              }
              if (el.elementCode === 'LIVE_STREAM' && this.user.id === 4) {
                return {
                  ...el
                }
              }
              if (el.elementCode === 'LIVE_STREAM' && !this.isPlus) {
                return {
                  ...el,
                  purchase: 1
                }
              }
              if (module && this.userRole.includes('support')) {
                return el
              }
              if (module && !this.isBusinessPlus) {
                return {
                  ...el,
                  purchase: 1
                }
              }
            }

            return el
          })

          return [group[0], elements]
        })
      return Object.fromEntries(filtered)
    }
  },

  methods: {
    end () {
      this.$store.commit('editor/SET_SUBJECT_EVENT', null)
    },
    cloneElement (element) {
      return JSON.parse(JSON.stringify({
        ...new element.section().json(),
        editor: this.editor
      }).replaceAll('GROUPED_SID', shortid.generate()))
    },
    onClick (element) {
      if (this.currentSubject.options.originKey === 'custom') {
        return this.currentSubject.subject.options.content.cols[0].elements.push(this.cloneElement(element))
      }

      this.$set(this.currentSubject.subject, this.currentSubject.options.originKey, insert(this.currentSubject.subject.elements, this.currentSubject.options.elIndex + 1, this.cloneElement(element)))
      this.currentSubject.options.elIndex++
    }
  }
}
</script>