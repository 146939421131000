<template>
  <div
    class="position-relative"
    :style="heroStyles"
  >
    <div
      class="overlay-inner"
      :style="{backgroundColor: settings.hero_banner.backgroundColor}"
    >
      <h1 class="d-flex align-items-center w-100 h-100 px-3">
        <component
          :is="settings.hero_banner.styles[resolution].bold ? 'b' : 'div'"
          class="blog-title w-100"
          :style="textStyle"
          v-text="settings.hero_banner.text"
        />
      </h1>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Hero',
  computed: {
    ...mapState('blogSettings', {
      settings: state => state.settings
    }),
    ...mapState('editor', {
      resolution: state => state.resolution
    }),
    heroStyles () {
      return {
        height: '246px',
        width: '100%',
        backgroundImage: `url(${this.settings.hero_banner.image.src || this.settings.hero_banner.image.placeholder})`,
        backgroundSize: this.settings.hero_banner.backgroundSize,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }
    },
    textStyle () {
      return {
        fontSize: this.settings.hero_banner.styles[this.resolution].fontSize,
        color: this.settings.hero_banner.styles[this.resolution].color,
        fontFamily: this.settings.hero_banner.styles[this.resolution].fontFamily,
        textAlign: this.settings.hero_banner.styles[this.resolution].align
      }
    }
  }
}
</script>
