<template>
  <highliter
    v-model="section.name"
    :class="{'element-hidden': !visability('menu')}"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-element"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('menu')"
  >
    <div
      v-show="!section.options.content.menu.visability.includes(resolutioner)"
      :class="`--${section.sid}--icon`"
      class="cursor-pointer"
      @click="() => open = !open"
    >
      <i
        v-show="!open"
        :class="[section.options.content.menu.openIcon, `--${section.sid}--open-icon`]"
      />
      <i
        v-show="open"
        :class="[section.options.content.menu.closeIcon, `--${section.sid}--close-icon`]"
      />
    </div>

    <b-collapse
      v-if="!section.options.content.menu.visability.includes(resolutioner)"
      v-model="open"
    >
      <el-menu-links-v4
        :section="section.options.content.menu.subject"
        :highlither="false"
      />
    </b-collapse>
    <el-menu-links-v4
      v-else
      :section="section.options.content.menu.subject"
      :highlither="false"
    />
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter'
import ElMenuLinksV4 from '@/components/builder/elements/17.menu/v4/ElMenuLinksV4'

export default {
  name: 'ElCollapsableMenuLinks',
  components: {ElMenuLinksV4, Highliter},
  mixins: [SectionMixin],
  data () {
    return {
      open: false
    }
  },
  watch: {
    resolutioner () {
      this.open = false
    }
  }
}
</script>
