<template>
  <div>
    <div
      class="border mt-[-1px] d-flex align-items-center justify-content-between cursor-pinter hover:bg-[#F9F9F9] h-[28px] text-[13px] px-[13px] font-[700] text-[#44474A]"
    >
      <span v-text="day.name" />
      <svg
        :class="{'transform rotate-180': open}"
        fill="none"
        height="7"
        viewBox="0 0 10 7"
        width="10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.719727 1.00006L4.85989 5.10817L9.00005 1.00006"
          stroke="#6E747A"
          stroke-width="1.5"
        />
      </svg>
    </div>

    <div
      v-if="open"
      class="py-[10px]"
    >
      <date-picker
        v-model="fromTime"
        :clearable="false"
        class="w-100"
        :range="true"
        format="HH:mm:ss"
        input-class="form-control"
        type="time"
        value-type="format"
      />

      <span
        class="mt-2 inline-block text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px] mb-2"
        @click="fromTime = null"
      >All Day</span>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'

export default {
  name: 'VisibilityControlsDayOfWeek',
  components: {DatePicker},
  props: {
    day: {
      type: Object,
      required: true
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fromTime: {
      get () {
        return this.day.settings.timeRange
      },
      set (val) {
        this.$set(this.day.settings, 'timeRange', val)
      }
    }
  }
}
</script>