<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('progress')"
  >
    <Template :section="section">
      <b-progress
        :height="`${height}px`"
        :max="section.options.content.progress.max"
        :style="{...shadow}"
      >
        <b-progress-bar
          :value="section.options.content.progress.complite"
          :animated="section.options.content.progress.animated"
          :style="{backgroundColor: section.options.content.progress.color}"
        >
          <div :style="styles">
            {{ section.options.content.progress.text.value }}
          </div>
        </b-progress-bar>
      </b-progress>    
    </Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import Template from './Template'
import _ from 'lodash'
import ResolutionMixin from '../../../mixins/ResolutionMixin'

export default {
  name: 'FeaturedItems1',

  components: {Template, Highliter},

  mixins: [SectionMixin, ResolutionMixin],

  computed: {
    height () {
      return _.get(this.section.options.content.progress, ['height', this.resolutioner], _.get(this.section.options.content.progress, 'height', 38))
    },
    shadow () {
      const shadow = this.section.options.content.progress.shadowOptions
      const color = this.section.options.content.progress.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    styles () {
      return {
        fontSize: this.section.options.content.progress.text.fontSize,
        fontWeight: this.section.options.content.progress.text.fontWeight,
        fontFamily: this.section.options.content.progress.text.fontFamily,
        color: this.section.options.content.progress.text.color
      }
    }
  }
}
</script>
