<template>
  <div>
    <div
      v-if="title"
      class="text-[13px] text-[#333] mb-[14px]"
      v-text="title"
    />

    <div class="flex items-center justify-center gap-[11px]">
      <button
        class="p-0"
        @click="prevPresset"
      >
        <svg
          fill="none"
          height="15"
          viewBox="0 0 15 15"
          width="15"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.18306 7.05806C5.06585 7.17527 5 7.33424 5 7.5C5 7.66576 5.06585 7.82473 5.18306 7.94194L7.68306 10.4419C7.92714 10.686 8.32286 10.686 8.56694 10.4419C8.81102 10.1979 8.81102 9.80214 8.56694 9.55806L6.50888 7.5L8.56694 5.44194C8.81102 5.19786 8.81102 4.80214 8.56694 4.55806C8.32286 4.31398 7.92714 4.31398 7.68306 4.55806L5.18306 7.05806Z"
            fill="#4D4D4D"
          />
          <path
            clip-rule="evenodd"
            d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM13.75 7.5C13.75 10.9518 10.9518 13.75 7.5 13.75C4.04822 13.75 1.25 10.9518 1.25 7.5C1.25 4.04822 4.04822 1.25 7.5 1.25C10.9518 1.25 13.75 4.04822 13.75 7.5Z"
            fill="#4D4D4D"
            fill-rule="evenodd"
          />
        </svg>
      </button>

      <div
        class="flex rounded-[4px] items-center justify-center h-[111px] w-[184px] px-[20px] cursor-pinter shadow-[0_1.7px_5px_rgba(0,0,0,0.1)] !border-[#3D82EA] !border-[1.5px]"
      >
        <slot
          :content="tes"
          name="preview"
        />
      </div>

      <button
        class="p-0"
        @click="nextPresset"
      >
        <svg
          fill="none"
          height="15"
          viewBox="0 0 15 15"
          width="15"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.81694 7.94194C9.93415 7.82473 10 7.66576 10 7.5C10 7.33424 9.93415 7.17527 9.81694 7.05806L7.31694 4.55806C7.07286 4.31398 6.67714 4.31398 6.43306 4.55806C6.18898 4.80214 6.18898 5.19786 6.43306 5.44194L8.49112 7.5L6.43306 9.55806C6.18898 9.80214 6.18898 10.1979 6.43306 10.4419C6.67714 10.686 7.07286 10.686 7.31694 10.4419L9.81694 7.94194Z"
            fill="#4D4D4D"
          />
          <path
            clip-rule="evenodd"
            d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5ZM13.75 7.5C13.75 10.9518 10.9518 13.75 7.5 13.75C4.04822 13.75 1.25 10.9518 1.25 7.5C1.25 4.04822 4.04822 1.25 7.5 1.25C10.9518 1.25 13.75 4.04822 13.75 7.5Z"
            fill="#4D4D4D"
            fill-rule="evenodd"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ElementPresset',
  props: {
    title: {
      type: String
    },
    pressets: {
      type: Array,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      presset: 0,
      sliderValue: 0
    }
  },
  computed: {
    tes: {
      get () {
        const val = this.value > this.pressets.length ? this.pressets.length - 1 : this.value
        return this.pressets.find(item => item.id == val)
      },
      set (val) {
        this.$emit('input', val)
        setTimeout(() => this.tes.onSelect(val))
      }
    },
    selectedPresset () {
      return this.pressets[this.sliderValue]
    }
  },
  created () {
    this.render()
  },
  methods: {
    slectPresset () {
      this.$emit('input', this.sliderValue)
      this.pressets[this.sliderValue].onSelect()
    },
    render () {
      this.sliderValue = _.clone(this.value)
    },
    prevPresset () {
      if (this.tes.id - 1 === -1) {
        return this.tes = this.pressets[this.pressets.length - 1].id
      }

      this.tes = this.tes.id - 1
    },
    nextPresset () {
      if (this.tes.id + 1 > this.pressets.length - 1) {
        return this.tes = 0
      }

      this.tes = this.tes.id + 1
    }
  }
}
</script>