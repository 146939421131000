import { EventEmitter } from 'events'
import { AdapterFactory } from './adapters/AdapterFactory'

export { ListAvailableAdapters } from './adapters/AdapterFactory'

const Message = {
  NO_MEDIA: 'no-media',
  MEDIA_RECOVERED: 'media-recovered',
  PEER_CONNECTION_FAILED: 'peer-connection-failed',
  PEER_CONNECTION_CONNECTED: 'peer-connection-connected',
  INITIAL_CONNECTION_FAILED: 'initial-connection-failed',
  CONNECT_ERROR: 'connect-error'
}

const MediaConstraintsDefaults = {
  audioOnly: false,
  videoOnly: false
}

const RECONNECT_ATTEMPTS = 2

class WebRTCPlayer extends EventEmitter {
  constructor (opts) {
    super()
    this.mediaConstraints = { ...MediaConstraintsDefaults, ...opts.mediaConstraints }
    this.videoElement = opts.video
    this.adapterType = opts.type
    this.adapterFactory = opts.adapterFactory
    this.statsTypeFilter = opts.statsTypeFilter
    this.mediaTimeoutThreshold = opts.timeoutThreshold || 30000
    this.iceServers = opts.iceServers || [{ urls: 'stun:stun.l.google.com:19302' }]
    this.debug = !!opts.debug
    this.reconnectAttemptsLeft = RECONNECT_ATTEMPTS
    this.msStatsInterval = 5000
    this.mediaTimeoutOccured = false
    this.timeoutThresholdCounter = 0
    this.bytesReceived = 0
  }

  async load (channelUrl, authKey = undefined) {
    this.channelUrl = channelUrl
    this.authKey = authKey
    await this.connect()
  }

  log (...args) {
    if (this.debug) {
      console.log('WebRTC-player', ...args)
    }
  }

  error (...args) {
    console.error('WebRTC-player', ...args)
  }

  async onConnectionStateChange () {
    if (this.peer.connectionState === 'failed') {
      this.emit(Message.PEER_CONNECTION_FAILED)
      this.peer.close()

      if (this.reconnectAttemptsLeft <= 0) {
        this.error('Connection failed, reconnecting failed')
        return
      }

      this.log(`Connection failed, recreating peer connection, attempts left ${this.reconnectAttemptsLeft}`)
      await this.connect()
      this.reconnectAttemptsLeft--
    } else if (this.peer.connectionState === 'connected') {
      this.log('Connected')
      this.emit(Message.PEER_CONNECTION_CONNECTED)
      this.reconnectAttemptsLeft = RECONNECT_ATTEMPTS
    }
  }

  onErrorHandler (error) {
    this.log(`onError=${error}`)
    switch (error) {
    case 'reconnectneeded':
      this.peer.close()
      this.videoElement.srcObject = null
      this.setupPeer()
      this.adapter.resetPeer(this.peer)
      this.adapter.connect()
      break
    case 'connectionfailed':
      this.peer.close()
      this.videoElement.srcObject = null
      this.emit(Message.INITIAL_CONNECTION_FAILED)
      break
    case 'connecterror':
      this.peer.close()
      this.adapter.resetPeer(this.peer)
      this.emit(Message.CONNECT_ERROR)
      break
    }
  }

  async onConnectionStats () {
    if (this.peer && this.statsTypeFilter) {
      let bytesReceivedBlock = 0
      const stats = await this.peer.getStats(null)

      stats.forEach((report) => {
        if (report.type.match(this.statsTypeFilter)) {
          this.emit(`stats:${report.type}`, report)
        }

        if (report.type.match('inbound-rtp')) {
          bytesReceivedBlock += report.bytesReceived
        }
      })

      if (bytesReceivedBlock <= this.bytesReceived) {
        this.timeoutThresholdCounter += this.msStatsInterval

        if (!this.mediaTimeoutOccured && this.timeoutThresholdCounter >= this.mediaTimeoutThreshold) {
          this.emit(Message.NO_MEDIA)
          this.mediaTimeoutOccured = true
        }
      } else {
        this.bytesReceived = bytesReceivedBlock
        this.timeoutThresholdCounter = 0

        if (this.mediaTimeoutOccured) {
          this.emit(Message.MEDIA_RECOVERED)
          this.mediaTimeoutOccured = false
        }
      }
    }
  }

  setupPeer () {
    this.peer = new RTCPeerConnection({ iceServers: this.iceServers })
    this.peer.onconnectionstatechange = this.onConnectionStateChange.bind(this)
    this.peer.ontrack = this.onTrack.bind(this)
  }

  onTrack (event) {
    for (const stream of event.streams) {
      if (stream.id === 'feedbackvideomslabel') continue

      console.log(
        'Set video element remote stream to ' + stream.id,
        ' audio ' + stream.getAudioTracks().length + ' video ' + stream.getVideoTracks().length
      )

      if (!this.videoElement.srcObject) {
        this.videoElement.srcObject = new MediaStream()
      }

      for (const track of stream.getTracks()) {
        this.videoElement.srcObject.addTrack(track)
      }
    }
  }

  async connect () {
    this.setupPeer()

    if (this.adapterType !== 'custom') {
      this.adapter = AdapterFactory(
        this.adapterType,
        this.peer,
        this.channelUrl,
        this.onErrorHandler.bind(this),
        this.mediaConstraints,
        this.authKey
      )
    } else if (this.adapterFactory) {
      this.adapter = this.adapterFactory(
        this.peer,
        this.channelUrl,
        this.onErrorHandler.bind(this),
        this.mediaConstraints,
        this.authKey
      )
    }

    if (!this.adapter) {
      throw new Error(`Failed to create adapter (${this.adapterType})`)
    }

    if (this.debug) {
      this.adapter.enableDebug()
    }

    this.statsInterval = setInterval(this.onConnectionStats.bind(this), this.msStatsInterval)

    try {
      await this.adapter.connect()
    } catch (error) {
      console.error(error)
      this.stop()
    }
  }

  mute () {
    this.videoElement.muted = true
  }

  unmute () {
    this.videoElement.muted = false
  }

  async unload () {
    await this.adapter.disconnect()
    this.stop()
  }

  stop () {
    clearInterval(this.statsInterval)
    this.peer.close()
    this.videoElement.srcObject = null
    this.videoElement.load()
  }

  destroy () {
    this.stop()
    this.removeAllListeners()
  }
}

export { WebRTCPlayer, Message }
