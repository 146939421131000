import {mapState} from 'vuex'

export default {
  data () {
    return {
      pages:[],
      pageIsLoading: false,
      searchQueryLoading: false,
      paginationCurrentPage: 1,
      paginationPerPage: 20,
      paginationTotal: 0,
      searchQuery:''
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },
  methods: {
    onSearch () {
      this.paginationCurrentPage = 1
      if(this.$refs.scroll) this.$refs.scroll.scrollTop = 0
      this.getPages()
    },
    scrollLoadingPages (event) {
      if(this.pageIsLoading) return
      const $el = event.target
      const scrollHeight = $el.scrollHeight - $el.clientHeight
      const scrollTop = $el.scrollTop
      const delta = scrollTop > 0 ? 20 : 0
      if (Math.round(scrollTop + delta) >= scrollHeight && (this.paginationCurrentPage || 1) < (this.paginationTotal / this.paginationPerPage)) {
        document.body.style.overflow = 'hidden'
        if (!this.paginationCurrentPage) {
          this.paginationCurrentPage = 2
        } else {
          this.paginationCurrentPage++
        }
        this.getPages()
      }
    },
    async getPages (){
      if(this.searchQuery){
        await this.getFilteredPages(this.paginationCurrentPage)
      }else{
        await this.getUnfilteredPages(this.paginationCurrentPage)
      }
    },
    async getUnfilteredPages (page){
      this.pageIsLoading = true
      try {
        const result = await this.$store.dispatch('pages/loadPagesScrolling', {
          page,
          orderId: this.currentPage.id
        })

        this.paginationPerPage = Number(result.per_page)
        this.paginationTotal = Number(result.total)
        this.pages = page == 1 ? result.data : [...this.pages,...result.data]
      } finally{
        this.pageIsLoading = false
      }
    },
    async getFilteredPages (page){
      this.setRightLoading(page, true)
      try {
        let result = await axios.get(`api/projects/${this.project.id}/pages/with-categories`,{
          params: {
            q:this.searchQuery,
            page
          }
        })
        result = result.data.data
        this.paginationPerPage = Number(result.per_page)
        this.paginationTotal = Number(result.total)
        this.pages = page == 1 ? result.data : [...this.pages,...result.data]
      } finally{
        this.setRightLoading(page, false)
      }
    },
    setRightLoading (page, val){
      if(page > 1){
        this.pageIsLoading = val
      }else{
        this.searchQueryLoading = val
      }
    }
  }
}
