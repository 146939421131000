<template>
  <div>
    <SidbearSettingsGroup
      active
      title="List"
    >
      <template v-if="Array.isArray(config.items)">
        <draggable
          v-model="config.items"
          handle=".move"
        >
          <div
            v-for="(item, index) in config.items"
            :key="index"
            class="!border-[#E2E5EC] group rounded-[2px] h-[38px] flex items-center pl-[13px] hover:bg-[#F1F5FD] cursor-pinter mb-[12px]"
            style="border:1px solid;"
          >
            <div class="mr-[11px] move cursor-move">
              <svg
                fill="none"
                height="11"
                viewBox="0 0 11 11"
                width="11"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.57745 0.894104H0.568359V3.8941H3.57745V0.894104Z"
                  fill="#3D82EA"
                />
                <path
                  d="M10.5984 0.894104H7.58936V3.8941H10.5984V0.894104Z"
                  fill="#3D82EA"
                />
                <path
                  d="M3.57745 7.8941H0.568359V10.8941H3.57745V7.8941Z"
                  fill="#3D82EA"
                />
                <path
                  d="M10.5984 7.8941H7.58936V10.8941H10.5984V7.8941Z"
                  fill="#3D82EA"
                />
              </svg>
            </div>

            <span
              class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]"
            >
              {{ item.label }}
            </span>

            <button
              v-b-tooltip
              class="w-[38px] h-[36px] text-center border-left bg-[#fff] p-0 ml-auto"
              title="Delete column"
              @click.stop="config.items.splice(index, 1)"
            >
              <svg
                fill="none"
                height="10"
                viewBox="0 0 10 10"
                width="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
                  fill="#6E747A"
                />
              </svg>
            </button>
          </div>
        </draggable>

        <button
          class="flex items-center border rounded-[3px] font-[600] text-[#44474A] text-[14px] h-[38px] px-[17px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
          @click="addListItem"
        >
          <span class="text-[#3D82EA] text-[22px] mr-[9px]">+</span>
          Add List Item
        </button>
      </template>

      <template v-else>
        <div
          v-for="n in config.rows"
          :key="n"
          class="mb-4"
        >
          <draggable
            v-model="config.items[n]"
            class="list-group mb-3"
            handle=".move"
          >
            <b-list-group-item
              v-for="(item, index) in config.items[n]"
              :key="item.label"
              class="d-flex align-items-center text-capitalize"
            >
              <icon
                class="move mr-3"
                icon="move.svg"
              />
              <div class="text-truncate">
                {{ item.label }}
              </div>

              <icon
                class="cursor-pinter ml-auto"
                icon="trash.svg"
                @click.native="config.items[n].splice(index, 1)"
              />
            </b-list-group-item>
          </draggable>

          <div
            class="d-flex align-items-center justify-content-end cursor-pinter font-weight-bold text-primary w-100"
            @click="addListItem"
          >
            <icon
              class="mr-2"
              icon="plus.svg"
            />
            Add List Item
          </div>
        </div>
      </template>

      <b-form-group label="Text Color">
        <color-picker
          :color="textColor"
          @input="event => textColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </b-form-group>

      <background-customs :config="section.options.customize.style.background" />

      <b-form-group label="Icon">
        <icon-select v-model="section.options.customize.style.listIcon.icon" />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center gap-[10px]">
          <color-picker
            :color="section.options.customize.style.listIcon.color"
            @input="event => section.options.customize.style.listIcon.color = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />

          <label class="mb-0 pb-0">Color</label>
        </div>
      </b-form-group>

      <b-form-group label="Icon Position">
        <div class="flex items-center">
          <button
            v-b-tooltip
            :class="{'bg-[#F8F8F8]': verticalAlign === 'start'}"
            class="w-[47px] h-[43px] py-0 px-[11px] border rounded-l-[4px] hover:bg-[#F8F8F8]"
            title="Start"
            @click="() => verticalAlign = 'start'"
          >
            <svg
              fill="none"
              height="15"
              viewBox="0 0 24 15"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_128_80)">
                <path
                  :fill="verticalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
                  d="M10.8591 3.03638H4.72412V14.4114H10.8591V3.03638Z"
                />
                <path
                  :fill="verticalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
                  d="M17.9377 3.03638H11.8027V11.0937H17.9377V3.03638Z"
                />
                <path
                  :fill="verticalAlign === 'start' ? '#4F5255' : '#7E7E7E'"
                  d="M23.6006 2.08826H0.948242V0.666377H23.6006V2.08826Z"
                />
              </g>
              <defs>
                <clipPath id="clip0_128_80">
                  <rect
                    fill="white"
                    height="15"
                    width="24"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
          <button
            v-b-tooltip
            :class="{'bg-[#F8F8F8]': verticalAlign === 'center'}"
            class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom border-right hover:bg-[#F8F8F8]"
            title="Center"
            @click="() => verticalAlign = 'center'"
          >
            <svg
              fill="none"
              height="14"
              viewBox="0 0 23 14"
              width="23"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                :fill="verticalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
                height="12.5494"
                width="6.01636"
                x="4.9118"
                y="0.666626"
              />
              <rect
                :fill="verticalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
                height="10.2254"
                width="6.01636"
                x="11.8538"
                y="1.59619"
              />
              <rect
                :fill="verticalAlign === 'center' ? '#4F5255' : '#7E7E7E'"
                height="1.39437"
                transform="matrix(1 0 0 -1 0.746765 7.63843)"
                width="22.2142"
              />
            </svg>
          </button>
          <button
            v-b-tooltip
            :class="{'bg-[#F8F8F8]': verticalAlign === 'end'}"
            class="w-[47px] h-[43px] py-0 px-[11px] border-top border-bottom border-right hover:bg-[#F8F8F8]"
            title="End"
            @click="() => verticalAlign = 'end'"
          >
            <svg
              fill="none"
              height="15"
              viewBox="0 0 23 15"
              width="23"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                :fill="verticalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
                height="11.14"
                transform="matrix(1 -8.77996e-08 -8.70476e-08 -1 4.70483 11.8066)"
                width="6.00827"
              />
              <rect
                :fill="verticalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
                height="7.89083"
                transform="matrix(1 -8.77996e-08 -8.70476e-08 -1 11.637 11.8066)"
                width="6.00827"
              />
              <rect
                :fill="verticalAlign === 'end' ? '#4F5255' : '#7E7E7E'"
                height="1.3925"
                transform="matrix(1 0 0 -1 0.545044 14.1278)"
                width="22.1844"
              />
            </svg>
          </button>
        </div>
      </b-form-group>

      <b-form-group
        label="Icon Size"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="iconSize"
              :interval="1"
              :max="40"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col>
            <b-input
              v-model="iconSize"
              :max="40"
              :min="0"
              :step="1"
              size="sm"
              type="number"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        label="Vertical Gap"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="space"
              :interval="1"
              :max="100"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col>
            <b-input
              v-model="space"
              :max="100"
              :min="0"
              :step="1"
              size="sm"
              type="number"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        label="Horizontal Gap"
        label-class="!pb-[3px]"
      >
        <b-row class="align-items-center pl-2">
          <b-col
            cols="8"
          >
            <vue-slider
              v-model="horizontalGap"
              :interval="1"
              :max="100"
              :min="0"
              class="editor-slider-1"
              tooltip="none"
            />
          </b-col>
          <b-col>
            <b-input
              v-model="horizontalGap"
              :max="100"
              :min="0"
              :step="1"
              size="sm"
              type="number"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        label="Alignment"
        class="mb-0"
      >
        <b-btn-group class="pt-[2px]">
          <b-btn
            variant="white"
            @click="align = 'start'"
          >
            <i
              :class="{'text-primary': align === 'start'}"
              class="fa fa-outdent"
            />
          </b-btn>
          <b-btn
            variant="white"
            @click="align = 'center'"
          >
            <i
              :class="{'text-primary': align === 'center'}"
              class="fa fa-align-justify"
            />
          </b-btn>
          <b-btn
            variant="white"
            @click="align = 'end'"
          >
            <i
              :class="{'text-primary': align === 'end'}"
              class="fa fa-indent"
            />
          </b-btn>
        </b-btn-group>
      </b-form-group>
    </SidbearSettingsGroup>
    <div
      v-if="config.hasOwnProperty('rows')"
      class="menubar mb-4"
    >
      <b-btn-group class="d-block">
        <b-btn
          variant="menubar-button"
          @click="config.rows = 1"
        >
          <icon
            v-if="config.rows === 1"
            icon="active-list-1.svg"
          />
          <icon
            v-else
            icon="list-1.svg"
          />
        </b-btn>
        <b-btn
          variant="menubar-button"
          @click="config.rows = 2"
        >
          <icon
            v-if="config.rows === 2"
            icon="active-list-2.svg"
          />
          <icon
            v-else
            icon="list-2.svg"
          />
        </b-btn>
        <b-btn
          variant="menubar-button"
          @click="config.rows = 3"
        >
          <icon
            v-if="config.rows === 3"
            icon="active-list-3.svg"
          />
          <icon
            v-else
            icon="list-3.svg"
          />
        </b-btn>
      </b-btn-group>
    </div>

    <tiptap-mod
      v-if="selectedSubject"
      :config="selectedSubject.options.content.description"
      :local-section="selectedSubject"
      :hide="['animations', 'align']"
      group-prefix="Text"
      component-name="Text"
    />

    <SidbearSettingsGroup
      title="Border & Radius"
    >
      <border-customs
        :config="config"
        :default-border="[0,0,0,0]"
        :default-radius="[0,0,0,0]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <shadow-customs :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="section.options.customize.style.padding" />
      <margin-full-customs :config="margin" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import draggable from 'vuedraggable'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import IconSelect from '@/components/editor/components/IconSelect'
import ElParagraph from '@/components/builder/elements/3.paragraph/ElParagraph'
import _ from 'lodash'

export default {
  name: 'ListMod',
  components: {IconSelect, SidbearSettingsGroup, draggable},
  mixins: [ModMixin],
  data () {
    return {
      newListItemModal: false,
      item: '',
      label: '',
      listIndex: null
    }
  },
  computed: {
    margin () {
      if (!this.section.options.customize.style.margin) {
        this.$set(this.section.options.customize.style, 'margin', {})
      }

      return this.section.options.customize.style.margin
    },
    selectedSubject () {
      let subject = this.config.items[this.section.selectedSubjectIndex]

      if (subject) {
        return subject.subject
      }

      return false
    },
    space: {
      get () {
        return this.config.space || 0
      },
      set (val) {
        this.$set(this.config, 'space', val)
      }
    },
    horizontalGap: {
      get () {
        return _.get(this.config, ['horizontalGap', this.resolutioner], _.get(this.config, ['horizontalGap', 'lg'], 0))
      },
      set (val) {
        this.$set(this.config, 'horizontalGap', {..._.set(this.config['horizontalGap'] || {}, [this.resolutioner], val)})
      }
    },
    verticalAlign: {
      get () {
        return _.get(this.config, ['verticalAlign', this.resolutioner], _.get(this.config, ['verticalAlign', 'lg'], 'center'))
      },
      set (val) {
        this.$set(this.config, 'verticalAlign', {..._.set(this.config['verticalAlign'] || {}, [this.resolutioner], val)})
      }
    },
    textColor: {
      get () {
        return _.get(this.config, ['color', this.resolutioner], _.get(this.config, ['color', 'lg'], '#fff'))
      },
      set (val) {
        this.$set(this.config, 'color', {..._.set(this.config['color'] || {}, [this.resolutioner], val)})
      }
    },
    iconSize: {
      get () {
        return parseInt(_.get(this.config, ['fontSize', this.resolutioner], _.get(this.config, 'fontSize', 16)))
      },
      set (val) {
        this.$set(this.config, 'fontSize', {..._.set(this.config.fontSize || {}, [this.resolutioner], val)})
      }
    },
    align: {
      get () {
        return _.get(this.config, ['align', this.resolutioner], _.get(this.config, ['align', 'lg'], 'start'))
      },
      set (val) {
        this.$set(this.config, 'align', {..._.set(this.config['align'] || {}, [this.resolutioner], val)})
      }
    }
  },
  methods: {
    addListItem () {
      const subject = new ElParagraph({
        'customize': null,
        'content': {
          'description': {
            'type': 'tiptap-mod',
            'display': true,
            padding: {
              lg: {
                top: 0,
                bottom: 0,
                left: 10,
                right: 0
              },
              md: {
                top: 0,
                bottom: 0,
                left: 10,
                right: 0
              },
              sm: {
                top: 0,
                bottom: 0,
                left: 10,
                right: 0
              }
            },
            'resolutionStyle': {
              'sm': {
                'fontSize': null,
                'fontFamily': null,
                'color': null,
                'align': null,
                'padding': {
                  'top': 0,
                  'bottom': 0,
                  'x': 10
                }
              },
              'md': {
                'fontSize': null,
                'fontFamily': null,
                'color': null,
                'align': null,
                'padding': {
                  'top': 0,
                  'bottom': 0,
                  'x': 10
                }
              },
              'lg': {
                'fontSize': null,
                'fontFamily': null,
                'color': null,
                'align': 'left',
                'padding': {
                  'top': 0,
                  'bottom': 0,
                  'x': 10
                }
              }
            },
            'text': '<p>Cras faucibus purus tincidunt tincidunt mollis. </p>',
            'animations': {
              'animation': null,
              'once': true,
              'offset': 200,
              'delay': 50
            },
            'letterSpacing': 0
          }
        }
      }).json()

      if (this.listIndex) {
        this.config.items[this.listIndex].push({
          label: `Item ${this.config.items.length + 1}`,
          subject
        })
      } else {
        this.config.items.push({
          label: `Item ${this.config.items.length + 1}`,
          subject
        })
      }

      this.label = ''
      this.item = ''
      this.newListItemModal = false
    }
  }
}
</script>
