<template>
  <div>
    <draggable
      v-model="elements"
      :clone="cloneElement"
      :group="{ name: 'elements', pull: 'clone', put: false }"
      :scroll="true"
      :force-fallback="true"
      fallback-class="elements-sortable-fallback"
      :force-autoscroll-fallback="true"
      :scroll-sensitivity="60"
      :bubble-scroll="true"
      :fallback-on-body="true"
      handle=".move"
      @end="end"
    >
      <div
        v-for="(element, indexEl) in elements"
        :key="indexEl"
        :class="isClickEvent ? 'clickable' : 'draggable'"
        @click="() => isClickEvent ? onClick(element) : null"
      >
        <b-card
          :class="!isClickEvent ? 'move' : null"
          class="border-0"
          no-body
        >
          <section-item-hover class="d-flex align-items-center cursor-move border-0">
            <icon
              v-if="isClickEvent"
              class="move d-block mr-3"
              icon="move.svg"
              style="display: block !important;"
            />
            <div
              class="mr-3"
              v-html="element.icon"
            />
            <div class="pt-1">
              {{ startCase(element.name) }}
            </div>

            <span
              v-if="element.beta"
              class="bg-primary text-center ml-2 text-white h-[17px] w-[50px] rounded-pill text-[12px] font-normal"
            >BETA</span>
          </section-item-hover>
        </b-card>
      </div>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import elements from '@builder/components/builder/elements'
import TextMixins from '../../mixins/TextMixins'
import Icon from '@builder/components/editor/utils/Icon'
import SectionItemHover from '../utils/SectionItemHover'
import {mapState} from 'vuex'
import {insert} from '../../../sections/utils/helpers'
import shortid from 'shortid'

export default {
  name: 'DynamicElementsDragItems',
  components: {SectionItemHover, Icon, draggable},

  mixins: [TextMixins],

  props: {
    editor: {
      type: String,
      default: 'project'
    },
    group: {
      type: Array,
      required: true
    }
  },

  computed: {
    isClickEvent () {
      return this.addSubjectEvent === 'click'
    },
    ...mapState('editor', {
      currentSubject: state => state.currentSubject,
      addSubjectEvent: state => state.addSubjectEvent
    }),
    elements () {
      let _elements = []

      this.group.forEach(group => {
        _elements = [..._elements, ...elements[group]]
      })

      return _elements
    }
  },

  methods: {
    end () {
      this.$store.commit('editor/SET_SUBJECT_EVENT', null)
    },
    cloneElement (element) {
      return JSON.parse(JSON.stringify({
        ...new element.section().json(),
        editor: this.editor
      }).replaceAll('GROUPED_SID', shortid.generate()))
    },
    onClick (element) {
      if (this.currentSubject.options.originKey === 'custom') {
        return this.currentSubject.subject.options.content.cols[0].elements.push(this.cloneElement(element))
      }

      this.$set(this.currentSubject.subject, this.currentSubject.options.originKey, insert(this.currentSubject.subject.elements, this.currentSubject.options.elIndex + 1, this.cloneElement(element)))
      this.currentSubject.options.elIndex++
    }
  }
}
</script>
