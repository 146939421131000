<template>
  <div>
    <b-form-group label="Icon">
      <div class="flex gap-[10px]">
        <icon-select
          v-model="config.icon"
          class="max-w-[88px]"
        />
        <color-picker
          :color="iconColor"
          class="mt-[-1px]"
          @input="event => iconColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
        />
      </div>
    </b-form-group>

    <b-form-group label="Icon Position">
      <v-select
        v-model="iconPosition"
        :options="iconPositionOptions"
        :reduce="option => option.key"
        :searchable="false"
        class="custom-select"
      />
    </b-form-group>
  </div>
</template>

<script>
import ModControlMixin from '@/components/mixins/ModControlMixin'
import IconSelect from '@/components/editor/components/IconSelect'

export default {
  name: 'ButtonIcon',
  components: {IconSelect},
  mixins: [ModControlMixin],
  data () {
    return {
      iconPositionOptions: [
        {key: 'prepend', label: 'Before'},
        {key: 'append', label: 'After'},
        {key: 'left-edge', label: 'Left Edge'},
        {key: 'right-edge', label: 'Right Edge'}
      ]
    }
  },
  computed: {
    iconColor: {
      get () {
        return this.config.iconColor
      },
      set (val) {
        this.$set(this.config, 'iconColor', val)
      }
    },
    iconPosition: {
      get () {
        return this.config.iconPosition ? this.config.iconPosition : 'append'
      },
      set (val) {
        this.$set(this.config, 'iconPosition', val)
      }
    }
  }
}
</script>