<template>
  <b-form-group>
    <template
      v-if="customs.label"
      slot="label"
    >
      <div class="custom-input-label">
        {{ customs.label }} <span
          v-if="$v.field.value.$params.hasOwnProperty('required')"
          class="text-danger"
        >*</span>
      </div>
    </template>

    <b-textarea
      v-model="$v.field.value.$model"
      :placeholder="customs.placeholder"
      :size="customs.size[resolution]"
      :rows="customs.rows"
      :state="$v.field.value.$error ? false : null"
    />


    <div
      v-if="$v.field.value.$error"
      class="mt-2"
    >
      <div
        v-for="(validator, index) in customs.validators"
        v-if="!$v.field.value[index]"
        :key="index"
        class="text-danger"
      >
        {{ index }}
      </div>
    </div>
  </b-form-group>
</template>

<script>
import ComponentProvider from '../../../mixins/ComponentProvider'

export default {
  name: 'EsInput',
  mixins: [ComponentProvider],
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    $v: {
      type: Object
    }
  }
}
</script>
