<template>
  <div>
    <b-row>
      <b-col md="3">
        <h6 class="font-weight-bold mb-3">
          Blog Home Page
        </h6>
        <ul class="list-unstyled">
          <li class="d-flex align-items-center justify-content-between mb-3">
            <div class="mt-1">
              Display Popular Posts
            </div>
            <b-checkbox
              v-model="settings.widget_sidebar.display_popular_posts"
              size="lg"
              switch
              class="ml-3"
            />
          </li>
          <li class="d-flex align-items-center justify-content-between mb-3">
            <div class="mt-1">
              Display Search
            </div>
            <b-checkbox
              v-model="settings.widget_sidebar.display_search"
              size="lg"
              switch
              class="ml-3"
            />
          </li>
          <li class="d-flex align-items-center justify-content-between mb-3">
            <div class="mt-1">
              Display Categories
            </div>
            <b-checkbox
              v-model="settings.widget_sidebar.display_categories"
              size="lg"
              switch
              class="ml-3"
            />
          </li>
          <li class="d-flex align-items-center justify-content-between mb-3">
            <div class="mt-1">
              Display Latest Posts
            </div>
            <b-checkbox
              v-model="settings.widget_sidebar.display_latest_posts"
              size="lg"
              switch
              class="ml-3"
            />
          </li>
        </ul>
      </b-col>
      <b-col
        offset="1"
        md="3"
      >
        <h6 class="font-weight-bold mb-3">
          Blog Post Page
        </h6>
        <ul class="list-unstyled">
          <li class="d-flex align-items-center justify-content-between mb-3">
            <div class="mt-1">
              Comments Moderation
            </div>
            <b-checkbox
              v-model="settings.comments_moderation"
              :value="typeof settings.comments_moderation === 'boolean' ? true : 1"
              size="lg"
              switch
              class="ml-3"
            />
          </li>
          <li class="d-flex align-items-center justify-content-between mb-3">
            <div class="mt-1">
              Allow Comments
            </div>
            <b-checkbox
              v-model="settings.allow_comments"
              :value="typeof settings.allow_comments === 'boolean' ? true : 1"
              size="lg"
              switch
              class="ml-3"
            />
          </li>
          <li class="d-flex align-items-center justify-content-between mb-3">
            <div class="mt-1">
              Display Comments
            </div>
            <b-checkbox
              v-model="settings.display_comments"
              :value="typeof settings.display_comments === 'boolean' ? true : 1"
              size="lg"
              switch
              class="ml-3"
            />
          </li>
          <li class="d-flex align-items-center justify-content-between mb-3">
            <div class="mt-1">
              Display Publication Date
            </div>
            <b-checkbox
              v-model="settings.layout.show_date"
              :value="typeof settings.layout.show_date === 'boolean' ? true : 1"
              size="lg"
              switch
              class="ml-3"
            />
          </li>
          <li class="d-flex align-items-center justify-content-between mb-3">
            <div class="mt-1">
              Display Social Media Links
            </div>
            <b-checkbox
              v-model="settings.display_social_share"
              :value="typeof settings.display_social_share === 'boolean' ? true : 1"
              size="lg"
              switch
              class="ml-3"
            />
          </li>
          <li class="d-flex align-items-center justify-content-between mb-3">
            <div class="mt-1">
              Display Like Button
            </div>
            <b-checkbox
              v-model="settings.display_like_button"
              :value="typeof settings.display_like_button === 'boolean' ? true : 1"
              size="lg"
              switch
              class="ml-3"
            />
          </li>
        </ul>
      </b-col>
      <b-col
        offset="1"
        md="4"
      >
        <h6 class="font-weight-bold mb-3">
          Social Accounts
        </h6>
        <ul class="list-unstyled">
          <li
            v-for="(account, index) in settings.social_accounts"
            :key="index"
            class="d-flex align-items-center group mb-3"
          >
            <div
              class="social-icon mr-3"
              :style="{backgroundColor: account.background}"
            >
              <i
                :class="account.icon"
                :style="{color: account.color}"
              />
            </div>

            <b-input
              v-model="account.link"
              type="url"
            />

            <i
              class="fa fa-trash-o px-3 -mr-10 opacity-0 transition group-hover:opacity-100 hover:text-red-500 cursor-pinter"
              @click="settings.social_accounts.splice(index, 1)"
            />
          </li>
          <li>
            <b-btn
              v-b-modal.add-social-account
              block
              variant="white"
            >
              Add Another Social Account
            </b-btn>
          </li>
        </ul>
      </b-col>
    </b-row>

    <!-- Modal -->
    <social-links-modal @add-item="item => settings.social_accounts.push(item)" />
  <!-- End Modal -->
  </div>
</template>

<script>
import SocialLinksModal from './modals/SocialLinksModal'
export default {
  name: 'BlogSocialSettings',
  components: {SocialLinksModal},
  props: {
    settings: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.social-icon {
  width: 35.44px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}
</style>
