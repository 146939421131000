<template>
  <div class="d-flex position-relative">
    <treeselect
      v-model="folder"
      :disable-branch-nodes="true"
      :load-options="loadOptions"
      :options="folders"
      :searchable="false"
      :show-count="true"
      no-options-text="No templates"
      readonly
      value-format="object"
    >
      <label
        slot="value-label"
        slot-scope="{ node}"
      >
        {{ node.raw.name }}
      </label>

      <label
        slot="option-label"
        slot-scope="{ node, shouldShowCount, count, labelClassName }"
        :class="labelClassName"
      >
        {{ node.raw.name }}
      </label>
    </treeselect>
  </div>
</template>

<script>
import Treeselect, {LOAD_CHILDREN_OPTIONS} from '@riophae/vue-treeselect'

export default {
  name: 'ProjectsTreeSelect',
  components: {Treeselect},
  props: {
    value: {
      required: true
    },
    ignoreId: {
      type: Number,
      default: 0
    },
    showChevronIcon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      folders: []
    }
  },
  computed: {
    folder: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  created () {
    this.folders.unshift({
      id: 'my-templates',
      name: 'My Templates',
      children: null
    })
    // axios.get('api/templates/categories')
    //   .then(({data}) => {
    //     this.folders = data.data.map((folder) => {
    //       return {
    //         ...folder,
    //         children: null
    //       }
    //     })
    //
    //     this.folders.unshift({
    //       id: 'my-templates',
    //       name: 'My Templates',
    //       children: null
    //     })
    //   })
  },
  methods: {
    loadOptions ({action, parentNode, callback}) {
      if (action === LOAD_CHILDREN_OPTIONS) {
        if (parentNode.id === 'my-templates') {
          return axios.get('api/templates/my-templates').then(({data}) => {
            parentNode.children = data.data.map(temp => {
              return {
                ...temp,
                name: temp.title
              }
            })

            callback()
          })
        }

        axios.get(`api/templates?category_id=${parentNode.id}`).then(({data}) => {
          parentNode.children = data.data.data

          callback()
        })
      }
    }
  }
}
</script>