<template>
  <div
    :class="`--${section.sid}--wrapper`"
    :data-uid="section.uid"
  >
    <highliter
      v-model="section.name"
      section-type="element"
      class="highlight-element"
      :dublicate-deep="parentUID"
      :dublicate="section"
      :data-sid="section.sid"
      @add-subject="$emit('add-subject')"
      @favorite="$emit('favorite', $el)"
      @flow="handleFlow"
      @move-to="to => $emit('move-to', to)"
      @dublicate="$emit('dublicate')"
      @delete="$emit('delete')"
      @click.stop.native="sectionSettings('video-animation-mod')"
    >
      <div
        class="flex w-full"
        :data-sid="section.sid"
        :class="[`--${section.sid}--container`]"
      >
        <video
          v-if="showVideo"
          ref="videoAnimation"
          :src="section.options.content.video.src"
          :class="`--${section.sid}--video`"
          loop
          autoplay
          muted
          playsinline
        />
        <div
          v-else
          class="w-full h-full h5 bg-gray-300 mb-0 p-4 text-center rounded"
        >
          Video Animations
        </div>
      </div>
    </highliter>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'
import CustomStylesMixin from '@/components/mixins/CustomStylesMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElVideoAnimation',
  components: {Highliter},
  mixins: [
    SectionMixin,
    BorderMixin,
    CustomStylesMixin
  ],
  data () {
    return {
      showVideo: false
    }
  },
  computed: {
    videoSource () {
      return this.section.options.content.video.src
    }
  },
  mounted () {
    if (this.videoSource) this.showVideo = true
  },
  watch: {
    videoSource (val) {
      if (val) this.showVideo = true
    }
  }
}
</script>