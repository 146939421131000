export default {
  methods: {
    scrollToElement (link) {
      const $el = document.querySelector(`[data-id='${link.href}']`)
      if ($el) {
        return $el.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        })
      }
    }
  }
}
