<template>
  <div>
    <SidbearSettingsGroup
      active
      title="Icon Box"
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <b-form-group>
        <v-select
          v-model="section.options.content.iconBox.customIcon"
          :reduce="val => val.value"
          :searchable="false"
          :options="[{label: 'Icon', value: 'icon'}, {label: 'Lottie Icon', value: 'lottie'}, {label: 'Image', value: 'image'}]"
        />
      </b-form-group>

      <template v-if="section.options.content.iconBox.customIcon === 'icon'">
        <b-form-group>
          <icon-select
            v-model="config.icon"
            class="mb-2"
          />

          <div class="flex items-center justify-end">
            <sid-color
              :sid="`.--${section.sid}-- .icon`"
              rule-name="color"
            />
          </div>
        </b-form-group>

        <sid-range-slider
          label="Icon Size"
          :sid="`.--${section.sid}-- .icon`"
          rule-name="font-size"
        />
      </template>

      <template v-else-if="section.options.content.iconBox.customIcon === 'lottie'">
        <sid-lottie-icon
          :hide-checkbox="true"
          :config="config"
          :sid="section.sid"
        />
      </template>

      <template v-else-if="section.options.content.iconBox.customIcon === 'image'">
        <sid-upload-image
          :sid="`.--${section.sid}-- .icon-image`"
          rule-name="content"
          title="Icon Image"
        />

        <sid-range-slider
          rule-name="width"
          :sid="`.--${section.sid}-- .icon-image`"
          default-dimension="px"
          :dimensions="[]"
          label="Image Size"
          :max="500"
          var="--width"
        />
      </template>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Icon Position</label>

          <sid-buttons-group
            class="mb-0"
            label=""
            :options="[
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.54688\' y=\'1.59863\' width=\'4.1387\' height=\'7.67361\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.32251\' y=\'1.59863\' width=\'4.1387\' height=\'5.43547\' fill=\'%237E7E7E\'/%3E%3Crect width=\'15.2814\' height=\'0.959201\' transform=\'matrix(1 0 0 -1 0 0.958984)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'start',
                tooltip: 'Start'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'9\' viewBox=\'0 0 16 9\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'2.87305\' y=\'0.349243\' width=\'4.05866\' height=\'8.46585\' fill=\'%237E7E7E\'/%3E%3Crect x=\'7.55664\' y=\'0.976318\' width=\'4.05866\' height=\'6.8981\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9858\' height=\'0.94065\' transform=\'matrix(1 0 0 -1 0.0634766 5.05249)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'center',
                tooltip: 'Center'
              },
              {
                svg: 'data:image/svg+xml,%3Csvg width=\'16\' height=\'10\' viewBox=\'0 0 16 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.0532\' height=\'7.51508\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.43945 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.0532\' height=\'5.32318\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 8.11523 7.86438)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.9657\' height=\'0.939385\' transform=\'matrix(1 0 0 -1 0.632812 9.43018)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                value: 'end',
                tooltip: 'End'
              }
            ]"
            :sid="`.--${section.sid}-- .icon-box-wrapper`"
            rule-name="align-items"
          />
        </div>
      </b-form-group>

      <sid-flex-direction
        :sid="`.--${section.sid}-- .icon-box-wrapper`"
        rule-name="flex-direction"
      />

      <sid-range-slider
        label="Gap"
        :sid="`.--${section.sid}-- .icon-box-container .icon-box-wrapper`"
        rule-name="gap"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Layout & Style">
      <sid-align
        :sid="`.--${section.sid}-- .icon-box-container`"
        rule-name="align-items"
        label="Content Alignment"
      />

      <sid-background :sid="`.--${section.sid}--`" />
    </SidbearSettingsGroup>

    <tiptap-mod-v2
      :config="section.options.content.iconBox.subject.options.content.description"
      :local-section="section.options.content.iconBox.subject"
      :hide="['animations', 'align']"
      group-prefix="Text"
      :show-active-group="false"
      component-name="Text"
    />

    <SidbearSettingsGroup title="Click Action">
      <ButtonAction
        :config="config"
        class="mb-0"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid}--`"
        rule-name="border"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Padding & Margins">
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import IconSelect from '@/components/editor/components/IconSelect'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidLottieIcon from '@/components/editor/components/sid-controls/sid-control-pressets/SidLottieIcon'
import SidStyles from '@/components/mixins/SidStyles'
import ButtonAction from '@/components/editor/controls/button-mod/ButtonAction.vue'
import SidUploadImage
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-image/SidUploadImage.vue'
import SidFlexDirection
  from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection.vue'

export default {
  name: 'IconBoxMod',
  components: {
    SidFlexDirection,
    SidUploadImage,
    ButtonAction,
    SidLottieIcon,
    SidSelect,
    SidLinkedGroup,
    SidAlign,
    SidColor, SidBackground, SidButtonsGroup, SidRangeSlider, IconSelect, SidbearSettingsGroup},
  mixins: [ModMixin, SidStyles],
  data () {
    return {
      newListItemModal: false,
      item: '',
      label: '',
      listIndex: null
    }
  }
}
</script>
