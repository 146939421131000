<template>
  <v-select
    v-model="shape"
    left
    :reduce="option => option.key"
    :options="options"
    :searchable="false"
  />
</template>

<script>
export default {
  name: 'Shape',
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      options: [
        {
          key: null,
          label: 'Global Style'
        },
        {
          key: 'rounded',
          label: 'Rounded'
        },
        {
          key: 'squared',
          label: 'Squared'
        },
        {
          key: 'pill',
          label: 'Pilled'
        }
      ]
    }
  },
  computed: {
    shape: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>