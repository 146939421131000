<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="el-dynamic"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('data')"
  >
    <div :data-sid="section.sid">
      <div
        class="post-featured-info flex align-items-center w-100 flex-wrap"
        :class="className()"
      >
        <div class="d-flex align-items-center flex-wrap">
          <div class="post-author flex align-items-center">
            <b-img
              v-if="l.get(customPostData,'author.avatar')"
              height="33"
              width="33"
              rounded="circle"
              class="avatar post-author-img mr-2"
              :src="l.get(customPostData,'author.avatar')"
              alt="Author Avatar"
            />
            <div
              v-else
              class="rounded-circle post-author-img mr-2"
            />
            <div class="post-author-name">
              {{ l.get(customPostData,'author.name','John Smith') }}
            </div>
          </div>

          <div class="post-date">
            March 28, 2023
          </div>
        </div>

        <div class="post-category">
          Social Media
        </div>
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
import _ from 'lodash'

export default {
  name: 'ElBlogCategories',
  components: {Highliter},
  mixins: [SectionMixin],
  inject: {
    getCustomPostEditorData: {
      default:() => () => null
    }
  },
  data () {
    return {
      l: _
    }
  },
  computed: {
    customPostData () {
      return this.getCustomPostEditorData() 
    }
  },
  methods: {
    className (name = '') {
      return `--post-featured-info-${this.section.sid}${name}`
    }
  }
}
</script>

<style lang="scss">
.post-featured-info {
  justify-content: space-between;

  & > div {
    gap: 10px;
  }

  .post-author-name {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    color: #3D82EA;
  }

  .post-date {
    color: #667085;
    font-weight: 600;
    font-size: 13px;
  }

  .post-category {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    color: #3D82EA;
  }

  .post-author-img {
    width: 33px;
    height: 33px;
    background: #dddddd;
  }
}
</style>