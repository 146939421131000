<template>
  <highliter
    v-model="section.name"
    class="highlight-section"
    hide-copy
    hide-move
    :class="['navbar-' + resolution, {'position-absolute w-100': bringToFront}]"
    label="Navigation"
    uid="#navigation"
    :dublicate="{group: 'navbar'}"
    @delete="$emit('delete')"
    @flow="handleFlow"
    @click.stop.native="sectionSettings('menuItemsV2Mod', section)"
  >
    <div :style="{...backgroundColorSubject(section.options.content.subject.specialCols.navigation, 'navbarBackground')}">
      <b-collapse
        v-if="isCollapsed && section.options.content.subject.specialCols.navigation.placement === 'top'"
        id="header-collapse"
        v-model="collapse"
      >
        <flex-wrapper-col
          :col="section.options.content.subject.specialCols.navigation"
          :index="0"
          :section="section.options.content.subject"
          :custom-back-options="{openedItem: 'menuItemsV2Mod', section}"
          mod="navigation-flex-col-mod"
          @flow="handleFlow"
        />
      </b-collapse>

      <div class="flex items-start">
        <div class="w-100">
          <el-flex-wrapper-box
            :section="section.options.content.subject"
            :custom-back-options="{openedItem: 'menuItemsV2Mod', section}"
            :highliter-options="{hideAdd: true, hideMove: true, hideRight: true}"
            @flow="handleFlow"
          >
            <b-link
              v-if="collasedBreackpoint"
              class="absolute top-1/2 -translate-y-1/2 right-[10px] z-[1000000000]"
              :style="{color: hamburgerIconColor, ...hamburgerIconSize}"
              @click.stop.native="collapse = !collapse"
            >
              <i :class="hamburgerIcon" />
            </b-link>

            <template v-slot:navigation="{index, column}">
              <flex-wrapper-col
                v-if="!isCollapsed"
                :col="column"
                :index="index"
                :section="section.options.content.subject"
                :custom-back-options="{openedItem: 'menuItemsV2Mod', section}"
                mod="navigation-flex-col-mod"
                @flow="handleFlow"
              />
            </template>
          </el-flex-wrapper-box>
        </div>
      </div>

      <b-collapse
        v-if="isCollapsed && section.options.content.subject.specialCols.navigation.placement === 'bottom'"
        id="header-collapse"
        v-model="collapse"
      >
        <flex-wrapper-col
          :col="section.options.content.subject.specialCols.navigation"
          :index="0"
          :section="section.options.content.subject"
          :custom-back-options="{openedItem: 'menuItemsV2Mod', section}"
          mod="navigation-flex-col-mod"
          @flow="handleFlow"
        />
      </b-collapse>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import ElFlexWrapperBox from '@/components/builder/sections/utils/el-inline/ElFlexWrapperBox'
import _ from 'lodash'
import BackgroundMixin from '@/components/mixins/BackgroundMixin'

export default {
  name: 'Navbar1',
  components: {ElFlexWrapperBox},
  mixins: [SectionMixin, BackgroundMixin],
  data () {
    return {
      collapse: false,
      collapsed: false
    }
  },
  computed: {
    hamburgerIconSize () {
      return {
        fontSize: `${_.get(this.section.options.content.subject.specialCols.navigation, ['hamburgerIconSize', this.resolutioner], _.get(this.section.options.content.subject.specialCols.navigation, ['hamburgerIconSize', 'lg'], 16))}px`
      }
    },
    bringToFront () {
      return _.get(this.section.options.content.menuItems, ['bringToFront', this.resolutioner], _.get(this.section.options.content.menuItems, ['bringToFront', 'lg'], false))
    },
    collasedBreackpoint () {
      const breakpoints = ['lg', 'md', 'sm']
      const currentBreakpoint = breakpoints.findIndex(b => b === this.resolutioner)
      const collapsableBreakpoint = breakpoints.findIndex(b => b === this.section.options.content.subject.specialCols.navigation.showOn)

      return collapsableBreakpoint < currentBreakpoint
    },
    isCollapsed () {
      return this.collapsed || this.collasedBreackpoint
    },
    iconOpen () {
      return _.get(this.section.options.content.subject.specialCols.navigation, 'hamburgerIcon_open', 'fa fa-bars')
    },
    iconClose () {
      return _.get(this.section.options.content.subject.specialCols.navigation, 'hamburgerIcon_close', 'fa fa-times')
    },
    iconColor () {
      return _.get(this.section.options.content.subject.specialCols.navigation, 'hamburgerIcon_color', '#656565')
    },
    closeIconColor () {
      return _.get(this.section.options.content.subject.specialCols.navigation, 'hamburgerIcon_close_color', this.iconColor)
    },
    hamburgerIcon () {
      if (!this.collapse) {
        return this.iconOpen
      }

      return this.iconClose
    },
    hamburgerIconColor () {
      if (!this.collapse) {
        return this.iconColor
      }

      return this.closeIconColor
    }
  }
}
</script>
