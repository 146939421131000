var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex position-relative page-folder-tree"},[_c('treeselect',{attrs:{"options":_vm.folders,"searchable":false,"show-count":true,"no-options-text":"No folders created","placeholder":"All Pages","readonly":""},scopedSlots:_vm._u([{key:"value-label",fn:function(ref){
var node = ref.node;
return _c('label',{},[_vm._v(" "+_vm._s(node.raw.name)+" ")])}},{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName},[_vm._v(" "+_vm._s(node.raw.name)+" ")])}}]),model:{value:(_vm.folder),callback:function ($$v) {_vm.folder=$$v},expression:"folder"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }