<template>
  <div class="flex gap-[6px]">
    <b-btn
      variant="white"
      class="w-[29px] h-[29px] flex items-center justify-center p-0"
      @click="() => alignMutation = 'normal'"
    >
      <svg
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="{fill: alignMutation === 'normal' ? '#7CACFA' : '#333333'}"
      >
        <path
          d="M0.4645 0C0.721036 0 0.929 0.220568 0.929 0.492651V10.3457C0.929 10.6178 0.721036 10.8383 0.4645 10.8383C0.207964 10.8383 0 10.6178 0 10.3457V0.492651C0 0.220568 0.207964 0 0.4645 0Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.858 1.47795C1.858 1.20587 2.06596 0.985303 2.3225 0.985303H8.8255C9.08204 0.985303 9.29 1.20587 9.29 1.47795V4.43386C9.29 4.70595 9.08204 4.92651 8.8255 4.92651H2.3225C2.06596 4.92651 1.858 4.70595 1.858 4.43386V1.47795ZM2.787 1.97061V3.94121H8.361V1.97061H2.787Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.3225 5.91182C2.06596 5.91182 1.858 6.13238 1.858 6.40447V9.36038C1.858 9.63246 2.06596 9.85303 2.3225 9.85303H5.1095C5.36604 9.85303 5.574 9.63246 5.574 9.36038V6.40447C5.574 6.13238 5.36604 5.91182 5.1095 5.91182H2.3225ZM2.787 8.86773V6.89712H4.645V8.86773H2.787Z"
        />
      </svg>
    </b-btn>
    <b-btn
      variant="white"
      class="w-[29px] h-[29px] flex items-center justify-center p-0"
      @click="() => alignMutation = 'center'"
    >
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="{fill: alignMutation === 'center' ? '#7CACFA' : '#333333'}"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.5 1C5.5 0.723858 5.27614 0.5 5 0.5C4.72386 0.5 4.5 0.723858 4.5 1V1.5H3C2.72386 1.5 2.5 1.72386 2.5 2V5C2.5 5.27614 2.72386 5.5 3 5.5H4.5V6.5H0.5C0.223858 6.5 0 6.72386 0 7V10C0 10.2761 0.223858 10.5 0.5 10.5H4.5V11C4.5 11.2761 4.72386 11.5 5 11.5C5.27614 11.5 5.5 11.2761 5.5 11V10.5H9.5C9.77614 10.5 10 10.2761 10 10V7C10 6.72386 9.77614 6.5 9.5 6.5H5.5V5.5H7C7.27614 5.5 7.5 5.27614 7.5 5V2C7.5 1.72386 7.27614 1.5 7 1.5H5.5V1ZM4.5 2.5H3.5V4.5H4.5V2.5ZM5.5 4.5V2.5H6.5V4.5H5.5ZM4.5 7.5H1V9.5H4.5V7.5ZM5.5 9.5V7.5H9V9.5H5.5Z"
        />
      </svg>
    </b-btn>
    <b-btn
      variant="white"
      class="w-[29px] h-[29px] flex items-center justify-center p-0"
      @click="() => alignMutation = 'end'"
    >
      <svg
        width="11"
        height="12"
        viewBox="0 0 11 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="{fill: alignMutation === 'end' ? '#7CACFA' : '#333333'}"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 1.63636C0 1.33512 0.230254 1.09091 0.514286 1.09091H7.71429C7.99832 1.09091 8.22857 1.33512 8.22857 1.63636V4.90909C8.22857 5.21034 7.99832 5.45455 7.71429 5.45455H0.514286C0.230254 5.45455 0 5.21034 0 4.90909V1.63636ZM1.02857 2.18182V4.36364H7.2V2.18182H1.02857Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.77143 0C10.0555 0 10.2857 0.244208 10.2857 0.545455V11.4545C10.2857 11.7558 10.0555 12 9.77143 12C9.4874 12 9.25714 11.7558 9.25714 11.4545V0.545455C9.25714 0.244208 9.4874 0 9.77143 0Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.11429 7.09091C4.11429 6.78966 4.34454 6.54545 4.62857 6.54545H7.71429C7.99832 6.54545 8.22857 6.78966 8.22857 7.09091V10.3636C8.22857 10.6649 7.99832 10.9091 7.71429 10.9091H4.62857C4.34454 10.9091 4.11429 10.6649 4.11429 10.3636V7.09091ZM5.14286 7.63636V9.81818H7.2V7.63636H5.14286Z"
        />
      </svg>
    </b-btn>
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'

export default {
  name: 'SidHorizontalAlign',
  mixins: [SidConcept],
  data () {
    return {
      align: null
    }
  },
  computed: {
    alignMutation: {
      get () {
        return this.align
      },
      set (val) {
        this.align = val
        this.addSidRules([[this.getSid(), [this.ruleName, val]]])
        this.$emit('changed',this.align)
      }
    }
  },
  mounted () {
    this.align = this.getSidStyle(this.getSid(), this.ruleName)
    if (!this.align) {
      this.align = this.defaultValue ? this.defaultValue : 'normal'
    }
  }
}
</script>