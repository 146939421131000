<template>
  <div>
    <div>
      <b-form @submit.prevent="submit">
        <b-form-group label="Public Key">
          <b-input 
            v-model="service.public_key"
            type="password"
            required
          />
        </b-form-group>
        <b-form-group label="Private Key">
          <b-input
            v-model="service.private_key"
            type="password"
            required
          />
        </b-form-group>
        <b-btn
          v-if="!service.status"
          :disabled="!service.public_key && !service.private_key"
          variant="white"
          type="submit"
        >
          <svg
            class="mr-[7.5px]"
            fill="none"
            height="8"
            viewBox="0 0 9 8"
            width="9"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.35355 0.146447C1.15829 -0.0488155 0.841709 -0.0488155 0.646447 0.146447C0.451184 0.341709 0.451184 0.658291 0.646447 0.853553L3.79289 4L0.646447 7.14645C0.451184 7.34171 0.451184 7.65829 0.646447 7.85355C0.841709 8.04881 1.15829 8.04881 1.35355 7.85355L4.85355 4.35355C5.04882 4.15829 5.04882 3.84171 4.85355 3.64645L1.35355 0.146447Z"
              fill="#3D82EA"
            />
            <path
              d="M4.85355 0.146447C4.65829 -0.0488155 4.34171 -0.0488155 4.14645 0.146447C3.95118 0.341709 3.95118 0.658291 4.14645 0.853553L7.29289 4L4.14645 7.14645C3.95118 7.34171 3.95118 7.65829 4.14645 7.85355C4.34171 8.04881 4.65829 8.04881 4.85355 7.85355L8.35355 4.35355C8.54881 4.15829 8.54881 3.84171 8.35355 3.64645L4.85355 0.146447Z"
              fill="#3D82EA"
            />
          </svg>

          Integrate
        </b-btn>
      </b-form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Stripe',
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      statusToggleLoading: false
    }
  },
  methods: {
    toggleStatus () {
      const {setStatusToggleLoading} = this
      this.$emit('toggle-status',{setLoading:setStatusToggleLoading})
    },
    submit () {
      this.$emit('submit', this.service)
    }
  }
}
</script>