<template>
  <div>
    <slot
      name="value"
      v-bind="{mutation}"
    />
    <input
      v-model="mutation"
      type="number"
    >
  </div>
</template>

<script>
import _ from 'lodash'
import resolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  name: 'ResolutionControlCustoms',
  mixins: [resolutionMixin],
  props: {
    valueName: {
      type: String,
      required: true
    },
    parentObject: {
      type: Object,
      required: true
    },
    defaultValue: {
      required: true
    }
  },
  computed: {
    mutation: {
      get () {
        return _.get(this.parentObject, [this.valueName, this.res], _.get(this.parentObject, [this.valueName, 'lg'], this.defaultValue))
      },
      set (val) {
        this.$set(this.parentObject, this.valueName, {..._.set(this.parentObject[this.valueName] || {}, [this.resolutioner], val)})
      }
    }
  }
}
</script>