<template>
  <div>
    <i :class="[customs.icon]" />
  </div>
</template>

<script>
import ComponentProvider from '@builder/components/mixins/ComponentProvider'
import ResolutionMixin from '../../mixins/ResolutionMixin'


export default {
  name: 'ContentEditable',

  mixins: [ComponentProvider, ResolutionMixin]
}
</script>