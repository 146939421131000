<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element"
    :dublicate-deep="parentUID"
    :dublicate="section"
    :hide-right="true"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="() => null"
  >
    <b-form-group
      class="estage-form-element-group mb-0"
      :class="`--${sid}--form-field --${sid}--form-field-card-exp-month`"
    >
      <template
        v-if="control.label"
        #label
        class="flex items-center gap-x-[4px]"
        :class="`--${sid}--form-field-label`"
      >
        Expiry Date
        <span
          class="text-danger"
        >
          *
        </span>
      </template>
      
      <v-select
        v-model="control.value"
        :options="expMonthsOptions"
        label="label"
        required
        class="input"
        :class="`--${sid}--form-field-input`"
      />
    </b-form-group>
  </highliter>
</template>

<script>
import BorderMixin from '@/components/mixins/BorderMixin'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElFieldCardExpMonth',
  mixins: [BorderMixin, SectionMixin],
  components: {Highliter},
  data () {
    return {
      expMonthsOptions: [
        {
          value: 1,
          label: 'Jan'
        },
        {
          value: 2,
          label: 'Feb'
        },
        {
          value: 3,
          label: 'Mar'
        },
        {
          value: 4,
          label: 'Apr'
        },
        {
          value: 5,
          label: 'May'
        },
        {
          value: 6,
          label: 'Jun'
        },
        {
          value: 7,
          label: 'Jul'
        },
        {
          value: 8,
          label: 'Aug'
        },
        {
          value: 9,
          label: 'Sep'
        },
        {
          value: 10,
          label: 'Oct'
        },
        {
          value: 11,
          label: 'Nov'
        },
        {
          value: 12,
          label: 'Dec'
        }
      ]
    }
  },
  computed: {
    control () {
      return this.section.options.content.control
    }
  },
  methods: {
    focus () {
      this.$el.querySelector('legend').classList.add('active')
    },
    blur () {
      this.$el.querySelector('legend').classList.remove('active')
    },
    expYearsOptions (endYear) {
      let startYear = new Date().getFullYear()
      const endDate = endYear || new Date().getFullYear()
      let years = []
      while (startYear <= endDate) {
        years.push(startYear)
        startYear++
      }
      return years
    }
  }
}
</script>