import {mapState} from 'vuex'
import Cookie from '../../../mixins/Cookie'
import moment from 'moment-timezone'
import _ from 'lodash'

export default {
  data () {
    return {
      timer: null
    }
  },
  mixins: [Cookie],
  computed: {
    dialFontFamily () {
      return _.get(this.section.options.content.time, 'dialFontFamily', this.globalStyles.font.body.fontFamily)
    },
    ...mapState('projects', {
      project: state => state.project
    }),
    timeDate () {
      return this.moment(this.section.options.content.time.time).format('YYYY-MM-DD HH:mm:ss')
    },
    timeZoneTransform () {
      let time = this.section.options.content.time.time

      if (this.section.options.content.time.countdownType === 'date') {
        const tz = moment(time).tz(this.section.options.content.time.timeZone, true)
        if (tz) {
          return tz.tz(this.section.options.content.time.origTimezone).valueOf()
        }
      }

      return time
    }
  },
  methods: {
    restart () {
      if (this.section.options.content.time.countdownType === 'time' && this.timer) {
        this.section.options.content.time.time = this.moment().add(this.timer.diffWithOldTime, 'seconds').valueOf()
        VEvent.fire('timer-update')

        if (this.hasOwnProperty('setCountdown')) {
          this.setCountdown()
        }
      }
    },
    remove () {
      axios.delete(`api/projects/${this.project.id}/timers/${this.section.options.content.time.id}`)
        .finally(() => {
          this.$emit('delete')
        })
    },
    get () {
      axios.get(`api/projects/${this.project.id}/timers/${this.section.options.content.time.id}`)
        .then(({data}) => {
          const diffWithCurrentTime = this.moment().diff(this.moment(data.data.end_date), 'seconds')
          const diffWithOldTime = this.moment(data.data.end_date).diff(this.moment(data.data.start_date), 'seconds')
          let startDate = this.moment().valueOf()

          this.section.options.content.time.end_date = data.data.end_date

          if (!this.section.options.content.time.onPageLoad) {
            this.timer = {
              diffWithOldTime
            }

            if (this.moment(data.data.end_date).isBefore(this.moment()) && this.section.options.content.time.countdownType === 'time') {
              if (diffWithCurrentTime > diffWithOldTime) {
                this.section.options.content.time.time = this.moment().add(diffWithOldTime, 'seconds').valueOf()
              } else {
                this.section.options.content.time.time = this.moment().add(diffWithOldTime - diffWithCurrentTime, 'seconds').valueOf()
                startDate = this.moment().subtract(diffWithCurrentTime ,'seconds').valueOf()
              }

              axios.put(`api/projects/${this.project.id}/timers/${this.section.options.content.time.id}`, {
                start_date: startDate,
                end_date: JSON.stringify(this.section.options.content.time.time),
                content: '[]',
                is_active: true
              })

              VEvent.fire('timer-update')
            } else {
              this.section.options.content.time.time = data.data.end_date
              VEvent.fire('timer-update')
            }
          } else {
            if (!this.get_cookie(`timer-${this.section.options.content.time.id}`) || JSON.parse(this.get_cookie(`timer-${this.section.options.content.time.id}`)).updated_at !== data.data.updated_at) {
              this.set_cookie(`timer-${this.section.options.content.time.id}`, JSON.stringify({
                start_date: startDate,
                end_date: this.moment().add(diffWithOldTime, 'seconds'),
                updated_at: data.data.updated_at
              }))
            }

            const cookie = JSON.parse(this.get_cookie(`timer-${this.section.options.content.time.id}`))

            const cookieDiffWithCurrentTime = this.moment().diff(this.moment(cookie.end_date), 'seconds')
            const cookieDiffWithOldTime = this.moment(cookie.end_date).diff(this.moment(cookie.start_date), 'seconds')

            this.timer = {
              diffWithOldTime: cookieDiffWithOldTime
            }

            if (this.moment(cookie.end_date).isBefore(this.moment()) && this.section.options.content.time.countdownType === 'time') {
              if (cookieDiffWithCurrentTime > cookieDiffWithOldTime) {
                this.section.options.content.time.time = this.moment().add(cookieDiffWithOldTime, 'seconds').valueOf()
              } else {
                this.section.options.content.time.time = this.moment().add(cookieDiffWithOldTime - cookieDiffWithCurrentTime, 'seconds').valueOf()
                startDate = this.moment().subtract(cookieDiffWithCurrentTime ,'seconds').valueOf()
              }

              this.set_cookie(`timer-${this.section.options.content.time.id}`, JSON.stringify({
                start_date: startDate,
                end_date: this.section.options.content.time.time,
                updated_at: data.data.updated_at
              }))

              VEvent.fire('timer-update')
            } else {
              this.section.options.content.time.time = this.moment(cookie.end_date).subtract(cookie.start_date, 'secondst')
              VEvent.fire('timer-update')
            }
          }
        })
    },
    create () {
      VEvent.fire('loader', true)
      axios.post(`api/projects/${this.project.id}/timers`, {
        start_date: new Date().getTime(),
        end_date: 0,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        content: '[]',
        countdownType: 'date',
        is_active: true
      })
        .then(({data}) => {
          this.section.options.content.time.id = data.data.id
          this.section.options.content.time = {
            countdownType: 'date',
            ...this.section.options.content.time,
            ...data.data
          }
        })
        .finally(() => {
          VEvent.fire('loader', false)
        })
    }
  },
  created () {
    if (!this.section.options.content.time.hasOwnProperty('id')) {
      this.create()
    } else {
      this.get()
    }
  }
}
