import { Mark } from 'tiptap'
import { updateMark } from 'tiptap-commands'

export default class TextColor extends Mark {

  get name () {
    return 'fontsize'
  }

  get defaultOptions () {
    return {
      fontSize: ['11px']
    }
  }

  get schema () {
    return {
      attrs: {
        fontSize: {
          default: '11px'
        }
      },
      parseDOM: this.options.fontSize.map(() => ({
        tag: 'spanr',
        getAttrs (dom){
          return {
            fontSize:dom.style.fontSize
          }
        }
      })),
      toDOM:
        node => {
          return ['spanr', {
            style: `font-size:${node.attrs.fontSize}`
          }, 0]
        }
    }
  }

  commands ({ type }) {
    return (attrs) => updateMark(type, attrs)
  }
}
