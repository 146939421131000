<template>
  <div
    class="border group mb-[10px] rounded-[2px] h-[29px] flex items-center pl-[13px] hover:bg-[#F1F5FD] cursor-pinter"
    @click="$emit('open-settings')"
  >
    <svg
      class="move cursor-move mr-[14px]"
      fill="none"
      height="10"
      viewBox="0 0 11 10"
      width="11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.57745 0H0.568359V3H3.57745V0Z"
        fill="#3D82EA"
      />
      <path
        d="M10.5984 0H7.58936V3H10.5984V0Z"
        fill="#3D82EA"
      />
      <path
        d="M3.57745 7H0.568359V10H3.57745V7Z"
        fill="#3D82EA"
      />
      <path
        d="M10.5984 7H7.58936V10H10.5984V7Z"
        fill="#3D82EA"
      />
    </svg>

    <span class="text-truncate max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]">{{
      control.field_name
    }}</span>

    <button
      v-b-tooltip
      class="w-[38px] h-[29px] text-center border border-right-0 bg-[#fff] p-0 ml-auto"
      title="Delete"
      @click.stop="deleteControl"
    >
      <svg
        fill="none"
        height="10"
        viewBox="0 0 10 10"
        width="10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
          fill="#6E747A"
        />
      </svg>
    </button>
    <button
      title="Column Settings"
      class="flex h-[27px] items-center justify-center border-left w-[31px] bg-[#fff] p-0 hover:bg-gray-100"
      @click.stop="$emit('open-settings')"
    >
      <svg
        fill="none"
        height="10"
        viewBox="0 0 7 10"
        width="7"
        xmlns="http://www.w3.org/2000/svg"
      ><path
        d="M0.879944 9.23245L4.98805 4.89924L0.879944 0.566031"
        stroke="#6E747A"
        stroke-width="1.5"
      /></svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'FormControlsListItem',
  props: {
    control: {
      type: Object,
      required: true
    },
    // eslint-disable-next-line vue/prop-name-casing
    form_id: {
      type: Number,
      required: true
    }
  },
  methods: {
    deleteControl () {
      this.$store.dispatch('forms/deleteControl', {
        form_id: this.form_id,
        field_id: this.control.id
      })
        .then(() => {
          this.$emit('deleted')
        })
    },
    copyControl () {
      this.$emit('copy', JSON.parse(JSON.stringify(this.control)))
    }
  }
}
</script>

<style scoped>

</style>
