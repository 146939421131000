import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Post List'
export const group = 'Dynamic blog'
export const beta = false

export const defaultOptions = {
  content: {
    data: {
      type: 'post-list-mod',
      icon: 'fa fa-arrow-right',
      orderBy: '',
      posts: [],
      categories: [],
      author: {},
      display: {
        featuredImage: false,
        listIcon: true,
        showAuthor: true,
        showDate: true,
        showCategory: true
      }
    }
  }
}

export const icon = ''

export default class ElPostList extends SectionProvider {
  constructor (options = {}) {
    super('ElPostList', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
