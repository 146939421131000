<template>
  <div :style="{...fullMarginSubject(section.options.customize.style)}">
    <div
      class="flip-timer"
      :style="scale"
      :class="{'hide-label': !property(section.options.content.time, 'displayLabels', true)}"
    >
      <div
        :class="container"
        class="position-relative time-item-3"
        :style="{...shadow, ...fullPaddingSubject(section.options.customize.style)}"
      >
        <slot
          name="time"
          v-bind="{color: colorCustom('Subtitle'), scale, sizing}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TemplateMixin from '../../../../mixins/TemplateMixin'
import ThemeMixin from '../../../../mixins/ThemeMixin'
import PropertiesManager from '../../../../mixins/PropertiesManager'
import _ from 'lodash'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'Carousel1Template',
  mixins: [TemplateMixin, ThemeMixin, PropertiesManager, BorderMixin],
  computed: {
    scale () {
      return _.get(this.section.options.content.time, [`size${this.tablet ? 'md' : this.resolution}`], this.section.options.content.time.size) || 1
    },
    sizing () {
      return this.section.options.content.time.sizing || {
        width: 6,
        counter: 3,
        padding: 2,
        subtitle: .9
      }
    }
  }
}
</script>

<style lang="scss">
.time-item-3 {
  .timer-block {
    .timer-item {
      .time {
        border-radius: 10px;
        position: relative;
        display: block;
        font-weight: 700;
        font-size: 94px;
        text-align: center;
        line-height: 1;
        width: 160px;
        margin-bottom: 14px;
      }

      .subtitle {
        text-align: center;
        font-weight: 700;
        display: block;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }
  }
}

.resolution-sm {
  .time-item-3 {
    .timer-block {
      .timer-item {
        .time {
          padding: 10px 10px;
          font-size: 40px;
          width: auto;
        }

        .time-divider {
          font-size: 30px;
          margin-right: 10px;
          margin-left: 10px;
        }

        .subtitle {
          font-size: 10px;
        }
      }
    }
  }
}
</style>
