import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Post Thumbnail'
export const group = 'Dynamic blog'
export const beta = false

export const defaultOptions = {
  content: {
    data: {
      post: '',
      type: 'featured-post-thumb-mod'
    }
  }
}

export const icon = ''

export default class ElPostCategories extends SectionProvider {
  constructor (options = {}) {
    super('ElFeaturedPostThumb', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
