import _ from 'lodash'
import {mapState} from 'vuex'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  mixins: [ResolutionMixin],
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    getGlobalTextStyles () {
      const h1 = _.get(this.globalStyles, ['textStyles', 'h1', 'fontFamily', this.resolutioner], {})
      const h2 = _.get(this.globalStyles, ['textStyles', 'h2', 'fontFamily', this.resolutioner], {})
      const h3 = _.get(this.globalStyles, ['textStyles', 'h3', 'fontFamily', this.resolutioner], {})
      const h4 = _.get(this.globalStyles, ['textStyles', 'h4', 'fontFamily', this.resolutioner], {})
      const paragraph = _.get(this.globalStyles, ['textStyles', 'paragraph', 'fontFamily', this.resolutioner], {})
      const button = _.get(this.globalStyles, 'button.options.content.button.fontFamily', null) || paragraph

      return {
        h1,
        h2,
        h3,
        h4,
        paragraph,
        button
      }
    }
  }
}