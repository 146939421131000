<template>
  <b-col :cols="columns">
    <b-form-group :style="{textAlign: button.aligment[resolution]}">
      <b-btn
        :block="button.aligment[resolution] === 'full-width'"
        :class="[button.shadow, button.shape || globalStyles.buttons.shape, btnStyle, {'btn-icon': !button.text}]"
        :disabled="loading || gdpr.enabled && !gdprCheck"
        :style="buttonStyles"
        class="flex items-center py-0"
        type="submit"
      >
        <div class="d-flex align-items-center">
          <template v-if="loading">
            <div class="mx-auto d-flex align-items-center">
              <div>{{ button.text }}</div>
              <b-spinner
                class="ml-2"
                small
              />
            </div>
          </template>
          <template v-else>
            <div class="w-100">
              {{ button.text }}
            </div>
            <i
              v-if="button.icon"
              :class="[button.icon, {'ml-2': button.text}]"
            />
          </template>
        </div>
      </b-btn>
    </b-form-group>
  </b-col>
</template>

<script>
import {mapState} from 'vuex'
import _ from 'lodash'
import ResolutionMixin from '../../../mixins/ResolutionMixin'

export default {
  name: 'ButtonForm',
  mixins: [ResolutionMixin],
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    gdprCheck: {
      type: Boolean,
      required: true
    },
    gdpr: {
      type: Object,
      required: true
    }
  },
  computed: {
    columns () {
      return _.get(this.button.columns, this.resolutioner, this.button.columns)
    },
    btnStyle () {
      return !this.button.btnStyle ? this.globalStyles.buttons.outlined ? 'outlined' : 'filled' : this.button.btnStyle
    },
    ...mapState('editor', {
      globalStyles: state => state.globalStyles,
      resolution: state => state.resolution
    }),
    button () {
      return this.form.button
    },
    buttonSize () {
      const sizeTransform = {
        sm: 45,
        null: 50,
        lg: 60
      }

      const size = _.get(this.button, ['size'], '')

      if (!sizeTransform[size]) {
        return size || 50
      }

      return sizeTransform[size]
    },
    buttonStyles () {
      return {
        color: this.btnStyle === 'outlined' ? !!this.button.backgroundColor === false ? this.globalStyles.colors.buttons : this.button.backgroundColor : this.button.color ? this.button.color : '#fff',
        backgroundColor: !!this.button.backgroundColor === false ? this.globalStyles.colors.buttons : this.button.backgroundColor,
        borderColor: !!this.button.backgroundColor === false ? this.globalStyles.colors.buttons : this.button.backgroundColor,
        fontFamily: (this.button.fontFamily || 'Global Style') === 'Global Style' ? _.get(this.globalStyles, 'buttons.fontFamily', 'Roboto') : this.button.fontFamily,
        height: `${this.buttonSize}px`,
        fontSize: `${this.buttonSize / 3.2}px !important`
      }
    }
  }
}
</script>
