<template>
  <div>
    <editor-menu-bar
      v-slot="{ commands, isActive, getNodeAttrs, getMarkAttrs }"
      :editor="editor"
    >
      <div>
        <div
          v-if="linkMenuIsActive"
          class="mb-4"
        >
          <b-row>
            <b-col md="5">
              <b-form-group label="Select a page or enter a URL.">
                <pages-select-menu
                  input-class="rounded-[4px]"
                  :others="[{ id: 'custom-link', title: 'Custom Link' }]"
                  other-title="Other:"
                  @selectPage="(item) => (selectedPage = item)"
                  @selectOther="(item) => (selectedPage = item)"
                >
                  <template
                    v-if="selectedPage"
                    slot="placeholder"
                  >
                    <div class="px-3">
                      {{ selectedPage.title }}
                    </div>
                  </template>
                </pages-select-menu>
              </b-form-group>

              <b-form-group
                v-if="selectedPage && selectedPage.custom"
                class="position-relative"
              >
                <b-input
                  ref="linkInput"
                  v-model="selectedPage.id"
                  type="url"
                  autofocus
                  placeholder="https://"
                  @keydown.esc="hideLinkMenu"
                />
              </b-form-group>

              <div class="d-flex">
                <b-btn
                  class="btn-icon mr-2"
                  variant="primary"
                  @click="setLinkUrl(commands.link)"
                >
                  <i class="fa fa-check" />
                </b-btn>

                <b-btn
                  class="btn-icon"
                  @click.stop="setLinkUrl(commands.link, null)"
                >
                  <i class="fa fa-times" />
                </b-btn>
              </div>
            </b-col>
          </b-row>
        </div>

        <div class="rounded-top border p-2">
          <b-row
            class="menubar"
            no-gutters
          >
            <b-col cols="2">
              <b-btn-group>
                <b-btn
                  variant="menubar-button border-0"
                  :class="{ 'is-active': isActive.bold() }"
                  @click="commands.bold"
                >
                  <i class="fa fa-bold" />
                </b-btn>
                <b-btn
                  variant="menubar-button border-0"
                  :class="{ 'is-active': isActive.italic() }"
                  @click="commands.italic"
                >
                  <i class="fa fa-italic" />
                </b-btn>
                <b-btn
                  variant="menubar-button border-0"
                  :class="{ 'is-active': isActive.underline() }"
                  @click="commands.underline"
                >
                  <i class="fa fa-underline" />
                </b-btn>
                <b-btn
                  variant="menubar-button border-0"
                  :class="{ 'is-active': isActive.link() }"
                  @click="showLinkMenu(getMarkAttrs('link'))"
                >
                  <i class="fa fa-link" />
                </b-btn>
              </b-btn-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </editor-menu-bar>

    <div class="border border-top-0 p-4">
      <editor-content
        ref="editor"
        style="overflow-wrap: anywhere"
        class="success-message"
        :editor="editor"
      />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from 'tiptap-extensions'
import { mapGetters } from 'vuex'
import PagesPaginationMixin from '../../../editor/mixins/PagesPaginationMixin'
import PagesSelectMenu from '@/components/editor/components/pagesSelectMenu/PagesSelectMenu'

export default {
  name: 'Content',

  components: {
    PagesSelectMenu,
    EditorContent,
    EditorMenuBar
  },

  mixins: [PagesPaginationMixin],

  props: {
    value: {
      required: true
    }
  },

  data () {
    return {
      selectedPage: null,
      linkUrl: null,
      linkMenuIsActive: false,
      keepInBounds: true,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ],
        onUpdate: ({ getHTML }) => {
          const content = getHTML()
          this.$emit('input', content)
        },
        parseOptions: {
          preserveWhitespace: 'full'
        },
        content: this.value
      })
    }
  },

  computed: {
    ...mapGetters('pages', {
      pages: 'getPages'
    })
  },

  beforeDestroy () {
    // this.editor.destroy()
  },

  methods: {
    async onOpen () {
      setTimeout(() => {
        document
          .querySelector('.vs__dropdown-menu')
          .addEventListener('scroll', this.scrollLoadingPages)
      })
    },
    onClose () {
      this.searching = false
      document
        .querySelector('.vs__dropdown-menu')
        .removeEventListener('scroll', this.scrollLoadingPages)
    },
    showLinkMenu (attrs) {
      this.selectedPage = attrs.href ? JSON.parse(attrs.href) : null
      this.linkMenuIsActive = true
    },
    hideLinkMenu () {
      this.selectedPage = null
      this.linkMenuIsActive = false
    },
    setLinkUrl (command) {
      command({ href: JSON.stringify(this.selectedPage) })
      this.hideLinkMenu()
    }
  }
}
</script>

<style lang="scss">
.success-message {
  > .ProseMirror {
    min-height: 200px;
  }

  a {
    color: #4f83e3ff;
  }

  p {
    color: #6e747a;
    font-size: 16px;
  }

  strong {
    color: #000;
  }
}
</style>
