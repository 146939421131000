<template>
  <StateTabs
    v-model="model"
    :states-text="['Normal State', 'Hover State']"
    class="pb-[12px]"
  >
    <background-customs :config="subject.customize.style.background" />
    <template slot="action-state">
      <background-customs
        v-if="model"
        :config="hoverBackground"
      />
    </template>
  </StateTabs>
</template>

<script>
import _ from 'lodash'
import StateTabs from '@/components/editor/components/StateTabs'

export default {
  components: {StateTabs},
  props: {
    subject: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      model: false
    }
  },
  computed: {
    hoverConfig: {
      get () {
        if (!this.subject.hover) {
          this.$set(this.subject, 'hover', {})
        }
        return this.subject.hover
      },
      set (val) {
        this.subject.hover = val
      }
    },
    hoverBackground () {
      const _bg = _.get(this.subject, ['hover', 'background'], null)
      if (!_bg) return this.$set(this.hoverConfig, 'background', _.cloneDeep(this.subject.customize.style.background))

      return _bg
    }
  }
}
</script>