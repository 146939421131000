import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Hero_006'
export const group = 'hero'
export const defaultOptions = {
  'content': {
    'items': {
      'type': null,
      'children': [
        {
          'display': true,
          'name': 'Flex Wrapper',
          'hideFromSections': false,
          'component': 'ElFlexWrapperV2',
          'group': '',
          'layout': null,
          'options': {
            'content': {
              'display': true,
              'cols': [
                {
                  'display': true,
                  'name': 'Flex Column',
                  'elements': [
                    {
                      'display': true,
                      'name': 'Flex Wrapper',
                      'hideFromSections': false,
                      'component': 'ElFlexWrapperV2',
                      'group': '',
                      'layout': null,
                      'options': {
                        'content': {
                          'display': true,
                          'cols': [
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'uid': 'tPLYzJ_lh',
                                  'display': true,
                                  'name': 'Menu Links',
                                  'sid': 'l64q5vxx--jGIrVm6oSi',
                                  'hideFromSections': false,
                                  'component': 'ElMenuLinksV4',
                                  'group': 'Navigation',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'menu': {
                                        'display': true,
                                        'type': 'menu-links-mod-v4',
                                        'links': [
                                          {
                                            'id': '87iF7qBpvJ',
                                            'href': '#',
                                            'text': 'Products',
                                            'customLink': false,
                                            'blank': false,
                                            'itemType': '',
                                            'children': []
                                          },
                                          {
                                            'id': 'sIQmYXsCFr',
                                            'href': '#',
                                            'text': 'About',
                                            'customLink': false,
                                            'blank': false,
                                            'itemType': '',
                                            'children': []
                                          },
                                          {
                                            'id': 'x7EGJp6I-n',
                                            'href': '#',
                                            'text': 'Contact',
                                            'customLink': false,
                                            'blank': false,
                                            'itemType': '',
                                            'children': []
                                          }
                                        ]
                                      }
                                    }
                                  },
                                  'editor': 'project'
                                }
                              ],
                              'sortIndex': {
                                'lg': 0,
                                'md': 0,
                                'sm': 0
                              },
                              'uid': 'vdOXBQHUnO',
                              'sid': 'l64q5vxx--P6clmPGd_0',
                              'highlight': false,
                              '_uid': 'hFbll9yV_',
                              'animations': {
                                'animation': null,
                                'once': true,
                                'offset': 200,
                                'delay': 50
                              }
                            },
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'uid': '07iI5fu_q',
                                  'display': true,
                                  'name': 'Subheadline',
                                  'sid': 'l64q5vxx--KWWlhTss4W',
                                  'hideFromSections': false,
                                  'component': 'ElSubheadlineV2',
                                  'group': 'Text',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'description': {
                                        'type': 'tiptap-mod-v2',
                                        'display': true,
                                        'tag': 'h4',
                                        'resolutionStyle': {
                                          'sm': {},
                                          'md': {},
                                          'lg': {}
                                        },
                                        'text': '<p>Beautology</p>',
                                        'animations': {
                                          'animation': null,
                                          'once': true,
                                          'offset': 200,
                                          'delay': 50
                                        }
                                      }
                                    },
                                    'customize': null
                                  },
                                  'editor': 'project'
                                },
                                {
                                  'uid': 'GPeX3XpWc',
                                  'display': true,
                                  'name': 'Subheadline',
                                  'sid': 'l64q5vxx--NfLL3nXRDD',
                                  'hideFromSections': false,
                                  'component': 'ElSubheadlineV2',
                                  'group': 'Text',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'description': {
                                        'type': 'tiptap-mod-v2',
                                        'display': true,
                                        'tag': 'h4',
                                        'resolutionStyle': {
                                          'sm': {},
                                          'md': {},
                                          'lg': {}
                                        },
                                        'text': '<p>Fully Natural Skincare</p>',
                                        'animations': {
                                          'animation': null,
                                          'once': true,
                                          'offset': 200,
                                          'delay': 50
                                        }
                                      }
                                    },
                                    'customize': null
                                  },
                                  'editor': 'project'
                                }
                              ],
                              'sortIndex': {
                                'lg': 1,
                                'md': 1,
                                'sm': 1
                              },
                              'uid': 'SvVX9jE1ct',
                              'sid': 'l64q5vxx--cHYwpgyzsT',
                              'highlight': false,
                              '_uid': 'T8Lh6d-fQJ',
                              'animations': {
                                'animation': null,
                                'once': true,
                                'offset': 200,
                                'delay': 50
                              }
                            },
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'uid': 'hffiWecLK',
                                  'display': true,
                                  'name': 'Menu Links',
                                  'sid': '64q5vxx--NYni0sXC7',
                                  'hideFromSections': false,
                                  'component': 'ElMenuLinksV4',
                                  'group': 'Navigation',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'menu': {
                                        'display': true,
                                        'type': 'menu-links-mod-v4',
                                        'links': [
                                          {
                                            'id': '87iF7qBpvJ',
                                            'href': '#',
                                            'text': 'Features',
                                            'customLink': false,
                                            'blank': false,
                                            'itemType': '',
                                            'children': []
                                          },
                                          {
                                            'id': 'sIQmYXsCFr',
                                            'href': '#',
                                            'text': 'Buy Now',
                                            'customLink': false,
                                            'blank': false,
                                            'itemType': '',
                                            'children': []
                                          }
                                        ]
                                      }
                                    }
                                  },
                                  'editor': 'project',
                                  '_uid': '64q5vxx--NYni0sXC7'
                                }
                              ],
                              'sortIndex': {
                                'lg': 2,
                                'md': 2,
                                'sm': 2
                              },
                              'uid': '831Ecf7yOX',
                              'sid': 'l64q5vxx--qTk_GK3thV',
                              'highlight': false,
                              '_uid': 'JQg_vbGRgt',
                              'animations': {
                                'animation': null,
                                'once': true,
                                'offset': 200,
                                'delay': 50
                              }
                            }
                          ],
                          'animations': {
                            'animation': null,
                            'once': true,
                            'offset': 200,
                            'delay': 50
                          }
                        }
                      },
                      'uid': 'Hv7H9E4Og',
                      'sid': 'l64q5vxx--I5fqZ7Ajv-',
                      'temp_id': '-o7_RRf6n'
                    },
                    {
                      'uid': 'CSCXg4PsI',
                      'display': true,
                      'name': 'Headline',
                      'sid': 'l64q5vxx--cN58i8l_G',
                      'hideFromSections': false,
                      'component': 'ElHeadlineV2',
                      'group': 'Text',
                      'ignoreGroup': false,
                      'layout': null,
                      'options': {
                        'content': {
                          'description': {
                            'type': 'tiptap-mod-v2',
                            'display': true,
                            'tag': 'h3',
                            'resolutionStyle': {
                              'sm': {},
                              'md': {},
                              'lg': {}
                            },
                            'text': '<p>Beauty Means Taking Care Of Your Face</p>',
                            'animations': {
                              'animation': 'fade-up',
                              'once': true,
                              'offset': 200,
                              'delay': 50,
                              'duration': '700'
                            }
                          }
                        }
                      },
                      'editor': 'project'
                    },
                    {
                      'display': true,
                      'name': 'Flex Wrapper',
                      'hideFromSections': false,
                      'component': 'ElFlexWrapperV2',
                      'group': '',
                      'layout': null,
                      'options': {
                        'content': {
                          'display': true,
                          'cols': [
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'uid': 'ZjwZaGTev',
                                  'display': true,
                                  'name': 'Image',
                                  'sid': 'l64q5vxx--7T0p5lfayD',
                                  'hideFromSections': false,
                                  'component': 'ElImageV3',
                                  'group': 'Media',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'image': {
                                        'display': true,
                                        'type': 'ImageModV3',
                                        'src': null,
                                        'alt': 'Image',
                                        'modal': {
                                          'data': {
                                            'modal_id': null
                                          }
                                        },
                                        'animations': {
                                          'animation': 'fade-in',
                                          'once': true,
                                          'offset': 200,
                                          'delay': '1100',
                                          'duration': '1200'
                                        },
                                        'url': ''
                                      }
                                    }
                                  },
                                  'editor': 'project'
                                },
                                {
                                  'uid': 'JkvaRj8qZ',
                                  'display': true,
                                  'name': 'Image',
                                  'sid': '64q5vxx--L4b1pltGA',
                                  'hideFromSections': false,
                                  'component': 'ElImageV3',
                                  'group': 'Media',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'image': {
                                        'display': true,
                                        'type': 'ImageModV3',
                                        'src': null,
                                        'alt': 'Image',
                                        'modal': {
                                          'data': {
                                            'modal_id': null
                                          }
                                        },
                                        'animations': {
                                          'animation': 'fade-in',
                                          'once': true,
                                          'offset': 200,
                                          'delay': '1100',
                                          'duration': '1000'
                                        },
                                        'url': ''
                                      }
                                    }
                                  },
                                  'editor': 'project',
                                  '_uid': '64q5vxx--L4b1pltGA'
                                }
                              ],
                              'sortIndex': {
                                'lg': 1,
                                'md': 1,
                                'sm': 0
                              },
                              'uid': 'ZKQBYE1zsO',
                              'sid': 'l64q5vxx--i4vnZUgeJc',
                              'highlight': false,
                              '_uid': 'CWBXaCwxw9',
                              'animations': {
                                'animation': null,
                                'once': true,
                                'offset': 200,
                                'delay': 50
                              }
                            },
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'display': true,
                                  'name': 'Flex Wrapper',
                                  'hideFromSections': false,
                                  'component': 'ElFlexWrapperV2',
                                  'group': '',
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'display': true,
                                      'cols': [
                                        {
                                          'display': true,
                                          'name': 'Flex Column',
                                          'elements': [
                                            {
                                              'uid': 'qaK2GLiif',
                                              'display': true,
                                              'name': 'Image',
                                              'sid': 'l64q5vxx--2UoN1dYx4y',
                                              'hideFromSections': false,
                                              'component': 'ElImageV3',
                                              'group': 'Media',
                                              'ignoreGroup': false,
                                              'layout': null,
                                              'options': {
                                                'content': {
                                                  'image': {
                                                    'display': true,
                                                    'type': 'ImageModV3',
                                                    'src': null,
                                                    'alt': 'Image',
                                                    'modal': {
                                                      'data': {
                                                        'modal_id': null
                                                      }
                                                    },
                                                    'animations': {
                                                      'animation': 'fade-up',
                                                      'once': true,
                                                      'offset': 200,
                                                      'delay': '800'
                                                    },
                                                    'url': ''
                                                  }
                                                }
                                              },
                                              'editor': 'project'
                                            }
                                          ],
                                          'sortIndex': {
                                            'lg': 0,
                                            'md': 0,
                                            'sm': 0
                                          },
                                          'uid': '72OPxa6f1h',
                                          'sid': 'l64q5vxx--l4fIIuyaXK',
                                          'highlight': false,
                                          '_uid': 'mFGrw59Kn',
                                          'animations': {
                                            'animation': null,
                                            'once': true,
                                            'offset': 200,
                                            'delay': 50
                                          }
                                        },
                                        {
                                          'display': true,
                                          'name': 'Flex Column',
                                          'elements': [
                                            {
                                              'uid': 'CtTVYrIT-',
                                              'display': true,
                                              'name': 'Subheadline',
                                              'sid': 'l64q5vxx--d0eTjwmHdj',
                                              'hideFromSections': false,
                                              'component': 'ElSubheadlineV2',
                                              'group': 'Text',
                                              'ignoreGroup': false,
                                              'layout': null,
                                              'options': {
                                                'content': {
                                                  'description': {
                                                    'type': 'tiptap-mod-v2',
                                                    'display': true,
                                                    'tag': 'h4',
                                                    'resolutionStyle': {
                                                      'sm': {},
                                                      'md': {},
                                                      'lg': {}
                                                    },
                                                    'text': '<p>NEW FACE SCRUB</p>',
                                                    'animations': {
                                                      'animation': null,
                                                      'once': true,
                                                      'offset': 200,
                                                      'delay': 50
                                                    }
                                                  }
                                                },
                                                'customize': null
                                              },
                                              'editor': 'project'
                                            },
                                            {
                                              'uid': 'Kpx9PwjZ1',
                                              'display': true,
                                              'name': 'Paragraph',
                                              'sid': 'l64q5vxx--W-kNb0qt_b',
                                              'hideFromSections': false,
                                              'component': 'ElParagraphV2',
                                              'group': 'Text',
                                              'ignoreGroup': false,
                                              'layout': null,
                                              'options': {
                                                'content': {
                                                  'description': {
                                                    'type': 'tiptap-mod-v2',
                                                    'display': true,
                                                    'resolutionStyle': {
                                                      'sm': {},
                                                      'md': {},
                                                      'lg': {}
                                                    },
                                                    'text': '<p>Maecenas aliquam nisi ipsum, id elementum ex tristique sit amet.</p>',
                                                    'animations': {
                                                      'animation': null,
                                                      'once': true,
                                                      'offset': 200,
                                                      'delay': 50
                                                    }
                                                  }
                                                },
                                                'customize': null
                                              },
                                              'editor': 'project'
                                            },
                                            {
                                              'uid': 'mBKmrWTSo',
                                              'display': true,
                                              'name': 'Button',
                                              'sid': 'l64q5vxx--5zGPMKck3i',
                                              'hideFromSections': false,
                                              'component': 'ElButtonV2',
                                              'group': 'Media',
                                              'ignoreGroup': false,
                                              'layout': null,
                                              'options': {
                                                'content': {
                                                  'button': {
                                                    'pressetID': 0,
                                                    'display': true,
                                                    'type': 'button-mod-v2',
                                                    'text': 'Try it Now!',
                                                    'buttonType': 'button',
                                                    'icon': {
                                                      'lottie': {
                                                        'jsonPath': '',
                                                        'loop': true,
                                                        'autoplay': true
                                                      }
                                                    },
                                                    'hoverAnimation': {},
                                                    'animations': {
                                                      'animation': null,
                                                      'once': true,
                                                      'offset': 200,
                                                      'delay': 50
                                                    }
                                                  }
                                                },
                                                'customize': null
                                              },
                                              'editor': 'project'
                                            }
                                          ],
                                          'sortIndex': {
                                            'lg': 1,
                                            'md': 1,
                                            'sm': 1
                                          },
                                          'uid': 'gt9Lg8c-aU',
                                          'sid': 'l64q5vxx--9oej4a7OPE',
                                          'highlight': false,
                                          '_uid': 'ARDoIFl18L',
                                          'animations': {
                                            'animation': 'fade-up',
                                            'once': true,
                                            'offset': 200,
                                            'delay': '1000',
                                            'duration': '700'
                                          }
                                        }
                                      ],
                                      'animations': {
                                        'animation': null,
                                        'once': true,
                                        'offset': 200,
                                        'delay': 50
                                      }
                                    }
                                  },
                                  'uid': 'hy-4FVWpa',
                                  'sid': 'l64q5vxx--F7QIZIcfEF',
                                  'temp_id': 'SpR-HP7X_'
                                }
                              ],
                              'sortIndex': {
                                'lg': 0,
                                'md': 0,
                                'sm': 1
                              },
                              'uid': '5DEh-K1eP',
                              'sid': 'l64q5vxx--wMsrX3yble',
                              'highlight': false,
                              '_uid': 'GYUI0hBbS',
                              'animations': {
                                'animation': null,
                                'once': true,
                                'offset': 200,
                                'delay': 50
                              }
                            },
                            {
                              'display': true,
                              'name': 'Flex Column',
                              'elements': [
                                {
                                  'uid': 'Phj9gcqf0',
                                  'display': true,
                                  'name': 'Video Popup',
                                  'css': null,
                                  'sid': 'l64q5vxx--7gVGTTavF-',
                                  'sids': null,
                                  'hideFromSections': false,
                                  'component': 'ElVideoPopupV3',
                                  'group': 'Media',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'video': {
                                        'display': true,
                                        'type': 'video-popup-mod-v3',
                                        'placeholder': 'https://res.cloudinary.com/estage/image/upload/v1690421776/users/user-572/9c889bc5533b7f1d8acbbf3b0c18c74e.png',
                                        'src': 'https://www.youtube.com/watch?v=fdAsQ_i2NdU',
                                        'position': 'center',
                                        'size': 'cover',
                                        'autoplay': true,
                                        'shadow': null,
                                        'playIcon': {
                                          'icon': 'fa fa-play'
                                        }
                                      }
                                    }
                                  },
                                  'editor': 'project'
                                },
                                {
                                  'uid': 'o96TQdbJM',
                                  'display': true,
                                  'name': 'Paragraph',
                                  'sid': '64q5vxx--yfs6i6au1',
                                  'hideFromSections': false,
                                  'component': 'ElParagraphV2',
                                  'group': 'Text',
                                  'ignoreGroup': false,
                                  'layout': null,
                                  'options': {
                                    'content': {
                                      'description': {
                                        'type': 'tiptap-mod-v2',
                                        'display': true,
                                        'resolutionStyle': {
                                          'sm': {},
                                          'md': {},
                                          'lg': {}
                                        },
                                        'text': '<p>See how <strong><span style="color:rgba(0, 0, 0, 1)">Beautology</span></strong> is changing the skincare industry. </p>',
                                        'animations': {
                                          'animation': null,
                                          'once': true,
                                          'offset': 200,
                                          'delay': 50
                                        }
                                      }
                                    },
                                    'customize': null
                                  },
                                  'editor': 'project',
                                  '_uid': '64q5vxx--yfs6i6au1'
                                }
                              ],
                              'sortIndex': {
                                'lg': 2,
                                'md': 2,
                                'sm': 2
                              },
                              'uid': 'EYUgdiag3E',
                              'sid': 'l64q5vxx--t1YXMI155r',
                              'highlight': false,
                              '_uid': '9wXUU5SsJn',
                              'animations': {
                                'animation': 'fade-up',
                                'once': true,
                                'offset': 200,
                                'delay': '1200',
                                'duration': '500'
                              }
                            }
                          ],
                          'animations': {
                            'animation': null,
                            'once': true,
                            'offset': 200,
                            'delay': 50
                          }
                        }
                      },
                      'uid': 'dBt5jkxjZ',
                      'sid': 'l64q5vxx--NC21lzrRtG',
                      'temp_id': '-o7_RRf6n'
                    }
                  ],
                  'sortIndex': {
                    'lg': 0,
                    'md': 0,
                    'sm': 0
                  },
                  'uid': 'DBWSXvR0AI',
                  'sid': 'l64q5vxx--eRBktU98Qr',
                  '_uid': '9E1GCrZgjC',
                  'highlight': false,
                  'animations': {
                    'animation': null,
                    'once': true,
                    'offset': 200,
                    'delay': 50
                  }
                }
              ],
              'animations': {
                'animation': null,
                'once': true,
                'offset': 200,
                'delay': 50
              }
            }
          },
          'uid': 'qr1Fmik19',
          'sid': 'l64q5vxx--yMaUZ5Inb_',
          'temp_id': 'j0pbRmZok'
        }
      ]
    },
    'lottie': {}
  },
  'backgroundImage': {
    'src': ''
  },
  'backgroundVideo': {
    'enabled': false,
    'url': '',
    'loop': true,
    'ratio': 1
  }
}

export const iframeURL = 'https://my-web-1690418634120.estage.site'

export default class Section extends SectionProvider {
  constructor (options = {}) {
    super('BlankV2', options)

    this.name = name
    this.group = group
    this.sid = 'l64q5vxx--SYCDdf-rgz'
    this.sids = [
      'l64q5vxx--SYCDdf-rgz',
      'l64q5vxx--yMaUZ5Inb_',
      'l64q5vxx--eRBktU98Qr',
      'l64q5vxx--I5fqZ7Ajv-',
      'l64q5vxx--P6clmPGd_0',
      'l64q5vxx--jGIrVm6oSi',
      'l64q5vxx--cHYwpgyzsT',
      'l64q5vxx--KWWlhTss4W',
      'l64q5vxx--NfLL3nXRDD',
      'l64q5vxx--qTk_GK3thV',
      '64q5vxx--NYni0sXC7',
      'l64q5vxx--cN58i8l_G',
      'l64q5vxx--NC21lzrRtG',
      'l64q5vxx--wMsrX3yble',
      'l64q5vxx--F7QIZIcfEF',
      'l64q5vxx--l4fIIuyaXK',
      'l64q5vxx--2UoN1dYx4y',
      'l64q5vxx--9oej4a7OPE',
      'l64q5vxx--d0eTjwmHdj',
      'l64q5vxx--W-kNb0qt_b',
      'l64q5vxx--5zGPMKck3i',
      'l64q5vxx--i4vnZUgeJc',
      'l64q5vxx--7T0p5lfayD',
      '64q5vxx--L4b1pltGA',
      'l64q5vxx--t1YXMI155r',
      'l64q5vxx--7gVGTTavF-',
      '64q5vxx--yfs6i6au1'
    ]
    this.css = [
      [
        '.--l64q5vxx--SYCDdf-rgz--container { max-width: 100vw; padding-top: 70px; padding-bottom: 90px; }',
        '.--l64q5vxx--yMaUZ5Inb_--flex { max-width: 1300px; --width: 1300px; }',
        '.--l64q5vxx--eRBktU98Qr--flex-col-el { width: 100%; --width: 100%; }',
        '.--l64q5vxx--yMaUZ5Inb_--bg { background-color: rgb(255, 255, 255); }',
        '.--l64q5vxx--yMaUZ5Inb_--container { border-radius: 0px; --border-top-left-radius: 0; --border-top-right-radius: 0; --border-bottom-left-radius: 0; --border-bottom-right-radius: 0; box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 60px 10px; }',
        '.--l64q5vxx--jGIrVm6oSi--wrapper { padding: 20px; gap: 30px; font-family: Inter; font-weight: 300; justify-content: start; align-items: start; }',
        '.--l64q5vxx--jGIrVm6oSi--link { font-size: 14px; color: rgb(102, 111, 119); }',
        '.--l64q5vxx--P6clmPGd_0--flex-col-el { width: 100%; --width: 100%; }',
        '.--l64q5vxx--cHYwpgyzsT--flex-col-el { width: 100%; --width: 100%; }',
        '.--l64q5vxx--qTk_GK3thV--flex-col-el { width: 100%; --width: 100%; }',
        '.--l64q5vxx--I5fqZ7Ajv---flex { max-width: 80vw; --width: 80vw; flex-wrap: nowrap; }',
        '.--l64q5vxx--KWWlhTss4W-text { padding: 20px 20px 5px; }',
        '.--l64q5vxx--KWWlhTss4W-text div { color: rgb(0, 0, 0); font-family: "Playfair Display"; }',
        '.--64q5vxx--NYni0sXC7--wrapper { padding: 20px; gap: 30px; font-family: Inter; font-weight: 300; justify-content: end; align-items: end; }',
        '.--64q5vxx--NYni0sXC7--link { font-size: 14px; color: rgb(102, 111, 119); }',
        '.--l64q5vxx--P6clmPGd_0--flex-col-padding { padding: 0px; }',
        '.--l64q5vxx--qTk_GK3thV--flex-col-padding { padding: 0px; }',
        '.--l64q5vxx--I5fqZ7Ajv---flex.flex-wrapper { padding: 0px; }',
        '.--l64q5vxx--yMaUZ5Inb_--flex.flex-wrapper { padding-top: 0px; }',
        '.--l64q5vxx--cN58i8l_G-text { padding: 20px 90px; }',
        '.--l64q5vxx--cN58i8l_G-text div { color: rgb(0, 0, 0); font-family: "Playfair Display"; font-weight: 300; font-size: 91px; line-height: 1; --line-height: 1; }',
        '.--l64q5vxx--wMsrX3yble--flex-col-el { width: 100%; --width: 100%; }',
        '.--l64q5vxx--i4vnZUgeJc--flex-col-el { width: 100%; --width: 100%; margin-top: -90px; }',
        '.--l64q5vxx--t1YXMI155r--flex-col-el { width: 100%; --width: 100%; margin-top: -50px; }',
        '.--l64q5vxx--NC21lzrRtG--flex { max-width: 80vw; --width: 80vw; flex-wrap: nowrap; }',
        '.--l64q5vxx--7T0p5lfayD--container { justify-content: center; }',
        '.--l64q5vxx--7T0p5lfayD--wrapper { padding: 0px; display: inline-flex; }',
        '.el-image.--l64q5vxx--7T0p5lfayD--image { width: 150px; --width: 150px; }',
        '.--l64q5vxx--7T0p5lfayD--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690419812/users/user-572/08925223382e33a17ee92c1cee4cc052.png"); --bg-id: 167316; }',
        '.--l64q5vxx--i4vnZUgeJc--flex-col > div { display: inherit; --display: inherit; }',
        '.--64q5vxx--L4b1pltGA--container { justify-content: center; }',
        '.--64q5vxx--L4b1pltGA--wrapper { padding: 0px; display: inline-flex; }',
        '.el-image.--64q5vxx--L4b1pltGA--image { width: 100px; --width: 100px; }',
        '.--64q5vxx--L4b1pltGA--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690419868/users/user-572/f6288275b6d6b265b2acecbac77b0402.png"); --bg-id: 167317; }',
        '.--l64q5vxx--i4vnZUgeJc--flex-col { align-items: end; text-align: center; justify-content: center; }',
        '.--l64q5vxx--NC21lzrRtG--container { margin-bottom: -125px; }',
        '.--l64q5vxx--l4fIIuyaXK--flex { max-width: 80vw; --width: 80vw; }',
        '.--l64q5vxx--l4fIIuyaXK--flex-col-el { width: 100%; --width: 100%; }',
        '.--l64q5vxx--9oej4a7OPE--flex-col-el { width: 100%; --width: 100%; }',
        '.--l64q5vxx--F7QIZIcfEF--flex { max-width: 100%; --width: 100%; flex-direction: column; }',
        '.--l64q5vxx--wMsrX3yble--flex-col > div { display: contents; --display: contents; }',
        '.--l64q5vxx--F7QIZIcfEF--flex.flex-wrapper { padding: 0px; }',
        '.--l64q5vxx--l4fIIuyaXK--flex-col-padding { padding: 0px; }',
        '.--l64q5vxx--9oej4a7OPE--flex-col-padding { padding: 0px; }',
        '.--l64q5vxx--NC21lzrRtG--flex.flex-wrapper { padding-top: 70px; align-items: start; }',
        '.--l64q5vxx--2UoN1dYx4y--container { justify-content: start; }',
        '.--l64q5vxx--2UoN1dYx4y--wrapper { padding: 0px; margin-top: -140px; margin-bottom: 25px; }',
        '.el-image.--l64q5vxx--2UoN1dYx4y--image { width: 150px; --width: 150px; }',
        '.--l64q5vxx--2UoN1dYx4y--image { content: url("https://res.cloudinary.com/estage/image/upload/v1690420816/users/user-572/1d41756a12667cb6240577ca06ae39cd.png"); --bg-id: 167322; }',
        '.--l64q5vxx--d0eTjwmHdj-text { padding: 0px 0px 0px 10px; }',
        '.--l64q5vxx--d0eTjwmHdj-text div { font-family: "Playfair Display"; font-weight: 300; color: rgb(77, 77, 77); text-align: left; }',
        '.--l64q5vxx--W-kNb0qt_b-text { padding: 10px 20px 20px 10px; }',
        '.--l64q5vxx--W-kNb0qt_b-text div { text-align: left; color: rgb(170, 170, 170); font-family: Inter; font-weight: 300; font-size: 13px; line-height: 1.8; --line-height: 1.8; }',
        '.--l64q5vxx--wMsrX3yble--flex-col-padding { padding-right: 75px; padding-top: 0px; }',
        '.--l64q5vxx--5zGPMKck3i--1 { margin: 0px 20px 20px 7px; font-family: Inter; font-size: 12px; color: rgb(0, 0, 0); background-color: rgba(0, 0, 0, 0); border-width: 2px; border-color: rgb(0, 0, 0); border-radius: 99px; }',
        '.--l64q5vxx--5zGPMKck3i--2 { justify-content: start; }',
        '.--l64q5vxx--d0eTjwmHdj-margin { margin-top: -20px; }',
        '.--l64q5vxx--5zGPMKck3i--1:hover { background-color: rgb(0, 0, 0); color: rgb(255, 255, 255); }',
        '.--l64q5vxx--7gVGTTavF---play-btn { background-color: rgba(255, 255, 255, 0.55); --height: 80px; border-top-width: 0px; }',
        '.--l64q5vxx--7gVGTTavF---wrapper { margin-top: 0px; }',
        '.--64q5vxx--yfs6i6au1-text { padding: 10px 20px 20px 0px; }',
        '.--64q5vxx--yfs6i6au1-text div { text-align: left; color: rgb(170, 170, 170); font-family: Inter; font-weight: 300; font-size: 13px; line-height: 1.8; --line-height: 1.8; }',
        '.--l64q5vxx--NfLL3nXRDD-text { padding: 0px; }',
        '.--l64q5vxx--NfLL3nXRDD-text div { color: rgb(102, 111, 119); font-family: Inter; font-weight: 300; font-size: 9px; }'
      ],
      [
        '.--l64q5vxx--yMaUZ5Inb_--flex { max-width: 100%; --width: 100%; }',
        '.--l64q5vxx--I5fqZ7Ajv---flex { max-width: 100%; --width: 100%; flex-wrap: nowrap; }',
        '.--l64q5vxx--NC21lzrRtG--flex { max-width: 100%; --width: 100%; flex-wrap: nowrap; }',
        '.--l64q5vxx--l4fIIuyaXK--flex { max-width: 100%; --width: 100%; }',
        '.--l64q5vxx--F7QIZIcfEF--flex { max-width: 100%; --width: 100%; flex-wrap: nowrap; }',
        '.--l64q5vxx--cN58i8l_G-text div { font-size: 70px; }',
        '.--l64q5vxx--wMsrX3yble--flex-col-padding { padding-right: 5px; }',
        '.--l64q5vxx--eRBktU98Qr--flex-col-padding { padding-left: 0px; padding-right: 0px; }',
        '.el-image.--l64q5vxx--2UoN1dYx4y--image { width: 100px; --width: 100px; }',
        '.--l64q5vxx--d0eTjwmHdj-text div { font-size: 19px; }',
        '.--l64q5vxx--2UoN1dYx4y--wrapper { margin-top: -170px; margin-left: 6px; margin-bottom: 18px; }',
        '.--l64q5vxx--W-kNb0qt_b-text div { font-size: 12px; }',
        '.--l64q5vxx--W-kNb0qt_b-text { padding-right: 0px; }',
        '.--l64q5vxx--7gVGTTavF---play-btn { --height: 50px; --width: 50px; font-size: 12px; }',
        '.--64q5vxx--yfs6i6au1-text div { font-size: 12px; line-height: 1.6; --line-height: 1.6; }',
        '.--64q5vxx--yfs6i6au1-text { padding-right: 0px; }',
        '.--l64q5vxx--t1YXMI155r--flex-col-el { margin-top: -70px; }',
        '.--l64q5vxx--jGIrVm6oSi--link { font-size: 12px; }',
        '.--64q5vxx--NYni0sXC7--link { font-size: 12px; }',
        '.--l64q5vxx--cHYwpgyzsT--flex-col-el { width: 30%; --width: 30%; }',
        '.--l64q5vxx--P6clmPGd_0--flex-col-el { width: 35%; --width: 35%; }',
        '.--l64q5vxx--qTk_GK3thV--flex-col-el { width: 35%; --width: 35%; }',
        '.--l64q5vxx--jGIrVm6oSi--wrapper { gap: 14px; }',
        '.--64q5vxx--NYni0sXC7--wrapper { gap: 13px; }',
        '.--l64q5vxx--SYCDdf-rgz--container { padding-top: 40px; }'
      ],
      [
        '.--l64q5vxx--yMaUZ5Inb_--flex { max-width: 100%; --width: 100%; }',
        '.--l64q5vxx--I5fqZ7Ajv---flex { max-width: 100%; --width: 100%; flex-flow: column wrap; }',
        '.--l64q5vxx--NC21lzrRtG--flex { max-width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--l64q5vxx--l4fIIuyaXK--flex { max-width: 100%; --width: 100%; flex-wrap: wrap; }',
        '.--l64q5vxx--F7QIZIcfEF--flex { max-width: 100%; --width: 100%; flex-flow: wrap; column-gap: 0px; }',
        '.--l64q5vxx--P6clmPGd_0--flex-col-el { width: 100%; --width: 100%; }',
        '.--l64q5vxx--jGIrVm6oSi--wrapper { justify-content: center; }',
        '.--l64q5vxx--cHYwpgyzsT--flex-col-el { width: 100%; --width: 100%; }',
        '.--l64q5vxx--KWWlhTss4W-text { padding-top: 0px; }',
        '.--l64q5vxx--cHYwpgyzsT--flex-col-padding { padding-top: 0px; padding-bottom: 0px; }',
        '.--l64q5vxx--qTk_GK3thV--flex-col-el { width: 100%; --width: 100%; }',
        '.--l64q5vxx--qTk_GK3thV--flex-col { align-items: center; text-align: center; justify-content: center; }',
        '.--64q5vxx--NYni0sXC7--wrapper { justify-content: center; }',
        '.--l64q5vxx--cN58i8l_G-text { padding-left: 25px; padding-right: 25px; padding-top: 10px; }',
        '.--l64q5vxx--cN58i8l_G-text div { font-size: 40px; line-height: 1.1; --line-height: 1.1; }',
        '.--l64q5vxx--2UoN1dYx4y--wrapper { margin-top: 0px; margin-left: 0px; margin-bottom: 0px; padding-top: 0px; }',
        '.--l64q5vxx--i4vnZUgeJc--flex-col-el { width: 60%; --width: 60%; }',
        '.--l64q5vxx--wMsrX3yble--flex-col-padding { padding-left: 0px; padding-right: 0px; padding-bottom: 0px; }',
        '.el-image.--l64q5vxx--2UoN1dYx4y--image { width: 100px; --width: 100px; }',
        '.--l64q5vxx--l4fIIuyaXK--flex-col > div { display: inherit; --display: inherit; }',
        '.--l64q5vxx--l4fIIuyaXK--flex-col-el { width: 35%; --width: 35%; }',
        '.--l64q5vxx--9oej4a7OPE--flex-col-el { width: 65%; --width: 65%; margin-top: 0px; }',
        '.--l64q5vxx--2UoN1dYx4y--container { justify-content: start; }',
        '.--l64q5vxx--W-kNb0qt_b-text div { font-size: 11px; line-height: 1.6; --line-height: 1.6; }',
        '.--l64q5vxx--W-kNb0qt_b-text { padding-top: 5px; padding-bottom: 7px; }',
        '.--l64q5vxx--d0eTjwmHdj-text div { font-size: 17px; }',
        '.--l64q5vxx--d0eTjwmHdj-margin { margin-top: 0px; }',
        '.--l64q5vxx--NC21lzrRtG--flex.flex-wrapper { padding-right: 10px; padding-left: 10px; padding-bottom: 0px; }',
        '.--l64q5vxx--t1YXMI155r--flex-col-el { margin-top: 0px; }',
        '.--l64q5vxx--NC21lzrRtG--container { margin-bottom: 0px; }',
        '.--l64q5vxx--t1YXMI155r--flex-col-padding { padding-left: 0px; padding-right: 0px; }',
        '.--64q5vxx--yfs6i6au1-text div { text-align: center; }',
        '.--l64q5vxx--5zGPMKck3i--1 { margin-bottom: 0px; }',
        '.--l64q5vxx--l4fIIuyaXK--flex-col { align-items: end; }',
        '.--l64q5vxx--eRBktU98Qr--flex-col-padding { padding-bottom: 0px; }',
        '.--64q5vxx--yfs6i6au1-text { padding-bottom: 0px; }',
        '.--l64q5vxx--SYCDdf-rgz--container { padding-top: 30px; }'
      ]
    ]

    this.defaultOptions = defaultOptions
  }
}
