<template>
  <div
    :class="`--${section.sid}--wrapper`"
    :data-uid="section.uid"
  >
    <highliter
      v-model="section.name"
      section-type="element"
      class="highlight-element"
      :dublicate-deep="parentUID"
      :dublicate="section"
      :data-sid="section.sid"
      @add-subject="$emit('add-subject')"
      @favorite="$emit('favorite', $el)"
      @flow="handleFlow"
      @move-to="to => $emit('move-to', to)"
      @dublicate="$emit('dublicate')"
      @delete="$emit('delete')"
      @click.stop.native="sectionSettings('compare')"
    >
      <div
        class="flex w-full"
        :data-sid="section.sid"
        :class="[`--${section.sid}--container`]"
      >
        <div
          class="el-compare-slider relative overflow-hidden flex w-full"
          :class="[`--${section.sid}--compare-slider`, {'vertical': vertical}, vertical ? 'cursor-row-resize' : 'cursor-col-resize']"
          @mousedown="startDrag"
          @touchstart="startDrag"
          @click="evt => drag(evt, true)"
        >
          <div 
            class="el-compare-slider-content absolute left-0 top-0 overflow-hidden bg-cover bg-center"
            :class="[vertical ? 'w-full h-1/2' : 'w-1/2 h-full']"
            :style="{...(vertical ? {height: progress} : {width: progress})}"
          >
            <img
              :src="section.options.content.compare.afterImageURL"
              alt="After Image"
              class="after-image absolute top-0 left-0 z-[2] object-cover"
              :class="[vertical ? 'h-unset w-full' : 'w-auto h-full']"
              draggable="false"
            >
          </div>
          
          <img
            :src="section.options.content.compare.beforeImageURL"
            alt="Before Image"
            class="before-image static top-0 left-0 max-w-full h-auto z-[1] object-cover"
            draggable="false"
          >
  
          <div
            class="el-compare-slider-handler absolute flex items-center justify-center top-0 left-0 z-[5]"
            :class="[vertical ? 'w-full' : 'h-full', `--${section.sid}--handler`]"
            :style="{...(vertical ? {top: progress, height: 'var(--handler-size)'} : {left: progress, width: 'var(--handler-size)'})}"
          >
            <div 
              class="absolute flex items-center justify-center"
              :class="`--${section.sid}--handler-button`"
            >
              <i :class="[section.options.content.compare.handleIcon, `--${section.sid}--handler-icon`]" />
            </div>
          </div>
        </div>
      </div>
    </highliter>
  </div>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import BorderMixin from '@/components/mixins/BorderMixin'
import CustomStylesMixin from '@/components/mixins/CustomStylesMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElCompareSliderV2',
  components: {Highliter},
  mixins: [
    SectionMixin,
    BorderMixin,
    CustomStylesMixin
  ],
  data () {
    return {
      isDragging: false,
      progress: '50%'
    }
  },
  computed: {
    vertical () {
      return this.section.options.content.compare.vertical
    }
  },
  methods: {
    startDrag (event) {
      this.isDragging = true
      if (event.type === 'mousedown') {
        document.addEventListener('mousemove', this.drag)
        document.addEventListener('mouseup', this.stopDrag)
      } else if (event.type === 'touchstart') {
        document.addEventListener('touchmove', this.drag, {passive: false})
        document.addEventListener('touchend', this.stopDrag)
      }
    },

    drag (event, force = false) {
      if (!this.isDragging && !force) return

      if (event.type === 'touchmove') {
        event.preventDefault() // Prevent page scrolling on mobile devices
      }

      const offset = this.$el.querySelector('.el-compare-slider').getBoundingClientRect()
      const smoothOnClickEls = [this.$el.querySelector('.el-compare-slider-content'), this.$el.querySelector('.el-compare-slider-handler')]

      if (force) {
        smoothOnClickEls.forEach(el => el.style.transition = '.25s ease-out')
      } else {
        smoothOnClickEls.forEach(el => el.style.transition = null)
      }

      let clientX, clientY

      if (event.type === 'mousemove' || event.type === 'mousedown') {
        clientX = event.pageX
        clientY = event.clientY
      } else if (event.type === 'touchmove' || event.type === 'touchstart') {
        clientX = event.touches[0].pageX
        clientY = event.touches[0].clientY
      }

      if (this.vertical) {
        const iTopWidth = ((clientY - offset.top) / offset.height) * 100

        if (iTopWidth < 0 || iTopWidth > 100) return

        this.progress = `${iTopWidth}%`
      } else {
        const iTopWidth = ((clientX - offset.left) / offset.width) * 100

        if (iTopWidth < 0 || iTopWidth > 100) return

        this.progress = `${iTopWidth}%`
      }
    },

    stopDrag () {
      this.isDragging = false
      document.removeEventListener('mousemove', this.drag)
      document.removeEventListener('mouseup', this.stopDrag)
      document.removeEventListener('touchmove', this.drag)
      document.removeEventListener('touchend', this.stopDrag)
    }
  }
}
</script>

<style lang="scss">
.h-unset {
  height: unset;
}

.el-compare-slider {
  img {
    user-select: none;
    max-width: none;
    width: 100%;
    top: 0;
    display: block;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}
</style>