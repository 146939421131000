<template>
  <b-row no-gutters>
    <b-col class="mr-1">
      <b-form-group label="Height">
        <b-input-group>
          <b-input
            v-model="config.ratio[resolution].h"
            style="height: 38px;"
            type="number"
          />
          <v-select
            v-model="typeH"
            :options="options"
            :searchable="false"
            class="custom-select custom-select-sm"
            style="max-width: 60px;"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group label="Width">
        <b-input-group>
          <b-input
            v-model="config.ratio[resolution].w"
            style="height: 38px;"
            type="number"
          />
          <v-select
            v-model="typeW"
            :options="options"
            :searchable="false"
            class="custom-select custom-select-sm"
            style="max-width: 60px;"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'ImageResolutionSettings',

  props: {
    config: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      options: ['px', '%', 'vw', 'vh']
    }
  },

  computed: {
    typeH: {
      set (val) {
        this.$set(this.config.ratio, 'typeH', val)
      },
      get () {
        return this.config.ratio.typeH || this.config.ratio.type
      }
    },
    typeW: {
      set (val) {
        this.$set(this.config.ratio, 'typeW', val)
      },
      get () {
        return this.config.ratio.typeW || this.config.ratio.type
      }
    },
    ...mapState('editor', {
      resolution: state => state.resolution
    })
  }
}
</script>
