
const state = () => ({
  folders: [],
  files: {},
  filters:{
    q:'',
    sorting:'updated_at'
  },
  sortingOptions:[
    {id:'updated_at', order_by:'DESC',name:'Most Recent'},
    {id:'original_name', order_by:'ASC',name:'Titles A > Z'},
    {id:'is_favorite', order_by:'ASC',name:'My Saved Images'},
    {id:'size', order_by:'ASC',name:'Size'}
  ],
  activeFolder: null,
  activeFile: null,
  movedFile: null,
  totals:{}
})

const getters = {
  folders:(state,rootGetter,rootState)=>{
    let data = state.folders
    if(state.activeFolder){
      data = state.activeFolder.items
      if(state.activeFolder.protected && state.activeFolder.type == 'image'){
        if(!data.find(d => d.id == -999)){
          data.unshift({
            'id': -999,
            'name': 'My Saved Images',
            'type': 'image',
            'parent_id': state.activeFolder.id,
            'created_at': '2022-10-27T00:08:56.000000Z',
            'updated_at': '2022-10-27T15:08:56.000000Z',
            'media_count': (rootState.editor.globalStyles.libraryImages||[]).length,
            'is_my_saved_images':true,
            'items': []
          })
        }
      }
    }
    return data
  },
  breadcrumbs:(state)=>{
    let data = [
      {
        title:'Assets Home',
        id:null
      }
    ]
    if(state.activeFolder){
      const list = generateBreadcrumbs(state.folders,state.activeFolder)
      data = [...data,...list]
    }
    return data
  }
}

const actions = {
  async getTotals ({commit}, params = {}) {
    params.sort_by = 'order'
    await axios.get('api/media/total', {params})
      .then(({data}) => {
        commit('SET_TOTAL', data.data)
      })
  },
  async getFolders ({commit}, params = {}) {
    params.sort_by = 'order'
    await axios.get('api/media-folders', {params})
      .then(({data}) => {
        commit('SET_FOLDERS', data.data)
      })
  },
  async getFiles ({commit}, params = {}){
    const state = this.state.assetsLibrary

    if(state.activeFolder && state.activeFolder.is_my_saved_images) {
      return commit('SET_FILES',{})
    }

    const search = state.filters.q
    const sorting = state.filters.sorting
    const sort = state.sortingOptions.find(s=> s.id == sorting)

    if(sort){
      params.sort_by = sort.id
      params.order_by = sort.order_by
    }
    if(!state.activeFolder && !search){
      return commit('SET_FILES',{})
    }

    if(state.activeFolder) params.folder_id = state.activeFolder.id
    if(search) params.search = search

    await axios.get('api/media', {params})
      .then(({data}) => {
        commit('SET_FILES',data.data)
      })
  },
  async createFolder ({dispatch}, data = {}) {
    return await axios.post('api/media-folders',data)
      .then(async ({data}) => {
        await new Promise((resolve) => setTimeout(()=>{
          dispatch('getFolders')
            .then(() => {
              resolve()
            })
        }, 100))
        // commit('INSERT_FOLDER',data.data)
        return data.data
      })
  },
  async createFile ({dispatch}, data = {}) {
    if(data.get('new_folder_name')){
      const folder = await dispatch('createFolder',{
        name:data.get('new_folder_name'),
        parent_id:data.get('new_folder_parent')
      })

      if(!data.get('folder_id')){
        data.set('folder_id',folder.id)
      }
    }

    return await axios.post('api/media', data)
  },
  async saveFile (_, params = {}) {
    await axios.post('api/media/save-media', params)
  },
  async updateFile ({commit}, params = {}) {
    await axios.put('api/media/'+params.id, params)
      .then(({data}) => {
        if(params.action == 'move'){
          commit('MOVE_FILE',data.data.id)
        }else{
          commit('UPDATE_FILE',data.data)
        }
      })
  },
  async moveFileToFolder ({commit}, params = {}) {
    commit('MOVE_FILE',params.id)
    await axios.put('api/media/'+params.id, params)
      .catch(() => {
        commit('UNDO_MOVED_FILE',params.id)
      })
  },
  async deleteFile ({commit}, params = {}) {
    await axios.delete('api/media/'+params.id)
      .then(() => {
        commit('DELETE_FILE',params.id)
      })
  },

  async updateFolder ({commit,dispatch}, params = {}) {
    await axios.put('api/media-folders/'+params.id, params)
      .then(async ({data}) => {
        if(params.action == 'move'){
          await dispatch('getFolders')
        }else{
          commit('UPDATE_FOLDER',data.data)
        }
      })
  },

  async reorderFolder (_, data = {}) {
    await axios.post('api/media-folders/reorder', data)
  },

  async deleteFolder ({dispatch}, params = {}) {
    await axios.delete('api/media-folders/'+params.id,{params})
      .then(async () => {
        const state = this.state.assetsLibrary
        await dispatch('getFolders')
        if(!params.is_delete_items && state.activeFolder.protected){
          await dispatch('getFiles')
        }
      })
  }
}

const mutations = {
  SET_TOTAL (state, payload) {
    state.totals = payload
  },
  SET_FOLDERS (state, payload) {
    state.folders = payload
    if(state.activeFolder) {
      state.activeFolder = findFolderById(state.folders, state.activeFolder.id)
    }
  },
  SET_ACTIVE_FOLDER (state, payload) {
    state.activeFolder = payload
  },
  SET_FILES (state, payload) {
    let result = payload
    if (payload.current_page && payload.current_page != 1) {
      payload.data = [...state.files.data, ...payload.data]
      result = payload
    }
    state.files = result
  },
  INSERT_FILE (state, payload) {
    state.totals.total_size += payload.size
    if(state.activeFolder && state.activeFolder.id == payload.folder_id){
      state.files.data.unshift(payload)
    }
  },
  UPDATE_FILE (state, payload) {
    state.files.data.forEach(f => {
      if(f.id === payload.id){
        f.original_name = payload.original_name
        f.alt = payload.alt
        f.no_index = payload.no_index
      }
    })
  },
  DELETE_FILE (state, id) {
    state.files.data = state.files.data.filter(f=> {
      if(f.id == id){
        state.totals.total_size -= f.size
      }
      return f.id != id
    })
    if(state.activeFile && state.activeFile.id == id) state.activeFile = null
  },
  MOVE_FILE (state, id) {
    const index = state.files.data.findIndex(f=> f.id == id)
    if(index != -1){
      const file = state.files.data[index]
      state.movedFile = {
        ...file,
        index
      }
      state.files.data.splice(index, 1)
    }
    if(state.activeFile && state.activeFile.id == id) state.activeFile = null
  },
  UNDO_MOVED_FILE (state, id) {
    if(state.movedFile && state.movedFile.id == id){
      state.files.data.splice(state.movedFile.index, 0, state.movedFile)
    }
  },
  INSERT_FOLDER (/* state, payload */) {
    // [TEMP]
    // "protected": 1,
    // "order": 1,

    // "media_count": 0,
    // "items": []
    // const w = insertFolder(state.folders, state.activeFolder.id)
    // if(state.activeFolder){
    //   state.files.data.unshift(payload)
    // }else{

    // }
  },
  UPDATE_FOLDER (state, payload) {
    updateFolderById(state.folders,payload.id,(f)=>{
      console.log('f :>> ', f)
    })
    // state.files.data.forEach(f => {
    //   if(f.id == payload.id){
    //     f.original_name = payload.original_name
    //   }
    // })
  },
  SET_ACTIVE_FILE (state, payload) {
    state.activeFile = payload
  },
  SET_SORTING (state, payload) {
    state.filters.sorting = payload
  },
  SET_SEARCH_QUERY (state, payload) {
    state.filters.q = payload
  }
}

const generateBreadcrumbs = (folders,folder)=>{
  let data = [{
    id:folder.id,
    title:folder.name,
    folder
  }]

  if(folder.parent_id){
    const f = findFolderById(folders, folder.parent_id)
    data = [...generateBreadcrumbs(folders,f),...data]
  }
  return data
}

const findFolderById = (folders,id)=>{
  let folder = folders.find(f=> f.id == id)
  if(!folder){
    for (const f of folders) {
      folder = findFolderById(f.items,id)
      if(folder) return folder
    }
  }
  return folder
}
const updateFolderById = (folders,id,fn)=>{
  let folder = folders.find(f=> f.id == id)
  if(!folder){
    folders.forEach(f => {
      folder = findFolderById(f.items,id)
      if(folder){
        fn(folder)
        return folder
      }
    })
  }
  return folder
}
// const insertFolder = (folders,id,newFolder)=>{
//   let folder = folders.find(f=> f.id == id)
//   if(!folder){
//     for (const f of folders) {
//       return  insertFolder(f.items,id,newFolder)
//     }
//   }else{
//     folders.items.push(newFolder)
//     return folders
//   }
// }

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
