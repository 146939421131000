<template>
  <div>
    <b-form-group label="Bounding">
      <v-select
        v-model="selectedMotionScrollBoundaryMutation"
        :options="filteredOptions"
        :searchable="false"
        :reduce="val => val.value"
        placeholder="Select boundary"
      />
    </b-form-group>

    <b-form-group>
      <img
        v-show="selectedMotionScrollBoundaryMutation === 0"
        class="img-fluid"
        src="https://www.dropbox.com/s/zqbprwdky8zlos3/Frame_4.gif?raw=1"
        alt="Scroll animation sample"
      >
      <img
        v-show="selectedMotionScrollBoundaryMutation === 1"
        class="img-fluid"
        src="https://www.dropbox.com/s/kco5nha9xqb4cs2/Frame_4%20%281%29.gif?raw=1"
        alt="Scroll animation sample"
      >
      <img
        v-show="selectedMotionScrollBoundaryMutation === 2"
        class="img-fluid"
        src="https://www.dropbox.com/s/icpcwvksao8e53l/Frame_4%20%283%29.gif?raw=1"
        alt="Scroll animation sample"
      >
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'MotionScrollBounding',
  props: {
    motion: {
      type: Object,
      required: true
    }
  },
  computed: {
    selectedMotionScrollBoundaryMutation: {
      get () {
        return this.motion.scroll.bounding || 0
      },
      set (val) {
        this.$set(this.motion.scroll, 'bounding', val)
      }
    },
    filteredOptions () {
      return [
        {
          label: 'Full Height',
          value: 0
        },
        {
          label: 'Bottom',
          value: 1
        },
        {
          label: 'Top',
          value: 2
        }
      ]
    }
  }
}
</script>
