import SectionProvider from '@builder/sections/SectionProvider'
import { defaultOptions } from './ElPostsGrid'

export const name = 'Post Grid'
export const group = 'Dynamic Post Template'
export const beta = false

export const icon = `
`

export default class ElPostGrid extends SectionProvider {
  constructor (options = {}) {
    super('ElPostGrid', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
