<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    :uid="section.uid"
    class="el-dynamic"
    section-type="element"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('data')"
  >
    <div :data-sid="section.sid">
      <div
        class="post-featured-thumb d-flex"
        :class="className()"
      >
        <img
          src="https://res.cloudinary.com/estage/image/upload/v1692191348/users/user-3/fe0cec0aebff9e6f4e6a104ff985d1ce.png"
          class="img object-cover"
        >
      </div>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'

export default {
  name: 'ElBlogCategories',
  components: {Highliter},
  mixins: [SectionMixin],
  methods: {
    className (name = '') {
      return `--post-featured-thumb-${this.section.sid}${name}`
    }
  }
}
</script>

<style lang="scss">
.post-featured-thumb {
  .img {
    --height: 400px;
    --width: 100%;

    height: var(--height);
    width: var(--width)
  }
}
</style>