import Vue from 'vue'
import resizable from '@/directives/resizable'

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        el.removeEventListener('scroll', f)
      }
    }
    el.addEventListener('scroll', f)
  }
})


Vue.directive('resizable', resizable)

const updateHScroll = (el,binding)=>{
  setTimeout(() => {
    if(el.scrollHeight > el.clientHeight){
      el.classList.add(binding.value)
    }else{
      el.classList.remove(binding.value)
    }
  }, 200)
}

const updateLoading = (el,binding)=>{
  const indicator = `
        <span data-spinner class="absolute bg-white spinner-border spinner-border-sm text-primary">
        </span>
      `
  const indicatorFound = el.querySelector('span[data-spinner]')

  if (binding.value) {
    el.classList.add('relative','pointer-events-none','overflow-hidden')
    el.setAttribute('data-kt-indicator', 'on')
    if (!indicatorFound) el.insertAdjacentHTML('beforeend', indicator)
  } else {
    el.classList.remove('relative','pointer-events-none','overflow-hidden')
    if (indicatorFound) indicatorFound.remove()
  }

}

Vue.directive('h-scroll-class', {
  bind: function (el ,binding) {
    updateHScroll(el,binding)
  },
  inserted: function (el ,binding) {
    updateHScroll(el,binding)
  },
  update: function (el ,binding) {
    updateHScroll(el,binding)
  }
})
Vue.directive('loading', {
  bind: function (el ,binding) {
    updateLoading(el,binding)
  },
  inserted: function (el ,binding) {
    updateLoading(el,binding)
  },
  update: function (el ,binding) {
    updateLoading(el,binding)
  }
})