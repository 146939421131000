<template>
  <v-select
    v-model="model"
    v-bind="$attrs"
    :options="paginated"
    label="name"
    :filterable="false"
    @open="onOpen"
    @close="onClose"
    @search="(query) => (search = query)"
  >
    <template v-slot:selected-option>
      {{ name }}
    </template>

    <template #list-footer>
      <li
        v-show="hasNextPage"
        ref="load"
        class="loader"
      >
        Loading more options...
      </li>
    </template>
  </v-select>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CountrySelect',
  props: {
    value: {
      required: true
    }
  },
  data: () => ({
    observer: null,
    limit: 10,
    search: '',
    countries: []
  }),
  computed: {
    name () {
      return _.get(_.find(this.countries, ['id', this.model]), 'name', '')
    },
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val.id)
      }
    },
    filtered () {
      return this.countries.filter((country) => country.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
    paginated () {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage () {
      return this.paginated.length < this.filtered.length
    }
  },
  mounted () {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll)

    axios.get('api/user/profile/countries')
      .then(({data}) => {
        this.countries = data.data
      })
  },
  methods: {
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    async infiniteScroll ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    }
  }
}
</script>