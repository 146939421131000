<template>
  <div>
    <SidbearSettingsGroup
      active
      title="Form"
    >
      <form-fields :form="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      content-class="pb-0"
      group
      title="Button"
    >
      <button-mod
        v-if="config.subject"
        :config="config.subject.options.content.button"
        :local-section="config.subject"
        :hide="['align']"
        component-name="Button"
        group-prefix="Button"
      >
        <template slot="actions">
          <actions-after-submit v-model="config.form.actions" />

          <div>
            <div
              v-for="(action, index) in config.form.actions"
              :key="index"
            >
              <div
                :class="{'bg-[#F9F9F9]': active === index + 4}"
                class="border mt-[-1px] d-flex align-items-center justify-content-between cursor-pinter hover:bg-[#F9F9F9] h-[60px] px-[23px] font-[700] text-[#44474A]"
                @click="active === index + 4 ? active = null : active = index + 4"
              >
                {{ action.name }}

                <svg
                  :class="{'transform rotate-180': active === index + 4}"
                  fill="none"
                  height="7"
                  viewBox="0 0 10 7"
                  width="10"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.719727 1.00006L4.85989 5.10817L9.00005 1.00006"
                    stroke="#6E747A"
                    stroke-width="1.5"
                  />
                </svg>
              </div>

              <div
                v-if="active === index + 4"
                class="pt-[16px]"
              >
                <component
                  :is="camelCase(action.name)"
                  :config="config"
                  :index="active"
                  :service="action"
                  class="pb-3"
                  @removeAction="removeAction(index)"
                  @deleteResponder="deleteResponder"
                />
              </div>
            </div>
          </div>
        </template>
      </button-mod>
      <form-button
        v-else
        :config="config"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Form Styles">
      <form-style :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="GDPR Compliance">
      <form-g-d-p-r :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <padding-full-customs :config="padding" />
      <margin-full-customs :config="margin" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ThemeMixin from '@builder/components/mixins/ThemeMixin'
import FormControlsList from '../components/form/FormControlsList'
import FormFields from '../components/form/FormFields'
import FormButton from '../components/form/FormButton'
import PropertiesManager from '../../mixins/PropertiesManager'
import ActionsAfterSubmit from '../components/form/ActionsAfterSubmit'
import Panel from '../controls/Panel'
import TextMixins from '../../mixins/TextMixins'
import FormGDPR from '../components/form/FormGDPR'
import ActionModal from '../../builder/utils/auto-responders/ActionModal'
import FormStyle from '../components/form/FormStyle'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import ButtonMod from '@/components/editor/mods/ButtonMod'

export default {
  name: 'FormMod',
  components: {
    ButtonMod,
    SidbearSettingsGroup,
    FormStyle, ActionModal, FormGDPR, Panel, ActionsAfterSubmit, FormButton, FormFields, FormControlsList
  },
  mixins: [ModMixin, ThemeMixin, PropertiesManager, TextMixins],

  data () {
    return {
      active: 0
    }
  },

  computed: {
    validator () {
      return this.$store.state.validation.validator || this.$validator
    },
    padding () {
      if (!this.config.padding) {
        this.$set(this.config, 'padding', {})
      }

      return this.config.padding
    },
    margin () {
      if (!this.config.margin) {
        this.$set(this.config, 'margin', {})
      }

      return this.config.margin
    },
    uid () {
      return this.section.uid
    }
  },

  mounted () {
    VEvent.listen('page.save', this.saveForm)
  },

  beforeDestroy () {
    this.saveForm()
    VEvent.off('page.save', this.saveForm)
  },

  methods: {
    deleteResponder (service) {
      for (let c in this.config.controls) {
        this.$set(this.config.controls[c], 'mapping', this.config.controls[c].mapping.filter(con => con.auto_responder_id !== service.id))
      }

      this.saveForm()
    },
    removeAction (index){
      this.config.form.actions.splice(index, 1)
    },
    saveForm () {
      this.validator.validateAll().then((result) => {
        if (result) {
          this.$store.dispatch('forms/saveForm', this.config)
            .finally(() => {
              // this.$bvToast.toast('Form settings have been updated', {
              //   title: 'Form',
              //   autoHideDelay: 2000,
              //   appendToast: false,
              //   variant: 'white',
              //   solid: true
              // })
            })
          this.$store.commit('validation/SET_VALIDATOR', {
            validator: null
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@builder/assets/scss/_variables.scss";

.hover-soft-primary-bg {
  &.active {
    border-bottom: 1px solid #eee;
  }

  > .fa {
    width: 15px;
    height: 15px;
  }
}

.panel-space-top {
  margin-top: 33px;
}

.panel-settings {
  bottom: 0;
}
</style>
