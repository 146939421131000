<template>
  <div :class="[style]">
    <slot
      name="field"
      :$v="$v"
    />
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import { required, email, minLength, maxLength, ipAddress, url, numeric, integer, decimal } from 'vuelidate/lib/validators'
import _ from 'lodash'
import {mapGetters, mapState} from 'vuex'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin],

  data () {
    return {
      field: {
        state: null,
        value: ''
      },
      validators: {
        required,
        email,
        ipAddress,
        url,
        numeric,
        integer,
        decimal,
        minLength,
        maxLength
      }
    }
  },

  computed: {
    ...mapGetters('forms', [
      'getAvaliableForms'
    ]),
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    style () {
      const btnShape = this.relatedButton ? this.relatedButton.options.content.button.resolutionStyle.lg.shape : null
      return this.section.options.content.input.style
        ? `form-control-${this.section.options.content.input.style}`
        : btnShape ? `form-control-${btnShape}` : `form-control-${this.globalStyles.buttons.shape}`
    },
    activeValidators () {
      const validators = {}
      for (let key in this.section.options.content.input.validators) {
        const val = this.section.options.content.input.validators[key]
        validators[key] = typeof val === 'boolean' ? this.validators[key] : this.validators[key](val)
      }
      return validators
    },
    relatedButton () {
      return this.section.options.content.input.relatedUID
        ? _.head(this.getAvaliableForms.filter(button => button.options.content.button.buttonUID === this.section.options.content.input.relatedUID))
        : null
    }
  },

  validations () {
    return {
      field: {
        value: this.activeValidators
      }
    }
  }
}
</script>
