<template>
  <b-form-group>
    <div class="flex justify-between items-center">
      <label
        v-if="title"
        class="pb-0"
        v-text="title"
      />

      <div
        class="flex items-center hover:bg-gray-100 justify-center text-dark text-[11px] font-[600] h-[29px] w-[114px] rounded-[4px] border gap-[11px] cursor-pointer"
        :class="{'w-100': !title}"
        @click="() => modal = true"
      >
        Upload Image

        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00016 1.33335C5.38933 1.33335 4.0835 2.63919 4.0835 4.25002C4.0835 4.57219 3.82233 4.83335 3.50016 4.83335C2.45806 4.83335 1.75016 5.557 1.75016 6.29169C1.75016 7.02637 2.45806 7.75002 3.50016 7.75002C3.82233 7.75002 4.0835 8.01119 4.0835 8.33335C4.0835 8.65552 3.82233 8.91669 3.50016 8.91669C1.96494 8.91669 0.583496 7.81217 0.583496 6.29169C0.583496 4.95522 1.65079 3.94013 2.9517 3.714C3.21418 1.71215 4.92669 0.166687 7.00016 0.166687C9.07364 0.166687 10.7861 1.71215 11.0486 3.714C12.3495 3.94013 13.4168 4.95522 13.4168 6.29169C13.4168 7.81217 12.0354 8.91669 10.5002 8.91669C10.178 8.91669 9.91683 8.65552 9.91683 8.33335C9.91683 8.01119 10.178 7.75002 10.5002 7.75002C11.5423 7.75002 12.2502 7.02637 12.2502 6.29169C12.2502 5.557 11.5423 4.83335 10.5002 4.83335C10.178 4.83335 9.91683 4.57219 9.91683 4.25002C9.91683 2.63919 8.61099 1.33335 7.00016 1.33335Z"
            fill="#3D82EA"
          />
          <path
            d="M9.16264 6.99583C8.93484 7.22364 8.56549 7.22364 8.33768 6.99583L7.5835 6.24165V11.25C7.5835 11.5722 7.32233 11.8334 7.00016 11.8334C6.678 11.8334 6.41683 11.5722 6.41683 11.25V6.24164L5.66264 6.99583C5.43484 7.22364 5.06549 7.22364 4.83768 6.99583C4.60988 6.76803 4.60988 6.39868 4.83768 6.17088L6.58768 4.42088C6.69708 4.31148 6.84545 4.25002 7.00016 4.25002C7.15487 4.25002 7.30324 4.31148 7.41264 4.42088L9.16264 6.17088C9.39045 6.39868 9.39045 6.76803 9.16264 6.99583Z"
            fill="#3D82EA"
          />
        </svg>
      </div>
    </div>

    <div
      v-if="image.id"
      class="flex items-center h-[29px] rounded-[4px] border mt-2 pl-[4px]"
    >
      <div
        class="w-[22px] h-[22px] border rounded-[3px] bg-contain bg-no-repeat"
        :style="{backgroundImage: image.url}"
      />

      <div
        v-if="!inFavorites"
        v-b-tooltip
        class="flex items-center justify-center h-full w-[34px] border-l cursor-pointer hover:bg-gray-100 ml-auto"
        title="Save this image"
        @click="save"
      >
        <i class="fa fa-save" />
      </div>

      <div
        v-else
        v-b-tooltip
        class="flex items-center justify-center h-full w-[34px] border-l cursor-pointer hover:bg-gray-100 ml-auto"
        title="View Saved Images"
        @click="removeSaved"
      >
        <i class="fa fa-save text-primary" />
      </div>

      <div
        class="flex items-center justify-center h-full w-[34px] border-l cursor-pointer hover:bg-gray-100"
        @click="remove"
      >
        <i class="fa fa-trash" />
      </div>
    </div>

    <assets-lib-modal
      :modal.sync="modal"
      :file="image"
      type="jpg|png|svg|gif|jpeg|blob"
      :uploaded="uploaded"
    />
  </b-form-group>
</template>

<script>
import AssetsLibModal from '@/components/assets-library/AssetsLibModal'
import SidConcept from '@/components/mixins/SidConcept'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'SidUploadImage',
  components: {AssetsLibModal},
  mixins: [ SidConcept ],

  props: {
    title: {
      type: String,
      default: 'Background Image'
    }
  },

  data () {
    return {
      modal: false,
      image: {}
    }
  },

  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles,
      section: state => state.section
    }),

    ...mapGetters({
      folders: 'assetsLibrary/folders'
    }),

    inFavorites () {
      return (this.globalStyles.libraryImages || []).includes(this.imagePath)
    },

    imagePath () {
      return (this.image.url || '').replace(/^url\(['"]?|['"]?\)$/g, '')
    }
  },

  watch: {
    sid () {
      this.prepareOptions()
    }
  },

  created () {
    this.prepareOptions()
  },

  methods: {
    ...mapMutations({
      setActiveFolder: 'assetsLibrary/SET_ACTIVE_FOLDER',
      setActiveFile: 'assetsLibrary/SET_ACTIVE_FILE'
    }),

    prepareOptions () {
      this.image = {}
      if (this.getSidStyle(this.getSid(), this.ruleName) === 'none') return
      const src = this.section.component === 'ElImageV4' ? this.section.options.content.image?.src : null

      this.image = {
        id: this.getSidStyle(this.getSid(), '--bg-id') || 'rand',
        url: src ? `url(${src})` : this.getSidStyle(this.getSid(), this.ruleName)
      }
    },

    save () {
      !Object.prototype.hasOwnProperty.call(this.globalStyles, 'libraryImages')
        ? this.$set(this.globalStyles, 'libraryImages', [])
        : null

      this.globalStyles.libraryImages.push(
        this.section.component === 'ElImageV4' ? this.section.options.content.image.src : this.imagePath
      )

      VEvent.fire('loader', true)
      this.$store.dispatch('projects/save', true).then(async () => {
        VEvent.fire('loader', false)

        this.$swal({
          icon: 'success',
          iconColor: '#4F83E3',
          toast: true,
          position: 'top-right',
          title: 'Saved in Assets Library.',
          showConfirmButton: false,
          timer: 3000
        })
      })
    },

    removeSaved () {
      this.setActiveFolder(null)
      this.setActiveFile(null)

      this.setActiveFolder({
        'id': -999,
        'name': 'My Saved Images',
        'type': 'image',
        'parent_id': this.folders[0].id,
        'created_at': '2022-10-27T00:08:56.000000Z',
        'updated_at': '2022-10-27T15:08:56.000000Z',
        'media_count': (this.$store.state.editor.globalStyles.libraryImages || []).length,
        'is_my_saved_images': true,
        'items': []
      })

      this.modal = true
    },

    remove () {
      if (this.section.component === 'ElImageV4') {
        this.section.options.content.image.noIndex = null
        this.section.options.content.image.src = null
      } else {
        this.addSidRules([[ this.getSid(), [[ this.ruleName, '' ]]]])
        this.addSidRules([[ this.getSid(), [[ '--bg-id', '' ]]]])
      }

      this.image = {
        id: null,
        url: ''
      }
    },

    uploaded (file) {
      if (!this.section.options.content.image) {
        this.section.options.content.image = file
      }

      if (this.section.component === 'ElImageV4') {
        this.section.options.content.image.noIndex = file.no_index ? '1' : '0'
        this.section.options.content.image.src = file.src
      } else {
        this.addSidRules([[this.getSid(), [[this.ruleName, `url(${file.url})`]]]])
      }

      this.section.options.content.image.alt = file.alt
      this.addSidRules([[this.getSid(), [['--bg-id', file.id]]]])

      this.image = {
        id: file.id || 'rand',
        url: `url(${ file.url })`
      }
    }
  }
}
</script>
