<template>
  <div>
    <b-form-group>
      <b-checkbox v-model="enabled">
        Sticky on Scroll
      </b-checkbox>
    </b-form-group>

    <div v-if="enabled">
      <b-form-group label="Sticky Size">
        <v-select
          v-model="size"
          :options="sizeOptions"
          :reduce="option => option.key"
          :searchable="false"
          append-to-body
          class="custom-select"
        />
      </b-form-group>

      <b-form-group label="Sticky Position">
        <v-select
          v-model="position"
          :options="positionOptions"
          :reduce="option => option.key"
          :searchable="false"
          append-to-body
          class="custom-select"
        />
      </b-form-group>

      <b-form-group label="Sticky Style">
        <v-select
          v-model="style"
          :options="styleOptions"
          :reduce="option => option.key"
          :searchable="false"
          append-to-body
          class="custom-select"
        />
      </b-form-group>

      <b-form-group>
        <div class="flex items-center justify-between">
          <label
            class="mb-0 pb-0"
            for="required-mark2"
          >Closable</label>

          <b-checkbox
            id="required-mark2"
            v-model="closable"
            switch
            value="1"
          />
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ModControlMixin from '@/components/mixins/ModControlMixin'

export default {
  name: 'VideoSticky',
  mixins: [ModControlMixin],
  data () {
    return {
      sizeOptions: [
        {
          key: 0,
          label: 'Small'
        },
        {
          key: 1,
          label: 'Medium'
        },
        {
          key: 2,
          label: 'Large'
        }
      ],
      positionOptions: [
        {
          key: 0,
          label: 'Top Left'
        },
        {
          key: 1,
          label: 'Top Right'
        },
        {
          key: 2,
          label: 'Bottom Left'
        },
        {
          key: 3,
          label: 'Bottom Right'
        }
      ],
      styleOptions: [
        {
          key: 0,
          label: 'Black with Drop Shadow'
        },
        {
          key: 1,
          label: 'White with Drop Shadow'
        }
      ]
    }
  },
  computed: {
    enabled: {
      get () {
        return _.get(this.config, 'sticky.enabled', false)
      },
      set (val) {
        this.$set(this.config, 'sticky', {..._.set(this.config.sticky || {}, 'enabled', val)})
      }
    },
    size: {
      get () {
        return _.get(this.config, 'sticky.size', 0)
      },
      set (val) {
        this.$set(this.config, 'sticky', {..._.set(this.config.sticky || {}, 'size', val)})
      }
    },
    position: {
      get () {
        return _.get(this.config, 'sticky.position', 0)
      },
      set (val) {
        this.$set(this.config, 'sticky', {..._.set(this.config.sticky || {}, 'position', val)})
      }
    },
    style: {
      get () {
        return _.get(this.config, 'sticky.style', 0)
      },
      set (val) {
        this.$set(this.config, 'sticky', {..._.set(this.config.sticky || {}, 'style', val)})
      }
    },
    closable: {
      get () {
        return _.get(this.config, 'sticky.closable', true)
      },
      set (val) {
        this.$set(this.config, 'sticky', {..._.set(this.config.sticky || {}, 'closable', val)})
      }
    }
  }
}
</script>