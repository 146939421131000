<template>
  <div>
    <SidbearSettingsGroup
      active
      title="Progress Bar Settings"
    >
      <b-form-group v-if="linked">
        <button
          class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
          @click="() => unlinkSid()"
        >
          Unlink Styles
          <i class="fa fa-link text-primary" />
        </button>
      </b-form-group>

      <sid-background
        :sid="`.--${section.sid}--progress`"
      />

      <sid-background
        label="Bar Color"
        :sid="`.--${section.sid}--bar`"
      />

      <b-form-group
        label="Progress"
        label-class="!pb-[3px]"
      >
        <b-row
          class="align-items-center"
          no-gutters
        >
          <b-col
            class="pr-2"
            cols="9"
          >
            <vue-slider
              v-model="config.value"
              :dot-size="17"
              :max="parseInt(config.max)"
              :min="0"
              class="editor-slider-1 px-2"
              tooltip="none"
            />
          </b-col>

          <b-col cols="3">
            <b-input
              v-model="config.value"
              :max="100"
              :min="0"
              type="number"
            />
          </b-col>
        </b-row>
      </b-form-group>

      <sid-width-and-height
        :sid="`.--${section.sid}--progress`"
      />

      <b-form-group>
        <b-checkbox v-model="config.animated">
          Animated
        </b-checkbox>
      </b-form-group>

      <b-form-group label="Text">
        <b-input
          v-model="config.text"
        />
      </b-form-group>

      <sid-font
        :sid="`.--${section.sid}--bar`"
        rule-name="font"
      />

      <sid-range-slider
        label="Font Size"
        rule-name="font-size"
        :sid="`.--${section.sid}--bar`"
      />

      <sid-range-slider
        label="Letter Spacing"
        rule-name="letter-spacing"
        :sid="`.--${section.sid}--bar`"
      />

      <sid-align
        :sid="`.--${section.sid}--text`"
        rule-name="justify-content"
        label="Text Alignment"
      />

      <sid-range-slider
        label="Text Gap"
        rule-name="padding"
        :min="0"
        :sid="`.--${section.sid}--text`"
        default-dimension="px"
        :dimensions="['px']"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Decoration</label>

          <sid-font-style
            :sid="`.--${section.sid}--bar`"
            rule-name="font"
          />
        </div>
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Border & Radius">
      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--progress`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--progress`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--progress`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid}--progress`"
        rule-name="border"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Drop Shadow">
      <b-form-group
        label="Shadow Color"
        class="mb-0"
      >
        <sid-box-shadow
          :sid="`.--${section.sid}--progress`"
          rule-name="box-shadow"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Padding & Margins">
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--wrapper`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidWidthAndHeight from '@/components/editor/components/sid-controls/sid-control-pressets/SidWidthAndHeight'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidFontStyle from '@/components/editor/components/sid-controls/SidFontStyle'
import SidAlign from '@/components/editor/components/sid-controls/SidAlign'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidBoxShadow from '@/components/editor/components/sid-controls/sid-control-pressets/box-shadow/SidBoxShadow'
import SidStyles from '@/components/mixins/SidStyles'

export default {
  name: 'ProgressbarModV2',
  components: {
    SidBoxShadow,
    SidColor,
    SidSelect,
    SidLinkedGroup,
    SidAlign, SidFontStyle, SidRangeSlider, SidFont, SidBackground, SidWidthAndHeight, SidbearSettingsGroup},
  mixins: [ModMixin, SidStyles]
}
</script>