import Vue from 'vue'
import VeeValidate from 'vee-validate'
import { Validator  } from 'vee-validate'

Vue.use(VeeValidate, {fieldsBagName: 'veeFields'})

Validator.extend('domain', {
  getMessage: () => 'Domain is not valid.',
  validate: value => /^(?:(?:(?:[a-zA-z-]+):\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?::[0-9]{1,5})?$/.test(value)
})
