<template>
  <div>
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin],
  mounted () {
    this.$store.dispatch('forms/addFormField', this.section)
  },
  destroyed () {
    this.$store.dispatch('forms/removeFormField', this.section)
  }
}
</script>
