<template>
  <a
    v-if="settings.ad_banner.enabled"
    :href="settings.ad_banner.href"
    target="_blank"
    style="height: 20vh;"
    class="max-w-lg d-flex position-relative justify-content-center mx-auto lg:max-w-none my-20"
  >
    <div
      class="position-absolute w-100 h-100 bg-cover rounded"
      :style="{backgroundRepeat: 'no-repeat', backgroundImage: `url(${settings.ad_banner.image.src || settings.ad_banner.image.placeholder})`}"
    />
  </a>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Ad',
  computed: {
    ...mapState({
      settings: state => state.blogSettings.settings
    })
  }
}
</script>
