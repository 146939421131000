import {mapState} from 'vuex'

export default {
  data () {
    return {
      loadedPages: [],
      pageIsLoading: false,
      currentPaginationPage: 1,
      searchedPages: [],
      searching: false,
      search: '',
      scrollTop: 0,
      $menu: null,
      total: 0,
      paginationSelectedPage: 0
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('pages', {
      paginationPages: state => state.pages,
      currentPage: state => state.page
    })
  },
  methods: {
    async loadPages () {
      this.pageIsLoading = true
      const pages = await axios.get(`api/projects/${this.project.id}/pages?perPage=25&page=${this.currentPaginationPage}&q=${this.search}`)
      this.loadedPages = [...this.loadedPages, ...pages.data.data.data]
      this.total = pages.data.data.total
      this.currentPaginationPage++
      setTimeout(() => {
        document.querySelector('.vs__dropdown-menu').addEventListener('scroll', this.scrollLoadingPages)
      })
      this.pageIsLoading = false
    },
    async onOpen () {
      this.loadPages()
    },
    onClose () {
      this.searching = false
      this.search = ''
      this.loadedPages = []
      this.currentPaginationPage = 1
      document.querySelector('.vs__dropdown-menu').removeEventListener('scroll', this.scrollLoadingPages)
      this.loadPageState()
    },
    async onSearch (search) {
      this.loadedPages = []
      this.currentPaginationPage = 1
      this.search = search
      this.loadPages()
    },
    scrollLoadingPages (event) {
      const $el = event.target
      const scrollHeight = parseInt($el.scrollHeight - $el.clientHeight)
      if (parseInt($el.scrollTop) === scrollHeight && (this.total > this.loadedPages.length)) {
        this.$menu = event.target
        this.scrollTop = event.target.scrollTop
        this.loadPages()
      }
    },
    loadPageState () {
      if (this.paginationSelectedPage) {
        this.pageIsLoading = true
        axios.get(`api/projects/${this.project.id}/pages/${this.paginationSelectedPage}`)
          .then(({data}) => {
            this.loadedPages = [data.data]
            this.pageIsLoading = false
          })
      }
    }
  }
}
