<template>
  <div
    class="pt-[9px] min-h-[500px]"
  >
    <SidbearSettingsGroup
      active
      title="Image Popup"
    >
      <b-form-group label="Popup Image">
        <image-upload
          :image="config.popupImage"
          class="w-100"
          disable-settigns
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <div v-if="config.subject">
      <component
        :is="config.subject.options.content.image.type"
        :config="config.subject.options.content.image"
        :local-section="config.subject"
        :show-active-group="false"
        component-name="Thumbnail"
      />
    </div>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'
import ImageUpload from '../utils/ImageUpload'
import Animations from '../controls/Animations'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'

export default {
  name: 'ImageMod',
  components: {SidbearSettingsGroup, Animations, ImageUpload},
  mixins: [ModMixin],

  data () {
    return {
      file: [],
      url: ''
    }
  },

  computed: {
    customModal () {
      if (!this.config.hasOwnProperty('modal')) {
        this.$set(this.config, 'modal', {
          data: {
            modal_id: null
          }
        })
      }

      return this.config.modal
    },
    animations: {
      get () {
        if (!this.config.hasOwnProperty('animations')) {
          this.$set(this.config, 'animations', {
            animation: null,
            once: true,
            offset: 200,
            delay: 50
          })
        }

        return this.config.animations
      },
      set (val) {
        this.config.animations = val
      }
    },
    ratio () {
      return {
        type: this.config.ratio.type,
        ...this.config.ratio[this.resolution]
      }
    },
    align: {
      get () {
        return this.config.align[this.resolution]
      },
      set (val) {
        this.config.align[this.resolution] = val
      }
    }
  },

  watch: {
    file () {
      // this.url = URL.createObjectURL(this.file)
      // this.config.src= this.url

      let formData = new FormData()

      formData.append('file', this.file)

      axios.post('api/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(({data}) => {
          this.config.src = data.data.url
          this.config.id = data.data.id
        })
    }
  },

  created () {
    if (!this.config.hasOwnProperty('align') || typeof this.config.align === 'string') {
      this.$set(this.config, 'align', {
        sm: 'center',
        lg: 'center'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-es-image-upload {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #E2E5EC;
  font-size: 14px;
  font-weight: 600;
  color: #44474A;
  height: 38px;
  box-shadow: 0 1px 2px 0 rgba(226, 226, 226, 0.5)
}
</style>
