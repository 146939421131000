<template>
  <div
    v-if="state"
    class="alt-loader animate__animated animate__fadeInUp shadow rounded d-flex align-items-center"
  >
    <b-spinner
      v-if="!end"
      variant="primary"
      class="mr-3"
    />
    <i
      v-else
      class="fa fa-check h4 text-primary mb-0 mr-3"
    />
    <div
      class="text-muted"
      v-text="message"
    />
  </div>
</template>

<script>
export default {
  name: 'AltPreloader',

  data () {
    return {
      state: false,
      message: '',
      end: false
    }
  },

  created () {
    VEvent.listen('alt-loader', ({state, message}) => {
      this.message = message

      if (!state && message) {
        this.end = true

        setTimeout(() => {
          this.state = state
          this.message = ''
          this.end = false
        }, 1000)
      } else {
        this.state = state
        if (!this.state) {
          this.message = ''
        }
      }
    })
  }
}
</script>

<style lang="scss">
.alt-loader {
  position: fixed;
  top: 110px;
  right: 30px;
  z-index: 10000;
  background: #fff;
  max-height: 70px;
  padding: 20px 30px;
}
</style>
