<template>
  <div
    v-if="section.options.customize.style.border.style !== 'none'"
    class="mb-4"
  >
    <label class="mb-2">Radius</label>

    <div class="d-flex align-items-center">
      <div
        class="shadow-control-box text-uppercase"
        :class="{'active': !config.radius}"
        @click="config.radius = 0"
      >
        <icon icon="shadow-none.svg" />
      </div>

      <div
        v-for="(radius, index) in radiuses"
        :key="index"
        class="shadow-control-box text-uppercase border"
        :class="{'active': config.radius === radius.radius}"
        @click="config.radius = radius.radius"
      >
        {{ radius.key }}
      </div>
    </div>
  </div>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'

export default {
  name: 'Shadow',

  mixins: [ControlMixin],

  data () {
    return {
      radiuses: [
        {
          key: 's',
          radius: '5px'
        },
        {
          key: 'm',
          radius: '10px'
        },
        {
          key: 'l',
          radius: '20px'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.shadow-control-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 39px;
  border-radius: 3px;
  background: #fff;
  cursor: pointer;

  & {
    font-weight: 500;
    font-size: 15px;
    color: #6e747a;
  }

  &.active {
    box-shadow: 0 0 0 2px #7CACFA !important;
  }

  &:not(:last-child) {
    margin-right: 19px;
  }
}
</style>
