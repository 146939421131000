<template>
  <div
    class="container-fluid position-relative"
    :style="{zIndex}"
  >
    <b-row
      class="position-relative"
    >
      <b-col
        :md="cols(width)"
        :offset-md="cols(((12 - width) / 2).toFixed())"
        class="px-0 position-relative"
      >
        <slot name="overlays" />
        <background-video
          v-if="backgroundVideo.url && backgroundVideo.enabled"
          :options="backgroundVideo"
          class="overflow-hidden"
        />

        <shape-divider
          :config="section.options.customize.style"
          :styles="{...radius}"
        />
        <section
          ref="container"
          :class="{'is-placeholder-height': !section.options.content.items.children.length}"
          :style="section.options.content.items.children.length ? {...fullPaddingSubject(section.options.customize.style), ...shadow, ...border, ...radius} : ''"
          class="section-container position-relative"
        >
          <div
            :style="imageFullHeightContentPosition"
            class="d-flex w-100 h-100"
          >
            <div class="w-100">
              <div
                class="d-flex flex-column w-100"
                :style="{...customSize}"
                :class="[align]"
              >
                <slot />
              </div>
            </div>
          </div>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TemplateMixin from '../../../../mixins/TemplateMixin'
import ThemeMixin from '../../../../mixins/ThemeMixin'
import {backgroundVideo} from '@/composition'
import BackgroundVideo from '@/components/builder/utils/background-video/BackgroundVideo'
import ShapeDivider from '@/components/editor/components/shape-divider/ShapeDivider'
import _ from 'lodash'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'FeaturedItems4Template',
  components: {ShapeDivider, BackgroundVideo},
  mixins: [TemplateMixin, ThemeMixin, BorderMixin],

  computed: {
    zIndex () {
      return _.get(this.section.options.customize, 'zIndex', 0)
    },
    align: {
      get () {
        return `justify-content-${_.get(this.section.options.customize.style, ['verticalAlign', this.resolutioner], 'start')}`
      },
      set () {
      }
    },
    customSize () {
      const enabled = _.get(this.section.options.customize.style, ['customSize', 'enabled', this.resolutioner], false)
      if (!enabled) return {}

      const heightData = _.get(this.section.options.customize.style, ['customSize', 'height', this.resolutioner], {
        value: 100,
        type: 'vh'
      })

      return {
        height: `${heightData.value}${heightData.type}`
      }
    },
    width () {
      const cols = {
        'full-width': 12,
        wide: 10,
        medium: 8,
        small: 6
      }

      return cols[this.section.options.customize.style.blockWidth.width]
    },
    shadow () {
      const shadow = this.section.options.customize.style.shadowOptions
      const color = this.section.options.customize.style.shadowColor || '#DADADA'

      if (!shadow) return {}

      return {
        boxShadow: shadow.spread ? `${shadow.offsetX || 0}px ${shadow.offsetY || 0}px ${shadow.blur}px ${shadow.spread || 0}px ${color}` : ''
      }
    },
    border () {
      const border = this.section.options.customize.style.border || [0, 0, 0, 0]

      return {
        borderColor: this.section.options.customize.style.borderColor,
        borderStyle: 'solid',
        borderTopWidth: `${border[0]}px`,
        borderRightWidth: `${border[1]}px`,
        borderBottomWidth: `${border[2]}px`,
        borderLeftWidth: `${border[3]}px`
      }
    },
    radius () {
      const radius = this.section.options.customize.style.radius || [5, 5, 5, 5]

      return {
        borderTopLeftRadius: `${radius[0]}px`,
        borderTopRightRadius: `${radius[1]}px`,
        borderBottomLeftRadius: `${radius[2]}px`,
        borderBottomRightRadius: `${radius[3]}px`
      }
    },
    backgroundVideo () {
      if (!this.section.options.customize.style.hasOwnProperty('backgroundVideo')) {
        this.$set(this.section.options.customize.style, 'backgroundVideo', backgroundVideo)
      }

      return this.section.options.customize.style.backgroundVideo
    }
  },

  created () {
    if (!this.section.options.customize.style.hasOwnProperty('backgroundVideo')) {
      this.$set(this.section.options.customize.style, 'backgroundVideo', JSON.parse(JSON.stringify(backgroundVideo)))
    }
  }
}
</script>
