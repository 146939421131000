<template>
  <div>
    <v-select
      v-model="mapped"
      class="custom-select"
      :options="filteredControls"
      :reduce="reduce"
    >
      <template #selected-option="fil">
        {{ startCase(fil[name()]) }}
      </template>

      <template #option="fil">
        {{ startCase(fil[name()]) }}
      </template>
    </v-select>
  </div>
</template>

<script>
import _ from 'lodash'
import TextMixins from '../../../mixins/TextMixins'

export default {
  name: 'FormMappingField',
  mixins: [TextMixins],
  props: {
    field: {
      type: Object,
      required: true
    },
    controls: {
      type: [Array, Object],
      required: true
    },
    allFields: {
      type: [Array, Object],
      required: true
    },
    autoresponder: {
      type: Object,
      required: true
    }
  },
  computed: {
    mapped: {
      get () {
        return _.pick(_.find(this.field.mapping, {auto_responder_id: this.autoresponder.id}), ['api_name', 'api_id', 'auto_responder_id', 'field_id']) || {}
      },
      set (val) {
        const isHas = _.findIndex(this.field.mapping, ['auto_responder_id', this.autoresponder.id])
        if (isHas !== -1) {
          (this.field.mapping || this.$set(this.field, 'mapping', [])).splice(isHas, 1)
        }
        (this.field.mapping || this.$set(this.field, 'mapping', [])).push(val)
      }
    },
    filteredControls () {
      return this.controls.filter(control => {
        if (!_.isEmpty(_.find(_.filter(_.flatten(this.allFields.map(c => c.mapping)), ['auto_responder_id', this.autoresponder.id]), ['api_name', control[this.name()]]))) {
          return this.mapped.api_name === control[this.name()]
        }

        return true
      })
    }
  },
  methods: {
    name () {
      const names = {
        MailChimp: 'name',
        AWeber: 'name',
        Ontraport: 'name',
        ActiveCampaign: 'title',
        GetResponse: 'name',
        ConvertKit: 'key',
        Sendlane: 'name',
        Keap: 'key',
        HubSpot: 'name',
        SendGrid: 'name',
        Brevo: 'name',
        MailerLite: 'title',
        Zoho: 'name',
        Moosend: 'name',
        Sender: 'name'
      }

      return names[this.autoresponder.name]
    },
    reduce (option) {
      if (this.autoresponder.name === 'MailChimp') {
        return {
          api_name: option.tag,
          api_id: option.merge_id.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'AWeber') {
        return {
          api_name: option.name,
          api_id: option.id.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'Ontraport') {
        return {
          api_name: option.id,
          api_id: option.id.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'ActiveCampaign') {
        return {
          api_name: option.id,
          api_id: option.perstag.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'GetResponse') {
        return {
          api_name: option.customFieldId,
          api_id: option.name.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'ConvertKit') {
        return {
          api_name: option.key,
          api_id: option.id.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'Sendlane') {
        return {
          api_name: option.name,
          api_id: option.id.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'Keap') {
        return {
          api_name: option.key,
          api_id: option.id.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'HubSpot') {
        return {
          api_name: option.name,
          api_id: option.id.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'SendGrid') {
        return {
          api_name: option.name,
          api_id: option.id.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'Brevo') {
        return {
          api_name: option.name,
          api_id: option.field_key.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'MailerLite') {
        return {
          api_name: option.key,
          api_id: option.id.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'Zoho') {
        return {
          api_name: option.id,
          api_id: option.id.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'Moosend') {
        return {
          api_name: option.id,
          api_id: option.id.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }

      if (this.autoresponder.name === 'Sender') {
        return {
          api_name: option.id,
          api_id: option.id.toString(),
          auto_responder_id: this.autoresponder.id,
          field_id: this.field.id
        }
      }
    }
  }
}
</script>
