<template>
  <div>
    <div
      class="flex items-center btn-group w-full"
      :class="{'flex-wrap': stack}"
    > 
      <button
        v-for="tab in tabs"
        :key="tab"
        class="btn h-[33px] !text-[13px] border hover:!bg-[#F2F4F5] !text-[#44474A] px-3 py-0 mb-[16px]"
        :class="[{'!bg-[#F2F4F5]': active === tab}, {'w-100 mb-2': stack}]"
        @click="active = tab"
        v-text="tab"
      />
    </div>

    <div
      v-for="tab in tabs"
      v-if="active === tab"
      :key="`slot-${tab}`"
    >
      <slot :name="tab" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'RuleTabs',
  props: {
    tabs: {
      type: Array,
      default: () => ['Normal', 'Hover']
    },
    stack: {
      default: false
    }
  },
  data () {
    return {
      active: this.tabs[0]
    }
  }
}
</script>