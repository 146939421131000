<template>
  <div>
    <b-form-group>
      <b-checkbox v-model="config.noGutters">
        No Gutters
      </b-checkbox>
    </b-form-group>

    <alignment-items-control :config="config" />

    <alignment-items-control
      label="Placeholder Alignment"
      :config="config"
      mutation-key="placeholderAlign"
      :exlude="['between']"
    />

    <b-row no-gutters>
      <b-col cols="6">
        <b-form-group label="Label color">
          <color-picker
            :color="labelColor"
            @input="event => labelColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />
        </b-form-group>
      </b-col>
      
      <b-col cols="6">
        <b-form-group label="Background Color">
          <color-picker
            :color="backgroundColor"
            @input="event => backgroundColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group label="Text Color">
          <color-picker
            :color="textColor"
            @input="event => textColor = `rgba(${event.rgba.r}, ${event.rgba.g}, ${event.rgba.b}, ${event.rgba.a})`"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group
      label="Border & Radius"
    >
      <border-customs-v3
        :config="getBorderConfig"
        :default-border="[1,1,1,1]"
        :default-radius="[4,4,4,4]"
        default-color="rgb(238, 238, 238)"
      />
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'FormStyle',

  props: {
    config: {
      type: Object,
      required: true
    }
  },

  computed: {
    getBorderConfig () {
      if (Array.isArray(this.config.radius) && Array.isArray(this.config.border)) {
        this.$set(this.config, 'border', {
          lg: _.clone(this.config.border || [0,0,0,0]),
          md: _.clone(this.config.border || [0,0,0,0]),
          sm: _.clone(this.config.border || [0,0,0,0])
        })

        this.$set(this.config, 'radius', {
          lg: _.clone(this.config.radius || [4,4,4,4]),
          md: _.clone(this.config.radius || [4,4,4,4]),
          sm: _.clone(this.config.radius || [4,4,4,4])
        })
      }

      return this.config
    },
    backgroundColor: {
      get () {
        return this.config.backgroundColor || '#fff'
      },
      set (val) {
        this.$set(this.config, 'backgroundColor', val)
      }
    },
    borderColor: {
      get () {
        return this.config.borderColor || 'rgb(238, 238, 238)'
      },
      set (val) {
        this.$set(this.config, 'borderColor', val)
      }
    },
    textColor: {
      get () {
        return this.config.color || '#495057'
      },
      set (val) {
        this.$set(this.config, 'color', val)
      }
    },
    labelColor: {
      get () {
        return _.get(this.config, 'style.labelColor', '#6e747a')
      },
      set (labelColor) {
        this.$set(this.config, 'style', Object.assign({}, this.config.style || {}, {labelColor}))
      }
    }
  }
}
</script>
