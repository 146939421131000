<template>
  <highliter
    v-model="section.name"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings('image')"
  >
    <FeaturedItems5Template :section="section">
      <template slot="elements">
        <elements v-model="section.options.content.elements" />
      </template>
    </FeaturedItems5Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import FeaturedItems5Template from '@builder/templates/sections/featured/FeaturedItems5Template'

export default {
  name: 'FeaturedItems5',

  components: {FeaturedItems5Template, Highliter},

  mixins: [SectionMixin]
}
</script>
