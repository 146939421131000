<template>
  <div class="pt-[9px] sid-select">
    <SidbearSettingsGroup
      title="Navbar"
    >
      <b-form-group label="Navbar Collapsable">
        <v-select
          v-model="config.specialCols.navigation.showOn"
          :options="breackOptions"
          :reduce="val => val.value"
          :searchable="false"
        />
      </b-form-group>

      <b-form-group label="Placement">
        <v-select
          v-model="config.specialCols.navigation.placement"
          :options="options"
          :reduce="val => val.value"
          :searchable="false"
        />
      </b-form-group>

      <sid-buttons-group
        label="Horizontal Alignment"
        :options="[
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'start',
            tooltip: 'Start'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'center',
            tooltip: 'Center'
          },
          {
            svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
            value: 'end',
            tooltip: 'End'
          }
        ]"
        :sid="`#header-collapse`"
        rule-name="justify-content"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup title="Hamburger Icon">
      <b-form-group label="Open icon">
        <icon-select v-model="iconOpen" />

        <sid-color
          :sid="`.--${config.sid}--open-icon`"
          rule-name="color"
        />
      </b-form-group>

      <b-form-group label="Close icon">
        <icon-select v-model="iconClose" />

        <sid-color
          :sid="`.--${config.sid}--close-icon`"
          :reference="`.--${config.sid}--icon`"
          rule-name="color"
        />
      </b-form-group>

      <b-form-group
        label="Hamburger Icon Size"
        label-class="!pb-[3px]"
        class="mb-0"
      >
        <sid-range-slider
          :sid="`.--${config.sid}--icon`"
          rule-name="font-size"
        />
      </b-form-group>

      <b-form-group>
        <sid-buttons-group
          label="Icon Placement"
          class="mb-0"
          :options="[
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              tooltip: 'Start',
              value: '10px',
              property: 'left',
              customProperties: [[`.--${config.sid}--icon`, ['right', 'auto']], [`.--${config.sid}--icon`, ['left', '10px']]]
            },
            {
              svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
              tooltip: 'End',
              value: '10px',
              property: 'right',
              customProperties: [[`.--${config.sid}--icon`, ['left', 'auto']], [`.--${config.sid}--icon`, ['right', '10px']]]
            }
          ]"
          :sid="`.--${config.sid}--icon`"
          rule-name="rightd"
        />
      </b-form-group>

      <b-form-group>
        <sid-translate
          :sid="`.--${config.sid}--icon`"
          rule-name="translate"
          :min="-100"
          :max="100"
          :step="1"
        />
      </b-form-group>
    </SidbearSettingsGroup>

    <flex-column-mod-v2
      component-name="Navigation"
      :config="config.specialCols.navigation"
    />
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import IconSelect from '@/components/editor/components/IconSelect'
import _ from 'lodash'
import FlexColumnModV2 from '@/components/editor/mods/FlexColumnModV2'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidTranslate from '@/components/editor/components/sid-controls/sid-control-pressets/transform/SidTranslate'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup'

export default {
  name: 'NavigationFlexColMod',
  components: {
    SidButtonsGroup,
    SidbearSettingsGroup, SidTranslate, SidColor, SidRangeSlider, FlexColumnModV2, IconSelect},
  mixins: [ModMixin],
  data () {
    return {
      breackOptions: [
        {
          label: 'Tablet And Down',
          value: 'lg'
        },
        {
          label: 'Mobile And Down',
          value: 'md'
        },
        {
          label: 'Always',
          value: false
        }
      ],
      options: [
        {
          label: 'Top',
          value: 'top'
        },
        {
          label: 'Bottom',
          value: 'bottom'
        }
      ]
    }
  },
  computed: {
    navbarBackground () {
      if (!this.config.specialCols.navigation.navbarBackground) {
        this.$set(this.config.specialCols.navigation, 'navbarBackground', {
          background: '',
          gradient: {
            position: '',
            color: ''
          }
        })
      }

      return this.config.specialCols.navigation.navbarBackground
    },
    iconOpen: {
      get () {
        return _.get(this.config.specialCols.navigation, 'hamburgerIcon_open', 'fa fa-bars')
      },
      set (val) {
        this.$set(this.config.specialCols.navigation, 'hamburgerIcon_open', val)
      }
    },
    iconClose: {
      get () {
        return _.get(this.config.specialCols.navigation, 'hamburgerIcon_close', 'fa fa-times')
      },
      set (val) {
        this.$set(this.config.specialCols.navigation, 'hamburgerIcon_close', val)
      }
    },
    iconColor: {
      get () {
        return _.get(this.config.specialCols.navigation, 'hamburgerIcon_color', '#656565')
      },
      set (val) {
        this.$set(this.config.specialCols.navigation, 'hamburgerIcon_color', val)
      }
    },
    closeIconColor: {
      get () {
        return _.get(this.config.specialCols.navigation, 'hamburgerIcon_close_color', this.iconColor)
      },
      set (val) {
        this.$set(this.config.specialCols.navigation, 'hamburgerIcon_close_color', val)
      }
    },
    hamburgerIconSize: {
      get () {
        return _.get(this.config.specialCols.navigation, ['hamburgerIconSize', this.resolutioner], _.get(this.config.specialCols.navigation, ['hamburgerIconSize', 'lg'], 16))
      },
      set (val) {
        this.$set(this.config.specialCols.navigation, 'hamburgerIconSize', {..._.set(this.config.specialCols.navigation['hamburgerIconSize'] || {}, [this.resolutioner], val)})
      }
    }
  }
}
</script>