<template>
  <div>
    <div
      class="flex items-center gap-[16px] h-[43px] bg-gradient-to-b from-white to-[#F1F3F4] px-[18px] border-b border-[#E2E5EC]"
    >
      <b-link @click="$store.commit('editor/SET_PAGE', 'sections')">
        <svg
          width="11"
          height="9"
          viewBox="0 0 11 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.35225 8.83525C4.57192 9.05492 4.92808 9.05492 5.14775 8.83525C5.36742 8.61558 5.36742 8.25942 5.14775 8.03975L2.1705 5.0625H9.8125C10.1232 5.0625 10.375 4.81066 10.375 4.5C10.375 4.18934 10.1232 3.9375 9.8125 3.9375H2.1705L5.14775 0.960248C5.36742 0.740578 5.36742 0.384422 5.14775 0.164753C4.92808 -0.0549175 4.57192 -0.0549176 4.35225 0.164753L0.414753 4.10225C0.195082 4.32192 0.195082 4.67808 0.414753 4.89775L4.35225 8.83525Z"
            fill="#6E747A"
          />
        </svg>
      </b-link>

      <span class="text-[#44474A] font-[700] text-[15px] mb-0">
        Page Settings
      </span>
    </div>

    <div class="flex flex-column relative max-h-full h-[calc(100vh-155px)] simple-scrollbar">
      <div class="overflow-auto pb-[1px]">
        <b-card
          class="border-0"
          no-body
        >
          <div class="section-settings">
            <b-card
              class="border-0 noselect"
              no-body
            >
              <b-card-body class="border-top-0 pr-3 pt-0 pb-0">
                <SidbearSettingsGroup
                  active
                  title="Add Custom Scripts / Code"
                >
                  <b-form-group>
                    <button
                      class="flex items-center border rounded-[2px] h-[38px] w-[182px] hover:bg-gray-100 !pl-[17px] py-0 pr-0"
                      @click="injectionModal"
                    >
                      <span class="text-[#3D82EA] font-[600] text-[22px] mr-[9px]">+</span> Add Code Injection
                    </button>
                  </b-form-group>
                </SidbearSettingsGroup>

                <SidbearSettingsGroup
                  title="Layout Settings"
                >
                  <b-form-group>
                    <b-checkbox v-model="hideHeader">
                      Hide Header
                    </b-checkbox>
                  </b-form-group>

                  <b-form-group>
                    <b-checkbox v-model="hideFooter">
                      Hide Footer
                    </b-checkbox>
                  </b-form-group>

                  <b-form-group>
                    <b-checkbox v-model="stickyFooter">
                      Sticky Footer
                    </b-checkbox>
                  </b-form-group>

                  <background-customs :config="backgroundColor" />
                  <background-image-customs
                    :animation-enabled="false"
                    :config="backgroundImage"
                  />
                </SidbearSettingsGroup>

                <SidbearSettingsGroup
                  title="Restrictions"
                >
                  <page-password-select
                    :page="page"
                    class="mb-0"
                  />
                </SidbearSettingsGroup>

                <SidbearSettingsGroup
                  title="Page SEO Settings"
                >
                  <page-seo-settings />
                </SidbearSettingsGroup>

                <SidbearSettingsGroup
                  title="Integrations"
                >
                  <integration-exclusion-settings
                    :project-id="project.id"
                    :page-id="page.id"
                  />
                </SidbearSettingsGroup>
              </b-card-body>
            </b-card>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import _ from 'lodash'
import BackgroundImageCustoms from '../controls/BackgroundImageCustoms'
import PageSeoSettings from './PageSeoSettings'
import IntegrationExclusionSettings from './IntegrationExclusionSettings'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import PagePasswordSelect from '@/components/editor/components/PagePasswordSelect'

export default {
  name: 'PageSettings',
  components: {PagePasswordSelect, SidbearSettingsGroup, PageSeoSettings, BackgroundImageCustoms,IntegrationExclusionSettings},

  data () {
    return {
      selected: 'A',
      url: window.location.origin + '/',
      preview: {
        src: ''
      }
    }
  },

  computed: {
    backgroundRepeat: {
      get () {
        return this.backgroundImage.backgroundRepeat
      },
      set (backgroundRepeat) {
        this.$set(this.page.settings, 'backgroundImage', {...this.backgroundImage, backgroundRepeat})
      }
    },
    backgroundImage () {
      if (!this.page.settings.hasOwnProperty('backgroundImage')) {
        this.$set(this.page.settings, 'backgroundImage', {
          type: 'background-image-customs',
          src: '',
          position: 'center',
          size: 'cover',
          opacity: 1,
          backgroundRepeat: 'repeat'
        })
      }

      return this.page.settings.backgroundImage
    },
    backgroundColor () {
      if (!this.page.settings.hasOwnProperty('background')) {
        this.$set(this.page.settings, 'background', {
          background: ''
        })
      }

      return this.page.settings.background
    },
    ...mapState({
      globalStyles: state => state.editor.globalStyles
    }),
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('pages', {
      page: state => state.page
    }),
    hideFooter: {
      get () {
        return _.get(this.page, ['settings', 'hideFooter'], false)
      },
      set (val) {
        this.$set(this.page.settings, 'hideFooter', val)
      }
    },
    stickyFooter: {
      get () {
        return _.get(this.page, ['settings', 'stickyFooter'], false)
      },
      set (val) {
        this.$set(this.page.settings, 'stickyFooter', val)
      }
    },
    hideHeader: {
      get () {
        return this.page.settings.hideHeader || false
      },
      set (val) {
        this.$set(this.page.settings, 'hideHeader', val)
      }
    }
  },

  mounted () {
    this.preview.src = this.globalStyles.favicon || ''
  },

  methods: {
    changeTitle (val) {
      document.title = val || 'Estage Builder'
    },
    setFavicon (src) {
      this.globalStyles.favicon = src

      // let link = document.querySelector("link[rel~='icon']")
      // if (!link) {
      //   link = document.createElement('link')
      //   link.rel = 'icon'
      //   document.getElementsByTagName('head')[0].appendChild(link)
      // }
      // link.href = src
    },
    imageDeleted () {
      this.setFavicon('/favicon.png')
    },
    updateFavicon (image) {
      this.setFavicon(image.src)
    },
    injectionModal () {
      VEvent.fire('injection-code')
    }
  }
}
</script>

<style lang="scss">
.global-styles {
  .select-box {
    border-radius: 4px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
    text-align: center;
    font-size: 34px;
    width: 70px;
    height: 70px;
    display: flex !important;
    border: 1px solid #e2e5ec;
    align-items: center;
    justify-content: center;

    &.active {
      box-shadow: 0 0 0 2px #7CACFA !important;
    }
  }

  label {
    font-size: 15px;
  }

  .color-picker {
    padding: 0 !important;
    border: none !important;
  }
}
</style>
