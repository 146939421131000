<template>
  <div class="overflow-hidden inherit-radius">
    <div
      v-if="topDivider"
    >
      <div
        :class="[{'flip': topDivider.flip}, {'invert-shape': topDivider.invert}]"
        :style="styles"
        class="custom-shape-divider-top inherit-radius"
      >
        <shape :config="topDivider" />
      </div>
    </div>

    <div
      v-if="bottomDivider"
    >
      <div
        :class="[{'flip': bottomDivider.flip}, {'invert-shape': bottomDivider.invert}]"
        :style="styles"
        class="custom-shape-divider-bottom inherit-radius"
      >
        <shape :config="bottomDivider" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Shape from './Shape'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'

export default {
  name: 'ShapeDivider',
  components: {Shape},
  mixins: [ResolutionMixin],
  props: {
    config: {
      type: Object,
      required: true
    },
    styles: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    topDivider () {
      if (!_.get(this.config, ['shapeDivider', 'top', 'type', this.resolutioner], null)) return false

      return {
        type: _.get(this.config, ['shapeDivider', 'top', 'type', this.resolutioner], null),
        height: _.get(this.config, ['shapeDivider', 'top', 'height', this.resolutioner], 200),
        flip: _.get(this.config, ['shapeDivider', 'top', 'flip', this.resolutioner], false),
        invert: _.get(this.config, ['shapeDivider', 'top', 'invert', this.resolutioner], false),
        color: _.get(this.config, 'shapeDivider.top.color', '#D9D9D9')
      }
    },
    bottomDivider () {
      if (!_.get(this.config, ['shapeDivider', 'bottom', 'type', this.resolutioner], null)) return false

      return {
        type: _.get(this.config, ['shapeDivider', 'bottom', 'type', this.resolutioner], null),
        height: _.get(this.config, ['shapeDivider', 'bottom', 'height', this.resolutioner], 200),
        flip: _.get(this.config, ['shapeDivider', 'bottom', 'flip', this.resolutioner], false),
        invert: _.get(this.config, ['shapeDivider', 'bottom', 'invert', this.resolutioner], false),
        color: _.get(this.config, 'shapeDivider.bottom.color', '#D9D9D9')
      }
    }
  }
}
</script>

<style lang='scss'>
.custom-shape-divider-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &.flip {
    transform: scaleX(-1);
  }

  &.invert-shape {
    transform: scaleY(-1) translateY(100%);
  }

  &.flip.invert-shape {
    transform: scale(-1) translateY(100%);
  }

  svg {
    width: 100%
  }
}

.custom-shape-divider-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &.flip {
    transform: scaleX(-1) rotate(180deg);
  }

  &.invert-shape {
    transform: scaleY(-1) rotate(180deg) translateY(100%);
  }

  &.flip.invert-shape {
    transform: scale(-1) rotate(180deg) translateY(100%);
  }

  svg {
    width: 100%
  }
}
</style>