<template>
  <div class="sid-select">
    <b-form-group>
      <div class="flex items-center justify-between">
        <label
          class="mb-0 pb-0"
          for="float-image"
        >Display Video Background</label>

        <b-checkbox
          id="float-image"
          v-model="config.enabled"
          switch
          value="1"
        />
      </div>
    </b-form-group>

    <b-form-group>
      <b-checkbox
        v-model="config.autoRatio"
      >
        Auto Ratio
      </b-checkbox>
    </b-form-group>

    <b-form-group>
      <sid-checkbox
        value="fixed"
        unchecked-value="absolute"
        rule-name="position"
        :sid="sid"
      >
        Parallax
      </sid-checkbox>
    </b-form-group>

    <b-form-group label="Insert Video">
      <v-select
        v-model="videoType"
        :searchable="false"
        :options="[
          'ESTAGE',
          'External URL'
        ]"
      />
    </b-form-group>

    <b-form-group v-if="config.videoType === 'ESTAGE'">
      <div
        class="flex items-center hover:bg-gray-100 justify-start text-dark px-[16px] text-[13px] font-[600] h-[38px] rounded-[4px] border gap-[9px] cursor-pointer"
        @click="() => modal = true"
      >
        <i class="fa fa-plus text-primary" />
        Upload video
      </div>
      <video-lib-modal
        :modal.sync="modal"
        :file="video"
        :uploaded="uploaded"
      />
    </b-form-group>

    <b-form-group v-else>
      <div class="flex items-center gap-[17px]">
        <label class="p-0">Video URL</label>

        <b-input
          v-model="url"
          class="!w-[156px]"
          placeholder="http://"
        />
      </div>
    </b-form-group>

    <b-form-group label="Ratio">
      <vue-slider
        v-model="ratio"
        :dot-size="17"
        :interval="0.1"
        :max="4"
        :min=".1"
        class="editor-slider-1"
        tooltip="none"
      />
    </b-form-group>
  </div>
</template>

<script>
import _ from 'lodash'
import ControlMixin from '../../mixins/ControlMixin'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox.vue'
import ResolutionMixin from '@/components/mixins/ResolutionMixin'
import VideoLibModal from '@/components/video-library/VideoLibModal.vue'

export default {
  name: 'BackgroundVideoCustoms',
  components: {
    VideoLibModal,
    SidCheckbox
  },
  mixins: [ControlMixin, ResolutionMixin],

  props: {
    sid: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      open: false,
      modal: false,
      video: {}
    }
  },

  computed: {
    videoType: {
      get () {
        return this.config.videoType || 'ESTAGE'
      },
      set (val) {
        this.$set(this.config, 'videoType', val)
      }
    },
    ratio: {
      get () {
        return _.get(this.config, ['ratio', this.resolutioner], _.get(this.config, 'ratio.lg', this.config.ratio))
      },
      set (val) {
        this.$set(this.config, 'ratio', {..._.set(this.config.ratio, [this.resolutioner], val)})
      }
    },
    url: {
      get () {
        return this.config.url
      },
      set (val) {
        this.config.url = ''
        setTimeout(() => this.config.url = val, 100)
      }
    }
  },
  methods: {
    uploaded (file) {
      this.url = 'https://player.estage.com/' + file.id
    }
  },
  mounted () {
    if (!this.config.videoType) {
      this.$set(this.config, 'videoType', 'ESTAGE')
    }
  }
}
</script>

