import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Field Card Expiry Month'
export const group = ''

export default class ElFieldCardExpMonth extends SectionProvider {
  constructor (options = {}) {
    super('ElFieldCardExpMonth', options)

    this.name = name
    this.group = group
  }
}