<template>
  <div class="flex items-center">
    <input
      v-model="search"
      placeholder="Search all comments..."
      class="h-[38px] px-[14px] rounded-left border-right-0 border w-[200px]"
    >
    <location v-model="location" />
    <button
      class="border p-0 w-[43px] h-[38px] border-left-0 bg-white rounded-right"
      @click="queryFilters.search ? reset() : apply()"
    >
      <svg
        v-if="!queryFilters.search"
        width="27"
        height="27"
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_782_407"
          style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="27"
          height="27"
        >
          <rect
            width="27"
            height="27"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_782_407)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.1734 14.0365C18.052 11.4004 17.8089 7.71703 15.4439 5.35203C12.8078 2.71599 8.53397 2.71599 5.89793 5.35203C3.26189 7.98807 3.26189 12.2619 5.89793 14.898C8.26293 17.263 11.9463 17.5061 14.5824 15.6275L21.0123 22.0574L22.6033 20.4664L16.1734 14.0365C18.052 11.4004 16.1734 14.0365 16.1734 14.0365ZM13.8529 6.94302C15.6102 8.70038 15.6102 11.5496 13.8529 13.307C12.0955 15.0643 9.24628 15.0643 7.48892 13.307C5.73156 11.5496 5.73156 8.70038 7.48892 6.94302C9.24628 5.18566 12.0955 5.18566 13.8529 6.94302C15.6102 8.70038 13.8529 6.94302 13.8529 6.94302Z"
            fill="#6E747A"
          />
        </g>
      </svg>
      <svg
        v-else
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
          fill="#6E747A"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import Location from '@/views/dashboard/comments/Location.vue'

export default {
  name: 'Search',
  components: {Location},
  props: {
    queryFilters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      search: '',
      location: null
    }
  },
  methods: {
    apply () {
      this.queryFilters.searchBy = this.location
      this.queryFilters.search = this.search
    },
    reset () {
      this.search = null
      this.location = null
      this.queryFilters.searchBy = null
      this.queryFilters.search = null
    }
  }
}
</script>