<template>
  <div>
    <h2 class="page-title mb-5">
      Login Info
    </h2>

    <b-row class="align-items-end">
      <b-col md="4">
        <b-form-group label="Email Address">
          <b-input
            :value="user.email"
            class="bg-white"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <change-email-modal />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="align-items-end">
      <b-col md="4">
        <b-form-group label="Password">
          <b-input
            type="password"
            class="bg-white"
            value="12345678"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <change-password-modal />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ChangePasswordModal from './components/ChangePasswordModal'
import ChangeEmailModal from './components/ChangeEmailModal'

export default {
  name: 'Main',
  components: {ChangeEmailModal, ChangePasswordModal},
  computed: {
    ...mapState('auth', {
      user: state => state.user
    })
  }
}
</script>