<template>
  <div>
    <SidbearSettingsGroup
      title="Tabs"
      active
    >
      <b-form-group>
        <sid-checkbox
          value="none"
          unchecked-value=""
          rule-name="display"
          :sid="`.--${section.sid}--flex`"
        >
          Hide Tabs
        </sid-checkbox>
      </b-form-group>

      <draggable
        v-model="section.options.content.gridCollapse.items"
        handle=".move"
      >
        <div
          v-for="(item, index) in section.options.content.gridCollapse.items"
          :key="`${index}-tab`"
        >
          <div
            class="!border-[#E2E5EC] group rounded-[2px] h-[29px] flex items-center pl-[13px] hover:bg-[#F1F5FD] cursor-pinter mb-[12px]"
            :class="{'bg-[#F1F5FD]': section.options.content.gridCollapse.opened === index}"
            style="border:1px solid;"
          >
            <div class="mr-[11px] move cursor-move">
              <svg
                fill="none"
                height="11"
                viewBox="0 0 11 11"
                width="11"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.57745 0.894104H0.568359V3.8941H3.57745V0.894104Z"
                  fill="#3D82EA"
                />
                <path
                  d="M10.5984 0.894104H7.58936V3.8941H10.5984V0.894104Z"
                  fill="#3D82EA"
                />
                <path
                  d="M3.57745 7.8941H0.568359V10.8941H3.57745V7.8941Z"
                  fill="#3D82EA"
                />
                <path
                  d="M10.5984 7.8941H7.58936V10.8941H10.5984V7.8941Z"
                  fill="#3D82EA"
                />
              </svg>
            </div>

            <span
              class="text-truncate !cursor-text max-w-[165px] text-[15px] group-hover:text-[#3D82EA] text-[#7E7E7E]"
            >
              <input
                v-model="item.title"
                class="w-[131px] bg-transparent"
              >
            </span>

            <b-checkbox
              v-model="openIndex"
              :value="index"
              :unchecked-value="null"
            />

            <button
              v-b-tooltip
              class="flex h-[29px] items-center justify-center border-left w-[31px] p-0 hover:bg-gray-100 ml-auto"
              title="Copy"
              @click="() => copy(item)"
            >
              <i class="copy-icon" />
            </button>

            <button
              v-b-tooltip
              class="w-[38px] h-[29px] text-center border-right-0 border bg-[#fff] p-0"
              title="Delete"
              @click.stop="section.options.content.gridCollapse.items.splice(index, 1)"
            >
              <svg
                fill="none"
                height="10"
                viewBox="0 0 10 10"
                width="10"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.32913 0.170839C1.10132 -0.0569667 0.731973 -0.0569667 0.504167 0.170839C0.276362 0.398645 0.276362 0.767991 0.504167 0.995797L4.17507 4.6667L0.504167 8.33759C0.276362 8.5654 0.276362 8.93475 0.504167 9.16255C0.731973 9.39036 1.10132 9.39036 1.32913 9.16255L5.00002 5.49165L8.67083 9.16246C8.89864 9.39027 9.26799 9.39027 9.49579 9.16246C9.7236 8.93466 9.7236 8.56531 9.49579 8.33751L5.82498 4.6667L9.49579 0.995885C9.7236 0.768079 9.7236 0.398733 9.49579 0.170927C9.26799 -0.0568786 8.89864 -0.0568786 8.67083 0.170927L5.00002 3.84174L1.32913 0.170839Z"
                  fill="#6E747A"
                />
              </svg>
            </button>

            <button
              title="Column Settings"
              class="flex h-[27px] items-center justify-center border-left w-[31px] bg-[#fff] p-0 hover:bg-gray-100"
              @click.stop="() => section.options.content.gridCollapse.opened = index"
            >
              <svg
                fill="none"
                height="10"
                viewBox="0 0 7 10"
                width="7"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                d="M0.879944 9.23245L4.98805 4.89924L0.879944 0.566031"
                stroke="#6E747A"
                stroke-width="1.5"
              /></svg>
            </button>
          </div>

          <div v-if="section.options.content.gridCollapse.opened === index">
            <b-form-group class="sid-select">
              <div class=" flex items-center justify-between gap-[10px]">
                <label class="pb-0 w-[200px]">Tab Style</label>

                <v-select
                  v-model="item.tabType"
                  :searchable="false"
                  placeholder="Text (Default)"
                  :reduce="val => val.value"
                  :options="[{label: 'Text (Default)', value: null}, {label: 'Icon', value: 'icon'}, {label: 'Image', value: 'image'}]"
                  @option:selected="val => onTypeSelect(item, val)"
                />
              </div>
            </b-form-group>

            <b-form-group label="Tab ID">
              <b-input v-model="item.hash" />
            </b-form-group>
          </div>
        </div>
      </draggable>

      <button
        class="flex items-center border gap-[5px] rounded-[4px] font-[600] text-dark !text-[11px] h-[29px] px-[18px] shadow-[0_2px_3px_rgba(0, 0, 0, 0.06)] mb-[25px]"
        @click="addListItem"
      >
        Add Tab
        <span class="text-[#3D82EA] text-[20px]">+</span>
      </button>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Tab Content Settings"
    >
      <sid-width-and-height
        :sid="`.--${section.sid}--content`"
        default-value="100%"
      />

      <sid-background :sid="`.--${section.sid}--content`" />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Border Type</label>

          <sid-select
            :sid="`.--${section.sid}--content`"
            rule-name="border-style"
            :searchable="false"
            class="w-[118px]"
            :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
          />
        </div>
      </b-form-group>

      <b-form-group>
        <sid-linked-group
          :sid="`.--${section.sid}--content`"
          label="Border Width"
          rule-name="border"
          class="!mb-[7px]"
          :angles="[
            {
              text: '',
              value: 'top-width'
            },
            {
              text: '',
              value: 'right-width'
            },
            {
              text: '',
              value: 'bottom-width'
            },
            {
              text: '',
              value: 'left-width'
            }
          ]"
        />

        <div class="flex w-full justify-end">
          <sid-color
            :sid="`.--${section.sid}--content`"
            rule-name="border-color"
          />
        </div>
      </b-form-group>

      <sid-linked-group
        label="Radius"
        :sid="`.--${section.sid}--content`"
        rule-name="border"
        :angles="[
          {
            text: '',
            value: 'top-left-radius'
          },
          {
            text: '',
            value: 'top-right-radius'
          },
          {
            text: '',
            value: 'bottom-left-radius'
          },
          {
            text: '',
            value: 'bottom-right-radius'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--content`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--content`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Tab Styles"
    >
      <rule-tabs :tabs="['Normal', 'Active', 'Hover']">
        <template #Normal>
          <sid-range-slider
            label="Horizontal Gap"
            :sid="`.--${section.sid}--flex`"
            rule-name="column-gap"
          />

          <sid-range-slider
            label="Vertical Gap"
            :sid="`.--${section.sid}--flex`"
            rule-name="row-gap"
          />

          <sid-checkbox
            :sid="`.--${section.sid}--flex`"
            rule-name="flex-wrap"
            value="wrap"
          >
            Wrap
          </sid-checkbox>

          <sid-flex-direction
            :sid="`.--${section.sid}--flex`"
            rule-name="flex-direction"
          >
            <template #column>
              <sid-buttons-group
                label="Alignment"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'12\' viewBox=\'0 0 13 12\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 3.26855 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.11228\' height=\'8.65576\' transform=\'matrix(1 -8.77996e-08 -8.70476e-08 -1 6.86035 9.85815)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 0.721313)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.4915\' height=\'0.721313\' transform=\'matrix(1 0 0 -1 0.875 11.0599)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E',
                    value: 'stretch',
                    tooltip: 'Stretch'
                  }
                ]"
                :sid="`.--${section.sid}--flex`"
                rule-name="align-items"
              />
            </template>

            <template #row>
              <sid-buttons-group
                label="Alignment"
                :options="[
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'start',
                    tooltip: 'Start'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'center',
                    tooltip: 'Center'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'end',
                    tooltip: 'End'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'space-between',
                    tooltip: 'Between'
                  },
                  {
                    svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                    value: 'space-around',
                    tooltip: 'Around'
                  }
                ]"
                :sid="`.--${section.sid}--flex`"
                rule-name="justify-content"
              />
            </template>
          </sid-flex-direction>

          <hr>

          <sid-background :sid="`.--${section.sid}--tab`" />

          <sid-font
            :sid="`.--${section.sid}--tab`"
          />

          <sid-range-slider
            label="Font Size"
            :sid="`.--${section.sid}--tab`"
            rule-name="font-size"
          />

          <sid-range-slider
            label="Letter-Spacing"
            :sid="`.--${section.sid}--tab`"
            rule-name="letter-spacing"
          />

          <sid-range-slider
            label="Line-Height"
            :sid="`.--${section.sid}--tab`"
            rule-name="line-height"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${section.sid}--tab`"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--tab`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--tab`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--tab`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--tab`"
            rule-name="border"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />

          <sid-linked-group
            label="Margin"
            :sid="`.--${section.sid}--tab`"
            rule-name="margin"
            :min="-2000"
            :max="2000"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />

          <sid-linked-group
            label="Padding"
            :sid="`.--${section.sid}--tab`"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>
        <template #Active>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${section.sid}--tab.active`"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <sid-background :sid="`.--${section.sid}--tab.active`" />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--tab.active`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--tab.active`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--tab.active`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--tab.active`"
            rule-name="border"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>
        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Text Color</label>

              <sid-color
                :sid="`.--${section.sid}--tab`"
                presudo=":hover"
                rule-name="color"
              />
            </div>
          </b-form-group>

          <sid-background
            :sid="`.--${section.sid}--tab`"
            presudo=":hover"
          />

          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${section.sid}--tab`"
                rule-name="border-style"
                presudo=":hover"
                :searchable="false"
                class="w-[118px]"
                :options="[{label: 'Solid', value: 'solid'}, {label: 'Dashed', value: 'dashed'}, {label: 'Dotted', value: 'dotted'}, {label: 'Double', value: 'double'}, {label: 'Outset', value: 'outset'}]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${section.sid}--tab`"
              label="Border Width"
              rule-name="border"
              presudo=":hover"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${section.sid}--tab`"
                presudo=":hover"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${section.sid}--tab`"
            rule-name="border"
            presudo=":hover"
            :angles="[
              {
                text: '',
                value: 'top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Padding & Margins"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${section.sid}--flex`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
      <sid-linked-group
        label="Margin"
        :sid="`.--${section.sid}--flex`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import RuleTabs from '@/components/editor/components/RuleTabs'
import SidColor from '@/components/editor/components/sid-controls/SidColor'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground'
import ModMixin from '@/components/mixins/ModMixin'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect'
import SidCheckbox from '@/components/editor/components/sid-controls/SidCheckbox'
import SidFlexDirection from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup'
import SidWidthAndHeight from '@/components/editor/components/sid-controls/sid-control-pressets/SidWidthAndHeight'
import draggable from 'vuedraggable'

export default {
  name: 'GridCollapseModV3',
  components: {
    SidWidthAndHeight,
    SidButtonsGroup,
    SidFlexDirection,
    SidCheckbox,
    SidSelect,
    draggable,
    SidLinkedGroup, SidBackground, SidFont, SidRangeSlider, SidColor, RuleTabs, SidbearSettingsGroup},
  mixins: [ModMixin],
  computed: {
    openIndex: {
      get () {
        if (this.section.options.content.gridCollapse.openIndex === null) return -1
        return this.section.options.content.gridCollapse.openIndex || 0
      },
      set (val) {
        this.$set(this.section.options.content.gridCollapse, 'openIndex', val)
      }
    }
  },
  methods: {
    addListItem () {
      this.section.options.content.gridCollapse.items.push({'title': `Tab ${this.section.options.content.gridCollapse.items.length + 1}`, 'elements': []})
    },
    copy (item) {
      this.section.options.content.gridCollapse.items.push(JSON.parse(JSON.stringify(item)))
    },
    onTypeSelect (item, val) {
      if (val.value === 'icon') {
        this.$set(item, 'subject', {'display':true,'name':'Icon','css':[['.--cea967j--QCCucchPN--1 { font-size: 46px; color: rgb(117, 147, 255); margin: 20px 0px; scale: 1; }'],[],[]],'sid':'cea967j--QCCucchPN','sids':['cea967j--QCCucchPN'],'hideFromSections':false,'component':'ElIcon','group':'Media','layout':null,'options':{'content':{'icon':{'type':'icon-mod','display':true,'icon':'fa fa-gem','lottie':{},'iconColor':''}}},'editor':'project','temp_id':'_A1eN2o4V'})
      } else if (val.value === 'image') {
        this.$set(item, 'subject', {'display':true,'name':'Image','css':[['.--cea967j--wY-rho7VI--container { justify-content: center; }'],[],[]],'sid':'cea967j--wY-rho7VI','sids':['cea967j--wY-rho7VI'],'hideFromSections':false,'component':'ElImageV3','group':'Media','layout':null,'options':{'content':{'image':{'display':true,'type':'ImageModV3','src':null,'alt':'Image','modal':{'data':{'modal_id':null}},'animations':{'animation':null,'once':true,'offset':200,'delay':50},'url':''}}},'editor':'project','temp_id':'NYJSAisDp'})
      }
    }
  }
}
</script>
