<template>
  <div
    :class="`justify-content-${align}`"
    class="el-divider d-flex"
  >
    <div :style="{width: `${widthLine}%`}">
      <slot />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import TemplateMixin from '@builder/components/mixins/TemplateMixin'
import BorderMixin from '@/components/mixins/BorderMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin, BorderMixin],
  computed: {
    widthLine () {
      return _.get(this.section.options.content.horizontalLine.widthLine, this.resolutioner, _.get(this.section.options.content.horizontalLine.widthLine, 'lg', this.section.options.content.horizontalLine.widthLine)) || 100
    },
    align: {
      set () {

      },
      get () {
        return _.get(this.section.options.content.horizontalLine, ['align', this.resolutioner], _.get(this.section.options.content.horizontalLine, 'align', 'center'))
      }
    }
  }
}
</script>
