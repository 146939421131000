<template>
  <div class="no-gutters sid-select">
    <b-form-group :class="{'mb-0': !config.action}">
      <v-select
        v-model="config.action"
        :options="[{label: 'No Action', value: null},{value: 'custom-link', label: 'Custom Link'}, {label: 'Pages and Posts', value: 'Link'}, {label: 'Link Rotators', value: 'Random Link'}, {label: 'Modal', value: 'Modal'}, {label: 'Scroll to Container', value: 'Scroll to element'}, {label: 'Click To Call', value: 'call'}, {label: 'Click To Text', value: 'sms'}, {label: 'Click To Email', value: 'mail'}, {label: 'Global Variable Link', value: 'global-variable'}, {label: 'Calendar', value: 'calendar'}, {label: 'Share', value: 'share'}]"
        :reduce="option => option.value"
        :searchable="false"
        class="custom-select"
        left
        placeholder="Select Click Action"
      />
    </b-form-group>

    <b-col
      v-if="config.action === 'global-variable'"
      cols="12"
    >
      <b-form-group>
        <v-select
          v-model="config.customLink"
          :options="variables"
          label="name"
          :reduce="val => val.key"
        />
      </b-form-group>

      <b-form-group class="mb-0">
        <b-checkbox v-model="config.blank_page">
          Open In New Tab
        </b-checkbox>
      </b-form-group>
    </b-col>

    <b-col
      v-if="config.action === 'calendar'"
      cols="12"
    >
      <b-form-group label="Provider">
        <v-select
          v-model="calendar.provider"
          :options="[{label: 'Google', value: 'google'}, {label: 'Outlook', value: 'outlook'}, {label: 'Apple', value: 'apple'}]"
          :reduce="option => option.value"
          :searchable="false"
          class="custom-select"
          left
        />
      </b-form-group>

      <b-form-group label="Timezone">
        <v-select
          v-model="calendar.timeZone"
          :options="timezones"
        />
      </b-form-group>

      <b-form-group label="Start Date">
        <date-picker
          v-model="calendar.start.dateTime"
          :clearable="false"
          :disabled-date="disabledBeforeToday"
          class="w-100"
          format="YYYYMMDDTHH:mm:ss"
          input-class="form-control"
          type="datetime"
          value-type="format"
        />
      </b-form-group>

      <b-form-group label="End Date">
        <date-picker
          v-model="calendar.end.dateTime"
          :clearable="false"
          :disabled-date="disabledBeforeToday"
          class="w-100"
          format="YYYYMMDDTHH:mm:ss"
          input-class="form-control"
          type="datetime"
          value-type="format"
        />
      </b-form-group>

      <b-form-group label="Event Name">
        <b-input v-model="calendar.summary" />
      </b-form-group>

      <b-form-group label="Description">
        <tiptap-v2 v-model="calendar.description" />
      </b-form-group>
    </b-col>

    <b-col
      v-if="config.action === 'share'"
      cols="12"
    >
      <b-form-group label="Provider">
        <v-select
          v-model="share.provider"
          :options="[{label: 'Facebook', value: 'facebook'}, {label: 'Twitter', value: 'twitter'}]"
          :reduce="option => option.value"
          :searchable="false"
          class="custom-select"
          left
        />
      </b-form-group>

      <b-form-group label="Link">
        <div class="position-relative">
          <pages-select-menu
            :others="[{id: 'custom-link', title: 'Custom Link'}, {id: 'global-variable', title: 'Global Variable'}, {id: 'blog', title: 'ESTAGE Blog', code: project.blog_slug}]"
            input-class="rounded-[4px]"
            other-title="Other:"
            @selectOther="item => $set(share, 'page', item)"
            @selectPage="item => $set(share, 'page', item)"
          >
            <template
              v-if="share"
              slot="placeholder"
            >
              <div class="px-3">
                {{ share.page.title }}
              </div>
            </template>
          </pages-select-menu>
        </div>
      </b-form-group>

      <b-form-group
        v-if="share.page && share.page.id === 'custom-link'"
        label="Share URL"
      >
        <b-input
          v-model="share.page.slug"
          placeholder="Current page by default"
        />
      </b-form-group>

      <b-form-group
        v-if="share.page && share.page.id === 'global-variable'"
        label="Global Variable"
      >
        <v-select
          v-model="share.variable"
          label="name"
          :options="variables"
        />
      </b-form-group>
    </b-col>

    <b-col
      v-if="config.action === 'Random Link'"
      cols="12"
    >
      <div
        v-for="(link, index) in randomLinks"
        :key="index"
      >
        <div class="d-flex align-items-center mb-2">
          <b-input
            v-model="link.href"
            class="mr-2"
          />
          <a
            class="hover:opacity-50 transition"
            href="javascript:;"
            @click="() => removeRandomLink(index)"
          >
            <svg
              fill="none"
              height="12"
              viewBox="0 0 12 12"
              width="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 1.05L10.95 0L6 4.95L1.05 0L0 1.05L4.95 6L0 10.95L1.05 12L6 7.05L10.95 12L12 10.95L7.05 6L12 1.05Z"
                fill="#ADB5BD"
              />
            </svg>
          </a>
        </div>
      </div>

      <b-form-group class="mb-0">
        <b-btn
          v-if="randomLinks.length <= 5"
          @click="addRandomLink"
        >
          Add more
        </b-btn>
      </b-form-group>
    </b-col>

    <b-col
      v-if="config.action === 'Link'"
      cols="12"
    >
      <b-form-group>
        <label>Links to</label>
        <div class="position-relative">
          <pages-select-menu
            :others="[{id: 'blog', title: 'ESTAGE Blog', code: project.blog_slug}]"
            input-class="rounded-[4px]"
            other-title="Other:"
            input-placeholder="Type to search pages and posts"
            title="Pages and Posts"
            add-posts
            @selectOther="item => page = item"
            @selectPage="item => page = item"
          >
            <template
              v-if="page"
              slot="placeholder"
            >
              <div class="px-3">
                {{ page.title }}
              </div>
            </template>
          </pages-select-menu>
        </div>
      </b-form-group>
    </b-col>

    <b-col
      v-if="config.action === 'Modal'"
      cols="12"
    >
      <action-modal
        :service="customModal"
        class="mb-0 px-0"
      />
    </b-col>

    <b-col
      v-if="config.action === 'Scroll to element'"
      cols="12"
    >
      <b-form-group
        class="mb-0"
        label="Select Block or Row Only"
      >
        <pick-element
          v-model="config"
          :allow-picks="['ElFlexWrapperV2', 'BlankV2']"
        />
      </b-form-group>

      <!--      <pick-element v-model="config" />-->
    </b-col>

    <b-col
      v-if="config.action === 'custom-link'"
      cols="12"
    >
      <b-form-group
        label="Custom link"
      >
        <b-input
          v-model="config.customLink"
          type="url"
        />
      </b-form-group>
    </b-col>

    <b-col
      v-if="config.action === 'call'"
      cols="12"
    >
      <b-form-group
        label="Phone Number"
        class="phone-number-control mb-0"
      >
        <b-input-group class="align-items-center">
          <template #prepend>
            <div style="width: 60px;">
              <phone-code-select v-model="code" />
            </div>
          </template>
          <div class="dial-code">
            {{ code.dial_code }}
          </div>
          <b-form-input
            v-model="config.tel"
            type="tel"
            :maxlength="15"
            class="!h-[38px]"
          />
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col
      v-if="config.action === 'sms'"
      cols="12"
    >
      <b-form-group
        label="Phone Number"
        class="phone-number-control mb-0"
      >
        <b-input-group class="align-items-center">
          <template #prepend>
            <div style="width: 60px;">
              <phone-code-select v-model="code" />
            </div>
          </template>
          <div class="dial-code">
            {{ code.dial_code }}
          </div>
          <b-form-input
            v-model="config.tel"
            type="tel"
            :maxlength="15"
            class="!h-[38px]"
          />
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col
      v-if="config.action === 'mail'"
      cols="12"
    >
      <b-form-group
        label="Email Address"
        class="mb-0"
      >
        <b-form-input
          v-model="config.mail"
          type="email"
          class="!h-[38px]"
        />
      </b-form-group>
    </b-col>

    <b-col
      v-if="config.action === 'Link' || config.action === 'custom-link'"
      cols="12"
    >
      <b-form-group class="mb-0">
        <b-checkbox v-model="config.blank_page">
          Open In New Tab
        </b-checkbox>
      </b-form-group>
    </b-col>
  </div>
</template>

<script>
import ModControlMixin from '@/components/mixins/ModControlMixin'
import ActionModal from '@/components/builder/utils/auto-responders/ActionModal'
import PagesSelectMenu from '@/components/editor/components/pagesSelectMenu/PagesSelectMenu'
import PhoneCodeSelect from '@/layouts/dashboard/compontens/account/general/components/PhoneCodeSelect'
import {mapState} from 'vuex'
import {toJSON} from '@/utils/helpers'
import TimezonesMixin from '@/components/mixins/TimezonesMixin'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import TiptapV2 from '@/components/builder/utils/TiptapV2.vue'

export default {
  name: 'ButtonAction',
  components: {TiptapV2, PhoneCodeSelect, PagesSelectMenu, ActionModal, DatePicker},
  mixins: [ModControlMixin, TimezonesMixin],
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    code: {
      get () {
        return this.config.code || {'name': 'United States', 'dial_code': '+1', 'code': 'US'}
      },
      set (val) {
        this.$set(this.config, 'code', val)
      }
    },
    randomLinks () {
      if (!this.config.randomLinks) {
        this.$set(this.config, 'randomLinks', [{href: ''}])
      }

      return this.config.randomLinks
    },
    calendar () {
      if (!this.config.calendar) {
        this.$set(this.config, 'calendar', {
          provider: 'google',
          'summary': '',
          'location': '',
          'description': '',
          'timeZone': 'America/Los_Angeles',
          'start': {
            'dateTime': ''
          },
          'end': {
            'dateTime': ''
          }
        })
      }

      return this.config.calendar
    },

    share () {
      if (!this.config.share) {
        this.$set(this.config, 'share', {
          provider: 'facebook',
          page: {},
          variable: ''
        })
      }

      return this.config.share
    },

    customModal () {
      if (!this.config.hasOwnProperty('modal')) {
        this.$set(this.config, 'modal', {
          data: {
            modal_id: null
          }
        })
      }

      return this.config.modal
    },
    page: {
      get () {
        return this.config.page || null
      },
      set (val) {
        this.$set(this.config, 'page', val)
      }
    },
    variables () {
      return toJSON(this.project.variables)
    }
  },
  methods: {
    disabledBeforeToday (date) {
      const today = this.moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm')

      return this.moment(date).isBefore(today)
    },
    removeRandomLink (index) {
      this.randomLinks.splice(index, 1)
    },
    addRandomLink () {
      if (this.randomLinks.length > 5) return
      this.randomLinks.push({href: ''})
    }
  }
}
</script>
