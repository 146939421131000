import shortid from 'shortid'
import _ from 'lodash'
import {toStringify} from '../../utils/helpers'

const state = () => ({
  allFormControls: [],
  avaliableForms: [],
  wizard: {
    confirmation: false,
    currentStep: 0,
    count: 2
  }
})

const getters = {
  getAvaliableForms (state) {
    return state.avaliableForms
  }
}

const actions = {
  resetFormsAndControls ({state}) {
    state.allFormControls = []
    state.avaliableForms = []
  },
  setWizardConfirmation ({state}, payload) {
    state.wizard.confirmation = payload
  },
  setWizardCurrentStep ({state}, payload) {
    state.wizard.currentStep = payload
  },
  setWizardCount ({state}, payload) {
    state.wizard.count = payload
  },
  addFormField ({state}, payload) {
    if (state.allFormControls.findIndex(control => control.section.options.content.input.inputUID === payload.section.options.content.input.inputUID) !== -1) {
      payload.section.options.content.input.inputUID = shortid.generate()
      payload.section.options.content.input.relatedUID = null
    }

    state.allFormControls.push(payload)
  },
  addAvaliableButton ({state}, payload) {
    if (state.avaliableForms.findIndex(button => button.options.content.button.buttonUID === payload.options.content.button.buttonUID) !== -1) {
      payload.options.content.button.buttonUID = shortid.generate()
    }

    state.avaliableForms.push(payload)
  },
  removeAvaliableButton ({state}, payload) {
    const index = state.avaliableForms.findIndex(button => button.options.content.button.buttonUID === payload.options.content.button.buttonUID)

    if (index !== -1) {
      state.allFormControls.filter(control => control.section.options.content.input.relatedUID === payload.options.content.button.buttonUID)
        .forEach(control => control.options.content.input.relatedUID = null)

      state.avaliableForms.splice(index, 1)
    }
  },
  removeFormField ({state}, payload) {
    const index = state.allFormControls.findIndex(control => control.section.options.content.input.inputUID === payload.options.content.input.inputUID)

    if (index !== -1) {
      state.allFormControls.splice(index, 1)
    }
  },
  async createForm ({rootState, dispatch}, payload = []) {
    const PromiseBuffer = []
    let form = await axios.post(`api/projects/${rootState.projects.project.id}/forms`, {
      name: 'New Form',
      actions: '{}',
      is_active: true,
      columns: toStringify({
        lg: 12,
        md: 12,
        sm: 12
      })
    })

    payload.forEach(control => {
      PromiseBuffer.push(dispatch('addControl', {
        form_id: form.data.data.id, ...control,
        columns: toStringify({lg: 12, md: 12, sm: 12})
      }))
    })

    let controls = await Promise.all(PromiseBuffer)

    return {
      form: form.data.data,
      controls: controls.map(control => {
        return {
          ...control.data.data,
          mapping: []
        }
      })
    }
  },
  async addControl ({rootState}, control) {
    return await axios.post(`api/projects/${rootState.projects.project.id}/forms/${control.form_id}/form-fields`, {
      ...control,
      mapping: [],
      validators:'[]'
    })
  },
  async getForm ({rootState}, payload) {
    return await axios.get(`api/projects/${rootState.projects.project.id}/forms/${payload}`)
  },
  async fetchControls ({rootState}, payload) {
    return await axios.get(`api/projects/${rootState.projects.project.id}/forms/${payload}/form-fields`)
  },
  async publicGetForm ({rootState}, payload) {
    return await axios.get(`api/site/${rootState.projects.project.subdomain}/form/${payload}`)
  },
  async saveForm ({rootState}, payload) {
    await axios.put(`api/projects/${rootState.projects.project.id}/forms/${payload.form.id}`, {
      ...payload.form,
      actions: JSON.stringify(payload.form.actions)
    })

    const mapping = _.reduce(_.flatten(payload.controls.map(c => c.mapping)), (acc, mapped) => {
      (acc[mapped.auto_responder_id] || (acc[mapped.auto_responder_id] = {
        auto_responder_id: mapped.auto_responder_id,
        fields: []
      })).fields.push({
        ...mapped,
        id: mapped.field_id
      })
      return acc
    }, {})

    const PromiseBuffer = []

    for (let mapped in mapping) {
      PromiseBuffer.push(axios.put(`api/projects/${rootState.projects.project.id}/forms/${payload.id}/form-fields/mapping`, mapping[mapped]))
    }

    payload.controls.forEach((control, index) => {
      PromiseBuffer.push(axios.put(`api/projects/${rootState.projects.project.id}/forms/${payload.id}/form-fields/${control.id}`, {
        ...control,
        columns: Object.keys(control.columns).length ? toStringify(control.columns) : toStringify({
          lg: control.columns,
          md: control.columns,
          sm: control.columns
        }),
        order: index
      }))
    })

    return await Promise.all(PromiseBuffer)
  },
  async deleteControl ({rootState}, {form_id, field_id}) {
    return await axios.delete(`api/projects/${rootState.projects.project.id}/forms/${form_id}/form-fields/${field_id}`)
  }
}

const mutations = {}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
