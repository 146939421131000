<template>
  <div class="sid-select w-full px-[16px]">
    <Sidbear-settings-group
      title="Summary Title"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: '0 -16px'}"
    >
      <sid-font :sid="`.payment-summary-title.--${element.sid}--title`" />

      <sid-range-slider
        label="Font Size"
        :sid="`.payment-summary-title.--${element.sid}--title`"
        default-dimension="px"
        :px-range="[0, 300]"
        rule-name="font-size"
      />

      <sid-range-slider
        label="Letter-Spacing"
        :sid="`.payment-summary-title.--${element.sid}--title`"
        rule-name="letter-spacing"
        :min="-2"
        :dimensions="[]"
      />

      <sid-range-slider
        label="Line-Height"
        :sid="`.payment-summary-title.--${element.sid}--title`"
        rule-name="line-height"
        :default-dimension="''"
        :min="0"
        :max="5"
        :step=".1"
        var="--line-height"
        :dimensions="[]"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            :sid="`.payment-summary-title.--${element.sid}--title`"
            rule-name="color"
          />
        </div>
      </b-form-group>
    </Sidbear-settings-group>
    
    <Sidbear-settings-group
      title="Product Title"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: '0 -16px'}"
    >
      <sid-font :sid="`.payment-summary-product.--${element.sid}--product`" />

      <sid-range-slider
        label="Font Size"
        :sid="`.payment-summary-product.--${element.sid}--product`"
        default-dimension="px"
        :px-range="[0, 300]"
        rule-name="font-size"
      />

      <sid-range-slider
        label="Letter-Spacing"
        :sid="`.payment-summary-product.--${element.sid}--product`"
        rule-name="letter-spacing"
        :min="-2"
        :dimensions="[]"
      />

      <sid-range-slider
        label="Line-Height"
        :sid="`.payment-summary-product.--${element.sid}--product`"
        rule-name="line-height"
        :default-dimension="''"
        :min="0"
        :max="5"
        :step=".1"
        var="--line-height"
        :dimensions="[]"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            :sid="`.payment-summary-product.--${element.sid}--product`"
            rule-name="color"
          />
        </div>
      </b-form-group>
    </Sidbear-settings-group>
    
    <Sidbear-settings-group
      title="Product Price"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: '0 -16px'}"
    >
      <sid-font :sid="`.payment-summary-price.--${element.sid}--price`" />

      <sid-range-slider
        label="Font Size"
        :sid="`.payment-summary-price.--${element.sid}--price`"
        default-dimension="px"
        :px-range="[0, 300]"
        rule-name="font-size"
      />

      <sid-range-slider
        label="Letter-Spacing"
        :sid="`.payment-summary-price.--${element.sid}--price`"
        rule-name="letter-spacing"
        :min="-2"
        :dimensions="[]"
      />

      <sid-range-slider
        label="Line-Height"
        :sid="`.payment-summary-price.--${element.sid}--price`"
        rule-name="line-height"
        :default-dimension="''"
        :min="0"
        :max="5"
        :step=".1"
        var="--line-height"
        :dimensions="[]"
      />

      <b-form-group>
        <div class="flex items-center justify-between">
          <label class="pb-0">Text Color</label>

          <sid-color
            :sid="`.payment-summary-price.--${element.sid}--price`"
            rule-name="color"
          />
        </div>
      </b-form-group>
    </Sidbear-settings-group>

    <SidbearSettingsGroup
      title="Background"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: '0 -16px'}"
    >
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <sid-background
              :sid="`.--${element.sid}--margin`"
              small
            />
          </b-form-group>
        </template>
        <template #Hover>
          <b-form-group>
            <sid-background
              :sid="`.--${element.sid}--margin`"
              presudo=":hover"
              small
            />
          </b-form-group>
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup
      title="Border & Radius"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: '0 -16px'}"
    >
      <rule-tabs>
        <template #Normal>
          <b-form-group>
            <div class="flex items-center justify-between !gap-[20px]">
              <label class="w-full pb-0">Border Type</label>

              <sid-select
                :sid="`.--${element.sid}--margin`"
                rule-name="border-style"
                :searchable="false"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  },
                  {
                    label: 'Dashed',
                    value: 'dashed'
                  },
                  {
                    label: 'Dotted',
                    value: 'dotted'
                  },
                  {
                    label: 'Double',
                    value: 'double'
                  },
                  {
                    label: 'Outset',
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${element.sid}--margin`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${element.sid}--margin`"
                rule-name="border-color"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${element.sid}--margin`"
            rule-name="border"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
        <template #Hover>
          <b-form-group>
            <div class="flex items-center justify-between">
              <label class="pb-0">Border Type</label>

              <sid-select
                :sid="`.--${element.sid}--margin`"
                rule-name="border-style"
                :searchable="false"
                class="w-[118px]"
                presudo=":hover"
                :options="[
                  {
                    label: 'Solid',
                    value: 'solid'
                  },
                  {
                    label: 'Dashed',
                    value: 'dashed'
                  },
                  {
                    label: 'Dotted',
                    value: 'dotted'
                  },
                  {
                    label: 'Double',
                    value: 'double'
                  },
                  {
                    label: 'Outset',
                    value: 'outset'
                  }
                ]"
              />
            </div>
          </b-form-group>

          <b-form-group>
            <sid-linked-group
              :sid="`.--${element.sid}--margin`"
              label="Border Width"
              rule-name="border"
              class="!mb-[7px]"
              presudo=":hover"
              :angles="[
                {
                  text: '',
                  value: 'top-width'
                },
                {
                  text: '',
                  value: 'right-width'
                },
                {
                  text: '',
                  value: 'bottom-width'
                },
                {
                  text: '',
                  value: 'left-width'
                }
              ]"
            />

            <div class="flex w-full justify-end">
              <sid-color
                :sid="`.--${element.sid}--margin`"
                rule-name="border-color"
                presudo=":hover"
              />
            </div>
          </b-form-group>

          <sid-linked-group
            label="Radius"
            :sid="`.--${element.sid}--margin`"
            rule-name="border"
            presudo=":hover"
            :titles="['top-l', 'top-r', 'bottom-l', 'bottom-r']"
            :angles="[
              {
                text: '',
                value: 'top-left-radius',
                cssVar: '--border-top-left-radius'
              },
              {
                text: '',
                value: 'top-right-radius',
                cssVar: '--border-top-right-radius'
              },
              {
                text: '',
                value: 'bottom-left-radius',
                cssVar: '--border-bottom-left-radius'
              },
              {
                text: '',
                value: 'bottom-right-radius',
                cssVar: '--border-bottom-right-radius'
              }
            ]"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    
    <SidbearSettingsGroup
      title="Padding & Margins"
      button-class="!px-[16px]"
      content-class="!py-[14px] !px-[16px]"
      :style="{margin: '0 -16px'}"
    >
      <sid-linked-group
        label="Padding"
        :sid="`.--${element.sid}--container`"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="`.--${element.sid}--margin`"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'
import SidSelect from '@/components/editor/components/sid-controls/SidSelect.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidBackground
  from '@/components/editor/components/sid-controls/sid-control-pressets/background-color/SidBackground.vue'
export default {
  name: 'PaymentSummarySettings',
  components: {
    SidBackground,
    RuleTabs, SidSelect,
    SidLinkedGroup,
    SidbearSettingsGroup,
    SidRangeSlider,
    SidColor,
    SidFont
  },
  props: {
    element: {
      type: Object,
      required: true
    }
  }
}
</script>