<template>
  <b-col v-bind="options">
    <component
      :is="node.component"
      v-for="(node, index) in children"
      :key="index"
      :options="node.options"
      :children="node.children"
    />
  </b-col>
</template>

<script>
import ComponentProvider from '@builder/components/mixins/ComponentProvider'

export default {
  name: 'EsCol',

  mixins: [ComponentProvider]
}
</script>
