<template>
  <highliter
    v-model="section.name"
    :dublicate="section"
    :dublicate-deep="parentUID"
    class="highlight-section"
    @delete="$emit('delete')"
    @dublicate="$emit('dublicate')"
    @favorite="$emit('favorite')"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @add-subject="$emit('add-subject')"
    @click.stop.native="!section.options.content.items.children.length ? addRow() : sectionSettings('block-mod')"
  >
    <div
      class="w-full el-container relative"
      :data-sid="section.sid"
      :class="[`--${section.sid}--wrapper`, {'element-hidden': !visability()}, {'empty-section-drag': !section.options.content.items.children.length}]"
    >
      <div
        class="block-bg inherit-radius"
        :class="`--${section.sid}--bg`"
      />

      <background-video
        v-if="section.options.backgroundVideo.url && section.options.backgroundVideo.enabled"
        :options="section.options.backgroundVideo"
        :class="`--${section.sid}--video`"
        class="inherit-radius"
      />

      <background-slider-v2
        :options="section.options.backgroundSlider"
        :class="`--${section.sid}--bg bg inherit-radius`"
      />

      <div
        class="el-overlay inherit-radius"
      />

      <img
        class="float-image-wrapper"
        alt="Float Image"
      >

      <shape-divider
        :config="section.options"
      />

      <div class="flex justify-center position-relative">
        <div
          :class="`--${section.sid}--container`"
          class="align-items-center el-block max-w-[70vw] padding-20 block-container w-full h-full"
        >
          <draggable
            v-model="section.options.content.items.children"
            :group="{ name: 'rows', pull: true, put: ['rows'] }"
            class="contents"
            handle=".move"
            ghost-class="rows-sortable-ghost"
            :scroll="true"
            :force-fallback="true"
            fallback-class="rows-sortable-fallback"
            :force-autoscroll-fallback="true"
            :scroll-sensitivity="60"
            :bubble-scroll="true"
            :fallback-on-body="true"
            @end="onDragEnd"
            @start="onDragStart"
            @add="onAddedRow"
          >
            <component
              :is="section.component"
              v-for="(section, index) in section.options.content.items.children"
              :key="section.uid"
              :section="section"
              @delete="() => deleteSection(index)"
              @dublicate="() => duplicateElement(index)"
              @flow="handleFlow"
              @add-subject="() => addSubject(index)"
              @favorite="() => favoriteBlock = JSON.parse(JSON.stringify(section))"
              @move-to="to => moveTo(index, to)"
            >
              <slot />
            </component>
          </draggable>
        </div>
      </div>
    </div>

    <add-to-favorite-modal
      :favorite="favoriteBlock"
      @submit="favorite"
    />
  </highliter>
</template>

<script>
import Highliter from '@/components/builder/utils/Highliter'
import SectionMixin from '@/components/mixins/SectionMixin'
import {insert} from '@/sections/utils/helpers'
import shortid from 'shortid'
import draggable from 'vuedraggable'
import DraggableElementsHandler from '@/components/mixins/DraggableElementsHandler'
import BackgroundVideo from '@/components/builder/utils/background-video/BackgroundVideo'
import ShapeDivider from '@/components/editor/components/shape-divider/ShapeDivider'
import BackgroundSliderV2 from '@/components/builder/utils/BackgroundSliderV2'
import AddToFavoriteModal from '@/components/builder/components/modals/AddToFavoriteModal'

export default {
  name: 'BlankV2',
  components: {AddToFavoriteModal, BackgroundSliderV2, ShapeDivider, BackgroundVideo, Highliter, draggable},
  mixins: [SectionMixin, DraggableElementsHandler],

  data () {
    return {
      favoriteBlock: {}
    }
  },

  methods: {
    addRow () {
      this.$store.commit('editor/SET_PAGE', {
        name: 'add-row',
        event: 'click',
        subject: {
          subject: this.section.options.content.items,
          options: {subject: this, originKey: 'children'}
        }
      })
    },
    favorite (element) {
      this.$store.dispatch('favorites/addFavorite', {
        favorite: {
          section: element.favorite,
          group: 'rows'
        },
        inGlobal: element.globally,
        node: this.$el
      })
    },
    onAddedRow ({newIndex}) {
      if (this.section.options.content.items.children[newIndex].name !== 'Flex Wrapper') return

      this.$store.commit('editor/SET_PAGE', {
        name: 'add-element',
        event: 'click',
        subject: {
          subject: this.section.options.content.items.children[newIndex].options.content.cols[0],
          options: {subject: this, originKey: 'elements'}
        }
      })
    },
    deleteSection (section) {
      this.section.options.content.items.children.splice(section, 1)
    },
    addSubject (index) {
      this.$store.commit('editor/SET_PAGE', {
        name: 'add-row',
        event: 'click',
        subject: {
          subject: this.section.options.content.items,
          options: {index, originKey: 'children'}
        }
      })
    },
    duplicateElement (element) {
      this.section.options.content.items.children = insert(this.section.options.content.items.children, element, JSON.parse(JSON.stringify({
        ...this.section.options.content.items.children[element],
        uid: shortid.generate()
      })))
    },
    moveTo (element, to) {
      const copySection = JSON.parse(JSON.stringify(this.section.options.content.items.children[element]))

      if (to === 'up') {
        if (element === 0) return
        this.section.options.content.items.children.splice(element, 1)
        this.section.options.content.items.children = insert(this.section.options.content.items.children, element - 1, copySection)
      } else if (to === 'down') {
        if (element + 1 === this.section.options.content.items.children.length) return
        this.section.options.content.items.children.splice(element, 1)
        this.section.options.content.items.children = insert(this.section.options.content.items.children, element + 1, copySection)
      }
    }
  }
}
</script>