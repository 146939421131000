<template>
  <settings-page-card>
    <template slot="title">
      <div class="px-4">
        <div class="d-flex align-items-center justify-content-between mb-4">
          <h5 class="title font-weight-bold">
            Restrictions
          </h5>

          <edit-password-modal @submited="fechList" />
        </div>
      </div>
    </template>

    <div class="px-4">
      <b-list-group v-if="!loading">
        <b-list-group-item
          v-for="password in passwords"
          :key="password.id"
        >
          <page-password
            :password="password"
            @deleted="fechList"
          />
        </b-list-group-item>
      </b-list-group>

      <div
        v-else
        class="text-center py-5"
      >
        <b-spinner variant="primary" />
      </div>
    </div>
  </settings-page-card>
</template>

<script>
import SettingsPageCard from '../../layouts/dashboard/SettingsPageCard'
import {mapState} from 'vuex'
import PagePassword from '../../components/editor/components/PagePassword'
import EditPasswordModal from '../../components/editor/components/EditPasswordModal'

export default {
  name: 'PageRestrictions',

  components: {EditPasswordModal, PagePassword, SettingsPageCard},

  data () {
    return {
      loading: true,
      passwords: []
    }
  },

  computed: {
    ...mapState('projects', {
      project: state => state.project
    })
  },

  created () {
    this.fechList()
  },

  methods: {
    fechList () {
      axios.get(`api/projects/${this.project.id}/passwords`)
        .then(({data}) => {
          this.passwords = data.data.data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
