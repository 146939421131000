import { Mark } from 'tiptap'
import { updateMark, removeMark } from 'tiptap-commands'

export default class FontFamily extends Mark {

  get name () {
    return 'fontfamily'
  }

  get defaultOptions () {
    return {
      fontFamily: ['']
    }
  }

  get schema () {
    return {
      attrs: {
        fontFamily: {
          default: ''
        }
      },
      parseDOM: this.options.fontFamily.map(() => ({
        tag: 'spanec',
        getAttrs (dom){
          return {
            fontFamily:dom.style.fontFamily
          }
        }
      })),
      toDOM:
        node => {
          return ['spanec', {
            style: `font-family:${node.attrs.fontFamily}`
          }, 0]
        }
    }
  }

  commands ({ type }) {
    return (attrs) => {
      if (!attrs.fontFamily) {
        return removeMark(type, attrs)
      }

      return updateMark(type, attrs)
    }
  }
}
