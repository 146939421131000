export default {
  data () {
    return {
      enableSync: false,
      display: {display: true}
    }
  },
  computed: {
    getDisplays () {
      if (!this.enableSync) return [this.resolutioner ? this.resolutioner : this.resolution]

      return this.display.display === true ? (this.resolutioner ? ['sm', 'md', 'lg'] : ['sm', 'lg']) : this.display.display
    }
  },

  watch: {
    config () {
      this.enableSync = false
      this.display = {display: true}
    },
    resolutioner () {
      this.enableSync = false
      this.display = {display: true}
    },
    resolution () {
      this.enableSync = false
      this.display = {display: true}
    }
  }
}