import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Divider'
export const group = 'Basic'

export const defaultOptions = {
  content: {
    horizontalLine: {
      type: 'horizontal-line-mod',
      display: true,
      color: '#AEB1B4',
      width: '2px',
      pilled: false
    }
  },
  customize: {
    style: {
      background: null,
      shadow: null,
      padding: {
        type: 'height-customs',
        sm: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        },
        md: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        },
        lg: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        }
      }
    }
  }
}

export const icon = `<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<rect y="6" width="21" height="3" rx="1.5" fill="url(#pattern0)"/>
<defs>
<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
<use xlink:href="#image0" transform="translate(-0.047619) scale(0.047619 0.333333)"/>
</pattern>
<image id="image0" width="23" height="3" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAADCAYAAAB4bZQtAAAAJ0lEQVQoFWNgYGBoZWBg+MnAwPCfihhkHshchs9UNBTZgSBzaedyAO8CQqRURGOWAAAAAElFTkSuQmCC"/>
</defs>
</svg>
`
export default class ElDivider extends SectionProvider {
  constructor (options = {}) {
    super('ElDivider', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
