<template>
  <div>
    <b-form-group :label="`${prefix} Font family`">
      <v-select
        v-model="familyMutated"
        :options="fonts"
        class="custom-select mb-[10px]"
        left
        placeholder="Global Style"
        @option:selected="onSelect"
      >
        <template #selected-option="{label}">
          <div
            :style="{fontFamily: label === '' ? 'Proxima Nova' : label}"
            v-text="label"
          />
        </template>

        <template #option="{label}">
          <div
            :style="{fontFamily: label}"
            v-text="label || 'Global Style'"
          />
        </template>
      </v-select>

      <div class="w-[77px]">
        <b-link
          :to="{name:'settings.fonts'}"
          class="text-decoration-none w-[76px] mb-[12px]"
        >
          <span
            class="flex w-[76px] relative text-[#333333] text-[13px] cursor-pointer hover:opacity-80 line-height-[13px]"
          >
            Font Settings
            <span class="absolute w-full h-[1px] bg-[#333333] left-0 bottom-0 translate-y-[-1px]" />
          </span>
        </b-link>
      </div>
    </b-form-group>

    <sid-font-weight
      ref="fontWeight"
      :sid="sid"
      :prefix="prefix"
      :presudo="presudo"
      rule-name="font-weight"
      :family-mutated="familyMutated"
      :editor="editor"
      class="mb-0"
      :selected-font-weight="selectedFontWeight"
      @onFontWeightChange="val => $emit('onFontWeightChange', val)"
    />

    <span
      class="mt-2 block text-[#333333] underline text-[13px] cursor-pointer hover:opacity-80 line-height-[13px] mb-4"
      @click="reset"
    >
      Reset Font Settings
    </span>
  </div>
</template>

<script>
import SidConcept from '@/components/mixins/SidConcept'
import {mapState} from 'vuex'
import _ from 'lodash'
import SidFontWeight from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFontWeight'

export default {
  name: 'SidFontFamily',
  components: {SidFontWeight},
  mixins: [SidConcept],
  props: {
    prefix: {
      type: String,
      default: 'prefix'
    },
    editor: {
      type: Boolean,
      default: false
    },
    selectedFont: {
      default: ''
    },
    selectedFontWeight: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      family: ''
    }
  },
  computed: {
    ...mapState('editor', {
      globalStyles: state => state.globalStyles
    }),
    ...mapState('font', {
      allCustomFonts: state => state.allCustomFonts
    }),
    addedGoogleFonts () {
      return this.globalStyles.font.addedGoogleFonts
    },
    fonts () {
      const fonts = []

      fonts.push('')
      fonts.push(...this.allCustomFonts.map(font => font.font_name))
      fonts.push(...this.addedGoogleFonts.map(font => font.family))

      return _.uniq(fonts).sort()
    },
    familyMutated: {
      get () {
        return (this.selectedFont || this.family || this.sidValueMutation).replaceAll('"', '')
      },
      set (val) {
        this.family = val

        if (!this.editor) {
          this.apply(val)
        }
      }
    }
  },
  watch: {
    resolutioner () {
      this.family = ''
    },
    sid () {
      this.family = ''
    },
    selectedFont (val) {
      if (!val) this.family = ''
    }
  },
  methods: {
    apply (val) {
      this.addSidRules([[this.getSid(), [this.ruleName, val]]])
    },
    onSelect (option) {
      this.$emit('onFontChange', {
        val: option,
        apply: () => this.apply(option)
      })
    },
    reset () {
      this.family = ''
      this.addSidRules([[this.getSid(), ['font-family', '']]])
      this.addSidRules([[this.getSid(), ['font-weight', '']]])
      this.$refs.fontWeight.reset()
    }
  }
}
</script>