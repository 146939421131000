import SectionProvider from '@builder/sections/SectionProvider'

export const name = 'Post Text'
export const group = 'Dynamic blog'
export const beta = false

export const defaultOptions = {
  content: {
    data: {
      post: '',
      type: 'featured-post-text-mod',
      maxWords: null
    }
  }
}

export const icon = ''

export default class ElBogFeaturedPostText extends SectionProvider {
  constructor (options = {}) {
    super('ElFeaturedPostText', options)

    this.name = name
    this.group = group
    this.defaultOptions = defaultOptions
  }
}
