<template>
  <div>
    <b-form-group>
      <b-form-radio
        v-model="config.scrollType"
        :value="null"
      >
        <div class="pt-1">
          No Effects
        </div>
      </b-form-radio>
      <b-form-radio
        v-model="config.scrollType"
        value="sticky"
      >
        <div class="pt-1">
          Sticky Menu
        </div>
      </b-form-radio>
      <b-form-radio
        v-model="config.scrollType"
        value="shrink"
      >
        <div class="pt-1">
          Sticky Menu (shrink on scroll)
        </div>
      </b-form-radio>
      <b-form-radio
        v-model="config.scrollType"
        value="reveal"
      >
        <div class="pt-1">
          Reveal on Scroll Up
        </div>
      </b-form-radio>
    </b-form-group>

    <template v-if="config.scrollType">
      <b-form-group>
        <b-checkbox
          v-model="blur"
          :value="1"
        >
          Blur
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <shadow-customs :config="config.onScrollStyles" />
      </b-form-group>

      <!--      <b-form-group>-->
      <!--        <border-customs-v2-->
      <!--          :config="borderCustoms"-->
      <!--          :default-border="[0,0,0,0]"-->
      <!--          :default-radius="[0,0,0,0]"-->
      <!--        />-->
      <!--      </b-form-group>-->

      <div class="relative">
        <div
          v-if="blur"
          class="absolute top-0 left-0 bg-white w-100 h-100 z-index-100 opacity-80"
        />
        <background-customs :config="backgroundColor" />
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
import ControlMixin from '../../mixins/ControlMixin'

export default {
  mixins: [ControlMixin],
  computed: {
    onScrollStyles () {
      if (!this.config.onScrollStyles) {
        this.$set(this.config, 'onScrollStyles', {})
      }

      return this.config.onScrollStyles
    },
    borderCustoms () {
      if (!this.onScrollStyles.border) {
        this.$set(this.onScrollStyles, 'border', [0,0,0,0])
      }

      if (!this.onScrollStyles.radius) {
        this.$set(this.onScrollStyles, 'radius', {
          lg: [0, 0, 0, 0],
          md: [0, 0, 0, 0],
          sm: [0, 0, 0, 0]
        })
      }

      return this.onScrollStyles
    },
    backgroundColor () {
      if (!_.get(this.config, ['onScrollStyles', 'background', 'lg'], null)) {
        this.$set(this.config, 'onScrollStyles', {
          background: {
            lg: {
              background: '',
              backgroundType: null
            }
          }
        })
      }

      return this.config.onScrollStyles.background.lg
    },
    blur: {
      get () {
        return _.get(this.config.onScrollStyles, ['blur', 'lg'], 0)
      },
      set (val) {
        this.$set(this.config, 'onScrollStyles', {..._.set(this.config.onScrollStyles || {}, ['blur', 'lg'], val)})
      }
    }
  }
}
</script>
