<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <p class="text-capitalize mb-0">
        Grid
      </p>
    </div>

    <b-form-group>
      <label>Columns on mobile ({{ config.options.customize.cols.sm }})</label>
      <b-input
        v-model="config.options.customize.cols.sm"
        :disabled="resolution === 'lg'"
        type="range"
        :min="1"
        max="12"
      />
    </b-form-group>

    <b-form-group>
      <label>Columns on desktop ({{ config.options.customize.cols.lg }})</label>
      <b-input
        v-model="config.options.customize.cols.lg"
        :disabled="resolution === 'sm'"
        type="range"
        :min="1"
        max="12"
      />
    </b-form-group>


    <b-form-group>
      <label>Offset on mobile ({{ config.options.customize.offset.sm }})</label>
      <b-input
        v-model="config.options.customize.offset.sm"
        :disabled="resolution === 'lg'"
        type="range"
        min="0"
        :max="config.options.customize.cols.sm"
      />
    </b-form-group>

    <b-form-group>
      <label>Offset on desktop ({{ config.options.customize.offset.lg }})</label>
      <b-input
        v-model="config.options.customize.offset.lg"
        :disabled="resolution === 'sm'"
        type="range"
        min="0"
        :max="config.options.customize.cols.lg"
      />
    </b-form-group>

    <template v-if="config.options.customize.hasOwnProperty('order')">
      <b-form-group>
        <label>Order on mobile ({{ config.options.customize.order.sm }})</label>
        <b-input
          v-model="config.options.customize.order.sm"
          :disabled="resolution === 'lg'"
          type="range"
          min="0"
          :max="config.options.customize.cols.sm"
        />
      </b-form-group>

      <b-form-group>
        <label>Order on desktop ({{ config.options.customize.order.lg }})</label>
        <b-input
          v-model="config.options.customize.order.lg"
          :disabled="resolution === 'sm'"
          type="range"
          min="0"
          :max="config.options.customize.cols.lg"
        />
      </b-form-group>
    </template>
  </div>
</template>

<script>
import ModMixin from '@builder/components/mixins/ModMixin'

export default {
  name: 'ButtonMod',
  mixins: [ModMixin],
  data () {
    return {
      cols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    }
  }
}
</script>
