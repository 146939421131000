<template>
  <div
    :data-sid="`form-field-${sid}`"
    :class="`--${section.sid}--margin`"
  >
    <highliter
      v-model="section.name"
      section-type="element"
      class="highlight-element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <b-form-group
        class="estage-form-element-group mb-0"
        :class="`--${sid}--form-field`"
      >
        <template
          v-if="control.label"
          #label
          class="flex items-center gap-x-[4px]"
          :class="`--${sid}--label`"
        >
          {{ control.label }}
          <span
            v-if="control.is_required"
            class="text-danger"
          >
            *
          </span>
        </template>

        <legend class="d-none active" />

        <b-form-input
          v-model="control.value"
          :placeholder="control.placeholder"
          :required="!!control.is_required"
          v-bind="$attrs"
          type="number"
          class="input"
          :class="`--${sid}--input`"
        />
      </b-form-group>
    </highliter>
  </div>
</template>

<script>
import BorderMixin from '@/components/mixins/BorderMixin'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
export default {
  name: 'ElFieldInput',
  mixins: [BorderMixin, SectionMixin],
  components: {Highliter},
  computed: {
    control () {
      return this.section.options.content.control
    }
  }
}
</script>