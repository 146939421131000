<template>
  <highliter
    v-model="section.name"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings(true)"
  >
    <VideoItems4Template :section="section">
      <template slot="video">
        <highliter
          v-show="visability('video')"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('video')"
        >
          <video-play :customs="section.options.content.video" />
        </highliter>
      </template>

      <template slot="title">
        <highliter
          v-show="visability('title')"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('title')"
        >
          <content-editable
            v-model="section.options.content.title.text"
            :uid="tiptapUID('title')"
            :customs="section.options.content.title"
          />
        </highliter>
      </template>


      <template slot="subtitle">
        <highliter
          v-show="visability('subtitle')"
          @favorite="$emit('favorite', $el)"
          @flow="handleFlow"
          @move-to="to => $emit('move-to', to)"
          @dublicate="$emit('dublicate')"
          @delete="$emit('delete')"
          @click.stop.native="sectionSettings('subtitle')"
        >
          <content-editable
            v-model="section.options.content.subtitle.text"
            :uid="tiptapUID('subtitle')"
            :customs="section.options.content.subtitle"
          />
        </highliter>
      </template>
    </VideoItems4Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditable from '@builder/components/builder/utils/ContentEditable'
import VideoItems4Template from '@builder/templates/sections/videos/VideoItems4Template'
import VideoPlay from '../../utils/VideoPlay'

export default {
  name: 'VideoItem3',

  components: {VideoPlay, VideoItems4Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  created () {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>
