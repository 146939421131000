<template>
  <div>
    <div
      v-if="loaded && project"
      class="editor select-none"
    >
      <router-view />
      <new-section-modal />
      <new-link-modal />
      <new-page-modal />
      <custom-modal />
      <injection-page-modal />
    </div>
  </div>
</template>

<script>
import NewSectionModal from '@builder/components/editor/modals/NewSectionModal'
import NewLinkModal from '../../components/editor/modals/NewLinkModal'
import {mapState} from 'vuex'
import NewPageModal from '../../components/editor/modals/NewPageModal'
import InjectionPageModal from '../../components/editor/modals/InjectionPageModal'
import CodeInjectionPageMixin from '../../components/mixins/CodeInjectionPageMixin'
import CustomModal from '../../components/editor/modals/CustomModal'

export default {
  name: 'Builder',
  components: {
    CustomModal,
    InjectionPageModal,
    NewPageModal, NewLinkModal, NewSectionModal},

  mixins: [CodeInjectionPageMixin],

  computed: {
    ...mapState('projects', {
      loaded: state => state.loaded,
      project: state => state.project
    })
  },

  created () {
    VEvent.fire('loader', true)

    this.$store.dispatch('projects/load')
      .then(() => {
        this.$store.dispatch('apiIntegration/getlAllMailServices')
      })
      .finally(() => {
        VEvent.fire('loader', false)
      })
  }
}
</script>
