<template>
  <highliter
    v-model="section.name"
    :uid="section.uid"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings('group')"
  >
    <VideoItems3Template
      class="hide-add hide-copy"
      :section="section"
    >
      <template>
        <b-col :cols="cols(9, 12)">
          <highliter
            @favorite="$emit('favorite', $el)"
            @flow="handleFlow"
            @move-to="to => $emit('move-to', to)"
            @dublicate="$emit('dublicate')"
            @delete="$emit('delete')"
            @click.stop.native="$store.dispatch('editor/sectionSettings', {section: section.options.content.group.items[preview], openedItem: 'video'})"
          >
            <video-play :customs="section.options.content.group.items[preview].options.content.video" />
          </highliter>
          <div class="my-3" />

          <highliter
            @favorite="$emit('favorite', $el)"
            @flow="handleFlow"
            @move-to="to => $emit('move-to', to)"
            @dublicate="$emit('dublicate')"
            @delete="$emit('delete')"
            @click.stop.native="$store.dispatch('editor/sectionSettings', {section: section.options.content.group.items[preview], openedItem: 'title'})"
          >
            <content-editable
              v-model="section.options.content.group.items[preview].options.content.title.text"
              class="video-preview-title"
              type="paragraph"
              :customs="section.options.content.group.items[preview].options.content.title"
            />
          </highliter>
        </b-col>

        <b-col :cols="cols(3, 12)">
          <div
            v-for="(video, index) in section.options.content.group.items"
            :key="index"
          >
            <video-item1
              v-if="index !== preview"
              :section="video"
            />
          </div>
        </b-col>
      </template>
    </VideoItems3Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ContentEditable from '@builder/components/builder/utils/ContentEditable'
import VideoItems3Template from '@builder/templates/sections/videos/VideoItems3Template'

export default {
  name: 'VideoItem3',

  components: {VideoItems3Template, ContentEditable, Highliter},

  mixins: [SectionMixin],

  data () {
    return {
      preview: 0
    }
  },

  created () {
    this.section.classes = {
      tiptap: {}
    }
  }
}
</script>

<style lang="scss">
.video-preview-title p {
  font-size: 46px;
}

.resolution-sm {
  .video-preview-title p {
    font-size: 28px !important;
  }
}
</style>
