<template>
  <div :style="{...fullMarginSubject(section.options.customize.style, 'marginTop')}">
    <b-container
      :class="[{'px-0': hasContainer}, {'element-hidden': !visability()}]"
      :fluid="removedContainer"
      :style="{...backgroundColor, ...border, ...borderRadius, ...shadow}"
      class="position-relative topad"
    >
      <highliter
        v-model="section.name"
        :class="{'mr-[-13px] ml-[-13px]': !hasContainer}"
        :dublicate="section"
        :uid="section.uid"
        class="el-row"
        delay
        section-type="row"
        @active="state => active = state"
        @delete="$emit('delete')"
        @dublicate="$emit('dublicate')"
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @add-subject="$emit('add-subject')"
        @click.stop.native="sectionSettings('row-mod')"
      >
        <div :class="{'px-[15px]': !hasContainer}">
          <div
            :style="{...fullPaddingSubject(section.options.customize.style)}"
            class="relative z-10"
          >
            <el-row-sizer
              :active-column="activeColumn"
              :columns="columns"
              :columns-to-show="columnsToShow"
            >
              <template v-slot:row="{handlers}">
                <b-row
                  :class="['align-items-' + section.options.customize.style.alignRow.align, {'vh-100': vh100}]"
                  :no-gutters="noGutters"
                  class="display-flex px-0"
                >
                  <b-col
                    v-for="(column, index) in columns"
                    :key="index"
                    :class="[{'element-hidden': !rowVisability(column.display)}, {'mb-3': getColumn(index).elements.length == 2} && !noGutters, 'justify-content-' + section.options.customize.style.alignRow.align]"
                    :cols="getActualColumnsToShow(index)"
                    :style="{...fullMarginColumnSubject(getColumn(index), [20,20,20,20])}"
                    class="elements-column"
                    @mouseenter="setActiveColumn($event, index)"
                  >
                    <div
                      :style="{...shadowSubject(getColumn(index)), ...borderSubject(getColumn(index), [0,0,0,0]), ...radiusSubject(getColumn(index), [0,0,0,0]), ...paddingSubject(getColumn(index), [20,20,20,20]), backgroundColor: getColumn(index).background, backgroundImage: _gradient(getColumn(index))}"
                      class="position-relative h-100"
                    >
                      <div
                        :style="{...imageRadiusSubject(getColumn(index)), ...backgroundImageSubject(getColumn(index))}"
                        class="image-background"
                      />

                      <background-slider
                        :style="{...imageRadiusSubject(getColumn(index))}"
                        :styles="backgroundImageSubject(getColumn(index))"
                        :options="backgroundSliderSubject(getColumn(index))"
                      />

                      <div
                        :style="{...imageRadiusSubject(getColumn(index)), ...backgroundImageOverlaySubject(getColumn(index))}"
                        class="image-background"
                      />

                      <shape-divider
                        :config="getColumn(index)"
                        :styles="{...imageRadiusSubject(getColumn(index))}"
                      />

                      <draggable
                        v-model="getColumn(index).elements"
                        :class="{'empty-row-drag h-100': !getColumn(index).elements.length, 'pb-3': getColumn(index).elements.length == 2}"
                        :group="{ name: group, pull: true, put: ['elements', 'rows'] }"
                        handle=".move"
                        :ghost-class="`${group}-sortable-ghost`"
                        :scroll="true"
                        :force-fallback="true"
                        :fallback-class="`${group}-sortable-fallback`"
                        :force-autoscroll-fallback="true"
                        :scroll-sensitivity="60"
                        :bubble-scroll="true"
                        :fallback-on-body="true"
                        @choose="onChoose"
                        @end="onDragEnd"
                        @start="onDragStart"
                        @click.stop.native="!getColumn(index).elements.length ? addSubject(index, -1) : null"
                      >
                        <component
                          :is="section.component"
                          v-for="(section, elIndex) in getColumn(index).elements"
                          :key="section.uid"
                          :section="section"
                          @delete="() => deleteElement(index, elIndex)"
                          @dublicate="() => dublicateElement(index, elIndex)"
                          @favorite="() => favoriteBlock = JSON.parse(JSON.stringify(columns[index].elements[elIndex]))"
                          @flow="handleFlow"
                          @move-to="to => moveTo(index, elIndex, to)"
                          @add-subject="addSubject(index, elIndex)"
                        />
                      </draggable>
                    </div>

                    <el-row-resize-handler
                      :class="{'active': active}"
                      :column-index="index"
                      @on-mousedown="handlers.selectHandler"
                    />
                  </b-col>
                </b-row>
              </template>
            </el-row-sizer>
          </div>
        </div>
      </highliter>

      <div
        :style="{...backgroundImage, ...imageRadius}"
        class="image-background"
      />
      <background-slider
        :style="{...borderRadius}"
        :styles="backgroundImage"
        :options="backgroundSlider"
      />
      <div
        :style="{...backgroundImageOverlay, ...imageRadius}"
        class="image-background"
      />

      <shape-divider
        :config="section.options.customize.style"
        :styles="{...imageRadius}"
      />

      <add-to-favorite-modal
        :favorite="favoriteBlock"
        @submit="favorite"
      />
    </b-container>
  </div>
</template>

<script>
import _ from 'lodash'
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '../../../utils/Highliter'
import CustomStylesMixin from '../../../../mixins/CustomStylesMixin'
import ElRowSizer from './components/ElRowSizer'
import ElRowResizeHandler from './components/ElRowResizeHandler'
import draggable from 'vuedraggable'
import shortid from 'shortid'
import {insert} from '../../../../../sections/utils/helpers'
import {mapState} from 'vuex'
import ResolutionMixin from '../../../../mixins/ResolutionMixin'
import DraggableElementsHandler from '../../../../mixins/DraggableElementsHandler'
import AddToFavoriteModal from '../../../components/modals/AddToFavoriteModal'
import BorderMixin from '@/components/mixins/BorderMixin'
import ShapeDivider from '@/components/editor/components/shape-divider/ShapeDivider'
import BackgroundSlider from '@/components/builder/utils/BackgroundSlider'
import BackgroundMixin from '@/components/mixins/BackgroundMixin'

export default {
  name: 'EsButtonUtil',
  components: {BackgroundSlider, ShapeDivider, AddToFavoriteModal, ElRowResizeHandler, ElRowSizer, Highliter, draggable},
  mixins: [SectionMixin, CustomStylesMixin, ResolutionMixin, DraggableElementsHandler, BorderMixin, BackgroundMixin],

  data () {
    return {
      activeColumn: null,
      activeMutated: false,
      group: 'elements',
      activeTimeout: null,
      favoriteBlock: {}
    }
  },

  computed: {
    vh100 () {
      return this.resolutioner === 'lg' ? this.section.options.customize.vh100 : false
    },
    active: {
      get () {
        return this.activeMutated
      },
      set (val) {
        clearTimeout(this.activeTimeout)
        if (!val) {
          this.activeTimeout = setTimeout(() => {
            this.activeMutated = val
          }, 0)

          return
        }

        this.activeMutated = val
      }
    },
    ...mapState('editor', {
      resolutionMut: state => state.resolution,
      tablet: state => state.tablet
    }),
    columns () {
      return new Array(this.section.options.customize.columnsToShow)
        .fill({})
        .map((val, key) => this.section.options.content.columns[this.orderColumns[key]])
    },
    orderColumns () {
      if (this.section.options.customize.hasOwnProperty('orderColumns')) {
        return this.section.options.customize.orderColumns[this.resolutioner]
      } else {
        this.$set(this.section.options.customize, 'orderColumns', {
          sm: [0, 1, 2, 3],
          md: [0, 1, 2, 3],
          lg: [0, 1, 2, 3]
        })
        return this.section.options.customize.orderColumns[this.resolutioner]
      }
    },
    resolutioner () {
      return this.tablet ? 'md' : this.resolutionMut
    },
    noGutters () {
      return _.get(this.section.options.customize, 'noGutters', false)
    },
    hasContainer () {
      return _.get(this.section.options.customize, 'hasContainer', false)
    },
    removedContainer () {
      return _.get(this.section.options.customize, 'removeContainer', false)
    },
    columnsToShow: {
      set (val) {
        this.section.options.customize.columnsToShow = val
      },
      get () {
        return this.section.options.customize.columnsToShow
      }
    }
  },

  methods: {
    fullMarginColumnSubject (subject) {
      if (!subject.margin || !subject.margin[this.resolutioner]) return {}

      return {
        paddingTop: subject.margin[this.resolutioner].top + 'px',
        paddingBottom: subject.margin[this.resolutioner].bottom + 'px',
        paddingLeft: subject.margin[this.resolutioner].left + 'px',
        paddingRight: subject.margin[this.resolutioner].right + 'px'
      }
    },
    addSubject (index, elIndex) {
      this.$store.commit('editor/SET_PAGE', {
        name: 'add-element',
        event: 'click',
        subject: {
          subject: this.columns[index],
          options: {index, elIndex, originKey: 'elements'}
        }
      })
    },
    rowVisability (displays) {
      if (displays === true || displays === undefined) {
        return true
      }

      if (typeof displays === 'object') {
        return !displays.length || displays.includes(this.resolutioner)
      }

      return displays
    },
    favorite (element) {
      this.$store.dispatch('favorites/addFavorite', {
        favorite: {
          section: element.favorite,
          group: 'elements'
        },
        inGlobal: element.globally
      })
        .finally(() => {
          this.favoriteBlock = {}
        })
    },
    getColumn (column) {
      return this.columns[column]
    },
    _gradient (config) {
      if (config.backgroundType === 'gradient' && config.hasOwnProperty('backgroundGradient')) {
        const values = config.backgroundGradient.map(gradient => {
          return `${gradient.color} ${gradient.position}%`
        })

        return `linear-gradient(${config.gradientDirection || 90}deg,${values})`
      }

      return null
    },
    shadowEdge (config) {
      if (!config.shadowEdge || !config.shadowEdge.enabled) {
        return 'unset'
      }

      return `${config.shadowEdge.x}px ${config.shadowEdge.y}px ${config.shadowEdge.blur}px ${config.shadowEdge.color}`
    },
    setActiveColumn (event, index) {
      this.activeColumn = {
        bound: event.target.getBoundingClientRect(),
        index
      }
    },
    deleteElement (column, element) {
      this.columns[column].elements.splice(element, 1)
      this.$store.commit('editor/SET_PAGE', 'add-element')
    },
    dublicateElement (column, element) {
      this.columns[column].elements = insert(this.columns[column].elements, element, JSON.parse(JSON.stringify({
        ...this.columns[column].elements[element],
        uid: shortid.generate()
      })))
    },
    moveTo (column, element, to) {
      const copySection = JSON.parse(JSON.stringify(this.columns[column].elements[element]))

      if (to === 'up') {
        if (element === 0) return
        this.columns[column].elements.splice(element, 1)
        this.columns[column].elements = insert(this.columns[column].elements, element - 1, copySection)
      } else if (to === 'down') {
        if (element + 1 === column.length) return
        this.columns[column].elements.splice(element, 1)
        this.columns[column].elements = insert(this.columns[column].elements, element + 1, copySection)
      }
    },
    getActualColumnsToShow (index) {
      try {
        return this.getColumn(index)[this.resolutioner].cols
      } catch {
        this.$set(this.getColumn(index), 'sm', {
          cols: 12
        })
        this.$set(this.getColumn(index), 'md', {
          cols: 12
        })
        this.$set(this.getColumn(index), 'lg', {
          cols: this.getColumn(index).cols
        })

        return this.getColumn(index)[this.resolutioner].cols
      }
    },
    onChoose (item) {
      if (item.item.querySelector('.el-row')) {
        return this.group = 'rows'
      }
      this.group = 'elements'
    }
  }
}
</script>