<template>
  <div>
    <SidbearSettingsGroup
      title="Post List"
      active
    >
      <b-form-group>
        <b-checkbox
          v-for="(_, index) in displayItems"
          :key="index"
          v-model="displayItems[index]"
          switch
        >
          {{ startCase(index) }}
        </b-checkbox>
      </b-form-group>

      <b-form-group>
        <sid-flex-direction
          :sid="className()"
          rule-name="flex-direction"
        >
          <template #column>
            <sid-buttons-group
              label="Horizontal Alignment"
              :options="[
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'start',
                  tooltip: 'Start'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'center',
                  tooltip: 'Center'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'end',
                  tooltip: 'End'
                }
              ]"
              :sid="className()"
              rule-name="align-items"
            />
          </template>

          <template #row>
            <sid-buttons-group
              label="Horizontal Alignment"
              class="mb-0"
              :options="[
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'10.04\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 10.04 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'5.54224\' y=\'10.9219\' width=\'3.94211\' height=\'7.30912\' transform=\'rotate(180 5.54224 10.9219)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.5555\' height=\'0.91364\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.913574 14.5557)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'start',
                  tooltip: 'Start'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'15\' viewBox=\'0 0 11 15\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect x=\'3.9668\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 3.9668 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'10.4326\' y=\'11.2344\' width=\'3.96671\' height=\'7.35473\' transform=\'rotate(180 10.4326 11.2344)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'14.3631\' height=\'0.901561\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 5.59766 14.363)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'center',
                  tooltip: 'Center'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'11\' height=\'16\' viewBox=\'0 0 11 16\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 0 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'4.1188\' height=\'7.63672\' transform=\'matrix(1 8.74228e-08 8.74228e-08 -1 4.7002 12.2031)\' fill=\'%237E7E7E\'/%3E%3Crect x=\'9.53613\' y=\'16\' width=\'15.2079\' height=\'0.95459\' transform=\'rotate(-90 9.53613 16)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'end',
                  tooltip: 'End'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'14\' height=\'13\' viewBox=\'0 0 14 13\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 4.4834 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.02612\' height=\'8.41616\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 11.7031 2.28711)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.701172 12.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'11.1734\' height=\'0.701346\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 13.1602 12.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'space-between',
                  tooltip: 'Between'
                },
                {
                  svg: 'data:image/svg+xml,%3Csvg width=\'13\' height=\'14\' viewBox=\'0 0 13 14\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 5.48535 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'3.26467\' height=\'9.07958\' transform=\'matrix(-1 8.74228e-08 8.66707e-08 1 10.0303 2.51978)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 0.756836 13.0225)\' fill=\'%237E7E7E\'/%3E%3Crect width=\'12.0541\' height=\'0.756631\' transform=\'matrix(-4.37114e-08 -1 -1 4.37114e-08 12.25 13.0225)\' fill=\'%237E7E7E\'/%3E%3C/svg%3E%0A',
                  value: 'space-around',
                  tooltip: 'Around'
                }
              ]"
              :sid="className()"
              rule-name="justify-content"
            />
          </template>
        </sid-flex-direction>
      </b-form-group>

      <sid-range-slider
        label="Gap"
        :sid="className()"
        rule-name="gap"
        default-dimension="px"
        :dimensions="[]"
      />
    </SidbearSettingsGroup>
    <SidbearSettingsGroup title="Title Text">
      <rule-tabs :tabs="['Normal', 'Active', 'Hover']">
        <template #Normal>
          <b-form-group label="Color">
            <sid-color
              rule-name="color"
              :sid="className(' a .title')"
            />
          </b-form-group>

          <sid-font :sid="className(' a .title')" />

          <sid-range-slider
            label="Font Size"
            :sid="className(' a .title')"
            rule-name="font-size"
            default-dimension="px"
            :dimensions="[]"
          />

          <sid-linked-group
            label="Padding"
            :sid="className(' a .title')"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />

          <sid-linked-group
            label="Margin"
            :sid="className(' a .title')"
            rule-name="margin"
            :min="-2000"
            :max="2000"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>

        <template #Hover>
          <sid-color
            rule-name="color"
            :sid="className(' a:hover .title')"
            presudo=":hover"
          />
        </template>

        <template #Active>
          <sid-color
            rule-name="color"
            :sid="className(' a.active .title')"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    <SidbearSettingsGroup title="Secondary Text">
      <rule-tabs :tabs="['Normal', 'Active', 'Hover']">
        <template #Normal>
          <b-form-group label="Color">
            <sid-color
              rule-name="color"
              :sid="className(' a .secondary')"
            />
          </b-form-group>

          <sid-font :sid="className(' a .secondary')" />

          <sid-range-slider
            label="Font Size"
            :sid="className(' a .secondary')"
            rule-name="font-size"
            default-dimension="px"
            :dimensions="[]"
          />

          <sid-linked-group
            label="Padding"
            :sid="className(' .secondary')"
            rule-name="padding"
            :min="0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />

          <sid-linked-group
            label="Margin"
            :sid="className(' .secondary')"
            rule-name="margin"
            :min="-2000"
            :max="2000"
            class="mb-0"
            :angles="[
              {
                text: '',
                value: 'top'
              },
              {
                text: '',
                value: 'right'
              },
              {
                text: '',
                value: 'bottom'
              },
              {
                text: '',
                value: 'left'
              }
            ]"
          />
        </template>

        <template #Hover>
          <sid-color
            rule-name="color"
            :sid="className(' a:hover .secondary')"
            presudo=":hover"
          />
        </template>

        <template #Active>
          <sid-color
            rule-name="color"
            :sid="className(' .active a .secondary')"
          />
        </template>
      </rule-tabs>
    </SidbearSettingsGroup>
    <SidbearSettingsGroup title="Icon">
      <b-form-group>
        <icon-select v-model="section.options.content.data.icon" />
      </b-form-group>

      <b-form-group label="Color">
        <sid-color
          rule-name="color"
          :sid="className(' a .icon')"
        />
      </b-form-group>

      <sid-range-slider
        label="Icon Size"
        :sid="className(' a .icon')"
        rule-name="font-size"
        default-dimension="px"
        :dimensions="[]"
      />

      <sid-linked-group
        label="Padding"
        :sid="className(' a .icon')"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="className(' a .icon')"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
    <SidbearSettingsGroup title="Padding & Margins">
      <sid-linked-group
        label="Padding"
        :sid="className()"
        rule-name="padding"
        :min="0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />

      <sid-linked-group
        label="Margin"
        :sid="className()"
        rule-name="margin"
        :min="-2000"
        :max="2000"
        class="mb-0"
        :angles="[
          {
            text: '',
            value: 'top'
          },
          {
            text: '',
            value: 'right'
          },
          {
            text: '',
            value: 'bottom'
          },
          {
            text: '',
            value: 'left'
          }
        ]"
      />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import ModMixin from '@/components/mixins/ModMixin'
import TextMixins from '@/components/mixins/TextMixins'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup.vue'
import SidRangeSlider from '@/components/editor/components/sid-controls/SidRangeSlider.vue'
import SidButtonsGroup from '@/components/editor/components/sid-controls/SidButtonsGroup.vue'
import SidFlexDirection
  from '@/components/editor/components/sid-controls/sid-control-pressets/flex/SidFlexDirection.vue'
import SidColor from '@/components/editor/components/sid-controls/SidColor.vue'
import IconSelect from '@/components/editor/components/IconSelect.vue'
import RuleTabs from '@/components/editor/components/RuleTabs.vue'
import SidFont from '@/components/editor/components/sid-controls/sid-control-pressets/font/SidFont.vue'
import SidLinkedGroup from '@/components/editor/components/sid-controls/SidLinkedGroup.vue'

export default {
  name: 'PostListMod',
  components: {
    SidLinkedGroup,
    SidFont,
    RuleTabs, IconSelect, SidColor, SidFlexDirection, SidButtonsGroup, SidRangeSlider, SidbearSettingsGroup},
  mixins: [ModMixin, TextMixins],
  computed: {
    displayItems () {
      return this.section.options.content.data.display
    }
  },
  methods: {
    className (name = '') {
      return `.--post-list-${this.section.sid}${name}`
    }
  }
}
</script>