<template>
  <highliter
    v-model="section.name"
    section-type="element"
    class="highlight-element"
    :dublicate-deep="parentUID"
    :dublicate="section"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @add-subject="$emit('add-subject')"
    @click.stop.native="sectionSettings('progress')"
  >
    <div
      :data-sid="section.sid"
      :class="`--${section.sid}--wrapper`"
    >
      <b-progress
        :class="`--${section.sid}--progress`"
        :max="section.options.content.progress.max"
      >
        <b-progress-bar
          :value="section.options.content.progress.value"
          :class="`--${section.sid}--bar`"
          :animated="section.options.content.progress.animated"
        >
          <div
            class="d-flex w-100 progress-bar-text py-0"
            :class="`--${section.sid}--text`"
          >
            {{ section.options.content.progress.text }}
          </div>
        </b-progress-bar>
      </b-progress>
    </div>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ResolutionMixin from '../../../mixins/ResolutionMixin'

export default {
  name: 'FeaturedItems1',

  components: {Highliter},

  mixins: [SectionMixin, ResolutionMixin]
}
</script>

<style lang="scss">
.progress-bar-text {
  justify-content: center;
}
</style>
