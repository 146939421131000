<template>
  <highliter
    v-model="section.name"
    :uid="section.uid"
    @favorite="$emit('favorite', $el)"
    @flow="handleFlow"
    @move-to="to => $emit('move-to', to)"
    @dublicate="$emit('dublicate')"
    @delete="$emit('delete')"
    @click.stop.native="sectionSettings()"
  >
    <ImageItem4Template :section="section">
      <highliter
        @favorite="$emit('favorite', $el)"
        @flow="handleFlow"
        @move-to="to => $emit('move-to', to)"
        @dublicate="$emit('dublicate')"
        @delete="$emit('delete')"
        @click.stop.native="sectionSettings('image')"
      >
        <b-img :src="section.options.content.image.src || section.options.content.image.placeholder" />
      </highliter>
    </ImageItem4Template>
  </highliter>
</template>

<script>
import SectionMixin from '@builder/components/mixins/SectionMixin'
import Highliter from '@builder/components/builder/utils/Highliter'
import ImageItem4Template from '@builder/templates/sections/images/ImageItem4Template'

export default {
  name: 'ImageItem3',

  components: {ImageItem4Template, Highliter},

  mixins: [SectionMixin]
}
</script>
