import router from '@builder/router/index'
import {toJSON, toStringify} from '../../utils/helpers'
import {applyPageStyles, pageStylesToString} from '@/utils/useStylesheet'

const layouts = [
  {
    id: 1,
    preview: require('@builder/assets/images/blog-layout-blank.png'),
    sections: [{
      'display': true,
      'name': 'Flex Wrapper',
      'hideFromSections': false,
      'component': 'ElFlexWrapperV2',
      'group': '',
      'layout': null,
      'options': {
        'content': {
          'display': true,
          'cols': [{
            'display': true,
            'name': 'Flex Column',
            'elements': [],
            'sortIndex': {'lg': 0, 'md': 0, 'sm': 0},
            'uid': '40iv5RYKWD',
            'sid': 'ibz27y6--bK6jsYgXZy'
          }]
        }
      },
      'uid': '-qrxKasMB',
      'sid': 'ibz27y6--wC_g0H6vXR',
      'temp_id': 'j0pbRmZok',
      'css': [['.--ibz27y6--wC_g0H6vXR--flex { max-width:80vw; --width:80vw; }', '.--ibz27y6--wC_g0H6vXR--bg { background: #fff; }', '.--ibz27y6--bK6jsYgXZy--flex-col-el { width: 100%; --width: 100%; }'], ['.--ibz27y6--wC_g0H6vXR--flex { max-width: 100%; --width:100%; }'], ['.--ibz27y6--wC_g0H6vXR--flex { max-width: 100%; --width:100%; }']],
      'sids': ['ibz27y6--wC_g0H6vXR', 'ibz27y6--bK6jsYgXZy']
    }]
  }
]

const modalDraft =
  {
    name: 'Default Modal Name',
    version: 2,
    content: JSON.parse(JSON.stringify(layouts[0].sections)),
    actions: ['on-click', 'on-submit'],
    settings: {
      hideBackdrop: false,
      closeIcon: true,
      closeIconColor: '#6E747A',
      centered: true,
      noCloseOnBackdrop: false,
      noCloseOnEsc: false,
      scrollable: false,
      size: 'lg',
      verticalAlign: 'center',
      horizontalAlign: 'center',
      background: {
        background: 'rgba(0,0,0,.8)',
        gradient: {
          position: '',
          color: ''
        }
      }
    }
  }

const state = () => ({
  modal: {
    name: 'Default Modal Name',
    version: 2,
    content: layouts[0].sections,
    actions: ['on-click', 'on-submit'],
    settings: {
      hideBackdrop: false,
      closeIcon: true,
      closeIconColor: '#6E747A',
      centered: true,
      noCloseOnBackdrop: false,
      noCloseOnEsc: false,
      scrollable: false,
      size: 'lg',
      background: {
        background: 'rgba(0,0,0,.8)',
        gradient: {
          position: '',
          color: ''
        }
      }
    }
  },
  modals: [],
  showModal: null,
  loadedModal: {},
  layouts: layouts
})

const getters = {}

const actions = {
  async fetch ({state, rootState}) {
    await axios.get(`api/projects/${rootState.projects.project.id}/modals?page=1&perPage=100`)
      .then(({data}) => {
        state.modals = data.data.data
      })
  },
  async load ({state, rootState, commit}, id) {
    if (router.currentRoute.name !== 'preview' && router.currentRoute.name !== 'module.modal') return
    commit('SHOW_MODAL', false)
    await axios.get(`api/projects/${rootState.projects.project.id}/modals/${id}`)
      .then(({data}) => {
        state.loadedModal = {
          ...data.data,
          content: toJSON(data.data.content),
          settings: toJSON(data.data.settings)
        }
      })
    setTimeout(() => {
      commit('SHOW_MODAL', true)
    })
  },
  async save ({state, dispatch, rootState}) {
    const fonts = await dispatch('font/prepareFonts', {}, {root: true})

    return await axios[state.modal.id ? 'put' : 'post'](`api/projects/${rootState.projects.project.id}/modals${state.modal.id ? `/${state.modal.id}` : ''}`, {
      ...state.modal,
      content: toStringify(state.modal.content),
      actions: toStringify(state.modal.actions),
      settings: toStringify(state.modal.settings),
      css: pageStylesToString('subject-styles'),
      is_active: true,
      fonts: fonts.googleFonts + fonts.customFonts
    })
      .then(({data}) => {
        if (!state.modal.id) {
          return dispatch('startEdit', {id: data.data.id})
        }

        state.modal = {
          ...state.modal,
          id: data.data.id
        }
      })
  },
  async deactivate ({rootState}, modal) {
    await axios.put(`api/projects/${rootState.projects.project.id}/modals/${modal.id}`, {
      ...modal,
      is_active: 0
    })
      .then(() => {
        modal.is_active = 0
      })
  },
  async activate ({rootState}, modal) {
    await axios.put(`api/projects/${rootState.projects.project.id}/modals/${modal.id}`, {
      ...modal,
      is_active: true
    })
      .then(() => {
        modal.is_active = 1
      })
  },
  async modalDelete ({rootState}, modal) {
    return await axios.delete(`api/projects/${rootState.projects.project.id}/modals/${modal.id}`)
  },
  startNewModal ({commit}, name) {
    applyPageStyles('@media screen {}\n' +
      '    @media (max-width: 1100px) {}\n' +
      '    @media (max-width: 520px) {}', 'subject-styles')

    commit('EDIT_POST', {
      ...JSON.parse(JSON.stringify(modalDraft)),
      name
    })

    router.push({name: 'modal.editor', params: {modal_id: 'new-modal'}})
      .catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
  },
  async startEdit ({state, rootState}, modal) {
    if (modal.id === 'new-modal') return

    VEvent.fire('loader', true)
    await axios.get(`api/projects/${rootState.projects.project.id}/modals/${modal.id}`)
      .then(({data}) => {
        const modal = data.data

        state.modal = {
          ...modal,
          content: toJSON(modal.content),
          settings: toJSON(modal.settings)
        }

        applyPageStyles(state.modal.css, 'subject-styles')

        router.push({name: 'modal.editor', params: {modal_id: modal.id}})
          .catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err
            }
          })
      })
      .finally(() => {
        VEvent.fire('loader', false)
      })
  },
  selectLayout ({state}, layout) {
    state.modal.content = layout
  }
}

const mutations = {
  EDIT_POST (state, payload) {
    state.modal = payload
  },
  SHOW_MODAL (state, payload) {
    state.showModal = payload
  }
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
