<template>
  <div
    class="video-item-1"
    :style="{...padding}"
  >
    <div
      class="video rounded"
      :style="{...shadow}"
    >
      <slot name="video" />
    </div>
    <slot name="title" />
    <slot
      v-if="visability('subtitle')"
      name="subtitle"
    />
  </div>
</template>

<script>
import TemplateMixin from '../../../components/mixins/TemplateMixin'

export default {
  name: 'VideoItems1Template',
  mixins: [TemplateMixin]
}
</script>

<style lang="scss">
.video-item-1 {
  .video {
    margin-bottom: 15px;
  }
}
</style>
