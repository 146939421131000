<template>
  <div
    class="pollite d-flex align-items-center cursor-pointer justify-content-center"
    :class="[{'pollite-small': !small}, {'disabled': disabled}]"
  >
    <div class="position-relative">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="1.83333"
          width="22"
          height="20.1667"
          fill="#C4C4C4"
        />
        <rect
          width="7"
          height="5.5"
          fill="#F9F9F9"
        />
        <rect
          x="7"
          width="8"
          height="5.5"
          fill="#DDDDDD"
        />
        <rect
          x="15"
          width="7"
          height="5.5"
          fill="#F9F9F9"
        />
        <rect
          y="16.5"
          width="7"
          height="5.5"
          fill="#E5E5E5"
        />
        <rect
          x="7"
          y="16.5"
          width="8"
          height="5.5"
          fill="#F9F9F9"
        />
        <rect
          x="15"
          y="16.5"
          width="7"
          height="5.5"
          fill="#E5E5E5"
        />
        <rect
          y="11"
          width="7"
          height="5.5"
          fill="#F9F9F9"
        />
        <rect
          x="7"
          y="11"
          width="8"
          height="5.5"
          fill="#DDDDDD"
        />
        <rect
          x="15"
          y="11"
          width="7"
          height="5.5"
          fill="#F9F9F9"
        />
        <rect
          y="5.5"
          width="7"
          height="5.5"
          fill="#DDDDDD"
        />
        <rect
          x="7"
          y="5.5"
          width="8"
          height="5.5"
          fill="#F9F9F9"
        />
        <rect
          x="15"
          y="5.5"
          width="7"
          height="5.5"
          fill="#DDDDDD"
        />
      </svg>
      <div
        class="border pollite-backdrop"
        :style="{height: '23px', width: '23px', background: color}"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorPickerPlaceholder',

  props: {
    color: {
      required: true
    },
    small: {
      default: true
    },
    disabled: {
      default: false
    }
  }
}
</script>

<style lang="scss">
  .pollite.pollite-small {
    width: 26px;
    height: 26px;
  }
</style>
