<template>
  <div
    class="text-center image-popup"
  >
    <slot />
  </div>
</template>

<script>
import TemplateMixin from '@builder/components/mixins/TemplateMixin'

export default {
  name: 'AboutUs1Template',
  mixins: [TemplateMixin]
}
</script>