<template>
  <div>
    <b-form-group v-if="linked">
      <button
        class="flex p-0 text-[11px] hover:bg-gray-100 items-center text-dark border rounded-[4px] w-[114px] h-[30px] justify-content-center gap-[5px] cursor-pointer font-[500] hover:text-[#3D82EA] text-[#7E7E7E]"
        @click="() => unlinkSid()"
      >
        Unlink Styles
        <i class="fa fa-link text-primary" />
      </button>
    </b-form-group>

    <component
      :is="componentGroupName"
      :active="showActiveGroup"
      ignore-triggers
      title="Image"
    >
      <b-form-group>
        <image-upload
          :image="config"
          class="w-100"
          disable-settigns
        />
      </b-form-group>

      <b-form-group label="Alt image text">
        <b-input v-model="config.alt" />
      </b-form-group>

      <b-form-group label="Link">
        <b-input
          v-model="url"
          placeholder="URL"
        />
      </b-form-group>

      <image-translate
        :config="config"
        :hide="hide"
      />

      <image-flip :config="config" />

      <b-form-group
        v-if="!hide.includes('align')"
        label="Alignment"
      >
        <b-btn-group>
          <b-btn
            variant="white"
            @click="align = 'start'"
          >
            <i
              :class="{'text-primary': align === 'start'}"
              class="fa fa-outdent"
            />
          </b-btn>
          <b-btn
            variant="white"
            @click="align = 'center'"
          >
            <i
              :class="{'text-primary': align === 'center'}"
              class="fa fa-align-justify"
            />
          </b-btn>
          <b-btn
            variant="white"
            @click="align = 'end'"
          >
            <i
              :class="{'text-primary': align === 'end'}"
              class="fa fa-indent"
            />
          </b-btn>
        </b-btn-group>
      </b-form-group>

      <b-form-group>
        <template slot="label">
          Shape ({{ resolutioner }})
        </template>

        <v-select
          v-model="shape"
          :options="masks"
          :searchable="false"
          label="name"
          placeholder="Select Shape"
        />
      </b-form-group>

      <b-form-group>
        <ButtonAction
          :config="config"
          class="mb-0"
        />
      </b-form-group>
    </component>

    <SidbearSettingsGroup
      title="Border & Radius"
    >
      <border-customs
        :border-default="[0,0,0,0]"
        :config="config"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Drop Shadow"
    >
      <shadow-customs
        :config="config"
        :hide="shape ? shape.code === 'bubble' ? ['spread'] : [] : []"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Image Effects"
    >
      <image-filters :config="config" />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      content-class="pb-0"
      title="Padding & Margins"
    >
      <padding-full-customs :config="section.options.customize.style.padding" />
      <margin-full-customs
        :config="section.options.customize.style.marginTop"
        class="mb-0"
      />
    </SidbearSettingsGroup>

    <SidbearSettingsGroup
      title="Animations"
    >
      <b-form-group label="Onload effect">
        <animations :animations="config.animations" />
      </b-form-group>

      <hover-animations v-model="hoverAnimation" />
    </SidbearSettingsGroup>
  </div>
</template>

<script>
import _ from 'lodash'
import ModMixin from '../../mixins/ModMixin'
import FactoryMixin from '@/components/mixins/FactoryMixin'
import Animations from '../controls/Animations'
import ImageFilters from '../components/ImageFilters'
import ImageFlip from '@/components/editor/controls/image-mod/ImageFlip'
import ImageTranslate from '@/components/editor/controls/image-mod/ImageTranslate'
import SidbearSettingsGroup from '@/components/editor/components/SidbearSettingsGroup'
import MarginCustomsV2 from '@/components/editor/controls/MarginCustomsV2'
import HoverAnimations from '@/components/editor/controls/HoverAnimations'
import ButtonAction from '@/components/editor/controls/button-mod/ButtonAction'
import SidStyles from '@/components/mixins/SidStyles'

export default {
  name: 'ImageModV2',
  components: {
    ButtonAction,
    HoverAnimations,
    MarginCustomsV2,
    SidbearSettingsGroup,
    ImageTranslate, ImageFlip, Animations, ImageFilters
  },
  mixins: [ModMixin, FactoryMixin, SidStyles],
  props: {
    componentGroupName: {
      type: String,
      default: 'SidbearSettingsGroup'
    }
  },
  data () {
    return {
      masks: [
        {code: null, name: 'No Shape'},
        {code: 'circle', name: 'Circle'},
        {code: 'rounded', name: 'Rounded'},
        {code: 'bubble', name: 'Bubble'}
      ]
    }
  },
  computed: {
    hoverAnimation: {
      get () {
        return this.config.hoverAnimation || null
      },
      set (val) {
        this.$set(this.config, 'hoverAnimation', val)
      }
    },
    url: {
      get () {
        return this.config.url
      },
      set (val) {
        this.$set(this.config, 'url', val)
      }
    },
    align: {
      get () {
        return _.get(this.config, ['align', this.resolutioner], 'center')
      },
      set (val) {
        this.$set(this.config, 'align', {
          ..._.get(this.config, 'align', {}),
          [this.resolutioner]: val
        })
      }
    },
    offsetTop: {
      get () {
        return this.config.offsetTop[this.resolutioner]
      },
      set (val) {
        this.config.offsetTop[this.resolutioner] = val
      }
    },
    shape: {
      get () {
        return this.config.shape[this.resolutioner]
      },
      set (val) {
        this.config.shape[this.resolutioner] = val
      }
    },
    shapeCode () {
      return _.get(this.config.shape, [this.resolutioner, 'code'], null)
    }
  }
}
</script>
