import {mapState} from 'vuex'

export default {
  data () {
    return {
      pageIsLoading: false,
      currentPaginationPage: 1,
      searchedPages: [],
      searching: false,
      scrollTop: 0,
      $menu: null
    }
  },
  computed: {
    ...mapState('projects', {
      project: state => state.project
    }),
    ...mapState('pages', {
      paginationPages: state => state.pages,
      currentPage: state => state.page
    })
  },
  methods: {
    async onOpen () {
      this.currentPaginationPage = 1
      setTimeout(() => {
        document.querySelector('.vs__dropdown-menu').addEventListener('scroll', this.scrollLoadingPages)
      })
    },
    onClose () {
      this.searching = false
      document.querySelector('.vs__dropdown-menu').removeEventListener('scroll', this.scrollLoadingPages)
    },
    async onSearch (search) {
      if(search.length) {
        this.searching = true
        const pages = await axios.get(`api/projects/${this.project.id}/pages?q=${search}`)
        this.selectPages = pages.data.data.data
        this.searching = false
      } else {
        this.selectPages = JSON.parse(JSON.stringify(this.pages.data))
      }
    },
    scrollLoadingPages (event) {
      const $el = event.target
      const scrollHeight = $el.scrollHeight - $el.clientHeight

      if (Math.round($el.scrollTop) >= scrollHeight && (this.currentPaginationPage || 1) < (this.pages.total / 25)) {
        document.body.style.overflow = 'hidden'
        this.$menu = event.target
        this.scrollTop = event.target.scrollTop
        if (!this.currentPaginationPage) {
          this.currentPaginationPage = 2
        } else {
          this.currentPaginationPage++
        }
      }
    }
  },
  watch: {
    async currentPaginationPage (val) {
      if (!val) return

      this.pageIsLoading = true
      let pages = await this.$store.dispatch('pages/loadPagesScrolling', {page:val})
      pages = pages.data
      this.pageIsLoading = false
      document.body.style.overflow = null

      if (this.allSelected) {
        pages.forEach(page => this.selectPages.push(page.id))
      }

      this.pages.data = [...this.pages.data, ...pages.filter(page => page.id !== this.currentPage.id).filter(page => !page.is_home)]
      setTimeout(() => {
        this.$menu.scrollTop = this.scrollTop
      }, 1)
    }
  }
}
