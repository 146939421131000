<template>
  <div>
    <b-form-group label="Page">
      <pages-select-menu
        :others="[{id: 'custom-link', title: 'Custom Link'}, {id: 'blog', title: 'ESTAGE Blog', code: project.blog_slug}]"
        input-class="rounded-[4px]"
        other-title="Other:"
        @selectOther="item => service.data.page = item"
        @selectPage="item => service.data.page = item"
      >
        <template
          v-if="service.data.page"
          slot="placeholder"
        >
          <div class="px-3">
            {{ service.data.page.title }}
          </div>
        </template>
      </pages-select-menu>
    </b-form-group>

    <template v-if="service.data.page.id === 'custom-link'">
      <b-form-group label="Redirect URL">
        <b-input
          v-model="service.data.customLink"
          v-validate="{url: {require_protocol: true }}"
          placeholder="https://estage.com"
          type="url"
          name="url"
          class="mb-1"
          :state="errors.has('url') ? false : null"
        />
        <span
          v-if="errors.has('url')"
          class="text-danger text-[12px]"
        >Invalid URL or http:// is required</span>
      </b-form-group>

      <b-form-group>
        <b-checkbox v-model="service.data.open_new_tab">
          Open in new tab
        </b-checkbox>
      </b-form-group>
    </template>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import PagesSelectMenu from '@/components/editor/components/pagesSelectMenu/PagesSelectMenu'

export default {
  name: 'EmailToAdmin',
  components: {PagesSelectMenu},
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('pages', {
      pages: 'getPages'
    }),
    ...mapState('projects', {
      project: state => state.project
    })
  }
}
</script>
