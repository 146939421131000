<template>
  <div class="menubar mb-4">
    <label class="mb-2">Section Align</label>

    <div class="d-flex justify-content-between flex-wrap">
      <div
        :style="{backgroundImage: `url(${config.images.left})`}"
        class="section-image"
        :class="{'active': config.align === 'left'}"
        @click="config.align = 'left'"
      />
      <div
        :style="{backgroundImage: `url(${config.images.right})`}"
        class="section-image"
        :class="{'active': config.align === 'right'}"
        @click="config.align = 'right'"
      />
    </div>
  </div>
</template>

<script>
import ControlMixin from '../../mixins/ControlMixin'

export default {
  name: 'AlignRow',

  mixins: [ControlMixin]
}
</script>

<style lang="scss" scoped>
.section-image {
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.26));
  width: 130px;
  height: 76px;
  background-position: center;
  border-radius: 3px;
  margin-bottom: 30px;

  &.active {
    box-shadow: 0 0 0 3px #7CACFA;
  }
}
</style>
