const state = () => ({
  isValided: true,
  validator: null
})

const getters = {

}

const actions = {

}

const mutations = {
  SET_VALIDATE (state, payload) {
    state.isValided = payload.isValided
  },
  SET_VALIDATOR (state, payload) {
    state.validator = payload.validator
  },
  RESET_VALIDATORS (state) {
    state.validator = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
